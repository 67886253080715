import React, { useEffect, useMemo, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { Button, CompoundSmilesPreview, Input, Select, Tooltip } from '../../../index';
import { SmilesSearcher } from '../../../SmilesSearcher';
import { httpSynMolDB, checkNumericField } from '../../../../utils';
import {
  calculateQuenchingListOnLimited,
  calculateVolumesOnLimited,
  calculateVolumesOnLimitedGroup,
} from '../../../../utils/synjetHelpers';
import { INPUT_EXCLUDE_SYMBOLS } from '../../../../constants/regexp';
import { SOLVENT_TYPES, SOLVENT_TYPES_OPTIONS } from '../../../../constants';

const SynJetCompoundInfoEdit = ({
  compound,
  setCompound,
  limitingTitle,
  isLimiting,
  validationErrors,
  clearValidationErrors,
  limitingCompound,
  isScreening,
  limitingCompoundGroup,
  isLimitingCompoundGroup,
  isFixed,
  disableLimiting,
  isLibGen,
  isSolid,
  isQuenching,
  isCompoundAddAvailable,
  isSolidCompoundInfo,
  disableSolventTypeChange,
}) => {
  const [solventsOptions, setSolventsOptions] = useState([]);
  const [selectValue, setSelectValue] = useState('name');

  const getSolventOptions = () =>
    solventsOptions.map(compound => ({
      value: compound.compound_name,
      label: compound.compound_name,
    }));

  useEffect(() => {
    httpSynMolDB('get_compound_list?tag=solvent&return_compatibility=true').then(resp => {
      setSolventsOptions([{ compound_name: 'None' }, ...resp.compound_list]);
    });
  }, []);

  useEffect(() => {
    if (!isLibGen || isLimitingCompoundGroup) return;

    let { volumes } = compound;
    if (limitingCompoundGroup) {
      volumes = calculateVolumesOnLimitedGroup(limitingCompoundGroup, compound.concentration, compound);
      if (isSolid && isLimitingCompoundGroup) {
        const volume = volumes[0];
        volumes = [volume];
      }
    }
    setCompound({
      ...compound,
      volumes,
    });
  }, [isSolid]);

  useEffect(() => {
    if (compound?.solvents && !compound?.solvents.length) {
      setCompound({
        ...compound,
        solvents: [{ solvent: { compound_name: 'None' }, fraction: 1 }],
      });
    }
  }, []);

  const handleSolventChange = (value, index, isFraction) => {
    if (isFraction) clearValidationErrors('fraction');
    else clearValidationErrors('solvents');
    const solventData = isFraction
      ? null
      : solventsOptions.find(solventOption => solventOption.compound_name === value);

    if (index || index === 0) {
      setCompound({
        ...compound,
        solvents: compound.solvents.map((solvent, solventIndex) =>
          solventIndex === index
            ? isFraction
              ? { ...solvent, fraction: checkNumericField(value, 1, 3) }
              : { ...solvent, solvent: solventData }
            : solvent
        ),
      });
    } else {
      setCompound({ ...compound, solvents: [{ solvent: solventData, fraction: 1 }] });
    }
  };

  const handleAddSolvent = () => {
    setCompound({
      ...compound,
      solvents: [
        { ...compound.solvents[0], fraction: 0 },
        { solvent: null, fraction: 0 },
      ],
    });
  };

  const handleDeleteSolvent = () => {
    setCompound({
      ...compound,
      solvents: [{ ...compound.solvents[0], fraction: 1 }],
    });
  };

  const handleLimitingChange = isLimiting => {
    setCompound({
      ...compound,
      isLimiting,
      equivalents: isLimiting ? [1] : isFixed ? [null] : [null, null, null],
      volumes: isLimiting ? [null] : isFixed ? [null] : [null, null, null],
    });
  };

  const handleSolventType = solventType => {
    const calculationOptions = {
      solvents: null,
      concentration: null,
    };
    setCompound({
      ...compound,
      solventType,
      ...calculationOptions,
    });
  };

  const handleConcentrationChange = value => {
    const concentration = checkNumericField(value, 10000000000, 3);
    setCompound({
      ...compound,
      concentration,
      volumes:
        isScreening || isQuenching
          ? isLimitingCompoundGroup
            ? compound.volumes
            : disableLimiting
            ? calculateVolumesOnLimited(limitingCompoundGroup, concentration, compound)
            : calculateVolumesOnLimitedGroup(limitingCompoundGroup, concentration, compound)
          : calculateVolumesOnLimited(limitingCompound, concentration, compound),
    });
    clearValidationErrors('concentration');
  };

  const handleEquivalentChange = (value, index) => {
    clearValidationErrors('equivalents');
    const equivalents = compound.equivalents.map((equivalent, eqIndex) =>
      eqIndex === index ? checkNumericField(value, 10000000000, 3) : equivalent
    );
    setCompound({
      ...compound,
      equivalents,
      volumes:
        isScreening || isQuenching
          ? disableLimiting
            ? calculateVolumesOnLimited(limitingCompoundGroup, compound.concentration, compound, equivalents)
            : calculateVolumesOnLimitedGroup(limitingCompoundGroup, compound.concentration, compound, equivalents)
          : calculateVolumesOnLimited(limitingCompound, compound.concentration, compound, equivalents),
    });
  };

  const handleVolumeChange = value => {
    clearValidationErrors('volumes');
    setCompound({
      ...compound,
      volumes: [checkNumericField(value, 10000000000, 4)],
    });
  };

  return (
    <>
      <div className="synjet-compound__info__smiles">
        <>
          <SmilesSearcher
            synjet
            changeSelect={smiles => {
              clearValidationErrors('smiles');
              clearValidationErrors('name');
              if (smiles?.compound_id) setCompound({ ...compound, smiles });
            }}
            smilesPlaceholder="Search"
            data={compound?.smiles}
            handleCompoundChange={() => {
              setCompound({ ...compound, smiles: null });
            }}
            initialSearchValue={compound?.smiles?.compound_name}
            setSelectValue={setSelectValue}
            error={selectValue === 'name' ? validationErrors?.name : validationErrors?.smiles}
            errorText={selectValue === 'name' ? validationErrors?.name : validationErrors?.smiles}
          />
          {compound?.smiles?.compound_id && (
            <CompoundSmilesPreview
              compound={compound?.smiles}
              className="smiles-preview"
              options={{ width: 130, height: 80 }}
              showTooltip
            />
          )}
        </>
        {isLibGen && (
          <Select
            options={SOLVENT_TYPES_OPTIONS}
            placeholder="None"
            className="solvent-type"
            value={compound?.solventType}
            onChange={type => handleSolventType(type)}
            disabled={(!isCompoundAddAvailable && isSolidCompoundInfo) || disableSolventTypeChange}
          />
        )}
      </div>
      <div className={cn('synjet-compound__info__solvent', { 'synjet-compound__info__solvent-edit': !isSolid })}>
        {isSolid ? (
          <p className="solid-solvent">N/A</p>
        ) : (
          <>
            {compound?.solvents ? (
              <>
                {compound?.solvents?.length ? (
                  compound.solvents.map((solvent, index, solvents) => (
                    <>
                      <div className="synjet-compound__row">
                        <Select
                          options={getSolventOptions()}
                          placeholder="None"
                          className="solvent-select"
                          value={compound?.solvents[index]?.solvent?.compound_name}
                          onChange={solvent => handleSolventChange(solvent, index)}
                          error={validationErrors?.solvents && validationErrors?.solvents[index]}
                          errorText={validationErrors?.solvents && validationErrors?.solvents[index]}
                        />
                        {index === 1 && (
                          <Button
                            type="text"
                            className={cn('add-solvent', 'delete-solvent')}
                            onClick={handleDeleteSolvent}
                          >
                            <DeleteOutlined />
                          </Button>
                        )}
                      </div>
                      {solvent?.solvent?.compound_name !== 'None' && (
                        <div className="synjet-compound__row">
                          <span className="synjet-compound__text">Fraction</span>
                          <Input
                            className="fraction-input"
                            placeholder="0"
                            maxLength={5}
                            disabled={solvents.length === 1}
                            onChange={value => handleSolventChange(value, index, true)}
                            value={compound?.solvents[index]?.fraction}
                            regex={INPUT_EXCLUDE_SYMBOLS}
                            error={validationErrors?.fraction && validationErrors?.fraction[index]}
                            errorText={validationErrors?.fraction && validationErrors?.fraction[index]}
                          />
                        </div>
                      )}
                    </>
                  ))
                ) : (
                  <Select
                    options={getSolventOptions()}
                    placeholder="None"
                    className="solvent-select"
                    value="None"
                    onChange={solvent => handleSolventChange(solvent)}
                    error={validationErrors?.solvent}
                    errorText={validationErrors?.solvent}
                  />
                )}
                {compound?.solvents?.length === 1 &&
                  compound?.solvents &&
                  compound?.solvents[0]?.solvent?.compound_name !== 'None' && (
                    <Button type="text" className="add-solvent" onClick={handleAddSolvent}>
                      <PlusOutlined /> ADD SOLVENT
                    </Button>
                  )}
              </>
            ) : (
              <Select
                options={getSolventOptions()}
                placeholder="Select the solvent"
                className="solvent-select"
                onChange={solvent => handleSolventChange(solvent)}
                error={validationErrors?.solvent}
                errorText={validationErrors?.solvent}
              />
            )}
          </>
        )}
      </div>
      <div className="synjet-compound__info__concentration">
        {isSolid ? (
          <p className="solid-solvent">N/A</p>
        ) : (
          <Input
            className="concentration-input"
            placeholder="0"
            maxLength={10}
            regex={INPUT_EXCLUDE_SYMBOLS}
            value={compound?.concentration}
            onChange={handleConcentrationChange}
            error={validationErrors?.concentration}
            errorText={validationErrors?.concentration}
          />
        )}
      </div>
      {!isScreening && !isQuenching && (
        <div className="synjet-compound__info__limiting">
          {(limitingCompound && !isLimiting) || disableLimiting ? (
            <Tooltip
              title={
                disableLimiting
                  ? "You can't make compound limiting on variable step"
                  : `${limitingCompound?.name} is limiting. Please unmark it to proceed`
              }
            >
              <Switch checked={false} disabled />
            </Tooltip>
          ) : (
            <Switch checked={compound?.isLimiting} onChange={handleLimitingChange} />
          )}
        </div>
      )}
      <div className="synjet-compound__info__equivalent">
        <>
          {compound?.isLimiting || isLimitingCompoundGroup ? (
            <div
              className="synjet-compound__info__equivalent__grid"
              style={
                (isScreening || isQuenching) && isLimitingCompoundGroup
                  ? {
                      gridTemplateColumns: '1fr 1fr',
                    }
                  : isFixed
                  ? { gridTemplateColumns: '1fr 1fr' }
                  : {}
              }
            >
              {compound?.isLimiting && !isFixed && !isScreening && !isQuenching ? (
                <>
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    Equivalent
                  </span>
                  <span />
                  <Input className="equivalent-input" placeholder="0" disabled value={compound?.equivalents[0]} />
                  <span />
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    {isSolid ? 'Mass (mg)' : 'Volume (µL)'}
                  </span>
                  <span />
                  <Input
                    className="equivalent-input"
                    placeholder="0"
                    maxLength={10}
                    regex={INPUT_EXCLUDE_SYMBOLS}
                    onChange={handleVolumeChange}
                    value={compound?.volumes[0]}
                    error={validationErrors?.volumes && validationErrors?.volumes[0]}
                    errorText={validationErrors?.volumes && validationErrors?.volumes[0]}
                  />
                  <span />
                </>
              ) : (
                <>
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    Equivalent
                  </span>
                  <Input className="equivalent-input" placeholder="0" disabled value={compound?.equivalents[0]} />
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    {isSolid ? 'Mass (mg)' : 'Volume (µL)'}
                  </span>
                  <Input
                    className="equivalent-input"
                    placeholder="0"
                    maxLength={10}
                    regex={INPUT_EXCLUDE_SYMBOLS}
                    onChange={handleVolumeChange}
                    value={compound?.volumes[0]}
                    error={validationErrors?.volumes && validationErrors?.volumes[0]}
                    errorText={validationErrors?.volumes && validationErrors?.volumes[0]}
                  />
                </>
              )}
            </div>
          ) : (
            <div
              className="synjet-compound__info__equivalent__grid"
              style={
                isScreening || isQuenching
                  ? {
                      gridTemplateColumns:
                        limitingCompoundGroup && !isLimitingCompoundGroup && !disableLimiting
                          ? `repeat(${(limitingCompoundGroup?.compoundsList?.length || 0) + 1}, 1fr)`
                          : '1fr 1fr',
                    }
                  : isFixed
                  ? { gridTemplateColumns: '1fr 1fr' }
                  : {}
              }
            >
              <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                Equivalent
              </span>
              {compound?.equivalents.map((equivalent, i) => (
                <>
                  <Input
                    className="equivalent-input"
                    placeholder="0"
                    maxLength={10}
                    regex={INPUT_EXCLUDE_SYMBOLS}
                    value={equivalent}
                    onChange={value => handleEquivalentChange(value, i)}
                    error={validationErrors?.equivalents && validationErrors?.equivalents[i]}
                    errorText={validationErrors?.equivalents && validationErrors?.equivalents[i]}
                  />
                </>
              ))}
              <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                {isSolid ? 'Mass (mg)' : 'Volume (µL)'}
              </span>
              {compound?.volumes.map(volume => (
                <Tooltip title="Select limiting reagent to unlock">
                  <div>
                    <Input className="equivalent-input" placeholder="N/A" disabled value={volume} />
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default SynJetCompoundInfoEdit;
