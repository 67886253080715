import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SynJetCompound } from '../../index';
import { Button, openNotification } from '../../../Common';
import { updateCompoundList, addCompoundToList } from '../../../../store/synjet/synjet.actions';
import './index.scss';
import { selectAbilityAddCompound } from '../../../../store/synjet/synjet.selector';
import cn from 'classnames';

const SynJetCompoundList = ({
  type,
  compoundList,
  limitingTitle,
  compoundOffset = 0,
  limitingCompound,
  step,
  isFixed,
  viewOnly = false,
  addCompoundAvailable,
  disableLimiting,
  highlightErrors,
  isLibGen,
}) => {
  const dispatch = useDispatch();
  const isCommonCompoundAddAvailable = useSelector(selectAbilityAddCompound);
  const canAddCompound =
    addCompoundAvailable &&
    isCommonCompoundAddAvailable &&
    (!!compoundList?.[compoundList.length - 1]?.smiles || !compoundList?.length);

  const handleCompoundChange = (updatedCompound, index) => {
    const updatedList = compoundList.map((compound, compoundIndex) =>
      compoundIndex === index ? updatedCompound : compound
    );
    dispatch(updateCompoundList(updatedList, `${type}s`, step));
  };

  const handleCompoundDelete = (index, compoundName) => {
    const updatedList = compoundList.filter((compound, compoundIndex) => compoundIndex !== index);
    dispatch(updateCompoundList(updatedList, `${type}s`, step));
    openNotification(`${compoundName} was removed`);
  };

  const handleCompoundAdd = () => {
    dispatch(addCompoundToList(`${type}s`, step));
  };

  return (
    <div className={cn('synjet-compound-list', { 'synjet-compound-list__view-only': viewOnly })}>
      <h3 className="synjet-compound-list__title">{type}</h3>
      {!!compoundList.length && (
        <>
          <div className="synjet-compound-list__header">
            <span className="title">Name</span>
            <span className="title">Solvent</span>
            <span className="title">Concentration (M)</span>
            <span className="title">Limiting</span>
            <div className="equivalent" style={isFixed ? { gridTemplateColumns: '1fr 1fr' } : {}}>
              <span />
              {isFixed ? (
                <span className="title">Ratio: FIXED</span>
              ) : (
                <>
                  <span className="title">Ratio: LOW</span>
                  <span className="title">Ratio: MED</span>
                  <span className="title">Ratio: HIGH</span>
                </>
              )}
            </div>
          </div>
          {compoundList.map((compound, compoundIndex) => (
            <SynJetCompound
              compoundInfo={compound}
              key={compoundIndex + type}
              type={type}
              limitingCompound={limitingCompound}
              letter={compound.name}
              limitingTitle={limitingTitle}
              handleSubmit={compound => handleCompoundChange(compound, compoundIndex)}
              handleDelete={compoundName => handleCompoundDelete(compoundIndex, compoundName)}
              isFixed={isFixed}
              disableLimiting={disableLimiting}
              highlightErrors={highlightErrors}
              viewOnly={viewOnly}
              isLibGen={isLibGen}
              disableSolventTypeChange={!!step}
              isCompoundAddAvailable={isCommonCompoundAddAvailable}
            />
          ))}
        </>
      )}
      {canAddCompound && (
        <Button className="add-btn" onClick={handleCompoundAdd}>
          Add {type} {compoundList?.length + 1}
        </Button>
      )}
    </div>
  );
};

export default SynJetCompoundList;
