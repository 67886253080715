import React, { useEffect } from 'react';
import './index.scss';
import { isEqual } from 'lodash';
import { Divider } from '../../Common';
import { AnalyticalSettings } from '../../AnalyticalSettings';
import { getCompoundDataForAnalytic, getCompoundDataForAnalyticSynjet } from '../../../utils/experimentHelpers';
import { PROJECT_TYPES, SYNJET_PROCESS_TYPES } from '../../../constants';
import { getExperimentsData } from '../../SynJet/SynJetProccess/SynJetExperimentTable/rows';

export const ChooseExperimentAnalytical = ({
  analytical,
  processData,
  reactions,
  handleFormChange,
  processType,
  setDefaultAnalyticalData,
  defaultAnalyticalData,
  errors,
  setErrors,
  isSynjet,
  loading,
  experiment,
  isSynjetPro,
}) => {
  const isMassAvailable = (analytical && analytical.find(analytic => analytic === 'LC-MS')) || isSynjet;

  useEffect(() => {
    if (!isMassAvailable) return;
    let compoundData = [];
    if (isSynjet) {
      const experimentReactions = experiment
        ? getExperimentsData(JSON.parse(experiment.reactionsInfo)).map(row => (row.name - 1).toString())
        : [];
      compoundData = isEqual(
        reactions.map(i => i.toString()),
        experimentReactions
      )
        ? defaultAnalyticalData
        : getCompoundDataForAnalyticSynjet(
            processData,
            reactions,
            processType === SYNJET_PROCESS_TYPES.OPTIMIZATION,
            isSynjetPro
          );
    } else {
      const experimentReactions = experiment
        ? JSON.parse(experiment.reactionsInfo)?.reactions.map(reaction => reaction.index.toString())
        : [];
      const reactionsForAnalytic = processType === PROJECT_TYPES.LIBRARY_GENERATION ? reactions : ['0'];
      compoundData =
        isEqual(
          reactions.map(i => i.toString()),
          experimentReactions
        ) && experiment.analyticalSetting
          ? defaultAnalyticalData
          : getCompoundDataForAnalytic(processData, reactionsForAnalytic);
    }
    setDefaultAnalyticalData(compoundData);
  }, [processData, reactions.length]);

  return (
    <div className="createExperimentModal__analytical">
      {!isSynjetPro && (
        <div className="createExperimentModal__analitycal__row">
          <span className="createExperimentModal__subtitle" style={{ marginRight: 104 }}>
            Analytical method
          </span>
          <span className="createExperimentModal__text">
            {analytical.length ? analytical.join(', ') : isSynjet ? 'Other' : 'None'}
          </span>
        </div>
      )}
      {isMassAvailable ? (
        <AnalyticalSettings
          analyticalData={defaultAnalyticalData}
          setErrors={newErrors => setErrors({ analyticData: newErrors })}
          errors={errors?.analyticData}
          handleAnalyticalDataChange={data => handleFormChange(data, 'analyticalData')}
          loading={loading}
        />
      ) : (
        <>
          <Divider />
          <span className="createExperimentModal__analytical__empty-state">
            For this analytical method no additional settings needed
          </span>
        </>
      )}
    </div>
  );
};
