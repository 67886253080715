import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popup } from '../../../index';
import { SYNJET_COMPOUND_TYPES } from '../../../../constants/synjet';
import { useOutsideAlerter } from '../../../../utils/customHooks';
import SynJetCompoundInfoView from './SynJetCompoundInfoView';
import SynJetCompoundInfoEdit from './SynJetCompoundInfoEdit';
import './style.scss';
import { isEqual } from 'lodash';
import { toCapitalizeChart1 } from '../../../../utils';
import { synjetCompoundValidation } from '../../../../utils/synjetHelpers';
import { NullCompoundCard } from '../../../Common';
import { SOLVENT_TYPES } from '../../../../constants';
import { SynjetProductWrapper } from './SynjetProductWrapper';

const COMPOUND_MODE = {
  VIEW_MODE: 'view',
  EDIT_MODE: 'edit',
};

const SynJetCompound = ({
  mode = 'view',
  letter = 'A',
  compoundInfo,
  handleSubmit,
  limitingTitle,
  type,
  handleDelete = () => {},
  limitingCompound,
  isScreening,
  isLimitingCompoundGroup,
  limitingCompoundGroup,
  viewOnly,
  isFixed,
  disableLimiting,
  highlightErrors,
  isLibGen,
  isProListItem,
  conditions,
  quenchingList,
  isExpectedIntermediate,
  disableConditionDelete,
  disableDelete,
  isCompoundAddAvailable,
  fixedConditions,
  disableSolventTypeChange,
}) => {
  const compoundRef = useRef(null);
  const compoundModeRef = useRef({ isView: true, compound: null, compoundInfo: null });
  const [compoundMode, setCompoundMode] = useState(mode);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [compound, setCompound] = useState(compoundInfo);
  const isViewMode = compoundMode === COMPOUND_MODE.VIEW_MODE;
  const isEditMode = compoundMode === COMPOUND_MODE.EDIT_MODE;

  const isSolid = useMemo(() => compound?.solventType === SOLVENT_TYPES.SOLID, [compound?.solventType]);
  const isQuenching = useMemo(() => type === SYNJET_COMPOUND_TYPES.QUENCHING, [type]);

  const handleCompoundAwayClick = () => {
    if (!compoundModeRef.current.isView) {
      // check if create new smiles modal open
      const createCompoundModal = document.getElementsByClassName('creation-new-compound_content');
      if (createCompoundModal[0]?.offsetHeight) {
        return;
      }
      if (!isEqual(compoundModeRef.current.compoundInfo, compoundModeRef.current.compound)) setDiscardModalOpen(true);
      else {
        setCompoundMode(COMPOUND_MODE.VIEW_MODE);
      }
    }
  };

  useOutsideAlerter(compoundRef, handleCompoundAwayClick);

  const handleCompoundClick = e => {
    if (deleteModalOpen || compoundInfo?.isNullCompound || viewOnly || e.target.className === 'show-hidden') return;
    setCompoundMode(COMPOUND_MODE.EDIT_MODE);
  };

  useEffect(() => {
    compoundModeRef.current.compound = compound;
  }, [compound]);

  useEffect(() => {
    compoundModeRef.current.compoundInfo = compoundInfo;
    setCompound(compoundInfo);
  }, [compoundInfo]);

  useEffect(() => {
    if (isViewMode) setValidationErrors({});
    compoundModeRef.current.isView = isViewMode;
  }, [isViewMode]);

  const handleCompoundSave = e => {
    e.stopPropagation();
    const isValid = synjetCompoundValidation(
      type,
      compound,
      limitingCompound,
      compoundInfo,
      setValidationErrors,
      limitingCompoundGroup,
      isLimitingCompoundGroup,
      isProListItem
    );
    if (!isValid) return;
    setCompoundMode(COMPOUND_MODE.VIEW_MODE);
    handleSubmit(compound);
  };

  const handleCompoundChangesDiscard = e => {
    e.stopPropagation();
    setDiscardModalOpen(false);
    setCompoundMode(COMPOUND_MODE.VIEW_MODE);
    setCompound(compoundInfo);
  };

  const handleCompoundDeleteClick = e => {
    e.stopPropagation();
    setDeleteModalOpen(true);
  };

  const handleCompoundDelete = e => {
    e.stopPropagation();
    setDeleteModalOpen(false);
    handleDelete(`${toCapitalizeChart1(type)} ${letter}`);
  };

  const handleCompoundDeleteCancel = e => {
    e.stopPropagation();
    setDeleteModalOpen(false);
  };

  const handleCompoundCancel = e => {
    e.stopPropagation();
    if (isEqual(compoundInfo, compound)) {
      setCompoundMode(COMPOUND_MODE.VIEW_MODE);
    } else {
      setDiscardModalOpen(true);
    }
  };

  return (
    <div
      ref={compoundRef}
      className={cn(
        'synjet-compound',
        { 'synjet-compound__view-only': viewOnly },
        { 'synjet-compound__edit': isEditMode },
        { 'synjet-compound__limit': (isViewMode || viewOnly) && (compoundInfo?.isLimiting || isLimitingCompoundGroup) }
      )}
      onClick={handleCompoundClick}
    >
      <div className={cn('synjet-compound__block', 'synjet-compound__border')}>
        <span className="synjet-compound__letter">{letter}</span>
      </div>
      <div
        className={cn(
          { 'synjet-compound__info': type !== SYNJET_COMPOUND_TYPES.PRODUCT },
          { 'synjet-compound__info-screening': type !== SYNJET_COMPOUND_TYPES.PRODUCT && isScreening },
          { 'synjet-compound__info-product': type === SYNJET_COMPOUND_TYPES.PRODUCT },
          { 'synjet-compound__info-quenching': isQuenching },
          { 'synjet-compound__border': isEditMode || (isViewMode && type !== SYNJET_COMPOUND_TYPES.PRODUCT) }
        )}
      >
        {type === SYNJET_COMPOUND_TYPES.PRODUCT ? (
          <SynjetProductWrapper
            isProListItem={isProListItem}
            isViewMode={isViewMode}
            compoundInfo={compoundInfo}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            compound={compound}
            setCompound={setCompound}
            conditions={conditions}
            quenchingList={quenchingList}
            isExpectedIntermediate={isExpectedIntermediate}
            disableConditionDelete={disableConditionDelete}
            fixedConditions={fixedConditions}
            limitingCompound={limitingCompound}
            handleSubmit={handleSubmit}
          />
        ) : (
          <>
            {isViewMode && (
              <>
                {compoundInfo.isNullCompound ? (
                  <NullCompoundCard className="synjet-compound__row" />
                ) : (
                  <SynJetCompoundInfoView
                    compoundInfo={compoundInfo}
                    isScreening={isScreening}
                    limitingCompoundGroup={limitingCompoundGroup}
                    isLimitingCompoundGroup={isLimitingCompoundGroup}
                    isFixed={isFixed}
                    disableLimiting={disableLimiting}
                    highlightErrors={highlightErrors}
                    isSolid={isSolid}
                    isQuenching={isQuenching}
                  />
                )}
              </>
            )}
            {isEditMode && (
              <SynJetCompoundInfoEdit
                compound={compound}
                setCompound={setCompound}
                isLimiting={compoundInfo?.isLimiting}
                limitingTitle={limitingTitle}
                validationErrors={validationErrors}
                limitingCompound={limitingCompound}
                isScreening={isScreening}
                limitingCompoundGroup={limitingCompoundGroup}
                isLimitingCompoundGroup={isLimitingCompoundGroup}
                clearValidationErrors={field =>
                  setValidationErrors(errors => ({
                    ...errors,
                    [field]: null,
                  }))
                }
                isFixed={isFixed}
                disableLimiting={disableLimiting}
                isLibGen={isLibGen}
                isSolid={isSolid}
                isQuenching={isQuenching}
                isCompoundAddAvailable={isCompoundAddAvailable}
                isSolidCompoundInfo={compoundInfo.solventType === SOLVENT_TYPES.SOLID}
                disableSolventTypeChange={disableSolventTypeChange}
              />
            )}
          </>
        )}
      </div>
      <div className="synjet-compound__action">
        {isViewMode && type !== SYNJET_COMPOUND_TYPES.PRODUCT && !disableDelete && (
          <Button type="text" className="action-btn" onClick={handleCompoundDeleteClick}>
            <DeleteOutlined />
          </Button>
        )}
        {isEditMode && (
          <>
            <div className="edit-btn-container">
              <Button type="text" className="action-btn" onClick={handleCompoundSave}>
                <CheckOutlined />
              </Button>
            </div>
            <div className="edit-btn-container">
              <Button type="text" className="action-btn" onClick={handleCompoundCancel}>
                <CloseOutlined />
              </Button>
            </div>
          </>
        )}
      </div>
      <Popup
        title="Discard changes"
        open={discardModalOpen}
        handleSubmit={handleCompoundChangesDiscard}
        textSubmit="Confirm"
        handleCancel={() => setDiscardModalOpen(false)}
      >
        {`Discard unsaved changes for ${toCapitalizeChart1(type)} ${letter}?`}
      </Popup>
      <Popup
        title={`Remove ${toCapitalizeChart1(type)} ${letter}`}
        open={deleteModalOpen}
        handleSubmit={handleCompoundDelete}
        textSubmit="Remove"
        handleCancel={handleCompoundDeleteCancel}
      >
        {`Are you sure you want to remove ${toCapitalizeChart1(type)} ${letter}?`}
        {!isScreening ? ' Please note: the related compounds will be removed as well' : null}
      </Popup>
    </div>
  );
};

export default SynJetCompound;
