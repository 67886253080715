import React, { useEffect } from 'react';

import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { INTEGER } from '../../constants/regexp';
import { DatePicker, Input, Tooltip } from '../Common';
import { Bird } from '../Common/Icons';
import CompoundSmilesPreview from '../CompoundSmilesPreview/CompoundSmilesPreview';
import { SmilesSearcher } from '../SmilesSearcher';

const ViewCompound = ({ data }) => (
  <div className="analytical-settings_item_compound">
    <CompoundSmilesPreview
      hideMW
      options={{
        height: 57,
        width: 88,
      }}
      compound={data}
      showEmptyCompound
    />
  </div>
);

export const MassOfInterestItem = ({
  order,
  data,
  previewMode,
  newCompound,
  updateNewCompound,
  dataCreated,
  clearDataCreated,
  confirmAdding,
  updateItem,
  deleteItem,
  itemErrors,
  loading,
  unknownCompound = false,
}) => {
  useEffect(() => {
    if (data?.compound || (!data?.compound && unknownCompound)) {
      const mol = !data.mol || data.mol === '-' ? 0 : +data.mol;
      updateItem && updateItem(mol + data.mass, 'observ', order);
    }
  }, [data?.mol, data?.mass]);

  const handleMassModificationChange = (value, order) => {
    if (INTEGER.test(value) || !value || value === '-') {
      updateItem(value, 'mol', order);
    }
  };

  const handleIntervalChange = (field, value, order) => {
    updateItem(value, field, order);
  };

  const formatter = t => {
    const v = `${t}`.replace(/-/g, '');
    if (v < 0) v = 0;
    if (v > 10 || v.toString().indexOf('10') === 0) return 10;
    if (v.toString().indexOf('.') > 1) return v.toString().substr(0, 2);
    if (v.toString().indexOf('.') > -1 && v.toString().length >= 3)
      return v.toString().substr(0, v.toString().indexOf('.') + 3);
    return v;
  };

  return (
    <div
      className={cn('analytical-settings_item', {
        'analytical-settings_item_new': newCompound,
        'analytical-settings_item_preview': previewMode,
      })}
    >
      {newCompound ? (
        <>
          {dataCreated.compound ? (
            <ViewCompound data={dataCreated.compound} />
          ) : (
            <div className="analytical-settings_item_new_compound">
              <span className="analytical-settings_item_new_compound_label">Side product</span>
              <SmilesSearcher
                synjet
                changeSelect={(smile, setCompound) => {
                  if (setCompound === 'set-compound') updateNewCompound(smile);
                }}
              />
            </div>
          )}
          <div className="analytical-settings_item_new_actions">
            <Bird onClick={confirmAdding} />
            <CloseOutlined onClick={clearDataCreated} />
          </div>
        </>
      ) : (
        <>
          <ViewCompound data={data.compound} />
          <div
            className={cn('analytical-settings_item_type', {
              'analytical-settings_item_type_product': data.type === 'Product',
            })}
          >
            <Tooltip title={data.type}>{data.type}</Tooltip>
          </div>
          <div className="analytical-settings_item_label">
            {previewMode ? (
              <Tooltip title={data.label}>{data.label}</Tooltip>
            ) : (
              <Input
                maxLength="256"
                placeholder="Enter name"
                value={data.label}
                disabled={loading}
                onChange={value => updateItem(value, 'label', order)}
                error={itemErrors?.label}
                errorText={itemErrors?.label}
              />
            )}
          </div>
          <div className={cn('analytical-settings_item_mass', { 'mass-input': !data?.compound })}>
            {!data?.compound && !previewMode ? (
              <Input
                maxLength="10"
                placeholder="0"
                type="number"
                value={data.mass}
                disabled={loading}
                onChange={value => {
                  updateItem(value, 'mass', order);
                }}
                formatter={t => {
                  const v = `${t}`.replace(/-/g, '');
                  if (v < 0) v = 0;
                  return t;
                }}
              />
            ) : (
              <Tooltip title={data.mass}>{data.mass}</Tooltip>
            )}
          </div>
          <div className="analytical-settings_item_mol">
            {previewMode ? (
              <Tooltip title={data.mol}>{data.mol}</Tooltip>
            ) : (
              <Input
                maxLength="10"
                placeholder="0"
                type="number"
                value={data.mol}
                disabled={loading}
                onChange={value => handleMassModificationChange(value, order)}
              />
            )}
          </div>
          <div
            className={cn('analytical-settings_item_observ', {
              'analytical-settings_item_observ-error': itemErrors?.uniqueError,
            })}
          >
            <Tooltip title={data.observ}>{data.observ}</Tooltip>
            {itemErrors?.observ && <p className="text-error">{itemErrors?.observ}</p>}
          </div>
          <div className="analytical-settings_item_start">
            {previewMode ? (
              data.interval_start
            ) : (
              <Input
                type="number"
                placeholder="0.00"
                value={data.interval_start}
                disabled={loading}
                onChange={value => handleIntervalChange('interval_start', value, order)}
                error={itemErrors?.uniqueError || itemErrors?.interval_start}
                errorText={itemErrors?.interval_start}
                formatter={formatter}
              />
            )}
          </div>
          <div className="analytical-settings_item_end">
            {previewMode ? (
              data.interval_end
            ) : (
              <Input
                type="number"
                placeholder="0.00"
                value={data.interval_end}
                disabled={loading}
                onChange={value => handleIntervalChange('interval_end', value, order)}
                error={itemErrors?.uniqueError || itemErrors?.interval_end}
                errorText={itemErrors?.interval_end}
                formatter={formatter}
              />
            )}
          </div>
          {data.type !== 'Product' && !previewMode ? (
            <div className="analytical-settings_item_remove" onClick={() => deleteItem(order)}>
              <DeleteOutlined />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
