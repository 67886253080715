import React, { useEffect, useState } from 'react';

import { REJECTION_REASONS_BATCH } from 'constants/index';
import { useDispatch } from 'react-redux';

import {
  createNote,
  getBatchExperiments,
  rejectBatch,
  rejectExperimentFromBatch,
} from '../../store/experiment/experiment.actions';
import { Input, openNotification, Popup, Select } from '../Common';

const initialErrors = {
  reason: null,
  note: null,
};

const initialData = {
  reason: null,
  note: null,
};

export const RejectExperimentFromBatchPopup = ({
  open,
  closePopup,
  showWarning,
  type,
  batch,
  experiment,
  navigateToFirstBatch,
  getBatchDetails,
  setSideLoader,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState(initialErrors);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const maxLengthInput = 256;

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setConfirmationPopupOpen(false);
      setErrors(initialErrors);
      setData(initialData);
    }
  }, [open]);

  const handleCancel = () => {
    closePopup();
  };

  const decline = async () => {
    setLoading(true);
    try {
      if (type === 'experiment') {
        await dispatch(rejectExperimentFromBatch(batch.uuid, experiment.uuid)).then(resp => {
          if (!!resp.errors && !!resp.errors.length) {
            openNotification(resp.errors[0].messages[0], resp.errors[0].messages[0]);
          } else {
            addRejectionNote(data.note, data.reason, experiment.uuid);
            if (batch?.experiments?.length > 1) {
              const id_exp =
                batch.experiments[0].uuid === experiment.uuid ? batch.experiments[1] : batch.experiments[0];
              getBatchDetails(batch?.batchId, true, id_exp.key, true);
              if (setSideLoader) setSideLoader(true);
              dispatch(getBatchExperiments({ device: batch?.timeSlot?.device.uuid }))
                .then(() => {})
                .finally(() => {
                  if (setSideLoader) setSideLoader(false);
                });
            } else {
              navigateToFirstBatch();
            }
          }
        });
      } else {
        await dispatch(rejectBatch(batch.uuid)).then(resp => {
          if (resp.errors) {
            openNotification(resp.errors[0].messages[0], resp.errors[0].messages[0]);
          } else {
            navigateToFirstBatch();
            openNotification('The Batch was rejected - all related Experiments are moved back into Submitted status');
            batch.experiments.forEach(i => {
              addRejectionNote(data.note, data.reason, i.uuid);
            });
          }
        });
      }
      closePopup();
    } catch (e) {
      openNotification(null, e);
    } finally {
      setLoading(false);
    }
  };

  const addRejectionNote = (note, reason, id) => {
    dispatch(createNote({ body: encodeURIComponent(note), reason, experiment: id }));
  };

  const checkErrors = () => {
    const newErrors = {};
    if (!data.reason) newErrors.reason = 'Please select the rejection reason';
    if (!data.note) newErrors.note = 'Please provide your notes first';
    setErrors({ ...errors, ...newErrors });
    if (Object.keys(newErrors).length > 0) return;
    if (showWarning) setConfirmationPopupOpen(true);
    else decline();
  };

  const changeValue = (value, type) => {
    setData({ ...data, [type]: value });
    setErrors({ ...errors, [type]: null });
  };

  return (
    <>
      <Popup
        title={`Reject ${type}`}
        open={open && !confirmationPopupOpen}
        className="popup-create-process"
        handleCancel={handleCancel}
        textSubmit="Decline"
        handleSubmit={checkErrors}
        loading={loading}
      >
        <div className="decline-experiment-popup_content">
          <Select
            label="Rejection reason"
            onChange={v => changeValue(v, 'reason')}
            options={REJECTION_REASONS_BATCH}
            value={data.reason}
            disabled={loading}
            error={errors.reason}
            errorText={errors.reason}
            placeholder="Please select reason"
            required
          />
          <Input
            allowSpecials
            withoutComma={false}
            maxLength={maxLengthInput}
            autoFocus
            label="Comment"
            placeholder="Leave your comment here"
            disabled={loading}
            value={data.note}
            onChange={v => changeValue(v, 'note')}
            error={errors.note}
            errorText={errors.note}
            type="textarea"
            className="experiment-notes-list_input"
            require
          />
        </div>
      </Popup>
    </>
  );
};
