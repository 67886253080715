import React from 'react';
import { CheckOptionRow } from 'components/Execution/ExperimentFinalization/ExperimentFinalizationChecklist';

export const CheckList = ({ finalizationOptions, setFinalizationOptions }) => {
  return (
    <div className="experiment-finalization-checklist sj-checklist">
      <h5 className="experiment-finalization-checklist__title">Stand by check-list</h5>
      {finalizationOptions.map((option, index) => (
        <div className="experiment-finalization-checklist__half-row">
          <CheckOptionRow
            key={index}
            optionKey={index}
            label={option.label}
            checked={option.checked}
            onChangeOptions={(e, v) => {
              setFinalizationOptions(prev =>
                prev.map((fOption, idx) => {
                  if (idx === e) fOption.checked = v;
                  return fOption;
                })
              );
            }}
          />
        </div>
      ))}
    </div>
  );
};
