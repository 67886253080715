import React, { useEffect, useState } from 'react';
import './style.scss';
import { MenuDetails } from '../../../components/Projects/DetailsProject/MenuDetails';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { GeneralTab } from '../../../components/Projects/DetailsProject/GeneralTab';
import { TargetProductTab } from 'components/Projects/DetailsProject/TargetProductTab/TargetProductTab';
import { TeamTab } from '../../../components/Projects/DetailsProject/TeamTab';
import { ProjectActivitiesTab } from 'components/Projects/DetailsProject/ProjectActivitiesTab/ProjectActivitiesTab';
import { connect } from 'react-redux';
import {
  getDetailsProject,
  clearDetailsProject,
  createProject,
  updateTeamMembers,
  updateManager,
  updateStatusProject,
} from '../../../store/projects/projects.actions';
import { getUserByRoles } from '../../../store/common/common.actions';
import { Spinner } from '../../../components/Common/Spinner';

const DetailsProject = props => {
  const [activeTab, setActiveTab] = useState('general');
  const [loading, setLoading] = useState(false);
  let { url, params } = useRouteMatch();

  useEffect(() => {
    getProjectDetails();
    return () => props.clearDetailsProject();
  }, []);

  const getProjectDetails = async () => {
    setLoading(true);
    try {
      await props.getDetailsProject(params.id);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateTab = tab => {
    setActiveTab(tab);
  };

  const permissions = props.user.permissions?.project || {};
  return (
    <Spinner loading={loading}>
      <div className="details-project">
        {props.details?.uuid && (
          <>
            <MenuDetails
              details={props.details}
              activeTab={activeTab}
              updateTab={updateTab}
              mainPath={url}
              updateStatusProject={props.updateStatusProject}
              permissions={permissions}
            />
            <div className="details-project_right-block">
              <Switch>
                {activeTab === 'general' && <GeneralTab {...props} permissions={permissions} />}
                {activeTab === 'team' && <TeamTab {...props} permissions={permissions} />}
                {activeTab === 'activities' && (
                  <ProjectActivitiesTab
                    {...props}
                    permissions={permissions}
                    project={props.details}
                    getProjectDetails={getProjectDetails}
                    userRoles={props.user.roles}
                  />
                )}
                {activeTab === 'product' && (
                  <TargetProductTab
                    project={props.details}
                    getProjectDetails={getProjectDetails}
                    userRoles={props.user.roles}
                  />
                )}
              </Switch>
            </div>
          </>
        )}
      </div>
    </Spinner>
  );
};

const mapStateToProps = store => {
  return {
    user: store.commonReducer.user,
    details: store.projectReducer.details,
    usersByRoles: store.commonReducer.usersBuRoles,
  };
};
export default connect(mapStateToProps, {
  getDetailsProject,
  clearDetailsProject,
  createProject,
  getUserByRoles,
  updateTeamMembers,
  updateManager,
  updateStatusProject,
})(DetailsProject);
