import React, { useEffect, useState } from 'react';
import { ReactComponent as NotUsedSVG } from '../../../../../dist/images/NotUsedType.svg';
import { ReactComponent as FilledSVG } from '../../../../../dist/images/FilledType.svg';
import { Divider } from '../../../../Common';
import './style.scss';
import LmBalloon from './LmBalloon';

const LmDispensersBalloons = ({ dispenserData, getCompound }) => {
  const getDefaultSize = i => {
    let size = 'large';

    if ((i >= 1 && i <= 4) || (i >= 13 && i <= 16)) {
      size = 'small';
    }

    if ((i >= 5 && i <= 8) || (i >= 17 && i <= 20)) {
      size = 'medium';
    }
    return size;
  };

  const getSize = volume => {
    let size;

    switch (volume) {
      case 3:
        size = 'mini';
        break;
      case 5:
        size = 'small';
        break;
      case 10:
        size = 'medium';
        break;
      case 30:
        size = 'large';
        break;
      default:
        size = 'large';
    }

    return size;
  };

  const getRow = (data, isSS) => {
    const dispensers = data.sort((a, b) => a.number - b.number);
    const startNumber = isSS ? 13 : 1;
    const row = [];

    for (let i = startNumber; i < startNumber + 12; i += 1) {
      let ballonData = {};
      let size = getDefaultSize(i);

      const currentDispenser = dispensers.find(disp => disp.number === i);
      if (currentDispenser) {
        const compound = getCompound(currentDispenser.compound_id || currentDispenser.solvents[0].compound_id);
        size = getSize(currentDispenser.reservoir_volume);

        ballonData = {
          isFilled: true,
          name: compound?.compound_name,
          formula: compound?.formula,
          volume: currentDispenser.reservoir_volume,
          backpack: currentDispenser.backpack,
          fluidBody: currentDispenser.fluid_body,
          calibration: currentDispenser.calibration,
          material: currentDispenser.material,
        };
      }

      row.push(<LmBalloon number={i} size={size} {...ballonData} />);
    }

    return isSS ? row : row.reverse();
  };

  return (
    <div className="lm-balloons">
      <div className="lm-balloons__header">
        <div>
          <NotUsedSVG />
          <span>Not used</span>
        </div>
        <div>
          <FilledSVG />
          <span>Used</span>
        </div>
      </div>
      <div className="lm-balloons__dispensers">
        <span>Dispensers</span>
        <Divider />
        <div className="dispensers-balloons">
          <div className="dispensers-balloons__peek-array">
            <span>PEEK array</span>
            <div className="balloons-range">{getRow(dispenserData.filter(i => i.material === 'PEEK'))}</div>
          </div>
          <div className="dispensers-balloons__ss-array">
            <span>SS array</span>
            <div className="balloons-range">
              {getRow(
                dispenserData.filter(i => i.material === '316SS'),
                true
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LmDispensersBalloons);
