import React from 'react';
import { Spin as AntdSpin } from 'antd';
import './style.scss';
import cn from 'classnames';

export const Spinner = ({ children, className, loading, wrapperClassName, tip = 'Please wait' }) => (
  <AntdSpin
    wrapperClassName={wrapperClassName}
    className={cn('spinner-component', className)}
    size="large"
    spinning={loading}
    tip={tip}
  >
    {children}
  </AntdSpin>
);
