import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import moment from 'moment';
import { Button, Popup, Spinner } from '../Common';
import { generatePdf } from './utils';
import { SynJetProPrepareMaterials } from '../Execution/SynJetProExecution/SynJetProPrepareMaterials';

export const PdfGeneratorSJP = ({ batchInfo, experiments, tableData }) => {
  const [generate, setGenerate] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (generate) {
      generatePdf({
        ref,
        name: `${batchInfo.batchId}_${moment().format('MM-DD-YYYY')}_Experiment_info`,
        callback: () => {
          setGenerate(false);
        },
      });
    }
  }, [generate]);
  const handleGenerate = () => {
    setGenerate(true);
  };

  return (
    <>
      <Button disabled={generate || !batchInfo} className="export-pdf-btn" secondary onClick={handleGenerate}>
        Export to PDF
      </Button>
      <Popup className="pdf-export-popup" open={generate} title="">
        <Spinner
          loading
          tip={
            <span>
              We are generating PDF
              <br /> This can take a few minutes
            </span>
          }
        />
      </Popup>
      {generate && (
        <div ref={ref} className="pdf-generator">
          <SynJetProPrepareMaterials batch={batchInfo} experiments={experiments} tableData={tableData} pdfMode />
        </div>
      )}
    </>
  );
};
