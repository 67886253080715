import './style.scss';

import React from 'react';

import { Input as AntdInput, InputNumber } from 'antd';
import cn from 'classnames';

import { Label } from '../Label/Label';

export const Input = React.forwardRef(
  ({
    label,
    value = '',
    onChange = () => {},
    type = 'text',
    className,
    error = false,
    errorText,
    require = false,
    rows = 4,
    field,
    setRef,
    withoutComma = true,
    allowSpecials,
    regex,
    positive,
    ...rest
  }) => {
    const { Password, TextArea } = AntdInput;
    let el = '';
    const props = {
      value,
      onChange: e => {
        if (type === 'number') {
          onChange(e, field);
          return;
        }
        let newValue = e.target.value;
        if (newValue === '.') newValue = '0.';
        if (withoutComma) newValue = newValue.replace(/,/g, '');
        if (!allowSpecials) {
          onChange(newValue.replace(regex || /[^\w\d\s!-?#$%&*()@.:;|\\]+/g, ''), field);
        } else {
          onChange(newValue, field);
        }
      },
      ref: setRef,
      ...rest,
    };
    if (type === 'text') {
      el = <AntdInput {...props} />;
    } else if (type === 'password') {
      el = <Password {...props} />;
    } else if (type === 'textarea') {
      el = <TextArea rows={rows} {...props} />;
    } else if (type === 'number') {
      el = (
        <InputNumber
          formatter={value => {
            if (value === '.') value = '0.';
            if (positive) value = value.toString().replace('-', '');
            if (props.formatter) return props.formatter(value);
            return props.integer ? value.toString().replace('.', '') : value;
          }}
          {...props}
        />
      );
    }

    return (
      <div className={cn('input-wrapper', className, { 'input-error': !!error, 'sri-label': !!label })}>
        {!!label && <Label requireInput={require}>{label}</Label>}
        {el}
        {!!error && <p className="text-error">{errorText}</p>}
      </div>
    );
  }
);
