import { toCapitalizeChart1 } from './common';

export const validationLength = (value, name, length = 3) => {
  if (value.length < length) return `${name} should have at least ${length} characters`;
  else return '';
};

export const transformString = string => {
  let strAfterReplace = string.replace(/([A-Z]+)/g, ' $1').toLowerCase();
  strAfterReplace = strAfterReplace.charAt(0).toUpperCase() + strAfterReplace.slice(1);
  return strAfterReplace;
};

export const validationIsEmpty = object => {
  let errors = {};
  for (let key in object) {
    errors[key] = !!object[key]
      ? ''
      : key == 'project'
      ? `Please select the project`
      : key == 'name'
      ? `Please enter the process name`
      : `Please enter the ${transformString(key)}`;
  }
  return errors;
};

export const processedFormatErrors = str => {
  if (!str) return '';
  return toCapitalizeChart1(str).replace(/\./g, '');
};
