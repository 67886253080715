import React, { useEffect, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { PROJECT_TYPES, typesReactor } from '../../../constants/common';
import { AddComponentButton } from './AddComponentButton';
import { Formula } from './Formula';
import { Compound } from './Compound';
import { Pump } from './Pump';
import { Popover } from '../../Common';
import OptimizationPopoverContent from '../OptimizationPopover/OptimizationPopover';
import { MEASUREMENT_OPTIONS, EQUIPMENT_TYPES } from '../../../constants';
import ReactorCompoundList from './ReactorCompoundList';
import { PopoverControllable } from '../../SynJet/PopoverControllable/PopoverControllable';
import { ManualLibGenEquipmentOptions } from './ManualLibGenPopover/ManualLibGenEquipmentOptions';

export const Reactor = ({
  data,
  changeReactor,
  openCompoundAddToReactor,
  reactorIdx,
  changeCompound,
  noArrow = false,
  updateReactor,
  variableReactorExists,
  processType,
  realTime = false,
  experiment,
  configCharts,
  manual = false,
  previewMode = false,
}) => {
  const [popoverVisible, setPopoverVisible] = useState('');
  if (!data.name) return null;
  const typeConfig = { ...typesReactor, ...EQUIPMENT_TYPES }[data.type];
  const Icon = typeConfig.icon;
  const packingsLength = data.catalysts?.length || 0;
  const emptyBottomBlock = !data.times.length && !data.temperatures.length;

  const isLiquid = data.type === 'liquid';
  const isGas = data.type === 'gas';

  const openReactorChange = e => {
    if (e.target.classList.contains('workspace-add-button-text') || e.target.classList.contains('workspace-add-button'))
      return;
    changeReactor();
  };

  const onChangeReactorOptions = (options, measurement, deleteIndex, last_iteration) => {
    const newReactorData = {
      ...data,
      [measurement]: options,
    };
    newReactorData.variable = newReactorData.times.length > 1 || newReactorData.temperatures.length > 1;
    updateReactor(newReactorData, reactorIdx, deleteIndex, last_iteration, measurement);
    setPopoverVisible('');
  };

  const manualName = manual && data.type === 'other' ? data.otherType : EQUIPMENT_TYPES[data.type]?.name;
  return (
    <div
      data-pdf-node
      className={cn('reactor-container-wrapper', {
        'no-arrow': !!noArrow,
        working: realTime && data.working,
        'not-working': realTime && !data.working,
        'reactor-container-wrapper-gas': isGas,
      })}
    >
      <div className={cn('reactor-container', [data.type])} onClick={openReactorChange}>
        <div className="reactor-left-block">
          <div className={cn('reactor-left-block-name-block', { 'liquid-name': isLiquid || isGas })}>
            <Icon />
            <div className="reactor-header">
              {!realTime && (
                <div className={cn('reactor-left-block-name-block_type', { 'half-block': !!packingsLength })}>
                  {manual ? manualName : data.title}
                </div>
              )}
              <span className={cn('reactor-left-block-name-block_name', { 'half-block': !!packingsLength })}>
                {isGas ? `GAS, ${data.key}` : isLiquid ? `LIQUID, ${data.key}` : data.key}
              </span>
              {realTime && !isLiquid && !isGas && (
                <PopoverControllable data={data} experiment={experiment} configAllCharts={configCharts}>
                  <div className={cn('reactor-left-block-name-block_details')}>Details</div>
                </PopoverControllable>
              )}
              {!!packingsLength && (
                <div className="reactor-left-block-name-block_packings">
                  {data.catalysts.map(i => i.compound_name).join(', ')}
                </div>
              )}
              {manual && (
                <div className="reactor-left-block-name-block_packings">
                  {(data.atmosphere || data.atmosphereAdditional) &&
                    `${
                      data.atmosphere === 'other' || !data.atmosphere
                        ? data.atmosphereAdditional || ''
                        : data.atmosphere
                    }`}
                  {data.pressure && `, ${data.pressure} psi`}
                </div>
              )}
            </div>
          </div>
          {!isLiquid && !isGas && (
            <div className={cn('reactor-left-block-data-block', { 'big-time-block': !!data.times?.length, realTime })}>
              {!realTime && (
                <div className={cn('reactor-left-block-data-block_item', { empty: emptyBottomBlock })}>
                  {data.times.map((t, idx) => (
                    <Formula
                      name={`${manual ? (data.times.length > 1 ? `${idx + 1}: ` : '') : ''}t${
                        manual ? '' : data.times.length > 1 ? idx + 1 : ''
                      }`}
                      value={t}
                      unit="min"
                      time
                      key={idx}
                      error={t < 0.001}
                      errorValueToDisplay={t}
                      manualReactor
                    />
                  ))}
                  {(data.variable || !variableReactorExists) &&
                    ((!manual && processType === PROJECT_TYPES.OPTIMIZATION) ||
                      (manual && processType !== PROJECT_TYPES.PRODUCTION)) && (
                      <Popover
                        overlayClassName={cn('ant-optimization-popover', {
                          libgen: processType !== PROJECT_TYPES.OPTIMIZATION,
                        })}
                        content={() =>
                          processType === PROJECT_TYPES.OPTIMIZATION ? (
                            <OptimizationPopoverContent
                              options={data.times}
                              measurement={MEASUREMENT_OPTIONS.times.value}
                              reactorData={data}
                              onSave={onChangeReactorOptions}
                              onClose={() => setPopoverVisible('')}
                              isOpen={popoverVisible === MEASUREMENT_OPTIONS.times.value}
                              manual={manual}
                            />
                          ) : (
                            <ManualLibGenEquipmentOptions
                              reactorData={data}
                              onSave={updateReactor}
                              onCancel={() => setPopoverVisible('')}
                              isOpen={popoverVisible}
                            />
                          )
                        }
                        trigger="click"
                        visible={popoverVisible === MEASUREMENT_OPTIONS.times.value}
                        onVisibleChange={visible => setPopoverVisible(visible ? MEASUREMENT_OPTIONS.times.value : '')}
                      >
                        {!previewMode ? (
                          <span
                            className="reactor-left-block-data-block_item_options"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            options
                          </span>
                        ) : null}
                      </Popover>
                    )}
                </div>
              )}
              <div className={cn('reactor-left-block-data-block_item', { empty: emptyBottomBlock })}>
                {!realTime &&
                  (data?.temperatures?.length && data?.temperatures[0] !== null ? (
                    data.temperatures.map((t, idx) => (
                      <Formula
                        name={`T${data.temperatures.length > 1 ? idx + 1 : ''}`}
                        value={manual ? t : t || data.temperatures[0]}
                        unit="℃"
                        key={idx}
                      />
                    ))
                  ) : (
                    <span className="reactor-left-block-data-block_item_no-time">T = N/A</span>
                  ))}
                {realTime && (
                  <>
                    <Formula name="SP" value={data?.setTemperature} unit="℃" />
                    {data?.actualTemperature && <Formula name="PV" value={data?.actualTemperature} unit="℃" />}
                  </>
                )}
                {(data.variable || !variableReactorExists) && processType === PROJECT_TYPES.OPTIMIZATION && (
                  <Popover
                    overlayClassName={cn('ant-optimization-popover', {
                      libgen: processType !== PROJECT_TYPES.OPTIMIZATION,
                    })}
                    content={() => (
                      <OptimizationPopoverContent
                        options={data.temperatures}
                        measurement={MEASUREMENT_OPTIONS.temperatures.value}
                        reactorData={data}
                        onSave={onChangeReactorOptions}
                        onClose={() => setPopoverVisible('')}
                        isOpen={popoverVisible === MEASUREMENT_OPTIONS.temperatures.value}
                        manual={manual}
                      />
                    )}
                    trigger="click"
                    visible={popoverVisible === MEASUREMENT_OPTIONS.temperatures.value}
                    onVisibleChange={visible =>
                      setPopoverVisible(visible ? MEASUREMENT_OPTIONS.temperatures.value : '')
                    }
                  >
                    {!previewMode ? (
                      <span
                        className="reactor-left-block-data-block_item_options"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        options
                      </span>
                    ) : null}
                  </Popover>
                )}
              </div>
            </div>
          )}
        </div>
        {!isLiquid && !isGas && (
          <ReactorCompoundList
            reactorProperties={data.properties}
            changeCompound={changeCompound}
            reactorIdx={reactorIdx}
            openCompoundAddToReactor={openCompoundAddToReactor}
          />
        )}
      </div>
      {isLiquid && (
        <Pump
          liquid
          data={data.pumps[0]}
          changePump={changeReactor}
          experiment={experiment}
          realTime={realTime}
          configCharts={configCharts}
        />
      )}
      {isGas && (
        <Pump
          isGas={isGas}
          data={data.pumps[0]}
          changePump={changeReactor}
          experiment={experiment}
          realTime={realTime}
          configCharts={configCharts}
        />
      )}
    </div>
  );
};
