import './styles.scss';

import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { Menu, Popover, SpaceHorizontal, TextBody12 } from '@atoms';

export const FilterFactoryItem = React.memo(
  ({ className, label, children, predicates, updateFilterPredicate, icon, removeFilter, ...data }) => {
    const { uuid } = data;
    const onRemoveFilter = () => {
      removeFilter(uuid);
    };
    return (
      <div className={cn('filter-factory-item', className)}>
        <TextBody12>{label}</TextBody12>
        <SpaceHorizontal multiplier="x2" />
        <div className="filter-factory-item_item">
          <Popover
            placement="bottomLeft"
            trigger="click"
            content={
              <Menu onClick={updateFilterPredicate(uuid)} items={predicates} selectedKeys={[data.predicate.key]} />
            }
          >
            <div className="filter-factory-item_item_left">{data.predicate.icon}</div>
          </Popover>
          <div className="filter-factory-item_item_center">{children(data)}</div>
          <div className="filter-factory-item_item_right" onClick={onRemoveFilter}>
            <DeleteOutlined />
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => prev.predicate.key === next.predicate.key && JSON.stringify(prev.value) === JSON.stringify(next.value)
);
