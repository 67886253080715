import { useCallback, useEffect, useState } from 'react';

import { useDebounce } from 'hooks';

export const useTableController = ({
  fetchData,
  pageSize = 30,
  filters,
  search,
  makeTestInitialRequest = async () => Promise.resolve(true),
}) => {
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSort, setActiveSort] = useState('name');
  const [error, setError] = useState();
  const [initialEmptyStateCanBePresented, setInitialEmptyStateCanBePresented] = useState(true);

  const invokeInitialRequest = async () => {
    if (!(await makeTestInitialRequest())) {
      return;
    }
    try {
      setPage(1);
      setIsLoading(true);
      const data = await fetchData({
        page: 1,
        pageSize,
        activeSort,
        filters,
        search,
      });
      if (data.results.length && initialEmptyStateCanBePresented) {
        setInitialEmptyStateCanBePresented(false);
      }
      setTableData(data.results);
      setTotalCount(data.totalCount);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useDebounce({ action: invokeInitialRequest, delay: 600, deps: [activeSort, filters, search] });

  const loadMore = useCallback(
    () =>
      fetchData({
        page: page + 1,
        pageSize,
        activeSort,
        filters,
        search,
      }).then(resp => {
        setTableData(prev => [...prev, ...resp.results]);
        setPage(prev => prev + 1);
      }),
    [page, fetchData, activeSort]
  );

  const updateSpecificRowData = (data, uuid) => {
    setTableData(prev => prev.map(rowData => (rowData.uuid === uuid ? data : rowData)));
  };

  const getInitialData = useCallback(() => {
    setIsLoading(true);

    fetchData({
      page: 1,
      pageSize,
      activeSort,
      filters,
    })
      .then(resp => {
        setPage(1);
        setTableData(resp.results);
        setTotalCount(resp.totalCount);
      })
      .finally(() => setIsLoading(false));
  }, [pageSize, activeSort]);

  const onSortClick = sort => setActiveSort(sort);

  return {
    tableData,
    setTableData,
    totalCount,
    loadMore,
    isLoading,
    onSortClick,
    getInitialData,
    activeSort,
    invokeInitialRequest,
    updateSpecificRowData,
    error,
    initialEmptyStateCanBePresented,
  };
};
