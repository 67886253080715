import React from 'react';
import { Modal as AntdModal } from 'antd';
import './style.scss';
import cn from 'classnames';
import { Button } from '..';

export const Popup = ({
  title = '',
  open,
  handleSubmit,
  handleCancel,
  handleThirdButton,
  className,
  textCancle = 'Cancel',
  textSubmit = 'Save',
  textThirdButton = '',
  textFirstButton = '',
  handleFirstButton,
  children,
  loading,
  footerComponent,
  disabledPrimaryButton,
  buttonBackAction,
  notLoadingThirdButton,
  buttonBack = false,
  createExperimentSecondStepSJP,
  ...rest
}) => {
  const footer = [
    <Button type="secondary" onClick={handleCancel} disabled={loading}>
      {textCancle}
    </Button>,
  ];
  if (handleSubmit) {
    footer.push(
      <Button disabled={disabledPrimaryButton} onClick={handleSubmit} loading={loading}>
        {!loading && textSubmit}
      </Button>
    );
  }
  if (handleFirstButton) {
    footer.unshift(
      <Button onClick={handleFirstButton} type="secondary" disabled={loading}>
        {textFirstButton}
      </Button>
    );
  }
  if (buttonBack) {
    footer.unshift(
      <Button type="secondary" disabled={disabledPrimaryButton} onClick={buttonBackAction} loading={loading}>
        {!loading && 'Back'}
      </Button>
    );
  }
  if (handleThirdButton) {
    footer.push(
      <Button
        disabled={notLoadingThirdButton && loading}
        onClick={handleThirdButton}
        loading={notLoadingThirdButton ? false : loading}
      >
        {(!loading || notLoadingThirdButton) && textThirdButton}
      </Button>
    );
  }
  if (createExperimentSecondStepSJP) {
    className = `${className} purificationModal`;
  }
  return (
    <AntdModal
      className={cn('popup-wrapper', className)}
      title={title}
      visible={open}
      centered
      footer={footerComponent || footer}
      {...rest}
    >
      {children}
    </AntdModal>
  );
};
