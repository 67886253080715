import { DEVICES } from 'constants/common';

import { closeNotification, openNotification } from '../../components/Common';
import { http, httpRest } from '../../utils/http';
import { WebsocketService } from '../../utils/service/WebSocketService';
import {
  mutationAbortExperiment,
  mutationCheckList,
  mutationCompleteBatch,
  mutationCompleteExperiment,
  mutationCreateNote,
  mutationCreationRoute,
  mutationDestroyResultAttachment,
  mutationEndBatch,
  mutationEndExperiment,
  mutationExperimentPumpKeys,
  mutationExperimentResult,
  mutationFinalizeBatch,
  mutationFinalizeBatchExperiment,
  mutationFinalizeExperiment,
  mutationFinishMaterialsPreparation,
  mutationGetAvailableAutoSyns,
  mutationInProgressExperiment,
  mutationLaunchExpLab,
  mutationPauseBatch,
  mutationPausedExperiment,
  mutationPrepareExperiment,
  mutationRejectBatch,
  mutationRejectExperiment,
  mutationRejectExperimentFromBatch,
  mutationResumeBatch,
  mutationSaveExtensibleTableGrid,
  mutationSubmitExperiment,
  mutationUpdateExtensibleExperiment,
  mutationUpdateNote,
  mutationUpdatePumpBottle,
  mutationUpdatePumpMaterial,
  mutationUpdateReactorMaterial,
  mutationUpdateSJPExperimentsReactionsInfo,
  queryAnalitycsData,
  queryBatchDetailsMetaData,
  queryCheckList,
  queryConstructor,
  queryExperimentResult,
  queryExperimentResultPurifications,
  queryExperimentsByUuids,
  queryGetBatchExperiments,
  queryGetExperiment,
  queryGetExperiments,
  queryGetExtensibleExperiment,
  queryGetExtensibleExperiments,
  queryGetExtensibleTableFields,
  queryGetExtensibleTableFieldsFull,
  queryGetExtensibleTableFieldsGrid,
  queryGetNotes,
  queryListMaintenence,
  queryLogs,
  queryProcessDeviceStatus,
  queryUnivariateConfig,
  queryUnivariateData,
} from '../graphql';
import { parseProcessDefinition } from '../synjet/synjet.actions';
import * as actionsTypes from './experiment.actionTypes';

const setExperimentsList = payload => ({ type: actionsTypes.SET_EXPERIMENTS_LIST, payload });
const setNotesList = payload => ({ type: actionsTypes.SET_NOTES_LIST, payload });
const setNoteUpdate = payload => ({ type: actionsTypes.SET_NOTE_UPDATE, payload });
const setNoteCreation = payload => ({ type: actionsTypes.SET_NOTE_CREATION, payload });
const setExperimentResult = payload => ({ type: actionsTypes.SET_APPENDED_RESULTS, payload });
const setMaintenances = payload => ({ type: actionsTypes.SET_MAINTENANCE, payload });

export const updateUnivariateData = payload => ({ type: actionsTypes.UPDATE_UNIVARIATE_DATA, payload });

export const getExperiments = (filters, data) => dispatch =>
  http(queryGetExperiments('send', filters, data)).then(resp => {
    dispatch(setExperimentsList(queryGetExperiments('get', resp)));
    return queryGetExperiments('get', resp);
  });

export const getExperimentsBySearch = (filters, data) =>
  http(queryGetExperiments('send', filters, data)).then(resp => queryGetExperiments('get', resp));

export const getListMaintenance = filters => dispatch =>
  http(queryListMaintenence('send', filters)).then(resp =>
    dispatch(setMaintenances(queryListMaintenence('get', resp)))
  );

export const getBatchExperiments = filters => dispatch =>
  http(queryGetBatchExperiments('send', filters)).then(resp => {
    const data = queryGetBatchExperiments('get', resp);
    dispatch(setExperimentsList(data));
    return data;
  });

export const getExperiment = (key, notScheduled) => dispatch =>
  http(queryConstructor(queryGetExperiment('send', key, notScheduled)))
    .then(resp => {
      if (resp.experiment.process?.device === DEVICES.SYNJET || resp.experiment.process?.device === DEVICES.SYNJETPRO) {
        dispatch(parseProcessDefinition(resp.experiment.process.definition, resp.experiment.process.variableStep));
      }
      return queryGetExperiment('get', resp);
    })
    .catch(err => {
      if (err.data) return queryGetExperiment('get', err.data);
    });

export const getNotes = data => dispatch =>
  http(queryGetNotes('send', data)).then(resp => {
    dispatch(setNotesList(queryGetNotes('get', resp)));
    return queryGetNotes('get', resp);
  });

export const updateNote = data => dispatch =>
  http(mutationUpdateNote('send', data)).then(resp => {
    dispatch(setNoteUpdate(mutationUpdateNote('get', resp)));
  });

export const createNote = data => dispatch =>
  http(mutationCreateNote('send', data)).then(resp => {
    dispatch(setNoteCreation(mutationCreateNote('get', resp)));
  });

export const prepareExperiment = id => async dispatch => {
  const { prepareExperiment } = await http(mutationPrepareExperiment(id));
  return prepareExperiment;
};

export const rejectExperiment = (id, data) => async dispatch => {
  const { rejectExperiment } = await http(mutationRejectExperiment(id, data));
  return rejectExperiment;
};

export const updateExperimentPumpKeys = (id, data) => async dispatch => {
  const { updatePumpKey } = await http(mutationExperimentPumpKeys(id, data));
  return updatePumpKey;
};

export const submitExperiment = id => async dispatch => {
  const { submitExperiment } = await http(mutationSubmitExperiment(id));
  return submitExperiment;
};

export const setToStoreDataStepTwo = payload => dispatch => {
  dispatch({ type: actionsTypes.SET_DATA_STEP_TWO, payload });
};

export const updateDataPreparationMaterials = (type, data) => dispatch => {
  let mutation = mutationFinishMaterialsPreparation;
  if (type === 'updateReactorMaterial') mutation = mutationUpdateReactorMaterial;
  else if (type === 'updatePumpBottle') mutation = mutationUpdatePumpBottle;
  else if (type === 'updatePumpMaterial') mutation = mutationUpdatePumpMaterial;
  return http(mutation('send', data)).then(resp => {
    const proccedData = mutation('get', resp);
    if (proccedData.errors) {
      if (Array.isArray(proccedData.errors)) {
        proccedData.errors.forEach(i => i?.messages?.forEach(j => openNotification('', j)));
      }

      throw 'error';
    }
    return proccedData;
  });
};

export const getCheckList = (type, data) => http(queryCheckList('send')).then(resp => queryCheckList('get', resp));

export const launchExpLab = data =>
  http(mutationLaunchExpLab('send', data)).then(resp => mutationLaunchExpLab('get', resp));

export const updateDataCheckList = uuid =>
  http(mutationCheckList('send', uuid)).then(resp => {
    const data = mutationCheckList('get', resp);
    if (data.errors) {
      data.errors.forEach(i => {
        i.messages.forEach(j => {
          openNotification('', j);
        });
      });
      throw 'error';
    }
  });
export const pausedExperiment = data => async dispatch => {
  const { pausedExperiment } = await http(mutationPausedExperiment(data));
  return pausedExperiment;
};
export const inProgressExperiment = data => async dispatch => {
  const { inProgressExperiment } = await http(mutationInProgressExperiment(data));
  return inProgressExperiment;
};
export const endExperiment = data => async dispatch => {
  const inProgressExperiment = await http(mutationEndExperiment(data)).then(resp => resp.endExperiment);
  return inProgressExperiment;
};

export const finalizeExperiment = expId => async dispatch => {
  const finalizeExperiment = await http(mutationFinalizeExperiment(expId)).then(resp => resp.finalizeExperiment);
  return finalizeExperiment;
};

export const abortExperiment = expId => async dispatch => {
  const abortExperiment = await http(mutationAbortExperiment(expId)).then(resp => resp.abortExperiment);
  return abortExperiment;
};

export const uploadFile = data =>
  httpRest({ url: 'file/experiment/attachments/', data, method: 'post' })
    .then(resp => resp)
    .catch(err => err);

export const getExperimentResultPurifications = () =>
  http(queryExperimentResultPurifications('send')).then(resp => queryExperimentResultPurifications('get', resp));

export const saveExperimentResult = (data, isSynGet) =>
  http(mutationExperimentResult('send', data, isSynGet)).then(resp => mutationExperimentResult('get', resp));

export const completeExperiment = expId => async dispatch => {
  const { completeExperiment } = await http(mutationCompleteExperiment(expId));
  return completeExperiment;
};

export const setRouteStructureFromExp = data => http(mutationCreationRoute('send', data));

export const getAppendedResult = id => dispatch =>
  http(queryExperimentResult('send', id)).then(resp => {
    dispatch(setExperimentResult(queryExperimentResult('get', resp)));
    return queryExperimentResult('get', resp);
  });

export const destroyResultAttachment = uuid =>
  http(mutationDestroyResultAttachment('send', { uuid }))
    .then(resp => mutationDestroyResultAttachment('get', resp))
    .catch(err => err);

export const getConfigurationUnivariateCharts = ({ uuid }) =>
  http(queryUnivariateConfig('send', uuid)).then(resp => queryUnivariateConfig('get', resp));

export const getDataUnivariateCharts = ({ uuid, sensorIds, controller }) =>
  http(queryUnivariateData('send', { uuid, sensorIds }), controller).then(resp => {
    const processData = queryUnivariateData('get', resp);
    return processData;
  });

export const getAnalyticsChartsData = ({ uuid, sensorIds }) =>
  http(queryAnalitycsData('send', { uuid, sensorIds })).then(resp => queryAnalitycsData('get', resp));

export const getLogs = uuid => http(queryLogs('send', uuid)).then(resp => queryLogs('get', resp));

const setDeviceOnline = ({ pumps, device, reactors }) => ({
  type: actionsTypes.SET_DEVICE_ONLINE,
  pumps,
  device,
  reactors,
});

export const getProcessDeviceStatus = (deviceId, deviceName) => dispatch =>
  http(queryProcessDeviceStatus(deviceId, deviceName)).then(resp => {
    if (resp.error) {
      dispatch(
        setDeviceOnline({
          device: false,
          pumps: [],
          reactors: [],
        })
      );
      throw resp.error;
    }
    const data = {
      device: resp.device.online,
      pumps: resp.allPumps.results,
      reactors: resp.allReactors,
      connection: resp.localRemoteConnection,
    };
    dispatch(setDeviceOnline(data));
    dispatch(setConnectivity(resp.localRemoteConnection));
    return data;
  });

export const setConnectivity = connected => ({
  type: actionsTypes.SET_CONNECTIVITY,
  connected,
});

let connectivitySubject;

export const subscribeToConnectivityChange = (deviceName, setDisableButtons) => dispatch => {
  connectivitySubject = new WebsocketService(`/experiment-service/${deviceName}/state/`);
  connectivitySubject.subscribe(msg => {
    if (msg.type === 'experiment_service_state') {
      dispatch(setConnectivity(msg.online));
      if (!msg.online) {
        if (setDisableButtons) {
          setDisableButtons(true);
        }
        openNotification(
          null,
          'Hardware connectivity issue occurred, please wait until connection is restored',
          0,
          'experiment_service_state'
        );
      } else {
        if (setDisableButtons) {
          setDisableButtons(false);
        }
        closeNotification('experiment_service_state');
      }
    }
  });
  return connectivitySubject;
};

export const unsubscribeFromConnectivityChange = () => () => {
  if (connectivitySubject) {
    connectivitySubject.unsubscribe();
  }
};

export const rejectExperimentFromBatch = (batchId, experimentId) => async dispatch => {
  const { batchExperimentReject, ...others } = await http(mutationRejectExperimentFromBatch(batchId, experimentId));
  console.log(batchExperimentReject);
  console.log('others', others);
  return batchExperimentReject;
};

export const rejectBatch = batchId => async dispatch => {
  const { batchReject } = await http(mutationRejectBatch(batchId));
  return batchReject;
};

export const pauseBatch = data => async dispatch =>
  http(mutationPauseBatch('send', data)).then(resp => mutationPauseBatch('get', resp));
export const resumeBatch = data => async dispatch =>
  http(mutationResumeBatch('send', data)).then(resp => mutationResumeBatch('get', resp));
export const endBatch = data => async dispatch =>
  http(mutationEndBatch('send', data)).then(resp => mutationEndBatch('get', resp));

export const finalizeBatch = data => async dispatch =>
  http(mutationFinalizeBatch('send', data)).then(resp => mutationFinalizeBatch('get', resp));

export const completeBatch = data => dispatch =>
  http(mutationCompleteBatch('send', data)).then(resp => mutationCompleteBatch('get', resp));

export const finalizeExperimentOnBatch = data => dispatch =>
  http(mutationFinalizeBatchExperiment('send', data)).then(resp => mutationFinalizeBatchExperiment('get', resp));

export const getAvailableAutoSyns = data => dispatch =>
  http(mutationGetAvailableAutoSyns('send', data)).then(resp => mutationGetAvailableAutoSyns('get', resp));

export const updateSJPExperimentsReactionsInfo = data => dispatch =>
  http(mutationUpdateSJPExperimentsReactionsInfo('send', data)).then(resp =>
    mutationUpdateSJPExperimentsReactionsInfo('get', resp)
  );

export const getBatchMetaData = data => async dispatch =>
  http(queryBatchDetailsMetaData('send', data)).then(resp => {
    const response = queryBatchDetailsMetaData('get', resp);
    return response;
  });

export const getExtensibleTableFields = data =>
  http(queryGetExtensibleTableFields('send', data)).then(resp => {
    try {
      const response = queryGetExtensibleTableFields('get', resp);
      return response;
    } catch (e) {
      openNotification(null, "You don't have permissions to perform this action");
      throw e;
    }
  });

export const getExtensibleExperiments = data =>
  http(queryGetExtensibleExperiments('send', data)).then(resp => {
    try {
      const response = queryGetExtensibleExperiments('get', resp);
      return response;
    } catch (e) {
      openNotification(null, "You don't have permissions to perform this action");
      throw e;
    }
  });

export const getExtensibleTableFieldsFull = data =>
  http(queryGetExtensibleTableFieldsFull('send', data)).then(resp => {
    const response = queryGetExtensibleTableFieldsFull('get', resp);
    return response;
  });

export const getExtensibleTableFieldsGrid = async data => {
  const resp = await http(queryGetExtensibleTableFieldsGrid('send', data || {}));
  return queryGetExtensibleTableFieldsGrid('get', resp);
};

export const updateExtensibleTableGrid = data =>
  http(mutationSaveExtensibleTableGrid('send', data)).then(resp => {
    const response = mutationSaveExtensibleTableGrid('get', resp);
    return response;
  });

export const getExtensibleExperiment = data =>
  http(queryGetExtensibleExperiment('send', data || {})).then(resp => {
    const response = queryGetExtensibleExperiment('get', resp);
    return response;
  });

export const updateExtensibleExperiment = (data, uuid) =>
  http(mutationUpdateExtensibleExperiment('send', data, uuid)).then(resp => {
    const response = mutationUpdateExtensibleExperiment('get', resp);
    if (response.errors) {
      throw response.errors;
    }
    return getExtensibleExperiment({ uuid });
  });

export const getExperimentsByUuids = usersUuids =>
  http(queryExperimentsByUuids('send', null, usersUuids)).then(resp => queryExperimentsByUuids('get', resp));
