import React from 'react';
import './styles.scss';

export const ResultingRouteTitle = ({ title }) => (
  <div className="resulting-route-title">
    <span>Resulting route(s):</span>
    &nbsp;
    <span>{title}</span>
  </div>
);
