import { Tabs } from '../../Common/Tabs';
import { LogsTable } from './LogsTable';
import { ExperimentProcess } from './ExperimentProcess';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { UnivariateTab } from '../UnivariateTab';
import { Analytics } from '../Analytics';
import { parseLogs } from '../../../utils';
import { WebsocketService } from '../../../utils/service/WebSocketService';

const OPTIONS = [
  { value: '2', label: 'Log' },
  { value: '3', label: 'Univariate' },
];

export const ExperimentSummary = ({
  experiment,
  allReactors,
  estimatedTime,
  getExperimentData = () => {},
  showProgress = true,
  viewMode,
  idContainerTab,
}) => {
  const [activeTab, setActiveTab] = useState('3');
  const [subject, setSubject] = useState(null);
  const [forceUpdater, setForceUpdate] = useState(false);
  const [isExperiment, setIsExperiment] = useState(false);

  useEffect(() => {
    if (experiment?.uuid && !viewMode) {
      if (subject) subject.unsubscribe();
      const newSubject = new WebsocketService(`/experiment-execution/${experiment?.uuid}/`);
      setSubject(newSubject);
    }
    if (experiment?.uuid) {
      setForceUpdate(true);
    }
    setIsExperiment(true);
    return () => {
      if (subject) subject.unsubscribe();
      setSubject(null);
    };
  }, [experiment?.uuid]);

  useEffect(() => {
    if (forceUpdater) setForceUpdate(false);
  }, [forceUpdater]);

  const fetchExperiment = message => {
    if (message.experiment_status === 'Pending Finalization') {
      getExperimentData();
    }
  };
  useEffect(() => {
    if (subject) subject.subscribe(fetchExperiment);
  }, [subject]);

  const tabOptions = [...OPTIONS];
  if (experiment?.analyticalSetting) {
    tabOptions.unshift({ value: '1', label: 'Analytics' });
  }
  if (experiment?.pumpKeys && showProgress) {
    tabOptions.push({ value: '4', label: 'Process' });
  }
  return (
    <div className="experiment-progress__body">
      <Tabs options={tabOptions} activeKey={activeTab} onChange={setActiveTab}>
        {!forceUpdater && (
          <div className="experiment-progress__body__content">
            {activeTab === '1' && <Analytics viewMode={viewMode} experiment={experiment} />}
            {activeTab === '2' && <LogsTable experiment={experiment} />}
            {activeTab === '3' && isExperiment && (
              <UnivariateTab idContainerTab={idContainerTab} viewMode={viewMode} experiment={experiment} />
            )}
            {activeTab === '4' && (
              <ExperimentProcess
                experiment={experiment}
                data={experiment?.process?.definition}
                allReactors={allReactors}
                processType={experiment?.process?.type}
                systemName={experiment?.timeSlot?.device?.name}
              />
            )}
          </div>
        )}
      </Tabs>
    </div>
  );
};
