import React, { useEffect } from 'react';
import './style.scss';
import cn from 'classnames';
import { Collapse } from 'components/Common';

export const ManualDescription = ({ uuid, description, setDescription, editMode }) => (
  <Collapse
    items={[
      {
        title: 'Description',

        key: 1,
        body: (
          <div className="textarea-padding-block">
            {editMode ? (
              <textarea
                value={description}
                className={cn('manual-description__textarea')}
                onChange={e => setDescription(e.target.value)}
                placeholder={editMode ? 'Type your description here...' : ''}
                spellCheck="false"
              />
            ) : (
              <div className="read-only manual-description__textarea">{description}</div>
            )}
          </div>
        ),
      },
    ]}
  />
);
