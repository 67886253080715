import './styles.scss';

import React from 'react';

import cn from 'classnames';

import { BaseSideBar } from '@atoms';
import { HeaderAction, SCROLL_BOX_SIZES, ScrollBox } from '@molecules';

export const SideBar = ({ className, visible, title, header, body, actions, subHeader, onClose }) => (
  <BaseSideBar className={cn('side-bar', className)} visible={visible}>
    <ScrollBox
      size={SCROLL_BOX_SIZES.huge}
      body={body}
      subHeader={subHeader}
      header={header || <HeaderAction label={title} onClose={onClose} />}
      actions={actions}
    />
  </BaseSideBar>
);
