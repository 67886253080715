import './ColumnsSortableSelectAll.styles.scss';

import React from 'react';

import { TextBody12 } from '@atoms';

import { Checkbox } from '../../../../components';

export const ColumnsSortableSelectAll = ({ onSelectAll, isAllSelected }) => (
  <div className="columns-sortable-select-all">
    <Checkbox onChange={onSelectAll} value={isAllSelected}>
      <TextBody12>Column</TextBody12>
    </Checkbox>
  </div>
);
