import React from 'react';
import { Table } from '../../../../Common';
import './style.scss';
import { SmilesElem } from '../../../PrepareMaterials/SmilesElem';
import cn from 'classnames';
import { PageContainer } from '../../../../PdfGenerator/PageContainer';

export const SolidsSummaryTable = ({ tableData, experiments, pdfMode = false }) => {
  const getCompound = compoundId => {
    let compound = null;
    const func = (j, id) => {
      if (j.reactionsInfo) {
        const compoundData = JSON.parse(j.reactionsInfo).compounds.find(k => k.compound_id === id);
        if (compoundData) {
          compound = compoundData;
          return true;
        }
      }

      return false;
    };

    experiments.find(a => func(a, compoundId));
    return compound;
  };

  const columns = [
    {
      title: 'Structure',
      dataIndex: 'structure',
      width: '0',
      render: (value, row, index) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (row?.allData?.is_normalized) {
          const data = row.solvent?.value[0]?.compound;
          obj.children = (
            <div className="syn-jet-materials_table_solvent-norm">
              <span>{data.compound_name || data.formula}</span>
              <span>(Solvent)</span>
            </div>
          );
        } else if (!row?.allData?.is_normalized && row.structure.value) {
          obj.children = (
            <SmilesElem id={row.uuid} data={row.structure.value} displayLoupe isNeededTitle={false} pdfMode={pdfMode} />
          );
        }
        return obj;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '0',
      render: (value, row) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        obj.children = (
          <div className="syn-jet-materials_table_solvent-norm">
            <span>{value.name}</span>
            <span>{value.formula}</span>
          </div>
        );

        return obj;
      },
    },
    {
      title: 'Total amount needed for batch, mg',
      dataIndex: 'totalAmount',
      width: '0',
      render: (value, row) => {
        const obj = {
          children: <div className={cn('syn-jet-materials_table_total-amount', row.className)}>{value.toFixed(2)}</div>,
        };

        return obj;
      },
    },
  ];

  const getTotalAmount = vials => vials.reduce((totalAmount, item) => totalAmount + item.calculated_mass, 0);

  const setRowSolutionTable = dispenser => {
    const compound = getCompound(dispenser.compound_id);

    return {
      allData: dispenser,
      uuid: dispenser.uuid,
      structure: {
        value: compound,
      },
      name: {
        name: compound?.compound_name,
        formula: compound?.formula,
      },
      totalAmount: getTotalAmount(dispenser.vials),
    };
  };

  const setSolidsSummaryTableData = data => (data ? data.map(i => setRowSolutionTable(i)) : []);

  return (
    <Table
      className="solids-table"
      rowClassName="syn-jet-materials_table_row"
      columns={columns}
      dataSource={setSolidsSummaryTableData(tableData)}
      sidePadding={false}
    />
  );
};
