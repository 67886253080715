import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import List from './List/List';
import DetailsProject from './Details/DetailsProject';

export const Projects = props => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route path={`${path}/details/:id`} component={DetailsProject} />
    </Switch>
  );
};
