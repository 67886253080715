import React, { useEffect, useState } from 'react';
import { ProcessDetails } from '../../components/ProcessBuilder/ProcessDetails/ProcessDetails';
import { Button, openNotification, closeNotification, Popup, Spinner } from '../../components/Common';
import { WorkSpace } from '../../components/ProcessBuilder/WorkSpace/WorkSpace';
import './style.scss';
import SidebarReactors from '../../components/ProcessBuilder/SidebarReactors/SidebarReactors';
import SidebarPump from '../../components/ProcessBuilder/SidebarPomps/SidebarPumps';
import { getProcess, setProcess, updateProcess } from '../../store/autosyn/autosyn.actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { parseProcessBuilderData, createDataToSaveProcess } from '../../utils';
import { getDetailsUser, setEditMode } from '../../store/common/common.actions';
import {
  getReactorsTypes,
  updateProcessDefinition,
  updateTheLockState,
  setValidationErrors,
  setValidationWarnings,
} from '../../store/processBuilder/processbuilder.actions';
import { CreateExperimentModal } from 'components';
import { SidebarPumpCompound } from '../../components/ProcessBuilder/SidebarPumpCompound/SidebarPumpCompound';
import { ViewInitialRoute } from '../../components/ProcessBuilder/ViewInitialRoute';
import history from '../../utils/history';
import { SidebarReactorCompound } from '../../components/ProcessBuilder/SidebarReactorCompound/SidebarReactorCompound';
import cn from 'classnames';
import { OnBeforeLeave } from '../../components/OnBeforeLeave';
import { errors, reactors, warnings } from '../../store/processBuilder/processbuilder.selector';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { editModeSelector } from '../../store/common/common.selector';
import { checkIfMyLock } from '../../utils/checkIfMyLock';
import { LockLabel } from '../../components/ProcessBuilder/LockLabel/LockLabel';
import { COMPOUND_VARIATY, PROCESS_BUILDER_ERROR, PROCESS_TYPES, PROJECT_TYPES } from '../../constants';
import {
  calculatePumpCompounds,
  recalculatePumpOnLimitedCompound,
  getUpdatedPumpCompound,
} from './ProcessBuilderHelpers';
import { setModalOpen } from '../../store/experimentCreation/experimentCreation.action';
import { CreatorProcessFromRoute } from '../../components/AutoSin/CreatorProcessFromRoute';

const emptyPumpProperty = {
  reactants: [],
  reactants_consentration: [],
  reagents: [],
  reagents_consentration: [],
  solvents: [],
  solvents_fraction: [],
};
const emptyReactorProp = {
  products: [],
  sideproducts: [],
};

const ProcessBuilder = props => {
  const [openSidebar, setOpenSidebar] = useState(false); //sidebar reactor
  const [loadingButton, setLoadingButton] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openValidatePopup, setOpenValidatePopup] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [showInitialRoute, setShowInitialRoute] = useState(false);
  const [positionToAddReactor, setPostitionToAddReactor] = useState({
    position: 0,
    length: 0,
    pumpPosition: 0,
    reactorCompoundPosition: 0,
    compoundPosition: 0,
  });
  const [editingData, setEditingData] = useState(null);
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [openSidebarPump, setSidebarPump] = useState(false);
  const [openSidebarCompoundPump, setSidebarCompoundPump] = useState(false);
  const [sidebarCompoundPumpIndex, setSidebarCompoundPumpIndex] = useState(null);
  const [openSidebarCompoundReactor, setSidebarCompoundReactor] = useState(false);
  const [validatedConfirmationOpen, setValidatedConfirmationOpen] = useState(false);
  const [variationPump, setVariationPump] = useState(null);
  const [variationPumpReactorIndex, setVariationPumpReactorIndex] = useState(null);
  const [variationPumpReactors, setVariationPumpReactors] = useState(null);
  const [compoundPosition, setCompoundPosition] = useState(-1);
  const [positionToDelete, setPositionToDelete] = useState(-1);
  const [pumpToDeleteCompound, setPumpToDeleteCompound] = useState(null);
  const [isVariablePump, setIsVariablePump] = useState(false);
  const [createExperimentErrorPopup, setCreateExperimentErrorPopup] = useState(false);
  const [calcTime, setCalcTime] = useState([]);

  const validationErrors = useSelector(errors);
  const validationWarnings = useSelector(warnings);
  const [count, _setCount] = useState(0);
  const [allErrorsCount, _setAllErrorsCount] = useState(0);
  const [allWarningsCount, _setAllWarningsCount] = useState(0);
  const [index, setIndex] = useState(1);
  const [showResultingRoute, setShowResultingRoute] = useState(false);
  const [pumpIdxForCompound, setPumpIndexForCompound] = useState(0);
  const [processPermissionsError, setProcessPermissionsError] = useState(false);
  const [becameVariable, setBecameVariable] = useState(null);

  let { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const counterRef = React.useRef(count);
  const setCount = data => {
    counterRef.current = data;
    _setCount(data);
  };

  const allErrorsCountRef = React.useRef(allErrorsCount);
  const setAllErrorsCount = data => {
    allErrorsCountRef.current = data;
    _setAllErrorsCount(data);
  };

  const allWarningsCountRef = React.useRef(allWarningsCount);
  const setAllWarningsCount = data => {
    allWarningsCountRef.current = data;
    _setAllWarningsCount(data);
  };

  const closePopupResultingRoute = () => {
    setShowResultingRoute(false);
  };

  useEffect(() => {
    if (!openSidebarCompoundPump) setSidebarCompoundPumpIndex(null);
  }, [openSidebarCompoundPump]);

  useEffect(() => {
    if (!openSidebarCompoundReactor) setSidebarCompoundPumpIndex(null);
  }, [openSidebarCompoundReactor]);

  useEffect(() => {
    setLoading(true);
    props.getDetailsUser();
    props.getProcess(id).finally(data => {
      setLoading(false);
      if (location?.state?.showInitial) {
        setShowInitialRoute(true);
        history.replace(location.pathname);
      }
    });
    getReactorsTypes();
    return () => {
      if (allErrorsCountRef.current > 0 || allWarningsCountRef.current > 0) {
        for (let i = 0; i < allErrorsCountRef.current; i++) {
          closeNotification('queue-notification-' + i);
        }
        for (let i = 0; i < allWarningsCountRef.current; i++) {
          closeNotification('warning-notification-' + i);
        }
        dispatch(setValidationErrors([]));
        dispatch(setValidationWarnings([]));
      }
      props.setProcess(undefined);
      if (validationErrors.length) {
        validationErrors.forEach((error, index) => {
          closeNotification('queue-notification-' + index);
        });
      }
    };
  }, []);

  const [isInitial, setIsInitial] = useState(true);
  useEffect(() => {
    const process = props.process;
    setHeaderData(process);
    if (process?.error) {
      openNotification(null, PROCESS_BUILDER_ERROR);
      setProcessPermissionsError(true);
    } else {
      if (process?.process && isInitial) {
        setIsInitial(false);
        if (process?.process?.definition) {
          if (props.process?.process?.processLock?.isMyLock) {
            props.setEditMode(true);
          } else {
            props.setEditMode(false);
          }
        } else {
          if (process?.process && !process.process?.processLock?.createdBy) {
            lockFunction('locked');
            props.setEditMode(true);
          }
        }
        setProcessPermissionsError(false);
      }
    }
  }, [props.process]);

  useEffect(() => {
    if (validationErrors?.length) {
      setCount(validationErrors.length);
      setAllErrorsCount(validationErrors.length);
      validationErrors.forEach((err, idx) => {
        openNotification('', err, null, 'queue-notification-' + idx, updateCounter);
      });
    }
  }, [validationErrors]);

  useEffect(() => {
    if (validationWarnings?.length) {
      setAllWarningsCount(validationWarnings.length);
      validationWarnings.forEach((warning, idx) => {
        openNotification('', '', null, 'warning-notification-' + idx, null, warning);
      });
    }
  }, [validationWarnings]);

  const updateCounter = () => {
    setCount(counterRef.current - 1);
  };

  useEffect(() => {
    if (props.process && props.reactors?.allReactors) {
      if (props.process.process?.definition) {
        const processDefinition = JSON.parse(props.process.process?.definition);
        const builderData = parseProcessBuilderData(processDefinition, props.reactors, props.process.process.type);
        setInitialData(builderData);
        setData(builderData);
        setStepData(processDefinition.process_steps);
      }
    }
  }, [props?.process?.process?.definition, props.reactors]);

  useEffect(() => {
    if (props.process?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION) {
      let variationPump = null;
      let variationPumpIndex = null;
      data.forEach((reactor, reactorIndex) => {
        const reactorPump = reactor.pumps.find(pump => pump.properties.length > 1);
        if (reactorPump) {
          variationPump = reactorPump;
          variationPumpIndex = reactorIndex;
        }
      });
      setVariationPump(variationPump);
      setVariationPumpReactorIndex(variationPumpIndex);
    }
  }, [data]);

  useEffect(() => {
    let newData = [...data];
    if (variationPump) {
      newData = newData.map((reactor, index) =>
        reactor.properties &&
        index >= variationPumpReactorIndex &&
        reactor.properties.length !== variationPump.properties.length
          ? { ...reactor, properties: [...reactor.properties, emptyReactorProp] }
          : reactor
      );
    } else {
      newData = newData.map((reactor, index) =>
        reactor.properties ? { ...reactor, properties: reactor.properties.slice(0, 1) } : reactor
      );
    }
    setData(newData);
  }, [variationPump, variationPumpReactorIndex]);

  const addReactor = (position, length, reactor, editMode, variableReactorTimes, deleteIndex, last_iteration) => {
    let isUnderPump = false;
    let newData = [...data];
    let reactorProperties = reactor.properties;
    if (!editMode) {
      if (variationPump && position > variationPumpReactorIndex) {
        reactorProperties = new Array(variationPump.properties.length).fill(null).map(() => emptyReactorProp);
      }
      if (!!newData[position - 1] && !newData[position - 1].name) {
        newData[position - 1] = {
          ...reactor,
          properties: reactorProperties,
          pumps: [...reactor.pumps, ...data[position - 1].pumps],
        };
        isUnderPump = true;
      } else newData.splice(position, 0, { ...reactor, properties: reactorProperties });
      setOpenSidebar(false);
      openNotification('Reactor has been added to the process.');
      setPostitionToAddReactor({ position: 0, length: 0 });
      setEditingData(null);
    } else newData[position] = reactor;
    if (reactor.type !== 'liquid' && reactor.type !== 'gas') {
      if (
        editMode
          ? editingData?.times?.find((t, i) => reactor?.times[i] !== t) ||
            editingData?.times?.length !== reactor?.times?.length
          : calcTime.find((t, i) => reactor?.times[i] !== t) !== undefined
      ) {
        if (props.process?.process?.type === 'Optimization') {
          if (!isUnderPump) {
            newData = recalculateProcessOnOptimization(
              reactor,
              isUnderPump ? position - 1 : position,
              newData,
              reactor.variable ? reactor.times : getVariableReactorTimes(newData),
              deleteIndex,
              last_iteration,
              false
            );
          }
        } else {
          const posToRecalculate = isUnderPump ? position - 1 : position;
          newData = recalculateProcessByChangeResidenceTime(newData, getVariableReactorTimes(), posToRecalculate, true);
        }
      }
    }
    if (editingData) {
      setEditingData(reactor);
      openNotification('Reactor is successfully updated');
    }
    if (reactor.type === 'liquid') {
      newData = recalculateResidenceTimeBelow(newData);
    }

    newData = recalculateAllGasReactors(newData);
    setCalcTime([]);
    recalculatePumpIndexes(newData);
  };

  const recalculateAllGasReactors = newData => {
    return newData.map((reactor, idx) => {
      if (reactor.type === 'gas') {
        reactor.pumps[0].flow_rate = getFlowRateForGasPump(idx, newData);
      }
      return reactor;
    });
  };

  const setGenerationData = generationData => {
    const newData = recalculateProcessByChangeResidenceTime(
      generationData,
      getVariableReactorTimes(),
      generationData.length - 1,
      true
    );
    setCalcTime([]);
    recalculatePumpIndexes(newData);
  };
  const setPumpIdx = position => {
    let pumpIdx = 0;
    data.map((reactor, index) => {
      if (index <= positionToAddReactor.position) {
        pumpIdx += reactor.pumps.length;
      }
    });
    setIndex(pumpIdx + 1);
  };

  const setPumpIdxForComp = (position, newPositions, isLiquid) => {
    let pumpIdx = position;
    data.map((reactor, index) => {
      if (index < newPositions.position) {
        pumpIdx += reactor.pumps.length;
      }
    });
    setPumpIndexForCompound(isLiquid ? pumpIdx : pumpIdx + 1);
  };

  const setPumpIdxForPump = (position, newPositions, open, isLiquid) => {
    let pumpIdx = open ? (data[position] ? data[position].pumps.length : 0) : newPositions.pumpPosition;
    data.map((reactor, index) => {
      if (index < newPositions.position) {
        pumpIdx += reactor.pumps.length;
      }
    });
    setPumpIndexForCompound(isLiquid ? pumpIdx : pumpIdx + 1);
  };

  const addPump = (idx, length, pumpData) => {
    let newData = [...data];

    if (!positionToAddReactor.emptyReactor) {
      newData = newData.map((item, index) => {
        if (index === idx)
          return {
            ...item,
            pumps: editingData
              ? item.pumps.map((i, pumpIndex) => {
                  if (pumpIndex === positionToAddReactor.pumpPosition) {
                    if (
                      (newData[positionToAddReactor.position].pumps[positionToAddReactor.pumpPosition].type[0] ===
                        'MFC' &&
                        pumpData.type[0] !== 'MFC') ||
                      (newData[positionToAddReactor.position].pumps[positionToAddReactor.pumpPosition].type[0] !==
                        'MFC' &&
                        pumpData.type[0] === 'MFC')
                    ) {
                      let u = {
                        ...pumpData,
                        properties: i.properties.map((property, index) => {
                          return {
                            ...property,
                            reagents: [],
                            reactants: [],
                            solvents: [],
                          };
                        }),
                      };
                      return u;
                    } else {
                      return pumpData;
                    }
                  }
                  return i;
                })
              : [...item.pumps, { name: 'Pump 1', ...pumpData }],
          };
        return item;
      });
    } else {
      if (data[positionToAddReactor.position] && !data[positionToAddReactor.position]?.name) {
        newData = newData.map((i, index) => {
          if (index === positionToAddReactor.position) {
            return {
              ...i,
              pumps: [i.pumps, ...pumpData],
            };
          }
          return i;
        });
      } else {
        newData.splice(positionToAddReactor.position, 0, { pumps: [pumpData] });
      }
    }
    const changeFromMFCToAnother = editingData && editingData?.type[0] === 'MFC' && pumpData.type[0] !== 'MFC';
    const changeFromAnotherToMFC = editingData && editingData?.type[0] !== 'MFC' && pumpData.type[0] === 'MFC';
    const isEditMode = (!!editingData && !changeFromMFCToAnother) || (pumpData.type[0] === 'MFC' && !editingData);
    const variableReactorTimes = getVariableReactorTimes(newData);
    let isCalcuatedTimeSaving = true;
    let pumpIndex = editingData
      ? positionToAddReactor.pumpPosition
      : newData[positionToAddReactor.position].pumps.length - 1;

    if (filterPumps(newData[positionToAddReactor.position].pumps).length === 1) {
      newData = recalculateResidenceTimeBelow(newData);
    } else {
      newData[positionToAddReactor.position].pumps[pumpIndex].flow_rate.forEach((time, timeIndex) => {
        if (
          getPumpFlowRate(
            isEditMode,
            changeFromAnotherToMFC ? newData : null,
            true,
            timeIndex,
            positionToAddReactor.position
          ) != time &&
          !changeFromAnotherToMFC
        )
          isCalcuatedTimeSaving = false;
      });
      if (!isCalcuatedTimeSaving) newData = recalculateResidenceTimeBelow(newData);
    }
    let f_r = variableReactorTimes.map((time, timeIndex) =>
      isCalcuatedTimeSaving
        ? getPumpFlowRate(
            isEditMode,
            changeFromAnotherToMFC ? newData : null,
            true,
            timeIndex,
            positionToAddReactor.position
          )
        : newData[positionToAddReactor.position].pumps[pumpIndex].flow_rate[timeIndex]
    );
    newData[positionToAddReactor.position] = {
      ...newData[positionToAddReactor.position],
      pumps: newData[positionToAddReactor.position].pumps.map((pump, index) => {
        let len = !!data[positionToAddReactor.position]
          ? positionToAddReactor.position
          : positionToAddReactor.position; /* - 1*/
        if (editingData ? true : !!data[len] && index !== data[len]?.pumps.length) {
          if (newData[positionToAddReactor.position].type === 'gas' && index === 0) {
            pump.flow_rate = getFlowRateForGasPump(positionToAddReactor.position);
          } else if (newData[positionToAddReactor.position].type === 'liquid' && index === 0)
            pump.flow_rate = pump.flow_rate;
          else if (pump.type[0] !== 'MFC' && index !== positionToAddReactor.pumpPosition && isCalcuatedTimeSaving)
            pump.flow_rate = f_r;
        }
        return pump;
      }),
    };
    newData = MFRRecalculation(newData);
    newData = recalculateCompounds(newData);
    newData = recalculateAllGasReactors(newData);
    recalculatePumpIndexes(newData);
    if (!editingData) {
      setSidebarPump(false);
      openNotification('Pump has been added to the process');
      setPostitionToAddReactor({ position: 0, length: 0, pumpPosition: 0 });
    } else {
      openNotification('Pump is successfully updated');
      setEditingData(pumpData);
    }
  };

  const recalculatePumpIndexes = newData => {
    let pumpIdx = 0;
    setData(
      newData.map(reactor => {
        let pumps = [];
        if (reactor.type === 'liquid' || reactor.type === 'gas') {
          let liquidPump = reactor.pumps[0];
          //reactor.pumps.splice(0, 1);
          let reactorsWithoutFirst = reactor.pumps.slice(1, reactor.pumps.length);
          let newPumps = [...reactorsWithoutFirst, liquidPump].map(pump => {
            pumpIdx += 1;
            return { ...pump, name: `Pump ${pumpIdx}` };
          });
          liquidPump = newPumps[newPumps.length - 1];
          newPumps.splice(newPumps.length - 1, 1);
          pumps = [liquidPump, ...newPumps];
        } else {
          pumps = reactor.pumps.map(pump => {
            pumpIdx += 1;
            return { ...pump, name: `Pump ${pumpIdx}` };
          });
        }
        return {
          ...reactor,
          pumps,
        };
      })
    );
  };

  const addReactorVariation = (reactorIndex, isAdd, count) => {
    setVariationPumpReactors({ ...variationPumpReactors, [reactorIndex]: count });
    const updatedData = data.map((reactor, index) => {
      return index >= reactorIndex && reactor?.properties
        ? { ...reactor, properties: isAdd ? [...reactor.properties, emptyReactorProp] : reactor.properties.slice(-1) }
        : reactor;
    });
    setData(updatedData);
  };

  const saveProcess = (validate = false) => {
    if (!validate) setLoadingButton(true);
    setLoading(true);
    const dataToSave = createDataToSaveProcess(
      data,
      variationPump,
      variationPumpReactorIndex,
      props.process?.process?.type
    );
    // close previous errors
    if (validationErrors.length) {
      validationErrors.forEach((error, index) => {
        closeNotification('queue-notification-' + index);
      });
    }
    // close previous warnings
    for (let i = 0; i < allWarningsCountRef.current; i++) {
      closeNotification('warning-notification-' + i);
    }
    let modeEdit = props.editMode;
    props
      .updateProcessDefinition(
        {
          definition: dataToSave,
          uuid: id,
        },
        validate
      )
      .then(async data => {
        if (data.updateProcessDefinition.errors) {
          setLoadingButton(false);
          setLoading(false);
        } else {
          props.setProcess({
            process: {
              ...props.process.process,
              definition: data.updateProcessDefinition.definition,
            },
          });
          props.setEditMode(false);
          modeEdit = false;
          openNotification(validate ? 'Process is valid for experiment' : 'Process has been saved');
          setLoadingButton(false);
        }
        if (validate) {
          setOpenValidatePopup(false);
          if (modeEdit) {
            await lockFunction('locked');
          }
        } else {
          setValidatedConfirmationOpen(false);
          setLoading(false);
        }
      })
      .catch(async () => {
        openNotification('Process has been saved.', 'Please check your data.');
        setLoadingButton(false);
        setLoading(false);
        if (validate) {
          setOpenValidatePopup(false);
          if (modeEdit) {
            await lockFunction('locked');
          }
        } else {
          setValidatedConfirmationOpen(false);
        }
      })
      .finally(() => {
        props.getProcess(id).finally(() => {
          setLoading(false);
        });
      });
  };

  const validateProcess = () => {
    if (!props.editMode)
      lockFunction('locked').then(() => {
        saveProcess(true);
      });
    else saveProcess(true);
  };

  const deleteReactor = () => {
    let updatedData = data
      .map((item, index) => {
        if (index === positionToAddReactor.position) {
          let pumps = [...item.pumps];
          if (
            data[positionToAddReactor.position].type === 'liquid' ||
            data[positionToAddReactor.position].type === 'gas'
          )
            pumps.splice(0, 1);
          return { pumps };
        }
        return item;
      })
      .filter(reactor => !!reactor.name || !!reactor.pumps?.length);

    if (data[positionToAddReactor.position].variable) {
      updatedData = updatedData.map((i, index) => {
        if (i.pumps?.length > 0) {
          i.pumps.map((item, index) => {
            item.flow_rate.splice(1); //check
            if (i?.flow_rate?.length > 0) i.flow_rate.splice(1);
            //delete equivalents on pump compound

            item.properties.forEach(property => {
              let pump_reagents = property.reagents;
              let pump_reactants = property.reactants;
              if (!!pump_reactants?.length) {
                for (let j = 0; j < pump_reactants.length; j++) {
                  property.reactants[j].equivalents.splice(1);
                }
              }
              if (!!pump_reagents?.length) {
                for (let j = 0; j < pump_reagents.length; j++) {
                  property.reagents[j].equivalents.splice(1);
                }
              }
            });
          });
        }
        if (i.times?.length > 0) {
          i.times.splice(1);
        }
        if (i.temperatures?.length > 0) {
          i.temperatures.splice(1);
        }
        return i;
      });
    }
    if (data[positionToAddReactor.position].type === 'liquid') {
      updatedData = recalculateResidenceTimeBelow(updatedData);
      //recalculate flow rate for all gas pumps
      updatedData = updatedData.map((item, idx) => {
        if (item.type === 'gas') {
          item.pumps[0].flow_rate = getFlowRateForGasPump(idx, updatedData);
        }
        return item;
      });
    }
    recalculatePumpIndexes(updatedData);
    setOpenSidebar(false);
    setPostitionToAddReactor({ position: 0, length: 0 });
    setConfirmationDelete(false);
    setEditingData(null);
  };

  const openChangeReactor = (reactorData, idx) => {
    setOpenSidebar(true);
    setEditingData(reactorData);
    setPostitionToAddReactor({ position: idx, length: data.length });
  };

  const openChangePump = (pumpData, idx, reactorIndex) => {
    let pumpPosition = idx;
    if (data[reactorIndex].type === 'liquid' || data[reactorIndex].type === 'gas') pumpPosition += 1;
    setSidebarPump(true);
    setEditingData(pumpData);
    setPostitionToAddReactor({ position: reactorIndex, length: data.length, pumpPosition });
    setPumpIdxForPump(
      data[reactorIndex].type === 'liquid' || data[reactorIndex].type === 'gas' ? pumpPosition - 1 : pumpPosition,
      {
        position: reactorIndex,
        length: data.length,
        pumpPosition:
          data[reactorIndex].type === 'liquid' || data[reactorIndex].type === 'gas' ? pumpPosition - 1 : pumpPosition,
      }
    );
    setIndex(pumpPosition + 1);
  };

  const deletePump = () => {
    const variableReactorTimes = getVariableReactorTimes();
    let updatedData = [...data]
      .map((i, idx) => {
        if (idx === positionToAddReactor.position)
          return {
            ...i,
            pumps: i.pumps.filter((pump, index) => index !== positionToAddReactor.pumpPosition),
          };
        return i;
      })
      .filter(reactor => !!reactor.name || !!reactor.pumps?.length);
    if (!!updatedData[positionToAddReactor.position]?.name)
      updatedData = updatedData.map((reactor, reactorIdx) => {
        if (positionToAddReactor.position === reactorIdx) {
          let newPumps = [...reactor.pumps];
          reactor.pumps.forEach((pump, index) => {
            if (
              pump.type[0] === 'MFC' ||
              (reactor.type === 'liquid' && index === 0) ||
              (reactor.type === 'gas' && index === 0)
            )
              return;
            newPumps[index] = { ...pump };
            newPumps[index].flow_rate = variableReactorTimes.map((time, timeIndex) => {
              return getPumpFlowRate(true, updatedData, true, timeIndex, positionToAddReactor.position);
            });
            newPumps[index].properties = newPumps[index].properties.map(property => ({
              ...property,
              reagents: property.reagents.map(reagent => ({
                ...reagent,
                molar_flow_rate: newPumps[index].flow_rate.map(flow_rate => flow_rate * reagent.concentration),
              })),
              reactants: property.reactants.map(reactant => ({
                ...reactant,
                molar_flow_rate: newPumps[index].flow_rate.map(flow_rate => flow_rate * reactant.concentration),
              })),
            }));
          });
          reactor.pumps = newPumps;
        }

        //recalculate flow rate for gas pump when any pump was deleted
        if (reactor.type === 'gas') {
          //gas separator always has only one pump
          reactor.pumps[0].flow_rate = getFlowRateForGasPump(reactorIdx, updatedData);
        }
        return reactor;
      });
    updatedData = recalculateResidenceTime(updatedData, true, positionToAddReactor.position, true);
    if (!updatedData[positionToAddReactor.position]?.pumps.length)
      updatedData = recalculateResidenceTimeBelow(updatedData, positionToAddReactor.position, true, true);
    updatedData = MFRRecalculation(updatedData);
    updatedData = recalculateCompounds(updatedData);
    recalculatePumpIndexes(updatedData);
    setSidebarPump(false);
    setPostitionToAddReactor({ position: 0, length: 0, pumpPosition: 0 });
    setConfirmationDelete(false);
    setEditingData(null);
  };

  const MFRRecalculation = editingData => {
    return editingData.map(reactor => {
      reactor.pumps = reactor.pumps.map(pump => {
        pump.properties = pump.properties.map(property => ({
          ...property,
          reagents: property.reagents.map(reagent => ({
            ...reagent,
            molar_flow_rate: pump.flow_rate.map(flow_rate => flow_rate * reagent.concentration),
          })),
          reactants: property.reactants.map(reactant => ({
            ...reactant,
            molar_flow_rate: pump.flow_rate.map(flow_rate => flow_rate * reactant.concentration),
          })),
        }));
        return pump;
      });
      return reactor;
    });
  };

  const editingType = editingData?.pumps ? 'Reactor' : editingData?.name ? 'Pump' : 'Compound';

  const openCompoundAddToPump = (position, pumpPosition, compoundIDx, isVariative) => {
    let pp = pumpPosition;
    if (data[position].type === 'liquid' || data[position].type === 'gas') pp += 1;
    setPostitionToAddReactor({ position, pumpPosition: pp, length: 0, compoundPosition: compoundIDx });
    setSidebarCompoundPump(true);
    setPumpIdx(pp);
    setPumpIdxForComp(
      pp,
      { position, pumpPosition: pp, length: 0, compoundPosition: compoundIDx },
      data[position].type === 'liquid' || data[position].type === 'gas'
    );
    if (isVariative) {
      setBecameVariable({ position, pumpPosition: pp, length: 0, compoundPosition: COMPOUND_VARIATY[compoundIDx] });
      setSidebarCompoundPumpIndex(COMPOUND_VARIATY[compoundIDx]);
    } else {
      setBecameVariable(null);
    }
  };

  const getCurrentPumpByPosition = () => {
    return data[positionToAddReactor.position]?.pumps[positionToAddReactor.pumpPosition];
  };

  const handleAddPumpSliderOpen = (position, length, emptyReactor) => {
    let maxLength = data[position]?.type === 'liquid' ? 6 : 5;
    if (length >= maxLength) {
      openNotification(null, 'Draft Process allows Max. 5 Pumps for Reaction step');
      return;
    }
    setPostitionToAddReactor({ position, length, emptyReactor });
    setPumpIdxForPump(
      position,
      { position, length, emptyReactor, pumpPosition: position },
      true,
      data[position]?.type === 'liquid'
    );
    setPumpIdx(position);
    setSidebarPump(true);
  };

  const openCompoundAddToReactor = (position, propertyIndex, isVariative) => {
    setSidebarCompoundReactor(true);
    setPostitionToAddReactor({ position, length: 0, reactorCompoundPosition: propertyIndex });
    if (isVariative) setSidebarCompoundPumpIndex(COMPOUND_VARIATY[propertyIndex]);
  };

  const setReactorCompoundData = compound => {
    let newData = [...data];
    let objectToChange = {};
    const updatedReactor = newData[positionToAddReactor.position];
    const updatedReactorProperties = updatedReactor?.properties[positionToAddReactor.reactorCompoundPosition];
    if (compound.sideproducts)
      objectToChange = {
        sideproducts: editingData
          ? compound.sideproducts
          : [...updatedReactorProperties.sideproducts, ...compound.sideproducts],
      };
    else if (compound.intermediate)
      objectToChange = {
        products: [compound.intermediate],
      };
    newData[positionToAddReactor.position] = {
      ...updatedReactor,
      properties: newData[positionToAddReactor.position].properties.map((property, index) =>
        index === positionToAddReactor.reactorCompoundPosition ? { ...property, ...objectToChange } : property
      ),
    };
    if (!editingData) {
      openNotification('Compound has been added to the Reactor');
      setSidebarCompoundReactor(false);
      setEditingData(null);
      setPostitionToAddReactor({ position: 0, length: 0 });
    } else {
      openNotification('Compound is successfully updated');
    }
    setData(newData);
  };

  const setPumpCompoundData = compound => {
    let newData = [...data];
    newData = newData.map((i, reactorIndex) => {
      if (reactorIndex === positionToAddReactor.position) {
        const pumps = i.pumps.map((pump, pumpIndex) => {
          if (pumpIndex === positionToAddReactor.pumpPosition) {
            const objectToChange = getUpdatedPumpCompound(
              !!editingData,
              pump?.properties[positionToAddReactor.compoundPosition],
              positionToAddReactor,
              compound
            );
            const updatedPumpProperties =
              positionToAddReactor.compoundPosition > pump.properties.length - 1
                ? [...pump.properties, { ...emptyPumpProperty, ...objectToChange }]
                : pump.properties.map((property, i) =>
                    i === positionToAddReactor.compoundPosition ? { ...property, ...objectToChange } : property
                  );
            return { ...pump, properties: updatedPumpProperties };
          } else {
            return recalculatePumpOnLimitedCompound(pump, compound.limiting_reagent);
          }
        });
        return {
          ...i,
          pumps,
        };
      } else {
        return { ...i, pumps: i.pumps.map(pump => recalculatePumpOnLimitedCompound(pump, compound.limiting_reagent)) };
      }
    });
    newData = recalculateCompounds(newData);

    if (!editingData) {
      openNotification('Compound has been added to the Pump');
      setSidebarCompoundPump(false);
      setEditingData(null);
      setPostitionToAddReactor({ position: 0, length: 0 });
    } else {
      openNotification('Compound is successfully updated');
    }
    setData(newData);
  };

  const changeCompound = (editData, reactorIdx, index, pumpIdx, compoundPosition, variationAvailable) => {
    let pumpCpd = editData.reactant || editData.reagent || editData.solvent;
    const compoundPositionSetting = pumpCpd
      ? { compoundPosition: compoundPosition }
      : { reactorCompoundPosition: compoundPosition };
    setPostitionToAddReactor({
      position: reactorIdx,
      pumpPosition:
        (data[reactorIdx].type === 'liquid' || data[reactorIdx].type === 'gas') && pumpCpd ? pumpIdx + 1 : pumpIdx,
      compoundIdx: index,
      ...compoundPositionSetting,
    });
    if (pumpCpd) {
      setSidebarCompoundPump(true);
    } else {
      setSidebarCompoundReactor(true);
    }
    if (variationAvailable) setSidebarCompoundPumpIndex(COMPOUND_VARIATY[compoundPosition]);
    setEditingData(editData);
    setPumpIdx(pumpIdx);
    setPumpIdxForComp(pumpIdx, {
      position: reactorIdx,
      pumpPosition: data[reactorIdx].type === 'liquid' && pumpCpd ? pumpIdx + 1 : pumpIdx,
      compoundIdx: index,
      ...compoundPositionSetting,
    });
  };

  const deleteCompound = () => {
    let newData = [...data];
    let variable = false;
    if (editingData.reactant || editingData.reagent || editingData.solvent) {
      newData = newData.map((reactor, reactorIdx) => {
        if (reactorIdx === positionToAddReactor.position) {
          return {
            ...reactor,
            pumps: reactor.pumps.map((pump, pumpIdx) => {
              if (pumpIdx === positionToAddReactor.pumpPosition) {
                let objectToChange = {};
                if (editingData.reactant)
                  objectToChange.reactants = pump.properties[positionToAddReactor.compoundPosition].reactants.filter(
                    (reactant, index) => index !== positionToAddReactor.compoundIdx
                  );
                else if (editingData.reagent) {
                  objectToChange.reagents = pump.properties[positionToAddReactor.compoundPosition].reagents.filter(
                    (reactant, index) => index !== positionToAddReactor.compoundIdx
                  );
                } else if (editingData.solvent)
                  objectToChange.solvents = pump.properties[positionToAddReactor.compoundPosition].solvents.filter(
                    (reactant, index) => index !== positionToAddReactor.compoundIdx
                  );
                if (
                  pump.properties[compoundPosition].reactants.length +
                    pump.properties[compoundPosition].reagents.length +
                    pump.properties[compoundPosition].solvents.length <=
                  1
                ) {
                  if (
                    props.process?.process?.type === 'Library generation' &&
                    pumpToDeleteCompound.properties.length > 1
                  ) {
                    variable = true;
                    pump.properties.splice(positionToAddReactor.compoundPosition, 1);
                  }
                }
                return {
                  ...pump,
                  properties: pump.properties.map((property, i) => {
                    return i === positionToAddReactor.compoundPosition && !variable
                      ? { ...property, ...objectToChange }
                      : property;
                  }),
                };
              }
              return pump;
            }),
          };
        }
        return reactor;
      });
      newData = newData.map((item, index) => {
        if (
          index >= positionToAddReactor.position &&
          props.process?.process?.type === 'Library generation' &&
          item.properties
        ) {
          item.properties.splice(compoundPosition, 1);
        }
        if (!item.properties?.length) {
          item.properties = [
            {
              products: [],
              sideproducts: [],
            },
          ];
        }
        return item;
      });
      newData = recalculateCompounds(newData);
      setSidebarCompoundPump(false);
    } else {
      if (editingData.length) {
        newData = data.map((i, idx) => {
          if (idx === positionToAddReactor.position)
            return {
              ...i,
              properties: i.properties.map((j, jdx) =>
                positionToAddReactor.reactorCompoundPosition === jdx ? { ...j, sideproducts: [] } : j
              ),
            };
          return i;
        });
      } else {
        newData = data.map((i, idx) => {
          if (idx === positionToAddReactor.position) {
            return {
              ...i,
              properties: i.properties.map((j, jdx) =>
                positionToAddReactor.reactorCompoundPosition === jdx ? { ...j, products: [] } : j
              ),
            };
          }
          return i;
        });
      }
      setSidebarCompoundReactor(false);
    }
    setEditingData(null);
    setPostitionToAddReactor({ position: 0, length: 0 });
    setData(newData);
    setConfirmationDelete(false);
  };

  const findLimitingReagent = newData => {
    let limitingReagent = null;
    const limitingArray = newData || data;
    limitingArray.forEach(reactor => {
      reactor.pumps.forEach(pump =>
        pump.properties.forEach(property => {
          [...property.reagents, ...property.reactants].forEach(compound => {
            if (!!compound.limiting_reagent) limitingReagent = compound;
          });
        })
      );
    });
    return limitingReagent;
  };
  const findVariativeLimitingReagent = variatyIndex => {
    let limitingReagent = null;
    if (variationPump)
      [...variationPump.properties[variatyIndex].reagents, ...variationPump.properties[variatyIndex].reactants].forEach(
        compound => {
          if (compound.limiting_reagent) limitingReagent = compound;
        }
      );
    return limitingReagent;
  };

  const recalculateCompounds = dataToChange => {
    const variationData = getVariablePumpData(dataToChange);
    const limitingReagent = findLimitingReagent(dataToChange);
    return dataToChange.map(reactor => ({
      ...reactor,
      pumps: reactor.pumps.map(pump => ({
        ...pump,
        properties: pump.properties.map((pumpProp, pumpPropIndex) => ({
          ...pumpProp,
          reagents: calculatePumpCompounds(pumpProp.reagents, pump, limitingReagent, variationData, pumpPropIndex),
          reactants: calculatePumpCompounds(pumpProp.reactants, pump, limitingReagent, variationData, pumpPropIndex),
        })),
      })),
    }));
  };

  const getVariableReactorData = newData => {
    let index = -1;
    let variableData = newData ? newData : [...data];
    variableData.forEach((i, indexVariable) => {
      if (i.variable || i?.times?.length > 1 || i?.temperature?.length > 1) {
        index = indexVariable;
      }
    });
    return variableData[index];
  };

  const getVariablePumpData = newData => {
    let variablePump = null;
    const arrayToSearch = newData || data;
    arrayToSearch.forEach(i => {
      if (i.pumps?.length > 0) {
        i.pumps.forEach(pump => {
          if (pump.properties?.length > 1) {
            variablePump = pump;
          }
        });
      }
    });
    return variablePump;
  };

  const getFlowRateForGasPump = (
    position = positionToAddReactor.position,
    dataToChange = [...data],
    onTheSameLevel
  ) => {
    //sum of flow rates of all previous pumps
    let pumpsLength = 0;
    let flowRatesSum = [0];
    dataToChange.forEach((i, idx) => {
      if (onTheSameLevel ? idx < position : idx <= position) {
        pumpsLength += filterPumps(i.pumps).length;
        if (pumpsLength > 0 && !!i.pumps.length) {
          i.pumps.forEach((pump, pumpIdx) => {
            if (pump.type[0] !== 'MFC' && !(i.type === 'gas' && pumpIdx === 0)) {
              pump.flow_rate.forEach((item, index) => {
                flowRatesSum[index] = flowRatesSum[index] ?? 0;
                flowRatesSum[index] += +item;
              });
            }
          });
        }
      }
    });
    return flowRatesSum;
  };

  const getPumpFlowRate = (
    editMode = false,
    dataToChange,
    onTheSameLevel,
    timeIndex,
    positionToAddReactorIndex = positionToAddReactor.position
  ) => {
    if (!dataToChange) dataToChange = [...data];
    if (!dataToChange[positionToAddReactorIndex]) return 0;
    let vol = props.reactors.allReactors.find(reactor => reactor.key === dataToChange[positionToAddReactorIndex].key)
      ?.volume;
    let pumpsLength = onTheSameLevel ? filterPumps(dataToChange[positionToAddReactorIndex].pumps).length : 0;
    // add new (not first) or delete pump
    if (onTheSameLevel) {
      let pumpsSumm = 0;
      filterPumps(data[positionToAddReactorIndex].pumps).forEach((i, idx) => {
        if (
          (data[positionToAddReactorIndex].type === 'liquid' && idx === 0) ||
          (data[positionToAddReactorIndex].type === 'gas' && idx === 0)
        ) {
          pumpsLength -= 1;
        } else pumpsSumm += +i.flow_rate[timeIndex];
      });
      if (pumpsSumm > 0) return pumpsSumm / (pumpsLength + (editMode ? 0 : 1));
    }
    if (!vol) return 0;
    // for residence time recalculation
    let liquidsSumm = 0;
    if (!onTheSameLevel) {
      dataToChange.forEach((i, idx) => {
        if (idx <= positionToAddReactorIndex) {
          pumpsLength +=
            i.type === 'liquid' || i.type === 'gas' ? filterPumps(i.pumps).length - 1 : filterPumps(i.pumps).length;
          if ((i.type === 'liquid' || i.type === 'gas') && pumpsLength > 0) {
            liquidsSumm += +i.pumps[0].flow_rate[0];
          }
        }
      });
    }
    let reactorTime = dataToChange[positionToAddReactorIndex].times[timeIndex] || 0;
    let fr = (vol / (reactorTime / 60) + liquidsSumm) / (pumpsLength + (editMode ? 0 : 1));
    return Number.isFinite(fr) ? fr : 0;
  };

  const lockFunction = state => {
    return updateTheLockState({
      uuid: props.process?.process?.processLock?.uuid,
      state,
    });
  };

  const getVariableReactorTimes = newData => {
    const variableReactor = getVariableReactorData(newData);
    if (variableReactor) {
      return variableReactor.times;
    }
    return [0];
  };

  const recalculateProcessOnOptimization = (
    changedReactor,
    changedReactorIndex,
    _data,
    variableReactorTimes,
    deleteIndex,
    last_iteration,
    editMode = true
  ) => {
    let newData = [..._data];
    if (!variableReactorTimes) {
      variableReactorTimes = getVariableReactorTimes(newData);
    }
    if (!!deleteIndex?.length && (changedReactor.variable || last_iteration) && _data.length > 1) {
      let arr = [...data];
      arr = arr.map((i, index) => {
        for (let k = 0; k < deleteIndex.length; k++) {
          if (i.times) {
            i.times.splice(deleteIndex[k], 1);
          }
        }
        i.temperatures = newData[index].temperatures;
        if (last_iteration) {
          i.variable = false;
        }
        if (!!i.pumps) {
          let arr_pumps = [...i.pumps];
          arr_pumps.map((item, index) => {
            if (!!deleteIndex.length) {
              //delete flow rate on pump when delete option of time on reactor
              for (let k = 0; k < deleteIndex.length; k++) {
                item.flow_rate.splice(deleteIndex[k], 1);
              }
              i.temperatures = newData[index].temperatures;
              item.properties.forEach(property => {
                let pump_reagents = property.reagents;
                let pump_reactants = property.reactants;
                if (!!pump_reactants?.length) {
                  for (let j = 0; j < pump_reactants.length; j++) {
                    for (let k = 0; k < deleteIndex.length; k++) {
                      property.reactants[j].equivalents.splice(deleteIndex[k], 1);
                    }
                  }
                }
                if (!!pump_reagents?.length) {
                  for (let j = 0; j < pump_reagents.length; j++) {
                    for (let k = 0; k < deleteIndex.length; k++) {
                      property.reagents[j].equivalents.splice(deleteIndex[k], 1);
                    }
                  }
                }
              });
            }
            return item;
          });
        }
        return i;
      });
      setData(arr);
      newData = arr;
    }
    newData = newData.map(reactor => {
      const times = variableReactorTimes.map((time, timeIndex) => {
        return reactor?.type !== 'liquid' && reactor?.times
          ? reactor?.times[timeIndex]
            ? reactor?.times[timeIndex]
            : time || time
          : undefined;
      });
      if (reactor.name) {
        return {
          ...reactor,
          times: times,
        };
      } else {
        return reactor;
      }
    });
    newData = recalculateProcessByChangeResidenceTime(
      newData,
      variableReactorTimes,
      changedReactorIndex,
      !!editingData
    );
    newData = recalculatePumpsFlowRate(newData, variableReactorTimes, changedReactorIndex, true, editMode);
    newData = recalculateMFRForLimitingCompound(newData);
    newData = recalculateCompounds(newData);

    newData = recalculateResidenceTimeBelow(newData, changedReactorIndex);
    newData = recalculateAllGasReactors(newData);
    return newData;
  };

  const recalculateMFRForLimitingCompound = newData => {
    newData.forEach(reactor => {
      reactor.pumps.forEach(pump => {
        pump.properties.forEach(property => {
          property.reactants.forEach(reactant => {
            if (reactant.limiting_reagent) {
              reactant.molar_flow_rate = pump.flow_rate.map(
                flowRate => flowRate * reactant.concentration * 1000 * 0.001 * 1
              );
            }
          });
          property.reagents.forEach(reagent => {
            if (reagent.limiting_reagent) {
              reagent.molar_flow_rate = pump.flow_rate.map(
                flowRate => flowRate * reagent.concentration * 1000 * 0.001 * 1
              );
            }
          });
        });
      });
    });
    return newData;
  };

  const recalculateProcessByChangeResidenceTime = (
    newData,
    variableReactorTimes,
    positionToAddReactorIndex,
    useNegative
  ) => {
    let _data = recalculatePumpsFlowRate(newData, variableReactorTimes, positionToAddReactorIndex, false);
    _data = recalculateResidenceTime(_data, false, positionToAddReactorIndex, !useNegative, false, true);
    _data = recalculateResidenceTimeBelow(_data, positionToAddReactorIndex, !useNegative, false, true);
    return _data;
  };

  const recalculatePumpsFlowRate = (
    newData,
    variableReactorTimes,
    positionToAddReactorIndex,
    isBelow = false,
    editMode = true
  ) =>
    newData.map((reactor, reactorIdx) => {
      reactor.pumps.map((pump, pumpIdx) => {
        if (isBelow ? positionToAddReactorIndex < reactorIdx : positionToAddReactorIndex >= reactorIdx)
          pump.flow_rate = variableReactorTimes.map((time, timeIndex) => {
            let flow;
            if (pumpIdx === 0 && (reactor.type === 'liquid' || reactor.type === 'gas'))
              flow = !!pump.flow_rate[timeIndex] && pump.flow_rate[timeIndex] !== 0 ? pump.flow_rate[timeIndex] : 0;
            else if (pump.type[0] === 'MFC') flow = pump.flow_rate[0];
            else if (
              (!reactor.name && !newData.find((reactor, i) => i > reactorIdx && reactor.name)) ||
              !newData.find((reac, i) => positionToAddReactorIndex >= i && reac.pumps.length) ||
              (isBelow && pump.flow_rate[timeIndex])
            ) {
              flow = pump.flow_rate[timeIndex] || 0;
            } else if (isBelow) {
              flow = 0;
            } else flow = getPumpFlowRate(editMode, newData, false, timeIndex, positionToAddReactorIndex);
            return flow;
          });
        return pump;
      });
      return reactor;
    });

  const recalculateResidenceTimeBelow = (
    newData,
    position,
    usePositiveNumbers = true,
    isPumpDeleting,
    skipCurrent = false
  ) => {
    return recalculateResidenceTime(
      newData,
      true,
      position || positionToAddReactor.position,
      usePositiveNumbers,
      isPumpDeleting,
      skipCurrent
    );
  };

  const filterPumps = pumps => {
    return pumps?.filter(pump => pump.type[0] !== 'MFC') || [];
  };

  const recalculateResidenceTime = (
    _data,
    isBelow = false,
    positionToAddReactorIndex,
    usePositiveNumbers = true,
    isPumpDeleting,
    skipCurrent = false
  ) => {
    return _data.map((reactor, reactorIdx) => {
      if (
        isBelow
          ? skipCurrent
            ? positionToAddReactorIndex < reactorIdx
            : positionToAddReactorIndex <= reactorIdx
          : positionToAddReactorIndex > reactorIdx
      ) {
        let allowZero = false;
        _data.forEach((dI, dIIndex) => {
          let pumpsLength = dI.type === 'liquid' ? filterPumps(dI.pumps).length - 1 : filterPumps(dI.pumps).length;
          if (pumpsLength && dIIndex <= reactorIdx) allowZero = true;
        });
        let vol = props.reactors.allReactors.find(i => i.key === reactor.key)?.volume;
        if (!vol) return reactor;
        let pumpsSumm = [];
        _data.forEach((dataItem, dataIndex) => {
          if (dataIndex <= reactorIdx)
            filterPumps(dataItem.pumps).forEach((pump, pumpIdx) => {
              pump.flow_rate.forEach((pumpFlowRate, flowRateIndex) => {
                if (!pumpsSumm[flowRateIndex]) pumpsSumm[flowRateIndex] = 0;
                if (usePositiveNumbers) {
                  if (pumpsSumm[flowRateIndex] < 0) pumpsSumm[flowRateIndex] = 0;
                }
                // set Flow rate from the reactor pump
                pumpsSumm[flowRateIndex] += pumpFlowRate * (dataItem.type === 'liquid' && pumpIdx === 0 ? -1 : 1);
              });
            });
        });
        if (pumpsSumm.length) {
          reactor.times = pumpsSumm.map((sum, index) => {
            if (!_data.find((reac, i) => positionToAddReactorIndex >= i && reac.pumps.length) && !isPumpDeleting) {
              return reactor.times[index] || 0;
            }
            if (sum > 0 || (allowZero && sum <= 0)) return sum <= 0 ? 0 : (vol / sum) * 60;
            return reactor.times[index];
          });
        }
      }
      return reactor;
    });
  };

  const disableCreateExperiment =
    (props.process?.process?.status !== 'validated' && props.process?.process?.status !== 'experiment submitted') ||
    props.editMode;
  const showCreateExperimentBtn = props.user?.permissions?.execution?.create_experiment;

  const handleCreateExperiment = async () => {
    setLoading(true);
    try {
      const { updateProcessDefinition } = await props.updateProcessDefinition({ uuid: id }, true);
      if (updateProcessDefinition.errors) {
        setCreateExperimentErrorPopup(true);
        props.getProcess(props.process?.process?.uuid).finally(() => {
          setLoading(false);
        });
      } else dispatch(setModalOpen(props.process?.process, stepData, data));
    } catch (e) {
      setCreateExperimentErrorPopup(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Spinner loading={loading}>
        <div className="builder">
          {headerData && !processPermissionsError && (
            <div className="builder-content">
              <ProcessDetails
                data={headerData}
                setShowResultingRoute={setShowResultingRoute}
                showResultingRoute={showResultingRoute}
                currentUser={props.user}
                setShowInitialRoute={setShowInitialRoute}
                showInitialRoute={showInitialRoute}
              />
              <CreatorProcessFromRoute
                isPreviewResult
                dataProcess={props.process}
                open={showResultingRoute}
                handleCancel={closePopupResultingRoute}
                deviceType={PROCESS_TYPES.AutoSyn.device}
              />
              {showInitialRoute && headerData?.process && (
                <ViewInitialRoute
                  setGenerationData={setGenerationData}
                  reactors={props.reactors}
                  dataProcess={props.process}
                  updateProcess={props.updateProcess}
                  setShowInitialRoute={setShowInitialRoute}
                  showInitialRoute={showInitialRoute}
                  editMode={props.editMode}
                />
              )}
              <div
                className={cn({ 'work-space-preview-mode': !props.editMode && !!props.process?.process?.definition })}
              >
                <div className="preview-mode-wrapper">
                  {!processPermissionsError && (
                    <WorkSpace
                      previewMode={!props.editMode && !!props.process?.process?.definition}
                      data={data}
                      setData={setData}
                      addReactorVariation={addReactorVariation}
                      addPump={(position, length, emptyReactor) =>
                        handleAddPumpSliderOpen(position, length, emptyReactor)
                      }
                      addReactor={(position, length) => {
                        if (length >= 10) {
                          openNotification(null, 'Process can’t have >10 Reactors');
                          return;
                        }
                        setOpenSidebar(!openSidebar);
                        setPostitionToAddReactor({ position, length });
                      }}
                      changeReactor={openChangeReactor}
                      changePump={openChangePump}
                      openCompoundAddToPump={openCompoundAddToPump}
                      openCompoundAddToReactor={openCompoundAddToReactor}
                      changeCompound={changeCompound}
                      processType={props.process?.process?.type}
                      variationPump={variationPump}
                      setCompoundPosition={setCompoundPosition}
                      onAfterUpdateReactor={(
                        newReactorData,
                        reactorIndex,
                        newData,
                        variableReactorTimes,
                        deleteIndex,
                        last_iteration
                      ) => {
                        setData(
                          recalculateProcessOnOptimization(
                            newReactorData,
                            reactorIndex,
                            newData,
                            variableReactorTimes,
                            deleteIndex,
                            last_iteration
                          )
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {!processPermissionsError && (
            <div className="builder-footer fixed-footer">
              <div>
                {props.user?.permissions?.process.change_process &&
                  props.process?.process?.status !== 'experiment submitted' && (
                    <Button
                      loading={loadingButton}
                      disabled={
                        loadingButton ||
                        !data.length ||
                        props.process.process?.status === 'experiment submitted' ||
                        checkIfMyLock(props.process?.process?.processLock)
                      }
                      onClick={() => {
                        if (!props.editMode) {
                          lockFunction('locked').then(() => {
                            props.setEditMode(true);
                          });
                        } else {
                          if (props.process.process?.status === 'validated') {
                            setValidatedConfirmationOpen(true);
                          } else saveProcess();
                        }
                      }}
                    >
                      {!props.editMode && !!props.process?.process?.definition ? 'Edit' : 'Save'}
                    </Button>
                  )}
                {props.editMode && (
                  <Button
                    style={{
                      marginLeft: '8px',
                    }}
                    secondary
                    onClick={() => {
                      setOpenCancelPopup(true);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <LockLabel processLock={props.process?.process?.processLock} />
              </div>
              <div>
                {validationErrors?.length > 0 && (
                  <Badge count={count} showZero>
                    <div className="badge-wrapper">
                      <ExclamationCircleOutlined />
                    </div>
                  </Badge>
                )}
                {props.user?.permissions?.process.change_process &&
                  props.process?.process?.status !== 'experiment submitted' && (
                    <Button
                      className="validate"
                      disabled={checkIfMyLock(props.process?.process?.processLock)}
                      disabledTooltip={
                        checkIfMyLock(props.process?.process?.processLock)
                          ? `The process is being edited by ${props.process?.process?.processLock.createdBy.firstName} ${props.process?.process?.processLock.createdBy.lastName}`
                          : !props.editMode
                          ? 'The process can only be validated in edit mode'
                          : null
                      }
                      secondary
                      onClick={() => {
                        setOpenValidatePopup(true);
                      }}
                    >
                      Validate
                    </Button>
                  )}
                {showCreateExperimentBtn && (
                  <Button
                    disabled={disableCreateExperiment || checkIfMyLock(props.process?.process?.processLock)}
                    disabledTooltip={
                      checkIfMyLock(props.process?.process?.processLock) &&
                      `The process is being edited by ${props.process?.process?.processLock.createdBy.firstName} ${props.process?.process?.processLock.createdBy.lastName}`
                    }
                    onClick={handleCreateExperiment}
                  >
                    Create an experiment
                  </Button>
                )}
              </div>
            </div>
          )}
          <SidebarReactors
            open={openSidebar}
            idPump={index}
            processData={props.process}
            processTreeData={data}
            positionToAddReactor={positionToAddReactor}
            setCalcTime={setCalcTime}
            onClose={() => {
              setOpenSidebar(false);
              setEditingData(null);
            }}
            setReactorData={(dataReactor, deleteIndex, last_iteration) => {
              if (!props.editMode) {
                props.setEditMode(true);
              }
              let { position, length } = positionToAddReactor;
              addReactor(position, length, dataReactor, !!editingData, null, deleteIndex, last_iteration);
            }}
            editingData={openSidebar && editingData}
            deleteReactor={() => {
              setConfirmationDelete(true);
            }}
            getIndex={getVariableReactorData}
            getPumpFlowRate={getPumpFlowRate}
            getVariableReactorTimes={getVariableReactorTimes}
            getFlowRateForGasPump={getFlowRateForGasPump}
          />
          {openSidebarCompoundReactor && (
            <SidebarReactorCompound
              open={openSidebarCompoundReactor}
              title="Add a compound"
              setReactorCompoundData={setReactorCompoundData}
              disableIntermediate={
                data[positionToAddReactor.position].properties[positionToAddReactor.reactorCompoundPosition].products
              }
              onClose={() => {
                setSidebarCompoundReactor(false);
                setEditingData(null);
                setPostitionToAddReactor({ position: 0, length: 0 });
              }}
              editingData={openSidebarCompoundReactor && editingData}
              onDelete={() => setConfirmationDelete(true)}
              sidebarCompoundPumpIndex={sidebarCompoundPumpIndex}
            />
          )}
          <SidebarPump
            open={openSidebarPump}
            id={index}
            idPump={pumpIdxForCompound}
            processData={headerData}
            title="Add a compound"
            setPumpData={data => {
              let { position, length } = positionToAddReactor;
              addPump(position, length, data);
            }}
            onClose={() => {
              setSidebarPump(false);
              setEditingData(null);
            }}
            getVariableReactor={getVariableReactorData}
            editingData={openSidebarPump && editingData}
            deletePump={() => {
              setConfirmationDelete(true);
            }}
            getPumpFlowRate={getPumpFlowRate}
            getVariableReactorTimes={getVariableReactorTimes}
            pumpLevelLength={data[positionToAddReactor.position]?.pumps.length || 0}
          />
          {openSidebarCompoundPump && (
            <SidebarPumpCompound
              open={openSidebarCompoundPump}
              title="Add a compound"
              idPump={pumpIdxForCompound}
              setPumpCompoundData={setPumpCompoundData}
              onDelete={(currentPump, editingData, compoundPosition) => {
                setPositionToDelete(compoundPosition);
                setPumpToDeleteCompound(currentPump);
                setIsVariablePump(currentPump.properties?.length > 1);
                setConfirmationDelete(true);
              }}
              getVariableReactor={getVariableReactorData}
              processData={headerData}
              onClose={() => {
                setSidebarCompoundPump(false);
                setEditingData(null);
                setPostitionToAddReactor({ position: 0, length: 0 });
                setBecameVariable(null);
              }}
              editingData={openSidebarCompoundPump && editingData}
              getCurrentPumpByPosition={getCurrentPumpByPosition}
              findLimitingReagent={findLimitingReagent}
              findVariativeLimitingReagent={findVariativeLimitingReagent}
              sidebarCompoundPumpIndex={sidebarCompoundPumpIndex}
              variationPump={variationPump}
              getVariablePumpData={getVariablePumpData}
              compoundPosition={compoundPosition}
              becameVariable={becameVariable}
            />
          )}
        </div>
        <Popup
          open={confirmationDelete}
          title="Warning"
          handleSubmit={
            editingType === 'Reactor' ? deleteReactor : editingType === 'Pump' ? deletePump : deleteCompound
          }
          handleCancel={() => {
            setConfirmationDelete(false);
          }}
          textSubmit="Delete"
          loading={loading}
        >
          <span>
            {isVariablePump
              ? 'Deletion of this compound will affect all following reactors'
              : `Do you want to Delete a ${editingType}?`}
          </span>
        </Popup>
        <Popup
          open={openValidatePopup}
          title="Validate process"
          handleSubmit={validateProcess}
          handleCancel={() => {
            setOpenValidatePopup(false);
          }}
          textSubmit="Validate"
          loading={loading}
        >
          <span>Please note that validate action will also save all process changes made</span>
        </Popup>
        <Popup
          open={openCancelPopup}
          title="Leave the page?"
          handleSubmit={() => {
            setLoading(true);
            lockFunction('unlocked').then(() => {
              setLoading(false);
              setOpenCancelPopup(false);
              props.setEditMode(false);
              if (props.process.process?.definition) {
                setData(
                  parseProcessBuilderData(
                    JSON.parse(props.process.process?.definition),
                    props.reactors,
                    props.process.process.type
                  )
                );
              } else {
                setData([]);
                setInitialData([]);
              }
            });
          }}
          handleCancel={() => {
            setOpenCancelPopup(false);
          }}
          textSubmit="Ok"
          loading={loading}
        >
          Changes you made may not be saved
        </Popup>
        <Popup
          open={validatedConfirmationOpen}
          title="Warning"
          handleSubmit={() => {
            saveProcess();
            setValidatedConfirmationOpen(false);
          }}
          handleThirdButton={() => {
            validateProcess();
            setValidatedConfirmationOpen(false);
          }}
          handleCancel={() => {
            setValidatedConfirmationOpen(false);
          }}
          textSubmit="Save in draft"
          textThirdButton="Validate and save"
          loading={loading}
        >
          <div>Please note that you’ve changed the Validated process.</div>
          <div>Please validate it or it will be saved in Draft status until validated.</div>
        </Popup>
        <Popup
          open={createExperimentErrorPopup}
          title="Warning"
          textCancle="OK"
          handleCancel={() => setCreateExperimentErrorPopup(false)}
        >
          <div>The hardware setup has changed. The designed process is not valid anymore.</div>
          <div>Please update the process to create an experiment.</div>
        </Popup>
      </Spinner>
      <CreateExperimentModal isProcessBuilder={true} isSynJet={false} />
      <OnBeforeLeave />
    </>
  );
};
const mapStateToProps = store => {
  return {
    process: store.processReducer.process,
    user: store.commonReducer.user,
    editMode: editModeSelector(store),
    reactors: store.processbuilderReducer.reactors,
  };
};

export default connect(mapStateToProps, {
  getProcess,
  setEditMode,
  getDetailsUser,
  updateProcess,
  getReactorsTypes,
  updateProcessDefinition,
  setProcess,
})(ProcessBuilder);
