import './styles.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DndDropEdgeAreas } from '@molecules/VirtualTable/components/DndDropEdgeAreas';
import { HeaderRow } from '@molecules/VirtualTable/components/HeaderRow';
import { TableInitialLoading } from '@molecules/VirtualTable/components/TableInitialLoading';
import { SortTableHOC } from '@molecules/VirtualTable/hooks/useSortController';
import cn from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid } from 'react-window';

import { Spinner } from '../../../components';
import { ColumnResizerIndicator } from './components/ColumnResizer';
import { VirtualTableCell } from './components/VirtualTableCell';
import { COLUMN_DATA_SELECTABLE, HEADER_HEIGHT } from './constants';
import { isDraggingNoRerender } from './hooks/useDndController';
import { useSelectableContext } from './hooks/useSelectableController';
import { addAcceptedDropColumns, calculateColumnWidth } from './utils';

const useVirtualTableController = ({ draftColumns, loadMore }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableSize, setTableSize] = useState(null);

  const { isSelectableContext } = useSelectableContext();
  const loadMoreData = async () => {
    try {
      setIsLoading(true);
      await loadMore();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(() => {
    if (!tableSize) {
      return [];
    }
    const columnsPreItems = [];
    if (isSelectableContext) {
      columnsPreItems.push(COLUMN_DATA_SELECTABLE);
    }
    return columnsPreItems.concat(
      draftColumns.map(column => ({
        ...column,
        width: calculateColumnWidth({ column, columns: draftColumns, tableSize }),
        acceptedDropColumns: addAcceptedDropColumns({ column, columns: draftColumns }),
      }))
    );
  }, [tableSize, draftColumns]);

  return {
    isLoading,
    setIsLoading,
    tableSize,
    setTableSize,
    columns,
    loadMoreData,
  };
};

const Component = ({
  tableData,
  columns: draftColumns,
  rowHeight = 48,
  totalCount,
  isLoading: isTableLoading,
  mapColumnToRow,
  actions = {},
  sortOrder,
}) => {
  const { onClickRow, updateOrder, updateColumnWidth } = actions;

  const { isLoading, tableSize, setTableSize: onResize, columns, loadMoreData } = useVirtualTableController({
    draftColumns,
    ...actions,
  });

  const ref = useRef();
  const outerRef = useRef();

  useEffect(() => {
    if (ref && tableSize) {
      // eslint-disable-next-line no-unused-expressions
      ref.current?.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true,
      });
    }
  }, [tableSize, !!ref.current, columns]);

  useEffect(() => {
    if (sortOrder && outerRef.current) {
      outerRef.current.scrollTo({
        top: 0,
      });
    }
  }, [sortOrder, !!outerRef.current]);

  const onScroll = ({ scrollTop }) => {
    if (isLoading || tableData.length >= totalCount || isDraggingNoRerender) {
      return;
    }
    if (tableData.length * rowHeight - 100 < scrollTop + ref.current?.props.height) {
      loadMoreData();
    }
  };

  const tableRowsIndexes = useMemo(() => tableData.map(row => row.uuid), [tableData]);

  const innerElementType = ({ children, ...rest }) => (
    <div className="virtual-grid_cell-container" {...rest}>
      <DndDropEdgeAreas updateOrder={updateOrder} totalWidth={rest.style.width}>
        <ColumnResizerIndicator
          scrollAreaHeight={rest.style.height}
          tableRef={outerRef}
          tableRefController={ref}
          columns={columns}
          updateColumnWidth={updateColumnWidth}
        />
        <HeaderRow updateOrder={updateOrder} tableChildren={children} columns={columns} />
        {children}
      </DndDropEdgeAreas>
    </div>
  );

  const renderCellData = (rowIndex, columnIndex) => {
    const rowData = tableData[rowIndex];
    const columnData = columns[columnIndex];
    let cellData = rowData[columnData.dataIndex];
    if (mapColumnToRow) {
      cellData = mapColumnToRow(rowData, columnData);
    }
    if (columnData.render) {
      return columnData.render({
        record: cellData,
        index: rowIndex,
      });
    }
    return cellData;
  };

  if (isTableLoading && tableData.length === 0) {
    return (
      <div className="virtual-table">
        <TableInitialLoading loading={isLoading || isTableLoading} />
      </div>
    );
  }

  return (
    <div className={cn('virtual-table', { 'is-data': tableData.length })}>
      {(isTableLoading || isLoading) && <Spinner loading className="virtual-grid_loading" />}
      <AutoSizer onResize={onResize}>
        {() => {
          if (!tableSize) {
            return <></>;
          }
          return (
            <Grid
              overscanColumnCount={5}
              overscanRowCount={10}
              outerRef={outerRef}
              itemCount={totalCount}
              innerElementType={innerElementType}
              ref={ref}
              className="virtual-grid"
              columnCount={columns.length}
              columnWidth={idx => columns[idx].width}
              rowCount={tableData.length}
              rowHeight={index => (index === 0 ? HEADER_HEIGHT : rowHeight)}
              onScroll={onScroll}
              {...tableSize}
            >
              {({ columnIndex, rowIndex, style }) => {
                const { dataIndex } = columns[columnIndex];
                return (
                  <VirtualTableCell
                    rowId={tableData[rowIndex].uuid}
                    tableRowsIndexes={tableRowsIndexes}
                    dataIndex={dataIndex}
                    isLast={columns.length - 1 === columnIndex}
                    isEven={rowIndex % 2 === 0}
                    columnIndex={columnIndex}
                    style={{ ...style, top: style.top + HEADER_HEIGHT }}
                    onClick={() => {
                      onClickRow(tableData[rowIndex].uuid);
                    }}
                  >
                    {renderCellData(rowIndex, columnIndex)}
                  </VirtualTableCell>
                );
              }}
            </Grid>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export const VirtualTable = ({ sortOrder, emptyState, error, ...props }) => {
  let component;
  if (error) {
    component = <div />;
  } else if (!props.isLoading && !props.tableData.length) {
    component = emptyState;
  } else {
    component = <Component {...props} sortOrder={sortOrder} />;
  }
  return (
    <SortTableHOC sortOrder={sortOrder} onSortClick={props.actions?.onSortClick}>
      {component}
    </SortTableHOC>
  );
};
