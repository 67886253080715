import React, { useEffect, useState } from 'react';
import { Button, Input, Popup } from 'components/Common';
import { DeleteOutlined } from '@ant-design/icons';
import { formatSeconds } from 'utils';
import { MEASUREMENT_OPTIONS } from 'constants';
import { EQUIPMENT_TYPES } from 'constants/manualProcess';
import { useSelector } from 'react-redux';
import '../../OptimizationPopover/style.scss';
import './style.scss';
import { equipmentValidation } from 'components/ProcessBuilder/Manual/Sidebars/equipmentValidations';
import cn from 'classnames';

export const ManualLibGenEquipmentOptions = ({ reactorData, onSave, onCancel, isOpen }) => {
  const [newOptions, setNewOptions] = useState([{}]);
  const [errors, setErrors] = useState([]);
  const [initial, setInitial] = useState(false);
  const [deleteOptionConfirmation, openDeleteOptionConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    if (isOpen) {
      let options = [];
      reactorData.temperatures.forEach((t, idx) => {
        options.push({
          time: reactorData.times[idx],
          temperature: t,
        });
      });
      setNewOptions(options);
    }
  }, [reactorData, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setErrors([]);
      setInitial(false);
    } else {
      setInitial(true);
    }
  }, [isOpen]);

  const onChange = (v, type, index) => {
    setInitial(false);
    let _errors = [...errors];
    setNewOptions(
      newOptions.map((option, idx) => {
        if (index === idx) {
          option[type] = v;
          if (_errors[index]) _errors[index][type] = false;
        }
        return option;
      })
    );
    setErrors(_errors);
  };

  const onClose = () => {
    onCancel();
    /*setNewOptions([{}]);*/
  };

  const onAddOption = () => {
    setNewOptions([...newOptions, {}]);
  };

  const onSavePress = () => {
    let hasErrors = validateOptions();
    if (hasErrors) return;
    const newReactorData = {
      ...reactorData,
      temperatures: newOptions.map(i => i.temperature),
      times: newOptions.map(i => i.time),
    };
    newReactorData.variable = newReactorData.times.length > 1 && newReactorData.temperatures.length > 1;
    onSave(newReactorData);
    onClose();
  };

  const validateOptions = () => {
    let _errors = [];
    let hasErrors = false;
    let hours = newOptions.map(option => Math.trunc(option.time / 3600));
    let { obj } = equipmentValidation(
      {
        ...reactorData,
        timeMin: newOptions.map((option, idx) => Math.trunc(option.time / 60 - hours[idx] * 60)),
        timeSec: newOptions.map(option => option.time % 60),
        timeHour: hours,
        temperatures: newOptions.map(option => option.temperature),
      },
      reactorData.otherType
    );
    newOptions.forEach((option, index) => {
      let error = {};
      if (obj.temperatures[index]) {
        error.temperature = obj.temperatures[index];
        hasErrors = true;
      }
      if (obj.times[index]) {
        error.time = obj.times[index];
        hasErrors = true;
      }
      _errors.push(error);
    });
    setErrors(_errors);
    return hasErrors;
  };

  const onDeleteOptions = index => {
    setDeleteIndex(index);
    openDeleteOptionConfirmation(true);
  };

  const onDeleteOptionsAction = () => {
    let newOptionsData = [...newOptions];
    newOptionsData.splice(deleteIndex - 1, 1);
    setNewOptions(newOptionsData);
    setDeleteIndex(-1);
  };

  const type = EQUIPMENT_TYPES[reactorData.type]?.name;

  return (
    <div
      className="optimization-popover manual-input"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div className="optimization-popover_content">
        <h5 className="optimization-popover_title">{type} options</h5>
        {newOptions.map((option, index) => {
          return (
            <ManualLibGenEquipmentPopoverContentOption
              option={option}
              error={errors[index]}
              index={index + 1}
              onDeleteOptions={onDeleteOptions}
              onChange={(v, type) => onChange(v, type, index)}
              initial={initial}
            />
          );
        })}
        {newOptions.length < 5 && (
          <div className="optimization-popover_add_option" onClick={onAddOption}>
            + Add Option
          </div>
        )}
      </div>
      <div className="optimization-popover_footer">
        <Button secondary onClick={onClose} className="optimization-popover_footer_cancel_button">
          Cancel
        </Button>
        <Button onClick={onSavePress}>Save</Button>
      </div>
      <Popup
        open={deleteOptionConfirmation}
        title=" "
        handleSubmit={() => {
          onDeleteOptionsAction();
          openDeleteOptionConfirmation(false);
        }}
        handleCancel={() => {
          openDeleteOptionConfirmation(false);
        }}
        textSubmit="Proceed"
        loading={false}
      >
        <span>Do you want to Delete this option?</span>
      </Popup>
    </div>
  );
};

const ManualLibGenEquipmentPopoverContentOption = ({ option, error, index, onDeleteOptions, onChange, initial }) => {
  const [sec, setSec] = useState(null);
  const [min, setMin] = useState(null);
  const [hour, setHour] = useState(null);

  useEffect(() => {
    if (option.time && initial) {
      let h = Math.trunc(option.time / 3600),
        secs = option.time % 60,
        mins = Math.trunc(option.time / 60) - h * 60;
      setSec(secs);
      setMin(mins);
      setHour(h);
    }
  }, [option.time]);

  useEffect(() => {
    let time = +hour * 3600 + +min * 60 + +sec;
    onChange(time, 'time');
  }, [sec, min, hour]);

  const RoundTime = (e, action = 'mins') => {
    if (action === 'secs') {
      let secs = Number(String(e).replace(/[^0-9 ]/gi, ''));
      if (secs <= 0) secs = 0;
      if (secs > 59) {
        let hours = +hour,
          minutes = +min,
          additionalHours = Math.floor(secs / 3600),
          additionalMinutes = Math.floor(secs / 60) - additionalHours * 60,
          seconds = secs - additionalMinutes * 60 - additionalHours * 3600;
        if (+minutes + additionalMinutes > 59) {
          additionalHours += 1;
          additionalMinutes -= 60;
          seconds = secs - additionalMinutes * 60 - additionalHours * 3600;
        }
        if (hours + additionalHours > 999) {
          additionalHours = 999;
          additionalMinutes = 0;
          hours = 0;
          seconds = 0;
        }
        setHour(+hours + additionalHours);
        setMin(+minutes + additionalMinutes);
        setSec(+seconds);
      } else {
        setSec(secs);
      }
    } else if (action === 'mins') {
      let mins = Number(String(e).replace(/[^0-9 ]/gi, ''));
      if (mins <= 0) mins = 0;
      if (mins > 59) {
        let hours = hour,
          additionalHours = Math.floor(mins / 60),
          minutes = mins - additionalHours * 60;
        if (hours + additionalHours > 999) {
          setHour(999);
          setMin(0);
          setSec(0);
        } else {
          setHour(hours + additionalHours);
          setMin(minutes);
        }
      } else {
        setMin(mins);
      }
    }
  };
  return (
    <div className="manual-libgen-input-option">
      <div className="manual-libgen-input-option__title">
        Option {index}
        {index > 1 && <DeleteOutlined onClick={() => onDeleteOptions(index)} />}
      </div>
      <div className="manual-libgen-input-option__content">
        <div className={cn('manual-libgen-input-option__content__row', { error: error && error.temperature })}>
          <div className="optimization-popover_content_option_content_row_title">Temperature</div>
          <div className="optimization-popover_content_option_content_row_equal-sign">=</div>
          <Input
            value={option.temperature}
            onChange={v => onChange(v, 'temperature')}
            error={error ? error.temperature : null}
            errorText={error ? error.temperature : null}
            type="number"
          />
          <span className="time"> °C</span>
        </div>
        <div className="manual-libgen-input-option__content__row">
          <div className="optimization-popover_content_option_content_row_title">Reaction time</div>
          <div className="optimization-popover_content_option_content_row_equal-sign">=</div>
          <div className="optimization-popover_content_option_content_row_time">
            <Input
              className="input input-time"
              value={Math.round(hour)}
              maxLength={3}
              onChange={e => {
                let str = e.replace(/[^0-9 ]/gi, '');
                if (str < 100000) {
                  setHour(str);
                }
              }}
              error={error?.time}
              errorText={error?.time}
            />{' '}
            <span className="time"> h &nbsp;&nbsp;</span>
            <Input
              className="input input-time"
              value={Math.round(min)}
              maxLength={2}
              onChange={e => {
                let str = e.replace(/[^0-9 ]/gi, '');
                if (e < 100) {
                  RoundTime(e, 'mins');
                }
              }}
              error={error?.time}
            />{' '}
            <span className="time"> min</span>
            <Input
              className="input"
              value={Math.round(sec) || ''}
              maxLength={2}
              error={error?.time}
              onChange={e => {
                if (e < 100) {
                  RoundTime(e, 'secs');
                }
              }}
            />{' '}
            <span className="time"> sec</span>
          </div>
        </div>
      </div>
    </div>
  );
};
