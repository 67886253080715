import './styles.scss';

import React from 'react';

import { TextBody14 } from '@atoms/Typography';
import cn from 'classnames';

export const OptionWithColor = ({ className, label, color, stretchHeight = true }) => (
  <div className={cn('option-with-color', className, { 'stretch-height': stretchHeight })}>
    {color && <div className="option-with-color_color" style={{ background: `${color}` }} />}
    <TextBody14 className="option-with-color_label">{label}</TextBody14>
  </div>
);
