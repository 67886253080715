import { createSelector } from 'reselect';
import { SOLVENT_TYPES, SYNJET_PROCESS_TYPES, DEVICES } from '../../constants';

export const synjectProcess = state => state.synjetReducer.process;
export const synjectProcessSteps = state => state.synjetReducer.steps;
export const synjetRules = state => state.synjetReducer.rules;
export const synjetProducts = state => state.synjetReducer.products;
export const synjetExpectedIntermediates = state => state.synjetReducer.expectedIntermediates || [];
export const synjetQuenching = state => state.synjetReducer.quenching || [];

export const selectEnableQuenching = state => state.synjetReducer.process?.process?.enableQuenching;

export const selectFirstVariableStep = createSelector([synjectProcessSteps], steps => {
  const isFirstVariable = steps.length > 1 && !steps?.[0]?.isFixed;
  if (isFirstVariable) return steps?.[0];
  return null;
});
export const selectAbilityAddCompound = createSelector(
  [synjectProcessSteps, synjectProcess, synjetQuenching],
  (steps, process, quenching) => {
    let compoundSum = 0;
    steps.forEach((step, stepIndex) => {
      const { compounds, conditions } = step;
      if (
        (process?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION || step.isFixed) &&
        !process?.process?.device === DEVICES.SYNJETPRO
      ) {
        compoundSum += compounds.reactants.length + compounds.reagents.length;
      } else {
        compoundSum +=
          compounds.reagentGroups.reduce(
            (sum, currentGroup) =>
              sum +
              currentGroup.compoundsList.filter(
                compound => !compound.isNullCompound && compound.solventType !== SOLVENT_TYPES.SOLID
              ).length,
            0
          ) +
          compounds.reactantGroups.reduce(
            (sum, currentGroup) =>
              sum +
              currentGroup.compoundsList.filter(
                compound => !compound.isNullCompound && compound.solventType !== SOLVENT_TYPES.SOLID
              ).length,
            0
          );
      }
      if (
        conditions?.[0]?.dispensing?.solvent &&
        stepIndex === steps.length - 1 &&
        !process?.process?.device === DEVICES.SYNJETPRO
      ) {
        compoundSum += 1;
      }
    });

    if (process?.process?.enableQuenching) {
      compoundSum += quenching.length;
    }
    return compoundSum < 24;
  }
);

export const conditions = state => state.synjetReducer.conditions;
export const experimentsData = state => state.synjetReducer.processData;
export const errors = store => store.synjetReducer.errors;
export const batchAbortedErrorShown = store => store.synjetReducer.batchAbortedErrorShown;
