import React, { useState, useEffect, useCallback } from 'react';
import { Sidebar, Input, Select, Popup, RadioGroup } from '../../Common';
import './style.scss';
import { ReactorType } from '..';
import { getReactorsTypes } from '../../../store/processBuilder/processbuilder.actions';
import { connect } from 'react-redux';
import { Select as AntdSelect } from 'antd';
import { checkIfTrue } from '../../../utils/common';
import { formatSeconds, httpSynMolDB } from '../../../utils';
import { DeleteOutlined } from '@ant-design/icons';
import { PROJECT_TYPES } from '../../../constants';

const inialErrors = {
  tubular: {
    time: false,
    min: false,
    max: false,
  },
  packed: {
    time: false,
    min: false,
    max: false,
  },
  liquid: {
    flow: false,
    flow_req: false,
  },
};

const initialErrorsOptimization = {
  tubular: {
    times: [false],
    min: [false],
    max: [false],
  },
  packed: {
    times: [false],
    min: [false],
    max: [false],
  },
};

const initialOptimization = {
  tubular: {
    times: [null],
    temperatures: [null],
    variable: false,
  },
  packed: {
    times: [null],
    temperatures: [null],
    variable: false,
  },
};

const SEPARATOR_TYPES = [
  { label: 'Liquid', value: 'liquid' },
  { label: 'Gas', value: 'gas' },
];

const GAS_TYPE = 'Gas-liquid separator';
const CheckIfNull = React.memo(({ data }) => (data === null || data === undefined ? '-' : `${data} °C`));
const SidebarReactors = ({
  open,
  onClose,
  getReactorsTypes,
  reactors,
  setReactorData,
  editingData,
  deleteReactor,
  processData,
  getIndex,
  idPump,
  processTreeData,
  positionToAddReactor,
  setCalcTime,
  getFlowRateForGasPump,
}) => {
  const statusReactors = {
    tubular: true,
    packed: false,
    liquid: false,
  };
  const [selectedReactor, setSelectedReactor] = useState(statusReactors);
  const [min, setMin] = useState('');
  const [sec, setSec] = useState('');
  const [packingOptions, setPackingOptions] = useState([]);
  const [packs, setPacks] = useState([null]);
  const [flow, setFlow] = useState(0);
  const [temperature, setTemperature] = useState(null);
  const [edited, setEdited] = useState(false);

  const [liquidReactorsOptions, setLiquidReactorsOptions] = useState([]);
  const [tubularReactorsOptions, setTubularReactorsOptions] = useState([]);
  const [packedReactorsOptions, setPackedReactorsOptions] = useState([]);
  const [gasReactorsOptions, setGasReactorsOptions] = useState([]);
  const [currentOptions, setCurrentOptions] = useState(tubularReactorsOptions);
  const [cancelConfirmation, openCancelConfirmation] = useState(false);
  const [deleteConfirmation, openDeleteConfirmation] = useState(false);
  const [deleteTimeConfirmation, openDeleteTimeConfirmation] = useState(false);
  const [typeOfProcess, setTypeOfProcess] = useState('Production');

  const [reactor, setReactor] = useState(tubularReactorsOptions[0]);

  const [optimizationData, setOptimizationaData] = useState(initialOptimization);

  const [errorsOptimization, setErrorsOptimizations] = useState(initialErrorsOptimization);

  const [dataVariable, setDataVariable] = useState(null);
  const [isVariable, setIsVariable] = useState(false);
  const [paramsForDelete, setParamsForDelete] = useState([null, null]);

  const [deletedIndexes, setDeletedIndexes] = useState([]);

  const [flowRatesLiquid, setFlowRatesLiquid] = useState([null]);

  const variableReactorExists = !!processTreeData?.find(reactor => reactor.variable);

  const [liquidOptimizationErrors, setLiquidOptimizationErrors] = useState({
    flow: [false],
    flow_req: [false],
  });
  const [isGas, setIsGas] = useState(false);

  useEffect(() => {
    setTypeOfProcess(processData?.process?.type);
  }, [processData]);

  const errorsText = {
    tubular: {
      time: 'Residence time is a required field',
      min: 'Temperature should be >= -20°C',
      max: mess => `Temperature should be <= ${mess}`,
    },
    packed: {
      time: 'Residence time is a required field',
      min: 'Temperature should be >= 20°C',
      max: mess => `Temperature should be <= ${mess}`,
    },
    liquid: {
      flow: 'Flow rate should be > 0',
      flow_req: 'Flow rate is a required field',
    },
  };

  const [errors, setErrors] = useState(inialErrors);

  const reset = () => {
    setMin('');
    setSec('');
    setTemperature('');
    setFlow(0);
    setErrors(inialErrors);
    setFlow(0);
    setPacks([null]);
    setIsGas(false);
    setReactor(tubularReactorsOptions[0]);
    setErrorsOptimizations(initialErrorsOptimization);
    if (!editingData) setOptimizationaData(initialOptimization);
    if (!!editingData) {
      if (!editingData.hasOwnProperty('variable')) {
        setIsVariable(false);
      }
    }
    if (!editingData && !!dataVariable) {
      let arr_time = [];
      setOptimizationaData({
        ...optimizationData,
        tubular: { ...optimizationData.tubular, times: arr_time },
        packed: { ...optimizationData.tubular, times: arr_time },
      });
      setLiquidOptimizationErrors({ flow: arr_time, flow_req: arr_time });
    }
    setDeletedIndexes([]);
    chooseReactor('tubular', tubularReactorsOptions);
  };

  const { Option } = AntdSelect;

  const chooseReactor = (type, reactorType, calcNewTime = false, reactorSelectedType = null) => {
    let temp = selectedReactor;
    setCurrentOptions(reactorType);
    setReactor(reactorSelectedType || reactorType[0]);
    Object.keys(temp).forEach(v => (temp[v] = false));
    setSelectedReactor({
      ...temp,
      [type]: true,
    });
    //calculation for case 2 Production
    if (!!calcNewTime) {
      calcTime(reactorType[0], true);
    }
  };

  const setFlowRates = () => {
    let gasFlowRate = getFlowRateForGasPump();
    setFlow(gasFlowRate[0]);
    if (typeOfProcess === 'Optimization') {
      setFlowRatesLiquid(gasFlowRate);
    }
  };

  const calcTime = (reactorType, newReactor) => {
    if (!reactorType) reactorType = reactor;
    let calc_time = [0];
    let flows_before_adding = [0];
    if (!!positionToAddReactor.position || positionToAddReactor.position === 0) {
      processTreeData.forEach((i, index) => {
        let newReactorHasNoPumps =
          !editingData &&
          (positionToAddReactor.position === processTreeData.length ||
            processTreeData[positionToAddReactor.position].name);
        if (newReactorHasNoPumps ? index < positionToAddReactor.position : index <= positionToAddReactor.position) {
          const filteredPumps = i?.pumps.filter(pump => pump.type[0] !== 'MFC');
          if (filteredPumps?.length > 0) {
            let length = !!filteredPumps[0].flow_rate ? filteredPumps[0].flow_rate.length : 1;
            for (let k = 0; k < length; k++) {
              if (!flows_before_adding[k] && flows_before_adding[k] !== 0) flows_before_adding.push(0);
              filteredPumps.forEach(pump => {
                if (i.type === 'liquid') {
                  flows_before_adding[k] -= +pump.flow_rate[k];
                } else {
                  flows_before_adding[k] += +pump.flow_rate[k];
                }
                if (flows_before_adding[k] < 0) flows_before_adding[k] = 0;
              });
            }
          }
        }
      });
    }
    let v = getIndex();
    /*let length = !!processTreeData[0]?.pumps[0]?.flow_rate
      ? processTreeData[0]?.pumps[0]?.flow_rate.length
      : v?.times?.length;*/
    let length = v?.times?.length || 1;
    for (let k = 0; k < length; k++) {
      if (calc_time[k] !== 0) calc_time[k] = 0;
      calc_time[k] = Math.round((reactorType?.volume / +flows_before_adding[k]) * 60);
    }
    if (!!calc_time[0] || calc_time[0] === 0) {
      if (!editingData) setCalcTime(calc_time);
      RoundTime(calc_time[0], false);
      formatTimeOpen(calc_time, reactorType, newReactor);
    }
  };

  useEffect(() => {
    if (editingData && selectedReactor[editingData.type] && edited) {
      setDataToEdit();
    }
  }, [JSON.stringify(selectedReactor)]);

  const RoundTime = (e, mins = true) => {
    let secs = Number(String(e).replace(/[^0-9 ]/gi, ''));
    if (secs <= 0) secs = 0;
    if (secs > 59) {
      let minutes = Math.floor(secs / 60);
      let seconds = secs - minutes * 60;
      if (secs + min >= 100000) {
        setMin(99999);
        setSec(0);
      } else {
        setMin((mins ? +min : 0) + minutes);
        setSec(seconds);
      }
    } else {
      setSec(secs);
    }
  };

  useEffect(() => {
    getReactorsTypes();
    httpSynMolDB(`get_compound_list?tag=catalyst`).then(resp => {
      setPackingOptions(compareOptions(resp.compound_list, 'compound_name'));
    });
  }, []);

  useEffect(() => {
    if (!!temperature) {
      let react;
      Object.entries(selectedReactor).forEach(([key, value]) => {
        if (value) {
          react = key;
        }
      });
      validation(react, false);
    }
  }, [reactor]);

  const setDataToEdit = () => {
    setFlow(editingData.flow_rate || 0);
    if (editingData.type === 'gas') {
      setFlow(editingData.pumps[0]?.flow_rate[0] || 0);
    }
    setTemperature(editingData.temperatures ? editingData.temperatures[0] : null);
    if (editingData.catalysts?.length) {
      setPacks(editingData.catalysts.map(i => i.compound_name));
    }
    if (editingData.times?.length) {
      let formatedSeconds = formatSeconds(editingData.times[0]);
      setMin(formatedSeconds.min);
      setSec(formatedSeconds.sec);
    }
    if (editingData.type === 'packed') {
      setOptimizationaData({ ...optimizationData, packed: editingData });
    } else if (editingData.type === 'tubular') {
      setOptimizationaData({ ...optimizationData, tubular: editingData });
    }
  };

  useEffect(() => {
    setEdited(false);
    if (editingData) {
      let b;
      if (editingData.type == 'tubular') {
        b = tubularReactorsOptions;
      } else if (editingData.type == 'packed') {
        b = packedReactorsOptions;
      } else if (editingData.type == 'liquid') {
        b = liquidReactorsOptions;
      } else if (editingData.type === 'gas') {
        b = gasReactorsOptions;
        setIsGas(true);
      }

      if (
        (editingData.variable || !variableReactorExists) &&
        processData?.process?.type === PROJECT_TYPES.OPTIMIZATION
      ) {
        setIsVariable(true);
      }
      if (typeOfProcess === 'Optimization' && editingData.type === 'liquid') {
        setFlowRatesLiquid(editingData.pumps[0] ? editingData.pumps[0].flow_rate : editingData.flow_rate);
      }
      if (typeOfProcess === 'Optimization' && editingData.type === 'gas') {
        setFlowRatesLiquid(getFlowRateForGasPump());
      }
      chooseReactor(
        editingData.type === 'gas' ? 'liquid' : editingData.type,
        b,
        false,
        b.find(i => i.key === editingData.key)
      );
      setDataToEdit();
    } else {
      chooseReactor('tubular', tubularReactorsOptions, true);
      setFlow(0);
      setTemperature();
      setPacks([null]);
      setMin('');
      setSec('');
      setIsVariable(false);
    }
  }, [editingData]);

  useEffect(() => {
    let result = [];
    if (reactors.allReactors) {
      result = compareOptions(
        reactors?.allReactors.filter(i => {
          return i.type == 'Tubular reactor';
        }),
        'key'
      );

      setTubularReactorsOptions(result);
      setCurrentOptions(result);
      setReactor(result[0]);
      result = compareOptions(
        reactors?.allReactors.filter(i => {
          return i.type == 'Packed bed reactor';
        }),
        'key'
      );

      setPackedReactorsOptions(result);

      result = compareOptions(
        reactors?.allReactors.filter(i => {
          return i.type === 'Liquid-liquid separator';
        }),
        'key'
      );

      setLiquidReactorsOptions(result);

      result = compareOptions(
        reactors?.allReactors.filter(i => {
          return i.type === 'Gas-liquid separator';
        }),
        'key'
      );

      setGasReactorsOptions(result);
    }
  }, [reactors]);

  useEffect(() => {
    if (!open) {
      setErrors(inialErrors);
      reset();
      return;
    }
    let v = getIndex();
    setDataVariable(v);
    if (!!v && !editingData) {
      let arr_time = [];
      let arr_temp = [null];
      for (let i = 0; i < v.times.length; i++) {
        arr_time[i] = null;
      }
      // for (let i = 0; i < v.temperatures.length; i++) {
      //   arr_temp[i] = null;
      // }

      setOptimizationaData({
        ...optimizationData,
        tubular: { ...optimizationData.tubular, temperatures: arr_temp, times: arr_time },
        packed: { ...optimizationData.tubular, temperatures: arr_temp, times: arr_time },
      });
      setFlowRatesLiquid(arr_time);
      calcTime(null, true);
    }
    if (!v && !editingData) {
      setOptimizationaData({
        ...optimizationData,
        tubular: { ...optimizationData.tubular, temperatures: [null], times: [null] },
        packed: { ...optimizationData.tubular, temperatures: [null], times: [null] },
      });
      calcTime(null, true);
    }
  }, [open]);

  const compareOptions = (temp, field) => {
    for (let i in temp) {
      temp[i].value = temp[i][field];
      temp[i].label = temp[i][field];
    }
    return temp;
  };

  const onSave = () => {
    let react;
    Object.entries(selectedReactor).forEach(([key, value]) => {
      if (value) {
        react = key;
      }
    });
    let isInvalid;
    if (
      (typeOfProcess === 'Optimization' && react !== 'liquid') ||
      (typeOfProcess === 'Optimization' && react === 'liquid' && dataVariable)
    ) {
      isInvalid = validationOptimization(react);
    } else if (
      typeOfProcess === 'Production' ||
      typeOfProcess === 'Library generation' ||
      (typeOfProcess === 'Optimization' && react === 'liquid' && !dataVariable)
    ) {
      isInvalid = validation(react);
    }

    isInvalid = isGas ? false : isInvalid;
    let obj = {};
    if (!isInvalid) {
      setEdited(false);
      let compounds = editingData
        ? {
            properties: [...editingData.properties],
          }
        : {
            properties: [
              {
                products: [],
                sideproducts: [],
              },
            ],
          };
      let pumps = editingData ? editingData.pumps : [];
      if (
        (editingData?.type === 'liquid' &&
          (react !== 'liquid' || (react === 'liquid' && reactor?.type === GAS_TYPE))) ||
        (editingData?.type === 'gas' && reactor?.type !== GAS_TYPE)
      ) {
        editingData.pumps.splice(0, 1);
      }
      if (
        (reactor?.type === 'Liquid-liquid separator' && (!editingData || editingData?.type !== 'liquid')) ||
        (reactor?.type === GAS_TYPE && (!editingData || editingData?.type !== 'gas')) ||
        ((reactor?.type === GAS_TYPE || reactor?.type === 'Liquid-liquid separator') && !pumps.length)
      ) {
        pumps = [
          {
            name: 'Pump 1',
            properties: [
              {
                reactants: [],
                reactants_consentration: [],
                reagents: [],
                reagents_consentration: [],
                solvents: [],
                solvents_fraction: [],
              },
            ],
            type: ['MGAT'],
            flow_rate: [flow],
            flash_solvent: null,
          },
          ...pumps,
        ];
      }

      if (react === 'tubular') {
        if (typeOfProcess === 'Production' || typeOfProcess === 'Library generation') {
          obj = {
            key: reactor.value,
            name: 'Reactor 1',
            type: 'tubular',
            title: 'tubular',
            times: [Number(min * 60) + Number(sec)],
            temperatures: [temperature],
            catalysts: [],
            pumps,
            pressure: null,
            ...compounds,
          };
        } else if (typeOfProcess === 'Optimization') {
          obj = {
            key: reactor.value,
            name: 'Reactor 1',
            type: 'tubular',
            title: 'tubular',
            times: optimizationData.tubular.times.sort((a, b) => a - b),
            temperatures: optimizationData.tubular.temperatures.sort((a, b) => a - b),
            catalysts: [],
            pumps,
            pressure: null,
            variable: isVariable,
            ...compounds,
          };
        }
        if (
          (optimizationData.tubular.times.length > 1 || optimizationData.tubular.temperatures.length > 1) &&
          !dataVariable
        ) {
          obj.variable = true;
        }
        let last_iteration = false;
        if (!!deletedIndexes.length) {
          if (
            optimizationData.tubular.times.length - deletedIndexes.length < 1 &&
            optimizationData.tubular.temperatures.length - deletedIndexes.length < 1
          ) {
            obj.variable = false;
            last_iteration = true;
          }
        }
        setReactorData(obj, deletedIndexes, last_iteration);
        setDeletedIndexes([]);
      } else if (react === 'liquid') {
        const isGas = reactor.type === GAS_TYPE;
        if (typeOfProcess === 'Production' || typeOfProcess === 'Library generation') {
          pumps[0].flow_rate = [flow];
          obj = {
            key: reactor.value,
            name: 'Reactor 1',
            title: 'separator',
            type: isGas ? 'gas' : 'liquid',
            times: [],
            temperatures: [],
            properties: [
              {
                products: [],
                sideproducts: [],
              },
            ],
            catalysts: [],
            flow_rate: flow,
            pumps,
            pressure: null,
          };
        } else if (typeOfProcess === 'Optimization') {
          let f = !dataVariable ? [flow] : flowRatesLiquid;
          pumps[0].flow_rate = f;
          obj = {
            name: 'Reactor 1',
            key: reactor.value,
            title: 'separator',
            type: isGas ? 'gas' : 'liquid',
            times: [],
            temperatures: [],
            properties: [
              {
                products: [],
                sideproducts: [],
              },
            ],
            catalysts: [],
            flow_rate: f,
            pumps,
            pressure: null,
          };
        }
        setReactorData(obj);
        setDeletedIndexes([]);
      } else if (react === 'packed') {
        let last_iteration = false;
        if (typeOfProcess === 'Production' || typeOfProcess === 'Library generation') {
          obj = {
            name: 'Reactor 1',
            key: reactor.value,
            title: 'packed bed',
            type: 'packed',
            times: [Number(min * 60) + Number(sec)],
            temperatures: [temperature],
            catalysts: packs.map(i => packingOptions.find(o => o.compound_name == i)).filter(i => !!i),
            flow_rate: flow,
            pressure: null,
            pumps,
            ...compounds,
          };
        } else if (typeOfProcess === 'Optimization') {
          obj = {
            name: 'Reactor 1',
            key: reactor.value,
            title: 'packed bed',
            type: 'packed',
            times: optimizationData.packed.times.sort((a, b) => a - b),
            temperatures: optimizationData.packed.temperatures.sort((a, b) => a - b),
            catalysts: packs.map(i => packingOptions.find(o => o.compound_name == i)).filter(i => !!i),
            flow_rate: flow,
            pressure: null,
            variable: isVariable,
            pumps,
            ...compounds,
          };
          if (
            (optimizationData.packed.times.length > 1 || optimizationData.packed.temperatures.length > 1) &&
            !dataVariable
          ) {
            obj.variable = true;
          }

          //if delete last option
          if (!!deletedIndexes.length) {
            if (
              optimizationData.packed.times.length - deletedIndexes.length < 1 &&
              optimizationData.packed.temperatures.length - deletedIndexes.length < 1
            ) {
              obj.variable = false;
              last_iteration = true;
            }
          }
        }
        setReactorData(obj, deletedIndexes, last_iteration);
        setDeletedIndexes([]);
      }
      !editingData && reset();
    }
  };

  const validation = (type, withTime = true) => {
    let obj = {
      tubular: {
        time: withTime ? Number(min * 60) + Number(sec) === 0 : false,
        min: temperature < -20 || !temperature,
        max: temperature > reactor.maxTemperature || !temperature,
      },
      packed: {
        time: withTime ? Number(min * 60) + Number(sec) === 0 : false,
        min: temperature < 20 || !temperature,
        max: temperature > reactor.maxTemperature || !temperature,
      },
      liquid: {
        flow: !!(flow == 0 && flow !== ''),
        flow_req: flow === '',
      },
    };
    setErrors(obj);
    return checkIfTrue(obj[type]);
  };

  const validationOptimization = type => {
    let error = false;
    let obj;
    if (type !== 'liquid') {
      let temp =
        optimizationData[type].times.length > optimizationData[type].temperatures.length
          ? optimizationData[type].times.length
          : optimizationData[type].temperatures.length;
      obj = initialErrorsOptimization;
      for (let i = 0; i < temp; i++) {
        let min_tubular = [...obj.tubular.min];
        if (optimizationData[type].temperatures.length > i)
          min_tubular[i] = optimizationData[type].temperatures[i] < -20 || !optimizationData[type].temperatures[i];

        let min_packed = [...obj.packed.min];
        if (optimizationData[type].temperatures.length > i)
          min_packed[i] = optimizationData[type].temperatures[i] < 20 || !optimizationData[type].temperatures[i];

        let max = [...obj.tubular.max];
        if (optimizationData[type].temperatures.length > i)
          max[i] =
            optimizationData[type].temperatures[i] > reactor.maxTemperature || !optimizationData[type].temperatures[i];

        let times = [...obj[type].times];
        if (optimizationData[type].times.length > i) {
          times[i] = !optimizationData[type].times[i];
        }

        if (times[i] || (type === 'packed' && min_packed[i]) || (type === 'tubular' && min_tubular[i]) || max[i])
          error = true;
        obj = {
          tubular: {
            times,
            min: min_tubular,
            max: max,
          },
          packed: {
            times,
            min: min_packed,
            max: max,
          },
        };
      }
      setErrorsOptimizations(obj);
    } else if (type === 'liquid') {
      let temp = dataVariable?.times?.length || 0;
      obj = {};
      let flows = [...flowRatesLiquid];
      let flow_req = [...flowRatesLiquid];
      for (let i = 0; i < temp; i++) {
        if (flowRatesLiquid.length > i) {
          if (flowRatesLiquid[i] == null) {
            flow_req[i] = true;
          } else if (flowRatesLiquid[i] <= 0) {
            flows[i] = true;
          } else {
            flow_req[i] = false;
            flows[i] = false;
          }
        }
        if (!flowRatesLiquid[i]) error = true;
        obj = {
          liquid: {
            flow: flows,
            flow_req,
          },
        };
      }
      setLiquidOptimizationErrors(obj['liquid']);
    }
    return error;
  };

  const canSave = (edited && editingData) || !editingData;

  const showOptionsTubular = !dataVariable || isVariable;
  const showOptionsPacked = !dataVariable || isVariable;
  const isMoreThanFiveTemperatures = editingData?.temperatures?.length >= 5;
  const isMoreThanFiveTimes = editingData?.times?.length >= 5;

  const onCloseSidebar = () => {
    if (!edited) onClose();
    else openCancelConfirmation(true);
  };

  const formatTime = (e, index, type) => {
    type = returnReactorType(type);
    if (!type) return;
    if (!isFinite(e)) e = 0;
    let new_time = optimizationData[type] ? [...optimizationData[type].times] : [];
    new_time[index] = formatSeconds(optimizationData[type].times[index]).min * 60 + Number(e);
    setOptimizationaData({ ...optimizationData, [type]: { ...optimizationData[type], times: new_time } });
    setEdited(true);
  };

  const formatTimeOpen = (calc, type, newReactor = false) => {
    type = returnReactorType(type);
    if (!type) return;
    let new_time = optimizationData[type] ? [...optimizationData[type].times] : [];
    for (let i = 0; i < calc.length; i++) {
      if (!new_time[i]) new_time[i] = 0;
      if (!isFinite(calc[i])) calc[i] = new_time[i];
      let num = !!optimizationData[type] ? optimizationData[type].times[i] : 0;
      new_time[i] = /*formatSeconds(num).min * 60 + */ Number(calc[i]);
    }
    let ifRecalculation = new_time.some(i => i !== 0);
    if (newReactor || ifRecalculation)
      setOptimizationaData({
        ...optimizationData,
        [type]: {
          ...optimizationData[type],
          times: new_time,
          temperatures: ifRecalculation && !newReactor ? optimizationData[type].temperatures : [null],
        },
      });
  };

  const changeTemperature = (e, type, index) => {
    let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
    if (((str || !e) && e < 100000) || e === '-') {
      e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 2) : e;
      let new_temperature = [...optimizationData[type].temperatures];
      new_temperature[index] = e;
      setOptimizationaData({
        ...optimizationData,
        [type]: { ...optimizationData[type], temperatures: new_temperature },
      });
      setEdited(true);
    }
    if (e == '-') {
      let new_temperature = [...optimizationData[type].temperatures];
      new_temperature[index] = e;
      setOptimizationaData({
        ...optimizationData,
        [type]: { ...optimizationData[type], temperatures: new_temperature },
      });
      setEdited(true);
    }

    let replaceMin = [...errorsOptimization[type].min];
    let replaceMax = [...errorsOptimization[type].max];
    replaceMin[index] = false;
    replaceMax[index] = false;
    setErrorsOptimizations({
      ...errorsOptimization,
      [type]: { ...errorsOptimization[type], min: replaceMin, max: replaceMax },
    });
  };

  const deleteTemperature = (type, index) => {
    if (!!optimizationData[type].temperatures[index]) {
      setEdited(true);
    }

    let arr = optimizationData[type].temperatures;
    arr.splice(index, 1);
    setOptimizationaData({ ...optimizationData, [type]: { ...optimizationData[type], temperatures: arr } });
    let arr_min = errorsOptimization[type].min;
    let arr_max = errorsOptimization[type].max;
    setEdited(true);
    if (!!arr_min) {
      arr_min.splice(index, 1);
      setErrorsOptimizations({
        ...errorsOptimization,
        [type]: { ...errorsOptimization[type], min: arr_min },
      });
    }
    if (!!arr_max) {
      arr_max.splice(index, 1);
      setErrorsOptimizations({
        ...errorsOptimization,
        [type]: { ...errorsOptimization[type], max: arr_max },
      });
    }
  };

  const deleteTime = (type, index) => {
    if (!!optimizationData[type].times[index]) {
      setEdited(true);
    }
    let arr = [...optimizationData[type].times];
    arr.splice(index, 1);
    setOptimizationaData({
      ...optimizationData,
      [type]: { ...optimizationData[type], times: arr },
    });

    let arr1 = errorsOptimization[type].times;
    if (!!arr1) {
      arr1.splice(index, 1);
      setErrorsOptimizations({
        ...errorsOptimization,
        [type]: { ...errorsOptimization[type], times: arr1 },
      });
    }

    let arr_del = [...deletedIndexes];
    arr_del.push(index);
    setDeletedIndexes(arr_del);
  };

  const returnReactorType = reactorType => {
    if (!reactorType) return '';
    if (reactorType.type === 'Tubular reactor') return 'tubular';
    else if (reactorType.type === 'Packed bed reactor') return 'packed';
    else if (reactorType.type === GAS_TYPE) return 'gas';
    else if (reactorType.type === 'Packed bed reactor') return 'liquid';
    return reactorType;
  };

  const getReactorSelectorPlaceholder = useCallback(() => {
    return (
      <div className="custom-option">
        <div className="name-reactor">{reactor?.label}</div>
        <div className="second-row">
          <div className="parameter">Material: {reactor?.material ? `${reactor?.material}` : '-'}</div>
          <div className="parameter">Volume: {reactor?.volume ? `${reactor?.volume} ml` : '-'}</div>
          <div className="parameter">
            Max temperature: <CheckIfNull data={reactor?.maxTemperature} />
          </div>
        </div>
      </div>
    );
  }, [reactor]);

  const handleReactorTypeChange = value => {
    const val = reactors.allReactors.find(i => i.key === value);
    setEdited(true);
    setReactor(val);
    calcTime(val, false);
  };

  const onChangeSeparatorType = value => {
    setIsGas(value === 'gas');
    setEdited(true);
    if (value === 'gas') {
      setFlowRates();
      setCurrentOptions(gasReactorsOptions);
      setReactor(gasReactorsOptions[0]);
    } else {
      setFlow(0);
      setCurrentOptions(liquidReactorsOptions);
      setReactor(liquidReactorsOptions[0]);
    }
  };

  return (
    <>
      <Sidebar
        width={528}
        open={open}
        onClose={onCloseSidebar}
        onSave={onSave}
        onDelete={deleteReactor}
        disableSave={!canSave}
        editingData={editingData}
        title={`${editingData ? 'Edit' : 'Add'} a Reactor`}
        saveButtonText={`${editingData ? 'Save' : 'Add reactor'}`}
        cancelText={edited ? 'Cancel' : 'Close'}
      >
        <div className="reactor">
          <ReactorType
            title="Tubular"
            type="tubular"
            status={selectedReactor['tubular'] ? 'active' : 'passive'}
            onClick={() => {
              reset();
              chooseReactor('tubular', tubularReactorsOptions, true);
              setEdited(true);
            }}
          />
          <ReactorType
            title="Packed bed"
            type="packed"
            status={selectedReactor['packed'] ? 'active' : 'passive'}
            onClick={() => {
              reset();
              chooseReactor('packed', packedReactorsOptions, true);
              setEdited(true);
            }}
          />
          <ReactorType
            title="Separator"
            type="liquid"
            status={selectedReactor['liquid'] ? 'active' : 'passive'}
            onClick={() => {
              reset();
              chooseReactor('liquid', isGas ? gasReactorsOptions : liquidReactorsOptions, true);
              setEdited(true);
            }}
          />
        </div>
        {reactors.allReactors && (
          <div className="sidebar-reactor">
            {selectedReactor['liquid'] && (
              <div className="separator-choice">
                <div className="separator-label">Separator type</div>
                <RadioGroup
                  options={SEPARATOR_TYPES}
                  setValue={onChangeSeparatorType}
                  field="separator"
                  value={isGas ? 'gas' : 'liquid'}
                />
              </div>
            )}
            {currentOptions.length && (
              <Select
                key={currentOptions[0].label}
                value={reactor?.key || 'R1'}
                placeholder={getReactorSelectorPlaceholder()}
                onChange={handleReactorTypeChange}
                optionFilterProp="children"
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {currentOptions.map(i => (
                  <Option value={i.label} label={i.label} key={i.label}>
                    <div className="custom-option">
                      <div className="name-reactor">{i.label}</div>
                      <div className="second-row">
                        <div className="parameter">Material: {i.material ? `${i.material}` : '-'}</div>
                        <div className="parameter">Volume: {i.volume ? `${i.volume} ml` : '-'}</div>
                        <div className="parameter">
                          Max temperature: <CheckIfNull data={i.maxTemperature} />
                        </div>
                      </div>
                    </div>
                  </Option>
                ))}
              </Select>
            )}
            <div className="parameters">
              <div className="parameters-title">Set experimental parameters</div>
              {selectedReactor['tubular'] && (
                <>
                  {(typeOfProcess === 'Production' || typeOfProcess === 'Library generation') && (
                    <>
                      <div className="parameters-digits">
                        <div className="first-column column">
                          <div className="req-row">
                            <div className="column-title temperature">
                              Temperature <div className="required">*</div>
                            </div>
                          </div>
                          <div className="req-row">
                            <div className="column-title residence">
                              Residence time <div className="required">*</div>
                            </div>
                          </div>
                        </div>
                        <div className="second-column column">
                          <div className="row">
                            <Input
                              className="input"
                              placeholder={`-20 - ${reactor?.maxTemperature}`}
                              value={temperature}
                              allowSpecials
                              onChange={e => {
                                let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                                if (((str || !e) && e < 100000) || e === '-') {
                                  e =
                                    e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 2) : e;
                                  setTemperature(e);
                                  setEdited(true);
                                }
                                setErrors({ ...errors, tubular: { ...errors.tubular, min: false, max: false } });
                              }}
                              error={errors.tubular.min || errors.tubular.max}
                              errorText={
                                (errors.tubular.min && errorsText.tubular.min) ||
                                (errors.tubular.max && errorsText.tubular.max(reactor?.maxTemperature))
                              }
                            />{' '}
                            <span className="temperature-symb">°C</span>
                          </div>
                          <div className="first-row">
                            <Input
                              className="input input-time"
                              value={Math.round(min)}
                              error={errors.tubular.time}
                              maxLength={5}
                              onChange={e => {
                                let str = e.replace(/[^0-9 ]/gi, '');
                                if (str < 100000) {
                                  setMin(str);
                                  setEdited(true);
                                  setErrors({ ...errors, tubular: { ...errors.tubular, time: false } });
                                }
                              }}
                              errorText={errorsText.tubular.time}
                            />{' '}
                            <span className="time"> min</span>
                            <Input
                              className="input"
                              error={errors.tubular.time}
                              value={Math.round(sec) || ''}
                              maxLength={2}
                              onChange={e => {
                                if (e < 100) {
                                  RoundTime(e);
                                  setEdited(true);
                                  setErrors({ ...errors, tubular: { ...errors.tubular, time: false } });
                                }
                              }}
                            />{' '}
                            <span className="time"> sec</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {typeOfProcess === 'Optimization' && (
                    <>
                      <div className="parameters-digits optimization">
                        <div className="temeperature-block">
                          <div className="temperature-item item">
                            <div className="wrapper">
                              {optimizationData.tubular.temperatures.length &&
                                optimizationData.tubular.temperatures.map((i, index) => (
                                  <div className="block-row">
                                    <div className="req-row">
                                      <div className="req-row">
                                        <div className="column-title temperature">
                                          Temperature, T{index + 1}
                                          <div className="required">*</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="block-row">
                                      {!!reactor && (
                                        <Input
                                          className="input"
                                          placeholder={`-20 - ${reactor?.maxTemperature}`}
                                          allowSpecials
                                          value={i}
                                          onChange={e => {
                                            changeTemperature(e, 'tubular', index);
                                          }}
                                          error={
                                            errorsOptimization.tubular.min[index] ||
                                            errorsOptimization.tubular.max[index]
                                          }
                                          errorText={
                                            (errorsOptimization.tubular.min[index] && errorsText.tubular.min) ||
                                            (errorsOptimization.tubular.max[index] &&
                                              errorsText.tubular.max(reactor?.maxTemperature))
                                          }
                                        />
                                      )}{' '}
                                      <span className="unit">°C</span>
                                    </div>
                                    {optimizationData.tubular.temperatures.length > 1 && showOptionsTubular && (
                                      <div className={'delete-option'}>
                                        <DeleteOutlined
                                          onClick={() => {
                                            openDeleteConfirmation(true);
                                            setParamsForDelete(['tubular', index]);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              {showOptionsTubular && !isMoreThanFiveTemperatures && (
                                <div
                                  className="add-option"
                                  onClick={() => {
                                    let temp = [...optimizationData.tubular.temperatures];
                                    temp.push(null);
                                    setOptimizationaData({
                                      ...optimizationData,
                                      tubular: { ...optimizationData.tubular, temperatures: temp },
                                    });
                                  }}
                                >
                                  + add option
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="reasidense-time_block">
                          <div className="time-item item">
                            <div className="wrapper">
                              {!!optimizationData.tubular.times.length &&
                                optimizationData.tubular.times.map((i, index) => (
                                  <div className="block-row">
                                    <div className="req-row">
                                      <div className="column-title time">
                                        Residence time, t{index + 1}
                                        <div className="required">*</div>
                                      </div>
                                    </div>
                                    <div className="block-row">
                                      <Input
                                        className="input input-time"
                                        value={
                                          Math.round(formatSeconds(optimizationData.tubular.times[index]).min) || ''
                                        }
                                        error={errorsOptimization.tubular.times[index]}
                                        onChange={e => {
                                          let str = e ? e.replace(/[^0-9 ]/gi, '') : '';
                                          if (str < 100000) {
                                            let new_time = [...optimizationData.tubular.times];
                                            new_time[index] =
                                              e * 60 + formatSeconds(optimizationData.tubular.times[index]).sec;
                                            setOptimizationaData({
                                              ...optimizationData,
                                              tubular: { ...optimizationData.tubular, times: new_time },
                                            });
                                            setEdited(true);
                                            let replace = [...errorsOptimization.tubular.times];
                                            replace[index] = false;
                                            setErrorsOptimizations({
                                              ...errorsOptimization,
                                              tubular: { ...errorsOptimization.tubular, times: replace },
                                            });
                                          }
                                        }}
                                        errorText={errorsText.tubular.time}
                                      />{' '}
                                      <span className="unit unit-space"> min</span>
                                      <Input
                                        className="input"
                                        error={errorsOptimization.tubular.times[index]}
                                        value={
                                          Math.round(formatSeconds(optimizationData.tubular.times[index]).sec) || ''
                                        }
                                        onChange={e => {
                                          if (e < 100) {
                                            formatTime(e, index, 'tubular');
                                            setEdited(true);
                                            let replace = [...errorsOptimization.tubular.times];
                                            replace[index] = false;
                                            setErrorsOptimizations({
                                              ...errorsOptimization,
                                              tubular: { ...errorsOptimization.tubular, times: replace },
                                            });
                                          }
                                        }}
                                      />{' '}
                                      <span className="unit"> sec</span>
                                    </div>
                                    {optimizationData.tubular.times.length > 1 && showOptionsTubular && (
                                      <div className={'delete-option'}>
                                        <DeleteOutlined
                                          onClick={() => {
                                            openDeleteTimeConfirmation(true);
                                            setParamsForDelete(['tubular', index]);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              {showOptionsTubular && !isMoreThanFiveTimes && (
                                <div
                                  className="add-option"
                                  onClick={() => {
                                    let temp = [...optimizationData.tubular.times];
                                    temp.push(0);
                                    setOptimizationaData({
                                      ...optimizationData,
                                      tubular: { ...optimizationData.tubular, times: temp },
                                    });
                                  }}
                                >
                                  + add option
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {selectedReactor['packed'] && (
                <>
                  {(typeOfProcess === 'Production' || typeOfProcess === 'Library generation') && (
                    <>
                      <div className="parameters-digits">
                        <div className="first-column column">
                          <div className="column-title temperature">Temperature</div>
                          <div className="column-title residence">Residence time</div>
                          <div className="column-title residence">Packing</div>
                        </div>
                        <div className="second-column column">
                          <div className="row">
                            <Input
                              className="input"
                              placeholder={`20 - ${reactor?.maxTemperature}`}
                              value={temperature}
                              allowSpecials
                              onChange={e => {
                                let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                                if ((str || !e) && e < 100000) {
                                  e =
                                    e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 2) : e;
                                  setTemperature(e);
                                  setEdited(true);
                                }
                                setErrors({ ...errors, packed: { ...errors.packed, min: false, max: false } });
                              }}
                              error={errors.packed.min || errors.packed.max}
                              errorText={
                                (errors.packed.min && errorsText.packed.min) ||
                                (errors.packed.max && errorsText.packed.max(reactor?.maxTemperature))
                              }
                            />
                            <span className="temperature-symb">°C</span>
                          </div>
                          <div className="first-row before-packings">
                            <Input
                              className="input"
                              error={errors.packed.time}
                              value={Math.round(min)}
                              onChange={e => {
                                let str = e.replace(/[^0-9 ]/gi, '');
                                if (str < 100000) {
                                  setMin(str);
                                  setEdited(true);
                                }
                              }}
                              errorText={errorsText.packed.time}
                            />{' '}
                            <span className="time"> min</span>
                            <Input
                              className="input"
                              error={errors.packed.time}
                              value={Math.round(sec)}
                              onChange={e => {
                                if (e < 100) {
                                  RoundTime(e);
                                  setEdited(true);
                                }
                              }}
                            />
                            <span className="time"> sec</span>
                          </div>
                          <div className="packing-block">
                            {packs.length &&
                              packs.map((i, index) => (
                                <div className="packing-wrapper">
                                  <Select
                                    className="packing-select"
                                    placeholder="Select the packing"
                                    options={packingOptions}
                                    value={i}
                                    onChange={e => {
                                      setEdited(true);
                                      let new_packs = [...packs];
                                      new_packs[index] = e;
                                      setPacks(new_packs);
                                    }}
                                  />
                                  <div className={'delete-packing'}>
                                    <DeleteOutlined
                                      onClick={() => {
                                        let new_packs = [...packs];
                                        new_packs.splice(index, 1);
                                        if (packs.length > 1) {
                                          setPacks(new_packs);
                                        } else {
                                          setPacks([null]);
                                        }
                                        setEdited(true);
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            <div
                              className="add-packing"
                              onClick={() => {
                                let temp = [...packs];
                                temp.push(null);
                                setPacks(temp);
                                setEdited(true);
                              }}
                            >
                              + add packing
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {typeOfProcess === 'Optimization' && (
                    <>
                      <div className="parameters-digits optimization">
                        <div className="temeperature-block">
                          <div className="temperature-item item">
                            <div className="wrapper">
                              {optimizationData.packed.temperatures.length &&
                                optimizationData.packed.temperatures.map((i, index) => (
                                  <div className="block-row">
                                    <div className="req-row">
                                      <div className="column-title temperature">
                                        Temperature, T{index + 1}
                                        <div className="required">*</div>
                                      </div>
                                    </div>
                                    <div className="block-row">
                                      <Input
                                        className="input"
                                        placeholder={`20 - ${reactor?.maxTemperature}`}
                                        value={i}
                                        allowSpecials
                                        onChange={e => {
                                          changeTemperature(e, 'packed', index);
                                        }}
                                        error={
                                          errorsOptimization.packed.min[index] || errorsOptimization.packed.max[index]
                                        }
                                        errorText={
                                          (errorsOptimization.packed.min[index] && errorsText.packed.min) ||
                                          (errorsOptimization.packed.max[index] &&
                                            errorsText.packed.max(reactor?.maxTemperature))
                                        }
                                      />{' '}
                                      <span className="unit">°C</span>
                                    </div>
                                    {optimizationData.packed.temperatures.length > 1 && showOptionsPacked && (
                                      <div className={'delete-option'}>
                                        <DeleteOutlined
                                          onClick={() => {
                                            openDeleteConfirmation(true);
                                            setParamsForDelete(['packed', index]);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              {showOptionsPacked && !isMoreThanFiveTemperatures && (
                                <div
                                  className="add-option"
                                  onClick={() => {
                                    let temp = [...optimizationData.packed.temperatures];
                                    temp.push(null);
                                    setOptimizationaData({
                                      ...optimizationData,
                                      packed: { ...optimizationData.packed, temperatures: temp },
                                    });
                                  }}
                                >
                                  + add option
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="reasidense-time_block">
                          <div className="time-item item">
                            <div className="wrapper">
                              {!!optimizationData.packed.times.length &&
                                optimizationData.packed.times.map((i, index) => (
                                  <div className="block-row">
                                    <div className="req-row">
                                      <div className="column-title time">
                                        Residence time, t{index + 1}
                                        <div className="required">*</div>
                                      </div>
                                    </div>
                                    <div className="block-row">
                                      <Input
                                        className="input input-time"
                                        value={
                                          Math.round(formatSeconds(optimizationData.packed.times[index]).min) || ''
                                        }
                                        error={errorsOptimization.packed.times[index]}
                                        onChange={e => {
                                          let str = e ? e.replace(/[^0-9 ]/gi, '') : '';
                                          if (str < 100000) {
                                            let new_time = [...optimizationData.packed.times];
                                            new_time[index] =
                                              e * 60 + formatSeconds(optimizationData.packed.times[index]).sec;
                                            setOptimizationaData({
                                              ...optimizationData,
                                              packed: { ...optimizationData.packed, times: new_time },
                                            });
                                            setEdited(true);
                                            let replace = [...errorsOptimization.packed.times];
                                            replace[index] = false;
                                            setErrorsOptimizations({
                                              ...errorsOptimization,
                                              packed: { ...errorsOptimization.packed, times: replace },
                                            });
                                          }
                                        }}
                                        errorText={errorsText.packed.time}
                                      />{' '}
                                      <span className="unit unit-space"> min</span>
                                      <Input
                                        className="input"
                                        error={errorsOptimization.packed.times[index]}
                                        value={
                                          Math.round(formatSeconds(optimizationData.packed.times[index]).sec) || ''
                                        }
                                        onChange={e => {
                                          if (e < 100) {
                                            formatTime(e, index, 'packed');
                                            setEdited(true);
                                            let replace = [...errorsOptimization.packed.times];
                                            replace[index] = false;
                                            setErrorsOptimizations({
                                              ...errorsOptimization,
                                              packed: { ...errorsOptimization.packed, times: replace },
                                            });
                                          }
                                        }}
                                      />{' '}
                                      <span className="unit"> sec</span>
                                    </div>
                                    {optimizationData.packed.times.length > 1 && showOptionsPacked && (
                                      <div className={'delete-option'}>
                                        <DeleteOutlined
                                          onClick={() => {
                                            openDeleteTimeConfirmation(true);
                                            setParamsForDelete(['packed', index]);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              {showOptionsPacked && !isMoreThanFiveTimes && (
                                <div
                                  className="add-option"
                                  onClick={() => {
                                    let temp = [...optimizationData.packed.times];
                                    temp.push(0);
                                    setOptimizationaData({
                                      ...optimizationData,
                                      packed: { ...optimizationData.packed, times: temp },
                                    });
                                  }}
                                >
                                  + add option
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="packing-block">
                          <div className="packing-row">
                            <div className="column-title packing">Packing</div>
                            <div className="packings-list">
                              {packs.length &&
                                packs.map((i, index) => (
                                  <div className="packing-wrapper">
                                    <Select
                                      className="packing-select"
                                      placeholder="Select the packing"
                                      options={packingOptions}
                                      value={i}
                                      onChange={e => {
                                        setEdited(true);
                                        let new_packs = [...packs];
                                        new_packs[index] = e;
                                        setPacks(new_packs);
                                      }}
                                    />
                                    <div className={'delete-packing'}>
                                      <DeleteOutlined
                                        onClick={() => {
                                          let new_packs = [...packs];
                                          new_packs.splice(index, 1);
                                          if (packs.length > 1) {
                                            setPacks(new_packs);
                                          } else {
                                            setPacks([null]);
                                          }
                                          setEdited(true);
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div
                            className="add-packing"
                            onClick={() => {
                              let temp = [...packs];
                              temp.push(null);
                              setPacks(temp);
                              setEdited(true);
                            }}
                          >
                            + add packing
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {selectedReactor['liquid'] && (
                <>
                  {(typeOfProcess === 'Production' || typeOfProcess === 'Library generation' || !dataVariable) && (
                    <div className="parameters-digits">
                      <div className="first-column column">
                        <div className="req-row">
                          <div className="column-title temperature">
                            Flow rate <div className="required"> *</div>
                          </div>
                        </div>
                      </div>
                      <div className="second-column column">
                        <div className="first-row liquid">
                          <Input
                            className="input input-flow"
                            value={flow}
                            disabled={isGas}
                            error={isGas ? false : errors.liquid.flow || errors.liquid.flow_req}
                            onChange={e => {
                              let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                              if ((str || !e) && e < 1000) {
                                e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 4) : e;
                                setFlow(e);
                                setEdited(true);
                                setErrors({ ...errors, liquid: { ...errors.liquid, flow: false, flow_req: false } });
                              }
                            }}
                            errorText={
                              (errors.liquid.flow && errorsText.liquid.flow) ||
                              (errors.liquid.flow_req && errorsText.liquid.flow_req)
                            }
                          />
                          <span className="time">mL/min</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {typeOfProcess === 'Optimization' &&
                    !!dataVariable &&
                    flowRatesLiquid.length &&
                    flowRatesLiquid.map((i, index) => (
                      <div className="parameters-digits">
                        <div className="first-column column">
                          <div className="req-row">
                            <div className="column-title temperature">
                              {`Flow rate, Q${idPump}${index + 1}`}
                              <div className="required">*</div>
                            </div>
                          </div>
                        </div>
                        <div className="second-column column">
                          <div className="first-row liquid">
                            <Input
                              className="input input-flow-mass"
                              value={i}
                              disabled={isGas}
                              error={
                                isGas
                                  ? false
                                  : liquidOptimizationErrors.flow[index] || liquidOptimizationErrors.flow_req[index]
                              }
                              onChange={e => {
                                let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                                if ((str || !e) && e < 1000) {
                                  e =
                                    e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 4) : e;
                                  let arr = [...flowRatesLiquid];
                                  arr[index] = e;
                                  setFlowRatesLiquid(arr);
                                  setEdited(true);
                                  let replace = liquidOptimizationErrors;
                                  replace.flow[index] = false;
                                  replace.flow_req[index] = false;
                                  setLiquidOptimizationErrors({ flow: replace.flow, flow_req: replace.flow_req });
                                }
                              }}
                              errorText={
                                (liquidOptimizationErrors.flow[index] && errorsText.liquid.flow) ||
                                (liquidOptimizationErrors.flow_req[index] && errorsText.liquid.flow_req)
                              }
                            />
                            <span className="time">mL/min</span>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        )}
      </Sidebar>
      <Popup
        open={cancelConfirmation}
        title="Warning"
        handleSubmit={() => {
          onClose();
          reset();
          openCancelConfirmation(false);
        }}
        handleCancel={() => {
          openCancelConfirmation(false);
        }}
        textSubmit="Proceed"
        loading={false}
      >
        <span>All changes will be lost, proceed?</span>
      </Popup>
      <Popup
        open={deleteConfirmation}
        title="Warning"
        handleSubmit={() => {
          openDeleteConfirmation(false);
          deleteTemperature(paramsForDelete[0], paramsForDelete[1]);
        }}
        handleCancel={() => {
          openDeleteConfirmation(false);
        }}
        textSubmit="Delete"
        loading={false}
      >
        <span>Do you want to Delete this option?</span>
      </Popup>
      <Popup
        open={deleteTimeConfirmation}
        title="Delete Option"
        handleSubmit={() => {
          openDeleteTimeConfirmation(false);
          deleteTime(paramsForDelete[0], paramsForDelete[1]);
        }}
        handleCancel={() => {
          openDeleteTimeConfirmation(false);
        }}
        textSubmit="Delete"
        loading={false}
      >
        <span>Do you want to Delete this option?</span>
      </Popup>
    </>
  );
};

const mapStateToProps = store => {
  return {
    reactors: store.processbuilderReducer.reactors,
  };
};

export default connect(mapStateToProps, {
  getReactorsTypes,
})(SidebarReactors);
