import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from '../../../Common';
export const columns = (processPreview, twoSteps) => {
  const columnsArray = [
    {
      title: 'Temperature, C°',
      dataIndex: 'temperature',
      key: 'temperature',
      align: 'right',
      width: processPreview ? 130 : 160,
      render: text => text || '-',
    },
    {
      title: 'Vials',
      dataIndex: 'vials',
      key: 'vials',
      align: 'right',
      width: processPreview ? 50 : 160,
      render: text => text || '-',
    },
    {
      title: 'Heating blocks, QTY',
      dataIndex: 'heatBlocks',
      key: 'heatBlocks',
      align: 'right',
      width: twoSteps && processPreview ? 170 : twoSteps ? 200 : processPreview ? 180 : 210,
      render: (text, data) => {
        return (
          <span className="column-content">
            {text || '-'}
            {data?.isWarning && (
              <Tooltip
                title="Capacity of the heating area is 8 blocks max"
                overlayClassName="synjet-process-error-tooltip"
              >
                <InfoCircleOutlined className="total-icon" />
              </Tooltip>
            )}
          </span>
        );
      },
    },
  ];
  const dataColumns = twoSteps
    ? [
        ...columnsArray,
        {
          title: '',
          dataIndex: 'fixed',
          key: 'fixed',
          align: 'right',
          width: processPreview ? 130 : 160,
          render: text => text || '',
        },
      ]
    : columnsArray;
  return dataColumns;
};
