import React from 'react';
import { Tooltip } from '../../Common';
import CompoundSmilesPreview from '../../CompoundSmilesPreview/CompoundSmilesPreview';
import { InfoCircleOutlined } from '@ant-design/icons';
import { convertTime } from '../../../utils';

export const getCoefficientColumns = (arr_columns, interpolated, showEmptyTooltip) => {
  return arr_columns.map((item, index) => {
    // title: item,
    return {
      title: item.label ? (
        <>
          <span className="capitalize ellipsis" title={item.label}>
            {item.label}
          </span>
          {(item.compound || (!item.compound && showEmptyTooltip)) && (
            <Tooltip
              title={
                <CompoundSmilesPreview
                  compound={item?.compound}
                  options={{ width: 130, height: 80 }}
                  hideMW
                  showDivider
                  showEmptyCompound={showEmptyTooltip}
                />
              }
              overlayClassName="synjet-experiment-table_header_tooltip"
            >
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          )}
          <br />
          m/z {item.observ && Number(item.observ).toFixed(3)}{' '}
          {item?.interval_end && `(${convertTime(item.interval_start)} - ${convertTime(item.interval_end)})`}
        </>
      ) : (
        item
      ),
      dataIndex: item.label ? item.label : item,
      key: item.label ? item.label : item,
      sorter: !!interpolated
        ? (a, b) => {
            return 0;
          }
        : false,
      width: index === 0 ? '3%' : !interpolated && index === 1 ? '20%' : '10%',
      render: (data, row) => {
        return {
          props: {
            style: {
              backgroundColor: interpolated ? !!row[data.label].color && `#${row[data.label].color}BF` : '',
            },
          },
          children: data.value,
        };
      },
    };
  });
};
