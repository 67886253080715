import React, { useMemo } from 'react';

import cn from 'classnames';

import { Switch } from '../../../../../Common/Switch';
import { SmilesElem } from '../../../../PrepareMaterials/SmilesElem';
import { setFixed } from '../../SolidsTable';

const useColumns = ({ handleChangeSwitch, tableData, ...props }) =>
  useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '20%',
        render: (value, row, index, ...other) => {
          const obj = {
            children: <div className={row.className}>&nbsp;</div>,
          };

          if (row.name.value) {
            obj.children = (
              <SmilesElem
                id={`manual-${row.name.value}-${index}`}
                data={row.name.value}
                displayLoupe
                isNeededTitle={false}
                customOptions={{ height: 80, width: 120 }}
              />
            );
          }
          return obj;
        },
      },
      {
        title: '',
        dataIndex: 'compound_name',
        width: '0',
        render: (value, row) => {
          const obj = {
            children: <div className={row.className}>&nbsp;</div>,
          };
          obj.children = (
            <div className="lv-solutions-table_compound-name">
              <span>{value.name}</span>
              <span>{value.formula}</span>
            </div>
          );

          return obj;
        },
      },
      {
        title: 'Vial #',
        dataIndex: 'vial_number',
        width: '10%',
        render: (value, row) => {
          const obj = {
            children: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value.value.map(i => (
                  <div className={cn('lv-solutions-table_vial', row.className)}>{i}</div>
                ))}
              </div>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Volume, uL',
        dataIndex: 'volume',
        width: '10%',
        render: (value, row) => {
          const obj = {
            children: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value.value.map(i => (
                  <div className={cn('lv-solutions-table_vial', row.className)}>{setFixed(i)}</div>
                ))}
              </div>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Location',
        dataIndex: 'location',
        width: '10%',
        render: (value, row, index, ...other) => {
          const obj = {
            children: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value.value.map(i => (
                  <div className={cn('lv-solutions-table_location', row.className)}>{i}</div>
                ))}
              </div>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Done',
        dataIndex: 'has_sent',
        width: '10%',
        render: (val, row, index, ...other) => {
          const obj = {
            children: (
              <div className={row.className}>
                <Switch checked={row.has_sent?.value} onChange={value => handleChangeSwitch(value, index, row)} />
              </div>
            ),
          };
          return obj;
        },
      },
    ],
    [tableData]
  );

export default useColumns;
