import React, { useEffect, useState } from 'react';
import { Collapse } from './Collapse';
import './style.scss';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as actionsTypes from '../../../store/synjet/synjet.actionTypes';

export const ProcessItemCollapse = ({ items, editMode, useStoreUpdate = false }) => {
  const [activeKey, setActiveKeys] = useState(editMode ? 1 : items.map(i => i.key));
  const dispatch = useDispatch();
  const setBatchErrorShown = () => ({ type: actionsTypes.UPDATE_STATE_COLLAPSE });

  const onChange = keys => {
    if (useStoreUpdate) dispatch(setBatchErrorShown());
    setActiveKeys(keys);
  };

  useEffect(() => {
    setActiveKeys(editMode ? 1 : items.map(i => i.key));
  }, [editMode]);

  return (
    <Collapse
      className={cn('process-item-collapse', { 'view-mode': !editMode })}
      onChange={onChange}
      items={items.map(i => {
        if (!i.body) i.body = i.emptyState;
        i.title = (
          <>
            {i.mainTitle}
            {i.numberOfItems ? <span className="process-item-collapse__number-of-items">{i.numberOfItems}</span> : ''}
            {i.stepTitle && <div className="process-item-collapse__step">{i.stepTitle}</div>}
          </>
        );
        return i;
      })}
      activeKey={activeKey}
    />
  );
};
