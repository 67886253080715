import React from 'react';
import { Button, Popup } from '../../../Common';
import { MassOfInterestTable } from '../../../AnalyticalSettings/MassOfInterestTable';
import { mapCompoundToAnalyticDataToFront } from '../../../../utils/experimentHelpers';
import './style.scss';

export const MassOfInterestPreviewModal = ({ open, handleClose, data, experimentName, analyticalType }) => {
  const tableData = data ? mapCompoundToAnalyticDataToFront(data) : null;

  return (
    <Popup
      open={open}
      handleCancel={handleClose}
      width={'max-content'}
      title="Online analysis"
      className={'online-analysis-popup'}
      footerComponent={
        <div>
          <Button type="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
      }
      onCancel={handleClose}
    >
      <p className="online-analysis-popup__text">Method: {analyticalType?.lenth ? 'LC-MC' : 'Other'}</p>
      <p className="online-analysis-popup__text">Experiment: {experimentName}</p>
      {tableData && <MassOfInterestTable previewMode tableData={tableData} newCompounds={[]} />}
    </Popup>
  );
};
