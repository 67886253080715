import { DEVICES } from '../../src/constants';
import { DISPENSER_COUNT_ERROR, MAX_DISPENSER_COUNT } from '../constants/execution';

export const validateDispenserCount = dispenserOptions =>
  dispenserOptions?.filter(dispenser => !dispenser.is_manually)?.length <= MAX_DISPENSER_COUNT;

export const setDispenserCountError = dispenserOptions => {
  const automaticallyDispensers = dispenserOptions.filter(dispenser => !dispenser.is_manually);
  return automaticallyDispensers.map((dispenser, index) =>
    index > MAX_DISPENSER_COUNT - 1 ? { ...dispenser, validationError: DISPENSER_COUNT_ERROR } : dispenser
  );
};

export const getAppendedFilesReactions = (type, resultData) => {
  const products = JSON.parse(resultData.productsInfo);
  const t = resultData.attachments?.filter(i => i.type === type) || [];
  const reactions = products.map(product => {
    if (product?.attachments?.length) {
      const reactionsAttachments = product.attachments.map(attachment => {
        const file = t.find(i => i.uuid === attachment);
        return {
          ...file,
          loaded: true,
          path: file.file,
        };
      });
      return reactionsAttachments;
    }
    return [{}];
  });
  return reactions;
};

// TODO: remove duplicates
const getMappedBatchConfiguration = config =>
  config?.schedule.reduce((acc, cur) => {
    const id = `${cur.experiment_uuid},${cur.step + 1}`;
    let vials;

    if (id in acc) {
      vials = [...acc[id], cur];
    } else {
      vials = [cur];
    }

    return {
      ...acc,
      [id]: vials,
    };
  }, {});

const getMappedExecutionData = experimentsData => {
  let result = {};

  experimentsData.forEach(exp => {
    const data = exp.execution?.executionSteps.reduce((acc, cur) => {
      const id = `${exp.uuid},${cur.step[0]}`;
      const offset = cur.vialsOffset;

      return {
        ...acc,
        [id]: [...(id in acc ? acc[id] : []), `${cur.uuid},${offset}`],
      };
    }, {});

    result = {
      ...result,
      ...data,
    };
  });

  return result;
};

export const getExecutionIdToVialNameDeps = (batch, experiment) => {
  if (!batch.configuration) return {};
  const fullBatchConfig = JSON.parse(batch.configuration);
  let batchConfig;
  let executionData;
  if (experiment?.timeSlot?.device?.type == DEVICES.SYNJETPRO) {
    batchConfig = getMappedBatchConfiguration(fullBatchConfig);
    executionData = getMappedExecutionData(batch.experiments);
  } else {
    batchConfig = { ...fullBatchConfig };
    executionData = [...batch.experiments];
  }
  const unionData = Object.keys(batchConfig).reduce((acc, curId) => {
    const data = batchConfig[curId].reduce((prev, vial, index) => {
      if (!(curId in executionData)) return prev;

      let prepareData = {};

      executionData[curId].forEach(item => {
        const executionId = item?.split(',')?.[0];
        const offset = Number(item?.split(',')?.[1]);

        const vialName = offset ? fullBatchConfig?.schedule[index + offset].name : vial?.name;

        prepareData = {
          ...prepareData,
          [`${executionId},${vial?.index}`]: `${curId},${vialName}`,
        };
      });

      return {
        ...prev,
        ...prepareData,
      };
    }, {});

    return {
      ...acc,
      ...data,
    };
  }, {});
  return getExpWithCalibrationTemperature(experiment, unionData);
};

const getExpWithCalibrationTemperature = (exp, executionIdToVialNameDeps) =>
  Object.fromEntries(Object.entries(executionIdToVialNameDeps).filter(item => item[1].split(',')?.[0] === exp?.uuid));

// const setExperimentData = (experiments, uuid) => {
//   setSelectedExperimentData(experiments.find(experiment => experiment.uuid === uuid));
// };

const updateProcessStepWithTemp = (data, id, temp) => {
  if (id) {
    const result = data;

    const splitData = id.split(',');
    const obj = {
      step: splitData[1],
      vialName: splitData[2],
    };

    const vialIdInArr = result[obj.step - 1].step.vials.findIndex(vial => Number(vial.name) === Number(obj.vialName));
    result[obj.step - 1].step.vials[vialIdInArr].calibrationTemperature = temp;

    return result;
  } else return;
};

export const withCalibrationTemperature = (proccesStepsData, experiment, executionIdToVialNameDeps) => {
  const result = proccesStepsData;
  const executionSteps = experiment?.execution?.executionSteps;
  if (!executionSteps) return null;
  executionSteps.forEach(execData => {
    if (!execData.calibrationTemperatures) return;
    const vialIdxs = Object.keys(JSON.parse(execData.calibrationTemperatures));
    const vialTemprs = Object.values(JSON.parse(execData.calibrationTemperatures));
    vialIdxs.forEach((vialIndex, index) => {
      const id = `${execData?.uuid},${vialIndex}`;
      updateProcessStepWithTemp(result, executionIdToVialNameDeps[id], vialTemprs[index]);
    });
  });

  return result;
};
