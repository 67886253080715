import { getExperimentsBySearch } from 'store/experiment/experiment.actions';
import { getListUsersBySearch } from 'store/users/users.actions';
import { httpSynMolDB } from 'utils';

import { FIELD_DATA_TYPES } from '@organisms';

export const getUsers = search =>
  getListUsersBySearch(1, 30, search).then(
    resp =>
      resp?.map(process => ({
        value: process.uuid,
        label: `${process?.firstName} ${process?.lastName}`,
      })) || []
  );

export const getExperiments = search =>
  getExperimentsBySearch({ search, searchType: 'name' }, { page: 1, pageSize: 30 }).then(resp =>
    resp?.map(process => ({
      value: process.uuid,
      label: `${process?.name}`,
    }))
  );
export const getSmiles = search =>
  httpSynMolDB(`get_compound_name_list?substring=${encodeURIComponent(search)}`).then(resp => {
    if (resp?.compound_name_list) {
      return resp.compound_name_list.slice(0, 20).map(i => ({ label: i.compound_name, value: i.compound_id }));
    }
    return httpSynMolDB(`get_compound?smiles=${encodeURIComponent(search)}`).then(res => {
      if (res?.compound) {
        return [{ label: res.compound?.compound_name || res.compound?.smiles, value: res.compound.compound_id }];
      }
      return [];
    });
  });

export const validateNumeric = num => {
  const parsedValue = parseInt(num.replace(/[^0-9|-]/g, ''));
  if (typeof parsedValue === 'number' && !Number.isNaN(parsedValue)) {
    return `${parsedValue}`;
  }
  if (num === '-') {
    return num;
  }
  return '';
};

export const validateDecimal = num => {
  const isNegative = num[0] === '-';
  const parsedValue = parseFloat(`${num}`.replace(/[^0-9|.]/g, ''));
  if (typeof parsedValue === 'number' && !Number.isNaN(parsedValue)) {
    return `${isNegative ? '-' : ''}${parsedValue}`;
  }
  if (num === '-') {
    return num;
  }
  return '';
};

export const validateNumberDependOnDataType = ({ dataType, value }) => {
  if (dataType === FIELD_DATA_TYPES.numeric) {
    return validateNumeric(value);
  }
  if (dataType === FIELD_DATA_TYPES.decimal) {
    return validateDecimal(value);
  }
  return value;
};
