import React, { useState, useEffect } from 'react';
import { Sidebar, Select, Input, Popup } from '../../../Common';
import './style.scss';
import { INITIAL_EQUIPMENT, EQUIPMENT_TYPES, PROJECT_TYPES, ATMOSPHERE_OPTIONS } from '../../../../constants';
import { checkIfTrue } from '../../../../utils';
import { Confirmations } from './Confirmations';
import cn from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import { equipmentValidation } from './equipmentValidations';

const INITIAL_ERRORS = {
  temperatures: [false],
  times: [false],
  atmosphere: false,
  atmosphereAdditional: false,
  pressure: false,
  otherType: false,
};

const EquipmentIcon = ({ selected, type, onSelect }) => {
  const Icon = EQUIPMENT_TYPES[type].icon;
  return (
    <div className={cn('equipment-icon', { selected })} onClick={onSelect}>
      <Icon />
      <div className="equipment-icon__name">{EQUIPMENT_TYPES[type].name}</div>
    </div>
  );
};

const EquipmentDataItem = ({ name, children, className = '' }) => {
  return (
    <div className={cn('equipment-sidebar__values__row', className)}>
      <div className="equipment-sidebar__values__row__name">{name}</div>
      <div className="equipment-sidebar__values__row__content">{children}</div>
    </div>
  );
};

export const SidebarEquipment = ({ open, onClose, editingData, onSaveEquipment, onDelete, processType }) => {
  const [edited, setEdited] = useState(false);
  const [errors, setErrors] = useState(INITIAL_ERRORS);
  const [cancelConfirmation, openCancelConfirmation] = useState(false);
  const [deleteConfirmation, openDeleteConfirmation] = useState(false);
  const [deleteOptionConfirmation, openDeleteOptionConfirmation] = useState(false);

  const [equipment, setEquipment] = useState({ ...INITIAL_EQUIPMENT });
  const [showOther, setShowOther] = useState(false);
  const [otherType, setOtherType] = useState('');

  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [deleteType, setDeleteType] = useState(null);

  useEffect(() => {}, []);

  useEffect(() => {
    if (open) {
      if (!editingData) {
        reset();
      } else {
        let mins = [];
        let secs = [];
        let hours = [];
        editingData.times.forEach(time => {
          let h = Math.trunc(time / 3600);
          secs.push(time % 60);
          mins.push(Math.trunc(time / 60) - h * 60);
          hours.push(h);
        });
        setEquipment({
          ...editingData,
          timeHour: hours,
          timeMin: mins,
          timeSec: secs,
        });
        if (editingData.otherType) setOtherType(editingData.otherType);
      }
    } else reset();
  }, [open]);

  const reset = () => {
    setEquipment({ ...INITIAL_EQUIPMENT });
    setErrors({ ...INITIAL_ERRORS });
    setOtherType('');
    setEdited(false);
  };

  const onChangeField = (field, value, index = null) => {
    if (index === null) {
      setEquipment({ ...equipment, [field]: value });
      setErrors({ ...errors, [field]: '' });
    } else {
      let v = [...equipment[field]];
      v[index] = value;
      setEquipment({ ...equipment, [field]: v });

      let err = [...errors[field === 'timeMin' || field === 'timeHour' || field === 'timeSec' ? 'times' : field]];
      err[index] = '';
      setErrors({
        ...errors,
        [field === 'timeMin' || field === 'timeHour' || field === 'timeSec' ? 'times' : field]: err,
      });
    }
    setEdited(true);
  };

  const onSave = () => {
    if (validation()) {
      const equipmentObject = {
        ...equipment,
        type: equipment.type,
        times: equipment.timeHour.map(
          (i, index) => +i * 3600 + +equipment.timeMin[index] * 60 + +equipment.timeSec[index]
        ),
        temperatures: equipment.temperatures,
        name: equipment.type === 'other' ? otherType : EQUIPMENT_TYPES[equipment.type].name,
        properties: editingData ? editingData.properties : [{}],
        otherType: equipment.type === 'other' ? otherType : null,
      };
      onSaveEquipment(equipmentObject);
    }
  };

  const validation = () => {
    let validationEquip = equipmentValidation(equipment, otherType);
    setErrors(validationEquip.obj);
    return validationEquip.isValid;
  };

  const onSelectType = type => {
    setEquipment({ ...INITIAL_EQUIPMENT, type });
    setErrors({ ...INITIAL_ERRORS });
  };

  const RoundTime = (e, action = 'mins', index) => {
    if (action === 'secs') {
      let secs = Number(String(e).replace(/[^0-9 ]/gi, ''));
      if (secs <= 0) secs = 0;
      if (secs > 59) {
        let hours = +equipment.timeHour[index],
          minutes = +equipment.timeMin[index],
          additionalHours = Math.floor(secs / 3600),
          additionalMinutes = Math.floor(secs / 60) - additionalHours * 60,
          seconds = secs - additionalMinutes * 60 - additionalHours * 3600;
        if (+minutes + additionalMinutes > 59) {
          additionalHours += 1;
          additionalMinutes -= 60;
          seconds = secs - additionalMinutes * 60 - additionalHours * 3600;
        }
        if (hours + additionalHours > 999) {
          additionalHours = 999;
          additionalMinutes = 0;
          hours = 0;
          seconds = 0;
        }
        setEquipment({
          ...equipment,
          timeHour: equipment.timeHour.map((h, idx) => (idx === index ? +hours + additionalHours : h)),
          timeMin: equipment.timeMin.map((m, idx) => (idx === index ? +minutes + additionalMinutes : m)),
          timeSec: equipment.timeSec.map((s, idx) => (idx === index ? +seconds : s)),
        });
      } else {
        setEquipment({
          ...equipment,
          timeSec: equipment.timeSec.map((s, idx) => (idx === index ? secs : s)),
        });
      }
    } else if (action === 'mins') {
      let mins = Number(String(e).replace(/[^0-9 ]/gi, ''));
      if (mins <= 0) mins = 0;
      if (mins > 59) {
        let hours = +equipment.timeHour[index],
          additionalHours = Math.floor(mins / 60),
          minutes = mins - additionalHours * 60;
        if (hours + additionalHours > 999) {
          setEquipment({
            ...equipment,
            timeHour: equipment.timeHour.map((h, idx) => (idx === index ? 999 : h)),
            timeMin: equipment.timeMin.map((m, idx) => (idx === index ? 0 : m)),
            timeSec: equipment.timeMin.map((s, idx) => (idx === index ? 0 : s)),
          });
        } else {
          setEquipment({
            ...equipment,
            timeHour: equipment.timeHour.map((h, idx) => (idx === index ? hours + additionalHours : h)),
            timeMin: equipment.timeMin.map((m, idx) => (idx === index ? minutes : m)),
          });
        }
      } else {
        setEquipment({
          ...equipment,
          timeMin: equipment.timeMin.map((m, idx) => (idx === index ? mins : m)),
        });
      }
    }
    let err = [...errors.times];
    err[index] = '';
    setErrors({ ...errors, times: err });
  };

  const onAddTemperature = () => {
    let addTime = processType === PROJECT_TYPES.LIBRARY_GENERATION;
    setEquipment({
      ...equipment,
      temperatures: [...equipment.temperatures, null],
      timeMin: addTime ? [...equipment.timeMin, null] : [...equipment.timeMin],
      timeSec: addTime ? [...equipment.timeSec, null] : [...equipment.timeSec],
      timeHour: addTime ? [...equipment.timeHour, null] : [...equipment.timeHour],
    });
  };

  const onAddTime = () => {
    setEquipment({
      ...equipment,
      timeMin: [...equipment.timeMin, null],
      timeSec: [...equipment.timeSec, null],
      timeHour: [...equipment.timeHour, null],
    });
  };

  const onDeleteOptions = (type, index) => {
    setDeleteIndex(index);
    setDeleteType(type);
    openDeleteOptionConfirmation(true);
  };

  const onDeleteOptionsAction = () => {
    let type = deleteType,
      index = deleteIndex;

    let newTemperatures = [...equipment.temperatures],
      newTimesMin = [...equipment.timeMin],
      newTimesSec = [...equipment.timeSec],
      newTimesHour = [...equipment.timeHour];
    let deleteTime = processType === PROJECT_TYPES.LIBRARY_GENERATION;

    if (type === 'temperatures') {
      newTemperatures.splice(index, 1);
    }
    if (type === 'times' || deleteTime) {
      newTimesMin.splice(index, 1);
      newTimesSec.splice(index, 1);
      newTimesHour.splice(index, 1);
    }
    setEquipment({
      ...equipment,
      temperatures: newTemperatures,
      timeMin: newTimesMin,
      timeSec: newTimesSec,
      timeHour: newTimesHour,
    });
    setDeleteIndex(-1);
    setDeleteType(null);
  };

  const onCancel = () => {
    if (edited) openCancelConfirmation(true);
    else onClose();
  };

  const showTempTimeLabels = processType !== PROJECT_TYPES.PRODUCTION;

  return (
    <>
      <Sidebar
        width={528}
        open={open}
        onClose={onCancel}
        onSave={onSave}
        onDelete={() => openDeleteConfirmation(true)}
        disableSave={false}
        editingData={editingData}
        title={`Add equipment`}
        saveButtonText={`${editingData ? 'Save' : 'Set'}`}
        cancelText={edited ? 'Cancel' : 'Close'}
      >
        <div className="equipment-sidebar">
          <div className="equipment-sidebar__types">
            {Object.keys(EQUIPMENT_TYPES).map((type, index) => {
              return (
                <EquipmentIcon
                  type={type}
                  selected={equipment.type === type}
                  key={index}
                  onSelect={() => onSelectType(type)}
                />
              );
            })}
          </div>
          <div className="equipment-sidebar__values">
            {equipment.type === 'other' && (
              <EquipmentDataItem name="Equipment type" className="equipment-type">
                <Input
                  value={otherType}
                  onChange={e => {
                    setOtherType(e);
                    setErrors({ ...errors, otherType: false });
                  }}
                  className="other"
                  placeholder="Type here"
                  error={errors.otherType}
                  errorText={errors.otherType}
                  maxLength={50}
                />
              </EquipmentDataItem>
            )}
            <div className="equipment-sidebar__values__title">Set experimental parameters</div>
            {
              <>
                {equipment.temperatures.map((i, index) => {
                  return (
                    <>
                      {processType === PROJECT_TYPES.LIBRARY_GENERATION && (
                        <div className="option-name libgen">
                          option {index + 1}
                          {index > 0 && processType === PROJECT_TYPES.LIBRARY_GENERATION && (
                            <DeleteOutlined onClick={() => onDeleteOptions('temperatures', index)} />
                          )}
                        </div>
                      )}
                      <EquipmentDataItem
                        name={showTempTimeLabels ? `Temperature, T${index + 1}` : 'Temperature'}
                        key={index}
                      >
                        <Input
                          value={equipment.temperatures[index]}
                          onChange={v => onChangeField('temperatures', v, index)}
                          placeholder={EQUIPMENT_TYPES[equipment.type].temperaturePlaceholder}
                          type="number"
                          error={errors.temperatures[index]}
                          errorText={errors.temperatures[index]}
                          formatter={value => {
                            if (value.toString().indexOf('.') > -1 && value.toString().length <= 6) {
                              return value.toString().substr(0, value.toString().indexOf('.') + 2);
                            }
                            return value.toString().substr(0, 6);
                          }}
                        />
                        <span className="measure"> °C</span>
                        {index > 0 && processType === PROJECT_TYPES.OPTIMIZATION && (
                          <DeleteOutlined onClick={() => onDeleteOptions('temperatures', index)} />
                        )}
                      </EquipmentDataItem>
                      {processType === PROJECT_TYPES.LIBRARY_GENERATION && (
                        <EquipmentDataItem
                          name={showTempTimeLabels ? `Reaction time, t${index + 1}` : 'Reaction time'}
                          className="reaction-time"
                        >
                          <Input
                            className="input input-time"
                            value={Math.round(equipment.timeHour[index])}
                            maxLength={3}
                            onChange={e => {
                              let str = e.replace(/[^0-9 ]/gi, '');
                              if (str < 100000) {
                                onChangeField('timeHour', str, index);
                                setEdited(true);
                              }
                            }}
                            error={errors.times[index]}
                            errorText={errors.times[index]}
                          />{' '}
                          <span className="time"> h &nbsp;&nbsp;</span>
                          <Input
                            className="input input-time"
                            value={Math.round(equipment.timeMin[index])}
                            maxLength={2}
                            onChange={e => {
                              let str = e.replace(/[^0-9 ]/gi, '');
                              if (str < 100000) {
                                RoundTime(e, 'mins', index);
                                setEdited(true);
                              }
                            }}
                            error={errors.times[index]}
                          />{' '}
                          <span className="time"> min</span>
                          <Input
                            className="input"
                            value={Math.round(equipment.timeSec[index]) || ''}
                            maxLength={2}
                            //error={errors.times[index]}
                            onChange={e => {
                              if (e < 100) {
                                RoundTime(e, 'secs', index);
                                setEdited(true);
                              }
                            }}
                          />{' '}
                          <span className="time"> sec</span>
                          {index > 0 && processType === PROJECT_TYPES.OPTIMIZATION && (
                            <DeleteOutlined onClick={() => onDeleteOptions('times', index)} />
                          )}
                        </EquipmentDataItem>
                      )}
                    </>
                  );
                })}
                {processType !== PROJECT_TYPES.PRODUCTION && equipment.temperatures.length < 5 && (
                  <div className="optimization-popover_add_option optimization" onClick={onAddTemperature}>
                    + Add Option
                  </div>
                )}
              </>
            }
            {processType !== PROJECT_TYPES.LIBRARY_GENERATION && (
              <>
                {equipment.timeMin.map((i, index) => {
                  return (
                    <EquipmentDataItem
                      name={showTempTimeLabels ? `Reaction time, t${index + 1}` : 'Reaction time'}
                      className="reaction-time"
                    >
                      <Input
                        className="input input-time"
                        value={Math.round(equipment.timeHour[index])}
                        maxLength={3}
                        onChange={e => {
                          let str = e.replace(/[^0-9 ]/gi, '');
                          if (str < 100000) {
                            onChangeField('timeHour', str, index);
                            setEdited(true);
                          }
                        }}
                        error={errors.times[index]}
                        errorText={errors.times[index]}
                      />{' '}
                      <span className="time"> h &nbsp;&nbsp;</span>
                      <Input
                        className="input input-time"
                        value={Math.round(equipment.timeMin[index])}
                        maxLength={2}
                        onChange={e => {
                          let str = e.replace(/[^0-9 ]/gi, '');
                          if (str < 100000) {
                            RoundTime(e, 'mins', index);
                            setEdited(true);
                          }
                        }}
                        error={errors.times[index]}
                      />{' '}
                      <span className="time"> min</span>
                      <Input
                        className="input"
                        value={Math.round(equipment.timeSec[index]) || ''}
                        maxLength={2}
                        error={errors.times[index]}
                        onChange={e => {
                          if (e < 100) {
                            RoundTime(e, 'secs', index);
                            setEdited(true);
                          }
                        }}
                      />{' '}
                      <span className="time"> sec</span>
                      {index > 0 && processType === PROJECT_TYPES.OPTIMIZATION && (
                        <DeleteOutlined onClick={() => onDeleteOptions('times', index)} />
                      )}
                    </EquipmentDataItem>
                  );
                })}
                {equipment.timeMin.length < 5 && processType === PROJECT_TYPES.OPTIMIZATION && (
                  <div className="optimization-popover_add_option optimization" onClick={onAddTime}>
                    + Add Option
                  </div>
                )}
              </>
            )}
            <EquipmentDataItem name="Atmosphere">
              {equipment.type !== 'other' && (
                <Select
                  options={ATMOSPHERE_OPTIONS[equipment.type]}
                  value={equipment.atmosphere}
                  onChange={v => onChangeField('atmosphere', v)}
                  error={errors.atmosphere}
                  errorText={errors.atmosphere}
                />
              )}
              {(equipment.atmosphere === 'other' || equipment.type === 'other') && (
                <Input
                  value={equipment.atmosphereAdditional}
                  onChange={v => onChangeField('atmosphereAdditional', v)}
                  className={cn('other', { 'main-field': equipment.type === 'other' })}
                  error={errors.atmosphereAdditional}
                  errorText={errors.atmosphereAdditional}
                  placeholder="Type here"
                  maxLength={50}
                />
              )}
            </EquipmentDataItem>
            <EquipmentDataItem name="Pressure">
              <Input
                value={equipment.pressure}
                onChange={v => onChangeField('pressure', v)}
                type="number"
                error={errors.pressure}
                errorText={errors.pressure}
              />
              <span className="measure"> psi</span>
            </EquipmentDataItem>
          </div>
        </div>
      </Sidebar>
      <Confirmations
        openDeleteConfirmation={openDeleteConfirmation}
        openCancelConfirmation={openCancelConfirmation}
        onDelete={onDelete}
        onClose={() => {
          onClose();
          reset();
        }}
        deleteConfirmation={deleteConfirmation}
        cancelConfirmation={cancelConfirmation}
      />
      <Popup
        open={deleteOptionConfirmation}
        title=" "
        handleSubmit={() => {
          onDeleteOptionsAction();
          openDeleteOptionConfirmation(false);
        }}
        handleCancel={() => {
          openDeleteOptionConfirmation(false);
        }}
        textSubmit="Proceed"
        loading={false}
      >
        <span>Do you want to Delete this option?</span>
      </Popup>
    </>
  );
};
