export const MAX_DISPENSER_COUNT = 24;

export const SYNJET_MAX_HEATING_BLOCKS = 5;
export const SYNJET_PRO_MAX_HEATING_BLOCKS = 8;
export const SYNJET_PRO_VIALS_BY_HEATING_BLOCK = 6;

export const DISPENSER_COUNT_ERROR = 'Max 24 dispensers are available';
export const DISPENSER_COUNT_ERROR_MESSAGE =
  'Max number (24) of available dispensers is exceeded. Please revisit the dispensers assignment or fill in the corresponding compounds manually';

export const UNIQUE_TEMP_COUNT_ERROR = 'Max 8 unique Temperature values are available';
export const VIALS_WERE_EXCLUDED = "Vials' number was changed because of exclusion rule appliance";
export const INITIALLY_EXCLUDED_VIAL = 'This condition is not applicable for current experiment';
