import * as actionTypes from './common.actionTypes';
import { http } from '../../utils';
import { queryDetailsCurrentUser, queryGetDevices, queryGetUserByRoles } from '../graphql';

const setCurrentUser = data => ({ type: actionTypes.SET_CURRENT_USER, data });
const setUsersByRoles = data => ({ type: actionTypes.SET_USERS_BY_ROLES, data });
export const setDevices = data => ({ type: actionTypes.SET_DEVICES, data });
export const setGlobalLoading = data => ({ type: actionTypes.SET_LOADING, data });
export const setEditMode = data => ({ type: actionTypes.SET_EDIT_MODE, data });

const clearUser = () => ({ type: actionTypes.CLEAN_USERS });
const clearProjects = () => ({ type: actionTypes.CLEAN_PROJECTS });
const clearProcesses = () => ({ type: actionTypes.CLEAN_PROCESSES });
const clearProcessBuilderSynjet = () => ({ type: actionTypes.CLEAN_PROCESS_BUILDER_SYNJET });

export const getDetailsUser = () => dispatch =>
  http(queryDetailsCurrentUser()).then(resp => {
    const data = queryDetailsCurrentUser('get', resp);
    dispatch(setCurrentUser(data));
    return data;
  });

export const getUserByRoles = (roles, search) => dispatch =>
  // dispatch(setUsersByRoles({}));
  http(queryGetUserByRoles('send', roles, search)).then(resp =>
    // dispatch(setUsersByRoles(queryGetUserByRoles('get', resp)));
    queryGetUserByRoles('get', resp)
  );
export const cleanerStore = place => dispatch => {
  if (place === 'users') {
    dispatch(clearUser());
  } else if (place === 'projects') dispatch(clearProjects());
  else if (place === 'processes') dispatch(clearProcesses());
  else if (place === 'process-builder-synjet') dispatch(clearProcessBuilderSynjet());
};

export const getDevices = isExecution => dispatch =>
  http(queryGetDevices('send', { forExecution: isExecution })).then(resp => {
    const d = queryGetDevices('get', resp);
    dispatch(setDevices(d));
    return d;
  });

export const navigateTo = path => (dispatch, getState, { history }) => {
  history.push(path);
};
