import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Input, openNotification } from '../../Common';
import { dateMMDDYY, timehhmma } from '../../../constants';
import moment from 'moment';
import './style.scss';
import { setTOffsetUtc, toISOfromDateTime, toISOfromDateTimeUTC } from '../../../utils/date';
import { checkIntersectionCalendar, filterObj, lengthObj } from '../../../utils';

export const MainTenanceCreation = ({
  open,
  close,
  calendarApi,
  request,
  dataEvent,
  title = 'Schedule maintenance',
}) => {
  const [data, setData] = useState({ date: moment(), start: null, end: null, comment: '', allDay: false });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open) {
      if (dataEvent) {
        setData({
          date: setTOffsetUtc(dataEvent.start),
          start: setTOffsetUtc(dataEvent.start),
          end: setTOffsetUtc(dataEvent.end),
          comment: dataEvent.description,
          allDay: dataEvent.allDay,
          uuid: dataEvent?.uuid,
        });
      }
    } else {
      setErrors({});
      setData({ date: moment(), start: null, end: null, comment: '', allDay: false });
    }
  }, [dataEvent, open]);

  const onChange = (value, field) => {
    const newData = {
      ...data,
      [field]: value,
    };
    const newErrors = { ...errors };
    delete newErrors[field];
    if (field === 'allDay') {
      delete newErrors.end;
      delete newErrors.start;
      if (value) {
        newData.start = moment().startOf('day');
        newData.end = moment().endOf('day');
      } else {
        newData.start = null;
        newData.end = null;
      }
    }
    if (
      moment().startOf('day').diff(newData.start, 'seconds') === 0 &&
      moment().endOf('day').add(-1, 'minute').diff(newData.end, 'seconds') === 0
    ) {
      newData.allDay = true;
    }
    setData(newData);

    setErrors(newErrors);
  };

  const validationEmpty = () => {
    return {
      start: data.start ? '' : 'Please enter the start time',
      end: data.end ? '' : 'Please enter the end time',
    };
  };
  const validation = () => {
    let errorsValidation = validationEmpty();
    errorsValidation.start =
      errorsValidation.start ||
      (moment().isAfter(toISOfromDateTime(data.date, data.start)) ? 'Start time cannot be scheduled in the past' : '');
    errorsValidation.end =
      errorsValidation.end || (data.end < data.start ? 'Cannot be earlier than the start time' : '');
    return filterObj(errorsValidation);
  };

  const save = () => {
    const errorsValidation = validation();
    if (lengthObj(errorsValidation)) setErrors(errorsValidation);
    else {
      let start = toISOfromDateTimeUTC(data.date, data.start);
      let end = toISOfromDateTimeUTC(data.date, data.end);
      const result = checkIntersectionCalendar(
        {
          startDate: start,
          endDate: end,
          uuid: data.uuid,
        },
        calendarApi
      );
      if (result) {
        request({ ...data, comment: encodeURI(data.comment) })
          .then(() => {
            close();
          })
          .catch(() => setErrors({ end: 'This timeslot is occupied' }));
      } else {
        setErrors({ end: 'This timeslot is occupied' });
      }
    }
  };
  return (
    <div className="maintenance-creation">
      <header className="maintenance-creation_header">
        <h5 className="maintenance-creation_header_title">{title}</h5>
        {/*<CloseOutlined onClick={close} className="maintenance-creation_header_close" />*/}
      </header>
      <section>
        <div className="maintenance-creation_value_row">
          <div className="maintenance-creation_value_row_content">
            <label className="maintenance-creation_label">Date</label>
            <DatePicker
              className="maintenance-creation_value"
              value={data.date}
              onChange={val => onChange(val, 'date')}
              format={dateMMDDYY}
              error={!!errors.date}
              disabledDate={current => moment().startOf('day') >= current}
            />
          </div>
          {errors.date && <span className="maintenance-creation_value_row_error">{errors.date}</span>}
        </div>

        <div className="maintenance-creation_value_row">
          <div className="maintenance-creation_value_row_content">
            <label className="maintenance-creation_label" />
            <Checkbox value={data.allDay} field="allDay" onChange={onChange}>
              All day event
            </Checkbox>
          </div>
        </div>

        <div className="maintenance-creation_value_row">
          <div className="maintenance-creation_value_row_content">
            <label className="maintenance-creation_label">Start time</label>
            <DatePicker
              className="maintenance-creation_value"
              time
              showNow={false}
              disabled={data.allDay}
              value={data.start}
              onChange={val => onChange(val, 'start')}
              format={timehhmma}
              error={!!errors.start}
            />
          </div>
          {errors.start && <span className="maintenance-creation_value_row_error">{errors.start}</span>}
        </div>
        <div className="maintenance-creation_value_row">
          <div className="maintenance-creation_value_row_content">
            <label className="maintenance-creation_label">End time</label>
            <DatePicker
              className="maintenance-creation_value"
              time
              showNow={false}
              disabled={data.allDay}
              value={data.end}
              onChange={val => onChange(val, 'end')}
              format={timehhmma}
              error={!!errors.end}
            />
          </div>
          {errors.end && <span className="maintenance-creation_value_row_error">{errors.end}</span>}
        </div>
        <div className="maintenance-creation_value_row">
          <Input
            maxLength="256"
            label="Description"
            placeholder="Describe maintenance goals"
            disabled={loading}
            value={data.comment}
            onChange={value => {
              onChange(value, 'comment');
            }}
            error={errors.comment}
            errorText={errors.comment}
            type="textarea"
            className="maintenance-creation_input"
          />
        </div>
      </section>
      <footer className="schedule-popover-modal_footer">
        <Button secondary onClick={close}>
          Cancel
        </Button>
        <Button onClick={save}>Confirm</Button>
      </footer>
    </div>
  );
};
