import moment from 'moment';

import { PROJECT_TYPES } from '../constants';
import { toCapitalizeChart1 } from './common';

const mapCompoundToAnalyticData = (compoundList, type) =>
  compoundList.map(product => ({
    compound: product,
    type,
    label: product?.compound_name || product?.smiles,
    mass: product?.exactmolwt,
    mol: 1,
    observ: product?.exactmolwt + 1,
    interval_start: '0',
    interval_end: '4.00',
  }));

const convertEstimatedTime = seconds => {
  const minutes = Math.floor(seconds / 60);
  const sec = Math.floor(seconds % 60);
  return `${minutes}m ${sec}s`;
};
export const mapCompoundToAnalyticDataToFront = compoundList =>
  compoundList.map(product => ({
    compound: product.compound,
    type: product.chemical_type,
    label: product.label,
    mass: product.exact_mass,
    mol: product.modification_mass,
    observ: product.observed_mass,
    interval_start: (+product.interval_start / 60).toFixed(2),
    interval_end: (+product.interval_end / 60).toFixed(2),
  }));

export const mapCompoundToAnalyticDataToBack = compoundList =>
  compoundList.map(product => {
    let compound = null;
    if (product.compound) {
      const { compatibility, ...restCompound } = product.compound;
      compound = compatibility ? product.compound : restCompound;
    }
    return {
      compound,
      chemical_type: product.type,
      label: product.label?.trim(),
      exact_mass: product.mass,
      modification_mass: product.mol,
      observed_mass: product.observ,
      interval_start: Math.floor(Number(product.interval_start) * 60),
      interval_end: Math.floor(Number(product.interval_end) * 60),
    };
  });

const getUniqueCompounds = compoundList =>
  compoundList.filter((compound, compoundIndex, compoundArray) => compoundArray.indexOf(compound) === compoundIndex);

export const getCompoundDataForAnalytic = (processData, reactions) => {
  if (!processData) return [];
  const expectedIntermediates = [];
  const reagents = [];
  const reactants = [];
  const sideProducts = [];
  processData.forEach(step => {
    reactions.forEach((reaction, reactionIndex) => {
      if (step.properties) {
        if (step.properties.length > 1 && step.properties[reaction]) {
          step.properties[reaction].products.forEach(product => expectedIntermediates.push(product));
          step.properties[reaction].sideproducts.forEach(sideproduct => sideProducts.push(sideproduct));
        } else if (step?.properties[0] && !reactionIndex) {
          step.properties[0].products.forEach(product => expectedIntermediates.push(product));
          step.properties[0].sideproducts.forEach(sideproduct => sideProducts.push(sideproduct));
        }
      }
      step.pumps.forEach(pump => {
        if (pump?.properties?.length > 1 && pump?.properties && pump?.properties[reaction]) {
          pump.properties[reaction].reagents.forEach(reagent => reagents.push(reagent.properties));
          pump.properties[reaction].reactants.forEach(reactant => reactants.push(reactant.properties));
        } else if (pump?.properties[0] && !reactionIndex) {
          pump.properties[0].reagents.forEach(reagent => reagents.push(reagent.properties));
          pump.properties[0].reactants.forEach(reactant => reactants.push(reactant.properties));
        }
      });
    });
  });
  const products = expectedIntermediates.splice(-reactions.length, reactions.length);
  return [
    ...mapCompoundToAnalyticData(getUniqueCompounds(products), 'Product'),
    ...mapCompoundToAnalyticData(getUniqueCompounds(expectedIntermediates), 'Expected Intermediate'),
    ...mapCompoundToAnalyticData(getUniqueCompounds(reagents), 'Reagent'),
    ...mapCompoundToAnalyticData(getUniqueCompounds(reactants), 'Reactant'),
    ...mapCompoundToAnalyticData(getUniqueCompounds(sideProducts), 'Side Product'),
  ];
};

const getCompoundByName = (isGroup, step, type, name) => {
  if (!isGroup) {
    return step?.compounds?.[`${type}s`] && step.compounds[`${type}s`].find(compound => compound.name === name)?.smiles;
  }
  let compoundItem = null;
  step?.compounds?.[`${type}Groups`] &&
    step.compounds[`${type}Groups`].forEach(group => {
      const compound = group.compoundsList.find(compoundItem => compoundItem.name === name);
      if (compound) compoundItem = compound;
    });
  return compoundItem?.smiles;
};

export const getCompoundDataForAnalyticSynjet = (processData, reactions, isOptimization, isSynjetPro) => {
  if (!processData) return [];
  const isFirsVariable = !processData?.processSteps?.[0].isFixed;
  const expectedIntermediates = [];
  const reagents = [];
  const reactants = [];
  const libGenProducts = [];
  const isTwoStepProcess = processData.experiments.process_steps?.length > 1;
  processData.experiments.process_steps.forEach((step, stepIndex) => {
    const isGroup = !isOptimization && !step.step.isFixed;
    expectedIntermediates.push(processData?.processSteps?.[stepIndex]?.product?.smiles);
    reactions.forEach(reactionIndex => {
      const vial = step.step.vials[reactionIndex];
      if (vial) {
        if (vial?.reagents) {
          vial.reagents.forEach(reagent =>
            reagents.push(getCompoundByName(isGroup, processData.processSteps[stepIndex], 'reagent', reagent.name))
          );
        }
        vial.reactants.forEach(reactant =>
          reactants.push(getCompoundByName(isGroup, processData.processSteps[stepIndex], 'reactant', reactant.name))
        );
        if ((isTwoStepProcess && !!stepIndex) || !isTwoStepProcess) {
          if (!libGenProducts.includes(vial.productName)) libGenProducts.push(vial.productName);
        }
      }
    });
  });

  const products = expectedIntermediates.splice(-1, 1);
  const synjetProVialProduct = processData.products
    ? libGenProducts.map(vialProduct => processData.products.find(product => product.name === vialProduct))
    : [];
  const synjetProProduct = synjetProVialProduct.map(vialProduct => vialProduct?.product);
  const synjetProExpectedIntermidiate = isFirsVariable
    ? synjetProVialProduct.map(product => {
        const expectedIntermediate = product?.combination?.expectedIntermediate;
        return expectedIntermediate?.product;
      })
    : [];
  const synjetProQuenching = processData.quenching?.map(product => product.smiles) || [];
  const productsPro = isSynjetPro ? [...mapCompoundToAnalyticData(synjetProProduct, 'Product')] : [];
  const quenchingPro = isSynjetPro
    ? [
        ...mapCompoundToAnalyticData(
          getUniqueCompounds(synjetProQuenching).filter(reagent => !!reagent),
          'Quenching'
        ),
      ]
    : [];
  return [
    ...mapCompoundToAnalyticData(
      products.filter(product => !!product),
      'Product'
    ),
    ...productsPro,
    ...mapCompoundToAnalyticData(
      synjetProExpectedIntermidiate.filter(intermediate => !!intermediate),
      'Expected Intermediate'
    ),
    ...mapCompoundToAnalyticData(
      expectedIntermediates.filter(intermediate => !!intermediate),
      'Expected Intermediate'
    ),
    ...mapCompoundToAnalyticData(
      getUniqueCompounds(reactants).filter(reactant => !!reactant),
      'Reactant'
    ),
    ...mapCompoundToAnalyticData(
      getUniqueCompounds(reagents).filter(reagent => !!reagent),
      'Reagent'
    ),
    ...quenchingPro,
  ];
};

const getReactionTabTableDataPumpProperties = (selectedPumpProperties, reactionsInfo, pump, type) => {
  const reagentArray = [];
  selectedPumpProperties[`${type}s`].forEach(reactant => {
    const compound = reactionsInfo.compounds.find(compound => compound.compound_id === reactant[type]);
    if (compound) {
      reagentArray.push({
        compound,
        chemical_type: toCapitalizeChart1(type),
        pump_reactor: pump,
        mw: compound.molwt,
        concentration: reactant.concentration,
        equivalent: reactant.equivalent ? reactant.equivalent.toFixed(1) : '',
        is_limited: reactant.limiting_reagent,
      });
    }
  });
  return reagentArray;
};

export const getReactionTabTableData = (reactionsInfo, reaction, reactionIndex, processType) => {
  if (!reactionsInfo || !reaction) return [];
  const reactionsData = [];
  let reactorKey = null;
  reactionsInfo.process_steps.forEach((step, stepIndex, stepArray) => {
    step.step.forEach(step => {
      step.pumps.forEach(pump => {
        const selectedPump = reactionsInfo.pumps.find(pumpItem => pumpItem.name === pump);
        if (selectedPump) {
          const selectedPumpProperties = selectedPump.properties[reactionIndex];
          if (selectedPumpProperties) {
            reactionsData.push(
              ...getReactionTabTableDataPumpProperties(selectedPumpProperties, reactionsInfo, pump, 'reactant')
            );
            reactionsData.push(
              ...getReactionTabTableDataPumpProperties(selectedPumpProperties, reactionsInfo, pump, 'reagent')
            );
          }
        }
      });

      const selectedReactor = reactionsInfo.reactors.find(reactorItem => {
        reactorKey = reactorItem.key;
        return reactorItem.name === step.reactor;
      });
      if (selectedReactor) {
        const selectedReactorProperties = selectedReactor.properties[reactionIndex];
        if (selectedReactorProperties) {
          selectedReactorProperties.sideproducts.forEach(sideproduct => {
            const compound = reactionsInfo.compounds.find(compound => compound.compound_id === sideproduct);
            if (compound) {
              reactionsData.push({
                compound,
                chemical_type: 'Side product',
                pump_reactor: step.reactor,
                mw: compound.molwt,
              });
            }
          });
          const reactorProduct = selectedReactorProperties.product;
          if (reactorProduct) {
            const compound = reactionsInfo.compounds.find(compound => compound.compound_id === reactorProduct);
            if (compound) {
              reactionsData.push({
                compound,
                chemical_type: stepArray.length - 1 === stepIndex ? 'Product' : 'Expected intermediate',
                pump_reactor: reactorKey,
                mw: compound.molwt,
                prod_rate:
                  stepArray.length - 1 === stepIndex
                    ? processType === PROJECT_TYPES.OPTIMIZATION
                      ? 'N/A'
                      : reaction.estimated_production_rate
                      ? reaction.estimated_production_rate.toFixed(1)
                      : ''
                    : '',
              });
            }
          }
        }
      }
    });
  });
  return reactionsData;
};

export const calculateTotalTime = (hours, minutes, seconds) => hours * 3600 + minutes * 60 + +seconds;

export const getReactionTabTableDataManual = reactionsInfo => {
  const data = [];
  if (reactionsInfo) {
    reactionsInfo.reactions.forEach((reaction, index) => {
      const reactionData = [];
      const values = Object.values(reaction);
      values.forEach(value => {
        if (!Array.isArray(value)) {
          if (!!value && value.chemical_type !== 'solvent') {
            if (Array.isArray(value)) {
              value.forEach(row => {
                reactionData.push(row);
              });
            } else {
              reactionData.push(value);
            }
          }
        } else {
          value.forEach(row => {
            reactionData.push(row);
          });
        }
      });
      data.push(reactionData);
    });
  }
  return data;
};

export const convertTotalTime = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = Math.floor(seconds % 60);
  return { hours, minutes, sec };
};
