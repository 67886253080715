import React, { useState } from 'react';

import { Input } from 'components';
import { useDebounce } from 'hooks';

import { FIELD_DATA_TYPES } from '@organisms';

import { validateDecimal, validateNumberDependOnDataType, validateNumeric } from '../utils';

export const InputNumber = ({ dataType, value, onChange }) => {
  const [localValue, setLocalValue] = useState(value);
  const formatter = val => {
    if (dataType === FIELD_DATA_TYPES.numeric) {
      return validateNumeric(val);
    }
    if (dataType === FIELD_DATA_TYPES.decimal) {
      if (val === '.') return '0.';
      if (val.includes('.') && val[val.length - 1] === '0') return val;
      if (val?.split('.').length === 2 && val[val.length - 1] === '.') return val;
      return validateDecimal(val);
    }
  };

  const onChangeInput = val => {
    const newValue = validateNumberDependOnDataType({ dataType, value: `${val}` });
    setLocalValue(newValue);
    if (parseFloat(newValue)) {
      onChange(newValue);
    }
  };

  return <Input formatter={formatter} type="number" value={localValue} onChange={onChangeInput} />;
};
