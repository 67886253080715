import React from 'react';
import './style.scss';

export const Notes = ({ notes, count, ...rest }) => {
  return (
    <div className="experiment-notes">
      <span>{count}</span>
      <span className="notes-image" />
    </div>
  );
};
