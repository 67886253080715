import React from 'react';
import './style.scss';
import cn from 'classnames';
import { CompoundSmilesPreview } from '../../../index';

const SynJetCompoundInfoView = ({
  compoundInfo,
  isScreening,
  limitingCompoundGroup,
  isLimitingCompoundGroup,
  isFixed,
  disableLimiting,
  highlightErrors,
  isSolid,
  isQuenching,
}) => (
  <>
    <div className="synjet-compound__info__smiles">
      {compoundInfo?.smiles ? (
        <CompoundSmilesPreview compound={compoundInfo?.smiles} showTooltip />
      ) : (
        <span className="synjet-compound__text">N/A</span>
      )}
    </div>
    <div className="synjet-compound__info__solvent">
      <span className="synjet-compound__text">
        {compoundInfo?.solvents
          ? compoundInfo?.solvents?.length
            ? compoundInfo?.solvents.map(solvent => solvent?.solvent?.compound_name).join(',')
            : 'None'
          : 'N/A'}
      </span>
    </div>
    <div className="synjet-compound__info__concentration">
      <span
        className={cn('synjet-compound__text index', {
          'error-text': highlightErrors || !compoundInfo?.concentration,
        })}
      >
        {compoundInfo?.concentration || 'N/A'}
      </span>
    </div>
    {!isScreening && !isQuenching && (
      <div className="synjet-compound__info__limiting">
        <span className="synjet-compound__text limiting">{compoundInfo?.isLimiting ? 'Yes' : ''}</span>
      </div>
    )}
    <div className="synjet-compound__info__equivalent">
      {compoundInfo?.equivalents.every(equvalent => !equvalent) ? (
        <div
          className="synjet-compound__info__equivalent__grid"
          style={
            isScreening || isQuenching
              ? {
                  gridTemplateColumns:
                    limitingCompoundGroup && !isLimitingCompoundGroup && !disableLimiting
                      ? `repeat(${(limitingCompoundGroup?.compoundsList?.length || 0) + 1}, 1fr)`
                      : '1fr 1fr',
                }
              : isFixed
              ? { gridTemplateColumns: '1fr 1fr' }
              : { gridTemplateColumns: '1fr 1fr 1fr 1fr' }
          }
        >
          <span />
          {compoundInfo?.equivalents.map((value, index) => (
            <span
              key={index}
              className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__text', {
                'error-text': highlightErrors && !value,
              })}
            >
              N/A
            </span>
          ))}
        </div>
      ) : (
        <>
          {compoundInfo?.isLimiting || isLimitingCompoundGroup ? (
            <div
              className={cn('synjet-compound__info__equivalent__grid', 'view-grid')}
              style={
                (isScreening || isQuenching) && isLimitingCompoundGroup
                  ? { gridTemplateColumns: '1fr 1fr' }
                  : isFixed
                  ? { gridTemplateColumns: '1fr 1fr' }
                  : {}
              }
            >
              {compoundInfo?.isLimiting && !isFixed && !isScreening && !isQuenching ? (
                <>
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    Equivalent
                  </span>
                  <span />
                  <span
                    className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__text', {
                      'error-text': highlightErrors && !compoundInfo?.equivalents[0],
                    })}
                  >
                    {compoundInfo?.equivalents[0] || 'N/A'}
                  </span>
                  <span />
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    {isSolid ? 'Mass (mg)' : 'Volume (µL)'}
                  </span>
                  <span />
                  <span className={cn('view__text', { 'error-text': highlightErrors && !compoundInfo?.volumes[0] })}>
                    {`${compoundInfo?.volumes[0] || 'N/A'} ${isSolid ? 'mg' : 'µL'}`}
                  </span>
                  <span />
                </>
              ) : (
                <>
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    Equivalent
                  </span>
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__text')}>
                    {compoundInfo?.equivalents[0] || 'N/A'}
                  </span>
                  <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                    {isSolid ? 'Mass (mg)' : 'Volume (µL)'}
                  </span>
                  <span
                    className={cn('view__text', {
                      'error-text': !compoundInfo?.volumes[0] || (!compoundInfo?.volumes[0] && highlightErrors),
                    })}
                  >
                    {`${compoundInfo?.volumes[0] || 'N/A'} ${isSolid ? 'mg' : 'µL'}`}
                  </span>
                </>
              )}
            </div>
          ) : (
            <div
              className={cn('synjet-compound__info__equivalent__grid', 'view-grid')}
              style={
                isScreening || isQuenching
                  ? {
                      gridTemplateColumns:
                        limitingCompoundGroup && !isLimitingCompoundGroup && !disableLimiting
                          ? `repeat(${(limitingCompoundGroup?.compoundsList?.length || 0) + 1}, 1fr)`
                          : '1fr 1fr',
                    }
                  : isFixed
                  ? { gridTemplateColumns: '1fr 1fr' }
                  : {}
              }
            >
              <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                Equivalent
              </span>
              {compoundInfo?.equivalents.map((equivalent, index) => (
                <span
                  key={`equivalent_${index}`}
                  className={cn('synjet-compound__text', {
                    'error-text': (limitingCompoundGroup && !equivalent) || (highlightErrors && !equivalent),
                  })}
                >
                  {equivalent || 'N/A'}
                </span>
              ))}
              <span className={cn('synjet-compound__text', 'synjet-compound__info__equivalent__title')}>
                {isSolid ? 'Mass (mg)' : 'Volume (µL)'}
              </span>
              {compoundInfo?.volumes.map((volumes, index) => (
                <span
                  key={`volume_${index}`}
                  className={cn('view__text', { 'error-text': limitingCompoundGroup && !volumes })}
                >
                  {`${volumes || 'N/A'} ${isSolid ? 'mg' : 'µL'}`}
                </span>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  </>
);

export default SynJetCompoundInfoView;
