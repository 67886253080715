import { openNotification } from '../../components/Common';
import { http } from '../../utils/http';
import {
  allReactorsQuery,
  mutationAddProjectTargetCompound,
  mutationCreateProject,
  mutationDeleteEmptyRoute,
  mutationUpdateManager,
  mutationUpdateStatusProject,
  mutationUpdateTeamMembers,
  queryConstructor,
  queryDetailsProject,
  queryFiltersProject,
  queryGetExperiment,
  queryListProjects,
} from '../graphql';
import * as actions from './projects.actionTypes';

const setProjectsList = payload => ({ type: actions.SET_PROJECTS_LIST, payload });
const setAllProjectsList = payload => ({ type: actions.SET_ALL_PROJECTS_LIST, payload });
const setDetailsProject = payload => ({ type: actions.SET_DETAILS_PROJECT, payload });
const updateDetailsProject = payload => ({ type: actions.UPDATE_DETAILS_PROJECT, payload });
const setFiltersData = payload => ({ type: actions.SET_FILTERS_DATA, payload });

export const getListProjects = (filters, noSetToStore, pageSize = 12) => dispatch => {
  const page = filters?.page;
  delete filters.page;
  return http(queryListProjects('send', null, page, filters, pageSize)).then(resp => {
    if (noSetToStore) return queryListProjects('get', resp);
    return dispatch(setProjectsList(queryListProjects('get', resp)));
  });
};

export const getAllProjects = (filters, search, pageSize) => dispatch =>
  http(queryListProjects('send', null, 1, (filters = ''), (pageSize = 10000), search)).then(resp => {
    dispatch(setAllProjectsList(queryListProjects('get', resp)));
    return queryListProjects('get', resp);
  });

export const getDetailsProject = uuid => dispatch =>
  http(queryDetailsProject('send', uuid)).then(resp => {
    dispatch(setDetailsProject(queryDetailsProject('get', uuid, resp)));
  });

export const clearDetailsProject = () => dispatch => dispatch(setDetailsProject(null));

export const createProject = data => dispatch =>
  http(mutationCreateProject('send', data)).then(resp => {
    const obj = mutationCreateProject('get', { ...resp, uuid: data.uuid || resp.uuid });
    if (obj.errors) return obj;
    if (data.uuid) {
      dispatch(updateDetailsProject(obj));
    }
    return obj;
  });

export const getFiltersProjects = () => dispatch =>
  http(queryFiltersProject()).then(resp => {
    const filters = queryFiltersProject('get', resp);
    dispatch(setFiltersData(filters));
    return filters;
  });

export const updateTeamMembers = data => dispatch =>
  http(mutationUpdateTeamMembers('send', data)).then(resp => {
    const processData = mutationUpdateTeamMembers('get', resp);
    if (processData.errors) {
      openNotification(
        '',
        'Not all of the selected users could be added to the Project Team. Please update the list of team members to complete Project set up'
      );
      return { errors: true };
    }
    dispatch(getDetailsProject(data.uuid));
  });

export const updateManager = data => dispatch =>
  http(mutationUpdateManager('send', data)).then(resp => {
    if (resp.error) return { error: true };
    dispatch(getDetailsProject(data.uuid));
  });

export const updateStatusProject = data => dispatch =>
  http(mutationUpdateStatusProject('send', data)).then(resp => {
    const obj = mutationUpdateStatusProject('get', resp);
    if (obj.errors) return obj;
    dispatch(updateDetailsProject(obj));
    return obj;
  });

export const getExperimentInfo = id => () =>
  http(queryConstructor(allReactorsQuery(), queryGetExperiment('send', id, false))).then(resp => resp);

export const updateTargetProduct = data => dispatch =>
  http(mutationAddProjectTargetCompound('send', data)).then(resp => mutationAddProjectTargetCompound('get', resp));

export const deleteEmptyRoute = data => dispatch =>
  http(mutationDeleteEmptyRoute('send', data)).then(resp => {
    const obj = mutationDeleteEmptyRoute('get', resp);
    if (obj.errors) return obj;
    dispatch(getDetailsProject(obj.projectId));
    return obj;
  });
