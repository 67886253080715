import { useEffect, useMemo, useState } from 'react';

import { mapGridColumnsToList } from '@templates/ExperimentsTable/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateExtensibleTableGrid } from 'store/experiment/experiment.actions';
import { getData } from 'store/slices/extensibleTableFieldsGrid/getters';

import { shouldBeHighlight } from '@atoms';

import { fetchData } from '../../../store/slices/extensibleTableFieldsGrid/actions';

export const useStateController = invokeInitialTableRequest => {
  const [visible, setVisible] = useState(false);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [activeHighlightedItemValue, setActiveHighlightedItemValue] = useState();
  const [highlightedValueItems, setHighlightedValueItems] = useState([]);

  const tableFieldsGrid = useSelector(getData);
  const dispatch = useDispatch();

  useEffect(() => {
    setColumns(mapGridColumnsToList(tableFieldsGrid));
  }, [tableFieldsGrid]);

  const isAllSelected = useMemo(() => columns.filter(i => i.selected).length === columns.length, [columns]);

  const getHighlightedValueItems = ({ columnsArr = columns, value = search.trim() }) =>
    columnsArr
      .filter(i =>
        shouldBeHighlight({
          value: i.label,
          highlightPart: value,
        })
      )
      .map(i => i.value);

  const onSearch = value => {
    setSearch(value);
    const newHighlightedValueItems = getHighlightedValueItems({ value: value.trim() });
    if (newHighlightedValueItems.length > 0) {
      document.getElementById(newHighlightedValueItems[0]).scrollIntoView();
      setActiveHighlightedItemValue(newHighlightedValueItems[0]?.trim());
    }
    setHighlightedValueItems(newHighlightedValueItems);
  };

  const onUp = () => {
    let indexActiveItem = highlightedValueItems.indexOf(activeHighlightedItemValue) - 1;
    if (indexActiveItem === -1) {
      indexActiveItem = highlightedValueItems.length - 1;
    }
    const valueActiveItem = highlightedValueItems[indexActiveItem];

    if (highlightedValueItems.length > 0) {
      document.getElementById(valueActiveItem).scrollIntoView();
      setActiveHighlightedItemValue(valueActiveItem);
    }
  };

  const onDown = () => {
    let indexActiveItem = highlightedValueItems.indexOf(activeHighlightedItemValue) + 1;
    if (indexActiveItem > highlightedValueItems.length - 1) {
      indexActiveItem = 0;
    }
    const valueActiveItem = highlightedValueItems[indexActiveItem];

    if (highlightedValueItems.length > 0) {
      document.getElementById(valueActiveItem).scrollIntoView();
      setActiveHighlightedItemValue(valueActiveItem);
    }
  };

  const onOrderChanged = newColumns => {
    setColumns(newColumns);

    if (!activeHighlightedItemValue) {
      return;
    }
    const newHighlightedValueItems = getHighlightedValueItems({
      columnsArr: newColumns,
    });
    if (newHighlightedValueItems.join('') === highlightedValueItems.join('')) {
      return;
    }
    setHighlightedValueItems(newHighlightedValueItems);
  };

  const onSelect = value => {
    setColumns(prev => prev.map(i => (i.value === value ? { ...i, selected: !i.selected } : i)));
  };

  const onSelectAll = () => {
    if (isAllSelected) {
      setColumns(prev => prev.map(i => ({ ...i, selected: false })));
    } else {
      setColumns(prev => prev.map(i => ({ ...i, selected: true })));
    }
  };
  const onSave = async () => {
    setIsLoading(true);
    await updateExtensibleTableGrid(
      columns.map(({ uuid, selected }) => ({
        uuid,
        hidden: !selected,
      }))
    );
    setIsLoading(false);
    setVisible(false);
    setSearch('');
    invokeInitialTableRequest();
    dispatch(fetchData());
  };
  const onReset = () => {
    setSearch('');
    setColumns(mapGridColumnsToList(tableFieldsGrid));
  };

  const onOpen = () => setVisible(true);
  const onClose = () => {
    setVisible(false);
    onReset();
  };

  return {
    columns,
    visible,
    onSearch,
    activeHighlightedItemValue,
    highlightedValueItems,
    search,
    onDown,
    onUp,
    onOrderChanged,
    onSelect,
    onSave,
    onReset,
    isAllSelected,
    isLoading,
    onSelectAll,
    onOpen,
    onClose,
  };
};
