import React, { useState } from 'react';

import { Select } from 'components';
import { useDispatch } from 'react-redux';
import { getExperiments } from 'store/experiment/experiment.actions';

export const ExperimentSelect = ({ value, onChange, disabled }) => {
  const [focused, setFocused] = useState(false);

  const dispatch = useDispatch();
  const getData = search =>
    dispatch(getExperiments({ search, searchType: 'name' }, { page: 1, pageSize: 30 })).then(resp =>
      resp?.map(process => ({
        value: process.uuid,
        label: `${process?.name}`,
      }))
    );

  return (
    <Select
      allowClear
      disabled={disabled}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      showArrow
      showSearch
      getOptions={search => getData(search)}
      typeToSearch={focused}
      labelInValue
      value={value || undefined}
      onChange={(label, val) => {
        setFocused(false);
        onChange(val || null);
      }}
    />
  );
};
