import React, { useEffect, useState } from 'react';
import { Button, Input, Popup } from '../../Common';
import { DeleteOutlined } from '@ant-design/icons';
import { formatSeconds, formatSecondsToMinsHours } from '../../../utils';
import './style.scss';
import { MEASUREMENT_OPTIONS } from '../../../constants';
import { useSelector } from 'react-redux';
import { equipmentValidation } from 'components/ProcessBuilder/Manual/Sidebars/equipmentValidations';

const OptionControl = ({
  measurement,
  value,
  setValue,
  error,
  setError,
  reactorData,
  manual = false,
  maxTemperature,
}) => {
  switch (measurement) {
    case MEASUREMENT_OPTIONS.times.value:
      const time = manual ? formatSecondsToMinsHours(Number(value)) : formatSeconds(Number(value));
      if (!manual) time.hours = 0;
      return (
        <>
          {manual && (
            <>
              <Input
                className="input"
                error={error}
                value={time.hours}
                maxLength={3}
                onChange={e => {
                  const str = e.replace(/[^0-9 ]/gi, '');
                  if (str < 100000) {
                    setValue(str * 3600 + time.min * 60 + time.sec);
                  }
                  setError('');
                }}
              />{' '}
              <span className="time"> h &nbsp;&nbsp;</span>
            </>
          )}
          <Input
            className="input"
            error={error}
            value={time.min}
            maxLength={2}
            onChange={e => {
              const str = e.replace(/[^0-9 ]/gi, '');
              if (str < 100000) {
                setValue(+time.hours * 3600 + str * 60 + time.sec);
              }
              setError('');
            }}
          />{' '}
          <span className="time"> min</span>
          <Input
            className="input"
            error={error}
            value={time.sec}
            maxLength={2}
            onChange={e => {
              setValue(+time.hours * 3600 + time.min * 60 + Number(e));
            }}
          />
          <span className="time"> sec</span>
        </>
      );
    case MEASUREMENT_OPTIONS.temperatures.value:
      return (
        <>
          <Input
            allowSpecials
            className="input"
            placeholder={`${reactorData.type === 'tubular' ? -20 : 20} - ${maxTemperature || 300}`}
            value={value}
            onChange={e => {
              const str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
              if ((str || !e) && e < 100000) {
                setValue(e);
              }
              if (e === '-') {
                setValue(e);
              }
              setError('');
            }}
            error={error}
          />{' '}
          <span className="temperature-symb">°C</span>
        </>
      );
  }
};

const errorsText = {
  time: 'Residence time should be > 0',
  temperature: 'Temperature is a required field',
  min: min => `Temperature should be >= ${min}°C`,
  max: max => `Temperature should be <= ${max}`,
};

const validateTime = time => {
  if (!time) {
    return errorsText.time;
  }
  return '';
};

const validateTemperature = (temp, reactorData) => {
  if (temp === '' || temp === null || temp === undefined) {
    return errorsText.temperature;
  }
  const minTemp = reactorData.type === 'Tubular reactor' ? -20 : 20;
  const maxTemp = reactorData.maxTemperature || 300;

  if (temp < minTemp) {
    return errorsText.min(minTemp);
  }

  if (temp > maxTemp) {
    return errorsText.max(maxTemp);
  }

  return '';
};

const validateOptions = (options, measurement, reactorData) => {
  let valid = true;

  const errors = options.map(option => {
    if (measurement === MEASUREMENT_OPTIONS.times.value) {
      const error = validateTime(option);
      if (error) {
        valid = false;
      }
      return error;
    } else if (measurement === MEASUREMENT_OPTIONS.temperatures.value) {
      const error = validateTemperature(option, reactorData);
      if (error) {
        valid = false;
      }
      return error;
    }
  });
  return {
    valid,
    validationErrors: errors,
  };
};

const OptimizationPopover = props => {
  const { options = [], measurement, onClose, onSave, reactorData, isOpen } = props;
  const reactorTypes = useSelector(state => state.processbuilderReducer.reactors);
  const [editedOptions, setEditedOptions] = useState(options);
  const [errors, setErrors] = useState([]);
  const [deletedIndexes, setDeletedIndexes] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [deleteOptionConfirmation, openDeleteOptionConfirmation] = useState(false);
  const [selectedReactorType, setSelectedReactorType] = useState();

  useEffect(() => {
    setEditedOptions(options);
    setErrors([]);
    setDeletedIndexes([]);
  }, [options]);

  useEffect(() => {
    if (!isOpen) {
      setEditedOptions(options);
      setErrors([]);
    }
  }, [isOpen]);

  useEffect(() => {
    const reactorType = props.manual
      ? reactorData.type
      : reactorTypes.allReactors?.find(reactor => reactorData.key === reactor.key);
    setSelectedReactorType(reactorType);
  }, [props.manual, reactorData, reactorTypes]);

  const onCancel = () => {
    setEditedOptions(options);
    setErrors([]);
    if (onClose) onClose();
  };

  const onAddOption = () => {
    setEditedOptions([...editedOptions, '']);
    const indexes = [...deletedIndexes];
    indexes.pop();
    setDeletedIndexes(indexes);
  };

  const onDeleteOption = index => {
    setDeleteIndex(index);
    openDeleteOptionConfirmation(true);
  };

  const onDeleteOptionsAction = () => {
    let index = deleteIndex;
    setEditedOptions([...editedOptions.filter((option, i) => index !== i)]);
    setErrors([...errors.filter((error, i) => index !== i)]);
    setDeletedIndexes([...deletedIndexes, index]);
    setDeleteIndex(-1);
  };

  const validateManualOptions = () => {
    let valid = true;
    let hours = editedOptions.map(time => Math.trunc(time / 3600));
    const objectToValidate =
      props.measurement === MEASUREMENT_OPTIONS.times.value
        ? {
            timeMin: editedOptions.map((time, idx) => Math.trunc(time / 60 - hours[idx] * 60)),
            timeSec: editedOptions.map(time => time % 60),
            timeHour: hours,
            temperatures: [],
          }
        : {
            temperatures: editedOptions.map(option => (option !== '' ? +option : option)),
            timeMin: [],
            timeSec: [],
            timeHour: [],
          };

    const { obj } = equipmentValidation(
      { ...objectToValidate, type: props.reactorData.type },
      props.reactorData.otherType
    );

    const errors = editedOptions.map((option, index) => {
      if (measurement === MEASUREMENT_OPTIONS.times.value) {
        const error = obj[MEASUREMENT_OPTIONS.times.value][index];
        if (error) {
          valid = false;
        }
        return error;
      } else if (measurement === MEASUREMENT_OPTIONS.temperatures.value) {
        const error = obj[MEASUREMENT_OPTIONS.temperatures.value][index];
        if (error) {
          valid = false;
        }
        return error;
      }
    });

    return {
      valid,
      validationErrors: errors,
    };
  };

  const onSavePress = () => {
    const { validationErrors, valid } = props.manual
      ? validateManualOptions()
      : validateOptions(editedOptions, measurement, selectedReactorType);
    if (!valid) {
      setErrors(validationErrors);
      return;
    }
    onSave(
      editedOptions.sort((a, b) => a - b),
      measurement,
      measurement === MEASUREMENT_OPTIONS.times.value && editedOptions.length < options.length ? deletedIndexes : false,
      editedOptions.length === 1
    );
  };

  return (
    <div
      className="optimization-popover"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div className="optimization-popover_content">
        <h5 className="optimization-popover_title">Optimization options</h5>
        {editedOptions.map((option, i) => {
          return (
            <div className="optimization-popover_option_row" key={i}>
              <div className="optimization-popover_option_row_content">
                <span className="optimization-popover_measurement">{`${MEASUREMENT_OPTIONS[measurement].label}${
                  i + 1
                } `}</span>{' '}
                ={' '}
                <div className="optimization-popover_control_wrapper">
                  <OptionControl
                    measurement={measurement}
                    value={option}
                    setValue={value => {
                      const newOptions = [...editedOptions];
                      newOptions[i] = value;
                      setEditedOptions(newOptions);
                    }}
                    error={errors[i]}
                    setError={error => {
                      const errorList = [...errors];
                      errorList[i] = error;
                      setErrors(errorList);
                    }}
                    reactorData={reactorData}
                    manual={props.manual}
                    maxTemperature={selectedReactorType?.maxTemperature}
                  />
                </div>
                {editedOptions.length > 1 && (
                  <div className={'optimization-popover_delete_option'}>
                    <DeleteOutlined onClick={() => onDeleteOption(i)} />
                  </div>
                )}
              </div>
              <span className="optimization-popover_option_error">{errors[i]}</span>
            </div>
          );
        })}
        {editedOptions.length < 5 && (
          <div className="optimization-popover_add_option" onClick={onAddOption}>
            + Add Option
          </div>
        )}
      </div>
      <div className="optimization-popover_footer">
        <Button secondary onClick={onCancel} className="optimization-popover_footer_cancel_button">
          Cancel
        </Button>
        <Button onClick={onSavePress}>Save</Button>
      </div>
      <Popup
        open={deleteOptionConfirmation}
        title=" "
        handleSubmit={() => {
          onDeleteOptionsAction();
          openDeleteOptionConfirmation(false);
        }}
        handleCancel={() => {
          openDeleteOptionConfirmation(false);
        }}
        textSubmit="Proceed"
        loading={false}
      >
        <span>Do you want to Delete this option?</span>
      </Popup>
    </div>
  );
};

export default OptimizationPopover;
