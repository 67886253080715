import React from 'react';
import './style.scss';
import cn from 'classnames';
import { PROJECT_TYPES } from 'constants/common';
import { AddComponentButton } from './AddComponentButton';
import { InputComponents } from './InputComponents';
import { Glassware } from './Glassware';
import { Reactor } from './Reactor';

export const ManualComponentsItem = ({
  index,
  data,
  addInput,
  processType,
  addEquipment,
  addGlassware,
  getInputNumber,
  onSaveInputEquivalents,
  onSaveEquipmentOptions,
  openCompoundAddToEquipment,
  changeEquipmentCompound,
  variableEquipmentExists,
  hasOneElement,
  openAddProperty,
  variationInput,
  limitingInput,
}) => {
  const _addInput = () => {
    addInput(index);
  };

  const changeGlassware = () => {
    addGlassware(index, true);
  };

  const changeEquipment = () => {
    addEquipment(index, true);
  };

  const updateEquipmentOptions = (newData, idx, deleteIndex) => {
    onSaveEquipmentOptions(newData, idx, deleteIndex, index);
  };

  const changeInput = (data, inputIndex) => {
    addInput(index, inputIndex);
  };

  const onChangeInputEquivalents = (propertyIndex, equivalents, inputIndex) => {
    onSaveInputEquivalents(equivalents, inputIndex, index, propertyIndex);
  };

  const pumps = data.inputs.map((i, inputIndex) => ({ ...i, type: [`input ${getInputNumber(index, inputIndex)}`] }));

  return (
    <div
      className={
        !data.equipment && data.visibleEquipment
          ? 'manual-components-item glassware-empty-equipment-box'
          : data.equipment && !data.glassware && !data.inputs.length
          ? 'manual-components-item glassware-equipment-box'
          : 'manual-components-item'
      }
    >
      {data.glassware ? (
        <Glassware openEditMode={changeGlassware} data={data.glassware} noArrow={index === 0} />
      ) : (
        <AddComponentButton type="glassware" addAction={() => addGlassware(index)} />
      )}
      {((data.glassware && index > 0) ||
        data.visibleInput ||
        data.equipment ||
        (data.glassware && data.inputs.length)) && (
        <InputComponents
          pumps={pumps}
          addPump={_addInput}
          reactorIdx={index}
          manual
          changePump={changeInput}
          onChangeInputEquivalents={onChangeInputEquivalents}
          hasGlassware={data.glassware}
          hasOneInput={hasOneElement && pumps.length === 1}
          variationPossible={processType === PROJECT_TYPES.LIBRARY_GENERATION}
          variationPump={variationInput}
          openCompoundAdd={openAddProperty}
          setCompoundPosition={() => {}}
          changeCompound={(compound, property, propertyIndex, inputIndex, compoundIndex) => {
            openAddProperty(index, inputIndex, compoundIndex);
          }}
          showOptions={processType === PROJECT_TYPES.OPTIMIZATION}
          limitingInput={limitingInput}
        />
      )}
      {data.equipment ? (
        <Reactor
          changeReactor={changeEquipment}
          changeCompound={changeEquipmentCompound}
          manual
          data={data.equipment}
          type={data.equipment.type}
          reactorIdx={index}
          processType={processType}
          updateReactor={updateEquipmentOptions}
          openCompoundAddToReactor={idx => openCompoundAddToEquipment(idx, index)}
          variableReactorExists={variableEquipmentExists}
          noArrow={index === 0 && !data.glassware && !pumps.length}
        />
      ) : (
        (data.glassware || data.visibleEquipment) && (
          <AddComponentButton type="equipment" addAction={() => addEquipment(index)} />
        )
      )}
    </div>
  );
};
