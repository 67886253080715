import { PROJECT_TYPES } from '../../../../constants';

export const optionCalculation = (
  inputValues,
  canCalculate,
  field,
  index,
  limitingInput,
  processType,
  canCalculateEq = false,
  fake = false
) => {
  let mass = inputValues[inputValues.chemical_type].mass[index];
  let moles = inputValues[inputValues.chemical_type].moles[index];
  let volume = inputValues[inputValues.chemical_type].volume[index];
  let density = inputValues[inputValues.chemical_type].density;
  let concentration = inputValues[inputValues.chemical_type].concentration;
  let mw = inputValues[inputValues.chemical_type].compound.molwt;
  let equivalents = inputValues[inputValues.chemical_type].equivalents[index];
  let all_eq = [...inputValues[inputValues.chemical_type].equivalents];
  let obj;
  let isSolution = inputValues.reagent_type === 'solution';
  switch (field) {
    case 'mass':
      if (canCalculate.moles[index] && (!moles || fake)) {
        moles = calc_moles(mass, mw);
        if (!!limitingInput && canCalculate.equivalents[index] && !equivalents) {
          if (canCalculateEq) all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
        }
      }
      if (
        (!!volume && canCalculate.density) ||
        (((!!density && inputValues.reagent_type !== 'solution') || !!concentration) && canCalculate.volume[index])
      ) {
        obj = ifMassKnown(
          mass,
          volume,
          density,
          concentration,
          moles,
          index,
          isSolution,
          inputValues.reagent_type === 'liquid'
        );
        volume = obj.volume;
        density = obj.density;
        concentration = obj.concentration;
      }
      if (!!limitingInput && moles) {
        all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
      }
      break;
    case 'moles':
      if (canCalculate.mass[index] && (!mass || fake)) mass = calc_mass(moles, mw);
      if (
        (!!volume && canCalculate.density) ||
        (((!!density && inputValues.reagent_type !== 'solution') || !!concentration) && canCalculate.volume[index]) ||
        (!!volume && canCalculate.concentration)
      ) {
        obj = ifMassKnown(
          mass,
          volume,
          density,
          concentration,
          moles,
          index,
          isSolution,
          inputValues.reagent_type === 'liquid'
        );
        volume = obj.volume;
        density = obj.density;
        concentration = obj.concentration;
      }
      if (!!limitingInput) {
        if (canCalculateEq) all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
      }
      break;
    case 'volume':
      if (!!density && inputValues.reagent_type !== 'solution') {
        if (volume && canCalculate.mass[index] && !mass) mass = calc_mass_with_density(density, volume);
        if (mass && canCalculate.moles[index] && !moles) {
          moles = calc_moles(mass, mw);
          if (!!limitingInput) {
            if (canCalculateEq) all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
          }
        }
      } else {
        // if (mass && canCalculate.density) density = calc_density(mass, volume);
        if (concentration && canCalculate.moles[index]) {
          if (canCalculate.moles[index] && (!moles || fake)) {
            moles = calc_moles_with_concentration(concentration, volume);
            if (!!limitingInput) {
              if (canCalculateEq) all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
            }
            if (canCalculate.mass[index] && (!mass || fake)) mass = calc_mass(moles, mw);
          }
        } else if (!concentration) {
          if (index == 0) {
            if (moles && isSolution) {
              concentration = calc_concentration(moles, volume);
            }
          }
        }
      }
      if (!!limitingInput && moles) {
        if (canCalculateEq) all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
      }
      break;
    case 'density':
      if (!!volume) {
        if (density && canCalculate.mass[index] && (!mass || fake)) mass = calc_mass_with_density(density, volume);
        if (mass && canCalculate.moles[index] && (!moles || fake)) {
          moles = calc_moles(mass, mw);
          if (!!limitingInput && moles) {
            if (canCalculateEq) all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
          }
        }
      } else {
        if (!volume && canCalculate.volume[index] && mass) volume = calc_volume(mass, density);
      }
      if (!!limitingInput && moles) {
        all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
      }
      break;
    case 'concentration':
      if (!!moles) {
        if ((!volume || fake) && canCalculate.volume[index])
          volume = calc_volume_with_concentration(concentration, moles);
        if ((!mass || fake) && canCalculate.mass[index]) mass = calc_mass(moles, mw);
      } else if (!!volume) {
        if (canCalculate.moles[index] && (!moles || fake)) {
          moles = calc_moles_with_concentration(concentration, volume);
        }
        if ((!mass || fake) && canCalculate.mass[index]) mass = calc_mass(moles, mw);
      }
      if (!!limitingInput) {
        if (canCalculateEq) all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
      }
      break;
  }
  return {
    mass: num(mass, 3),
    moles: num(moles, 3),
    concentration: num(concentration, 3),
    volume: num(volume, 3),
    equivalents:
      processType === PROJECT_TYPES.LIBRARY_GENERATION
        ? all_eq
        : num(Array.isArray(all_eq) ? all_eq[index] : all_eq, 3),
  };
};

export const calculateSpecificField = (inputValues, field, index, limitingInput, processType) => {
  let mass = inputValues[inputValues.chemical_type].mass[index];
  let moles = inputValues[inputValues.chemical_type].moles[index];
  let volume = inputValues[inputValues.chemical_type].volume[index];
  let density = inputValues[inputValues.chemical_type].density;
  let concentration = inputValues[inputValues.chemical_type].concentration;
  let mw = inputValues[inputValues.chemical_type].mw;
  let all_eq = [...inputValues[inputValues.chemical_type].equivalents];

  let v = null;
  switch (field) {
    case 'mass':
      if (!!moles && !!mw) v = calc_mass(moles, mw);
      else if (density && volume) v = calc_mass_with_density(density, volume);
      break;
    case 'moles':
      if (mass && mw && inputValues.reagent_type !== 'solution') v = calc_moles(mass, mw);
      else if (concentration && volume) v = calc_moles_with_concentration(concentration, volume);
      if (!!limitingInput) {
        all_eq = calc_equivalents(moles, limitingInput, processType, all_eq);
      }
      break;
    case 'volume':
      if (mass && density) v = calc_volume(mass, density);
      else if (moles && concentration) v = calc_volume_with_concentration(moles, concentration);
      break;

    case 'concentration':
      if (moles && volume) v = calc_concentration(moles, volume);
      break;
  }
  return {
    [field]: num(v, 3),
  };
};

const num = (value, decimals) => (value ? Number(value)?.toFixed(decimals) : '');

const calc_moles = (mass, mw) => {
  return (1000 * Number(mass)) / Number(mw);
};
const calc_mass = (moles, mw) => {
  return (Number(mw) * Number(moles)) / 1000;
};
const calc_mass_with_density = (density, volume) => Number(density) * Number(volume);
const calc_volume = (mass, density) => Number(mass) / Number(density);
const calc_volume_with_concentration = (moles, concentration) => {
  return Number(moles) / Number(concentration);
};
const calc_density = (mass, volume) => {
  return Number(mass) / Number(volume);
};
const calc_moles_with_concentration = (concentration, volume) => Number(concentration) * Number(volume);
const calc_equivalents = (moles, lim, processType, equivalents) => {
  if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
    let arr = [];
    if (lim.properties) {
      lim.properties.forEach((propertyLim, propertyIndex) => {
        arr[propertyIndex] = propertyLim.limiting ? (Number(moles) / Number(propertyLim?.moles[0])).toFixed(3) : null;
      });
      return arr;
    } else {
      equivalents.forEach((item, index) => {
        arr[index] = lim ? (Number(moles) / Number(lim.moles[0])).toFixed(3) : null;
      });
      return arr;
    }
  }
  return lim ? Number(moles) / Number(lim.moles[0]) : null;
};

const calc_concentration = (moles, volume) => Number(moles) / Number(volume);

const ifMassKnown = (mass, volume, density, concentration, moles, index, isSolution, isLiquid) => {
  let temp_density = density;
  let temp_volume = volume;
  let temp_concentration = concentration;
  if (!!volume) {
    // if (mass) {
    //   temp_density = calc_density(mass, volume);
    // }
    if (moles && index === 0 && !temp_concentration && isSolution) {
      temp_concentration = calc_concentration(moles, volume);
    }
  } else if (!!density || !!concentration) {
    if (!!density && isLiquid) temp_volume = calc_volume(mass, density);
    else if (!!concentration && isSolution) temp_volume = calc_volume_with_concentration(moles, concentration);
  }
  return { density: temp_density, volume: temp_volume, concentration: temp_concentration };
};

//mass=mw / moles
//mass = density * volume

//moles = mass / mw
//moles = concentration * volume

//volume = concentration / moles
//volume = mass / density

//density = mass / volume
