import moment from 'moment';

export const formatSeconds = value => {
  return {
    min: Math.floor(value / 60),
    sec: value % 60,
  };
};

export const formatSecondsToMinsHours = value => {
  let h = Math.floor(value / 3600);
  return {
    hours: h,
    min: Math.floor(value / 60 - h * 60),
    sec: value % 60,
  };
};

export const formatSecondsToTimeString = value => {
  if (value < 0) return 'Pending';
  const getDoubleDigits = value => {
    value = Math.floor(value);
    return value.toString().length < 2 ? ('0' + value).slice(-2) : value;
  };
  const valueReturn = `${getDoubleDigits(value / 3600)}:${getDoubleDigits((value % 3600) / 60)}:${getDoubleDigits(
    value % 60
  )}`;
  return valueReturn;
};

export const formatSecondsToHours = value => {
  return {
    hour: ~~(value / 3600),
    min: ~~((value % 3600) / 60),
  };
};

export const showExpTime = property =>
  property
    ? Math.ceil((+property % 3600) / 60) !== 60
      ? `${formatSecondsToHours(Math.floor(+property)).hour}h
     ${Math.ceil((+property % 3600) / 60)} min`
      : `${formatSecondsToHours(Math.floor(+property)).hour + 1}h 00 min`
    : 'N/A';

export const formatToHHMM = _time => {
  /*let _time = moment().startOf('day').seconds(time);*/
  return `${moment(_time).format('HH')}h ${moment(_time).format('mm')}min`;
};

export const formatToHHMMWithoutText = time => `${moment(time).format('HH')}:${moment(time).format('mm')}`;

export const formatSecondsToMinutes = seconds => {
  let mins = Math.trunc(seconds / 60);
  if (seconds % 60 > 0) mins += 1;
  return mins;
};

export const formatSecondsToMinutesDect = (seconds, symbolsNumberToFixed) => {
  if (seconds === null) return null;
  return (seconds / 60).toFixed(symbolsNumberToFixed);
};

export const convertTime = value => {
  const getDoubleDigits = value => {
    value = Math.floor(value);
    return value.toString().length < 2 ? ('0' + value).slice(-2) : value;
  };
  return `${getDoubleDigits(formatSeconds(value).min)}:${getDoubleDigits(formatSeconds(value).sec)}`;
};
