import React from 'react';

import { SortableList } from '@molecules';

import { ColumnsSortableListItem } from './components/ColumnsSortableListItem';

export const ColumnsSortableList = ({ columns, onOrderChanged, onSelect, searchValue, activeHighlightedItemValue }) => (
  <SortableList
    onOrderChanged={onOrderChanged}
    items={columns}
    renderItem={args => (
      <ColumnsSortableListItem
        {...args}
        activeHighlightedItemValue={activeHighlightedItemValue}
        searchValue={searchValue}
        onSelect={onSelect}
      />
    )}
  />
);
