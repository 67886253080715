import React, { useEffect } from 'react';
import './style.scss';
import cn from 'classnames';
import { AddComponentButton } from './AddComponentButton';
import { Pump } from './Pump';
import { Reactor } from './Reactor';

export const InputComponents = ({
  pumps,
  addPump,
  changePump,
  openCompoundAdd,
  reactorIdx,
  changeCompound,
  variationPossible,
  variationPump,
  getPumpFlowRate,
  setCompoundPosition,
  realTime = false,
  configCharts,
  experiment,
  manual = false,
  onChangeInputEquivalents = () => {},
  hasGlassware = false,
  hasOneInput,
  showOptions,
  limitingInput,
}) => {
  const firstContainer = manual ? !hasGlassware && reactorIdx === 0 : reactorIdx === 0;
  return (
    <div
      data-pdf-node
      className={cn('workspace-input-container', {
        'first-container': firstContainer,
        empty: !pumps.length,
        'one-input': hasOneInput,
      })}
    >
      {pumps.map((pump, idx) => (
        <Pump
          data={pump}
          key={idx}
          changePump={() => changePump(pump, idx, reactorIdx)}
          openCompoundAdd={compoundIDx =>
            openCompoundAdd(
              reactorIdx,
              idx,
              compoundIDx,
              pump.properties.length > 1 || compoundIDx > pump.properties.length - 1
            )
          }
          configCharts={configCharts}
          experiment={experiment}
          reactorIdx={reactorIdx}
          pumpIdx={idx}
          changeCompound={changeCompound}
          varationAvailable={variationPossible && (!variationPump || variationPump.name === pump.name)}
          getPumpFlowRate={getPumpFlowRate}
          realTime={realTime}
          setCompoundPosition={setCompoundPosition}
          manual={manual}
          onChangeInputEquivalents={(v, propertyIndex) => onChangeInputEquivalents(propertyIndex, v, idx)}
          showOptions={showOptions}
          limitingInput={limitingInput}
          notVariable={manual && variationPump && variationPump.name !== pump.name}
        />
      ))}
      <AddComponentButton type="input" addAction={addPump} />
    </div>
  );
};
