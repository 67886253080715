import React from 'react';
import { SJDispensingOptions } from './SJDispensingOptions';

export const SJProDispensingOptions = ({ dispensingOptions, batch, errors, getDetails }) => {
  const autoDispensingOptions = dispensingOptions.filter(option => !option.is_manually);
  const manuallyDispensingOptions = dispensingOptions.filter(option => option.is_manually);

  return (
    <div>
      {!!manuallyDispensingOptions.length && (
        <SJDispensingOptions
          dispensingOptions={manuallyDispensingOptions}
          experiments={batch?.experiments}
          batchId={batch?.uuid}
          getDetails={getDetails}
          step={batch?.step}
          errors={errors}
          isPro
          isManual
        />
      )}
      <SJDispensingOptions
        dispensingOptions={autoDispensingOptions}
        experiments={batch?.experiments}
        batchId={batch?.uuid}
        getDetails={getDetails}
        step={batch?.step}
        errors={errors}
        isPro
      />
    </div>
  );
};
