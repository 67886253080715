export const FIELD_DATA_TYPES = {
  short_text: 'short_text',
  long_text: 'long_text',
  numeric: 'numeric',
  decimal: 'decimal',
  date: 'date',
  datetime: 'datetime',
  boolean: 'boolean',
  user: 'user',
  smile: 'smile',
  select: 'select',
  multiselect: 'multiselect',
  experiment: 'experiment',
};

export const FIELD_DATA_FOR_DROPDOWN = [
  { value: 'short_text', label: 'Short Text' },
  { value: 'long_text', label: 'Long Text' },
  { value: 'numeric', label: 'Numeric' },
  { value: 'decimal', label: 'Decimal' },
  { value: 'date', label: 'Date' },
  { value: 'datetime', label: 'Date & Time' },
  { value: 'boolean', label: 'Checkbox' },
  { value: 'user', label: 'User link' },
  { value: 'smile', label: 'SMILE' },
  { value: 'select', label: 'Dropdown' },
  { value: 'multiselect', label: 'Multiselect dropdown' },
  { value: 'experiment', label: 'Experiment link' },
];

export const FIELD_ATTRIBUTE = {
  name: 'name',
  dataType: 'dataType',
  editable: 'editable',
  unique: 'unique',
  hint: 'hint',
  defaultField: 'defaultField',
  updateHistory: 'updateHistory',
  visible: 'visible',
};

export const TYPE_FOR_DEFAULT_FIELD = [
  FIELD_DATA_TYPES.boolean,
  FIELD_DATA_TYPES.user,
  FIELD_DATA_TYPES.select,
  FIELD_DATA_TYPES.multiselect,
];

export const INITIAL_OPTION = {
  value: '',
  label: '',
  color: '',
  errorMessage: '',
  errorMessageForColor: '',
};

export const INITIAL_DEFAULT_VALUE = {
  checkbox: false,
  value: false,
};

export const INITIAL_ERROR_MESSAGE = {
  name: '',
  hint: '',
  defaultField: '',
  dataType: '',
};

export const KEY_COLUMN_PREFIXES = {
  label: '_label',
  uuid: '_uuid',
};
