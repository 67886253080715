import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HardwareSummary } from '../HardwareSummary/HardwareSummary';
import { CompoundData } from './Compound';
import { ConditionsAndDispensingOptions } from '../ConditionsAndDispensingOptions/ConditionsAndDispensingOptions';
import {
  synjectProcessSteps,
  synjetProducts,
  synjectProcess,
  synjetRules,
  experimentsData,
  synjetQuenching,
  synjetExpectedIntermediates,
} from '../../../../store/synjet/synjet.selector';
import { SYNJET_PROCESS_TYPES } from '../../../../constants';
import { parseProductDataToArray } from '../../../../utils/synjetHelpers';
import reagent from '../../../../dist/icons/process-builder/limited_reagent.svg';
import './styles.scss';
import cn from 'classnames';

export const CompoundGroup = ({ name, children, isLimiting }) => (
  <div className={cn('process-preview__compound-group', { 'limiting-group': isLimiting })}>
    {isLimiting && <img src={reagent} className="limiting" />}
    <div className="process-preview__compound-group__title">{name}</div>
    {children}
  </div>
);

export const CompoundLists = ({ compounds, processType, product, synjetProQuenching, intermediate }) => {
  return (
    <>
      <div className="block-row all-width">
        <div className="block-row__block">
          <div className="block-row__block__title">Reactants</div>
          {compounds.reactants.map(i => (
            <CompoundData data={i} />
          ))}
          {compounds.reactantGroups.map(group => {
            return (
              <CompoundGroup name={group.name} isLimiting={group.isLimiting}>
                {group.compoundsList.map(i => (
                  <CompoundData data={i} />
                ))}
              </CompoundGroup>
            );
          })}
        </div>
        <div className="block-row__block">
          <div className="block-row__block__title">Reagents</div>
          {compounds.reagents.map(i => (
            <CompoundData data={i} />
          ))}
          {compounds.reagentGroups.map(group => {
            return (
              <CompoundGroup name={group.name} isLimiting={group.isLimiting}>
                {group.compoundsList.map(i => (
                  <CompoundData data={i} />
                ))}
              </CompoundGroup>
            );
          })}
        </div>
        <div className="block-row__block">
          <div className="block-row__block__title">{intermediate ? 'Expected intermediate' : 'Product'}</div>
          {product?.length ? (
            product.map((item, index) => (
              <CompoundData data={{ ...item, name: `${intermediate ? 'EI' : 'Pr'}${index + 1}` }} />
            ))
          ) : product?.smiles ? (
            <CompoundData data={{ ...product, name: `${intermediate ? 'EI' : 'Pr'}1` }} />
          ) : (
            <span className="empty">{intermediate ? 'No expected intermediate' : 'No product'}</span>
          )}
        </div>
      </div>
      <div className="block-row all-width">
        {synjetProQuenching?.length > 0 && (
          <div className="block-row__block">
            <div className="block-row__block__title">Quenching</div>
            {synjetProQuenching.map(item => (
              <CompoundData data={item} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export const ProcessPreviewData = ({ processType, isPro }) => {
  const processSteps = useSelector(synjectProcessSteps);
  const experiments = useSelector(experimentsData);
  const products = useSelector(synjetProducts);
  const synjetProQuenching = useSelector(synjetQuenching);
  const synjetProExpectedIntermediate = useSelector(synjetExpectedIntermediates);
  let productsData = parseProductDataToArray(products);

  let twoStepsProductPro = [];
  if (isPro && processSteps.length > 1) {
    let firstStepProduct = [];
    if (processSteps[0].isFixed) {
      firstStepProduct = processSteps[0].product;
    } else {
      firstStepProduct = parseProductDataToArray(synjetProExpectedIntermediate);
    }
    twoStepsProductPro.push(firstStepProduct);
    twoStepsProductPro.push([...productsData]);
  }
  const twoStepsWithFirstFixed = processSteps[0]?.isFixed && processSteps.length > 1;

  return processSteps.length > 1 ? (
    <>
      <div className="block-row process-preview">
        <div>
          <div className="process-preview__title">Hardware summary</div>
          <HardwareSummary
            experiments={experiments}
            twoStepsWithFirstFixed={twoStepsWithFirstFixed}
            isPro={isPro}
            processPreview
          />
        </div>
      </div>
      {processSteps.map((step, index) => {
        return (
          <>
            <div className="block-row__block__step-title">
              Step {index + 1} - {step.isFixed ? 'Fixed' : 'Variable'}
            </div>
            <ConditionsAndDispensingOptions
              processType={processType}
              processConditions={step.conditions}
              hideDispensing={true}
              showHHMM={false}
              editMode={false}
              isFixed={step.isFixed}
            />
            <CompoundLists
              compounds={step.compounds}
              processType={processType}
              product={isPro ? twoStepsProductPro[index] : step.product}
              intermediate={index == 0 ? true : false}
            />
          </>
        );
      })}
    </>
  ) : (
    <>
      <div className="block-row process-preview">
        <div>
          <div className="process-preview__title">Hardware summary</div>
          <HardwareSummary
            experiments={experiments}
            twoStepsWithFirstFixed={twoStepsWithFirstFixed}
            isPro={isPro}
            processPreview
          />
        </div>
        <ConditionsAndDispensingOptions
          processType={processType}
          processConditions={processSteps[0]?.conditions}
          hideDispensing
          showHHMM={false}
          editMode={false}
        />
      </div>
      <CompoundLists
        compounds={processSteps[0]?.compounds}
        processType={processType}
        product={isPro ? productsData : processSteps[0]?.product}
        synjetProQuenching={synjetProQuenching}
      />
    </>
  );
};
