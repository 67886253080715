import { moduleName } from './constants';

const getState = state => state[moduleName];

export const getData = state => {
  const stateSlice = getState(state);
  return stateSlice.data;
};
export const getError = state => {
  const stateSlice = getState(state);
  return stateSlice.error;
};

export const getIsFetched = state => {
  const stateSlice = getState(state);
  return stateSlice.isFetched;
};

export const getIsLoading = state => {
  const stateSlice = getState(state);
  return stateSlice.isLoading;
};
