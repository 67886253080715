import './styles.scss';

import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, openNotification } from 'components/Common';
import { useDispatch } from 'react-redux';
import { createExtensibleTableField, setUpdateExperimentField } from 'store/experimentField/experimentField.actions';
import { initialState } from 'store/experimentField/experimentField.reducer';

import { ExperimentTemplateFieldPopup } from '@organisms';

export const CreateExperimentFieldPopup = ({
  onUpdateTable,
  experimentFieldPopup,
  setExperimentFieldPopup,
  editing,
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleError = errors => {
    errors.forEach(error => {
      error.messages.forEach(errorText => openNotification('', errorText));
    });
  };

  const onSubmit = fieldData => {
    setLoading(true);
    dispatch(createExtensibleTableField(fieldData))
      .then(resp => {
        if (resp.errors) {
          handleError(resp.errors);
        } else {
          onUpdateTable();
          setExperimentFieldPopup(false);
          openNotification('The experiment field has been successfully created');
          dispatch(setUpdateExperimentField(initialState));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        secondary
        className="create-field-button"
        onClick={() => {
          setExperimentFieldPopup(true);
        }}
      >
        <PlusOutlined />
        Add field
      </Button>
      {experimentFieldPopup && !editing && (
        <ExperimentTemplateFieldPopup
          setExperimentFieldPopup={setExperimentFieldPopup}
          onSubmit={onSubmit}
          loading={loading}
        />
      )}
    </>
  );
};
