import './style.scss';

import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar as AntdAvatar } from 'antd';
import cn from 'classnames';

export const Avatar = ({ size = 36, src, children, name, className, classNameAvatarName }) => {
  const inside = children || (name ? name.match(/\b(\w)/g)?.join('') : '');
  return (
    <>
      <AntdAvatar
        className={cn('avatar', className)}
        size={size}
        src={src}
        icon={!children && !name && <UserOutlined />}
      >
        {inside}
      </AntdAvatar>
      {!!name && <span className={cn('avatar-name', classNameAvatarName)}>{name}</span>}
    </>
  );
};
