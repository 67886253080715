import React, { useEffect, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StatusLabel } from '../../Common/StatusLabel';
import { Input, openNotification, Spinner, Table } from '../../Common';
import { SmilesElem } from './SmilesElem';
import { Switch } from '../../Common/Switch';
import { reactors } from '../../../store/processBuilder/processbuilder.selector';
import { dataStepTwo } from '../../../store/experiment/experiment.selector';
import { parseProcessBuilderData } from '../../../utils';
import {
  getExperiment,
  setToStoreDataStepTwo,
  updateDataPreparationMaterials,
} from '../../../store/experiment/experiment.actions';

const compoundPumn = (k, isMFC, lengthVariantOptimization, j, keys) => ({
  compound: { compound: k.properties },
  mw: { value: k.properties.molwt },
  concetration: isMFC ? '' : { value: k.concentration },
  calc_mass: isMFC ? '' : { value: k.calculated_mass },
  act_concetration: isMFC ? '' : { value: k.actual_concentration },
  act_mass: isMFC ? '' : { value: k.actual_mass },
  has_sent: { checked: k.is_done },
  keyCompounds: keys,
  length_variant_optimization: lengthVariantOptimization,
  dataPump: j,
  isMFC,
});

const bottle2 = (j, data) => ({
  bottle: { name: 'Bottle 2' },
  compound: { flash_solvent: j.flash_solvent[0] },
  calc_volume: { value: data.bottle2_volume },
  act_volume: { value: data.bottle2_actual_volume },
  has_sent: { checked: data.bottle2_is_done },
  className: 'background-blue',
  dataPump: j,
});

const bottle1 = (j, rowSpan, isMFC, isVariable, letter, pump) => ({
  pump: (isVariable && letter === 'A') || !isVariable ? { name: j.name, type: j.type[0], key: j.key } : '',
  bottle: { name: isVariable ? `Bottle 1 - ${letter}` : 'Bottle 1' },
  compound: isMFC ? '' : { compound: pump.solvents },
  calc_volume: isMFC ? '' : { value: pump.bottle1_volume },
  act_volume: isMFC ? '' : { value: pump.bottle1_actual_volume },
  rowSpan,
  dataPump: j,
  isBottle1: true,
  className: 'background-grey',
});
const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
const setPackedReactor = data => ({
  pump: { name: 'Packed bed', type: data.key },
  bottle: { name: data.catalysts.length ? 'Cartridge' : '' },
  act_mass: { value: data.cartridge_actual_mass },
  compound: { packing: data.catalysts.map(i => i.compound_name || i.formula).join(', ') },
  has_sent: { checked: !!data.is_done },
  className: 'background-green',
  nameReactor: data.name,
});

const setRow = data => ({
  pump: '',
  bottle: '',
  compound: '',
  calc_volume: '',
  act_volume: '',
  mw: '',
  concetration: '',
  calc_mass: '',
  act_concetration: '',
  act_mass: '',
  has_sent: '',
  ...data,
});

const ceil10 = (valueProp, exp = 1) => {
  let value = valueProp.toString().split('e');
  value = Math.ceil(+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
  value = value.toString().split('e');
  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
};

const round = value => {
  try {
    if (!value && value !== 0) {
      return '';
    }
    return parseFloat(parseFloat(value).toFixed(3));
  } catch (e) {
    return 'Invalid value';
  }
};

export const PrepareMaterials = ({
  experiment,
  setTableData,
  setHeight,
  setErrors,
  tableData,
  height,
  errors,
  setEdited,
  checkIfProcessStillValid,
  validationError,
}) => {
  const [loading, setLoading] = useState(false);
  const [expData, setExpData] = useState(experiment);
  const { key: experimentKey } = useParams();
  const allReactors = useSelector(reactors).allReactors || [];
  const cacheData = useSelector(dataStepTwo);
  const dispatch = useDispatch();

  const transformKey = key => {
    const parts = key.split('_');
    return `${parts[0]}_${parts[2]}`;
  };
  useEffect(() => {
    if (!!expData && !!allReactors.length && !validationError) {
      // if (!!tableData.length) return;
      const reactionsInfo = JSON.parse(expData.reactionsInfo);
      const proccessedData = parseProcessBuilderData(reactionsInfo, { allReactors }, expData.process.type, true);
      const data = [];

      const isOptimization = expData.process.type === 'Optimization';
      const isLibraryGen = expData.process.type === 'Library generation';
      const callBack = j => {
        if (
          j.type[0] === 'MGAT' &&
          j.properties[0]?.reactants?.length === 0 &&
          j.properties[0]?.reagents?.length === 0 &&
          j.properties[0]?.solvents?.length === 0
        )
          return;
        const isMFC = j.type[0] === 'MFC';
        const isVariable = j.is_variant;
        let rowSpan = isMFC ? 0 : 1;
        const lengthVariantOptimization = j.lengthOfVariants;
        j.properties.forEach(pump => {
          rowSpan += pump.reactants.length + pump.reagents.length + 1;
        });
        const setUpReactReag = k => {
          const keys = [];
          if (!isVariable) {
            const currentKey = transformKey(k.key);
            const targetPumpData = reactionsInfo.pumps.find(i => i.name === j.name);
            if (k.reagent) {
              targetPumpData.properties.forEach(i => {
                const variantReagent = i.reagents.find(l => transformKey(l.key) === currentKey);
                if (variantReagent) keys.push(variantReagent.key);
              });
            } else {
              targetPumpData.properties.forEach(i => {
                const variantReactant = i.reactants.find(l => transformKey(l.key) === currentKey);
                if (variantReactant) keys.push(variantReactant.key);
              });
            }
          }
          data.push(setRow(compoundPumn(k, isMFC, lengthVariantOptimization, j, isVariable ? [k.key] : keys)));
        };

        if (isOptimization) {
          const dataPump = j.properties[0];
          rowSpan = dataPump.reactants.length + dataPump.reagents.length + (isMFC ? 1 : 2);
          data.push(setRow(bottle1(j, rowSpan, isMFC, isVariable, letters[0], dataPump)));
          dataPump.reactants.forEach(setUpReactReag);
          dataPump.reagents.forEach(setUpReactReag);
        } else {
          j.properties.forEach((pump, idx) => {
            data.push(setRow(bottle1(j, rowSpan, isMFC, isVariable, letters[idx], pump)));
            pump.reactants.forEach(setUpReactReag);
            pump.reagents.forEach(setUpReactReag);
          });
        }

        !isMFC && data.push(setRow(bottle2(j, j.properties[0])));
      };
      proccessedData.forEach(i => {
        i.pumps.forEach(callBack);
        if (i.type === 'packed') {
          i.catalysts.length && data.push(setRow(setPackedReactor(i)));
        }
      });
      setTableData(data);
    }
  }, [JSON.stringify(expData), !!allReactors.length, validationError]);

  useEffect(
    () => () => {
      dispatch(setToStoreDataStepTwo(tableData));
    },
    [tableData]
  );

  useEffect(() => {
    if (experimentKey) {
      setLoading(true);
      dispatch(getExperiment(experimentKey)).then(exp => {
        setExpData(exp);
        setLoading(false);
      });
    }
    checkIfProcessStillValid();
    const handlerResize = e => {
      const screen = document.querySelector('.prepare-materials');
      if (!screen) return;
      const header = screen.querySelector('.ant-table-header');
      const newHeight = window.innerHeight - 361 - header?.offsetHeight;

      setHeight(newHeight > 300 ? newHeight : 300);
    };
    handlerResize();
    window.addEventListener('resize', handlerResize);
    return () => window.removeEventListener('resize', handlerResize);
  }, []);

  const validationInput = value => {
    let error = false;
    const val = value.split('.');
    if (val.length === 2) {
      if (val[0].length === 0 || val[0].length === 11) error = true;
      else if (val[1].length === 2) error = true;
    } else if (val[0].length === 11) error = true;

    return error;
  };
  const handleChangeInput = (value, field, index) => {
    if (validationInput(value)) return;
    setTableData(
      tableData.map((i, idx, arr) => {
        if (index === idx) {
          let bottle = null;
          let concentration = null;
          const isActMass = field === 'act_mass';
          if (isActMass) {
            arr.forEach((j, jdx) => {
              if (jdx < idx && !!j.bottle) bottle = j;
            });

            concentration = +value / (i.mw.value * +bottle?.calc_volume.value);
          }
          return {
            ...i,
            [field]: { ...i[field], value },
            act_concetration: isActMass
              ? {
                  ...i.act_concetration,
                  value: concentration === 0 ? '0.0' : ceil10(concentration, -1),
                }
              : i.act_concetration,
            has_sent: i.has_sent ? { ...i.has_sent, checked: false } : i.has_sent,
          };
        }
        return i;
      })
    );
    setEdited(true);
    setErrors(errors.filter(i => i.index !== index));
  };

  const handleChangeSwitch = (value, index, rowData) => {
    let type = '';
    let data = null;
    setEdited(true);
    if (!!rowData.bottle && !rowData.compound.hasOwnProperty('packing')) {
      type = 'updatePumpBottle';
      if (rowData.calc_volume.value - 0.05 * rowData.calc_volume.value > +rowData.act_volume.value) {
        setErrors([...errors, { index, field: 'act_volume' }]);
        openNotification('', 'Actual Volume should be more than Calculated Volume');
        return;
      }
      const variableBottle = letters.find(i => i === rowData.bottle.name[rowData.bottle.name.length - 1]);
      data = {
        uuid: expData.uuid,
        pump: rowData.dataPump.name,
        bottle: rowData.bottle.name[rowData.bottle.name.length - 1],
        actualVolume: +rowData.act_volume.value,
      };
      if (variableBottle) data = { ...data, bottle: '1', reactionIndex: letters.findIndex(i => i === variableBottle) };
    } else if (rowData.compound?.hasOwnProperty('packing')) {
      type = 'updateReactorMaterial';
      if (+rowData.act_mass.value === 0) {
        setErrors([...errors, { index, field: 'act_mass' }]);
        openNotification('', 'Actual Mass of packing should be provided');
        return;
      }
      data = {
        uuid: expData.uuid,
        reactor: rowData?.nameReactor,
        cartridgeActualMass: +rowData.act_mass.value,
      };
    } else {
      type = 'updatePumpMaterial';
      if (rowData.calc_mass.value - 0.05 * rowData.calc_mass.value > +rowData.act_mass.value) {
        setErrors([...errors, { index, field: 'act_mass' }]);
        openNotification('', 'Actual Mass should be more than Calculated Mass');
        return;
      }
      data = {
        uuid: expData.uuid,
        pump: rowData?.dataPump?.name,
        materialKeys: rowData.keyCompounds,
        actualMass: +rowData.act_mass.value || 0,
        actualConcentration: rowData.act_concetration.value,
      };
      if (!data.actualConcentration) delete data.actualConcentration;
    }
    setLoading(true);
    dispatch(updateDataPreparationMaterials(type, data))
      .then(() => {
        if (value !== null) {
          setTableData(
            tableData.map((i, idx) => {
              if (index === idx) {
                return {
                  ...i,
                  has_sent: { ...i.has_sent, checked: value },
                };
              }
              return i;
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: 'Pump',
      dataIndex: 'pumn',
      width: '8%',
      fixed: 'left',
      cellpadding: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: (
            <div className="pumn-block">
              <span>{row.pump.name}</span>
              <span>{row.pump.type}</span>
              {row.pump?.key && <span>{row.pump.key}</span>}
            </div>
          ),
          props: { rowSpan: row.rowSpan, colSpan: row.pump ? 1 : 0 },
        };
        return obj;
      },
    },
    {
      title: 'Bottle / Cartridge',
      dataIndex: 'bottle',
      width: '8%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        const name = row.bottle.name ? `${row.bottle.name.split(' ')[0]} ${row.bottle.name.split(' ')[1]}` : '';
        if (row.bottle.name) {
          obj.children = (
            <div className={cn('bottle-cell', row.className)}>
              <div>{row.bottle.name}</div>
              <div>{name === 'Bottle 1' ? '(Reagent)' : name === 'Bottle 2' ? '(Solvent)' : ''}</div>
            </div>
          );
        }
        return obj;
      },
    },
    {
      title: 'Reagent / Reactant / Solvent',
      dataIndex: 'compound',
      width: '29%',
      render: (value, row, index, ...other) => {
        const obj = { children: <div className={row.className}>&nbsp;</div> };
        if (row.compound.flash_solvent || row.compound.packing) {
          obj.children = (
            <div className={cn('flash-solvent-block', row.className)}>
              {row.compound.packing || row.compound.flash_solvent.compound_name || row.compound.flash_solvent.formula}
            </div>
          );
        } else if (Array.isArray(row.compound.compound)) {
          obj.children = (
            <div className={cn('solvent-block', row.className)}>
              {row.compound.compound.map((i, idx, arr) => {
                const elem = i.properties;
                return (
                  <>
                    <span className="solvent-block_name">{elem.compound_name || elem.formula}</span>
                    <span className="solvent-block_val">
                      {i.fraction && <>({i.fraction})</>}
                      {idx === arr.length - 1 ? '' : ', '}
                    </span>
                  </>
                );
              })}
            </div>
          );
        } else if (typeof row.compound.compound === 'object') {
          obj.children = (
            <div>
              <SmilesElem id={index} data={row.compound.compound} displayLoupe />
            </div>
          );
        }

        return obj;
      },
    },
    {
      title: 'Calculated Volume (mL)',
      dataIndex: 'calc_volume',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (row.calc_volume.hasOwnProperty('value')) {
          obj.children = (
            <div className={cn('calc-volume-block', row.className)}>{Number(row.calc_volume.value).toFixed(1)}</div>
          );
        }
        return obj;
      },
    },
    {
      title: 'Actual Volume (mL)',
      dataIndex: 'act_volume',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (row.act_volume.hasOwnProperty('value')) {
          const error = errors.find(i => i.index === index);
          obj.children = (
            <Input
              regex={/[^\d|.]+/g}
              className={row.className}
              value={row.act_volume.value}
              onChange={(...args) => handleChangeInput(...args, index)}
              field="act_volume"
              onBlur={() => {
                if (row.bottle.name === 'Bottle 2') return;
                handleChangeSwitch(null, index, row);
              }}
              error={error?.field === 'act_volume'}
            />
          );
        }
        return obj;
      },
    },
    {
      title: 'MW (g/mol)',
      dataIndex: 'mw',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };

        if (row.mw?.value) {
          obj.children = <div className={cn('calc-volume-block', row.className)}>{round(row.mw.value)}</div>;
        }
        return obj;
      },
    },
    {
      title: 'Concentration (M)',
      dataIndex: 'concetration',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn('calc-volume-block', row.className)}>&nbsp;</div>,
        };

        if (row.concetration.hasOwnProperty('value')) {
          obj.children = <div className={cn('calc-volume-block', row.className)}>{round(row.concetration.value)}</div>;
        }
        return obj;
      },
    },
    {
      style: { background: 'green' },
      title: 'Calculated Mass (mg)',
      dataIndex: 'calc_mass',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (row.calc_mass?.value) {
          obj.children = <div className={cn('calc-volume-block', row.className)}>{round(row.calc_mass.value)}</div>;
        }
        return obj;
      },
    },
    {
      title: 'Actual Concentration (M)',
      dataIndex: 'act_concetration',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };

        if (row.act_concetration?.hasOwnProperty('value')) {
          obj.children = (
            <div className={cn('calc-volume-block', row.className)}>{round(row.act_concetration.value)}</div>
          );
        }
        return obj;
      },
    },
    {
      title: 'Actual Mass (mg)',
      dataIndex: 'act_mass',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (typeof row.act_mass === 'object') {
          const error = errors.find(i => i.index === index);
          obj.children = (
            <Input
              regex={/[^\d|.]+/g}
              value={row.act_mass.value}
              className={row.className}
              onChange={(...args) => handleChangeInput(...args, index)}
              field="act_mass"
              error={error?.field === 'act_mass'}
            />
          );
        }
        return obj;
      },
    },
    {
      title: 'Done',
      dataIndex: 'has_sent',
      width: '6%',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (typeof row.has_sent?.checked === 'boolean') {
          obj.children = (
            <div className={row.className}>
              <Switch checked={row.has_sent?.checked} onChange={value => handleChangeSwitch(value, index, row)} />
            </div>
          );
        }
        return obj;
      },
    },
  ];

  return (
    <div className="prepare-materials">
      <div className="prepare-materials_header">
        <span className="prepare-materials_header_name">{expData?.name}</span>
        <StatusLabel type="active">{expData?.status}</StatusLabel>
      </div>
      <div>
        <Spinner loading={loading}>
          <Table
            rowClassName="prepare-materials_row"
            columns={columns}
            dataSource={tableData}
            sidePadding={false}
            scroll={{ y: height }}
          />
        </Spinner>
      </div>
    </div>
  );
};
