import { http } from '../../utils/http';
import { mutationUpdateUserRoles, queryListUsers, queryUserById, queryUsersByUuids } from '../graphql';
import * as actions from './users.actionsType';

const setUsersList = payload => ({ type: actions.SET_USERS_LIST, payload });

export const getListUsers = (page, pageSize, search) => dispatch =>
  http(queryListUsers('send', null, page, pageSize, search)).then(resp => {
    dispatch(setUsersList(queryListUsers('get', resp, page)));
    return resp.allUsers.results;
  });

export const getUsersByUuids = usersUuids =>
  http(queryUsersByUuids('send', null, usersUuids)).then(resp => queryUsersByUuids('get', resp));

export const getListUsersBySearch = (page, pageSize, search) =>
  http(queryListUsers('send', null, page, pageSize, search)).then(resp => resp.allUsers.results);

export const getUserById = uuid => dispatch =>
  http(queryUserById('send', null, uuid)).then(resp => {
    dispatch(setUsersList(queryUserById('get', resp, uuid)));
    return resp.user;
  });

export const updateUserRoles = (data, page) => dispatch =>
  http(mutationUpdateUserRoles(data)).then(resp => {
    dispatch(getListUsers(page));
    return resp.updateUserRoles;
  });
