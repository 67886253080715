import React, { useEffect, useState } from 'react';
import './style.scss';
import {
  Chart as BizChart,
  Line,
  Point,
  Tooltip as BizTooltip,
  Slider,
  Legend,
  Interaction,
  Axis,
  Path,
  Annotation,
} from 'bizcharts';
import { getHHmmSSFromSec } from '../../../utils/date';
import { Tooltip as TooltipComponent } from '../../Common';

const lineStyle = {
  stroke: '#E00087',
  fill: '#E00087',
  // lineDash: [0, 1, 1],
  lineWidth: 1,
};

const getTimeScale = ({ data, configChart, axisYName, endPoint, useMin }) => {
  let defaultConfig = {
    type: 'linear',
    alias: axisYName,
  };
  const lastPointData = data[data.length - 1];
  const max = Math.ceil(lastPointData.time / 5) * 5;
  const clearMax = Math.ceil(lastPointData.time);
  const min = Math.floor(data[0].time);
  const rangeStartEnd = clearMax - (useMin ? min : 0);
  if (rangeStartEnd < 30) {
    const isLastPoint = endPoint.timeInSec === lastPointData.timeInSec;
    defaultConfig = {
      ...defaultConfig,
      min: useMin ? min : 0,
      tickInterval: isLastPoint && !useMin ? 5 : 1,
      max: isLastPoint && !useMin ? 30 : clearMax,
    };
  } else if (rangeStartEnd <= 120) {
    defaultConfig = {
      ...defaultConfig,
      min: useMin ? min : 0,
      tickInterval: 5,
      max,
    };
  } else {
    const tickInterval = Math.floor(rangeStartEnd / 60);
    defaultConfig = {
      ...defaultConfig,
      min: useMin ? min : 0,
      tickInterval: tickInterval * 5,
      max: max + tickInterval * 5,
    };
  }

  return defaultConfig;
};

const getTimeScaleMimi = ({ data, configChart, axisYName, useMin }) => {
  const lastPointData = data[data.length - 1];
  const max = Math.ceil(lastPointData.time / 5) * 5;
  const clearMax = Math.ceil(lastPointData.time);
  const min = Math.floor(data[0].time);
  const rangeStartEnd = clearMax - min;
  const tickInterval = Math.floor(rangeStartEnd / 60);

  return {
    type: 'linear',
    alias: axisYName,
    min: useMin ? min : 0,
    tickInterval: tickInterval * 20,
    max: max + tickInterval * 5,
  };
};

const getValueScale = ({ data, miniVersion }) => {
  const defaultConfig = {
    alias: 'T, °C',
  };
  let min = Math.floor(Math.min(...data.map(i => i.value)) / 10) * 10;
  if (min > 0) min = 0;
  const max = Math.ceil(Math.max(...data.map(i => i.value)) / 10) * 10;
  const realMax = Math.ceil(Math.max(...data.map(i => i.value)));
  const different = max - min;
  const different10 = Math.ceil(different / 10);

  const degree = miniVersion ? 2 : 1;
  const tickInterval = Math.floor(different / 100);
  const adjustValue = tickInterval * 15 * degree;
  return {
    ...defaultConfig,
    tickInterval: adjustValue,
    min: min === 0 ? 0 : min - different10,
    max: max <= 30 ? realMax + 2 : max + different10,
  };
};

const getValueScaleAnalytics = ({ data }) => {
  const defaultConfig = {
    alias: 'T, °C',
  };
  let tickInterval = 5000000;
  const max = Math.ceil(Math.max(...data.map(i => i.value)) / 1000000) * 1000000;
  if (max < 10000000) tickInterval = 500000;
  else if (max < 100000000) tickInterval = 5000000;
  else if (max < 1000000000) tickInterval = 50000000;
  else if (max < 10000000000) tickInterval = 500000000;
  else if (max < 100000000000) tickInterval = 5000000000;
  else tickInterval = 50000000000;
  return {
    ...defaultConfig,
    min: 0,
    tickInterval,
    max: max + tickInterval,
  };
};

export const Chart = React.memo(
  ({
    data,
    dataConfig,
    label,
    axisYName,
    endPoint,
    analytics,
    miniVersion,
    useMin,
    errorDataNotFount,
    errorData502,
  }) => {
    const [scale, setScale] = useState({});
    const [axisYMax, setAxisYMax] = useState(1);
    const [axisXMax, setAxisXMax] = useState(1);
    const [lastPoint, setLastPoint] = useState(null);
    const isMuchData = !data?.length;
    useEffect(() => {
      if (data.length) {
        const time = miniVersion
          ? getTimeScaleMimi({
              data,
              axisYName,
              endPoint,
              useMin,
            })
          : getTimeScale({
              data,
              axisYName,
              endPoint,
              useMin,
            });
        const value = analytics ? getValueScaleAnalytics({ data }) : getValueScale({ data, miniVersion });
        time.tickInterval = time.tickInterval || 1;
        value.tickInterval = value.tickInterval || 5;
        time.max = Math.floor(time.max / time.tickInterval) * time.tickInterval + time.tickInterval;
        value.max = Math.floor(value.max / value.tickInterval) * value.tickInterval + value.tickInterval;
        const last = data[data.length - 1];
        setLastPoint(last);
        setAxisYMax(value.max);
        setAxisXMax(time.max);
        setScale({
          time,
          value,
        });
      } else {
        setScale({
          time: {
            alias: 'T, °C',
            max: 1,
            min: 0,
            tickInterval: 1,
            type: 'linear',
          },

          value: {
            alias: 'T, °C',
            max: 1,
            min: 0,
            tickInterval: 1,
          },
        });
      }
    }, [data.length]);

    const currentPointView = getHHmmSSFromSec(lastPoint?.timeInSec || 0);
    const text = {
      position: 'start',
      autoRotate: false,
      style: {
        fill: '#2C4652',
      },
      offsetX: 5,
      offsetY: -0,
      content: currentPointView,
    };

    return (
      <>
        {label && <div className="chart-component_label">{label}</div>}
        <div className="chart-component">
          {isMuchData && !useMin && !analytics && (
            <div className="chart-component_empty">
              {errorDataNotFount ? (
                <span className="chart-component_empty_title">Data not found</span>
              ) : endPoint ? (
                ''
              ) : (
                <>
                  <span className="chart-component_empty_title">Data could not be rendered</span>
                  {!errorData502 && (
                    <span className="chart-component_empty_sub-title">
                      Amount of univariate data is too big and could not be rendered on the chart
                    </span>
                  )}
                </>
              )}
            </div>
          )}
          <BizChart
            animate
            scale={scale}
            padding={miniVersion ? [28, 42, 30, 42] : [56, 85, 60, 85]}
            autoFit
            data={data}
          >
            <Line
              animate={{
                appear: {
                  animation: 'wave-in',
                  duration: 1000,
                },
              }}
              position="time*value"
              color={[
                'name',
                name => {
                  const configItem = dataConfig.find(i => i.sensor_id === name);
                  return configItem?.colorLine;
                },
              ]}
              shape={[
                'name',
                name => {
                  const configItem = dataConfig.find(i => i.sensor_id === name);
                  return configItem?.typeLine;
                },
              ]}
              style={[
                'name',
                name => {
                  let res = { lineDash: [5, 0, 0] };
                  if (dataConfig.find(i => i.sensor_id === name)?.typeLine === 'line') res = { lineDash: [0, 0, 0] };
                  return res;
                },
              ]}
            />
            {!miniVersion && !isMuchData && (
              <Annotation.Line
                start={{ time: lastPoint?.time, value: '0' }}
                end={{ time: lastPoint?.time, value: axisYMax }}
                style={lineStyle}
                text={text}
                animate={false}
              />
            )}
            <Axis
              name="time"
              label={{
                formatter(text, item, index) {
                  return text == axisXMax ? 't, min' : text;
                },
              }}
            />
            <Axis
              name="value"
              label={{
                formatter(text, item, index) {
                  return text == axisYMax
                    ? axisYName
                    : analytics && text != 0
                    ? Number.parseFloat(text).toExponential(2)
                    : text;
                },
              }}
            />
            <BizTooltip visible={!miniVersion} shared follow showMarkers showCrosshairs>
              {(title, items, ...props) => (
                <Tooltip analytics={analytics} title={title} items={items} axisYName={axisYName} />
              )}
            </BizTooltip>
            <Legend
              visible={!miniVersion}
              layout="horizontal"
              position="bottom"
              itemName={{
                formatter: (text, item, index) => dataConfig?.find(i => i.sensor_id === text)?.name,
              }}
            />
          </BizChart>
        </div>
      </>
    );
  },
  (prev, next) => prev.data.length === next.data.length && prev?.endPoint?.timeInSec === next?.endPoint?.timeInSec
);

const Tooltip = ({ title, items, axisYName, analytics, ...props }) => {
  const titleTime = getHHmmSSFromSec(items[0]?.data?.timeInSec);
  return (
    <div className="chart-component_tooltip">
      <div className="chart-component_tooltip_title">{titleTime}</div>
      {items.map(i => {
        const { color } = i;
        const type = i.data?.extraOptions?.typeLine;
        const name = i.data?.extraOptions?.name;
        const value = analytics ? Number.parseFloat(i.value).toExponential(2) : i.value;
        return (
          <div className="chart-component_tooltip_item">
            <div className="chart-component_tooltip_item_wrapper">
              <div
                className="chart-component_tooltip_item_line"
                style={{ borderBottom: `3px ${type === 'dash' ? 'dashed' : 'solid'} ${color}` }}
              />
              <TooltipComponent title={name}>
                <div className="chart-component_tooltip_item_name">{name}</div>
              </TooltipComponent>
            </div>
            <div className="chart-component_tooltip_item_value">
              {value} {axisYName !== 'Intensity' && axisYName}
            </div>
          </div>
        );
      })}
    </div>
  );
};
