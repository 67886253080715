import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from 'utils/history';
import { Spinner, Popup, StatusLabel, openNotification } from 'components/Common';
import { isEqual } from 'lodash';
import { saveAs } from 'file-saver';
import { Steps, Footer, SJReviseTheDetails, SJBatchConfiguration } from '../../../components/Execution';
import { user } from '../../../store/common/common.selector';
import '../style.scss';
import { useParams } from 'react-router-dom';
import { DEVICES, EXPERIMENT_STATUSES } from '../../../constants/common';
import {
  manageBatch,
  recalculateDispensers,
  updateSJPExperimentConditions,
  saveVialsSchedule,
} from '../../../store/scheduling/scheduling.actions';
import { setBatchErrorShown, sjpLaunchBatch } from '../../../store/synjet/synjet.actions';
import {
  finalizeBatch,
  finalizeExperimentOnBatch,
  getNotes,
  pauseBatch,
  resumeBatch,
  endBatch,
  setRouteStructureFromExp,
  completeBatch,
} from '../../../store/experiment/experiment.actions';
import { getDevices } from '../../../store/common/common.actions';
import { BatchNotesSidebar } from '../../../components/Execution/NotesSidebar/BatchNotesSidebar';
import { RejectExperimentFromBatchPopup } from '../../../components/BatchDetails/RejectExperimentFromBatchPopup';
import { SynJetProPrepareMaterials } from '../../../components/Execution/SynJetProExecution/SynJetProPrepareMaterials';
import { getExperimentTableData } from '../../../utils/execution';
import { prepareMaterialsSynJetPro } from '../../../store/synjetPro/synjetPro.actions';
import { setDispenserCountError, validateDispenserCount } from '../../../utils/synjetProExecutionHelpers';
import { SynJetProLoadMaterials } from '../../../components/Execution/SynJetProExecution/SynJetProLoadMaterials';
import { getSynjetProcessData } from '../../../utils/synjetHelpers';
import { parseJSONErrors } from '../../../utils';
import { DISPENSER_COUNT_ERROR_MESSAGE } from '../../../constants/execution';
import SynJetProExperimentProgress from '../../../components/Execution/SynJetProExecution/SynJetProExperimentProgress';
import { SjpBatchFinalization } from '../../../components/Execution/SynJetProExecution/BatchFinalization/BatchFinalization';
import { parseExpToRouteSynJetPro } from '../../../utils/parseRoute/parseSynjetProRoute';

const listCheckboxesSJP = [
  {
    is_checked: false,
    item: 'Confirm heater blocks are at desired temperature(s) for batch',
    sub_items: [],
  },
  {
    is_checked: false,
    item: 'Check FANUC / hardware status',
    sub_items: [],
  },
  {
    is_checked: false,
    item: 'Confirm vials loaded in starting block',
    sub_items: [],
  },
  {
    is_checked: false,
    item: 'Confirm dispenser reservoirs loaded with reactant/reagent solutions',
    sub_items: [],
  },
  {
    is_checked: false,
    item: 'Confirm finish tray empty',
    sub_items: [],
  },
  {
    is_checked: false,
    item: 'Final Safety check',
    sub_items: [
      {
        is_checked: false,
        item: 'Verify no vials on deck',
        sub_items: [],
      },
      {
        is_checked: false,
        item: 'Verify no vials on conveyor',
        sub_items: [],
      },
      {
        is_checked: false,
        item: 'Verify no leaks or other abnormalities around dispensers',
        sub_items: [],
      },
    ],
  },
];

const finalizationCheckList = [
  { checked: false, label: 'Confirm heater blocks in standby (room temp or off)' },
  { checked: false, label: 'Clean the workspace' },
  { checked: false, label: 'Replace fluid bodies' },
  { checked: false, label: 'Dispose of waste' },
  { checked: false, label: 'Leave dispense heads in standby with appropriate solvent' },
  { checked: false, label: 'Power down hardware' },
  {
    checked: false,
    label: 'Verify starting block, heater block, conveyor belt, cooling block, and finish tray are clear of vials',
  },
];

export const SynJetProDetails = () => {
  const dispatch = useDispatch();
  const { key: batchKey } = useParams();
  const [batchID, setBatchId] = useState(null);
  const currentUser = useSelector(user);
  const [deviceId, setDeviceId] = useState('');
  const [activeStep, setActiveStep] = useState(null);
  const [batch, setBatch] = useState(null);
  const [batchLoading, setBatchLoading] = useState(false);
  const [experiments, setExperiments] = useState([]);
  const [showNotesSidebar, setShowNotesSidebar] = useState(false);
  const [rejectBatchOpen, setRejectBatchOpen] = useState(false);
  const [rejectWarningOpen, setRejectWarningOpen] = useState(false);
  const [type, setType] = useState('batch');
  const [checkboxesOnBatchConfigStep, setcheckboxesOnBatchConfigStep] = useState({ allChecked: 0, generated: false });
  const [confirmOnBatchConfigStep, setconfirmOnBatchConfigStep] = useState(false);
  const [openCompleteConfirmation, setOpenCompleteConfirmation] = useState(false);

  const [finalizationOptions, setFinalizationOptions] = useState([...finalizationCheckList]);

  const [rejectItem, setRejectItem] = useState(null);
  const [experimentsInfo, setExperimentsInfo] = useState([]);
  const [heatingBlocks, setHeatingBlocks] = useState([]);
  const [initialHeatingBlocks, setInitialHeatingBlocks] = useState([]);
  const [initialExperimentsInfo, setInitialExperimentsInfo] = useState([]);
  const [dispensingOptions, setDispensingOptions] = useState([]);

  const [dispenserErrors, setDispenserErrors] = useState([]);
  const [conditionsErrors, setConditionsErrors] = useState(null);

  const [errors, setErrors] = useState({
    actual_volume: [],
    actual_mass: [],
    solvents: [],
    fraction: [],
  });
  const [edited, setEdited] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [lmTableData, setLmTableData] = useState([]);
  const [lmErrors, setLmErrors] = useState({ solids: {} });
  const [listCheckboxes, setListCheckboxes] = useState([]);
  const [finishPlates, setFinishPlates] = useState({ tray1: '', tray2: '' });
  const [finishPlatesErrors, setFinishPlatesErrors] = useState({ tray1: false, tray2: false });
  const [errorNotification, setErrorNotification] = useState(null);
  const [openPauseConfirmation, setOpenPauseConfirmation] = useState(false);
  const [openEndConfirmation, setOpenEndConfirmation] = useState(false);
  const [notReceivedAnalytics, setNotReceivedAnalytics] = useState([]);
  const [openFinalizeConfirmation, setOpenFinalizeConfirmation] = useState(false);
  const [finalizeExperimentActionOnClick, setFinalizeExperimentAction] = useState(false);
  const [plateVialsData, setPlateVialsData] = useState([]);

  const [connectivitySubject, setConnectivitySubject] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);

  const disableContinue =
    (activeStep === 3 && !checkboxesOnBatchConfigStep.generated) ||
    (activeStep === 4 && listCheckboxes.some(i => i.is_checked === false));

  const isPermittedDownloadDatFile = currentUser?.permissions?.execution?.download_dat_files;

  const mappedTableDataFor4Step = data => {
    setTableData({
      ...data,
      solid_structure: data.solid_structure.map(i => ({
        ...i,
        has_sent: i.vials.every(item => item.actual_mass > item.calculated_mass),
      })),
    });
  };

  const getDetails = (skipStepNavigation = false) => {
    setBatchLoading(true);
    dispatch(manageBatch({ data: { key: batchKey }, type: 'getBatchDetails' }))
      .then(response => {
        dispatch(getNotes({ experimentId: response.uuid }));
        setBatch(response);
        setBatchId(response.uuid);
        response.step === 3
          ? mappedTableDataFor4Step(JSON.parse(response.metaData))
          : setTableData(JSON.parse(response.metaData));
        setExperiments(response.experiments);
        setDeviceId(response.timeSlot.device.uuid);
        setPlateVialsData(JSON.parse(response.metaData).final_plate);

        if (response.step && !skipStepNavigation) setActiveStep(response.step + 1);
        else setActiveStep(1);
      })
      .finally(() => setBatchLoading(false));

    setListCheckboxes(listCheckboxesSJP);
  };

  useEffect(() => {
    if (batch && batch?.experiments) {
      const experiments = batch?.experiments.map(exp => getExperimentTableData(exp));
      setExperimentsInfo(experiments);
      setInitialExperimentsInfo(experiments);
      if (batch?.dispenserStructure) {
        setDispensingOptions(JSON.parse(batch?.dispenserStructure));
        setLmTableData(JSON.parse(batch?.dispenserStructure));
      }
      if (batch.metaData) {
        const parsedMetaData = JSON.parse(batch?.metaData);
        const { heating_blocks } = parsedMetaData;
        setHeatingBlocks(heating_blocks || []);
        setInitialHeatingBlocks(heating_blocks || []);
      }
    }
  }, [batch]);

  const handleRejectConfirm = () => {
    setRejectWarningOpen(false);
    setRejectBatchOpen(true);
  };

  const dropdownMenu = [
    ...(batch?.experiments
      ? batch?.experiments.map(experiment => ({
          name: `Reject Experiment ${experiment.name}`,
          onClick: () => {
            setRejectWarningOpen(true);
            setType('experiment');
            setRejectItem(experiment);
          },
        }))
      : []),
    {
      name: 'Reject batch',
      onClick: () => {
        setRejectWarningOpen(true);
        setType('batch');
      },
    },
  ];

  const manageExperimentRoutes = experiments => {
    if (!experiments) {
      return;
    }
    experiments.forEach(experiment => {
      if (experiment.status === 'Completed' && !experiment.route) {
        const parsedRoute = parseExpToRouteSynJetPro(JSON.parse(experiment.reactionsInfo), experiment.key);
        setRouteStructureFromExp({
          uuid: experiment.uuid,
          data: JSON.stringify(JSON.stringify(parsedRoute)),
        });
      }
    });
  };

  useEffect(() => {
    getDetails();
  }, [batchKey]);

  const handleError = errors => {
    setBatchLoading(false);
    errors.forEach(error => {
      error.messages.forEach(msg => openNotification(null, msg));
    });
  };

  const checkTableValidation = data => {
    let isVolumeError = false;
    let isMassError = false;
    let isFractionError = false;
    let isSolventError = false;
    const err = {
      actual_volume: [],
      actual_mass: [],
      fraction: [],
      solvents: [],
    };
    data.forEach((rowData, index) => {
      if (+rowData.actual_volume < Math.ceil(rowData.calculated_volume * 100) / 100) {
        isVolumeError = true;
        err.actual_volume.push({ index, field: 'actual_volume' });
      }
      if (+rowData.actual_mass < Math.ceil(rowData.calculated_mass * 100) / 100) {
        isMassError = true;
        err.actual_mass.push({ index, field: 'actual_mass' });
      }

      const fragmentSum = rowData?.solvents.reduce((sum, currentSolvent) => +currentSolvent.fraction + sum, 0);
      if (rowData.solvents.length && fragmentSum !== 1) {
        isFractionError = true;
        err.fraction.push({
          index,
          solventsIdx: [...rowData.solvents.map((i, idxSolvent) => idxSolvent)],
        });
      }

      const solventsIdx = [];
      rowData.solvents.forEach((solvent, solventIdx) => {
        if (!solvent.compound_id) {
          isSolventError = true;
          solventsIdx.push(solventIdx);
        }
      });
      if (isSolventError) err.solvents.push({ index, solventsIdx });
    });

    if (isVolumeError) openNotification('', 'Actual Volume should be more than Calculated Volume');
    if (isMassError) openNotification('', 'Actual Mass should be more than Calculated Mass');
    if (isFractionError) openNotification('', 'The sum of solvent Fractions should be equal to 1.0');
    if (isSolventError) openNotification('', 'Solvent is a required field');

    setErrors(prevState => ({ ...prevState, ...err }));
    return !(isVolumeError || isMassError || isFractionError || isSolventError);
  };

  const navigateToFirstBatch = () => {
    setBatchLoading(true);
    dispatch(getDevices(true)).then(d => {
      const device = d.find(i => i.type === DEVICES.SYNJETPRO).uuid;
      dispatch(manageBatch({ data: { device }, type: 'getBatchForAssign' }))
        .then(resp => {
          history.push(`/execution?key=${resp[0].batchId}&tab=scheduled&isBatch=true&deviceId=${deviceId}`);
        })
        .finally(() => {
          setBatchLoading(false);
        });
    });
  };

  const parseToNumber = data =>
    data.map(i => ({
      ...i,
      actual_mass: Number(i.actual_mass),
      actual_volume: Number(i.actual_volume),
      solvents: i.solvents.map(j => ({ ...j, fraction: +j.fraction })),
    }));

  const updateFractionToNumber = async () =>
    setTableData(prevState => ({
      ...prevState,
      solution_structure: prevState.solution_structure.map(i => ({
        ...i,
        solvents: i.solvents.map(j => ({ ...j, fraction: +j.fraction })),
      })),
    }));

  const notReceivedAnalyticsNames =
    notReceivedAnalytics.length > 0 ? notReceivedAnalytics.map(i => i.name).join(', ') : '';
  const notReceivedAnalyticsOneExperiment = notReceivedAnalytics.length === 1;

  const handleUpdateExperimentConditions = (updatedExperiments, updatedHeatingBlocks) => {
    setDispenserErrors([]);
    dispatch(
      updateSJPExperimentConditions({
        batchID,
        experiments: updatedExperiments.length ? updatedExperiments : null,
        heatingBlocks: updatedHeatingBlocks.length ? heatingBlocks : null,
      })
    )
      .then(resp => {
        if (resp?.warnings) {
          resp.warnings.forEach((warn, warnIdx) => {
            warn.messages.forEach((msg, msgIdx) => {
              openNotification('', '', null, `warning-notification-${warnIdx}${msgIdx}`, null, msg);
            });
          });
        }
        getDetails();
        setActiveStep(2);
      })
      .catch(errors => {
        if (typeof errors === 'string') {
          openNotification(null, errors);
        } else {
          const msgs = [];
          parseJSONErrors(JSON.stringify(errors)).forEach(msg => {
            if (msg.match(/\[\S+\]/gm)?.length) msgs.push(msg);
            else openNotification(null, msg);
          });
          setDispenserErrors(msgs);
        }
      })
      .finally(() => {
        // setShowWarning(null);
      });
  };

  const sendPrepareMaterials = (batch, data) => dispatch(prepareMaterialsSynJetPro({ batch, solutionStructure: data }));

  const sendDispensingOptions = () => {
    const updatedConditions = experimentsInfo.filter(
      (experiment, experimentIndex) => !isEqual(experiment, initialExperimentsInfo?.[experimentIndex])
    );
    const updatedHeatingBlock = heatingBlocks.filter(
      (block, blockIndex) => !isEqual(block, initialHeatingBlocks?.[blockIndex])
    );
    const updatedExperiments = updatedConditions.map(condition => ({
      experiment: condition.experimentID,
      reactionsInfo: getSynjetProcessData({
        ...condition,
        processData: { process_steps: condition?.processSteps },
      }),
    }));

    handleUpdateExperimentConditions(updatedExperiments, updatedHeatingBlock);
  };

  const check4stepValidation = () => {
    let error = false;
    let isMinError = false;
    let isMaxError = false;

    if (finishPlates.tray1.length < 3 || finishPlates.tray2.length < 3) {
      isMinError = true;
      error = true;
      setFinishPlatesErrors({ tray1: finishPlates.tray1.length < 3, tray2: finishPlates.tray2.length < 3 });
    } else if (finishPlates.tray1.length > 25 || finishPlates.tray2.length > 25) {
      isMaxError = true;
      error = true;
      setFinishPlatesErrors({ tray1: finishPlates.tray1.length > 25, tray2: finishPlates.tray2.length > 25 });
    }

    if (
      lmTableData.some(i => !!i?.has_sent === false) ||
      tableData.solid_structure.some(i => !!i?.has_sent === false) ||
      tableData.manual_structure.some(i => !!i?.has_sent === false)
    ) {
      error = true;
      openNotification('', 'All toggles should be switched on');
    }

    if (isMinError) {
      openNotification('', 'Min allowed number of characters: 3');
    }
    if (isMaxError) {
      openNotification('', 'Max allowed number of characters: 25');
    }

    return error;
  };

  const onContinue = () => {
    if (activeStep === 1) {
      if (checkTableValidation(tableData.solution_structure)) {
        updateFractionToNumber()
          .then(() => sendPrepareMaterials(batch.uuid, parseToNumber(tableData.solution_structure)))
          .then(() => getDetails());
      }
    } else if (activeStep === 2) {
      if (validateDispenserCount(dispensingOptions) && !conditionsErrors) {
        // call to be
        sendDispensingOptions();
      } else if (conditionsErrors) {
        openNotification('', conditionsErrors);
      } else {
        setDispensingOptions(setDispenserCountError(dispensingOptions));
        openNotification('', DISPENSER_COUNT_ERROR_MESSAGE);
      }
    } else if (activeStep === 3) {
      if (!checkboxesOnBatchConfigStep.allChecked) {
        setconfirmOnBatchConfigStep(true);
      } else goFrom3rdTo4thStep();
    } else if (activeStep === 4) {
      if (!check4stepValidation()) {
        const data = {
          uuid: batch.uuid,
          finishPlates: [
            { plateLabel: 'TRAY_1', plateValue: finishPlates.tray1 },
            { plateLabel: 'TRAY_2', plateValue: finishPlates.tray2 },
          ],
        };
        sjpLaunchBatch({ data }).then(resp => {
          if (!resp.errors) {
            getDetails();
          } else {
            openNotification('', resp.errors[0].messages || resp.errors[0].message);
          }
        });
      }
    }
  };

  const goFrom3rdTo4thStep = () => {
    setBatchLoading(true);
    setconfirmOnBatchConfigStep(false);
    dispatch(saveVialsSchedule(batchID))
      .then(resp => {
        if (resp.warnings) {
          resp.warnings.forEach(err => {
            err.messages.forEach(msg => {
              openNotification(null, null, null, null, null, msg);
            });
          });
        }
        if (errorNotification) {
          openNotification(null, errorNotification);
        } else {
          setActiveStep(4);
        }
        getDetails();
      })
      .finally(() => {
        setBatchLoading(false);
      });
  };

  const pauseResumeBatchAction = () => {
    setBatchLoading(true);
    const func = batch.status === EXPERIMENT_STATUSES.InProgress.value ? pauseBatch : resumeBatch;
    dispatch(func(batchID)).then(resp => {
      if (resp.errors) handleError(resp.errors);
      else getDetails();
      setOpenPauseConfirmation(false);
    });
  };

  const endBatchAction = () => {
    setBatchLoading(true);
    dispatch(setBatchErrorShown(true));
    dispatch(endBatch(batchID)).then(resp => {
      if (resp.errors) {
        handleError(resp.errors);
        dispatch(setBatchErrorShown(false));
      } else getDetails();
      setOpenEndConfirmation(false);
    });
  };

  const handleRecalculateDispensers = e => {
    e.stopPropagation();
    dispatch(recalculateDispensers({ batchId: batch.uuid })).then(resp => {
      if (resp.errors) {
        handleError(resp.errors);
      } else getDetails();
    });
  };

  const finalizeBatchAction = () => {
    setBatchLoading(true);
    dispatch(finalizeBatch(batchID)).then(resp => {
      if (resp.errors) handleError(resp.errors);
      else getDetails();
      setOpenFinalizeConfirmation(false);
    });
  };

  const finalizeExperimentAction = experiment => {
    setBatchLoading(true);
    dispatch(finalizeExperimentOnBatch(experiment?.uuid)).then(resp => {
      if (resp.errors) handleError(resp.errors);
      else getDetails();
      setOpenFinalizeConfirmation(false);
    });
  };

  const completeBatchAction = (goToAppendResults = false) => {
    setBatchLoading(true);
    dispatch(completeBatch(batchID)).then(resp => {
      setOpenCompleteConfirmation(false);
      if (resp.errors) handleError(resp.errors);
      else if (goToAppendResults) {
        history.push(
          `/execution?key=${batch.experiments[0]?.key}&tab=history&isBatch=false&deviceId=${batch?.timeSlot?.device?.uuid}`
        );
      } else history.push('/execution');
    });
  };

  const finalizeBatchConfirmation = (experiment = null) => {
    // check if some analytics does not receive yet
    const experimentsInProgress = batch.experiments.filter(exp => exp.status === EXPERIMENT_STATUSES.InProgress.value);
    setFinalizeExperimentAction(experiment);
    if (!!experimentsInProgress.length || experiment?.name) {
      setNotReceivedAnalytics(experiment?.name ? [experiment] : experimentsInProgress);
      setOpenFinalizeConfirmation(true);
    } else {
      setNotReceivedAnalytics([]);
      if (experiment?.name) finalizeExperimentAction(experiment);
      else finalizeBatchAction();
    }
  };

  const pauseOrResume = batch?.status === EXPERIMENT_STATUSES.InProgress.value ? 'Pause' : 'Resume';

  const setBatchStatus = status => {
    setBatch({
      ...batch,
      status,
    });
  };

  const goToFinalization = () => {
    setActiveStep(6);
    getDetails();
  };

  const getCompound = compoundId => {
    let compound = null;
    const func = (j, id) => {
      if (j.reactionsInfo) {
        const compoundData = JSON.parse(j.reactionsInfo).compounds.find(k => k.compound_id === id);
        if (compoundData) {
          compound = compoundData;
          return true;
        }
      }

      return false;
    };

    experiments.find(a => func(a, compoundId));
    return compound;
  };

  const downloadDatFile = () => {
    const auth = localStorage.getItem('token');
    fetch(`/api/execution/dat_files_archive/?key=${batchKey}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${auth}`,
      },
    })
      .then(res => res.blob())
      .then(res => saveAs(res, `${batchKey}.zip`));
  };

  return (
    <Spinner loading={batchLoading}>
      <div className="reset-padding-container">
        <Steps active={activeStep} isSynJetPro />
        <div className="sj-execution-header">
          <div className="name">
            Batch
            {batch?.batchId}
          </div>
          <StatusLabel type="active">{batch?.status}</StatusLabel>
        </div>
        <div className="sj-execution-body">
          {activeStep === 1 && (
            <SynJetProPrepareMaterials
              batch={batch}
              errors={errors}
              setErrors={setErrors}
              setEdited={setEdited}
              experiments={experiments}
              setExperiments={setExperiments}
              tableData={tableData}
              setTableData={setTableData}
            />
          )}
          {activeStep === 2 && (
            <SJReviseTheDetails
              batch={batch}
              getDetails={getDetails}
              experimentsInfo={experimentsInfo}
              dispensingOptions={dispensingOptions}
              setExperimentsInfo={setExperimentsInfo}
              errors={dispenserErrors}
              isPro
              handleRecalculateDispensers={handleRecalculateDispensers}
              heatingBlocks={heatingBlocks}
              setHeatingBlocks={setHeatingBlocks}
              setConditionsErrors={setConditionsErrors}
              initialExperimentInfo={initialExperimentsInfo}
            />
          )}
          {activeStep === 3 && (
            <SJBatchConfiguration
              batch={batch}
              isPro
              checkboxesOnBatchConfigStep={checkboxesOnBatchConfigStep}
              onContinue={goFrom3rdTo4thStep}
              confirmOnBatchConfigStep={confirmOnBatchConfigStep}
              setconfirmOnBatchConfigStep={setconfirmOnBatchConfigStep}
              setcheckboxesOnBatchConfigStep={setcheckboxesOnBatchConfigStep}
              setErrorNotification={setErrorNotification}
            />
          )}
          {activeStep === 4 && (
            <SynJetProLoadMaterials
              batch={batch}
              errors={lmErrors}
              setErrors={setLmErrors}
              setEdited={setEdited}
              loadVialsTableData={tableData}
              setLoadVialsTableData={setTableData}
              lmTableData={lmTableData}
              setLmTableData={setLmTableData}
              experiments={experiments}
              listCheckboxes={listCheckboxes}
              setListCheckboxes={setListCheckboxes}
              finishPlates={finishPlates}
              setFinishPlates={setFinishPlates}
              finishPlatesErrors={finishPlatesErrors}
              setFinishPlatesErrors={setFinishPlatesErrors}
              getCompound={getCompound}
            />
          )}
          {activeStep === 5 && (
            <SynJetProExperimentProgress
              batch={batch}
              setBatchStatus={setBatchStatus}
              finalizeAndComplete={finalizeBatchConfirmation}
              goToFinalization={goToFinalization}
              manageExperimentRoutes={manageExperimentRoutes}
              connectivitySubject={connectivitySubject}
              setConnectivitySubject={setConnectivitySubject}
              getDetails={getDetails}
              setDisableButtons={setDisableButtons}
              dispensingOptions={dispensingOptions}
              errors={dispenserErrors}
              showDispensing
              plateVialsData={plateVialsData}
              getProduct={getCompound}
              disableButtons={disableButtons}
            />
          )}
          {activeStep === 6 && (
            <SjpBatchFinalization
              batch={batch}
              plateVialsData={plateVialsData}
              getProduct={getCompound}
              finalizationOptions={finalizationOptions}
              setFinalizationOptions={setFinalizationOptions}
              disableButtons={disableButtons}
              setDisableButtons={setDisableButtons}
              connectivitySubject={connectivitySubject}
              setConnectivitySubject={setConnectivitySubject}
            />
          )}
        </div>
      </div>
      <Footer
        isSynjetPro
        experiment={batch}
        tableData={tableData}
        dropdownMenu={dropdownMenu}
        activeStep={activeStep}
        handleBack={() => setActiveStep(step => step - 1)}
        permissions={currentUser?.permissions}
        canSeeNotes={!!currentUser.permissions?.execution.view_note}
        showNotes={() => {
          setShowNotesSidebar(true);
        }}
        remoteConnectivity
        showPause={batch?.status === EXPERIMENT_STATUSES.InProgress.value}
        showResume={batch?.status === EXPERIMENT_STATUSES.Paused.value}
        showEndExperiment={batch?.status === EXPERIMENT_STATUSES.Paused.value}
        showFinalize={batch?.status === EXPERIMENT_STATUSES.PendingFinalization.value}
        disableCompleteExperiment={finalizationOptions.find(i => !i.checked)}
        notesLength={experiments.reduce((sum, exp) => sum + exp.notes.length, 0)}
        handleContinue={onContinue}
        disableContinue={disableContinue}
        pauseExperiment={() => setOpenPauseConfirmation(true)}
        resumeExperiment={() => setOpenPauseConfirmation(true)}
        completeExperiment={() => {
          setOpenCompleteConfirmation(true);
        }}
        endExperiment={() => setOpenEndConfirmation(true)}
        finalizeExperiment={finalizeBatchConfirmation}
        disableButtons={disableButtons}
        isPermittedDownloadDatFile={isPermittedDownloadDatFile}
        downloadDatFile={downloadDatFile}
      />
      {showNotesSidebar && (
        <BatchNotesSidebar
          experiments={experiments}
          updateNotesList={() => {
            dispatch(manageBatch({ data: { key: batchKey }, type: 'getBatchDetails' })).then(response => {
              setExperiments(response.experiments);
            });
          }}
          open={showNotesSidebar}
          onClose={() => setShowNotesSidebar(false)}
        />
      )}
      <Popup
        open={rejectWarningOpen}
        title="Warning"
        textSubmit="Proceed"
        handleSubmit={handleRejectConfirm}
        handleCancel={() => setRejectWarningOpen(false)}
      >
        Reject will reset the preparation progress for the Batch, proceed?
      </Popup>
      <Popup
        title="Experiment results"
        open={openCompleteConfirmation}
        textSubmit="Yes"
        textCancle="No"
        handleCancel={() => completeBatchAction()}
        handleSubmit={() => completeBatchAction(true)}
        loading={batchLoading}
      >
        Do you want to navigate to experiment results screen to append the results?
      </Popup>
      <Popup
        title=" "
        open={openPauseConfirmation}
        textSubmit={pauseOrResume}
        handleCancel={() => setOpenPauseConfirmation(false)}
        handleSubmit={pauseResumeBatchAction}
        loading={batchLoading}
      >
        {`Do you really want to ${pauseOrResume} the batch execution?`}
      </Popup>
      <Popup
        title=" "
        open={openEndConfirmation}
        textSubmit="End batch"
        handleCancel={() => setOpenEndConfirmation(false)}
        handleSubmit={endBatchAction}
        loading={batchLoading}
      >
        Do you really want to End the Batch execution?
      </Popup>
      <Popup
        title="Finalize"
        open={openFinalizeConfirmation}
        textSubmit="Finalize"
        handleCancel={() => setOpenFinalizeConfirmation(false)}
        handleSubmit={() => {
          if (finalizeExperimentActionOnClick) finalizeExperimentAction(finalizeExperimentActionOnClick);
          else finalizeBatchAction();
        }}
        loading={batchLoading}
      >
        Do you really want to finalize the batch?
      </Popup>
      <RejectExperimentFromBatchPopup
        closePopup={() => setRejectBatchOpen(false)}
        open={rejectBatchOpen}
        type={type}
        batch={batch}
        experiment={rejectItem}
        navigateToFirstBatch={navigateToFirstBatch}
        getBatchDetails={getDetails}
      />
    </Spinner>
  );
};
