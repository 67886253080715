import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProcessBuilderSynJet } from './ProcessBuilderSynJet/ProcessBuilderSynJet';
import { ExperimentDetailsSynJet } from './ExperimentDetailsSynJet/ExperimentDetailsSynJet';

export const SynJet = props => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/process-builder/details/:id`} component={ProcessBuilderSynJet} />
      <Route path={`${path}/details/:key`} component={ExperimentDetailsSynJet} />
    </Switch>
  );
};
