import './styles.scss';

import React from 'react';

import { PopoverContent } from '@templates/CustomizationTable/components/PopoverContent';
import moment from 'moment';

import { OptionWithColor } from '@atoms';
import { FIELD_DATA_TYPES, KEY_COLUMN_PREFIXES } from '@organisms';

import { Avatar, Popover, Tooltip } from '../../../components';
import CompoundSmilesPreview from '../../../components/CompoundSmilesPreview/CompoundSmilesPreview';
import { dateMMDDYY, dateMMDDYYmmHH } from '../../../constants';
import { dateFromBEFormat } from '../../../utils/date';

export const mapGridColumnsToList = columns =>
  columns
    .map(el => ({
      ...el,
      label: el.name,
      value: el.fieldName,
      selected: !el.hidden,
    }))
    .filter(el => !el.archived);

export const getExperimentFieldValue = ({ storage, dataType, fieldName }) => {
  if (dataType === FIELD_DATA_TYPES.user || dataType === FIELD_DATA_TYPES.experiment) {
    if (!storage[`${fieldName}${KEY_COLUMN_PREFIXES.uuid}`]) {
      return null;
    }
    return {
      value: storage[`${fieldName}${KEY_COLUMN_PREFIXES.uuid}`],
      label: storage[`${fieldName}${KEY_COLUMN_PREFIXES.label}`],
    };
  }
  if (dataType === FIELD_DATA_TYPES.date) {
    const value = storage[`${fieldName}${KEY_COLUMN_PREFIXES.label}`];
    return value ? dateFromBEFormat(value) : value;
  }
  if (dataType === FIELD_DATA_TYPES.datetime) {
    const value = storage[fieldName] || storage[`${fieldName}${KEY_COLUMN_PREFIXES.label}`];
    return value ? moment(value) : value;
  }
  if (dataType === FIELD_DATA_TYPES.multiselect) {
    const value = storage[fieldName];
    return value ? value.map(i => JSON.stringify(i)) : value;
  }
  return storage[fieldName];
};

export const getDefaultValue = (type, value) => {
  if (
    type !== FIELD_DATA_TYPES.boolean &&
    (value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0) ||
      (value === 'string' && JSON.parse(value) === ''))
  ) {
    return '-';
  }

  const withPopover = options => {
    if (options.length === 1) {
      return (
        <Tooltip destroyTooltipOnHide placement="top" title={options[0].label}>
          <div
            style={{
              width: '100%',
            }}
          >
            <OptionWithColor color={options[0].color} label={options[0].label} />
          </div>
        </Tooltip>
      );
    }
    return (
      <Popover trigger="hover" content={<PopoverContent options={options} />}>
        <a className="options-amount">
          {options.length} {`option${options.length > 1 ? 's' : ''}`}
        </a>
      </Popover>
    );
  };

  switch (type) {
    case FIELD_DATA_TYPES.select:
      const selectValue = typeof value === 'string' ? JSON.parse(value) : value;
      return withPopover([selectValue]);
    case FIELD_DATA_TYPES.multiselect:
      let multiSelectValue = typeof value === 'string' ? JSON.parse(value) : value;
      multiSelectValue = multiSelectValue.map(i => (typeof i === 'string' ? JSON.parse(i) : i));
      return withPopover(multiSelectValue);
    case FIELD_DATA_TYPES.user:
      if (value?.label) {
        return (
          <Tooltip destroyTooltipOnHide placement="top" title={value.label}>
            <div className="user-value-block">
              <Avatar classNameAvatarName="user-avatar-name" size={24} name={value.label} />
            </div>
          </Tooltip>
        );
      }
      return '-';
    case FIELD_DATA_TYPES.experiment:
      return value?.label || '-';
    case FIELD_DATA_TYPES.boolean:
      if (value === null) {
        return '-';
      }
      return value ? 'True' : 'False';
    case FIELD_DATA_TYPES.short_text:
    case FIELD_DATA_TYPES.long_text:
    case FIELD_DATA_TYPES.numeric:
    case FIELD_DATA_TYPES.decimal:
      return value;
    case FIELD_DATA_TYPES.datetime:
      return moment(value).format(dateMMDDYYmmHH);
    case FIELD_DATA_TYPES.date:
      return moment(value).format(dateMMDDYY);
    case FIELD_DATA_TYPES.smile:
      return (
        <Tooltip
          overlayClassName="synjet-experiment-table_header_tooltip"
          title={
            <CompoundSmilesPreview
              compound={value}
              options={{ width: 130, height: 80 }}
              hideMW
              showDivider
              showEmptyCompound
            />
          }
        >
          <div
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {value.compound_name || value.smiles}
          </div>
        </Tooltip>
      );
    default:
      return '-';
  }
};

export const mapExperimentTableFieldsGridToColumns = data =>
  data?.map((column, idx) => ({
    ...column,
    title: column.name,
    dataIndex: column.fieldName,
    sortValue: [FIELD_DATA_TYPES.smile, FIELD_DATA_TYPES.multiselect].includes(column.dataType)
      ? null
      : column.fieldName,
    actualIndex: idx,
    render: ({ record }) => getDefaultValue(column.dataType, record),
  })) || [];
