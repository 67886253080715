import { http } from '../../utils';
import * as actionsTypes from './experimentField.actionTypes';
import { queryExperimentField, mutationExtensibleTableField, mutationArchiveExtensibleTableField } from '../graphql';

export const setExperimentFieldData = data => ({
  type: actionsTypes.EXPERIMENT_FIELD_DATA,
  data,
});

export const setUpdateExperimentField = data => ({ type: actionsTypes.UPDATE_EXPERIMENT_FIELD_DATA, data });

export const getExperimentFieldData = data => async () =>
  http(queryExperimentField('send', data)).then(resp => {
    const response = queryExperimentField('get', resp);
    return response;
  });

export const createExtensibleTableField = data => async () =>
  http(mutationExtensibleTableField(data, 'create', 'send')).then(resp => {
    const response = mutationExtensibleTableField(resp, 'create', 'get');
    return response;
  });

export const updateExtensibleTableField = data => async () =>
  http(mutationExtensibleTableField(data, 'update', 'send')).then(resp => {
    const response = mutationExtensibleTableField(resp, 'update', 'get');
    return response;
  });

export const updateTableFieldStatus = data => async () =>
  http(mutationArchiveExtensibleTableField(data, 'send')).then(resp => {
    const response = mutationArchiveExtensibleTableField(resp, 'get');
    return response;
  });
