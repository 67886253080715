import React, { useMemo } from 'react';
import cn from 'classnames';
import { SmilesElem } from '../../../../PrepareMaterials/SmilesElem';
import { Switch } from '../../../../../Common/Switch';
import { setFixed } from '../index';

const useColumns = ({ handleChangeSwitch, ...props }) => {
  const memoizedColumns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: <div className={row.className}>&nbsp;</div>,
          };
          if (!row.name.value) {
            const data = row.solvent?.value[0]?.compound;
            obj.children = (
              <div className="syn-jet-materials_lm-table_solvent-norm">
                <span>{data.compound_name || data.formula}</span>
                <span>Solvent</span>
              </div>
            );
          } else if (row.name.value) {
            obj.children = (
              <SmilesElem
                id={`lm-table${index}`}
                data={row.name.value}
                displayLoupe
                customOptions={{ height: 80, width: 120 }}
              />
            );
          }
          return obj;
        },
      },
      {
        title: 'Solvent',
        dataIndex: 'solvent',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: <div className={row.className}>&nbsp;</div>,
          };
          if (row.solvent.value && row.name.value) {
            obj.children = (
              <div className={cn('syn-jet-materials_lm-table_solvent', row.className)}>
                {row.solvent.value.map((i, idx, arr) => {
                  const useComma = arr.length - 1 !== idx;
                  return (
                    <>
                      <span>{i.compound?.compound_name || i.compound?.formula}</span>
                      {i.fraction !== 1 && (
                        <span className="syn-jet-materials_lm-table_solvent_extra">({i.fraction})</span>
                      )}
                      {useComma ? ', ' : ''}
                    </>
                  );
                })}
              </div>
            );
          }
          return obj;
        },
      },
      {
        title: 'MW (g/mol)',
        dataIndex: 'mw',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: <div className={cn('syn-jet-materials_lm-table_mw', row.className)}>{value?.value}</div>,
          };

          if (!row.name.value) {
            const data = row.solvent?.value[0]?.compound;
            obj.children = <div className={cn('syn-jet-materials_lm-table_mw', row.className)}>{data?.molwt}</div>;
          }

          return obj;
        },
      },
      {
        title: 'Concentration (M)',
        dataIndex: 'concentration',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: (
              <div className={cn('syn-jet-materials_lm-table_concentration', row.className)}>{value.value}</div>
            ),
          };
          if (!row.name.value) {
            obj.children = <div className={cn('syn-jet-materials_lm-table_concentration', row.className)}>&nbsp;</div>;
          }
          return obj;
        },
      },
      {
        title: 'Dispenser set',
        dataIndex: 'dispenser',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: (
              <div className={cn('syn-jet-materials_lm-table_dispenser', row.className)}>
                <div className="">
                  <span className="syn-jet-materials_lm-table_dispenser_title">Backpack</span>
                  <span>{value.backpack}</span>
                </div>
                <div className="">
                  <span className="syn-jet-materials_lm-table_dispenser_title">Fluid body</span>
                  <span>{value.fluid_body}</span>
                </div>
                <div className="">
                  <span className="syn-jet-materials_lm-table_dispenser_title">Calibration</span>
                  <span>{value.calibration} µL/ms</span>
                </div>
              </div>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Calculated',
        children: [
          {
            title: 'Volume (mL)',
            dataIndex: 'calc_volume',
            width: '0',
            render: (value, row, index, ...other) => {
              const obj = {
                children: <div className={row.className}>{Math.ceil(value.value * 100) / 100}</div>,
              };
              return obj;
            },
          },
          {
            title: 'Mass (mg)',
            dataIndex: 'calc_mass',
            width: '0',
            render: (value, row, index, ...other) => {
              const obj = {
                children: <div className={row.className}>{setFixed(value.value, 3)}</div>,
              };
              return obj;
            },
          },
        ],
      },
      {
        title: 'Actual',
        children: [
          {
            title: 'Volume (mL)',
            dataIndex: 'actual_volume',
            width: '0',
            render: (value, row, index, ...other) => {
              const obj = {
                children: <div className={row.className}>{Math.ceil(value.value * 100) / 100}</div>,
              };
              return obj;
            },
          },
          {
            title: 'Mass (mg)',
            dataIndex: 'actual_mass',
            width: '0',
            render: (value, row, index, ...other) => {
              const obj = {
                children: <div className={row.className}>{value.value ? Math.ceil(value.value * 100) / 100 : ''}</div>,
              };
              return obj;
            },
          },
        ],
      },
      {
        title: 'Dispenser #',
        dataIndex: 'dispenser_number',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: <div className={row.className}>{value}</div>,
          };
          return obj;
        },
      },
      {
        title: 'Done',
        dataIndex: 'has_sent',
        width: '0',
        fixed: 'right',
        render: (val, row, index, ...other) => {
          const obj = {
            children: (
              <div className={row.className}>
                <Switch checked={row.has_sent?.value} onChange={value => handleChangeSwitch(value, index, row)} />
              </div>
            ),
          };
          return obj;
        },
      },
    ],
    [props.tableData]
  );

  return memoizedColumns;
};

export default useColumns;
