const setRowTable = (dispenser, getCompound) => {
  const compound = getCompound(dispenser.compound_id);

  return {
    dispenserUuid: dispenser.uuid,
    name: {
      value: compound,
    },
    vials: dispenser.vials,
    compound_name: {
      name: compound?.compound_name || compound?.smiles,
      formula: compound?.formula,
    },
    vial_number: { value: dispenser.vials.map(i => i.name) },
    location: { value: dispenser.vials.map(i => i.location) },
    calc_amt: { value: dispenser.vials.map(i => i.calculated_mass || '') },
    actual_amt: { value: dispenser.vials.map(i => i.actual_mass || '') },
    error_percentage: { value: dispenser.vials.map(i => i.error || 'N/A') },
    has_sent: { value: dispenser.has_sent },
  };
};

export default setRowTable;
