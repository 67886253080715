import createReducer from '../createReducer.js';
import * as actionTypes from './scheduling.actionTypes';

const common = {
  events: [],
  errors: [],
  warnings: [],
  potentialBatchForExperiment: null,
};

function setListEvents(state, { data }) {
  return { ...state, events: data };
}

function setPotentialBatchForExperiment(state, { data }) {
  return { ...state, potentialBatchForExperiment: data };
}

function setValidationErrors(state, { data }) {
  return { ...state, errors: data };
}

function setValidationWarnings(state, { data }) {
  return { ...state, warnings: data };
}

const schedulingReducer = createReducer(common, {
  [actionTypes.SET_LIST_EVENTS]: setListEvents,
  [actionTypes.SET_VALIDATION_ERRORS_ADD_EXPERIMENT_TO_BATCH]: setValidationErrors,
  [actionTypes.SET_VALIDATION_WARNINGS_ADD_EXPERIMENT_TO_BATCH]: setValidationWarnings,
  [actionTypes.SET_POTENTIAL_BATCH_FOR_EXPERIMETN]: setPotentialBatchForExperiment,
});

export default schedulingReducer;
