import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import './style.scss';
export const Tooltip = ({ children, title, placement = 'topLeft', ...rest }) => (
  <AntdTooltip
    placement={placement}
    overlayClassName="common-tooltip"
    title={title}
    arrowPointAtCenter={true}
    {...rest}
  >
    {children}
  </AntdTooltip>
);
