import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import { Button } from 'components/Common';
import { RightOutlined } from '@ant-design/icons';
import { ConditionsAndDispensingValuesBlock } from '../SynJet/SynJetProccess/ConditionsAndDispensingOptions/ConditionsAndDispensingValuesBlock';
import { HardwareSummary } from '../SynJet/SynJetProccess/HardwareSummary/HardwareSummary';
import {
  DEVICES,
  DISPLAY_OPTIONS,
  SYNJET_PROCESS_TYPES,
  SYNJET_PRO_PROCESS_TYPES,
  TEMPERATURE_LIMITS,
} from '../../constants';
import { SynJetExperimentTable } from '../SynJet/SynJetProccess/SynJetExperimentTable/SynJetExperimentTable';
import { ButtonGroup } from '../Common/ButtonGroup';
import { parseProcessDataFromBackend } from '../../utils/synjetHelpers';
import { formatToHHMM } from '../../utils';
import { synjectProcessSteps } from '../../store/synjet/synjet.selector';
import { PageContainer } from '../PdfGenerator/PageContainer';

const buttons = [
  { label: 'Equivalent', value: DISPLAY_OPTIONS.equivalent.label },
  { label: 'Volume', value: DISPLAY_OPTIONS.volume.label },
];

export const InfoSynJet = ({ dataExperiment, dataSynJet, pdfMode }) => {
  const [selectedDisplayOption, setSelectedDisplayOption] = useState(DISPLAY_OPTIONS.equivalent.label);
  const [dataForTable, setDataForTable] = useState('');

  const processSteps = useSelector(synjectProcessSteps);

  useEffect(() => {
    if (dataExperiment) {
      setDataForTable(
        parseProcessDataFromBackend(JSON.parse(dataExperiment.reactionsInfo), dataExperiment?.process?.variableStep)
      );
    }
  }, [dataExperiment]);

  const TableExperiment = ({ defaultTab }) => (
    <>
      <div data-protected className="experiment-table">
        <div className="row-title">
          <div className="conditions-and-dispensing-options-block_title">Experiment table</div>
          {!pdfMode && (
            <ButtonGroup
              className="process-builder-container-display-option-button-group"
              value={selectedDisplayOption}
              onChange={value => {
                setSelectedDisplayOption(value);
              }}
              options={buttons}
            />
          )}
        </div>
      </div>
      {!!dataForTable && (
        <div data-protected className="buttons-table">
          <SynJetExperimentTable
            experiments={{ process_steps: dataForTable.processSteps }}
            displayOption={defaultTab || selectedDisplayOption}
            processType={dataExperiment?.process?.type}
            processSteps={dataForTable.steps}
            showCopiedVials
            enableVialCopying={dataExperiment?.enableVialCopying}
          />
        </div>
      )}
    </>
  );

  const Child = () => (
    <div className="container-info-experiment_info synjet-details">
      {dataSynJet?.steps?.map((i, index) => (
        <>
          {dataSynJet?.steps?.length > 1 && index === 0 && (
            <div data-pdf-node className="container-info-experiment_info_synjet-block_step1_title">
              Step {index + 1} - {i.isFixed ? 'Fixed' : 'Variable'}
            </div>
          )}
          <div className="container-info-experiment_info_synjet">
            {index === 0 &&
            dataSynJet?.steps?.length > 1 &&
            (dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.SCREENING ||
              dataExperiment?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION) ? (
              i?.isFixed ? (
                <div className="block-tables-title">
                  <div className="conditions-and-dispensing-options-block_title">Temperature & Time</div>
                  <ConditionsAndDispensingValuesBlock type="Optimization" isFixed>
                    <div className="children_row">
                      <div className="children_row_title">FIXED</div>
                      <>
                        <div className="view-data">{i?.conditions[0].temperature.fixed}</div>
                        <div className="view-data">{`${formatToHHMM(i.conditions[0].time.fixed)}`}</div>
                      </>
                    </div>
                  </ConditionsAndDispensingValuesBlock>
                </div>
              ) : (
                <div className="condition-block">
                  <div className="conditions-and-dispensing-options-block_title">Conditions</div>
                  <MultipleConditionsScreening
                    processType={dataExperiment?.process?.type}
                    isFixed={false}
                    conditions={i.conditions}
                  />
                </div>
              )
            ) : (
              <></>
            )}
            {index === 0 && dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION && !i.isFixed && (
              <TemperatureAndTime i={i} />
            )}
            <div className="block-tables-title">
              {dataSynJet?.steps?.length > 1 && index === 1 && (
                <div data-pdf-node className="container-info-experiment_info_synjet-block_step1_title">
                  Step {index + 1} - {i.isFixed ? 'Fixed' : 'Variable'}
                </div>
              )}
              {index === 1 && (
                <div className="container-info-experiment_info_synjet">
                  {dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION ? (
                    <div>
                      {dataSynJet?.steps?.length === 1 || (dataSynJet?.steps?.length > 1 && index === 1) ? (
                        <TemperatureAndTime i={i} />
                      ) : (
                        <>
                          <div className="conditions-and-dispensing-options-block_title">Temperature & Time</div>
                          <ConditionsAndDispensingValuesBlock type="Optimization" isFixed>
                            <div className="children_row">
                              <div className="children_row_title">FIXED</div>
                              <div className="view-data">{i?.conditions[0].temperature.fixed}</div>
                              <div className="view-data">{`${formatToHHMM(i.conditions[0].time.fixed)}`}</div>
                            </div>
                          </ConditionsAndDispensingValuesBlock>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="condition-block">
                      {index === 1 &&
                      (dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.SCREENING ||
                        dataExperiment?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION) ? (
                        i?.isFixed ? (
                          <div className="block-tables-title">
                            <div className="conditions-and-dispensing-options-block_title">Temperature & Time</div>
                            <ConditionsAndDispensingValuesBlock type="Optimization" isFixed>
                              <div className="children_row">
                                <div className="children_row_title">FIXED</div>
                                <>
                                  <div className="view-data">{i?.conditions[0].temperature.fixed}</div>
                                  <div className="view-data">{`${formatToHHMM(i.conditions[0].time.fixed)}`}</div>
                                </>
                              </div>
                            </ConditionsAndDispensingValuesBlock>
                          </div>
                        ) : (
                          <div className="block-tables-title">
                            <div className="condition-block">
                              <div className="conditions-and-dispensing-options-block_title">Conditions</div>
                              <MultipleConditionsScreening
                                processType={dataExperiment?.process?.type}
                                isFixed={i.isFixed}
                                conditions={i.conditions}
                              />
                            </div>
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <DispensingOptionsAndHardwareSummery dataExperiment={dataExperiment} dataSynJet={dataSynJet} i={i} />
                </div>
              )}
              <div className="row-options">
                {(dataSynJet?.steps?.length > 1 && index === 1) ||
                  (dataSynJet?.steps?.length == 1 &&
                    index === 0 &&
                    (dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.SCREENING ||
                      dataExperiment?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION) &&
                    !i?.isFixed && (
                      <div className="condition-block">
                        <div className="conditions-and-dispensing-options-block_title">Conditions</div>
                        <MultipleConditionsScreening
                          processType={dataExperiment?.process?.type}
                          isFixed={false}
                          conditions={i.conditions}
                        />
                      </div>
                    ))}
                {index === 0 &&
                  (dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.SCREENING ||
                    dataExperiment?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION) &&
                  dataSynJet?.steps?.length === 1 && (
                    <DispensingOptionsAndHardwareSummery
                      dataExperiment={dataExperiment}
                      dataSynJet={dataSynJet}
                      i={i}
                    />
                  )}
              </div>
              {index === 0 &&
                dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION &&
                !i.isFixed &&
                dataSynJet?.steps?.length === 1 && (
                  <DispensingOptionsAndHardwareSummery dataExperiment={dataExperiment} dataSynJet={dataSynJet} i={i} />
                )}

              {index === 0 &&
                dataExperiment?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION &&
                dataSynJet?.steps?.length > 1 && (
                  <>
                    <div className="conditions-and-dispensing-options-block_title">Temperature & Time</div>
                    <ConditionsAndDispensingValuesBlock type="Optimization" isFixed>
                      <div className="children_row">
                        <div className="children_row_title">FIXED</div>
                        <>
                          <div className="view-data">{i?.conditions[0].temperature.fixed}</div>
                          <div className="view-data">{`${formatToHHMM(i.conditions[0].time.fixed)}`}</div>
                        </>
                      </div>
                    </ConditionsAndDispensingValuesBlock>
                  </>
                )}
            </div>
          </div>
        </>
      ))}
      {!pdfMode && <TableExperiment />}
      {dataExperiment?.process?.device === DEVICES.SYNJETPRO && (
        <PurificationDetails purificationData={dataExperiment?.purification} />
      )}
    </div>
  );
  return pdfMode ? (
    <>
      <PageContainer noTable>
        <Child />
      </PageContainer>
      <PageContainer>
        <TableExperiment defaultTab={DISPLAY_OPTIONS.equivalent.label} />
      </PageContainer>
      <PageContainer>
        <TableExperiment defaultTab={DISPLAY_OPTIONS.volume.label} />
      </PageContainer>
    </>
  ) : (
    <Child />
  );
};

const MultipleConditionsScreening = ({ conditions, processType, isFixed }) => (
  <ConditionsAndDispensingValuesBlock type={processType} isFixed={isFixed}>
    {conditions.map((condition, index) => (
      <div className="children_row">
        <div className="children_row_title">{isFixed ? 'FIXED' : `COND${index + 1}`}</div>
        <>
          <div className="view-data">{condition.temperature.fixed}</div>
          <div className="view-data">{`${formatToHHMM(condition.time.fixed)}`}</div>
        </>
      </div>
    ))}
  </ConditionsAndDispensingValuesBlock>
);

const DispensingOptionsAndHardwareSummery = ({ i, dataSynJet }) => (
  <div data-pdf-node className="hardware-dispensing">
    <div>
      <div className="container-info-experiment_info_synjet-block">
        <div className="container-info-experiment_info_synjet-block_title">Dispensing options</div>
        <div className="container-info-experiment_info_synjet-block_dispensing options">
          <div className="view-data row-data">
            <div className="view-data__title">Total vial volume</div>
            <div>{i.conditions[0].dispensing.volume} µL</div>
          </div>
          <div className="view-data row-data">
            {i.conditions[0].dispensing.normalize && <div className="view-data__title">Normalize with</div>}
            <div>{i.conditions[0].dispensing?.solvent?.compound_name}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="container-info-experiment_info_synjet-block">
        <div className="container-info-experiment_info_synjet-block_title">Hardware summary</div>
        <HardwareSummary
          isPro={dataSynJet?.process?.process?.device === DEVICES.SYNJETPRO}
          experiments={{ process_steps: dataSynJet.processSteps }}
          twoStepsWithFirstFixed={dataSynJet.processSteps[0].step.isFixed && dataSynJet.processSteps.length > 1}
        />
      </div>
    </div>
  </div>
);

const TemperatureAndTime = ({ i }) => (
  <>
    <div data-pdf-node className="container-info-experiment_info_synjet-block">
      <div className="container-info-experiment_info_synjet-block_title">Temperature range</div>
      <ConditionsAndDispensingValuesBlock title="Temperature, °C">
        {['low', 'med', 'high'].map((item, idx) => (
          <div className="view-data">{i.conditions[0].temperature[item]}</div>
        ))}
      </ConditionsAndDispensingValuesBlock>
    </div>
    <div data-pdf-node className="container-info-experiment_info_synjet-block">
      <div data-pdf-node className="container-info-experiment_info_synjet-block_title">
        Time range
      </div>
      <ConditionsAndDispensingValuesBlock title="Time, hh:mm">
        {['low', 'med', 'high'].map(item => (
          <div className="view-data">{`${formatToHHMM(i.conditions[0].time[item])}`}</div>
        ))}
      </ConditionsAndDispensingValuesBlock>
    </div>
  </>
);
export const PurificationDetails = ({ purificationData, isProExecution, resultsInfo }) => {
  const purificationText = decodeURIComponent(purificationData).trim();
  const [longText, setLongText] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const arrow = showMore ? 'arrow-top' : 'arrow-bottom';
  const purificationRef = useRef();
  useLayoutEffect(() => {
    const height = purificationRef.current?.getBoundingClientRect().height;
    height > 100 ? setLongText(true) : setLongText(false);
  }, [purificationData]);
  return (
    <>
      {purificationText.length > 0 && (
        <div
          className={
            isProExecution
              ? 'purification-collapse purification-collapse_execution'
              : resultsInfo
              ? 'purification-collapse purification-collapse_result'
              : 'purification-collapse'
          }
        >
          {!resultsInfo && (
            <div className="conditions-and-dispensing-options-block_title purification-title">Purification details</div>
          )}
          <div className="purification-body">
            <div className="purification-block">
              <div
                className={
                  !longText
                    ? 'purification-text'
                    : showMore
                    ? 'purification-text purification-text_expand'
                    : 'purification-text purification-text_collapse'
                }
                ref={purificationRef}
              >
                <div className="purification-text">{purificationText}</div>
              </div>
              {longText && (
                <Button
                  secondary
                  className="purification-button"
                  onClick={() => {
                    setShowMore(show => !show);
                  }}
                >
                  <RightOutlined className={`ant-collapse-arrow ${arrow}`} />
                  {showMore ? 'Show less' : 'Show more'}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
