import React, { useEffect, useState } from 'react';
import { Button, Input } from 'components/Common';
import { DeleteOutlined } from '@ant-design/icons';
import { formatSeconds } from 'utils';
import { MEASUREMENT_OPTIONS } from 'constants';
import { useSelector } from 'react-redux';
import '../../OptimizationPopover/style.scss';
import { calculateSpecificField, optionCalculation } from '../../Manual/Sidebars/inputCalculations';
import './style.scss';
import { PROJECT_TYPES } from '../../../../constants';

const CAN_CALCULATE = {
  moles: [true],
  mass: [true],
  volume: [true],
  equivalents: [true],
};

export const ManualOptimizationInputPopoverContent = ({
  equivalents,
  onCancel,
  onSave,
  isOpen,
  data,
  limitingInput,
  isLimiting,
}) => {
  const [newEquivalents, setNewEquivalents] = useState([{}]);
  const [errors, setErrors] = useState([]);

  const [canCalculate, setCanCalculate] = useState({ ...CAN_CALCULATE });

  useEffect(() => {
    if (equivalents?.length) setNewEquivalents(equivalents);
  }, [equivalents]);

  useEffect(() => {
    if (!isOpen) {
      setErrors([]);
    }
  }, [isOpen]);

  const onChange = (v, type, index) => {
    let _errors = [...errors];
    setNewEquivalents(
      newEquivalents.map((equivalent, idx) => {
        if (index === idx) {
          equivalent[type] = v;
          if (_errors[index]) _errors[index][type] = false;
        }
        return equivalent;
      })
    );
    setErrors(_errors);
  };

  const onClose = () => {
    onCancel();
    setNewEquivalents(equivalents?.length ? equivalents : [{}]);
  };

  const onAddOption = () => {
    setNewEquivalents([
      ...newEquivalents,
      {
        ...newEquivalents[0],
        moles: null,
        equivalents: null,
        mass: null,
        volume: null,
      },
    ]);
    let length_for_calculate = Array.apply(0, new Array(newEquivalents.length + 1)).map((i, idx) =>
      idx === newEquivalents.length ? true : false
    );
    setCanCalculate({
      moles: [...length_for_calculate],
      mass: [...length_for_calculate],
      volume: [...length_for_calculate],
      equivalents: [...length_for_calculate],
    });
  };

  const onSavePress = () => {
    let hasErrors = validateEquivalents();
    if (hasErrors) return;
    onSave(newEquivalents);
    onClose();
  };

  const validateEquivalents = () => {
    let _errors = [];
    let hasErrors = false;
    newEquivalents.forEach(equivalent => {
      let errorItem = {};
      dataMappingArray
        .filter(i => i.value !== 'equivalents')
        .forEach(dataItem => {
          if (!equivalent[dataItem.value] || equivalent[dataItem.value] < 0) {
            if (equivalent.reagent_type === 'solid' && dataItem.value === 'volume') {
            } else {
              errorItem[dataItem.value] = true;
              hasErrors = true;
            }
          }
        });
      _errors.push(errorItem);
    });
    setErrors(_errors);
    return hasErrors;
  };

  const onDeleteInputEquivalents = index => {
    let newEquivalentsData = [...newEquivalents];
    newEquivalentsData.splice(index - 1, 1);
    setNewEquivalents(newEquivalentsData);
  };

  const calculationOnBlur = (field, index = 0) => {
    if (field === 'equivalents') return;
    let obj;

    let temp_entered = canCalculate[field];
    temp_entered[index] = false;
    setCanCalculate({ ...canCalculate, [field]: temp_entered });
    obj = optionCalculation(
      {
        chemical_type: newEquivalents[0]?.chemical_type,
        reagent_type: newEquivalents[0]?.reagent_type,
        [newEquivalents[0]?.chemical_type]: {
          ...newEquivalents[0],
          moles: newEquivalents.map(i => i.moles),
          equivalents: newEquivalents.map(i => i.equivalents),
          mass: newEquivalents.map(i => i.mass),
          volume: newEquivalents.map(i => i.volume),
          density: newEquivalents[0].density,
          concentration: newEquivalents[0].concentration,
          mw: newEquivalents[0].mw,
        },
      },
      canCalculate,
      field,
      index,
      limitingInput,
      PROJECT_TYPES.OPTIMIZATION,
      true
    );
    let indexErrors = {};
    setNewEquivalents(
      newEquivalents.map((equivalent, idx) => {
        if (index === idx) {
          equivalent.moles = obj.moles;
          equivalent.mass = obj.mass;
          equivalent.volume = obj.volume;
          equivalent.equivalents = obj.equivalents;
          indexErrors = {
            moles: obj.moles ? false : errors[index]?.moles,
            mass: obj.mass ? false : errors[index]?.mass,
            volume: obj.volume ? false : errors[index]?.volume,
            equivalents: false,
          };
        }
        return equivalent;
      })
    );

    setErrors(
      errors.map((i, idx) => {
        if (idx === index) return { ...i, ...indexErrors };
        return i;
      })
    );
  };

  const calculateClick = (field, index) => {
    let newField = newEquivalents[0][field];

    let obj;

    obj = calculateSpecificField(
      {
        chemical_type: newEquivalents[0]?.chemical_type,
        [newEquivalents[0]?.chemical_type]: {
          ...newEquivalents[0],
          moles: newEquivalents.map(i => i.moles),
          equivalents: newEquivalents.map(i => i.equivalents),
          mass: newEquivalents.map(i => i.mass),
          volume: newEquivalents.map(i => i.volume),
          density: newEquivalents[0].density,
          concentration: newEquivalents[0].concentration,
          mw: newEquivalents[0].mw,
        },
      },
      field,
      index,
      limitingInput
    );

    newField = obj[field];

    setNewEquivalents(
      newEquivalents.map((equivalent, idx) => {
        if (index === idx) {
          equivalent[field] = newField;
        }
        return equivalent;
      })
    );
  };

  return (
    <div
      className="optimization-popover manual-input"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div className="optimization-popover_content">
        <h5 className="optimization-popover_title">Optimization options</h5>
        {newEquivalents.map((equivalent, index) => {
          return (
            <ManualOptimizationInputPopoverContentOption
              equivalent={equivalent}
              error={errors[index]}
              index={index + 1}
              onDeleteInputEquivalents={onDeleteInputEquivalents}
              onChange={(v, type) => onChange(v, type, index)}
              onBlur={field => {
                calculationOnBlur(field, index);
              }}
              isLimiting={isLimiting}
              calculateClick={field => calculateClick(field, index)}
            />
          );
        })}
        {newEquivalents.length < 5 && (
          <div className="optimization-popover_add_option" onClick={onAddOption}>
            + Add Option
          </div>
        )}
      </div>
      <div className="optimization-popover_footer">
        <Button secondary onClick={onClose} className="optimization-popover_footer_cancel_button">
          Cancel
        </Button>
        <Button onClick={onSavePress}>Save</Button>
      </div>
    </div>
  );
};

const dataMappingArray = [
  { name: 'Equivalents', value: 'equivalents', measure: '' },
  { name: 'Moles', value: 'moles', measure: 'mol' },
  { name: 'Mass', value: 'mass', measure: 'g' },
  { name: 'Volume', value: 'volume', measure: 'mL' },
];

const ManualOptimizationInputPopoverContentOption = ({
  equivalent,
  index,
  onChange,
  error = {},
  onDeleteInputEquivalents,
  isLimiting,
  onBlur,
  calculateClick,
}) => (
  <div className="optimization-popover_content_option">
    <div className="optimization-popover_content_option_title">
      option {index}
      {index > 1 && <DeleteOutlined onClick={() => onDeleteInputEquivalents(index)} />}
    </div>
    <div className="optimization-popover_content_option_content">
      {dataMappingArray.map(i => {
        if (equivalent.reagent_type === 'solid' && i.value === 'volume') return '';
        return (
          <div className="optimization-popover_content_option_content_row">
            <div className="optimization-popover_content_option_content_row_title">{i.name}</div>
            <div className="optimization-popover_content_option_content_row_equal-sign">=</div>
            {i.value === 'equivalents' ? (
              <span>{isLimiting ? 1 : equivalent[i.value] || 'N/A'}</span>
            ) : (
              <div className="optimization-popover_content_option_content_row_input_content">
                <div>
                  <Input
                    value={equivalent[i.value]}
                    onChange={v => onChange(v, i.value)}
                    error={error ? error[i.value] : null}
                    onBlur={() => onBlur(i.value)}
                    errorText={`'${i.name}' is a required field`}
                    disabled={isLimiting && i.value === 'equivalents'}
                  />
                  <span>{i.measure}</span>
                </div>
                <span className="calc-btn" onClick={() => calculateClick(i.value)}>
                  calculate
                </span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  </div>
);
