import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { Checkbox, Input, openNotification, Popup, Select } from '..';
import { filterObj, lengthObj } from '../../utils/common';
import { validationIsEmpty, validationLength } from '../../utils/validation';
import { createProcessFromScratch } from '../../store/processBuilder/processbuilder.actions';
import history from '../../utils/history';
import { PROCESS_TYPES, steps_synjet, steps_synjet_pro, SYNJET_PRO_PROCESS_TYPES } from '../../constants';
import {
  initialStateToProcessTypeMapper,
  typeOptionsToProcessTypeMapper,
} from '../../constants/createProcessConstatants';

const maxLengthInput = 256;

export const PopupCreateProcess = ({
  open,
  openPopup,
  closePopup,
  clone,
  getListProjects,
  isSynJet,
  deviceType,
  processType,
}) => {
  const [processData, setProcessData] = useState({});
  const [newProjectId, setNewProjectId] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isClone, setIsClone] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsClone(!!clone);
    const initialState = initialStateToProcessTypeMapper[processType];
    if (clone) {
      setProcessData({
        name: clone.name,
        type: initialState?.type,
        project: clone.project,
        projectName: clone.projectName,
      });
    } else {
      setProcessData(initialState);
    }
  }, []);

  const handleChanges = (value, field) => {
    setProcessData({ ...processData, [field]: value });
    const errorsNew = { ...errors };
    delete errorsNew[field];
    setErrors(errorsNew);
  };

  const handleSelectChanges = (field, value) => {
    if (field === 'numberOfSteps' && value === '2') {
      setProcessData({
        ...processData,
        [field]: value,
        variableStep: '1',
        enableQuenching: false,
      });
    } else setProcessData({ ...processData, [field]: value });

    if (clone && field === 'projectName') {
      setNewProjectId(value);
    }
    const errorsNew = { ...errors };
    delete errorsNew[field];
    setErrors(errorsNew);
  };

  const saveChanges = () => {
    const errors = validate();
    if (lengthObj(errors)) setErrors(errors);
    else {
      setLoading(true);
      const dataToSend = { ...processData, name: encodeURIComponent(processData.name) };

      dataToSend.device = PROCESS_TYPES[processType].device;

      if (processType === PROCESS_TYPES.SynJet.key || processType === PROCESS_TYPES.SynJetPro.key) {
        if (!clone && dataToSend.numberOfSteps === '1') dataToSend.variableStep = dataToSend.numberOfSteps;
        else if (!clone && dataToSend.numberOfSteps === '2') {
          dataToSend.variableStep = processType === PROCESS_TYPES.SynJet.key ? '2' : dataToSend.variableStep;
        }
      } else {
        delete dataToSend.variableStep;
        delete dataToSend.numberOfSteps;
      }
      if (isClone) {
        dataToSend.uuid = clone.uuid;
        if (newProjectId) dataToSend.project = newProjectId;
      }
      dispatch(createProcessFromScratch(dataToSend)).then(resp => {
        setLoading(false);
        if (resp?.errors) {
          const errorsResponse = {};
          resp.errors.map(i => (errorsResponse[i.field] = i.messages[0]));
          setErrors(errorsResponse);
        }
        if (resp.errors) {
          // openNotification('', resp.errors.message);
        } else {
          handleCancel();
          const path = `${PROCESS_TYPES[processType].path}/process-builder/details/${resp.uuid}`;
          history.push(path);
          openNotification(`The process has been successfully ${isClone ? 'cloned' : 'created'}`);
        }
      });
    }
  };

  const handleCancel = () => {
    closePopup();
    setProcessData(initialStateToProcessTypeMapper[processType]);
    setErrors({});
  };

  const validate = () => {
    const { name, type, project } = processData;
    const errors = validationIsEmpty({ name, type, project });
    errors.name = errors.name || validationLength(name, 'Process name');

    return filterObj(errors);
  };

  const getProjects = useCallback(
    value =>
      getListProjects(
        {
          page: 1,
          search: value,
          action: 'create process',
        },
        true,
        20
      ).then(resp => {
        if (value === '') return [];
        if (resp?.results) return resp.results.map(i => ({ value: i.uuid, label: i.name }));
        return [];
      }),
    []
  );

  return (
    <Popup
      title={isClone ? 'Clone process' : 'Create new process'}
      open={open}
      className="popup-create-process"
      handleCancel={handleCancel}
      textSubmit={isClone ? 'Clone' : 'Continue'}
      handleSubmit={saveChanges}
      loading={loading}
    >
      <Select
        typeToSearch
        getOptions={getProjects}
        onChange={(...args) => handleSelectChanges(isClone ? 'projectName' : 'project', ...args)}
        value={processData.projectName}
        placeholder="Select project"
        field={isClone ? 'projectName' : 'project'}
        label="Project"
        required
        error={errors.project}
        errorText={errors.project}
        disabled={loading}
        notFoundContent={false}
      />
      <Input
        maxLength={maxLengthInput}
        label={isClone ? 'Name of the new process' : 'Set process name'}
        disabled={loading}
        placeholder="Process name"
        value={processData.name}
        onChange={handleChanges}
        field="name"
        require
        autoFocus
        error={errors.name}
        errorText={errors.name}
        allowSpecials
        withoutComma={false}
      />
      {!isClone && (
        <Select
          onChange={(...args) => handleSelectChanges('type', ...args)}
          showSearch={false}
          value={processData.type}
          label="Process type"
          placeholder="Select process type"
          required
          // options={isSynJet ? typeOptionsSynJet : typeOptions}
          options={typeOptionsToProcessTypeMapper[processType]}
          error={errors.type}
          errorText={errors.type}
          disabled={loading}
        />
      )}
      {(processType === PROCESS_TYPES.SynJet.key || processType === PROCESS_TYPES.SynJetPro.key) && !clone && (
        <>
          <Select
            label="Number of steps"
            showSearch={false}
            options={processType === PROCESS_TYPES.SynJetPro.key ? steps_synjet_pro : steps_synjet}
            value={processData.numberOfSteps}
            required
            onChange={(...args) => handleSelectChanges('numberOfSteps', ...args)}
            error={errors.numberOfSteps}
            errorText={errors.numberOfSteps}
            disabled={loading}
          />
          {processData.numberOfSteps === '2' && processType === PROCESS_TYPES.SynJetPro.key && (
            <Select
              label="Variable step"
              showSearch={false}
              options={processType === PROCESS_TYPES.SynJetPro.key ? steps_synjet_pro : steps_synjet}
              value={processData.variableStep}
              required
              onChange={(...args) => handleSelectChanges('variableStep', ...args)}
              error={errors.variableStep}
              errorText={errors.variableStep}
              disabled={loading}
            />
          )}
          {/* {processData.numberOfSteps === '2' && ( */}
          {/*  <Select */}
          {/*    label="Variable step" */}
          {/*    showSearch={false} */}
          {/*    options={steps_numerals} */}
          {/*    value={processData.variableStep} */}
          {/*    required */}
          {/*    onChange={(...args) => handleSelectChanges('variableStep', ...args)} */}
          {/*    error={errors.variableStep} */}
          {/*    errorText={errors.variableStep} */}
          {/*    disabled={loading} */}
          {/*  /> */}
          {/* )} */}
        </>
      )}

      {processType === PROCESS_TYPES.SynJetPro.key &&
        processData.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION &&
        !clone &&
        processData.numberOfSteps === '1' && (
          <Checkbox
            field="enableQuenching"
            disabled={loading}
            onChange={handleChanges}
            value={processData.enableQuenching || false}
          >
            Add quenching
          </Checkbox>
        )}
    </Popup>
  );
};
