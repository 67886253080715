import { StatusLabel } from '../../Common/StatusLabel';
import React, { useEffect, useState } from 'react';
import { ExperimentSummary } from '../ExperimentProgress/ExperimentSummary';
import { ExperimentFinalizationCheckList } from './ExperimentFinalizationChecklist';
import './style.scss';
import { ExperimentTimer } from '../ExperimentProgress/ExperimentTimer';
import moment from 'moment';

export const ExperimentFinalization = ({
  experiment,
  allReactors,
  options,
  setOptions,
  showTimer,
  showContent = true,
  stopCountdown = false,
}) => {
  const [actualTime, setActualTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(0);

  const stopTimer = experiment?.status === 'Completed' || stopCountdown;
  var timer = null;

  useEffect(() => {
    if (experiment && showTimer) {
      if (!actualTime) {
        setEstimatedTime(experiment?.totalTime);
        if (experiment?.status === 'Completed') {
          setActualTime(experiment?.actualTime);
        } else if (experiment?.execution?.startedAt && !stopTimer) setActualTime(experiment?.execution?.startedAt);
      }
      if ((experiment?.execution?.finalizedAt || experiment?.execution?.abortedAt) && stopCountdown) {
        timer = setTimeout(() => {
          setActualTime(experiment?.actualTime);
        }, 1000);
      }
    }
  }, [experiment, showTimer, stopCountdown]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <section className="experiment-finalization">
      <header className="experiment-finalization__header">
        <h3 className="experiment-finalization__header__title">Experiment {experiment?.name}</h3>
        <div className="experiment-finalization__header__info">
          {showTimer && (
            <ExperimentTimer
              actualTime={actualTime}
              estimatedTime={estimatedTime}
              setActualTime={setActualTime}
              stopCountdown={stopTimer}
              startTime={experiment?.execution?.startedAt}
            />
          )}
          <StatusLabel type="active">{experiment?.status}</StatusLabel>
        </div>
      </header>
      {showContent && (
        <>
          <ExperimentFinalizationCheckList options={options} setOptions={setOptions} />
          <ExperimentSummary experiment={experiment} allReactors={allReactors} estimatedTime={estimatedTime} />
        </>
      )}
    </section>
  );
};
