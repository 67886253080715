import React from 'react';
import './styles.scss';
import cn from 'classnames';
import { PageHeader } from '../PageHeader';

export const PageContainer = ({ children, className, isSynJet, noTable, exceptionPage }) => (
  <div className={cn('page-container', className, { isSynJet, noTable, exceptionPage })}>
    <PageHeader />
    {children}
  </div>
);
