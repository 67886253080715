import React, { useState } from 'react';
import { Collapse } from '../../Common';
import './index.scss';

export const ReactionCollapse = ({ items }) => {
  const [activeKey, setActiveKeys] = useState([0]);

  const onChange = keys => {
    setActiveKeys(keys);
  };

  return (
    <Collapse accordion className="reaction-tab__collapse" onChange={onChange} items={items} activeKey={activeKey} />
  );
};
