import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import './style.scss';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, CompoundSmilesPreview, Input, Select } from '../../../index';
import { checkNumericField, httpSynMolDB } from '../../../../utils';
import { INPUT_EXCLUDE_SYMBOLS } from '../../../../constants/regexp';
import { getCompoundType, getExperimentContainerStyle } from '../../../../utils/execution';

export const SJDispenserCompound = ({
  compound,
  experiments,
  isEdit,
  isNormalize,
  compoundInfo,
  updateCompoundInfo,
  validationErrors,
  setValidationErrors,
  isPro,
  handleManualChange,
  isManual,
}) => {
  const [solventsOptions, setSolventsOptions] = useState([]);

  const experimentIndex = experiments
    ? experiments.findIndex(experiment => experiment.uuid === compound.experiment_uuid)
    : 0;

  const getSolventOptions = () =>
    solventsOptions?.map(compound => ({
      value: compound.compound_name,
      label: compound.compound_name,
    }));

  const handleFillManually = e => {
    e.stopPropagation();
    handleManualChange(compound.experiment_uuid);
  };

  const handleSolventChange = (value, index, isFraction) => {
    const solventData = isFraction
      ? null
      : solventsOptions.find(solventOption => solventOption.compound_name === value);
    if (isFraction && validationErrors?.fraction) {
      setValidationErrors({
        ...validationErrors,
        fraction: validationErrors.fraction?.map((fractionError, fractionIndex) =>
          fractionIndex === index ? null : fractionError
        ),
      });
    } else if (validationErrors?.solvents) {
      setValidationErrors({
        ...validationErrors,
        solvents: validationErrors.solvents?.map((solventError, solventIndex) =>
          solventIndex === index ? null : solventError
        ),
      });
    }
    updateCompoundInfo({
      ...compoundInfo,
      solvents: compoundInfo.solvents.length
        ? compoundInfo.solvents?.map((solvent, solventIndex) =>
            solventIndex === index
              ? isFraction
                ? { ...solvent, fraction: checkNumericField(value, 1, 3) }
                : { ...solvent, solvent: solventData }
              : solvent
          )
        : [{ solvent: solventData, fraction: 1 }],
    });
  };
  const handleNormalizeSolventChange = value => {
    const solventData = solventsOptions.find(solventOption => solventOption.compound_name === value);
    if (validationErrors?.solvent) {
      setValidationErrors({
        ...validationErrors,
        solvent: null,
      });
    }
    updateCompoundInfo({
      ...compoundInfo,
      solvent: solventData,
    });
  };

  const handleSolventAdd = () => {
    updateCompoundInfo({
      ...compoundInfo,
      solvents: [...compoundInfo.solvents, { solvent: null, fraction: 0 }],
    });
  };

  const handleSolventDelete = () => {
    updateCompoundInfo({
      ...compoundInfo,
      solvents: compoundInfo.solvents.slice(0, 1)?.map(solvent => ({ ...solvent, fraction: 1 })),
    });
  };

  useEffect(() => {
    httpSynMolDB('get_compound_list?tag=solvent&return_compatibility=true').then(resp => {
      setSolventsOptions([{ compound_name: 'None' }, ...resp.compound_list]);
    });
  }, []);

  return (
    <div
      className="dispenser-container-compound" /* onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} */
    >
      <div className={cn('dispenser-container-compound__row', { pro: isPro })}>
        <div className={cn('dispenser-container-compound__experiment', getExperimentContainerStyle(experimentIndex))}>
          <h5 className="dispenser-container-compound__title">
            Experiment&nbsp;
            {compoundInfo?.name}
          </h5>
          <h5 className="dispenser-container-compound__subtitle">
            {isNormalize
              ? 'Solvent 1'
              : `${getCompoundType(compound?.compound_name, compound?.experiment_uuid, experiments)} ${
                  compound?.compound_name
                }`}
          </h5>
        </div>
        {isNormalize ? (
          <>
            {isEdit && !isPro ? (
              <Select
                options={getSolventOptions().filter(option => option.value !== 'None')}
                placeholder="None"
                className="solvent-select"
                value={compoundInfo?.solvent?.compound_name}
                onChange={solvent => handleNormalizeSolventChange(solvent)}
                error={validationErrors?.solvent}
                errorText={validationErrors?.solvent}
              />
            ) : (
              <div className="normalize-solvent-container">
                <p className="normalize-solvent">{compoundInfo?.solvent?.compound_name}</p>
                {isPro && (
                  <p className="normalized-volume">{`Calculated Volume: ${
                    compoundInfo?.calculatedVolume * 1000
                  } µL`}</p>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="dispenser-container-compound__element">
              <CompoundSmilesPreview
                compound={compoundInfo?.compound}
                hideMW
                isPro={isPro}
                calculatedVolume={compound?.calculated_volume * 1000}
              />
            </div>
            <div className="dispenser-container-compound__solvents">
              <div className="dispenser-container-compound__solvents__container">
                {isEdit && !isPro ? (
                  <>
                    {compoundInfo?.solvents?.length ? (
                      compoundInfo?.solvents?.map((solvent, index, solvents) => (
                        <div className="solvent__column" key={solvent?.solvent?.compound_name}>
                          <div className="solvent__row">
                            Solvent:{' '}
                            <Select
                              options={getSolventOptions()}
                              placeholder="None"
                              className="solvent-select"
                              value={solvent?.solvent?.compound_name}
                              onChange={solvent => handleSolventChange(solvent, index)}
                              error={validationErrors?.solvents && validationErrors?.solvents[index]}
                              errorText={validationErrors?.solvents && validationErrors?.solvents[index]}
                            />
                            {index === 1 && (
                              <Button
                                type="text"
                                className={cn('add-solvent', 'delete-solvent')}
                                onClick={handleSolventDelete}
                              >
                                <DeleteOutlined />
                              </Button>
                            )}
                          </div>
                          {solvent?.compound_name !== 'None' && solvents?.length > 1 && (
                            <div className="solvent__row">
                              <span className="synjet-compound__text">Fraction:</span>
                              <Input
                                className="fraction-input"
                                placeholder="0"
                                maxLength={5}
                                disabled={solvents.length === 1}
                                onChange={value => handleSolventChange(value, index, true)}
                                value={compoundInfo?.solvents[index]?.fraction}
                                regex={INPUT_EXCLUDE_SYMBOLS}
                                error={validationErrors?.fraction && validationErrors?.fraction[index]}
                                errorText={validationErrors?.fraction && validationErrors?.fraction[index]}
                              />
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <>
                        Solvent:{' '}
                        <Select
                          options={getSolventOptions()}
                          placeholder="None"
                          value="None"
                          className="solvent-select"
                          onChange={solvent => handleSolventChange(solvent, 0)}
                          error={validationErrors?.solvent}
                          errorText={validationErrors?.solvent}
                        />
                      </>
                    )}
                    {compoundInfo?.solvents?.length === 1 &&
                      compoundInfo?.solvents &&
                      compoundInfo?.solvents[0]?.solvent?.compound_name !== 'None' && (
                        <Button type="text" className="add-solvent" onClick={handleSolventAdd}>
                          <PlusOutlined /> ADD SOLVENT
                        </Button>
                      )}
                  </>
                ) : (
                  <>
                    Solvent: {!compoundInfo?.solvents?.length && <span>&nbsp;None</span>}
                    {compoundInfo?.solvents?.length === 1
                      ? compoundInfo?.solvents?.[0]?.solvent?.compound_name
                      : compoundInfo?.solvents?.map((solvent, index) => (
                          <>
                            {solvent?.solvent.compound_name}
                            <span className="dispenser-container-compound__subtitle">({solvent?.fraction})</span>
                            {!index && <span>, </span>}
                          </>
                        ))}
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {isNormalize && <div />}
        {isPro && !isEdit && (
          <div className="fill_manually-container">
            <Button className="fill_manually_btn" secondary onClick={handleFillManually}>
              {isManual ? 'To dispenser' : 'Fill manually'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
