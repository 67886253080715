import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { ReactionCollapse } from './ReactionCollapse';
import { COMPOUND_VARIATY, PROJECT_TYPES } from '../../../constants';
import { ReactionTabTable } from './ReactionTabTable';
import { getReactionTabTableData, getReactionTabTableDataManual } from '../../../utils/experimentHelpers';
import Canvas from '../../DrawerRoute/Canvas';
import { PageContainer } from '../../PdfGenerator/PageContainer';
import { PageHeader } from '../../PdfGenerator/PageHeader';
import { TitleContainer } from '../../PdfGenerator/TitleContainer';

export const ReactionTab = ({ reactionsInfo = {}, processType, experiment, manual, reactionsIdx, pdfMode }) => {
  const [collapseItems, setCollapseItems] = useState([]);
  const routeData = useMemo(() => {
    if (experiment?.route) return JSON.parse(experiment.route.data);
    return null;
  }, [JSON.stringify(experiment?.route)]);

  const routeCanvas = idx =>
    routeData && (
      <div className="reaction-tab_route-container">
        <div className="reaction-tab_route-container_wrapper">
          <Canvas indexUniq={0} results={routeData} routeId={idx} onCanvasClick={() => {}} type="routeCanvas" />
        </div>
      </div>
    );
  // useEffect(() => {
  //   if (!reactionsInfo?.reactions || reactionsInfo?.reactions.length < 2) return;
  //   setCollapseItems([]);
  // }, [reactionsInfo]);

  useEffect(() => {
    if (!reactionsInfo?.reactions || reactionsInfo?.reactions.length < 2) return;
    const collapseItems = reactionsInfo.reactions.map((reaction, reactionIndex) => {
      let reactionsData;
      if (manual) {
        reactionsData = getReactionTabTableDataManual(reactionsInfo);
      } else {
        reactionsData = getReactionTabTableData(reactionsInfo, reaction, reactionIndex, processType);
      }
      const roundLetterNumber = Math.trunc(reactionsIdx?.reaction_indexes[reactionIndex] / 26);
      return {
        title: `Reaction ${
          !manual
            ? processType === PROJECT_TYPES.OPTIMIZATION
              ? reaction.index + 1
              : COMPOUND_VARIATY[reaction.index]
            : processType === PROJECT_TYPES.OPTIMIZATION
            ? reactionsIdx?.reaction_indexes[reactionIndex] + 1
            : reactionsIdx?.reaction_indexes[reactionIndex] > 25
            ? `${COMPOUND_VARIATY[roundLetterNumber - 1]}${
                COMPOUND_VARIATY[reactionsIdx?.reaction_indexes[reactionIndex] - roundLetterNumber * 26]
              }`
            : COMPOUND_VARIATY[reactionsIdx?.reaction_indexes[reactionIndex]]
        }`,
        body: (
          <>
            {manual ? routeCanvas(reactionIndex) : routeCanvas(reaction.index)}
            <ReactionTabTable reactionsData={manual ? reactionsData[reactionIndex] : reactionsData} manual={manual} />
          </>
        ),
      };
    });
    setCollapseItems(collapseItems);
  }, [reactionsInfo]);

  const singleRoute = () => (
    <>
      {routeCanvas(0)}
      <ReactionTabTable
        reactionsData={
          manual
            ? getReactionTabTableDataManual(manual ? reactionsInfo : reactionsInfo)[0]
            : getReactionTabTableData(reactionsInfo, reactionsInfo?.reactions?.[0], 0, processType)
        }
        manual={manual}
      />
    </>
  );

  if (pdfMode) {
    return reactionsInfo?.reactions?.length > 1 ? (
      collapseItems.map(i => (
        <PageContainer className="reaction-tab-pdf-container">
          <TitleContainer>{i.title}</TitleContainer>
          {i.body}
        </PageContainer>
      ))
    ) : (
      <PageContainer className="reaction-tab-pdf-container">
        <TitleContainer>Reactions</TitleContainer>
        {singleRoute()}
      </PageContainer>
    );
  }
  return (
    <div className="reaction-tab">
      <div className="reaction-tab_title">Reactions</div>
      {reactionsInfo?.reactions?.length > 1 ? <ReactionCollapse items={collapseItems} /> : singleRoute()}
    </div>
  );
};
