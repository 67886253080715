const setRowTable = (dispenser, getCompound) => {
  const compound = getCompound(dispenser.compound_id);

  return {
    dispenserUuid: dispenser.uuid,
    name: {
      value: compound,
    },
    compound_name: {
      name: compound?.compound_name || compound?.smiles,
      formula: compound?.formula,
    },
    vial_number: { value: dispenser.manual_vials.map(i => i.name) },
    volume: { value: dispenser.manual_vials.map(i => i.volume) },
    location: { value: dispenser.manual_vials.map(i => i.location) },
    has_sent: { value: dispenser.has_sent || false },
  };
};

export default setRowTable;
