import React, { useEffect, useState } from 'react';
import { BatchConfigurationWithExperimentsTable } from '../BatchConfigurationWithExperimentsTable/BatchConfigurationWithExperimentsTable';
import { CheckList } from './CheckList/CheckList';

export const BatchFinalization = ({
  batch,
  finalizationOptions,
  setFinalizationOptions,
  connectivitySubject,
  setConnectivitySubject,
  setDisableButtons,
  disableButtons,
}) => (
  <div>
    <CheckList finalizationOptions={finalizationOptions} setFinalizationOptions={setFinalizationOptions} />
    <BatchConfigurationWithExperimentsTable
      batch={batch}
      connectivitySubject={connectivitySubject}
      setConnectivitySubject={setConnectivitySubject}
      setDisableButtons={setDisableButtons}
      disableButtons={disableButtons}
    />
  </div>
);
