import React from 'react';
import { ExperimentStatus, Tabs } from '../../../Common';

export const BatchTabs = ({
  experiments,
  selectedExperiment,
  onExperimentChange,
  children,
  getExperimentStatus,
  isProExecution,
}) => {
  const getOptions = experiments =>
    experiments.map(experiment => ({
      value: experiment.uuid,
      label: (
        <span>
          {experiment.name}{' '}
          <ExperimentStatus status={isProExecution ? getExperimentStatus(experiment.uuid) : experiment.status} />
        </span>
      ),
    }));

  return (
    <Tabs options={getOptions(experiments)} activeKey={selectedExperiment} onChange={onExperimentChange}>
      {children}
    </Tabs>
  );
};
