import './style.scss';
import '@fullcalendar/react';

import React, { useEffect, useRef } from 'react';

import cn from 'classnames';

import { LABEL_STEPS, SYNJET_EXPERIMENT_STATUSES } from '../../../constants';
import { DEVICES, modeSched, PRIORITIES } from '../../../constants/common';
import {
  isoToExecutionView,
  isoToExecutionViewList,
  isoToExecutionViewTimeZone,
  transferIsoToMMDDYY,
  transferIsoToMMDDYYmmHH,
  transferIsoToMMDDYYmmHHutc,
} from '../../../utils/date';
import { formatSecondsToHours, formatSecondsToTimeString } from '../../../utils/formatTime';
import { Avatar, Button, Tooltip } from '../../Common';
import { StatusPriority } from '../../Experiment/StatusPriority';
import { Notes } from '../Notes/Notes';

export const Card = ({
  uuid,
  name,
  active,
  data,
  onScheduleExperimentPress,
  status,
  process = {},
  submittedAt,
  compatibleWith,
  createdBy = {},
  onClick,
  className,
  reschedule,
  unschedule,
  mode,
  premissionsActions = {},
  tab,
}) => {
  const elem = useRef(null);
  const initial = useRef(null);

  const scheduleReScheduleHandler = e => {
    reschedule && reschedule(e);
    onScheduleExperimentPress && onScheduleExperimentPress(e);
  };

  const unscheduleHandler = e => {
    e.stopPropagation();
    unschedule();
  };

  const dataEvent =
    mode === 'calendar'
      ? ''
      : JSON.stringify({
          duration: Math.ceil(data.totalTime / 60),
          data: { ...data, duration: Math.ceil(data.totalTime / 60) },
        });

  const showDetails = e => {
    onClick && onClick(data.key, e);
  };

  const isScheduled = mode === modeSched;
  const isCalendar = mode === 'calendar';

  useEffect(() => {
    if (active && elem.current && !initial.current) {
      initial.current = true;
      elem.current.scrollIntoView();
    }
  }, [active]);

  const estTime = `${
    formatSecondsToHours(
      Math.floor(data?.status === SYNJET_EXPERIMENT_STATUSES.COMPLETED.label ? data?.actualTime : data?.totalTime)
    ).hour
  }h ${Math.ceil(
    ((data?.status === SYNJET_EXPERIMENT_STATUSES.COMPLETED.label ? data?.actualTime : data?.totalTime) % 3600) / 60
  )}min`;

  return (
    <div
      ref={elem}
      data-card={dataEvent}
      className={cn('experiment-card', { active }, className)}
      id={`experiment-card-${uuid}`}
      onClick={showDetails}
    >
      <div className="experiment-card_preview-month">
        <span>{`${formatSecondsToHours(data?.totalTime).hour}h ${formatSecondsToHours(data?.totalTime).min}min`}</span>
        <span>{name}</span>
      </div>
      <p className="experiment-type">
        {process.type}{' '}
        {(process?.device === DEVICES.SYNJET || process?.device === DEVICES.SYNJETPRO) &&
          `(${LABEL_STEPS[process?.numberOfSteps]})`}
      </p>
      <div className="experiment-name-wrapper">
        <h3 className="name">{name}</h3>
        <div className="right_time">
          <div className="priority-block">
            {mode === 'scheduling' || mode === 'calendar' ? (
              <StatusPriority priority={data.priority} />
            ) : (
              <div className={cn({ 'in-progress': status === 'Inprogress' })}>{status}</div>
            )}
          </div>
          <span className="time">{`${estTime}`}</span>
        </div>
      </div>
      <div className="experiment-item">
        <div className="experiment-item-project" title={process.project?.name}>
          Project: {process.project?.name}
        </div>
        {!!data.notes?.length && <Notes count={data?.notes.length} />}
      </div>
      {isScheduled && (
        <p className="experiment-item">
          <div className="experiment-item-project" title={process.name}>
            Process: {process.name}
          </div>
        </p>
      )}
      {!isScheduled &&
        (tab === 'history' ? (
          <Tooltip title={transferIsoToMMDDYYmmHH(data?.completedAt)}>
            <p className="experiment-item">{`Completed: ${isoToExecutionViewList(data?.completedAt)}`}</p>
          </Tooltip>
        ) : (
          <Tooltip title={transferIsoToMMDDYYmmHH(data?.timeSlot?.startAt || data.start)}>
            <p className="experiment-item">
              Scheduled: {isoToExecutionViewTimeZone(data.timeSlot?.startAt || data.start)}
            </p>
          </Tooltip>
        ))}
      <div className="hidden-fields">
        {!isScheduled && (
          <p className="experiment-item">
            <div className="experiment-item-project" title={process.name}>
              Process: {process.name}
            </div>
          </p>
        )}
        {!isCalendar && (
          <p className="experiment-item">
            Compatible with:{' '}
            {compatibleWith?.map((i, index) => `${i.name}${index !== compatibleWith.length - 1 ? ', ' : ''}`)}
          </p>
        )}
        {(isScheduled || isCalendar) && (
          <p className="experiment-item">
            Status:
            {data?.status}
          </p>
        )}
        <div className="name-wrapper">
          <span>
            <Avatar size={20} name={`${createdBy.firstName} ${createdBy.lastName}`} />{' '}
          </span>
          <Tooltip
            placement="top"
            title={`Date submitted: ${transferIsoToMMDDYYmmHHutc(data.submittedAt).substr(0, 16)}`}
          >
            <span>{transferIsoToMMDDYY(data.submittedAt)}</span>
          </Tooltip>
        </div>
        <div className={cn('experiment-card-buttons', { 'hide-scheduled-buttons': mode === 'execution' })}>
          <div>
            {premissionsActions.canScheduleRescheduleExprt && (
              <Button secondary onClick={scheduleReScheduleHandler}>
                {reschedule ? 'Reschedule' : 'Schedule'}
              </Button>
            )}
            {unschedule && premissionsActions.canScheduleRescheduleExprt && (
              <Button secondary onClick={unscheduleHandler}>
                Unschedule
              </Button>
            )}
          </div>
          <Button secondary onClick={showDetails}>
            Details
          </Button>
        </div>
      </div>
    </div>
  );
};
