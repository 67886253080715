import React, { useCallback } from 'react';
import './style.scss';
import { Bottle, Molecule } from '../../Common/Icons';
import { StatusLabel } from '../../Common/StatusLabel';
import { Tooltip } from '../..';
import cn from 'classnames';
import history from '../../../utils/history';
import { typesProjects } from '../../../constants';

const activeStatus = {
  color: '#46C5E9',
  name: 'active',
  tooltip:
    'This project is active. Users are able access the project. Project can be associated with a route/process/experiment/compound.',
};

const passiveStatus = {
  color: 'rgba(44, 70, 82, 0.3)',
  name: 'passive',
  tooltip: 'Tooltip draft version',
};

const config = {
  drug: {
    icon: Molecule,
    active: activeStatus,
    draft: passiveStatus,
  },

  process: {
    icon: Bottle,
    text: 'draft',
    type: 'passive',
    active: activeStatus,
    draft: passiveStatus,
  },
};

export const Card = ({ status, type, typeText, name, uuid, createdAt, manager, compounds, id, i, permissions }) => {
  const typeConfig = typesProjects[type];
  const Icon = typeConfig.icon;
  const redirectToDetails = () => {
    if (!permissions.view_project) return;
    history.push(`/projects/details/${uuid}`);
  };
  return (
    <div className="project-info-card" onClick={redirectToDetails}>
      <Icon className={typeConfig[status].className} />
      <div className="project-info-card_container-data">
        <div className="project-info-card_container-info">
          <div className="project-info-card_info">
            <span
              className={cn('project-info-card_info_name', { 'project-info-card_info_name_1-line': compounds })}
              title={name}
            >
              {name}
            </span>
            <span className="project-info-card_info_uuid-date common-style-text">
              {i.key} •<Tooltip title={`Project was created on ${createdAt}`}> {createdAt.substring(0, 11)}</Tooltip>
            </span>
            <span className="common-style-text project-info-card_info_manager">Project manager: {manager}</span>
            <span className="project-info-card_info_type common-style-text">Type: {typeConfig.label}</span>
          </div>
          <div className="project-info-card_status">
            <Tooltip title={typeConfig[status].tooltip}>
              {' '}
              <StatusLabel className="ant-tooltip-open" type={typeConfig[status].name}>
                {status}
              </StatusLabel>
            </Tooltip>
          </div>
        </div>
        {compounds && (
          <div className="project-info-card_progress">
            <div className="project-info-card_progress_info">
              <span className="project-info-card_progress_info_title common-style-text">
                Completed compounds: {compounds}/10
              </span>
              <Tooltip title="This project contains 2 routes 4 processes, 8 experiments">
                <span className="project-info-card_progress_info_extra common-style-text">R:2 P:4 E:8</span>
              </Tooltip>
            </div>
            <div className="project-info-card_progress_indicator">
              <div
                className="project-info-card_progress_indicator_active-part"
                style={{ width: `calc(100%/${compounds})` }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
