export const SET_EXPERIMENTS_LIST = 'SET_EXPERIMENTS_LIST';
export const SET_NOTES_LIST = 'SET_NOTES_LIST';
export const SET_NOTE_CREATION = 'SET_NOTE_CREATION';
export const SET_NOTE_UPDATE = 'SET_NOTE_UPDATE';
export const SET_DATA_STEP_TWO = 'SET_DATA_STEP_TWO';
export const SET_PAUSED_PROCESS = 'SET_PAUSED_PROCESS';
export const SET_RESUME_PROCESS = 'SET_RESUME_PROCESS';
export const SET_END_EXPERIMENT = 'SET_END_EXPERIMENT';
export const SET_APPENDED_RESULTS = 'SET_APPENDED_RESULTS';
export const SET_DEVICE_ONLINE = 'SET_DEVICE_ONLINE';
export const SET_CONNECTIVITY = 'SET_CONNECTIVITY';
export const SET_MAINTENANCE = 'SET_MAINTENANCE';

export const UPDATE_UNIVARIATE_DATA = 'UPDATE_UNIVARIATE_DATA';
