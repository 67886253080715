import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { AnalyticsComponent } from './AnalyticsComponent';

export const Analytics = React.memo(
  props => {
    return <AnalyticsComponent {...props} />;
  },
  (prev, next) => !!prev.experiment === !!next.experiment
);
