import React, { useEffect, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { Spinner } from '../../../Common/Spinner';
import { Input, openNotification, Table, Tooltip } from '../../../Common';
import { SmilesElem } from '../../PrepareMaterials/SmilesElem';
import { Switch } from '../../../Common/Switch';
import { updateDispenserMaterial } from '../../../../store/synjet/synjet.actions';

const defaultOptionsList = [
  'Check heater blocks',
  'Check FANUC / hardware status',
  'Confirm dispensers',
  'Confirm vials loaded in feeder bowl',
  'Confirm vials loaded in feeder bowl',
  'Confirm vials loaded in feeder bowl',
  'Confirm vials loaded in feeder bowl',
  'Confirm vials loaded in feeder bowl',
  'Confirm vials loaded in feeder bowl',
];

const subOptions = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

const setFixed = value => {
  if (!value) return '';
  const valueSplit = `${value}`.split('.');
  if (valueSplit.length === 2) {
    return value.toFixed(2);
  }
  return `${value}.00`;
};

export const renderList = ({ arr, isSub, parentIdx, parentData, updateChecklist }) => (
  <div className={cn('syn-jet-materials_list_container', { 'syn-jet-materials_list_container_sub': isSub })}>
    {arr.map((i, idx) => (
      <>
        <div
          className={cn('syn-jet-materials_list_item', {
            'syn-jet-materials_list_item_sub': !!i.sub_items?.length,
          })}
        >
          <div className="syn-jet-materials_list_item_order">{isSub ? subOptions[idx] : idx + 1}</div>
          <div className="syn-jet-materials_list_item_name">{i.item}</div>
          {i.sub_items?.length ? (
            <div
              className={cn('syn-jet-materials_list_item_status', {
                'syn-jet-materials_list_item_status_completed': i.is_checked,
              })}
            >
              {i.is_checked ? 'Completed' : 'Not completed'}
            </div>
          ) : (
            <div className="syn-jet-materials_list_item_switch">
              <Switch
                checked={i.is_checked}
                onChange={value => {
                  let newItem = { ...i };
                  if (isSub) {
                    newItem = { ...parentData };
                    newItem.sub_items = newItem.sub_items.map((j, jdx) =>
                      jdx === idx ? { ...j, is_checked: value } : j
                    );
                    if (!newItem.sub_items.find(j => !j.is_checked)) newItem.is_checked = true;
                    else newItem.is_checked = false;
                    updateChecklist(parentIdx, newItem);
                  } else {
                    newItem.is_checked = value;
                    updateChecklist(idx, newItem);
                  }
                }}
              />
            </div>
          )}
        </div>
        {!!i.sub_items?.length &&
          renderList({
            arr: i.sub_items,
            isSub: true,
            parentIdx: idx,
            parentData: i,
            updateChecklist,
          })}
      </>
    ))}
  </div>
);
export const SynJetPrepareMaterials = ({
  errors,
  setErrors,
  setEdited,
  tableData,
  setTableData,
  listCheckboxes,
  setListCheckboxes,
}) => {
  const [loading, setLoading] = useState(false);

  const validationInput = value => {
    let error = false;
    const val = value.split('.');
    if (val.length === 2) {
      if (val[0].length === 0 || val[0].length === 11) error = true;
      else if (val[1].length === 3) error = true;
    } else if (val[0].length === 11) error = true;

    return error;
  };

  const handleChangeInput = (value, field, index) => {
    if (validationInput(value)) return;
    setTableData(
      tableData.map((i, idx) => {
        if (index === idx) {
          let volume = i.concentration.value * +i.act_volume.value * i.mw.value;
          if (field === 'act_volume') {
            volume = i.concentration.value * +value * i.mw.value;
          }
          return {
            ...i,
            act_mass: { ...i.act_mass, value: +volume.toFixed(2) },
            [field]: { ...i[field], value },
            has_sent: i.has_sent ? { ...i.has_sent, value: false } : i.has_sent,
          };
        }
        return i;
      })
    );
    setEdited(true);
    setErrors(errors.filter(i => i.index !== index));
  };

  const handleChangeSwitch = (value, index, rowData) => {
    if (+rowData.act_volume.value < +Math.ceil(rowData.calc_volume.value * 100) / 100) {
      setErrors([...errors, { index, field: 'act_volume' }]);
      openNotification('', 'Actual Volume should be more than Calculated Volume');
      return;
    }
    const data = {
      uuid: rowData.batchUuid,
      dispenser: rowData.dispenserUuid,
      volume: +rowData.act_volume.value,
      mass: +rowData.act_mass.value,
    };
    if (rowData.allData.is_normalized) {
      delete data.mass;
    }
    setLoading(true);
    updateDispenserMaterial({ data })
      .then(() => {
        setTableData(
          tableData.map((i, idx) => {
            if (index === idx) {
              return {
                ...i,
                has_sent: { ...i.has_sent, value },
              };
            }
            return i;
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateChecklist = (index, data) => {
    setListCheckboxes(listCheckboxes.map((i, idx) => (idx === index ? data : i)));
  };

  const columns = [
    {
      title: '',
      dataIndex: 'order',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn('syn-jet-materials_table_order', row.className)}>{index + 1}</div>,
        };
        return obj;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (row?.allData?.is_normalized) {
          const data = row.solvent?.value[0]?.compound;
          obj.children = (
            <div className="syn-jet-materials_table_solvent-norm">
              <span>{data.compound_name || data.formula}</span>
              <span>Solvent</span>
            </div>
          );
        } else if (!row?.allData?.is_normalized && row.name.value)
          obj.children = <SmilesElem id={index} data={row.name.value} displayLoupe />;
        return obj;
      },
    },
    {
      title: 'Solvent',
      dataIndex: 'solvent',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (row.solvent.value && !row?.allData?.is_normalized) {
          obj.children = (
            <div className={cn('syn-jet-materials_table_solvent', row.className)}>
              {row.solvent.value.map((i, idx, arr) => {
                const useComma = arr.length - 1 !== idx;
                return (
                  <>
                    <span>{i.compound?.compound_name || i.compound?.formula}</span>
                    <span className="syn-jet-materials_table_solvent_extra">({i.fraction})</span>
                    {useComma ? ', ' : ''}
                  </>
                );
              })}
            </div>
          );
        }
        return obj;
      },
    },
    {
      title: 'MW (g/mol)',
      dataIndex: 'mw',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn('syn-jet-materials_table_mw', row.className)}>{value?.value}</div>,
        };

        if (row?.allData?.is_normalized) {
          const data = row.solvent?.value[0]?.compound;
          obj.children = <div className={cn('syn-jet-materials_table_mw', row.className)}>{data?.molwt}</div>;
        }

        return obj;
      },
    },
    {
      title: 'Concentration (M)',
      dataIndex: 'concentration',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn('syn-jet-materials_table_concentration', row.className)}>{value.value}</div>,
        };
        if (row?.allData?.is_normalized) {
          obj.children = <div className={cn('syn-jet-materials_table_concentration', row.className)}>&nbsp;</div>;
        }
        return obj;
      },
    },
    {
      title: 'Dispenser',
      dataIndex: 'dispenser',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: (
            <div className={cn('syn-jet-materials_table_dispenser', row.className)}>
              <div className="">
                <span className="syn-jet-materials_table_dispenser_title">Backpack</span>
                <span>{value.backpack}</span>
              </div>
              <div className="">
                <span className="syn-jet-materials_table_dispenser_title">Fluid body</span>
                <span>{value.fluid_body}</span>
              </div>
              <div className="">
                <span className="syn-jet-materials_table_dispenser_title">Calibration</span>
                <span>{value.calibration} µL/ms</span>
              </div>
            </div>
          ),
        };
        return obj;
      },
    },
    {
      title: 'Calculated',
      children: [
        {
          title: 'Volume (mL)',
          dataIndex: 'calc_volume',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: (
                <div className={cn('syn-jet-materials_table_volume', row.className)}>
                  {Math.ceil(value.value * 100) / 100}
                </div>
              ),
            };
            return obj;
          },
        },
        {
          title: 'Mass (mg)',
          dataIndex: 'calc_mass',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: (
                <div className={cn('syn-jet-materials_table_volume', row.className)}>{setFixed(value.value)}</div>
              ),
            };

            if (row?.allData?.is_normalized) {
              obj.children = <div className={cn('syn-jet-materials_table_volume', row.className)}>&nbsp;</div>;
            }
            return obj;
          },
        },
      ],
    },
    {
      title: 'Actual',
      children: [
        {
          title: 'Volume (mL)',
          dataIndex: 'act_volume',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: <div className={row.className}>&nbsp;</div>,
            };
            const error = errors.find((i, idx) => index === i.index);
            obj.children = (
              <Input
                regex={/[^\d|.]+/g}
                value={row.act_volume.value}
                className={cn('syn-jet-materials_table_act_volume', row.className)}
                onChange={(...args) => handleChangeInput(...args, index)}
                field="act_volume"
                error={error?.field === 'act_volume'}
              />
            );
            return obj;
          },
        },
        {
          title: 'Mass (mg)',
          dataIndex: 'act_mass',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: (
                <div className={cn('syn-jet-materials_table_act-mass', row.className)}>
                  <Tooltip title={row.act_mass.value}>{row.act_mass.value}</Tooltip>
                </div>
              ),
            };

            if (row?.allData?.is_normalized) {
              obj.children = <div className={cn(row.className)}>&nbsp;</div>;
            }
            return obj;
          },
        },
      ],
    },
    {
      title: 'Done',
      dataIndex: 'has_sent',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: (
            <div className={row.className}>
              <Switch checked={row.has_sent?.value} onChange={value => handleChangeSwitch(value, index, row)} />
            </div>
          ),
        };
        return obj;
      },
    },
  ];

  return (
    <div className="syn-jet-materials">
      <span className="syn-jet-materials_title">Solutions</span>
      <div className="syn-jet-materials_table">
        <Spinner loading={loading}>
          <Table
            rowClassName="syn-jet-materials_table_row"
            columns={columns}
            dataSource={tableData}
            sidePadding={false}
            // scroll={{ y: height }}
          />
        </Spinner>
      </div>
      <span className="syn-jet-materials_title">Checklist</span>
      <div className="syn-jet-materials_list">{renderList({ arr: listCheckboxes, updateChecklist })}</div>
    </div>
  );
};
