export const CHEMICAL_TYPES = {
  product: 'Product',
  reagent: 'Reagent',
  reactant: 'Reactant',
  expectedIntermediate: 'Expected Intermediate',
  sideproduct: 'Side Product',
  quenching: 'Quenching',
};

export const SHEDULER_REJECTIONS_REASONS = [
  { label: 'Experiment duration is too long', value: 'Experiment duration is too long' },
  {
    label: 'Experiment contains a chemical compatibility issue',
    value: 'Experiment contains a chemical compatibility issue',
  },
  { label: 'Required hardware is unavailable', value: 'Required hardware is unavailable' },
  { label: 'Other', value: 'Other' },
];

export const EXECUTION_REJECTIONS_REASONS = [
  { label: 'Insufficient reactant/ reagent', value: 'Insufficient reactant/ reagent' },
  {
    label: 'Reactant/ reagent issue (e.g., solubility, purity)',
    value: 'Reactant/ reagent issue (e.g., solubility, purity)',
  },
  { label: 'Pump failure', value: 'Pump failure' },
  { label: 'CityScape hardware failure', value: 'CityScape hardware failure' },
  { label: 'Analytics failure', value: 'Analytics failure' },
  { label: 'Contaminated flow path', value: 'Contaminated flow path' },
  { label: 'Other', value: 'Other' },
];

export const TYPES_EVENTS = [
  { label: 'Unscheduled experiments', value: 'exp' },
  { label: 'Maintenance list', value: 'maintenance' },
];

export const DISABLED_MINUTES = [
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
];

export const SYNJET_SHEDULER_REJECTIONS_REASONS = [
  { label: 'Reactant/reagent unstable', value: 'Reactant/reagent unstable' },
  { label: 'Reactant/reagent/solvent incompatibility issue', value: 'Reactant/reagent/solvent incompatibility issue' },
  {
    label: 'Reactant/reagent/solvent incompatible with hardware',
    value: 'Reactant/reagent/solvent incompatible with hardware',
  },
  { label: 'Conditions incompatible with hardware', value: 'Conditions incompatible with hardware' },
  { label: 'Other', value: 'Other' },
];

export const REJECTION_REASONS_BATCH = [
  { label: 'Reactant/reagent unavailable', value: 'Reactant/reagent unavailable' },
  { label: 'Reactant/reagent unstable', value: 'Reactant/reagent unstable' },
  { label: 'Reactant/reagent solubility issue', value: 'Reactant/reagent solubility issue' },
  { label: 'Reactant/reagent/solvent incompatibility issue', value: 'Reactant/reagent/solvent incompatibility issue' },
  {
    label: 'Reactant/reagent/solvent incompatible with hardware',
    value: 'Reactant/reagent/solvent incompatible with hardware',
  },
  { label: 'Insufficient time to complete experiment', value: 'Insufficient time to complete experiment' },
  { label: 'Hardware failure', value: 'Hardware failure' },
  { label: 'Analytical instrument failure', value: 'Analytical instrument failure' },
  { label: 'Other', value: 'Other' },
];

export const MANUAL_EXECUTION_REJECTIONS_REASONS = [
  { label: 'Reactant/reagent unavailable', value: 'Reactant/reagent unavailable' },
  { label: 'Reactant/reagent/solvent incompatibility issue', value: 'Reactant/reagent/solvent incompatibility issue' },
  { label: 'Insufficient time to complete experiment', value: 'Insufficient time to complete experiment' },
  { label: 'Equipment unavailable', value: 'Equipment unavailable' },
  { label: 'Other', value: 'Other' },
];

export const MANUAL_SCHEDULER_REJECTIONS_REASONS = [
  { label: 'Reactant/reagent unstable', value: 'Reactant/reagent unstable' },
  { label: 'Reactant/reagent/solvent incompatibility issue', value: 'Reactant/reagent/solvent incompatibility issue' },
  {
    label: 'Reactant/reagent/solvent incompatible with equipment',
    value: 'Reactant/reagent/solvent incompatible with equipment',
  },
  { label: 'Conditions incompatible with equipment', value: 'Conditions incompatible with equipment' },
  { label: 'Other', value: 'Other' },
];

export const MIN_VIALS_COPY = 1;
export const MAX_VIALS_COPY = 48;
