import './ColumnResizer.styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { COLUMN_RESIZER_DRAG_AREA_WIDTH, COLUMN_RESIZER_INDICATOR_ATTRIBUTE, MIN_COLUMN_WIDTH } from '../constants';
import { useColumnResizerContext } from '../hooks/useColumnResizerController';
import { useDndContext } from '../hooks/useDndController';
import { useSelectableContext } from '../hooks/useSelectableController';

export const ColumnResizerDragArea = ({ children, columnIndex }) => {
  const { onMouseLeave, onMouseEnter, isColumnResizerContext, onMouseMoveArea } = useColumnResizerContext();
  const { dragColumnIndex } = useDndContext() || {};

  if (!isColumnResizerContext) {
    return children;
  }
  return (
    <>
      {children}
      <div
        style={{
          display: dragColumnIndex ? 'none' : 'block',
          left: -COLUMN_RESIZER_DRAG_AREA_WIDTH / 2,
          width: COLUMN_RESIZER_DRAG_AREA_WIDTH,
        }}
        className="column-resizer"
        onMouseLeave={onMouseLeave}
        onMouseMove={event => onMouseMoveArea({ event, columnIndex: columnIndex - 1 })}
        onMouseEnter={event => onMouseEnter({ event, columnIndex: columnIndex - 1 })}
      />
    </>
  );
};

export const ColumnResizerIndicator = ({
  tableRef,
  columns,
  updateColumnWidth,
  tableRefController,
  scrollAreaHeight,
}) => {
  const [leftOffset, setLeftOffset] = useState(-1);
  const {
    isColumnResizerContext,
    leftOffset: initialLeftOffset,
    columnIndex,
    setIsMouseDown,
    isMouseDown,
    reset,
  } = useColumnResizerContext();

  useEffect(() => {
    setLeftOffset(initialLeftOffset);
  }, [initialLeftOffset]);

  const minLeftOffset = useMemo(() => {
    if (!columnIndex || !isMouseDown) {
      return {};
    }
    return columns.slice(0, columnIndex).reduce((prev, curr) => curr.width + prev, 0) + MIN_COLUMN_WIDTH;
  }, [columns, columnIndex, isMouseDown]);

  const getLeftOffset = () => {
    if (minLeftOffset && leftOffset < minLeftOffset) {
      return minLeftOffset;
    }
    return leftOffset;
  };

  const onMouseDown = () => {
    setIsMouseDown(true);
  };

  const onMouseUp = () => {
    const newWidth = getLeftOffset() - minLeftOffset + MIN_COLUMN_WIDTH;
    const targetColumnData = columns[columnIndex];
    updateColumnWidth({
      columnIndex: targetColumnData.actualIndex,
      width: newWidth,
    });
    if (minLeftOffset < leftOffset) {
      reset({
        leftOffset,
      });
    } else {
      reset();
    }
    tableRefController.current.resetAfterColumnIndex(columnIndex, true);
  };

  const updateLeftOffset = event => {
    if (columnIndex !== undefined) {
      setLeftOffset(event.currentTarget.scrollLeft + (event.x - event.currentTarget.getBoundingClientRect().left));
    }
  };

  useEffect(() => {
    if (isMouseDown && tableRef.current) {
      tableRef.current.addEventListener('mousemove', updateLeftOffset);
    }

    return () => {
      if (isMouseDown && tableRef.current) {
        tableRef.current.removeEventListener('mousemove', updateLeftOffset);
      }
    };
  }, [isMouseDown, !!tableRef.current]);

  useEffect(() => {
    if (tableRef.current && columnIndex) {
      tableRef.current.addEventListener('mousedown', onMouseDown);
      tableRef.current.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      if (tableRef.current && columnIndex) {
        tableRef.current.removeEventListener('mousedown', onMouseDown);
        tableRef.current.removeEventListener('mouseup', onMouseUp);
      }
    };
  }, [!!tableRef.current, columnIndex, updateLeftOffset, onMouseDown, onMouseUp]);

  if (isColumnResizerContext) {
    return (
      <div
        data-type={COLUMN_RESIZER_INDICATOR_ATTRIBUTE}
        className="column-resizer-indicator"
        style={{
          height: scrollAreaHeight,
          left: getLeftOffset(),
        }}
      />
    );
  }
  return <></>;
};
