import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Popover, Button, DatePicker } from '../../Common';
import { dateMMDDYY, timehhmma } from '../../../constants';
import './style.scss';

const getEndTime = (date, startTime, duration) => {
  if (!date || !startTime || !duration) {
    return 'N/A';
  }

  const experimentStart = moment(
    `${moment(date).format('MM-DD-YYYY')} ${moment(startTime).format('hh:mm a')}`,
    'MM-DD-YYYY hh:mm a'
  );
  return experimentStart.add(duration, 'minutes').format('MM/DD/YYYY hh:mm a');
};

const validateScheduleInfo = data => {
  const result = {
    valid: true,
    errors: { date: '', time: '' },
  };

  if (!data.date) {
    result.valid = false;
    result.errors.date = 'Please enter the date';
  }

  if (!data.time) {
    result.valid = false;
    result.errors.time = 'Please enter the start time';
  }

  if (data.date && data.time) {
    const experimentStart = moment(`${moment(data.date).format('MM-DD-YYYY')} ${moment(data.time).format('hh:mm a')}`);

    if (moment().isAfter(experimentStart)) {
      result.valid = false;
      result.errors.time = 'Experiment cannot be scheduled in the past';
    }
  }

  return result;
};

export const SchedulePopover = ({ visible, content, children, onVisibleChange }) => {
  return (
    <Popover
      className="scheduling-popover"
      visible={visible}
      content={content}
      placement="right"
      trigger="click"
      onVisibleChange={onVisibleChange}
    >
      {children}
    </Popover>
  );
};
