import React, { useState } from 'react';
import { Popup, openNotification } from 'components/Common';
import { manageBatch } from 'store/scheduling/scheduling.actions';
import { useDispatch } from 'react-redux';

export const RemoveFromBatchPopup = ({ open, handleCancel, experimentsLength, batch, experiment, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const removeFromBatchConfirm = () => {
    setLoading(true);
    dispatch(manageBatch({ data: { uuid: batch.uuid, experiment: experiment.uuid }, type: 'deleteExperiment' }))
      .then(resp => {
        onSuccess();
        openNotification('Experiment was removed from the batch');
      })
      .catch(err => {
        err.errors.forEach(err => {
          openNotification('', err.message);
        });
      })
      .finally(() => {
        setLoading(false);
        handleCancel();
      });
  };

  return (
    <Popup
      title="Remove from batch"
      open={open}
      handleCancel={handleCancel}
      textSubmit="Confirm"
      handleSubmit={removeFromBatchConfirm}
      loading={loading}
    >
      {experimentsLength === 1
        ? 'This is the one experiment in the batch. This action will unbatch Batch ' +
          batch?.batchId +
          ' and return experiment into queue.'
        : 'Do you want to remove experiment from batch? '}
    </Popup>
  );
};
