import { PROJECT_TYPES } from '../constants';
import { EQUIPMENT_TYPES, ALL_OPTIONS, GLASSWARE_OPTIONS } from 'constants/manualProcess';
import {
  getOptimizationColumnsAndReactions,
  getLibraryColumnsAndReactions,
} from 'components/ManualLabProcessBuilder/ManualReactionsCollapse/reactionsTableHelpers';
import { decodeURIComponentSafe } from 'utils';

export const parseProcessBuilderDataToBackend = (data, description = '', processType) => {
  var parsedData = {
    process_steps: [],
    equipments: [],
    inputs: [],
    glasswares: [],
    compounds: [],
    description: description,
  };
  const { reactions } =
    processType === PROJECT_TYPES.OPTIMIZATION
      ? getOptimizationColumnsAndReactions(data)
      : processType === PROJECT_TYPES.PRODUCTION
      ? { reactions: [{}] }
      : getLibraryColumnsAndReactions(data);

  let variableInput = null;
  data.forEach(dataItem => {
    if (!!dataItem.inputs.find(i => i.variable)) variableInput = dataItem.inputs.find(i => i.variable);
  });

  data.forEach((dataItem, dataItemIndex) => {
    parsedData.process_steps.push({
      step: [
        {
          equipment: dataItem.equipment?.name || null,
          inputs: dataItem.inputs?.map(i => i.name) || [],
          glassware: dataItem.glassware?.name || null,
        },
      ],
    });
    if (dataItem.equipment) {
      let { properties, compounds } = getEquipmentProperties(dataItem.equipment, processType, reactions, variableInput);
      parsedData.equipments.push({
        name: dataItem.equipment.name,
        type: dataItem.equipment.otherType || EQUIPMENT_TYPES[dataItem.equipment.type].beName,
        pressure: dataItem.equipment.pressure,
        atmosphere: dataItem.equipment.atmosphereAdditional || dataItem.equipment.atmosphere,
        properties,
      });
      if (compounds.length) parsedData.compounds = [...parsedData.compounds, ...compounds];
    }
    if (dataItem.inputs.length) {
      dataItem.inputs.forEach(input => {
        let isNotVariableInput = !!variableInput && variableInput.name !== input.name;

        let { properties, compounds } = getInputProperties(input, processType, reactions, isNotVariableInput);
        parsedData.inputs.push({
          name: input.name,
          is_variable: !!input.variable,
          properties: properties,
        });
        if (compounds.length) parsedData.compounds = [...parsedData.compounds, ...compounds];
      });
    }
    if (dataItem.glassware) {
      if (dataItem.glassware.otherType) {
        dataItem.glassware.type = dataItem.glassware.otherType;
        delete dataItem.glassware.otherType;
      }
      parsedData.glasswares.push({
        ...dataItem.glassware,
      });
    }
  });
  // set unique compounds
  let compounds = [];
  parsedData.compounds.forEach(cpd => {
    if (cpd && !compounds.find(c => c.compound_id === cpd.compound_id)) compounds.push(cpd);
  });
  compounds.map(compound => {
    if (!compound.compatibility) delete compound.compatibility;
  });
  parsedData.compounds = compounds;
  return parsedData;
};

const getEquipmentProperties = (equipment, processType, reactions, variableInput) => {
  let properties = [],
    compounds = [];

  const { temperatures, times } = equipment;

  let longArray = [...times],
    smallArray = [...temperatures],
    longTimes = true;
  if (temperatures.length >= times.length) {
    longArray = [...temperatures];
    smallArray = [...times];
    longTimes = false;
  }
  equipment.properties.forEach(property => {
    longArray.forEach((temp, tempIndex) => {
      if (processType === PROJECT_TYPES.OPTIMIZATION) {
        smallArray.forEach((time, timeIndex) => {
          if (property.sideproducts) compounds = [...compounds, ...property.sideproducts];
          if (property.products) compounds = [...compounds, ...property.products];
          if (
            !properties.find(prop => {
              return (
                prop.temperature === +temperatures[longTimes ? timeIndex : tempIndex] &&
                prop.time === +times[longTimes ? tempIndex : timeIndex] &&
                prop.product === (property.products ? property.products[0]?.compound_id : null) &&
                prop.sideproducts?.filter(sp => property.sideproducts.find(psp => psp.compound_id === sp.compound_id))
                  .length === prop.sideproducts?.length
              );
            })
          )
            properties.push({
              product: property.products ? property.products[0]?.compound_id : null,
              sideproducts: property.sideproducts ? property.sideproducts.map(i => i.compound_id) : [],
              temperature: +temperatures[longTimes ? timeIndex : tempIndex],
              time: +times[longTimes ? tempIndex : timeIndex],
            });
        });
      } else {
        if (
          !properties.find(prop => {
            return (
              prop.temperature === +temperatures[tempIndex] &&
              prop.time === +times[tempIndex] &&
              prop.product === (property.products ? property.products[0]?.compound_id : null) &&
              prop.sideproducts?.filter(sp => property.sideproducts.find(psp => psp.compound_id === sp.compound_id))
                .length === prop.sideproducts?.length
            );
          })
        )
          if (property.sideproducts) compounds = [...compounds, ...property.sideproducts];
        if (property.products) compounds = [...compounds, ...property.products];
        properties.push({
          product: property.products ? property.products[0]?.compound_id : null,
          sideproducts: property.sideproducts ? property.sideproducts.map(i => i.compound_id) : [],
          temperature: +temperatures[tempIndex],
          time: +times[tempIndex],
        });
      }
    });
  });

  let duplicateCount = reactions.length / properties.length;
  let duplicatedProperties = [];

  for (let i = 0; i < duplicateCount; i++) {
    duplicatedProperties = [...duplicatedProperties, ...properties];
  }
  let indexCounter = processType === PROJECT_TYPES.OPTIMIZATION ? 0 : equipment.times.length || 1;
  let counter = 0;
  const propertiesIndexes = [...reactions].map((r, rIndex) => {
    let equipmentIndex = equipment.name.substr(equipment.name.indexOf('Equipment ') + 10);
    const equipmentName = 'E' + (+equipmentIndex - 1);

    let index = (r[equipmentName]?.equipmentPropertyIndex || 0) + counter;
    counter += indexCounter;
    if (counter >= reactions.length) counter = 0;
    return index;
  });
  duplicatedProperties = sortEquipmentPropertiesToBe(duplicatedProperties, propertiesIndexes);

  return { properties: duplicatedProperties, compounds };
};

const getInputProperties = (input, processType, reactions, isNotVariableInput) => {
  let properties = [],
    compounds = [];

  input.properties.forEach(property => {
    if (!property.compound) {
      compounds.push(property.solvent);
      properties.push({
        type: 'Solvent',
        reagent_type: null,
        compound: null,
        mass: null,
        limiting_reagent: !!property.limiting,
        equivalents: null,
        density: null,
        volume: null,
        moles: null,
        concentration: null,
        solvent: property.solvent.compound_id || null,
        solvent_volume: property.solvent_volume || null,
      });
    } else
      property.equivalents.forEach((equivalent, index) => {
        if (property.compound) compounds.push(property.compound);
        if (property.solvent) compounds.push(property.solvent);
        if (isNotVariableInput) index = 0;
        properties.push({
          type: capitalize(property.chemical_type),
          reagent_type: property.reagent_type ? capitalize(property.reagent_type) : null,
          compound: property.compound?.compound_id || null,
          mass: property.mass[index],
          limiting_reagent: !!property.limiting,
          equivalents: !!property.limiting ? 1 : equivalent ? equivalent : null,
          density: property.density || null,
          volume: property.volume[index] || null,
          moles: property.moles[index],
          concentration: property.concentration || null,
          solvent: property.solvent?.compound_id || null,
          solvent_volume: property.solvent_volume || null,
        });
      });
  });

  let duplicateCount = reactions.length / properties.length;
  let duplicatedProperties = [];

  for (let i = 0; i < duplicateCount; i++) {
    duplicatedProperties = [...duplicatedProperties, ...properties];
  }
  const propertiesIndexes = [...reactions].map(r => {
    const inputName = 'I' + input.name.substr(input.name.indexOf('Input ') + 6);
    return r[inputName]?.inputPropertyIndex || 0;
  });
  duplicatedProperties = sortInputPropertiesToBe(duplicatedProperties, propertiesIndexes);

  return { properties: duplicatedProperties, compounds };
};

const sortInputPropertiesToBe = (properties, indexes) => {
  return indexes.map((indexKey, indexNumber) => {
    return {
      ...properties[indexKey],
      pb_property: indexNumber,
    };
  });
};

const sortPropertiesFromBe = properties => {
  return properties.sort((a, b) => {
    return a.pb_property - b.pb_property;
  });
};

const sortEquipmentPropertiesToBe = (properties, indexes) => {
  return indexes.map((indexKey, indexNumber) => {
    return {
      ...properties[indexKey],
      pb_property: indexNumber,
    };
  });
};

const capitalize = text => text.charAt(0).toUpperCase() + text.slice(1);

export const parseProcessBuilderDataFromBackend = (definition, processType) => {
  let { process_steps, equipments, inputs, glasswares, compounds, description } = definition.equipments
    ? definition
    : JSON.parse(definition);
  let parsedData = [];

  const variableInput = inputs.find(i => !!i.is_variable);
  process_steps.forEach(step => {
    step.step.forEach(stepItem => {
      parsedData.push({
        equipment: stepItem.equipment
          ? setEquipment(stepItem.equipment, equipments, compounds, processType, variableInput)
          : null,
        glassware: stepItem.glassware ? setGlassware(stepItem.glassware, glasswares, compounds) : null,
        inputs: setInputs(stepItem.inputs, inputs, compounds, processType),
      });
    });
  });
  return { parsedData, description: decodeURIComponentSafe(description) };
};

const setEquipment = (equipment, allEquipments, allCompounds, processType, variableInput) => {
  let foundedEquipment = allEquipments.find(i => i.name === equipment);
  let { times, temperatures, properties } = setEquipmentPropertiesToThePB(
    foundedEquipment.properties,
    allCompounds,
    processType,
    variableInput
  );

  let type = Object.keys(EQUIPMENT_TYPES).find(key => EQUIPMENT_TYPES[key].beName === foundedEquipment.type);

  return {
    name: equipment,
    type: type ? type : 'other',
    otherType: !type ? foundedEquipment.type : null,
    pressure: foundedEquipment.pressure,
    atmosphere: ALL_OPTIONS[foundedEquipment.atmosphere]?.value || 'other',
    atmosphereAdditional: !ALL_OPTIONS[foundedEquipment.atmosphere] ? foundedEquipment.atmosphere : null,
    temperatures,
    times,
    properties: sortPropertiesFromBe(properties),
  };
};

const setGlassware = (glassware, allGlasswares, allCompounds) => {
  let foundedGlassware = allGlasswares.find(i => i.name === glassware);
  return {
    type: GLASSWARE_OPTIONS.find(i => i.value === foundedGlassware.type)?.value || 'other',
    otherType: GLASSWARE_OPTIONS.find(i => i.value === foundedGlassware.type) ? null : foundedGlassware.type,
    size: foundedGlassware.size,
  };
};

const setInputs = (inputs, allInputs, allCompounds, processType) => {
  return inputs.map(input => {
    let foundedInput = allInputs.find(i => i.name === input);
    const variableInput = allInputs.find(i => i.is_variable);
    const variableInputProps = variableInput
      ? setInputPropertiesToThePB(variableInput.properties, allCompounds, processType, true, [])
      : [];
    return {
      name: input,
      variable: !!foundedInput.is_variable,
      properties: sortPropertiesFromBe(
        setInputPropertiesToThePB(
          foundedInput.properties,
          allCompounds,
          processType,
          foundedInput.is_variable,
          variableInputProps
        )
      ),
    };
  });
};

const setEquipmentPropertiesToThePB = (props, compounds, processType, variableInput) => {
  let temperatures = props.map(p => p.temperature),
    times = props.map(p => p.time),
    properties = [];

  props.forEach(beProperty => {
    if (
      !properties.find(accumulatedProperty => {
        return (
          (accumulatedProperty.products[0]?.compound_id === beProperty.product ||
            (!beProperty.product && !accumulatedProperty.products[0]?.compound_id)) &&
          (!beProperty.sideproducts?.length ||
            (beProperty.sideproducts?.length &&
              beProperty.sideproducts?.filter(sp => {
                return !!accumulatedProperty.sideproducts?.find(psp => psp?.compound_id === sp);
              })))
        );
      })
    ) {
      let product = compounds.find(i => i?.compound_id === beProperty?.product);
      properties.push({
        products: product ? [product] : [],
        sideproducts: beProperty.sideproducts?.map(sp => compounds.find(i => i?.compound_id === sp)) || [],
      });
    }
  });
  if (!variableInput) {
    properties = [properties[0]];
  }
  if (processType === PROJECT_TYPES.OPTIMIZATION) {
    temperatures = getUniqueValues(temperatures);
    times = getUniqueValues(times);
    properties = [properties[0]];
  } else {
    times = [];
    temperatures = [];
    props.forEach(property => {
      let currentIndex = times.findIndex(time => time === property.time);
      if (
        currentIndex &&
        (times[currentIndex] !== property.time || temperatures[currentIndex] !== property.temperature)
      ) {
        times.push(property.time);
        temperatures.push(property.temperature);
      }
    });
  }

  return {
    temperatures: temperatures.length ? temperatures : [null],
    times: times.length ? times : [null],
    properties: properties.length ? properties : [{ products: [], sideproducts: [] }],
  };
};

const setInputPropertiesToThePB = (properties, compounds, processType, isVariable, variableInputProps = []) => {
  var props = [];
  properties.forEach((property, propertyIndex) => {
    let chemical_type = property.type.toLowerCase();
    if (
      (property.solvent &&
        !props.find(
          i => i.solvent?.compound_id === property.solvent && i.solvent_volume === property.solvent_volume
        )) ||
      (property.compound &&
        !props.find(
          i =>
            i.compound?.compound_id === property.compound &&
            i.mass[0] === property.mass &&
            i.equivalents[0] === property.equivalents
        )) ||
      (processType === PROJECT_TYPES.LIBRARY_GENERATION &&
        !isVariable &&
        variableInputProps.length &&
        variableInputProps.length >= propertyIndex)
    )
      chemical_type === 'solvent'
        ? props.push({
            chemical_type,
            reagent_type: property.reagent_type?.toLowerCase(),
            solvent: compounds.find(i => i.compound_id === property.solvent),
            solvent_volume: property.solvent_volume,
            mw: 0,
          })
        : props.push({
            chemical_type,
            reagent_type: property.reagent_type?.toLowerCase(),
            compound: compounds.find(i => i.compound_id === property.compound),
            mw: 0,
            solvent: property.solvent ? compounds.find(i => i.compound_id === property.solvent) : null,
            solvent_volume: property.solvent_volume || null,
            concentration: property.concentration,
            mass: [property.mass],
            moles: [property.moles],
            volume: [property.volume],
            density: property.density,
            limiting: property.limiting_reagent,
            equivalents: [property.equivalents],
          });
  });
  if (processType === PROJECT_TYPES.LIBRARY_GENERATION && !isVariable) {
    props =
      props[0].chemical_type === 'solvent'
        ? [{ ...props[0] }]
        : [
            {
              ...props[0],
              equivalents: props.map(p => p.equivalents[0]),
            },
          ];
  }
  if (processType === PROJECT_TYPES.OPTIMIZATION) {
    props.map(prop => {
      if (prop.compound && properties.find(property => property.compound === prop.compound.compound_id)) {
        let foundedProp = properties.filter(property => property.compound === prop.compound.compound_id);
        const uniqueIndexes = [];
        prop.equivalents = foundedProp.map(i => i.equivalents);
        prop.mass = foundedProp.map(i => i.mass);
        prop.moles = foundedProp.map(i => i.moles);
        prop.volume = foundedProp.map(i => i.volume);
        let indexes = [];
        prop.equivalents.forEach((equivalent, equivalentIndex) => {
          let indexToFilter = foundedProp.findIndex(
            (foundedPropItem, foundedPropItemIndex) =>
              foundedPropItemIndex > equivalentIndex &&
              !indexes.includes(foundedPropItemIndex) &&
              foundedPropItem.equivalents === equivalent &&
              foundedPropItem.mass === prop.mass[equivalentIndex] &&
              foundedPropItem.moles === prop.moles[equivalentIndex]
          );
          if (indexToFilter > -1) indexes.push(indexToFilter);
        });

        prop.equivalents = prop.equivalents.filter((e, _idx) => !indexes.includes(_idx));
        prop.mass = prop.mass.filter((e, _idx) => !indexes.includes(_idx));
        prop.moles = prop.moles.filter((e, _idx) => !indexes.includes(_idx));
        prop.volume = prop.volume.filter((e, _idx) => !indexes.includes(_idx));
      }
      return prop;
    });
    return [props[0]];
  }

  return props;
};

const getUniqueValues = arr => {
  let result = [];

  for (let str of arr) {
    if (!result.includes(str)) {
      result.push(str);
    }
  }

  return result;
};
