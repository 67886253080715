import { http } from '../../utils/http';
import * as actionsTypes from './processbuilder.actionTypes';
import {
  queryReactorsTypes,
  queryGetPumpTypes,
  mutationCreateProcess,
  mutationUpdateProcessDefinition,
  mutationUpdateTheLockState,
  queryGetPumpKeys,
} from '../graphql';
import { objectToGraphqlString, parseJSONErrors } from '../../utils';

const setReactorsTypes = payload => ({ type: actionsTypes.SET_REACTORS_TYPES, payload });
const setPumpTypes = data => ({ type: actionsTypes.SET_PUMP_TYPES, data });
export const setValidationErrors = data => ({ type: actionsTypes.SET_VALIDATION_ERRORS, data });
export const setValidationWarnings = data => ({ type: actionsTypes.SET_VALIDATION_WARNINGS, data });

export const getReactorsTypes = noSave => dispatch => {
  return http(queryReactorsTypes('send')).then(resp => {
    const proccedData = queryReactorsTypes('get', resp);
    if (!noSave) dispatch(setReactorsTypes(proccedData));
    return proccedData;
  });
};

export const getPumpTypes = (data, search = '') => dispatch => {
  return http(queryGetPumpTypes('send', data, search)).then(resp => {
    return dispatch(setPumpTypes(queryGetPumpTypes('get', resp)));
  });
};

export const getPumpKeys = (search = '', system, maxFlowRate) => dispatch => {
  return http(queryGetPumpKeys(search, system, maxFlowRate)).then(resp => {
    return resp.allPumps.results.map(pump => ({ label: pump.key, value: pump.key }));
  });
};

export const createProcessFromScratch = data => dispatch => {
  return http(mutationCreateProcess(data.uuid ? 'clone' : 'send', data))
    .then(resp => {
      return mutationCreateProcess('get', resp);
    })
    .catch(err => {
      return { errors: err };
    });
};

export const updateProcessDefinition = (data, validate) => dispatch => {
  dispatch(setValidationErrors([]));
  dispatch(setValidationWarnings([]));
  return http(mutationUpdateProcessDefinition(data, validate)).then(resp => {
    if (resp.updateProcessDefinition.errors && validate) {
      let err = parseJSONErrors(resp.updateProcessDefinition.errors);
      dispatch(setValidationErrors(err));
    }
    if (resp.updateProcessDefinition.warnings && validate) {
      let warnings = [];
      resp.updateProcessDefinition.warnings.map(e => {
        if (e.messages.length) warnings = [...warnings, ...e.messages];
      });
      dispatch(setValidationWarnings(warnings));
    }
    return resp;
  });
};

export const updateTheLockState = data => {
  return http(mutationUpdateTheLockState(data));
};

export const createProcessFromRoute = data => dispatch => {
  return http(mutationCreateProcess('send', data))
    .then(resp => {
      return mutationCreateProcess('get', resp);
    })
    .catch(err => {
      return { errors: err };
    });
};

export const saveManualProcess = (data, validate) => dispatch => {};

export const lockManualProcess = (lockUuid, action = 'locked') => dispatch => {
  return http(mutationUpdateTheLockState({ uuid: lockUuid, state: action }));
};
