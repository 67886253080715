import createReducer from '../createReducer.js';
import * as actionsTypes from './experiment.actionTypes';
import { useState } from 'react';

const initialState = {
  allExperimtns: {},
  experiments: [],
  notes: [],
  creationNote: [],
  updateNote: [],
  dataStepTwo: null,
  pausedExperiment: null,
  inProgressExperiment: null,
  endExperiment: null,
  appendedResult: null,
  deviceOnline: false,
  pumpsOnline: [],
  reactorsOnline: [],
  connectivity: false,

  valueRange: [0, 0],
  configCharts: [],
  dataCharts: null,
  updateDataChart: false,
  startPoint: null,
  filteredData: null,
  loading: true,

  listMaintenance: null,
  loadingUpdateUnivariateData: false,

  isMuchData: false,
};

function updateUnivariateData(state, { payload }) {
  return { ...state, ...payload };
}

function setExperimentsList(state, data) {
  return { ...state, experiments: data.payload };
}

function setNotesList(state, data) {
  return { ...state, notes: data.payload };
}

const setNoteCreation = (state, data) => ({
  ...state,
  creationNote: data.payload,
});

const setNoteUpdate = (state, data) => ({
  ...state,
  updateNote: data.payload,
});

const setDataStepTwo = (state, data) => ({
  ...state,
  dataStepTwo: data.payload,
});

const setPausedExperiment = (state, data) => ({
  ...state,
  pausedExperiment: data.payload,
});

const setInProgressExperiment = (state, data) => ({
  ...state,
  inProgressExperiment: data.payload,
});

const setEndExperiment = (state, data) => ({
  ...state,
  endExperiment: data.payload,
});

const setAppendedResult = (state, data) => ({
  ...state,
  appendedResult: data.payload,
});

const setDeviceOnline = (state, action) => ({
  ...state,
  deviceOnline: action.device,
  pumpsOnline: action.pumps,
  reactorsOnline: action.reactors,
});

const setConnectivity = (state, action) => ({
  ...state,
  connectivity: action.connected,
});

const setMaintenances = (state, action) => ({
  ...state,
  listMaintenance: action.payload,
});

const experimentReducer = createReducer(initialState, {
  [actionsTypes.SET_EXPERIMENTS_LIST]: setExperimentsList,
  [actionsTypes.SET_NOTES_LIST]: setNotesList,
  [actionsTypes.SET_NOTE_CREATION]: setNoteCreation,
  [actionsTypes.SET_NOTE_UPDATE]: setNoteUpdate,
  [actionsTypes.SET_DATA_STEP_TWO]: setDataStepTwo,
  [actionsTypes.SET_PAUSED_PROCESS]: setPausedExperiment,
  [actionsTypes.SET_PAUSED_PROCESS]: setPausedExperiment,
  [actionsTypes.SET_RESUME_PROCESS]: setInProgressExperiment,
  [actionsTypes.SET_END_EXPERIMENT]: setEndExperiment,
  [actionsTypes.SET_APPENDED_RESULTS]: setAppendedResult,
  [actionsTypes.SET_DEVICE_ONLINE]: setDeviceOnline,
  [actionsTypes.SET_CONNECTIVITY]: setConnectivity,

  [actionsTypes.UPDATE_UNIVARIATE_DATA]: updateUnivariateData,
  [actionsTypes.SET_MAINTENANCE]: setMaintenances,
});

export default experimentReducer;
