import { PROJECT_TYPES } from '../../../constants';
import { formatSeconds } from '../../../utils';

const getReaction = (processData, timeOptionIndex, temperatureIndex) => {
  const reaction = {
    include: true,
  };
  let pumpNumber = 1;

  processData.forEach((reactor, reactorIndex) => {
    reactor.pumps.forEach((pump, pumpIndex) => {
      reaction[`P${reactorIndex + 1}${pumpIndex + 1}`] = {
        label: `Q${pumpNumber}${timeOptionIndex + 1}`,
        value: pump.flow_rate[timeOptionIndex],
      };
      pumpNumber++;
    });
    if (reactor.temperatures && reactor.type !== 'liquid') {
      reaction[`RT${reactorIndex + 1}`] = {
        label: reactor.temperatures.length === 1 ? 'T' : `T${temperatureIndex + 1}`,
        value: reactor.temperatures.length === 1 ? reactor.temperatures[0] : reactor.temperatures[temperatureIndex],
      };
    }
    if (reactor.times && reactor.type !== 'liquid') {
      const { min, sec } = formatSeconds(Number(reactor.times[timeOptionIndex]));
      reaction[`Rt${reactorIndex + 1}`] = {
        label: `t${timeOptionIndex + 1}`,
        value: min || sec ? `${min}:${sec < 10 ? `0${sec.toFixed(0)}` : sec.toFixed(0)}` : '',
      };
    }
  });

  return reaction;
};

const getOptimizationReactions = processData => {
  const reactions = [];
  const variableReactor = processData.find(reactor => reactor.variable);
  console.log('processData', processData);
  if (!variableReactor) {
    reactions.push(getReaction(processData, 0, 0));
  } else {
    variableReactor.temperatures.forEach((temperatureIOption, temperatureIndex) => {
      variableReactor.times.forEach((timeOption, timeOptionIndex) => {
        reactions.push(getReaction(processData, timeOptionIndex, temperatureIndex));
      });
    });
  }

  return reactions;
};

const variableLetters = ['A', 'B', 'C', 'D', 'E'];

const getLibraryGenerationReactions = processData => {
  const reactions = [];
  let variablePump = null;
  let variableIndex = 0;
  let variablePumpReactorIndex = 0;

  processData.forEach((reactor, reactorIndex) => {
    reactor.pumps.forEach((pump, index) => {
      if (pump.properties.length > 1 && !variablePump) {
        variablePump = pump;
        variableIndex = index;
        variablePumpReactorIndex = reactorIndex;
      }
    });
  });

  if (!variablePump) {
    return [];
  }
  variablePump.properties.forEach((property, propertyIndex) => {
    const reaction = {
      include: true,
    };
    let pumpNumber = 1;

    processData.forEach((reactor, reactorIndex) => {
      reactor.pumps.forEach((pump, pumpIndex) => {
        if (reactorIndex === variablePumpReactorIndex && variableIndex === pumpIndex) {
          const compounds = [];
          const cur = pump.properties[propertyIndex];
          cur.reagents.forEach(item => {
            compounds.push(item);
          });
          cur.reactants.forEach(item => {
            compounds.push(item);
          });
          reaction[`P${pumpNumber}`] = { label: variableLetters[propertyIndex], compounds };
        } else {
          const compounds = [];
          const cur = pump.properties[0];
          cur.reagents.forEach(item => {
            compounds.push(item);
          });
          cur.reactants.forEach(item => {
            compounds.push(item);
          });
          reaction[`P${pumpNumber}`] = { label: 'FIXED', compounds };
        }
        pumpNumber++;
      });
    });

    reactions.push(reaction);
  });

  return reactions;
};

export const getProcessReactions = (processData, processType) => {
  if (!processData || !processData.length) {
    return [];
  }

  if (processType === PROJECT_TYPES.OPTIMIZATION) {
    return getOptimizationReactions(processData);
  }
  if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
    return getLibraryGenerationReactions(processData);
  }
};
