import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { openNotification } from '../Common';

const PDF_Width = 1684;
const PDF_Height = 1190;
const PADDING_PAGE = 24;
let COUNT = 0;

const deleteExtraNodes = node => {
  Array.from(node.children).forEach(i => {
    if (!i.getAttribute('data-protected')) node.removeChild(i);
  });
};
const deleteTrNewNode = ({ offsetTr, node }) => {
  const tbodyInitialPage = node.querySelector('.ant-table-tbody');
  Array.from(tbodyInitialPage.children).forEach((j, jdx) => {
    if (offsetTr > jdx) tbodyInitialPage.removeChild(j);
  });
};

const deleteTrInitialNode = ({ node }) => {
  const tbodyInitialPage = node.querySelector('.ant-table-tbody');
  const tfooter = node.querySelector('.ant-table-summary');
  if (tfooter) tfooter.remove();
  Array.from(tbodyInitialPage.children)
    .reverse()
    .forEach(j => {
      if (node.offsetHeight > 1190) {
        tbodyInitialPage.removeChild(j);
      }
    });
  return tbodyInitialPage.children.length;
};

const deleteExtraPdfNodes = ({ offsetTr, node }) => {
  const pdfNodes = node.querySelectorAll('[data-pdf-node]');
  Array.from(pdfNodes).forEach((j, jdx) => {
    if (offsetTr > jdx) j.remove();
  });
};

const deletePdfNodes = ({ node }) => {
  const pdfNodes = node.querySelectorAll('[data-pdf-node]');
  const pdfNodesNew = Array.from(pdfNodes)
    .reverse()
    .filter(j => {
      if (node.offsetHeight > 1190) {
        j.remove();
        return false;
      }
      return !!j;
    });
  return pdfNodesNew.length;
};

const cloneCanvases = ({ nodeCloned, node }) => {
  const newCanvases = nodeCloned.querySelectorAll('canvas');
  const oldCanvases = node.querySelectorAll('canvas');
  newCanvases.forEach((canvas, idx) => {
    const context = canvas.getContext('2d');
    context.drawImage(oldCanvases[idx], 0, 0);
  });
};

const processLongPage = nodes => {
  const newNodes = [];
  nodes.forEach(node => {
    if (node.offsetHeight > 1190 && !node.classList.contains('exceptionPage')) {
      let offsetTr = 0;
      const nodeCloned = node.cloneNode(true);
      cloneCanvases({ nodeCloned, node });
      newNodes.push(node);
      node.after(nodeCloned);
      newNodes.push(nodeCloned);
      if (!node.classList.contains('noTable') && !!node.querySelector('.ant-table')) {
        // --------
        offsetTr = deleteTrInitialNode({ offsetTr, node });
        // --------
        deleteExtraNodes(nodeCloned);
        deleteTrNewNode({ offsetTr, node: nodeCloned });
      } else {
        //-----
        offsetTr = deletePdfNodes({ node });
        //-----
        deleteExtraPdfNodes({ offsetTr, node: nodeCloned });
      }
    } else newNodes.push(node);
  });
  return newNodes;
};

const adjustSizeCanvases = ({ node }) => {
  const canvases = node.querySelectorAll('canvas');
  const allWidth = PDF_Width - PADDING_PAGE * 2;
  canvases.forEach(canvas => {
    if (canvas.offsetWidth > allWidth) {
      const K = allWidth / canvas.width;
      const canvasNew = document.createElement('canvas');
      canvasNew.id = canvas.id;
      canvasNew.width = allWidth;
      canvasNew.height = canvas.height * K;
      const context = canvasNew.getContext('2d');
      context.drawImage(canvas, 0, 0, canvasNew.width, canvasNew.height);
      canvas.replaceWith(canvasNew);
    }
  });
};

const adjustSizeBlocks = ({ node }) => {
  const blocks = node.querySelectorAll('[data-adjust-width]');

  blocks.forEach(block => {
    const container = node.querySelector(`[${block.getAttribute('data-adjust-width')}]`);
    if (container.offsetWidth / block.offsetWidth < 1) {
      const K = container.offsetWidth / block.offsetWidth;
      // eslint-disable-next-line no-param-reassign
      block.style.transform = `scale(${K}, ${K})`;
    }
  });
};

const checkLongPages = nodes => {
  COUNT += 1;
  if (COUNT > 40) {
    console.log('COUNT', COUNT);
    return [];
  }
  let valid = true;
  nodes.forEach(node => {
    adjustSizeCanvases({ node });
    adjustSizeBlocks({ node });
    if (node.offsetHeight > 1190 && !node.classList.contains('exceptionPage')) valid = false;
  });
  if (!valid) {
    const newNodes = processLongPage(nodes);
    return checkLongPages(newNodes);
  }
  return nodes;
};

export const generatePdf = ({ ref, callback, name }) => {
  COUNT = 0;
  const nodes = ref.current.querySelectorAll('.page-container');
  if (nodes.length > 40) {
    openNotification('', 'Generated PDF is too big');
    callback();
    return;
  }
  let allPages = [];
  try {
    allPages = checkLongPages(nodes);
    allPages.forEach((item, idx, arr) => {
      const headerPage = item.querySelector('.page-header_pages');
      headerPage.innerText = `Page ${idx + 1} of ${arr.length}`;
    });
    allPages = Array.from(allPages);
  } catch (e) {
    openNotification('', 'Something went wrong');
  }

  Promise.all(
    allPages.map(i =>
      html2canvas(i, {
        fixLigatures: true,
        allowTaint: true,
        scrollX: 0,
        scrollY: 0,
        scale: 3,
      })
    )
  )
    .then(data => {
      const pdf = new jsPDF({ orientation: 'landscape', format: [PDF_Width, PDF_Height], unit: 'pt' });
      const arr2d = data.map(i => {
        i.getContext('2d');
        return i.toDataURL('image/jpeg', 1);
      });

      arr2d.forEach((i, idx, arr) => {
        const el = allPages[idx];
        if (el.classList.contains('exceptionPage') && allPages[idx].offsetHeight > PDF_Height) {
          Array.from(Array(Math.ceil(allPages[idx].offsetHeight / (PDF_Height + 20)))).forEach((j, jdx, jarr) => {
            pdf.addImage(i, 'PNG', 0, -PDF_Height * jdx, PDF_Width, el.offsetHeight, '', 'FAST');
            if (arr.length - 1 !== idx || (arr.length - 1 === idx && jarr.length - 1 !== jdx)) {
              pdf.addPage([PDF_Width, PDF_Height]);
            }
          });
        } else {
          pdf.addImage(i, 'PNG', 0, 0, PDF_Width, PDF_Height, '', 'FAST');
          if (arr.length - 1 !== idx) pdf.addPage([PDF_Width, PDF_Height]);
        }
      });

      pdf.save(name);
    })
    .finally(() => {
      callback();
    });
};
