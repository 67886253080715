import cn from 'classnames';
import React from 'react';
import { DEVICES } from '../../../constants';
import { ProcessPreviewData } from '../../SynJet/SynJetProccess/SynjetViewProcessPopup/ProcessPreviewData';
import { WorkSpace } from '../../ProcessBuilder/WorkSpace/WorkSpace';
import { ManualWorkArea } from '../../ProcessBuilder/WorkSpace/ManualWorkArea';
import { PageContainer } from '../../PdfGenerator/PageContainer';

export const ProcessTab = ({ commonInfo, definition, description, pdfMode }) => {
  if (DEVICES.SYNJET === commonInfo?.process?.device || DEVICES.SYNJETPRO === commonInfo?.process?.device) {
    if (pdfMode) {
      return (
        <PageContainer exceptionPage>
          <div className="synjet-popup process-preview-block">
            <ProcessPreviewData processType={commonInfo?.process?.type} />
          </div>
        </PageContainer>
      );
    }
    return (
      <div className="synjet-popup process-preview-block">
        <ProcessPreviewData
          processType={commonInfo?.process?.type}
          isPro={commonInfo?.process?.device === DEVICES.SYNJETPRO}
        />
      </div>
    );
  }
  if (DEVICES.AUTOSYN === commonInfo?.process?.device) {
    if (definition && pdfMode)
      return <WorkSpace pdfMode previewMode data={definition} processType={commonInfo.process?.type} />;
    if (definition) {
      return (
        <div className="experiment-details_body_work-space">
          <WorkSpace previewMode data={definition} processType={commonInfo.process?.type} />
        </div>
      );
    }
    return <></>;
  }
  if (DEVICES.LAB === commonInfo?.process?.device) {
    if (definition) {
      const workArea = (
        <ManualWorkArea
          pdfMode={pdfMode}
          data={definition}
          process={commonInfo.process}
          processType={commonInfo.process?.type}
          description={description}
          editMode={false}
        />
      );
      if (pdfMode) {
        return workArea;
      }
      return (
        <div className={cn({ 'work-space-view-mode': !!definition })}>
          <div className="view-mode-wrapper">{workArea}</div>
        </div>
      );
    }

    return <></>;
  }
  return <></>;
};
