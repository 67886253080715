import React from 'react';

import { PopoverContent } from '@templates/CustomizationTable/components/PopoverContent';
import { Avatar, Popover, Tooltip } from 'components';

import { FIELD_DATA_TYPES } from '@organisms';

export const getDefaultValue = (type, value) => {
  if (
    type !== FIELD_DATA_TYPES.boolean &&
    (value === null || value === undefined || (value === 'string' && JSON.parse(value) === ''))
  ) {
    return '-';
  }

  const withPopover = options => (
    <Popover trigger="hover" content={<PopoverContent options={options} />}>
      <a className="options-amount">
        {options.length} {`option${options.length > 1 ? 's' : ''}`}
      </a>
    </Popover>
  );

  switch (type) {
    case FIELD_DATA_TYPES.select:
      const selectValue = typeof value === 'string' ? JSON.parse(value) : value;
      return withPopover([selectValue]);
    case FIELD_DATA_TYPES.multiselect:
      const multiSelectValue = typeof value === 'string' ? JSON.parse(value) : value;
      return withPopover(multiSelectValue);
    case FIELD_DATA_TYPES.user:
      try {
        const parsedValue = JSON.parse(value);
        return (
          <Tooltip destroyTooltipOnHide placement="top" title={parsedValue}>
            <div className="user-value-block">
              <Avatar classNameAvatarName="user-avatar-name" size={24} name={parsedValue} />
            </div>
          </Tooltip>
        );
      } catch (e) {
        return value;
      }
    case FIELD_DATA_TYPES.boolean:
      if (value === null || value === undefined) {
        return '-';
      }
      return value && value !== 'false' ? 'True' : 'False';
    default:
      return '-';
  }
};
