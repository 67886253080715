import React, { useEffect, useState } from 'react';
import './style.scss';
import { Spinner } from '../../Common/Spinner';
import { Input, openNotification, Table, Tooltip } from '../../Common';
import cn from 'classnames';
import { SmilesElem } from '../PrepareMaterials/SmilesElem';
import { Switch } from '../../Common/Switch';
import { updateDispenserMaterial, updateInputMaterial } from '../../../store/synjet/synjet.actions';
import { renderList } from '../SynJetExecution/SynJetPrepareMaterials';
import { filterObj } from '../../../utils';

const subOptions = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

const setFixed = value => {
  if (!value) return '';
  const valueSplit = `${value}`.split('.');
  if (valueSplit.length === 2) {
    return value.toFixed(3);
  } else {
    return value + '.000';
  }
};

const checkDataType = row => {
  const isReagent = row?.allData?.type === 'Reagent';
  const isReactant = row?.allData?.type === 'Reactant';
  const isLiquid = row?.allData?.reagent_type === 'Liquid';
  const isSolution = row?.allData?.reagent_type === 'Solution';
  return {
    isReagent: isReagent,
    isReactant: isReactant,
    isLiquid: isLiquid,
    isSolution: isSolution,
  };
};

export const LabPrepareMaterials = ({
  errors,
  setErrors,
  setEdited,
  tableData,
  setTableData,
  listCheckboxes,
  setListCheckboxes,
}) => {
  const [loading, setLoading] = useState(false);

  const validationInput = value => {
    let error = false;
    const val = value.split('.');
    if (val.length === 2) {
      if (val[0].length === 0 || val[0].length === 11) error = true;
      else if (val[1].length === 4) error = true;
    } else if (val[0].length === 11) error = true;

    return error;
  };

  const handleChangeInput = (value, field, index) => {
    if (validationInput(value)) return;
    setTableData(
      tableData.map((i, idx) => {
        if (index === idx) {
          return {
            ...i,
            [field]: { ...i[field], value: value },
            has_sent: i.has_sent ? { ...i.has_sent, value: false } : i.has_sent,
          };
        }
        return i;
      })
    );
    setEdited(true);
    setErrors(errors.filter(i => i.index !== index));
  };
  const handleChangeSwitch = (value, index, rowData) => {
    const errorsNew = [];
    if (!!rowData.solvent_volume.value && +rowData.solvent_volume.value > +rowData.solvent_volume_act.value) {
      errorsNew.push({ index: index, field: 'solvent_volume_act' });
      openNotification('', 'Actual Solvent Volume should be more than Calculated Solvent Volume');
    }
    if (!!rowData.volume_calc.value && +rowData.volume_calc.value > +rowData.volume_act.value) {
      errorsNew.push({ index: index, field: 'volume_act' });
      openNotification('', 'Actual Volume should be more than Calculated Volume');
    }
    if (!!rowData.mass_calc.value && +rowData.mass_calc.value > +rowData.mass_act.value) {
      errorsNew.push({ index: index, field: 'mass_act' });
      openNotification('', 'Actual Mass should be more than Calculated Mass');
    }
    if (errorsNew.length) {
      setErrors([...errors, ...errorsNew]);
      return;
    }
    const data = filterObj({
      uuid: rowData.uuidExp,
      name: rowData.nameInput,
      reactionIndexes: rowData.keys,
      actualMass: +rowData.mass_act.value,
      actualVolume: +rowData.volume_act.value,
      actualSolventVolume: +rowData.solvent_volume_act.value,
    });
    setLoading(true);
    updateInputMaterial({ data })
      .then(() => {
        setTableData(
          tableData.map((i, idx) => {
            if (index === idx)
              return {
                ...i,
                has_sent: { ...i.has_sent, value: value },
              };
            return i;
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateChecklist = (index, data) => {
    setListCheckboxes(listCheckboxes.map((i, idx) => (idx === index ? data : i)));
  };

  const columns = [
    {
      title: '',
      dataIndex: 'order',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn('lab-materials_table_order', row.className)}>{value}</div>,
          props: { rowSpan: row.rowSpan, colSpan: 'order' },
        };
        return obj;
      },
    },
    {
      title: 'Name',
      dataIndex: 'compound',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn(row.className, 'lab-materials_table_compound')}>&nbsp;</div>,
          props: {},
        };
        if (row?.isSolvent) {
          obj.children = (
            <div className="lab-materials_table_compound">
              <span>{value?.compound_name || value?.formula}</span>
              <span>Solvent</span>
            </div>
          );
        } else
          obj.children = (
            <div className="lab-materials_table_compound">
              <SmilesElem id={index} data={value} customOptions={{ width: 150, height: 80 }} displayLoupe={true}>
                <div className="lab-materials_table_type">
                  {row.allData.type} ({row.allData.reagent_type})
                </div>
              </SmilesElem>
            </div>
          );
        obj.props = { rowSpan: row.rowSpan };
        return obj;
      },
    },
    {
      title: 'Solvent',
      dataIndex: 'solvent',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn(row.className, 'lab-materials_table_solvent')}>—</div>,
        };
        if (row?.isSolvent)
          obj.children = <div className={cn(row.className, 'lab-materials_table_solvent')}>&nbsp;</div>;
        else if (value.value) {
          obj.children = (
            <div className={cn(row.className, 'lab-materials_table_solvent')}>
              {value?.value?.compound_name || value?.value?.formula}
            </div>
          );
        }
        obj.props = { rowSpan: row.rowSpan, colSpan: 'solvent' };
        return obj;
      },
    },
    {
      title: '',
      dataIndex: 'variation_index',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: <div className={cn('lab-materials_table_variations', row.className)}>{value}</div>,
        };
        if (row?.isSolvent || !row.useBlueBlocks) obj.children = <div>&nbsp;</div>;
        return obj;
      },
    },
    {
      title: 'Amount (mmol)',
      dataIndex: 'amount',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: (
            <div
              className={cn('lab-materials_table_cell', 'lab-materials_table_cell_amount', row.className, {
                'lab-materials_table_white-back': !row.useBlueBlocks,
              })}
            >
              {value.value}
            </div>
          ),
        };
        if (row?.isSolvent) obj.children = <div className={'lab-materials_table_cell_amount'}>—</div>;
        return obj;
      },
    },
    {
      title: 'Density (g/mL)',
      dataIndex: 'density',
      width: '0',
      render: (value, row, index, ...other) => {
        const dataType = checkDataType(row);
        const obj = {
          children: (
            <div
              className={cn('lab-materials_table_concentration lab-materials_table_cell', row.className, {
                'lab-materials_table_white-back': !row.useBlueBlocks,
              })}
            >
              {!((dataType.isReagent || dataType.isReactant) && dataType.isLiquid) ? '—' : value?.value}
            </div>
          ),
        };
        return obj;
      },
    },
    {
      title: 'MW (g/mol)',
      dataIndex: 'mw',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          children: (
            <div
              className={cn('lab-materials_table_cell', row.className, {
                'lab-materials_table_white-back': row?.isSolvent || !row.useBlueBlocks,
              })}
            >
              {value.value}
            </div>
          ),
        };

        return obj;
      },
    },
    {
      title: 'Concentration (M)',
      dataIndex: 'concentration',
      width: '0',
      render: (value, row, index, ...other) => {
        const dataType = checkDataType(row);
        const obj = {
          children: (
            <div
              className={cn('lab-materials_table_cell', row.className, {
                'lab-materials_table_white-back': !row.useBlueBlocks,
              })}
            >
              {!((dataType.isReagent || dataType.isReactant) && dataType.isSolution) ? '—' : value.value}
            </div>
          ),
        };
        return obj;
      },
    },
    {
      title: 'Calculated',
      children: [
        {
          title: 'Solvent volume (mL)',
          dataIndex: 'solvent_volume',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: (
                <div
                  className={cn('lab-materials_table_cell', row.className, {
                    'lab-materials_table_white-back': row?.isSolvent || !row.useBlueBlocks,
                  })}
                >
                  {Math.ceil(value?.value * 100) / 100 || '—'}
                </div>
              ),
              props: { colspan: 'border-left' },
            };
            return obj;
          },
        },
        {
          title: 'Volume (mL)',
          dataIndex: 'volume_calc',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: (
                <div
                  className={cn('lab-materials_table_cell', row.className, {
                    'lab-materials_table_white-back': row?.isSolvent || !row.useBlueBlocks,
                  })}
                >
                  {setFixed(value?.value) || '—'}
                </div>
              ),
            };
            return obj;
          },
        },
        {
          title: 'Mass (g)',
          dataIndex: 'mass_calc',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: (
                <div
                  className={cn('lab-materials_table_cell', row.className, {
                    'lab-materials_table_white-back': !row.useBlueBlocks,
                  })}
                >
                  {setFixed(value?.value) || '—'}
                </div>
              ),
            };

            if (row?.isSolvent) obj.children = <div>—</div>;
            return obj;
          },
        },
      ],
    },
    {
      title: 'Actual',
      children: [
        {
          title: 'Solvent volume (mL)',
          dataIndex: 'solvent_volume_act',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: <div className={row.className}>&nbsp;</div>,
              props: { colspan: 'border-left' },
            };
            const error = errors?.find(i => index === i.index && i?.field === 'solvent_volume_act');
            obj.children = (
              <div
                className={cn(
                  'lab-materials_table_cell',
                  {
                    'lab-materials_table_white-back': row?.isSolvent || !row.useBlueBlocks,
                  },
                  'lab-materials_table_inputs'
                )}
              >
                <Input
                  disabled={!row?.solvent_volume?.value}
                  regex={/[^\d|.]+/g}
                  value={row.solvent_volume_act?.value}
                  className={cn('', row.className)}
                  onChange={(...args) => handleChangeInput(...args, index)}
                  field="solvent_volume_act"
                  error={error?.field === 'solvent_volume_act'}
                />
              </div>
            );
            return obj;
          },
        },
        {
          title: 'Volume (mL)',
          dataIndex: 'volume_act',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: <div className={row.className}>&nbsp;</div>,
            };
            const error = errors?.find(i => index === i.index && i?.field === 'volume_act');
            obj.children = (
              <div
                className={cn(
                  'lab-materials_table_cell',
                  {
                    'lab-materials_table_white-back': row?.isSolvent || !row.useBlueBlocks,
                  },
                  'lab-materials_table_inputs'
                )}
              >
                <Input
                  disabled={!row?.volume_calc?.value}
                  regex={/[^\d|.]+/g}
                  value={row.volume_act?.value}
                  className={cn('', row.className)}
                  onChange={(...args) => handleChangeInput(...args, index)}
                  field="volume_act"
                  error={error}
                />
              </div>
            );
            return obj;
          },
        },
        {
          title: 'Mass (g)',
          dataIndex: 'mass_act',
          width: '0',
          render: (value, row, index, ...other) => {
            const obj = {
              children: <div className={row.className}>&nbsp;</div>,
            };
            const error = errors?.find(i => index === i.index && i?.field === 'mass_act');
            obj.children = (
              <div
                className={cn(
                  'lab-materials_table_cell',
                  {
                    'lab-materials_table_white-back': row?.isSolvent || !row.useBlueBlocks,
                  },
                  'lab-materials_table_inputs'
                )}
              >
                <Input
                  disabled={!row?.mass_calc?.value}
                  regex={/[^\d|.]+/g}
                  value={row.mass_act?.value}
                  className={cn('', row.className)}
                  onChange={(...args) => handleChangeInput(...args, index)}
                  field="mass_act"
                  error={!!error}
                />
              </div>
            );
            return obj;
          },
        },
      ],
    },
    {
      title: 'Done',
      dataIndex: 'has_sent',
      width: '0',
      render: (value, row, index, ...other) => {
        const obj = {
          props: { colspan: 'border-left' },
          children: (
            <div
              className={cn(
                row.className,
                'lab-materials_table_cell',
                {
                  'lab-materials_table_white-back': row?.isSolvent || !row.useBlueBlocks,
                },
                'lab-materials_table_switch'
              )}
            >
              <Switch checked={row.has_sent?.value} onChange={value => handleChangeSwitch(value, index, row)} />
            </div>
          ),
        };
        return obj;
      },
    },
  ];

  return (
    <div className="lab-materials">
      <span className="lab-materials_title">Solutions</span>
      <div className="lab-materials_table">
        <Spinner loading={loading}>
          <Table
            rowClassName="lab-materials_table_row"
            columns={columns}
            dataSource={tableData}
            sidePadding={false}
            // scroll={{ y: height }}
          />
        </Spinner>
      </div>
      <span className="lab-materials_title">Checklist</span>
      <div className="lab-materials_list">{renderList({ arr: listCheckboxes, updateChecklist: updateChecklist })}</div>
    </div>
  );
};
