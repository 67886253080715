import './style.scss';

import React from 'react';

import no_results from 'dist/images/no-results.svg';

import { Heading3, TextBody16 } from '@atoms';

export const EmptyStateFiltered = ({ message = 'Please try using a different search / filter combination' }) => (
  <>
    <div className="experiments-empty-state">
      <div className="experiments-empty-state_caption">
        <Heading3 className="experiments-empty-state_caption_main-caption">Nothing found</Heading3>
        <TextBody16 className="experiments-empty-state_caption_message">{message}</TextBody16>
      </div>
      <img src={no_results} />
    </div>
  </>
);
