import './SelectableHeaderCell.styles.scss';

import React from 'react';

import cn from 'classnames';

import { Checkbox } from '../../../../components';
import { useSelectableContext } from '../hooks/useSelectableController';

export const SelectableCheckbox = ({ onSelectAll, isAllSelected }) => (
  <Checkbox onChange={onSelectAll} value={isAllSelected} className="selectable-header-cell-checkbox" />
);

export const SelectableHeaderCell = ({ style, className, onSelectAll, isAllSelected }) => (
  <div style={style} className={cn('selectable-header-cell', className)}>
    <SelectableCheckbox isAllSelected={isAllSelected} onSelectAll={onSelectAll} />
  </div>
);
