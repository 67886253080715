import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Input } from 'antd';
import cn from 'classnames';
import SmilesDrawer from 'smiles-drawer';

import { httpSynMolDB } from '../../../../utils';
import { Bird, Search, Select } from '../../../Common';

const options = {
  height: 90,
};

export const SmilesSearcherAutoSin = ({ onChange, onChangeSearchType, value, searchType, searchAction }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <Input.Group className=" smiles-searcher-autosin" compact>
      <Select
        showSearch={false}
        className="smiles-searcher-autosin_searcher_categories"
        onChange={e => {
          onChangeSearchType(e);
        }}
        value={searchType}
        options={[
          { value: 'name', label: 'By name' },
          { value: 'smiles', label: 'By SMILES' },
        ]}
      />
      <Search
        smile={searchType === 'smiles'}
        allowSpecials
        loading={loading}
        placeholder="Search"
        value={value}
        searchAction={searchAction}
        onChange={value => {
          onChange(value);
        }}
      />
    </Input.Group>
  );
};
