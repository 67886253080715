import React, { useState } from 'react';
import { Table as AntdTable } from 'antd';
import './style.scss';
import { Avatar, Pagination } from '../../';
import { DisplayPagination } from '../../../utils/DisplayPagination';

const emptyColumn = {
  title: '',
  dataIndex: '',
  key: '',
  width: 24,
  className: 'ant-table-empty-cell',
  render: () => <div />,
};

export const Table = ({
  onRowClick = () => {},
  dataSource,
  columns,
  rowClassName,
  totalCount,
  current,
  defaultPageSize,
  onChange,
  changeStateTable,
  sidePadding = true,
  ...rest
}) => {
  let columnList;

  if (sidePadding) {
    columnList = [emptyColumn, ...columns, emptyColumn];
  } else {
    columnList = columns;
  }

  return (
    <>
      <AntdTable
        dataSource={dataSource}
        columns={columnList}
        pagination={false}
        onChange={changeStateTable}
        onRow={record => {
          return {
            onClick: e => {
              onRowClick(record, e);
            },
          };
        }}
        rowClassName={rowClassName}
        {...rest}
      />
      {totalCount > 0 && (
        <div className="count-rows">
          <DisplayPagination totalCount={totalCount} current={current} />
          {totalCount > 10 && (
            <Pagination allPages={totalCount} onChange={onChange} current={current} defaultPageSize={10} />
          )}
          <div />
        </div>
      )}
    </>
  );
};
