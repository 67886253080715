import './style.scss';

import React from 'react';

import { DatePicker as AntdDatePicker, TimePicker as AntdTimePicker } from 'antd';
import cn from 'classnames';

const { RangePicker } = AntdDatePicker;

export const DatePicker = ({ error, range, date, time, className, ...props }) => {
  if (date && time) {
    return <AntdDatePicker showTime className={cn('date-picker', className, { 'input-error': error })} {...props} />;
  }
  if (range && time) {
    return <AntdTimePicker.RangePicker className={cn('date-picker', className, { 'input-error': error })} {...props} />;
  }
  if (range) {
    return <RangePicker className={cn('date-picker', className, { 'input-error': error })} {...props} />;
  }

  if (time) {
    return <AntdTimePicker className={cn('date-picker', className, { 'input-error': error })} {...props} />;
  }

  return <AntdDatePicker className={cn('date-picker', className, { 'input-error': error })} {...props} />;
};
