import React from 'react';
import { SJDispenserList } from './SJDispenserList';
import './style.scss';

export const SJDispensingOptions = ({
  dispensingOptions,
  experiments,
  batchId,
  getDetails,
  step,
  errors,
  previewDispensers = false,
  isPro = false,
  isManual = false,
}) => {
  const compounds = dispensingOptions ? dispensingOptions.filter(option => !option.is_normalized) : [];
  const normalizeCompounds = dispensingOptions ? dispensingOptions.filter(option => option.is_normalized) : [];
  return (
    <div className="dispensing-block">
      <h5 className="title">{isManual ? 'Dispense manually' : 'Reactants & Reagents'}</h5>
      <SJDispenserList
        dispenserList={compounds}
        experiments={experiments}
        batchId={batchId}
        getDetails={getDetails}
        step={step}
        errors={errors}
        previewDispensers={previewDispensers}
        isPro={isPro}
        isManual={isManual}
      />
      {!!normalizeCompounds.length && (
        <>
          <h5 className="title">Normalization solvents</h5>
          <SJDispenserList
            dispenserList={normalizeCompounds}
            experiments={experiments}
            offset={compounds.length}
            isNormalize
            batchId={batchId}
            getDetails={getDetails}
            step={step}
            errors={errors}
            previewDispensers={previewDispensers}
            isPro={isPro}
            isManual={isManual}
          />
        </>
      )}
    </div>
  );
};
