import './index.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { openNotification, Popup, Spinner } from 'components';
import { isEqual } from 'lodash';
import { connect, useDispatch } from 'react-redux';

import { PROCESS_TYPES, PROJECT_TYPES } from '../../../constants';
import { getProcess } from '../../../store/autosyn/autosyn.actions';
import { getAvailableAutoSyns } from '../../../store/experiment/experiment.actions';
import {
  calculateExperimentTime,
  checkExperimentNameUnique,
  createExperiment,
  getExperimentInfo,
  setModalClose,
  setProcess,
} from '../../../store/experimentCreation/experimentCreation.action';
import { getReactorsTypes, updateProcessDefinition } from '../../../store/processBuilder/processbuilder.actions';
import { getProcess as getSynjetProcess } from '../../../store/synjet/synjet.actions';
import {
  calculateTotalTime,
  convertTotalTime,
  mapCompoundToAnalyticDataToBack,
} from '../../../utils/experimentHelpers';
import history from '../../../utils/history';
import { PopupEditExperiment } from '../../Execution/PopupEditExperiment/PopupEditExperiment';
import { PopupSubmitExperiment } from '../../Execution/PopupSubmitExperiment/PopupSubmitExperiment';
import { getProcessReactionsAndColumns } from '../../ManualLabProcessBuilder/ManualReactionsCollapse/reactionsTableHelpers';
import { getReactionTableColumns } from '../../ProcessBuilder/ReactionsAccordion/columns';
import { getProcessReactions } from '../../ProcessBuilder/ReactionsAccordion/reactions';
import {
  getExperimentModalFooter,
  getExperimentModalTitle,
  getInitialYields,
  parseReactionsInfo,
  validateAnalyticData,
  validateExperimentInfo,
} from './CreateExperimentHelpers';
import { CreateExperimentModalBody } from './CreateExperimentModalBody';

const initialExperimentData = {
  name: '',
  useAnalytic: 'none',
  analytics: [],
  priority: '0',
  notes: '',
  purification: '',
  theoreticalProdRate: [],
  estimatedTime: null,
  timePerReaction: null,
  timePerReactionHour: null,
  timePerReactionMin: null,
  timePerReactionSec: null,
  reactionsInfo: null,
  analyticalData: [],
  allowVialsCopy: false,
};

const getExperimentDetailsURL = (isSynjet, isSynjetPro, isLab) => {
  if (isSynjet) return 'synjet';
  if (isSynjetPro) return 'synjet_pro';
  if (isLab) return 'lab';
  return 'autosyn';
};

const CreateExperimentModal = ({
  isModalOpen,
  process,
  steps,
  processData,
  experimentInfoLoading,
  experimentCreationLoading,
  showProjectForm,
  reactors,
  isProcessBuilder,
  experiment,
  getExperiment,
  showFirstStepIsLab,
  chooseInstrument,
  isSynjetPro: isSynjetProProps,
  isLab: isLabProps,
  isSynJet: isSynJetProps,
}) => {
  const [experimentStep, setExperimentStep] = useState(0);
  const [experimentData, setExperimentData] = useState(initialExperimentData);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(null);
  const [timeLoading, setTimeLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [availableAnalytics, setAvailableAnalytics] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [project, setProject] = useState(null);
  const [processInstrument, setProcessInstrument] = useState(null);
  const [processID, setProcessID] = useState(null);
  const [reactions, setReactions] = useState([]);
  const [vialsCopies, setVialsCopies] = useState({});
  const [selectedReaction, setSelectedReactions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isSubmitted, setSubmitted] = useState(false);
  const [skipReactionStep, setSkipReactionStep] = useState(false);
  const [moveBack, setMoveBack] = useState(false);
  const [defaultAnalyticalData, setDefaultAnalyticalData] = useState([]);
  const [availableEquipment, setAvailableEquipment] = useState([]);
  const [loadAvailableEquipment, setLoadAvailableEquipment] = useState(false);
  const [errorCopiedVials, setErrorCopiedVials] = useState([]);
  const [initialExpRender, setInitialExpRender] = useState(true);

  const [creationLoaderSubmit, setCreationLoaderSubmit] = useState(false);
  const isSynjetPro = chooseInstrument ? processInstrument === PROCESS_TYPES.SynJetPro.key : isSynjetProProps;
  const isLab = chooseInstrument ? processInstrument === PROCESS_TYPES.Lab.key : isLabProps;
  const isSynJet = chooseInstrument ? processInstrument === PROCESS_TYPES.SynJet.key : isSynJetProps;

  const experimentURL = createdExperiment =>
    showProjectForm
      ? `/${getExperimentDetailsURL(isSynJet, isSynjetPro, isLab)}/details/${createdExperiment}`
      : `/${getExperimentDetailsURL(isSynJet, isSynjetPro, isLab)}/details/${createdExperiment}?parent=${
          isSynJet || isSynjetPro ? process?.process?.uuid : process?.uuid
        }`;

  const getReactions = useCallback(() => {
    if (!process || !isModalOpen) return;
    return getProcessReactions(processData, process.type);
  }, [processData, process]);

  const getColumns = useCallback(() => {
    if (!process) return;
    return getReactionTableColumns(processData, process.type);
  }, [processData, process]);

  const getReactionsAndColumns = useCallback(() => getProcessReactionsAndColumns(processData, process?.type), [
    processData,
    process,
  ]);

  const updateProcessInstrument = async instrument => {
    dispatch(setProcess(null, null, null));
    setExperimentStep(0);
    setExperimentData(initialExperimentData);
    setErrors({});
    setProcessID(null);
    setProject(null);
    setSkipReactionStep(false);
    setSelectedReactions([]);
    setSubmitted(false);
    setInitialExpRender(true);
    setMoveBack(false);
    setProcessInstrument(instrument);
  };
  useEffect(() => {
    if (reactions.length && experiment?.enableVialCopying && !experimentData.allowVialsCopy && initialExpRender) {
      setExperimentData({
        ...experimentData,
        allowVialsCopy: experiment?.enableVialCopying,
      });
      setInitialExpRender(false);
    }
  }, [reactions, experiment, experimentData, initialExpRender]);

  useEffect(() => {
    if (!isModalOpen) return;
    if (!processData) {
      dispatch(getReactorsTypes());
    } else if (isSynJet || isSynjetPro) {
      setMoveBack(false);
    } else if (isLab) {
      if (process?.type !== PROJECT_TYPES.PRODUCTION && !selectedReaction.length) {
        const { columns, reactions } = getReactionsAndColumns();
        if (experiment) {
          const { reaction_indexes: reactionIndexes } = JSON.parse(experiment.reactionsInfo);
          const mappedReactions = reactionIndexes?.length
            ? reactions.map((reaction, reactionIndex) =>
                reactionIndexes.includes(reactionIndex)
                  ? reaction
                  : {
                      ...reaction,
                      include: false,
                    }
              )
            : reactions;
          setReactions(mappedReactions);
        } else {
          setReactions(reactions);
        }
        setColumns(columns);
      }
    } else if (process?.type !== PROJECT_TYPES.PRODUCTION && !selectedReaction.length) {
      const reactions = getReactions();
      const experimentReactions = experiment
        ? JSON.parse(experiment.reactionsInfo)?.reactions?.map(reaction => reaction.index.toString())
        : [];
      const mappedReactions = experimentReactions.length
        ? reactions.map((reaction, reactionIndex) =>
            experimentReactions.includes(reactionIndex.toString())
              ? reaction
              : {
                  ...reaction,
                  include: false,
                }
          )
        : reactions;
      setReactions(mappedReactions);
      setColumns(getColumns());
    }
  }, [processData, isModalOpen, processID, experiment]);

  useEffect(() => {
    if (process?.type !== PROJECT_TYPES.PRODUCTION && !isSynJet && !isSynjetPro) {
      if (reactions?.length < 2) {
        setSkipReactionStep(true);
      }
    }
  }, [reactions]);

  useEffect(() => {
    if (process?.type === PROJECT_TYPES.PRODUCTION) return;
    if (experimentStep === 1 && !moveBack) {
      if (experimentData.estimatedTime && !isSynJet && !isSynjetPro) {
        setExperimentData({
          ...experimentData,
          estimatedTime: null,
        });
      }
      if (reactions) setSelectedReactions(reactions.filter(reaction => reaction.include));
    } else {
      setErrors({});
    }
  }, [experimentStep, process]);

  const handleExperimentTimeCalculation = async synjetData => {
    setTimeLoading(true);
    if (!synjetData) {
      setTimeLoading(false);
      if (!validateExperimentInfo(experimentData, process.type, setErrors, false, true)) return;
    }

    try {
      const { totalTime } = await dispatch(
        calculateExperimentTime({
          process: isSynJet || isSynjetPro ? process?.process?.uuid : process.uuid,
          reactionsInfo: synjetData
            ? JSON.stringify(parseReactionsInfo(synjetData.reactionsInfo))
            : JSON.stringify(parseReactionsInfo(experimentData.reactionsInfo)),
          timePerReaction: experimentData.timePerReaction && +experimentData.timePerReaction,
        })
      );
      if (synjetData) {
        setExperimentData({
          ...synjetData,
          estimatedTime: totalTime,
        });
      } else {
        setExperimentData({
          ...experimentData,
          estimatedTime: totalTime,
        });
      }
    } catch (e) {
      if (synjetData) {
        setExperimentData({
          ...synjetData,
          estimatedTime: null,
        });
      } else {
        setExperimentData({
          ...experimentData,
          estimatedTime: null,
        });
      }
      openNotification(null, e);
    } finally {
      setTimeLoading(false);
    }
  };

  const getReactionsData = () => {
    if (process.type === PROJECT_TYPES.PRODUCTION || skipReactionStep) return [0];
    const selectedReactions = selectedReaction
      .map(reaction => reactions.indexOf(reaction))
      .filter(reaction => reaction !== -1);
    return selectedReactions;
  };

  useEffect(() => {
    if (
      !isModalOpen ||
      !process ||
      (!steps.length && !isSynJet && !isSynjetPro && !isLab) ||
      moveBack ||
      ((isSynJet || isSynjetPro) && experimentStep !== 1) ||
      (!selectedReaction?.length && process.type !== PROJECT_TYPES.PRODUCTION && !skipReactionStep) ||
      (!experimentStep && showProjectForm) ||
      (experimentStep === 1 &&
        !showProjectForm &&
        !isSynJet &&
        !isSynjetPro &&
        (process.type === PROJECT_TYPES.PRODUCTION ||
          (process.type !== PROJECT_TYPES.PRODUCTION && skipReactionStep))) ||
      experimentStep === 2
    ) {
      return;
    }
    const reactionsData = getReactionsData();
    if (!reactionsData?.length) return;
    const infoData = {
      processID: isSynJet || isSynjetPro ? process?.process?.uuid : process.uuid,
      reactions: JSON.stringify(reactionsData),
      isSynJet: isSynJet || isSynjetPro,
    };
    const experimentReactionsData = {};
    if (process.type === PROJECT_TYPES.OPTIMIZATION || isSynJet || isLab || isSynjetPro) {
      reactionsData.forEach(reaction => {
        if (isSynjetPro && experimentData.allowVialsCopy) {
          experimentReactionsData[reaction] = { duplicates: vialsCopies?.[reaction] };
        } else {
          experimentReactionsData[reaction] = {};
        }
      });
    } else {
      reactionsData.forEach(
        reaction =>
          (experimentReactionsData[reaction] = {
            estimated_yields: getInitialYields(steps?.length),
            requested_output: null,
          })
      );
    }
    dispatch(getExperimentInfo(infoData))
      .then(resp => {
        const { experimentAnalyticalTypes, experimentPriorities, experimentTheoreticalRates } = resp;
        setAvailableAnalytics(experimentAnalyticalTypes);
        setPriorities(experimentPriorities.map(priority => ({ label: priority.label, value: priority.key })));
        let existingExperimentData = {};
        if (experiment) {
          const { reactionsInfo, analyticalSetting, name, analyticalType, priority, totalTime } = experiment;
          let { purification } = experiment;
          purification = decodeURIComponent(purification).trim();
          const { reactions } = JSON.parse(reactionsInfo);
          const experimentReactions = reactions ? reactions.map(reaction => reaction.index) : [];
          const existingExperimentReactionsData = reactions
            ? reactions?.reduce((reactionData, reaction) => {
                reactionData[reaction?.index] = {
                  estimated_yields: reaction?.estimated_yields,
                  requested_output: reaction?.requested_output,
                };
                return reactionData;
              }, {})
            : [];
          const existingeExperimentTheoreticalRates = reactions
            ? reactions?.map(reaction => ({
                index: reaction.index,
                value: reaction.theoretical_production_rate,
              }))
            : [];
          /* const mappedAnalyticalData = analyticalSetting
            ? analyticalSetting.map(setting => ({
                ...setting,
                start: moment(setting.start, 'mm sss'),
                end: moment(setting.end, 'mm sss'),
              }))
            : []; */
          const mappedAnalyticalData = analyticalSetting || [];
          setDefaultAnalyticalData(mappedAnalyticalData);
          const experimentReactionInfo =
            !isSynJet && !isSynjetPro && !isLab && isEqual(reactionsData, experimentReactions)
              ? {
                  reactionsInfo: existingExperimentReactionsData,
                  theoreticalProdRate: existingeExperimentTheoreticalRates,
                  estimatedTime: totalTime,
                }
              : {};
          const useAnalytic = isSynJet
            ? analyticalType.length
              ? 'LC-MS'
              : 'other'
            : analyticalType.length
            ? 'use'
            : 'none';
          existingExperimentData = {
            name,
            useAnalytic,
            purification,
            analytics: analyticalType,
            priority: priority.toString(),
            analyticalData: mappedAnalyticalData,
            timePerReaction: reactions?.[0]?.time_per_reaction,
            ...experimentReactionInfo,
          };
          if (isLab) {
            const {
              hours: timePerReactionHour,
              minutes: timePerReactionMin,
              sec: timePerReactionSec,
            } = convertTotalTime(totalTime);
            existingExperimentData = {
              name,
              timePerReactionHour,
              timePerReactionMin,
              timePerReactionSec,
            };
          }
        }
        if (isLab) {
          const newExperimentData = {
            ...experimentData,
            reactionsInfo: experimentReactionsData,
            ...existingExperimentData,
          };
          setExperimentData(newExperimentData);
        } else if (isSynJet || isSynjetPro) {
          const useAnalytic = experimentAnalyticalTypes.find(type => type.value === 'LC-MS' && type.available)
            ? 'LC-MS'
            : 'other';
          const analytics = experimentAnalyticalTypes.find(type => type.value === 'LC-MS' && type.available)
            ? ['LC-MS']
            : [];

          const newExperimentData = {
            ...experimentData,
            reactionsInfo: experimentReactionsData,
            useAnalytic,
            analytics,
            ...existingExperimentData,
          };
          setExperimentData(newExperimentData);
          handleExperimentTimeCalculation(newExperimentData);
        } else {
          setExperimentData({
            ...experimentData,
            theoreticalProdRate: experimentTheoreticalRates,
            reactionsInfo: experimentReactionsData,
            ...existingExperimentData,
          });
        }
      })
      .catch(e => {
        openNotification(null, e);
        dispatch(setModalClose());
      });
  }, [process, steps, experimentStep, selectedReaction, skipReactionStep, experiment, isModalOpen]);

  useEffect(() => {
    if (isSynJet) return;
    if (experimentData.analytics?.length) {
      setExperimentData({
        ...experimentData,
        useAnalytic: 'use',
      });
    }
  }, [experimentData.analytics]);

  useEffect(() => {
    if (isSynJet) {
      if (experimentData.useAnalytic === 'LC-MS') {
        setExperimentData({
          ...experimentData,
          analytics: ['LC-MS'],
        });
      } else {
        setExperimentData({
          ...experimentData,
          analytics: [],
        });
      }
    } else if (experimentData.useAnalytic === 'none') {
      setExperimentData({
        ...experimentData,
        analytics: [],
      });
    }
  }, [experimentData.useAnalytic]);

  useEffect(() => {
    if (!isModalOpen) {
      setExperimentStep(0);
      setExperimentData(initialExperimentData);
      setErrors({});
      setProcessID(null);
      setProject(null);
      setProcessInstrument(null);
      setSkipReactionStep(false);
      setSelectedReactions([]);
      setSubmitted(false);
      setInitialExpRender(true);
    }
  }, [isModalOpen]);

  const handleConfirm = async () => {
    if (experimentData?.allowVialsCopy && !experimentStep) {
      const emptyCopies = Object.entries(vialsCopies).reduce((accum, [key, value]) => {
        if (!value && reactions[+key]?.include) accum.push(+key);
        return accum;
      }, []);
      setErrorCopiedVials(emptyCopies);
      if (emptyCopies.length) {
        openNotification(null, 'The number of vial copies is allowed in the range from 1-48');
        return;
      }
    }

    if (showProjectForm && !experimentStep) {
      if (!project || !processID || errors?.processID || (chooseInstrument && !processInstrument)) {
        const newErrors = {
          processID: processID ? errors?.processID : 'Select at least 1 option to proceed',
          project: project ? null : 'Select at least 1 option to proceed',
        };
        if (chooseInstrument && !processInstrument) {
          newErrors.processInstrument = 'Select at least 1 option to proceed';
        }
        setErrors(newErrors);
        return;
      }
      try {
        setMoveBack(false);
        setConfirmLoading(true);
        const { updateProcessDefinition: validationInfo } = await dispatch(
          updateProcessDefinition(
            {
              uuid: processID,
            },
            true
          )
        );
        if (validationInfo.errors) {
          const errorMessage = validationInfo?.errors?.[0].messages?.[0] || 'Error while validating process';
          setErrors({
            processID: errorMessage,
          });
          return;
        }
      } catch (e) {
        const errorMessage =
          (e?.errors?.[0].message && e?.errors?.[0].message.replace(/'|\[|\]/g, '')) ||
          'Error while validating process';
        setErrors({
          processID: errorMessage,
        });
        return;
      } finally {
        setConfirmLoading(false);
      }
    } else if (
      (((process?.type === PROJECT_TYPES.PRODUCTION && !showProjectForm) ||
        (process?.type !== PROJECT_TYPES.PRODUCTION && skipReactionStep)) &&
        experimentStep === 0) ||
      experimentStep === 1
    ) {
      if (
        !validateExperimentInfo(experimentData, process.type, setErrors, isSynJet || isSynjetPro, isLab) &&
        !isSubmitted
      ) {
        return;
      }
      if (experiment?.name !== experimentData.name) {
        setConfirmLoading(true);
        try {
          const isUniqueName = await dispatch(checkExperimentNameUnique(encodeURIComponent(experimentData.name)));
          if (!isUniqueName && experimentData.name) {
            setErrors({
              name: 'The name already exists',
            });
            return;
          }
        } catch (e) {
          setErrors({
            name: 'Error while validate experiment name',
          });
          return;
        } finally {
          setConfirmLoading(false);
        }
      }
    }
    setExperimentStep(step => step + 1);
    setMoveBack(false);
  };

  const handleBack = () => {
    if (experimentStep === 1 && process.type !== PROJECT_TYPES.PRODUCTION && reactions?.length > 1) {
      setSelectedReactions([]);
    }
    setExperimentStep(step => step - 1);
    setMoveBack(true);
  };

  const handleSaveExperiment = async (isSubmitted, loadingCallback) => {
    if (isLab) {
      if (!validateExperimentInfo(experimentData, process.type, setErrors, false, false, true)) return;
    }
    const totalTime = calculateTotalTime(
      experimentData.timePerReactionHour,
      experimentData.timePerReactionMin,
      experimentData.timePerReactionSec
    );
    let analyticalSettings = '';
    let temp_data = [];
    if (experimentData.analytics.find(analytic => analytic === 'LC-MS') || isSynJet || isSynjetPro) {
      temp_data = mapCompoundToAnalyticDataToBack(experimentData.analyticalData);
      temp_data = temp_data.map(item => ({
        ...item,
      }));
      analyticalSettings = JSON.stringify(temp_data);
    } else {
      analyticalSettings = '';
    }
    if (analyticalSettings && !validateAnalyticData(experimentData.analyticalData, setErrors) && !isSubmitted) return;
    const createExperimentData = {
      uuid: experiment ? experiment.uuid : '',
      name: encodeURIComponent(experimentData.name),
      process: isSynJet || isSynjetPro ? process?.process?.uuid : process.uuid,
      analyticalType: JSON.stringify(experimentData.analytics),
      priority: experimentData.priority && experimentData.priority.toString(),
      reactionsInfo: JSON.stringify(parseReactionsInfo(experimentData.reactionsInfo)),
      note: experimentData.notes ? encodeURIComponent(experimentData.notes) : '',
      purification: experimentData?.purification ? encodeURIComponent(experimentData.purification) : '',
      timePerReaction: experimentData.timePerReaction && +experimentData.timePerReaction,
      isSubmitted,
      analyticalSetting: analyticalSettings,
      totalTime,
      enableVialCoping: experimentData.allowVialsCopy,
    };
    try {
      const { errors: errorsData, key } = await dispatch(createExperiment(createExperimentData));
      if (!errorsData) {
        if (!showProjectForm) {
          if (isSynJet || isSynjetPro) {
            await dispatch(getSynjetProcess(process?.process?.uuid));
          } else {
            await dispatch(getProcess(process.uuid));
          }
        }
        setSubmitted(isSubmitted);
        dispatch(setModalClose());
        const draftMessage = !isSubmitted ? ' Please note: the experiment is saved as a Draft' : '';
        const updatedMessage = `Experiment is successfully ${experiment ? 'updated' : 'created'}.`;
        openNotification(`${updatedMessage}${draftMessage}`);
        if (!experiment) {
          if (isProcessBuilder) {
            window.open(experimentURL(key), '_blank');
          } else {
            history.push(experimentURL(key));
          }
        } else {
          getExperiment(key);
        }
      } else {
        const newError = {};
        JSON.parse(errorsData).forEach(
          error => (newError[error.field] = error.messages[0].field ? error.messages : error.messages.join(' '))
        );
        setErrors(newError);
      }
    } catch (e) {
      openNotification(null, e);
      dispatch(setModalClose());
    } finally {
      loadingCallback();
    }
  };

  const handleSubmit = () => {
    if (
      !validateAnalyticData(experimentData.analyticalData, setErrors) &&
      (experimentData.analytics?.length || isSynjetPro)
    )
      return;
    if (!isSynJet && !isSynjetPro && !isLab) {
      setLoadAvailableEquipment(true);
      const validateExperimentData = {
        processId: isSynJet || isSynjetPro ? process?.process?.uuid : process.uuid,
        analyticalType: JSON.stringify(experimentData.analytics),
      };
      dispatch(getAvailableAutoSyns(validateExperimentData)).then(resp => {
        setAvailableEquipment(resp?.availableDevices);
        setLoadAvailableEquipment(false);
        setSubmitModalOpen(true);
      });
    } else {
      setSubmitModalOpen(true);
    }
  };

  const handleSubmitConfirmation = () => {
    setCreationLoaderSubmit(true);
    handleSaveExperiment(true, () => setCreationLoaderSubmit(false)).finally(() => {});
    setTimeout(() => {
      setSubmitModalOpen(false);
    }, 300);
  };

  const handleEditConfirmation = () => {
    setCreationLoaderSubmit(true);
    handleSaveExperiment(editModalOpen?.isSubmitting, () => setCreationLoaderSubmit(false)).finally(() => {});
    setTimeout(() => {
      setEditModalOpen(false);
    }, 300);
  };

  return (
    <>
      <Popup
        maskClosable={false}
        open={isModalOpen}
        handleCancel={() => dispatch(setModalClose())}
        width="max-content"
        className="select-process-experiment-popup"
        createExperimentSecondStepSJP={experimentStep == 1 && isSynjetPro}
        footerComponent={getExperimentModalFooter(
          process,
          experimentStep,
          () => dispatch(setModalClose()),
          handleConfirm,
          handleBack,
          handleSaveExperiment,
          handleSubmit,
          experimentCreationLoading,
          showProjectForm,
          reactions?.length >= 2,
          isSynJet || isSynjetPro,
          confirmLoading,
          !!experiment,
          setEditModalOpen,
          isLab,
          showFirstStepIsLab,
          creationLoaderSubmit
        )}
        onCancel={() => dispatch(setModalClose())}
      >
        <div className="createExperimentModal">
          <h4 className="createExperimentModal__title">
            {getExperimentModalTitle(
              experimentStep,
              process,
              reactions?.length < 2,
              showProjectForm,
              isSynJet || isSynjetPro,
              !!experiment,
              isLab
            )}
          </h4>
          <Spinner
            loading={
              confirmLoading ||
              experimentInfoLoading ||
              (timeLoading && (isSynJet || isSynjetPro)) ||
              loadAvailableEquipment
            }
          >
            <div className="createExperimentModal__body">
              <CreateExperimentModalBody
                experiment={experiment}
                experimentStep={experimentStep}
                showProjectForm={showProjectForm}
                isProduction={process?.type === PROJECT_TYPES.PRODUCTION}
                process={process}
                steps={steps}
                setExperimentData={setExperimentData}
                experimentData={experimentData}
                availableAnalytics={availableAnalytics}
                priorities={priorities}
                errors={errors}
                skipReactionStep={skipReactionStep}
                experimentCreationLoading={experimentCreationLoading}
                submitModalOpen={submitModalOpen}
                processID={processID}
                project={project}
                setProcessID={setProcessID}
                setProject={setProject}
                processInstrument={processInstrument}
                setProcessInstrument={updateProcessInstrument}
                chooseInstrument={chooseInstrument}
                reactors={reactors}
                setErrors={setErrors}
                reactions={reactions}
                columns={columns}
                setReactions={setReactions}
                processData={processData}
                setDefaultAnalyticalData={setDefaultAnalyticalData}
                defaultAnalyticalData={defaultAnalyticalData}
                isSynjet={isSynJet}
                isSynjetPro={isSynjetPro}
                moveBack={moveBack}
                handleExperimentTimeCalculation={handleExperimentTimeCalculation}
                isLab={isLab}
                vialsCopies={vialsCopies}
                setVialsCopies={setVialsCopies}
                errorCopiedVials={errorCopiedVials}
                setErrorCopiedVials={setErrorCopiedVials}
                initialExpRender={initialExpRender}
              />
            </div>
          </Spinner>
          <PopupSubmitExperiment
            submitLoading={experimentCreationLoading}
            submitModalOpen={submitModalOpen}
            handleSubmitConfirmation={handleSubmitConfirmation}
            setSubmitModalOpen={setSubmitModalOpen}
            availableEquipment={availableEquipment}
          />
          <PopupEditExperiment
            editModalOpen={!!editModalOpen}
            handleEditConfirmation={handleEditConfirmation}
            setEditModalOpen={setEditModalOpen}
          />
        </div>
      </Popup>
    </>
  );
};

const mapStateToProps = state => ({
  isModalOpen: state.createExperimentReducer.isModalOpen,
  process: state.createExperimentReducer.process,
  steps: state.createExperimentReducer.steps,
  experiment: state.createExperimentReducer.experiment,
  processData: state.createExperimentReducer.processData,
  experimentInfoLoading: state.createExperimentReducer.experimentInfoLoading,
  experimentCreationLoading: state.createExperimentReducer.experimentCreationLoading,
  experimentSubmitLoading: state.createExperimentReducer.experimentSubmitLoading,
  showProjectForm: state.createExperimentReducer.showProjectForm,
  reactors: state.processbuilderReducer.reactors,
});

export default connect(mapStateToProps)(CreateExperimentModal);
