import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Select, Time, Tooltip } from '../../../Common';
import './style.scss';
import { formatToHHMM, formatToHHMMWithoutText, httpSynMolDB } from '../../../../utils';
import {
  PROJECT_TYPES,
  SYNJET_PROCESS_TYPES,
  SYNJET_PRO_PROCESS_TYPES,
  TEMPERATURE_LIMITS,
  TEMPERATURE_LIMITS_PRO,
  VOLUME_LIMITS,
  VOLUME_LIMITS_PRO,
} from '../../../../constants';
import { DeleteOutlined } from '@ant-design/icons';
import { addNewCondition, deleteCondition, updateConditions } from '../../../../store/synjet/synjet.actions';
import { selectAbilityAddCompound } from '../../../../store/synjet/synjet.selector';
import { useDispatch, useSelector } from 'react-redux';
import { ConditionsAndDispensingValuesBlock } from './ConditionsAndDispensingValuesBlock';
import moment from 'moment';
import { getMinMaxTemperatureValues as getProps } from 'utils/execution';

const TEMPERATURE_PRIORITIES = {
  low: { key: 'low', priority: 2 },
  med: { key: 'med', priority: 1 },
  high: { key: 'high', priority: 0 },
};

export const ConditionsAndDispensingOptions = ({
  processType,
  processConditions,
  step,
  editMode,
  isFixed,
  hideDispensing = false,
  showHHMM = true,
  highlightErrors,
  isPro,
  numberOfSteps,
}) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const isCompoundAddAvailable = useSelector(selectAbilityAddCompound);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [timeSelectOptions, setTimeOptions] = useState([]);

  const temperatureMin = isPro ? TEMPERATURE_LIMITS_PRO.MIN : TEMPERATURE_LIMITS.MIN;
  const temperatureMax = isPro ? TEMPERATURE_LIMITS_PRO.MAX : TEMPERATURE_LIMITS.MAX;
  const volumeMin = isPro ? VOLUME_LIMITS_PRO.MIN : VOLUME_LIMITS.MIN;
  const volumeMax = isPro ? VOLUME_LIMITS_PRO.MAX : VOLUME_LIMITS.MAX;

  useEffect(() => {
    httpSynMolDB('get_compound_list?tag=solvent&return_compatibility=true').then(resp => {
      setOptions([
        { value: null, label: 'None' },
        ...resp.compound_list.map(i => ({
          ...i,
          value: i.compound_id,
          label: i.compound_name,
          data: i,
        })),
      ]);
    });
  }, []);

  useEffect(() => {});

  const round5 = val => Math.round(val / 5) * 5;

  const disabledHours = type => {
    let disabled = [];
    for (let i = 0; i < 24; i++) disabled.push(i);
    const hours = {
      low: +time.low?.format('H'),
      med: +time.med?.format('H'),
      high: +time.high?.format('H'),
    };
    switch (type) {
      case 'low': {
        return !isNaN(hours.med)
          ? disabled.filter(i => i > hours.med)
          : !isNaN(hours.high)
          ? disabled.filter(i => i > hours.high)
          : [];
      }
      case 'med': {
        if (isNaN(hours.high) && isNaN(hours.low)) return [];
        disabled = disabled.filter(i => {
          const a = !isNaN(hours.low) ? i < hours.low : false;
          const b = !isNaN(hours.high) ? i > hours.high : false;
          return a || b;
        });
        return disabled;
      }
      case 'high': {
        return !isNaN(hours.med)
          ? disabled.filter(i => i < hours.med)
          : !isNaN(hours.low)
          ? disabled.filter(i => i < hours.low)
          : [];
      }
    }
  };

  const disabledMinutes = (type, selectedHour) => {
    let disabled = [];
    for (let i = 0; i < 60; i += 15) disabled.push(i);
    const hours = {
      low: +time.low?.format('H'),
      med: +time.med?.format('H'),
      high: +time.high?.format('H'),
    };
    const mins = {
      low: +time.low?.format('m'),
      med: +time.med?.format('m'),
      high: +time.high?.format('m'),
    };
    switch (type) {
      case 'low': {
        if (!isNaN(hours.med) && hours.med === selectedHour) disabled = disabled.filter(i => i > mins.med);
        else if (!isNaN(hours.high) && hours.high === selectedHour) disabled = disabled.filter(i => i > mins.high);
        else disabled = [];
        break;
      }
      case 'med': {
        if (
          (!isNaN(hours.low) && hours.low !== selectedHour && !isNaN(hours.high) && hours.high !== selectedHour) ||
          (isNaN(hours.high) && isNaN(hours.low))
        ) {
          disabled = [];
        } else {
          disabled = disabled.filter(i => {
            const a = !isNaN(hours.low) && hours.low === selectedHour ? i < mins.low : false;
            const b = !isNaN(hours.high) && hours.high === selectedHour ? i > mins.high : false;
            return a || b;
          });
        }
        break;
      }
      case 'high': {
        if (!isNaN(hours.med) && hours.med === selectedHour) disabled = disabled.filter(i => i < mins.med);
        else if (!isNaN(hours.low) && hours.low === selectedHour) disabled = disabled.filter(i => i < mins.low);
        else disabled = [];
        break;
      }
      case 'fixed': {
        disabled = [];
      }
    }
    return selectedHour === 0 ? [0, ...disabled] : disabled;
  };

  const isScreening = processType === SYNJET_PROCESS_TYPES.SCREENING;
  const isLibraryGenerationPro = processType === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION;

  const { temperature, time, dispensing } = processConditions[0];
  const dispensingVolume = dispensing.volume;

  const updateDispensingOptions = (type, value) => {
    if (type === 'solvent') value = options.find(option => option.value === value).data;
    dispatch(
      updateConditions(
        'dispensing',
        {
          ...dispensing,
          [type]: value,
        },
        0,
        step
      )
    );
  };

  const setVolume = volume => updateDispensingOptions('volume', volume);
  const setNormalize = normalize => updateDispensingOptions('normalize', normalize);
  const setSolvent = solvent => updateDispensingOptions('solvent', solvent);
  const setTime = (type, val, index = 0) => {
    if (typeof val === 'string') val = moment(val, 'HH:mm');
    dispatch(updateConditions('time', { ...time, [type]: val }, index, step));
  };

  let conditionsCount; // the user should be able to create maximum amount of conditions depending on the type of SynJet
  if (isPro && numberOfSteps === '2') {
    if (step == 0) {
      conditionsCount = 3;
    } else if (step == 1) {
      conditionsCount = 7;
    }
  } else if (isPro) {
    conditionsCount = 8;
  } else {
    conditionsCount = 4;
  }

  const canAddConditions = processConditions.length < conditionsCount && editMode && !isFixed;
  const canDeleteConditions = processConditions.length > 1 && editMode && !isFixed;

  const setTemperature = (type, val, index = 0) => {
    if (val === null) val = temperatureMin;
    let y;
    if (processType === PROJECT_TYPES.OPTIMIZATION) {
      y = setTemperaturesByPriorities(type, round5(val));
    } else {
      y = { ...temperature, [type]: round5(val) };
    }
    dispatch(updateConditions('temperature', { ...y }, index, step));
    // dispatch(updateConditions('temperature', { ...temperature, [type]: round5(val) }, index, step));
  };

  const setTemperaturesByPriorities = (type, val) => {
    let obj = { ...temperature };
    ['low', 'med', 'high'].forEach(i => {
      if (TEMPERATURE_PRIORITIES[type].priority >= TEMPERATURE_PRIORITIES[i].priority) {
        obj = { ...obj, [i]: val };
      }
    });
    return obj;
  };

  useEffect(() => {
    const options = [];
    const timeOptions = [];
    processConditions.forEach((condition, index) => {
      if (index > 2 && !isPro) return;
      if (!options.find(option => option.value === condition.temperature.fixed) && options.length < 4) {
        options.push({ value: condition.temperature.fixed, label: condition.temperature.fixed });
      }
      if (
        !timeOptions.find(option => option.value === moment(condition.time.fixed).format('HH:mm')) &&
        timeOptions.length < 4
      ) {
        const v = moment(condition.time.fixed).format('HH:mm');
        timeOptions.push({ value: v, label: v });
      }
    });
    setTemperatureOptions(options.sort((a, b) => a.value > b.value));
    setTimeOptions(timeOptions.sort((a, b) => a.value > b.value));
    if (processConditions.length === 4 && !!processConditions[3]) {
      if (options.length > 2 && !options.find(option => option.value === processConditions[3]?.temperature.fixed)) {
        dispatch(updateConditions('temperature', { ...temperature, fixed: options[0].value }, 3, step));
      }
      if (
        timeOptions.length > 2 &&
        !timeOptions.find(option => option.value === moment(processConditions[3]?.time.fixed).format('HH:mm'))
      ) {
        dispatch(updateConditions('time', { ...time, fixed: moment(timeOptions[0].value, 'HH:mm') }, 3, step));
      }
    }
  }, [JSON.stringify(processConditions)]);

  const showTemperatureSelect = processConditions.length === 4 && temperatureOptions.length > 2 && !isPro;
  const showTimeSelect = processConditions.length === 4 && timeSelectOptions.length > 2 && !isPro;

  useEffect(() => {
    if (!dispensing.normalize && dispensing.solvent) {
      setSolvent(null);
    }
  }, [dispensing.normalize]);
  return (
    <div className="conditions-and-dispensing-options-block">
      <div className="conditions-and-dispensing-options-block_content">
        {isScreening || (!isScreening && isFixed) || isLibraryGenerationPro ? (
          <>
            <div className="condition-block">
              <div className="conditions-and-dispensing-options-block_title">Conditions</div>
              <ConditionsAndDispensingValuesBlock type={processType} isFixed={isFixed}>
                {processConditions.map((condition, index) => (
                  <div className="children_row">
                    <div className="children_row_title">{isFixed ? 'FIXED' : `COND${index + 1}`}</div>
                    {editMode ? (
                      <>
                        {showTemperatureSelect && index === 3 ? (
                          <Select
                            className="time-select"
                            options={temperatureOptions}
                            onChange={e => setTemperature('fixed', e, index)}
                            value={condition.temperature.fixed}
                            showSearch={false}
                          />
                        ) : (
                          <Input
                            type="number"
                            integer
                            placeholder="0"
                            min={temperatureMin}
                            max={temperatureMax}
                            step={5}
                            value={condition.temperature.fixed}
                            onChange={e => {
                              dispatch(updateConditions('temperature', { ...temperature, fixed: e }, index, step));
                            }}
                            onBlur={() => {
                              let result = round5(condition.temperature.fixed);
                              if (result < temperatureMin || typeof condition.temperature.fixed !== 'number') {
                                result = temperatureMin;
                              } else if (result > temperatureMax) result = temperatureMax;
                              dispatch(updateConditions('temperature', { ...temperature, fixed: result }, index, step));
                            }}
                          />
                        )}
                        {showTimeSelect && index === 3 ? (
                          <Select
                            className="time-select"
                            options={timeSelectOptions}
                            onChange={e => setTime('fixed', e, index)}
                            value={moment(condition.time.fixed).format('HH:mm')}
                            showSearch={false}
                          />
                        ) : (
                          <Time
                            minuteStep={15}
                            showNow={false}
                            value={condition.time.fixed}
                            onChange={e => setTime('fixed', e, index)}
                            hideDisabledOptions
                            disabledMinutes={selectedHour => disabledMinutes('fixed', selectedHour)}
                            inputReadOnly
                            clearIcon={() => null}
                            error={
                              highlightErrors && !moment().startOf('day').diff(moment(condition.time.fixed), 'minutes')
                            }
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <div className="view-data">{condition.temperature.fixed}</div>
                        <div className="view-data">
                          {showHHMM
                            ? formatToHHMM(condition.time.fixed)
                            : formatToHHMMWithoutText(condition.time.fixed)}
                        </div>
                      </>
                    )}
                    {canDeleteConditions && (
                      <div className="delete_child_row">
                        <DeleteOutlined onClick={() => dispatch(deleteCondition(index, step))} />
                      </div>
                    )}
                  </div>
                ))}
              </ConditionsAndDispensingValuesBlock>
            </div>
            {canAddConditions && (
              <Button className="add-condition-button" onClick={() => dispatch(addNewCondition(step))}>
                Add Condition
              </Button>
            )}
          </>
        ) : (
          <>
            <div className="condition-block">
              <div className="conditions-and-dispensing-options-block_title">Temperature range</div>
              <ConditionsAndDispensingValuesBlock title="Temperature, °C" type={processType} isFixed={isFixed}>
                {['low', 'med', 'high'].map((i, idx) => {
                  const props = getProps(i, temperature);
                  return editMode ? (
                    <Input
                      type="number"
                      integer
                      value={temperature[i]}
                      onChange={e => setTemperature(i, e)}
                      key={idx}
                      placeholder="0"
                      min={props.min}
                      max={props.max}
                      step={5}
                    />
                  ) : (
                    <div className="view-data">{temperature[i]}</div>
                  );
                })}
              </ConditionsAndDispensingValuesBlock>
            </div>
            <div className="condition-block">
              <div className="conditions-and-dispensing-options-block_title">Time range</div>
              <ConditionsAndDispensingValuesBlock title="Time, hh:mm" type={processType} isFixed={isFixed}>
                {['low', 'med', 'high'].map((i, idx) =>
                  editMode ? (
                    <Time
                      value={time[i]}
                      onChange={e => setTime(i, e)}
                      disabledHours={() => disabledHours(i)}
                      disabledMinutes={selectedHour => disabledMinutes(i, selectedHour)}
                      minuteStep={15}
                      showNow={false}
                      hideDisabledOptions
                      inputReadOnly
                      clearIcon={() => null}
                      error={highlightErrors && !moment().startOf('day').diff(moment(time[i]), 'minutes')}
                    />
                  ) : (
                    <div className="view-data">
                      {showHHMM ? formatToHHMM(time[i]) : formatToHHMMWithoutText(time[i])}
                    </div>
                  )
                )}
              </ConditionsAndDispensingValuesBlock>
            </div>
          </>
        )}
      </div>
      {!hideDispensing && (
        <div className="conditions-and-dispensing-options-block_content">
          <div className="condition-block">
            <div className="conditions-and-dispensing-options-block_title">Dispensing options</div>
            <div className="conditions-and-dispensing-options-block_content-block options">
              {editMode ? (
                <Input
                  type="number"
                  label="Total vial volume, µL"
                  defaultValue={volumeMin}
                  value={dispensingVolume}
                  onChange={setVolume}
                  max={volumeMax}
                  min={volumeMin}
                  integer
                  onBlur={() => {
                    let result = dispensingVolume;
                    if (result < volumeMin || typeof result !== 'number') result = volumeMin;
                    else if (result > volumeMax) result = volumeMax;
                    setVolume(result);
                  }}
                />
              ) : (
                <div className="view-data row-data">
                  <div className="view-data__title">Total vial volume</div>
                  <div>{dispensingVolume} µL</div>
                </div>
              )}
              {editMode ? (
                <div className="row">
                  {isCompoundAddAvailable || dispensing.solvent ? (
                    <Checkbox children="Normalize with" value={dispensing.normalize} onChange={setNormalize} />
                  ) : (
                    <Tooltip title="Can't select normalization cause all dispensers in use">
                      <Checkbox children="Normalize with" disabled />
                    </Tooltip>
                  )}

                  <Select
                    disabled={!dispensing.normalize}
                    options={options}
                    placeholder="Select the solvent"
                    value={dispensing.normalize ? dispensing.solvent?.compound_name || null : undefined}
                    onChange={setSolvent}
                  />
                </div>
              ) : dispensing.normalize ? (
                <div className="view-data row-data">
                  <div className="view-data__title">Normalize with</div>
                  <div>{dispensing.solvent?.compound_name || 'None'}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
