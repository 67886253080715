import { Spinner } from '../../Common/Spinner';
import cn from 'classnames';
import Canvas from '../../DrawerRoute/Canvas';
import React from 'react';

export const ContainerRoute = ({
  filters,
  loadingRoute,
  detailsRoute,
  modeSegment,
  updateRangeSegments,
  rangeSegments,
  previewRouteProcessData,
}) => {
  return (
    <div className="creator-processes_content">
      {filters.route || previewRouteProcessData ? (
        <Spinner loading={loadingRoute}>
          <div
            className={cn('creator-processes_content_route', {
              'creator-processes_content_route_mode-segment': modeSegment,
            })}
          >
            {detailsRoute?.strategyRouteIndex?.S0R0?.route_segments.map((i, index) => {
              if (modeSegment && filters.segment - 1 !== index) return;
              let data = {
                ...detailsRoute,
                strategyRouteIndex: { S0R0: { ...detailsRoute.strategyRouteIndex.S0R0, route_segments: [i] } },
              };
              const isSelected = index + 1 === filters.segment;
              return (
                <div
                  className={cn('creator-processes_content_route_item', {
                    'creator-processes_content_route_item_active': isSelected,
                  })}
                >
                  <div className="creator-processes_content_route_item_title">
                    Segment {index + 1}
                    {isSelected && <span>Selected</span>}
                  </div>
                  <div className="creator-processes_content_route_item_divider" />
                  <div className="creator-processes_content_route_item_segment">
                    <Canvas
                      isSelected={isSelected}
                      rangeSegments={rangeSegments}
                      updateRangeSegments={updateRangeSegments}
                      renderSteps={modeSegment}
                      indexUniq={index + 1}
                      results={data}
                      onCanvasClick={() => {}}
                      type="routeCanvas"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Spinner>
      ) : (
        <div className="creator-processes_content_empty">The selected route will be displayed here</div>
      )}
    </div>
  );
};
