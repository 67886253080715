import React from 'react';

import { HEADER_HEIGHT } from '../constants';
import { getHeaderStyles, getMinMaxColumnsData } from '../utils';
import { HeaderCell } from './HeaderCell';

export const HeaderRow = ({ tableChildren, columns, updateOrder }) => {
  const { minIndex, maxIndex, leftOffset, childrenWidths } = getMinMaxColumnsData(tableChildren);
  const length = maxIndex - minIndex + 1;
  return Array.from(Array(length).keys()).map(index => {
    const targetIndex = minIndex + index;
    const columnData = columns[targetIndex];
    const style = getHeaderStyles({
      width: childrenWidths[index],
      height: HEADER_HEIGHT,
      left: index === 0 ? leftOffset : null,
    });
    return <HeaderCell columnIndex={targetIndex} style={style} columnData={columnData} updateOrder={updateOrder} />;
  });
};
