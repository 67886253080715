import React from 'react';
import { Avatar } from '../../Common';
import './styles.scss';
import { LabelValue } from '../LabelValue';
import { MovingBlock } from '../../Experiment/Info';

export const InfoTabCommon = ({
  dataExperiment,
  isSynJet,
  isLabDevice,
  numberDefinitionSteps,
  actualTimeExp,
  showMore,
  relatedExperiments,
  getExperimentInfo,
  historyToShow,
  setShowMore,
  pdfMode,
}) => (
  <div className="container-info-experiment_info_left">
    <LabelValue label="Experiment type" value={dataExperiment?.process?.type} />
    <LabelValue label="Number of steps" value={numberDefinitionSteps} />
    <LabelValue label="Solvents used" value={dataExperiment?.solventsUsed} />
    {!isSynJet && !isLabDevice && <LabelValue label="Output volume" value={`${dataExperiment?.outputVolume} mL`} />}
    {!isLabDevice && (
      <LabelValue
        label="Analytical methods"
        value={
          !dataExperiment?.analyticalType || !dataExperiment?.analyticalType.length
            ? 'None'
            : dataExperiment?.analyticalType
        }
      />
    )}
    {!isSynJet && (
      <div className="container-info-experiment_author">
        {!!dataExperiment?.createdBy?.firstName && !!dataExperiment?.createdBy?.lastName ? (
          <Avatar>
            {`${dataExperiment?.createdBy?.firstName} ${dataExperiment?.createdBy?.lastName}`.match(/\b(\w)/g).join('')}
          </Avatar>
        ) : (
          <Avatar> </Avatar>
        )}
        <div className="container-info-experiment_author_text">
          <span>Created by</span>
          <span className="ellipsis-createdby">
            {dataExperiment?.createdBy?.firstName} {dataExperiment?.createdBy?.lastName}
          </span>
        </div>
      </div>
    )}
    <LabelValue label="Actual time" value={actualTimeExp} />
    {!isSynJet && (
      <MovingBlock
        pdfMode={pdfMode}
        dataExperiment={dataExperiment}
        showMore={showMore}
        relatedExperiments={relatedExperiments}
        getExperimentInfo={getExperimentInfo}
        historyToShow={historyToShow}
        setShowMore={setShowMore}
      />
    )}
  </div>
);
