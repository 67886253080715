import moment from 'moment';
import { prepareDataRoute } from './helpers';
import {
  MESSAGES,
  SYNJET_INITIAL_COMPOUND,
  SYNJET_INITIAL_COMPOUND_SCRINNING,
  SYNJET_PROCESS_TYPES,
} from '../../constants';
import { parseProcessDataFromBackend } from '../synjetHelpers';

const getProductId = (route = {}) => route?.products?.[0] || '';

const getCompoundSmiles = (compoundId = '', compoundList = {}) => compoundList?.[compoundId] || null;

const getTimeFromDayStart = (hours = 0) => {
  if (hours > 23.75) {
    return moment().startOf('day').add(23.75, 'hours');
  }
  return moment().startOf('day').add(hours, 'hours');
};

const getStepData = (route, compounds) => {
  const variation = route.variations[0];
  if (variation?.conditions.length > 1) {
    throw new Error(MESSAGES['237']);
  }
  const condition = variation?.conditions[0];
  const solvents = condition?.solvents.map(solventId => getCompoundSmiles(solventId, compounds)) || [];
  const reactants = route.reactants.map(reactantId => getCompoundSmiles(reactantId, compounds)) || [];
  const reagents = condition?.reagents.map(reagentId => getCompoundSmiles(reagentId, compounds)) || [];
  const catalysts = condition?.catalysts.map(catalystId => getCompoundSmiles(catalystId, compounds)) || [];
  const product = {
    smiles: getCompoundSmiles(getProductId(route), compounds),
  };
  const temperatures = condition?.temps || [];
  const times = condition?.times || [];
  return {
    temperatures,
    times,
    product,
    reactants,
    reagents: [...reagents, ...catalysts],
    solvents,
  };
};

const getSolvents = solvents => {
  if (!solvents || !solvents.length) {
    return [];
  }

  if (!solvents[0]) {
    return [
      {
        solvent: {
          compound_name: 'None',
        },
        fraction: 1,
      },
    ];
  }

  if (solvents.length > 1) {
    return [
      {
        solvent: solvents[0],
        fraction: 0.5,
      },
      {
        solvent: solvents[1],
        fraction: 0.5,
      },
    ];
  }

  return [
    {
      solvent: solvents[0],
      fraction: 1,
    },
  ];
};

const getInitialCompound = (reactantSmiles, solvents, initialData = {}) => ({
  ...initialData,
  smiles: reactantSmiles,
  solvents: getSolvents(solvents),
});

const getFixedTemperature = (temperatures = []) => {
  const temperature = temperatures[0] || 0;
  if (temperature > 180) {
    return 180;
  }
  if (temperature < 20) {
    return 20;
  }
  return temperature;
};

const getFixedTime = (times = []) => {
  const time = times[0] || 0;
  if (time < 0.25) {
    return getTimeFromDayStart();
  }
  if (time > 24) {
    return getTimeFromDayStart(24);
  }
  return getTimeFromDayStart(time);
};

const getVariableTemperatures = (temperatures = []) => {
  let temp1 = temperatures[0];
  let temp2 = temperatures[1];
  let temp3 = temperatures[2];

  const initialTemps = {
    minTemp: 20,
    medTemp: 20,
    maxTemp: 20,
  };

  if (!temp1 || temp1 < 20 || temp1 > 180) {
    initialTemps.minTemp = 20;
    temp1 = 20;
  }

  if (temp1) {
    initialTemps.minTemp = temp1;
  }

  if (!temp2) {
    initialTemps.medTemp = temp1;
    temp2 = temp1;
  }

  if (temp2 && temp2 > temp1) {
    initialTemps.medTemp = temp2;
  } else {
    initialTemps.medTemp = temp1;
    temp2 = temp1;
  }

  if (!temp3) {
    initialTemps.maxTemp = temp2;
    temp3 = temp2;
  }

  if (temp3 && temp3 > temp2) {
    initialTemps.maxTemp = temp3;
  } else {
    initialTemps.maxTemp = temp2;
  }

  return initialTemps;
};

const getVariableTimes = (times = []) => {
  let time1 = times[0];
  let time2 = times[1];
  let time3 = times[2];

  const initialTimes = {
    minTime: getTimeFromDayStart(),
    medTime: getTimeFromDayStart(),
    maxTime: getTimeFromDayStart(),
  };

  if (!time1 || time1 < 0.25 || time1 > 24) {
    initialTimes.minTime = getTimeFromDayStart();
    time1 = 0;
  }

  if (time1) {
    initialTimes.minTime = getTimeFromDayStart(time1);
  }

  if (!time2) {
    initialTimes.medTime = getTimeFromDayStart(time1);
    time2 = time1;
  }

  if (time2 && time2 > time1) {
    initialTimes.medTime = getTimeFromDayStart(time2);
  } else {
    initialTimes.medTime = getTimeFromDayStart(time1);
    time2 = time1;
  }

  if (!time3) {
    initialTimes.maxTime = getTimeFromDayStart(time2);
    time3 = time2;
  }

  if (time3 && time3 > time2) {
    initialTimes.maxTime = getTimeFromDayStart(time3);
  } else {
    initialTimes.maxTime = getTimeFromDayStart(time2);
  }

  return initialTimes;
};

const getFixedStep = (route, compounds) => {
  const { temperatures, times, product, reactants, reagents, solvents } = getStepData(route, compounds);

  const fixedReactants = reactants.map(reactantSmiles =>
    getInitialCompound(reactantSmiles, solvents, SYNJET_INITIAL_COMPOUND_SCRINNING)
  );
  const fixedReagents = reagents.map(reagentSmiles =>
    getInitialCompound(reagentSmiles, solvents, SYNJET_INITIAL_COMPOUND_SCRINNING)
  );

  return {
    isFixed: true,
    product,
    compounds: {
      reactants: fixedReactants,
      reagents: fixedReagents,
      reactantGroups: [],
      reagentGroups: [],
    },
    conditions: [
      {
        temperature: {
          low: 20,
          med: 20,
          high: 20,
          fixed: getFixedTemperature(temperatures),
        },
        time: {
          low: null,
          med: null,
          high: null,
          fixed: getFixedTime(times),
        },
        dispensing: {
          volume: 34,
          normalize: false,
          solvent: null,
        },
      },
    ],
  };
};

const getOptimizationStep = (route, compounds) => {
  const { temperatures, times, product, reactants, reagents, solvents } = getStepData(route, compounds);
  if (reactants.length + reagents.leading > 5) {
    throw new Error(MESSAGES['238']);
  }

  const optimizationReactants = reactants?.map(reactantSmiles =>
    getInitialCompound(reactantSmiles, solvents, SYNJET_INITIAL_COMPOUND)
  );
  const optimizationReagents = reagents?.map(reagentSmiles =>
    getInitialCompound(reagentSmiles, solvents, SYNJET_INITIAL_COMPOUND)
  );

  const { minTemp, medTemp, maxTemp } = getVariableTemperatures(temperatures);
  const { minTime, medTime, maxTime } = getVariableTimes(times);

  return {
    isFixed: false,
    product,
    compounds: {
      reactants: optimizationReactants,
      reagents: optimizationReagents,
      reactantGroups: [],
      reagentGroups: [],
    },
    conditions: [
      {
        temperature: {
          low: minTemp,
          med: medTemp,
          high: maxTemp,
          fixed: 20,
        },
        time: {
          low: minTime,
          med: medTime,
          high: maxTime,
          fixed: getTimeFromDayStart(),
        },
        dispensing: {
          volume: 34,
          normalize: false,
          solvent: null,
        },
      },
    ],
  };
};

const getScreeningStep = (route, compounds) => {
  const { temperatures, times, product, reactants, reagents, solvents } = getStepData(route, compounds);

  if (reactants.length + reagents.leading > 5) {
    throw new Error(MESSAGES['238']);
  }

  const reactantGroups = reactants.map((reactantSmiles, reactantSmilesIndex) => ({
    name: `reactant ${reactantSmilesIndex + 1}`,
    isLimiting: false,
    compoundsList: [getInitialCompound(reactantSmiles, solvents, SYNJET_INITIAL_COMPOUND_SCRINNING)],
  }));

  const reagentGroups = reagents.map((reagentSmiles, reagentSmilesIndex) => ({
    name: `reagent ${reagentSmilesIndex + 1}`,
    isLimiting: false,
    compoundsList: [getInitialCompound(reagentSmiles, solvents, SYNJET_INITIAL_COMPOUND_SCRINNING)],
  }));

  return {
    isFixed: false,
    product,
    compounds: {
      reactants: [],
      reagents: [],
      reactantGroups,
      reagentGroups,
    },
    conditions: [
      {
        temperature: {
          low: 0,
          med: 0,
          high: 0,
          fixed: getFixedTemperature(temperatures),
        },
        time: {
          low: null,
          med: null,
          high: null,
          fixed: getFixedTime(times),
        },
        dispensing: {
          volume: 34,
          normalize: false,
          solvent: null,
        },
      },
    ],
  };
};

export const getSynjetProcessData = (routes, compounds, processType) =>
  routes
    .map((i, idx, arr) =>
      idx ? { ...i, reactants: i.reactants?.filter(j => !arr[idx - 1]?.products.includes(j)) } : i
    )
    .map((route, reouteIndex, arr) => {
      if (routes.length > 1 && reouteIndex === 0) {
        return getFixedStep(route, compounds);
      }
      switch (processType) {
        case SYNJET_PROCESS_TYPES.OPTIMIZATION:
          return getOptimizationStep(route, compounds);
        case SYNJET_PROCESS_TYPES.SCREENING:
          return getScreeningStep(route, compounds);
        default:
          return [];
      }
    });

export const parseSynjetRouteToProcessData = async ({ route, segment, part }, processType) => {
  if (!processType || !route) {
    return {};
  }

  const { reactorsDetails, compoundsDataBase } = await prepareDataRoute({ route, segment, part });
  return getSynjetProcessData(reactorsDetails, compoundsDataBase, processType);
};

const getCompoundIdFromName = (compounds, compoundsLibrary, property) =>
  compounds.map(compound => {
    const foundCompound = compoundsLibrary.find(compoundComponent => compoundComponent.name === compound.name);

    if (!foundCompound) {
      return compound;
    }

    return {
      ...compound,
      smiles: foundCompound[property] || null,
      solvents: foundCompound.solvents,
    };
  });

const setVialCompoundIds = experimentData => ({
  ...experimentData,
  process_steps: experimentData.process_steps.map(({ step }) => ({
    step: {
      ...step,
      vials: step.vials.map(vial => ({
        ...vial,
        reactants: getCompoundIdFromName(vial.reactants, experimentData.reactants, 'reactant'),
        reagents: getCompoundIdFromName(vial.reagents, experimentData.reagents, 'reagent'),
      })),
    },
  })),
});

const getSolventsFormCompounds = compounds => {
  const uniqueSolvents = new Set();

  compounds.forEach(compound => {
    if (compound.solvents) {
      compound.solvents.forEach(solvent => {
        if (solvent?.solvent) {
          uniqueSolvents.add(solvent.solvent);
        }
      });
    }
  });

  return [...uniqueSolvents];
};

export const parseExpToRouteSynJet = (experimentData, experimentKey) => {
  if (!experimentData) {
    return null;
  }

  const parsedData = parseProcessDataFromBackend(
    setVialCompoundIds(experimentData),
    experimentData.process_steps.length
  );
  const { step: variableStep } = parsedData.processSteps.find(step => !step.step.isFixed);
  const rxns = {};
  const strategyRouteIndex = {};

  const routes = variableStep.vials.map((vial, vialIndex) => {
    const strategyRouteIndexKey = `S0R${vialIndex}`;
    const vialRoute = {
      name: `${experimentKey}-RR-${vialIndex + 1}`,
      route_segments: [[]],
    };

    parsedData.processSteps.forEach(({ step }, stepIndex) => {
      const rxnId = `vial_${vialIndex}_${stepIndex}`;
      const stepVialData = step.vials[vialIndex];

      const reactants = stepVialData.reactants
        .map(reactant => reactant.smiles?.compound_id || null)
        .filter(reactantId => reactantId);

      vialRoute.route_segments[0].push({
        feedstockIds: [],
        prod_id: step.product,
        rxn_id: rxnId,
        branchIds: reactants,
      });

      rxns[rxnId] = {
        reactants,
        products: [step.product],
        variations: [
          {
            yields: [[step.product, '']],
            conditions: [
              {
                solvents: [...getSolventsFormCompounds([...stepVialData.reactants, ...stepVialData.reagents])],
                reagents: stepVialData.reagents
                  .map(reactant => reactant.smiles?.compound_id || null)
                  .filter(reactantId => reactantId),
                catalysts: [],
                temps: [stepVialData.temperature],
                times: [
                  Number(
                    (
                      moment(stepVialData.time).diff(moment(stepVialData.time).startOf('day'), 'seconds') /
                      60 /
                      60
                    ).toFixed(2)
                  ),
                ],
              },
            ],
          },
        ],
      };
    });

    strategyRouteIndex[strategyRouteIndexKey] = vialRoute;

    return vialRoute;
  });

  const cpds = experimentData.compounds.reduce(
    (result, compound) => ({ ...result, [compound.compound_id]: compound }),
    {}
  );

  return {
    routes,
    cpds,
    rxns,
    strategyRouteIndex,
  };
};
