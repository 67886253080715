import React, { useState, useEffect, useContext } from 'react';
import { RealTimeModalContext } from '../../ProcessBuilder/WorkSpace/WorkSpace';
import './style.scss';
import { Popover, Carousel, Button, Spinner } from '../../Common';
import { useSelector, useDispatch } from 'react-redux';
import { reactors, pumpsTypes } from '../../../store/processBuilder/processbuilder.selector';
import { getReactorsTypes, getPumpTypes } from '../../../store/processBuilder/processbuilder.actions';
import { getDataUnivariateCharts } from '../../../store/experiment/experiment.actions';
import { Chart } from '../../Execution/Chart';
import { colorsCharts } from '../../../constants';
import { getDiffChart } from '../../../utils/date';
import moment from 'moment';
import { callHelperUnivariateData } from '../../../utils/execution';

const materialReactor = (allReactors, key, pump, pumpTypes, type) => {
  if (pump) return pumpTypes.allPumps?.find(i => i.label === type)?.material;
  return allReactors.allReactors?.find(i => i.key === key)?.material;
};

const PopoverContent = ({
  onClose = () => {},
  data,
  pump,
  errorDataNotFount,
  allReactors,
  pumpTypes,
  dataCharts,
  configCharts,
  liquid,
  errorData502,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const onChangeSlider = i => {
    setCurrentSlide(i);
  };

  const Title = () => (
    <div className="title-carousel-autosyn">{configCharts && configCharts[currentSlide]?.labelChartSlider}</div>
  );

  return (
    <div className="controllable-popover_content">
      <h2 className="controllable-popover_title">{pump ? data.name : data.key}</h2>
      <div className="controllable-popover_option_row">
        <div className="controllable-popover_option_row__title">Current status</div>
        <div className="controllable-popover_option_row__value">{data.working ? 'Online' : 'Offline'}</div>
      </div>
      <div className="controllable-popover_option_row">
        <div className="controllable-popover_option_row__title">Material</div>
        <div className="controllable-popover_option_row__value">
          {materialReactor(allReactors, data.key, !!pump, pumpTypes, data.type[0])}
        </div>
      </div>
      {pump ? (
        <>
          {data?.cp && (
            <div className="controllable-popover_option_row">
              <div className="controllable-popover_option_row__title">Current Pressure</div>
              <div className="controllable-popover_option_row__value">{data?.cp && `${data.cp.toFixed(3)} psig`}</div>
            </div>
          )}
          {data?.sq && (
            <div className="controllable-popover_option_row">
              <div className="controllable-popover_option_row__title">Pump Set Flow Rate</div>
              <div className="controllable-popover_option_row__value">{data?.sq && `${data?.sq.toFixed(3)} sccm`}</div>
            </div>
          )}
          {data?.aq && (
            <div className="controllable-popover_option_row">
              <div className="controllable-popover_option_row__title">Pump Actual Flow Rate</div>
              <div className="controllable-popover_option_row__value">
                {data?.aq && `${data?.aq.toFixed(3)} mL/min`}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {data?.setTemperature && (
            <div className="controllable-popover_option_row">
              <div className="controllable-popover_option_row__title">Set temperature</div>
              <div className="controllable-popover_option_row__value">
                {data?.setTemperature && `${data?.setTemperature.toFixed(1)} °C`}{' '}
              </div>
            </div>
          )}
          {data?.actualTemperature && (
            <div className="controllable-popover_option_row">
              <div className="controllable-popover_option_row__title">Actual temperature</div>
              <div className="controllable-popover_option_row__value">
                {data?.actualTemperature && `${data?.actualTemperature.toFixed(1)} °C`}
              </div>
            </div>
          )}
        </>
      )}
      {dataCharts && (
        <div className="controllable-popover_content_wrapper-slider">
          <Carousel noTitle customTitle Title={Title} onChange={onChangeSlider} countSlide={dataCharts?.length || 0}>
            {dataCharts.map((i, idx) => (
              <div className="controllable-popover_content_chart">
                <Chart
                  errorData502={errorData502}
                  errorDataNotFount={errorDataNotFount}
                  miniVersion
                  axisYName={configCharts[idx].unit}
                  endPoint={i.length && [i?.length - 1] ? i[i?.length - 1] : {}}
                  dataConfig={[configCharts[idx]]}
                  data={i}
                  label=""
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      <div className="controllable-popover_content__footer">
        <Button secondary onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

const processData = (data, startPoint, config) =>
  data.map(i => {
    const timeSec = getDiffChart(startPoint.datetime, i.datetime);
    const timeTrunc = Math.trunc(timeSec / 60);
    const timeExtraSec = timeSec - timeTrunc * 60;
    const proportionExtraSecFromMin = Math.trunc((timeExtraSec * 100) / 60);
    return {
      timeInSec: timeSec,
      time: +`${timeTrunc}.${
        proportionExtraSecFromMin < 10 ? `0${proportionExtraSecFromMin}` : proportionExtraSecFromMin
      }`,
      name: i.sensor_id,
      extraOptions: {
        colorLine: undefined,
        name: data.label,
        sensor_id: i.sensor_id,
        typeLine: 'line',
        x: 'time',
        y: 'value',
      },
      value: i.value,
    };
  });

export const PopoverControllable = ({ open, children, data, pump, experiment, configAllCharts, liquid = false }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [dataCharts, setDataCharts] = useState(null);
  const [configCharts, setConfigCharts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState(null);
  const [errorDataNotFount, setErrorDataNotFount] = useState(false);
  const [errorData502, setErrorData502] = useState(false);
  const dispatch = useDispatch();
  const allReactors = useSelector(reactors);
  const pumpTypes = useSelector(pumpsTypes);

  const { isRealTimeModalOpen, setRealTimeModalOpen } = useContext(RealTimeModalContext);

  useEffect(() => {
    dispatch(getReactorsTypes());
    dispatch(getPumpTypes());
  }, []);

  const onClose = () => {
    setPopoverVisible(false);
    setRealTimeModalOpen(false);
  };

  const setLabelChart = i => {
    if (i.labelGroup === 'Pump and System Pressure') return 'Pump Pressure';
    if (i.labelGroup === 'Reactors Temperature' && i.label.indexOf('Actual') > 0 && i.label[0] === 'R') {
      return 'Reactor Actual Temperature';
    }
    if (i.labelGroup === 'Reactors Temperature' && i.label.indexOf('Set') > 0 && i.label[0] === 'R') {
      return 'Reactor Set Temperature';
    }
    return i.labelGroup;
  };

  const setConfig = sensorsData => {
    setConfigCharts(
      sensorsData.map((i, idx) => ({
        colorLine: colorsCharts[idx],
        labelChartSlider: setLabelChart(i),
        name: i.label,
        unit: i.unit,
        sensor_id: i.sensor_id,
        typeLine: 'line',
        x: 'time',
        y: 'value',
      }))
    );
  };
  useEffect(() => {
    if (popoverVisible) {
      if (!dataCharts && !configCharts) {
        setLoading(true);
        const sensorsData = [];
        configAllCharts.forEach(i => {
          const sensors = i.lines.filter(
            j =>
              j.label.split(' ')[0] === data.key ||
              (i.label === 'Reactors Temperature' && j.label.split(' ')[0].indexOf(data.key) === 0)
          );
          if (sensors.length) {
            sensors.forEach(k => {
              sensorsData.push({ ...k, labelGroup: i.label, unit: i.unit });
            });
          }
        });
        const newController = new AbortController();
        setController(newController);
        callHelperUnivariateData({
          controller: newController,
          sensorsIds: sensorsData.map(i => i.sensor_id).join(','),
          body: { uuid: experiment?.uuid },
          chunkNumber: 1,
          callBackNotData: () => {
            setErrorDataNotFount(true);
          },
          callBack502: () => {
            setErrorData502(true);
          },
        })
          .then(dataCharts => {
            const startPoint = { datetime: moment.utc(experiment?.execution?.startedAt).format() };
            const processedData = dataCharts.map(i => processData(i, startPoint, sensorsData[0]));
            setConfig(sensorsData);
            setDataCharts(processedData);
          })
          .catch(() => {
            setConfig(sensorsData);
            setDataCharts(sensorsData.map(i => []));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setErrorData502(false);
      setErrorDataNotFount(false);
      setController(null);
      setDataCharts(null);
      setConfigCharts(null);
    }
  }, [popoverVisible, !!experiment, !!configCharts]);

  useEffect(
    () => () => {
      if (controller) controller.abort();
    },
    [!!controller]
  );

  return (
    <Popover
      overlayClassName="ant-optimization-popover"
      content={() => (
        <Spinner loading={loading}>
          <PopoverContent
            errorData502={errorData502}
            errorDataNotFount={errorDataNotFount}
            data={data}
            pump={pump}
            dataCharts={dataCharts}
            configCharts={configCharts}
            allReactors={allReactors}
            pumpTypes={pumpTypes}
            onClose={onClose}
            isOpen={popoverVisible}
            liquid={liquid}
          />
        </Spinner>
      )}
      visible={popoverVisible}
      onVisibleChange={visible => {
        setDataCharts(null);
        setConfigCharts(null);
        setPopoverVisible(visible);
      }}
      trigger={[]}
    >
      <div
        onClick={() => {
          if (isRealTimeModalOpen) return;
          setPopoverVisible(true);
          setRealTimeModalOpen(true);
        }}
      >
        {children}
      </div>
    </Popover>
  );
};
