import './styles.scss';

import React from 'react';

import cn from 'classnames';

import { Tooltip } from '../../../components';

export const IconButtonWithTooltip = ({ className, icon, tooltip }) => (
  <Tooltip placement="top" title={tooltip}>
    <button className={cn('icon-button-with-tooltip', className)}>{icon}</button>
  </Tooltip>
);
