import React from 'react';
import { PreviewSmiles } from '../../Common/PreviewSmiles';
import { LabelValue } from '../LabelValue';
import { formatSecondsToHours } from '../../../utils';

export const InfoTabSlide = ({ data, isLabDevice, dataExperiment, Header }) => (
  <div className="container-info-experiment_info_right_item">
    {Header && <Header />}
    <PreviewSmiles data={data} />
    {!isLabDevice && (
      <LabelValue
        className="container-info-experiment_info_right_first-el"
        label="Theoretical production rate"
        value={`${data?.theoretical_product_rate?.toFixed(2)} mg/h`}
      />
    )}
    {!isLabDevice && dataExperiment?.process?.type !== 'Optimization' ? (
      <>
        <LabelValue label="Requested output" value={`${data?.requested_output?.toFixed(2)} mg`} />
        <LabelValue
          label="Overall estimated percent Yield"
          value={`${data?.overall_estimated_percent_yield?.toFixed(2)} %`}
        />
        <LabelValue label="Theoretical Yield" value={`${(data?.theoretical_yield * 1000).toFixed(2)} mg`} />
        <LabelValue label="Estimated production rate" value={`${data?.estimated_production_rate?.toFixed(2)} mg/h`} />
        <LabelValue
          label="Production time"
          value={`${formatSecondsToHours(data?.production_time).hour}h ${
            formatSecondsToHours(data?.production_time).min
          }min`}
        />
      </>
    ) : (
      !isLabDevice && (
        <LabelValue label="Time per reaction" value={data?.time_per_reaction ? `${data.time_per_reaction}min` : ''} />
      )
    )}
  </div>
);
