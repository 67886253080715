import { parseProcessBuilderDataFromBackend } from '../manualProcessBuilder';

const typesCompunds = {
  Reagent: 'Reagent',
  Reactant: 'Reactant',
  Solvent: 'Solvent',
};

const setTimeToHour = sec => {
  if (typeof sec !== 'number') return;
  return Math.floor((sec / 3600) * 100) / 100;
};

export const parseExpLabToRoute = ({ experiment }) => {
  const route = {
    routes: [],
    cpds: {},
    rxns: {},
    strategyRouteIndex: {},
  };
  const data = JSON.parse(experiment.reactionsInfo);
  data.compounds.forEach(i => (route.cpds[i.compound_id] = i));
  let countReaction = 0;
  const reactionInfo = parseProcessBuilderDataFromBackend(data, experiment.process.type)
    .parsedData.map(i => {
      const propertiesEquipment = data?.equipments?.find(j => j.name === i?.equipment?.name)?.properties;
      if (!countReaction && propertiesEquipment?.length) countReaction = propertiesEquipment?.length;
      return {
        equipment: i.equipment ? { ...i.equipment, properties: propertiesEquipment } : null,
        inputs: i.inputs.map(j => ({ ...j, properties: data?.inputs?.find(k => k.name === j.name)?.properties })),
      };
    })
    .filter(i => i?.equipment || i?.inputs?.length);
  countReaction = Array.from(Array(countReaction).keys());
  countReaction.forEach(idxReaction => {
    const routeReaction = {
      name: `${experiment.key}-RR-${idxReaction + 1}`,
      route_segments: [],
    };
    const dataCurrentReaction = reactionInfo.map(i => ({
      equipment: i.equipment?.properties ? { ...i.equipment, ...i.equipment.properties[idxReaction] } : null,
      inputs: i.inputs.map(j => ({ ...j, ...j.properties[idxReaction] })),
    }));

    const equipmentGroups = [];
    let group = [];

    dataCurrentReaction.forEach(i => {
      if (i.equipment?.product) {
        group.push(i);
        equipmentGroups.push(group);
        group = [];
      } else group.push(i);
    });
    routeReaction.route_segments = [
      [
        ...equipmentGroups.map((group, idxGroup, arr) => {
          const rxn_id = `reactor_${idxReaction}_${idxGroup}`;
          const reactantsIds = [];
          const currentProduct = group.find(item => !!item.equipment?.product)?.equipment?.product;
          const currentEquipment = group.find(item => !!item.equipment)?.equipment;
          group.forEach(item =>
            item.inputs.forEach(input => {
              const reactantInput = input.compound;
              if (input.type === typesCompunds.Reactant) reactantsIds.push(reactantInput);
            })
          );

          const prevProduct = arr[idxGroup - 1]?.find(item => !!item?.product)?.product;
          if (prevProduct) reactantsIds.unshift(prevProduct);
          route.rxns[rxn_id] = {
            reactants: reactantsIds,
            products: [currentProduct],
            variations: [
              {
                yields: [['SFC00000003', '']],
                conditions: group.map(item => ({
                  solvents: item.inputs
                    .filter(i => i.type === typesCompunds.Solvent || !!i.solvent)
                    .reduce((accum, current) => [...accum, current.solvent], []),
                  reagents: item.inputs
                    .filter(i => i.type === typesCompunds.Reagent)
                    .reduce((accum, current) => [...accum, current.compound], []),
                  catalysts: [],
                  temps: [currentEquipment.temperature],
                  times: [setTimeToHour(+currentEquipment.time)],
                })),
              },
            ],
          };
          return {
            feedstockIds: [],
            prod_id: currentProduct,
            rxn_id,
            branchIds: reactantsIds,
          };
        }),
      ],
    ];

    route.strategyRouteIndex[`S${0}R${idxReaction}`] = routeReaction;
    route.routes.push(routeReaction);
  });
  return route;
};
