import React, { useEffect, useMemo, useState, useCallback } from 'react';
import './style.scss';
import cn from 'classnames';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  getExperimentContainerStyle,
  getExperimentTimeConditions,
  getDisabledHours,
  getDisabledMinutes,
  getRequiredVialQuantity,
  getTotalRequiredVialQuantity,
  getTotalRequiredVialPositionQuantity,
  getTotalHeatingBlockQuantity,
  getMinMaxTemperatureValues,
  getUniqueCondition,
  getHeatingBlocks,
  updateSynjetProcessSteps,
  updateSynjetProProcessSteps,
  updateSynjetProProducts,
  getUniqueTemperatures,
} from 'utils/execution';
import { Input, openNotification, Select, Time, Tooltip } from '../../../Common';
import { SYNJET_PROCESS_TYPES, TEMPERATURE_LIMITS, DEVICES, SYNJET_PRO_PROCESS_TYPES } from '../../../../constants';
import { HeatingBlock } from './HeatingBlock';
import {
  INITIALLY_EXCLUDED_VIAL,
  SYNJET_MAX_HEATING_BLOCKS,
  SYNJET_PRO_MAX_HEATING_BLOCKS,
  UNIQUE_TEMP_COUNT_ERROR,
  VIALS_WERE_EXCLUDED,
} from '../../../../constants/execution';

const getTemperatureTitle = temperature =>
  temperature?.[0].toLowerCase().startsWith('cond')
    ? `${temperature?.[0]?.slice(0, 4)} ${temperature?.[0].substr(4)}`
    : temperature?.[0];

export const ConditionsHardwareSummary = ({
  experiments,
  experimentsInfo,
  handleExperimentInfoUpdate,
  isPro,
  heatingBlocks,
  setHeatingBlocks,
  setConditionsErrors,
}) => {
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [initialTemperatureOptions, setInitialTemperatureOptions] = useState(null);
  const [defaultHeatingBlocks, setDefaultHeatingBlocks] = useState([]);
  const [totalRequiredVialQuantity, setTotalRequiredVialQuantity] = useState(0);
  const [totalRequiredVialPositionQuantity, setTotalRequiredVialPositionQuantity] = useState(0);
  const [totalHeatingBlockQuantity, setTotalHeatingBlockQuantity] = useState(0);
  const [regenerateHeatingBlocks, setRegenerateHeatingBlocks] = useState(false);
  const [lastUniqueTemperature, setLastUniqueTemperature] = useState(null);

  const maxHeatingBlocksCount = useMemo(() => (isPro ? SYNJET_PRO_MAX_HEATING_BLOCKS : SYNJET_MAX_HEATING_BLOCKS), [
    isPro,
  ]);

  const showConditionSelector = (options, property) => {
    const conditionOptions = Object.entries(options).filter(option => option[0] !== 'fixed');
    return (
      conditionOptions.length > 3 &&
      getUniqueCondition(options, property).length === 3 &&
      conditionOptions.every(option => option[0].includes('COND'))
    );
  };

  const showConditionSelectorByIndex = (index, experimentIndex) =>
    (experimentsInfo[experimentIndex]?.steps?.length > 1 ? index === 4 : index === 3) &&
    experimentsInfo[experimentIndex].process.process.device !== DEVICES.SYNJETPRO;

  const handleConditionsBlur = (e, experimentIndex, conditionIndex, type, conditionName) => {
    setRegenerateHeatingBlocks(true);
    const isSynjet =
      experiments?.[experimentIndex]?.process?.type === SYNJET_PROCESS_TYPES.SCREENING ||
      experiments?.[experimentIndex]?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION;
    const value = type === 'time' ? e : +e?.target?.value || e;
    const { steps, products, quenching, expectedIntermediates } = experimentsInfo[experimentIndex];
    if (isPro) {
      const updatedSteps = updateSynjetProProcessSteps(
        steps,
        value,
        isSynjet,
        conditionName,
        temperatureOptions,
        experimentIndex,
        type,
        conditionIndex
      );
      const { updatedProducts, updatedEI = [] } = updateSynjetProProducts(
        updatedSteps,
        products,
        quenching,
        expectedIntermediates
      );
      handleExperimentInfoUpdate(experimentIndex, updatedSteps, updatedProducts, updatedEI);
    } else {
      const updatedSteps = updateSynjetProcessSteps(
        steps,
        value,
        isSynjet,
        conditionName,
        temperatureOptions,
        experimentIndex,
        type,
        conditionIndex,
        showConditionSelector
      );
      handleExperimentInfoUpdate(experimentIndex, updatedSteps);
    }
  };

  const handleConditionsChange = (value, experimentIndex, condition, type) => {
    if (value === null) value = TEMPERATURE_LIMITS.MIN;
    const updatedTemperatureOption = temperatureOptions.map((option, index) =>
      index === experimentIndex
        ? {
            ...temperatureOptions[index],
            [condition]: {
              ...temperatureOptions[index][condition],
              [type]: value,
            },
          }
        : option
    );
    setTemperatureOptions(updatedTemperatureOption);
  };

  useEffect(() => {
    if (!experiments) return;
    if (isPro) setConditionsErrors(null);
    const tempOptions = experiments.map((experiment, experimentIndex) =>
      getExperimentTimeConditions(
        experiment,
        experimentsInfo?.[experimentIndex]?.steps,
        experimentsInfo?.[experimentIndex]?.processSteps
      )
    );
    setTemperatureOptions(tempOptions);
    if (!initialTemperatureOptions) setInitialTemperatureOptions(tempOptions);
    setTotalRequiredVialQuantity(getTotalRequiredVialQuantity(tempOptions));
    setTotalRequiredVialPositionQuantity(getTotalRequiredVialPositionQuantity(tempOptions, experimentsInfo, isPro));
    setTotalHeatingBlockQuantity(getTotalHeatingBlockQuantity(tempOptions, isPro));
    if (isPro) {
      const uniqueTemperatures = getUniqueTemperatures(tempOptions);
      if (uniqueTemperatures.length > SYNJET_PRO_MAX_HEATING_BLOCKS) {
        setLastUniqueTemperature(uniqueTemperatures[0]);
      } else {
        setLastUniqueTemperature(null);
      }
      const newHeatingBlocks = getHeatingBlocks(tempOptions);
      setDefaultHeatingBlocks(newHeatingBlocks);
      if (regenerateHeatingBlocks || !heatingBlocks.length) {
        setHeatingBlocks(newHeatingBlocks);
        setRegenerateHeatingBlocks(false);
      }
    }
  }, [experimentsInfo]);

  useEffect(() => {
    if (totalHeatingBlockQuantity > maxHeatingBlocksCount) {
      openNotification(
        null,
        null,
        5,
        'warning',
        null,
        `The number of Heating blocks required for the Batch execution exceeds the limit of ${maxHeatingBlocksCount}`
      );
    }
  }, [totalHeatingBlockQuantity, maxHeatingBlocksCount]);

  const handleHeatingBlocksChange = (name, temperature) => {
    const updatedBlocks = heatingBlocks.map(block => (block.label === name ? { ...block, temperature } : block));
    setHeatingBlocks(updatedBlocks);
  };

  const handleDefaultHeatingBlocks = () => {
    setHeatingBlocks(defaultHeatingBlocks);
  };

  const getIsConditionError = temperature => lastUniqueTemperature === temperature?.[1]?.temperature;

  const isVialsWereExcludedByRule = useCallback(
    (temperature, experimentIndex) => {
      if (!initialTemperatureOptions) return false;
      const initialExperimentOption = initialTemperatureOptions?.[experimentIndex];
      const conditionalName = temperature[0];
      const conditionalValue = temperature[1];
      const initialVials = initialExperimentOption?.[conditionalName]?.vials;
      const currentVials = conditionalValue?.vials;
      return initialVials !== currentVials;
    },
    [initialTemperatureOptions]
  );

  const isVialWasExcludedInitially = useCallback(
    (temperature, experimentIndex) => {
      if (!initialTemperatureOptions) return false;
      const initialExperimentOption = initialTemperatureOptions?.[experimentIndex];
      const conditionalName = temperature[0];
      const initialVials = initialExperimentOption?.[conditionalName]?.vials;
      return !initialVials;
    },
    [initialTemperatureOptions]
  );

  const getTooltipText = (hasError, isVialsExcluded, isInitiallyExcluded) => {
    if (isVialsExcluded) return VIALS_WERE_EXCLUDED;
    if (hasError) return UNIQUE_TEMP_COUNT_ERROR;
    if (isInitiallyExcluded) return INITIALLY_EXCLUDED_VIAL;
    return '';
  };
  return (
    <div className="conditions-hardware">
      <div className="container">
        <div className="conditions-hardware_row">
          <div className="conditions-hardware-summary">
            <div className="conditions-hardware-summary__header">
              <h5 />
              <div className="conditions-hardware-summary__header__row">
                <h5 className="conditions-hardware-summary__header__text">Temperature, C°</h5>
                <h5 className="conditions-hardware-summary__header__text">Vials</h5>
              </div>
            </div>
            <div className="conditions-hardware-summary__body">
              {experiments &&
                experiments.map((experiment, experimentIndex) => (
                  <div className="experiment-condition">
                    <div
                      className={cn('experiment-condition__container', getExperimentContainerStyle(experimentIndex))}
                    >
                      <h5 className="experiment-condition__title">{`Experiment ${experiment.name}`}</h5>
                    </div>
                    <div className="experiment-condition__temperature">
                      {temperatureOptions[experimentIndex] &&
                        Object.entries(temperatureOptions[experimentIndex]).map((temperature, index, array) => {
                          const limitsValues = {};
                          if (temperatureOptions[experimentIndex].low) {
                            Object.keys(temperatureOptions[experimentIndex]).forEach(k => {
                              limitsValues[k] = temperatureOptions[experimentIndex][k].temperature;
                            });
                          }
                          const hasError = getIsConditionError(temperature);
                          const isVialsExcluded = isVialsWereExcludedByRule(temperature, experimentIndex);
                          const isInitiallyExcluded = isVialWasExcludedInitially(temperature, experimentIndex);
                          return (
                            <>
                              <div className="experiment-condition__row">
                                <div className="temperature">
                                  <p
                                    className={cn('condition-title', {
                                      error: hasError,
                                    })}
                                  >
                                    {getTemperatureTitle(temperature)}
                                  </p>
                                  {showConditionSelector(temperatureOptions[experimentIndex], 'temperature') &&
                                  showConditionSelectorByIndex(index, experimentIndex) ? (
                                    <Select
                                      className="time-select"
                                      options={getUniqueCondition(temperatureOptions[experimentIndex], 'temperature')}
                                      onChange={value =>
                                        handleConditionsBlur(
                                          value,
                                          experimentIndex,
                                          index,
                                          'temperature',
                                          temperature?.[0]
                                        )
                                      }
                                      value={temperature?.[1]?.temperature}
                                      showSearch={false}
                                    />
                                  ) : (
                                    <Input
                                      value={temperature?.[1]?.temperature}
                                      type="number"
                                      integer
                                      placeholder="0"
                                      error={hasError}
                                      min={
                                        limitsValues.low
                                          ? getMinMaxTemperatureValues(temperature?.[0], limitsValues).min
                                          : TEMPERATURE_LIMITS.MIN
                                      }
                                      max={
                                        limitsValues.low
                                          ? getMinMaxTemperatureValues(temperature?.[0], limitsValues).max
                                          : TEMPERATURE_LIMITS.MAX
                                      }
                                      step={5}
                                      className="condition-input"
                                      onChange={value =>
                                        handleConditionsChange(value, experimentIndex, temperature?.[0], 'temperature')
                                      }
                                      onBlur={e =>
                                        handleConditionsBlur(e, experimentIndex, index, 'temperature', temperature?.[0])
                                      }
                                      disabled={isInitiallyExcluded}
                                    />
                                  )}
                                  {(hasError || isVialsExcluded || isInitiallyExcluded) && (
                                    <Tooltip
                                      title={getTooltipText(hasError, isVialsExcluded, isInitiallyExcluded)}
                                      className="tooltip"
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  )}
                                </div>
                                <p className="vials_count">{temperature?.[1]?.vials}</p>
                              </div>
                              {array.length - 1 === index && (
                                <div className="experiment-condition__row">
                                  <p className="condition-subtitle">Required quantity:</p>
                                  <div className="tooltip-container">
                                    <p className="condition-title">
                                      {getRequiredVialQuantity(temperatureOptions[experimentIndex])}
                                    </p>
                                    {!isPro && (
                                      <Tooltip title="Including 1 prime vial" className="tooltip">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={cn('conditions-hardware-summary', 'conditions-hardware-summary__time')}>
            <div className={cn('conditions-hardware-summary__header', 'conditions-hardware-summary__time__header')}>
              <h5 className="conditions-hardware-summary__header__text">Time, hh:mm</h5>
            </div>
            <div className="conditions-hardware-summary__body">
              {experiments &&
                experiments.map((experiment, experimentIndex) => (
                  <div className="experiment-condition">
                    <div className="experiment-condition__time">
                      {temperatureOptions[experimentIndex] &&
                        Object.entries(temperatureOptions[experimentIndex]).map((option, index) => (
                          <>
                            <div className="experiment-condition__row">
                              <p className="condition-title">
                                {option?.[0].toLowerCase().startsWith('cond')
                                  ? `${option?.[0]?.slice(0, 4)} ${option?.[0].substr(4)}`
                                  : option[0]}
                              </p>
                              {showConditionSelector(temperatureOptions[experimentIndex], 'time') &&
                              showConditionSelectorByIndex(index, experimentIndex) ? (
                                <Select
                                  className="time-select"
                                  options={getUniqueCondition(temperatureOptions[experimentIndex], 'time')}
                                  onChange={value =>
                                    handleConditionsBlur(
                                      moment(value, 'HH:mm'),
                                      experimentIndex,
                                      index,
                                      'time',
                                      option[0]
                                    )
                                  }
                                  value={option[1].time.format('HH:mm')}
                                  showSearch={false}
                                />
                              ) : (
                                <Time
                                  clearIcon={() => null}
                                  minuteStep={15}
                                  showNow={false}
                                  value={option[1].time}
                                  onChange={value =>
                                    handleConditionsBlur(value, experimentIndex, index, 'time', option[0])
                                  }
                                  disabledHours={() => getDisabledHours(option[0], temperatureOptions[experimentIndex])}
                                  disabledMinutes={selectedHour =>
                                    getDisabledMinutes(option[0], selectedHour, temperatureOptions[experimentIndex])
                                  }
                                  disabled={isVialWasExcludedInitially(option, experimentIndex)}
                                />
                              )}
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {isPro && (
          <HeatingBlock
            heatingBlocks={heatingBlocks}
            temperatureOptions={temperatureOptions}
            handleHeatingBlocksChange={handleHeatingBlocksChange}
            setDefaultHeatingBlocks={handleDefaultHeatingBlocks}
            setConditionsErrors={setConditionsErrors}
          />
        )}
      </div>

      <div className="conditions-hardware__summary">
        <h5 className="conditions-hardware__summary__title">Summary</h5>
        <h5 />
        <h5 className="conditions-hardware__summary__subtitle">Required quantity of vials:</h5>
        <h5 className="conditions-hardware__summary__title">{totalRequiredVialQuantity}</h5>
        <h5 className="conditions-hardware__summary__subtitle">Required quantity of vial positions:</h5>
        <h5 className="conditions-hardware__summary__title">{totalRequiredVialPositionQuantity}</h5>
        <h5 className="conditions-hardware__summary__subtitle">Required quantity of heating blocks:</h5>
        <h5
          className={cn('conditions-hardware__summary__title', {
            'conditions-hardware__summary__error': totalHeatingBlockQuantity > maxHeatingBlocksCount,
          })}
        >
          {totalHeatingBlockQuantity}
        </h5>
      </div>
    </div>
  );
};
