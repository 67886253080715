import './styles.scss';

import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { Checkbox, Divider, Input, Popup, Select, Spinner, StatusLabel } from 'components/Common';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateExperimentField } from 'store/experimentField/experimentField.actions';
import { initialState } from 'store/experimentField/experimentField.reducer';
import { experimentField } from 'store/experimentField/experimentField.selector';
import { getListUsers } from 'store/users/users.actions';
import { getStrTagPlaceholder } from 'utils';
import { v4 as uuidv4 } from 'uuid';

import { ComplexCheckbox, DropdownSection, FormAttribute } from '@organisms';

import {
  FIELD_ATTRIBUTE,
  FIELD_DATA_FOR_DROPDOWN,
  FIELD_DATA_TYPES,
  INITIAL_DEFAULT_VALUE,
  INITIAL_ERROR_MESSAGE,
  INITIAL_OPTION,
  TYPE_FOR_DEFAULT_FIELD,
} from './constants';

export const ExperimentTemplateFieldPopup = ({
  setExperimentFieldPopup,
  editing,
  onSubmit,
  loading,
  onArchivate = () => {},
  setFieldID = () => {},
}) => {
  const experimentFieldData = useSelector(experimentField);
  const [data, setData] = useState(experimentFieldData);

  const IS_ARCHIVED = experimentFieldData.archived;
  const IS_SYSTEMIC = experimentFieldData.systemic;
  const IS_UNEDITABLE = (editing && !data.editable) || IS_SYSTEMIC;
  const IS_DROPDOWN = data.dataType === FIELD_DATA_TYPES.select;
  const IS_MULTISELECT = data.dataType === FIELD_DATA_TYPES.multiselect;
  const IS_USERLIST = data.dataType === FIELD_DATA_TYPES.user;
  const IS_CHECKBOX = data.dataType === FIELD_DATA_TYPES.boolean;
  const DEFAULT_DATA = data.defaultField;

  const [errorMessage, setErrorMessage] = useState(INITIAL_ERROR_MESSAGE);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([{ ...INITIAL_OPTION, key: uuidv4() }]);
  const [optionsForDefault, setOptionsForDefault] = useState();
  const [colorCoding, setColorCoding] = useState(data.colorCoding);
  const [defaultOptionElements, setDefaultOptionElements] = useState();
  const [defaultValueElement, setDefaultValueElement] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setData(experimentFieldData);
    setDropdownOptions(experimentFieldData.dropdownOptions);
    setColorCoding(experimentFieldData.colorCoding);
  }, [experimentFieldData]);

  const getDefaultOptionElements = optionArr =>
    optionArr.map(option => (
      <div key={option?.key}>
        <div className="select-color">
          {option?.color && <div className="select-color-dropdown" style={{ background: `${option?.color}` }} />}
          <div>{option?.label || option?.value}</div>
        </div>
      </div>
    ));

  useEffect(() => {
    if (IS_DROPDOWN || IS_MULTISELECT) {
      const newOptionsForDefault = dropdownOptions.filter(option => {
        const value = typeof option?.value === 'number' ? option?.value.toString() : option?.value.trim();
        if (colorCoding) return value?.length && option.color.trim()?.length;

        return value?.length;
      });
      const defaultOptions = getDefaultOptionElements(newOptionsForDefault);
      setOptionsForDefault(newOptionsForDefault);
      setDefaultOptionElements(defaultOptions);
      setData({ ...data, dropdownOptions });
    }
  }, [dropdownOptions]);

  useEffect(() => {
    let defaultElement = DEFAULT_DATA?.value;
    if (defaultElement) {
      if (IS_DROPDOWN) {
        defaultElement = getDefaultOptionElements(DEFAULT_DATA?.value)[0];
      } else if (IS_MULTISELECT) {
        defaultElement = DEFAULT_DATA.value.map(option => option.key);
      } else if (IS_USERLIST) {
        defaultElement = DEFAULT_DATA?.value[0]?.label;
      }
    }
    setDefaultValueElement(defaultElement);
  }, [data.defaultField]);

  const onChangeAttributeValue = (value, type) => {
    const IS_HINT = type == FIELD_ATTRIBUTE.hint;
    const IS_UNIQUE = type == FIELD_ATTRIBUTE.unique;
    const IS_DATATYPE = type == FIELD_ATTRIBUTE.dataType;
    const IS_DEFAULT_VALUE = type == FIELD_ATTRIBUTE.defaultField;
    let newData = { [type]: value };
    if (IS_HINT) {
      newData = { [type]: { ...value } };
    } else if ((IS_UNIQUE && value) || IS_DATATYPE) {
      newData = { [type]: value, defaultField: { ...INITIAL_DEFAULT_VALUE } };
      if (IS_DATATYPE) {
        setDropdownOptions([{ ...INITIAL_OPTION, key: uuidv4() }]);
        setOptionsForDefault([]);
        setColorCoding(false);
      }
    } else if (IS_DEFAULT_VALUE) {
      if (value.checkbox) {
        newData = { [type]: { ...value }, unique: false };
      } else newData = { [type]: { ...value } };
    }
    if (errorMessage.hasOwnProperty(type)) setErrorMessage({ ...errorMessage, [type]: '' });
    setData({ ...data, ...newData });
  };

  const onChangeComplexValue = (fieldValue, field, type, selectData) => {
    let value = fieldValue;
    if (field != 'checkbox' && type != FIELD_ATTRIBUTE.hint) {
      if (Array.isArray(selectData)) {
        selectData = selectData.filter(option => !!option?.value);
        value = selectData.map(option => {
          const correctOption = optionsForDefault.find(item => item.key == option.key);
          return { key: option.key, value: correctOption.value, color: correctOption.color };
        });
      } else if (IS_DROPDOWN && selectData) {
        const correctOption = optionsForDefault.find(item => item.key == selectData.key);
        value = [{ key: selectData.key, value: correctOption.value, color: correctOption.color }];
      } else if (IS_USERLIST) {
        value = [{ ...selectData }];
      }
    }
    const objValue = field == 'checkbox' ? { [field]: value, value: '' } : { ...data[type], [field]: value };
    onChangeAttributeValue(objValue, type);
  };

  const messageForValidation = (data, min, max, option) => {
    const symbolLength = data?.trim().length;
    let message = '';
    if (min && max) {
      if (symbolLength < min) message = `Min allowed number of characters: ${min}`;
      else if (symbolLength > max) message = `Max allowed number of characters: ${max}`;
    } else if (!symbolLength && option) message = `Please choose a ${option}`;
    else if (!symbolLength) message = 'Mandatory fields missing';
    return message;
  };

  const validationAttribute = data => {
    let success = true;
    const nameMessage = messageForValidation(data.name, 1, 25);
    const hintTextMessage = data.hint.checkbox ? messageForValidation(data.hint.value, 1, 50) : '';
    const dataTypeMessage = messageForValidation(data.dataType);
    const defaultFieldMessage =
      DEFAULT_DATA.checkbox && !IS_CHECKBOX && !DEFAULT_DATA.value?.length > 0 ? messageForValidation('') : '';

    const newErrorMessage = {
      name: nameMessage,
      hint: hintTextMessage,
      dataType: dataTypeMessage,
      defaultField: defaultFieldMessage,
    };
    if (IS_DROPDOWN || IS_MULTISELECT) {
      const newDropdownOptions = dropdownOptions.map((option, index) => {
        const nonUnique = dropdownOptions.some((item, i) => item.value == option.value && i != index);
        return {
          ...option,
          errorMessage: nonUnique ? 'Dropdown option should be unique' : messageForValidation(option.value, 1, 25),
          errorMessageForColor: colorCoding ? messageForValidation(option.color, null, null, 'color') : '',
        };
      });
      setDropdownOptions(newDropdownOptions);
      const dropdownOptionsError = newDropdownOptions.some(
        option => option.errorMessage || (option.errorMessageForColor && colorCoding)
      );
      if (dropdownOptionsError) success = false;
    }
    setErrorMessage(newErrorMessage);
    for (const key in newErrorMessage) {
      if (newErrorMessage[key]) success = false;
    }
    return success;
  };

  const handleSubmit = () => {
    const successValidation = IS_SYSTEMIC ? true : validationAttribute(data);
    if (successValidation) {
      const availableOptions =
        IS_DROPDOWN || IS_MULTISELECT
          ? optionsForDefault?.map(({ value, color }) => ({ value, label: value, color }))
          : '';
      let defaultValueForSave = DEFAULT_DATA?.value;
      if (DEFAULT_DATA?.checkbox) {
        if (IS_DROPDOWN || IS_MULTISELECT) {
          defaultValueForSave = defaultValueForSave?.map(({ value, color }) => ({ value, label: value, color }));
        }
        defaultValueForSave = IS_DROPDOWN
          ? defaultValueForSave[0]
          : IS_USERLIST
          ? defaultValueForSave[0].value
          : defaultValueForSave;
      }
      if (!defaultValueForSave) defaultValueForSave = 'null';
      if (DEFAULT_DATA.checkbox && data.dataType === FIELD_DATA_TYPES.boolean) {
        defaultValueForSave = !!DEFAULT_DATA?.value;
      }
      const dataForSave = {
        uuid: data.uuid,
        tableName: '',
        name: encodeURIComponent(data.name),
        dataType: data.dataType,
        editable: data.editable,
        unique: data.unique,
        visibleInDetails: data.visible,
        addUpdatesToHistory: data.updateHistory,
        hint: encodeURIComponent(data.hint.value),
        defaultValue: defaultValueForSave,
        availableOptions,
        systemic: data.systemic,
      };
      onSubmit(dataForSave);
    } else return;
  };

  const getUsersList = search =>
    dispatch(getListUsers(1, 30, search)).then(resp => {
      const response = resp?.map(process => ({
        value: process.uuid,
        label: `${process?.firstName} ${process?.lastName}`,
      }));
      setOptionsForDefault(response);
      return response;
    });

  const updateField = field => {
    setData({ ...data, ...field });
  };

  const archivateField = () => {
    onArchivate(experimentFieldData.uuid, !experimentFieldData.archived);
  };

  const handleCancel = () => {
    if (!_.isEqual(data, experimentFieldData)) setOpenCancelConfirmation(true);
    else closePopup(true);
  };

  const closePopup = notChanges => {
    if (!notChanges) setOpenCancelConfirmation(false);
    setFieldID('');
    setExperimentFieldPopup(false);
    dispatch(setUpdateExperimentField(initialState));
  };

  return (
    <>
      <Popup
        title={editing ? 'Edit field' : 'Add field'}
        open
        className={cn('popup-experiment', { 'popup-experiment-editing': editing })}
        textCancle="Cancel"
        textThirdButton="Save"
        handleThirdButton={handleSubmit}
        notLoadingThirdButton
        textFirstButton={IS_ARCHIVED ? 'Activate field' : 'Archive field'}
        handleFirstButton={editing && archivateField}
        handleCancel={handleCancel}
        loading={loading}
      >
        <Spinner loading={loading}>
          {IS_ARCHIVED && (
            <StatusLabel type="passive" className="status-label-archive">
              Archived
            </StatusLabel>
          )}
          <FormAttribute title="Name" require>
            {IS_UNEDITABLE ? (
              data.name
            ) : (
              <Input
                value={data.name}
                onChange={value => {
                  onChangeAttributeValue(value, FIELD_ATTRIBUTE.name);
                }}
                error={errorMessage.name.length}
                errorText={errorMessage.name}
                className={cn({ 'attribute-error': errorMessage.name.length })}
                allowSpecials
              />
            )}
          </FormAttribute>
          <Divider />
          <FormAttribute require title="Data type">
            <Select
              showArrow
              options={FIELD_DATA_FOR_DROPDOWN}
              showSearch
              disabled={editing}
              value={data.dataType}
              error={errorMessage.dataType.length}
              errorText={errorMessage.dataType}
              onChange={(value, args) => {
                onChangeAttributeValue(value, FIELD_ATTRIBUTE.dataType, args);
              }}
            />
          </FormAttribute>
          {(IS_DROPDOWN || IS_MULTISELECT) && dropdownOptions.length && (
            <>
              <DropdownSection
                dropdownOptions={dropdownOptions}
                type="colorCoding"
                setDropdownOptions={setDropdownOptions}
                defaultFields={DEFAULT_DATA.value}
                setData={updateField}
                uneditabled={IS_UNEDITABLE}
                colorCoding={colorCoding}
                setColorCoding={setColorCoding}
              />
            </>
          )}
          <Divider />
          <FormAttribute title="Editable">
            <Checkbox
              field={FIELD_ATTRIBUTE.editable}
              value={data.editable}
              onChange={onChangeAttributeValue}
              disabled={IS_SYSTEMIC}
            >
              Yes
            </Checkbox>
          </FormAttribute>
          <Divider />
          <FormAttribute title="Unique">
            <Checkbox
              field={FIELD_ATTRIBUTE.unique}
              disabled={editing}
              value={data.unique}
              onChange={onChangeAttributeValue}
            >
              Yes
            </Checkbox>
          </FormAttribute>
          <Divider />
          {!IS_SYSTEMIC && (
            <>
              <ComplexCheckbox
                title="Hint text"
                value={data.hint.checkbox}
                field="checkbox"
                uneditabled={IS_UNEDITABLE}
                onChange={(value, field) => {
                  onChangeComplexValue(value, field, FIELD_ATTRIBUTE.hint);
                }}
              >
                <FormAttribute>
                  <Input
                    value={data.hint.value}
                    onChange={value => {
                      onChangeComplexValue(value, 'value', FIELD_ATTRIBUTE.hint);
                    }}
                    disabled={IS_UNEDITABLE}
                    error={errorMessage.hint.length}
                    errorText={errorMessage.hint}
                    placeholder="Type hint text there"
                    className={cn({ 'attribute-error': errorMessage.hint.length })}
                    allowSpecials
                  />
                </FormAttribute>
              </ComplexCheckbox>
              <Divider />
            </>
          )}
          {TYPE_FOR_DEFAULT_FIELD.includes(data.dataType) && (
            <>
              <ComplexCheckbox
                title="Default value"
                value={DEFAULT_DATA.checkbox}
                field="checkbox"
                uneditabled={IS_UNEDITABLE || (editing && data.unique)}
                onChange={(value, field) => {
                  onChangeComplexValue(value, field, FIELD_ATTRIBUTE.defaultField);
                }}
              >
                <FormAttribute>
                  {IS_CHECKBOX ? (
                    <Checkbox
                      value={DEFAULT_DATA.value}
                      onChange={value => {
                        onChangeComplexValue(value, 'value', FIELD_ATTRIBUTE.defaultField);
                      }}
                      disabled={IS_UNEDITABLE}
                    >
                      Checked
                    </Checkbox>
                  ) : (
                    <Select
                      showArrow
                      optionElement={!IS_USERLIST}
                      maxTagCount={0}
                      mode={IS_MULTISELECT ? 'multiple' : ''}
                      options={optionsForDefault}
                      showSearch
                      disabled={IS_UNEDITABLE}
                      getOptions={search => getUsersList(search)}
                      typeToSearch={IS_USERLIST}
                      value={defaultValueElement || null}
                      error={errorMessage.defaultField.length}
                      errorText={errorMessage.defaultField}
                      maxTagPlaceholder={opts =>
                        getStrTagPlaceholder(
                          opts.filter(opt => opt?.value),
                          optionsForDefault,
                          'options',
                          true
                        )
                      }
                      onChange={(value, args) => {
                        onChangeComplexValue(value, 'value', FIELD_ATTRIBUTE.defaultField, args);
                      }}
                    >
                      {!IS_USERLIST && defaultOptionElements}
                    </Select>
                  )}
                </FormAttribute>
              </ComplexCheckbox>
              <Divider />
            </>
          )}
          <FormAttribute title="Add updates to History">
            <Checkbox
              field={FIELD_ATTRIBUTE.updateHistory}
              value={data.updateHistory}
              onChange={onChangeAttributeValue}
            >
              Yes
            </Checkbox>
          </FormAttribute>
          <Divider />
          {!IS_SYSTEMIC && (
            <>
              <FormAttribute title="Visible in details">
                <Checkbox
                  field={FIELD_ATTRIBUTE.visible}
                  value={data.visible}
                  onChange={onChangeAttributeValue}
                  disabled={IS_UNEDITABLE}
                >
                  Yes
                </Checkbox>
              </FormAttribute>
              <Divider />
            </>
          )}
        </Spinner>
      </Popup>
      <Popup
        open={openCancelConfirmation}
        title="Discard changes"
        textCancle="Cancel"
        textSubmit="Confirm"
        handleCancel={() => {
          setOpenCancelConfirmation(false);
        }}
        handleSubmit={closePopup}
      >
        <div>Discard unsaved changes?</div>
      </Popup>
    </>
  );
};
