import React, { useState, useEffect } from 'react';
import { Popup, RadioGroup, Sidebar, openNotification } from '../../Common';
import './style.scss';
import { checkIfTrue } from '../../../utils';
import { SmilesSearcher } from '../../SmilesSearcher';

const options = [
  { label: 'Expected intermediate', value: 'Expected intermediate' },
  { label: 'Side product', value: 'Side product' },
];

const initial = {
  type: options[0].label,
  side_products: [],
  intermediate: [],
};

const initialErrors = {
  intermediate: false,
  sideproducts: false,
};

const errorsText = {
  intermediate: 'Expected intermediate is a required field',
  sideproducts: 'Side products is a required field',
};

export const SidebarReactorCompound = ({
  open,
  onClose,
  setReactorCompoundData,
  editingData,
  onDelete,
  disableIntermediate,
  sidebarCompoundPumpIndex,
}) => {
  const [data, setData] = useState(initial);
  const [errors, setErrors] = useState(initialErrors);
  const [sideProducts, setSideProduct] = useState([null]);
  const [intermediate, setIntermediate] = useState(null);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [cancelConfirmation, openCancelConfirmation] = useState(false);
  const [edited, setEdited] = useState(false);

  const [optionsTypes, setOptionsTypes] = useState(options);

  useEffect(() => {
    if (disableIntermediate?.length) {
      setOptionsTypes([
        { label: 'Expected intermediate', value: 'Expected intermediate', disabled: true },
        { label: 'Side product', value: 'Side product' },
      ]);
    }
  }, [disableIntermediate]);

  useEffect(() => {
    if (disableIntermediate?.length && !editingData) {
      setData({ ...data, type: 'Side product' });
    }
  }, [optionsTypes]);

  const reset = () => {
    setData(initial);
    setErrors(initialErrors);
    setSideProduct([null]);
    setIntermediate(null);
    setShowAddProduct(false);
    setEdited(false);
  };

  useEffect(() => {
    if (editingData) {
      if (editingData.compound_id) {
        setData({
          type: 'Expected intermediate',
        });
        setIntermediate(editingData);
      } else {
        setData({
          type: 'Side product',
        });
        setSideProduct(editingData);
        setShowAddProduct(true);
      }
    } else {
      reset();
    }
  }, [editingData]);

  const onSave = () => {
    let obj = {};
    const isInvalid = validation(data.type);
    if (!isInvalid) {
      if (data.type === 'Expected intermediate') {
        obj = {
          intermediate,
        };
      } else {
        obj = {
          sideproducts: sideProducts.filter(i => !!i),
        };
      }
      setReactorCompoundData(obj);
      setEdited(false);
      setErrors(initialErrors);
    }
  };

  const validation = type => {
    let t;
    type == 'Expected intermediate' ? (t = 'intermediate') : (t = 'sideproducts');
    const obj = {
      intermediate: {
        intermediate: !intermediate,
      },
      sideproducts: { sideproducts: !sideProducts[0] },
    };
    setErrors(obj);
    return checkIfTrue(obj[t]);
  };

  const onCloseSidebar = () => {
    if (!edited) onClose();
    else openCancelConfirmation(true);
  };

  const onChangeType = (v, field) => {
    reset();
    setData({ ...data, [field]: v });
  };

  const canSave = (edited && editingData) || !editingData;

  return (
    <>
      <Sidebar
        width={528}
        open={open}
        onClose={onCloseSidebar}
        onSave={onSave}
        disableSave={!canSave}
        field="type"
        title={`${editingData ? 'Edit' : 'Add'} a compound`}
        onDelete={onDelete}
        editingData={!!editingData}
        cancelText={edited ? 'Cancel' : 'Close'}
      >
        <div className="reactor-compound-wrapper">
          <div className="compound-wrapper">
            <div className="row">
              <div className="column-title">
                Product type <span className="required">*</span>
              </div>
              <RadioGroup
                value={data.type}
                options={optionsTypes}
                setValue={onChangeType}
                field="type"
                disabled={!!editingData}
              />
            </div>
            {data.type === 'Expected intermediate' && (
              <div className="column">
                <div className="column-title">
                  Expected intermediate {sidebarCompoundPumpIndex || ''}
                  <span className="required">*</span>
                </div>
                <div className="search-synmol">
                  <SmilesSearcher
                    onDeleteAction={() => {
                      setEdited(true);
                      setIntermediate(null);
                    }}
                    changeSelect={e => {
                      if (e?.compound_id) {
                        setEdited(true);
                        setIntermediate(e);
                      }
                      setErrors({ ...errors, intermediate: false });
                    }}
                    data={intermediate}
                    error={errors.intermediate}
                    errorText={errorsText.intermediate}
                    resetError={() => {
                      setErrors({ ...errors, intermediate: false });
                    }}
                  />
                </div>
              </div>
            )}
            {data.type === 'Side product' && (
              <div>
                <div className="column">
                  <div className="column-title">
                    Side product <span className="required">*</span>
                    {sidebarCompoundPumpIndex || ''}
                  </div>
                  <div className="search-synmol">
                    {sideProducts.length > 0 &&
                      sideProducts.map((i, index) => (
                        <div className="searcher-space">
                          <SmilesSearcher
                            onDeleteAction={() => {
                              setEdited(true);
                              const new_products = [...sideProducts].filter((i, idx) => idx !== index);
                              setSideProduct(new_products.length > 0 ? new_products : [null]);
                            }}
                            changeSelect={e => {
                              setEdited(true);
                              const new_products = [...sideProducts];
                              new_products[index] = e;
                              setSideProduct(new_products);
                              setShowAddProduct(true);
                              setErrors({ ...errors, sideproducts: false });
                            }}
                            resetError={() => {
                              setErrors({ ...errors, sideproducts: false });
                            }}
                            data={i}
                            error={errors.sideproducts}
                            errorText={errorsText.sideproducts}
                          />
                        </div>
                      ))}
                  </div>
                  {showAddProduct && sideProducts.every(product => !!product) && (
                    <div
                      className="add-product"
                      onClick={() => {
                        const temp = [...sideProducts];
                        temp.push(null);
                        setEdited(true);
                        setSideProduct(temp);
                        setShowAddProduct(false);
                      }}
                    >
                      + add side-product
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Sidebar>
      <Popup
        open={cancelConfirmation}
        title="Warning"
        handleSubmit={() => {
          onClose();
          reset();
          openCancelConfirmation(false);
        }}
        handleCancel={() => {
          openCancelConfirmation(false);
        }}
        textSubmit="Proceed"
        loading={false}
      >
        <span>All changes will be lost, proceed?</span>
      </Popup>
    </>
  );
};
