import React, { useState, useEffect } from 'react';
import './style.scss';
import { Popup } from '../Common';
import { Compound } from '../ProcessBuilder/WorkSpace/Compound';

export const PopupWithStructureView = ({ openPopup, data, closePopup, label }) => {
  return (
    <Popup
      title="Structure preview"
      open={openPopup}
      className="structure-view-popup"
      handleCancel={closePopup}
      textCancle="Close"
    >
      <div>
        <div className="compound-info">
          {data.compound_name}
          <br />
          {data.compound_id}
          <br />
          {data.smiles}
          <br />
          {data.inchi}
        </div>
        <div className="divider" />
        <Compound data={data} width={432} height={210} label={`${label}-scale`} />
      </div>
    </Popup>
  );
};
