import React from 'react';
import cn from 'classnames';
import { Compound } from './Compound';
import { AddComponentButton } from './AddComponentButton';
import { Tooltip } from '../../Common';
import { COMPOUND_VARIATY } from '../../../constants';

const ReactorCompoundList = ({ reactorProperties, changeCompound, reactorIdx, openCompoundAddToReactor }) => {
  const handleSlideProductChange = (e, property, propertyIndex) => {
    e.stopPropagation();
    changeCompound(property.sideproducts, reactorIdx, -1, null, propertyIndex, reactorProperties.length > 1);
  };
  return (
    <div className={cn('reactor-compounds-list')}>
      {reactorProperties.map((property, propertyIndex) => {
        const compoundsLength = property.products?.length || 0;
        const sideproductsLength = property.sideproducts?.length || 0;
        return (
          <div
            className={cn(
              'reactor-right-block',
              { empty: !compoundsLength && !sideproductsLength },
              { 'left-separator': propertyIndex < reactorProperties.length - 1 && reactorProperties.length > 1 }
            )}
          >
            {reactorProperties.length > 1 && (
              <div className={cn('variation-container')}>
                <span className="variation-label">{COMPOUND_VARIATY[propertyIndex]}</span>
              </div>
            )}
            {compoundsLength > 0 &&
              property.products.map((product, idx) => (
                <div
                  key={idx}
                  className={cn('compound-block', {
                    'bottom-separator':
                      ((idx < compoundsLength - 1 && !sideproductsLength) ||
                        (idx < compoundsLength && !!sideproductsLength)) &&
                      compoundsLength + sideproductsLength > 1,
                  })}
                >
                  <div className="compound-block-content" style={{ width: 118 }}>
                    {product && (
                      <Compound
                        data={product}
                        label={`${reactorIdx}_${propertyIndex}`}
                        width={100}
                        padding={12}
                        height={40}
                        changeCompound={() =>
                          changeCompound(product, reactorIdx, idx, null, propertyIndex, reactorProperties.length > 1)
                        }
                        displayLoupe
                      />
                    )}
                  </div>
                </div>
              ))}
            {sideproductsLength > 0 && (
              <Tooltip
                title={property.sideproducts
                  ?.filter(i => i)
                  ?.map(i => i?.compound_name)
                  .join(', ')}
              >
                <div
                  className="compound-block sideproducts"
                  onClick={e => handleSlideProductChange(e, property, propertyIndex)}
                >
                  <div className="compound-block-content grey">
                    {sideproductsLength} side product
                    {sideproductsLength === 1 ? '' : 's'}
                  </div>
                </div>
              </Tooltip>
            )}
            {(!compoundsLength || !sideproductsLength) && (
              <div className={cn('add-compound-button', { 'not-always-visible': compoundsLength > 0 })}>
                <AddComponentButton
                  type=""
                  addAction={() => openCompoundAddToReactor(propertyIndex, reactorProperties.length > 1)}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReactorCompoundList;
