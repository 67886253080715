import { Glassware, Microwave, Heating, Other, ParrShaker, Cooling } from '../components/Common';

export const INITIAL_MANUAL_STATE = {
  glassware: null,
  inputs: [],
  equipment: null,
};

export const GLASSWARE_TYPES = {
  glassware: {
    icon: Glassware,
  },
};

export const EQUIPMENT_TYPES = {
  heating_bath: {
    icon: Heating,
    name: 'Heating bath/mantle',
    beName: 'Heating bath',
    shortLabel: 'HB',
    temperaturePlaceholder: '20 - 300',
  },
  cooling_bath: {
    icon: Cooling,
    name: 'Cooling bath',
    beName: 'Cooling bath',
    shortLabel: 'CB',
    temperaturePlaceholder: '-100 - 20',
  },
  parr_shaker: {
    icon: ParrShaker,
    name: 'Parr shaker',
    beName: 'Parr shaker',
    shortLabel: 'PS',
    temperaturePlaceholder: '20 - 200',
  },
  microwave: {
    icon: Microwave,
    name: 'Microwave',
    beName: 'Microwave',
    shortLabel: 'MI',
    temperaturePlaceholder: '20 - 300',
  },
  other: {
    icon: Other,
    name: 'Other',
    beName: 'Other',
    shortLabel: 'OT',
    temperaturePlaceholder: '-100 - 500',
  },
};

//GLASSWARE
export const INITIAL_GLASSWARE = {
  type: null,
  size: null,
  otherType: null,
};

export const GLASSWARE_OPTIONS = [
  { label: 'Flask', value: 'Flask' },
  { label: 'Vial', value: 'Vial' },
  { label: 'Jar', value: 'Jar' },
  { label: 'Test tube', value: 'Test tube' },
  { label: 'Other', value: 'Other' },
];

//INPUTS
export const INITIAL_INPUT = {
  chemical_type: 'reagent',
  reagent_type: 'liquid',
  reagent: {
    chemical_type: 'reagent',
    compound: null,
    mw: 0,
    solvent: null,
    solvent_volume: null,
    mass: [null],
    moles: [null],
    volume: [null],
    density: null,
    limiting: false,
    equivalents: [null],
    name: '',
    concentration: null,
  },
  reactant: {
    chemical_type: 'reactant',
    compound: null,
    mw: 0,
    solvent: null,
    solvent_volume: null,
    concentration: null,
    mass: [null],
    moles: [null],
    volume: [null],
    density: null,
    limiting: false,
    equivalents: [null],
    name: '',
  },
  solvent: {
    solvent: null,
    solvent_volume: null,
    mw: null,
    name: '',
  },
};

export const INITIAL_ERRORS = {
  reagent: {
    type: false,
    compound: false,
    mw: false,
    solvent: false,
    solvent_volume: false,
    mass: [false],
    moles: [false],
    volume: [false],
    density: false,
    limiting: false,
    concentration: false,
  },
  reactant: {
    type: false,
    compound: false,
    mw: false,
    solvent: false,
    solvent_volume: false,
    mass: [false],
    moles: [false],
    volume: [false],
    density: false,
    limiting: false,
    concentration: false,
  },
  solvent: {
    solvent: false,
    solvent_volume: false,
    mw: false,
  },
};

export const RADIO_INPUT_OPTIONS = [
  { label: 'Liquid', value: 'liquid' },
  { label: 'Solid', value: 'solid' },
  { label: 'Solution', value: 'solution' },
];

export const COMPOUND_OPTIONS = [
  { label: 'Reagent', value: 'reagent' },
  { label: 'Reactant', value: 'reactant' },
  { label: 'Solvent', value: 'solvent' },
];

export const INITIAL_EQUIPMENT = {
  type: Object.keys(EQUIPMENT_TYPES)[0],
  temperatures: [null],
  timeHour: [null],
  timeMin: [null],
  timeSec: [null],
  atmosphere: null,
  atmosphereAdditional: '',
  pressure: null,
};

export const ALL_OPTIONS = {
  air: { value: 'Air', label: 'Air' },
  nitrogen: { value: 'Nitrogen', label: 'Nitrogen' },
  argon: { value: 'Argon', label: 'Argon' },
  carbon: { value: 'Carbon Monoxide', label: 'Carbon monoxide' },
  hydrogen: { value: 'Hydrogen', label: 'Hydrogen' },
  other: { value: 'other', label: 'Other' },
};

export const ATMOSPHERE_OPTIONS = {
  heating_bath: [ALL_OPTIONS.air, ALL_OPTIONS.nitrogen, ALL_OPTIONS.argon, ALL_OPTIONS.carbon, ALL_OPTIONS.other],
  cooling_bath: [ALL_OPTIONS.air, ALL_OPTIONS.nitrogen, ALL_OPTIONS.argon, ALL_OPTIONS.carbon, ALL_OPTIONS.other],
  parr_shaker: [ALL_OPTIONS.hydrogen, ALL_OPTIONS.other],
  microwave: [ALL_OPTIONS.air, ALL_OPTIONS.nitrogen, ALL_OPTIONS.argon, ALL_OPTIONS.other],
  other: [],
};
