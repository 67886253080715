import './styles.scss';

import React, { useMemo, useState } from 'react';

import { Tabs } from 'components';

import { CustomizationTable, ExperimentsSavedFilters, ExperimentsTable } from '@templates';

import { OPTIONS_TABS } from './constants';
import { useExperimentFiltersController } from './useExperimentFiltersController';
import { mapFiltersToQueryFormat } from './utils';

export const Experiments = () => {
  const [activeTab, setActiveTab] = useState(OPTIONS_TABS[0].value);

  const onChange = activeKey => {
    setActiveTab(activeKey);
  };

  const { filters, search, isFiltersLoaded, ...filtersActions } = useExperimentFiltersController();
  const mappedFiltersForRequest = useMemo(() => (isFiltersLoaded ? mapFiltersToQueryFormat(filters) : null), [
    filters,
    isFiltersLoaded,
  ]);

  return (
    <div className="experiments">
      <Tabs className="experiments_tabs" activeKey={activeTab} options={OPTIONS_TABS} onChange={onChange} />
      {activeTab === OPTIONS_TABS[0].value && (
        <div className="experiments_tab-experiments">
          <ExperimentsSavedFilters
            {...filtersActions}
            isFiltersLoaded={isFiltersLoaded}
            filters={filters}
            search={search}
            className="experiments_tab-experiments_filters"
          />
          <ExperimentsTable
            filters={mappedFiltersForRequest}
            search={search}
            isFiltersLoaded={isFiltersLoaded}
            className="experiments_tab-experiments_table"
          />
        </div>
      )}
      {activeTab === OPTIONS_TABS[1].value && <CustomizationTable />}
    </div>
  );
};
