import React from 'react';

import { FIELD_DATA_FOR_DROPDOWN } from '@organisms';

import { ReactComponent as CancelIconSVG } from '../../../../dist/images/CancelIcon.svg';
import { ReactComponent as CheckmarkSVG } from '../../../../dist/images/CheckMarkGreen.svg';
import { getDefaultValue } from './getDefaultValue';

export const getIcon = value => (value ? <CheckmarkSVG /> : <CancelIconSVG className="cancel-icon" />);

export const getMappedData = data => ({
  ...data,
  results: data.results.map(item => {
    const result = Object.entries(item).reduce(
      (acc, cur) => ({
        ...acc,
        [cur[0]]: typeof cur[1] === 'boolean' ? getIcon(cur[1]) : cur[1],
      }),
      {}
    );

    return {
      ...result,
      dataType: FIELD_DATA_FOR_DROPDOWN.find(i => i.value === item.dataType)?.label,
      defaultValue: getDefaultValue(item.dataType, item.defaultValueLabel ?? item.defaultValue),
      archived: item.archived ? 'Archived' : 'Active',
    };
  }),
});
