import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Button } from 'components/Common';
import { formatSecondsToMinutesDect } from 'utils';

export const SJBatchConfigurationDelayTable = ({ data, uncheckData, loading, showRefresh, generateTable }) => {
  return (
    <div className="sj-batch-configuration-block__delay-table">
      <div className="sj-batch-configuration-block__delay-table__title">Delay from previous</div>
      <div className="sj-batch-configuration-block__delay-table__block-content">
        <div className="sj-batch-configuration-block__delay-table__block">
          <div className="sj-batch-configuration-block__delay-table__block__header row">
            <div className="sj-batch-configuration-block__delay-table__block__header__item" />
            {data.map((dataItem, index) => {
              let name = dataItem.name;
              if (name.length > 10) name = name.substr(0, 10).trim() + '...';
              return (
                <div className="sj-batch-configuration-block__delay-table__block__header__item" key={index}>
                  <Checkbox
                    checked={dataItem.checked}
                    onClick={() => uncheckData(dataItem.uuid)}
                    disabled={dataItem.disabled}
                  />
                  {name}
                </div>
              );
            })}
          </div>
          <div className="sj-batch-configuration-block__delay-table__block__body row">
            <div className="sj-batch-configuration-block__delay-table__block__body__item">Delay, min</div>
            {data.map((dataItem, index) => (
              <div className="sj-batch-configuration-block__delay-table__block__body__item" key={index}>
                {dataItem.checked
                  ? dataItem.delay_from_previous
                    ? formatSecondsToMinutesDect(dataItem.delay_from_previous, 2)
                    : 0
                  : '--'}
              </div>
            ))}
          </div>
        </div>
        {showRefresh && (
          <Button onClick={generateTable} secondary>
            Refresh
          </Button>
        )}
      </div>
    </div>
  );
};
