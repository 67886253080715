import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LabProcessBuilder } from 'containers/ProcessBuilder/LabProcessBuilder';
import { ExperimentDetails } from '../AutoSyn/ExperimentDetails/ExperimentDetails';

export const ManualLab = props => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/process-builder/details/:id`} component={LabProcessBuilder} />
      <Route path={`${path}/details/:key`} component={ExperimentDetails} />
    </Switch>
  );
};
