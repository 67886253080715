import { combineReducers } from 'redux';

import processReducer from './autosyn/autosyn.reducer';
import commonReducer from './common/common.reducer';
import experimentReducer from './experiment/experiment.reducer';
import createExperimentReducer from './experimentCreation/experimentCreation.reducer';
import experimentFieldReducer from './experimentField/experimentField.reducer';
import processbuilderReducer from './processBuilder/processbuilder.reducer';
import projectReducer from './projects/projects.reducer';
import schedulingReducer from './scheduling/scheduling.reducer';
// Template
import { moduleName as templateModuleName } from './slices/extensibleTableFieldsGrid/constants';
import templateReducer from './slices/extensibleTableFieldsGrid/slice';
// Template
import synjetReducer from './synjet/synjet.reducer';
import synjetProReducer from './synjetPro/synjetPro.reducer';
import usersReducer from './users/users.reducer';

const rootReducer = combineReducers({
  commonReducer,
  projectReducer,
  usersReducer,
  processReducer,
  processbuilderReducer,
  createExperimentReducer,
  experimentReducer,
  schedulingReducer,
  synjetReducer,
  synjetProReducer,
  experimentFieldReducer,
  [templateModuleName]: templateReducer,
});

export default rootReducer;
