import { PROCESS_TYPES, steps_synjet } from './common';

export const AUTOSYN_TYPE_OPTIONS = [
  { label: 'Production', value: 'Production' },
  { label: 'Optimization', value: 'Optimization' },
  { label: 'Library generation', value: 'Library generation' },
];

export const SYNJET_TYPE_OPTIONS = [
  { label: 'Optimization', value: 'Optimization' },
  { label: 'Screening', value: 'Screening' },
];

export const SYNJET_PRO_TYPE_OPTIONS = [
  { label: 'Library generation', value: 'Library generation' },
  // disable for current implementation
  // { label: 'Optimization', value: 'Optimization' },
  // { label: 'Screening', value: 'Screening' },
];

export const typeOptionsToProcessTypeMapper = {
  [PROCESS_TYPES.AutoSyn.key]: AUTOSYN_TYPE_OPTIONS,
  [PROCESS_TYPES.SynJet.key]: SYNJET_TYPE_OPTIONS,
  [PROCESS_TYPES.SynJetPro.key]: SYNJET_PRO_TYPE_OPTIONS,
  [PROCESS_TYPES.Lab.key]: AUTOSYN_TYPE_OPTIONS,
};

export const STEP_OPTIONS = [
  { label: '1-Step', value: '1' },
  { label: '2-Step (1st step fixed)', value: '2' },
];

const COMMON_INITIAL_CREATE_PROCESS_STATE = {
  name: '',
  type: 'Production',
  project: null,
  numberOfSteps: steps_synjet[0].value,
  variableStep: '2',
};

const AUTOSYN_INITIAL_CREATE_PROCESS_STATE = {
  ...COMMON_INITIAL_CREATE_PROCESS_STATE,
  type: 'Production',
};

const SYNJET_INITIAL_CREATE_PROCESS_STATE = {
  ...COMMON_INITIAL_CREATE_PROCESS_STATE,
  type: 'Optimization',
};

const SYNJET_PRO_INITIAL_CREATE_PROCESS_STATE = {
  ...COMMON_INITIAL_CREATE_PROCESS_STATE,
  type: 'Library generation',
};

export const initialStateToProcessTypeMapper = {
  [PROCESS_TYPES.AutoSyn.key]: AUTOSYN_INITIAL_CREATE_PROCESS_STATE,
  [PROCESS_TYPES.SynJet.key]: SYNJET_INITIAL_CREATE_PROCESS_STATE,
  [PROCESS_TYPES.SynJetPro.key]: SYNJET_PRO_INITIAL_CREATE_PROCESS_STATE,
  [PROCESS_TYPES.Lab.key]: AUTOSYN_INITIAL_CREATE_PROCESS_STATE,
};
