import moment from 'moment';
import { dateMMDDYYmmHH, dateMMDDYY, dateYYYYMMDD } from '../constants';

export const transferIsoToMMDDYYmmHH = date => moment(date).format(dateMMDDYYmmHH);
export const transferIsoToMMDDYYmmHHutc = date => moment.utc(date).format(dateMMDDYYmmHH);
export const transferIsoToMMDDYYutc = date => moment.utc(date).format(dateMMDDYY);
export const transferIsoToMMDDYY = date => moment(date).format(dateMMDDYY);
export const dateFromBEFormat = date => moment(date, dateYYYYMMDD);

export const isoToExecutionView = date => moment.utc(date).format('MMM DD, YYYY [at] hh:mm a');
export const isoToExecutionViewTimeZone = date => moment(date).format('MMM DD, YYYY [at] hh:mm a');
export const toISOfromDateTime = (date, time) =>
  moment(`${moment(date).format('MM-DD-YYYY')} ${moment(time).format('hh:mm a')}`, 'MM-DD-YYYY hh:mm a').toISOString();

export const isoToExecutionViewList = date => moment(date).format('MMM DD, YYYY [at] hh:mm a');

export const getMMSSfromSeconds = date => moment.utc(date).startOf('day').add(date, 'seconds').format('m[m] ss[s]');

export const toISOfromDateTimeUTC = (date, time) =>
  moment(`${moment(date).format('MM-DD-YYYY')} ${moment(time).format('hh:mm a')}`, 'MM-DD-YYYY hh:mm a').format(
    'YYYY-MM-DDTHH:mm:ss'
  );

export const toISOString = date => moment(date).format('YYYY-MM-DDTHH:mm:ss');

export const toISOStringUTCMinDay = date => moment.utc(date).add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss');
export const toISOStringUTCPlusDay = date => moment.utc(date).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');

export const convertMMtoHHmm = min => {
  const minProcess = Math.ceil(min);
  const ceilHH = Math.floor(minProcess / 60);
  const minHH = minProcess - ceilHH * 60;
  return `${ceilHH < 10 ? '0' + ceilHH : ceilHH}:${minHH < 10 ? '0' + minHH : minHH}`;
};
export const convertMinToHours = min => moment().startOf('day').add(min, 'minutes').format('HH:mm');

export const getHHmm = (date, withoutMeridiem) => moment.utc(date).format(withoutMeridiem ? 'hh:mm' : 'hh:mm a');
export const getHHmmFormat = (date, withoutMeridiem) => moment(date).format(withoutMeridiem ? 'hh:mm' : 'hh:mm a');
export const getMmDdHHmmFormat = (date, withoutMeridiem) => moment(date).format(withoutMeridiem ? 'hh:mm' : 'hh:mm a');
export const getMmDdHhmm = date => moment.utc(date).format('MM/DD, hh:mm a');

export const setHHmmFormat = time => moment.utc(time, 'hh:mm').format('hh:mm');
export const isALlDay = (start, end) => {
  const t = moment;
  if (
    t(start).startOf('day').diff(start, 'seconds') === 0 &&
    t(end).endOf('day').add(-1, 'minute').diff(end, 'seconds') === 0
  ) {
    return true;
  }
  return false;
};
export const compareCurrentDateAndUTC = date => {
  return moment.utc().add(moment().utcOffset(), 'minutes').isAfter(date);
};

export const compareCurrentDateWithDate = (date, isMonth) => {
  let dateStr = date;
  if (isMonth) {
    dateStr = moment(dateStr + moment().add(10, 'seconds').format(' HH:mm:ss'), `YYYY-MM-DD HH:mm:ss`).format();
  }
  return moment().isAfter(dateStr);
};

export const getDiffBetweenTwoDays = (start, end) => {
  return moment(end).diff(moment(start), 'seconds');
};
export const compareDays = (start, end) => moment(start).format('MM-DD-YYYY') === moment(end).format('MM-DD-YYYY');

export const isPastEvent = date => moment.utc().isAfter(date);

export const dateBetweenTwoDays = (start, end, target) =>
  moment(target).isBetween(start, end) || moment(target).isSame(start);

export const getHHmmTimerM = date => moment(date).format('m');
export const getHHmmTimerH = date => moment(date).format('h');

export const getHHmmSSFromSec = sec => moment().startOf('day').set('seconds', sec).format('HH:mm:ss');
export const getHHmmFromSec = sec => moment().startOf('day').set('seconds', sec).format('HH:mm');

export const startAndEndTimeToText = ({ startAt, endAt }) => timeToText(startAt) + ' — ' + timeToText(endAt);

export const timeToText = date => moment(date).format('MMMM DD, YYYY hh:mm A');

export const getDiff = (start, end) => {
  const format = 'YYYY-MM-DD hh:mm:ss.sss Z';
  return moment.utc(end, format).diff(moment.utc(start, format), 'seconds');
};

export const getDiffChart = (start, end) => {
  const endDate = moment.utc(end.substring(0, 19)).format();
  return moment.utc(endDate).diff(moment.utc(start), 'seconds');
};

export const getHHmmDiff = (start, end) => convertMMtoHHmm(getDiff(start, end));

export const formatDate = 'YYYY-MM-DDTHH:mm:ss';
export const convertDateTimeZone = (date, format = formatDate) =>
  moment.utc(date, format).subtract(moment().utcOffset(), 'minutes').format();

export const setTOffsetUtc = date => moment(date).utcOffset(moment().utcOffset());
