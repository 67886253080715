/* eslint-disable no-unused-vars */
import React from 'react';
import './style.scss';
import { Table } from '../../../../Common';
import useColumns from './hooks/useColumns';
import setRowTable from './utils/setRowTable';

const SolutionsManualTable = ({ tableData, setTableData, getCompound }) => {
  const handleChangeSwitch = (value, index) => {
    setTableData(
      tableData.map((i, idx) => {
        if (index === idx) {
          return {
            ...i,
            has_sent: value,
          };
        }
        return i;
      })
    );
  };

  const columns = useColumns({ handleChangeSwitch, tableData });

  const setMappedTableData = data => (data ? data.map(i => setRowTable(i, getCompound)) : []);

  return (
    <Table
      rowClassName="lv-solutions-table_row"
      columns={columns}
      dataSource={setMappedTableData(tableData)}
      sidePadding={false}
    />
  );
};

export default SolutionsManualTable;
