import { prepareDataRoute } from './helpers';

const defaultFields = {
  cartridge_actual_mass: undefined,
  is_done: undefined,
  variable: false,
  pressure: null,
};

const defaultFieldsTubular = {
  title: 'tubular',
  type: 'tubular',
  ...defaultFields,
};

const defaultFieldsPacked = {
  title: 'packed bed',
  type: 'packed',
  ...defaultFields,
};

const setProperties = ({ compoundsDataBase, reactor, isSetEmpty }) => {
  return [
    {
      products: isSetEmpty ? [] : reactor.products.map(i => compoundsDataBase[i]).filter(i => i),
      sideproducts: [],
    },
  ];
};
const average = nums => {
  return nums?.length ? nums.reduce((a, b) => +a + +b) / nums.length : null;
};
const setTemperature = ({ reactor, idx, typeReactor }) => {
  const possibleTemperature = reactor?.variations?.conditions[idx]?.temps;
  const value = average(possibleTemperature);
  if (value && value <= typeReactor.maxTemperature && typeReactor.minTemperature <= value) return [value];
  else return [];
};
export const setTime = ({ reactor, idx, typeReactor }) => {
  const possibleTimes = reactor.variations.conditions[idx]?.times || 0;
  const value = average(possibleTimes);
  const hours = Math.trunc(value);
  let min = `${value}`.split('.')[1];
  min = +`0.${min}`;
  return hours || min ? [(hours || 0) * 3600 + Math.ceil((min || 0) * 60) * 60] : [0];
};
const getTypeReactor = ({ field, reactors }) => {
  const newReactors = { ...reactors };
  let type = newReactors[field].find(i => !i.used);
  if (type) newReactors[field] = newReactors[field].map(i => (i.key === type.key ? { ...i, used: true } : i));
  else {
    newReactors[field] = newReactors[field].map(i => ({ ...i, used: false }));
    type = { ...newReactors[field][0] };
  }

  return { type, reactors: newReactors };
};
const setTypeReactor = ({ isPacked, reactors }) => {
  let result;
  if (isPacked) result = getTypeReactor({ field: 'packedBed', reactors });
  else result = getTypeReactor({ field: 'tubular', reactors });
  return result;
};
const getCatalyst = ({ reactor, idx, compoundsDataBase }) =>
  reactor?.variations?.conditions[idx]?.catalysts?.map(i => compoundsDataBase[i]).filter(i => i) || [];

const pumpFields = ({ solvent, order }) => ({
  flash_solvent: solvent,
  flow_rate: [1],
  is_variant: false,
  key: undefined,
  lengthOfVariants: 1,
  name: `Pump ${order}`,
  properties: [],
  type: ['HPLC-PEEK'],
});

const setPropertiesPump = ({ data }) => {
  return [
    {
      flow_rate: [1],
      flush_solvent: '',
      reactants: [],
      reactants_consentration: [],
      reagents: [],
      reagents_consentration: [],
      solvents: [],
      solvents_fraction: [],
      ...data,
    },
  ];
};

const setPumps = ({
  route,
  idxCondition,
  compoundsDataBase,
  countPumps,
  isConditionsMoreOne,
  isFirstReactor,
  dataPrevReactor,
}) => {
  const arrPumps = [];
  let count = countPumps;
  const solvents = route?.variations?.conditions[idxCondition]?.solvents || [];
  const dataSolventFlash = solvents?.length ? compoundsDataBase[solvents[0]] : null;
  const solvent = dataSolventFlash ? [dataSolventFlash] : [];

  const reagents = route?.variations?.conditions[idxCondition]?.reagents || [];
  let reactants = route?.reactants;
  if (!isFirstReactor) reactants = route?.reactants.filter(i => i !== dataPrevReactor?.products[0]);

  if (!isConditionsMoreOne) {
    reactants.forEach((i, idx) => {
      const dataReactant = compoundsDataBase[i];
      const item = pumpFields({ solvent, order: count + 1 });
      const reactantsPump = dataReactant
        ? [
            {
              concentration: 1,
              equivalents: ['N/A'],
              limiting_reagent: false,
              molar_flow_rate: [1],
              properties: dataReactant,
              reactant: dataReactant?.compound_id,
            },
          ]
        : [];
      item.properties = setPropertiesPump({
        data: {
          flow_rate: [1],
          flush_solvent: dataSolventFlash?.compound_id,
          reactants: reactantsPump,
          solvents: solvents
            ?.filter(i => compoundsDataBase[i])
            .map(i => ({
              concentration: null,
              equivalent: null,
              equivalents: [],
              fraction: 1 / solvents.length,
              molar_flow_rate: null,
              properties: compoundsDataBase[i],
              solvent: compoundsDataBase[i]?.compound_id,
            })),
        },
      });

      arrPumps.push(item);
      count += 1;
    });
  }
  reagents.forEach((i, idx) => {
    const dataReagent = compoundsDataBase[i];
    const item = pumpFields({ solvent, order: count + 1 });
    const reagentPump = dataReagent
      ? [
          {
            concentration: 1,
            equivalent: [null],
            equivalents: ['N/A'],
            limiting_reagent: false,
            molar_flow_rate: [1],
            properties: dataReagent,
            reagent: dataReagent?.compound_id,
          },
        ]
      : [];
    item.properties = setPropertiesPump({
      data: {
        flush_solvent: dataSolventFlash?.compound_id,
        reagents: reagentPump,
        solvents: solvents
          ?.filter(i => compoundsDataBase[i])
          .map(i => ({
            concentration: null,
            equivalent: null,
            equivalents: [],
            fraction: 1 / solvents.length,
            molar_flow_rate: null,
            properties: compoundsDataBase[i],
            solvent: compoundsDataBase[i]?.compound_id,
          })),
      },
    });

    arrPumps.push(item);
    count += 1;
  });
  return arrPumps;
};

export const parseRouteToAutoSyn = async ({ route, segment, part, allReactors }) => {
  let reactors = {
    tubular: allReactors
      .filter(i => i.type === 'Tubular reactor')
      .filter((i, idx) => +i.key.replace(/R/g, '') % 2 === 1)
      .map(i => ({ ...i, minTemperature: -20 }))
      .sort((a, b) => +a.key.replace(/R/g, '') > +b.key.replace(/R/g, '')),
    packedBed: allReactors.filter(i => i.type === 'Packed bed reactor').map(i => ({ ...i, minTemperature: 20 })),
  };
  const { reactorsDetails, compoundsDataBase, partForTransform } = await prepareDataRoute({ route, segment, part });
  let countPumps = 0;
  const process = partForTransform.reduce((accum, current, idx) => {
    const reactorDetailsPrev = reactorsDetails[idx - 1];
    const reactorDetails = reactorsDetails[idx];
    reactorDetails.variations = reactorDetails.variations[0] || { conditions: [{ solvents: [] }] };
    const newReactors = [];

    const isPacked = !!reactorDetails?.variations?.conditions[0]?.catalysts?.length;
    const conditions = [...reactorDetails.variations.conditions];
    if (conditions.length > 1) {
      conditions.forEach((y, ydx, yArr) => {
        const result = setTypeReactor({ isPacked, reactors });
        const type = { ...result.type };
        reactors = result.reactors;
        const pumsData = setPumps({
          route: reactorDetails,
          idxCondition: ydx,
          isFirstReactor: idx === 0,
          compoundsDataBase,
          countPumps,
          dataPrevReactor: reactorDetailsPrev,
          isConditionsMoreOne: conditions.length > 1,
        });
        countPumps += pumsData.length;
        newReactors.push({
          name: `Reactor ${accum.length + ydx + 1}`,
          catalysts: getCatalyst({ reactor: reactorDetails, compoundsDataBase, idx: ydx }),
          flow_rate: [0],
          key: type?.key,
          properties: setProperties({
            compoundsDataBase,
            reactor: reactorDetails,
            isSetEmpty: yArr.length - 1 !== ydx,
          }),
          temperatures: setTemperature({ reactor: reactorDetails, idx: ydx, typeReactor: type }),
          times: setTime({ reactor: reactorDetails, idx: ydx, typeReactor: type }),

          pumps: pumsData,

          ...(isPacked ? defaultFieldsPacked : defaultFieldsTubular),
        });
      });
    } else {
      const result = setTypeReactor({ isPacked, reactors });
      const type = { ...result.type };
      reactors = result.reactors;
      const pumsData = setPumps({
        route: reactorDetails,
        idxCondition: 0,
        compoundsDataBase,
        countPumps,
        dataPrevReactor: reactorDetailsPrev,
        isFirstReactor: idx === 0,
      });
      countPumps += pumsData.length;
      newReactors.push({
        name: `Reactor ${accum.length + 1}`,
        catalysts: getCatalyst({ reactor: reactorDetails, compoundsDataBase, idx: 0 }),
        flow_rate: [0],
        key: type?.key,
        properties: setProperties({ compoundsDataBase, reactor: reactorDetails }),
        temperatures: setTemperature({ reactor: reactorDetails, idx: 0, typeReactor: type }),
        times: setTime({ reactor: reactorDetails, idx: 0, typeReactor: type }),
        pumps: pumsData,

        ...(isPacked ? defaultFieldsPacked : defaultFieldsTubular),
      });
    }

    return [...accum, ...newReactors];
  }, []);
  return process;
};
