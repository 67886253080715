import React, { useEffect, useRef } from 'react';
import './style.scss';
import SmilesDrawer from 'smiles-drawer';
import { v1 as uuidv1 } from 'uuid';

const options = {
  height: 200,
};

export const PreviewSmiles = ({ data }) => {
  const key = Math.random();

  const canvas = useRef(null);
  const canvasContainer = useRef(null);
  useEffect(() => {
    if (!!data) {
      options.width = canvasContainer.current.offsetWidth;
      canvas.current = new SmilesDrawer.Drawer(options);
      SmilesDrawer.parse(data.smiles, function (tree) {
        canvas.current.draw(tree, `smiles-drawer_${key}_${data.smiles}`, 'light', false);
      });
    }
  }, [data]);

  return (
    <div className="preview-smiles">
      <div className="preview-smiles_title">Target product</div>
      <div className="preview-smiles_structure" ref={canvasContainer}>
        <canvas id={`smiles-drawer_${key}_${data.smiles}`} />
      </div>
      <div className="preview-smiles_name">{data.name}</div>
      <div className="preview-smiles_smile">{data.smiles}</div>
    </div>
  );
};
