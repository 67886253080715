import React, { useEffect, useState } from 'react';
import { ExperimentFinalizationCheckList } from '../ExperimentFinalization/ExperimentFinalizationChecklist';
import { AppendResultsForm } from '../../Experiment/ResultsTab/AppendResultsForm/AppendResultsForm';
import { appendedInitialState, initialState } from '../../Experiment/ResultsTab/PopupAppendResults';
import { getAppendedResult } from '../../../store/experiment/experiment.actions';
import { useDispatch } from 'react-redux';
import './style.scss';

export const LabExperimentFinalization = ({
  experiment,
  options,
  setOptions,
  setFinalizationData,
  setErrors,
  errors,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ ...initialState });
  const [appendedFiles, setAppendedFiles] = useState({ ...appendedInitialState });
  const [reactions, setReactions] = useState([]);
  const [resultData, setResultData] = useState({});

  useEffect(() => {
    if (experiment.uuid) {
      dispatch(getAppendedResult({ experimentId: experiment.uuid })).then(resp => {
        setResultData(resp);
      });
    }
  }, [experiment]);

  useEffect(() => {
    setFinalizationData({
      reactions,
      comments: data,
      appendedFiles,
    });
  }, [reactions, data, appendedFiles]);

  return (
    <section className="lab-finalization">
      <ExperimentFinalizationCheckList options={options} setOptions={setOptions} />
      <div className="lab-finalization__append-results">
        <h5 className="lab-finalization__append-results__header">Experiment results</h5>
        <AppendResultsForm
          isSynJet={false}
          setHasChanges={() => {}}
          data={data}
          errors={errors}
          appendedFiles={appendedFiles}
          reactions={reactions}
          setReactions={setReactions}
          setErrors={setErrors}
          experimantData={experiment}
          resultData={resultData}
          setData={setData}
          setAppendedFiles={setAppendedFiles}
          isLabDevice
        />
      </div>
    </section>
  );
};
