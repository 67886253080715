import './SelectableCell.styles.scss';

import React from 'react';

import cn from 'classnames';

import { Checkbox } from '../../../../components';

const areEqual = (prev, next) =>
  prev.rowId === next.rowId && prev.tableRowsIndexes === next.tableRowsIndexes && prev.isSelected === next.isSelected;

export const SelectableCell = React.memo(
  ({ className = '', style, rowId, tableRowsIndexes, isSelected, onSelect, isEven }) => {
    const onChange = () => onSelect(rowId, tableRowsIndexes);
    return (
      <div className={cn('selectable-cell', className, { selected: isSelected, even: isEven })} style={style}>
        <Checkbox onChange={onChange} value={isSelected} />
      </div>
    );
  },
  areEqual
);
