import React from 'react';
import { Button, DropdownButton } from '../../Common';
import './style.scss';
import { PdfGeneratorSJP } from '../../PdfGenerator/PdfGeneratorSJP';

export const Footer = ({
  activeStep,
  showNotes,
  notesLength = 0,
  canSeeNotes = false,
  handleDecline,
  permissions,
  handleContinue,
  handleBack,
  disableContinue,
  showEndExperiment = false,
  hideNotesNumber = false,
  endExperiment,
  showPause,
  pauseExperiment,
  resumeExperiment,
  showFinalize,
  finalizeExperiment,
  showResume,
  experiment,
  completeExperiment,
  disableCompleteExperiment,
  onNavigateToLastStep,
  remoteConnectivity,
  isSynjet,
  isSynjetPro,
  dropdownMenu = [],
  canSeeDescription = false,
  showDescription = () => {},
  isLab,
  tableData,
  disableButtons,
  downloadDatFile,
  isPermittedDownloadDatFile = false,
}) => {
  const permissionsForReject = permissions?.execution?.create_reject_experiment;
  const isOneOfThreeSteps = activeStep === 1 || activeStep === 2 || activeStep === 3;

  return (
    <div className="builder-footer">
      <div>
        {activeStep !== 1 && ((activeStep < 4 && isSynjet) || (activeStep < 5 && isSynjetPro)) && !isLab && (
          <Button style={{ marginRight: 8 }} secondary onClick={handleBack}>
            Back
          </Button>
        )}
        {(permissionsForReject && (isOneOfThreeSteps || (activeStep === 4 && isSynjetPro)) && !isLab) ||
        (isLab && activeStep === 1) ? (
          <>
            {isSynjet || isSynjetPro ? (
              <DropdownButton secondaryTypeButton text="Reject" menu={dropdownMenu} />
            ) : (
              <Button secondary onClick={handleDecline}>
                Reject
              </Button>
            )}
          </>
        ) : null}
        {isPermittedDownloadDatFile && ((activeStep === 3 && isSynjet) || (activeStep === 4 && isSynjetPro)) && (
          <Button style={{ marginLeft: 8 }} secondary onClick={downloadDatFile}>
            Download .DAT file
          </Button>
        )}
        {showEndExperiment && isLab && (
          <Button onClick={() => endExperiment(true)} secondary>
            Abort
          </Button>
        )}
      </div>
      <div>
        {canSeeDescription && activeStep === 1 && (
          <Button style={{ marginRight: 8 }} secondary onClick={showDescription}>
            Show description
          </Button>
        )}
        {activeStep === 1 && isSynjetPro && (
          <PdfGeneratorSJP batchInfo={experiment} experiments={experiment.experiments} tableData={tableData} />
        )}
        {canSeeNotes && (
          <Button style={{ marginRight: 8, marginLeft: 8 }} secondary onClick={showNotes}>
            Show notes {!hideNotesNumber && `(${notesLength})`}
          </Button>
        )}
        {(((activeStep === 1 || activeStep === 2) && !isLab) || (activeStep === 3 && isSynjetPro)) && (
          <Button onClick={handleContinue} disabled={disableContinue} style={{ marginRight: 8 }}>
            Continue
          </Button>
        )}
        {((activeStep === 3 && !isLab && !isSynjetPro) || (isLab && activeStep === 1)) &&
          (experiment?.step >= 3 ? (
            <Button onClick={handleContinue} disabled={disableContinue} style={{ marginRight: 8 }}>
              Continue
            </Button>
          ) : (
            <Button onClick={handleContinue} disabled={disableContinue} style={{ marginRight: 8 }}>
              Launch Experiment
            </Button>
          ))}
        {activeStep === 4 && isSynjetPro && (
          <Button onClick={handleContinue} disabled={disableContinue} style={{ marginRight: 8 }}>
            Launch batch
          </Button>
        )}
        {showPause && ((activeStep === 4 && !isSynjetPro) || (activeStep === 5 && isSynjetPro)) && (
          <Button
            style={{ marginRight: 8 }}
            onClick={() => pauseExperiment(true)}
            disabled={!remoteConnectivity || disableButtons}
          >
            Pause
          </Button>
        )}
        {!showPause && showResume && ((activeStep === 4 && !isSynjetPro) || (activeStep === 5 && isSynjetPro)) && (
          <Button
            style={{ marginRight: showEndExperiment ? 8 : 0 }}
            onClick={() => resumeExperiment(true)}
            disabled={!remoteConnectivity || disableButtons}
          >
            Resume
          </Button>
        )}
        {showFinalize && <Button onClick={finalizeExperiment}>Finalize</Button>}
        {showEndExperiment && !isLab && (
          <Button onClick={() => endExperiment(true)} disabled={!remoteConnectivity || disableButtons}>
            End {isSynjet ? 'batch' : 'experiment'}
          </Button>
        )}
        {activeStep === 4 && experiment?.status === 'Finalization' && (
          <Button onClick={onNavigateToLastStep} disabled={disableButtons}>
            Finalize
          </Button>
        )}
        {(activeStep === 5 || (isLab && activeStep === 3) || (isSynjetPro && activeStep === 6)) &&
          experiment?.status === 'Finalization' && (
            <Button onClick={() => completeExperiment()} disabled={disableCompleteExperiment}>
              Complete
            </Button>
          )}
      </div>
    </div>
  );
};
