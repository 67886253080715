import './VirtualTableCell.styles.scss';

import React from 'react';

import cn from 'classnames';

import { TextBody14 } from '@atoms';
import { COLUMN_TYPES, useDndContext, useSelectableContext } from '@molecules';

import { DND_DROP_AREA_END_EDGE, DND_DROP_AREA_START_EDGE } from '../constants';
import { SelectableCell } from './SelectableCell';

export const VirtualTableCell = ({
  className = '',
  style,
  columnIndex,
  children,
  onClick,
  dataIndex,
  rowId,
  tableRowsIndexes,
  isEven,
  isLast,
}) => {
  const { onSelect, selected, isAllSelected, isSelectableContext } = useSelectableContext();
  const { potentialDropColumnIndex, dragColumnIndex } = useDndContext() || {};
  const dragOverlayActive = dragColumnIndex === dataIndex;

  const isSelected = !!selected?.includes(rowId) || isAllSelected;
  if (dataIndex === COLUMN_TYPES.selectable) {
    return (
      <SelectableCell
        isEven={isEven}
        style={style}
        rowId={rowId}
        className={cn(className)}
        tableRowsIndexes={tableRowsIndexes}
        onSelect={onSelect}
        isSelected={isSelected}
      />
    );
  }
  const columnIndexWithOffset = isSelectableContext ? columnIndex - 1 : columnIndex;
  const isFirst = columnIndexWithOffset === 0;
  return (
    <div
      className={cn('virtual-table-cell', className, {
        first: columnIndex === 0,
        even: isEven,
        'drop-active': potentialDropColumnIndex === dataIndex,
        'drag-overlay': dragOverlayActive,
        selected: isSelected,
        'drop-area-start-edge': isFirst && potentialDropColumnIndex === DND_DROP_AREA_START_EDGE,
        'drop-area-end-edge': isLast && potentialDropColumnIndex === DND_DROP_AREA_END_EDGE,
      })}
      style={style}
      onClick={onClick}
    >
      <div className="virtual-table-cell_aligh">
        {typeof children === 'string' ? <TextBody14 tooltip ellipsis text={children} /> : children}
      </div>
    </div>
  );
};
