import React, { useEffect, useState } from 'react';
import './style.scss';
import { ExpandableTree } from 'components/Common';
import cn from 'classnames';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { TreeElementDetails } from '../TreeElementDetails/TreeElementDetails';
import { TREE_ELEMENT_TYPES } from '../../../../constants';

export const ProjectActivitiesTab = ({ permissions, project, getProjectDetails, userRoles }) => {
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [activeCollapse, setActiveCollapse] = useState(true);

  const setSelectedNode = (uuid, nodes) => {
    setSelected(uuid);
    setSelectedItem(nodes[0] || null);
  };

  const getExperimentAndChildren = process => {
    return {
      key: process.uuid,
      device: process.device,
      title: process.name,
      icon: ({ selected }) => <div className={cn('experiment-tree-icon process', { selected })} />,
      type: TREE_ELEMENT_TYPES.process.key,
      children: process.experiments
        .map(exp => {
          return {
            key: exp.key,
            device: process.device,
            title: exp.name,
            icon: ({ selected }) => <div className={cn('experiment-tree-icon experiment', { selected })} />,
            type: TREE_ELEMENT_TYPES.experiment.key,
            deletable: false,
          };
        })
        .sort((a, b) => {
          // sort experiments
          if (moment(a.updatedAt).diff(b.updatedAt) > 0) return 1;
          return -1;
        }),
    };
  };

  useEffect(() => {
    if (project?.processes) {
      setDataGroupedByRoute(project?.processes, project?.routes);
    }
  }, [project]);

  const setDataGroupedByRoute = (data, routes) => {
    // sort experimens within process
    data = data.map(process => {
      process.experiments = process.experiments.sort((exp1, exp2) => {
        if (moment(exp1.updatedAt).diff(exp2.updatedAt, 'seconds') < 0) return 1;
        return -1;
      });
      return process;
    });
    let treeDataGroupedByRoute = [];
    data
      .sort((a, b) => {
        // sort processes by last experiment updates or process updates
        if (
          moment(a.experiments[0]?.updatedAt || a.updatedAt).diff(
            b.experiments[0]?.updatedAt || b.updatedAt,
            'seconds'
          ) < 0
        )
          return 1;
        return -1;
      })
      .forEach(process => {
        if (process.route) {
          if (!treeDataGroupedByRoute.find(route => route.key === process.route.uuid)) {
            treeDataGroupedByRoute.push({
              ...process.route,
              key: process.route.uuid,
              title: process.route.name,
              type: TREE_ELEMENT_TYPES.route.key,
              icon: ({ selected }) => <div className={cn('experiment-tree-icon route', { selected })} />,
              children: [getExperimentAndChildren(process)],
              device: process.device,
              deletable: false,
            });
          } else
            treeDataGroupedByRoute = treeDataGroupedByRoute.map(route => {
              if (route.uuid === process.route.uuid)
                return {
                  ...route,
                  device: process.device,
                  type: TREE_ELEMENT_TYPES.route.key,
                  children: [...route.children, getExperimentAndChildren(process)],
                  deletable: false,
                };
              return route;
            });
        } else treeDataGroupedByRoute.push(getExperimentAndChildren(process));
      });

    let emptyRoutes = routes.filter(route => !route.processes.length);
    emptyRoutes = emptyRoutes
      .sort((a, b) => {
        if (moment(a.createdAt).diff(b.createdAt, 'seconds') < 0) return 1;
        return -1;
      })
      .map(route => {
        return {
          ...route,
          key: route.uuid,
          title: route.name || '',
          type: TREE_ELEMENT_TYPES.route.key,
          icon: ({ selected }) => <div className={cn('experiment-tree-icon route', { selected })} />,
          children: [],
          deletable: true,
        };
      });

    treeDataGroupedByRoute = [...treeDataGroupedByRoute, ...emptyRoutes];
    setTreeData(treeDataGroupedByRoute);
  };

  return (
    <div className={cn('projects-tree-component-expand', { 'projects-tree-component-collapsed': !activeCollapse })}>
      <div className="projects-tree-component__title">Routes, processes, experiments</div>
      <div className="project-tree-wrapper">
        <div className="projects-tree-component">
          <ExpandableTree
            searchTree
            treeData={treeData}
            onSelect={setSelectedNode}
            selectedKeys={selected}
            getProjectDetails={getProjectDetails}
            project={project}
            userRoles={userRoles}
          />
        </div>
        <div className="projects-tree-component-details">
          <TreeElementDetails
            type={selectedItem?.type}
            itemId={selectedItem?.key}
            title={selectedItem?.title}
            device={selectedItem?.device}
            experimentId={selectedItem?.experimentId}
          />
        </div>
      </div>

      <div
        className="circle"
        onClick={() => {
          setActiveCollapse(!activeCollapse);
        }}
      >
        <LeftOutlined className={cn({ 'circle-expaned': !activeCollapse })} />
        <RightOutlined className={cn({ 'circle-expaned': activeCollapse })} />
      </div>
    </div>
  );
};
