import React, { useState, useEffect } from 'react';
import { Input, Popup, Select, openNotification } from '../Common';
import {
  DEVICES,
  EXECUTION_REJECTIONS_REASONS,
  SHEDULER_REJECTIONS_REASONS,
  SYNJET_SHEDULER_REJECTIONS_REASONS,
  MANUAL_EXECUTION_REJECTIONS_REASONS,
  MANUAL_SCHEDULER_REJECTIONS_REASONS,
} from 'constants/index';
import { useDispatch } from 'react-redux';
import { rejectExperiment } from '../../store/experiment/experiment.actions';

const initialErrors = {
  reason: null,
  note: null,
};

const initialData = {
  reason: null,
  note: null,
};

export const DeclineExperimentPopup = ({
  open,
  closePopup,
  isSchedule,
  handleNavigation = () => {},
  experiment,
  showWarning,
  device,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState(initialErrors);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const maxLengthInput = 256;

  useEffect(() => {
    if (!open) {
      setErrors(initialErrors);
      setData(initialData);
    }
  }, [open]);

  const handleCancel = () => {
    closePopup();
  };

  const decline = async () => {
    setLoading(true);
    await dispatch(rejectExperiment(experiment, { ...data, note: encodeURIComponent(data.note) }));
    setConfirmationPopupOpen(false);
    closePopup();
    openNotification('Experiment has been rejected and moved to Submitted status');
    setLoading(false);
    handleNavigation(experiment);
  };

  const checkErrors = () => {
    let newErrors = {};
    if (!data.reason) newErrors.reason = 'Please select the rejection reason';
    if (!data.note) newErrors.note = 'Please provide your notes first';
    setErrors({ ...errors, ...newErrors });
    if (Object.keys(newErrors).length > 0) return;
    if (showWarning) setConfirmationPopupOpen(true);
    else decline();
  };

  const changeValue = (value, type) => {
    setData({ ...data, [type]: value });
    setErrors({ ...errors, [type]: null });
  };

  return (
    <>
      <Popup
        title="Reject experiment"
        open={open && !confirmationPopupOpen}
        className="popup-create-process"
        handleCancel={handleCancel}
        textSubmit="Decline"
        handleSubmit={checkErrors}
        loading={false}
      >
        <div className="decline-experiment-popup_content">
          <Select
            label="Rejection reason"
            onChange={v => changeValue(v, 'reason')}
            options={
              isSchedule
                ? DEVICES.SYNJET === device
                  ? SYNJET_SHEDULER_REJECTIONS_REASONS
                  : DEVICES.AUTOSYN === device
                  ? SHEDULER_REJECTIONS_REASONS
                  : MANUAL_SCHEDULER_REJECTIONS_REASONS
                : DEVICES.LAB === device
                ? MANUAL_EXECUTION_REJECTIONS_REASONS
                : EXECUTION_REJECTIONS_REASONS
            }
            value={data.reason}
            disabled={loading}
            error={errors.reason}
            errorText={errors.reason}
            placeholder="Please select reason"
            required
          />
          <Input
            allowSpecials
            withoutComma={false}
            maxLength={maxLengthInput}
            autoFocus
            label="Notes"
            placeholder="Type your note here"
            disabled={loading}
            value={data.note}
            onChange={v => changeValue(v, 'note')}
            error={errors.note}
            errorText={errors.note}
            type="textarea"
            className="experiment-notes-list_input"
            require
          />
        </div>
      </Popup>
      <Popup
        title="Warning"
        open={confirmationPopupOpen}
        className="popup-create-process"
        handleCancel={() => setConfirmationPopupOpen(false)}
        textSubmit="Reject experiment"
        handleSubmit={decline}
        loading={loading}
      >
        Once declined, the experiment won't be available for scheduling until the Chemistry Designer confirms that it
        could be scheduled again.
      </Popup>
    </>
  );
};
