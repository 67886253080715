/* eslint-disable no-unused-vars */
import './style.scss';

import React from 'react';

import { updateSjpSolidVials } from '../../../../../store/synjet/synjet.actions';
import { openNotification, Table } from '../../../../Common';
import useColumns from './hooks/useColumns';
import setRowTable from './utils/setRowTable';

export const setFixed = value => {
  if (!value) return '';
  const valueSplit = `${value}`.split('.');
  if (valueSplit.length === 2) {
    return value.toFixed(2);
  }
  return `${value}.0`;
};

const validationInput = value => {
  let error = false;
  const val = value.split('.');
  if (val.length === 2) {
    if (val[0].length === 0 || val[0].length === 11) error = true;
    else if (val[1].length === 3) error = true;
  } else if (val[0].length === 11) error = true;

  return error;
};

const SolidsTable = ({
  errors,
  setErrors,
  setEdited,
  tableData,
  setTableData,
  setLoading,
  experiments,
  getCompound,
  batchUuid,
}) => {
  const calculateError = (actualMass, calculatedMass) =>
    +actualMass > +calculatedMass.toFixed(2)
      ? ((actualMass * 100) / +calculatedMass.toFixed(2) - 100).toFixed(2)
      : 'N/A';

  const handleChangeInput = (value, field, index, indexSubRow) => {
    if (validationInput(value)) return;
    setTableData(
      tableData.map((i, idx) => {
        if (index === idx) {
          return {
            ...i,
            vials: i.vials.map((v, idxSubRow) =>
              idxSubRow === indexSubRow
                ? { ...v, actual_mass: value, error: calculateError(value, v.calculated_mass) }
                : v
            ),
            has_sent: false,
          };
        }
        return i;
      })
    );

    setEdited(true);
    setErrors({
      ...errors,
      solids: {
        ...errors.solids,
        [index]: errors.solids[index]?.filter(i => i !== indexSubRow),
      },
    });
  };

  const handleChangeSwitch = (value, index, rowData) => {
    if (rowData.vials.some(item => +item.calculated_mass.toFixed(2) >= +item.actual_mass)) {
      openNotification('', 'Actual Amt should be more than Calculated Amt');
      const updErrors = rowData.vials
        .map((item, idx) => (+item.calculated_mass.toFixed(2) > +item.actual_mass ? idx : null))
        .filter(item => item !== null);
      setErrors({
        ...errors,
        solids: {
          ...errors.solids,
          [index]: updErrors,
        },
      });
      return;
    }

    setTableData(
      tableData.map((i, idx) => {
        if (index === idx) {
          return {
            ...i,
            has_sent: value,
          };
        }
        return i;
      })
    );

    if (!value) {
      return;
    }

    const data = {
      batch: batchUuid,
      solidUuid: rowData.dispenserUuid,
      vials: rowData.vials.map(i => ({ name: String(i.name), error: +i.error, actualMass: +i.actual_mass })),
    };

    setLoading(true);
    updateSjpSolidVials({ data })
      .then(() => {
        setTableData(
          tableData.map((i, idx) => {
            if (index === idx) {
              return {
                ...i,
                has_sent: value,
              };
            }
            return i;
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useColumns({
    handleChangeSwitch,
    handleChangeInput,
    tableData,
    errors,
  });

  const setMappedTableData = data => (data ? data.map(i => setRowTable(i, getCompound)) : []);
  return (
    <Table
      rowClassName="lv-solids-table_row"
      columns={columns}
      dataSource={setMappedTableData(tableData)}
      sidePadding={false}
    />
  );
};

export default SolidsTable;
