import { SelectableCheckbox } from './components/SelectableHeaderCell';

export const COLUMN_RESIZER_DRAG_AREA_WIDTH = 20;
export const COLUMN_RESIZER_INDICATOR_ATTRIBUTE = 'column-resizer-indicator';

export const HEADER_HEIGHT = 54;
export const SCROLLBAR_WIDTH = 8;
export const DEFAULT_COLUMN_WIDTH = 150;
export const DND_DROP_AREA_EDGE_WIDTH = 20;
export const MIN_COLUMN_WIDTH = 70;
export const SELECTABLE_HEADER_WIDTH = 60;

export const COLUMN_TYPES = {
  selectable: 'selectable',
};

export const COLUMN_DATA_SELECTABLE = {
  title: SelectableCheckbox,
  dataIndex: COLUMN_TYPES.selectable,
  width: SELECTABLE_HEADER_WIDTH,
  onHeaderCell: () => ({
    dataIndex: COLUMN_TYPES.selectable,
  }),
};

export const DND_DROP_AREA_START_EDGE = 'DND_DROP_AREA_START_EDGE';
export const DND_DROP_AREA_END_EDGE = 'DND_DROP_AREA_END_EDGE';
