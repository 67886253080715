/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { moduleName } from './constants';

const initialState = {
  data: [],
  isFetched: false,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    loading(state) {
      state.isLoading = true;
      state.error = null;
    },
    finish(state) {
      state.isLoading = false;
      // TODO: should be implemented error component, after that delete isFetched = true
      state.isFetched = true;
    },
    error(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.data = [];
    },
    set(state, action) {
      const { data } = action.payload;
      state.data = data;
      state.isFetched = true;
    },
    add(state, action) {
      const { data } = action.payload;
      state.data.push(data);
    },
    update(state, action) {
      const { data } = action.payload;
      state.data = state.data.map(i => (i.uuid === data.uuid ? { ...i, ...data } : i));
    },
    reset(state) {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key];
      });
    },
  },
});

export default slice.reducer;
export const { loading, finish, error, set, add, update, reset } = slice.actions;
