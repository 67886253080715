import './style.scss';

import React, { useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

import { dateMMDDYY, timehhmma } from '../../../constants';
import { setTOffsetUtc } from '../../../utils/date';
import { Button, DatePicker, openNotification, Popover } from '../../Common';

const getEndTime = (date, startTime, duration) => {
  if (!date || !startTime || !duration) {
    return 'N/A';
  }

  const experimentStart = moment(
    `${moment(date).format('MM-DD-YYYY')} ${moment(startTime).format('hh:mm a')}`,
    'MM-DD-YYYY hh:mm a'
  );

  return experimentStart.add(duration, 'minutes').format('MM/DD/YYYY hh:mm a');
};

const validateScheduleInfo = data => {
  const result = {
    valid: true,
    errors: { date: '', time: '' },
  };

  if (!data.date) {
    result.valid = false;
    result.errors.date = 'Please enter the date';
  }

  if (!data.time) {
    result.valid = false;
    result.errors.time = 'Please enter the start time';
  }

  if (data.date && data.time) {
    const experimentStart = moment(`${moment(data.date).format('MM-DD-YYYY')} ${moment(data.time).format('hh:mm a')}`);

    if (moment().isAfter(experimentStart)) {
      result.valid = false;
      result.errors.time = 'Experiment cannot be scheduled in the past';
    }
  }

  return result;
};

export const CreateEditExperimentPopover = ({
  experimentInfo = {},
  onCloseSchedulePopover,
  title,
  visible,
  isLabDevice,
  ...props
}) => {
  const [formValues, setFormValues] = useState({ date: null, time: null });
  const [errors, setErrors] = useState({ date: '', time: '' });

  useEffect(() => {
    if (visible) {
      setErrors({ date: '', time: '' });
      if (experimentInfo?.date)
        setFormValues({ date: setTOffsetUtc(experimentInfo.date), time: setTOffsetUtc(experimentInfo.date) });
      else setFormValues({ date: null, time: null });
    }
  }, [visible, experimentInfo.date]);

  const onFormUpdate = (value, property) => {
    setFormValues({
      ...formValues,
      [property]: value,
    });
    setErrors({
      ...errors,
      [property]: '',
    });
  };

  const onSaveExperiment = () => {
    const { errors, valid } = validateScheduleInfo(formValues);
    if (!valid) {
      setErrors(errors);
    } else {
      props.request &&
        props.request(formValues).catch(() => {
          if (isLabDevice)
            openNotification(null, 'You cannot schedule more than 5 experiments to be executed at the same time');
          setErrors({ time: 'This timeslot is occupied' });
        });
    }
  };

  return (
    <div className="schedule-popover-modal">
      <header className="schedule-popover-modal_header">
        <h5 className="schedule-popover-modal_header_title">{title}</h5>
      </header>
      <section>
        <div className="schedule-popover-modal_value_row">
          <div className="schedule-popover-modal_value_row_content">
            <label className="schedule-popover-modal_label">Date</label>
            <DatePicker
              disabled={props.loaderExternal}
              className="schedule-popover-modal_value"
              value={formValues.date}
              onChange={val => onFormUpdate(val, 'date')}
              format={dateMMDDYY}
              error={!!errors.date}
              disabledDate={current => moment().startOf('day') >= current}
            />
          </div>
          {errors.date && <span className="schedule-popover-modal_value_row_error">{errors.date}</span>}
        </div>
        <div className="schedule-popover-modal_value_row">
          <div className="schedule-popover-modal_value_row_content">
            <label className="schedule-popover-modal_label">Start time</label>
            <DatePicker
              disabled={props.loaderExternal}
              showNow={false}
              className="schedule-popover-modal_value"
              time
              value={formValues.time}
              onChange={val => onFormUpdate(val, 'time')}
              format={timehhmma}
              error={!!errors.time}
            />
          </div>
          {errors.time && <span className="schedule-popover-modal_value_row_error">{errors.time}</span>}
        </div>
        <div className="schedule-popover-modal_value_row">
          <div className="schedule-popover-modal_value_row_content">
            <label className="schedule-popover-modal_label">End time</label>
            <div className="schedule-popover-modal_value">
              {getEndTime(formValues.date, formValues.time, experimentInfo?.duration)}
            </div>
          </div>
        </div>
      </section>
      <footer className="schedule-popover-modal_footer">
        <Button disabled={props.loaderExternal} secondary onClick={onCloseSchedulePopover}>
          Cancel
        </Button>
        <Button disabled={props.loaderExternal} onClick={onSaveExperiment}>
          Continue
        </Button>
      </footer>
    </div>
  );
};
