import React from 'react';
import './style.scss';
import cn from 'classnames';
import limitingIcon from 'dist/icons/process-builder/limited_reagent.svg';
import { AddComponentButton } from './AddComponentButton';
import { Compound } from './Compound';
import { COMPOUND_VARIATY } from '../../../constants';
import { useProcessLabContext } from './ManualWorkArea';

const getCompoundLength = data =>
  (data.reactants?.length || 0) + (data.reagents?.length || 0) + (data.solvents?.length || 0);

export const PumpCompoundList = ({
  pumpProperties,
  openCompoundAdd,
  reactorIdx,
  pumpIdx,
  changeCompound,
  varationAvailable,
  setCompoundPosition,
  isMFCPump = false,
  manual = false,
}) => {
  const { editMode } = useProcessLabContext();
  const isPumpEmpty = pumpProperties.some(property => !getCompoundLength(property));
  const showVariationInEditMode = manual ? manual && editMode : true;

  const handleChangeCompound = (compound, property, compoundPosition, compoundIdx) => {
    setCompoundPosition(compoundPosition);
    const lengthNotSolvents = property.reagents.length + property.reactants.length;
    let compoundIndex = 0;
    if (compound.solvent) {
      compoundIndex = property.solvents.findIndex(
        (i, index) => i.solvent === compound.solvent && index === compoundIdx - lengthNotSolvents
      );
    } else if (compound.reagent) {
      compoundIndex = property.reagents.findIndex(
        (i, index) => i.reagent === compound.reagent && index === compoundIdx - property.reactants.length
      );
    } else if (compound.reactant) {
      compoundIndex = property.reactants.findIndex(
        (i, index) => i.reactant === compound.reactant && index === compoundIdx
      );
    }
    changeCompound(compound, reactorIdx, compoundIndex, pumpIdx, compoundPosition, varationAvailable);
  };
  return (
    <div className={cn('pump-compounds-list')}>
      {pumpProperties &&
        pumpProperties.map((property, i) => {
          const compoundsLength = getCompoundLength(property);
          const compounds = [...property.reactants, ...property.reagents, ...property.solvents];
          return (
            <div
              className={cn(
                'pump-compounds pump-item',
                { empty: !compoundsLength },
                { 'left-separator': i < pumpProperties.length - 1 && pumpProperties.length > 1 }
              )}
            >
              {varationAvailable && (pumpProperties.length > 1 || compoundsLength > 0) && (
                <>
                  <div className={cn({ 'variation-hidden': pumpProperties.length < 1 }, 'variation-container')}>
                    <span className="variation-label">{COMPOUND_VARIATY[i]}</span>
                  </div>
                </>
              )}
              {property.limiting && (
                <img
                  className={cn('limiting-reagent', {
                    variable: varationAvailable && (pumpProperties.length > 1 || compoundsLength > 0),
                  })}
                  src={limitingIcon}
                />
              )}
              {compoundsLength > 0 && (
                <>
                  {compounds.map((compound, idx) => (
                    <div
                      key={idx}
                      className={cn('compound-block', {
                        'bottom-separator': idx < compoundsLength - 1 && compoundsLength > 1,
                      })}
                    >
                      <div className="compound-block-content" style={{ width: 164, margin: 'auto' }}>
                        {compound?.properties && (
                          <Compound
                            data={compound?.properties}
                            nameOnly={isMFCPump || !!compound.solvent}
                            isMFC={isMFCPump}
                            key={idx}
                            label={`${reactorIdx}_${pumpIdx}_${idx}_${i}`}
                            changeCompound={() => {
                              handleChangeCompound(compound, property, i, manual ? pumpIdx : idx);
                            }}
                            displayLoupe
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {!manual && (
                <div className={cn('add-compound-button', { 'not-always-visible': compoundsLength > 0 })}>
                  <AddComponentButton type="compound" addAction={() => openCompoundAdd(i)} />
                </div>
              )}
            </div>
          );
        })}
      {pumpProperties.length < 5 && varationAvailable && !isPumpEmpty && showVariationInEditMode && (
        <div className={cn('pump-compounds', 'pump-add-compounds')}>
          <div className="variation-container">
            <span className="variation-label">{COMPOUND_VARIATY[pumpProperties.length]}</span>
          </div>
          <div className={cn('add-compound-button')}>
            <AddComponentButton
              type=""
              addAction={() => {
                setCompoundPosition(pumpProperties.length);
                openCompoundAdd(pumpProperties.length);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
