import './styles.scss';

import React from 'react';

import cn from 'classnames';

import { getIndexListOfHighlights } from './utils';

export const Highlighter = ({ value, highlightPart, active }) => {
  const visibleValue = value;
  const indexesOfHighlights = getIndexListOfHighlights(value, highlightPart);
  if (indexesOfHighlights[0] === -1 || indexesOfHighlights.length === 0) return value;
  return (
    <>
      {indexesOfHighlights.map((indexOfHighlight, n) => (
        <>
          {visibleValue.slice(0 + indexesOfHighlights[n - 1] + highlightPart.length, indexOfHighlight)}
          <mark className={cn('highlighter', { active })}>
            {visibleValue.slice(indexOfHighlight, indexOfHighlight + highlightPart.length)}
          </mark>
          {n === indexesOfHighlights.length - 1 &&
            visibleValue.slice(indexOfHighlight + highlightPart.length, visibleValue.length)}
        </>
      ))}
    </>
  );
};
