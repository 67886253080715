import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, CreateExperimentModal } from '../../../../components';
import { user } from '../../../../store/common/common.selector';
import { setModalOpen } from '../../../../store/experimentCreation/experimentCreation.action';

export const CreateExperiment = ({ buttonType }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(user);
  const canCreate = currentUser?.permissions?.execution?.create_experiment;
  if (!canCreate) {
    return <></>;
  }
  return (
    <>
      <Button type={buttonType} onClick={() => dispatch(setModalOpen())}>
        Create an experiment
      </Button>
      <CreateExperimentModal chooseInstrument showFirstStepIsLab />
    </>
  );
};
