import React, { useEffect, useState } from 'react';
import { ListExperiments } from '../../components/ListExperiments';
import { Calendar } from '../../components/Scheduling/Calendar';
import { Experiment } from '../../components/Experiment';
import { modeExc, modeSched } from '../../constants';
import ContainerExprSched from '../../components/ContainerExprSched';
const OPTIONS = [
  {
    title: 'Experiment IBU21',
  },
  {
    title: 'Experiment IBU21',
  },
];

export const Scheduling = () => {
  const { modeCalender, setModeCalendar } = useState(true);

  useEffect(() => {
    const main = document.querySelector('.main');
    main.style.padding = '0';
    return () => (main.style.padding = '24px 24px 0 24px');
  }, []);
  return <ContainerExprSched mode={modeSched} className="scheduling-container" />;
};
