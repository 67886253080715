import React, { useEffect } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import { setEditMode } from '../../store/common/common.actions';
import { OnBeforeLeave } from '../../components/OnBeforeLeave';

export const SynRoute = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const main = document.querySelector('.main');
    main.style.padding = '0';
    dispatch(setEditMode(true));
    return () => (main.style.padding = '24px 24px 0 24px');
  }, []);
  let tok = localStorage.getItem('token');
  let url = `https://synroute-stage.sri.com:5000/?token=${tok}`;
  return (
    <>
      <div className="iframe-wrapper">
        <iframe src={url} />
      </div>
      <OnBeforeLeave />
    </>
  );
};
