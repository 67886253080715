import { Bottle, Liquid, Molecule, Packed, Tubular } from '../components/Common';

export const USER_ROLES = {
  chemistryDesigner: 'Chemistry Designer',
  maintenanceTech: 'Maintenance Technician',
  operationsManager: 'Operations Manager',
  pm: 'Project Manager',
  sysAdmin: 'System Administrator',
  trainedOperator: 'Trained Operator',
};

const activeStatus = {
  name: 'active',
  className: 'active-color-icon',
  tooltip:
    'This project is active. Users are able access the project. Project can be associated with a route/process/experiment/compound.',
};

export const typesProcess = {
  lab: 'LAB',
  autoSyn: 'AS',
  synJet: 'SJ',
};

export const dependenciesRermissions = {
  user: { dependencies: ['devicetimeslot'] },
  project: { dependencies: [] },
  process: { dependencies: ['experiment', 'projectroute'] },
  scheduling: { dependencies: ['devicetimeslot', 'experiment', 'maintenance', 'note', 'batch'] },
  execution: { dependencies: ['experiment', 'note', 'batch', 'dat_file'] },
};

export const modeExc = 'execution';
export const modeSched = 'scheduling';
export const modeDetails = 'details';

const passiveStatus = {
  name: 'passive',
  className: 'draft-color-icon',
  tooltip:
    'This project is draft. Users can’t be associated with a Project. Project can’t be associated with a route/process/experiment/compound',
};

export const typesProjects = {
  'Process Development': {
    label: 'Process development',
    icon: Bottle,
    active: activeStatus,
    draft: passiveStatus,
  },
  'Drug Discovery': {
    label: 'Drug discovery',
    icon: Molecule,
    active: activeStatus,
    draft: passiveStatus,
  },
};

export const typesReactor = {
  tubular: {
    icon: Tubular,
  },
  liquid: {
    icon: Liquid,
    text: 'draft',
  },
  gas: {
    icon: Liquid,
    text: 'draft',
  },
  packed: {
    icon: Packed,
    text: 'draft',
  },
};

export const dateMMDDYYmmHH = 'MM/DD/YYYY hh:mm a';
export const dateMMDDYY = 'MM/DD/YYYY';
export const dateYYYYMMDD = 'YYYY-MM-DD';
export const dateMMDDYYTime = 'MM/DD/YYYY LT';
export const timehhmma = 'hh:mm a';
export const timemmss = 'mm:ss';

export const testRoute = {
  status: 'no-error',
  diversified: true,
  targetSmiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)c1cnccn1)B(O)O',
  database: 'CLEAN-REAXYS',
  nb_rxns_producing_target: 33,
  nb_new_rxns_producing_target: 33,
  routes: [
    {
      name: 'route-1',
      cost: 2.088702e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667194',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16967635',
          left_primaries: ['RXC-506297', 'CNEW-16667194'],
          right_primaries: ['CNEW-16667195'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16964652',
          left_primaries: ['CNEW-16667831', 'CNEW-16667195'],
          right_primaries: ['CNEW-16667131'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963221',
          left_primaries: ['CNEW-16667131'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.088702e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0677534e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            branches: [
              {
                prod_id: 'CNEW-16667131',
                cost: 2.046981e10,
                yield: 0.99,
                rxn_id: 'RNEW-amide n alkylation-16964652',
                branches: [
                  {
                    prod_id: 'CNEW-16667831',
                    cost: 190.24661,
                    yield: 0.99,
                    rxn_id: 'RNEW-amine alkylation-16963601',
                    branches: [
                      {
                        prod_id: 'RXC-907068',
                        cost: 65.41843,
                        yield: 1.0,
                        rxn_id: 'RXR-8865420',
                        branches: [
                          {
                            feedstock: 'RXC-110008',
                            cost: 15.338424,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667195',
                    cost: 2.0265112e10,
                    yield: 0.98691785,
                    rxn_id: 'RNEW-alcohol sulfonylation-16967635',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667194',
                        cost: 2.0e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667195',
            cost: 2.0265112e10,
            yield: 0.98691785,
            rxn_id: 'RNEW-alcohol sulfonylation-16967635',
            feedstockIds: ['RXC-506297', 'CNEW-16667194'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667131',
            cost: 2.046981e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide n alkylation-16964652',
            feedstockIds: [],
            branchIds: ['CNEW-16667831', 'CNEW-16667195'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0677534e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.088702e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-2',
      cost: 2.109851e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 18.31483,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667194',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154', 'RXC-17485996', 'RXC-906771'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16967635',
          left_primaries: ['RXC-506297', 'CNEW-16667194'],
          right_primaries: ['CNEW-16667195'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16967474',
          left_primaries: ['CNEW-16667859', 'CNEW-16667195'],
          right_primaries: ['CNEW-16667158'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964628',
          left_primaries: ['RXC-907068', 'CNEW-16667158'],
          right_primaries: ['CNEW-16667131'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963221',
          left_primaries: ['CNEW-16667131'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.109851e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0886905e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            branches: [
              {
                prod_id: 'CNEW-16667131',
                cost: 2.0677077e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964628',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667158',
                    cost: 2.0470307e10,
                    yield: 0.9899759,
                    rxn_id: 'RNEW-amide n alkylation-16967474',
                    branches: [
                      {
                        prod_id: 'CNEW-16667859',
                        cost: 168.96497,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                        branches: [
                          {
                            prod_id: 'RXC-21062592',
                            cost: 113.04069,
                            yield: 0.5,
                            rxn_id: 'RXR-30064816',
                            branches: [
                              {
                                feedstock: 'RXC-1910408',
                                cost: 8.240344,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667195',
                        cost: 2.0265112e10,
                        yield: 0.98691785,
                        rxn_id: 'RNEW-alcohol sulfonylation-16967635',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667194',
                            cost: 2.0e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667195',
            cost: 2.0265112e10,
            yield: 0.98691785,
            rxn_id: 'RNEW-alcohol sulfonylation-16967635',
            feedstockIds: ['RXC-506297', 'CNEW-16667194'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667158',
            cost: 2.0470307e10,
            yield: 0.9899759,
            rxn_id: 'RNEW-amide n alkylation-16967474',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667195'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667131',
            cost: 2.0677077e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964628',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667158'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0886905e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.109851e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-3',
      cost: 2.1098613e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667194',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16967635',
          left_primaries: ['RXC-506297', 'CNEW-16667194'],
          right_primaries: ['CNEW-16667195'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16967496',
          left_primaries: ['RXC-7014199', 'CNEW-16667195'],
          right_primaries: ['CNEW-16667153'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964629',
          left_primaries: ['RXC-907068', 'CNEW-16667153'],
          right_primaries: ['CNEW-16667131'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963221',
          left_primaries: ['CNEW-16667131'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.1098613e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0887005e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            branches: [
              {
                prod_id: 'CNEW-16667131',
                cost: 2.0677175e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964629',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667153',
                    cost: 2.0470403e10,
                    yield: 0.9899713,
                    rxn_id: 'RNEW-amide n alkylation-16967496',
                    branches: [
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667195',
                        cost: 2.0265112e10,
                        yield: 0.98691785,
                        rxn_id: 'RNEW-alcohol sulfonylation-16967635',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667194',
                            cost: 2.0e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667195',
            cost: 2.0265112e10,
            yield: 0.98691785,
            rxn_id: 'RNEW-alcohol sulfonylation-16967635',
            feedstockIds: ['RXC-506297', 'CNEW-16667194'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667153',
            cost: 2.0470403e10,
            yield: 0.9899713,
            rxn_id: 'RNEW-amide n alkylation-16967496',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667195'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667131',
            cost: 2.0677175e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964629',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667153'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0887005e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.1098613e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-4',
      cost: 2.1098613e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 3.7482433,
      cost_reagents: 24.332691,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667194',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-11382663',
        'RXC-3587189',
        'RXC-103233',
        'RXC-3587154',
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229', 'RXC-3587155', 'RXC-906771', 'RXC-2037554'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16967635',
          left_primaries: ['RXC-506297', 'CNEW-16667194'],
          right_primaries: ['CNEW-16667195'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16967518',
          left_primaries: ['CNEW-16667852', 'CNEW-16667195'],
          right_primaries: ['CNEW-16667148'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964630',
          left_primaries: ['RXC-907068', 'CNEW-16667148'],
          right_primaries: ['CNEW-16667131'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963221',
          left_primaries: ['CNEW-16667131'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.1098613e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0887005e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            branches: [
              {
                prod_id: 'CNEW-16667131',
                cost: 2.0677175e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964630',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667148',
                    cost: 2.0470403e10,
                    yield: 0.9899713,
                    rxn_id: 'RNEW-amide n alkylation-16967518',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667195',
                        cost: 2.0265112e10,
                        yield: 0.98691785,
                        rxn_id: 'RNEW-alcohol sulfonylation-16967635',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667194',
                            cost: 2.0e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667195',
            cost: 2.0265112e10,
            yield: 0.98691785,
            rxn_id: 'RNEW-alcohol sulfonylation-16967635',
            feedstockIds: ['RXC-506297', 'CNEW-16667194'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667148',
            cost: 2.0470403e10,
            yield: 0.9899713,
            rxn_id: 'RNEW-amide n alkylation-16967518',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667195'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667131',
            cost: 2.0677175e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964630',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667148'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0887005e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.1098613e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-5',
      cost: 2.1133832e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 3.6403692,
      cost_reagents: 5717.002,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-1098229',
          name: 'methanol',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667194',
          name: '',
          purchasable: false,
        },
        {
          cid: 'RXC-105778',
          name: 'methylpyrazine',
          purchasable: true,
        },
      ],
      reagents: [
        'RXC-3587154',
        'RXC-1098229',
        'RXC-605303',
        'RXC-2804068',
        'RXC-1098214',
        'RXC-1098595',
        'RXC-2349612',
        'RXC-8008338',
        'RXC-1209273',
        'RXC-113915',
        'RXC-1210120',
        'RXC-984320',
        'RXC-105778',
        'RXC-1708400',
        'RXC-202022',
        'RXC-1098295',
      ],
      solvents: ['RXC-1098229', 'RXC-1098295', 'RXC-1731042', 'RXC-506104', 'RXC-635760'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-9120677',
          left_primaries: ['RXC-1098229', 'RXC-2804068'],
          right_primaries: ['RXC-2938915'],
        },
        {
          rxn_id: 'RXR-2183112',
          left_primaries: ['RXC-2938915'],
          right_primaries: ['RXC-3198805'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16967635',
          left_primaries: ['RXC-506297', 'CNEW-16667194'],
          right_primaries: ['CNEW-16667195'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16967566',
          left_primaries: ['RXC-3198805', 'CNEW-16667195'],
          right_primaries: ['CNEW-16667168'],
        },
        {
          rxn_id: 'RXR-1529131',
          left_primaries: ['RXC-105778'],
          right_primaries: ['RXC-606324'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964633',
          left_primaries: ['CNEW-16667168', 'RXC-606324'],
          right_primaries: ['CNEW-16667131'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963221',
          left_primaries: ['CNEW-16667131'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.1133832e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0921872e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            branches: [
              {
                prod_id: 'CNEW-16667131',
                cost: 2.0711692e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964633',
                branches: [
                  {
                    prod_id: 'CNEW-16667168',
                    cost: 2.0504576e10,
                    yield: 0.9883214,
                    rxn_id: 'RNEW-amide n alkylation-16967566',
                    branches: [
                      {
                        prod_id: 'RXC-3198805',
                        cost: 131.84256,
                        yield: 0.77,
                        rxn_id: 'RXR-2183112',
                        branches: [
                          {
                            prod_id: 'RXC-2938915',
                            cost: 62.998764,
                            yield: 1.0,
                            rxn_id: 'RXR-9120677',
                            branches: [
                              {
                                feedstock: 'RXC-1098229',
                                cost: 0.10589881,
                                purchasable: true,
                              },
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667195',
                        cost: 2.0265112e10,
                        yield: 0.98691785,
                        rxn_id: 'RNEW-alcohol sulfonylation-16967635',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667194',
                            cost: 2.0e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'RXC-606324',
                    cost: 78.66611,
                    yield: 0.89,
                    rxn_id: 'RXR-1529131',
                    branches: [
                      {
                        feedstock: 'RXC-105778',
                        cost: 19.932835,
                        purchasable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'RXC-606324',
            cost: 78.66611,
            yield: 0.89,
            rxn_id: 'RXR-1529131',
            feedstockIds: ['RXC-105778'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667195',
            cost: 2.0265112e10,
            yield: 0.98691785,
            rxn_id: 'RNEW-alcohol sulfonylation-16967635',
            feedstockIds: ['RXC-506297', 'CNEW-16667194'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2938915',
            cost: 62.998764,
            yield: 1.0,
            rxn_id: 'RXR-9120677',
            feedstockIds: ['RXC-1098229', 'RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-3198805',
            cost: 131.84256,
            yield: 0.77,
            rxn_id: 'RXR-2183112',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667168',
            cost: 2.0504576e10,
            yield: 0.9883214,
            rxn_id: 'RNEW-amide n alkylation-16967566',
            feedstockIds: [],
            branchIds: ['RXC-3198805', 'CNEW-16667195'],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667131',
            cost: 2.0711692e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964633',
            feedstockIds: [],
            branchIds: ['CNEW-16667168', 'RXC-606324'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0921872e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.1133832e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-6',
      cost: 2.3072684e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667092',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16967336',
          left_primaries: ['CNEW-16667831', 'CNEW-16667092'],
          right_primaries: ['CNEW-16667079'],
        },
        {
          rxn_id: 'RNEW-dehydration-16964602',
          left_primaries: ['CNEW-16667079'],
          right_primaries: ['CNEW-16667000'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963220',
          left_primaries: ['CNEW-16667000'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.3072684e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.2841278e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            branches: [
              {
                prod_id: 'CNEW-16667000',
                cost: 2.2612564e10,
                yield: 0.9827379,
                rxn_id: 'RNEW-dehydration-16964602',
                branches: [
                  {
                    prod_id: 'CNEW-16667079',
                    cost: 2.2222223e10,
                    yield: 0.99,
                    rxn_id: 'RNEW-amine alkylation-16967336',
                    branches: [
                      {
                        prod_id: 'CNEW-16667831',
                        cost: 190.24661,
                        yield: 0.99,
                        rxn_id: 'RNEW-amine alkylation-16963601',
                        branches: [
                          {
                            prod_id: 'RXC-907068',
                            cost: 65.41843,
                            yield: 1.0,
                            rxn_id: 'RXR-8865420',
                            branches: [
                              {
                                feedstock: 'RXC-110008',
                                cost: 15.338424,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            prod_id: 'RXC-7014199',
                            cost: 73.42573,
                            yield: 0.5,
                            rxn_id: 'RXR-4048902',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        feedstock: 'CNEW-16667092',
                        cost: 2.2000001e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
          {
            prod_id: 'CNEW-16667079',
            cost: 2.2222223e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16967336',
            feedstockIds: ['CNEW-16667092'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667000',
            cost: 2.2612564e10,
            yield: 0.9827379,
            rxn_id: 'RNEW-dehydration-16964602',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.2841278e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.3072684e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-7',
      cost: 2.3072684e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667091',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16967333',
          left_primaries: ['CNEW-16667831', 'CNEW-16667091'],
          right_primaries: ['CNEW-16667079'],
        },
        {
          rxn_id: 'RNEW-dehydration-16964602',
          left_primaries: ['CNEW-16667079'],
          right_primaries: ['CNEW-16667000'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963220',
          left_primaries: ['CNEW-16667000'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.3072684e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.2841278e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            branches: [
              {
                prod_id: 'CNEW-16667000',
                cost: 2.2612564e10,
                yield: 0.9827379,
                rxn_id: 'RNEW-dehydration-16964602',
                branches: [
                  {
                    prod_id: 'CNEW-16667079',
                    cost: 2.2222223e10,
                    yield: 0.99,
                    rxn_id: 'RNEW-amine alkylation-16967333',
                    branches: [
                      {
                        prod_id: 'CNEW-16667831',
                        cost: 190.24661,
                        yield: 0.99,
                        rxn_id: 'RNEW-amine alkylation-16963601',
                        branches: [
                          {
                            prod_id: 'RXC-907068',
                            cost: 65.41843,
                            yield: 1.0,
                            rxn_id: 'RXR-8865420',
                            branches: [
                              {
                                feedstock: 'RXC-110008',
                                cost: 15.338424,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            prod_id: 'RXC-7014199',
                            cost: 73.42573,
                            yield: 0.5,
                            rxn_id: 'RXR-4048902',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        feedstock: 'CNEW-16667091',
                        cost: 2.2000001e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
          {
            prod_id: 'CNEW-16667079',
            cost: 2.2222223e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16967333',
            feedstockIds: ['CNEW-16667091'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667000',
            cost: 2.2612564e10,
            yield: 0.9827379,
            rxn_id: 'RNEW-dehydration-16964602',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.2841278e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.3072684e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-8',
      cost: 2.498676e10,
      depth: 8,
      nbrxns: 9,
      nbnewrxns: 7,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667092',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16967336',
          left_primaries: ['CNEW-16667831', 'CNEW-16667092'],
          right_primaries: ['CNEW-16667079'],
        },
        {
          rxn_id: 'RNEW-dehydration-16964602',
          left_primaries: ['CNEW-16667079'],
          right_primaries: ['CNEW-16667000'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16967401',
          left_primaries: ['CNEW-16667000'],
          right_primaries: ['CNEW-16666990'],
        },
        {
          rxn_id: 'RNEW-amide to amine reduction-16964617',
          left_primaries: ['CNEW-16666990'],
          right_primaries: ['CNEW-16667000'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963220',
          left_primaries: ['CNEW-16667000'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.498676e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.4736156e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            branches: [
              {
                prod_id: 'CNEW-16667000',
                cost: 2.4488466e10,
                yield: 0.93355,
                rxn_id: 'RNEW-amide to amine reduction-16964617',
                branches: [
                  {
                    prod_id: 'CNEW-16666990',
                    cost: 2.2861208e10,
                    yield: 0.9891238,
                    rxn_id: 'RNEW-benzylic oxidation-16967401',
                    branches: [
                      {
                        prod_id: 'CNEW-16667000',
                        cost: 2.2612564e10,
                        yield: 0.9827379,
                        rxn_id: 'RNEW-dehydration-16964602',
                        branches: [
                          {
                            prod_id: 'CNEW-16667079',
                            cost: 2.2222223e10,
                            yield: 0.99,
                            rxn_id: 'RNEW-amine alkylation-16967336',
                            branches: [
                              {
                                prod_id: 'CNEW-16667831',
                                cost: 190.24661,
                                yield: 0.99,
                                rxn_id: 'RNEW-amine alkylation-16963601',
                                branches: [
                                  {
                                    prod_id: 'RXC-907068',
                                    cost: 65.41843,
                                    yield: 1.0,
                                    rxn_id: 'RXR-8865420',
                                    branches: [
                                      {
                                        feedstock: 'RXC-110008',
                                        cost: 15.338424,
                                        purchasable: true,
                                      },
                                    ],
                                  },
                                  {
                                    prod_id: 'RXC-7014199',
                                    cost: 73.42573,
                                    yield: 0.5,
                                    rxn_id: 'RXR-4048902',
                                    branches: [
                                      {
                                        feedstock: 'RXC-2804068',
                                        cost: 11.712864,
                                        purchasable: true,
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                feedstock: 'CNEW-16667092',
                                cost: 2.2000001e10,
                                purchasable: false,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
          {
            prod_id: 'CNEW-16667079',
            cost: 2.2222223e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16967336',
            feedstockIds: ['CNEW-16667092'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667000',
            cost: 2.2612564e10,
            yield: 0.9827379,
            rxn_id: 'RNEW-dehydration-16964602',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16666990',
            cost: 2.2861208e10,
            yield: 0.9891238,
            rxn_id: 'RNEW-benzylic oxidation-16967401',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667000',
            cost: 2.4488466e10,
            yield: 0.93355,
            rxn_id: 'RNEW-amide to amine reduction-16964617',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.4736156e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.498676e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-9',
      cost: 2.498676e10,
      depth: 8,
      nbrxns: 9,
      nbnewrxns: 7,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667091',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16967333',
          left_primaries: ['CNEW-16667831', 'CNEW-16667091'],
          right_primaries: ['CNEW-16667079'],
        },
        {
          rxn_id: 'RNEW-dehydration-16964602',
          left_primaries: ['CNEW-16667079'],
          right_primaries: ['CNEW-16667000'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16967401',
          left_primaries: ['CNEW-16667000'],
          right_primaries: ['CNEW-16666990'],
        },
        {
          rxn_id: 'RNEW-amide to amine reduction-16964617',
          left_primaries: ['CNEW-16666990'],
          right_primaries: ['CNEW-16667000'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963220',
          left_primaries: ['CNEW-16667000'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.498676e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.4736156e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            branches: [
              {
                prod_id: 'CNEW-16667000',
                cost: 2.4488466e10,
                yield: 0.93355,
                rxn_id: 'RNEW-amide to amine reduction-16964617',
                branches: [
                  {
                    prod_id: 'CNEW-16666990',
                    cost: 2.2861208e10,
                    yield: 0.9891238,
                    rxn_id: 'RNEW-benzylic oxidation-16967401',
                    branches: [
                      {
                        prod_id: 'CNEW-16667000',
                        cost: 2.2612564e10,
                        yield: 0.9827379,
                        rxn_id: 'RNEW-dehydration-16964602',
                        branches: [
                          {
                            prod_id: 'CNEW-16667079',
                            cost: 2.2222223e10,
                            yield: 0.99,
                            rxn_id: 'RNEW-amine alkylation-16967333',
                            branches: [
                              {
                                prod_id: 'CNEW-16667831',
                                cost: 190.24661,
                                yield: 0.99,
                                rxn_id: 'RNEW-amine alkylation-16963601',
                                branches: [
                                  {
                                    prod_id: 'RXC-907068',
                                    cost: 65.41843,
                                    yield: 1.0,
                                    rxn_id: 'RXR-8865420',
                                    branches: [
                                      {
                                        feedstock: 'RXC-110008',
                                        cost: 15.338424,
                                        purchasable: true,
                                      },
                                    ],
                                  },
                                  {
                                    prod_id: 'RXC-7014199',
                                    cost: 73.42573,
                                    yield: 0.5,
                                    rxn_id: 'RXR-4048902',
                                    branches: [
                                      {
                                        feedstock: 'RXC-2804068',
                                        cost: 11.712864,
                                        purchasable: true,
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                feedstock: 'CNEW-16667091',
                                cost: 2.2000001e10,
                                purchasable: false,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
          {
            prod_id: 'CNEW-16667079',
            cost: 2.2222223e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16967333',
            feedstockIds: ['CNEW-16667091'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667000',
            cost: 2.2612564e10,
            yield: 0.9827379,
            rxn_id: 'RNEW-dehydration-16964602',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16666990',
            cost: 2.2861208e10,
            yield: 0.9891238,
            rxn_id: 'RNEW-benzylic oxidation-16967401',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667000',
            cost: 2.4488466e10,
            yield: 0.93355,
            rxn_id: 'RNEW-amide to amine reduction-16964617',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.4736156e10,
            yield: 0.9899868,
            rxn_id: 'RNEW-alkene to alkane-16963220',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.498676e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-10',
      cost: 2.519266e10,
      depth: 4,
      nbrxns: 5,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.0,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962959',
          left_primaries: ['RXC-112306', 'RXC-7014199'],
          right_primaries: ['CNEW-16667816'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963245',
          left_primaries: ['CNEW-16667816', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.519266e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3468095e10,
            yield: 0.98999965,
            rxn_id: 'RNEW-amide n alkylation-16963245',
            branches: [
              {
                prod_id: 'CNEW-16667816',
                cost: 164.67761,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16962959',
                branches: [
                  {
                    feedstock: 'RXC-112306',
                    cost: 40.105114,
                    purchasable: true,
                  },
                  {
                    prod_id: 'RXC-7014199',
                    cost: 73.42573,
                    yield: 0.5,
                    rxn_id: 'RXR-4048902',
                    branches: [
                      {
                        feedstock: 'RXC-2804068',
                        cost: 11.712864,
                        purchasable: true,
                      },
                    ],
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667729',
                cost: 2.3233407e10,
                yield: 0.9899538,
                rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                branches: [
                  {
                    feedstock: 'RXC-506297',
                    cost: 3.7410412,
                    purchasable: true,
                  },
                  {
                    feedstock: 'CNEW-16667727',
                    cost: 2.3e10,
                    purchasable: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667816',
            cost: 164.67761,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962959',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3468095e10,
            yield: 0.98999965,
            rxn_id: 'RNEW-amide n alkylation-16963245',
            feedstockIds: [],
            branchIds: ['CNEW-16667816', 'CNEW-16667729'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.519266e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-11',
      cost: 2.5241256e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16964685',
          left_primaries: ['CNEW-16667831', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963224',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5241256e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.49881e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.346809e10,
                yield: 0.98999995,
                rxn_id: 'RNEW-amide n alkylation-16964685',
                branches: [
                  {
                    prod_id: 'CNEW-16667831',
                    cost: 190.24661,
                    yield: 0.99,
                    rxn_id: 'RNEW-amine alkylation-16963601',
                    branches: [
                      {
                        prod_id: 'RXC-907068',
                        cost: 65.41843,
                        yield: 1.0,
                        rxn_id: 'RXR-8865420',
                        branches: [
                          {
                            feedstock: 'RXC-110008',
                            cost: 15.338424,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.346809e10,
            yield: 0.98999995,
            rxn_id: 'RNEW-amide n alkylation-16964685',
            feedstockIds: [],
            branchIds: ['CNEW-16667831', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.49881e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5241256e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-12',
      cost: 2.5447164e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16964685',
          left_primaries: ['CNEW-16667831', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16963256',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5447164e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3705178e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.346809e10,
                yield: 0.98999995,
                rxn_id: 'RNEW-amide n alkylation-16964685',
                branches: [
                  {
                    prod_id: 'CNEW-16667831',
                    cost: 190.24661,
                    yield: 0.99,
                    rxn_id: 'RNEW-amine alkylation-16963601',
                    branches: [
                      {
                        prod_id: 'RXC-907068',
                        cost: 65.41843,
                        yield: 1.0,
                        rxn_id: 'RXR-8865420',
                        branches: [
                          {
                            feedstock: 'RXC-110008',
                            cost: 15.338424,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.346809e10,
            yield: 0.98999995,
            rxn_id: 'RNEW-amide n alkylation-16964685',
            feedstockIds: [],
            branchIds: ['CNEW-16667831', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3705178e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5447164e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-13',
      cost: 2.5449087e10,
      depth: 5,
      nbrxns: 6,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 9.884702,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-17485996', 'RXC-906771'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963232',
          left_primaries: ['RXC-112306', 'CNEW-16667566'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5449087e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963232',
            branches: [
              {
                feedstock: 'RXC-112306',
                cost: 40.105114,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667566',
                cost: 2.3469898e10,
                yield: 0.98992366,
                rxn_id: 'RNEW-amide n alkylation-16963669',
                branches: [
                  {
                    prod_id: 'CNEW-16667859',
                    cost: 168.96497,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                    branches: [
                      {
                        prod_id: 'RXC-21062592',
                        cost: 113.04069,
                        yield: 0.5,
                        rxn_id: 'RXR-30064816',
                        branches: [
                          {
                            feedstock: 'RXC-1910408',
                            cost: 8.240344,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963232',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5449087e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-14',
      cost: 2.5449464e10,
      depth: 5,
      nbrxns: 6,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 0.82039493,
      cost_reagents: 15.902564,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-3587155', 'RXC-635760', 'RXC-906771', 'RXC-2037554'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963234',
          left_primaries: ['RXC-112306', 'CNEW-16667524'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5449464e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963234',
            branches: [
              {
                feedstock: 'RXC-112306',
                cost: 40.105114,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667524',
                cost: 2.3470248e10,
                yield: 0.9899089,
                rxn_id: 'RNEW-amide n alkylation-16963722',
                branches: [
                  {
                    prod_id: 'CNEW-16667852',
                    cost: 119.259865,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                    branches: [
                      {
                        prod_id: 'RXC-2328698',
                        cost: 63.83265,
                        yield: 0.927,
                        rxn_id: 'RXR-2173213',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963234',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5449464e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-15',
      cost: 2.5449464e10,
      depth: 4,
      nbrxns: 5,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.0,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963233',
          left_primaries: ['RXC-112306', 'CNEW-16667545'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5449464e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963233',
            branches: [
              {
                feedstock: 'RXC-112306',
                cost: 40.105114,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667545',
                cost: 2.3470248e10,
                yield: 0.9899089,
                rxn_id: 'RNEW-amide n alkylation-16963691',
                branches: [
                  {
                    prod_id: 'RXC-7014199',
                    cost: 73.42573,
                    yield: 0.5,
                    rxn_id: 'RXR-4048902',
                    branches: [
                      {
                        feedstock: 'RXC-2804068',
                        cost: 11.712864,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963233',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5449464e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-16',
      cost: 2.5456028e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.26185226,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-103905',
          name: '1,4-pyrazine',
          purchasable: true,
        },
        {
          cid: 'RXC-1732543',
          name: 'bromotrichloromethane',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-2037554'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-26486354',
          left_primaries: ['RXC-1732543'],
          right_primaries: ['RXC-1698092'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963836',
          left_primaries: ['RXC-1698092', 'RXC-2804068'],
          right_primaries: ['CNEW-16667895'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963339',
          left_primaries: ['CNEW-16667895', 'RXC-3587154'],
          right_primaries: ['CNEW-16667808'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963875',
          left_primaries: ['CNEW-16667808', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667401'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963237',
          left_primaries: ['RXC-103905', 'CNEW-16667401'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5456028e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3713434e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            branches: [
              {
                feedstock: 'RXC-103905',
                cost: 47.3759,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667401',
                cost: 2.347624e10,
                yield: 0.9896562,
                rxn_id: 'RNEW-amide n alkylation-16963875',
                branches: [
                  {
                    prod_id: 'CNEW-16667808',
                    cost: 196.36078,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963339',
                    branches: [
                      {
                        prod_id: 'CNEW-16667895',
                        cost: 140.16255,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide from acid chloride-16963836',
                        branches: [
                          {
                            prod_id: 'RXC-1698092',
                            cost: 77.548065,
                            yield: 0.5,
                            rxn_id: 'RXR-26486354',
                            branches: [
                              {
                                feedstock: 'RXC-1732543',
                                cost: 13.76403,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-1698092',
            cost: 77.548065,
            yield: 0.5,
            rxn_id: 'RXR-26486354',
            feedstockIds: ['RXC-1732543'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667895',
            cost: 140.16255,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963836',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667808',
            cost: 196.36078,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963339',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667401',
            cost: 2.347624e10,
            yield: 0.9896562,
            rxn_id: 'RNEW-amide n alkylation-16963875',
            feedstockIds: [],
            branchIds: ['CNEW-16667808', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3713434e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            feedstockIds: ['RXC-103905'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5456028e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-17',
      cost: 2.5456028e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.1,
      cost_reagents: 6.183184,
      cost_catalysts: 1075.8013,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-103905',
          name: '1,4-pyrazine',
          purchasable: true,
        },
        {
          cid: 'RXC-1098295',
          name: 'tetrachloromethane',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3587155',
        'RXC-14497211',
        'RXC-19270857',
        'RXC-19290122',
        'RXC-3587218',
        'RXC-8128137',
        'RXC-19276987',
        'RXC-4360473',
        'RXC-4122945',
        'RXC-19284165',
        'RXC-2037554',
        'RXC-8973685',
        'RXC-19286924',
        'RXC-3535376',
        'RXC-13320054',
        'RXC-19273435',
        'RXC-4921393',
        'RXC-3587191',
        'RXC-8135986',
        'RXC-3902804',
      ],
      solvents: ['RXC-3587155'],
      catalysts: ['RXC-14247131', 'RXC-3587236', 'RXC-3903066', 'RXC-16501948', 'RXC-3535368'],
      route: [
        {
          rxn_id: 'RXR-61316',
          left_primaries: ['RXC-1098295'],
          right_primaries: ['RXC-1098367'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963774',
          left_primaries: ['RXC-1098367', 'RXC-2804068'],
          right_primaries: ['CNEW-16667902'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963320',
          left_primaries: ['CNEW-16667902', 'RXC-3587154'],
          right_primaries: ['CNEW-16667809'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963814',
          left_primaries: ['CNEW-16667809', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667404'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963236',
          left_primaries: ['RXC-103905', 'CNEW-16667404'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5456028e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3713434e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            branches: [
              {
                feedstock: 'RXC-103905',
                cost: 47.3759,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667404',
                cost: 2.347624e10,
                yield: 0.9896562,
                rxn_id: 'RNEW-amide n alkylation-16963814',
                branches: [
                  {
                    prod_id: 'CNEW-16667809',
                    cost: 168.40076,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963320',
                    branches: [
                      {
                        prod_id: 'CNEW-16667902',
                        cost: 112.48213,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide from acid chloride-16963774',
                        branches: [
                          {
                            prod_id: 'RXC-1098367',
                            cost: 50.144444,
                            yield: 0.9,
                            rxn_id: 'RXR-61316',
                            branches: [
                              {
                                feedstock: 'RXC-1098295',
                                cost: 0.1,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-1098367',
            cost: 50.144444,
            yield: 0.9,
            rxn_id: 'RXR-61316',
            feedstockIds: ['RXC-1098295'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667902',
            cost: 112.48213,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963774',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667809',
            cost: 168.40076,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963320',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667404',
            cost: 2.347624e10,
            yield: 0.9896562,
            rxn_id: 'RNEW-amide n alkylation-16963814',
            feedstockIds: [],
            branchIds: ['CNEW-16667809', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3713434e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            feedstockIds: ['RXC-103905'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5456028e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-18',
      cost: 2.5496125e10,
      depth: 5,
      nbrxns: 6,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 0.82039493,
      cost_reagents: 15.902564,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-3587155', 'RXC-635760', 'RXC-906771', 'RXC-2037554'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963042',
          left_primaries: ['CNEW-16667524'],
          right_primaries: ['CNEW-16667523'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962903',
          left_primaries: ['RXC-112306', 'CNEW-16667523'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5496125e10,
        yield: 0.99,
        rxn_id: 'RNEW-amine alkylation-16962903',
        branches: [
          {
            feedstock: 'RXC-112306',
            cost: 40.105114,
            purchasable: true,
          },
          {
            prod_id: 'CNEW-16667523',
            cost: 2.5241164e10,
            yield: 0.92984015,
            rxn_id: 'RNEW-desilylation-16963042',
            branches: [
              {
                prod_id: 'CNEW-16667524',
                cost: 2.3470248e10,
                yield: 0.9899089,
                rxn_id: 'RNEW-amide n alkylation-16963722',
                branches: [
                  {
                    prod_id: 'CNEW-16667852',
                    cost: 119.259865,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                    branches: [
                      {
                        prod_id: 'RXC-2328698',
                        cost: 63.83265,
                        yield: 0.927,
                        rxn_id: 'RXR-2173213',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667523',
            cost: 2.5241164e10,
            yield: 0.92984015,
            rxn_id: 'RNEW-desilylation-16963042',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5496125e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962903',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-19',
      cost: 2.5496125e10,
      depth: 4,
      nbrxns: 5,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.0,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963024',
          left_primaries: ['CNEW-16667545'],
          right_primaries: ['CNEW-16667544'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962902',
          left_primaries: ['RXC-112306', 'CNEW-16667544'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5496125e10,
        yield: 0.99,
        rxn_id: 'RNEW-amine alkylation-16962902',
        branches: [
          {
            feedstock: 'RXC-112306',
            cost: 40.105114,
            purchasable: true,
          },
          {
            prod_id: 'CNEW-16667544',
            cost: 2.5241164e10,
            yield: 0.92984015,
            rxn_id: 'RNEW-desilylation-16963024',
            branches: [
              {
                prod_id: 'CNEW-16667545',
                cost: 2.3470248e10,
                yield: 0.9899089,
                rxn_id: 'RNEW-amide n alkylation-16963691',
                branches: [
                  {
                    prod_id: 'RXC-7014199',
                    cost: 73.42573,
                    yield: 0.5,
                    rxn_id: 'RXR-4048902',
                    branches: [
                      {
                        feedstock: 'RXC-2804068',
                        cost: 11.712864,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667544',
            cost: 2.5241164e10,
            yield: 0.92984015,
            rxn_id: 'RNEW-desilylation-16963024',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5496125e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962902',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-20',
      cost: 2.549773e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.0,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962959',
          left_primaries: ['RXC-112306', 'RXC-7014199'],
          right_primaries: ['CNEW-16667816'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963245',
          left_primaries: ['CNEW-16667816', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-amide to amine reduction-16964688',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963224',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.549773e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242001e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.3706546e10,
                yield: 0.98994154,
                rxn_id: 'RNEW-amide to amine reduction-16964688',
                branches: [
                  {
                    prod_id: 'CNEW-16667419',
                    cost: 2.3468095e10,
                    yield: 0.98999965,
                    rxn_id: 'RNEW-amide n alkylation-16963245',
                    branches: [
                      {
                        prod_id: 'CNEW-16667816',
                        cost: 164.67761,
                        yield: 0.99,
                        rxn_id: 'RNEW-amine alkylation-16962959',
                        branches: [
                          {
                            feedstock: 'RXC-112306',
                            cost: 40.105114,
                            purchasable: true,
                          },
                          {
                            prod_id: 'RXC-7014199',
                            cost: 73.42573,
                            yield: 0.5,
                            rxn_id: 'RXR-4048902',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667816',
            cost: 164.67761,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962959',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3468095e10,
            yield: 0.98999965,
            rxn_id: 'RNEW-amide n alkylation-16963245',
            feedstockIds: [],
            branchIds: ['CNEW-16667816', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667445',
            cost: 2.3706546e10,
            yield: 0.98994154,
            rxn_id: 'RNEW-amide to amine reduction-16964688',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242001e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.549773e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-21',
      cost: 2.5498184e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 18.31483,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154', 'RXC-17485996', 'RXC-906771'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964663',
          left_primaries: ['RXC-907068', 'CNEW-16667566'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963224',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5498184e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242452e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.3706968e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964663',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667566',
                    cost: 2.3469898e10,
                    yield: 0.98992366,
                    rxn_id: 'RNEW-amide n alkylation-16963669',
                    branches: [
                      {
                        prod_id: 'CNEW-16667859',
                        cost: 168.96497,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                        branches: [
                          {
                            prod_id: 'RXC-21062592',
                            cost: 113.04069,
                            yield: 0.5,
                            rxn_id: 'RXR-30064816',
                            branches: [
                              {
                                feedstock: 'RXC-1910408',
                                cost: 8.240344,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964663',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667566'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242452e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5498184e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-22',
      cost: 2.5498563e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964664',
          left_primaries: ['RXC-907068', 'CNEW-16667545'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963224',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5498563e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242827e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964664',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667545',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963691',
                    branches: [
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964664',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667545'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242827e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5498563e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-23',
      cost: 2.5498563e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 3.7482433,
      cost_reagents: 24.332691,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-11382663',
        'RXC-3587189',
        'RXC-103233',
        'RXC-3587154',
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229', 'RXC-3587155', 'RXC-906771', 'RXC-2037554'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964665',
          left_primaries: ['RXC-907068', 'CNEW-16667524'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963224',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5498563e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242827e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964665',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667524',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963722',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964665',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667524'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.5242827e10,
            yield: 0.9391706,
            rxn_id: 'RNEW-desilylation-16963224',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5498563e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-24',
      cost: 2.552737e10,
      depth: 3,
      nbrxns: 5,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.0,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667731',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962959',
          left_primaries: ['RXC-112306', 'RXC-7014199'],
          right_primaries: ['CNEW-16667816'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963947',
          left_primaries: ['CNEW-16667731'],
          right_primaries: ['CNEW-16667726'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963144',
          left_primaries: ['RXC-506297', 'CNEW-16667726'],
          right_primaries: ['CNEW-16667728'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16962914',
          left_primaries: ['CNEW-16667816', 'CNEW-16667728'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.552737e10,
        yield: 0.9899991,
        rxn_id: 'RNEW-amide n alkylation-16962914',
        branches: [
          {
            prod_id: 'CNEW-16667816',
            cost: 164.67761,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962959',
            branches: [
              {
                feedstock: 'RXC-112306',
                cost: 40.105114,
                purchasable: true,
              },
              {
                prod_id: 'RXC-7014199',
                cost: 73.42573,
                yield: 0.5,
                rxn_id: 'RXR-4048902',
                branches: [
                  {
                    feedstock: 'RXC-2804068',
                    cost: 11.712864,
                    purchasable: true,
                  },
                ],
              },
            ],
          },
          {
            prod_id: 'CNEW-16667728',
            cost: 2.5272074e10,
            yield: 0.98999894,
            rxn_id: 'RNEW-alcohol sulfonylation-16963144',
            branches: [
              {
                feedstock: 'RXC-506297',
                cost: 3.7410412,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667726',
                cost: 2.5019326e10,
                yield: 0.91928935,
                rxn_id: 'RNEW-desilylation-16963947',
                branches: [
                  {
                    feedstock: 'CNEW-16667731',
                    cost: 2.3e10,
                    purchasable: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667726',
            cost: 2.5019326e10,
            yield: 0.91928935,
            rxn_id: 'RNEW-desilylation-16963947',
            feedstockIds: ['CNEW-16667731'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667728',
            cost: 2.5272074e10,
            yield: 0.98999894,
            rxn_id: 'RNEW-alcohol sulfonylation-16963144',
            feedstockIds: ['RXC-506297'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667816',
            cost: 164.67761,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962959',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-11330190',
            cost: 2.552737e10,
            yield: 0.9899991,
            rxn_id: 'RNEW-amide n alkylation-16962914',
            feedstockIds: [],
            branchIds: ['CNEW-16667816', 'CNEW-16667728'],
          },
        ],
      ],
    },
    {
      name: 'route-25',
      cost: 2.5555583e10,
      depth: 5,
      nbrxns: 6,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 9.884702,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-17485996', 'RXC-906771'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963006',
          left_primaries: ['CNEW-16667566'],
          right_primaries: ['CNEW-16667565'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962901',
          left_primaries: ['RXC-112306', 'CNEW-16667565'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5555583e10,
        yield: 0.99,
        rxn_id: 'RNEW-amine alkylation-16962901',
        branches: [
          {
            feedstock: 'RXC-112306',
            cost: 40.105114,
            purchasable: true,
          },
          {
            prod_id: 'CNEW-16667565',
            cost: 2.5300027e10,
            yield: 0.92766297,
            rxn_id: 'RNEW-desilylation-16963006',
            branches: [
              {
                prod_id: 'CNEW-16667566',
                cost: 2.3469898e10,
                yield: 0.98992366,
                rxn_id: 'RNEW-amide n alkylation-16963669',
                branches: [
                  {
                    prod_id: 'CNEW-16667859',
                    cost: 168.96497,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                    branches: [
                      {
                        prod_id: 'RXC-21062592',
                        cost: 113.04069,
                        yield: 0.5,
                        rxn_id: 'RXR-30064816',
                        branches: [
                          {
                            feedstock: 'RXC-1910408',
                            cost: 8.240344,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667565',
            cost: 2.5300027e10,
            yield: 0.92766297,
            rxn_id: 'RNEW-desilylation-16963006',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5555583e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962901',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-26',
      cost: 2.5579856e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 19.224762,
      cost_reagents: 218.97531,
      cost_catalysts: 4.1074,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112305',
          name: 'pyrazinecarboxylic acid',
          purchasable: true,
        },
        {
          cid: 'RXC-1098229',
          name: 'methanol',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-1209273',
        'RXC-1361988',
        'RXC-2036449',
        'RXC-605365',
        'RXC-605283',
        'RXC-112305',
        'RXC-385653',
        'RXC-3587154',
        'RXC-1098229',
        'RXC-605303',
        'RXC-2804068',
        'RXC-1098214',
        'RXC-1098595',
        'RXC-2349612',
        'RXC-8008338',
      ],
      solvents: [
        'RXC-969212',
        'RXC-1730800',
        'RXC-635760',
        'RXC-605365',
        'RXC-1071255',
        'RXC-102391',
        'RXC-1731042',
        'RXC-1098229',
      ],
      catalysts: ['RXC-103233', 'RXC-605365'],
      route: [
        {
          rxn_id: 'RXR-74058',
          left_primaries: ['RXC-112305'],
          right_primaries: ['RXC-112307'],
        },
        {
          rxn_id: 'RXR-9120677',
          left_primaries: ['RXC-1098229', 'RXC-2804068'],
          right_primaries: ['RXC-2938915'],
        },
        {
          rxn_id: 'RXR-2183112',
          left_primaries: ['RXC-2938915'],
          right_primaries: ['RXC-3198805'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963282',
          left_primaries: ['RXC-3198805', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963255',
          left_primaries: ['RXC-112307', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5579856e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3828785e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            branches: [
              {
                prod_id: 'RXC-112307',
                cost: 99.39068,
                yield: 0.74,
                rxn_id: 'RXR-74058',
                branches: [
                  {
                    feedstock: 'RXC-112305',
                    cost: 34.619102,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.3590498e10,
                yield: 0.9848629,
                rxn_id: 'RNEW-amide n alkylation-16963282',
                branches: [
                  {
                    prod_id: 'RXC-3198805',
                    cost: 131.84256,
                    yield: 0.77,
                    rxn_id: 'RXR-2183112',
                    branches: [
                      {
                        prod_id: 'RXC-2938915',
                        cost: 62.998764,
                        yield: 1.0,
                        rxn_id: 'RXR-9120677',
                        branches: [
                          {
                            feedstock: 'RXC-1098229',
                            cost: 0.10589881,
                            purchasable: true,
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2938915',
            cost: 62.998764,
            yield: 1.0,
            rxn_id: 'RXR-9120677',
            feedstockIds: ['RXC-1098229', 'RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-3198805',
            cost: 131.84256,
            yield: 0.77,
            rxn_id: 'RXR-2183112',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667608',
            cost: 2.3590498e10,
            yield: 0.9848629,
            rxn_id: 'RNEW-amide n alkylation-16963282',
            feedstockIds: [],
            branchIds: ['RXC-3198805', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-112307',
            cost: 99.39068,
            yield: 0.74,
            rxn_id: 'RXR-74058',
            feedstockIds: ['RXC-112305'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3828785e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            feedstockIds: [],
            branchIds: ['RXC-112307', 'CNEW-16667608'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5579856e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-27',
      cost: 2.5579856e10,
      depth: 5,
      nbrxns: 6,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 0.21179762,
      cost_reagents: 76.6236,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112305',
          name: 'pyrazinecarboxylic acid',
          purchasable: true,
        },
        {
          cid: 'RXC-1098229',
          name: 'methanol',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3587154',
        'RXC-1098229',
        'RXC-605303',
        'RXC-2804068',
        'RXC-1098214',
        'RXC-1098595',
        'RXC-2349612',
        'RXC-8008338',
        'RXC-1209273',
      ],
      solvents: ['RXC-1098229'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-9120677',
          left_primaries: ['RXC-1098229', 'RXC-2804068'],
          right_primaries: ['RXC-2938915'],
        },
        {
          rxn_id: 'RXR-2183112',
          left_primaries: ['RXC-2938915'],
          right_primaries: ['RXC-3198805'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963282',
          left_primaries: ['RXC-3198805', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
          left_primaries: ['RXC-112305', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5579856e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3828785e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            branches: [
              {
                feedstock: 'RXC-112305',
                cost: 34.619102,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.3590498e10,
                yield: 0.9848629,
                rxn_id: 'RNEW-amide n alkylation-16963282',
                branches: [
                  {
                    prod_id: 'RXC-3198805',
                    cost: 131.84256,
                    yield: 0.77,
                    rxn_id: 'RXR-2183112',
                    branches: [
                      {
                        prod_id: 'RXC-2938915',
                        cost: 62.998764,
                        yield: 1.0,
                        rxn_id: 'RXR-9120677',
                        branches: [
                          {
                            feedstock: 'RXC-1098229',
                            cost: 0.10589881,
                            purchasable: true,
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2938915',
            cost: 62.998764,
            yield: 1.0,
            rxn_id: 'RXR-9120677',
            feedstockIds: ['RXC-1098229', 'RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-3198805',
            cost: 131.84256,
            yield: 0.77,
            rxn_id: 'RXR-2183112',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667608',
            cost: 2.3590498e10,
            yield: 0.9848629,
            rxn_id: 'RNEW-amide n alkylation-16963282',
            feedstockIds: [],
            branchIds: ['RXC-3198805', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3828785e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            feedstockIds: ['RXC-112305'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5579856e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-28',
      cost: 2.5579948e10,
      depth: 3,
      nbrxns: 5,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.0,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16962975',
          left_primaries: ['RXC-112306', 'RXC-7014199'],
          right_primaries: ['CNEW-16667816'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963946',
          left_primaries: ['CNEW-16667727'],
          right_primaries: ['CNEW-16667726'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963144',
          left_primaries: ['RXC-506297', 'CNEW-16667726'],
          right_primaries: ['CNEW-16667728'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16962914',
          left_primaries: ['CNEW-16667816', 'CNEW-16667728'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5579948e10,
        yield: 0.9899991,
        rxn_id: 'RNEW-amide n alkylation-16962914',
        branches: [
          {
            prod_id: 'CNEW-16667816',
            cost: 164.67773,
            yield: 0.98999906,
            rxn_id: 'RNEW-amide n alkylation-16962975',
            branches: [
              {
                feedstock: 'RXC-112306',
                cost: 40.105114,
                purchasable: true,
              },
              {
                prod_id: 'RXC-7014199',
                cost: 73.42573,
                yield: 0.5,
                rxn_id: 'RXR-4048902',
                branches: [
                  {
                    feedstock: 'RXC-2804068',
                    cost: 11.712864,
                    purchasable: true,
                  },
                ],
              },
            ],
          },
          {
            prod_id: 'CNEW-16667728',
            cost: 2.5324126e10,
            yield: 0.98999894,
            rxn_id: 'RNEW-alcohol sulfonylation-16963144',
            branches: [
              {
                feedstock: 'RXC-506297',
                cost: 3.7410412,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667726',
                cost: 2.5070858e10,
                yield: 0.9173998,
                rxn_id: 'RNEW-desilylation-16963946',
                branches: [
                  {
                    feedstock: 'CNEW-16667727',
                    cost: 2.3e10,
                    purchasable: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667726',
            cost: 2.5070858e10,
            yield: 0.9173998,
            rxn_id: 'RNEW-desilylation-16963946',
            feedstockIds: ['CNEW-16667727'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667728',
            cost: 2.5324126e10,
            yield: 0.98999894,
            rxn_id: 'RNEW-alcohol sulfonylation-16963144',
            feedstockIds: ['RXC-506297'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667816',
            cost: 164.67773,
            yield: 0.98999906,
            rxn_id: 'RNEW-amide n alkylation-16962975',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-11330190',
            cost: 2.5579948e10,
            yield: 0.9899991,
            rxn_id: 'RNEW-amide n alkylation-16962914',
            feedstockIds: [],
            branchIds: ['CNEW-16667816', 'CNEW-16667728'],
          },
        ],
      ],
    },
    {
      name: 'route-29',
      cost: 2.5688562e10,
      depth: 5,
      nbrxns: 6,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 0.21179762,
      cost_reagents: 76.6236,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112305',
          name: 'pyrazinecarboxylic acid',
          purchasable: true,
        },
        {
          cid: 'RXC-1098229',
          name: 'methanol',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3587154',
        'RXC-1098229',
        'RXC-605303',
        'RXC-2804068',
        'RXC-1098214',
        'RXC-1098595',
        'RXC-2349612',
        'RXC-8008338',
        'RXC-1209273',
      ],
      solvents: ['RXC-1098229'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-9120677',
          left_primaries: ['RXC-1098229', 'RXC-2804068'],
          right_primaries: ['RXC-2938915'],
        },
        {
          rxn_id: 'RXR-2183112',
          left_primaries: ['RXC-2938915'],
          right_primaries: ['RXC-3198805'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963282',
          left_primaries: ['RXC-3198805', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962954',
          left_primaries: ['CNEW-16667608'],
          right_primaries: ['CNEW-16667607'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16962896',
          left_primaries: ['RXC-112305', 'CNEW-16667607'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5688562e10,
        yield: 0.99,
        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16962896',
        branches: [
          {
            feedstock: 'RXC-112305',
            cost: 34.619102,
            purchasable: true,
          },
          {
            prod_id: 'CNEW-16667607',
            cost: 2.5431677e10,
            yield: 0.92760295,
            rxn_id: 'RNEW-desilylation-16962954',
            branches: [
              {
                prod_id: 'CNEW-16667608',
                cost: 2.3590498e10,
                yield: 0.9848629,
                rxn_id: 'RNEW-amide n alkylation-16963282',
                branches: [
                  {
                    prod_id: 'RXC-3198805',
                    cost: 131.84256,
                    yield: 0.77,
                    rxn_id: 'RXR-2183112',
                    branches: [
                      {
                        prod_id: 'RXC-2938915',
                        cost: 62.998764,
                        yield: 1.0,
                        rxn_id: 'RXR-9120677',
                        branches: [
                          {
                            feedstock: 'RXC-1098229',
                            cost: 0.10589881,
                            purchasable: true,
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2938915',
            cost: 62.998764,
            yield: 1.0,
            rxn_id: 'RXR-9120677',
            feedstockIds: ['RXC-1098229', 'RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-3198805',
            cost: 131.84256,
            yield: 0.77,
            rxn_id: 'RXR-2183112',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667608',
            cost: 2.3590498e10,
            yield: 0.9848629,
            rxn_id: 'RNEW-amide n alkylation-16963282',
            feedstockIds: [],
            branchIds: ['RXC-3198805', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667607',
            cost: 2.5431677e10,
            yield: 0.92760295,
            rxn_id: 'RNEW-desilylation-16962954',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5688562e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16962896',
            feedstockIds: ['RXC-112305'],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-30',
      cost: 2.5688562e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 4,
      fullroute: false,
      cost_solvents: 19.224762,
      cost_reagents: 218.97531,
      cost_catalysts: 4.1074,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112305',
          name: 'pyrazinecarboxylic acid',
          purchasable: true,
        },
        {
          cid: 'RXC-1098229',
          name: 'methanol',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-1209273',
        'RXC-1361988',
        'RXC-2036449',
        'RXC-605365',
        'RXC-605283',
        'RXC-112305',
        'RXC-385653',
        'RXC-3587154',
        'RXC-1098229',
        'RXC-605303',
        'RXC-2804068',
        'RXC-1098214',
        'RXC-1098595',
        'RXC-2349612',
        'RXC-8008338',
      ],
      solvents: [
        'RXC-969212',
        'RXC-1730800',
        'RXC-635760',
        'RXC-605365',
        'RXC-1071255',
        'RXC-102391',
        'RXC-1731042',
        'RXC-1098229',
      ],
      catalysts: ['RXC-103233', 'RXC-605365'],
      route: [
        {
          rxn_id: 'RXR-74058',
          left_primaries: ['RXC-112305'],
          right_primaries: ['RXC-112307'],
        },
        {
          rxn_id: 'RXR-9120677',
          left_primaries: ['RXC-1098229', 'RXC-2804068'],
          right_primaries: ['RXC-2938915'],
        },
        {
          rxn_id: 'RXR-2183112',
          left_primaries: ['RXC-2938915'],
          right_primaries: ['RXC-3198805'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963282',
          left_primaries: ['RXC-3198805', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962954',
          left_primaries: ['CNEW-16667608'],
          right_primaries: ['CNEW-16667607'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16962924',
          left_primaries: ['RXC-112307', 'CNEW-16667607'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5688562e10,
        yield: 0.99,
        rxn_id: 'RNEW-amide from acid chloride-16962924',
        branches: [
          {
            prod_id: 'RXC-112307',
            cost: 99.39068,
            yield: 0.74,
            rxn_id: 'RXR-74058',
            branches: [
              {
                feedstock: 'RXC-112305',
                cost: 34.619102,
                purchasable: true,
              },
            ],
          },
          {
            prod_id: 'CNEW-16667607',
            cost: 2.5431677e10,
            yield: 0.92760295,
            rxn_id: 'RNEW-desilylation-16962954',
            branches: [
              {
                prod_id: 'CNEW-16667608',
                cost: 2.3590498e10,
                yield: 0.9848629,
                rxn_id: 'RNEW-amide n alkylation-16963282',
                branches: [
                  {
                    prod_id: 'RXC-3198805',
                    cost: 131.84256,
                    yield: 0.77,
                    rxn_id: 'RXR-2183112',
                    branches: [
                      {
                        prod_id: 'RXC-2938915',
                        cost: 62.998764,
                        yield: 1.0,
                        rxn_id: 'RXR-9120677',
                        branches: [
                          {
                            feedstock: 'RXC-1098229',
                            cost: 0.10589881,
                            purchasable: true,
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2938915',
            cost: 62.998764,
            yield: 1.0,
            rxn_id: 'RXR-9120677',
            feedstockIds: ['RXC-1098229', 'RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-3198805',
            cost: 131.84256,
            yield: 0.77,
            rxn_id: 'RXR-2183112',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667608',
            cost: 2.3590498e10,
            yield: 0.9848629,
            rxn_id: 'RNEW-amide n alkylation-16963282',
            feedstockIds: [],
            branchIds: ['RXC-3198805', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667607',
            cost: 2.5431677e10,
            yield: 0.92760295,
            rxn_id: 'RNEW-desilylation-16962954',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112307',
            cost: 99.39068,
            yield: 0.74,
            rxn_id: 'RXR-74058',
            feedstockIds: ['RXC-112305'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-11330190',
            cost: 2.5688562e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16962924',
            feedstockIds: [],
            branchIds: ['RXC-112307', 'CNEW-16667607'],
          },
        ],
      ],
    },
    {
      name: 'route-31',
      cost: 2.5696973e10,
      depth: 4,
      nbrxns: 6,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 0.82039493,
      cost_reagents: 15.902564,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-3587155', 'RXC-635760', 'RXC-906771', 'RXC-2037554'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962960',
          left_primaries: ['RXC-112306', 'CNEW-16667852'],
          right_primaries: ['CNEW-16667816'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963145',
          left_primaries: ['CNEW-16667729'],
          right_primaries: ['CNEW-16667728'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16962914',
          left_primaries: ['CNEW-16667816', 'CNEW-16667728'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5696973e10,
        yield: 0.9899991,
        rxn_id: 'RNEW-amide n alkylation-16962914',
        branches: [
          {
            prod_id: 'CNEW-16667816',
            cost: 210.97472,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962960',
            branches: [
              {
                feedstock: 'RXC-112306',
                cost: 40.105114,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667852',
                cost: 119.259865,
                yield: 0.99,
                rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                branches: [
                  {
                    prod_id: 'RXC-2328698',
                    cost: 63.83265,
                    yield: 0.927,
                    rxn_id: 'RXR-2173213',
                    branches: [
                      {
                        feedstock: 'RXC-2804068',
                        cost: 11.712864,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    feedstock: 'RXC-3587154',
                    cost: 4.734618,
                    purchasable: true,
                  },
                ],
              },
            ],
          },
          {
            prod_id: 'CNEW-16667728',
            cost: 2.543998e10,
            yield: 0.9132636,
            rxn_id: 'RNEW-desilylation-16963145',
            branches: [
              {
                prod_id: 'CNEW-16667729',
                cost: 2.3233407e10,
                yield: 0.9899538,
                rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                branches: [
                  {
                    feedstock: 'RXC-506297',
                    cost: 3.7410412,
                    purchasable: true,
                  },
                  {
                    feedstock: 'CNEW-16667727',
                    cost: 2.3e10,
                    purchasable: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667728',
            cost: 2.543998e10,
            yield: 0.9132636,
            rxn_id: 'RNEW-desilylation-16963145',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667816',
            cost: 210.97472,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962960',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-11330190',
            cost: 2.5696973e10,
            yield: 0.9899991,
            rxn_id: 'RNEW-amide n alkylation-16962914',
            feedstockIds: [],
            branchIds: ['CNEW-16667816', 'CNEW-16667728'],
          },
        ],
      ],
    },
    {
      name: 'route-32',
      cost: 2.5705732e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.0,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962959',
          left_primaries: ['RXC-112306', 'RXC-7014199'],
          right_primaries: ['CNEW-16667816'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963245',
          left_primaries: ['CNEW-16667816', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-amide to amine reduction-16964688',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16963256',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5705732e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946045e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.3706546e10,
                yield: 0.98994154,
                rxn_id: 'RNEW-amide to amine reduction-16964688',
                branches: [
                  {
                    prod_id: 'CNEW-16667419',
                    cost: 2.3468095e10,
                    yield: 0.98999965,
                    rxn_id: 'RNEW-amide n alkylation-16963245',
                    branches: [
                      {
                        prod_id: 'CNEW-16667816',
                        cost: 164.67761,
                        yield: 0.99,
                        rxn_id: 'RNEW-amine alkylation-16962959',
                        branches: [
                          {
                            feedstock: 'RXC-112306',
                            cost: 40.105114,
                            purchasable: true,
                          },
                          {
                            prod_id: 'RXC-7014199',
                            cost: 73.42573,
                            yield: 0.5,
                            rxn_id: 'RXR-4048902',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667816',
            cost: 164.67761,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962959',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3468095e10,
            yield: 0.98999965,
            rxn_id: 'RNEW-amide n alkylation-16963245',
            feedstockIds: [],
            branchIds: ['CNEW-16667816', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667445',
            cost: 2.3706546e10,
            yield: 0.98994154,
            rxn_id: 'RNEW-amide to amine reduction-16964688',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946045e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5705732e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-33',
      cost: 2.5706148e10,
      depth: 6,
      nbrxns: 9,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 19.012964,
      cost_reagents: 152.23642,
      cost_catalysts: 4.1074,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-105778',
          name: 'methylpyrazine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-1209273',
        'RXC-1361988',
        'RXC-2036449',
        'RXC-605365',
        'RXC-605283',
        'RXC-112305',
        'RXC-385653',
        'RXC-17485996',
        'RXC-906771',
      ],
      solvents: ['RXC-969212', 'RXC-1730800', 'RXC-635760', 'RXC-605365', 'RXC-1071255', 'RXC-102391', 'RXC-1731042'],
      catalysts: ['RXC-103233', 'RXC-605365'],
      route: [
        {
          rxn_id: 'RXR-5414835',
          left_primaries: ['RXC-105778'],
          right_primaries: ['RXC-112305'],
        },
        {
          rxn_id: 'RXR-74058',
          left_primaries: ['RXC-112305'],
          right_primaries: ['RXC-112307'],
        },
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963272',
          left_primaries: ['RXC-3587154', 'CNEW-16667566'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963255',
          left_primaries: ['RXC-112307', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706148e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946433e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            branches: [
              {
                prod_id: 'RXC-112307',
                cost: 174.0482,
                yield: 0.74,
                rxn_id: 'RXR-74058',
                branches: [
                  {
                    prod_id: 'RXC-112305',
                    cost: 89.86567,
                    yield: 0.5,
                    rxn_id: 'RXR-5414835',
                    branches: [
                      {
                        feedstock: 'RXC-105778',
                        cost: 19.932835,
                        purchasable: true,
                      },
                    ],
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.3706968e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963272',
                branches: [
                  {
                    feedstock: 'RXC-3587154',
                    cost: 4.734618,
                    purchasable: true,
                  },
                  {
                    prod_id: 'CNEW-16667566',
                    cost: 2.3469898e10,
                    yield: 0.98992366,
                    rxn_id: 'RNEW-amide n alkylation-16963669',
                    branches: [
                      {
                        prod_id: 'CNEW-16667859',
                        cost: 168.96497,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                        branches: [
                          {
                            prod_id: 'RXC-21062592',
                            cost: 113.04069,
                            yield: 0.5,
                            rxn_id: 'RXR-30064816',
                            branches: [
                              {
                                feedstock: 'RXC-1910408',
                                cost: 8.240344,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667608',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963272',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112305',
            cost: 89.86567,
            yield: 0.5,
            rxn_id: 'RXR-5414835',
            feedstockIds: ['RXC-105778'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-112307',
            cost: 174.0482,
            yield: 0.74,
            rxn_id: 'RXR-74058',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946433e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            feedstockIds: [],
            branchIds: ['RXC-112307', 'CNEW-16667608'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706148e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-34',
      cost: 2.5706148e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 9.884702,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-105778',
          name: 'methylpyrazine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-17485996', 'RXC-906771'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-5414835',
          left_primaries: ['RXC-105778'],
          right_primaries: ['RXC-112305'],
        },
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963272',
          left_primaries: ['RXC-3587154', 'CNEW-16667566'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
          left_primaries: ['RXC-112305', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706148e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946433e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            branches: [
              {
                prod_id: 'RXC-112305',
                cost: 89.86567,
                yield: 0.5,
                rxn_id: 'RXR-5414835',
                branches: [
                  {
                    feedstock: 'RXC-105778',
                    cost: 19.932835,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.3706968e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963272',
                branches: [
                  {
                    feedstock: 'RXC-3587154',
                    cost: 4.734618,
                    purchasable: true,
                  },
                  {
                    prod_id: 'CNEW-16667566',
                    cost: 2.3469898e10,
                    yield: 0.98992366,
                    rxn_id: 'RNEW-amide n alkylation-16963669',
                    branches: [
                      {
                        prod_id: 'CNEW-16667859',
                        cost: 168.96497,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                        branches: [
                          {
                            prod_id: 'RXC-21062592',
                            cost: 113.04069,
                            yield: 0.5,
                            rxn_id: 'RXR-30064816',
                            branches: [
                              {
                                feedstock: 'RXC-1910408',
                                cost: 8.240344,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667608',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963272',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112305',
            cost: 89.86567,
            yield: 0.5,
            rxn_id: 'RXR-5414835',
            feedstockIds: ['RXC-105778'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946433e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            feedstockIds: [],
            branchIds: ['RXC-112305', 'CNEW-16667608'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706148e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-35',
      cost: 2.5706189e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 18.31483,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154', 'RXC-17485996', 'RXC-906771'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964663',
          left_primaries: ['RXC-907068', 'CNEW-16667566'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16963256',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706189e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946471e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.3706968e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964663',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667566',
                    cost: 2.3469898e10,
                    yield: 0.98992366,
                    rxn_id: 'RNEW-amide n alkylation-16963669',
                    branches: [
                      {
                        prod_id: 'CNEW-16667859',
                        cost: 168.96497,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                        branches: [
                          {
                            prod_id: 'RXC-21062592',
                            cost: 113.04069,
                            yield: 0.5,
                            rxn_id: 'RXR-30064816',
                            branches: [
                              {
                                feedstock: 'RXC-1910408',
                                cost: 8.240344,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964663',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667566'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946471e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706189e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-36',
      cost: 2.5706211e10,
      depth: 6,
      nbrxns: 10,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 13.061607,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-505944',
          name: 'ethanolamine',
          purchasable: true,
        },
        {
          cid: 'RXC-605257',
          name: 'dimethyl amine',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-3587158', 'RXC-17485996', 'RXC-906771'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-25467040',
          left_primaries: ['RXC-505944'],
          right_primaries: ['RXC-103905'],
        },
        {
          rxn_id: 'RXR-5804161',
          left_primaries: ['RXC-605257'],
          right_primaries: ['RXC-1732479'],
        },
        {
          rxn_id: 'RXR-214887',
          left_primaries: ['RXC-1732479'],
          right_primaries: ['RXC-1735345'],
        },
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963866',
          left_primaries: ['RXC-1735345', 'CNEW-16667566'],
          right_primaries: ['CNEW-16667401'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963237',
          left_primaries: ['RXC-103905', 'CNEW-16667401'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706211e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946492e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            branches: [
              {
                prod_id: 'RXC-103905',
                cost: 51.47416,
                yield: 0.5,
                rxn_id: 'RXR-25467040',
                branches: [
                  {
                    feedstock: 'RXC-505944',
                    cost: 0.7370803,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667401',
                cost: 2.3706968e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963866',
                branches: [
                  {
                    prod_id: 'RXC-1735345',
                    cost: 152.77866,
                    yield: 0.5,
                    rxn_id: 'RXR-214887',
                    branches: [
                      {
                        prod_id: 'RXC-1732479',
                        cost: 50.469334,
                        yield: 0.5,
                        rxn_id: 'RXR-5804161',
                        branches: [
                          {
                            feedstock: 'RXC-605257',
                            cost: 0.23466742,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667566',
                    cost: 2.3469898e10,
                    yield: 0.98992366,
                    rxn_id: 'RNEW-amide n alkylation-16963669',
                    branches: [
                      {
                        prod_id: 'CNEW-16667859',
                        cost: 168.96497,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                        branches: [
                          {
                            prod_id: 'RXC-21062592',
                            cost: 113.04069,
                            yield: 0.5,
                            rxn_id: 'RXR-30064816',
                            branches: [
                              {
                                feedstock: 'RXC-1910408',
                                cost: 8.240344,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-1732479',
            cost: 50.469334,
            yield: 0.5,
            rxn_id: 'RXR-5804161',
            feedstockIds: ['RXC-605257'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-1735345',
            cost: 152.77866,
            yield: 0.5,
            rxn_id: 'RXR-214887',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667401',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963866',
            feedstockIds: [],
            branchIds: ['RXC-1735345', 'CNEW-16667566'],
          },
        ],
        [
          {
            prod_id: 'RXC-103905',
            cost: 51.47416,
            yield: 0.5,
            rxn_id: 'RXR-25467040',
            feedstockIds: ['RXC-505944'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946492e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            feedstockIds: [],
            branchIds: ['RXC-103905', 'CNEW-16667401'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706211e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-37',
      cost: 2.5706211e10,
      depth: 6,
      nbrxns: 10,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 1.582462,
      cost_reagents: 21.61355,
      cost_catalysts: 1075.8013,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-505944',
          name: 'ethanolamine',
          purchasable: true,
        },
        {
          cid: 'RXC-1098295',
          name: 'tetrachloromethane',
          purchasable: true,
        },
        {
          cid: 'RXC-1910408',
          name: 'L-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3547232',
        'RXC-3587154',
        'RXC-4371014',
        'RXC-3587155',
        'RXC-14497211',
        'RXC-19270857',
        'RXC-19290122',
        'RXC-3587218',
        'RXC-8128137',
        'RXC-19276987',
        'RXC-4360473',
        'RXC-4122945',
        'RXC-19284165',
        'RXC-2037554',
        'RXC-8973685',
        'RXC-19286924',
        'RXC-3535376',
        'RXC-13320054',
        'RXC-19273435',
        'RXC-4921393',
        'RXC-3587191',
        'RXC-8135986',
        'RXC-3902804',
        'RXC-17485996',
        'RXC-906771',
      ],
      solvents: ['RXC-102391', 'RXC-635760', 'RXC-3587155'],
      catalysts: ['RXC-14247131', 'RXC-3587236', 'RXC-3903066', 'RXC-16501948', 'RXC-3535368'],
      route: [
        {
          rxn_id: 'RXR-25467040',
          left_primaries: ['RXC-505944'],
          right_primaries: ['RXC-103905'],
        },
        {
          rxn_id: 'RXR-61316',
          left_primaries: ['RXC-1098295'],
          right_primaries: ['RXC-1098367'],
        },
        {
          rxn_id: 'RXR-63034',
          left_primaries: ['RXC-1098367'],
          right_primaries: ['RXC-1734893'],
        },
        {
          rxn_id: 'RXR-30064816',
          left_primaries: ['RXC-1910408'],
          right_primaries: ['RXC-21062592'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
          left_primaries: ['RXC-21062592', 'RXC-3587154'],
          right_primaries: ['CNEW-16667859'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963669',
          left_primaries: ['CNEW-16667859', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667566'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963805',
          left_primaries: ['RXC-1734893', 'CNEW-16667566'],
          right_primaries: ['CNEW-16667404'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963236',
          left_primaries: ['RXC-103905', 'CNEW-16667404'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706211e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946492e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            branches: [
              {
                prod_id: 'RXC-103905',
                cost: 51.47416,
                yield: 0.5,
                rxn_id: 'RXR-25467040',
                branches: [
                  {
                    feedstock: 'RXC-505944',
                    cost: 0.7370803,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667404',
                cost: 2.3706968e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963805',
                branches: [
                  {
                    prod_id: 'RXC-1734893',
                    cost: 150.28888,
                    yield: 0.5,
                    rxn_id: 'RXR-63034',
                    branches: [
                      {
                        prod_id: 'RXC-1098367',
                        cost: 50.144444,
                        yield: 0.9,
                        rxn_id: 'RXR-61316',
                        branches: [
                          {
                            feedstock: 'RXC-1098295',
                            cost: 0.1,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667566',
                    cost: 2.3469898e10,
                    yield: 0.98992366,
                    rxn_id: 'RNEW-amide n alkylation-16963669',
                    branches: [
                      {
                        prod_id: 'CNEW-16667859',
                        cost: 168.96497,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
                        branches: [
                          {
                            prod_id: 'RXC-21062592',
                            cost: 113.04069,
                            yield: 0.5,
                            rxn_id: 'RXR-30064816',
                            branches: [
                              {
                                feedstock: 'RXC-1910408',
                                cost: 8.240344,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-21062592',
            cost: 113.04069,
            yield: 0.5,
            rxn_id: 'RXR-30064816',
            feedstockIds: ['RXC-1910408'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667859',
            cost: 168.96497,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963289',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667566',
            cost: 2.3469898e10,
            yield: 0.98992366,
            rxn_id: 'RNEW-amide n alkylation-16963669',
            feedstockIds: [],
            branchIds: ['CNEW-16667859', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-1098367',
            cost: 50.144444,
            yield: 0.9,
            rxn_id: 'RXR-61316',
            feedstockIds: ['RXC-1098295'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-1734893',
            cost: 150.28888,
            yield: 0.5,
            rxn_id: 'RXR-63034',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667404',
            cost: 2.3706968e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963805',
            feedstockIds: [],
            branchIds: ['RXC-1734893', 'CNEW-16667566'],
          },
        ],
        [
          {
            prod_id: 'RXC-103905',
            cost: 51.47416,
            yield: 0.5,
            rxn_id: 'RXR-25467040',
            feedstockIds: ['RXC-505944'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946492e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            feedstockIds: [],
            branchIds: ['RXC-103905', 'CNEW-16667404'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706211e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-38',
      cost: 2.570653e10,
      depth: 5,
      nbrxns: 8,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 19.012964,
      cost_reagents: 152.81752,
      cost_catalysts: 4.1074,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-147982',
          name: '2,3-Pyrazinedicarboxylic acid',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-1209273',
        'RXC-1361988',
        'RXC-2036449',
        'RXC-605365',
        'RXC-605283',
        'RXC-112305',
        'RXC-385653',
        'RXC-2037554',
        'RXC-506007',
        'RXC-3587155',
        'RXC-635760',
        'RXC-507540',
      ],
      solvents: ['RXC-969212', 'RXC-1730800', 'RXC-635760', 'RXC-605365', 'RXC-1071255', 'RXC-102391', 'RXC-1731042'],
      catalysts: ['RXC-103233', 'RXC-605365'],
      route: [
        {
          rxn_id: 'RXR-148366',
          left_primaries: ['RXC-147982'],
          right_primaries: ['RXC-112305'],
        },
        {
          rxn_id: 'RXR-74058',
          left_primaries: ['RXC-112305'],
          right_primaries: ['RXC-112307'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963273',
          left_primaries: ['RXC-3587154', 'CNEW-16667545'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963255',
          left_primaries: ['RXC-112307', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.570653e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            branches: [
              {
                prod_id: 'RXC-112307',
                cost: 233.85446,
                yield: 0.74,
                rxn_id: 'RXR-74058',
                branches: [
                  {
                    prod_id: 'RXC-112305',
                    cost: 134.12231,
                    yield: 0.85,
                    rxn_id: 'RXR-148366',
                    branches: [
                      {
                        feedstock: 'RXC-147982',
                        cost: 71.40396,
                        purchasable: true,
                      },
                    ],
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963273',
                branches: [
                  {
                    feedstock: 'RXC-3587154',
                    cost: 4.734618,
                    purchasable: true,
                  },
                  {
                    prod_id: 'CNEW-16667545',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963691',
                    branches: [
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667608',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963273',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112305',
            cost: 134.12231,
            yield: 0.85,
            rxn_id: 'RXR-148366',
            feedstockIds: ['RXC-147982'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-112307',
            cost: 233.85446,
            yield: 0.74,
            rxn_id: 'RXR-74058',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            feedstockIds: [],
            branchIds: ['RXC-112307', 'CNEW-16667608'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.570653e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-39',
      cost: 2.570653e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 10.465799,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-147982',
          name: '2,3-Pyrazinedicarboxylic acid',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-2037554', 'RXC-506007', 'RXC-3587155', 'RXC-635760', 'RXC-507540'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-148366',
          left_primaries: ['RXC-147982'],
          right_primaries: ['RXC-112305'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963273',
          left_primaries: ['RXC-3587154', 'CNEW-16667545'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
          left_primaries: ['RXC-112305', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.570653e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            branches: [
              {
                prod_id: 'RXC-112305',
                cost: 134.12231,
                yield: 0.85,
                rxn_id: 'RXR-148366',
                branches: [
                  {
                    feedstock: 'RXC-147982',
                    cost: 71.40396,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963273',
                branches: [
                  {
                    feedstock: 'RXC-3587154',
                    cost: 4.734618,
                    purchasable: true,
                  },
                  {
                    prod_id: 'CNEW-16667545',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963691',
                    branches: [
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667608',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963273',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112305',
            cost: 134.12231,
            yield: 0.85,
            rxn_id: 'RXR-148366',
            feedstockIds: ['RXC-147982'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            feedstockIds: [],
            branchIds: ['RXC-112305', 'CNEW-16667608'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.570653e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-40',
      cost: 2.570653e10,
      depth: 6,
      nbrxns: 9,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 19.833359,
      cost_reagents: 158.61612,
      cost_catalysts: 4.1074,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-1209273',
        'RXC-1361988',
        'RXC-2036449',
        'RXC-605365',
        'RXC-605283',
        'RXC-112305',
        'RXC-385653',
        'RXC-19931735',
        'RXC-3587155',
        'RXC-11343348',
        'RXC-2037554',
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
      ],
      solvents: [
        'RXC-969212',
        'RXC-1730800',
        'RXC-635760',
        'RXC-605365',
        'RXC-1071255',
        'RXC-102391',
        'RXC-1731042',
        'RXC-3587155',
        'RXC-906771',
        'RXC-2037554',
      ],
      catalysts: ['RXC-103233', 'RXC-605365'],
      route: [
        {
          rxn_id: 'RXR-29080011',
          left_primaries: ['RXC-112306'],
          right_primaries: ['RXC-112305'],
        },
        {
          rxn_id: 'RXR-74058',
          left_primaries: ['RXC-112305'],
          right_primaries: ['RXC-112307'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963274',
          left_primaries: ['RXC-3587154', 'CNEW-16667524'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963255',
          left_primaries: ['RXC-112307', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.570653e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            branches: [
              {
                prod_id: 'RXC-112307',
                cost: 228.62192,
                yield: 0.74,
                rxn_id: 'RXR-74058',
                branches: [
                  {
                    prod_id: 'RXC-112305',
                    cost: 130.25023,
                    yield: 0.5,
                    rxn_id: 'RXR-29080011',
                    branches: [
                      {
                        feedstock: 'RXC-112306',
                        cost: 40.105114,
                        purchasable: true,
                      },
                    ],
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963274',
                branches: [
                  {
                    feedstock: 'RXC-3587154',
                    cost: 4.734618,
                    purchasable: true,
                  },
                  {
                    prod_id: 'CNEW-16667524',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963722',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667608',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963274',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112305',
            cost: 130.25023,
            yield: 0.5,
            rxn_id: 'RXR-29080011',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-112307',
            cost: 228.62192,
            yield: 0.74,
            rxn_id: 'RXR-74058',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963255',
            feedstockIds: [],
            branchIds: ['RXC-112307', 'CNEW-16667608'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.570653e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-41',
      cost: 2.570653e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.82039493,
      cost_reagents: 16.264416,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-112306',
          name: 'pyrazinamide',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-19931735',
        'RXC-3587155',
        'RXC-11343348',
        'RXC-2037554',
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
      ],
      solvents: ['RXC-3587155', 'RXC-635760', 'RXC-906771', 'RXC-2037554'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-29080011',
          left_primaries: ['RXC-112306'],
          right_primaries: ['RXC-112305'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963274',
          left_primaries: ['RXC-3587154', 'CNEW-16667524'],
          right_primaries: ['CNEW-16667608'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
          left_primaries: ['RXC-112305', 'CNEW-16667608'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.570653e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            branches: [
              {
                prod_id: 'RXC-112305',
                cost: 130.25023,
                yield: 0.5,
                rxn_id: 'RXR-29080011',
                branches: [
                  {
                    feedstock: 'RXC-112306',
                    cost: 40.105114,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667608',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963274',
                branches: [
                  {
                    feedstock: 'RXC-3587154',
                    cost: 4.734618,
                    purchasable: true,
                  },
                  {
                    prod_id: 'CNEW-16667524',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963722',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667608',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963274',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112305',
            cost: 130.25023,
            yield: 0.5,
            rxn_id: 'RXR-29080011',
            feedstockIds: ['RXC-112306'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946789e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963227',
            feedstockIds: [],
            branchIds: ['RXC-112305', 'CNEW-16667608'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.570653e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-42',
      cost: 2.5706572e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964664',
          left_primaries: ['RXC-907068', 'CNEW-16667545'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16963256',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706572e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946828e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964664',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667545',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963691',
                    branches: [
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964664',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667545'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946828e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706572e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-43',
      cost: 2.5706572e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 3.7482433,
      cost_reagents: 24.332691,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-11382663',
        'RXC-3587189',
        'RXC-103233',
        'RXC-3587154',
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229', 'RXC-3587155', 'RXC-906771', 'RXC-2037554'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16964665',
          left_primaries: ['RXC-907068', 'CNEW-16667524'],
          right_primaries: ['CNEW-16667445'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16963256',
          left_primaries: ['CNEW-16667445'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706572e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946828e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            branches: [
              {
                prod_id: 'CNEW-16667445',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16964665',
                branches: [
                  {
                    prod_id: 'RXC-907068',
                    cost: 65.41843,
                    yield: 1.0,
                    rxn_id: 'RXR-8865420',
                    branches: [
                      {
                        feedstock: 'RXC-110008',
                        cost: 15.338424,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667524',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963722',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667445',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16964665',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'CNEW-16667524'],
          },
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946828e10,
            yield: 0.9899984,
            rxn_id: 'RNEW-benzylic oxidation-16963256',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706572e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-44',
      cost: 2.5706594e10,
      depth: 5,
      nbrxns: 9,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 1.582462,
      cost_reagents: 11.728848,
      cost_catalysts: 1075.8013,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-505944',
          name: 'ethanolamine',
          purchasable: true,
        },
        {
          cid: 'RXC-1098295',
          name: 'tetrachloromethane',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3547232',
        'RXC-3587154',
        'RXC-4371014',
        'RXC-3587155',
        'RXC-14497211',
        'RXC-19270857',
        'RXC-19290122',
        'RXC-3587218',
        'RXC-8128137',
        'RXC-19276987',
        'RXC-4360473',
        'RXC-4122945',
        'RXC-19284165',
        'RXC-2037554',
        'RXC-8973685',
        'RXC-19286924',
        'RXC-3535376',
        'RXC-13320054',
        'RXC-19273435',
        'RXC-4921393',
        'RXC-3587191',
        'RXC-8135986',
        'RXC-3902804',
      ],
      solvents: ['RXC-102391', 'RXC-635760', 'RXC-3587155'],
      catalysts: ['RXC-14247131', 'RXC-3587236', 'RXC-3903066', 'RXC-16501948', 'RXC-3535368'],
      route: [
        {
          rxn_id: 'RXR-5800728',
          left_primaries: ['RXC-505944'],
          right_primaries: ['RXC-103905'],
        },
        {
          rxn_id: 'RXR-61316',
          left_primaries: ['RXC-1098295'],
          right_primaries: ['RXC-1098367'],
        },
        {
          rxn_id: 'RXR-63034',
          left_primaries: ['RXC-1098367'],
          right_primaries: ['RXC-1734893'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963806',
          left_primaries: ['RXC-1734893', 'CNEW-16667545'],
          right_primaries: ['CNEW-16667404'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963236',
          left_primaries: ['RXC-103905', 'CNEW-16667404'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706594e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            branches: [
              {
                prod_id: 'RXC-103905',
                cost: 51.47416,
                yield: 0.5,
                rxn_id: 'RXR-5800728',
                branches: [
                  {
                    feedstock: 'RXC-505944',
                    cost: 0.7370803,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667404',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963806',
                branches: [
                  {
                    prod_id: 'RXC-1734893',
                    cost: 150.28888,
                    yield: 0.5,
                    rxn_id: 'RXR-63034',
                    branches: [
                      {
                        prod_id: 'RXC-1098367',
                        cost: 50.144444,
                        yield: 0.9,
                        rxn_id: 'RXR-61316',
                        branches: [
                          {
                            feedstock: 'RXC-1098295',
                            cost: 0.1,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667545',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963691',
                    branches: [
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-1098367',
            cost: 50.144444,
            yield: 0.9,
            rxn_id: 'RXR-61316',
            feedstockIds: ['RXC-1098295'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-1734893',
            cost: 150.28888,
            yield: 0.5,
            rxn_id: 'RXR-63034',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667404',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963806',
            feedstockIds: [],
            branchIds: ['RXC-1734893', 'CNEW-16667545'],
          },
        ],
        [
          {
            prod_id: 'RXC-103905',
            cost: 51.47416,
            yield: 0.5,
            rxn_id: 'RXR-5800728',
            feedstockIds: ['RXC-505944'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            feedstockIds: [],
            branchIds: ['RXC-103905', 'CNEW-16667404'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706594e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-45',
      cost: 2.5706594e10,
      depth: 6,
      nbrxns: 10,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.82039493,
      cost_reagents: 19.07947,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-505944',
          name: 'ethanolamine',
          purchasable: true,
        },
        {
          cid: 'RXC-605257',
          name: 'dimethyl amine',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3587158',
        'RXC-2804068',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-3587155', 'RXC-635760', 'RXC-906771', 'RXC-2037554'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-5800726',
          left_primaries: ['RXC-505944'],
          right_primaries: ['RXC-103905'],
        },
        {
          rxn_id: 'RXR-5804161',
          left_primaries: ['RXC-605257'],
          right_primaries: ['RXC-1732479'],
        },
        {
          rxn_id: 'RXR-214887',
          left_primaries: ['RXC-1732479'],
          right_primaries: ['RXC-1735345'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963868',
          left_primaries: ['RXC-1735345', 'CNEW-16667524'],
          right_primaries: ['CNEW-16667401'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963237',
          left_primaries: ['RXC-103905', 'CNEW-16667401'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706594e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            branches: [
              {
                prod_id: 'RXC-103905',
                cost: 51.47416,
                yield: 0.5,
                rxn_id: 'RXR-5800726',
                branches: [
                  {
                    feedstock: 'RXC-505944',
                    cost: 0.7370803,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667401',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963868',
                branches: [
                  {
                    prod_id: 'RXC-1735345',
                    cost: 152.77866,
                    yield: 0.5,
                    rxn_id: 'RXR-214887',
                    branches: [
                      {
                        prod_id: 'RXC-1732479',
                        cost: 50.469334,
                        yield: 0.5,
                        rxn_id: 'RXR-5804161',
                        branches: [
                          {
                            feedstock: 'RXC-605257',
                            cost: 0.23466742,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667524',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963722',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-1732479',
            cost: 50.469334,
            yield: 0.5,
            rxn_id: 'RXR-5804161',
            feedstockIds: ['RXC-605257'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-1735345',
            cost: 152.77866,
            yield: 0.5,
            rxn_id: 'RXR-214887',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667401',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963868',
            feedstockIds: [],
            branchIds: ['RXC-1735345', 'CNEW-16667524'],
          },
        ],
        [
          {
            prod_id: 'RXC-103905',
            cost: 51.47416,
            yield: 0.5,
            rxn_id: 'RXR-5800726',
            feedstockIds: ['RXC-505944'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            feedstockIds: [],
            branchIds: ['RXC-103905', 'CNEW-16667401'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706594e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-46',
      cost: 2.5706594e10,
      depth: 6,
      nbrxns: 10,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 2.4028568,
      cost_reagents: 27.631413,
      cost_catalysts: 1075.8013,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-505944',
          name: 'ethanolamine',
          purchasable: true,
        },
        {
          cid: 'RXC-1098295',
          name: 'tetrachloromethane',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3547232',
        'RXC-3587154',
        'RXC-4371014',
        'RXC-3587155',
        'RXC-14497211',
        'RXC-19270857',
        'RXC-19290122',
        'RXC-3587218',
        'RXC-8128137',
        'RXC-19276987',
        'RXC-4360473',
        'RXC-4122945',
        'RXC-19284165',
        'RXC-2037554',
        'RXC-8973685',
        'RXC-19286924',
        'RXC-3535376',
        'RXC-13320054',
        'RXC-19273435',
        'RXC-4921393',
        'RXC-3587191',
        'RXC-8135986',
        'RXC-3902804',
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
      ],
      solvents: ['RXC-102391', 'RXC-635760', 'RXC-3587155', 'RXC-906771', 'RXC-2037554'],
      catalysts: ['RXC-14247131', 'RXC-3587236', 'RXC-3903066', 'RXC-16501948', 'RXC-3535368'],
      route: [
        {
          rxn_id: 'RXR-5800726',
          left_primaries: ['RXC-505944'],
          right_primaries: ['RXC-103905'],
        },
        {
          rxn_id: 'RXR-61316',
          left_primaries: ['RXC-1098295'],
          right_primaries: ['RXC-1098367'],
        },
        {
          rxn_id: 'RXR-63034',
          left_primaries: ['RXC-1098367'],
          right_primaries: ['RXC-1734893'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963807',
          left_primaries: ['RXC-1734893', 'CNEW-16667524'],
          right_primaries: ['CNEW-16667404'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963236',
          left_primaries: ['RXC-103905', 'CNEW-16667404'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706594e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            branches: [
              {
                prod_id: 'RXC-103905',
                cost: 51.47416,
                yield: 0.5,
                rxn_id: 'RXR-5800726',
                branches: [
                  {
                    feedstock: 'RXC-505944',
                    cost: 0.7370803,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667404',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963807',
                branches: [
                  {
                    prod_id: 'RXC-1734893',
                    cost: 150.28888,
                    yield: 0.5,
                    rxn_id: 'RXR-63034',
                    branches: [
                      {
                        prod_id: 'RXC-1098367',
                        cost: 50.144444,
                        yield: 0.9,
                        rxn_id: 'RXR-61316',
                        branches: [
                          {
                            feedstock: 'RXC-1098295',
                            cost: 0.1,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667524',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963722',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-1098367',
            cost: 50.144444,
            yield: 0.9,
            rxn_id: 'RXR-61316',
            feedstockIds: ['RXC-1098295'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-1734893',
            cost: 150.28888,
            yield: 0.5,
            rxn_id: 'RXR-63034',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667404',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963807',
            feedstockIds: [],
            branchIds: ['RXC-1734893', 'CNEW-16667524'],
          },
        ],
        [
          {
            prod_id: 'RXC-103905',
            cost: 51.47416,
            yield: 0.5,
            rxn_id: 'RXR-5800726',
            feedstockIds: ['RXC-505944'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963236',
            feedstockIds: [],
            branchIds: ['RXC-103905', 'CNEW-16667404'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706594e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-47',
      cost: 2.5706594e10,
      depth: 5,
      nbrxns: 9,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 3.1769056,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-505944',
          name: 'ethanolamine',
          purchasable: true,
        },
        {
          cid: 'RXC-605257',
          name: 'dimethyl amine',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-3587158'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-5800728',
          left_primaries: ['RXC-505944'],
          right_primaries: ['RXC-103905'],
        },
        {
          rxn_id: 'RXR-5804161',
          left_primaries: ['RXC-605257'],
          right_primaries: ['RXC-1732479'],
        },
        {
          rxn_id: 'RXR-214887',
          left_primaries: ['RXC-1732479'],
          right_primaries: ['RXC-1735345'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963691',
          left_primaries: ['RXC-7014199', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667545'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963867',
          left_primaries: ['RXC-1735345', 'CNEW-16667545'],
          right_primaries: ['CNEW-16667401'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16963237',
          left_primaries: ['RXC-103905', 'CNEW-16667401'],
          right_primaries: ['CNEW-16667419'],
        },
        {
          rxn_id: 'RNEW-desilylation-16962926',
          left_primaries: ['CNEW-16667419'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5706594e10,
        yield: 0.93154496,
        rxn_id: 'RNEW-desilylation-16962926',
        branches: [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            branches: [
              {
                prod_id: 'RXC-103905',
                cost: 51.47416,
                yield: 0.5,
                rxn_id: 'RXR-5800728',
                branches: [
                  {
                    feedstock: 'RXC-505944',
                    cost: 0.7370803,
                    purchasable: true,
                  },
                ],
              },
              {
                prod_id: 'CNEW-16667401',
                cost: 2.370732e10,
                yield: 0.99,
                rxn_id: 'RNEW-amine alkylation-16963867',
                branches: [
                  {
                    prod_id: 'RXC-1735345',
                    cost: 152.77866,
                    yield: 0.5,
                    rxn_id: 'RXR-214887',
                    branches: [
                      {
                        prod_id: 'RXC-1732479',
                        cost: 50.469334,
                        yield: 0.5,
                        rxn_id: 'RXR-5804161',
                        branches: [
                          {
                            feedstock: 'RXC-605257',
                            cost: 0.23466742,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667545',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963691',
                    branches: [
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667545',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963691',
            feedstockIds: [],
            branchIds: ['RXC-7014199', 'CNEW-16667729'],
          },
        ],
        [
          {
            prod_id: 'RXC-1732479',
            cost: 50.469334,
            yield: 0.5,
            rxn_id: 'RXR-5804161',
            feedstockIds: ['RXC-605257'],
            branchIds: [],
          },
          {
            prod_id: 'RXC-1735345',
            cost: 152.77866,
            yield: 0.5,
            rxn_id: 'RXR-214887',
            feedstockIds: [],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667401',
            cost: 2.370732e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963867',
            feedstockIds: [],
            branchIds: ['RXC-1735345', 'CNEW-16667545'],
          },
        ],
        [
          {
            prod_id: 'RXC-103905',
            cost: 51.47416,
            yield: 0.5,
            rxn_id: 'RXR-5800728',
            feedstockIds: ['RXC-505944'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667419',
            cost: 2.3946848e10,
            yield: 0.9899975,
            rxn_id: 'RNEW-friedel crafts acylation-16963237',
            feedstockIds: [],
            branchIds: ['RXC-103905', 'CNEW-16667401'],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.5706594e10,
            yield: 0.93154496,
            rxn_id: 'RNEW-desilylation-16962926',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-48',
      cost: 2.574772e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.0,
      cost_reagents: 0.26185226,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-103905',
          name: '1,4-pyrazine',
          purchasable: true,
        },
        {
          cid: 'RXC-1732543',
          name: 'bromotrichloromethane',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-2037554'],
      solvents: [],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-26486354',
          left_primaries: ['RXC-1732543'],
          right_primaries: ['RXC-1698092'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963836',
          left_primaries: ['RXC-1698092', 'RXC-2804068'],
          right_primaries: ['CNEW-16667895'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963339',
          left_primaries: ['CNEW-16667895', 'RXC-3587154'],
          right_primaries: ['CNEW-16667808'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963875',
          left_primaries: ['CNEW-16667808', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667401'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963104',
          left_primaries: ['CNEW-16667401'],
          right_primaries: ['CNEW-16667400'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16962906',
          left_primaries: ['RXC-103905', 'CNEW-16667400'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.574772e10,
        yield: 0.98975044,
        rxn_id: 'RNEW-friedel crafts acylation-16962906',
        branches: [
          {
            feedstock: 'RXC-103905',
            cost: 47.3759,
            purchasable: true,
          },
          {
            prod_id: 'CNEW-16667400',
            cost: 2.5483817e10,
            yield: 0.9212215,
            rxn_id: 'RNEW-desilylation-16963104',
            branches: [
              {
                prod_id: 'CNEW-16667401',
                cost: 2.347624e10,
                yield: 0.9896562,
                rxn_id: 'RNEW-amide n alkylation-16963875',
                branches: [
                  {
                    prod_id: 'CNEW-16667808',
                    cost: 196.36078,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963339',
                    branches: [
                      {
                        prod_id: 'CNEW-16667895',
                        cost: 140.16255,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide from acid chloride-16963836',
                        branches: [
                          {
                            prod_id: 'RXC-1698092',
                            cost: 77.548065,
                            yield: 0.5,
                            rxn_id: 'RXR-26486354',
                            branches: [
                              {
                                feedstock: 'RXC-1732543',
                                cost: 13.76403,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-1698092',
            cost: 77.548065,
            yield: 0.5,
            rxn_id: 'RXR-26486354',
            feedstockIds: ['RXC-1732543'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667895',
            cost: 140.16255,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963836',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667808',
            cost: 196.36078,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963339',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667401',
            cost: 2.347624e10,
            yield: 0.9896562,
            rxn_id: 'RNEW-amide n alkylation-16963875',
            feedstockIds: [],
            branchIds: ['CNEW-16667808', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667400',
            cost: 2.5483817e10,
            yield: 0.9212215,
            rxn_id: 'RNEW-desilylation-16963104',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.574772e10,
            yield: 0.98975044,
            rxn_id: 'RNEW-friedel crafts acylation-16962906',
            feedstockIds: ['RXC-103905'],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-49',
      cost: 2.574772e10,
      depth: 6,
      nbrxns: 7,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.1,
      cost_reagents: 6.183184,
      cost_catalysts: 1075.8013,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-103905',
          name: '1,4-pyrazine',
          purchasable: true,
        },
        {
          cid: 'RXC-1098295',
          name: 'tetrachloromethane',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-3587155',
        'RXC-14497211',
        'RXC-19270857',
        'RXC-19290122',
        'RXC-3587218',
        'RXC-8128137',
        'RXC-19276987',
        'RXC-4360473',
        'RXC-4122945',
        'RXC-19284165',
        'RXC-2037554',
        'RXC-8973685',
        'RXC-19286924',
        'RXC-3535376',
        'RXC-13320054',
        'RXC-19273435',
        'RXC-4921393',
        'RXC-3587191',
        'RXC-8135986',
        'RXC-3902804',
      ],
      solvents: ['RXC-3587155'],
      catalysts: ['RXC-14247131', 'RXC-3587236', 'RXC-3903066', 'RXC-16501948', 'RXC-3535368'],
      route: [
        {
          rxn_id: 'RXR-61316',
          left_primaries: ['RXC-1098295'],
          right_primaries: ['RXC-1098367'],
        },
        {
          rxn_id: 'RNEW-amide from acid chloride-16963774',
          left_primaries: ['RXC-1098367', 'RXC-2804068'],
          right_primaries: ['CNEW-16667902'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963320',
          left_primaries: ['CNEW-16667902', 'RXC-3587154'],
          right_primaries: ['CNEW-16667809'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963814',
          left_primaries: ['CNEW-16667809', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667404'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963082',
          left_primaries: ['CNEW-16667404'],
          right_primaries: ['CNEW-16667403'],
        },
        {
          rxn_id: 'RNEW-friedel crafts acylation-16962905',
          left_primaries: ['RXC-103905', 'CNEW-16667403'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.574772e10,
        yield: 0.98975044,
        rxn_id: 'RNEW-friedel crafts acylation-16962905',
        branches: [
          {
            feedstock: 'RXC-103905',
            cost: 47.3759,
            purchasable: true,
          },
          {
            prod_id: 'CNEW-16667403',
            cost: 2.5483817e10,
            yield: 0.9212215,
            rxn_id: 'RNEW-desilylation-16963082',
            branches: [
              {
                prod_id: 'CNEW-16667404',
                cost: 2.347624e10,
                yield: 0.9896562,
                rxn_id: 'RNEW-amide n alkylation-16963814',
                branches: [
                  {
                    prod_id: 'CNEW-16667809',
                    cost: 168.40076,
                    yield: 0.99,
                    rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963320',
                    branches: [
                      {
                        prod_id: 'CNEW-16667902',
                        cost: 112.48213,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide from acid chloride-16963774',
                        branches: [
                          {
                            prod_id: 'RXC-1098367',
                            cost: 50.144444,
                            yield: 0.9,
                            rxn_id: 'RXR-61316',
                            branches: [
                              {
                                feedstock: 'RXC-1098295',
                                cost: 0.1,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        feedstock: 'RXC-3587154',
                        cost: 4.734618,
                        purchasable: true,
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667729',
                    cost: 2.3233407e10,
                    yield: 0.9899538,
                    rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667727',
                        cost: 2.3e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-1098367',
            cost: 50.144444,
            yield: 0.9,
            rxn_id: 'RXR-61316',
            feedstockIds: ['RXC-1098295'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667902',
            cost: 112.48213,
            yield: 0.99,
            rxn_id: 'RNEW-amide from acid chloride-16963774',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667809',
            cost: 168.40076,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963320',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667404',
            cost: 2.347624e10,
            yield: 0.9896562,
            rxn_id: 'RNEW-amide n alkylation-16963814',
            feedstockIds: [],
            branchIds: ['CNEW-16667809', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667403',
            cost: 2.5483817e10,
            yield: 0.9212215,
            rxn_id: 'RNEW-desilylation-16963082',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.574772e10,
            yield: 0.98975044,
            rxn_id: 'RNEW-friedel crafts acylation-16962905',
            feedstockIds: ['RXC-103905'],
            branchIds: [],
          },
        ],
      ],
    },
    {
      name: 'route-50',
      cost: 2.5753661e10,
      depth: 6,
      nbrxns: 8,
      nbnewrxns: 6,
      fullroute: false,
      cost_solvents: 0.82039493,
      cost_reagents: 15.902564,
      cost_catalysts: 0.0,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-105778',
          name: 'methylpyrazine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-3587154',
          name: 'ammonia',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667727',
          name: '',
          purchasable: false,
        },
      ],
      reagents: [
        'RXC-2804068',
        'RXC-3587158',
        'RXC-906771',
        'RXC-8128136',
        'RXC-11342937',
        'RXC-8127932',
        'RXC-2037554',
      ],
      solvents: ['RXC-3587155', 'RXC-635760', 'RXC-906771', 'RXC-2037554'],
      catalysts: [],
      route: [
        {
          rxn_id: 'RXR-5414835',
          left_primaries: ['RXC-105778'],
          right_primaries: ['RXC-112305'],
        },
        {
          rxn_id: 'RXR-2173213',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-2328698'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
          left_primaries: ['RXC-2328698', 'RXC-3587154'],
          right_primaries: ['CNEW-16667852'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16963948',
          left_primaries: ['RXC-506297', 'CNEW-16667727'],
          right_primaries: ['CNEW-16667729'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16963722',
          left_primaries: ['CNEW-16667852', 'CNEW-16667729'],
          right_primaries: ['CNEW-16667524'],
        },
        {
          rxn_id: 'RNEW-desilylation-16963042',
          left_primaries: ['CNEW-16667524'],
          right_primaries: ['CNEW-16667523'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16962942',
          left_primaries: ['RXC-3587154', 'CNEW-16667523'],
          right_primaries: ['CNEW-16667607'],
        },
        {
          rxn_id: 'RNEW-amide synthesis from carboxylic acid-16962896',
          left_primaries: ['RXC-112305', 'CNEW-16667607'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.5753661e10,
        yield: 0.99,
        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16962896',
        branches: [
          {
            prod_id: 'RXC-112305',
            cost: 89.86567,
            yield: 0.5,
            rxn_id: 'RXR-5414835',
            branches: [
              {
                feedstock: 'RXC-105778',
                cost: 19.932835,
                purchasable: true,
              },
            ],
          },
          {
            prod_id: 'CNEW-16667607',
            cost: 2.5496125e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962942',
            branches: [
              {
                feedstock: 'RXC-3587154',
                cost: 4.734618,
                purchasable: true,
              },
              {
                prod_id: 'CNEW-16667523',
                cost: 2.5241164e10,
                yield: 0.92984015,
                rxn_id: 'RNEW-desilylation-16963042',
                branches: [
                  {
                    prod_id: 'CNEW-16667524',
                    cost: 2.3470248e10,
                    yield: 0.9899089,
                    rxn_id: 'RNEW-amide n alkylation-16963722',
                    branches: [
                      {
                        prod_id: 'CNEW-16667852',
                        cost: 119.259865,
                        yield: 0.99,
                        rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
                        branches: [
                          {
                            prod_id: 'RXC-2328698',
                            cost: 63.83265,
                            yield: 0.927,
                            rxn_id: 'RXR-2173213',
                            branches: [
                              {
                                feedstock: 'RXC-2804068',
                                cost: 11.712864,
                                purchasable: true,
                              },
                            ],
                          },
                          {
                            feedstock: 'RXC-3587154',
                            cost: 4.734618,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'CNEW-16667729',
                        cost: 2.3233407e10,
                        yield: 0.9899538,
                        rxn_id: 'RNEW-alcohol sulfonylation-16963948',
                        branches: [
                          {
                            feedstock: 'RXC-506297',
                            cost: 3.7410412,
                            purchasable: true,
                          },
                          {
                            feedstock: 'CNEW-16667727',
                            cost: 2.3e10,
                            purchasable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667729',
            cost: 2.3233407e10,
            yield: 0.9899538,
            rxn_id: 'RNEW-alcohol sulfonylation-16963948',
            feedstockIds: ['RXC-506297', 'CNEW-16667727'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-2328698',
            cost: 63.83265,
            yield: 0.927,
            rxn_id: 'RXR-2173213',
            feedstockIds: ['RXC-2804068'],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667852',
            cost: 119.259865,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16963299',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667524',
            cost: 2.3470248e10,
            yield: 0.9899089,
            rxn_id: 'RNEW-amide n alkylation-16963722',
            feedstockIds: [],
            branchIds: ['CNEW-16667852', 'CNEW-16667729'],
          },
          {
            prod_id: 'CNEW-16667523',
            cost: 2.5241164e10,
            yield: 0.92984015,
            rxn_id: 'RNEW-desilylation-16963042',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'CNEW-16667607',
            cost: 2.5496125e10,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16962942',
            feedstockIds: ['RXC-3587154'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-112305',
            cost: 89.86567,
            yield: 0.5,
            rxn_id: 'RXR-5414835',
            feedstockIds: ['RXC-105778'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'RXC-11330190',
            cost: 2.5753661e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide synthesis from carboxylic acid-16962896',
            feedstockIds: [],
            branchIds: ['RXC-112305', 'CNEW-16667607'],
          },
        ],
      ],
    },
  ],
  cpds: {
    'RXC-11330190': {
      name: 'bortezomib',
      new: false,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)c1cnccn1)B(O)O',
      inchi:
        '1S/C19H25BN4O4/c1-13(2)10-17(20(27)28)24-18(25)15(11-14-6-4-3-5-7-14)23-19(26)16-12-21-8-9-22-16/h3-9,12-13,15,17,27-28H,10-11H2,1-2H3,(H,23,26)(H,24,25)/t15-,17-/m1/s1',
      inchi_key: 'GXJABQQUPOEUTA-NVXWUHKLSA-N',
      mol_cost: 425443.1,
      mono_weight: 384.1969,
      nb_rxns_producing: 33,
      nb_rxns_using: 6,
      em_tierNum: 2,
      em_price: 534.0,
      em_currency: 'USD',
      em_amount: 0.5,
      em_units: 'g',
      em_compoundId: '826157',
      em_versionId: '10448963',
      em_supplierId: '2065',
      em_supplierName: 'Pure Chemistry Scientific Inc.',
      em_catalogId: '2317',
      em_sku: '491382402',
    },
    'CNEW-16667444': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NCc1cnccn1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 37,
      nb_rxns_using: 1,
    },
    'CNEW-16667131': {
      name: '',
      new: true,
      smiles: 'CC(C)=C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NCc1cnccn1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 35,
      nb_rxns_using: 1,
    },
    'CNEW-16667831': {
      name: '',
      new: true,
      smiles: 'NC(=O)[C@@H](Cc1ccccc1)NCc1cnccn1',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 24,
      nb_rxns_using: 92,
    },
    'RXC-907068': {
      name: '2-aminomethylpyrazine',
      new: false,
      smiles: 'NCc1cnccn1',
      inchi: '1S/C5H7N3/c6-3-5-4-7-1-2-8-5/h1-2,4H,3,6H2',
      inchi_key: 'HQIBSDCOMQYSPF-UHFFFAOYSA-N',
      mol_cost: 768.0202,
      mono_weight: 109.064,
      nb_rxns_producing: 2,
      nb_rxns_using: 204,
      em_tierNum: 3,
      em_price: 1667.0,
      em_currency: 'USD',
      em_amount: 250.0,
      em_units: 'g',
      em_compoundId: 'PB00089',
      em_versionId: '299989437',
      em_supplierId: '1477',
      em_supplierName: 'Pharmablock - Backordered',
      em_catalogId: '1970',
      em_sku: '476479088',
    },
    'RXC-635760': {
      name: 'toluene',
      new: false,
      smiles: 'Cc1ccccc1',
      inchi: '1S/C7H8/c1-7-5-3-2-4-6-7/h2-6H,1H3',
      inchi_key: 'YXFVVABEGXRONW-UHFFFAOYSA-N',
      mol_cost: 0.45854264,
      mono_weight: 92.0626,
      nb_rxns_producing: 3104,
      nb_rxns_using: 12869,
      em_tierNum: 1,
      em_price: 934.0,
      em_currency: 'USD',
      em_amount: 200.0,
      em_units: 'l',
      em_compoundId: '035922',
      em_versionId: '300002916',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480125134',
    },
    'RXC-102551': {
      name: '1,4-dioxane',
      new: false,
      smiles: 'C1COCCO1',
      inchi: '1S/C4H8O2/c1-2-6-4-3-5-1/h1-4H2',
      inchi_key: 'RYHBNJHYFVUHQT-UHFFFAOYSA-N',
      mol_cost: 1.9942791,
      mono_weight: 88.05243,
      nb_rxns_producing: 127,
      nb_rxns_using: 4634,
      em_tierNum: 1,
      em_price: 4527.0,
      em_currency: 'USD',
      em_amount: 200.0,
      em_units: 'kg',
      em_compoundId: '044719',
      em_versionId: '483565',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480131694',
    },
    'RXC-1718733': {
      name: 'ethanol',
      new: false,
      smiles: 'CCO',
      inchi: '1S/C2H6O/c1-2-3/h3H,2H2,1H3',
      inchi_key: 'LFQSCWFLJHTTHZ-UHFFFAOYSA-N',
      mol_cost: 0.36912787,
      mono_weight: 46.04186,
      nb_rxns_producing: 2549,
      nb_rxns_using: 79408,
      em_tierNum: 3,
      em_price: 641.0,
      em_currency: 'USD',
      em_amount: 80.0,
      em_units: 'kg',
      em_compoundId: 'FI30533',
      em_versionId: '299995659',
      em_supplierId: '261',
      em_supplierName: 'Biosynth Carbosynth',
      em_catalogId: '1859',
      em_sku: '449843227',
    },
    'RXC-1098229': {
      name: 'methanol',
      new: false,
      smiles: 'CO',
      inchi: '1S/CH4O/c1-2/h2H,1H3',
      inchi_key: 'OKKJLVBELUTLKV-UHFFFAOYSA-N',
      mol_cost: 0.10589881,
      mono_weight: 32.02621,
      nb_rxns_producing: 2700,
      nb_rxns_using: 155744,
      em_tierNum: 1,
      em_price: 661.0,
      em_currency: 'USD',
      em_amount: 200.0,
      em_units: 'l',
      em_compoundId: '046842',
      em_versionId: '475176',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480133425',
    },
    'RXC-11382663': {
      name: 'Ni-doped silica',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 1,
    },
    'RXC-3587189': {
      name: 'hydrogen',
      new: false,
      smiles: '[H]',
      inchi: '1S/H2/h1H',
      inchi_key: 'UFHFLCQGNIYNRP-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 1.00783,
      nb_rxns_producing: 4257,
      nb_rxns_using: 11722,
    },
    'RXC-103233': {
      name: 'pyridine',
      new: false,
      smiles: 'c1ccncc1',
      inchi: '1S/C5H5N/c1-2-4-6-5-3-1/h1-5H',
      inchi_key: 'JUJWROOIHBZHMG-UHFFFAOYSA-N',
      mol_cost: 3.6955097,
      mono_weight: 79.0422,
      nb_rxns_producing: 884,
      nb_rxns_using: 24612,
      em_tierNum: 1,
      em_price: 217.0,
      em_currency: 'USD',
      em_amount: 5.0,
      em_units: 'l',
      em_compoundId: 'OR41162',
      em_versionId: '475020',
      em_supplierId: '1834',
      em_supplierName: 'Apollo Scientific US - Building Blocks',
      em_catalogId: '2102',
      em_sku: '398005301',
    },
    'RXC-3587154': {
      name: 'ammonia',
      new: false,
      smiles: 'N',
      inchi: '1S/H3N/h1H3',
      inchi_key: 'QGZKDVFQNNGYKY-UHFFFAOYSA-N',
      mol_cost: 4.734618,
      mono_weight: 17.02655,
      nb_rxns_producing: 5280,
      nb_rxns_using: 16338,
      em_tierNum: 3,
      em_price: 278.0,
      em_currency: 'USD',
      em_amount: 1000.0,
      em_units: 'ml',
      em_compoundId: 'H27080',
      em_versionId: '300013318',
      em_supplierId: '120',
      em_supplierName: 'Alfa Aesar (US)',
      em_catalogId: '334',
      em_sku: '440887337',
    },
    'RXC-3902804': {
      name: 'silica gel',
      new: false,
      smiles: 'O.O.[SiH4]',
      inchi: '1S/2H2O.Si/h2*1H2;',
      inchi_key: 'RFFGWGJUTJJDGK-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 68.02936,
      nb_rxns_producing: 720,
      nb_rxns_using: 7795,
    },
    'RXC-4122946': {
      name: 'nickel',
      new: false,
      smiles: '[Ni]',
      inchi: '1S/Ni',
      inchi_key: 'PXHVJJICTQNCMI-UHFFFAOYSA-N',
      mol_cost: 15.784444,
      mono_weight: 57.93535,
      nb_rxns_producing: 862,
      nb_rxns_using: 13694,
      em_tierNum: 1,
      em_price: 130.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: '93-2880',
      em_versionId: '475184',
      em_supplierId: '1258',
      em_supplierName: 'Strem Chemicals',
      em_catalogId: '1607',
      em_sku: '321342114',
    },
    'RXC-11350537': {
      name: 'palladium 10percent on activated carbon',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 2,
    },
    'RXC-110008': {
      name: '2-pyrazine carbonitrile',
      new: false,
      smiles: 'N#Cc1cnccn1',
      inchi: '1S/C5H3N3/c6-3-5-4-7-1-2-8-5/h1-2,4H',
      inchi_key: 'PMSVVUSIPKHUMT-UHFFFAOYSA-N',
      mol_cost: 15.338424,
      mono_weight: 105.0327,
      nb_rxns_producing: 12,
      nb_rxns_using: 235,
      em_tierNum: 2,
      em_price: 138.0,
      em_currency: 'USD',
      em_amount: 1000.0,
      em_units: 'g',
      em_compoundId: 'AG002BJA',
      em_versionId: '500347',
      em_supplierId: '934',
      em_supplierName: 'Angene Chemical',
      em_catalogId: '1875',
      em_sku: '391785658',
    },
    'RXC-7014199': {
      name: 'D-&alpha;-Chlor-hydrozimtsaeure-amid',
      new: false,
      smiles: 'NC(=O)[C@H](Cl)Cc1ccccc1',
      inchi: '1S/C9H10ClNO/c10-8(9(11)12)6-7-4-2-1-3-5-7/h1-5,8H,6H2,(H2,11,12)/t8-/m1/s1',
      inchi_key: 'HKNRCOYJYGAXHT-MRVPVSSYSA-N',
      mol_cost: 0.0,
      mono_weight: 183.04509,
      nb_rxns_producing: 1,
      nb_rxns_using: 56,
    },
    'RXC-2804068': {
      name: 'D-(R)-phenylalanine',
      new: false,
      smiles: 'N[C@H](Cc1ccccc1)C(=O)O',
      inchi: '1S/C9H11NO2/c10-8(9(11)12)6-7-4-2-1-3-5-7/h1-5,8H,6,10H2,(H,11,12)/t8-/m1/s1',
      inchi_key: 'COLNVLDHVKWLRT-MRVPVSSYSA-N',
      mol_cost: 11.712864,
      mono_weight: 165.07898,
      nb_rxns_producing: 146,
      nb_rxns_using: 408,
      em_tierNum: 3,
      em_price: 34.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'ug',
      em_compoundId: '17204',
      em_versionId: '530657',
      em_supplierId: '236',
      em_supplierName: 'Cayman Chemical',
      em_catalogId: '1677',
      em_sku: '293137185',
    },
    'CNEW-16667195': {
      name: '',
      new: true,
      smiles: 'CC(C)=C[C@H](OS(C)(=O)=O)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 3,
      nb_rxns_using: 13,
    },
    'RXC-506297': {
      name: 'methanesulfonyl chloride',
      new: false,
      smiles: 'CS(=O)(=O)Cl',
      inchi: '1S/CH3ClO2S/c1-5(2,3)4/h1H3',
      inchi_key: 'QARBMVPHQWIHKH-UHFFFAOYSA-N',
      mol_cost: 3.7410412,
      mono_weight: 113.95423,
      nb_rxns_producing: 36,
      nb_rxns_using: 49839,
      em_tierNum: 1,
      em_price: 631.0,
      em_currency: 'USD',
      em_amount: 20.0,
      em_units: 'kg',
      em_compoundId: '009736',
      em_versionId: '512172',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480109107',
    },
    'CNEW-16667194': {
      name: '',
      new: true,
      smiles: 'CC(C)=C[C@H](O)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 1,
    },
    'CNEW-16667158': {
      name: '',
      new: true,
      smiles: 'CC(C)=C[C@@H](NC(=O)[C@H](F)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 5,
    },
    'CNEW-16667859': {
      name: '',
      new: true,
      smiles: 'NC(=O)[C@H](F)Cc1ccccc1',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 10,
      nb_rxns_using: 56,
    },
    'RXC-21062592': {
      name: '(R)-2-fluoro-3-phenylpropanoic acid',
      new: false,
      smiles: 'O=C(O)[C@H](F)Cc1ccccc1',
      inchi: '1S/C9H9FO2/c10-8(9(11)12)6-7-4-2-1-3-5-7/h1-5,8H,6H2,(H,11,12)/t8-/m1/s1',
      inchi_key: 'SFJKLGCBWSLDGQ-MRVPVSSYSA-N',
      mol_cost: 0.0,
      mono_weight: 168.05865,
      nb_rxns_producing: 5,
      nb_rxns_using: 13,
    },
    'RXC-17485996': {
      name: 'pyridine hydrofluoride',
      new: false,
      smiles: 'F.c1ccncc1',
      inchi: '1S/C5H5N.FH/c1-2-4-6-5-3-1;/h1-5H;1H',
      inchi_key: 'GRJJQCWNZGRKAU-UHFFFAOYSA-N',
      mol_cost: 9.884702,
      mono_weight: 99.04843,
      nb_rxns_producing: 1,
      nb_rxns_using: 19,
      em_tierNum: 3,
      em_price: 47.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'ug',
      em_compoundId: '18007',
      em_versionId: '486140',
      em_supplierId: '236',
      em_supplierName: 'Cayman Chemical',
      em_catalogId: '1677',
      em_sku: '293137386',
    },
    'RXC-906771': {
      name: 'sodium nitrite',
      new: false,
      smiles: '[Na+].[O-]N=[OH+]',
      inchi: '1S/NO2.Na/c2-1-3;/q-1;+1',
      inchi_key: 'JYGOCQCXHYZFRS-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 70.99832,
      nb_rxns_producing: 129,
      nb_rxns_using: 1995,
    },
    'RXC-1910408': {
      name: 'L-phenylalanine',
      new: false,
      smiles: 'N[C@@H](Cc1ccccc1)C(=O)O',
      inchi: '1S/C9H11NO2/c10-8(9(11)12)6-7-4-2-1-3-5-7/h1-5,8H,6,10H2,(H,11,12)/t8-/m0/s1',
      inchi_key: 'COLNVLDHVKWLRT-QMMMGPOBSA-N',
      mol_cost: 8.240344,
      mono_weight: 165.07898,
      nb_rxns_producing: 427,
      nb_rxns_using: 2432,
      em_tierNum: 1,
      em_price: 1196.0,
      em_currency: 'USD',
      em_amount: 25.0,
      em_units: 'kg',
      em_compoundId: '00243',
      em_versionId: '514492',
      em_supplierId: '524',
      em_supplierName: 'Chem-Impex',
      em_catalogId: '999',
      em_sku: '112439480',
    },
    'CNEW-16667153': {
      name: '',
      new: true,
      smiles: 'CC(C)=C[C@@H](NC(=O)[C@H](Cl)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 5,
    },
    'CNEW-16667148': {
      name: '',
      new: true,
      smiles: 'CC(C)=C[C@@H](NC(=O)[C@H](Br)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 5,
    },
    'CNEW-16667852': {
      name: '',
      new: true,
      smiles: 'NC(=O)[C@H](Br)Cc1ccccc1',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 11,
      nb_rxns_using: 56,
    },
    'RXC-2328698': {
      name: '(S)-(alpha)-bromobenzenepropanoic acid',
      new: false,
      smiles: 'O=C(O)[C@H](Br)Cc1ccccc1',
      inchi: '1S/C9H9BrO2/c10-8(9(11)12)6-7-4-2-1-3-5-7/h1-5,8H,6H2,(H,11,12)/t8-/m1/s1',
      inchi_key: 'WDRSCFNERFONKU-MRVPVSSYSA-N',
      mol_cost: 0.0,
      mono_weight: 227.97859,
      nb_rxns_producing: 25,
      nb_rxns_using: 54,
    },
    'RXC-3587155': {
      name: 'water',
      new: false,
      smiles: 'O',
      inchi: '1S/H2O/h1H2',
      inchi_key: 'XLYOFNOQVPJJNP-UHFFFAOYSA-N',
      mol_cost: 0.1,
      mono_weight: 18.01056,
      nb_rxns_producing: 5202,
      nb_rxns_using: 89530,
    },
    'RXC-2037554': {
      name: 'sulfuric acid',
      new: false,
      smiles: 'O=S(=O)(O)O',
      inchi: '1S/H2O4S/c1-5(2,3)4/h(H2,1,2,3,4)',
      inchi_key: 'QAOWNCQODCNURD-UHFFFAOYSA-N',
      mol_cost: 0.26185226,
      mono_weight: 97.96738,
      nb_rxns_producing: 1025,
      nb_rxns_using: 19746,
      em_tierNum: 1,
      em_price: 534.0,
      em_currency: 'USD',
      em_amount: 200.0,
      em_units: 'l',
      em_compoundId: '098361',
      em_versionId: '300009071',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480148207',
    },
    'RXC-3587158': {
      name: 'hydrogen bromide',
      new: false,
      smiles: 'Br',
      inchi: '1S/BrH/h1H',
      inchi_key: 'CPELXLSAUQHCOX-UHFFFAOYSA-N',
      mol_cost: 3.1769056,
      mono_weight: 79.92616,
      nb_rxns_producing: 1115,
      nb_rxns_using: 5684,
      em_tierNum: 3,
      em_price: 187.0,
      em_currency: 'USD',
      em_amount: 5.0,
      em_units: 'kg',
      em_compoundId: 'OR13036',
      em_versionId: '299982188',
      em_supplierId: '1834',
      em_supplierName: 'Apollo Scientific US - Building Blocks',
      em_catalogId: '2103',
      em_sku: '475566287',
    },
    'RXC-8128136': {
      name: 'potassium bromide',
      new: false,
      smiles: 'Br.[K]',
      inchi: '1S/BrH.K/h1H;',
      inchi_key: 'LPWXYXNAGULPLG-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 118.88987,
      nb_rxns_producing: 127,
      nb_rxns_using: 1706,
    },
    'RXC-11342937': {
      name: 'potassium hydroxide',
      new: false,
      smiles: '[K+].[OH-]',
      inchi: '1S/K.H2O/h;1H2/q+1;/p-1',
      inchi_key: 'KWYUFKZDYYNOTN-UHFFFAOYSA-M',
      mol_cost: 0.7509421,
      mono_weight: 56.97427,
      nb_rxns_producing: 1,
      nb_rxns_using: 289,
      em_tierNum: 1,
      em_price: 127.0,
      em_currency: 'USD',
      em_amount: 10.0,
      em_units: 'kg',
      em_compoundId: '044688',
      em_versionId: '474494',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480131529',
    },
    'RXC-8127932': {
      name: 'sodium bromide',
      new: false,
      smiles: 'Br.[Na]',
      inchi: '1S/BrH.Na/h1H;',
      inchi_key: 'OJIVTPHEOARZQS-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 102.91593,
      nb_rxns_producing: 178,
      nb_rxns_using: 1805,
    },
    'CNEW-16667168': {
      name: '',
      new: true,
      smiles: 'CC(C)=C[C@@H](NC(=O)[C@H](N)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 25,
      nb_rxns_using: 17,
    },
    'RXC-3198805': {
      name: 'D-Phenylalaninamide',
      new: false,
      smiles: 'NC(=O)[C@H](N)Cc1ccccc1',
      inchi: '1S/C9H12N2O/c10-8(9(11)12)6-7-4-2-1-3-5-7/h1-5,8H,6,10H2,(H2,11,12)/t8-/m1/s1',
      inchi_key: 'OBSIQMZKFXFYLV-MRVPVSSYSA-N',
      mol_cost: 2616.9136,
      mono_weight: 164.09496,
      nb_rxns_producing: 18,
      nb_rxns_using: 103,
      em_tierNum: 2,
      em_price: 382.0,
      em_currency: 'USD',
      em_amount: 25.0,
      em_units: 'g',
      em_compoundId: 'A154473',
      em_versionId: '28294166',
      em_supplierId: '2061',
      em_supplierName: 'Ambeed',
      em_catalogId: '2324',
      em_sku: '491625692',
    },
    'RXC-2938915': {
      name: 'D-phenylalanine methyl ester',
      new: false,
      smiles: 'COC(=O)[C@H](N)Cc1ccccc1',
      inchi: '1S/C10H13NO2/c1-13-10(12)9(11)7-8-5-3-2-4-6-8/h2-6,9H,7,11H2,1H3/t9-/m1/s1',
      inchi_key: 'VSDUZFOSJDMAFZ-SECBINFHSA-N',
      mol_cost: 1894.4164,
      mono_weight: 179.09464,
      nb_rxns_producing: 32,
      nb_rxns_using: 244,
      em_tierNum: 3,
      em_price: 1017.0,
      em_currency: 'USD',
      em_amount: 100.0,
      em_units: 'g',
      em_compoundId: 'HAA5590',
      em_versionId: '1011858',
      em_supplierId: '935',
      em_supplierName: 'Iris Biotech',
      em_catalogId: '1370',
      em_sku: '293147669',
    },
    'RXC-605303': {
      name: 'acetyl chloride',
      new: false,
      smiles: 'CC(=O)Cl',
      inchi: '1S/C2H3ClO/c1-2(3)4/h1H3',
      inchi_key: 'WETWJCDKMRHUPV-UHFFFAOYSA-N',
      mol_cost: 4.4151945,
      mono_weight: 77.98724,
      nb_rxns_producing: 252,
      nb_rxns_using: 44315,
      em_tierNum: 1,
      em_price: 214.0,
      em_currency: 'USD',
      em_amount: 4.0,
      em_units: 'kg',
      em_compoundId: '094104',
      em_versionId: '474671',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480144082',
    },
    'RXC-1098214': {
      name: 'hydrogenchloride',
      new: false,
      smiles: 'Cl',
      inchi: '1S/ClH/h1H',
      inchi_key: 'VEXZGXHMUGYJMC-UHFFFAOYSA-N',
      mol_cost: 0.17112353,
      mono_weight: 35.97668,
      nb_rxns_producing: 2872,
      nb_rxns_using: 31906,
      em_tierNum: 1,
      em_price: 803.0,
      em_currency: 'USD',
      em_amount: 190.0,
      em_units: 'l',
      em_compoundId: '01829',
      em_versionId: '474765',
      em_supplierId: '524',
      em_supplierName: 'Chem-Impex',
      em_catalogId: '999',
      em_sku: '272385169',
    },
    'RXC-1098595': {
      name: 'chlorosulfonic acid',
      new: false,
      smiles: 'O=S(=O)(O)Cl',
      inchi: '1S/ClHO3S/c1-5(2,3)4/h(H,2,3,4)',
      inchi_key: 'XTHPWXDJESJLNJ-UHFFFAOYSA-N',
      mol_cost: 26.64089,
      mono_weight: 115.93349,
      nb_rxns_producing: 75,
      nb_rxns_using: 781,
      em_tierNum: 1,
      em_price: 221.0,
      em_currency: 'USD',
      em_amount: 1.0,
      em_units: 'kg',
      em_compoundId: '044858',
      em_versionId: '493104',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480132034',
    },
    'RXC-2349612': {
      name: 'boron trifluoride diethyl etherate',
      new: false,
      smiles: 'CC[O+](CC)[B-](F)(F)F',
      inchi: '1S/C4H10BF3O/c1-3-9(4-2)5(6,7)8/h3-4H2,1-2H3',
      inchi_key: 'MZTVMRUDEFSYGQ-UHFFFAOYSA-N',
      mol_cost: 19.01838,
      mono_weight: 142.07768,
      nb_rxns_producing: 1,
      nb_rxns_using: 2547,
      em_tierNum: 1,
      em_price: 67.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: '097637',
      em_versionId: '483937',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480146986',
    },
    'RXC-8008338': {
      name: 'calcium carbonate',
      new: false,
      smiles: 'O=C([O-])[O-].[Ca+2]',
      inchi: '1S/CH2O3.Ca/c2-1(3)4;/h(H2,2,3,4);/q;+2/p-2',
      inchi_key: 'VTYYLEPIZMXCLO-UHFFFAOYSA-L',
      mol_cost: 1.901634,
      mono_weight: 101.96299,
      nb_rxns_producing: 275,
      nb_rxns_using: 16119,
      em_tierNum: 2,
      em_price: 19.0,
      em_currency: 'USD',
      em_amount: 1000.0,
      em_units: 'g',
      em_compoundId: 'BD294207',
      em_versionId: '479462',
      em_supplierId: '2016',
      em_supplierName: 'BLD Pharmatech',
      em_catalogId: '2262',
      em_sku: '521552515',
    },
    'RXC-1209273': {
      name: 'thionyl chloride',
      new: false,
      smiles: 'O=S(Cl)Cl',
      inchi: '1S/Cl2OS/c1-4(2)3',
      inchi_key: 'FYSNRJHAOHDILO-UHFFFAOYSA-N',
      mol_cost: 7.9229946,
      mono_weight: 117.90469,
      nb_rxns_producing: 111,
      nb_rxns_using: 2035,
      em_tierNum: 1,
      em_price: 307.0,
      em_currency: 'USD',
      em_amount: 5.0,
      em_units: 'kg',
      em_compoundId: '033128',
      em_versionId: '486358',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480122507',
    },
    'RXC-606324': {
      name: '2-(chloromethyl)pyrazine',
      new: false,
      smiles: 'ClCc1cnccn1',
      inchi: '1S/C5H5ClN2/c6-3-5-4-7-1-2-8-5/h1-2,4H,3H2',
      inchi_key: 'GFHPSQFCHUIFTO-UHFFFAOYSA-N',
      mol_cost: 3617.0916,
      mono_weight: 128.01413,
      nb_rxns_producing: 5,
      nb_rxns_using: 90,
      em_tierNum: 3,
      em_price: 2609.0,
      em_currency: 'USD',
      em_amount: 100.0,
      em_units: 'g',
      em_compoundId: '37060',
      em_versionId: '29915932',
      em_supplierId: '1544',
      em_supplierName: 'AOBChem USA',
      em_catalogId: '1898',
      em_sku: '487922421',
    },
    'RXC-1098295': {
      name: 'tetrachloromethane',
      new: false,
      smiles: 'ClC(Cl)(Cl)Cl',
      inchi: '1S/CCl4/c2-1(3,4)5',
      inchi_key: 'VZGDMQKNWNREIO-UHFFFAOYSA-N',
      mol_cost: 0.1,
      mono_weight: 151.87541,
      nb_rxns_producing: 337,
      nb_rxns_using: 8445,
    },
    'RXC-1731042': {
      name: 'chloroform',
      new: false,
      smiles: 'ClC(Cl)Cl',
      inchi: '1S/CHCl3/c2-1(3)4/h1H',
      inchi_key: 'HEDRZPFGACZZDS-UHFFFAOYSA-N',
      mol_cost: 2.497835,
      mono_weight: 117.91438,
      nb_rxns_producing: 717,
      nb_rxns_using: 13336,
      em_tierNum: 1,
      em_price: 95.0,
      em_currency: 'USD',
      em_amount: 5.0,
      em_units: 'kg',
      em_compoundId: '035917',
      em_versionId: '475127',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480125106',
    },
    'RXC-506104': {
      name: 'ethyl acetate',
      new: false,
      smiles: 'CCOC(C)=O',
      inchi: '1S/C4H8O2/c1-3-6-4(2)5/h3H2,1-2H3',
      inchi_key: 'XEKOWRVHYACXOJ-UHFFFAOYSA-N',
      mol_cost: 0.3721941,
      mono_weight: 88.05243,
      nb_rxns_producing: 925,
      nb_rxns_using: 6737,
      em_tierNum: 1,
      em_price: 866.0,
      em_currency: 'USD',
      em_amount: 205.0,
      em_units: 'l',
      em_compoundId: '00757',
      em_versionId: '477309',
      em_supplierId: '524',
      em_supplierName: 'Chem-Impex',
      em_catalogId: '999',
      em_sku: '112440493',
    },
    'RXC-113915': {
      name: 'N-chloro-succinimide',
      new: false,
      smiles: 'O=C1CCC(=O)N1Cl',
      inchi: '1S/C4H4ClNO2/c5-6-3(7)1-2-4(6)8/h1-2H2',
      inchi_key: 'JRNVZBWKYDBUCA-UHFFFAOYSA-N',
      mol_cost: 5.5025196,
      mono_weight: 132.99306,
      nb_rxns_producing: 9,
      nb_rxns_using: 736,
      em_tierNum: 2,
      em_price: 40.0,
      em_currency: 'USD',
      em_amount: 1000.0,
      em_units: 'g',
      em_compoundId: 'A503107',
      em_versionId: '478516',
      em_supplierId: '2061',
      em_supplierName: 'Ambeed',
      em_catalogId: '2324',
      em_sku: '491670888',
    },
    'RXC-1210120': {
      name: 'Perbenzoic acid',
      new: false,
      smiles: 'O=C(OO)c1ccccc1',
      inchi: '1S/C7H6O3/c8-7(10-9)6-4-2-1-3-5-6/h1-5,9H',
      inchi_key: 'XCRBXWCUXJNEFX-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 138.0317,
      nb_rxns_producing: 50,
      nb_rxns_using: 822,
    },
    'RXC-984320': {
      name: 'dibenzoyl peroxide',
      new: false,
      smiles: 'O=C(OOC(=O)c1ccccc1)c1ccccc1',
      inchi: '1S/C14H10O4/c15-13(11-7-3-1-4-8-11)17-18-14(16)12-9-5-2-6-10-12/h1-10H',
      inchi_key: 'OMPJBNCRMGITSC-UHFFFAOYSA-N',
      mol_cost: 41.2008,
      mono_weight: 242.0579,
      nb_rxns_producing: 41,
      nb_rxns_using: 2208,
      em_tierNum: 3,
      em_price: 81.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: '934368',
      em_versionId: '474332',
      em_supplierId: '703',
      em_supplierName: 'J&K Scientific',
      em_catalogId: '1619',
      em_sku: '456095470',
    },
    'RXC-105778': {
      name: 'methylpyrazine',
      new: false,
      smiles: 'Cc1cnccn1',
      inchi: '1S/C5H6N2/c1-5-4-6-2-3-7-5/h2-4H,1H3',
      inchi_key: 'CAWHJQAVHZEVTJ-UHFFFAOYSA-N',
      mol_cost: 19.932835,
      mono_weight: 94.0531,
      nb_rxns_producing: 95,
      nb_rxns_using: 351,
      em_tierNum: 2,
      em_price: 199.0,
      em_currency: 'USD',
      em_amount: 1.0,
      em_units: 'kg',
      em_compoundId: 'M61917',
      em_versionId: '592196',
      em_supplierId: '89',
      em_supplierName: 'Synthonix - Stock',
      em_catalogId: '145',
      em_sku: '512100547',
    },
    'RXC-1708400': {
      name: "2,2'-azobis(isobutyronitrile)",
      new: false,
      smiles: 'CC(C)(C#N)/N=N/C(C)(C)C#N',
      inchi: '1S/C8H12N4/c1-7(2,5-9)11-12-8(3,4)6-10/h1-4H3',
      inchi_key: 'OZAIFHULBGXAKX-UHFFFAOYSA-N',
      mol_cost: 5550.366,
      mono_weight: 164.1062,
      nb_rxns_producing: 7,
      nb_rxns_using: 653,
      em_tierNum: 2,
      em_price: 338.0,
      em_currency: 'USD',
      em_amount: 10.0,
      em_units: 'g',
      em_compoundId: 'EN300-343540',
      em_versionId: '206842971',
      em_supplierId: '729',
      em_supplierName: 'Enamine BB - EU Stock',
      em_catalogId: '2129',
      em_sku: '510443220',
    },
    'RXC-202022': {
      name: 'trichloroisocyanuric acid',
      new: false,
      smiles: 'O=c1n(Cl)c(=O)n(Cl)c(=O)n1Cl',
      inchi: '1S/C3Cl3N3O3/c4-7-1(10)8(5)3(12)9(6)2(7)11',
      inchi_key: 'YRIZYWQGELRKNT-UHFFFAOYSA-N',
      mol_cost: 23.276115,
      mono_weight: 230.90051,
      nb_rxns_producing: 11,
      nb_rxns_using: 96,
      em_tierNum: 3,
      em_price: 238.0,
      em_currency: 'USD',
      em_amount: 2500.0,
      em_units: 'g',
      em_compoundId: 'B23906',
      em_versionId: '485513',
      em_supplierId: '120',
      em_supplierName: 'Alfa Aesar (US)',
      em_catalogId: '334',
      em_sku: '196043349',
    },
    'CNEW-16667000': {
      name: '',
      new: true,
      smiles: 'C=C(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NCc1cnccn1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 37,
      nb_rxns_using: 2,
    },
    'CNEW-16667079': {
      name: '',
      new: true,
      smiles: 'CC(C)(O)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NCc1cnccn1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 37,
      nb_rxns_using: 2,
    },
    'CNEW-16667092': {
      name: '',
      new: true,
      smiles: 'CC(C)(O)C[C@H](I)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 2,
    },
    'CNEW-16667091': {
      name: '',
      new: true,
      smiles: 'CC(C)(O)C[C@H](F)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 2,
    },
    'CNEW-16666990': {
      name: '',
      new: true,
      smiles: 'C=C(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)c1cnccn1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 32,
      nb_rxns_using: 1,
    },
    'CNEW-16667419': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)c1cnccn1)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 33,
      nb_rxns_using: 2,
    },
    'CNEW-16667816': {
      name: '',
      new: true,
      smiles: 'NC(=O)[C@@H](Cc1ccccc1)NC(=O)c1cnccn1',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 30,
      nb_rxns_using: 41,
    },
    'RXC-112306': {
      name: 'pyrazinamide',
      new: false,
      smiles: 'NC(=O)c1cnccn1',
      inchi: '1S/C5H5N3O/c6-5(9)4-3-7-1-2-8-4/h1-3H,(H2,6,9)',
      inchi_key: 'IPEHBUMCGVEMRF-UHFFFAOYSA-N',
      mol_cost: 40.105114,
      mono_weight: 123.04326,
      nb_rxns_producing: 18,
      nb_rxns_using: 385,
      em_tierNum: 3,
      em_price: 621.0,
      em_currency: 'USD',
      em_amount: 2.0,
      em_units: 'kg',
      em_compoundId: 'AP27270',
      em_versionId: '531199',
      em_supplierId: '261',
      em_supplierName: 'Biosynth Carbosynth',
      em_catalogId: '1859',
      em_sku: '449811227',
    },
    'CNEW-16667729': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@H](OS(C)(=O)=O)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 4,
      nb_rxns_using: 18,
    },
    'CNEW-16667727': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@H](O)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 2,
    },
    'CNEW-16667445': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NCc1cnccn1)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 37,
      nb_rxns_using: 2,
    },
    'CNEW-16667566': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](F)Cc1ccccc1)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 11,
    },
    'CNEW-16667524': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](Br)Cc1ccccc1)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 11,
    },
    'CNEW-16667545': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](Cl)Cc1ccccc1)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 11,
    },
    'RXC-103905': {
      name: '1,4-pyrazine',
      new: false,
      smiles: 'c1cnccn1',
      inchi: '1S/C4H4N2/c1-2-6-4-3-5-1/h1-4H',
      inchi_key: 'KYQCOXFCLRTKLS-UHFFFAOYSA-N',
      mol_cost: 47.3759,
      mono_weight: 80.03745,
      nb_rxns_producing: 139,
      nb_rxns_using: 2014,
      em_tierNum: 1,
      em_price: 275.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: 'P0544',
      em_versionId: '531195',
      em_supplierId: '304',
      em_supplierName: 'TCI America',
      em_catalogId: '1521',
      em_sku: '115104258',
    },
    'CNEW-16667401': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)Br)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 23,
      nb_rxns_using: 2,
    },
    'CNEW-16667808': {
      name: '',
      new: true,
      smiles: 'NC(=O)[C@@H](Cc1ccccc1)NC(=O)Br',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 19,
      nb_rxns_using: 24,
    },
    'CNEW-16667895': {
      name: '',
      new: true,
      smiles: 'O=C(Br)N[C@H](Cc1ccccc1)C(=O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 17,
      nb_rxns_using: 4,
    },
    'RXC-1698092': {
      name: 'carbonyl bromide chloride',
      new: false,
      smiles: 'O=C(Cl)Br',
      inchi: '1S/CBrClO/c2-1(3)4',
      inchi_key: 'ZYEIHEGIHDRDBZ-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 141.8821,
      nb_rxns_producing: 9,
      nb_rxns_using: 6,
    },
    'RXC-1732543': {
      name: 'bromotrichloromethane',
      new: false,
      smiles: 'ClC(Cl)(Cl)Br',
      inchi: '1S/CBrCl3/c2-1(3,4)5',
      inchi_key: 'XNNQFQFUQLJSQT-UHFFFAOYSA-N',
      mol_cost: 13.76403,
      mono_weight: 195.8249,
      nb_rxns_producing: 34,
      nb_rxns_using: 984,
      em_tierNum: 1,
      em_price: 321.0,
      em_currency: 'USD',
      em_amount: 5.0,
      em_units: 'kg',
      em_compoundId: '008672',
      em_versionId: '486430',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480107569',
    },
    'CNEW-16667404': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)Cl)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 23,
      nb_rxns_using: 2,
    },
    'CNEW-16667809': {
      name: '',
      new: true,
      smiles: 'NC(=O)[C@@H](Cc1ccccc1)NC(=O)Cl',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 19,
      nb_rxns_using: 24,
    },
    'CNEW-16667902': {
      name: '',
      new: true,
      smiles: 'O=C(Cl)N[C@H](Cc1ccccc1)C(=O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 17,
      nb_rxns_using: 4,
    },
    'RXC-1098367': {
      name: 'phosgene',
      new: false,
      smiles: 'O=C(Cl)Cl',
      inchi: '1S/CCl2O/c2-1(3)4',
      inchi_key: 'YGYAWVDWMABLBF-UHFFFAOYSA-N',
      mol_cost: 129.43855,
      mono_weight: 97.93262,
      nb_rxns_producing: 435,
      nb_rxns_using: 13390,
      em_tierNum: 1,
      em_price: 121.0,
      em_currency: 'USD',
      em_amount: 100.0,
      em_units: 'ml',
      em_compoundId: '098039',
      em_versionId: '516716',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480147696',
    },
    'RXC-14497211': {
      name: 'air',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-19270857': {
      name: 'fired clay fragments',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-19290122': {
      name: 'siloxene',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-3587218': {
      name: 'oxygen',
      new: false,
      smiles: 'O=O',
      inchi: '1S/O2/c1-2',
      inchi_key: 'MYMOFIZGZYHOMD-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 31.98983,
      nb_rxns_producing: 2490,
      nb_rxns_using: 12132,
    },
    'RXC-8128137': {
      name: 'copper(II) oxide',
      new: false,
      smiles: 'O.[Cu]',
      inchi: '1S/Cu.H2O/h;1H2',
      inchi_key: 'PTVDYARBVCBHSL-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 80.94017,
      nb_rxns_producing: 2291,
      nb_rxns_using: 23498,
    },
    'RXC-19276987': {
      name: 'metal chloride',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-4360473': {
      name: 'carbon',
      new: false,
      smiles: 'C',
      inchi: '1S/CH4/h1H4',
      inchi_key: 'VNWKTOKETHGBQD-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 16.0313,
      nb_rxns_producing: 806,
      nb_rxns_using: 6065,
    },
    'RXC-4122945': {
      name: 'iron',
      new: false,
      smiles: '[Fe]',
      inchi: '1S/Fe',
      inchi_key: 'XEEYBQQBJWHFJM-UHFFFAOYSA-N',
      mol_cost: 2.3429863,
      mono_weight: 55.93494,
      nb_rxns_producing: 494,
      nb_rxns_using: 18079,
      em_tierNum: 1,
      em_price: 199.0,
      em_currency: 'USD',
      em_amount: 5.0,
      em_units: 'kg',
      em_compoundId: '044795',
      em_versionId: '313094171',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480131900',
    },
    'RXC-19284165': {
      name: 'plaster',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-8973685': {
      name: 'zinc',
      new: false,
      smiles: '[Zn]',
      inchi: '1S/Zn',
      inchi_key: 'HCHKCACWOHOZIP-UHFFFAOYSA-N',
      mol_cost: 2.8575902,
      mono_weight: 63.92915,
      nb_rxns_producing: 298,
      nb_rxns_using: 6381,
      em_tierNum: 1,
      em_price: 106.0,
      em_currency: 'USD',
      em_amount: 2.5,
      em_units: 'kg',
      em_compoundId: '099358',
      em_versionId: '474462',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480150224',
    },
    'RXC-19286924': {
      name: 'pumice stone',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-3535376': {
      name: 'sulfur trioxide',
      new: false,
      smiles: 'O=S(=O)=O',
      inchi: '1S/O3S/c1-4(2)3',
      inchi_key: 'AKEJUJNQAAGONA-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 79.95681,
      nb_rxns_producing: 499,
      nb_rxns_using: 1350,
    },
    'RXC-13320054': {
      name: 'pyrosulfuryl chloride',
      new: false,
      smiles: 'O=S(=O)(Cl)OS(=O)(=O)Cl',
      inchi: '1S/Cl2O5S2/c1-8(3,4)7-9(2,5)6',
      inchi_key: 'NNTJKSMVNWGFTB-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 213.85641,
      nb_rxns_producing: 79,
      nb_rxns_using: 58,
    },
    'RXC-19273435': {
      name: 'infusorial earht',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-4921393': {
      name: 'ozone',
      new: false,
      smiles: 'O=[O+][O-]',
      inchi: '1S/O3/c1-3-2',
      inchi_key: 'CBENFWSGALASAD-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 48.99257,
      nb_rxns_producing: 329,
      nb_rxns_using: 2202,
    },
    'RXC-3587191': {
      name: 'dihydrogen peroxide',
      new: false,
      smiles: 'OO',
      inchi: '1S/H2O2/c1-2/h1-2H',
      inchi_key: 'MHAJPDPJQMAIIY-UHFFFAOYSA-N',
      mol_cost: 0.6207555,
      mono_weight: 34.00548,
      nb_rxns_producing: 650,
      nb_rxns_using: 7273,
      em_tierNum: 1,
      em_price: 73.0,
      em_currency: 'USD',
      em_amount: 4.0,
      em_units: 'kg',
      em_compoundId: '044711',
      em_versionId: '484980',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '491485091',
    },
    'RXC-8135986': {
      name: 'iron(II) chloride',
      new: false,
      smiles: 'Cl.Cl.[Fe]',
      inchi: '1S/2ClH.Fe/h2*1H;',
      inchi_key: 'DSECQYSQZGJSPY-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 127.8883,
      nb_rxns_producing: 223,
      nb_rxns_using: 4474,
    },
    'RXC-14247131': {
      name: 'antimony(V) chloride',
      new: false,
      smiles: '[Cl-].[Cl-].[Cl-].[Cl-].[Cl-].[Sb+5]',
      inchi: '1S/5ClH.Sb/h5*1H;/q;;;;;+5/p-5',
      inchi_key: 'VMPVEPPRYRXYNP-UHFFFAOYSA-I',
      mol_cost: 145.5285,
      mono_weight: 300.7872,
      nb_rxns_producing: 13,
      nb_rxns_using: 180,
      em_tierNum: 1,
      em_price: 221.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: '93-5102',
      em_versionId: '478642',
      em_supplierId: '1258',
      em_supplierName: 'Strem Chemicals',
      em_catalogId: '1607',
      em_sku: '321342959',
    },
    'RXC-3587236': {
      name: 'Gallium trichloride',
      new: false,
      smiles: '[Cl-].[Cl-].[Cl-].[Ga+3]',
      inchi: '1S/3ClH.Ga/h3*1H;/q;;;+3/p-3',
      inchi_key: 'UPWPDUACHOATKO-UHFFFAOYSA-K',
      mol_cost: 782.6946,
      mono_weight: 0.0,
      nb_rxns_producing: 33,
      nb_rxns_using: 1592,
      em_tierNum: 1,
      em_price: 403.0,
      em_currency: 'USD',
      em_amount: 100.0,
      em_units: 'g',
      em_compoundId: '93-3140',
      em_versionId: '507222',
      em_supplierId: '1258',
      em_supplierName: 'Strem Chemicals',
      em_catalogId: '1607',
      em_sku: '321342311',
    },
    'RXC-3903066': {
      name: 'iron(III) chloride',
      new: false,
      smiles: '[Cl-].[Cl-].[Cl-].[Fe+3]',
      inchi: '1S/3ClH.Fe/h3*1H;/q;;;+3/p-3',
      inchi_key: 'RBTARNINKXHZNM-UHFFFAOYSA-K',
      mol_cost: 2.0496624,
      mono_weight: 163.86497,
      nb_rxns_producing: 189,
      nb_rxns_using: 5136,
      em_tierNum: 1,
      em_price: 294.0,
      em_currency: 'USD',
      em_amount: 25.0,
      em_units: 'kg',
      em_compoundId: '098583',
      em_versionId: '43217231',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480148520',
    },
    'RXC-16501948': {
      name: 'iron(III) chloride tertahydrate',
      new: false,
      smiles: 'O.[Cl-].[Cl-].[Cl-].[Fe+3]',
      inchi: '1S/3ClH.Fe.H2O/h3*1H;;1H2/q;;;+3;/p-3',
      inchi_key: 'VITRLXDSBBVNCZ-UHFFFAOYSA-K',
      mol_cost: 0.0,
      mono_weight: 181.87553,
      nb_rxns_producing: 0,
      nb_rxns_using: 43,
    },
    'RXC-3535368': {
      name: 'antimonypentachloride',
      new: false,
      smiles: '[Cl-].[Cl-].[Cl-].[Cl-].[Cl-].[Sb+5]',
      inchi: '1S/5ClH.Sb/h5*1H;/q;;;;;+5/p-5',
      inchi_key: 'VMPVEPPRYRXYNP-UHFFFAOYSA-I',
      mol_cost: 145.5285,
      mono_weight: 300.7872,
      nb_rxns_producing: 31,
      nb_rxns_using: 1106,
      em_tierNum: 1,
      em_price: 221.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: '93-5102',
      em_versionId: '478642',
      em_supplierId: '1258',
      em_supplierName: 'Strem Chemicals',
      em_catalogId: '1607',
      em_sku: '321342959',
    },
    'CNEW-16667523': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](Br)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 18,
      nb_rxns_using: 22,
    },
    'CNEW-16667544': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](Cl)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 18,
      nb_rxns_using: 22,
    },
    'CNEW-16667728': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@H](OS(C)(=O)=O)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 2,
      nb_rxns_using: 63,
    },
    'CNEW-16667726': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@H](O)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 2,
      nb_rxns_using: 1,
    },
    'CNEW-16667731': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@H](O[Si])B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 1,
    },
    'CNEW-16667565': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](F)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 18,
      nb_rxns_using: 22,
    },
    'RXC-112307': {
      name: 'pyrazinoyl chloride',
      new: false,
      smiles: 'O=C(Cl)c1cnccn1',
      inchi: '1S/C5H3ClN2O/c6-5(9)4-3-7-1-2-8-4/h1-3H',
      inchi_key: 'TXJKATOSKLUITR-UHFFFAOYSA-N',
      mol_cost: 3360.7363,
      mono_weight: 141.9934,
      nb_rxns_producing: 1,
      nb_rxns_using: 191,
      em_tierNum: 3,
      em_price: 2202.0,
      em_currency: 'USD',
      em_amount: 100.0,
      em_units: 'g',
      em_compoundId: 'EB15016',
      em_versionId: '720046',
      em_supplierId: '441',
      em_supplierName: 'EvoBlocks',
      em_catalogId: '934',
      em_sku: '205422505',
    },
    'RXC-969212': {
      name: 'benzene',
      new: false,
      smiles: 'c1ccccc1',
      inchi: '1S/C6H6/c1-2-4-6-5-3-1/h1-6H',
      inchi_key: 'UHOVQNZJYSORNB-UHFFFAOYSA-N',
      mol_cost: 7.2379317,
      mono_weight: 78.04695,
      nb_rxns_producing: 3783,
      nb_rxns_using: 23315,
      em_tierNum: 3,
      em_price: 43.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'ml',
      em_compoundId: '984267',
      em_versionId: '479848',
      em_supplierId: '703',
      em_supplierName: 'J&K Scientific',
      em_catalogId: '1619',
      em_sku: '456096273',
    },
    'RXC-1730800': {
      name: 'dichloromethane',
      new: false,
      smiles: 'ClCCl',
      inchi: '1S/CH2Cl2/c2-1-3/h1H2',
      inchi_key: 'YMWUJEATGCHHMB-UHFFFAOYSA-N',
      mol_cost: 0.5119155,
      mono_weight: 83.95336,
      nb_rxns_producing: 175,
      nb_rxns_using: 15753,
      em_tierNum: 3,
      em_price: 1101.0,
      em_currency: 'USD',
      em_amount: 200.0,
      em_units: 'l',
      em_compoundId: 'SOL-002',
      em_versionId: '475165',
      em_supplierId: '935',
      em_supplierName: 'Iris Biotech',
      em_catalogId: '1370',
      em_sku: '293152062',
    },
    'RXC-605365': {
      name: 'N,N-dimethyl-formamide',
      new: false,
      smiles: 'CN(C)C=O',
      inchi: '1S/C3H7NO/c1-4(2)3-5/h3H,1-2H3',
      inchi_key: 'ZMXDDKWLCZADIW-UHFFFAOYSA-N',
      mol_cost: 0.41189033,
      mono_weight: 73.05276,
      nb_rxns_producing: 161,
      nb_rxns_using: 37633,
      em_tierNum: 3,
      em_price: 1127.0,
      em_currency: 'USD',
      em_amount: 200.0,
      em_units: 'l',
      em_compoundId: 'SOL-004',
      em_versionId: '483489',
      em_supplierId: '935',
      em_supplierName: 'Iris Biotech',
      em_catalogId: '1370',
      em_sku: '293152068',
    },
    'RXC-1071255': {
      name: 'C3H7N1O1',
      new: false,
      smiles: 'CNC(C)=O',
      inchi: '1S/C3H7NO/c1-3(5)4-2/h1-2H3,(H,4,5)',
      inchi_key: 'OHLUUHNLEMFGTQ-UHFFFAOYSA-N',
      mol_cost: 6.8709297,
      mono_weight: 73.05276,
      nb_rxns_producing: 108,
      nb_rxns_using: 458,
      em_tierNum: 2,
      em_price: 47.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: 'AG0035C2',
      em_versionId: '527568',
      em_supplierId: '934',
      em_supplierName: 'Angene Chemical',
      em_catalogId: '1875',
      em_sku: '391815441',
    },
    'RXC-102391': {
      name: 'tetrahydrofuran',
      new: false,
      smiles: 'C1CCOC1',
      inchi: '1S/C4H8O/c1-2-4-5-3-1/h1-4H2',
      inchi_key: 'WYURNTSHIVDZCO-UHFFFAOYSA-N',
      mol_cost: 1.0239193,
      mono_weight: 72.05751,
      nb_rxns_producing: 303,
      nb_rxns_using: 16740,
      em_tierNum: 1,
      em_price: 2911.0,
      em_currency: 'USD',
      em_amount: 205.0,
      em_units: 'l',
      em_compoundId: '00761',
      em_versionId: '482258',
      em_supplierId: '524',
      em_supplierName: 'Chem-Impex',
      em_catalogId: '999',
      em_sku: '272384961',
    },
    'RXC-1361988': {
      name: 'oxalyl dichloride',
      new: false,
      smiles: 'O=C(Cl)C(=O)Cl',
      inchi: '1S/C2Cl2O2/c3-1(5)2(4)6',
      inchi_key: 'CTSLXHKWHWQRSH-UHFFFAOYSA-N',
      mol_cost: 30.641369,
      mono_weight: 125.92753,
      nb_rxns_producing: 17,
      nb_rxns_using: 12969,
      em_tierNum: 1,
      em_price: 681.0,
      em_currency: 'USD',
      em_amount: 3.0,
      em_units: 'kg',
      em_compoundId: '003771',
      em_versionId: '489426',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480103200',
    },
    'RXC-2036449': {
      name: 'phosphorus pentachloride',
      new: false,
      smiles: 'ClP(Cl)(Cl)(Cl)Cl',
      inchi: '1S/Cl5P/c1-6(2,3,4)5',
      inchi_key: 'UHZYTMXLRWXGPK-UHFFFAOYSA-N',
      mol_cost: 42.47938,
      mono_weight: 205.81802,
      nb_rxns_producing: 94,
      nb_rxns_using: 3261,
      em_tierNum: 1,
      em_price: 186.0,
      em_currency: 'USD',
      em_amount: 1.0,
      em_units: 'kg',
      em_compoundId: '93-1545',
      em_versionId: '475934',
      em_supplierId: '1258',
      em_supplierName: 'Strem Chemicals',
      em_catalogId: '1607',
      em_sku: '321341458',
    },
    'RXC-605283': {
      name: 'triethylamine',
      new: false,
      smiles: 'CCN(CC)CC',
      inchi: '1S/C6H15N/c1-4-7(5-2)6-3/h4-6H2,1-3H3',
      inchi_key: 'ZMANZCXQSJIPKH-UHFFFAOYSA-N',
      mol_cost: 1.2902107,
      mono_weight: 101.12045,
      nb_rxns_producing: 292,
      nb_rxns_using: 11094,
      em_tierNum: 1,
      em_price: 1275.0,
      em_currency: 'USD',
      em_amount: 100.0,
      em_units: 'l',
      em_compoundId: '00319',
      em_versionId: '299984736',
      em_supplierId: '524',
      em_supplierName: 'Chem-Impex',
      em_catalogId: '999',
      em_sku: '205000150',
    },
    'RXC-112305': {
      name: 'pyrazinecarboxylic acid',
      new: false,
      smiles: 'O=C(O)c1cnccn1',
      inchi: '1S/C5H4N2O2/c8-5(9)4-3-6-1-2-7-4/h1-3H,(H,8,9)',
      inchi_key: 'NIPZZXUFJPQHNH-UHFFFAOYSA-N',
      mol_cost: 34.619102,
      mono_weight: 124.02728,
      nb_rxns_producing: 14,
      nb_rxns_using: 903,
      em_tierNum: 2,
      em_price: 133.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: 'AG0033FO',
      em_versionId: '531197',
      em_supplierId: '934',
      em_supplierName: 'Angene Chemical',
      em_catalogId: '1875',
      em_sku: '391806941',
    },
    'RXC-385653': {
      name: 'chloroformic acid ethyl ester',
      new: false,
      smiles: 'CCOC(=O)Cl',
      inchi: '1S/C3H5ClO2/c1-2-6-3(4)5/h2H2,1H3',
      inchi_key: 'RIFGWPKJUGCATF-UHFFFAOYSA-N',
      mol_cost: 24.986767,
      mono_weight: 107.99781,
      nb_rxns_producing: 22,
      nb_rxns_using: 21095,
      em_tierNum: 3,
      em_price: 111.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: 'L06311',
      em_versionId: '486489',
      em_supplierId: '592',
      em_supplierName: 'Alfa Aesar (UK)',
      em_catalogId: '1066',
      em_sku: '198635080',
    },
    'CNEW-16667608': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](N)Cc1ccccc1)B(O)O[Si]',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 25,
    },
    'CNEW-16667607': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@H](N)Cc1ccccc1)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 21,
      nb_rxns_using: 55,
    },
    'RXC-505944': {
      name: 'ethanolamine',
      new: false,
      smiles: 'NCCO',
      inchi: '1S/C2H7NO/c3-1-2-4/h4H,1-3H2',
      inchi_key: 'HZAXFHJVJLSVMW-UHFFFAOYSA-N',
      mol_cost: 0.7370803,
      mono_weight: 61.05276,
      nb_rxns_producing: 118,
      nb_rxns_using: 13953,
      em_tierNum: 1,
      em_price: 181.0,
      em_currency: 'USD',
      em_amount: 15.0,
      em_units: 'kg',
      em_compoundId: '043400',
      em_versionId: '299981165',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480130017',
    },
    'RXC-1735345': {
      name: 'carbamic acid bromide',
      new: false,
      smiles: 'NC(=O)Br',
      inchi: '1S/CH2BrNO/c2-1(3)4/h(H2,3,4)',
      inchi_key: 'RTTSQSBCRXBLAU-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 122.93198,
      nb_rxns_producing: 3,
      nb_rxns_using: 41,
    },
    'RXC-1732479': {
      name: 'cyanic acid',
      new: false,
      smiles: 'N#CO',
      inchi: '1S/CHNO/c2-1-3/h3H',
      inchi_key: 'XLJMAIOERFSOGZ-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 43.00581,
      nb_rxns_producing: 179,
      nb_rxns_using: 232,
    },
    'RXC-605257': {
      name: 'dimethyl amine',
      new: false,
      smiles: 'CNC',
      inchi: '1S/C2H7N/c1-3-2/h3H,1-2H3',
      inchi_key: 'ROSDSFDQCJNGOL-UHFFFAOYSA-N',
      mol_cost: 0.23466742,
      mono_weight: 45.05785,
      nb_rxns_producing: 814,
      nb_rxns_using: 26999,
      em_tierNum: 1,
      em_price: 1041.0,
      em_currency: 'USD',
      em_amount: 200.0,
      em_units: 'l',
      em_compoundId: '044754',
      em_versionId: '299986019',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480131819',
    },
    'RXC-1734893': {
      name: 'carbamic chloride',
      new: false,
      smiles: 'NC(=O)Cl',
      inchi: '1S/CH2ClNO/c2-1(3)4/h(H2,3,4)',
      inchi_key: 'CKDWPUIZGOQOOM-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 78.98249,
      nb_rxns_producing: 17,
      nb_rxns_using: 297,
    },
    'RXC-3547232': {
      name: 'ammonium chloride',
      new: false,
      smiles: 'N.[Cl-]',
      inchi: '1S/ClH.H4N/h1H;1H4/p-1',
      inchi_key: 'VUJJFFNQWXJXRC-UHFFFAOYSA-M',
      mol_cost: 0.0,
      mono_weight: 53.00323,
      nb_rxns_producing: 690,
      nb_rxns_using: 5021,
    },
    'RXC-4371014': {
      name: 'ammonium chloride',
      new: false,
      smiles: 'Cl.N',
      inchi: '1S/ClH.H3N/h1H;1H3',
      inchi_key: 'NLXLAEXVIDQMFP-UHFFFAOYSA-N',
      mol_cost: 0.8110462,
      mono_weight: 53.00323,
      nb_rxns_producing: 22,
      nb_rxns_using: 266,
      em_tierNum: 1,
      em_price: 141.0,
      em_currency: 'USD',
      em_amount: 10.0,
      em_units: 'kg',
      em_compoundId: '044722',
      em_versionId: '43213752',
      em_supplierId: '2024',
      em_supplierName: 'Oakwood Chemicals',
      em_catalogId: '2270',
      em_sku: '480131715',
    },
    'RXC-506007': {
      name: 'acetic acid',
      new: false,
      smiles: 'CC(=O)O',
      inchi: '1S/C2H4O2/c1-2(3)4/h1H3,(H,3,4)',
      inchi_key: 'QTBSBXVTEAMEQO-UHFFFAOYSA-N',
      mol_cost: 0.86259305,
      mono_weight: 60.02113,
      nb_rxns_producing: 4807,
      nb_rxns_using: 53377,
      em_tierNum: 1,
      em_price: 2801.0,
      em_currency: 'USD',
      em_amount: 195.0,
      em_units: 'l',
      em_compoundId: '02028',
      em_versionId: '475727',
      em_supplierId: '524',
      em_supplierName: 'Chem-Impex',
      em_catalogId: '999',
      em_sku: '489819403',
    },
    'RXC-507540': {
      name: 'nitrobenzene',
      new: false,
      smiles: 'O=[N+]([O-])c1ccccc1',
      inchi: '1S/C6H5NO2/c8-7(9)6-4-2-1-3-5-6/h1-5H',
      inchi_key: 'LQNUZADURLCDLV-UHFFFAOYSA-N',
      mol_cost: 8.782811,
      mono_weight: 123.03203,
      nb_rxns_producing: 727,
      nb_rxns_using: 3885,
      em_tierNum: 3,
      em_price: 34.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: 'N0758',
      em_versionId: '476135',
      em_supplierId: '304',
      em_supplierName: 'TCI America',
      em_catalogId: '1454',
      em_sku: '518020763',
    },
    'RXC-147982': {
      name: '2,3-Pyrazinedicarboxylic acid',
      new: false,
      smiles: 'O=C(O)c1nccnc1C(=O)O',
      inchi: '1S/C6H4N2O4/c9-5(10)3-4(6(11)12)8-2-1-7-3/h1-2H,(H,9,10)(H,11,12)',
      inchi_key: 'ZUCRGHABDDWQPY-UHFFFAOYSA-N',
      mol_cost: 71.40396,
      mono_weight: 168.0171,
      nb_rxns_producing: 6,
      nb_rxns_using: 307,
      em_tierNum: 2,
      em_price: 205.0,
      em_currency: 'USD',
      em_amount: 500.0,
      em_units: 'g',
      em_compoundId: 'BD9861',
      em_versionId: '531201',
      em_supplierId: '2016',
      em_supplierName: 'BLD Pharmatech',
      em_catalogId: '2262',
      em_sku: '476415413',
    },
    'RXC-19931735': {
      name: 'recombinant Acinetobacter baumanii pyrazinamidase/nicotinamidase',
      new: false,
      smiles: '',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 0,
      nb_rxns_using: 0,
    },
    'RXC-11343348': {
      name: 'potassium permanganate',
      new: false,
      smiles: 'O.O.O.[K+].[Mn].[OH-]',
      inchi: '1S/K.Mn.4O/q+1;;;;;-1',
      inchi_key: 'VZJVWSHVAAUDKD-UHFFFAOYSA-N',
      mol_cost: 0.0,
      mono_weight: 165.94402,
      nb_rxns_producing: 29,
      nb_rxns_using: 1131,
    },
    'CNEW-16667400': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)Br)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 4,
    },
    'CNEW-16667403': {
      name: '',
      new: true,
      smiles: 'CC(C)C[C@@H](NC(=O)[C@@H](Cc1ccccc1)NC(=O)Cl)B(O)O',
      inchi: '',
      inchi_key: '',
      mol_cost: 0.0,
      mono_weight: 0.0,
      nb_rxns_producing: 22,
      nb_rxns_using: 4,
    },
  },
  rxns: {
    'RNEW-benzylic oxidation-16962925': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667444'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.98997056]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98997056]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-15.0, 12.5, 40.0],
              times: [2.0, 25.0, 48.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98997056]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 50.0, 80.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98997056]],
          conditions: [
            {
              solvents: ['RXC-3587155', 'RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 50.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98997056]],
          conditions: [
            {
              solvents: ['RXC-506007'],
              reagents: [],
              catalysts: [],
              temps: [5.0, 57.5, 110.0],
              times: [0.5, 14.25, 28.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-alkene to alkane-16963221': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667131'],
      products: ['CNEW-16667444'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667444', 0.98995405]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: [],
              catalysts: ['RXC-19273435'],
              temps: [20.0, 35.0, 50.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.98995405]],
          conditions: [
            {
              solvents: ['RXC-1718733'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 40.0, 60.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.98995405]],
          conditions: [
            {
              solvents: ['RXC-506104'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 22.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.98995405]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [-78.0, -14.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.98995405]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.75, 13.875, 27.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16964652': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667831', 'CNEW-16667195'],
      products: ['CNEW-16667131'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102551', 'RXC-635760', 'RXC-605365'],
              reagents: ['RXC-2328698', 'RXC-3587154'],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
            {
              solvents: ['RXC-19273435', 'RXC-19290122'],
              reagents: ['RXC-907068', 'RXC-7014199'],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963601': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-907068', 'RXC-7014199'],
      products: ['CNEW-16667831'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667831', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667831', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667831', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667831', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667831', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-8865420': {
      costStages: 8.0e-2,
      new: false,
      comment: '',
      reactants: ['RXC-110008'],
      products: ['RXC-907068'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-11382663', 'RXC-3587189'],
              catalysts: [],
              temps: [140.0],
              times: [4.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: ['RXC-3587189'],
              catalysts: [],
              temps: [60.0],
              times: [8.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: ['RXC-3587189'],
              catalysts: [],
              temps: [60.0],
              times: [8.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: ['RXC-3587189'],
              catalysts: [],
              temps: [60.0],
              times: [8.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: ['RXC-103233', 'RXC-3587189'],
              catalysts: [],
              temps: [60.0],
              times: [8.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-3587189'],
              catalysts: ['RXC-3902804', 'RXC-4122946'],
              temps: [150.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-1718733'],
              reagents: ['RXC-3587189'],
              catalysts: ['RXC-11350537'],
              temps: [20.0],
              times: [16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: ['RXC-3587154', 'RXC-3587189'],
              catalysts: ['RXC-4122946'],
              temps: [],
              times: [1.5],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: ['RXC-3587154', 'RXC-3587189'],
              catalysts: ['RXC-4122946'],
              temps: [],
              times: [1.5],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-907068', 1.0]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: ['RXC-3587154', 'RXC-3587189'],
              catalysts: [],
              temps: [],
              times: [1.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-4048902': {
      costStages: 0.0,
      new: false,
      comment: '',
      reactants: ['RXC-2804068'],
      products: ['RXC-7014199'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-7014199', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-alcohol sulfonylation-16967635': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-506297', 'CNEW-16667194'],
      products: ['CNEW-16667195'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667195', 0.98691785]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667195', 0.98691785]],
          conditions: [
            {
              solvents: ['RXC-103233'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.5, 25.25, 49.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667195', 0.98691785]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667195', 0.98691785]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.01667, 18.508335, 36.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667195', 0.98691785]],
          conditions: [
            {
              solvents: ['RXC-1696894'],
              reagents: [],
              catalysts: [],
              temps: [-10.0, 5.0, 20.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16964628': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-907068', 'CNEW-16667158'],
      products: ['CNEW-16667131'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
            {
              solvents: ['RXC-1730800'],
              reagents: ['CNEW-16667400'],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
            {
              solvents: ['RXC-1730800'],
              reagents: ['CNEW-16667403'],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16967474': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667859', 'CNEW-16667195'],
      products: ['CNEW-16667158'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667158', 0.9899759]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667158', 0.9899759]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667158', 0.9899759]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667158', 0.9899759]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667158', 0.9899759]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide synthesis from carboxylic acid-16963289': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-21062592', 'RXC-3587154'],
      products: ['CNEW-16667859'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667859', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667859', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667859', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [2.0, 13.08335, 24.1667],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667859', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667859', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 13.5, 25.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-30064816': {
      costStages: 23.28,
      new: false,
      comment: '',
      reactants: ['RXC-1910408'],
      products: ['RXC-21062592', 'RXC-4362271'],
      iVariation: 0,
      variations: [
        {
          yields: [
            ['RXC-21062592', 0.5],
            ['RXC-4362271', 0.5],
          ],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-17485996', 'RXC-906771'],
              catalysts: [],
              temps: [-10.0, 20.0],
              times: [3.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16964629': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-907068', 'CNEW-16667153'],
      products: ['CNEW-16667131'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16967496': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-7014199', 'CNEW-16667195'],
      products: ['CNEW-16667153'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667153', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667153', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667153', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667153', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667153', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16964630': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-907068', 'CNEW-16667148'],
      products: ['CNEW-16667131'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16967518': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667852', 'CNEW-16667195'],
      products: ['CNEW-16667148'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667148', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667148', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667148', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667148', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667148', 0.9899713]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide synthesis from carboxylic acid-16963299': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-2328698', 'RXC-3587154'],
      products: ['CNEW-16667852'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667852', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667852', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667852', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [2.0, 13.08335, 24.1667],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667852', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667852', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 13.5, 25.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-2173213': {
      costStages: 1.11,
      new: false,
      comment: '',
      reactants: ['RXC-2804068'],
      products: ['RXC-2328698'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
            {
              solvents: ['RXC-3587155', 'RXC-635760'],
              reagents: ['RXC-2804068', 'RXC-3587158'],
              catalysts: [],
              temps: [-5.0, 15.0],
              times: [5.0],
              ph: [],
            },
            {
              solvents: ['RXC-3587155', 'RXC-635760'],
              reagents: ['RXC-906771'],
              catalysts: [],
              temps: [-6.0, 15.0],
              times: [6.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587158', 'RXC-906771'],
              catalysts: [],
              temps: [0.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587158', 'RXC-8128136', 'RXC-906771'],
              catalysts: [],
              temps: [-13.0, 20.0],
              times: [20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: ['RXC-3587155', 'RXC-635760'],
              reagents: ['RXC-11342937', 'RXC-3587158', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: ['RXC-3587155', 'RXC-635760', 'RXC-906771'],
              reagents: ['RXC-11342937', 'RXC-3587158'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587158', 'RXC-8127932', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [3.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554', 'RXC-8128136', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587158', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587158', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-2037554', 'RXC-8128136', 'RXC-906771'],
              catalysts: [],
              temps: [-10.0, 5.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554', 'RXC-8128136', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554', 'RXC-8128136', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: ['RXC-2037554'],
              reagents: ['RXC-8128136', 'RXC-906771'],
              catalysts: [],
              temps: [0.0],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554', 'RXC-8127932', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2328698', 0.927]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587158', 'RXC-8128136', 'RXC-906771'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16964633': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667168', 'RXC-606324'],
      products: ['CNEW-16667131'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667131', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16967566': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-3198805', 'CNEW-16667195'],
      products: ['CNEW-16667168'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667168', 0.9883214]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667168', 0.9883214]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667168', 0.9883214]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667168', 0.9883214]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667168', 0.9883214]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-2183112': {
      costStages: 2.0e-2,
      new: false,
      comment: '',
      reactants: ['RXC-2938915'],
      products: ['RXC-3198805'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-3198805', 0.77]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587154'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-3198805', 0.77]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: ['RXC-3587154'],
              catalysts: [],
              temps: [],
              times: [72.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-3198805', 0.77]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: ['RXC-3587154'],
              catalysts: [],
              temps: [0.0, 20.0],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-9120677': {
      costStages: 1.18,
      new: false,
      comment: '',
      reactants: ['RXC-1098229', 'RXC-2804068'],
      products: ['RXC-2938915'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
            {
              solvents: [],
              reagents: ['RXC-1098229', 'RXC-605303'],
              catalysts: [],
              temps: [0.0],
              times: [0.5],
              ph: [],
            },
            {
              solvents: [],
              reagents: ['RXC-2804068'],
              catalysts: [],
              temps: [],
              times: [3.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1098214'],
              catalysts: [],
              temps: [],
              times: [0.17],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1098595'],
              catalysts: [],
              temps: [20.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1098214'],
              catalysts: [],
              temps: [20.0],
              times: [16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: ['RXC-2349612', 'RXC-8008338'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [70.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1098214'],
              catalysts: [],
              temps: [],
              times: [4.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [-30.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [0.0],
              times: [6.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [5.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [0.0, 25.0],
              times: [18.5],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-2938915', 1.0]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [0.0, 20.0],
              times: [24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-1529131': {
      costStages: 5.58,
      new: false,
      comment: '',
      reactants: ['RXC-105778'],
      products: ['RXC-606324'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-113915', 'RXC-1210120'],
              catalysts: [],
              temps: [],
              times: [24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-113915', 'RXC-1210120'],
              catalysts: [],
              temps: [],
              times: [16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-113915', 'RXC-984320'],
              catalysts: [],
              temps: [],
              times: [24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-105778', 'RXC-113915'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-1708400'],
              catalysts: [],
              temps: [],
              times: [24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: ['RXC-202022'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1098295', 'RXC-113915'],
              catalysts: [],
              temps: [],
              times: [20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-113915', 'RXC-984320'],
              catalysts: [],
              temps: [],
              times: [16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295', 'RXC-506104', 'RXC-635760'],
              reagents: ['RXC-113915', 'RXC-984320'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-113915', 'RXC-984320'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-113915', 'RXC-984320'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: ['RXC-202022'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1098295'],
              reagents: ['RXC-113915', 'RXC-1708400'],
              catalysts: [],
              temps: [90.0],
              times: [16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: ['RXC-202022'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-606324', 0.89]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: ['RXC-202022'],
              catalysts: [],
              temps: [],
              times: [18.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-alkene to alkane-16963220': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667000'],
      products: ['CNEW-16667444'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667444', 0.9899868]],
          conditions: [
            {
              solvents: ['RXC-1098229'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 35.0, 50.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.9899868]],
          conditions: [
            {
              solvents: ['RXC-1718733'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 40.0, 60.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.9899868]],
          conditions: [
            {
              solvents: ['RXC-506104'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 22.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.9899868]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [-78.0, -14.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.9899868]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.75, 13.875, 27.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-dehydration-16964602': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667079'],
      products: ['CNEW-16667000'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667000', 0.9827379]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-20.0, 0.0, 20.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.9827379]],
          conditions: [
            {
              solvents: ['RXC-969212'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 55.0, 90.0],
              times: [0.333333, 7.6666665, 15.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.9827379]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 60.0, 120.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.9827379]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.9827379]],
          conditions: [
            {
              solvents: ['RXC-103233'],
              reagents: [],
              catalysts: [],
              temps: [-5.0, 42.5, 90.0],
              times: [0.25, 12.125, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16967336': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667831', 'CNEW-16667092'],
      products: ['CNEW-16667079'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16967333': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667831', 'CNEW-16667091'],
      products: ['CNEW-16667079'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667079', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide to amine reduction-16964617': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16666990'],
      products: ['CNEW-16667000'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667000', 0.93355]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 32.5, 65.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.93355]],
          conditions: [
            {
              solvents: ['RXC-1696894'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 14.5, 28.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.93355]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 70.0, 120.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.93355]],
          conditions: [
            {
              solvents: ['RXC-102391', 'RXC-1696894'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.5, 22.75, 45.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667000', 0.93355]],
          conditions: [
            {
              solvents: ['RXC-102391', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [-5.0, 27.5, 60.0],
              times: [1.0, 9.5, 18.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-benzylic oxidation-16967401': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667000'],
      products: ['CNEW-16666990'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16666990', 0.9891238]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16666990', 0.9891238]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-15.0, 12.5, 40.0],
              times: [2.0, 25.0, 48.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16666990', 0.9891238]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 50.0, 80.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16666990', 0.9891238]],
          conditions: [
            {
              solvents: ['RXC-3587155', 'RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 50.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16666990', 0.9891238]],
          conditions: [
            {
              solvents: ['RXC-506007'],
              reagents: [],
              catalysts: [],
              temps: [5.0, 57.5, 110.0],
              times: [0.5, 14.25, 28.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16962926': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667419'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.93154496]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.93154496]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16963245': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667816', 'CNEW-16667729'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.98999965]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.98999965]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.98999965]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.98999965]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.98999965]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16962959': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'RXC-7014199'],
      products: ['CNEW-16667816'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-alcohol sulfonylation-16963948': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-506297', 'CNEW-16667727'],
      products: ['CNEW-16667729'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667729', 0.9899538]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667729', 0.9899538]],
          conditions: [
            {
              solvents: ['RXC-103233'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.5, 25.25, 49.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667729', 0.9899538]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667729', 0.9899538]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.01667, 18.508335, 36.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667729', 0.9899538]],
          conditions: [
            {
              solvents: ['RXC-1696894'],
              reagents: [],
              catalysts: [],
              temps: [-10.0, 5.0, 20.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963224': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667445'],
      products: ['CNEW-16667444'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667444', 0.9391706]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667444', 0.9391706]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16964685': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667831', 'CNEW-16667729'],
      products: ['CNEW-16667445'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667445', 0.98999995]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98999995]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98999995]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98999995]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98999995]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-benzylic oxidation-16963256': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667445'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.9899984]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899984]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-15.0, 12.5, 40.0],
              times: [2.0, 25.0, 48.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899984]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 50.0, 80.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899984]],
          conditions: [
            {
              solvents: ['RXC-3587155', 'RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 50.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899984]],
          conditions: [
            {
              solvents: ['RXC-506007'],
              reagents: [],
              catalysts: [],
              temps: [5.0, 57.5, 110.0],
              times: [0.5, 14.25, 28.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963232': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'CNEW-16667566'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16963669': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667859', 'CNEW-16667729'],
      products: ['CNEW-16667566'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667566', 0.98992366]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667566', 0.98992366]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667566', 0.98992366]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667566', 0.98992366]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667566', 0.98992366]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963234': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'CNEW-16667524'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16963722': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667852', 'CNEW-16667729'],
      products: ['CNEW-16667524'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667524', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667524', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667524', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667524', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667524', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963233': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'CNEW-16667545'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16963691': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-7014199', 'CNEW-16667729'],
      products: ['CNEW-16667545'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667545', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667545', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667545', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667545', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667545', 0.9899089]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-friedel crafts acylation-16963237': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-103905', 'CNEW-16667401'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-5.0, 10.0, 25.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-605264'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 43.0, 86.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-1098293'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [1.0, 8.5, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [-78.0, -29.0, 20.0],
              times: [0.25, 8.125, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-507540'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 105.0, 210.0],
              times: [2.0, 11.0, 20.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16963875': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667808', 'CNEW-16667729'],
      products: ['CNEW-16667401'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667401', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide synthesis from carboxylic acid-16963339': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667895', 'RXC-3587154'],
      products: ['CNEW-16667808'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667808', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667808', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667808', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [2.0, 13.08335, 24.1667],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667808', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667808', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 13.5, 25.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide from acid chloride-16963836': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1698092', 'RXC-2804068'],
      products: ['CNEW-16667895'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667895', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 6.25, 12.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667895', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [2.0, 50.0, 98.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667895', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 3.5, 5.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667895', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.5, 36.25, 72.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667895', 0.99]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [10.0, 10.0, 10.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-26486354': {
      costStages: 1.0e-2,
      new: false,
      comment: '',
      reactants: ['RXC-1732543'],
      products: ['RXC-1698092'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-1698092', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1698092', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-friedel crafts acylation-16963236': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-103905', 'CNEW-16667404'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-5.0, 10.0, 25.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-605264'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 43.0, 86.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-1098293'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [1.0, 8.5, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [-78.0, -29.0, 20.0],
              times: [0.25, 8.125, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.9899975]],
          conditions: [
            {
              solvents: ['RXC-507540'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 105.0, 210.0],
              times: [2.0, 11.0, 20.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16963814': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667809', 'CNEW-16667729'],
      products: ['CNEW-16667404'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667404', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.9896562]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide synthesis from carboxylic acid-16963320': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667902', 'RXC-3587154'],
      products: ['CNEW-16667809'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667809', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667809', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667809', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [2.0, 13.08335, 24.1667],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667809', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667809', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 13.5, 25.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide from acid chloride-16963774': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1098367', 'RXC-2804068'],
      products: ['CNEW-16667902'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667902', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 6.25, 12.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667902', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [2.0, 50.0, 98.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667902', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 3.5, 5.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667902', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.5, 36.25, 72.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667902', 0.99]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [10.0, 10.0, 10.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-61316': {
      costStages: 3.0e-2,
      new: false,
      comment: '',
      reactants: ['RXC-1098295'],
      products: ['RXC-1098367'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587155'],
              catalysts: ['RXC-14247131'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587155'],
              catalysts: ['RXC-3587236'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211', 'RXC-19270857'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587155'],
              catalysts: [],
              temps: [250.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-19290122', 'RXC-3587218'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211', 'RXC-8128137'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211', 'RXC-19276987'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211', 'RXC-4360473'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211', 'RXC-4122945'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-19284165'],
              catalysts: [],
              temps: [900.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211', 'RXC-8973685'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-19286924', 'RXC-2037554'],
              catalysts: [],
              temps: [150.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554', 'RXC-3535376'],
              catalysts: [],
              temps: [78.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554', 'RXC-3535376'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211', 'RXC-4360473'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-13320054', 'RXC-2037554'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-13320054', 'RXC-19273435', 'RXC-2037554'],
              catalysts: [],
              temps: [80.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-4921393'],
              catalysts: [],
              temps: [45.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587191', 'RXC-8135986'],
              catalysts: [],
              temps: [73.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587155'],
              catalysts: ['RXC-3903066'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587155'],
              catalysts: ['RXC-16501948'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587155'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: ['RXC-3587155'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3902804'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587155'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: ['RXC-16501948'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587155'],
              catalysts: ['RXC-3587236'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587155'],
              catalysts: ['RXC-3903066'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587155'],
              catalysts: ['RXC-3535368'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587155'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1098367', 0.9]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-14497211'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16962903': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'CNEW-16667523'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963042': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667524'],
      products: ['CNEW-16667523'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667523', 0.92984015]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667523', 0.92984015]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16962902': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'CNEW-16667544'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963024': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667545'],
      products: ['CNEW-16667544'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667544', 0.92984015]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667544', 0.92984015]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide to amine reduction-16964688': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667419'],
      products: ['CNEW-16667445'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667445', 0.98994154]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 32.5, 65.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98994154]],
          conditions: [
            {
              solvents: ['RXC-1696894'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 14.5, 28.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98994154]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 70.0, 120.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98994154]],
          conditions: [
            {
              solvents: ['RXC-102391', 'RXC-1696894'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.5, 22.75, 45.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.98994154]],
          conditions: [
            {
              solvents: ['RXC-102391', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [-5.0, 27.5, 60.0],
              times: [1.0, 9.5, 18.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16964663': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-907068', 'CNEW-16667566'],
      products: ['CNEW-16667445'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16964664': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-907068', 'CNEW-16667545'],
      products: ['CNEW-16667445'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16964665': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-907068', 'CNEW-16667524'],
      products: ['CNEW-16667445'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667445', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16962914': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667816', 'CNEW-16667728'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.9899991]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.9899991]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.9899991]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.9899991]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.9899991]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-alcohol sulfonylation-16963144': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-506297', 'CNEW-16667726'],
      products: ['CNEW-16667728'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667728', 0.98999894]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667728', 0.98999894]],
          conditions: [
            {
              solvents: ['RXC-103233'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.5, 25.25, 49.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667728', 0.98999894]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667728', 0.98999894]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.01667, 18.508335, 36.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667728', 0.98999894]],
          conditions: [
            {
              solvents: ['RXC-1696894'],
              reagents: [],
              catalysts: [],
              temps: [-10.0, 5.0, 20.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963947': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667731'],
      products: ['CNEW-16667726'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667726', 0.91928935]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667726', 0.91928935]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16962901': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'CNEW-16667565'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963006': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667566'],
      products: ['CNEW-16667565'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667565', 0.92766297]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667565', 0.92766297]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide from acid chloride-16963255': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112307', 'CNEW-16667608'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 6.25, 12.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [2.0, 50.0, 98.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 3.5, 5.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.5, 36.25, 72.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [10.0, 10.0, 10.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-74058': {
      costStages: 1.93,
      new: false,
      comment: '',
      reactants: ['RXC-112305'],
      products: ['RXC-112307'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-969212'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-969212'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1361988'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-969212'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-2036449'],
              catalysts: [],
              temps: [20.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [20.0],
              times: [3.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [110.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1209273'],
              catalysts: ['RXC-103233'],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1071255', 'RXC-1730800'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-969212'],
              reagents: ['RXC-1209273', 'RXC-605365'],
              catalysts: [],
              temps: [],
              times: [6.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1361988'],
              catalysts: [],
              temps: [],
              times: [5.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1361988'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [20.0],
              times: [20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273', 'RXC-605283'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1361988'],
              catalysts: ['RXC-605365'],
              temps: [0.0, 20.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1361988', 'RXC-605365'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [75.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1209273', 'RXC-605365'],
              catalysts: [],
              temps: [20.0],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: ['RXC-112305', 'RXC-605283'],
              catalysts: [],
              temps: [-10.0],
              times: [8.0e-2],
              ph: [],
            },
            {
              solvents: ['RXC-102391'],
              reagents: ['RXC-385653'],
              catalysts: [],
              temps: [-10.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1731042'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [8.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [20.0, 40.0],
              times: [4.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-605365', 'RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [20.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1361988', 'RXC-605365'],
              catalysts: [],
              temps: [0.0, 20.0],
              times: [5.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: ['RXC-1361988', 'RXC-605365'],
              catalysts: [],
              temps: [20.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [120.0],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-969212'],
              reagents: ['RXC-1209273', 'RXC-605365'],
              catalysts: [],
              temps: [],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [0.0, 80.0],
              times: [3.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [60.0],
              times: [0.33],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [],
              times: [1.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: ['RXC-1209273'],
              catalysts: [],
              temps: [75.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112307', 0.74]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-1209273', 'RXC-605365'],
              catalysts: [],
              temps: [0.0, 80.0],
              times: [6.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16963282': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-3198805', 'CNEW-16667729'],
      products: ['CNEW-16667608'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667608', 0.9848629]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.9848629]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.9848629]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.9848629]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.9848629]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide synthesis from carboxylic acid-16963227': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112305', 'CNEW-16667608'],
      products: ['CNEW-16667419'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [2.0, 13.08335, 24.1667],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667419', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 13.5, 25.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide n alkylation-16962975': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'RXC-7014199'],
      products: ['CNEW-16667816'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667816', 0.98999906]],
          conditions: [
            {
              solvents: ['RXC-102551'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 100.0, 120.0],
              times: [1.5, 12.75, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.98999906]],
          conditions: [
            {
              solvents: ['RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [80.0, 107.5, 135.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.98999906]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [20.0, 85.0, 150.0],
              times: [1.0, 15.5, 30.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.98999906]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 40.0, 80.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.98999906]],
          conditions: [
            {
              solvents: ['RXC-506008'],
              reagents: [],
              catalysts: [],
              temps: [50.0, 90.0, 130.0],
              times: [2.0, 13.0, 24.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963946': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667727'],
      products: ['CNEW-16667726'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667726', 0.9173998]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667726', 0.9173998]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide synthesis from carboxylic acid-16962896': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112305', 'CNEW-16667607'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 12.5, 25.0],
              times: [2.0, 13.08335, 24.1667],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800', 'RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 13.5, 25.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16962954': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667608'],
      products: ['CNEW-16667607'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667607', 0.92760295]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667607', 0.92760295]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amide from acid chloride-16962924': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112307', 'CNEW-16667607'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 6.25, 12.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [2.0, 50.0, 98.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [2.0, 3.5, 5.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 20.0, 40.0],
              times: [0.5, 36.25, 72.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.99]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [10.0, 10.0, 10.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16962960': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-112306', 'CNEW-16667852'],
      products: ['CNEW-16667816'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667816', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963145': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667729'],
      products: ['CNEW-16667728'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667728', 0.9132636]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667728', 0.9132636]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-5414835': {
      costStages: 0.0,
      new: false,
      comment: '',
      reactants: ['RXC-105778'],
      products: ['RXC-112305'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-112305', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963272': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-3587154', 'CNEW-16667566'],
      products: ['CNEW-16667608'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-25467040': {
      costStages: 0.0,
      new: false,
      comment: '',
      reactants: ['RXC-505944'],
      products: ['RXC-103905'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-103905', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963866': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1735345', 'CNEW-16667566'],
      products: ['CNEW-16667401'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-214887': {
      costStages: 0.92,
      new: false,
      comment: '',
      reactants: ['RXC-1732479'],
      products: ['RXC-1735345'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-1735345', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587158'],
              catalysts: [],
              temps: [-80.0],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-5804161': {
      costStages: 0.0,
      new: false,
      comment: '',
      reactants: ['RXC-605257'],
      products: ['RXC-1732479'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-1732479', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963805': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1734893', 'CNEW-16667566'],
      products: ['CNEW-16667404'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-63034': {
      costStages: 0.0,
      new: false,
      comment: '',
      reactants: ['RXC-1098367'],
      products: ['RXC-1734893'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3547232'],
              catalysts: [],
              temps: [400.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587154'],
              catalysts: [],
              temps: [400.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587154'],
              catalysts: [],
              temps: [400.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587154'],
              catalysts: [],
              temps: [500.0, 525.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-4371014'],
              catalysts: [],
              temps: [270.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-1734893', 0.5]],
          conditions: [
            {
              solvents: ['RXC-102391', 'RXC-635760'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-148366': {
      costStages: 0.1,
      new: false,
      comment: '',
      reactants: ['RXC-147982'],
      products: ['RXC-112305'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-112305', 0.85]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-2037554', 'RXC-506007'],
              catalysts: [],
              temps: [],
              times: [2.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112305', 0.85]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [210.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112305', 0.85]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-3587155', 'RXC-635760'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112305', 0.85]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-507540'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963273': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-3587154', 'CNEW-16667545'],
      products: ['CNEW-16667608'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-29080011': {
      costStages: 2.0e-2,
      new: false,
      comment: '',
      reactants: ['RXC-112306'],
      products: ['RXC-112305'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-112305', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-19931735', 'RXC-3587155'],
              catalysts: [],
              temps: [30.0],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-112305', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: ['RXC-11343348', 'RXC-2037554'],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963274': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-3587154', 'CNEW-16667524'],
      products: ['CNEW-16667608'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667608', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-5800728': {
      costStages: 0.0,
      new: false,
      comment: '',
      reactants: ['RXC-505944'],
      products: ['RXC-103905'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-103905', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963806': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1734893', 'CNEW-16667545'],
      products: ['CNEW-16667404'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RXR-5800726': {
      costStages: 0.0,
      new: false,
      comment: '',
      reactants: ['RXC-505944'],
      products: ['RXC-103905'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-103905', 0.5]],
          conditions: [
            {
              solvents: [],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963868': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1735345', 'CNEW-16667524'],
      products: ['CNEW-16667401'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963807': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1734893', 'CNEW-16667524'],
      products: ['CNEW-16667404'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667404', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16963867': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-1735345', 'CNEW-16667545'],
      products: ['CNEW-16667401'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667401', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-friedel crafts acylation-16962906': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-103905', 'CNEW-16667400'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-5.0, 10.0, 25.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-605264'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 43.0, 86.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-1098293'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [1.0, 8.5, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [-78.0, -29.0, 20.0],
              times: [0.25, 8.125, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-507540'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 105.0, 210.0],
              times: [2.0, 11.0, 20.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963104': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667401'],
      products: ['CNEW-16667400'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667400', 0.9212215]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667400', 0.9212215]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-friedel crafts acylation-16962905': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-103905', 'CNEW-16667403'],
      products: ['RXC-11330190'],
      iVariation: 0,
      variations: [
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [-5.0, 10.0, 25.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-605264'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 43.0, 86.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-1098293'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [1.0, 8.5, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [-78.0, -29.0, 20.0],
              times: [0.25, 8.125, 16.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['RXC-11330190', 0.98975044]],
          conditions: [
            {
              solvents: ['RXC-507540'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 105.0, 210.0],
              times: [2.0, 11.0, 20.0],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-desilylation-16963082': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['CNEW-16667404'],
      products: ['CNEW-16667403'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667403', 0.9212215]],
          conditions: [
            {
              solvents: ['RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667403', 0.9212215]],
          conditions: [
            {
              solvents: ['RXC-1696894', 'RXC-3587155'],
              reagents: [],
              catalysts: [],
              temps: [],
              times: [8.33333e-2, 8.33333e-2, 8.33333e-2],
              ph: [],
            },
          ],
        },
      ],
    },
    'RNEW-amine alkylation-16962942': {
      costStages: 0.0,
      new: true,
      comment: '',
      reactants: ['RXC-3587154', 'CNEW-16667523'],
      products: ['CNEW-16667607'],
      iVariation: 0,
      variations: [
        {
          yields: [['CNEW-16667607', 0.99]],
          conditions: [
            {
              solvents: ['RXC-1730800'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 10.0, 20.0],
              times: [0.5, 10.25, 20.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667607', 0.99]],
          conditions: [
            {
              solvents: ['RXC-605365'],
              reagents: [],
              catalysts: [],
              temps: [10.0, 52.5, 95.0],
              times: [1.0, 12.5, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667607', 0.99]],
          conditions: [
            {
              solvents: ['RXC-102391'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 25.0, 50.0],
              times: [0.5, 12.25, 24.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667607', 0.99]],
          conditions: [
            {
              solvents: ['RXC-741857'],
              reagents: [],
              catalysts: [],
              temps: [19.0, 52.0, 85.0],
              times: [1.0, 23.5, 46.0],
              ph: [],
            },
          ],
        },
        {
          yields: [['CNEW-16667607', 0.99]],
          conditions: [
            {
              solvents: ['RXC-635680'],
              reagents: [],
              catalysts: [],
              temps: [0.0, 30.0, 60.0],
              times: [1.0, 19.75, 38.5],
              ph: [],
            },
          ],
        },
      ],
    },
  },
  strategyRouteIndex: {
    S0R0: {
      name: 'route-1',
      cost: 2.088702e10,
      depth: 5,
      nbrxns: 7,
      nbnewrxns: 5,
      fullroute: false,
      cost_solvents: 2.9278483,
      cost_reagents: 8.430128,
      cost_catalysts: 15.784444,
      cost_solvent_exchanges: 0.0,
      feedstocks: [
        {
          cid: 'RXC-110008',
          name: '2-pyrazine carbonitrile',
          purchasable: true,
        },
        {
          cid: 'RXC-2804068',
          name: 'D-(R)-phenylalanine',
          purchasable: true,
        },
        {
          cid: 'RXC-506297',
          name: 'methanesulfonyl chloride',
          purchasable: true,
        },
        {
          cid: 'CNEW-16667194',
          name: '',
          purchasable: false,
        },
      ],
      reagents: ['RXC-11382663', 'RXC-3587189', 'RXC-103233', 'RXC-3587154'],
      solvents: ['RXC-635760', 'RXC-102551', 'RXC-1718733', 'RXC-1098229'],
      catalysts: ['RXC-3902804', 'RXC-4122946', 'RXC-11350537'],
      route: [
        {
          rxn_id: 'RXR-8865420',
          left_primaries: ['RXC-110008'],
          right_primaries: ['RXC-907068'],
        },
        {
          rxn_id: 'RXR-4048902',
          left_primaries: ['RXC-2804068'],
          right_primaries: ['RXC-7014199'],
        },
        {
          rxn_id: 'RNEW-amine alkylation-16963601',
          left_primaries: ['RXC-907068', 'RXC-7014199'],
          right_primaries: ['CNEW-16667831'],
        },
        {
          rxn_id: 'RNEW-alcohol sulfonylation-16967635',
          left_primaries: ['RXC-506297', 'CNEW-16667194'],
          right_primaries: ['CNEW-16667195'],
        },
        {
          rxn_id: 'RNEW-amide n alkylation-16964652',
          left_primaries: ['CNEW-16667831', 'CNEW-16667195'],
          right_primaries: ['CNEW-16667131'],
        },
        {
          rxn_id: 'RNEW-alkene to alkane-16963221',
          left_primaries: ['CNEW-16667131'],
          right_primaries: ['CNEW-16667444'],
        },
        {
          rxn_id: 'RNEW-benzylic oxidation-16962925',
          left_primaries: ['CNEW-16667444'],
          right_primaries: ['RXC-11330190'],
        },
      ],
      route_tree: {
        prod_id: 'RXC-11330190',
        cost: 2.088702e10,
        yield: 0.98997056,
        rxn_id: 'RNEW-benzylic oxidation-16962925',
        branches: [
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0677534e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            branches: [
              {
                prod_id: 'CNEW-16667131',
                cost: 2.046981e10,
                yield: 0.99,
                rxn_id: 'RNEW-amide n alkylation-16964652',
                branches: [
                  {
                    prod_id: 'CNEW-16667831',
                    cost: 190.24661,
                    yield: 0.99,
                    rxn_id: 'RNEW-amine alkylation-16963601',
                    branches: [
                      {
                        prod_id: 'RXC-907068',
                        cost: 65.41843,
                        yield: 1.0,
                        rxn_id: 'RXR-8865420',
                        branches: [
                          {
                            feedstock: 'RXC-110008',
                            cost: 15.338424,
                            purchasable: true,
                          },
                        ],
                      },
                      {
                        prod_id: 'RXC-7014199',
                        cost: 73.42573,
                        yield: 0.5,
                        rxn_id: 'RXR-4048902',
                        branches: [
                          {
                            feedstock: 'RXC-2804068',
                            cost: 11.712864,
                            purchasable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    prod_id: 'CNEW-16667195',
                    cost: 2.0265112e10,
                    yield: 0.98691785,
                    rxn_id: 'RNEW-alcohol sulfonylation-16967635',
                    branches: [
                      {
                        feedstock: 'RXC-506297',
                        cost: 3.7410412,
                        purchasable: true,
                      },
                      {
                        feedstock: 'CNEW-16667194',
                        cost: 2.0e10,
                        purchasable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      route_segments: [
        [
          {
            prod_id: 'CNEW-16667195',
            cost: 2.0265112e10,
            yield: 0.98691785,
            rxn_id: 'RNEW-alcohol sulfonylation-16967635',
            feedstockIds: [],
            branchIds: ['RXC-506297', 'CNEW-16667194'],
          },
        ],
        [
          {
            prod_id: 'RXC-7014199',
            cost: 73.42573,
            yield: 0.5,
            rxn_id: 'RXR-4048902',
            feedstockIds: [],
            branchIds: ['RXC-2804068'],
          },
        ],
        [
          {
            prod_id: 'RXC-907068',
            cost: 65.41843,
            yield: 1.0,
            rxn_id: 'RXR-8865420',
            feedstockIds: ['RXC-110008'],
            branchIds: [],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667831',
            cost: 190.24661,
            yield: 0.99,
            rxn_id: 'RNEW-amine alkylation-16963601',
            feedstockIds: [],
            branchIds: ['RXC-907068', 'RXC-7014199'],
          },
        ],
        [
          {
            prod_id: 'CNEW-16667131',
            cost: 2.046981e10,
            yield: 0.99,
            rxn_id: 'RNEW-amide n alkylation-16964652',
            feedstockIds: [],
            branchIds: ['CNEW-16667831', 'CNEW-16667195'],
          },
          {
            prod_id: 'CNEW-16667444',
            cost: 2.0677534e10,
            yield: 0.98995405,
            rxn_id: 'RNEW-alkene to alkane-16963221',
            feedstockIds: [],
            branchIds: [],
          },
          {
            prod_id: 'RXC-11330190',
            cost: 2.088702e10,
            yield: 0.98997056,
            rxn_id: 'RNEW-benzylic oxidation-16962925',
            feedstockIds: [],
            branchIds: [],
          },
        ],
      ],
    },
  },
};

export const PROJECT_TYPES = {
  OPTIMIZATION: 'Optimization',
  PRODUCTION: 'Production',
  LIBRARY_GENERATION: 'Library generation',
};

export const SYNJET_PROCESS_TYPES = {
  OPTIMIZATION: 'Optimization',
  SCREENING: 'Screening',
};

export const SYNJET_PRO_PROCESS_TYPES = {
  ...SYNJET_PROCESS_TYPES,
  LIBRARY_GENERATION: 'Library generation',
};

export const SOLVENT_TYPES = {
  LIQUID: 'Liquid',
  SOLID: 'Solid',
};

export const SOLVENT_TYPES_LABELS = {
  LIQUID: 'Solution',
  SOLID: 'Solid',
};

export const SOLVENT_TYPES_OPTIONS = [
  {
    value: SOLVENT_TYPES.LIQUID,
    label: SOLVENT_TYPES_LABELS.LIQUID,
  },
  {
    value: SOLVENT_TYPES.SOLID,
    label: SOLVENT_TYPES_LABELS.SOLID,
  },
];

export const MEASUREMENT_OPTIONS = {
  times: {
    value: 'times',
    label: 't',
  },
  temperatures: {
    value: 'temperatures',
    label: 'T',
  },
};

export const COMPOUND_VARIATY = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
  19: 'T',
  20: 'U',
  21: 'V',
  22: 'W',
  23: 'X',
  24: 'Y',
  25: 'Z',
};

export const PRIORITIES = {
  2: 'High',
  1: 'Medium',
  0: 'Low',
};

export const EXPERIMENT_STATUSES = {
  Draft: {
    label: 'Draft',
    value: 'Draft',
  },
  Completed: {
    label: 'Completed',
    value: 'Completed',
  },
  Finalization: {
    label: 'Finalization',
    value: 'Finalization',
  },
  Paused: {
    label: 'Paused',
    value: 'Paused',
  },
  InProgress: {
    label: 'In progress',
    value: 'In Progress',
  },
  Inpreparation: {
    label: 'In preparation',
    value: 'In Preparation',
  },
  InQueue: {
    label: 'In queue',
    value: 'In Queue',
  },
  Submitted: {
    label: 'Submitted',
    value: 'Submitted',
  },
  PendingFinalization: {
    label: 'Pending Finalization',
    value: 'Pending Finalization',
  },
};

export const statuses_execution_scheduled = [
  'Completed',
  'Finalization',
  'Pending Finalization',
  'Paused',
  'In Progress',
  'In Preparation',
  'In Queue',
];

export const OPTIONS_SORTER = [
  { label: 'Recently submitted first', value: 'Recently' },
  { label: 'Old submitted first', value: 'Old' },
  { label: 'High priority first', value: 'High priority' },
  { label: 'Low priority first', value: 'Low priority' },
];

export const OPTIONS_SORTER_MAINTENANCE = [
  { label: 'Recent first', value: 'recently' },
  { label: 'Old first', value: 'old' },
];

export const high_priority = 'High priority';
export const old = 'Old';
export const low_priority = 'Low priority';
export const new_sub = 'Recently';

export const statuses_execution = ['Finalization', 'Pending Finalization', 'Paused', 'In Progress', 'In Preparation'];
export const steps_synjet = [
  {
    label: '1-Step',
    value: '1',
  },
  { label: '2-Step (1st step fixed)', value: '2' },
];
export const steps_synjet_pro = [
  {
    label: '1-Step',
    value: '1',
  },
  { label: '2-Step', value: '2' },
];
export const steps_numerals = [
  { label: '1st Step', value: '1' },
  { label: '2nd Step', value: '2' },
];
export const LINKS_LIMIT = 5;

export const TEMPERATURE_LIMITS = {
  MAX: 180,
  MIN: 20,
};

export const TEMPERATURE_LIMITS_PRO = {
  MAX: 200,
  MIN: 20,
};

export const VOLUME_LIMITS = {
  MAX: 40,
  MIN: 34,
};

export const VOLUME_LIMITS_PRO = {
  MAX: 1500,
  MIN: 500,
};

export const SYNJET_PROCESS_STEPS = {
  FIXED: 'FIXED',
  VARIABLE: 'VARIABLE',
};

export const WEBSOCKET_HOST = process.env.NODE_ENV === 'development' ? 'dev.core.cse.sri.com' : window.location.host;

export const ORIGIN = process.env.NODE_ENV === 'development' ? 'https://dev.core.cse.sri.com' : window.location.origin;

export const MAX_FILES_TO_ATTACH_NUMBER = 10;

export const FILE_TYPES = {
  purification: null,
  lcms: 'LC-MS',
  _1hnmr: '1H NMR',
  _13cnmr: '13C NMR',
  'Offline LC-MS': 'Offline LC-MS',
  'Mass spectrum': 'Mass spectrum',
  'UV-vis': 'UV-vis',
  'Reaction attachment': 'Reaction attachment',
};

export const FILE_TYPES_SJPRO = {
  'Offline LC-MS': 'Offline LC-MS',
  'Reaction attachment': 'Reaction attachment',
  purification: 'Purification results',
};

export const FILE_TYPES_ARRAY_AUTOSYN = ['1H NMR', '13C NMR', 'LC-MS'];
export const FILE_TYPES_ARRAY_SYNJET = ['Offline LC-MS', 'Mass spectrum', 'UV-vis'];
export const FILE_TYPES_ARRAY_SYNJET_PRO = ['Offline LC-MS'];
export const DEVICES = {
  AUTOSYN: 'AutoSyn',
  SYNJET: 'SynJet',
  SYNJETPRO: 'SynJet Pro',
  LAB: 'Lab',
};

export const PROCESS_TYPES = {
  AutoSyn: { key: 'AutoSyn', path: '/autosyn', device: DEVICES.AUTOSYN },
  SynJet: { key: 'SynJet', path: '/synjet', device: DEVICES.SYNJET },
  SynJetPro: { key: 'SynJetPro', path: '/synjet_pro', device: DEVICES.SYNJETPRO },
  Lab: { key: 'Lab', path: '/lab', device: DEVICES.LAB },
};

export const colorsCharts = [
  '#20A89A',
  '#FF8A00',
  '#CC00FF',
  '#FFF733',
  '#3582E5',
  '#EF5D80',
  '#84FFCB',
  '#FFCC33',
  '#BF388B',
  '#FFA77B',
  '#489ED2',
  '#F80196',
  '#94BE1D',
  '#CC6600',
  '#781F82',
  '#FF1A45',
  '#346503',
  '#6EE0FF',
  '#660000',
  '#DCFFA2',
  '#717171',
  '#FCA9AE',
  '#431070',
  '#CA0808',
  '#999900',
  '#6633FF',
  '#FF5C00',
  '#00256C',
  '#D48484',
  '#0033FF',
];

export const TREE_ELEMENT_TYPES = {
  process: {
    key: 'process',
    label: 'Process',
  },
  experiment: {
    key: 'experiment',
    label: 'Experiment',
  },
  route: {
    key: 'route',
    label: 'Route',
  },
};

export const INIT_PROCESSES_SORTING = { columnKey: 'updatedAt', order: 'descend' };

export const PROCESS_BUILDER_ERROR =
  'You do not have permission to access the page. Please contact your System Administrator to request access';
