import React from 'react';
import { formatSeconds, formatSecondsToMinsHours } from '../../../utils';
import cn from 'classnames';
import { Tooltip } from 'antd';

export const Formula = ({
  value,
  unit,
  name,
  time = false,
  error,
  errorValueToDisplay,
  manualPump = false,
  manualReactor = false,
}) => {
  var formatedSeconds = time ? formatSeconds(error ? errorValueToDisplay : value) : {};
  if (time && manualReactor) {
    formatedSeconds = formatSecondsToMinsHours(value);
  }

  const displayName = typeof name === 'function' ? name() : name;

  let displayValue = time ? (
    <>
      {!!formatedSeconds.hours && (
        <>
          <b>{formatedSeconds.hours}</b> h
        </>
      )}
      {!!formatedSeconds.min && (
        <>
          <b> {formatedSeconds.min}</b> min
        </>
      )}
      {(!!formatedSeconds.sec || !formatedSeconds.min) && (
        <>
          <b> {parseFloat(formatedSeconds.sec).toFixed(0)}</b> sec
        </>
      )}
    </>
  ) : (
    <>
      <b>{(Math.round((error ? errorValueToDisplay : value) * 1000) / 1000).toFixed(3)}</b> {unit}
    </>
  );

  if (manualPump)
    displayValue = (
      <>
        {value.equivalents && (
          <>
            <b>{value.equivalents}</b> equiv,
          </>
        )}
        {value.moles && (
          <>
            <b>{value.moles}</b> {unit}
          </>
        )}
        {!value.equivalents && !value.moles && value.volume && (
          <>
            <b>{value.volume}</b> ml
          </>
        )}
      </>
    );

  return (
    <div className={cn('formula', { 'formula-error': error })}>
      {displayName} {!manualPump && '='}{' '}
      {error ? (
        <Tooltip title={value}>
          <span>{displayValue}</span>
        </Tooltip>
      ) : (
        displayValue
      )}
    </div>
  );
};
