import React, { useEffect, useState } from 'react';
import './style.scss';
import { StatusLabel, openNotification } from '../../Common';
import { ExperimentTimer } from './ExperimentTimer';
import { ExperimentSummary } from './ExperimentSummary';
import { WebsocketService } from 'utils/service/WebSocketService';
import {
  unsubscribeFromConnectivityChange,
  subscribeToConnectivityChange,
} from '../../../store/experiment/experiment.actions';
import { useDispatch } from 'react-redux';

export const ExperimentProgress = ({ experiment, allReactors, updateExperimentInfoOnError, getExperimentData }) => {
  const dispatch = useDispatch();
  const [actualTime, setActualTime] = useState(new Date());
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [subject, setSubject] = useState(null);
  const [connectivitySubject, setConnectivitySubject] = useState(null);

  useEffect(() => {
    if (experiment?.timeSlot?.device?.name) {
      if (connectivitySubject) {
        dispatch(unsubscribeFromConnectivityChange());
      }
      const conSubject = dispatch(subscribeToConnectivityChange(experiment.timeSlot.device.name));
      setConnectivitySubject(conSubject);
    }

    return () => {
      dispatch(unsubscribeFromConnectivityChange());
    };
  }, [experiment?.timeSlot?.device?.name]);

  useEffect(() => {
    if (experiment?.uuid) {
      setEstimatedTime(experiment?.totalTime);
      if (experiment?.execution?.startedAt) setActualTime(experiment?.execution?.startedAt);
      if (subject) subject.unsubscribe();
      const newSubject = new WebsocketService(`/experiment-execution/${experiment?.uuid}/`);
      setSubject(newSubject);
    }
    return () => {
      if (subject) subject.unsubscribe();
      setSubject(null);
    };
  }, [experiment?.uuid]);

  useEffect(() => {
    if (subject) subject.subscribe(onErrorHandler);
  }, [subject]);

  const onErrorHandler = message => {
    if (message.type === 'runtime_error') {
      openNotification('', 'Experiment execution was terminated by Runtime error occurred');
      updateExperimentInfoOnError();
    }
    // update experiment info
    if (message.experiment_status && experiment.status !== message.experiment_status) {
      updateExperimentInfoOnError();
    }

    if (message.type === 'execution_message') {
      openNotification(message.message, '', 0);
    }
  };
  return (
    <div className="experiment-progress">
      <div className="experiment-progress__header">
        <div className="experiment-progress__header__title">Experiment {experiment?.name}</div>
        <div className="experiment-progress__header__rigth-content">
          <ExperimentTimer
            startTime={experiment?.execution?.startedAt}
            actualTime={actualTime}
            estimatedTime={estimatedTime}
            setActualTime={setActualTime}
          />
          <StatusLabel type="success">{experiment?.status}</StatusLabel>
        </div>
      </div>
      <ExperimentSummary
        experiment={experiment}
        allReactors={allReactors}
        estimatedTime={estimatedTime}
        getExperimentData={getExperimentData}
      />
    </div>
  );
};
