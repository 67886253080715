import './styles.scss';

import React, { useMemo } from 'react';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { getData, getError } from 'store/slices/extensibleTableFieldsGrid/getters';

import { ButtonGroupBox, IconButtonWithTooltip, SpaceHorizontal, TextBody12, TextBody16 } from '@atoms';
import { FilterFactoryItem, HeaderAction, ScrollBox } from '@molecules';
import { PopoverListWithSearch } from '@organisms';

import { Button, Search } from '../../../components';
import { ReactComponent as PlusSvg } from '../../../dist/images/plus.svg';
import { PREDICATES_BY_TYPE } from './constants';
import {
  FILTER_COMPONENTS_BASED_ON_FIELD_TYPES,
  filterComponentForEmptyAndIsNotEmptyPredicate,
} from './filterComponentsBasedOnFieldsTypes';

export const ExperimentsSavedFilters = ({
  className,
  filters,
  addFilter,
  updateFilterPredicate,
  updateFilterValue,
  removeFilter,
  search,
  onChangeSearch,
  clearFilters,
  resetFilters,
  isFiltersLoaded,
}) => {
  const tableFieldsGrid = useSelector(getData);
  const tableFieldsGridErrorFetched = useSelector(getError);

  const fieldOptions = useMemo(
    () =>
      tableFieldsGrid
        .filter(field => !field.archived)
        .map(el => ({
          value: el,
          label: el.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [tableFieldsGrid]
  );

  const onSelectField = (value, selected) => (selected ? removeFilter(value.uuid) : addFilter(value));

  const fieldSelectViewProps = {
    title: 'Filter column',
    options: fieldOptions,
    onClick: onSelectField,
    selectedItems: filters,
    searchPlaceholder: 'Search for column filters',
  };

  const showResetButton = useMemo(
    () =>
      !!filters.filter(
        ({ predicate, dataType, value }) => predicate.key !== PREDICATES_BY_TYPE[dataType][0].key || value !== undefined
      ).length,
    [filters]
  );

  const filtersList = useMemo(
    () => (
      <FiltersList
        {...{
          filters,
          updateFilterPredicate,
          updateFilterValue,
          removeFilter,
          fieldSelectViewProps,
        }}
      />
    ),
    [filters, fieldSelectViewProps]
  );

  if (tableFieldsGridErrorFetched) {
    return <></>;
  }
  if (!isFiltersLoaded) {
    return (
      <ScrollBox
        className={cn('experiment-saved-filters', className)}
        body={
          <div className="experiment-saved-filters_loader">
            <Skeleton.Input active block />
            {Array.from(Array(30).keys()).map(() => (
              <Skeleton.Input active block />
            ))}
          </div>
        }
      />
    );
  }

  return (
    <ScrollBox
      className={cn('experiment-saved-filters', className)}
      body={filtersList}
      header={
        <>
          <HeaderAction
            label="Filters"
            action={
              <PopoverListWithSearch
                listProps={{
                  title: 'Saved filters',
                  itemActions: (
                    <ButtonGroupBox>
                      <IconButtonWithTooltip icon={<EditOutlined />} tooltip="Edit filter" />
                      <IconButtonWithTooltip icon={<DeleteOutlined />} tooltip="Delete filter" />
                    </ButtonGroupBox>
                  ),
                }}
              >
                {/* <IconButtonWithTooltip icon={<ListSvg />} tooltip="Filter List" /> */}
              </PopoverListWithSearch>
            }
          />
          <SpaceHorizontal multiplier="x5" />

          <Search
            regex={/[^\w\d\s!?#$%&*()@.:;|/\-\\]+/g}
            placeholder="Search"
            value={search}
            onChange={onChangeSearch}
            button={false}
          />
          <SpaceHorizontal multiplier="x12" />
          <HeaderAction
            title={<TextBody16>Additional filters</TextBody16>}
            action={
              <PopoverListWithSearch listProps={fieldSelectViewProps}>
                <IconButtonWithTooltip icon={<PlusSvg />} tooltip="Add filter" />
              </PopoverListWithSearch>
            }
          />
        </>
      }
      actions={
        <>
          {/* <Button type="secondary" onClick={() => {}}> */}
          {/*  Save filters */}
          {/* </Button> */}
          {showResetButton ? (
            <Button type="secondary" onClick={resetFilters}>
              Reset
            </Button>
          ) : (
            <div />
          )}
          <Button type="secondary" onClick={clearFilters}>
            Clear
          </Button>
        </>
      }
    />
  );
};

const FiltersList = ({ filters, updateFilterPredicate, updateFilterValue, removeFilter, fieldSelectViewProps }) => {
  const renderFilterItemComponent = ({ dataType, value, uuid, availableOptions, predicate }) => {
    const defaultProperties = {
      predicate: predicate.key,
      onChange: updateFilterValue(uuid),
      value,
      options: availableOptions,
      dataType,
    };
    let Component = filterComponentForEmptyAndIsNotEmptyPredicate(predicate);
    if (Component) {
      return <Component {...defaultProperties} />;
    }
    Component = FILTER_COMPONENTS_BASED_ON_FIELD_TYPES[dataType];

    return <Component {...defaultProperties} />;
  };

  return filters.length ? (
    <div>
      {filters.map(({ name, ...filterData }) => (
        <FilterFactoryItem
          key={filterData.uuid}
          predicates={PREDICATES_BY_TYPE[filterData.dataType]}
          label={name}
          updateFilterPredicate={updateFilterPredicate}
          removeFilter={removeFilter}
          {...filterData}
        >
          {renderFilterItemComponent}
        </FilterFactoryItem>
      ))}
    </div>
  ) : (
    <div className="experiment-saved-filters_empty-state">
      <TextBody12>Here you can add additional filters </TextBody12>
      <PopoverListWithSearch listProps={fieldSelectViewProps}>
        <Button secondary> Add filter</Button>
      </PopoverListWithSearch>
    </div>
  );
};
