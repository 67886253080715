import React, { useMemo } from 'react';
import FilledBG from '../../../../../../dist/images/FilledBig.svg';
import NotUsedBG from '../../../../../../dist/images/NotUsedBig.svg';
import './style.scss';
import { Popover } from '../../../../../Common';
import BalloonPopover from './BalloonPopover';

const LmBalloon = ({ number, size, isFilled = false, ...balloonArgs }) => {
  const style = useMemo(
    () => ({
      backgroundImage: `url(${isFilled ? FilledBG : NotUsedBG})`,
      backgroundSize: 'cover',
    }),
    [isFilled]
  );

  const withPopover = children => (
    <Popover
      content={<BalloonPopover {...balloonArgs} />}
      trigger="hover"
      placement="topLeft"
      overlayClassName="balloon-popover-wrapper"
    >
      {children}
    </Popover>
  );

  const childElement = useMemo(
    () => (
      <div className={`lm-balloon lm-balloon-${size}`} style={style}>
        {number}
      </div>
    ),
    [number, isFilled]
  );

  return isFilled ? withPopover(childElement) : childElement;
};

export default LmBalloon;
