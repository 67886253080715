import React from 'react';
import cn from 'classnames';

export const ParrShaker = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#20A89A" />
      <g clip-path="url(#clip0)">
        <path
          d="M16.7143 38.7409C17.0354 39.0621 17.5478 39.0852 17.8966 38.796L32.4681 26.7133C32.5235 26.6673 33.0802 26.1046 33.7714 25.4154C35.7064 23.4805 36.4268 20.7842 35.9336 18.2819L38.7426 15.4729C39.0858 15.1297 39.0858 14.5733 38.7426 14.2301L33.7714 9.2589C33.4283 8.9157 32.8719 8.9157 32.5286 9.2589L29.7196 12.0679C27.2174 11.5746 24.5211 12.2952 22.5861 14.2301C22.003 14.8143 21.3318 15.4809 21.2884 15.5332L9.20242 30.1016C8.91271 30.4508 8.93656 30.9632 9.25738 31.2841L16.7143 38.7409ZM20.1086 34.6784L13.3206 27.8903L22.0253 17.3977L30.6036 25.9761L20.1086 34.6784ZM33.15 11.1231L36.8784 14.8516L35.3157 16.4143C34.9392 15.624 34.4248 14.8836 33.7714 14.2302C33.118 13.5768 32.3776 13.0624 31.5873 12.6859L33.15 11.1231ZM23.8289 15.4729C26.2275 13.0745 30.1301 13.0745 32.5286 15.4729C34.927 17.8715 34.927 21.7741 32.5286 24.1725L31.9071 24.794C31.4239 24.3108 23.6907 16.5775 23.2075 16.0943L23.8289 15.4729ZM12.1935 29.2489L18.7498 35.8052L17.3909 36.9319L11.0665 30.6074L12.1935 29.2489Z"
          fill="white"
        />
        <path
          d="M21.6791 10.2921C21.5166 10.348 17.6705 11.6891 15.1293 14.2302C12.5881 16.7714 11.247 20.6175 11.1912 20.78C11.0334 21.239 11.2776 21.739 11.7366 21.8967C12.1985 22.0555 12.6966 21.8073 12.8534 21.3513C12.8657 21.3153 14.1151 17.73 16.3721 15.473C18.6128 13.2324 22.1882 11.9759 22.2505 11.9543C22.7094 11.7965 22.9536 11.2965 22.7959 10.8376C22.638 10.3786 22.1381 10.1345 21.6791 10.2921Z"
          fill="white"
        />
        <path
          d="M37.1646 25.2063C36.706 25.0483 36.2059 25.2921 36.0476 25.7507C36.0351 25.7867 34.7776 29.3805 32.5287 31.6295C30.2797 33.8785 26.6859 35.136 26.6504 35.1482C26.1914 35.306 25.9472 35.806 26.1049 36.265C26.2619 36.7218 26.7606 36.9688 27.2217 36.8104C27.3841 36.7546 31.2303 35.4135 33.7715 32.8723C36.3126 30.3311 37.6538 26.485 37.7096 26.3225C37.8672 25.8638 37.6232 25.3643 37.1646 25.2063Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="30" height="30" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
