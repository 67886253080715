import React, { useCallback } from 'react';
import { Table } from 'components/Common';
import { getBatchConfigurationTableColumns } from './columns';

export const SJBatchConfigurationTable = ({ data }) => {
  const getColumns = useCallback(() => {
    return getBatchConfigurationTableColumns();
  }, [data]);

  return (
    <Table
      dataSource={data}
      bordered={false}
      /*sidePadding={!previewMode}*/
      columns={getColumns()}
      className="batch-configuration-table"
      rowClassName={record =>
        `${record.rowClassName} ${record.removal_time_delta ? record.deltaClassName : ''} ${
          record.isInPast ? 'in-past' : ''
        }`
      }
    />
  );
};
