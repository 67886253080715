import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { Select, Time, Input, Button, Popup } from '../../../Common';
import { DeleteOutlined } from '@ant-design/icons';
import {
  COMPOUND_ROLE,
  COMPONENT,
  OPERATOR,
  PREDEFINED_RULES,
  PREDEFINED_RULES_PRO,
  SYNJET_INITIAL_RULE,
} from '../../../../constants';
import { formatToHHMM, httpSynMolDB } from '../../../../utils';
import { SmilesSearcher } from '../../../SmilesSearcher';
import { useDispatch } from 'react-redux';
import { updateRules } from '../../../../store/synjet/synjet.actions';
import cn from 'classnames';
import { conditions } from '../../../../store/synjet/synjet.selector';

const emptyRule = {
  compound_id: '',
  compoundRole: '',
  compoundData: '',
  component_id: '',
  component: '',
  operator: '',
  componentValue: '',
  compoundSmiles: '',
  componentSmiles: '',
  predefined: false,
};

const equalOption = [{ label: 'equals to', value: 'equals to' }];

export const ExclusionRules = ({ rulesData, compounds, processType, synjetProcess, editMode, isPro, quenching }) => {
  const [solventOptions, setSolventOptions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [compound, setCompound] = useState({});
  const [deleteRuleConfirmation, openDeleteRuleConfirmation] = useState(false);
  const [indexDelete, setIndexDelete] = useState(null);
  const [rules, setRules] = useState('');
  const dispatch = useDispatch();

  const getSolventOptions = () => {
    return solventOptions.map(compound => ({
      value: compound.compound_name,
      label: compound.compound_name,
    }));
  };

  const predefinedRules = isPro ? PREDEFINED_RULES_PRO : PREDEFINED_RULES;

  useEffect(() => {
    httpSynMolDB(`get_compound_list?tag=solvent&return_compatibility=true`).then(resp => {
      setSolventOptions([{ compound_name: 'None' }, ...resp.compound_list]);
    });
    if (!synjetProcess?.process?.definition) {
      dispatch(updateRules([{ ...SYNJET_INITIAL_RULE }]));
    }
    return () => {
      dispatch(updateRules([SYNJET_INITIAL_RULE]));
    };
  }, []);

  useEffect(() => {
    if (!!rulesData.length) {
      setRules(initialAddingProperty(rulesData));
    }
  }, [rulesData]);

  const prevCompoundsRef = useRef();
  const prevQuenchingRef = useRef();
  useEffect(() => {
    prevCompoundsRef.current = compounds;
    prevQuenchingRef.current = quenching;
  });
  const prevCompounds = prevCompoundsRef.current;
  const prevQuenching = prevQuenchingRef.current;

  useEffect(() => {
    if (editMode) {
      let prevSolvents;
      let changedPredefined = [];
      let currentSolvents;
      let addedSolvent;
      let deletedSolvent;
      currentSolvents = getSolvents(compounds, quenching);
      if (!!prevCompounds || !!prevQuenching) {
        prevSolvents = getSolvents(prevCompounds, prevQuenching);
        if (prevSolvents.length === currentSolvents.length && !!currentSolvents.length) {
          let ch = changedObject(prevSolvents, currentSolvents);
          if (!!ch[0]) {
            ch.forEach(item => {
              let temp = predefinedRules.find(i => {
                return i.compoundData.toLowerCase() === item.compound_name;
              });
              changedPredefined.push(!!temp ? temp : null);
            });
          }
        }
        if (prevSolvents.length - currentSolvents.length > 0) {
          let temp = findDeletedSolvent(prevSolvents, currentSolvents);
          deletedSolvent = predefinedRules.find(i => {
            return i.compoundData.toLowerCase() === temp.compound_name;
          });
        }
        if (currentSolvents.length - prevSolvents.length === 1) {
          addedSolvent = changedObject(prevSolvents, currentSolvents)[1] || currentSolvents[currentSolvents.length - 1];
        }
      }
      if (!!changedPredefined.length || !!addedSolvent || !!deletedSolvent)
        checkExistingCompounds(currentSolvents, changedPredefined, addedSolvent, deletedSolvent);
    } else {
      setRules(initialAddingProperty(rulesData));
    }
  }, [compounds, quenching]);

  const findDeletedSolvent = (prev, current) => {
    let solvent;
    for (let i = 0; i < prev.length; i++) {
      if (!current[i] || prev[i].compound_name !== current[i].compound_name) {
        solvent = prev[i];
        break;
      }
    }
    return solvent;
  };

  const getSolvents = (compounds, quenching) => {
    let solvents = [];
    compounds.forEach(item => {
      for (let key in item.compounds) {
        if (processType === 'Optimization')
          item.compounds[key].forEach(i => {
            !!i.solvents &&
              i.solvents.forEach(solv => {
                solvents.push(solv.solvent);
              });
          });
        else
          item.compounds[key].forEach(i => {
            !!i.compoundsList &&
              i.compoundsList.forEach(el => {
                !!el.solvents &&
                  el.solvents.forEach(solv => {
                    solvents.push(solv.solvent);
                  });
              });
            !!i.solvents &&
              i.solvents.forEach(solv => {
                solvents.push(solv.solvent);
              });
          });
      }
      item.conditions.forEach(i => {
        i.dispensing.normalize && !!i.dispensing.solvent && solvents.push(i.dispensing.solvent);
      });
    });
    if (quenching?.length) {
      quenching.forEach(q => {
        if (q.solvents)
          q.solvents.forEach(solvent => {
            solvents.push(solvent.solvent);
          });
      });
    }
    return solvents;
  };

  const changedObject = (arr1, arr2) => {
    let prevAndCurrent = [];
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].compound_name !== arr2[i].compound_name) {
        prevAndCurrent.push(arr1[i]);
        prevAndCurrent.push(arr2[i]);
      }
    }
    return prevAndCurrent;
  };

  const initialAddingProperty = rules => {
    let obj = [...rules];
    obj = obj.map(i => {
      delete i.predefined;
      let addProperty = addPredefinedProperty(i);
      if (!!addProperty) {
        return { ...i, predefined: true };
      }
      return i;
    });
    return obj;
  };

  const deleteEmptyRules = data => {
    let resultRules = [...data];
    resultRules = resultRules.filter(item => {
      if (!!item) {
        let temp_predefined = item.predefined;
        item.predefined = false;
        delete item.predefined;
        delete emptyRule.predefined;
        delete item.id;
        item = addInitialId(item);
        let equal = shallowEqual(item, emptyRule);
        if (!equal) {
          item.predefined = temp_predefined;
        }
        return !equal;
      }
    });
    return resultRules;
  };

  const checkExistingCompounds = (solvents, changedPredefined, addedSolvent, deletedSolvent) => {
    let newRule;
    let resultRules = [...initialAddingProperty(rulesData)];
    if (!!addedSolvent) {
      newRule = {
        ...predefinedRules.find(i => {
          return i.compoundData.toLowerCase() === addedSolvent.compound_name.toLowerCase();
        }),
      };
    }
    if (!!newRule && (!!newRule.compound_name || !!newRule.component))
      resultRules.push({ ...newRule, component_id: null, predefined: true });
    let keys = Object.keys(SYNJET_INITIAL_RULE);
    let uniqueArray = getUniqueArray(resultRules.flat(Infinity), keys);
    uniqueArray = deleteEmptyRules(uniqueArray);

    let filter;
    if (!!changedPredefined.length) filter = afterUpdateSolvent(uniqueArray, changedPredefined, solvents);
    if (!!deletedSolvent) filter = deleteSolvent(uniqueArray, deletedSolvent, solvents);
    if (!!filter) uniqueArray = filter;
    dispatch(updateRules(uniqueArray));
    setRules(uniqueArray);
  };

  const deleteSolvent = (rules, solvent, solvents) => {
    let updatedRules = rules;
    updatedRules = rules.filter(item => shouldBeDeleted(item, solvent, solvents));
    return updatedRules;
  };

  const afterUpdateSolvent = (rules, changedPredefined, solvents) => {
    if (!!changedPredefined.length) {
      let updatedRules = rules;
      if (!!changedPredefined[0]) {
        //previos value of changed compound
        updatedRules = rules.filter(item => shouldBeDeleted(item, changedPredefined[0], solvents));
      }
      if (!!changedPredefined[1]) {
        //current value of changed compound
        let predefinedExist = checkIfPredefinedExist(rules, changedPredefined[1]);
        if (!!changedPredefined[1] && !predefinedExist)
          updatedRules.push({ ...changedPredefined[1], predefined: true });
      }
      return updatedRules;
    }
  };

  const shouldBeDeleted = (item, solvent, solvents) => {
    if (!!item.compoundRole && item.compoundData)
      return !(
        item.compoundRole.toLowerCase() === 'solvent' &&
        item.compoundData.toLowerCase() === solvent.compoundData.toLowerCase() &&
        item.predefined &&
        !ifUpdatedNotLastPredefined(solvents, solvent)
      );
    else return false;
  };

  const ifUpdatedNotLastPredefined = (solvents, updatedRule) =>
    solvents.some(solvent => updatedRule.compoundData.toLowerCase() === solvent.compound_name.toLowerCase());

  const checkIfPredefinedExist = (rules, newRule) => {
    let b = rules.find(item => {
      newRule.predefined = true;
      return shallowEqual(item, newRule);
    });
    return b;
  };

  const handleSelectChanges = (field, value, index, id, isTime = false, noErrors = false) => {
    let new_rules = [...rules];
    new_rules[index][field] = value;
    if (id) {
      new_rules[index][field === 'compoundData' ? 'compound_id' : 'component_id'] = id.compound_id;
      new_rules[index][field == 'compoundData' ? 'compoundSmiles' : 'componentSmiles'] = id?.smiles;
    }
    if (field === 'component') {
      new_rules[index]['operator'] = 'equals to';
      new_rules[index]['componentValue'] = '';
    }
    if (field === 'compoundData' || field === 'componentValue' || field === 'component') {
      let static_rule = checkIfPredefined(field, value, index, isTime);
      if (!!static_rule.length) {
        new_rules[index] = static_rule.map(i => {
          return { ...i, component_id: '', predefined: true };
        });
      }
    }
    validation(value, index, noErrors);
    new_rules = deleteEmptyRules(new_rules);
    dispatch(updateRules(new_rules.flat(1)));
  };

  const addNewRule = () => {
    let temp = [...rules];
    temp.push({ ...emptyRule });
    let e = errors;
    e[errors.length + 1] = false;
    setErrors(e);
    dispatch(updateRules(temp));
  };
  const validation = (value, index, noError) => {
    let arr = [...errors];
    arr[index] = noError ? false : !value;
    setErrors(arr);
  };

  const checkIfPredefined = (field, value, index, isTime) => {
    let predefinedRule = predefinedRules.filter(i => {
      return (
        !isTime &&
        rules[index].compoundRole !== 'reactant/reagent' &&
        ((i.compoundData.toLowerCase() === value?.toLowerCase() &&
          i.compoundRole === rules[index].compoundRole.toLowerCase()) ||
          (!!rules[index].component &&
            i.component.toLowerCase() === rules[index].component.toLowerCase() &&
            !!rules[index].operator &&
            i.operator.toLowerCase() === rules[index].operator.toLowerCase() &&
            i.componentValue === value) ||
          secondConditionIsSolvent(i, rules[index], value))
      );
    });

    let alreadyExist = false;
    rules.forEach((item, idx) => {
      let temp_predefined1 = item.predefined;
      let temp_predefined2 = rules[index].predefined;
      delete item.predefined;
      delete rules[index].predefined;
      delete item.id;
      delete rules[index].id;
      predefinedRule.forEach(rule => {
        let i = secondConditionIsSolvent(rule, item, item.componentValue);
        if ((shallowEqual(item, rule) || i) && idx !== index) {
          alreadyExist = true;
        }
      });
      item.predefined = temp_predefined1;
      rules[index].predefined = temp_predefined2;
    });

    return !alreadyExist && predefinedRule;
  };

  const secondConditionIsSolvent = (predefinedRule, currentRule, value) => {
    if (!!currentRule.component)
      return (
        predefinedRule.compoundRole.toLowerCase() === currentRule.component.toLowerCase() &&
        predefinedRule.compoundData.toLowerCase() === value?.toLowerCase()
      );
    else return;
  };

  function getUniqueArray(arr = [], compareProps = []) {
    let modifiedArray = [];
    if (compareProps.length === 0 && arr.length > 0) compareProps.push(...Object.keys(arr[0]));
    arr.map(item => {
      if (modifiedArray.length === 0) {
        modifiedArray.push(item);
      } else {
        item = addInitialId(item);
        if (
          !modifiedArray.some(item2 => {
            return compareProps.every(eachProps => {
              if (!!item) return item2[eachProps] === item[eachProps];
            });
          })
        ) {
          if (!!item) modifiedArray.push(item);
        }
      }
    });
    return modifiedArray;
  }

  const deleteRule = () => {
    let temp = [...rules];
    temp.splice(indexDelete, 1);
    let t = errors;
    t.splice(indexDelete, 1);
    setErrors(t);
    setRules(temp);
    dispatch(updateRules(temp));
  };

  const findId = name => {
    return solventOptions.find(i => {
      return i.compound_name === name;
    });
  };

  const addPredefinedProperty = rule => {
    delete rule.predefined;
    delete rule.id;
    let y = predefinedRules.find(i => {
      delete i.predefined;
      return shallowEqual(i, rule);
    });
    return y;
  };

  const shallowEqual = (object1, object2) => {
    object1 = addInitialId(object1);
    object2 = addInitialId(object2);
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  };

  const addInitialId = item => {
    if (!item.compound_id) item.compound_id = '';
    if (!item.component_id) item.component_id = '';
    if (!item.componentValue) item.componentValue = '';
    return item;
  };

  return (
    <>
      <div className="rules-container">
        <p className="rules-hint">Exclude vials from the experiment where:</p>
        <div className="rules">
          {!!rules?.length ? (
            rules.map((i, index) =>
              editMode ? (
                <>
                  <div className={cn('rule', { 'error-rule': !!errors[index] })}>
                    <div className="rule-reactor_name">R{index + 1}</div>

                    <div className="rule-condition">
                      <div className="rule-condition_select">
                        <Select
                          options={COMPOUND_ROLE}
                          value={i.compoundRole || 'Select parameter'}
                          onChange={e => {
                            let new_rules = [...rules];
                            new_rules[index].compoundRole = e;
                            dispatch(updateRules(new_rules));
                          }}
                          disabled={i.predefined}
                          onBlur={() => validation(i.compoundRole, index)}
                        />
                      </div>
                      <div className="rule-condition_compare">is equal to</div>
                      {i.compoundRole === COMPOUND_ROLE[1].label ? (
                        <div className="smiles-compound_info">
                          <SmilesSearcher
                            synjet
                            changeSelect={smiles => {
                              handleSelectChanges('compoundData', smiles.compound_name, index, smiles, false, true);
                            }}
                            smilesPlaceholder="Search"
                            data={i.compoundData}
                            handleCompoundChange={() => {
                              handleSelectChanges('compoundData', null, index, false, false, true);
                            }}
                            initialSearchValue={i.compoundData || i?.compoundSmiles}
                            validation={validation}
                            indexForValidation={index}
                          />
                        </div>
                      ) : (
                        <div className="rule-condition_select">
                          <Select
                            options={getSolventOptions()}
                            value={i.compoundData || 'Select compound'}
                            disabled={!i.compoundRole || i.predefined}
                            onChange={e => {
                              handleSelectChanges('compoundData', e, index, findId(e));
                            }}
                            onBlur={() => validation(i.compoundData, index)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="divider">
                      <div className="pill-conditions">and</div>
                    </div>
                    <div className="rule-condition rule-condition_second">
                      <div className="rule-condition_select">
                        <Select
                          options={COMPONENT}
                          value={i.component || 'Select parameter'}
                          disabled={i.predefined}
                          onChange={e => {
                            handleSelectChanges('component', e, index);
                          }}
                          onBlur={() => validation(i.component, index)}
                        />
                      </div>
                      <div className="rule-condition_select rule-operator">
                        <Select
                          options={
                            i.component === COMPONENT[2].label || i.component === COMPONENT[3].label
                              ? equalOption
                              : OPERATOR
                          }
                          disabled={!i.component || i.predefined}
                          value={i.operator || 'is more than'}
                          onChange={e => {
                            handleSelectChanges('operator', e, index);
                          }}
                          onBlur={() => validation(i.operator, index)}
                        />
                      </div>
                      <div className="rule-condition_select">
                        {i.component === COMPONENT[1].label && (
                          <Time
                            disabled={!i.component || !i.operator || i.predefined}
                            value={i.componentValue}
                            onChange={e => handleSelectChanges('componentValue', e, index, null, true)}
                            minuteStep={15}
                            showNow={false}
                            onBlur={() => validation(i.componentValue, index)}
                          />
                        )}
                        {(i.component === COMPONENT[0].label || !i.component) && (
                          <Input
                            type={'number'}
                            integer
                            positive
                            value={i.componentValue || ''}
                            onChange={e => handleSelectChanges('componentValue', e, index, null, true)}
                            disabled={!i.component || !i.operator || i.predefined}
                            onBlur={() => validation(i.componentValue, index)}
                          />
                        )}
                        {i.component === COMPONENT[2].label && (
                          <Select
                            options={getSolventOptions()}
                            value={i.componentValue}
                            disabled={!i.component || !i.operator || i.predefined}
                            onChange={e => handleSelectChanges('componentValue', e, index, findId(e))}
                            onBlur={() => validation(i.componentValue, index)}
                          />
                        )}
                      </div>
                      {i.component === COMPONENT[3].label && (
                        <div className="smiles-compound_info">
                          <SmilesSearcher
                            synjet
                            changeSelect={smiles => {
                              handleSelectChanges('componentValue', smiles?.compound_name, index, smiles, false, true);
                              setCompound({ ...compound, smiles: smiles });
                            }}
                            disabled={i.predefined}
                            smilesPlaceholder="Search"
                            componentId={i.component_id}
                            data={i.componentValue}
                            handleCompoundChange={() =>
                              handleSelectChanges('componentValue', null, index, false, false, true)
                            }
                            initialSearchValue={i.componentValue || i?.componentSmiles}
                            validation={validation}
                            indexForValidation={index}
                          />
                        </div>
                      )}
                    </div>

                    <div className="rule-trash-icon">
                      <DeleteOutlined
                        onClick={() => {
                          setIndexDelete(index);
                          openDeleteRuleConfirmation(true);
                        }}
                      />
                    </div>
                  </div>
                  {!!errors[index] && (
                    <div className="error-text">
                      You have invalid exclusion rules in the list. <br /> Please note: Invalid rules cannot be applied
                      to the process
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="rule">
                    <div className="rule-reactor_name">R{index + 1}</div>
                    <div className="rule-condition condition-view-mode">
                      <div className="half-condition">
                        <div className="bold-title">{i.compoundRole}</div>
                        <div>{(i.compoundData || i?.compoundSmiles) && 'equals to'}</div>
                        <div title={i.compoundData} className="possible-long-name bold-title">
                          {i.compoundData || i?.compoundSmiles}
                        </div>
                      </div>
                      <div className="half-condition half-condition_with-divider">
                        {i.component && (
                          <div className="divider">
                            <div className="pill-conditions">and</div>
                          </div>
                        )}
                        <div className="second-condition bold-title">{i.component}</div>
                        <div>{(!!i.componentValue || i?.componentSmiles) && i.operator}</div>
                        {i.component === 'time' ? (
                          <div className="bold-title">{formatToHHMM(i.componentValue)}</div>
                        ) : (
                          <div title={i.componentValue || i?.componentSmiles} className="possible-long-name bold-title">
                            {i.componentValue || i?.componentSmiles} {i.component === 'temperature' && '°C'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )
            )
          ) : (
            <></>
          )}
        </div>
        <Button onClick={addNewRule}>Add Rule</Button>
      </div>
      <Popup
        open={deleteRuleConfirmation}
        title="Delete "
        handleSubmit={() => {
          openDeleteRuleConfirmation(false);
          deleteRule();
        }}
        handleCancel={() => {
          openDeleteRuleConfirmation(false);
        }}
        textSubmit="Delete"
        loading={false}
      >
        <span>{`Are you sure you want to remove R${indexDelete + 1}?`}</span>
      </Popup>
    </>
  );
};
