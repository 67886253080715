import React from 'react';
import { Sidebar } from '../../Common';
import Notes from '../../Experiment/Notes';
import './style.scss';

export const NotesSidebar = ({ open, onClose, leaveNote, notes, updateNotesList, experimentId }) => {
  return (
    <Sidebar width={528} title="Notes" open={open} showFooter={false} className="notes-sidebar" onClose={onClose}>
      <Notes showDeleteNote notes={notes} updateNotesList={updateNotesList} dataExperiment={experimentId} />
    </Sidebar>
  );
};
