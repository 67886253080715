import React from 'react';
import { Button } from '../../Common';
import './style.scss';
import { compareCurrentDateAndUTC, getHHmm, getHHmmFormat } from '../../../utils/date';

export const MaintenancePreview = ({ unschedule, reschedule, data, premissions }) => {
  return (
    <div className="maintenance-preview">
      <div className="maintenance-preview_header">
        <span>Maintenance</span>
        <span>
          {data.allDay ? (
            'All day'
          ) : (
            <>
              {getHHmmFormat(data.start)} — {getHHmmFormat(data.end)}
            </>
          )}
        </span>
      </div>
      {data.description && (
        <div className="maintenance-preview_content">
          <div className="maintenance-preview_content_title">Description:</div>
          <div
            className="maintenance-preview_content_description"
            dangerouslySetInnerHTML={{ __html: data.description?.replace(/\n/g, '</br>') }}
          />
        </div>
      )}
      <div className="maintenance-preview_footer">
        {premissions.canChangeMaintenance && (
          <Button secondary onClick={reschedule}>
            Update
          </Button>
        )}
        {premissions.canDeleteMaintenance && (
          <Button secondary onClick={unschedule}>
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};
