import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default as SynJetCompoundGroup } from '../SynjetCompoundGroup/SynjeyCompoundGroup';
import { Button } from '../../../Common';
import { selectAbilityAddCompound } from '../../../../store/synjet/synjet.selector';
import { addCompoundGroupToList } from '../../../../store/synjet/synjet.actions';
import './index.scss';

const SynJetCompoundGroupList = ({
  type,
  compoundGroupsList,
  limitingTitle,
  compoundOffset = 0,
  limitingCompoundGroup,
  step,
  viewOnly,
  disableLimiting,
  highlightErrors,
  isLibGen,
}) => {
  const dispatch = useDispatch();
  const isCompoundAddAvailable = useSelector(selectAbilityAddCompound);
  const canAddGroup =
    (isCompoundAddAvailable &&
      compoundGroupsList?.[compoundGroupsList.length - 1]?.compoundsList &&
      !!compoundGroupsList?.[compoundGroupsList.length - 1]?.compoundsList.find(compound => compound.smiles)) ||
    !compoundGroupsList?.length;

  const handleCompoundGroupAdd = () => {
    dispatch(addCompoundGroupToList(`${type}Groups`, step));
  };

  return (
    <div className="synjet-compound-group-list">
      <h3 className="synjet-compound-list__title">{type}</h3>
      {!!compoundGroupsList.length && (
        <>
          {compoundGroupsList.map((compoundGroup, compoundGroupIndex) => (
            <SynJetCompoundGroup
              compoundGroup={compoundGroup}
              key={compoundGroupIndex + type}
              type={type}
              compoundOffset={compoundOffset}
              limitingTitle={limitingTitle}
              step={step}
              limitingCompoundGroup={limitingCompoundGroup}
              isCompoundAddAvailable={isCompoundAddAvailable}
              viewOnly={viewOnly}
              disableLimiting={disableLimiting}
              highlightErrors={highlightErrors}
              isLibGen={isLibGen}
            />
          ))}
        </>
      )}
      {canAddGroup && (
        <Button className="add-btn" onClick={handleCompoundGroupAdd}>
          Add {type} {compoundGroupsList?.length + 1}
        </Button>
      )}
    </div>
  );
};

export default SynJetCompoundGroupList;
