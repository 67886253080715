import React, { useState, useEffect } from 'react';
import { Sidebar, Popup, Select, Input, RadioGroup, Checkbox } from '../../../Common';
import './style.scss';
import {
  COMPOUND_OPTIONS,
  INITIAL_INPUT,
  RADIO_INPUT_OPTIONS,
  INITIAL_ERRORS,
  COMPOUND_VARIATY,
  PROCESS_TYPES,
  PROJECT_TYPES,
} from '../../../../constants';
import { checkIfTrue, httpSynMolDB } from '../../../../utils';
import { SmilesSearcher } from '../../../SmilesSearcher';
import { Confirmations } from './Confirmations';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { calculateSpecificField, optionCalculation } from './inputCalculations';
import { inputValidations } from './inputValidations';

const errorsText = {
  reagent: {
    type: false,
    compound: 'Please select the Compound',
    mw: false,
    solvent: 'Please select the Compound',
    solvent_volume: "'Volume' is a required field",
    mass: '‘Mass’ is a required field',
    moles: "'Moles' is a required field",
    volume: "'Volume' is a required field",
    density: "'Density' is a required field",
    concentration: "'Concentration' is a required field",
    limiting: false,
    equivalents: [false],
  },
  reactant: {
    type: false,
    compound: 'Please select the Compound',
    mw: false,
    solvent: 'Please select the Compound',
    solvent_volume: "'Volume' is a required field",
    mass: '‘Mass’ is a required field',
    moles: "'Moles' is a required field",
    volume: "'Volume' is a required field",
    density: "'Density' is a required field",
    concentration: "'Concentration' is a required field",
    limiting: false,
    equivalents: [false],
  },
  solvent: {
    solvent: 'Please select the Compound',
    solvent_volume: "'Volume' is a required field",
    mw: false,
  },
};

const CAN_CALCULATE = {
  moles: [true],
  mass: [true],
  volume: [true],
  equivalents: [true],
  density: true,
  concentration: true,
};

export const SidebarInput = ({
  open,
  onClose,
  editingData,
  onSaveInput,
  onDelete,
  processType,
  limitingInput,
  stateIndexes,
  variationInput,
  indexOfInput,
  inputWithName,
  selectedInput,
  becameVariable,
}) => {
  const [edited, setEdited] = useState(false);
  const [errors, setErrors] = useState({ ...INITIAL_ERRORS });
  const [cancelConfirmation, openCancelConfirmation] = useState(false);
  const [deleteConfirmation, openDeleteConfirmation] = useState(false);

  const [inputValues, setInputValues] = useState({ ...INITIAL_INPUT });
  const [compound, setCompound] = useState(null);

  const [solventsOptions, setSolventsOptions] = useState([]);
  const [selectedSolvent, setSelectedSolvent] = useState(null);

  const [canCalculate, setCanCalculate] = useState({ ...CAN_CALCULATE });

  const [isVariable, setIsVariable] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (open) {
      if (!!becameVariable) {
        setIsVariable(true);
      } else {
        setIsVariable(false);
      }
      if (!editingData) {
        reset();
      } else {
        getEditingData();
      }
    }
  }, [open]);

  const setVariationEq = props => {
    let arr = [];
    if ((becameVariable && !variationInput) || (!becameVariable && variationInput)) {
      let var1 = variationInput ? variationInput.properties.length : becameVariable.properties.length;
      for (let i = 0; i < var1; i++) {
        arr[i] = null;
      }
      setInputValues({
        ...INITIAL_INPUT,
        reagent: { ...INITIAL_INPUT.reagent, equivalents: [...arr] },
        reactant: { ...INITIAL_INPUT.reagent, equivalents: [...arr] },
        solvent: { ...INITIAL_INPUT.solvent },
        ...props,
      });
    } else {
      setInputValues({
        ...INITIAL_INPUT,
        reagent: { ...INITIAL_INPUT.reagent, equivalents: [null] },
        reactant: { ...INITIAL_INPUT.reagent, equivalents: [null] },
        solvent: { ...INITIAL_INPUT.solvent },
        ...props,
      });
    }
  };

  useEffect(() => {
    if (open) getEditingData();
  }, [editingData]);

  const getEditingData = () => {
    if (!!editingData && (!!editingData.chemical_type || !!editingData.solvent)) {
      setInputValues({
        ...inputValues,
        [editingData.chemical_type]: { ...editingData },
        chemical_type: editingData.chemical_type,
        reagent_type: editingData.reagent_type,
      });
      if (editingData?.solvent) setSelectedSolvent(editingData.solvent);
      setErrors({ ...INITIAL_ERRORS });
    }
    if (!!editingData && processType === PROJECT_TYPES.OPTIMIZATION && editingData?.chemical_type !== 'solvent') {
      let length_for_calculate = Array.apply(0, new Array(editingData.moles.length + 1)).map((i, idx) =>
        idx === editingData.moles.length ? true : false
      );
      setCanCalculate({
        ...canCalculate,
        moles: [...length_for_calculate],
        mass: [...length_for_calculate],
        volume: [...length_for_calculate],
        equivalents: [...length_for_calculate],
      });
    }
  };

  useEffect(() => {
    httpSynMolDB(`get_compound_list?tag=solvent&return_compatibility=true`).then(resp => {
      setSolventsOptions(compareOptions(resp.compound_list, 'compound_name'));
    });
  }, []);

  const compareOptions = (temp, field) => {
    for (let i in temp) {
      temp[i].value = temp[i][field];
      temp[i].label = temp[i][field];
    }
    return temp;
  };

  const reset = () => {
    if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
      setVariationEq();
    } else {
      setInputValues({
        ...INITIAL_INPUT,
      });
    }
    setCanCalculate({
      moles: [true],
      mass: [true],
      volume: [true],
      equivalents: [true],
      density: true,
      concentration: true,
    });
    setErrors({ ...INITIAL_ERRORS });
    setSelectedSolvent(null);
    setCompound(null);
    setEdited(false);
    setIsVariable(false);
  };

  const onSave = () => {
    if (validation()) {
      onSaveInput({
        ...inputValues[inputValues.chemical_type],
        name: inputWithName?.name
          ? processType !== PROJECT_TYPES.LIBRARY_GENERATION
            ? inputWithName?.name
            : `${inputWithName?.name}${COMPOUND_VARIATY[stateIndexes.propertyIndex]}`
          : `Input ${indexOfInput}${
              processType === PROJECT_TYPES.LIBRARY_GENERATION ? COMPOUND_VARIATY[stateIndexes.propertyIndex] : ''
            }`,
        reagent_type: inputValues.reagent_type,
        chemical_type: inputValues.chemical_type,
        name_input: inputWithName?.name
          ? processType !== PROJECT_TYPES.LIBRARY_GENERATION
            ? inputWithName?.name
            : `${inputWithName?.name}${COMPOUND_VARIATY[stateIndexes.propertyIndex]}`
          : `Input ${indexOfInput}${
              processType === PROJECT_TYPES.LIBRARY_GENERATION ? COMPOUND_VARIATY[stateIndexes.propertyIndex] : ''
            }`,
        is_variable: isVariable,
      });
    }
  };

  const validation = () => {
    let inputValid = inputValidations(inputValues);
    setErrors(inputValid.obj);
    return inputValid.isValid;
  };

  const onChangeType = (v, field) => {
    if (field === 'chemical_type' && v === 'solvent' && field !== 'reagent_type') {
      setInputValues({
        ...INITIAL_INPUT,
        [field]: v,
        reagent_type: null,
      });
      if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
        setVariationEq({
          [field]: v,
          reagent_type: null,
        });
      }
    } else if (field === 'reagent_type') {
      setInputValues({
        ...INITIAL_INPUT,
        [field]: v,
        chemical_type: inputValues.chemical_type,
      });
      if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
        setVariationEq({
          [field]: v,
          chemical_type: inputValues.chemical_type,
        });
      }
    } else {
      setInputValues({
        ...INITIAL_INPUT,
        [field]: v,
      });
      if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
        setVariationEq({
          ...INITIAL_INPUT,
          [field]: v,
        });
      }
    }
    setSelectedSolvent(null);
    setErrors({ ...INITIAL_ERRORS });
  };

  const changeFieldOption = (value, field, index = 0) => {
    let new_values = [...inputValues[inputValues.chemical_type][field]];
    new_values[index] = value;
    setInputValues({
      ...inputValues,
      [inputValues.chemical_type]: { ...inputValues[inputValues.chemical_type], [field]: new_values },
    });
    setEdited(true);
  };

  const changeField = (value, field) => {
    if (field === 'compound' || field === 'solvent') {
      setInputValues({
        ...inputValues,
        [inputValues.chemical_type]: {
          ...inputValues[inputValues.chemical_type],
          [field]: value,
          mw: value ? value.molwt : null,
        },
      });
      if (field === 'compound') {
        setCompound(value);
      }
    } else {
      setInputValues({
        ...inputValues,
        [inputValues.chemical_type]: { ...inputValues[inputValues.chemical_type], [field]: value },
      });
    }
    setEdited(true);
  };

  const addDeleteOption = (isDelete = false, index) => {
    let temp_moles = [...inputValues[inputValues.chemical_type].moles];
    let temp_mass = [...inputValues[inputValues.chemical_type].mass];
    let temp_volume = [...inputValues[inputValues.chemical_type].volume];
    let temp_eq = [...inputValues[inputValues.chemical_type].equivalents];

    let temp_canCalc = { ...canCalculate };
    if (isDelete) {
      //if index than Delete, else add
      temp_moles.splice(index, 1);
      temp_mass.splice(index, 1);
      temp_eq.splice(index, 1);
      temp_volume.splice(index, 1);

      temp_canCalc.moles.splice(index, 1);
      temp_canCalc.mass.splice(index, 1);
      temp_canCalc.volume.splice(index, 1);
      temp_canCalc.equivalents.splice(index, 1);
    } else {
      temp_moles.push(null);
      temp_mass.push(null);
      temp_volume.push(null);
      temp_eq.push(null);
      temp_canCalc.moles.push(true);
      temp_canCalc.mass.push(true);
      temp_canCalc.volume.push(true);
      temp_canCalc.equivalents.push(true);
    }

    setInputValues({
      ...inputValues,
      [inputValues.chemical_type]: {
        ...inputValues[inputValues.chemical_type],
        mass: temp_mass,
        moles: temp_moles,
        volume: temp_volume,
        equivalents: temp_eq,
      },
    });
    setCanCalculate({
      ...canCalculate,
      moles: temp_canCalc.moles,
      mass: temp_canCalc.mass,
      volume: temp_canCalc.volume,
      equivalents: temp_canCalc.equivalents,
    });
  };

  const getInputBlock = (
    title,
    field,
    regExp,
    maxValue,
    decimals,
    unit,
    index = 0,
    disabled = false,
    no_default_error
  ) => {
    let isArray = Array.isArray(inputValues[inputValues.chemical_type][field]);
    let eqValue = field === 'equivalents' && !!inputValues[inputValues.chemical_type].limiting ? 1 : null;
    if (field === 'equivalents') {
      let val = !!inputValues[inputValues.chemical_type].limiting
        ? 1
        : !inputValues[inputValues.chemical_type].equivalents[index]
        ? 'N/A'
        : inputValues[inputValues.chemical_type].equivalents[index];

      let eq = [...inputValues[inputValues.chemical_type].equivalents];
      return processType !== PROJECT_TYPES.LIBRARY_GENERATION ? (
        <div className="input-block">
          <div className="column-title">{title}</div>
          <div>{val}</div>
        </div>
      ) : !isVariable || !becameVariable || !variationInput ? (
        eq.map((variation, index) => (
          <div className="input-block">
            <div className="column-title">Equivalent {COMPOUND_VARIATY[index]}</div>
            <div>{variation ? variation : 'N/A'}</div>
          </div>
        ))
      ) : (
        <div className="input-block">
          <div className="column-title">Equivalent {COMPOUND_VARIATY[stateIndexes.propertyIndex]}</div>
          <div>
            {inputValues[inputValues.chemical_type].equivalents[0]
              ? inputValues[inputValues.chemical_type].equivalents[0]
              : 'N/A'}
          </div>
        </div>
      );
    } else {
      return (
        <div className="input-block">
          <div className="column-title">{title}</div>
          <div className="second-column">
            <div>
              <Input
                value={
                  isArray
                    ? eqValue || inputValues[inputValues.chemical_type][field][index]
                    : eqValue || inputValues[inputValues.chemical_type][field]
                }
                placeholder={'0'}
                regex={/[^\d.]+/g}
                onChange={e => {
                  let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                  if ((str || !e) && e < maxValue) {
                    e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), decimals) : e;
                    setEdited(true);
                    let replaceError = false;
                    if (isArray) {
                      changeFieldOption(e, field, index);
                      replaceError = [...errors[inputValues.chemical_type][field]];
                      replaceError[index] = false;
                    } else {
                      changeField(e, field);
                    }
                    setErrors({
                      ...errors,
                      [inputValues.chemical_type]: { ...errors[inputValues.chemical_type], [field]: replaceError },
                    });
                  }
                }}
                onBlur={() => {
                  if (field !== 'solvent' && field !== 'solvent_volume') calculationOnBlur(field, index);
                }}
                error={
                  no_default_error
                    ? false
                    : isArray && !!errors[inputValues.chemical_type][field]
                    ? errors[inputValues.chemical_type][field][index]
                    : errors[inputValues.chemical_type][field]
                }
                errorText={errorsText[inputValues.chemical_type][field]}
                disabled={disabled}
              />
              <span className="unit">{unit}</span>
            </div>
            {field !== 'density' && field !== 'solvent_volume' && !disabled && (
              <span className="calc-btn" onClick={() => calculateClick(field, index)}>
                calculate
              </span>
            )}
          </div>
        </div>
      );
    }
  };

  const calculateClick = (field, index) => {
    let newField =
      field !== 'concentration'
        ? [...inputValues[inputValues.chemical_type][field]]
        : inputValues[inputValues.chemical_type][field];

    let obj;

    obj = calculateSpecificField(inputValues, field, index, limitingInput, processType);
    if (field !== 'concentration') {
      newField[index] = obj[field];
    } else {
      newField = obj[field];
    }

    setInputValues({
      ...inputValues,
      [inputValues.chemical_type]: {
        ...inputValues[inputValues.chemical_type],
        [field]: newField,
      },
    });
  };

  const calculationOnBlur = (field, index = 0) => {
    let isArray = Array.isArray(inputValues[inputValues.chemical_type][field]);

    let mass = [...inputValues[inputValues.chemical_type].mass];
    let moles = [...inputValues[inputValues.chemical_type].moles];
    let volume = [...inputValues[inputValues.chemical_type].volume];
    let density = inputValues[inputValues.chemical_type].density;
    let concentration = inputValues[inputValues.chemical_type].concentration;
    let equivalents = [...inputValues[inputValues.chemical_type].equivalents];
    let obj;
    if (
      isArray ? !!inputValues[inputValues.chemical_type][field][index] : !!inputValues[inputValues.chemical_type][field]
    ) {
      let limInVar, lim_name, lim_upper;
      if (limitingInput) {
        limInVar = limitingInput.properties ? limitingInput.properties.find(i => i.limiting) : limitingInput;
        lim_name = limitingInput.properties ? limInVar.name_input : limitingInput?.name_input;
        lim_upper = limitingInput.properties ? limInVar.upperInputName : limitingInput?.upperInputName;
      }
      if (field !== 'density' && field !== 'concentration') {
        let temp_entered = canCalculate[field];
        temp_entered[index] = false;
        setCanCalculate({ ...canCalculate, [field]: temp_entered });
        obj = optionCalculation(
          inputValues,
          canCalculate,
          field,
          index,
          limitingInput,
          processType,
          !becameVariable || (limitingInput && becameVariable.name !== lim_upper)
        );

        moles[index] = obj.moles;
        mass[index] = obj.mass;
        volume[index] = obj.volume;
        if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
          equivalents = [...obj.equivalents];
        } else {
          equivalents[index] = obj.equivalents;
        }
        //density = obj.density;
        concentration = obj.concentration;
      } else {
        setCanCalculate({ ...canCalculate, [field]: false });
        for (let i = 0; i < inputValues[inputValues.chemical_type].moles.length; i++) {
          //number of options
          obj = optionCalculation(
            inputValues,
            canCalculate,
            field,
            i,
            limitingInput,
            processType,
            !becameVariable || (limitingInput && becameVariable.name !== lim_upper)
          );
          moles[i] = obj.moles;
          mass[i] = obj.mass;
          volume[i] = obj.volume;
          if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
            equivalents = [...obj.equivalents];
          } else {
            equivalents[i] = obj.equivalents;
          }
          //density = obj.density;
          concentration = obj.concentration;
        }
      }
      setErrors({ ...INITIAL_ERRORS });
      setInputValues({
        ...inputValues,
        [inputValues.chemical_type]: {
          ...inputValues[inputValues.chemical_type],
          mass,
          moles,
          volume,
          density: density,
          concentration: concentration,
          equivalents,
        },
      });
    } else {
      if (inputValues[inputValues.chemical_type].moles.length === 1) {
        if (
          !checkIfTrueExceptFields(inputValues[inputValues.chemical_type], [
            'compound',
            'mw',
            'chemical_type',
            'name',
            'name_input',
          ])
        ) {
          setCanCalculate({ ...CAN_CALCULATE });
        }
      }
    }
  };
  const checkIfTrueExceptFields = (obj, fields) => {
    for (let o in obj) {
      if (!fields.find(i => i === o)) {
        if (Array.isArray(obj[o]) ? obj[o][0] : obj[o]) return true;
      }
    }
    return false;
  };

  const recalculateEquivalents = () => {
    let equivalents = [...inputValues[inputValues.chemical_type].equivalents];
    equivalents = equivalents.map((eq, index) => {
      eq =
        limitingInput?.moles[0] &&
        (!!limitingInput?.name_input
          ? inputWithName?.name !== limitingInput?.name_input
          : inputWithName?.name !== limitingInput?.name)
          ? (Number(inputValues[inputValues.chemical_type]?.moles[index]) / Number(limitingInput?.moles[0])).toFixed(3)
          : null;
      return eq;
    });
    setInputValues({
      ...inputValues,
      [inputValues.chemical_type]: {
        ...inputValues[inputValues.chemical_type],
        equivalents,
        limiting: false,
      },
    });
  };

  const recalculateEquivalentsLibGen = () => {
    let equivalents = [...inputValues[inputValues.chemical_type].equivalents];
    if (limitingInput) {
      if (limitingInput.properties) {
        let arr = [];
        limitingInput.properties.forEach((propertyLim, propertyIndex) => {
          if (propertyLim.limiting && inputWithName?.name !== propertyLim?.upperInputName) {
            arr[propertyIndex] = (
              Number(inputValues[inputValues.chemical_type]?.moles[0]) / Number(propertyLim?.moles[0])
            ).toFixed(3);
          } else {
            arr = [null];
          }
        });
        equivalents = arr;
      } else {
        equivalents = equivalents.map((item, index) => {
          item =
            inputWithName?.name !== limitingInput?.upperInputName
              ? (Number(inputValues[inputValues.chemical_type]?.moles[0]) / Number(limitingInput?.moles[0])).toFixed(3)
              : null;
          return item;
        });
      }
    }
    setInputValues({
      ...inputValues,
      [inputValues.chemical_type]: {
        ...inputValues[inputValues.chemical_type],
        equivalents,
        limiting: false,
      },
    });
  };

  const onCancel = () => {
    if (edited) openCancelConfirmation(true);
    else onClose();
  };

  let reagent_reactant_type = inputValues.chemical_type === 'reagent' || inputValues.chemical_type === 'reactant';
  let rr_liquid = reagent_reactant_type && inputValues.reagent_type === 'liquid';
  let rr_solid = reagent_reactant_type && inputValues.reagent_type === 'solid';
  let rr_solution = reagent_reactant_type && inputValues.reagent_type === 'solution';
  let solvent_type = inputValues.chemical_type === 'solvent';
  return (
    <div className="input-wrapper">
      <Sidebar
        width={528}
        open={open}
        onClose={onCancel}
        onSave={onSave}
        onDelete={() => {
          if (selectedInput?.properties.length <= 1) {
            openDeleteConfirmation(true);
          } else onDelete();
        }}
        disableSave={false}
        cancelText={edited ? 'Cancel' : 'Close'}
        editingData={editingData}
        title={
          !editingData
            ? inputWithName?.name
              ? `Add ${inputWithName?.name}`
              : `Add input ${indexOfInput}${
                  processType === 'Library generation' ? COMPOUND_VARIATY[stateIndexes.propertyIndex] : ''
                }`
            : inputWithName?.name
            ? `Edit ${inputWithName?.name}`
            : `Edit input ${stateIndexes.editIndex + 1}`
        }
        saveButtonText={`${editingData ? 'Save' : 'Save'}`}
      >
        <div className="input">
          <div className="input-block not-centred">
            <div className="column-title">Chemical type</div>
            <RadioGroup
              options={COMPOUND_OPTIONS}
              value={inputValues?.chemical_type}
              setValue={onChangeType}
              field="chemical_type"
              disabled={!!editingData}
            />
          </div>
        </div>
        {!solvent_type && (
          <div className="input">
            <div className="input-block not-centred">
              <div className="column-title">Reagent / Reactant type</div>
              <RadioGroup
                options={RADIO_INPUT_OPTIONS}
                value={inputValues?.reagent_type}
                setValue={onChangeType}
                field="reagent_type" //reagent or reactant type
                disabled={!!editingData}
              />
            </div>
          </div>
        )}
        <div className="input-block_column">
          {reagent_reactant_type && (
            <>
              <div className="column-title">
                {inputValues.chemical_type[0].toUpperCase() + inputValues.chemical_type.slice(1)}
              </div>
              <div className="search-synmol">
                <SmilesSearcher
                  onDeleteAction={() => {
                    setEdited(true);
                    changeField(null, 'compound');
                    setCompound(null);
                    setInputValues({
                      ...inputValues,
                      [inputValues.chemical_type]: {
                        ...inputValues[inputValues.chemical_type],
                        compound: null,
                        mass: [null],
                        moles: [null],
                        volume: [null],
                        equivalents: [null],
                        density: null,
                        concentration: null,
                        limiting: false,
                      },
                    });
                  }}
                  changeSelect={e => {
                    if (!!e?.compound_id) {
                      setEdited(true);
                      setCompound(e);
                      changeField(e, 'compound');
                    }
                    setErrors({
                      ...errors,
                      [inputValues.chemical_type]: { ...errors[inputValues.chemical_type], compound: false },
                    });
                  }}
                  data={inputValues[inputValues.chemical_type].compound}
                  error={errors[inputValues.chemical_type].compound}
                  errorText={errorsText[inputValues.chemical_type].compound}
                  resetError={() => {
                    setErrors({
                      ...errors,
                      [inputValues.chemical_type]: { ...errors[inputValues.chemical_type], compound: false },
                    });
                  }}
                />
              </div>
            </>
          )}
          {(rr_solution || solvent_type) && (
            <>
              <div className="input-block solvent-name">
                <div className="column-title">{solvent_type ? 'Name' : 'Solvent'}</div>
                <Select
                  options={solventsOptions}
                  value={selectedSolvent?.compound_name || selectedSolvent}
                  placeholder="Select the solvent"
                  onChange={e => {
                    let solv = solventsOptions.find(i => i.compound_name === e);
                    setSelectedSolvent(solv);
                    setEdited(true);
                    changeField(solv, 'solvent');
                    setErrors({
                      ...errors,
                      [inputValues.chemical_type]: { ...errors[inputValues.chemical_type], solvent: false },
                    });
                  }}
                  // disabled={mfcPump}
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  error={rr_solution ? false : errors[inputValues.chemical_type].solvent}
                  errorText={errorsText[inputValues.chemical_type].solvent}
                />
              </div>
              {getInputBlock(
                solvent_type ? 'Volume' : 'Solvent volume',
                'solvent_volume',
                /^\\-?(\\d+\\.?\\d*|\\d*\\.?\\d+)$/gm,
                10000000,
                4,
                'mL',
                0,
                false,
                rr_solution
              )}
            </>
          )}
          <div className="input-block">
            <div className="column-title">MW</div>
            <span className="unit">
              {inputValues[inputValues.chemical_type][solvent_type ? 'solvent' : 'compound']?.molwt
                ? `${inputValues[inputValues.chemical_type][solvent_type ? 'solvent' : 'compound']?.molwt} g/mol`
                : '0'}
            </span>
          </div>
          {rr_solution &&
            getInputBlock(
              'Concentration',
              'concentration',
              /^\\-?(\\d+\\.?\\d*|\\d*\\.?\\d+)$/gm,
              10000000,
              4,
              'M',
              0,
              !inputValues[inputValues.chemical_type].compound
            )}
          {processType !== 'Optimization' && (
            <>
              {!solvent_type &&
                !rr_solution &&
                getInputBlock(
                  'Mass',
                  'mass',
                  /^\\-?(\\d+\\.?\\d*|\\d*\\.?\\d+)$/gm,
                  10000000,
                  4,
                  'g',
                  0,
                  !inputValues[inputValues.chemical_type].compound
                )}
              {!solvent_type &&
                getInputBlock(
                  'Amount',
                  'moles',
                  /^\\-?(\\d+\\.?\\d*|\\d*\\.?\\d+)$/gm,
                  10000000,
                  4,
                  'mmol',
                  0,
                  !inputValues[inputValues.chemical_type].compound
                )}
              {(rr_liquid || rr_solution) &&
                !rr_solid &&
                !solvent_type &&
                getInputBlock(
                  'Volume',
                  'volume',
                  /^\\-?(\\d+\\.?\\d*|\\d*\\.?\\d+)$/gm,
                  10000000,
                  4,
                  'mL',
                  0,
                  !inputValues[inputValues.chemical_type].compound
                )}
            </>
          )}
          {rr_liquid &&
            getInputBlock(
              'Density',
              'density',
              /^\\-?(\\d+\\.?\\d*|\\d*\\.?\\d+)$/gm,
              10000000,
              4,
              'g/mL',
              0,
              !inputValues[inputValues.chemical_type].compound
            )}
          {reagent_reactant_type && (
            <>
              <div className="input-block">
                <div className="column-title">Limiting reagent?</div>
                <Checkbox
                  className="ckeckbox-center"
                  value={inputValues[inputValues.chemical_type].limiting}
                  onChange={e => {
                    setEdited(true);
                    let value = [...inputValues[inputValues.chemical_type].equivalents];
                    if (Array.isArray(value)) {
                      value = value.fill(e ? 1 : null, 0, value.length);
                    }
                    if (!e) {
                      if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
                        recalculateEquivalentsLibGen();
                      } else {
                        recalculateEquivalents();
                      }
                    } else {
                      let temp_moles = [...inputValues[inputValues.chemical_type].moles];
                      let temp_mass = [...inputValues[inputValues.chemical_type].mass];
                      let temp_volume = [...inputValues[inputValues.chemical_type].volume];
                      if (
                        inputValues[inputValues.chemical_type].moles.length > 1 &&
                        processType === PROJECT_TYPES.OPTIMIZATION
                      ) {
                        temp_moles.splice(1, inputValues[inputValues.chemical_type].moles.length - 1);
                        temp_mass.splice(1, inputValues[inputValues.chemical_type].moles.length - 1);
                        value.splice(1, inputValues[inputValues.chemical_type].moles.length - 1);
                        temp_volume.splice(1, inputValues[inputValues.chemical_type].moles.length - 1);
                      }
                      setInputValues({
                        ...inputValues,
                        [inputValues.chemical_type]: {
                          ...inputValues[inputValues.chemical_type],
                          limiting: e,
                          equivalents: value,
                          mass: temp_mass,
                          moles: temp_moles,
                          volume: temp_volume,
                        },
                      });
                    }
                  }}
                  disabled={!inputValues[inputValues.chemical_type].compound}
                />
                <span className="unit">Yes</span>
              </div>
              {processType === 'Optimization' && (
                <div>
                  {inputValues[inputValues.chemical_type].moles.map((item, index) => (
                    <>
                      <div>
                        <div className="title-option">
                          Option {index + 1}
                          {inputValues[inputValues.chemical_type].moles.length > 1 && (
                            <DeleteOutlined
                              className="trash"
                              onClick={() => {
                                addDeleteOption(true, index);
                                setEdited(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        {getInputBlock(
                          'Equivalents',
                          'equivalents',
                          '',
                          10000000,
                          4,
                          '',
                          index,
                          !!inputValues[inputValues.chemical_type].limiting ||
                            !inputValues[inputValues.chemical_type].compound
                        )}
                        {!solvent_type &&
                          getInputBlock(
                            'Amount',
                            'moles',
                            '',
                            10000000,
                            4,
                            'mmol',
                            index,
                            !inputValues[inputValues.chemical_type].compound
                          )}
                        {!solvent_type && !rr_solution
                          ? getInputBlock(
                              'Mass',
                              'mass',
                              '',
                              10000000,
                              4,
                              'g',
                              index,
                              !inputValues[inputValues.chemical_type].compound
                            )
                          : null}
                        {(rr_liquid || rr_solution) &&
                          !rr_solid &&
                          getInputBlock(
                            'Volume',
                            'volume',
                            '',
                            10000000,
                            4,
                            'mL',
                            index,
                            !inputValues[inputValues.chemical_type].compound
                          )}
                      </div>
                    </>
                  ))}
                </div>
              )}
              {processType === 'Optimization' &&
                inputValues[inputValues.chemical_type].moles.length < 5 &&
                !inputValues[inputValues.chemical_type].limiting && (
                  <div className="add-option" onClick={() => addDeleteOption()}>
                    + add option
                  </div>
                )}
              {processType !== 'Optimization' &&
                getInputBlock(
                  'Equivalents',
                  'equivalents',
                  '',
                  10000000,
                  4,
                  '',
                  0,
                  !!inputValues[inputValues.chemical_type].limiting || !inputValues[inputValues.chemical_type].compound
                )}
            </>
          )}
        </div>
      </Sidebar>
      <Confirmations
        openDeleteConfirmation={openDeleteConfirmation}
        openCancelConfirmation={openCancelConfirmation}
        onDelete={onDelete}
        onClose={() => {
          onClose();
          //reset();
        }}
        deleteConfirmation={deleteConfirmation}
        cancelConfirmation={cancelConfirmation}
      />
    </div>
  );
};
