import React from 'react';
import './styles.scss';
import reagent from '../../../dist/icons/process-builder/limited_reagent.svg';

export const LimitedContainer = () => (
  <div className="limited-container">
    <img src={reagent} />
    <h4 className="limited-title">Limiting reagent</h4>
  </div>
);
