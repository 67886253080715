import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProcessBuilder from '../ProcessBuilder/ProcessBuilder';
import { ExperimentDetails } from './ExperimentDetails/ExperimentDetails';

export const AutoSyn = props => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/process-builder/details/:id`} component={ProcessBuilder} />
      <Route path={`${path}/details/:key`} component={ExperimentDetails} />
    </Switch>
  );
};
