import React, { useEffect, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { Avatar, PreviewSmiles, Carousel } from '../Common';
import { transferIsoToMMDDYY } from '../../utils/date';
import { formatSecondsToHours } from '../../utils';
import { DEVICES, LINKS_LIMIT } from '../../constants';
import { InfoSynJet } from './InfoSynJet';
import { InfoTabCommon } from '../ExperimentDetailsComponents/InfoTabCommon';
import { LabelValue } from '../ExperimentDetailsComponents/LabelValue';
import { InfoTabSlide } from '../ExperimentDetailsComponents/InfoTabSlide';
import { HeaderSlide } from '../ExperimentDetailsComponents/HeaderSlide';
import { PageHeader } from '../PdfGenerator/PageHeader';
import { PageContainer } from '../PdfGenerator/PageContainer';

export const Info = ({
  dataExperiment,
  getExperimentInfo,
  numberDefinitionSteps,
  dataSynJet,
  actualTimeExp,
  pdfMode,
  ExtraComponents,
}) => {
  const [targetProducts, setTargetProducts] = useState([]);
  const [relatedExperiments, setRelatedExperiments] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  useEffect(() => {
    if (!!dataExperiment && Object.keys(dataExperiment).length > 0) {
      setTargetProducts(JSON.parse(dataExperiment?.targetProducts));
      setRelatedExperiments(dataExperiment?.process.experiments.filter(i => i.status === 'Completed'));
      setInitialSlide(!initialSlide ? 1 : 0);
    }
  }, [dataExperiment]);

  const renderSlides = ({ useHeader, options, offset = 0 }) =>
    (options || targetProducts).map((i, index) => (
      <InfoTabSlide
        Header={useHeader && (() => <HeaderSlide currentSlide={offset + index} countSlide={targetProducts.length} />)}
        key={index}
        data={i}
        isLabDevice={isLabDevice}
        dataExperiment={dataExperiment}
      />
    ));

  const chunk = (all, one, i) => {
    const ch = Math.floor(i / 3);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  };

  const historyToShow =
    LINKS_LIMIT < relatedExperiments.length
      ? showMore || pdfMode
        ? relatedExperiments
        : relatedExperiments.slice(0, LINKS_LIMIT)
      : relatedExperiments;

  const isSynJet =
    DEVICES.SYNJET === dataExperiment?.process?.device || DEVICES.SYNJETPRO === dataExperiment?.process?.device;
  const isLabDevice = DEVICES.LAB === dataExperiment?.process?.device;
  const pdfExtraOptionsSlides = pdfMode ? targetProducts.slice(2).reduce(chunk, []) : [];
  const Child = (
    <div className="container-info-experiment">
      <div className="container-info-experiment_title">Experiment info</div>
      <div className="container-info-experiment_info">
        <InfoTabCommon
          pdfMode={pdfMode}
          actualTimeExp={actualTimeExp}
          dataExperiment={dataExperiment}
          isSynJet={isSynJet}
          isLabDevice={isLabDevice}
          numberDefinitionSteps={numberDefinitionSteps}
          showMore={showMore}
          relatedExperiments={relatedExperiments}
          getExperimentInfo={getExperimentInfo}
          historyToShow={historyToShow}
          setShowMore={setShowMore}
        />
        <div className="container-info-experiment_info_right">
          {isSynJet && (
            <MovingBlock
              pdfMode={pdfMode}
              dataExperiment={dataExperiment}
              showMore={showMore}
              relatedExperiments={relatedExperiments}
              getExperimentInfo={getExperimentInfo}
              historyToShow={historyToShow}
              setShowMore={setShowMore}
            />
          )}
          {!isSynJet &&
            !!targetProducts.length &&
            (pdfMode ? (
              renderSlides({ useHeader: true, options: targetProducts.slice(0, 2) })
            ) : (
              <Carousel countSlide={targetProducts.length} initialSlide={initialSlide}>
                {renderSlides({})}
              </Carousel>
            ))}
        </div>
      </div>
      {isSynJet && !pdfMode && (
        <>
          <InfoSynJet dataExperiment={dataExperiment} dataSynJet={dataSynJet} />
        </>
      )}
    </div>
  );
  return (
    <>
      {pdfMode ? (
        <>
          <PageContainer className="experiment-info-first-page" exceptionPage isSynJet={isSynJet}>
            <ExtraComponents />
            {Child}
          </PageContainer>
          {isSynJet && <InfoSynJet pdfMode dataExperiment={dataExperiment} dataSynJet={dataSynJet} />}
          {pdfExtraOptionsSlides.map((i, idx) => (
            <PageContainer>
              <div className="container-info-experiment_title">Experiment info</div>
              {renderSlides({ useHeader: true, options: i, offset: 2 + idx * 3 })}
            </PageContainer>
          ))}
        </>
      ) : (
        Child
      )}
    </>
  );
};

export const MovingBlock = ({ dataExperiment, showMore, relatedExperiments, historyToShow, setShowMore, pdfMode }) => (
  <>
    <LabelValue label="Created on" value={`${transferIsoToMMDDYY(dataExperiment?.createdAt)}`} />
    <LabelValue
      label="Submitted on"
      value={`${dataExperiment?.submittedAt ? transferIsoToMMDDYY(dataExperiment?.submittedAt) : 'N/A'}`}
    />
    <LabelValue label="Project key" value={dataExperiment?.process?.project?.key} />
    <LabelValue
      label="History"
      value={
        <div className="links-experiments">
          {relatedExperiments.length ? (
            <>
              {historyToShow.map((i, index) => (
                <a
                  href={`/execution?key=${i.key}&deviceId=${i.timeSlot.device.uuid}&tab=history`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {i.name}
                  {index !== historyToShow.length - 1 && ', '}
                </a>
              ))}
              {LINKS_LIMIT < relatedExperiments.length && !pdfMode && (
                <>
                  {!showMore && <span className="dots">...</span>}
                  <br />
                  <a className="show-more" onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'show less' : 'show more'}
                  </a>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      }
    />
  </>
);
