import * as actionsTypes from './experimentCreation.actionTypes';
import { http, parseProcessBuilderData } from '../../utils';
import {
  mutationCreateExperiment,
  queryExperimentInfo,
  mutationCalculateEstimatedTime,
  queryCheckExperimentName,
} from '../graphql';
import { parseProcessDefinition } from '../synjet/synjet.actions';
import { parseProcessBuilderDataFromBackend } from '../../utils/manualProcessBuilder';

export const setModalOpen = (process, steps, processData) => ({
  type: actionsTypes.SET_MODAL_OPEN,
  payload: {
    steps,
    process,
    processData,
  },
});

export const setExperimentEditModalOpen = (experiment, isSynjet, isLab) => async (dispatch, getState) => {
  const { reactors } = getState().processbuilderReducer;
  let steps = null;
  let processData = null;
  if (isLab) {
    const { parsedData } = parseProcessBuilderDataFromBackend(
      experiment?.process?.definition,
      experiment?.process?.type
    );
    processData = parsedData;
  } else if (!isSynjet) {
    const processDefinition = JSON.parse(experiment?.process?.definition);
    processData = parseProcessBuilderData(processDefinition, reactors, experiment?.process?.type);
    steps = processDefinition?.process_steps;
  } else {
    const process = experiment?.process;
    const { processSteps, steps: synjetSteps, products } = await dispatch(
      parseProcessDefinition(process.definition, process.variableStep, true)
    );
    processData = {
      experiments: { process_steps: processSteps },
      processSteps: synjetSteps,
    };
    steps = 0;
  }
  dispatch({
    type: actionsTypes.SET_EDIT_EXPERIMENT_MODAL_OPEN,
    payload: {
      experiment,
      processData,
      steps,
      isSynjet,
    },
  });
};

export const setModalClose = () => ({ type: actionsTypes.SET_MODAL_CLOSE });

export const setProcess = (process, processData, steps) => ({
  type: actionsTypes.SET_PROCESS,
  payload: {
    process,
    processData,
    steps,
  },
});

export const setExperimentInfoLoading = isLoading => ({
  type: actionsTypes.SET_EXPERIMENT_INFO_LOADING,
  payload: isLoading,
});

export const setExperimentCreationLoading = isLoading => ({
  type: actionsTypes.SET_EXPERIMENT_CREATION_LOADING,
  payload: isLoading,
});

export const setCalculationLoading = isLoading => ({
  type: actionsTypes.SET_CALCULATION_LOADING,
  payload: isLoading,
});

export const createExperiment = data => async dispatch => {
  dispatch(setExperimentCreationLoading(true));
  try {
    const { createExperiment: experimentData } = await http(mutationCreateExperiment(data));
    return experimentData;
  } catch (e) {
    const errorMessage =
      (e?.errors && e?.errors[0] && e?.errors[0]?.message) || 'Server error was occured while creation experiment';
    return Promise.reject(errorMessage);
  } finally {
    dispatch(setExperimentCreationLoading(false));
  }
};

export const calculateExperimentTime = data => async dispatch => {
  dispatch(setCalculationLoading(true));
  try {
    const { calculateExperimentTotalTime } = await http(mutationCalculateEstimatedTime(data));
    return calculateExperimentTotalTime;
  } catch (e) {
    const errorMessage =
      (e?.errors && e?.errors[0] && e?.errors[0]?.message) || 'Server error was occured while calculate experiment';
    return Promise.reject(errorMessage);
  } finally {
    dispatch(setCalculationLoading(false));
  }
};

export const getExperimentInfo = data => async dispatch => {
  dispatch(setExperimentInfoLoading(true));
  try {
    return await http(queryExperimentInfo(data));
  } catch (e) {
    const errorMessage =
      (e?.errors && e?.errors[0] && e?.errors[0]?.message) ||
      'Server error was occured while getting experiment info data';
    return Promise.reject(errorMessage);
  } finally {
    dispatch(setExperimentInfoLoading(false));
  }
};

export const checkExperimentNameUnique = name => dispatch => {
  return http(queryCheckExperimentName(name)).then(resp => {
    return !resp?.allExperiments?.totalCount;
  });
};
