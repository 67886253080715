import './style.scss';

import React from 'react';

import cn from 'classnames';

export const ButtonGroup = ({ value, options, onChange, className }) => (
  <div className={cn('button-group-container', className)}>
    {options.map((i, idx, arr) => {
      const isBeforeActive = arr[idx + 1]?.value === value;
      const active = i.value === value;
      return (
        <button
          onClick={e => {
            e.stopPropagation();
            onChange(i.value);
          }}
          className={cn('button-group-container_item', {
            'button-group-container_item_before-active': isBeforeActive,
            'button-group-container_item_active': active,
          })}
          value={i.value}
        >
          {i.label}
        </button>
      );
    })}
  </div>
);
