import React, { useCallback, useEffect, useState } from 'react';
import { getAnalyticsColumns, getExperimentTableColumns } from './columns';
import { Button, Checkbox, Input, Table, Tooltip } from '../../../Common';
import { calculatePageTotal, getExperimentsData } from './rows';
import './styles.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  MAX_VIALS_COPY,
  MIN_VIALS_COPY,
  SYNJET_PRO_PROCESS_TYPES,
  SYNJET_PROCESS_TYPES,
  TEMPERATURE_LIMITS,
} from '../../../../constants';
import { Table as AntdTable } from 'antd';
import { transferIsoToMMDDYYutc } from '../../../../utils/date';
import { PurificationDetails } from 'components/Experiment/InfoSynJet';
import { getMinMaxTemperatureValues } from '../../../../utils/execution';
import { getVialCopiesMapping, mapCopiedVials } from '../../../../utils/synjetHelpers';

const TotalRow = ({ pageData, processSteps, processType, showActualTemperatures, analytics, isQuenchingEnabled }) => {
  const total = calculatePageTotal(pageData, processSteps);
  const columns = [];

  processSteps.forEach((step, stepIndex) => {
    const conditionsColumn =
      processType === SYNJET_PROCESS_TYPES.OPTIMIZATION || showActualTemperatures ? ['', ''] : [''];

    const hasProductColumn =
      processType === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION && (processSteps.length < 2 || !!stepIndex);
    const productsColumn = hasProductColumn ? ['-'] : [];

    const quenchingColumn = isQuenchingEnabled ? ['-'] : [];

    const reactants =
      total[`step${stepIndex}`].reactants?.map(reactant => (reactant.volume < 0 ? '-' : reactant.volume.toFixed(3))) ||
      [];

    const reagents =
      total[`step${stepIndex}`].reagents?.map(reagent => (reagent.volume < 0 ? '-' : reagent.volume.toFixed(3))) || [];
    columns.push(...conditionsColumn, ...reactants, ...reagents, ...productsColumn, ...quenchingColumn);
  });

  return (
    <AntdTable.Summary.Row className="total-label">
      <AntdTable.Summary.Cell />
      <AntdTable.Summary.Cell>
        <span className="total-label">Total volume (µL)</span>
        {!total.is_valid && (
          <Tooltip title={total.error} overlayClassName="synjet-process-error-tooltip">
            <InfoCircleOutlined className="total-icon" />
          </Tooltip>
        )}
      </AntdTable.Summary.Cell>
      {columns.map(item => (
        <AntdTable.Summary.Cell>{item}</AntdTable.Summary.Cell>
      ))}
      {!!processSteps[processSteps.length - 1].conditions[0].dispensing.solvent &&
        !!processSteps[processSteps.length - 1].conditions[0].dispensing.normalize && (
          <AntdTable.Summary.Cell>
            {total.normalization_solvent_volume < 0 ? '-' : total.normalization_solvent_volume.toFixed(3)}
          </AntdTable.Summary.Cell>
        )}
      <AntdTable.Summary.Cell className="total-last">
        {total.total < 0 ? '-' : total.total.toFixed(3)}
      </AntdTable.Summary.Cell>
      {analytics ? analytics.map(() => <AntdTable.Summary.Cell />) : null}
      <AntdTable.Summary.Cell />
    </AntdTable.Summary.Row>
  );
};

export const SynJetExperimentTable = props => {
  const {
    experiments,
    displayOption,
    processType,
    processSteps,
    previewMode,
    showCheckboxes,
    setReactions = () => {},
    selectedReactions,
    moveBack,
    analytics,
    analyticsData,
    stepStatuses,
    calibrationTemperatures,
    analytical,
    showActualTemperatures,
    selectedExperiment,
    experimentReactionInfo,
    processStepsWithTime,
    isQuenchingEnabled,
    isProExecution,
    executionIdToVialNameDeps,
    allowVialsCopy,
    vialsCopies,
    handleVialCopyChange,
    setVialsCopies = () => {},
    errorCopiedVials,
    showCopiedVials = false,
    enableVialCopying = false,
    isSynJetPro,
  } = props;
  const dispensingVolume = processSteps[processSteps.length - 1]?.conditions[0].dispensing.volume;
  const [reactionsData, setReactionsData] = useState([]);
  const [copiedVialsData, setCopiedVialsData] = useState({});
  const variableStepIndex = processSteps ? processSteps.findIndex(step => !step.isFixed) : 0;

  const onRowSelect = (reactionIndex, checked) => {
    const newReactions = selectedReactions.map((reaction, index) =>
      index === reactionIndex ? { ...reaction, include: checked } : reaction
    );
    setReactions(newReactions);
  };

  const getDisableCheckbox = index =>
    selectedReactions.filter(reaction => reaction?.include && reaction?.[`step${variableStepIndex}`]).length === 1 &&
    selectedReactions[index].include;

  const getColumns = useCallback(() => {
    const columns = getExperimentTableColumns(
      processSteps,
      displayOption,
      processType,
      previewMode,
      stepStatuses,
      analytical,
      showActualTemperatures,
      isQuenchingEnabled,
      copiedVialsData
    );

    const vialCopyColumn = allowVialsCopy
      ? [
          {
            title: <span className="column-name">Vial copy</span>,
            dataIndex: ['step', 'copyData'],
            render: (include, rowData, index) => (
              <div>
                <Input
                  value={vialsCopies?.[index]}
                  type="number"
                  integer
                  placeholder="1"
                  min={MIN_VIALS_COPY}
                  max={MAX_VIALS_COPY}
                  step={1}
                  className="vial-copy-input"
                  onChange={value => handleVialCopyChange(index, value)}
                  disabled={!selectedReactions?.[index]?.include}
                  error={errorCopiedVials.includes(index)}
                />
              </div>
            ),
          },
        ]
      : [];
    const [vialNumber, ...restColumns] = columns;

    const analyticsColumns = getAnalyticsColumns(analytics, selectedExperiment, previewMode);
    const selectedVialsCount = selectedReactions?.filter(reaction => reaction.include)?.length;
    return showCheckboxes
      ? [
          {
            title: `Include: ${selectedVialsCount} / ${selectedReactions.length}`,
            dataIndex: 'include',
            key: 'include',
            width: 60,
            render: (include, rowData, index) => (
              <Checkbox
                checked={selectedReactions?.[index]?.include}
                disabled={
                  processType === SYNJET_PROCESS_TYPES.OPTIMIZATION ||
                  getDisableCheckbox(index) ||
                  !rowData.is_valid ||
                  !rowData[`step${variableStepIndex}`]
                }
                onChange={checked => onRowSelect(index, checked)}
              />
            ),
          },
          vialNumber,
          ...vialCopyColumn,
          ...restColumns,
          ...analyticsColumns,
        ]
      : [...columns, ...analyticsColumns];
  }, [
    processSteps,
    displayOption,
    showCheckboxes,
    selectedReactions,
    analytics,
    stepStatuses,
    showActualTemperatures,
    allowVialsCopy,
    vialsCopies,
    errorCopiedVials,
    copiedVialsData,
  ]);

  const getData = useCallback(
    () =>
      getExperimentsData(
        experiments,
        previewMode,
        analyticsData,
        calibrationTemperatures,
        analytical,
        processStepsWithTime,
        isProExecution,
        isSynJetPro
      ),
    [experiments, previewMode, analyticsData, calibrationTemperatures]
  );

  useEffect(() => {
    const data = getData();
    setReactionsData(data);
    let experimentReactions = [];
    let vialsCopies = {};
    if (experimentReactionInfo) {
      experimentReactions = getExperimentsData(
        experimentReactionInfo,
        previewMode,
        analyticsData,
        calibrationTemperatures,
        analytical,
        processStepsWithTime,
        isSynJetPro
      );
    }

    if (showCopiedVials && enableVialCopying) {
      setCopiedVialsData(getVialCopiesMapping(data));
    } else {
      setCopiedVialsData({});
    }

    const parsedReactions = allowVialsCopy
      ? experimentReactions.map(vial => ({ ...vial, name: vial.ancestor }))
      : experimentReactions;

    const mappedData = experimentReactions.length
      ? data.map(row =>
          parsedReactions.find(reaction => reaction.name === row.name)
            ? row
            : {
                ...row,
                include: false,
              }
        )
      : data;
    if (allowVialsCopy) {
      vialsCopies = mapCopiedVials(experimentReactions, mappedData);
    }
    if (!moveBack) {
      setReactions(mappedData);
      setVialsCopies(vialsCopies);
    }
  }, [
    experiments,
    dispensingVolume,
    previewMode,
    processSteps,
    moveBack,
    analyticsData,
    calibrationTemperatures,
    showCopiedVials,
  ]);

  return (
    <>
      <Table
        scroll={{ x: true }}
        dataSource={reactionsData}
        bordered
        sidePadding={!previewMode}
        columns={getColumns()}
        className={
          processSteps.length > 1
            ? 'synjet-experiment-table synjet-experiment-table-two-steps '
            : 'synjet-experiment-table'
        }
        rowClassName={record => !record.is_valid && 'error-row'}
        summary={pageData =>
          previewMode ? null : (
            <TotalRow
              pageData={pageData}
              processType={processType}
              processSteps={processSteps}
              showActualTemperatures={showActualTemperatures}
              analytics={analytics}
              isQuenchingEnabled={isQuenchingEnabled}
            />
          )
        }
      />
      {isProExecution && (
        <PurificationDetails purificationData={selectedExperiment?.purification} isProExecution={isProExecution} />
      )}
    </>
  );
};
