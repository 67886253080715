import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getData, getIsFetched } from 'store/slices/extensibleTableFieldsGrid/getters';
import history from 'utils/history';

import { PREDICATES_BY_TYPE } from '@templates';

import { mapFilterDataItem, mapFiltersToUrlQueryFormat, parseFiltersFromUrl } from './utils';

export const useExperimentFiltersController = () => {
  const { search: urlSearchData } = useLocation();
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState('');
  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false);

  const tableFieldsGrid = useSelector(getData);
  const isFetched = useSelector(getIsFetched);

  useEffect(() => {
    if (isFiltersLoaded) {
      const queryFiltersString = mapFiltersToUrlQueryFormat(filters);
      const queryUrlString = new URLSearchParams({
        search,
        filters: encodeURIComponent(queryFiltersString),
      }).toString();

      if (queryUrlString !== urlSearchData.substring(1)) {
        history.replace({
          pathname: '/experiments',
          search: queryUrlString,
        });
      }
    }
  }, [filters, search]);

  const processUrlFilters = async () => {
    try {
      const filtersUrlValue = JSON.parse(decodeURIComponent(new URLSearchParams(urlSearchData).get('filters')));
      const searchUrlValue = new URLSearchParams(urlSearchData).get('search');
      const parsedFilters = await parseFiltersFromUrl({ filtersUrlValue, tableFieldsGrid });
      setFilters(parsedFilters);
      setSearch(searchUrlValue);
    } catch (e) {
      console.log(e);
    } finally {
      setIsFiltersLoaded(true);
    }
  };

  useEffect(() => {
    if (isFetched) {
      processUrlFilters();
    }
  }, [isFetched]);

  const onChangeSearch = search => setSearch(search);

  const updateFilterValue = uuid => value => {
    setFilters(prev => prev.map(filter => (filter.uuid === uuid ? { ...filter, value } : filter)));
  };

  const updateFilterPredicate = uuid => (selectedPredicate, predicates) => {
    setFilters(prev =>
      prev.map(filter => {
        const newPredicate = predicates.find(predicate => predicate.key === selectedPredicate.key);
        return filter.uuid === uuid
          ? {
              ...filter,
              predicate: newPredicate,
              value: newPredicate.initialValue(filter.value),
              availableOptions:
                predicates.find(predicate => predicate.key === selectedPredicate.key).availableOptions ||
                filter.availableOptions,
            }
          : filter;
      })
    );
  };

  const addFilter = data => {
    setFilters(prev => [mapFilterDataItem(data), ...prev]);
  };

  const removeFilter = uuid => {
    setFilters(prev => prev.filter(filter => filter.uuid !== uuid));
  };

  const clearFilters = () => {
    setFilters([]);
  };

  const resetFilters = () => {
    setFilters(
      filters.map(filter => ({ ...filter, value: undefined, predicate: PREDICATES_BY_TYPE[filter.dataType][0] }))
    );
  };
  return {
    updateFilterValue,
    updateFilterPredicate,
    addFilter,
    removeFilter,
    clearFilters,
    resetFilters,
    search,
    onChangeSearch,
    filters,
    isFiltersLoaded,
  };
};
