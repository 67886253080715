import React from 'react';
import { Button } from '../Common';
import { MassOfInterestItem } from './MassOfInterestItem';
import cn from 'classnames';
import { CHEMICAL_TYPES } from '../../constants/experiment';

const chemicalTypes = [
  CHEMICAL_TYPES.product,
  CHEMICAL_TYPES.expectedIntermediate,
  CHEMICAL_TYPES.reagent,
  CHEMICAL_TYPES.reactant,
  CHEMICAL_TYPES.quenching,
  CHEMICAL_TYPES.sideproduct,
];

export const MassOfInterestTable = ({
  tableData,
  deleteItem,
  updateFieldOnItem,
  addCompound,
  addUnknownStructure,
  resetToDefault,
  dataCreated,
  updateNewCompound,
  confirmAdding,
  clearDataCreated,
  newCompounds,
  previewMode = false,
  errors,
  loading,
}) => {
  tableData.sort((first, second) => {
    return chemicalTypes.indexOf(first.type) - chemicalTypes.indexOf(second.type);
  });

  return (
    <div className="analytical-reactions-table">
      <div
        className={cn('analytical-settings_table-header', { 'analytical-settings_table-header_preview': previewMode })}
      >
        <div></div>
        <div className="analytical-settings_table-header_compound">Compound name</div>
        <div className="analytical-settings_table-header_type">Chemical type</div>
        <div className="analytical-settings_table-header_label">Chart label</div>
        <div className="analytical-settings_table-header_mass">Exact mass, g/mol</div>
        <div className="analytical-settings_table-header_mol">Mass modification</div>
        <div className="analytical-settings_table-header_mol">Observed mass, g/mol</div>
        <div className="analytical-settings_table-header_start">Interval start, minutes</div>
        <div className="analytical-settings_table-header_end">Interval end, minutes</div>
        {tableData.filter(item => item.type !== 'Product') && !previewMode ? (
          <div className="analytical-settings_table-header_remove" />
        ) : null}
      </div>
      <div className="analytical-settings_table-body">
        {[...tableData, ...newCompounds].map((i, idx) => (
          <MassOfInterestItem
            previewMode={previewMode}
            deleteItem={deleteItem}
            key={`${i.compound?.compound_id}__${idx}`}
            order={idx}
            data={i}
            updateItem={updateFieldOnItem}
            itemErrors={errors?.[idx]}
            loading={loading}
            unknownCompound={!i.compound}
          />
        ))}
        {dataCreated && (
          <MassOfInterestItem
            order="-1"
            newCompound
            updateNewCompound={updateNewCompound}
            confirmAdding={confirmAdding}
            dataCreated={dataCreated}
            clearDataCreated={clearDataCreated}
          />
        )}
        {!previewMode && (
          <div className="analytical-settings_table-body_add-block">
            <div>
              {!dataCreated && (
                <Button secondary onClick={addCompound}>
                  Add compound
                </Button>
              )}
              <Button secondary onClick={addUnknownStructure} style={{ marginLeft: 8 }}>
                Add unknown structure
              </Button>
            </div>
            <Button secondary disabled={false} onClick={resetToDefault}>
              Reset to default
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
