import createReducer from '../createReducer.js';
import * as actionsTypes from './experimentField.actionTypes';
import { INITIAL_DEFAULT_VALUE } from '@organisms';

export const initialState = {
  uuid: '',
  name: '',
  dataType: '',
  editable: true,
  unique: false,
  colorCoding: false,
  hint: {
    checkbox: false,
    value: '',
  },
  defaultField: { ...INITIAL_DEFAULT_VALUE },
  updateHistory: true,
  visible: true,
  archived: false,
  dropdownOptions: [],
  systemic: false,
};

const setExperimentFieldData = (state, data) => {
  return { ...state, experimentField: data.payload };
};

const setUpdateExperimentField = (state, data) => {
  return { ...state, ...data.data };
};

const experimentFieldReducer = createReducer(initialState, {
  [actionsTypes.EXPERIMENT_FIELD_DATA]: setExperimentFieldData,
  [actionsTypes.UPDATE_EXPERIMENT_FIELD_DATA]: setUpdateExperimentField,
});

export default experimentFieldReducer;
