import React from 'react';
import cn from 'classnames';

export const Glassware = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#20A89A" />
      <path
        d="M36.397 32.9019L28.6875 19.9299V13.4637C29.7787 13.0767 30.5625 12.0347 30.5625 10.8125C30.5625 9.26169 29.3008 8 27.75 8H20.25C18.6992 8 17.4375 9.26169 17.4375 10.8125C17.4375 12.0347 18.2213 13.0767 19.3125 13.4637V19.9299L11.6037 32.9008C10.7234 34.3681 10.7011 36.133 11.5441 37.622C12.3873 39.111 13.9121 40 15.6232 40H32.3767C34.0878 40 35.6127 39.111 36.4558 37.622C37.2987 36.1334 37.2767 34.3689 36.397 32.9019ZM20.25 9.875H27.75C28.2669 9.875 28.6875 10.2956 28.6875 10.8125C28.6875 11.3294 28.2669 11.75 27.75 11.75H20.25C19.7331 11.75 19.3125 11.3294 19.3125 10.8125C19.3125 10.2956 19.7331 9.875 20.25 9.875ZM21.0559 20.6665C21.1421 20.5216 21.1875 20.3561 21.1875 20.1875V13.625H26.8125V15.5H25.875C25.3572 15.5 24.9375 15.9198 24.9375 16.4375C24.9375 16.9552 25.3572 17.375 25.875 17.375H26.8125V19.25H25.875C25.3572 19.25 24.9375 19.6698 24.9375 20.1875C24.9375 20.7052 25.3572 21.125 25.875 21.125H27.2166L31.3291 28.0448C30.632 28.4008 29.6924 28.75 28.6875 28.75C26.502 28.75 24.6413 27.1281 24.6227 27.1117C24.6227 27.1117 24.6227 27.1116 24.6226 27.1116C24.5087 27.0101 22.2139 25 19.3125 25C19.0258 25 18.7366 25.0201 18.4455 25.0588L21.0559 20.6665ZM34.8242 36.6982C34.3184 37.5916 33.4034 38.125 32.3767 38.125H15.6232C14.5966 38.125 13.6816 37.5916 13.1757 36.6982C12.6699 35.8047 12.6832 34.7458 13.2135 33.8621L17.0573 27.3945C17.8346 27.0499 18.5923 26.875 19.3125 26.875C21.498 26.875 23.3587 28.4969 23.3754 28.5116C23.4719 28.5979 25.7744 30.625 28.6875 30.625C30.0781 30.625 31.3273 30.1696 32.2894 29.6606L34.7865 33.8621C34.7872 33.8633 34.7878 33.8644 34.7885 33.8655C35.3167 34.7458 35.3301 35.8047 34.8242 36.6982Z"
        fill="white"
      />
    </svg>
  </div>
);
