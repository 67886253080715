import React, { useState } from 'react';
import { Button, Collapse } from '../../../Common';
import { SJDispensingOptions } from './SJDispensingOptions';
import { ConditionsHardwareSummary } from './ConditionsHardwareSummary';
import { ExperimentInfo } from './ExperimentInfo';
import { getExperimentContainerStyle, getValidVialsProcessSteps } from '../../../../utils/execution';
import {
  getAncestorMapping,
  getCopiedVials,
  getInitialVials,
  getProcessStepsData,
  mapWithInitialProcessSteps,
} from '../../../../utils/synjetHelpers';
import './style.scss';
import cn from 'classnames';
import { SJProDispensingOptions } from './SJProDispensingOptions';
import { SYNJET_PRO_PROCESS_TYPES } from '../../../../constants';

export const SJReviseTheDetails = ({
  batch,
  getDetails,
  experimentsInfo,
  dispensingOptions,
  setExperimentsInfo,
  errors,
  isPro,
  handleRecalculateDispensers,
  heatingBlocks,
  setHeatingBlocks,
  setConditionsErrors,
  initialExperimentInfo,
}) => {
  const handleExperimentInfoUpdate = (experimentIndex, experimentSteps, experimentProducts, experimentEI) => {
    const experiment = batch?.experiments?.[experimentIndex];
    const { rules } = experimentsInfo[experimentIndex];
    let initialVials;
    let copiedVials;
    let ancestorMapping;
    if (initialExperimentInfo) {
      const { processSteps: initialProcessSteps } = initialExperimentInfo[experimentIndex];
      initialVials = getInitialVials(initialProcessSteps);
      copiedVials = getCopiedVials(initialVials);
      ancestorMapping = getAncestorMapping(initialProcessSteps);
    }
    const updatedProcessSteps = getProcessStepsData(
      experimentSteps,
      rules,
      experiment?.process?.type,
      null,
      experimentProducts,
      experimentEI
    );
    const validProcessSteps = getValidVialsProcessSteps(
      updatedProcessSteps,
      experiment?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION
    );
    const mappedProcessSteps = mapWithInitialProcessSteps(
      validProcessSteps,
      initialVials,
      copiedVials,
      ancestorMapping
    );
    const updatedExperimentInfo = experimentsInfo.map((info, infoIndex) =>
      infoIndex === experimentIndex
        ? {
            ...info,
            steps: experimentSteps,
            products: experimentProducts,
            expectedIntermediate: experimentEI,
            processSteps: mappedProcessSteps,
          }
        : info
    );
    setExperimentsInfo(updatedExperimentInfo);
  };

  const getExperimentCollapse = experiments =>
    experiments
      ? experiments.map((experiment, experimentIndex) => ({
          title: (
            <div className={cn('collapse__experiment', getExperimentContainerStyle(experimentIndex))}>
              <h5 className="collapse__experiment__title">{`Experiment ${experiment?.name}`}</h5>
            </div>
          ),
          body: (
            <ExperimentInfo experiment={experiment} experimentInfo={experimentsInfo?.[experimentIndex]} isPro={isPro} />
          ),
        }))
      : [];

  const REVISE_COLLAPSE = [
    {
      title: isPro ? (
        <div className="pro-collapse-header">
          Dispensing options
          <Button type="primary" onClick={handleRecalculateDispensers}>
            Reset to Default
          </Button>
        </div>
      ) : (
        'Dispensing options'
      ),
      body: isPro ? (
        <SJProDispensingOptions
          dispensingOptions={dispensingOptions}
          getDetails={getDetails}
          batch={batch}
          errors={errors}
          isPro
        />
      ) : (
        <SJDispensingOptions
          dispensingOptions={dispensingOptions}
          experiments={batch?.experiments}
          batchId={batch?.uuid}
          getDetails={getDetails}
          step={batch?.step}
          errors={errors}
          isPro={isPro}
        />
      ),
    },
    {
      title: 'Conditions & Hardware summary',
      body: (
        <ConditionsHardwareSummary
          experiments={batch?.experiments}
          experimentsInfo={experimentsInfo}
          handleExperimentInfoUpdate={handleExperimentInfoUpdate}
          isPro={isPro}
          heatingBlocks={heatingBlocks}
          setHeatingBlocks={setHeatingBlocks}
          setConditionsErrors={setConditionsErrors}
        />
      ),
    },
    ...getExperimentCollapse(batch?.experiments),
  ];

  const [activeKey, setActiveKeys] = useState(REVISE_COLLAPSE.map((i, index) => index));

  const onChange = keys => {
    setActiveKeys(keys);
  };

  return <Collapse items={REVISE_COLLAPSE} activeKey={activeKey} onChange={onChange} />;
};
