import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Input, openNotification, Popup, Select, Tooltip } from '../../../index';
import { SJDispenserCompound } from './SJDispenserCompound';
import { useOutsideAlerter } from '../../../../utils/customHooks';
import {
  getAvailableMaterials,
  getAvailableBackpack,
  getAvailableFluidBody,
  getAvailableReservoirVolumes,
} from '../../../../store/synjet/synjet.actions';
import { updateBatchDispensers } from '../../../../store/scheduling/scheduling.actions';
import {
  getCompoundInfo,
  getCompoundNormalizeInfo,
  validateSJDispenser,
  getExperimentUpdatedReactionInfo,
} from '../../../../utils/execution';
import { checkNumericField } from '../../../../utils';
import { DISPENSER_COUNT_ERROR } from '../../../../constants/execution';
import { DEVICES } from '../../../../constants';

export const SJDispenser = ({
  dispenserNumber,
  dispenser,
  experiments,
  isNormalize,
  batchId,
  getDetails,
  step,
  errors = [],
  previewDispensers,
  isPro,
  isManual = false,
}) => {
  const dispatch = useDispatch();
  const dispenserRef = useRef(null);
  const [localDispenser, setLocalDispenser] = useState(dispenser);
  const [dispenserCompoundInfo, setDispenserCompoundInfo] = useState([]);
  const [localDispenserCompoundInfo, setLocalDispenserCompoundInfo] = useState([]);
  const dispenserModeRef = useRef({
    isEdit: false,
    dispenser: null,
    localDispenser: null,
    localDispenserCompoundInfo: null,
    dispenserCompoundInfo: null,
    saveModal: false,
    showWarning: false,
  });
  const [isEdit, setEdit] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [materials, setMaterials] = useState([]);
  const [reservoirVolumes, setReservoirVolumes] = useState([]);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (errors.length) {
      errors.forEach(err => {
        if (err.indexOf(dispenser.uuid) > -1) {
          const msg = err.replace(`[${dispenser.uuid}]`, dispenserNumber);
          openNotification(null, msg);
        }
      });
    }
  }, [JSON.stringify(errors)]);

  const getBackpack = useCallback(
    async value => {
      try {
        const response = await dispatch(
          getAvailableBackpack({
            search: value,
            material: localDispenser?.material,
            deviceType: isPro ? DEVICES.SYNJETPRO : DEVICES.SYNJET,
          })
        );
        return response.map(i => ({ value: i.key, label: i.key }));
      } catch (e) {
        return [];
      }
    },
    [localDispenser?.material]
  );

  const getFluidBody = useCallback(
    async value => {
      try {
        const response = await dispatch(
          getAvailableFluidBody({
            search: value,
            material: localDispenser?.material,
            deviceType: isPro ? DEVICES.SYNJETPRO : DEVICES.SYNJET,
          })
        );
        return response.map(i => ({ value: i.key, label: i.key }));
      } catch (e) {
        return [];
      }
    },
    [localDispenser?.material]
  );

  useEffect(() => {
    dispatch(getAvailableMaterials({ deviceType: isPro ? DEVICES.SYNJETPRO : DEVICES.SYNJET }))
      .then(response => {
        setMaterials(response.map(i => ({ value: i, label: i })));
      })
      .catch(() => setMaterials([]));
  }, []);

  useEffect(() => {
    if (!isPro) return;
    dispatch(getAvailableReservoirVolumes())
      .then(response => {
        setReservoirVolumes(response);
      })
      .catch(() => setReservoirVolumes([]));
  }, [isPro]);

  useEffect(() => {
    setLocalDispenser(dispenser);
    dispenserModeRef.current.dispenser = dispenser;
  }, [dispenser]);

  useEffect(() => {
    dispenserModeRef.current.localDispenser = localDispenser;
  }, [localDispenser]);

  useEffect(() => {
    dispenserModeRef.current.dispenserCompoundInfo = dispenserCompoundInfo;
  }, [dispenserCompoundInfo]);

  useEffect(() => {
    dispenserModeRef.current.localDispenserCompoundInfo = localDispenserCompoundInfo;
  }, [localDispenserCompoundInfo]);

  useEffect(() => {
    if (!isEdit) setValidationErrors({});
    dispenserModeRef.current.isEdit = isEdit;
  }, [isEdit]);

  useEffect(() => {
    dispenserModeRef.current.saveModal = saveModalOpen;
  }, [saveModalOpen]);

  useEffect(() => {
    dispenserModeRef.current.showWarning = showWarning;
  }, [showWarning]);

  useEffect(() => {
    const compoundsInfo = isNormalize
      ? dispenser?.experiments.map(experiment =>
          getCompoundNormalizeInfo(experiment.experiment_uuid, experiments, experiment.calculated_volume)
        )
      : dispenser?.experiments.map(experiment =>
          getCompoundInfo(
            experiment.compound_name,
            experiment.experiment_uuid,
            experiments,
            experiment.calculated_volume
          )
        );
    setDispenserCompoundInfo(compoundsInfo);
    setLocalDispenserCompoundInfo(compoundsInfo);
  }, [dispenser?.experiments, experiments]);

  const handleCompoundAwayClick = () => {
    if (
      dispenserModeRef.current.isEdit &&
      !dispenserModeRef.current.saveModal &&
      !dispenserModeRef.current.showWarning
    ) {
      if (
        !isEqual(dispenserModeRef.current.localDispenser, dispenserModeRef.current.dispenser) ||
        !isEqual(dispenserModeRef.current.dispenserCompoundInfo, dispenserModeRef.current.localDispenserCompoundInfo)
      ) {
        setDiscardModalOpen(true);
      } else setEdit(false);
    }
  };

  useOutsideAlerter(dispenserRef, handleCompoundAwayClick);

  const handleDispenserChangesDiscard = e => {
    e.stopPropagation();
    setDiscardModalOpen(false);
    setEdit(false);
    setLocalDispenser(dispenser);
    setLocalDispenserCompoundInfo(dispenserCompoundInfo);
  };

  const parseSolvents = experiment => {
    if (experiment?.solvents.find(solvent => solvent.solvent.compound_name === 'None')) return null;
    return experiment?.solvents.map(solvent => ({
      compoundId: solvent?.solvent?.compound_id,
      fraction: solvent.fraction,
    }));
  };
  const handleSave = e => {
    if (e) e.stopPropagation();
    const materials =
      isManual || isEqual(localDispenser, dispenser)
        ? null
        : {
            backpack: localDispenser?.backpack,
            fluidBody: localDispenser?.fluid_body,
            material: localDispenser?.material,
          };
    const updatedExperiments = localDispenserCompoundInfo
      .filter((compound, compoundIndex) => !isEqual(compound, dispenserCompoundInfo?.[compoundIndex]))
      .map(experiment =>
        isNormalize
          ? {
              experiment: experiment?.experimentId,
              reactionsInfo: getExperimentUpdatedReactionInfo(experiment?.experimentId, experiments, null, [
                experiment?.solvent,
              ]),
              isNormalized: true,
              solvents: [
                {
                  compoundId: experiment.solvent?.compound_id,
                  fraction: 1,
                },
              ],
              calculatedVolume: experiment.calculatedVolume,
              isManually: dispenser.is_manually,
            }
          : {
              experiment: experiment?.experimentId,
              reactionsInfo: getExperimentUpdatedReactionInfo(
                experiment?.experimentId,
                experiments,
                experiment?.compoundName,
                experiment?.solvents
              ),
              compoundId: experiment?.compound?.compound_id,
              compoundName: experiment?.compoundName,
              isNormalized: false,
              solvents: parseSolvents(experiment),
              calculatedVolume: experiment.calculatedVolume,
              isManually: dispenser.is_manually,
            }
      );

    const data = {
      batch: batchId,
      dispenser: dispenser?.uuid,
      calibration: isManual ? null : localDispenser?.calibration,
      materials,
      experiments: updatedExperiments.length ? updatedExperiments : null,
      isPro,
      reservoirVolume: isManual ? null : localDispenser.reservoir_volume,
    };
    dispatch(updateBatchDispensers(data))
      .then(resp => {
        if (resp.updateBatchDispenser?.warnings) {
          resp.updateBatchDispenser.warnings.forEach(warn => {
            warn.messages.forEach(msg => {
              const parsedWarn = msg.replace(`[${dispenser.uuid}]`, dispenserNumber);
              openNotification(null, null, null, null, null, parsedWarn);
            });
          });
        }
        getDetails();
        setEdit(false);
      })
      .catch(errors => {
        errors.forEach(e => {
          const message = e?.messages?.[0] || '';
          const parsedError = message.replace(`[${dispenser.uuid}]`, dispenserNumber);
          openNotification(null, parsedError);
        });
      })
      .finally(() => {
        setSaveModalOpen(false);
        setShowWarning(false);
      });
  };

  const handleManualChange = experimentId => {
    const updatedExperiments = dispenserCompoundInfo
      .filter(compound => compound.experimentId === experimentId)
      .map(experiment =>
        isNormalize
          ? {
              experiment: experiment?.experimentId,
              isNormalized: true,
              solvents: [
                {
                  compoundId: experiment.solvent?.compound_id,
                  fraction: 1,
                },
              ],
              calculatedVolume: experiment.calculatedVolume,
              isManually: !dispenser.is_manually,
            }
          : {
              experiment: experiment?.experimentId,
              compoundId: experiment?.compound?.compound_id,
              compoundName: experiment?.compoundName,
              isNormalized: false,
              solvents: parseSolvents(experiment),
              calculatedVolume: experiment.calculatedVolume,
              isManually: !dispenser.is_manually,
            }
      );

    const data = {
      batch: batchId,
      dispenser: dispenser?.uuid,
      experiments: updatedExperiments.length ? updatedExperiments : null,
      isPro,
    };

    dispatch(updateBatchDispensers(data))
      .then(resp => {
        if (resp.updateBatchDispenser?.warnings) {
          resp.updateBatchDispenser.warnings.forEach(warn => {
            warn.messages.forEach(msg => {
              const parsedWarn = msg.replace(`[${dispenser.uuid}]`, dispenserNumber);
              openNotification(null, null, null, null, null, parsedWarn);
            });
          });
        }
        getDetails();
        setEdit(false);
      })
      .catch(errors => {
        errors.forEach(e => {
          const message = e?.messages?.[0] || '';
          const parsedError = message.replace(`[${dispenser.uuid}]`, dispenserNumber);
          openNotification(null, parsedError);
        });
      })
      .finally(() => {
        setSaveModalOpen(false);
        setShowWarning(false);
      });
  };

  const handleDispenserChangesSave = e => {
    e.stopPropagation();
    if (!validateSJDispenser(localDispenser, localDispenserCompoundInfo, isNormalize, setValidationErrors, isManual)) {
      return;
    }
    if (
      !isPro &&
      step &&
      (!isEqual(localDispenserCompoundInfo, dispenserCompoundInfo) || !isEqual(dispenser, localDispenser))
    ) {
      setShowWarning(true);
    } else if (!isEqual(localDispenserCompoundInfo, dispenserCompoundInfo)) setSaveModalOpen(true);
    else handleSave();
  };

  const handleDiscardSave = e => {
    e.stopPropagation();
    setSaveModalOpen(false);
    setShowWarning(false);
  };

  const handleDispenserOptionsChange = (field, value) => {
    setValidationErrors({
      ...validationErrors,
      [field]: null,
    });
    setLocalDispenser({
      ...localDispenser,
      [field]: value,
    });
  };

  useEffect(() => {
    if (localDispenser?.material === dispenser?.material) return;

    setLocalDispenser({
      ...localDispenser,
      backpack: null,
      fluid_body: null,
    });
  }, [localDispenser?.material]);

  const handleCalibrationChange = value => {
    const calibration = checkNumericField(value, 1000, 3);
    setLocalDispenser({
      ...localDispenser,
      calibration,
    });
    setValidationErrors({
      ...validationErrors,
      calibration: null,
    });
  };

  const handleCompoundUpdate = (updatedCompound, compoundIndex) => {
    setLocalDispenserCompoundInfo(
      localDispenserCompoundInfo.map((compoundInfo, index) =>
        index === compoundIndex ? updatedCompound : compoundInfo
      )
    );
  };

  const handleDispenserClick = () => {
    if (previewDispensers || isManual) return;
    setEdit(true);
  };

  return (
    <div
      className={cn('dispenser-container', {
        'dispenser-container__edit': isEdit,
        'dispenser-container__preview': previewDispensers,
        error: dispenser.validationError,
      })}
      ref={dispenserRef}
      onClick={handleDispenserClick}
    >
      <div className={cn('dispenser-container__row', { pro: isPro })}>
        <h5 className={cn('dispenser-container__title', { error: dispenser.validationError })}>
          {isManual && 'MANUAL'}
          &nbsp; {isPro ? 'SOLUTION' : 'DISPENSER'}
          &nbsp;
          {dispenserNumber}
          {dispenser.validationError && (
            <Tooltip title={DISPENSER_COUNT_ERROR} className="tooltip">
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </h5>
        {!isManual && (
          <>
            {isEdit ? (
              <>
                {isPro && (
                  <Select
                    placeholder="Select volume"
                    options={reservoirVolumes}
                    value={localDispenser?.reservoir_volume}
                    onChange={value => handleDispenserOptionsChange('reservoir_volume', value)}
                    error={validationErrors.reservoir_volume}
                    errorText={validationErrors.reservoir_volume}
                  />
                )}
                <Select
                  placeholder="Select material"
                  options={materials}
                  value={localDispenser?.material}
                  onChange={value => handleDispenserOptionsChange('material', value)}
                  error={validationErrors.material}
                  errorText={validationErrors.material}
                />
                <Select
                  typeToSearch
                  placeholder="Select backpack"
                  getOptions={getBackpack}
                  value={localDispenser?.backpack}
                  onChange={value => handleDispenserOptionsChange('backpack', value)}
                  disabled={!localDispenser?.material}
                  error={validationErrors.backpack}
                  errorText={validationErrors.backpack}
                  trigger={localDispenser?.material}
                />
                <Select
                  typeToSearch
                  placeholder="Select fluid body"
                  getOptions={getFluidBody}
                  value={localDispenser.fluid_body}
                  onChange={value => handleDispenserOptionsChange('fluid_body', value)}
                  disabled={!localDispenser?.material}
                  error={validationErrors.fluid_body}
                  errorText={validationErrors.fluid_body}
                  trigger={localDispenser?.material}
                />
                <Input
                  suffix="µL /ms"
                  value={localDispenser?.calibration}
                  onChange={handleCalibrationChange}
                  error={validationErrors.calibration}
                  errorText={validationErrors.calibration}
                />
              </>
            ) : (
              <>
                {isPro && (
                  <p className="dispenser-container__text">
                    {dispenser?.reservoir_volume ? `${dispenser?.reservoir_volume} ml` : 'N/A'}
                  </p>
                )}
                <p className="dispenser-container__text">{dispenser?.material || 'N/A'}</p>
                <p className="dispenser-container__text">{dispenser?.backpack || 'N/A'}</p>
                <p className="dispenser-container__text">{dispenser?.fluid_body || 'N/A'}</p>
                <p className="dispenser-container__text">{dispenser?.calibration || 'N/A'} µL /ms</p>
              </>
            )}
          </>
        )}
      </div>
      {dispenser?.experiments &&
        dispenser?.experiments.map((compound, compoundIndex) => (
          <SJDispenserCompound
            compound={compound}
            experiments={experiments}
            isEdit={isEdit}
            isNormalize={isNormalize}
            compoundInfo={localDispenserCompoundInfo[compoundIndex]}
            updateCompoundInfo={updatedCompound => handleCompoundUpdate(updatedCompound, compoundIndex)}
            validationErrors={validationErrors?.compoundsList?.[compoundIndex]}
            setValidationErrors={errors =>
              setValidationErrors({
                ...validationErrors,
                compoundsList: validationErrors.compoundsList.map((compoundError, errorIndex) =>
                  errorIndex === compoundIndex ? errors : compoundError
                ),
              })
            }
            isPro={isPro}
            handleManualChange={handleManualChange}
            isManual={isManual}
          />
        ))}
      {isEdit && (
        <div className="dispenser-container__btn-container">
          <Button type="secondary" className="cancel-btn" onClick={() => setDiscardModalOpen(true)}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleDispenserChangesSave}>
            Save
          </Button>
        </div>
      )}
      <Popup
        title="Discard changes"
        open={discardModalOpen}
        handleSubmit={handleDispenserChangesDiscard}
        textSubmit="Proceed"
        handleCancel={() => setDiscardModalOpen(false)}
      >
        {`All changes made for the Dispenser ${dispenserNumber} will be lost, proceed?`}
      </Popup>
      <Popup
        title="Updating Solvents"
        open={saveModalOpen}
        handleSubmit={handleSave}
        textSubmit="Proceed"
        handleCancel={handleDiscardSave}
      >
        Updating Solvents might lead to moving compounds to another dispenser, proceed?
      </Popup>
      <Popup
        open={!!showWarning}
        title="Warning"
        textSubmit="Proceed"
        handleSubmit={handleSave}
        handleCancel={handleDiscardSave}
      >
        Changes made for current wizard step will reset to default following wizard steps, proceed?
      </Popup>
    </div>
  );
};
