import React from 'react';
import { notification } from 'antd';
import './style.scss';

export const openNotification = (
  description,
  error,
  duration = 5,
  key = Math.random(),
  onClose = () => {},
  warning
) => {
  notification.open({
    description: `${warning || error || description}`,
    placement: 'bottomRight',
    className: `${warning ? 'warning-custom-class' : error ? 'error-custom-class' : 'custom-class'}`,
    style: {
      width: 350,
    },
    duration,
    bottom: 50,
    onClose,
    key,
  });
};
export const closeNotification = key => {
  if (key) notification.close(key);
};
