import './HeaderCell.styles.scss';

import React from 'react';

import { useSortContext } from '@molecules/VirtualTable/hooks/useSortController';
import cn from 'classnames';

import { TextBody12 } from '@atoms';
import { useSelectableContext } from '@molecules';

import { Tooltip } from '../../../../components';
import { ReactComponent as Sort } from '../../../../dist/images/sorting.svg';
import { COLUMN_TYPES, DND_DROP_AREA_END_EDGE, DND_DROP_AREA_START_EDGE } from '../constants';
import { useDndContext } from '../hooks/useDndController';
import { ColumnResizerDragArea } from './ColumnResizer';
import { SelectableHeaderCell } from './SelectableHeaderCell';

const useDndDragBoxController = ({ columnData: { dataIndex, acceptedDropColumns }, updateOrder }) => {
  const {
    setDragColumnIndex,
    setPotentialDropColumnIndex,
    dragColumnIndex,
    potentialDropColumnIndex,
    isDndContext,
  } = useDndContext();

  const isDragging = dragColumnIndex === dataIndex;
  const isDropping = potentialDropColumnIndex === dataIndex;
  const onDragEnter = () => {
    if (acceptedDropColumns.includes(dragColumnIndex)) {
      if (potentialDropColumnIndex !== dataIndex) {
        setPotentialDropColumnIndex(dataIndex);
      }
    } else {
      setPotentialDropColumnIndex(null);
    }
  };
  const onDragEnd = () => {
    setDragColumnIndex(null);
    setPotentialDropColumnIndex(null);
  };

  const onDrop = () => {
    if (dragColumnIndex && potentialDropColumnIndex) {
      updateOrder({
        dataIndex: dragColumnIndex,
        dropAreaDataIndex: potentialDropColumnIndex,
      });
      setDragColumnIndex(null);
      setPotentialDropColumnIndex(null);
    }
  };

  const onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragStart = () => setDragColumnIndex(dataIndex);

  return {
    onDragEnter,
    onDragEnd,
    onDrop,
    onDragOver,
    onDragStart,
    isDragging,
    isDropping,
    isDndContext,
    potentialDropColumnIndex,
  };
};

export const HeaderCell = ({ children, className, style, columnIndex, isLast, ...props }) => {
  const { dataIndex, title, sortValue } = props.columnData;
  const { isDragging, isDropping, isDndContext, potentialDropColumnIndex, ...actions } = useDndDragBoxController(props);
  const { onSort } = useSortContext();
  const { onSelectAll, isAllSelected, isSelectableContext } = useSelectableContext();

  if (dataIndex === COLUMN_TYPES.selectable) {
    return (
      <SelectableHeaderCell
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        style={style}
        className="header-cell is-first"
      />
    );
  }

  const onSortEvent = e => {
    e.stopPropagation();
    e.preventDefault();
    if (sortValue) {
      onSort({ sortValueColumn: sortValue });
    }
  };
  const baseContent = () => (
    <>
      <Tooltip title={title} placement="top">
        <span className="header-cell_title">
          <TextBody12>{title}</TextBody12>
        </span>
      </Tooltip>
      {!!sortValue && <SortIcon sortValue={sortValue} />}
    </>
  );

  const columnIndexWithOffset = isSelectableContext ? columnIndex - 1 : columnIndex;
  const isFirst = columnIndexWithOffset === 0;

  let content = <div className={cn('header-cell', { 'is-first': columnIndex === 0 })}>{baseContent()}</div>;

  if (isDndContext) {
    content = (
      <div
        data={dataIndex}
        draggable
        className={cn('header-cell dnd-drag-box', className, {
          active: isDragging,
          'active-drop': isDropping,
          'is-first': columnIndex === 0,
          'drop-area-start-edge': isFirst && potentialDropColumnIndex === DND_DROP_AREA_START_EDGE,
          'drop-area-end-edge': isLast && potentialDropColumnIndex === DND_DROP_AREA_END_EDGE,
        })}
        {...actions}
      >
        {baseContent()}
      </div>
    );
  }

  return (
    <div onClick={onSortEvent} style={style}>
      <ColumnResizerDragArea columnIndex={columnIndex}>{content}</ColumnResizerDragArea>
    </div>
  );
};

const SortIcon = ({ sortValue: sortValueColumn }) => {
  const { sortValue } = useSortContext();
  return (
    <div
      className={cn('header-cell_sort-icon', {
        ascend: sortValue === sortValueColumn,
        descend: `-${sortValueColumn}` === sortValue,
      })}
    >
      <Sort />
    </div>
  );
};
