import React, { useState } from 'react';
import './style.scss';
import { CompoundSmilesPreview, Input } from '../../../index';
import { SmilesSearcher } from '../../../SmilesSearcher';
import { checkNumericField } from '../../../../utils';

const SynJetProductInfoEdit = ({ compound, setCompound, validationErrors, clearValidationErrors }) => {
  const [selectValue, setSelectValue] = useState('name');

  const handleSmilesChange = smiles => {
    clearValidationErrors('smiles');
    if (smiles?.compound_id) setCompound({ ...compound, smiles: smiles });
  };

  return (
    <>
      <div className="synjet-compound__info__smiles">
        <>
          <SmilesSearcher
            synjet
            changeSelect={handleSmilesChange}
            smilesPlaceholder="Search"
            data={compound?.smiles}
            handleCompoundChange={() => setCompound({ ...compound, smiles: null })}
            initialSearchValue={compound?.smiles?.compound_name}
            setSelectValue={setSelectValue}
            error={selectValue === 'name' ? validationErrors?.name : validationErrors?.smiles}
            errorText={selectValue === 'name' ? validationErrors?.name : validationErrors?.smiles}
          />
          <CompoundSmilesPreview
            compound={compound?.smiles}
            className="smiles-preview"
            options={{ width: 130, height: 80 }}
            hideMW
            showTooltip
          />
        </>
      </div>
      <div className="synjet-compound__info-product__mass">
        <span className="synjet-compound__text">{compound?.smiles?.exactmolwt || 'N/A'}</span>
      </div>
    </>
  );
};

export default SynJetProductInfoEdit;
