/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import './style.scss';
import { Table } from '../../../../Common';
import useColumns from './hooks/useColumns';
import setRowTable from './utils/setRowTable';

export const setFixed = (value, fixedValue = 2) => {
  if (!value && value !== 0) return '';
  const valueSplit = `${value}`.split('.');
  if (valueSplit.length === 2) {
    return value.toFixed(fixedValue);
  }
  return `${value}.00`;
};

export const LoadMaterialsTable = ({ tableData, setTableData, getCompound }) => {
  const handleChangeSwitch = (value, index, rowData) => {
    setTableData(
      tableData.map((i, idx) => {
        if (index === idx) {
          return {
            ...i,
            has_sent: value,
          };
        }
        return i;
      })
    );
  };

  const memoizedColumns = useColumns({ handleChangeSwitch, tableData });

  const setMappedTableData = data => (data ? data.map((i, idx) => setRowTable(i, idx, getCompound)) : []);

  return (
    <Table
      rowClassName="syn-jet-materials_lm-table_row"
      columns={memoizedColumns}
      dataSource={setMappedTableData(tableData)}
      sidePadding={false}
    />
  );
};
