import { COMPOUND_VARIATY } from '../../constants';

const getDataOnLimitedData = (data, isLimited) => {
  return data.map(item => (isLimited ? { ...item, limiting_reagent: false } : item));
};

export const recalculatePumpOnLimitedCompound = (pump, isLimited) => {
  return {
    ...pump,
    properties: pump.properties.map(pumpProperty => ({
      ...pumpProperty,
      reactants: getDataOnLimitedData(pumpProperty.reactants, isLimited),
      reagents: getDataOnLimitedData(pumpProperty.reagents, isLimited),
    })),
  };
};

export const getUpdatedPumpCompound = (isEdit, updatedPumpProperty, positionToAddReactor, compound) => {
  const updatedProperty = compound.reagent
    ? 'reagents'
    : compound.reactant
    ? 'reactants'
    : compound.solvent
    ? 'solvents'
    : '';
  const getUpdatedPumpProperty = (isSolvent, isLimiting) => {
    return isSolvent || !isLimiting
      ? updatedPumpProperty[updatedProperty]
      : updatedPumpProperty[updatedProperty].map(item => ({ ...item, limiting_reagent: false }));
  };
  return {
    [updatedProperty]: isEdit
      ? updatedPumpProperty[updatedProperty].map((item, cpdIdx) =>
          cpdIdx === positionToAddReactor.compoundIdx
            ? compound
            : compound.limiting_reagent
            ? { ...item, limiting_reagent: false }
            : item
        )
      : updatedPumpProperty && updatedPumpProperty[updatedProperty]
      ? [compound, ...getUpdatedPumpProperty(updatedProperty === 'solvents', compound.limiting_reagent)]
      : [compound],
  };
};

export const calculateCompound = (compound, pump, limitingReagent) => {
  return pump.flow_rate.map((flowRate, flowRateIndex) => {
    const calculatedMFR = flowRate * compound.concentration * 1000 * 0.001 * 1;
    return limitingReagent
      ? compound.limiting_reagent
        ? 1
        : calculatedMFR / limitingReagent.molar_flow_rate[flowRateIndex]
      : 'N/A';
  });
};

const findVariativeLimitingReagent = prop => {
  let limitingReagent = null;
  if (prop)
    [...prop.reagents, ...prop.reactants].forEach(compound => {
      if (compound.limiting_reagent) limitingReagent = compound;
    });
  return limitingReagent;
};

export const calculateCompoundWithVariation = (compound, pump, limitingReagent, variationData, pumpPropIndex) => {
  const isVariative = pump.properties.length > 1;
  const calculatedMFR = pump.flow_rate[0] * compound.concentration * 1000 * 0.001 * 1;
  const variatyLimitingReagent = variationData.properties.map(prop => findVariativeLimitingReagent(prop));
  const variatyLimitingReagentSelected = variatyLimitingReagent.filter(reagent => !!reagent);
  if (isVariative) {
    if (compound.limiting_reagent) return [1];
    else {
      const variatyLimitedCompound = variatyLimitingReagent[pumpPropIndex];
      if (!variatyLimitedCompound) {
        if (variatyLimitingReagentSelected.length) return ['N/A'];
        return limitingReagent ? [Number(calculatedMFR / limitingReagent.molar_flow_rate[0])] : ['N/A'];
      }
      return [Number(calculatedMFR / variatyLimitedCompound.molar_flow_rate[0])];
    }
  }
  return variatyLimitingReagent.map(variaty => {
    return variaty
      ? compound.limiting_reagent
        ? 1
        : Number(calculatedMFR / variaty.molar_flow_rate[0])
      : !variatyLimitingReagentSelected.length && limitingReagent
      ? compound.limiting_reagent
        ? 1
        : Number(calculatedMFR / limitingReagent.molar_flow_rate[0])
      : 'N/A';
  });
};

export const calculatePumpCompounds = (data, pump, limitingReagent, variationData, pumpPropIndex) => {
  return data.map(compound => {
    compound.equivalents = variationData
      ? calculateCompoundWithVariation(compound, pump, limitingReagent, variationData, pumpPropIndex)
      : calculateCompound(compound, pump, limitingReagent);

    return compound;
  });
};
