import { webSocket } from 'rxjs/webSocket';
import { WEBSOCKET_HOST } from '../../constants';

const getSocketPath = path => {
  return `wss://${WEBSOCKET_HOST}/api/ws${path}?token=${localStorage.getItem('token')}`;
};

export class WebsocketService {
  reconnect;
  websocketSubject;
  path;

  constructor(path) {
    this.reconnect = true;
    this.path = path;
    this.websocketSubject = webSocket(getSocketPath(path));
  }

  // pass error handler only if you want to rewrite default functionality, and consider the logic of reconnecting the socket on error
  subscribe(msgHandler, errorCallback) {
    this.websocketSubject.subscribe(
      msg => msgHandler(msg),
      error => {
        console.error(`Error on ${this.path}:`, error);
        if (errorCallback) {
          errorCallback();
          return;
        }
        if (this.reconnect) {
          this.websocketSubject.unsubscribe();
          this.websocketSubject = webSocket(getSocketPath(this.path));
          this.subscribe(msgHandler, errorCallback);
        }
      },
      () => {
        if (this.reconnect) {
          this.subscribe(msgHandler, errorCallback);
        } else {
        }
      }
    );
  }

  unsubscribe() {
    this.reconnect = false;
    this.websocketSubject.unsubscribe();
  }
}
