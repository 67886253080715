import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import List from './List/List';

export const Users = props => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={List} />
    </Switch>
  );
};
