import React, { useEffect, useState } from 'react';
import './style.scss';
import moment from 'moment';
import { formatSecondsToTimeString } from '../../../../utils';
import { EXPERIMENT_STATUSES } from '../../../../constants';

let timer;
let countdown;

export const ExperimentTimer = ({
  estimatedTime,
  experimentDelay,
  startedAt,
  batchStartTime,
  experimentStatus,
  experimentActualTime,
}) => {
  const [actualTime, setActualTime] = useState(0);
  const [startingIn, setStaringIn] = useState(0);
  const stopTimer =
    experimentStatus === EXPERIMENT_STATUSES.Completed.value ||
    experimentStatus === EXPERIMENT_STATUSES.PendingFinalization.value;

  useEffect(() => {
    if (startedAt || stopTimer) {
      setStaringIn(0);
    } else if (batchStartTime) {
      const batchStart = moment(batchStartTime).add(experimentDelay || 0, 'seconds');
      const starting = moment(batchStart).diff(moment(), 'seconds');
      setStaringIn(starting < 0 ? 0 : starting);
    } else if (!batchStartTime && !startedAt) {
      setStaringIn(experimentDelay);
    }
  }, [experimentDelay, batchStartTime, startedAt]);

  useEffect(() => {
    if (countdown) clearTimeout(countdown);
    if (startingIn > 0 && !stopTimer && batchStartTime) {
      countdown = setTimeout(() => {
        setStaringIn(startingIn - 1);
      }, 1000);
    }
  }, [startingIn]);

  useEffect(() => {
    if (!startedAt) {
      setActualTime(0);
    } else if (startedAt) {
      let starting = moment().diff(moment(startedAt), 'seconds');
      starting = starting < 0 ? 0 : starting;
      setActualTime(stopTimer ? experimentActualTime || starting : starting);
    }
  }, [startedAt, experimentStatus]);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    if (actualTime >= 0 && !stopTimer && startedAt !== null && startedAt !== undefined) {
      timer = setTimeout(() => {
        setActualTime(actualTime + 1);
      }, 1000);
    }
  }, [actualTime]);

  return (
    <div className="batch-experiment-timer">
      <div className="batch-experiment-timer__section">
        <p className="batch-experiment-timer__title">Actual time</p>
        <p className="batch-experiment-timer__time">{formatSecondsToTimeString(actualTime || 0)}</p>
      </div>
      <div className="batch-experiment-timer__section">
        <p className="batch-experiment-timer__title">Estimated time</p>
        <p className="batch-experiment-timer__time">{formatSecondsToTimeString(estimatedTime || 0)}</p>
      </div>
      <div className="batch-experiment-timer__section">
        <p className="batch-experiment-timer__title">Starting in</p>
        <p className="batch-experiment-timer__time">{formatSecondsToTimeString(startingIn || 0)}</p>
      </div>
    </div>
  );
};
