import { formatSecondsToMinutesDect } from 'utils';

export const getBatchConfigurationTableColumns = () => {
  return [
    {
      title: 'Vial #',
      dataIndex: 'rowIndex',
      key: 'rowIndex',
      render: text => text || '-',
      className: 'right-bordered white-colored',
      sorter: {
        compare: (a, b) => a.rowIndex - b.rowIndex,
      },
    },
    {
      title: 'Experiment #',
      dataIndex: 'experimentIndex',
      key: 'experimentIndex',
      render: text => text || '-',
      sorter: {
        compare: (a, b) => a.experimentIndex - b.experimentIndex,
      },
    },
    {
      title: 'Experiment ID',
      dataIndex: 'experiment_name',
      key: 'experiment_name',
      render: text => text || '—',
      className: 'experiment-name',
    },
    {
      title: 'Index',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Source  position',
      dataIndex: 'source_position',
      key: 'source_position',
      render: text => text || '—',
      className: 'right-bordered',
    },
    {
      title: 'Heating  position',
      dataIndex: 'heating_position',
      key: 'heating_position',
      render: text => text || '—',
    },
    {
      title: 'Temperature,  °C',
      dataIndex: 'temperature',
      key: 'temperature',
      render: text => text || '—',
      sorter: {
        compare: (a, b) => a.temperature - b.temperature,
      },
    },
    {
      title: 'Temperature  block',
      dataIndex: 'temperature_block',
      key: 'temperature_block',
      render: text => text || '—',
    },
    {
      title: 'Heat place  time, min',
      dataIndex: 'heating_time',
      key: 'heating_time',
      render: v => formatSecondsToMinutesDect(v, 2) || '—',
      sorter: {
        compare: (a, b) => a.heating_time - b.heating_time,
      },
    },
    {
      title: 'Reaction  time, min',
      dataIndex: 'reaction_time',
      key: 'reaction_time',
      render: v => formatSecondsToMinutesDect(v, 2) || '—',
      sorter: {
        compare: (a, b) => a.reaction_time - b.reaction_time,
      },
    },
    {
      title: 'Actual reaction  time, min',
      dataIndex: 'actual_reaction_time',
      key: 'actual_reaction_time',
      render: v => formatSecondsToMinutesDect(v, 2) || '—',
      className: 'right-bordered',
      sorter: {
        compare: (a, b) => a.actual_reaction_time - b.actual_reaction_time,
      },
    },
    {
      title: 'Cool place  time, min',
      dataIndex: 'cooling_time',
      key: 'cooling_time',
      render: v => formatSecondsToMinutesDect(v, 2) || '—',
      className: 'left-bordered',
      sorter: {
        compare: (a, b) => a.cooling_time - b.cooling_time,
      },
    },
    {
      title: 'Cool  position',
      dataIndex: 'cooling_position',
      key: 'cooling_position',
      render: text => text || '—',
      className: 'right-bordered',
    },
    {
      title: 'Removal  time, min',
      dataIndex: 'removal_time',
      key: 'removal_time',
      render: v => formatSecondsToMinutesDect(v, 2) || '—',
      sorter: {
        compare: (a, b) => a.removal_time - b.removal_time,
      },
    },
    {
      title: 'Well plate  position',
      dataIndex: 'removal_position',
      key: 'removal_position',
      render: text => text || '—',
      className: 'right-bordered',
    },
    {
      title: 'Delta T',
      dataIndex: 'removal_time_delta',
      key: 'removal_time_delta',
      render: v => formatSecondsToMinutesDect(v, 2) || '—',
      className: 'white-colored fixed-width delta-item',
    },
  ];
};
