import React, { useEffect, useState } from 'react';
import { Table } from '../../Common';
import { getCoefficientColumns } from './columns';
import cn from 'classnames';

export const CoefficientsTable = ({
  experimentData,
  interpolated = false,
  setIsBottom,
  changeTableSort,
  isProjectPage,
  analytics,
  showEmptyTooltip = false,
}) => {
  const [tableData, setTableData] = useState([]);
  const [columnsNames, setColumnsNames] = useState([]);
  const [numberColumns, setNumberColumns] = useState(0);

  const scrollBlock = document.querySelector(
    isProjectPage ? '.tree-element-details__content' : '.experiment-details_body'
  );

  useEffect(() => {
    if (interpolated) {
      scrollBlock.addEventListener('scroll', handleScroll);
      return () => {
        scrollBlock.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  function handleScroll() {
    if (
      scrollBlock.scrollTop + 200 >=
      document.querySelector('.regression').offsetHeight +
        document.querySelector('.analytical-reactions-table').offsetHeight +
        document.querySelector('.results-info-row').offsetHeight
    ) {
      setIsBottom(true);
    }
  }
  useEffect(() => {
    if (!!experimentData) {
      if (!!JSON.parse(experimentData?.data)?.df_interpolated_columns) {
        parseBackendInterpolatedData(JSON.parse(experimentData.data));
      }
    }
  }, [experimentData?.data]);

  const parseBackendInterpolatedData = data => {
    let arr_columns;
    let resultData;
    arr_columns = getColumns(interpolated ? data.df_interpolated_columns : data.df_coefficients_columns);
    resultData = getDataForTable(interpolated ? data.df_interpolated : data.df_coefficients, arr_columns, interpolated);
    setTableData(resultData);
  };

  const getColumns = columns => {
    let numberColumns = 0;
    let arr_columns = [];
    columns.some((item, index) => {
      let arr = item.split('_');
      if (arr.length > 0 && arr[1] === 'color') {
        numberColumns = index;
      }
      return !!numberColumns;
    });

    arr_columns = columns
      .map(column => {
        if (column === 'Temperature') {
          return `${column}, C`;
        } else if (column === 'Time') {
          return `${column}, min`;
        }
        return column;
      })
      .slice(0, numberColumns);

    arr_columns.unshift('#');

    let u = [];
    if (analytics?.length)
      for (let i = 0; i < arr_columns.length; i++) {
        let cur;
        for (let k = 0; k < analytics.length; k++) {
          if (analytics[k].label === arr_columns[i]) {
            cur = analytics[k];
            break;
          } else {
            cur = arr_columns[i];
          }
        }
        u.push(cur);
      }
    setColumnsNames(u);
    return u;
  };

  const getDataForTable = (dataTable, arr_columns, isInt) => {
    let arr_values = [];
    let arr_colors = [];
    let resultData = [];
    dataTable.forEach((item, index) => {
      let row = {};
      arr_values = item.slice(0, isInt ? arr_columns.length : arr_columns.length - 1);
      if (!interpolated) {
        arr_values.unshift(index);
      }
      arr_colors = item.slice(arr_columns.length - 1);
      arr_columns.forEach((i, index) => {
        row[i.label ? i.label : i] = {
          label: i.label ? i.label : i,
          value: !!index ? arr_values[index] : arr_values[index] + 1,
          color: !!index ? arr_colors[index] : null,
        };
      });
      resultData.push(row);
    });
    setNumberColumns(numberColumns);
    return resultData;
  };

  return (
    <div className={cn('analytical-settings regression', { 'regression-interpolated': interpolated })}>
      {!!tableData.length && (
        <Table
          scroll={{ x: true }}
          dataSource={tableData}
          sidePadding={false}
          columns={getCoefficientColumns(columnsNames, interpolated, showEmptyTooltip)}
          changeStateTable={(pagination, filters, sorter) => {
            let i = columnsNames.findIndex(item => (item.label ? item.label === sorter.field : item === sorter.field));
            if (i || i === 0) changeTableSort(sorter, i);
          }}
        />
      )}
    </div>
  );
};
