import React from 'react';

import moment from 'moment';

import { PROJECT_TYPES } from '../../../constants';
import { Button, openNotification } from '../../Common';

export const getInitialYields = length => new Array(length).fill(null).map(() => null);

export const getExperimentModalTitle = (step, process, singleReaction, showProjectForm, isSynjet, isEdit, isLab) => {
  if (isLab) {
    if (process?.type !== PROJECT_TYPES.PRODUCTION && singleReaction)
      return isEdit ? 'Edit an experiment' : 'Create an experiment';
    switch (step) {
      case 0:
        return isEdit ? 'Step 1: Edit reactions' : 'Step 1: Select reactions';
      case 1:
        return isEdit ? 'Step 2: Edit an experiment' : 'Step 2: Create an experiment';
      default:
        return '';
    }
  }
  if (
    process &&
    !showProjectForm &&
    !isSynjet &&
    (process.type === PROJECT_TYPES.PRODUCTION || (process.type !== PROJECT_TYPES.PRODUCTION && singleReaction))
  ) {
    switch (step) {
      case 0:
        return isEdit ? 'Step 1: Edit an experiment' : 'Step 1: Create an experiment';
      case 1:
        return isEdit ? 'Step 2: Edit analytical settings' : 'Step 2: Analytical settings';
      default:
        return '';
    }
  }
  switch (step) {
    case 0:
      return isEdit ? 'Step 1: Edit reactions' : 'Step 1: Select reactions';
    case 1:
      return isEdit ? 'Step 2: Edit an experiment' : 'Step 2: Create an experiment';
    case 2:
      return isEdit ? 'Step 3: Edit analytical settings' : 'Step 3: Analytical settings';
    default:
      return '';
  }
};

export const getExperimentModalFooter = (
  process,
  step,
  handleCancel,
  handleConfirm,
  handleBack,
  handleCreate,
  handleSubmit,
  creationLoader,
  showProjectForm,
  showBackBtn,
  isSynJet,
  confirmLoading,
  isEdit,
  handleEditConfirmation,
  isLab,
  showFirstStepIsLab = false,
  creationLoaderSubmit
) => {
  if (isLab) {
    if (!showFirstStepIsLab && (process?.type === PROJECT_TYPES.PRODUCTION || !showBackBtn)) {
      return (
        <div className="createExperimentModal__footer">
          <Button type="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <div>
            <Button
              type="primary"
              loading={!creationLoaderSubmit && creationLoader}
              disabled={creationLoaderSubmit}
              onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: false }) : handleCreate(false))}
            >
              {isEdit ? 'Save' : 'Create'}
            </Button>
            <Button
              type="primary"
              disabled={!creationLoaderSubmit && creationLoader}
              loading={creationLoaderSubmit}
              onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: true }) : handleSubmit())}
            >
              {isEdit ? 'Save and submit' : 'Create and submit'}
            </Button>
          </div>
        </div>
      );
    }
    switch (step) {
      case 0:
        return (
          <div className="createExperimentModal__footer">
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleConfirm} loading={confirmLoading}>
              Confirm
            </Button>
          </div>
        );
      case 1:
        return (
          <div className="createExperimentModal__footer">
            <div>
              <Button type="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="secondary" onClick={handleBack}>
                Back
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                loading={!creationLoaderSubmit && creationLoader}
                disabled={creationLoaderSubmit}
                onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: false }) : handleCreate(false))}
              >
                {isEdit ? 'Save' : 'Create'}
              </Button>
              <Button
                type="primary"
                disabled={!creationLoaderSubmit && creationLoader}
                loading={creationLoaderSubmit}
                onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: true }) : handleSubmit())}
              >
                {isEdit ? 'Save and submit' : 'Create and submit'}
              </Button>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }
  if (!isSynJet && !showProjectForm && (process?.type === PROJECT_TYPES.PRODUCTION || !showBackBtn)) {
    switch (step) {
      case 0:
        return (
          <div className="createExperimentModal__footer">
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleConfirm} loading={confirmLoading}>
              Confirm
            </Button>
          </div>
        );
      case 1:
        return (
          <div className="createExperimentModal__footer">
            <div>
              <Button type="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="secondary" onClick={handleBack}>
                Back
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                loading={!creationLoaderSubmit && creationLoader}
                disabled={creationLoaderSubmit}
                onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: false }) : handleCreate(false))}
              >
                {isEdit ? 'Save' : 'Create'}
              </Button>
              <Button
                type="primary"
                disabled={!creationLoaderSubmit && creationLoader}
                loading={creationLoaderSubmit}
                onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: true }) : handleSubmit())}
              >
                {isEdit ? 'Save and submit' : 'Create and submit'}
              </Button>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }
  switch (step) {
    case 0:
      return (
        <div className="createExperimentModal__footer">
          <Button type="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleConfirm} loading={confirmLoading}>
            Confirm
          </Button>
        </div>
      );
    case 1:
      return (
        <div className="createExperimentModal__footer">
          <div>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="secondary" onClick={handleBack}>
              Back
            </Button>
          </div>
          <Button type="primary" onClick={handleConfirm} loading={confirmLoading}>
            Confirm
          </Button>
        </div>
      );
    case 2:
      return (
        <div className="createExperimentModal__footer">
          <div>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="secondary" onClick={handleBack}>
              Back
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              loading={!creationLoaderSubmit && creationLoader}
              disabled={creationLoaderSubmit}
              onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: false }) : handleCreate(false))}
            >
              {isEdit ? 'Save' : 'Create'}
            </Button>
            <Button
              type="primary"
              disabled={!creationLoaderSubmit && creationLoader}
              loading={creationLoaderSubmit}
              onClick={() => (isEdit ? handleEditConfirmation({ isSubmitting: true }) : handleSubmit())}
            >
              {isEdit ? 'Save and submit' : 'Create and submit'}
            </Button>
          </div>
        </div>
      );
    default:
      return <div />;
  }
};

export const validateAnalyticData = (analyticData, setErrors) => {
  let valid = true;
  setErrors({});
  const analyticDataErrors = [];
  analyticData.forEach((data, index, dataArray) => {
    analyticDataErrors[index] = {};
    if (!data.label?.trim()) {
      analyticDataErrors[index].label = 'Label for chart is a required field';
      valid = false;
    }
    if (data.label?.trim() && dataArray.findIndex(analytic => analytic.label === data.label) !== index) {
      analyticDataErrors[index].label = 'Label for chart should have a unique value within the same Experiment';
      valid = false;
    }
    if (data.observ <= 0) {
      analyticDataErrors[index].observ = 'Observed mass should be > 0';
      valid = false;
    }
    if (!data.interval_start && data.interval_start !== 0) {
      analyticDataErrors[index].interval_start = 'Interval Start is a required field';
      valid = false;
    }
    if (!data.interval_end && data.interval_end !== 0) {
      analyticDataErrors[index].interval_end = 'Interval End is a required field';
      valid = false;
    }
    if (data.interval_end && data.interval_start && data.interval_end <= data.interval_start) {
      analyticDataErrors[index].interval_end = 'Interval end should be > Interval start';
      valid = false;
    }
    if (
      data.interval_end &&
      data.interval_start &&
      dataArray.findIndex(
        analytic =>
          analytic.label === data.label &&
          analytic.observ === data.observ &&
          data.interval_end == analytic.interval_end &&
          data.interval_start == analytic.interval_start
      ) !== index
    ) {
      analyticDataErrors[index].uniqueError = true;
      valid = false;
    }
  });
  if (analyticDataErrors.some(error => error.uniqueError))
    openNotification('', 'This set of parameters should be unique for each compound');
  setErrors({ analyticData: analyticDataErrors });
  return valid;
};

export const parseReactionsInfo = experimentEeactionsInfo => {
  const reactionsInfo = {};
  for (const [key, value] of Object.entries(experimentEeactionsInfo)) {
    reactionsInfo[key] = value.requested_output
      ? {
          ...value,
          requested_output: +value.requested_output,
          estimated_yields: value.estimated_yields.map(estim => +estim),
        }
      : value;
  }

  return reactionsInfo;
};

export const validateExperimentInfo = (
  experimentData,
  processType,
  setErrors,
  isSynjet,
  timeCalculationValidation,
  isLab
) => {
  let valid = true;
  setErrors({});
  const newErrors = {};
  // common validation
  if (!timeCalculationValidation) {
    if (!experimentData.name) {
      newErrors.name = 'Please enter the Experiment name';
      valid = false;
    }
    if ((experimentData.name && experimentData.name.length < 3) || !experimentData.name.trim()) {
      newErrors.name = 'Experiment name must have at least 3 characters';
      valid = false;
    }
  }
  if (isLab) {
    const checkIfZero =
      experimentData.timePerReactionHour == 0 &&
      experimentData.timePerReactionMin == 0 &&
      experimentData.timePerReactionSec == 0;
    if (
      (!experimentData.timePerReactionHour || checkIfZero) &&
      (!experimentData.timePerReactionMin || checkIfZero) &&
      (!experimentData.timePerReactionSec || checkIfZero)
    ) {
      newErrors.timePerReactionHour = 'Please enter the hour';
      newErrors.timePerReactionMin = 'Please enter the minutes';
      newErrors.timePerReactionSec = 'Please enter the seconds';
      valid = false;
    }
  } else if (!isSynjet) {
    if (experimentData.useAnalytic === 'use' && !experimentData.analytics.length && !timeCalculationValidation) {
      newErrors.analytics = 'Please select at least 1 analytical tool';
      valid = false;
    }
    // optimization
    if (processType === PROJECT_TYPES.OPTIMIZATION) {
      if (!experimentData.timePerReaction || experimentData.timePerReaction < 0.001) {
        newErrors.timePerReaction = !experimentData.timePerReaction
          ? 'Please enter the time per reaction'
          : 'Ensure this value is greater than or equal to 0.001.';
        valid = false;
      }
    } else {
      for (const [reactionKey, reactionInfo] of Object.entries(experimentData.reactionsInfo)) {
        if (!reactionInfo.requested_output || reactionInfo.requested_output < 0.1) {
          const reactionInfoErrors =
            newErrors?.reactionsInfo && newErrors?.reactionsInfo[reactionKey]
              ? newErrors?.reactionsInfo[reactionKey]
              : {};
          newErrors.reactionsInfo = {
            ...newErrors.reactionsInfo,
            [reactionKey]: {
              ...reactionInfoErrors,
              requested_output:
                reactionInfo.requested_output &&
                (reactionInfo.requested_output === 0 || reactionInfo.requested_output < 0.1)
                  ? 'Cannot be less than 0.1.'
                  : 'Please enter the requested output',
            },
          };
          valid = false;
        }
        if (reactionInfo.estimated_yields.some(yieldValue => yieldValue < 0.1)) {
          const reactionInfoErrors =
            newErrors?.reactionsInfo && newErrors?.reactionsInfo[reactionKey]
              ? newErrors?.reactionsInfo[reactionKey]
              : {};
          newErrors.reactionsInfo = {
            ...newErrors.reactionsInfo,
            [reactionKey]: {
              ...reactionInfoErrors,
              estimated_yields: reactionInfo.estimated_yields.map((yieldValue, yieldIndex) => {
                if (typeof parseFloat(yieldValue) === 'number' && parseFloat(yieldValue) < 0.1) {
                  return 'Cannot be less than 0.1.';
                }
                if (!yieldValue) {
                  return `Please enter the Step ${yieldIndex + 1} estimated yield`;
                }
                return null;
              }),
            },
          };
          console.log(newErrors.reactionsInfo);
          valid = false;
        }
      }
    }
  }
  setErrors(newErrors);
  return valid;
};
