import React from 'react';
import classNames from 'classnames';
import { SJDispenser } from './SJDispenser';

export const SJDispenserList = ({
  dispenserList,
  experiments,
  isNormalize,
  offset = 0,
  batchId,
  getDetails,
  step,
  errors,
  previewDispensers,
  isPro,
  isManual = false,
}) => (
  <>
    {!isManual && (
      <div className={classNames('dispenser-header-container', { pro: isPro })}>
        <p />
        {isPro && <p>Reservoir volume</p>}
        <p>Dispenser material</p>
        <p>Backpack</p>
        <p>Fluid body</p>
        <p>Calibration</p>
      </div>
    )}
    {dispenserList.map((dispenser, dispenserIndex) => (
      <SJDispenser
        key={dispenser?.id}
        dispenserNumber={dispenserIndex + 1 + offset}
        dispenser={dispenser}
        isNormalize={isNormalize}
        experiments={experiments}
        batchId={batchId}
        getDetails={getDetails}
        step={step}
        errors={errors}
        previewDispensers={previewDispensers}
        isPro={isPro}
        isManual={isManual}
      />
    ))}
  </>
);
