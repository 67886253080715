import React from 'react';
import { Avatar, Popover } from '../../../components';

export const columns = [
  {
    title: '',
    dataIndex: 'avatar',
    key: 'avatar',
    width: 36,
    render: (text, row) => {
      let name = `${row.firstName} ${row.lastName}`;
      return !!row.firstName && !!row.lastName ? <Avatar>{name.match(/\b(\w)/g).join('')}</Avatar> : <Avatar></Avatar>;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    render: (text, row) => (
      <p className="name">
        {row.firstName} {row.lastName}
      </p>
    ),
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
    width: '30%',
    render: arr => (
      <p className="roles">
        {arr.map((i, index) => {
          return (
            <>
              {i}
              {index + 1 !== arr.length && ','}&nbsp;
            </>
          );
        })}
      </p>
    ),
  },
  {
    title: 'Projects',
    dataIndex: 'projects',
    key: 'projects',
    width: '40%',
    render: (text, i) =>
      text.length > 0 ? (
        <Popover placement="right" content={popoverContent(text)}>
          <a className="projects-amount">
            {text.length} {text.length !== 1 ? 'projects' : 'project'}
          </a>
        </Popover>
      ) : (
        <a className="no-projects">No projects</a>
      ),
  },
];

const popoverContent = projects => {
  let arr = projects.slice(0, 5);
  return (
    <div className="popoverContent">
      <div
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {arr.map((item, i) => (
          <li key={i} title={item.name}>
            {i + 1}.&nbsp;&nbsp;<span>{item.name}</span>
          </li>
        ))}
      </div>
      {projects.length > 5 && (
        <a className="projectsBtn">
          {projects.length - 5} more project{projects.length - 5 === 1 ? '' : 's'}
        </a>
      )}
    </div>
  );
};
