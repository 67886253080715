import React from 'react';
import { Select } from '..';
import './style.scss';

export const Sorter = ({ value, options, title = 'Sort by:', onChange }) => {
  return (
    <div className="sorter-component">
      <div className="sorter-component_title">{title}</div>
      <div className="sorter-component_selector">
        <Select showSearch={false} options={options} value={value} onChange={onChange} />
      </div>
    </div>
  );
};
