import React, { useState, createContext } from 'react';
import './style.scss';
import cn from 'classnames';
import { AddComponentButton } from './AddComponentButton';
import { InputComponents } from './InputComponents';
import { Reactor } from './Reactor';
import ReactionAccordion from '../ReactionsAccordion/ReactionsAccordion';
import { MEASUREMENT_OPTIONS, PROJECT_TYPES } from '../../../constants';
import { PageContainer } from '../../PdfGenerator/PageContainer';
import { TitleContainer } from '../../PdfGenerator/TitleContainer';

export const RealTimeModalContext = createContext();

export const WorkSpace = ({
  pdfMode,
  data,
  setData = () => {},
  addReactor = () => {},
  addPump = () => {},
  changeReactor = () => {},
  changePump = () => {},
  openCompoundAddToPump = () => {},
  openCompoundAddToReactor = () => {},
  changeCompound = () => {},
  getPumpFlowRate = () => {},
  previewMode = false,
  processType,
  addReactorVariation,
  variationPump,
  setCompoundPosition,
  onAfterUpdateReactor,
  realTime = false,
  experiment = null,
  configCharts,
}) => {
  const [isRealTimeModalOpen, setRealTimeModalOpen] = useState(false);

  const variableReactorExists = !!data.find(reactor => reactor.variable);

  const onUpdateReactor = (newReactorData, reactorIndex, deleteIndex, last_iteration, measurement) => {
    const newData = data.map((reactor, index) => {
      if (index === reactorIndex) return newReactorData;
      return reactor;
    });
    if (measurement === MEASUREMENT_OPTIONS.temperatures.value) {
      setData(newData);
      return;
    }
    let variableReactorTimes;
    if (newReactorData.times.length > 1 || newReactorData.temperatures.length > 1) {
      variableReactorTimes = newReactorData.times;
    } else {
      variableReactorTimes = [0];
    }
    onAfterUpdateReactor(newReactorData, reactorIndex, newData, variableReactorTimes, deleteIndex, last_iteration);
  };

  const showReactionsTable =
    (processType === PROJECT_TYPES.OPTIMIZATION || processType === PROJECT_TYPES.LIBRARY_GENERATION) && !realTime;

  const Child = (
    <RealTimeModalContext.Provider value={{ isRealTimeModalOpen, setRealTimeModalOpen }}>
      <div
        className={cn('workspace', {
          'preview-mode': previewMode,
          'real-time-mode': realTime,
          'preview-table-margin': previewMode && showReactionsTable,
        })}
      >
        {data.map((item, idx) => {
          let pumps = [...item.pumps];
          if (item?.times && !!item.times) {
            if (Array.isArray(item.times[0])) item.times = item.times[0];
            if (item.temperatures && Array.isArray(item.temperatures[0])) item.temperatures = item.temperatures[0];
          }
          if (item?.flow_rate) {
            pumps = pumps.map(item => {
              if (Array.isArray(item.flow_rate[0])) item.flow_rate = item.flow_rate[0];
              item.flow_rate.flat(Infinity);
              return item;
            });
          }
          if (item.type === 'liquid' || item.type === 'gas') pumps.splice(0, 1);
          return (
            <>
              {(!!item.name || (!item.name && idx === 0)) && (
                <AddComponentButton
                  type="reactor"
                  addAction={() => {
                    addReactor(idx, data.length);
                  }}
                />
              )}
              {!item.name && !!data[idx - 1] && !data[idx - 1]?.name && (
                <AddComponentButton
                  type="reactor"
                  addAction={() => {
                    addReactor(idx, data.length);
                  }}
                />
              )}
              <InputComponents
                pumps={pumps}
                data={data}
                addPump={() => addPump(idx, item.pumps.length)}
                variationPump={variationPump}
                reactorPropertiesLength={item?.properties?.length}
                variationPossible={processType === PROJECT_TYPES.LIBRARY_GENERATION}
                changePump={changePump}
                openCompoundAdd={openCompoundAddToPump}
                reactorIdx={idx}
                configCharts={configCharts}
                experiment={experiment}
                changeCompound={changeCompound}
                addReactorVariation={addReactorVariation}
                getPumpFlowRate={getPumpFlowRate}
                setCompoundPosition={setCompoundPosition}
                realTime={realTime}
              />
              <Reactor
                key={idx}
                data={item}
                reactorIdx={idx}
                noArrow={pumps.length === 0 && idx === 0}
                openCompoundAddToReactor={(propertyIndex, isVariative) =>
                  openCompoundAddToReactor(idx, propertyIndex, isVariative)
                }
                configCharts={configCharts}
                experiment={experiment}
                changeReactor={propIndex => changeReactor(item, idx, propIndex)}
                changeCompound={changeCompound}
                updateReactor={onUpdateReactor}
                variableReactorExists={variableReactorExists}
                processType={processType}
                realTime={realTime}
                previewMode={previewMode}
              />
            </>
          );
        })}
        {data.length > 0 && !!data[data.length - 1].name && (
          <InputComponents
            pumps={[]}
            addPump={() => addPump(data.length, 0, true)}
            changePump={() => {}}
            reactorIdx={data.length}
            openCompoundAdd={openCompoundAddToPump}
            changeCompound={changeCompound}
            getPumpFlowRate={getPumpFlowRate}
            realTime={realTime}
          />
        )}
        <AddComponentButton
          type="reactor"
          addAction={() => addReactor(data.length > 0 ? data.length : data.length - 1, data.length)}
        />
      </div>
      {showReactionsTable && !pdfMode && (
        <ReactionAccordion
          processData={data}
          wrapperClass={cn('reactions-accordion-wrapper', { 'preview-mode': previewMode })}
          processType={processType}
        />
      )}
    </RealTimeModalContext.Provider>
  );

  if (pdfMode) {
    return (
      <>
        {showReactionsTable && (
          <PageContainer>
            <TitleContainer data-pdf-node>Process</TitleContainer>
            <ReactionAccordion
              processData={data}
              wrapperClass={cn('reactions-accordion-wrapper', { 'preview-mode': previewMode })}
              processType={processType}
            />
          </PageContainer>
        )}
        <PageContainer exceptionPage>
          <TitleContainer data-pdf-node>Process</TitleContainer>
          <div className="experiment-details_body_work-space">{Child}</div>
        </PageContainer>
      </>
    );
  }
  return Child;
};
