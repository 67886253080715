export const COLUMNS_TITLES = {
  name: {
    label: 'Name',
    value: 'name',
    sortValue: 'name',
  },
  dataType: {
    label: 'Data Type',
    value: 'dataType',
    sortValue: 'data_type',
  },
  systemic: {
    label: 'System Field',
    value: 'systemic',
    sortValue: 'systemic',
  },
  editable: {
    label: 'Editable',
    value: 'editable',
    sortValue: 'editable',
  },
  unique: {
    label: 'Unique',
    value: 'unique',
    sortValue: 'unique',
  },
  visibleInDetails: {
    label: 'Visible in details',
    value: 'visibleInDetails',
    sortValue: 'visible_in_details',
  },
  defaultValue: {
    label: 'Default value',
    value: 'defaultValue',
    sortValue: 'default_value',
  },
  archived: {
    label: 'Field status',
    value: 'archived',
    sortValue: 'archived',
  },
};
