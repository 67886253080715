import React from 'react';
import './style.scss';
import { SynjetProCombinationView } from '../SynjetPro/SynjetProCombinationView';
import { CompoundSmilesPreview } from '../../../index';
import { getConditionalLabel, sortConditionalByTemperature } from '../../../../utils/synjetHelpers';
import { SynjetProQuenchingView } from '../SynjetPro/SynjetProQuenchingView';

const SynJetProProductListItemView = ({ compoundInfo, isExpectedIntermediate }) => {
  const sortedConditions = sortConditionalByTemperature(compoundInfo.conditions);

  return (
    <>
      <div className="synjet-compound__info__combination-list">
        <SynjetProCombinationView combination={compoundInfo.combination} />
        {compoundInfo.quenching && <SynjetProQuenchingView quenching={compoundInfo.quenching} />}
      </div>
      <div className="synjet-compound__info__condition-list">
        {sortedConditions.map(condition => (
          <span className="condition">{getConditionalLabel(condition)}</span>
        ))}
      </div>
      {isExpectedIntermediate ? (
        <div />
      ) : (
        <div className="synjet-compound__info__include">{compoundInfo?.includeProduct ? 'Yes' : 'No'}</div>
      )}
      <div className="synjet-compound__info__product">
        {compoundInfo?.product ? (
          <CompoundSmilesPreview
            compound={compoundInfo?.product}
            className="smiles-preview"
            options={{ width: 130, height: 80 }}
            hideMW
            showTooltip
          />
        ) : (
          'N/A'
        )}
      </div>
      <div className="synjet-compound__info__product-mass">{compoundInfo?.calculatedYield || 'N/A'}</div>
    </>
  );
};
export default SynJetProProductListItemView;
