import './style.scss';

import React, { useEffect, useState } from 'react';

import { Button, openNotification, Popup, Spinner, StatusLabel } from 'components/Common';
import { EXPERIMENT_STATUSES, modeSched, statuses_execution } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { user } from 'store/common/common.selector';
import { manageBatch, manageBatchStartPreparation } from 'store/scheduling/scheduling.actions';
import { parseJSONErrors } from 'utils';
import { compareCurrentDateAndUTC, startAndEndTimeToText } from 'utils/date';
import history from 'utils/history';

import { DEVICES } from '../../constants/common';
import { ExperimentBatchItem } from './ExperimentBatchItem';
import { MoveToBatchPopup } from './MoveToBatchPopup';
import { RejectExperimentFromBatchPopup } from './RejectExperimentFromBatchPopup';
import { RemoveFromBatchPopup } from './RemoveFromBatchPopup';
import { Warning } from './Warning';

export const BatchDetails = ({
  detailsKey,
  closeBatchDetails,
  mode,
  navigateToFirstBatch,
  getListExperiments,
  goToExperimentDetails,
  deviceId,
  setDetailsKey,
  setSideLoader,
}) => {
  const [batch, setBatch] = useState(null);
  const [selectedExperiment, setSelectedExperiment] = useState(null);
  const [removeFromBatchConfOpen, setRemoveFromBatchConfOpen] = useState(false);
  const [startPreparationOpen, setStartPreparationOpen] = useState(false);
  const [openUnbatchConfirmation, setOpenUnbatchConfirmation] = useState(false);
  const [moveToBatchConfOpen, setMoveToBatchConfOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rejectBatchOpen, setRejectBatchOpen] = useState(false);
  const [type, setType] = useState('batch');
  const currentUser = useSelector(user);
  const dispatch = useDispatch();

  useEffect(() => {
    getBatchDetails();
  }, [detailsKey]);

  const getBatchDetails = () => {
    setLoading(true);
    dispatch(manageBatch({ data: { key: detailsKey }, type: 'getBatchDetails' }))
      .then(resp => {
        setBatch(resp);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeFromBatch = experiment => {
    setSelectedExperiment(experiment);
    if (mode !== 'execution') {
      setRemoveFromBatchConfOpen(true);
    } else {
      rejectBatch('experiment');
      // request for reject experiment from batch
    }
  };

  const moveToBatch = experiment => {
    setSelectedExperiment(experiment);
    setMoveToBatchConfOpen(true);
  };

  const goToDetails = experiment => {
    setDetailsKey(experiment.key);
    const tab = experiment.status === EXPERIMENT_STATUSES.Completed.value ? 'history' : 'scheduled';
    history.push(`/scheduling?isBatch=false&key=${experiment.key}&tab=${tab}&deviceId=${deviceId}`);
    goToExperimentDetails();
  };

  const onSuccess = () => {
    if (batch?.experiments?.length > 1) {
      getBatchDetails();
    } else closeBatchDetails();
    getListExperiments();
  };

  const handleStartPreparation = () => {
    dispatch(manageBatchStartPreparation(batch.uuid))
      .then(resp => {
        if (resp.warnings) {
          resp.warnings.forEach((warn, warnIdx) => {
            warn.messages.forEach((msg, msgIdx) => {
              openNotification('', '', null, `warning-notification-${warnIdx}-${msgIdx}`, null, msg);
            });
          });
        }
        if (batch?.experiments[0]?.process.device === DEVICES.SYNJET) {
          history.push(`execution/sj/details/${detailsKey}`);
        } else {
          history.push(`execution/sjp/details/${detailsKey}`);
        }
      })
      .catch(errors => {
        parseJSONErrors(errors).forEach(err => {
          openNotification(null, err);
        });
      })
      .finally(() => setStartPreparationOpen(false));
  };

  const handleWizardPreparationButton = () => {
    if (statuses_execution.indexOf(batch?.status) === -1) {
      setStartPreparationOpen(true);
    } else if (batch?.experiments[0]?.process.device === DEVICES.SYNJET) {
      history.push(`execution/sj/details/${detailsKey}`);
    } else {
      history.push(`execution/sjp/details/${detailsKey}`);
    }
  };
  const rejectBatch = type => {
    setType(type);
    setRejectBatchOpen(true);
  };

  const unbatch = () => {
    setLoading(true);
    dispatch(
      manageBatch({
        data: { uuid: batch.uuid },
        type: 'unscheduleBatch',
      })
    )
      .then(resp => {
        getListExperiments();
        closeBatchDetails();
        openNotification('The batch has been unscheduled');
        setOpenUnbatchConfirmation(false);
      })
      .catch(err => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const canRemoveFromMoveToBatch =
    currentUser?.permissions?.execution?.update_batch && batch?.status === EXPERIMENT_STATUSES.InQueue.value;
  const canRejectExperiment =
    mode === 'execution' &&
    currentUser?.permissions?.execution?.create_reject_experiment &&
    (batch?.status === EXPERIMENT_STATUSES.InQueue.value || batch?.status === EXPERIMENT_STATUSES.Inpreparation.value);

  const canStartPreparation =
    currentUser?.permissions?.execution?.create_prepare_experiment &&
    batch?.status !== EXPERIMENT_STATUSES.Completed.value;
  const isInPast = compareCurrentDateAndUTC(batch?.timeSlot?.startAt);

  return (
    <>
      <div className="batch-details">
        <Spinner loading={loading}>
          <div className="batch-details-header">
            <div className="batch-details-header__right">
              <div className="batch-details-header__name">{batch?.batchId}</div>
              {batch?.timeSlot && (
                <div className="batch-details-header__time">
                  Scheduled:
                  {startAndEndTimeToText(batch?.timeSlot)}
                </div>
              )}
            </div>
            <StatusLabel type="active">{batch?.status}</StatusLabel>
            {batch?.hasWarnings && (
              <div className="batch-details-header__warnings">
                {batch?.warnings.map((warning, index) => (
                  <Warning data={warning} key={index} />
                ))}
              </div>
            )}
          </div>
          <div className="batch-details__experiments-list">
            {batch?.experiments?.map((experiment, index) => (
              <ExperimentBatchItem
                canEdit={canRemoveFromMoveToBatch}
                canRejectExperiment={canRejectExperiment}
                removeFromBatch={() => removeFromBatch(experiment)}
                moveToBatch={() => moveToBatch(experiment)}
                goToDetails={() => goToDetails(experiment)}
                data={experiment}
                executionMode={mode === 'execution'}
              />
            ))}
          </div>
          <div className="batch-details__footer">
            {mode === modeSched ? (
              <>
                <Button onClick={closeBatchDetails} secondary>
                  Close
                </Button>
                {batch?.status === EXPERIMENT_STATUSES.InQueue.value && !isInPast && canRemoveFromMoveToBatch && (
                  <Button style={{ marginLeft: 8 }} secondary onClick={() => setOpenUnbatchConfirmation(true)}>
                    Unbatch
                  </Button>
                )}
              </>
            ) : (
              <>
                {canRejectExperiment ? (
                  <Button onClick={() => rejectBatch('batch')} secondary>
                    Reject batch
                  </Button>
                ) : (
                  <div />
                )}
                {canStartPreparation && (
                  <Button onClick={handleWizardPreparationButton}>
                    {statuses_execution.indexOf(batch?.status) === -1 ? 'Start preparation' : 'Continue'}
                  </Button>
                )}
              </>
            )}
          </div>
        </Spinner>
      </div>
      <RemoveFromBatchPopup
        experimentsLength={batch?.experiments?.length}
        open={removeFromBatchConfOpen}
        batch={batch}
        experiment={selectedExperiment}
        handleCancel={() => {
          setRemoveFromBatchConfOpen(false);
          setSelectedExperiment(null);
        }}
        onSuccess={onSuccess}
      />
      <MoveToBatchPopup
        handleCancel={() => setMoveToBatchConfOpen(false)}
        onSuccess={onSuccess}
        experiment={selectedExperiment}
        open={moveToBatchConfOpen}
        batch={batch}
      />
      <Popup
        open={startPreparationOpen}
        title="Start Preparation"
        textSubmit="Proceed"
        handleSubmit={handleStartPreparation}
        handleCancel={() => setStartPreparationOpen(false)}
      >
        <div>Do you want to proceed with preparation of the selected Batch?</div>
      </Popup>
      <RejectExperimentFromBatchPopup
        closePopup={() => setRejectBatchOpen(false)}
        open={rejectBatchOpen}
        type={type}
        batch={batch}
        experiment={selectedExperiment}
        navigateToFirstBatch={navigateToFirstBatch}
        getBatchDetails={getBatchDetails}
        setSideLoader={setSideLoader}
      />
      <Popup
        open={openUnbatchConfirmation}
        title="Unbatch"
        textSubmit="Confirm"
        handleSubmit={unbatch}
        loading={loading}
        handleCancel={() => setOpenUnbatchConfirmation(false)}
      >
        You are about to unschedule the batch. <br />
        Note: After that, all the related experiments will be uncheduled and the corresponding time slot will be
        available for other events
      </Popup>
    </>
  );
};
