import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { Spinner, Popup, openNotification } from 'components/Common';
import { SJBatchConfigurationDelayTable } from './SJBatchConfigurationDelayTable';
import { SJBatchConfigurationTableBlock } from './SJBatchConfigurationTableBlock';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getExperimentContainerStyle } from 'utils/execution';
import { calculateVialsSchedule } from 'store/scheduling/scheduling.actions';
import cn from 'classnames';
import { SYNJET_SOCKET_TYPES } from 'constants/synjet';
import { EXPERIMENT_STATUSES } from 'constants/common';
import { WebsocketService } from 'utils/service/WebSocketService';
import moment from 'moment';

export const SJBatchConfiguration = ({
  batch,
  isPro,
  checkboxesOnBatchConfigStep,
  setcheckboxesOnBatchConfigStep = () => {},
  onContinue,
  setconfirmOnBatchConfigStep = () => {},
  confirmOnBatchConfigStep,
  showTableOnly = false,
  setErrorNotification = () => {},
}) => {
  const [delayData, setDelayData] = useState([]);
  const [tableData, _setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const [batchData, setBatch] = useState(null);
  const [experimentInfoSubject, setExperimentInfoSubject] = useState(null);

  const tableDataRef = React.useRef(tableData);
  const setTableData = data => {
    tableDataRef.current = data;
    _setTableData(data);
  };

  const { id: batchID } = useParams();

  const dispatch = useDispatch();

  var timers = {};
  var timeout = null;
  var experimentsStartTimes = {};

  useEffect(
    () => () => {
      if (experimentInfoSubject) {
        experimentInfoSubject.unsubscribe();
      }
      return () => {
        experimentsStartTimes = {};
        Object.keys(timers).forEach(key => {
          clearInterval(timers[key]);
        });
        clearTimeout(timeout);
      };
    },
    []
  );

  useEffect(() => {
    if (experimentInfoSubject && experimentInfoSubject?.reconnect) {
      experimentInfoSubject.subscribe(msg => updateExperiments(msg));
    }

    return () => {
      if (experimentInfoSubject) {
        experimentInfoSubject.unsubscribe();
      }
    };
  }, [experimentInfoSubject]);

  const setDelay = configuration => {
    const config = configuration ? JSON.parse(configuration) : null;
    let items = [];
    batch.experiments.forEach(experiment => {
      if (experiment.process.numberOfSteps == 1 && !experiment.process.enableQuenching) {
        items.push(experiment);
      } else if (experiment.process.enableQuenching) {
        items = [
          ...items,
          { ...experiment, name: `${experiment.name}`, step: 0 },
          { ...experiment, name: `Quenching: ${experiment.name}`, step: 1 },
        ];
      } else {
        items = [
          ...items,
          { ...experiment, name: `Step 1: ${experiment.name}`, step: 0 },
          { ...experiment, name: `Step 2: ${experiment.name}`, step: 1 },
        ];
      }
    });
    const delayItems = items.map(i => {
      if (!i.step) i.step = 0;
      let delayConfiguration = config?.delays.find(
        _delay => _delay.experiment_uuid === i.uuid && _delay.step == i.step
      );
      i.delay = delayConfiguration?.delay;
      i.checked = !config ? true : i.delay !== undefined;
      i.delay_from_previous = delayConfiguration?.delay_from_previous;
      i.experimentUUID = i.uuid;
      return i;
    });
    setDelayData(
      disableLastExperiment(delayItems).sort((a, b) => {
        return configuration
          ? b.checked === a.checked
            ? a.delay - b.delay
            : b.checked - a.checked
          : a.name > b.name
          ? 1
          : -1;
      })
    );
  };

  useEffect(() => {
    if (batch?.experiments) {
      setDelay(batch?.configuration);
      setTableRows(batch?.configuration);
      if (showTableOnly) {
        const subject = new WebsocketService(`/batch-execution/${batch?.uuid}/`);
        timeout = setTimeout(() => {
          setExperimentInfoSubject(subject);
        }, 1000);

        batch.experiments.forEach(exp => {
          if (exp.process.numberOfSteps == 1) timers[exp.uuid] = null;
          else
            timers = {
              ...timers,
              [`${exp.uuid}-1`]: null,
              [`${exp.uuid}-2`]: null,
            };
        });
      }
    }
  }, [batch]);

  useEffect(() => {
    if (!batch.configuration) {
      setDelay();
      setTableData([]);
    }
  }, [batch?.configuration]);

  useEffect(() => {
    setcheckboxesOnBatchConfigStep({
      allChecked: delayData.filter(i => i.checked).length === delayData.length && !showRefresh,
      generated: tableData.length,
    });
    if (delayData.length) {
      let sortedExperimentsUUIDs = [];
      delayData.forEach(delay => {
        if (!sortedExperimentsUUIDs.find(uuid => uuid === delay.experimentUUID))
          sortedExperimentsUUIDs.push(delay.experimentUUID);
      });
      let rows = [...tableData];
      sortedExperimentsUUIDs.forEach((uuid, index) => {
        rows.map(row => {
          if (row.experiment_uuid === uuid) row.experimentIndex = index + 1;
          return row;
        });
      });
      setTableData(rows);
    }
  }, [JSON.stringify(delayData)]);

  const disableLastExperiment = delayData => {
    const newDelayData = delayData.map(delayDataItem => {
      delayDataItem.disabled = false;
      return delayDataItem;
    });
    if (batch?.experiments.length < 2) {
      newDelayData.forEach(data => {
        data.disabled = true;
      });
    } else {
      const checkedExperiments = [];
      batch.experiments.forEach(experiment => {
        newDelayData.forEach(data => {
          if (data?.checked && experiment.uuid == data.uuid && !checkedExperiments.includes(experiment))
            checkedExperiments.push(experiment);
        });
      });
      if (checkedExperiments.length < 2) {
        newDelayData.forEach(data => {
          data.disabled = data?.checked ? true : false;
        });
      }
    }
    return newDelayData;
  };

  const uncheckData = uuid => {
    const checkedDelayData = delayData.map((delayDataItem, idx) => {
      if (delayDataItem.uuid === uuid) {
        delayDataItem.checked = !delayDataItem.checked;
      }
      setShowRefresh(true);
      return delayDataItem;
    });
    setDelayData(
      disableLastExperiment(checkedDelayData).sort((a, b) =>
        b.checked === a.checked ? a.delay - b.delay : b.checked - a.checked
      )
    );
  };

  const generateTable = () => {
    setLoading(true);
    setShowRefresh(false);
    let experiments = [];
    [...delayData]
      .filter(exp => exp.checked && exp.uuid)
      .map(exp => exp.uuid)
      .forEach(uuid => {
        if (!experiments.find(exp => exp === uuid)) experiments.push(uuid);
      });

    dispatch(
      calculateVialsSchedule({
        uuid: batch.uuid,
        experiments,
      })
    )
      .then(resp => {
        if (resp?.warnings) {
          resp.warnings.forEach(warn => {
            warn.messages.forEach(msg => {
              setErrorNotification(msg);
              openNotification(null, null, null, null, null, msg);
            });
          });
        } else {
          setErrorNotification(null);
        }
        setTableRows(resp?.batchConfiguration);
        setDelay(resp?.batchConfiguration);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setTableRows = configuration => {
    const experiments = [...batch.experiments].map(i => i.uuid);
    const config = JSON.parse(configuration);
    if (!config) return;
    let tableRows = [];
    config.schedule.forEach((vial, idx) => {
      const has2Steps = config.delays.filter(i => i.experiment_uuid === vial.experiment_uuid).length > 1;
      let enableQuenching = false;
      batch.experiments.forEach(experiment => {
        if (experiment.uuid == vial.experiment_uuid) {
          enableQuenching = experiment.process.enableQuenching; // experiment with quenching comes as a two-step process
        }
      });
      let row = {
        ...vial,
        experiment_name: `${
          vial?.is_quenching
            ? 'Quenching: '
            : !enableQuenching && isPro && vial.step == 0 && !vial.is_fixed && has2Steps
            ? 'Step ' + (+vial.step + 1) + ' (Temp ' + vial.temperature + '): '
            : has2Steps && !enableQuenching
            ? 'Step ' + (+vial.step + 1) + ': '
            : ''
        }${vial.experiment_name}`,
        rowIndex: idx + 1,
        experimentIndex: null,
        rowClassName: getExperimentContainerStyle(experiments.indexOf(vial.experiment_uuid)),
        deltaClassName: '',
        step: has2Steps ? +vial.step + 1 : null,
      };
      row.isInPast = checkIfIsInProgress({ ...vial, step: row.step }, config);
      if (!!row.is_prime) {
        row.experiment_name = 'Prime';
        row.deltaClassName = 'bold-delta';
      }
      tableRows.push(row);
    });
    setTableData(tableRows);
  };

  const setTimeoutForExperimentVial = (timerKey, experiment, step = null, initialSeconds = 0) => {
    experimentsStartTimes[timerKey] = initialSeconds;
    timers[timerKey] = setInterval(() => {
      experimentsStartTimes[timerKey] += 1;
      tableDataRef.current.forEach((row, rowIndex) => {
        const vialTime = row.removal_time || row.cooling_time;
        if (experimentsStartTimes[timerKey] >= vialTime && !row.isInPast) {
          setTableData(
            tableDataRef.current.map((i, index) => {
              if (index === rowIndex && !row.isInPast) {
                return { ...i, isInPast: true };
              }
              return i;
            })
          );
        }
      });
    }, 1000);
  };

  const updateExperiments = msg => {
    if (msg.type === 'batch_execution_update') {
      if (msg.data.experiments_statuses) {
        msg.data.experiments_statuses.forEach(exp => {
          let numberOfSteps = msg.data.experiments_statuses.filter(e => e.experiment_uuid === exp.experiment_uuid)
            .length;
          if (
            exp.experiment_status === EXPERIMENT_STATUSES.PendingFinalization.value ||
            exp.experiment_status === EXPERIMENT_STATUSES.Paused.value
          ) {
            setTableRows(batch?.configuration);
            // remove interval if exp is not in progress
            if (
              (numberOfSteps === 1 && timers[exp.experiment_uuid]) ||
              (numberOfSteps > 1 && timers[`${exp.experiment_uuid}-${exp.step}`])
            ) {
              clearInterval(
                numberOfSteps === 1 ? timers[exp.experiment_uuid] : timers[`${exp.experiment_uuid}-${exp.step}`]
              );
            }
            return;
          }
          if (exp.experiment_status !== EXPERIMENT_STATUSES.InProgress.value) return;

          if (numberOfSteps === 1 && !timers[exp.experiment_uuid]) {
            setTimeoutForExperimentVial(exp.experiment_uuid, exp, null);
          } else if (numberOfSteps > 1 && !timers[`${exp.experiment_uuid}-${exp.step}`]) {
            setTimeoutForExperimentVial(`${exp.experiment_uuid}-${exp.step}`, exp, exp.step);
          }
        });
      }
    }
  };

  const checkIfIsInProgress = (vial, config) => {
    const exp = batch?.experiments.find(exp => exp.uuid === vial.experiment_uuid);
    const vialTime = vial.removal_time || vial.cooling_time;
    // if experiment was already started
    if (
      exp.status === EXPERIMENT_STATUSES.InProgress.value &&
      moment().diff(moment(exp.execution.startedAt), 'seconds') < vialTime
    ) {
      let timerKey = `${exp.uuid}${vial.step ? '-' + vial.step : ''}`;
      if (timers[timerKey] === null)
        setTimeoutForExperimentVial(
          timerKey,
          { experiment_uuid: exp.uuid },
          vial.step,
          moment().diff(moment(exp.execution.startedAt), 'seconds')
        );
    }
    return (
      !!exp &&
      (((exp.status === EXPERIMENT_STATUSES.InProgress.value || exp.status === EXPERIMENT_STATUSES.Paused.value) &&
        moment().diff(moment(exp.execution.startedAt), 'seconds') >= vialTime) ||
        exp.status === EXPERIMENT_STATUSES.Completed.value ||
        exp.status === EXPERIMENT_STATUSES.PendingFinalization.value ||
        exp.status === EXPERIMENT_STATUSES.Finalization.value)
    );
  };

  return (
    <div className={cn('sj-batch-configuration-block', { 'table-only': showTableOnly })}>
      <Spinner loading={loading}>
        {!showTableOnly && (
          <SJBatchConfigurationDelayTable
            data={delayData}
            uncheckData={uncheckData}
            showRefresh={showRefresh}
            generateTable={generateTable}
            batch={batch}
          />
        )}
        <SJBatchConfigurationTableBlock data={tableData} generate={generateTable} batch={batch} isPro={isPro} />
      </Spinner>
      <Popup
        open={confirmOnBatchConfigStep}
        title="Warning"
        textSubmit="Proceed"
        handleSubmit={onContinue}
        handleCancel={() => setconfirmOnBatchConfigStep(false)}
      >
        <div>
          Note that unchecking the experiment from the Batch configuration table does not reject it from the Batch, so
          all the experiments will be included into execution, proceed?
        </div>
      </Popup>
    </div>
  );
};
