import React, { useContext, useEffect } from 'react';
import './style.scss';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { ManualComponentsItem } from './ManualComponentsItem';
import { ManualDescription } from './ManualDescription';
import ManualReactionsCollapse from '../../ManualLabProcessBuilder/ManualReactionsCollapse/ManualReactionsCollapse';
import { PROJECT_TYPES } from '../../../constants';
import { PageContainer } from '../../PdfGenerator/PageContainer';
import { TitleContainer } from '../../PdfGenerator/TitleContainer';

const ProcessLabContext = React.createContext();

export const useProcessLabContext = () => {
  const state = useContext(ProcessLabContext);
  return state || {};
};

export const ManualWorkArea = ({
  pdfMode,
  data,
  addInput = () => {},
  addGlassware = () => {},
  process,
  processType,
  addEquipment = () => {},
  onSaveInputEquivalents = () => {},
  onSaveEquipmentOptions = () => {},
  openCompoundAddToEquipment = () => {},
  changeEquipmentCompound = () => {},
  description,
  setDescription = () => {},
  editMode,
  openAddProperty = () => {},
  variationInput,
  limitingInput,
}) => {
  const { id } = useParams();
  const variableEquipmentExists = false;

  const notEmptyData = data.filter(i => !!i.inputs.length || !!i.glassware || !!i.equipment);

  if (!editMode) data = notEmptyData;

  const hasOneElement =
    notEmptyData.length === 1 &&
    ((!!notEmptyData[0].equipment && !notEmptyData[0].inputs?.length && !notEmptyData[0].glassware) ||
      (!notEmptyData[0].equipment && !!notEmptyData[0].inputs?.length && !notEmptyData[0].glassware) ||
      (!notEmptyData[0].equipment && !notEmptyData[0].inputs?.length && !!notEmptyData[0].glassware));

  const getInputNumber = (_index, _inputIndex) => {
    let index = 1;
    data.forEach((i, idx) => {
      if (i.inputs && idx < _index) index += i.inputs?.length;
      if (i.inputs && idx === _index) index += _inputIndex;
    });
    return index;
  };

  const values = {
    editMode,
  };
  const Child = (
    <>
      <ProcessLabContext.Provider value={values}>
        <div
          data-adjust-width="data-container-1"
          className={cn('workspace manual', {
            'view-mode': !editMode,
            'one-element-view-mode': hasOneElement && !editMode,
          })}
        >
          {data.map((i, index) => (
            <ManualComponentsItem
              index={index}
              data={i}
              addInput={addInput}
              key={index}
              processType={processType}
              addEquipment={addEquipment}
              addGlassware={addGlassware}
              getInputNumber={getInputNumber}
              onSaveInputEquivalents={onSaveInputEquivalents}
              onSaveEquipmentOptions={onSaveEquipmentOptions}
              openCompoundAddToEquipment={openCompoundAddToEquipment}
              changeEquipmentCompound={changeEquipmentCompound}
              variableEquipmentExists={variableEquipmentExists}
              hasOneElement={hasOneElement}
              openAddProperty={openAddProperty}
              variationInput={variationInput}
              limitingInput={limitingInput}
            />
          ))}
        </div>
        {!pdfMode && (
          <div className="manual-tables-wrapper">
            {processType !== PROJECT_TYPES.PRODUCTION && (
              <ManualReactionsCollapse
                processType={processType}
                processData={data}
                wrapperClass="manual-reactions-wrapper"
                closed
              />
            )}
            <div className="manual-description-wrapper">
              <ManualDescription
                uuid={id}
                description={description}
                setDescription={setDescription}
                editMode={editMode}
              />
            </div>
          </div>
        )}
      </ProcessLabContext.Provider>
    </>
  );
  if (pdfMode) {
    return (
      <>
        {processType !== PROJECT_TYPES.PRODUCTION && (
          <PageContainer>
            <TitleContainer data-pdf-node>Process</TitleContainer>
            <ManualReactionsCollapse
              defaultActiveKey="reactions"
              processType={processType}
              processData={data}
              wrapperClass="manual-reactions-wrapper"
              closed
            />
          </PageContainer>
        )}
        <PageContainer exceptionPage>
          <TitleContainer data-pdf-node>Process</TitleContainer>
          <div className="work-space-view-mode">
            <div data-container-1 className="view-mode-wrapper">
              {Child}
            </div>
          </div>
        </PageContainer>
      </>
    );
  }
  return Child;
};
