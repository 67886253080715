import React from 'react';
import { CompoundSmilesPreview } from '../../index';
import { LimitedContainer } from '../../ExperimentDetailsComponents/LimitedContainer';

export const columnsManual = [
  {
    title: 'Compound name',
    dataIndex: 'compound_name',
    key: 'compound_name',
    render: (include, rowData, index) => (
      <div>
        <CompoundSmilesPreview compound={rowData?.compound} hideMW />
        {rowData.is_limited && <LimitedContainer />}
      </div>
    ),
  },
  {
    title: 'Chemical type',
    dataIndex: 'chemical_type',
    key: 'chemical_type',
  },
  {
    title: 'Input/ Equipment',
    dataIndex: 'input_equipment',
    key: 'input_equipment',
  },
  {
    title: 'MW, g/mol',
    dataIndex: 'mw',
    key: 'mw',
  },

  {
    title: 'Concentration, M',
    dataIndex: 'concentration',
    key: 'concentration',
  },
  {
    title: 'Equivalent',
    dataIndex: 'equivalent',
    key: 'equivalent',
  },
  {
    title: 'Mass, g',
    dataIndex: 'mass',
    key: 'mass',
  },
  {
    title: 'Volume, mL',
    dataIndex: 'volume',
    key: 'volume',
  },
];
