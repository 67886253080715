import React, { useState } from 'react';
import { TimePicker } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import './style.scss';

export const Time = ({
  value,
  onChange,
  className = '',
  format = 'HH:mm',
  range,
  error = false,
  errorText = '',
  ...rest
}) => {
  const { RangePicker } = TimePicker;

  return (
    <div className={cn('time-wrapper', { error: !!error })}>
      {!range ? (
        <TimePicker
          {...rest}
          value={value}
          onChange={onChange}
          className={cn('time-picker', className)}
          format={format}
          placeholder="0"
          defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
        />
      ) : (
        <RangePicker
          {...rest}
          value={value}
          onChange={onChange}
          className={cn('time-picker', className)}
          format={format}
          placeholder="0"
          defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
        />
      )}
      {error && <div className="time-wrapper__error">{errorText}</div>}
    </div>
  );
};
