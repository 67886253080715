import React from 'react';
import { Button } from '../';
import './style.scss';
import cn from 'classnames';
import { Menu } from 'antd';
import { Dropdown } from 'antd';

export const DropdownButton = ({ menu = [], text = '', secondaryTypeButton = false }) => {
  const handleMenuClick = () => {};
  const menuItems = (
    <Menu onClick={handleMenuClick}>
      {menu.map(i => (
        <Menu.Item key="1" onClick={i.onClick}>
          {i.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menuItems} placement="bottomRight" trigger="click">
      <Button secondary={secondaryTypeButton}>{text}</Button>
    </Dropdown>
  );
};
