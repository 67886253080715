import React, { useEffect, useState } from 'react';
import { renderList } from '../../SynJetExecution/SynJetPrepareMaterials';
import '../style.scss';
import SolidsTable from './SolidsTable';
import { Spinner } from '../../../Common';
import SolidsStartingPlate from './SolidsStartingPlate';
import SolutionsManualTable from './SolutionsManualTable';
import LmDispensersBalloons from './LmDispensersBalloons';
import { LoadMaterialsTable } from './LoadMaterialsTable';
import FinishPlates from './FinishPlates';

export const SynJetProLoadMaterials = ({
  batch,
  errors,
  setEdited,
  setErrors,
  listCheckboxes,
  setListCheckboxes,
  loadVialsTableData,
  setLoadVialsTableData,
  experiments,
  getCompound,
  lmTableData,
  setLmTableData,
  finishPlates,
  setFinishPlates,
  finishPlatesErrors,
  setFinishPlatesErrors,
}) => {
  const [loading, setLoading] = useState(false);

  const updateChecklist = (index, data) => {
    setListCheckboxes(listCheckboxes.map((i, idx) => (idx === index ? data : i)));
  };

  const updateSolidsTable = data => {
    setLoadVialsTableData(prev => ({
      ...prev,
      solid_structure: data,
    }));
  };

  const updateManualTable = data => {
    setLoadVialsTableData(prev => ({
      ...prev,
      manual_structure: data,
    }));
  };

  return (
    <div className="sjp-load-materials">
      {!!loadVialsTableData.solid_structure.length && (
        <div className="syn-jet-materials">
          <span className="syn-jet-materials_title">Load vials: solids</span>
          <div className="lv-solids">
            <div className="lv-solids-table">
              <Spinner loading={loading}>
                <SolidsTable
                  errors={errors}
                  setErrors={setErrors}
                  experiments={experiments}
                  tableData={loadVialsTableData.solid_structure}
                  setEdited={setEdited}
                  setTableData={updateSolidsTable}
                  setLoading={setLoading}
                  getCompound={getCompound}
                  batchUuid={batch?.uuid}
                />
              </Spinner>
            </div>
            <SolidsStartingPlate batch={batch} data={loadVialsTableData.solid_structure} getCompound={getCompound} />
          </div>
        </div>
      )}
      {!!loadVialsTableData.manual_structure?.length && (
        <div className="syn-jet-materials">
          <span className="syn-jet-materials_title">Load vials: solutions (manual)</span>
          <div className="lv-solids">
            <div className="lv-solutions-table">
              <Spinner loading={loading}>
                <SolutionsManualTable
                  errors={errors}
                  setErrors={setErrors}
                  experiments={experiments}
                  tableData={loadVialsTableData.manual_structure}
                  setEdited={setEdited}
                  setTableData={updateManualTable}
                  setLoading={setLoading}
                  getCompound={getCompound}
                />
              </Spinner>
            </div>
            <SolidsStartingPlate
              batch={batch}
              data={loadVialsTableData.manual_structure}
              getCompound={getCompound}
              isManual
            />
          </div>
        </div>
      )}
      {batch?.dispenserStructure && (
        <div className="syn-jet-materials">
          <span className="syn-jet-materials_title">Load materials</span>
          <LmDispensersBalloons dispenserData={JSON.parse(batch.dispenserStructure)} getCompound={getCompound} />
          <span className="syn-jet-materials_title">Load materials</span>
          <div className="syn-jet-materials_lm-table">
            <Spinner loading={loading}>
              <LoadMaterialsTable
                errors={errors}
                setErrors={setErrors}
                experiments={experiments}
                tableData={lmTableData}
                setEdited={setEdited}
                setTableData={setLmTableData}
                setLoading={setLoading}
                getCompound={getCompound}
              />
            </Spinner>
          </div>
          <span className="syn-jet-materials_title">Name finish plates</span>
          <FinishPlates
            finishPlates={finishPlates}
            setFinishPlates={setFinishPlates}
            finishPlatesErrors={finishPlatesErrors}
            setFinishPlatesErrors={setFinishPlatesErrors}
          />
          <span className="syn-jet-materials_title">Checklist</span>
          <div className="syn-jet-materials_list">{renderList({ arr: listCheckboxes, updateChecklist })}</div>
        </div>
      )}
    </div>
  );
};
