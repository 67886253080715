import './styles.scss';

import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { Heading2 } from '@atoms';

export const HeaderAction = ({ label, title, action, onClose }) => (
  <div className="header-action">
    {title || <Heading2>{label}</Heading2>}
    {/* TODO: CloseOutlined should be ButtonIcon */}
    <div>{action || <CloseOutlined onClick={onClose} />}</div>
  </div>
);
