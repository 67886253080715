/**
 * Route visualization canvas
 *
 */

import React from 'react';
import { SRV } from './RouteVisualization';
import './RouteVisualization.scss';
import cn from 'classnames';

var cid = 0;

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
    };
  }

  generateCanvas = props => {
    const canvas = this.refs.canvas;
    let routeId = props.routeId ? props.routeId : 0;
    let strategyId = props.strategyId ? props.strategyId : 0;
    canvas.uid = cid++;
    SRV.CanvasMount(
      canvas,
      strategyId,
      routeId,
      props.type,
      props.results,
      props.onCanvasClick,
      this.setRowDataFromCanvas
    );
  };

  removeCanvas = () => {
    const canvas = this.refs.canvas;
    SRV.CanvasUnmount(canvas);
  };

  componentDidMount() {
    this.generateCanvas(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.routeId !== undefined &&
      this.props.routeId !== undefined &&
      (nextProps.routeId !== this.props.routeId || nextProps.results !== this.props.results)
    ) {
      this.removeCanvas();
      this.generateCanvas(nextProps);
    }
  }

  setRowDataFromCanvas = rowData => {
    if (!this.props.renderSteps || !this.props.isSelected) return;
    const dividedToRxr = {};
    const nodes = [];
    let countOfSteps = 1;
    rowData[0].forEach((i, idx, arr) => {
      if (!dividedToRxr[countOfSteps]) dividedToRxr[countOfSteps] = [i];
      else dividedToRxr[countOfSteps].push(i);
      if (i.type === 'rxr') {
        dividedToRxr[countOfSteps].push(arr[idx + 1]);
        ++countOfSteps;
      }
    });
    Object.keys(dividedToRxr).forEach((i, idx, arr) => {
      const lastNode = dividedToRxr[i][dividedToRxr[i].length - 1];
      const firstNode = dividedToRxr[i][0];
      const isFirstItem = idx === 0;
      const isLastItem = arr.length - 1 === idx;
      if (!isLastItem) {
        let obj = {
          left: isFirstItem
            ? 0
            : dividedToRxr[i][0].xoff -
              (firstNode.measureTextWidth > firstNode.nodeLength
                ? firstNode.measureTextWidth - firstNode.nodeLength
                : 0),
          width: lastNode.xoff + lastNode.nodeLength - dividedToRxr[i][0].xoff + lastNode.xgap,
          text: i,
        };
        nodes.push(obj);
      }
    });
    this.setState({
      rowData: nodes,
    });
    this.props.updateRangeSegments([0, nodes.length - 1]);
  };

  componentWillUnmount() {
    this.removeCanvas();
  }

  // Check if prop updated, mainly canvas type
  /*
  componentDidUpdate(prevProps, prevState) {

    // only update canvas if type changed
    if (prevProps.type !== this.props.type) {
      const canvas = this.refs.canvas;
      let routeId = this.props.routeId ? this.props.routeId : 0;
      console.log('canvas update', cid, mid--, canvas.id);
      SRV.InitCanvas(canvas, this.props.id, routeId, this.props.type, this.props.results, this.props.onCanvasClick);
    }
    }
*/
  render() {
    let strategyId = this.props.strategyId ? this.props.strategyId : 0;
    let routeId = this.props.routeId ? this.props.routeId : 0;
    let id = this.props.type + 'S' + strategyId + 'R' + routeId + this.props.indexUniq;

    return (
      <div
        className={cn('canvas-container', {
          'canvas-container-with-steps': this.state.rowData?.length,
        })}
      >
        <canvas id={id} ref="canvas" style={{ minWidth: '100%' }} />
        {this.props.rangeSegments &&
          this.state.rowData.map((i, idx) => {
            const lengthRowData = this.state.rowData.length - 1;
            const first = idx === this.props.rangeSegments[0] && idx !== 0;
            const last = idx === this.props.rangeSegments[1] && idx !== lengthRowData;
            const isActive = idx <= this.props.rangeSegments[1] && this.props.rangeSegments[0] <= idx;
            const isAllSelected =
              this.props.rangeSegments[1] - this.props.rangeSegments[0] === lengthRowData
                ? 'canvas-container_steps-all'
                : '';
            return (
              <div
                style={{ width: i.width, left: i.left }}
                className={cn('canvas-container_steps', isAllSelected, {
                  'canvas-container_steps_active': isActive,
                  'canvas-container_steps_active_last': last,
                  'canvas-container_steps_active_first': first,
                })}
              >
                {i.text}
              </div>
            );
          })}
      </div>
    );
  }
}

Canvas.defaultProps = {
  width: '100%',
  backgroundColor: 'transparent',
};

export default Canvas;
