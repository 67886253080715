import React, { useEffect, useState } from 'react';
import './style.scss';
import { Table } from '../../../Common';
import { columns } from './columns';
import { SYNJET_PROCESS_STEPS } from '../../../../constants/common';

const emptyState = [
  {
    temperature: null,
    vials: null,
    heatBlocks: null,
  },
  {
    temperature: <span className="quantity">Required quantity:</span>,
    vials: null,
    heatBlocks: null,
    isWarning: false,
  },
];

export const HardwareSummary = ({ twoStepsWithFirstFixed, experiments, isPro, processPreview }) => {
  const [data, setData] = useState(emptyState);
  //const [additionalVials, setAdditionalVials] = useState(0);
  let emptyTable = data[data.length - 1].vials ? false : true;
  let twoSteps = experiments.process_steps.length > 1;

  useEffect(() => {
    if (experiments?.process_steps) calculateHardwareSummary();
  }, [experiments]);

  const calculateHardwareSummary = () => {
    let heatCapacity = isPro ? 6 : 48;
    let vials = [],
      temperature = [];
    let fixedRow = [];
    //let _additionalVials = 0;
    if (experiments.process_steps.length === 1) {
      // 1-step process
      vials = [...experiments.process_steps[0].step.vials];
      vials = vials.filter(v => !!v.is_valid);
    } else if (twoSteps) {
      // 2-step process
      let fixedStep = experiments.process_steps.find(step => step.step.isFixed);
      let variableStep = experiments.process_steps.find(step => !step.step.isFixed);
      fixedStep.step.vials.forEach((vial, vialIndex) => {
        if (!!variableStep && variableStep?.step.vials[vialIndex]?.is_valid && vial.is_valid) {
          // if reaction is valid
          vials = [...vials, vial, variableStep.step.vials[vialIndex]];
          //then add 2 vials from this reaction
        } else if (!variableStep.step.vials[vialIndex]) {
          // if additional vials
          if (vial.is_valid) {
            // check if this vail is valid
            vials = [...vials, vial];
            //_additionalVials += 1;
          }
        }
      });
      //setAdditionalVials(_additionalVials);
      let variableVials = vials.filter(vial => vial.temperature_label != SYNJET_PROCESS_STEPS.FIXED);
      let fixedVials = vials.filter(vial => vial.temperature_label == SYNJET_PROCESS_STEPS.FIXED);
      fixedRow = [
        {
          temperature: fixedVials[0]?.temperature,
          vials: fixedVials.length,
          heatBlocks: Math.ceil(fixedVials.length / heatCapacity),
          fixedRow: true,
        },
      ];
      vials = [...variableVials];
    }
    vials.forEach(vial => {
      if (vial.temperature && !temperature.find(t => t === vial.temperature)) temperature.push(vial.temperature);
    });
    temperature = temperature.sort().map((t, index) => {
      let vialsNumber = vials.filter(v => v.temperature === t).length || 0;
      return {
        temperature: t,
        vials: vialsNumber,
        heatBlocks: Math.ceil(vialsNumber / heatCapacity),
      };
    });
    temperature = [...temperature, ...fixedRow];
    let vialsSumm = 0,
      heatBlocksSumm = 0;

    temperature.forEach(t => {
      vialsSumm += t.vials;
      heatBlocksSumm += t.heatBlocks;
    });

    if (temperature.length >= 1) {
      let sortedTemp = [
        ...temperature.map((i, idx) => {
          return temperature[idx];
        }),
      ]
        // .map((i, index) => {
        //   if (experiments.process_steps[0]?.step?.vials.find(v => v.temperature === i.temperature))
        //     i.isFirstStep = true;
        //   i.isFixed =
        //     (experiments.process_steps[0]?.step?.isFixed) ||
        //     (!i.isFirstStep && !experiments.process_steps[0]?.step?.isFixed);
        //   return i;
        // })
        .sort((a, b) => {
          return a.temperature - b.temperature;
        });

      // sortedTemp = sortedTemp.map((i, index) => {
      //   if (twoStepsWithFirstFixed && !!i.isFirstStep && !!i.vials && !isPro) {
      //     i.vials =
      //       i.vials.toString().indexOf('+') > -1
      //         ? i.vials
      //         : `${additionalVials ? i.vials + '+' + additionalVials : i.vials}`;
      //   }
      //   return i;
      // });
      if (twoSteps) {
        let fixedIndex;
        sortedTemp.forEach((item, i) => {
          if (item.fixedRow) fixedIndex = i;
        });
        let fixedTemp = sortedTemp.splice(fixedIndex, 1);
        fixedTemp[0].fixed = 'FIXED';
        if (twoStepsWithFirstFixed) {
          if (!isPro && fixedTemp[0].vials) {
            fixedTemp[0].vials = fixedTemp[0].vials - 2 + '+2';
          }
          sortedTemp.unshift(fixedTemp[0]);
        } else {
          sortedTemp.push(fixedTemp[0]);
        }
      }
      let warning = false;
      if (isPro && heatBlocksSumm > 8) {
        warning = true;
        //Capacity of the heating area is 8 blocks max
      }
      sortedTemp = [
        ...sortedTemp,
        {
          temperature: <span className="quantity">Required quantity:</span>,
          vials: vialsSumm,
          heatBlocks: heatBlocksSumm,
          isWarning: warning,
        },
      ];
      setData(sortedTemp);
    } else setData(emptyState);
  };
  return (
    <Table
      className={
        emptyTable || experiments.process_steps.length == 1
          ? 'hardware-table'
          : twoStepsWithFirstFixed
          ? 'hardware-table fixedStep fixedFirstStep'
          : 'hardware-table fixedStep fixedSecondStep'
      }
      dataSource={data}
      columns={columns(processPreview, twoSteps)}
      sidePadding={false}
    />
  );
};
