import React from 'react';
import { Collapse as AntdCollapse } from 'antd';
import cn from 'classnames';
import './style.scss';

export const Collapse = ({ defaultActiveKey, items, className = '', accordion = false, ...rest }) => (
  <AntdCollapse
    {...rest}
    className={cn('collapse', className)}
    defaultActiveKey={defaultActiveKey}
    expandIconPosition="right"
    accordion={accordion}
  >
    {items.map(item => (
      <AntdCollapse.Panel header={item.title} key={item.key} extra={item.extra}>
        {item.body}
      </AntdCollapse.Panel>
    ))}
  </AntdCollapse>
);
