import React, { useEffect } from 'react';
import { formatSecondsToTimeString } from '../../../utils';
import moment from 'moment';
import './style.scss';

let second;
export const ExperimentTimer = ({ actualTime, estimatedTime, setActualTime, stopCountdown, startTime }) => {
  const updateTime = () => {
    const currentTime = moment().format();
    const newTime = moment(moment(startTime).format());
    const t = moment(currentTime).diff(newTime, 'seconds');
    return t;
  };
  useEffect(() => {
    if (!stopCountdown && actualTime !== null) {
      if (typeof actualTime !== 'number') {
        setActualTime(updateTime());
      } else {
        second = setTimeout(() => {
          setActualTime(updateTime());
        }, 1000);
      }
    }

    return () => {
      if (second) clearTimeout(second);
    };
  }, [actualTime]);
  return (
    <div className="experiment-timer">
      <div className="experiment-timer__time-block">
        <div className="experiment-timer__time-block__title">Actual time</div>
        {typeof actualTime === 'number' && formatSecondsToTimeString(actualTime)}
      </div>
      <div className="experiment-timer__time-block">
        <div className="experiment-timer__time-block__title">Estimated time</div>
        {formatSecondsToTimeString(estimatedTime)}
      </div>
    </div>
  );
};
