import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { Button, ButtonGroup, openNotification, Popup, RangeSlider, Select, Spinner, Tooltip } from '../../Common';
import { ContainerRoute } from '../../AutoSin/CreatorProcessFromRoute/ContainerRoute';
import { lengthObj } from '../../../utils';
import { parseRouteToAutoSyn } from '../../../utils/parseRoute/parseRoute';
import { MESSAGES, testRoute, typesProcess } from '../../../constants';
import { parseSynjetRouteToProcessData } from '../../../utils/parseRoute/parseSynjetRoute';
import { parseRouteToLab } from '../../../utils/parseRoute/parseRouteLab';
import { checkIfMyLock } from '../../../utils/checkIfMyLock';
import { parseSynjetProRouteToProcessData } from '../../../utils/parseRoute/parseSynjetProRoute';

const buttons = [
  { label: 'Full route', value: 'full' },
  { label: 'Selected segment', value: 'segment' },
];

export const ViewInitialRoute = ({
  dataProcess,
  updateProcess,
  setShowInitialRoute,
  editMode,
  isSynjet,
  isPro,
  reactors,
  setGenerationData,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [buttonValue, setButtonValue] = useState(buttons[1].value);
  const [loadingRoute, setLoadingRoute] = useState(true);
  const [rangeSegments, setRangeSegments] = useState(dataProcess?.process?.steps);
  const [defaultSteps, setDefaultSteps] = useState(null);
  const [modeGenerated, setModeGenerated] = useState(dataProcess?.process?.steps ? 'change' : 'generate');
  const [confirmation, setConfirmation] = useState(false);
  const [edited, setEdited] = useState(false);

  const [optionsSteps, setOptionsSteps] = useState([]);
  const [fixed, setFixed] = useState(dataProcess?.process?.fixedStep || null);

  useEffect(() => {
    if (loadingRoute) setLoadingRoute(false);
  });

  useEffect(() => {
    dataProcess?.process?.steps && setRangeSegments(dataProcess?.process?.steps);
  }, [dataProcess?.process?.steps]);

  const closeConfirmation = useCallback(() => setConfirmation(false), []);
  const openConfirmation = useCallback(() => setConfirmation(true), []);

  const updateRangeSegments = useCallback(value => {
    setRangeSegments(value);
  }, []);

  const setDefaultStepsSegment = useCallback(value => {
    setDefaultSteps(value);
    if (isSynjet) {
      const isOneStep = dataProcess.process?.numberOfSteps === '1';
      const arr = [];
      if (isOneStep) {
        for (let i = 0; i <= value[1]; ++i) {
          arr.push({
            label: `Reaction ${i + 1}`,
            value: `${i},${i}`,
          });
        }
      } else {
        for (let i = 0; i < value[1]; ++i) {
          arr.push({
            label: `Reaction ${i + 1} - Reaction ${i + 2}`,
            value: `${i},${i + 1}`,
          });
        }
      }

      if (!fixed && !isOneStep) {
        const fixedDefaultIdx = arr[0].value.split(',')[0];
        setFixed(+fixedDefaultIdx);
      }
      setDefaultSteps(isOneStep ? [0, 0] : [0, 1]);
      setOptionsSteps(arr);
      !rangeSegments && updateRangeSegments(isOneStep ? [0, 0] : [0, 1]);
    } else {
      !rangeSegments && updateRangeSegments(value);
      setDefaultSteps(value);
    }
  }, []);

  const generateProcess = async () => {
    setLoading(true);

    const requestData = {
      stepFrom: rangeSegments[0],
      stepTo: rangeSegments[1],
      uuid: dataProcess?.process?.uuid,
    };

    const isOneStep = dataProcess.process?.numberOfSteps === '1';
    if (isSynjet && !isOneStep) requestData.variableStep = `${rangeSegments.findIndex(i => i !== fixed) + 1}`;
    if (isPro && !isOneStep) requestData.variableStep = dataProcess?.process?.variableStep;
    updateProcess(requestData)
      .then(async () => {
        let result;
        if (typesProcess.lab === type) {
          result = await parseRouteToLab({
            route: routeData,
            segment: dataProcess?.process?.segment,
            part: rangeSegments,
          });
        } else if (isSynjet && !isPro) {
          try {
            result = await parseSynjetRouteToProcessData(
              {
                route: routeData,
                segment: dataProcess?.process?.segment,
                part: rangeSegments,
              },
              dataProcess?.process?.type
            );
            openNotification(MESSAGES['83']);
          } catch (e) {
            openNotification(null, e);
          }
        } else if (isPro && isSynjet) {
          try {
            result = await parseSynjetProRouteToProcessData(
              {
                route: routeData,
                variableStep: dataProcess?.process?.variableStep,
                segment: dataProcess?.process?.segment,
                part: rangeSegments,
              },
              dataProcess?.process?.type
            );
            openNotification(MESSAGES['83']);
          } catch (e) {
            openNotification(null, e);
          }
        } else {
          result = await parseRouteToAutoSyn({
            route: routeData,
            segment: dataProcess?.process?.segment,
            part: rangeSegments,
            allReactors: reactors.allReactors,
          });
        }
        setShowInitialRoute(false);
        setGenerationData(result);
      })
      .finally(() => {
        closeConfirmation();
        setLoading(false);
      });
  };

  const setOptionsFixed = () => {
    const arr = [];
    if (rangeSegments) {
      for (let i = rangeSegments[0]; i <= rangeSegments[1]; ++i) {
        arr.push({
          label: `Reaction ${i + 1}`,
          value: i,
        });
      }
    }
    return arr;
  };

  const modeSegment = buttonValue === buttons[1].value;
  const routeData = dataProcess?.process?.route?.data;

  const showSlider =
    defaultSteps &&
    !(defaultSteps[0] === 0 && defaultSteps[1] === 0) &&
    modeSegment &&
    modeGenerated === 'generate' &&
    rangeSegments;

  const showSelectors = defaultSteps && modeSegment && isSynjet && modeGenerated === 'generate' && rangeSegments;

  const optionsFixed = setOptionsFixed();
  const variable = optionsFixed.find(i => i.value != fixed)?.label;
  const fixedPreview = optionsFixed.find(i => i.value == fixed)?.label;
  const valueSteps = rangeSegments && rangeSegments.join(',');
  const isOneStep = dataProcess.process?.numberOfSteps === '1';
  const ButtonsComponent = () => (
    <>
      {dataProcess?.process?.steps && (
        <Button
          secondary
          onClick={() => {
            updateRangeSegments(dataProcess?.process?.steps);
            if (isSynjet) {
              setFixed(dataProcess?.process?.steps[0]);
            }
            setModeGenerated('change');
          }}
        >
          Cancel
        </Button>
      )}
      <Button
        onClick={() => {
          if (edited) openConfirmation();
          else generateProcess();
        }}
        disabled={(!editMode && !!dataProcess?.process.definition) || checkIfMyLock(dataProcess?.process?.processLock)}
      >
        Generate process
      </Button>
    </>
  );

  const nameRoute = dataProcess?.process?.route?.name;
  return (
    <div className="view-initial-route">
      <Spinner loading={loading}>
        <div className="view-initial-route_header">
          <Tooltip title={nameRoute}>
            <span className="view-initial-route_header_key">{nameRoute}</span>
          </Tooltip>
          <ButtonGroup
            className="view-initial-route_header_buttons"
            value={buttonValue}
            onChange={value => {
              setButtonValue(value);
              setLoadingRoute(true);
            }}
            options={buttons}
          />
          <div className="view-initial-route_header_slider">
            {showSlider && !isSynjet && (
              <RangeSlider
                defaultValue={defaultSteps}
                value={rangeSegments}
                onChange={v => {
                  updateRangeSegments(v);
                  setEdited(true);
                }}
              />
            )}
          </div>
          <div className="view-initial-route_header_buttons-action">
            {modeGenerated === 'change' ? (
              <Button
                secondary
                disabled={
                  (!editMode && !!dataProcess?.process.definition) || checkIfMyLock(dataProcess?.process?.processLock)
                }
                onClick={() => {
                  setButtonValue(buttons[1].value);
                  setModeGenerated('generate');
                }}
              >
                {' '}
                Change segment/steps
              </Button>
            ) : isSynjet ? (
              <></>
            ) : (
              <ButtonsComponent />
            )}
          </div>
          <Popup
            title="Warning"
            open={confirmation}
            textSubmit="Proceed"
            handleCancel={closeConfirmation}
            handleSubmit={generateProcess}
            loading={loading}
          >
            <span>The previous process will be lost, do you want to proceed?</span>
          </Popup>
        </div>
        {showSelectors && (
          <div className="view-initial-route_steps">
            <div className="view-initial-route_steps_container">
              {optionsSteps?.length > 1 && (
                <div className="view-initial-route_steps_item">
                  <span className="view-initial-route_steps_item_label">Step(s)</span>
                  <Select
                    className="view-initial-route_steps_item_steps"
                    showSearch={false}
                    onChange={e => {
                      const newValue = e.split(',').map(i => +i);
                      setFixed(newValue[0]);
                      updateRangeSegments(newValue);
                      setEdited(true);
                    }}
                    value={valueSteps}
                    options={optionsSteps}
                  />
                </div>
              )}
              {!isOneStep && (
                <>
                  <div className="view-initial-route_steps_item">
                    <span className="view-initial-route_steps_item_label">Fixed</span>
                    <span className="view-initial-route_steps_item_value">{fixedPreview}</span>
                  </div>
                  <div className="view-initial-route_steps_item">
                    <span className="view-initial-route_steps_item_label">Variable</span>
                    <span className="view-initial-route_steps_item_value">{variable}</span>
                  </div>
                </>
              )}
            </div>
            <div>
              <ButtonsComponent />
            </div>
          </div>
        )}
        <div className="view-initial-route_content">
          <ContainerRoute
            modeGeneration
            rangeSegments={rangeSegments}
            updateRangeSegments={setDefaultStepsSegment}
            modeSegment={modeSegment}
            filters={{ route: true, segment: dataProcess?.process?.segment }}
            detailsRoute={routeData}
            loadingRoute={loadingRoute}
          />
        </div>
      </Spinner>
    </div>
  );
};
