import React, { useEffect, useState } from 'react';
import './style.scss';

const Text = React.memo(({ options, limit, more }) => {
  if (options > limit) {
    return 'Show less projects';
  } else {
    return `Show ${more} more project${more === 1 ? '' : 's'}`;
  }
});
export const ShowMoreList = ({ options = [], limit = 5 }) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(options.slice(0, limit));
  }, []);
  return (
    <div className="show-more-wrapper">
      <ul className="show-more-list">
        {list.map((v, k) => (
          <li title={v} key={k} className="list-ellipsis">
            {k + 1}.<span>{v}</span>
          </li>
        ))}
      </ul>
      {options.length > limit && (
        <a
          className="global-link"
          onClick={() => {
            if (options.length === list.length) {
              setList(options.slice(0, limit));
            } else {
              setList(options);
            }
          }}
        >
          <Text options={list.length} limit={limit} more={options.length - limit} />
        </a>
      )}
    </div>
  );
};
