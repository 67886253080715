export const processDetailsResp = `name
    updatedAt
    createdAt
    status
    uuid
    device
    definition
    type
    variableStep
    enableQuenching
    createdBy {
      uuid
      lastName
      firstName
    }
    project {
      uuid
      name
      key
    }
    experiments {
      uuid
      key
      status
      name
      completedAt
      route {
          uuid
          data
        }
  timeSlot{
      uuid
      batch {
        uuid
        experiments {
          uuid
        }
      }
      device {
      uuid
      name
      type
      }
      }
}
    processLock{
          uuid,
          createdBy{
            uuid,
            firstName,
            lastName
          }
          isMyLock,
          }
    route{
    data
    name
    key
    uuid
    }
    variableStep
    numberOfSteps
    stepTo
    stepFrom
    segment
    compatibleWith{
      name
      type
      uuid
    }`;
