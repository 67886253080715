import './styles.scss';

import React from 'react';

import { Skeleton } from 'antd';
import cn from 'classnames';

import { SpaceHorizontal, TextBody12 } from '@atoms';
import { SCROLL_BOX_SIZES, ScrollBox } from '@molecules';

import { Search } from '../../../components';
import { ListWithSearchItemDefault } from './components/ListWithSearchItemDefault';

export const ListWithSearchBase = ({
  className,
  options = [],
  itemActions,
  search,
  onSearch,
  onClick,
  isLoading,
  title = 'Search',
  searchPlaceholder = 'Type here',
}) => (
  <ScrollBox
    size={SCROLL_BOX_SIZES.none}
    className={cn('list-with-search', className)}
    isFooter={false}
    header={
      <>
        <Search placeholder={searchPlaceholder} button={false} value={search} onChange={value => onSearch(value)} />
        <SpaceHorizontal multiplier="x4" />
        <TextBody12>{title}</TextBody12>
      </>
    }
    bodyRender={() => {
      if (isLoading) {
        return <Loading />;
      }
      if (options.length) {
        return (
          <div>
            {options.map(option => (
              <ListWithSearchItemDefault {...option} onClick={onClick} itemActions={itemActions} />
            ))}
          </div>
        );
      }
      return (
        <div className="list-with-search_empty-state">
          <TextBody12>No data</TextBody12>{' '}
        </div>
      );
    }}
  />
);

const Loading = () => (
  <div className="list-with-search_loading">
    {Array.from(Array(5).keys()).map(() => (
      <Skeleton.Input size="medium" active block />
    ))}
  </div>
);
