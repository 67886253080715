import React from 'react';
import cn from 'classnames';

export const Packed = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 16C15 20.3301 14.3401 24.2057 13.3063 26.9626C12.788 28.3447 12.195 29.3937 11.5872 30.0792C10.9784 30.7659 10.4397 31 10 31C9.56025 31 9.0216 30.7659 8.41279 30.0792C7.80501 29.3937 7.21198 28.3447 6.69369 26.9626C5.65987 24.2057 5 20.3301 5 16C5 11.6699 5.65987 7.79426 6.69369 5.03742C7.21198 3.6553 7.80501 2.60629 8.41279 1.92077C9.0216 1.23408 9.56025 1 10 1C10.4397 1 10.9784 1.23408 11.5872 1.92077C12.195 2.60629 12.788 3.6553 13.3063 5.03742C14.3401 7.79426 15 11.6699 15 16Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M36 16C36 20.3301 35.3401 24.2057 34.3063 26.9626C33.788 28.3447 33.195 29.3937 32.5872 30.0792C31.9784 30.7659 31.4397 31 31 31C30.5603 31 30.0216 30.7659 29.4128 30.0792C28.805 29.3937 28.212 28.3447 27.6937 26.9626C26.6599 24.2057 26 20.3301 26 16C26 11.6699 26.6599 7.79426 27.6937 5.03742C28.212 3.6553 28.805 2.60629 29.4128 1.92077C30.0216 1.23408 30.5603 1 31 1C31.4397 1 31.9784 1.23408 32.5872 1.92077C33.195 2.60629 33.788 3.6553 34.3063 5.03742C35.3401 7.79426 36 11.6699 36 16Z"
        fill="white"
        fillOpacity="0.4"
        stroke="white"
        strokeWidth="2"
      />
      <line x1="35.918" y1="15.918" x2="39.9996" y2="15.918" stroke="white" strokeWidth="2" />
      <line y1="15.918" x2="4.08163" y2="15.918" stroke="white" strokeWidth="2" />
      <line x1="10" y1="1" x2="31" y2="1" stroke="white" strokeWidth="2" />
      <line x1="10" y1="31" x2="31" y2="31" stroke="white" strokeWidth="2" />
    </svg>
  </div>
);
