import './ColumnsSortableListItem.styles.scss';

import React from 'react';

import { Highlighter, SpaceVertical, TextBody14 } from '@atoms';

import { Checkbox } from '../../../../components';
import { ReactComponent as Drag } from '../../../../dist/images/drag.svg';

export const ColumnsSortableListItem = ({
  item: { label, value, selected },
  searchValue,
  onSelect,
  activeHighlightedItemValue,
}) => {
  const onChange = () => {
    onSelect(value);
  };
  return (
    <div id={value} className="columns-sortable-list-item">
      <Drag />
      <SpaceVertical multiplier="x3" />
      <Checkbox className="columns-sortable-list-item_checkbox" onChange={onChange} value={selected}>
        <TextBody14>
          <Highlighter active={activeHighlightedItemValue === value} value={label} highlightPart={searchValue} />
        </TextBody14>
      </Checkbox>
    </div>
  );
};
