export const SYNJET_UPDATE_COMPOUND_LIST = 'SYNJET_UPDATE_COMPOUND_LIST';
export const SYNJET_UPDATE_COMPOUND_GROUP = 'SYNJET_UPDATE_COMPOUND_GROUP';
export const SYNJET_UPDATE_COMPOUND = 'SYNJET_UPDATE_COMPOUND';
export const SYNJET_ADD_COMPOUND_TO_LIST = 'SYNJET_ADD_COMPOUND_TO_LIST';
export const SYNJET_ADD_COMPOUND_GROUP_TO_LIST = 'SYNJET_ADD_COMPOUND_GROUP_TO_LIST';
export const SYNJET_ADD_COMPOUND_TO_GROUP = 'SYNJET_ADD_COMPOUND_TO_GROUP';
export const SYNJET_ADD_NULL_COMPOUND_TO_GROUP = 'SYNJET_ADD_NULL_COMPOUND_TO_GROUP';
export const SYNJET_UPDATE_CONDITIONS = 'SYNJET_UPDATE_CONDITIONS';
export const SYNJET_UPDATE_PRODUCT = 'SYNJET_UPDATE_PRODUCT';
export const SYNJET_SET_TWO_STEP_PROCESS = 'SYNJET_SET_TWO_STEP_PROCESS';
export const SYNJET_ADD_CONDITION = 'SYNJET_ADD_CONDITION';
export const SYNJET_DELETE_CONDITION = 'SYNJET_DELETE_CONDITION';
export const SYNJET_SET_PROCESS = 'SYNJET_SET_PROCESS';
export const SYNJET_DELETE_COMPOUND_GROUP = 'SYNJET_DELETE_COMPOUND_GROUP';
export const SYNJET_UPDATE_COMPOUND_GROUPS = 'SYNJET_UPDATE_COMPOUND_GROUPS';
export const SYNJET_RESET_STORE = 'SYNJET_RESET_STORE';
export const SYNJET_SET_RULE = 'SYNJET_SET_RULE';
export const SYNJET_GENERATE_EXPERIMENTS_DATA = 'SYNJET_GENERATE_EXPERIMENTS_DATA';
export const SET_VALIDATION_ERRORS_SYNJET = 'SET_VALIDATION_ERRORS_SYNJET';
export const SYNJET_SET_STEPS = 'SYNJET_SET_STEPS';
export const SYNJET_RESET_STEPS = 'SYNJET_RESET_STEPS';
export const SET_BATCH_ERROR_SHOWN = 'SET_BATCH_ERROR_SHOWN';
export const UPDATE_STATE_COLLAPSE = 'UPDATE_STATE_COLLAPSE';
export const SYNJET_PRO_GENERATE_PRODUCTS_DATA = 'SYNJET_PRO_GENERATE_PRODUCTS_DATA';
export const SYNJET_PRO_GENERATE_EI_DATA = 'SYNJET_PRO_GENERATE_EI_DATA';

export const SYNJET_PRO_UPDATE_QUENCHING_LIST = 'SYNJET_PRO_UPDATE_QUENCHING_LIST';
export const SYNJET_PRO_ADD_QUENCHING_ITEM = 'SYNJET_PRO_ADD_QUENCHING_ITEM';
export const SYNJET_PRO_DATA_UPDATE = 'SYNJET_PRO_DATA_UPDATE';
export const SYNJET_PRO_SET_FORCE_CONDITION_VARIABLE_GENERATION = 'SYNJET_PRO_SET_FORCE_CONDITION_VARIABLE_GENERATION';
