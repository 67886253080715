import './TableInitialLoading.styles.scss';

import React from 'react';

import { Skeleton } from 'antd';

import { TextBody16 } from '@atoms';

import { Spinner } from '../../../../components';

export const TableInitialLoading = ({ loading }) => (
  <Spinner
    wrapperClassName="virtual-table-spinner"
    loading={loading}
    tip={
      <span>
        Please wait
        <br />
        <TextBody16 secondary className="spinner-subtitle">
          Your table is loading
        </TextBody16>
      </span>
    }
  >
    <div className="skeleton-header-loader">
      {['10%', '15%', '5%', '10%', '15%', '5%', '10%'].map(width => (
        <div style={{ width }}>
          <Skeleton.Input active />
        </div>
      ))}
    </div>
    <Skeleton active paragraph={{ rows: 20 }} />
  </Spinner>
);
