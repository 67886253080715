import './style.scss';

import React, { useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { Button } from 'components/Common';
import { StatusPriority } from 'components/Experiment/StatusPriority';
import { SYNJET_PRO_PROCESS_TYPES, SYNJET_PROCESS_TYPES } from 'constants/common';
import moment from 'moment';
import { formatSecondsToMinutes, showExpTime } from 'utils';
import { getExperimentTableData } from 'utils/execution';

import { RejectExperimentFromBatchPopup } from './RejectExperimentFromBatchPopup';

const dataMapping = [
  { name: 'Process type', field: 'type' },
  { name: 'Estimated time', field: 'totalTime' },
  { name: 'Temperature range, °C', field: 'temperature' },
  { name: 'Time range, min', field: 'range' },
  { name: '# Vials', field: 'vials' },
  { name: '# Dispensers', field: 'dispensers' },
];

const ValuesWithSeparator = ({ values = [] }) => (
  <div className="values-with-separator">
    {values.map(v => (
      <div className="values-with-separator__item">{v}</div>
    ))}
  </div>
);

export const ExperimentBatchItem = ({
  data,
  removeFromBatch,
  moveToBatch,
  goToDetails,
  canEdit,
  canRejectExperiment,
  executionMode = false,
}) => {
  const reactionsInfo = data?.reactionsInfo ? JSON.parse(data.reactionsInfo) : null;

  const TEMPERATURES_RANGE = ['low', 'med', 'high'];
  const TEMPERATURE_FIXED = ['fixed'];
  const ALL_CONDITIONS = [TEMPERATURE_FIXED, TEMPERATURE_FIXED, TEMPERATURE_FIXED, TEMPERATURE_FIXED];
  const SJP_FIRST_VARIABLE_CONDITIONS = [TEMPERATURE_FIXED, TEMPERATURE_FIXED, TEMPERATURE_FIXED];
  const SJP_SECOND_VARIABLE_CONDITIONS = [
    TEMPERATURE_FIXED,
    TEMPERATURE_FIXED,
    TEMPERATURE_FIXED,
    TEMPERATURE_FIXED,
    TEMPERATURE_FIXED,
    TEMPERATURE_FIXED,
    TEMPERATURE_FIXED,
  ];

  const mapToValues =
    data?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION
      ? data?.process?.numberOfSteps == 1
        ? [TEMPERATURES_RANGE]
        : data?.process?.variableStep == 1
        ? [TEMPERATURES_RANGE, TEMPERATURE_FIXED]
        : [TEMPERATURE_FIXED, TEMPERATURES_RANGE]
      : data?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION
      ? data?.process?.numberOfSteps == 1
        ? [...ALL_CONDITIONS, ...ALL_CONDITIONS]
        : data?.process?.variableStep == 1
        ? [...SJP_FIRST_VARIABLE_CONDITIONS, TEMPERATURE_FIXED]
        : [TEMPERATURE_FIXED, ...SJP_SECOND_VARIABLE_CONDITIONS]
      : data?.process?.numberOfSteps == 1
      ? ALL_CONDITIONS
      : data?.process?.variableStep == 1
      ? [...ALL_CONDITIONS, TEMPERATURE_FIXED]
      : [TEMPERATURE_FIXED, ...ALL_CONDITIONS];

  return (
    <div className="experiment-batch-item">
      <div className="experiment-batch-item__content">
        <div className="experiment-batch-item__content__header">
          <div className="experiment-batch-item__content__header__title">{data.name}</div>
          <div className="experiment-batch-item__content__header__priority">
            <StatusPriority priority={data.priority} />
          </div>
        </div>
        <div className="experiment-batch-item__content__data">
          {dataMapping.map((dataItem, index) => {
            let value = '';
            switch (dataItem.field) {
              case 'type': {
                value = `${data.process?.type}(${data.process?.numberOfSteps}-step)`;
                break;
              }
              case 'totalTime': {
                value = showExpTime(data.totalTime);
                break;
              }
              case 'temperature': {
                const values = [];
                mapToValues.forEach((v, index) => {
                  v.forEach(key => {
                    if (reactionsInfo.conditions[index]) values.push(reactionsInfo.conditions[index].temperature[key]);
                  });
                });
                value = <ValuesWithSeparator values={values} />;
                break;
              }
              case 'range': {
                let values = [];
                mapToValues.forEach((v, index) => {
                  v.forEach(key => {
                    if (reactionsInfo.conditions[index]) values.push(reactionsInfo.conditions[index].time[key]);
                  });
                });
                values = values.map(v => formatSecondsToMinutes(v));
                value = <ValuesWithSeparator values={values} />;
                break;
              }
              case 'vials': {
                const numberOfVials = data?.vialsCount;
                /* let { processSteps } = getExperimentTableData(data);
                if (processSteps.length > 1) {
                  numberOfVials = processSteps.find(step => step?.step?.isFixed)?.step?.vials?.length || 0;
                } else {
                  processSteps.forEach(step => {
                    numberOfVials += step.step.vials.length;
                  });
                }
                // add prime vial
                numberOfVials += 1; */
                value = `${numberOfVials} vial${numberOfVials > 1 ? 's' : ''}`;
                break;
              }
              case 'dispensers': {
                /* let numberOfDispensers = JSON.parse(dispensers)?.filter(i =>
                  i.experiments.find(exp => exp.experiment_uuid === data.uuid)
                )?.length; */
                const numberOfDispensers = data?.dispensersCount;
                value = numberOfDispensers
                  ? `${numberOfDispensers} dispenser${numberOfDispensers > 1 ? 's' : ''}`
                  : '-';
                break;
              }
            }
            return (
              <div className={cn('experiment-batch-item__content__data__item', `data-item-${index}`)}>
                <div className="experiment-batch-item__content__data__item__title">{dataItem.name}</div>
                <div className="experiment-batch-item__content__data__item__value">{value}</div>
              </div>
            );
          })}
        </div>
        <div className="experiment-batch-item__content__buttons">
          <div>
            {(canEdit || canRejectExperiment) && (
              <>
                <Button secondary onClick={removeFromBatch}>
                  Remove from batch
                </Button>
                {!executionMode && (
                  <Button secondary style={{ marginLeft: 8 }} onClick={moveToBatch}>
                    Move to another batch
                  </Button>
                )}
              </>
            )}
          </div>
          {!executionMode && (
            <Button secondary onClick={goToDetails}>
              Details
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
