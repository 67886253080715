import React from 'react';
import { Table } from '../../Common';
import { CompoundSmilesPreview } from '../../index';
import { columnsManual } from './columnsManual';
import { LimitedContainer } from '../../ExperimentDetailsComponents/LimitedContainer';

const columnList = [
  {
    title: 'Compound name',
    dataIndex: 'compound_name',
    key: 'compound_name',
    render: (include, rowData) => (
      <div>
        <CompoundSmilesPreview compound={rowData?.compound} hideMW />
        {rowData.is_limited && <LimitedContainer />}
      </div>
    ),
  },
  {
    title: 'Chemical type',
    dataIndex: 'chemical_type',
    key: 'chemical_type',
  },
  {
    title: 'Pump / Reactor',
    dataIndex: 'pump_reactor',
    key: 'pump_reactor',
    render: item => {
      if (!item.startsWith('Pump')) {
        return `Reactor ${item}`;
      }
      return item;
    },
  },
  {
    title: 'MW, g/mol',
    dataIndex: 'mw',
    key: 'mw',
  },

  {
    title: 'Concentration, M',
    dataIndex: 'concentration',
    key: 'concentration',
  },
  {
    title: 'Equivalent',
    dataIndex: 'equivalent',
    key: 'equivalent',
  },
  {
    title: 'Production rate, mg/h',
    dataIndex: 'prod_rate',
    key: 'prod_rate',
    className: 'reaction-tab__table__prod-rate',
    align: 'right',
  },
];

export const ReactionTabTable = ({ reactionsData, manual = false }) => (
  <div data-protected className="reaction-table">
    <Table columns={manual ? columnsManual : columnList} dataSource={reactionsData} sidePadding={false} />
  </div>
);
