import './styles.scss';

import React, { useState } from 'react';

import cn from 'classnames';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ renderItem, ...props }) => renderItem(props));

const List = SortableContainer(({ items, renderItem, dragging }) => (
  <div className={cn('sortable-list', { dragging })}>
    {items.map((item, index) => (
      <SortableItem key={item.value} index={index} item={item} renderItem={renderItem} />
    ))}
  </div>
));
export const SortableList = ({ items, onOrderChanged, renderItem }) => {
  const [dragging, setDragging] = useState(false);
  const onSortEnd = ({ oldIndex: oldIdx, newIndex: newIdx }) => {
    const itemsCopy = [...items];
    const [item] = itemsCopy.splice(oldIdx, 1);
    itemsCopy.splice(newIdx, 0, item);
    onOrderChanged(itemsCopy);
    setDragging(false);
  };
  const onSortStart = () => {
    setDragging(true);
  };
  return (
    <List
      dragging={dragging}
      helperClass="sortable-list-helper"
      items={items}
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      renderItem={renderItem}
    />
  );
};
