import React, { useCallback, useState } from 'react';
import './style.scss';
import { typesProjects } from '../../../../constants';
import { Button, openNotification, Popup, StatusLabel, Tooltip } from '../../../Common';
import { ListTabs } from './ListTabs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cn from 'classnames';

const options = [
  {
    name: 'General information',
    route: 'general',
  },
  {
    name: 'Project team',
    route: 'team',
  },
  {
    name: 'Project activities',
    route: 'activities',
  },
  {
    name: 'Target product',
    route: 'product',
  },
];

export const MenuDetails = ({ details, permissions, updateTab, activeTab, updateStatusProject }) => {
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeCollapse, setActiveCollapse] = useState(true);
  const typeConfig = typesProjects[details.type];
  const Icon = typeConfig.icon;
  const status = typeConfig[details?.status];

  const closeConfirmation = useCallback(() => setConfirmation(false), []);
  const openConfirmation = useCallback(() => setConfirmation(true), []);

  const activateProject = () => {
    setLoading(true);
    const defaultData = { uuid: details.uuid, name: details.name, program: details.program };
    updateStatusProject({ ...defaultData, status: 'active' })
      .then(resp => {
        if (!resp?.errors) {
          closeConfirmation();
          openNotification(`Project has been activated`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className={cn('menu-details-tab-expand', { 'menu-details-tab-collapsed': !activeCollapse })}>
      <div className={cn('menu-details-tab', { 'expand-menu': !activeCollapse })}>
        <div className="menu-details-tab_common">
          <Icon className={typeConfig[details.status].className} />
          <div className="menu-details-tab_common_info">
            <span className="menu-details-tab_common_info_name" title={details?.name}>
              {details?.name}
            </span>
            <span className="menu-details-tab_common_info_uuid-date common-style-text">
              {details?.key} •
              <Tooltip title={`Project was created on ${details?.createdAt}`}>
                {' '}
                {details?.createdAt.substring(0, 11)}
              </Tooltip>
            </span>
            <span className="menu-details-tab_common_info_extra">{typeConfig.label}</span>
            <StatusLabel type={status?.name}>{details?.status}</StatusLabel>
          </div>
        </div>
        <ListTabs
          options={options.filter(option => (details.type === 'Drug Discovery' ? true : option.route !== 'product'))}
          updateTab={updateTab}
          activeTab={activeTab}
        />
        {details?.status === 'draft' && permissions.change_project_status && (
          <Button block onClick={openConfirmation}>
            Activate project
          </Button>
        )}

        <Popup
          title="Attention"
          open={confirmation}
          textSubmit="Continue"
          handleCancel={closeConfirmation}
          handleSubmit={activateProject}
          loading={loading}
        >
          <span>
            Continuing activates the project. When the project is activated, you will be able to manage the Project
            team, and the system will share the Project Info with each user added as a team member. Continue?
          </span>
        </Popup>
      </div>
      <div
        className="circle"
        onClick={() => {
          setActiveCollapse(!activeCollapse);
        }}
      >
        <LeftOutlined className={cn({ 'circle-expaned': !activeCollapse })} />
        <RightOutlined className={cn({ 'circle-expaned': activeCollapse })} />
      </div>
    </div>
  );
};
