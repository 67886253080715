import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { EXPERIMENT_STATUSES } from 'constants/common';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { openNotification, Popup } from '../../../components/Common';
import { Spinner } from '../../../components/Common/Spinner';
import { ExperimentFinalization } from '../../../components/Execution/ExperimentFinalization/ExperimentFinalization';
import { ExperimentFinalizationPopup } from '../../../components/Execution/ExperimentFinalization/ExperimentFinalizationPopup';
import { ExperimentProgress } from '../../../components/Execution/ExperimentProgress';
import { Footer, NotesSidebar, ReviseTheDetails, Steps } from '../../../components/Execution/index';
import { PrepareMaterials } from '../../../components/Execution/PrepareMaterials';
import { StartUpChecklist } from '../../../components/Execution/StartUpChecklist';
import { DeclineExperimentPopup } from '../../../components/Experiment/DeclineExperimentPopup';
import { getDetailsUser } from '../../../store/common/common.actions';
import { editModeSelector, user } from '../../../store/common/common.selector';
import {
  completeExperiment,
  createNote,
  endExperiment,
  finalizeExperiment,
  getCheckList,
  getExperiment,
  getNotes,
  getProcessDeviceStatus,
  inProgressExperiment,
  pausedExperiment,
  setRouteStructureFromExp,
  updateDataCheckList,
  updateDataPreparationMaterials,
  updateExperimentPumpKeys,
} from '../../../store/experiment/experiment.actions';
import { connectivity, notes } from '../../../store/experiment/experiment.selector';
import { getReactorsTypes, updateProcessDefinition } from '../../../store/processBuilder/processbuilder.actions';
import { reactors } from '../../../store/processBuilder/processbuilder.selector';
import { parseJSONErrors, parseProcessBuilderData } from '../../../utils';
import { getProcessStructureFromDefinition } from '../../../utils/execution';
import history from '../../../utils/history';
import { parseExpToRouteAutoSyn } from '../../../utils/parseExpToRoute';

const validationErrorText = 'Process is not valid anymore';

const finalizationCheckList = {
  removeReagents: { label: 'Remove reagent and solvent bottles', checked: false },
  emptyWaste: { label: 'Empty waste', checked: false },
  collectProduct: { label: 'Collect product', checked: false },
};

export const Details = () => {
  const dispatch = useDispatch();
  const { key: experimentKey } = useParams();
  const [experimentID, setExperimentId] = useState(null);
  const notesList = useSelector(notes);
  const currentUser = useSelector(user);
  const remoteConnectivity = useSelector(connectivity);
  const [activeStep, setActiveState] = useState(0);
  const [showNotesSidebar, setShowNotesSidebar] = useState(false);
  const [experiment, setExperiment] = useState(null);
  const [definition, setDefinition] = useState(null);
  const [deviceSetUp, setDeviceSetUp] = useState([]);
  const [processStructure, setProcessStructure] = useState([]);
  const [experimentLoader, setExperimentLoader] = useState(false);
  const [declinePopupOpen, setDeclinePopupOpen] = useState(false);
  const [errors, setErrors] = useState({});

  // step 2

  const [tableData, setTableData] = useState([]);
  const [height, setHeight] = useState(500);
  const [errorsStepTwo, setErrorsStepTwo] = useState([]);
  //
  // step 3
  const [checkList, setCheckList] = useState([]);
  //
  // step 4
  // ....
  const [showPause, setShowPause] = useState(true);
  const [showEndExperiment, setShowEndExperiment] = useState(false);

  const [openPauseConf, setOpenPauseConf] = useState(false);
  const [openResumeConf, setOpenResumeConf] = useState(false);
  const [openEndConf, setOpenEndConf] = useState(false);

  // step5
  const [finalizationOptions, setFinalizationOptions] = useState(finalizationCheckList);
  const [finalizationPopupOpen, setFinalizationPopupOpen] = useState(false);

  //
  // common
  const [confirmation, setConfirmation] = useState(false);
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(true);
  //
  const allReactors = useSelector(reactors);

  const closeConfirmation = useCallback(() => {
    setConfirmation(false);
  }, []);
  const openConfirmation = useCallback(() => {
    setConfirmation(true);
  }, []);
  const handleRejectNavigation = () => {
    history.push('/execution');
  };

  const updateNotesList = body => {
    dispatch(getNotes(body));
  };

  const setDefaultStateStepTwo = useCallback(() => {
    setTableData([]);
  }, []);
  const setDefaultStateStepThird = useCallback(() => {
    getCheckListForStep();
  }, []);

  const processDataExperiment = (data, reactors) => {
    setExperiment(data);
    const pumpKeys = JSON.parse(data?.pumpKeys);
    const defaultPumpKeys = JSON.parse(data?.defaultPumpKeys);
    setDeviceSetUp(pumpKeys?.length ? pumpKeys : defaultPumpKeys);
    if (data.process.definition) {
      setDefinition(parseProcessBuilderData(JSON.parse(data.process.definition), reactors, data.process.type));
    } else {
      setDefinition(null);
    }

    if (data.step) setActiveState(data.step + 1);
    else setActiveState(1);
  };

  const checkDeviceOnlineStatus = data => {
    if (!data.connection) {
      openNotification(null, 'Hardware connectivity issue occurred, please wait until connection is restored');
      return false;
    }

    if (!data.device) {
      openNotification(
        null,
        `AutoSyn ${experiment?.timeSlot?.device?.name} is offline now - the Experiment cannot be executed`
      );
      return false;
    }

    return processStructure.reduce((isValid, item) => {
      const key = item.isReactor ? item.key : deviceSetUp.find(device => device.name === item?.name)?.key;
      const property = item.isReactor ? 'reactors' : 'pumps';
      const isOnline = data[property].find(component => component.key === key)?.online;
      if (!isOnline) {
        openNotification(
          null,
          item.isReactor
            ? `Reactor ${key} is offline now, the Experiment cannot be executed`
            : `Pump ${key} is offline now, use another Pump key from the list`
        );
      }
      return isValid && isOnline;
    }, true);
  };

  const handleContinue = () => {
    const oldScreen = experiment.step + 1 !== activeStep;
    checkIfProcessStillValid();
    const finallyFunc = () => {
      closeConfirmation();
      setLoading(false);
    };
    if (!edited && oldScreen && experiment.step) {
      if (activeStep === 1) {
        dispatch(getProcessDeviceStatus(experiment.timeSlot.device.uuid, experiment.timeSlot.device.name)).then(
          data => {
            if (!checkDeviceOnlineStatus(data)) {
              return;
            }
            setActiveState(activeStep + 1);
            setEdited(false);
          }
        );
      } else if (activeStep === 3) {
        dispatch(getProcessDeviceStatus(experiment.timeSlot.device.uuid, experiment.timeSlot.device.name)).then(
          data => {
            if (!data.connection) {
              openNotification(null, 'Hardware connectivity issue occurred, please wait until connection is restored');
              return;
            }
            if (!data.device) {
              openNotification(null, 'Experiment could not be launched due to execution service connection issues');
              return;
            }
            setActiveState(activeStep + 1);
            setEdited(false);
          }
        );
      } else {
        setActiveState(activeStep + 1);
        setEdited(false);
      }
      return;
    }
    if (edited && !confirmation && oldScreen) {
      openConfirmation();
      return;
    }
    setLoading(true);
    if (activeStep === 1) {
      const duplicatedSetUp = deviceSetUp.map(setUp => setUp.key).filter((e, i, a) => e && a.indexOf(e) !== i);
      const emptySetUp = deviceSetUp.map(setUp => setUp.key).filter(key => !key);
      if (duplicatedSetUp.length || emptySetUp.length) {
        const newError = {};
        duplicatedSetUp.forEach(value => (newError[value] = ' The same Pump Key is used more then once'));
        emptySetUp.forEach(value => (newError[value] = ' Please select the pump key'));
        setErrors(newError);
        finallyFunc();
      } else {
        dispatch(getProcessDeviceStatus(experiment.timeSlot.device.uuid, experiment.timeSlot.device.name)).then(
          data => {
            if (!checkDeviceOnlineStatus(data)) {
              finallyFunc();
              return;
            }

            dispatch(updateExperimentPumpKeys(experimentID, deviceSetUp))
              .then(resp => {
                if (resp?.errors) {
                  const errorsRequest = parseJSONErrors(resp.errors);
                  errorsRequest.map((err, index) => {
                    openNotification('', err, 5, `queue-notification-${index}`);
                  });
                  return;
                }
                if (confirmation) {
                  dispatch(getExperiment(experimentKey)).then(resp => {
                    processDataExperiment(resp, allReactors);
                  });
                  setDefaultStateStepTwo();
                } else {
                  setActiveState(2);
                  setExperiment({ ...experiment, step: 1 });
                }
              })
              .finally(finallyFunc);
          }
        );
      }
    } else if (activeStep === 2) {
      const errors = [];
      tableData.forEach((i, idx) => {
        if (i.isBottle1 && i.calc_volume.value - 0.05 * i.calc_volume.value > +i.act_volume.value)
          errors.push({ index: idx, field: 'act_volume' });
      });
      if (errors.length) {
        openNotification('', 'Actual Volume should be more than Calculated Volume');
        setErrorsStepTwo(errors);
        setLoading(false);
        return;
      }
      dispatch(updateDataPreparationMaterials(null, { uuid: experiment.uuid }))
        .then(() => {
          if (confirmation) {
            dispatch(getExperiment(experimentKey)).then(resp => {
              processDataExperiment(resp, allReactors);
            });
            setDefaultStateStepThird();
          } else {
            setActiveState(3);
            setExperiment({ ...experiment, step: 2 });
          }
        })
        .finally(finallyFunc);
    } else if (activeStep === 3) {
      dispatch(getProcessDeviceStatus(experiment.timeSlot.device.uuid, experiment.timeSlot.device.name)).then(data => {
        if (!data.connection) {
          openNotification(null, 'Hardware connectivity issue occurred, please wait until connection is restored');
          finallyFunc();
          return;
        }
        if (!data.device) {
          openNotification(null, 'Experiment could not be launched due to execution service connection issues');
          finallyFunc();
          return;
        }
        updateDataCheckList(experiment.uuid)
          .then(() => {
            if (confirmation) setActiveState(4);
            dispatch(getExperiment(experimentKey)).then(resp => {
              processDataExperiment(resp, allReactors);
            });
          })
          .finally(finallyFunc);
      });
    } else if (activeStep === 4) {
      dispatch(finalizeExperiment(experimentID))
        .then(() => {
          if (confirmation) setActiveState(5);
          dispatch(getExperiment(experimentKey)).then(resp => {
            processDataExperiment(resp, allReactors);
          });
        })
        .finally(finallyFunc);
    }
    setEdited(false);
  };

  const handleBack = () => {
    setActiveState(activeStep => activeStep - 1);
    setEdited(false);
  };
  const getCheckListForStep = () => {
    getCheckList().then(resp => {
      setCheckList(resp.map(i => ({ name: i, checked: false })));
    });
  };

  useEffect(() => {
    getCheckListForStep();
  }, []);

  useEffect(() => {
    if (experiment?.timeSlot?.device?.uuid) {
      dispatch(getProcessDeviceStatus(experiment.timeSlot.device.uuid, experiment.timeSlot.device.name));
    }
  }, [experiment]);

  const getExperimentData = () => {
    setExperimentLoader(true);
    Promise.all([dispatch(getReactorsTypes()), dispatch(getExperiment(experimentKey))]).then(resp => {
      const data = resp[1];
      updateNotesList({ experimentId: data.uuid });
      processDataExperiment(data, resp[0]);
      setExperimentId(data.uuid);
      setShowEndExperiment(data.status === EXPERIMENT_STATUSES.Paused.value);
    });
    setExperimentLoader(false);
  };

  useEffect(() => {
    getExperimentData();
  }, [experimentKey]);

  useEffect(() => {
    const updatedProcessStructure = getProcessStructureFromDefinition(definition).filter(structure => structure.name);
    setProcessStructure(updatedProcessStructure);
    let updatedSetUp = [...deviceSetUp];
    updatedProcessStructure
      .filter(structure => !structure.isReactor)
      .forEach(structure => {
        if (!deviceSetUp.find(setUp => setUp.name === structure.name))
          updatedSetUp = [...updatedSetUp, { name: structure.name, key: '' }];
      });
    setDeviceSetUp(updatedSetUp);
  }, [definition]);

  let disable = false;
  const isInProgress = experiment?.status === 'In Progress';
  if (activeStep === 2 && tableData) {
    disable =
      !!tableData.filter(i => i.has_sent.hasOwnProperty('checked') && !i.has_sent.checked).length +
      !!errorsStepTwo.length;
  } else if (activeStep === 3 && !isInProgress) {
    disable = !!checkList.filter(i => !i.checked).length;
  }

  const endExperimentClick = () => {
    endExperimentBtn(experimentID);
    setOpenEndConf(false);
  };

  const pauseExperiment = () => {
    setShowPause(false);
    setShowEndExperiment(true);
    setLoading(true);
    dispatch(pausedExperiment(experimentID)).then(resp => {
      createNote({
        body: ` Experiment was Paused by: ${currentUser.firstName} ${currentUser.lastName}`,
        experiment: experimentID,
      });
      getExperimentInfo();
      setOpenPauseConf(false);
    });
  };
  const resumeExperiment = () => {
    setShowPause(true);
    setShowEndExperiment(false);
    setLoading(true);
    dispatch(inProgressExperiment(experimentID)).then(resp => {
      createNote({
        body: `Experiment was Resumed from Pause by: ${currentUser.firstName} ${currentUser.lastName}`,
        experiment: experimentID,
      });
      getExperimentInfo();
      setOpenResumeConf(false);
    });
  };
  const endExperimentBtn = () => {
    setShowPause(true);
    setShowEndExperiment(false);
    setLoading(true);
    dispatch(endExperiment(experimentID)).then(resp => {
      updateNotesList({ experimentId: experimentID });
      getExperimentInfo();
    });
  };

  const getExperimentInfo = () => {
    dispatch(getExperiment(experimentKey)).then(resp => {
      setExperiment(resp);
      setLoading(false);
      updateNotesList({ experimentId: experimentID });
    });
  };

  const finalizeExperimentAction = () => {
    handleContinue();
    /* setLoading(true);
    dispatch(finalizeExperiment(experimentID)).then(data => {
      setLoading(false);
      if (!data.errors) handleContinue();
    }); */
  };

  const showFinalizationPopup = () => {
    setFinalizationPopupOpen(true);
  };

  const completeExperimentAction = async () => {
    setLoading(true);
    return dispatch(parseExpToRouteAutoSyn({ experiment }))
      .then(data => setRouteStructureFromExp({ uuid: experimentID, data: JSON.stringify(JSON.stringify(data)) }))
      .then(() => dispatch(completeExperiment(experimentID)))
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkIfProcessStillValid = () => {
    dispatch(
      updateProcessDefinition(
        { data: JSON.parse(experiment?.process?.definition), uuid: experiment?.process.uuid },
        true
      )
    )
      .then(resp => {
        setValidationError(!!resp.updateProcessDefinition?.errors);
        if (resp.updateProcessDefinition?.errors) {
          openNotification(validationErrorText, validationErrorText);
        }
        return !!resp.updateProcessDefinition?.errors;
      })
      .catch(err => {});
  };

  return (
    <Spinner loading={experimentLoader || loading}>
      <>
        <div className="reset-padding-container">
          <Steps active={activeStep} />
          {activeStep === 1 && (
            <ReviseTheDetails
              setEdited={setEdited}
              experiment={experiment}
              processStructure={processStructure}
              definition={definition}
              deviceSetUp={deviceSetUp}
              setDeviceSetUp={setDeviceSetUp}
              stepErrors={errors}
              setErrors={setErrors}
              remoteConnectivity={remoteConnectivity}
            />
          )}
          {activeStep === 2 && (
            <PrepareMaterials
              setEdited={setEdited}
              setTableData={setTableData}
              setHeight={setHeight}
              setErrors={setErrorsStepTwo}
              tableData={tableData}
              height={height}
              errors={errorsStepTwo}
              experiment={experiment}
              checkIfProcessStillValid={checkIfProcessStillValid}
              validationError={validationError}
            />
          )}
          {activeStep === 3 && (
            <>
              <StartUpChecklist
                experiment={experiment}
                checkList={checkList}
                setCheckList={setCheckList}
                definition={definition}
                setEdited={setEdited}
                processStructure={processStructure}
                deviceSetUp={deviceSetUp}
                setDeviceSetUp={setDeviceSetUp}
                stepErrors={errors}
                setErrors={setErrors}
                remoteConnectivity={remoteConnectivity}
              />
            </>
          )}
          {activeStep === 4 && (
            <ExperimentProgress
              experiment={experiment}
              allReactors={allReactors}
              updateExperimentInfoOnError={getExperimentInfo}
              getExperimentData={getExperimentData}
            />
          )}
          {activeStep === 5 && (
            <ExperimentFinalization
              experiment={experiment}
              allReactors={allReactors}
              options={finalizationOptions}
              setOptions={setFinalizationOptions}
              showTimer
            />
          )}
        </div>
        <Footer
          activeStep={activeStep}
          checkIfProcessStillValid={checkIfProcessStillValid}
          handleDecline={() => setDeclinePopupOpen(true)}
          permissions={currentUser?.permissions}
          showNotes={() => {
            setShowNotesSidebar(true);
          }}
          experiment={experiment}
          showEndExperiment={showEndExperiment}
          endExperiment={setOpenEndConf}
          disableContinue={disable || (activeStep === 2 && validationError)}
          notesLength={notesList.length}
          canSeeNotes={!!currentUser.permissions?.execution.view_note}
          handleContinue={handleContinue}
          handleBack={handleBack}
          showPause={showPause && experiment?.status === EXPERIMENT_STATUSES.InProgress.value}
          showResume={experiment?.status === EXPERIMENT_STATUSES.Paused.value}
          showFinalize={experiment?.status === EXPERIMENT_STATUSES.PendingFinalization.value}
          pauseExperiment={setOpenPauseConf}
          resumeExperiment={setOpenResumeConf}
          finalizeExperiment={finalizeExperimentAction}
          completeExperiment={showFinalizationPopup}
          disableCompleteExperiment={Object.values(finalizationOptions).some(option => !option.checked)}
          onNavigateToLastStep={() => setActiveState(5)}
          remoteConnectivity={remoteConnectivity}
        />
        {showNotesSidebar && (
          <NotesSidebar
            notes={notesList}
            updateNotesList={updateNotesList}
            experimentId={experimentID}
            open={showNotesSidebar}
            onClose={() => setShowNotesSidebar(false)}
          />
        )}

        <DeclineExperimentPopup
          open={declinePopupOpen}
          handleNavigation={handleRejectNavigation}
          experiment={experimentID}
          closePopup={() => setDeclinePopupOpen(false)}
        />
        <Popup
          title="Attention"
          open={confirmation}
          textSubmit="Proceed"
          handleCancel={closeConfirmation}
          handleSubmit={handleContinue}
          loading={loading}
        >
          <span>Changes made for current wizard step will reset to default following wizard steps, proceed?</span>
        </Popup>
        <Popup
          title="Please, confirm action"
          open={openEndConf}
          textSubmit="End Experiment"
          handleCancel={() => setOpenEndConf(false)}
          handleSubmit={endExperimentClick}
          loading={loading}
        >
          <span>Do you really want to End the experiment execution?</span>
        </Popup>
        <Popup
          title="Please, confirm action"
          open={openResumeConf}
          textSubmit="Resume"
          handleCancel={() => setOpenResumeConf(false)}
          handleSubmit={resumeExperiment}
          loading={loading}
        >
          <span>Do you really want to Resume the experiment execution?</span>
        </Popup>
        <Popup
          title="Please, confirm action"
          open={openPauseConf}
          textSubmit="Pause"
          handleCancel={() => setOpenPauseConf(false)}
          handleSubmit={pauseExperiment}
          loading={loading}
        >
          <span>Do you really want to Pause the experiment execution?</span>
        </Popup>
        <ExperimentFinalizationPopup
          open={finalizationPopupOpen}
          close={() => setFinalizationPopupOpen(false)}
          experimentKey={experimentKey}
          completeExperiment={completeExperimentAction}
        />
      </>
    </Spinner>
  );
};
