import React from 'react';
import { Checkbox, Table } from '../../Common';
import '../ReactionsAccordion/style.scss';
import './style.scss';

export const ReactionsTable = ({ reactions, showCheckboxes, columns, setReactions }) => {
  const onRowSelect = (reactionIndex, checked) => {
    const newReactions = reactions.map((reaction, index) =>
      index === reactionIndex ? { ...reaction, include: checked } : reaction
    );
    setReactions(newReactions);
  };
  const getDisableCheckbox = index => {
    return reactions.filter(reaction => reaction.include).length === 1 && reactions[index].include;
  };
  const columnList = showCheckboxes
    ? [
        {
          title: 'Include',
          dataIndex: 'include',
          key: 'include',
          width: 60,
          render: (include, record, index) => {
            return (
              <Checkbox
                checked={include}
                disabled={getDisableCheckbox(index)}
                onChange={checked => onRowSelect(index, checked)}
              />
            );
          },
        },
        ...columns,
      ]
    : [...columns];
  return (
    <div className="reaction-table">
      {!reactions.length ? (
        <div className="reaction-table_no_data">No reactions yet</div>
      ) : (
        <Table columns={columnList} dataSource={reactions} sidePadding={false} x={'50%'} />
      )}
    </div>
  );
};
