import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import UnivariateComponent from './UnivariateComponent';

export const UnivariateTab = React.memo(
  props => {
    return <UnivariateComponent {...props} />;
  },
  (prev, next) => !!prev.experiment === !!next.experiment
);
