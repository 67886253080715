import { useMemo } from 'react';

import { COLUMNS_TITLES } from '../constants';

const useColumns = () => {
  const columns = useMemo(
    () => [
      {
        title: COLUMNS_TITLES.name.label,
        dataIndex: COLUMNS_TITLES.name.value,
        sortValue: COLUMNS_TITLES.name.sortValue,
      },
      {
        title: COLUMNS_TITLES.dataType.label,
        dataIndex: COLUMNS_TITLES.dataType.value,
        sortValue: COLUMNS_TITLES.dataType.sortValue,
      },
      {
        title: COLUMNS_TITLES.systemic.label,
        dataIndex: COLUMNS_TITLES.systemic.value,
        sortValue: COLUMNS_TITLES.systemic.sortValue,
      },
      {
        title: COLUMNS_TITLES.editable.label,
        dataIndex: COLUMNS_TITLES.editable.value,
        sortValue: COLUMNS_TITLES.editable.sortValue,
      },
      {
        title: COLUMNS_TITLES.unique.label,
        dataIndex: COLUMNS_TITLES.unique.value,
        sortValue: COLUMNS_TITLES.unique.sortValue,
      },
      {
        title: COLUMNS_TITLES.visibleInDetails.label,
        dataIndex: COLUMNS_TITLES.visibleInDetails.value,
        sortValue: COLUMNS_TITLES.visibleInDetails.sortValue,
      },
      {
        title: COLUMNS_TITLES.defaultValue.label,
        dataIndex: COLUMNS_TITLES.defaultValue.value,
      },
      {
        title: COLUMNS_TITLES.archived.label,
        dataIndex: COLUMNS_TITLES.archived.value,
        sortValue: COLUMNS_TITLES.archived.sortValue,
      },
    ],
    []
  );

  return columns;
};

export default useColumns;
