import './style.scss';

import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { ShowMoreList } from 'components/Common/ShowMoreList/ShowMoreList';

import { filterObj, lengthObj } from '../../utils';
import { Avatar, Checkbox, openNotification, Popup, Select } from '..';

const options = [
  { value: 'AutoSyn', label: 'AutoSyn' },
  { value: 'SynJet', label: 'SynJet' },
  { value: 'SynJet Pro', label: 'SynJet Pro' },
  { value: 'Lab', label: 'Lab', disabled: true },
];
const role = {
  chemistry: false,
  maintenance: false,
  operations: false,
  pm: false,
  sysAdmin: false,
  trained: false,
};

const nameRoles = {
  'Chemistry Designer': 'chemistry',
  'Maintenance Technician': 'maintenance',
  'Operations Manager': 'operations',
  'Project Manager': 'pm',
  'System Administrator': 'sysAdmin',
  'Trained Operator': 'trained',
};

const nameBERoles = {
  chemistry: 'Chemistry Designer',
  maintenance: 'Maintenance Technician',
  operations: 'Operations Manager',
  pm: 'Project Manager',
  sysAdmin: 'System Administrator',
  trained: 'Trained Operator',
};

export const PopupUserDetails = ({
  setPopupDetails,
  setInfoPopup,
  infoPopup,
  popupDetails,
  updateUserRoles,
  setLoadingUsers,
  currentUser,
  page,
}) => {
  const [openSelect, setOpenSelect] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState(role);
  const [hardware, setHardware] = useState(['Lab']);
  const [rolesRequest, setRolesRequest] = useState('');
  const [relatedProjects, setRelatedProjects] = useState('');
  const [yourUser, setYourUser] = useState(false);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  useEffect(() => {
    if (infoPopup.roles) {
      const newRoles = { ...role };

      if (infoPopup.roles.find(i => i === 'System Administrator')) {
        for (const key in newRoles) {
          newRoles[key] = true;
        }
      } else {
        infoPopup.roles.forEach(i => {
          newRoles[nameRoles[i]] = true;
        });
      }
      if (infoPopup.roles.length === 1 && infoPopup.roles[0] === 'Trained Operator') {
        setOpenSelect(true);
      } else {
        setOpenSelect(false);
      }
      setRoles(newRoles);

      const arr = [];
      infoPopup.projects.forEach(item => {
        arr.push(item.name);
      });
      setRelatedProjects(arr);
      setHardware(infoPopup.familiarWith);
      if (currentUser.uuid === infoPopup.uuid) {
        setYourUser(true);
        setOpenSelect(false);
      }
    }
    setRolesRequest(infoPopup.roles);
  }, [infoPopup]);

  const onChange = (v, field) => {
    const newRoles = { ...roles, [field]: v };
    if (newRoles.sysAdmin) {
      for (const key in newRoles) {
        newRoles[key] = true;
      }
    }
    if (newRoles.trained && Object.values(newRoles).filter(value => value).length === 1) {
      setOpenSelect(true);
      if (!hardware.length) {
        setHardware(['Lab']);
      }
    } else {
      setOpenSelect(false);
      setHardware(['Lab']);
    }
    setRoles(newRoles);
  };

  const checkRoles = roles => {
    const rolesWithDevice = [nameBERoles.sysAdmin, nameBERoles.maintenance, nameBERoles.trained];
    return roles.some(item => rolesWithDevice.includes(item));
  };

  const saveChanges = () => {
    const rolesRequest = [];
    Object.keys(filterObj(roles)).forEach(i => rolesRequest.push(nameBERoles[i]));
    setLoading(true);
    const requestBody = checkRoles(rolesRequest)
      ? { uuid: infoPopup.uuid, roles: rolesRequest, familiarWith: hardware }
      : { uuid: infoPopup.uuid, roles: rolesRequest };
    updateUserRoles(requestBody, page)
      .then(data => {
        if (data.errors) {
          setLoadingUsers(false);
          setConfirmationPopupOpen(true);
          return;
        }
        setPopupDetails(false);
        openNotification('Role setup has been successfully updated');
      })
      .finally(() => {
        setLoading(false);
        setLoadingUsers(true);
        setRelatedProjects('');
      });
    setYourUser(false);
  };
  const disabled = lengthObj(filterObj(roles)) === 1;
  const disabledIfIsAdmin = roles.sysAdmin;

  return (
    <>
      <Popup
        open={popupDetails}
        handleCancel={() => {
          setInfoPopup({});
          setPopupDetails(false);
          setRoles(role);
          setOpenSelect(false);
          setRelatedProjects('');
          setYourUser(false);
          setLoading(false);
        }}
        handleSubmit={saveChanges}
        loading={loading}
      >
        <div className="popup-user-details">
          <div className="main-info">
            <Avatar size={96}>
              {!!infoPopup.firstName && !!infoPopup.lastName ? (
                `${infoPopup.firstName} ${infoPopup.lastName}`.match(/\b(\w)/g).join('')
              ) : (
                <div className="avatar-letters" />
              )}
            </Avatar>
            <h3 className="name">
              {infoPopup.firstName} {infoPopup.lastName}
            </h3>
          </div>
          <div className="roles">
            <p className="title-details">Roles</p>
            <div className="columns">
              <div className="popup-first-column">
                <Checkbox
                  field="chemistry"
                  disabled={(disabled && roles.chemistry) || disabledIfIsAdmin || loading}
                  onChange={onChange}
                  value={roles.chemistry}
                >
                  Chemistry Designer
                </Checkbox>
                <Checkbox
                  field="maintenance"
                  onChange={onChange}
                  disabled={(disabled && roles.maintenance) || disabledIfIsAdmin || loading}
                  value={roles.maintenance}
                >
                  Maintenance Technician
                </Checkbox>
                <Checkbox
                  field="trained"
                  onChange={onChange}
                  disabled={(disabled && roles.trained) || disabledIfIsAdmin || loading}
                  value={roles.trained}
                >
                  Trained Operator
                </Checkbox>
              </div>
              <div className="popup-second-column">
                <Checkbox
                  field="operations"
                  onChange={onChange}
                  disabled={(disabled && roles.operations) || disabledIfIsAdmin || loading}
                  value={roles.operations}
                >
                  Operations Manager
                </Checkbox>
                <Checkbox
                  field="pm"
                  onChange={onChange}
                  disabled={(disabled && roles.pm) || disabledIfIsAdmin || loading}
                  value={roles.pm}
                >
                  Project Manager
                </Checkbox>
                <Checkbox field="sysAdmin" onChange={onChange} value={roles.sysAdmin} disabled={loading || yourUser}>
                  System Administrator
                </Checkbox>
              </div>
            </div>
          </div>
          <div className={cn('hide-select', { 'show-select': openSelect })}>
            <Select
              open={openDropdown}
              mode="multiple"
              showSearch={false}
              options={options}
              value={hardware}
              style={{ width: '235px', marginBottom: '48px' }}
              maxTagCount={0}
              onChange={e => {
                setHardware(e);
              }}
              onClick={() => setOpenDropdown(true)}
              onBlur={() => setOpenDropdown(false)}
              onInputKeyDown={e => {
                if (e.keyCode === 27) {
                  setOpenDropdown(false);
                }
              }}
              suffixIcon={
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4 4L7 1" stroke="#2C4652" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              }
              tagRender={props => (
                <p className="multiple select select-row">
                  {hardware.map((item, index) => (
                    <p className="select-item">
                      {item}
                      {index + 1 !== hardware.length && hardware.length > 1 && ','}
                      &nbsp;
                    </p>
                  ))}
                </p>
              )}
              disabled={loading}
            />
          </div>
          <div className="project">
            <p className="title-details">Projects</p>
            {relatedProjects.length ? (
              <ShowMoreList
                options={relatedProjects}
                limit={5}
                more={relatedProjects.length > 5 && relatedProjects.length - 5}
              />
            ) : (
              <div className="no-projects">No projects</div>
            )}
          </div>
        </div>
      </Popup>
      <Popup
        title="Warning"
        open={confirmationPopupOpen}
        className="popup-create-process"
        handleCancel={() => setConfirmationPopupOpen(false)}
        textCancle="Ok"
      >
        You cannot remove the 'Project Manager' role for the selected user. {infoPopup.firstName} {infoPopup.lastName}{' '}
        is assigned to a Project(s) as a Project manager. Please unassign the user from the corresponding Project(s) to
        proceed
      </Popup>
    </>
  );
};
