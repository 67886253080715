export const SCROLL_BOX_SIZES = {
  // use to avoid any paddings around scroll box
  none: 'none',
  small: 'small',
  medium: 'medium',
  big: 'big',
  large: 'large',
  extraLarge: 'extra-large',
  doubleExtraLarge: 'double-extra-large',
  huge: 'huge',
};
