import React, { useState, useEffect, useRef } from 'react';
import SmilesDrawer from 'smiles-drawer';
import { Tooltip } from '../../Common';
import { PopupWithStructureView } from '../../PopupWithStructureView/PopupWithStructureView';
import { LoupeButton } from '../../PopupWithStructureView/LoupeButton';
import { usePdfContext } from '../../PdfGenerator';

export const Compound = ({
  data,
  label,
  width = 164,
  height = 80,
  padding = 16,
  changeCompound = () => {},
  nameOnly,
  isMFC = false,
  displayLoupe = false,
}) => {
  const { isPdf } = usePdfContext();
  const canvasContainer = useRef(null);
  const canvas = useRef(null);
  const [openLoupe, setOpenLoupe] = useState(false);

  const options = {
    height,
    padding,
    bondSpacing: 0,
  };

  useEffect(() => {
    if (canvasContainer.current && data) {
      options.width = width || canvasContainer.current.offsetWidth;
      canvas.current = new SmilesDrawer.Drawer(options);
      SmilesDrawer.parse(data.smiles, tree => {
        canvas.current.draw(tree, `smiles-drawer-${label}${isPdf ? '-pdf' : ''}`, 'light', false);
      });
    }
  }, [data, isMFC]);

  const clickOnLoupe = open => {
    setOpenLoupe(open);
  };

  const onClick = event => {
    event.stopPropagation();
    if (openLoupe) return;
    changeCompound();
  };

  return (
    <div className="compound" onClick={onClick}>
      {data.smiles && !nameOnly ? (
        <Tooltip title={data.compound_name}>
          <div ref={canvasContainer} className="compound-canvas-container canvas-container">
            <canvas id={`smiles-drawer-${label}${isPdf ? '-pdf' : ''}`} className="canvas-container__canvas" />
          </div>
        </Tooltip>
      ) : (
        <>{data.compound_name}</>
      )}
      {displayLoupe && <LoupeButton data={data} label={label} clickOnLoupe={clickOnLoupe} />}
    </div>
  );
};
