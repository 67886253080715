import { http } from '../../utils';
import { mutationPrepareMaterials } from '../graphql';
import { getProcess } from '../autosyn/autosyn.actions';

export const prepareMaterialsSynJetPro = data => dispatch =>
  http(mutationPrepareMaterials('send', data)).then(resp => {
    // const processedData = mutationPrepareMaterials('get', resp);
    // if (!processedData.errors) dispatch(getProcess(processedData.uuid));
    // else throw 'error update steps';
  });
