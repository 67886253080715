import React, { useCallback, useEffect, useState } from 'react';
import { Card, Pagination, Spinner } from '../../../components';
import './style.scss';
import { FiltersList } from '../../../components/FiltersList/FiltersList';
import { PopupCreateProject } from '../../../components/PopupCreateProject/PopupCreateProject';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { connect } from 'react-redux';
import { getListProjects, createProject, getFiltersProjects } from '../../../store/projects/projects.actions';
import { cleanerStore } from '../../../store/common/common.actions';
import { decodeUrlWithSeparatedSpecialSymbols, filterObj, lengthObj, urlSearchParams } from '../../../utils';
import { useLocation } from 'react-router-dom';
import history from '../../../utils/history';
import { DisplayPagination } from '../../../utils/DisplayPagination';

const List = props => {
  const [popupCreateProject, setPopupCreateProject] = useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [triggerSearchButton, setTriggerSearhButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [filtersValue, setFiltersValue] = useState({
    type: null,
    status: null,
    programs: [],
  });

  const { search } = useLocation();
  //

  useEffect(() => {
    props.getFiltersProjects().then(i => {
      const urlFilters = getFilters();
      const programs = urlFilters.program_In ? urlFilters.program_In.split(',') : i.programs.map(i => i.value);
      if (programs.length === i.programs.length - 1) programs.push('all');
      setPage(urlFilters.page || 1);
      setSearchValue(urlFilters.search || '');
      setFiltersValue({
        type: urlFilters.projectType || i.types[0].value,
        status: urlFilters.status || i.statuses[0].value,
        programs,
      });
    });

    return () => cleanerStore('projects');
  }, []);

  useEffect(() => {
    const objectForPush = {
      pathname: '/projects',
    };

    if (isActiveFilters()) {
      setInitialLoading(false);
      objectForPush.search = urlSearchParams({
        page,
        search: searchValue?.trim(),
        type: filtersValue.type,
        status: filtersValue.status,
        programs: filtersValue.programs.includes('all') ? [] : encodeURIComponent(filtersValue.programs),
      });
    } else objectForPush.search = search;
    history.replace(objectForPush);
  }, [filtersValue.programs.length, filtersValue.status, filtersValue.type, triggerSearchButton, page]);

  useEffect(() => {
    if (search && isActiveFilters()) {
      getListWithFilters();
    }
  }, [search, initialLoading]);
  //
  const openPopup = useCallback(() => setPopupCreateProject(true), []);
  const closePopup = useCallback(() => setPopupCreateProject(false), []);
  const getDataFromPage = useCallback(page => setPage(page), []);
  const onChangeSearch = value => {
    setSearchValue(value);
    if (value === '') searchAction();
  };
  const searchAction = useCallback(() => {
    setTriggerSearhButton(!triggerSearchButton);
    setPage(1);
  }, [triggerSearchButton]);

  const isActiveFilters = () => {
    return searchValue || filtersValue.type || filtersValue.status || filtersValue.programs.length;
  };
  //
  const getFilters = () => {
    const filterDecoded = decodeUrlWithSeparatedSpecialSymbols(search);
    return filterObj({
      page: filterDecoded.page || 1,
      search: filterDecoded.search,
      projectType: filterDecoded.type === 'all' ? '' : filterDecoded.type,
      status: filterDecoded.status === 'all' ? '' : filterDecoded.status,
      program_In: filterDecoded.programs
        ? filterDecoded.programs
            .split(',')
            .filter(i => i !== 'all')
            .map(item => {
              return item.toString().replace(/\\"/g, '"').replace(/"/g, '\\"');
            })
            .join(',')
        : [],
    });
  };
  const getListWithFilters = updatedFilters => {
    const filters = getFilters();
    setLoading(true);
    props
      .getListProjects(filters)
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeFilters = (field, value) => {
    const newFilters = { ...filtersValue, [field]: value };
    setFiltersValue(newFilters);
    setPage(1);
  };

  const resetFilters = () => {
    setFiltersValue({
      type: props.filters.types[0].value,
      status: props.filters.statuses[0].value,
      programs: props.filters.programs.map(i => i.value),
    });
    setTriggerSearhButton(!triggerSearchButton);
    setPage(1);
    setSearchValue('');
  };

  const createProjectAction = data =>
    props.createProject(data).then(resp => {
      if (!resp?.errors) getListWithFilters();
      return resp;
    });

  const { totalCount } = props.projects;
  const displayed = totalCount > 12 * page ? 12 * page : totalCount;
  const isFilters =
    searchValue ||
    (filtersValue.status && filtersValue.status !== 'all') ||
    (filtersValue.type && filtersValue.type !== 'all') ||
    (filtersValue.programs.length && filtersValue.programs.length !== props.filters.programs.length);
  const canRenderPagination = isActiveFilters();

  const permissions = props.user?.permissions?.project || {};

  return (
    <div className="list-projects">
      <div className="list-projects_header">
        <FiltersList
          permissions={permissions}
          createProject={openPopup}
          valueSearch={searchValue}
          filtersOptions={props.filters}
          filtersValue={filtersValue}
          searchAction={searchAction}
          onChangeSearch={onChangeSearch}
          onChangeDropDowns={handleChangeFilters}
          resetFilters={resetFilters}
        />
      </div>

      <Spinner loading={loading}>
        <div className="list-projects_projects">
          {props.projects.results?.length ? (
            props.projects.results.map(i => <Card {...i} i={i} permissions={permissions} />)
          ) : initialLoading || !lengthObj(props.projects) ? (
            <></>
          ) : (
            <EmptyState
              emptyState={!isFilters}
              action={openPopup}
              essence="project"
              filteringText="Please try using a different search / filter combination"
              canCreate={permissions?.create_project}
              mainText="No projects yet"
              message="Projects will be available here as soon as you are assigned"
            />
          )}
        </div>
        {!!totalCount && (
          <div className="list-projects_footer">
            <div className="list-projects_footer_count-pages">
              <DisplayPagination totalCount={totalCount} current={page} count={12} />
            </div>
            {!!canRenderPagination && totalCount > 12 && (
              <Pagination allPages={totalCount} onChange={getDataFromPage} current={page} />
            )}
          </div>
        )}
      </Spinner>
      <PopupCreateProject
        createProject={createProjectAction}
        openPopup={openPopup}
        closePopup={closePopup}
        open={popupCreateProject}
      />
    </div>
  );
};

const mapStateToProps = store => ({
  user: store.commonReducer.user,
  projects: store.projectReducer.projects,
  filters: store.projectReducer.filters,
});
export default connect(mapStateToProps, {
  getListProjects,
  createProject,
  getFiltersProjects,
  cleanerStore,
})(List);
