import { http } from '../../utils/http';
import * as actionsTypes from './synjet.actionTypes';
import { SYNJET_RESET_STEPS, SYNJET_SET_STEPS } from './synjet.actionTypes';
import {
  mutationSaveSynjetProcess,
  mutationUpdateProcess,
  mutationUpdateTheLockState,
  queryProcessDetails,
  queryAvailableDispenserMaterials,
  queryAvailableBackPacks,
  queryAvailableFluidBodies,
  mutationUpdateDispenserMaterials,
  queryConstructor,
  batchExperiments,
  mutationUpdateInputMaterials,
  queryAvailableReservoirVolumes,
  mutationUpdateSjpSolidVials,
  mutationSJProLaunchBatch,
} from '../graphql';
import { PROCESS_BUILDER_ERROR, SYNJET_PROCESS_TYPES } from '../../constants';
import {
  calculateTotalVolume,
  getFixedVials,
  getLibGenProducts,
  getLibGenVials,
  getLimitingCompoundFromProcessSteps,
  getOptimizationVials,
  getScreeningVials,
  getSynjetProcessData,
  getTwoStepScreeningVials,
  getVialProducts,
  parseProcessDataFromBackend,
  validateProcess,
} from '../../utils/synjetHelpers';
import { parseJSONErrors } from '../../utils';
import { openNotification } from '../../components';
import { getNotes } from '../experiment/experiment.actions';

export const setProcess = payload => ({ type: actionsTypes.SYNJET_SET_PROCESS, payload });
export const setValidationErrors = data => ({
  type: actionsTypes.SET_VALIDATION_ERRORS_SYNJET,
  data,
});

export const updateCompoundList = (updatedList, type, step) => ({
  type: actionsTypes.SYNJET_UPDATE_COMPOUND_LIST,
  compoundList: updatedList,
  compoundType: type,
  step,
});

export const updateCompoundGroup = (compoundGroup, type, step) => ({
  type: actionsTypes.SYNJET_UPDATE_COMPOUND_GROUP,
  compoundGroup,
  compoundType: type,
  step,
});

export const updateCompoundGroups = (reactantGroups, reagentGroups, step) => ({
  type: actionsTypes.SYNJET_UPDATE_COMPOUND_GROUPS,
  reactantGroups,
  reagentGroups,
  step,
});

export const setQuenchingList = quenching => ({
  type: actionsTypes.SYNJET_PRO_UPDATE_QUENCHING_LIST,
  quenching,
});

export const setForceProductConditionGeneration = forceProductConditionGeneration => ({
  type: actionsTypes.SYNJET_PRO_SET_FORCE_CONDITION_VARIABLE_GENERATION,
  forceProductConditionGeneration,
});

export const setSynjetProData = (quenching, products, expectedIntermediates) => ({
  type: actionsTypes.SYNJET_PRO_DATA_UPDATE,
  quenching,
  products,
  expectedIntermediates,
});

export const addQuenchingItem = limitingCompoundGroup => ({
  type: actionsTypes.SYNJET_PRO_ADD_QUENCHING_ITEM,
  limitingCompoundGroup,
});

export const updateCompound = (reactants, reagents, step) => ({
  type: actionsTypes.SYNJET_UPDATE_COMPOUND,
  reagents,
  reactants,
  step,
});

export const updateProduct = (product, step) => ({
  type: actionsTypes.SYNJET_UPDATE_PRODUCT,
  product,
  step,
});

export const setTwoStepProcess = variableStep => ({
  type: actionsTypes.SYNJET_SET_TWO_STEP_PROCESS,
  variableStep,
});

export const addCompoundToList = (type, step) => ({
  type: actionsTypes.SYNJET_ADD_COMPOUND_TO_LIST,
  compoundType: type,
  step,
});

export const addCompoundGroupToList = (type, step) => ({
  type: actionsTypes.SYNJET_ADD_COMPOUND_GROUP_TO_LIST,
  compoundType: type,
  step,
});

export const addCompoundToGroup = (compoundGroup, type, step, limitingCompoundGroup) => ({
  type: actionsTypes.SYNJET_ADD_COMPOUND_TO_GROUP,
  compoundType: type,
  compoundGroup,
  step,
  limitingCompoundGroup,
});

export const deleteCompoundGroup = (compoundGroup, type, step) => ({
  type: actionsTypes.SYNJET_DELETE_COMPOUND_GROUP,
  compoundType: type,
  compoundGroup,
  step,
});

export const handleCompoundAddNullControl = (compoundGroup, type, step) => ({
  type: actionsTypes.SYNJET_ADD_NULL_COMPOUND_TO_GROUP,
  compoundType: type,
  compoundGroup,
  step,
});

export const updateConditions = (type, data, conditionIndex = 0, step) => ({
  type: actionsTypes.SYNJET_UPDATE_CONDITIONS,
  data,
  conditionType: type,
  conditionIndex,
  step,
});

export const addNewCondition = step => ({
  type: actionsTypes.SYNJET_ADD_CONDITION,
  step,
});

export const deleteCondition = (index, step) => ({
  type: actionsTypes.SYNJET_DELETE_CONDITION,
  conditionIndex: index,
  step,
});

export const resetSynJetStore = () => ({
  type: actionsTypes.SYNJET_RESET_STORE,
});

export const updateProcess = data => dispatch =>
  http(mutationUpdateProcess('send', data)).then(resp => {
    const processedData = mutationUpdateProcess('get', resp);
    if (!processedData.errors) return dispatch(getProcess(processedData.uuid));
    throw 'error update steps';
  });

export const updateRules = rule => ({
  type: actionsTypes.SYNJET_SET_RULE,
  rules: rule,
});

const setExperimentTableData = data => ({
  type: actionsTypes.SYNJET_GENERATE_EXPERIMENTS_DATA,
  data,
});

export const setProductsData = data => ({
  type: actionsTypes.SYNJET_PRO_GENERATE_PRODUCTS_DATA,
  data,
});

export const setExpectedIntermediatesData = data => ({
  type: actionsTypes.SYNJET_PRO_GENERATE_EI_DATA,
  data,
});

export const generateExperimentsData = () => (dispatch, getState) => {
  const processType = getState().synjetReducer.process?.process?.type;
  const { rules, steps } = getState().synjetReducer;
  const { solvent, normalize } = steps[steps.length - 1].conditions[0].dispensing;
  const dispensingOption = normalize ? solvent?.compound_id : null;

  if (!validateProcess(rules, steps, processType, false)) {
    dispatch(setExperimentTableData({ process_steps: [] }));
    return;
  }

  const variableStep = steps.find(step => !step.isFixed);

  if (!variableStep) {
    return;
  }

  const calculatedVariableStep = {
    step: {
      product: variableStep.product?.smiles?.compound_id,
      isFixed: false,
      vials:
        processType === SYNJET_PROCESS_TYPES.OPTIMIZATION
          ? getOptimizationVials(variableStep, rules, dispensingOption, steps.length === 2)
          : steps.length === 2
          ? getTwoStepScreeningVials(variableStep, rules, dispensingOption)
          : getScreeningVials(variableStep, rules, dispensingOption),
    },
  };

  const processSteps = steps.map((step, stepIndex) => {
    if (step.isFixed) {
      return {
        step: {
          product: step.product?.smiles?.compound_id,
          isFixed: true,
          vials: getFixedVials(
            step,
            rules,
            dispensingOption,
            calculatedVariableStep.step.vials.length,
            stepIndex === 0
          ),
        },
      };
    }
    return calculatedVariableStep;
  });

  const calculatedProcessSteps = calculateTotalVolume(processSteps, steps);

  dispatch(setExperimentTableData({ process_steps: calculatedProcessSteps }));
};

export const generateExperimentsDataFromProducts = () => (dispatch, getState) => {
  const processType = getState().synjetReducer.process?.process?.type;
  const { rules, steps, products, expectedIntermediates } = getState().synjetReducer;
  const { solvent, normalize } = steps[steps.length - 1].conditions[0].dispensing;
  const dispensingOption = normalize ? solvent?.compound_id : null;

  if (!validateProcess(rules, steps, processType, false, products)) {
    dispatch(setExperimentTableData({ process_steps: [] }));
    return;
  }

  const variableStep = steps.find(step => !step.isFixed);
  const variableStepIndex = steps.findIndex(step => !step.isFixed);

  if (!variableStep) {
    return;
  }

  const fixedStep = steps.find(step => step.isFixed);
  const fixedStepIndex = steps.findIndex(step => step.isFixed);
  const isFirstFixed = steps?.[0]?.isFixed;

  const filterFirstStepProducts = fixedStep && !isFirstFixed;

  const setExpectedIntermediateAsProduct = steps?.length > 1 && !variableStepIndex;
  const calculatedVariableStep = {
    step: {
      product: variableStep.product?.smiles?.compound_id,
      isFixed: false,
      vials: getLibGenVials(
        products,
        rules,
        dispensingOption,
        null,
        filterFirstStepProducts,
        setExpectedIntermediateAsProduct,
        fixedStepIndex
      ),
    },
  };

  const vialProducts = getVialProducts(calculatedVariableStep, products);

  const processSteps = steps.map((step, stepIndex) => {
    if (step.isFixed) {
      return {
        step: {
          product: step.product?.smiles?.compound_id,
          isFixed: true,
          vials: getFixedVials(
            step,
            rules,
            dispensingOption,
            calculatedVariableStep.step.vials.length,
            stepIndex === 0,
            null,
            true,
            vialProducts
          ),
        },
      };
    }
    return calculatedVariableStep;
  });

  const calculatedProcessSteps = calculateTotalVolume(processSteps, steps);

  dispatch(setExperimentTableData({ process_steps: calculatedProcessSteps }));
};

export const generateProducts = () => (dispatch, getState) => {
  const {
    steps,
    process,
    products,
    quenching,
    forceProductConditionGeneration,
    expectedIntermediates,
  } = getState().synjetReducer;

  const isFirstFixed = steps?.[0]?.isFixed;

  const hasExpectedIntermediates = steps.length > 1 && !isFirstFixed;

  const quenchingData = process?.process?.enableQuenching && quenching;
  const expectedIntermediatesData = hasExpectedIntermediates ? expectedIntermediates : null;

  const isValid = validateProcess(
    [],
    steps,
    process?.process?.type,
    false,
    null,
    quenchingData,
    expectedIntermediatesData
  );
  if (!isValid) {
    dispatch(setProductsData([]));
    return;
  }

  const variableStep = steps.find(step => !step.isFixed);
  const fixedStep = steps.find(step => step.isFixed);

  if (!variableStep) {
    return;
  }

  const limitingCompound = getLimitingCompoundFromProcessSteps(steps);

  let newProducts = [];

  if (hasExpectedIntermediates) {
    newProducts = getLibGenProducts(
      variableStep,
      products,
      quenchingData,
      forceProductConditionGeneration,
      fixedStep,
      false,
      expectedIntermediates,
      false,
      false,
      limitingCompound
    );
  } else {
    newProducts = getLibGenProducts(
      variableStep,
      products,
      quenchingData,
      forceProductConditionGeneration,
      fixedStep,
      isFirstFixed,
      null,
      false,
      false,
      limitingCompound
    );
  }

  dispatch(setProductsData(newProducts));
};

export const generateExpectedIntermediate = () => (dispatch, getState) => {
  const { steps, process, expectedIntermediates, forceProductConditionVariableGeneration } = getState().synjetReducer;

  const isFirstFixed = steps?.[0]?.isFixed;

  const hasExpectedIntermediates = steps.length > 1 && !isFirstFixed;

  const variableStep = steps.find(step => !step.isFixed);
  const fixedStep = steps.find(step => step.isFixed);

  const isValid = validateProcess([], steps, process?.process?.type, false, null);

  if (!isValid) {
    dispatch(setExpectedIntermediatesData({ expectedIntermediates: [] }));
    return;
  }

  if (!variableStep && !fixedStep) {
    return;
  }

  const limitingCompound = getLimitingCompoundFromProcessSteps(steps);

  const newEI = hasExpectedIntermediates
    ? getLibGenProducts(
        variableStep,
        expectedIntermediates,
        null,
        forceProductConditionVariableGeneration,
        fixedStep,
        false,
        null,
        true,
        false,
        limitingCompound
      )
    : [];

  dispatch(setExpectedIntermediatesData({ expectedIntermediates: newEI }));
};

export const saveSynjetProcess = (validate = false) => (dispatch, getState) => {
  dispatch(setValidationErrors([]));
  const synjetData = getState().synjetReducer;
  const dataToSave = getSynjetProcessData(synjetData);
  const processId = synjetData.process.process.uuid;
  return http(mutationSaveSynjetProcess(processId, dataToSave, validate)).then(resp => {
    if (resp.updateProcessDefinition.errors && validate) {
      const err = parseJSONErrors(resp.updateProcessDefinition.errors);
      dispatch(setValidationErrors(err));
    }
    return resp;
  });
};

export const lockSynjetProcess = (uuid, state = 'locked') => () => http(mutationUpdateTheLockState({ uuid, state }));

export const setSteps = data => ({
  type: SYNJET_SET_STEPS,
  data,
});

export const resetSteps = () => ({
  type: SYNJET_RESET_STEPS,
});

export const parseProcessDefinition = (definition, variableStep, noStoreUpdate) => dispatch => {
  if (!definition) {
    // dispatch(updateRules([SYNJET_INITIAL_RULE]));
    // dispatch(setExperimentTableData({ process_steps: [] }));
    // dispatch(setSteps([SYNJET_INITIAL_STEP]));
    return;
  }
  const { rules, processSteps, steps, products, quenching, expectedIntermediates } = parseProcessDataFromBackend(
    JSON.parse(definition),
    variableStep
  );
  if (noStoreUpdate) {
    return Promise.resolve({
      rules,
      processSteps,
      steps,
      products,
      quenching,
      expectedIntermediates,
    });
  }

  dispatch(updateRules(rules));
  dispatch(setExperimentTableData({ process_steps: processSteps }));
  dispatch(setSynjetProData(quenching, products, expectedIntermediates));
  dispatch(setSteps(steps));
};

export const getProcess = (data, noUpdateStore, initialData) => dispatch => {
  const funcThen = async resp => {
    let new_resp = resp;
    if (typeof resp === 'string' && resp.includes(PROCESS_BUILDER_ERROR)) {
      new_resp = { error: true };
    }
    if (noUpdateStore) {
      return queryProcessDetails('get', new_resp, data);
    }
    if (new_resp?.process?.numberOfSteps === '2') {
      await dispatch(setTwoStepProcess(new_resp?.process?.variableStep - 1));
    }
    dispatch(setProcess(queryProcessDetails('get', new_resp, data)));
    !new_resp.error && dispatch(parseProcessDefinition(new_resp.process.definition, new_resp.process.variableStep));
    return new_resp;
  };
  if (initialData) {
    return Promise.resolve({ process: initialData }).then(funcThen);
  }
  return http(queryProcessDetails('send', null, data)).then(funcThen);
};

export const getAvailableMaterials = data => dispatch =>
  http(queryAvailableDispenserMaterials(data))
    .then(resp => resp?.availableDispenserMaterials)
    .catch(() => []);

export const getAvailableReservoirVolumes = () => dispatch =>
  http(queryAvailableReservoirVolumes())
    .then(resp => resp?.availableReservoirVolumes)
    .catch(() => []);

export const getAvailableBackpack = data => dispatch =>
  http(queryAvailableBackPacks(data))
    .then(resp => resp?.backpacks)
    .catch(() => []);

export const getAvailableFluidBody = data => dispatch =>
  http(queryAvailableFluidBodies(data))
    .then(resp => resp?.fluidBodies)
    .catch(() => []);

export const updateDispenserMaterial = ({ data }) =>
  http(mutationUpdateDispenserMaterials('sent', data)).then(resp => mutationUpdateDispenserMaterials('get', resp));

export const updateSjpSolidVials = ({ data }) =>
  http(mutationUpdateSjpSolidVials('sent', data)).then(resp => mutationUpdateSjpSolidVials('get', resp));

export const updateInputMaterial = ({ data }) =>
  http(mutationUpdateInputMaterials('sent', data)).then(resp => mutationUpdateInputMaterials('get', resp));

export const setBatchErrorShown = shown => ({
  type: actionsTypes.SET_BATCH_ERROR_SHOWN,
  shown,
});

export const getBatchExperiments = uuid => dispatch =>
  http(queryConstructor(batchExperiments(uuid))).then(resp => resp.batch?.experiments || []);

export const handleBatchAbortedStatus = selectedExperiment => (dispatch, getStore) => {
  const messageShown = getStore().synjetReducer.batchAbortedErrorShown;
  if (!messageShown) {
    openNotification(null, 'Experiment execution was terminated due to a Runtime error');
    dispatch(
      getNotes({
        experimentId: selectedExperiment,
      })
    );
    dispatch(setBatchErrorShown(true));
  }
};

export const sjpLaunchBatch = ({ data }) =>
  http(mutationSJProLaunchBatch('sent', data)).then(resp => mutationSJProLaunchBatch('get', resp));
