import React, { useCallback, useEffect, useState } from 'react';

import { openNotification, Popup, Spinner, Table, Tabs } from 'components';
import { CreatorProcessFromRoute } from 'components/AutoSin/CreatorProcessFromRoute';
import { FiltersListProcess } from 'components/AutoSin/FiltersListProcess/FiltersListProcess';
import { RenamePopup } from 'components/AutoSin/ProcessPopup/RenamePopup';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { PopupCreateProcess } from 'components/PopupCreateProcess/PopupCreateProcess';
import { PopupCreateProject } from 'components/PopupCreateProject/PopupCreateProject';
import { PopupProcessPreview } from 'components/PopupProcessPreview/PopupProcessPreview';
import { SynjetViewProcessPopup } from 'components/SynJet/SynJetProccess/SynjetViewProcessPopup/SynjetViewProcessPopup';
import { dateYYYYMMDD, PROCESS_TYPES, INIT_PROCESSES_SORTING } from 'constants/common';
import { OPTIONS_TABS } from 'modules/_screens/Processes/constants';
import { useColumns } from 'modules/_screens/Processes/hooks/useColumns';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getFiltersCreators,
  getFiltersProcesses,
  getListProcesses,
  getProcess,
  getTypesProcesses,
  updateProcessName,
} from 'store/autosyn/autosyn.actions';
import { cleanerStore, getDetailsUser } from 'store/common/common.actions';
import { createProcessFromRoute, updateTheLockState } from 'store/processBuilder/processbuilder.actions';
import { getAllProjects, getListProjects } from 'store/projects/projects.actions';
import {
  addLabelsToOptions,
  camelToSnakeCase,
  decodeUrl,
  filterObj,
  lengthObj,
  snakeToCamelCase,
  toCapitalizeChart1,
  urlSearchParams,
} from 'utils';
import { checkIfMyLock } from 'utils/checkIfMyLock';
import { dateFromBEFormat } from 'utils/date';
import history from 'utils/history';

const Component = props => {
  const [popupCreateProject, setPopupCreateProject] = useState(false);
  const [renamePopup, setRenamePopup] = useState(false);
  const [processName, setProcessName] = useState('');
  const [createProcessPopupOPened, setPopupCreateProcess] = useState(false);
  const [popupProcessPreviewOpen, setPopupProcessPreviewOpen] = useState(false);
  const [idRow, setIdRow] = useState('');
  const [cloneProcess, setCloneProcess] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [triggerSearchButton, setTriggerSearhButton] = useState(false);
  const [page, setPage] = useState(1);
  const [dates, setDates] = useState([null, null]);
  const [columnsTable, setColumnsTable] = useState([]);
  const [errorUnique, setErrorUnique] = useState({ error: false, message: '' });
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState();
  const [openSaveFromRoute, setOpenSaveFromRoute] = useState(false);
  const [openPopupUnlockProcess, setPopupUnlockProcess] = useState(false);
  const [loadingUnlockProcess, setLoadingUnlockProcess] = useState(false);
  const [lockUuid, setLockUuid] = useState('');
  const [activeTab, setActiveTab] = useState(PROCESS_TYPES.AutoSyn.key);
  const [searchType, setSearchType] = useState('name');
  const [filtersValue, setFiltersValue] = useState({
    projects: [],
    statuses: [],
    createdBy: [],
  });
  const [previewRouteProcessData, setPreviewRouteProcessData] = useState(null);
  const [previewProcessData, setPreviewProcessData] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [foundProjects, setFoundProjects] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    if (props.processes.results?.length) {
      setTableData(props.processes.results);
      setTotalCount(props.processes.totalCount);
    }
  }, [props.processes.results]);

  useEffect(() => {
    props.getDetailsUser().then(response => {
      props.getFiltersProcesses(activeTab).then(i => {
        const urlFilters = getFilters();
        let statuses = [];
        if (!!response.roles.find(el => el === 'Trained Operator') && response.roles.length === 1) {
          statuses = urlFilters.status_In
            ? urlFilters.status_In.split(',')
            : i.statuses.map(i => i.value === 'In Queue' || i.value === 'Completed');
        } else statuses = i.statuses.map(i => i.value);

        if (statuses.length === i.statuses.length - 1) statuses.push('all');
        setSearchType('name');
        setPage(1);
        setSearchValue('');
        setDates([null, null]);
        setSortedInfo(INIT_PROCESSES_SORTING);
        setFiltersValue({
          projects: [],
          statuses: statuses.filter(i => i),
          createdBy: [],
        });
      });
    });
    props.getAllProjects();

    return () => {
      setSortedInfo([]);
      setColumnsTable([]);
      cleanerStore('processes');
    };
  }, []);

  useEffect(() => {
    const urlFilters = getFilters();
    let sorting = {};
    if (urlFilters.orderBy) {
      sorting = compareInitialSorting(urlFilters);
    }
    setSortedInfo(sorting);
  }, [search]);

  useEffect(() => {
    const objectForPush = {
      pathname: '/processes',
    };
    if (isActiveFilters()) {
      if (initialLoading) {
        setColumnsTable(columns);
      }
      setInitialLoading(false);
      objectForPush.search = urlSearchParams({
        page,
        searchType,
        search: searchValue,
        createdTo: dates[1] && dates[1].format(dateYYYYMMDD),
        createdFrom: dates[0] && dates[0].format(dateYYYYMMDD),
        projects: filtersValue.projects.includes('all') ? [] : filtersValue.projects,
        statuses: filtersValue.statuses.includes('all') ? [] : filtersValue.statuses,
        createdBy: filtersValue.createdBy.includes('all') ? [] : filtersValue.createdBy,
        orderBy: sortedInfo?.order
          ? sortedInfo.order === 'descend'
            ? `-${camelToSnakeCase(
                sortedInfo.columnKey === 'process' && !!sortedInfo.columnFullKey
                  ? sortedInfo.columnFullKey
                  : sortedInfo.columnKey
              )}`
            : camelToSnakeCase(
                sortedInfo.columnKey === 'process' && !!sortedInfo.columnFullKey
                  ? sortedInfo.columnFullKey
                  : sortedInfo.columnKey
              )
          : '-updated_at',
        tab: 'processes',
      });
    } else objectForPush.search = search;
    history.replace(objectForPush);
  }, [
    filtersValue.projects.length,
    filtersValue.statuses.length,
    filtersValue.createdBy.length,
    triggerSearchButton,
    page,
    dates[0],
    dates[1],
    sortedInfo,
    activeTab,
  ]);

  useEffect(() => {
    if (!initialLoading) {
      getListWithFilters();
    }
  }, [activeTab]);

  useEffect(() => {
    if (search && !initialLoading) {
      getListWithFilters();
    }
  }, [search, initialLoading]);

  const compareInitialSorting = urlFilters => {
    const sorting = {};
    sorting.order = urlFilters.orderBy[0] === '-' ? 'descend' : 'ascend';
    sorting.columnKey =
      urlFilters.orderBy[0] === '-'
        ? snakeToCamelCase(urlFilters.orderBy.substring(1))
        : snakeToCamelCase(urlFilters.orderBy);
    return sorting;
  };

  const getListWithFilters = () => {
    const filters = getFilters();
    setLoading(true);
    if (filters.orderBy) {
      filters.orderBy = filters.orderBy.replace(/project/, 'project__name');
    }
    filters.device = PROCESS_TYPES[activeTab]?.device;
    const tab = toCapitalizeChart1(filters.tab);
    if (filters.tab) delete filters.tab;
    props
      .getListProcesses(filters, tab)
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
    setColumnsTable(columns);
  };

  const closePopupSaveFromRoute = useCallback(() => {
    setPreviewRouteProcessData(null);
    setOpenSaveFromRoute(false);
  }, []);
  const openPopupSaveFromRoute = useCallback(data => {
    setPreviewRouteProcessData(data);
    setOpenSaveFromRoute(true);
  }, []);

  const searchAction = useCallback(() => {
    setTriggerSearhButton(!triggerSearchButton);
    setPage(1);
  }, [triggerSearchButton]);

  const onChangeSearch = value => {
    setSearchValue(value);
    if (value === '') searchAction();
  };

  const closeRenamePopup = useCallback(() => setRenamePopup(false), []);

  const openPopup = useCallback(() => setPopupCreateProject(true), []);
  const closePopup = useCallback(() => setPopupCreateProject(false), []);
  const getDataFromPage = useCallback(page => {
    setPage(page);
  }, []);
  const changeStateTable = (pagination, filters, sorter) => {
    setSortedInfo({
      order: sorter.order ?? false,
      columnKey: sorter.field,
      columnFullKey: sorter.columnKey,
    });
  };
  const resetFilters = () => {
    setFiltersValue({
      projects: [],
      statuses: props.filters?.statuses.map(i => i.value),
      createdBy: [],
    });
    setSearchType('name');
    setTriggerSearhButton(!triggerSearchButton);
    setPage(1);
    setSearchValue('');
    setDates([null, null]);
    setSelectedOptions([]);
  };
  const hadnlerDates = useCallback(values => {
    setDates(values || [null, null]);
    setPage(1);
  }, []);

  const onChangeSearchType = useCallback(value => {
    setSearchValue('');
    setSearchType(value);
  }, []);

  const openRenamePopup = row => {
    setProcessName(row.name);
    setIdRow(row.uuid);
    setRenamePopup(true);
  };

  const openClonePopup = row => {
    setCloneProcess({
      name: `Clone ${row.name}`,
      projectName: row.project?.name,
      project: row.project?.uuid,
      uuid: row.uuid,
    });
    setPopupCreateProcess(true);
  };

  const openUnlockPopup = row => {
    setLockUuid(row.processLock.uuid);
    setPopupUnlockProcess(true);
  };

  const openProcessPreview = data => {
    setPreviewProcessData({
      name: data.name,
      uuid: data.uuid,
      project: data.project.name,
      type: data.type,
    });
    setPopupProcessPreviewOpen(true);
  };
  const isActiveFilters = () =>
    searchValue || filtersValue.statuses.length || filtersValue.projects.length || filtersValue.createdBy.length;

  const getFilters = () => {
    const filterDecoded = decodeUrl(search);
    const filtersFromAll = options =>
      options
        .split(',')
        .filter(i => i !== 'all')
        .join(',');
    const obj = {
      searchType: filterDecoded.searchType || 'name',
      page: filterDecoded.page || 1,
      search: filterDecoded.search?.trim(),
      project_In: filterDecoded.projects ? filtersFromAll(filterDecoded.projects) : [],
      status_In: filterDecoded.statuses ? filtersFromAll(filterDecoded.statuses) : [],
      createdBy_In: filterDecoded.createdBy ? filtersFromAll(filterDecoded.createdBy) : [],
      createdTo: filterDecoded.createdTo || null,
      createdFrom: filterDecoded.createdFrom || null,
      orderBy: filterDecoded.orderBy,
      tab: filterDecoded.tab || activeTab.toLowerCase(),
    };
    return filterObj(obj);
  };

  const actionOptionsProjects = (search, projectsInValue, isBlur) =>
    props.getAllProjects('', search).then(resp => {
      let response;
      if (search === '' && !projectsInValue?.length) response = [];
      else if (search === '' && projectsInValue?.length) {
        let t = [];
        t = resp.results.filter(value => projectsInValue.includes(value.uuid));
        response = addLabelsToOptions({ results: [...t] });
        setSelectedOptions(response);
      } else response = addLabelsToOptions(resp);
      setFoundProjects(response);
      return response;
    });

  const actionOptionsCreators = (search, creatorsInValue) =>
    props.getFiltersCreators('', search).then(resp => {
      let response;
      if (search === '' && !creatorsInValue?.length) {
        response = [];
      } else if (search === '' && creatorsInValue?.length) {
        response = resp.createdBy.filter(value => creatorsInValue.includes(value.value));
        setSelectedOptions(response);
      } else {
        response = resp.createdBy;
      }
      setFoundProjects(response);
      return response;
    });

  const handleChangeFilters = (field, value, stillOpen = false) => {
    const newFilters = { ...filtersValue, [field]: value };
    setFiltersValue(newFilters);
    setPage(1);
  };

  useEffect(() => {
    const filtered = [];

    for (const arr in props.processes.results) {
      for (const filter in props.user) {
        if (props.processes.results[arr].project?.uuid === props.user[filter].projects?.uuid) {
          filtered.push(props.processes.results[arr].project?.uuid);
        }
      }
    }
    setTableData(props.processes.results);
    setTotalCount(props.processes.totalCount);
  }, [props.processes.results]);

  const updateName = (value, id) => {
    setErrorUnique({ error: false, message: '' });
    return props
      .updateProcessName({ uuid: id, name: encodeURIComponent(value) })
      .then(() => {
        setErrorUnique({ error: false, message: '' });
        setRenamePopup(false);
        openNotification('Process has been renamed');
        getListWithFilters();
      })
      .catch(err => {
        setErrorUnique({ error: true, message: err });
      });
  };
  const redirectToDetails = (info, e) => {
    if (!permissions.process?.view_process) return;
    if (
      !(
        e.target.tagName === 'svg' ||
        e.target.tagName === 'LI' ||
        e.target.tagName === 'SPAN' ||
        e.target.tagName === 'path'
      )
    ) {
      window.open(`${PROCESS_TYPES[activeTab].path}/process-builder/details/${info.uuid}`, '_blank');
    }
  };

  const isFilters =
    searchValue ||
    (filtersValue.statuses.length && filtersValue.statuses.length !== props.filters.statuses.length) ||
    (filtersValue.projects.length && filtersValue.projects.length !== props.filters.projects.length) ||
    (filtersValue.createdBy.length && filtersValue.createdBy.length !== props.filters.createdBy.length) ||
    dates[0] ||
    dates[1];

  const permissions = props.user?.permissions || {};

  const openCreateProcessPopup = useCallback(() => setPopupCreateProcess(true), []);
  const closeCreateProcessPopup = useCallback(() => {
    setCloneProcess(null);
    setPopupCreateProcess(false);
  }, []);

  const menu = [
    {
      name: 'From a saved route',
      onClick: () => openPopupSaveFromRoute(),
    },
    {
      name: 'From scratch',
      onClick: () => openCreateProcessPopup(),
    },
  ];

  const onChange = activeKey => {
    setActiveTab(activeKey);
    setSortedInfo(INIT_PROCESSES_SORTING);
    resetFilters(activeKey);
  };

  useEffect(() => {
    setColumnsTable(columns);
  }, [sortedInfo, props.user]);

  const columns = useColumns({
    openRenamePopup,
    openClonePopup,
    openUnlockPopup,
    sortedInfo,
    permissions: props.user,
    openPopupSaveFromRoute,
    openProcessPreview,
    activeTab,
  });

  const optionsFilters = {
    statuses: props.filters.statuses,
    projects: props.filters.projects,
    createdBy: props.filters.createdBy,
  };

  return (
    <div className="autosyn-projects-tabs">
      <Tabs activeKey={activeTab} options={OPTIONS_TABS} onChange={onChange}>
        <div className="list-process">
          <div className="list-process_header">
            <FiltersListProcess
              menu={menu}
              onChangeSearchType={onChangeSearchType}
              searchType={searchType}
              permissions={permissions}
              openPopupSaveFromRoute={openPopupSaveFromRoute}
              valueSearch={searchValue}
              createProcess={openCreateProcessPopup}
              searchAction={searchAction}
              onChangeSearch={onChangeSearch}
              onChangeDropDowns={handleChangeFilters}
              filtersOptions={optionsFilters}
              dates={dates}
              resetFilters={() => resetFilters(activeTab)}
              hadnlerDates={hadnlerDates}
              filtersValue={filtersValue}
              activeTab={activeTab}
              isSynJet={PROCESS_TYPES[activeTab].key === PROCESS_TYPES.SynJet.key}
              isSynJetPro={PROCESS_TYPES[activeTab].key === PROCESS_TYPES.SynJetPro.key}
              isLab={PROCESS_TYPES[activeTab].key === PROCESS_TYPES.Lab.key}
              actionOptionsProjects={actionOptionsProjects}
              foundProject={foundProjects}
              actionOptionsCreators={actionOptionsCreators}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          </div>
          <Spinner loading={loading}>
            <div className="table-wrapper">
              {tableData?.length && props.user.permissions?.process.view_process ? (
                <Table
                  columns={columnsTable}
                  dataSource={tableData}
                  rowClassName={record =>
                    (checkIfMyLock(record.processLock) && 'disabled-row') ||
                    ((permissions?.process?.change_process || permissions?.process?.view_process) && 'no-cursor')
                  }
                  totalCount={totalCount}
                  changeStateTable={changeStateTable}
                  onChange={getDataFromPage}
                  current={page}
                  defaultPageSize={10}
                  onRowClick={(record, e) => {
                    redirectToDetails(record, e);
                  }}
                />
              ) : initialLoading || !lengthObj(props.processes) ? (
                <></>
              ) : (
                <EmptyState
                  action={openPopupSaveFromRoute}
                  canCreate={permissions?.process?.create_process}
                  menu={menu}
                  emptyState={!isFilters}
                  essence="processes"
                  message="Processes will be available here"
                  filteringText="Please try using a different search / filter combination"
                />
              )}
            </div>
          </Spinner>
          <PopupCreateProject openPopup={openPopup} closePopup={closePopup} open={popupCreateProject} />
          {createProcessPopupOPened && (
            <PopupCreateProcess
              openPopup={openCreateProcessPopup}
              closePopup={closeCreateProcessPopup}
              open={createProcessPopupOPened}
              clone={cloneProcess}
              getListProjects={props.getListProjects}
              projects={props.allProjects}
              isSynJet={PROCESS_TYPES[activeTab].key === PROCESS_TYPES.SynJet.key}
              deviceType={PROCESS_TYPES[activeTab].device}
              processType={PROCESS_TYPES[activeTab].key}
            />
          )}
          <RenamePopup
            openPopup={openRenamePopup}
            closePopup={closeRenamePopup}
            open={renamePopup}
            value={processName}
            id={idRow}
            save={updateName}
            error={errorUnique}
            setLoadingProcesses={setLoading}
          />
          {popupProcessPreviewOpen &&
          (PROCESS_TYPES[activeTab].key === PROCESS_TYPES.SynJet.key ||
            PROCESS_TYPES[activeTab].key === PROCESS_TYPES.SynJetPro.key) ? (
            <SynjetViewProcessPopup
              isPro={activeTab === PROCESS_TYPES.SynJetPro.key}
              data={previewProcessData || {}}
              open={popupProcessPreviewOpen}
              handleCancel={() => {
                setPopupProcessPreviewOpen(false);
                setPreviewProcessData(null);
              }}
            />
          ) : (
            <PopupProcessPreview
              data={previewProcessData || {}}
              open={popupProcessPreviewOpen}
              closePopup={() => {
                setPopupProcessPreviewOpen(false);
                setPreviewProcessData(null);
              }}
            />
          )}
          <Popup
            open={openPopupUnlockProcess}
            title="Unlock the process"
            handleSubmit={() => {
              setLoadingUnlockProcess(true);
              updateTheLockState({
                uuid: lockUuid,
                state: 'unlocked',
              }).then(() => {
                setLoadingUnlockProcess(false);
                setLoading(false);
                setPopupUnlockProcess(false);
                getListWithFilters();
                openNotification('Process has been unlocked');
              });
            }}
            handleCancel={() => {
              setPopupUnlockProcess(false);
            }}
            loading={loadingUnlockProcess}
            textSubmit="Unlock"
          >
            <span>All changes made by process editor will be lost. Do you want to proceed and unlock?</span>
          </Popup>
          {openSaveFromRoute && (
            <CreatorProcessFromRoute
              {...props}
              isSynjetPro={PROCESS_TYPES[activeTab].key === PROCESS_TYPES.SynJetPro.key}
              isSynjet={PROCESS_TYPES[activeTab].key === PROCESS_TYPES.SynJet.key}
              previewRouteProcessData={previewRouteProcessData}
              open={openSaveFromRoute}
              handleCancel={closePopupSaveFromRoute}
              deviceType={PROCESS_TYPES[activeTab].device}
            />
          )}
        </div>
      </Tabs>
    </div>
  );
};

const mapStateToProps = store => ({
  processes: store.processReducer.processes,
  filters: store.processReducer.filters,
  filtersExperiments: store.experimentReducer.filtersExperiments,
  user: store.commonReducer.user,
  allProjects: store.projectReducer.allProjects,
  typesProcesses: store.processReducer.typesProcesses,
});

export const Processes = connect(mapStateToProps, {
  getListProcesses,
  getFiltersProcesses,
  updateProcessName,
  getDetailsUser,
  getAllProjects,
  getListProjects,
  getTypesProcesses,
  createProcessFromRoute,
  getProcess,
  getFiltersCreators,
  cleanerStore,
})(Component);
