import { PROJECT_TYPES } from '../constants';

const setEquivsValues = values => values?.map(val => (val === 'N/A' ? null : val));
const parseEquivsValues = values => values?.map(val => (val === null ? 'N/A' : val));

export const parseProcessBuilderData = (serverData, reactors, processType, isWizardTwoStep) => {
  const getCompoundData = name => {
    return serverData.compounds.find(i => i.compound_id === name);
  };

  let data = [];
  serverData.process_steps.forEach((step, idx) => {
    step.step.forEach(_step => {
      let workAreaReactor = {};
      const { reactor, pumps } = _step;
      const reactorData = serverData.reactors.find(i => i.name === reactor);

      let type = '';
      if (reactor) {
        type = reactors?.allReactors?.find(i => reactorData?.key === i.key)?.type?.toLowerCase();
        if (type?.indexOf('tubular') > -1) type = 'tubular';
        else if (type?.indexOf('packed') > -1) type = 'packed';
        else if (type?.indexOf('gas') > -1) type = 'gas';
        else type = 'liquid';
      }

      const foundPumps = pumps.map(name => serverData.pumps.find(serverPump => serverPump.name === name));
      const uniqueIndicies = getVariableCombinations(serverData.reactors);
      const { time, temperature, reactorPumps } = getReactorProperties(
        reactorData,
        foundPumps,
        type,
        uniqueIndicies,
        processType,
        isWizardTwoStep
      );
      const pumpData = reactorPumps.map(pump => {
        const pumpProps = pump.properties[0];
        return {
          key: pump.key,
          name: pump.name,
          lengthOfVariants: pump.properties.length,
          type: [pump.type],
          flow_rate: pumpProps.flow_rate,
          is_variant: pump.is_variant,
          flash_solvent: [getCompoundData(pumpProps.flush_solvent)],
          properties: mapPumpPropsToFront(pump, pump.is_variant, getCompoundData),
        };
      });
      const reactorProps = reactorData?.properties[0];
      workAreaReactor = !!reactor
        ? {
            name: reactor,
            key: reactorData?.key,
            variable: reactorData?.is_variant || false,
            type,
            title: type === 'tubular' ? 'tubular' : type === 'liquid' || type === 'gas' ? 'separator' : 'packed bed',
            pumps: pumpData,
            flow_rate: type === 'liquid' && pumpData[0] ? pumpData[0].flow_rate[0] : [0],
            times: time,
            is_done: reactorProps?.is_done,
            cartridge_actual_mass: reactorData?.properties[0]?.cartridge_actual_mass,
            temperatures: temperature,
            pressure: reactorProps.pressure,
            catalysts: reactorProps.catalysts.map(i => getCompoundData(i)),
            properties: mapReactorPropsToFront(reactorData, getCompoundData, processType),
          }
        : { pumps: pumpData };
      data.push(workAreaReactor);
    });
  });

  return data;
};

const getVariableCombinations = reactors => {
  const variableReactor = reactors.find(reactor => reactor.is_variant);
  const uniqueTimes = new Set();
  const uniqueIndicies = new Set([0]);

  if (variableReactor) {
    variableReactor.properties.forEach((property, propertyIndex) => {
      const { time } = property;
      if (!uniqueTimes.has(time)) {
        uniqueTimes.add(time);
        uniqueIndicies.add(propertyIndex);
      }
    });
  }

  return uniqueIndicies;
};

const getReactorProperties = (reactorData, reactorPumps, reactorType, uniqueIndicies, processType, isWizardTwoStep) => {
  const data = {
    time: [],
    temperature: [],
    reactorPumps,
  };

  if (!reactorData && !reactorPumps) {
    return data;
  }

  const uniqueTimes = new Set();
  const uniqueTemperatures = new Set();

  if (reactorType !== 'liquid' && reactorData) {
    reactorData.properties.forEach((property, propertyIndex) => {
      const { temperature, time } = property;
      if (!uniqueTemperatures.has(temperature)) {
        uniqueTemperatures.add(temperature);
      }

      if (!uniqueTimes.has(time)) {
        uniqueTimes.add(time);
      }
    });
  }

  data.reactorPumps = data.reactorPumps.map(pump => {
    const flowRates = [];
    const reagentEquivalents = [];
    const reactantEquivalents = [];
    const reagentMFR = [];
    const reactantMFR = [];

    if (!pump.properties?.length) {
      return pump;
    }

    pump.properties.forEach((property, index) => {
      if (uniqueIndicies.has(index) || processType === PROJECT_TYPES.LIBRARY_GENERATION) {
        flowRates.push(property.flow_rate);

        property.reactants.forEach((reactant, reactantIndex) => {
          reactantEquivalents[reactantIndex] = reactantEquivalents[reactantIndex]
            ? reactantEquivalents[reactantIndex]
            : [];
          reactantEquivalents[reactantIndex].push(reactant.equivalent);
          reactantMFR[reactantIndex] = reactantMFR[reactantIndex] ? reactantMFR[reactantIndex] : [];
          reactantMFR[reactantIndex].push(reactant.molar_flow_rate);
        });
        property.reagents.forEach((reagent, reagentIndex) => {
          reagentEquivalents[reagentIndex] = reagentEquivalents[reagentIndex] ? reagentEquivalents[reagentIndex] : [];
          reagentEquivalents[reagentIndex].push(reagent.equivalent);
          reagentMFR[reagentIndex] = reagentMFR[reagentIndex] ? reagentMFR[reagentIndex] : [];
          reagentMFR[reagentIndex].push(reagent.molar_flow_rate);
        });
      }
    });
    return {
      ...pump,
      properties: pump.properties.map(property => {
        const extraData = isWizardTwoStep ? property : pump.properties[0];
        return {
          ...extraData,
          solvents: property.solvents,
          flow_rate:
            pump.is_variant || processType === PROJECT_TYPES.LIBRARY_GENERATION ? flowRates.slice(0, 1) : flowRates,
          reactants: property.reactants.map((reactant, index) => {
            return {
              ...reactant,
              equivalent: pump.is_variant ? [reactant.equivalent] : reactantEquivalents[index],
              molar_flow_rate: pump.is_variant ? [reactant.molar_flow_rate] : reactantMFR[index],
            };
          }),
          reagents: property.reagents.map((reagent, index) => {
            return {
              ...reagent,
              equivalent: pump.is_variant ? [reagent.equivalent] : reagentEquivalents[index],
              molar_flow_rate: pump.is_variant ? [reagent.molar_flow_rate] : reagentMFR[index],
            };
          }),
        };
      }),
    };
  });

  return {
    time: Array.from(uniqueTimes),
    temperature: Array.from(uniqueTemperatures),
    reactorPumps: data.reactorPumps,
  };
};

const mapReactorPropsToBacOptimization = (reactor, variableReactor) => {
  const data = [];
  if (variableReactor) {
    variableReactor.temperatures.forEach((temperature, temperatureIndex) => {
      variableReactor.times.forEach((time, timeIndex) => {
        data.push({
          temperature: (reactor.variable ? reactor.temperatures[temperatureIndex] : reactor.temperatures[0]) || null,
          time: +reactor.times[timeIndex] || null,
          pressure: reactor.pressure,
          catalysts: reactor.catalysts.map(i => i.compound_id),
          product: reactor.properties[0].products[0]?.compound_id || null,
          sideproducts: reactor.properties[0].sideproducts.map(i => i.compound_id),
        });
      });
    });
  } else {
    data.push({
      temperature: +reactor.temperatures[0] || null,
      time: +reactor.times[0] || null,
      pressure: reactor.pressure,
      catalysts: reactor.catalysts.map(i => i.compound_id),
      product: reactor.properties[0].products[0]?.compound_id || null,
      sideproducts: reactor.properties[0].sideproducts.map(i => i.compound_id),
    });
  }

  return data;
};

const mapPumpPropsToBacOptimization = (reactors, pump, flush_solvent) => {
  const data = [];
  const variableReactor = reactors.find(reactor => reactor.variable);
  if (variableReactor) {
    variableReactor.temperatures.forEach(() => {
      variableReactor.times.forEach((time, timeIndex) => {
        data.push({
          flush_solvent,
          flow_rate: +pump.flow_rate[timeIndex],
          reactants: pump.properties[0].reactants.map(reactant => mapCompoundToBack(reactant, timeIndex, timeIndex)),
          reagents: pump.properties[0].reagents.map(reagent => mapCompoundToBack(reagent, timeIndex, timeIndex)),
          solvents: pump.properties[0].solvents.map(solvent => mapCompoundToBack(solvent, timeIndex, timeIndex)),
        });
      });
    });
  } else {
    data.push({
      flush_solvent,
      flow_rate: +pump.flow_rate[0],
      reactants: pump.properties[0].reactants.map(reactant => mapCompoundToBack(reactant, 0, 0)),
      reagents: pump.properties[0].reagents.map(reagent => mapCompoundToBack(reagent, 0, 0)),
      solvents: pump.properties[0].solvents.map(solvent => mapCompoundToBack(solvent, 0, 0)),
    });
  }
  return data;
};
const getPumpIsVariable = (pump, variationPump) => {
  if (pump.is_variant) return true;
  if (variationPump) return pump.name === variationPump.name;
  return false;
};
export const createDataToSaveProcess = (data = [], variationPump, variationPumpReactorIndex, processType) => {
  let process_steps = [],
    reactors = [],
    pumps = [],
    compounds = [];
  const variableReactor = data.find(reactor => reactor.variable);

  data.forEach((reactor, reactorIdx) => {
    let reactorName = `Reactor ${reactorIdx + 1}`;
    const isVariative =
      !!reactor.variable || variationPumpReactorIndex || variationPumpReactorIndex === 0
        ? reactorIdx >= variationPumpReactorIndex
        : false;
    // if no prev. reactor / prev. reactor has expected intermediate / type is not liquid and reactor is not empty
    if (
      !data[reactorIdx - 1] ||
      (!!data[reactorIdx - 1] &&
        data[reactorIdx - 1]?.properties &&
        data[reactorIdx - 1].properties.length ===
          data[reactorIdx - 1].properties.filter(p => p.products.length).length &&
        reactor.key &&
        reactor.type !== 'liquid') ||
      (!!data[reactorIdx - 1] &&
        data[reactorIdx - 1].type === 'liquid' &&
        reactor.key &&
        reactor.type !== 'liquid' &&
        reactor.properties.length === reactor.properties.filter(p => p.products.length).length)
    )
      process_steps.push({
        step: [
          {
            reactor: reactor.key ? reactorName : null,
            pumps: reactor.pumps.map(pump => pump.name),
          },
        ],
      });
    else
      process_steps[process_steps.length - 1].step.push({
        reactor: reactor.key ? reactorName : null,
        pumps: reactor.pumps.map(pump => pump.name),
      });
    if (reactor.key) {
      reactors.push({
        name: reactorName,
        key: reactor.key,
        is_variant: isVariative,
        properties:
          processType === PROJECT_TYPES.OPTIMIZATION
            ? mapReactorPropsToBacOptimization(reactor, variableReactor)
            : mapReactorPropsToBack(reactor, isVariative, variationPump),
      });
      let reactorCompounds = [];
      reactor.properties.forEach(property => {
        reactorCompounds = [...reactorCompounds, ...property.sideproducts];
        if (property.products[0]) reactorCompounds.push(property.products[0]);
      });
      compounds = [...compounds, ...reactorCompounds, ...reactor.catalysts];
    }
    reactor.pumps.forEach(pump => {
      let flush_solvent = null;
      if (pump.flash_solvent && pump.flash_solvent[0] && pump.flash_solvent[0].compound_id) {
        compounds.push(pump.flash_solvent[0]);
        flush_solvent = pump.flash_solvent[0].compound_id;
      }

      pumps.push({
        name: pump.name,
        type: pump.type[0],
        is_variant: getPumpIsVariable(pump, variationPump),
        properties:
          processType === PROJECT_TYPES.OPTIMIZATION
            ? mapPumpPropsToBacOptimization(data, pump, flush_solvent)
            : mapPumpPropsToBack(pump, variationPump, flush_solvent),
      });
      let pumpCompounds = [];
      pump.properties.forEach(property => {
        const propertyCompounds = [...property.reagents, ...property.reactants, ...property.solvents].map(
          i => i.properties
        );
        pumpCompounds = [...pumpCompounds, ...propertyCompounds];
      });
      compounds = [...compounds, ...pumpCompounds];
    });
  });
  let serverCompounds = [];
  compounds.map(compound => {
    if (!compound.compatibility) delete compound.compatibility;
  });
  compounds.forEach(cpd => {
    if (!serverCompounds.find(sC => sC.compound_id === cpd.compound_id)) serverCompounds.push(cpd);
  });
  const processData = {
    process_steps,
    reactors,
    pumps,
    compounds: serverCompounds,
  };
  return processData;
};

const mapCompoundToBack = (compound, variatyIndex, optimizationVariableIndex = 0) => {
  const { properties, equivalents, molar_flow_rate, ...compoundData } = compound;
  const equivalent = equivalents ? setEquivsValues(equivalents)[variatyIndex] || null : null;

  return {
    ...compoundData,
    concentration: +compoundData.concentration,
    molar_flow_rate: molar_flow_rate ? +(molar_flow_rate[optimizationVariableIndex] || molar_flow_rate) : null,
    equivalent,
  };
};

const mapReactorPropsToBack = (reactor, isVariative, variativePump) => {
  return !variativePump || isVariative
    ? reactor.properties.map(property => ({
        temperature: +reactor.temperatures[0] || null,
        time: reactor.times[0] || null,
        pressure: reactor.pressure,
        catalysts: reactor.catalysts.map(i => i.compound_id),
        product: property.products[0]?.compound_id || null,
        sideproducts: property.sideproducts.map(i => i.compound_id),
      }))
    : variativePump.properties.map(property => ({
        temperature: +reactor.temperatures[0] || null,
        time: reactor.times[0] || null,
        pressure: reactor.pressure,
        catalysts: reactor.catalysts.map(i => i.compound_id),
        product: reactor.properties[0].products[0]?.compound_id || null,
        sideproducts: reactor.properties[0].sideproducts.map(i => i.compound_id),
      }));
};

const mapPumpPropsToBack = (pump, variativePump, flush_solvent) => {
  if (!variativePump || pump.name === variativePump?.name) {
    return pump.properties.map(property => ({
      flush_solvent,
      flow_rate: +pump.flow_rate[0],
      reactants: property.reactants.map(reactant => mapCompoundToBack(reactant, 0)),
      reagents: property.reagents.map(reagent => mapCompoundToBack(reagent, 0)),
      solvents: property.solvents.map(solvent => mapCompoundToBack(solvent, 0)),
    }));
  } else {
    return variativePump.properties.map((property, propIndex) => ({
      flush_solvent,
      flow_rate: +pump.flow_rate[0],
      reactants: pump.properties[0]?.reactants.map(reactant => mapCompoundToBack(reactant, propIndex)),
      reagents: pump.properties[0]?.reagents.map(reagent => mapCompoundToBack(reagent, propIndex)),
      solvents: pump.properties[0]?.solvents.map(solvent => mapCompoundToBack(solvent, propIndex)),
    }));
  }
};
const mapReactorPropsToFront = (reactorData, getCompoundData, processType) => {
  const properties =
    reactorData.is_variant && processType !== PROJECT_TYPES.OPTIMIZATION
      ? reactorData.properties
      : reactorData.properties.slice(0, 1);
  return properties.map(property => ({
    products: property.product ? [getCompoundData(property.product)] : [],
    sideproducts: property.sideproducts.map(i => getCompoundData(i)),
  }));
};

const mapPumpPropsToFront = (pump, isVariant, getCompoundData) => {
  const mapCompoundToFront = (compound, field, equivalents) => {
    return {
      ...compound,
      properties: getCompoundData(compound[field]),
      molar_flow_rate: compound.molar_flow_rate,
      equivalents: equivalents ? parseEquivsValues(equivalents) : [],
    };
  };
  if (isVariant) {
    return pump.properties.map(property => ({
      ...property,
      reactants: property.reactants.map(reactant =>
        mapCompoundToFront(reactant, 'reactant', reactant.equivalent, getCompoundData)
      ),
      reactants_consentration: [],
      reagents: property.reagents.map(reagent =>
        mapCompoundToFront(reagent, 'reagent', reagent.equivalent, getCompoundData)
      ),
      reagents_consentration: [],
      solvents: property.solvents.map(solvent => mapCompoundToFront(solvent, 'solvent', null, getCompoundData)),
      solvents_fraction: [],
    }));
  } else {
    return [
      {
        ...pump.properties[0],
        reactants: pump.properties[0].reactants.map(reactant =>
          mapCompoundToFront(reactant, 'reactant', reactant.equivalent, getCompoundData)
        ),
        reactants_consentration: [],
        reagents: pump.properties[0].reagents.map(reagent =>
          mapCompoundToFront(reagent, 'reagent', reagent.equivalent, getCompoundData)
        ),
        reagents_consentration: [],
        solvents: pump.properties[0].solvents.map(solvent =>
          mapCompoundToFront(solvent, 'solvent', null, getCompoundData)
        ),
        solvents_fraction: [],
      },
    ];
  }
};
