import './styles.scss';

import React, { useMemo, useState } from 'react';

import { ListWithSearchBase } from '@organisms/ListWithSearch/ListWithSearchBase';
import { useDebounce } from 'hooks';

import { Popover } from '@atoms';

export const ListWithSearch = ({ options = [], selectedItems, ...props }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce({ delay: 600, value: search })?.trim();
  const filteredOptions = useMemo(() => {
    let slicedOptions = options;
    if (debouncedSearch) {
      slicedOptions = options
        .filter(({ label }) => label.toLowerCase().includes(debouncedSearch.toLowerCase()))
        .slice(0, 30);
    }
    slicedOptions = slicedOptions.map(option => {
      const selected = !!selectedItems.find(selectedItem => selectedItem.uuid === option.value.uuid);
      return { ...option, selected };
    });
    return slicedOptions;
  }, [options, debouncedSearch, selectedItems]);

  return <ListWithSearchBase options={filteredOptions} search={search} onSearch={setSearch} {...props} />;
};

export const PopoverListWithSearch = ({ popoverProps, children, listProps }) => (
  <Popover
    destroyTooltipOnHide
    overlayClassName="popover-list-with-search-overlay"
    trigger="click"
    content={<ListWithSearch {...listProps} />}
    {...popoverProps}
  >
    <div>{children}</div>
  </Popover>
);
