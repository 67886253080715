import React from 'react';

import { OptionWithColor, TextBody14 } from '@atoms';

// TODO: make popover as part of atomic design, delete it from here
export const PopoverContent = ({ options }) => (
  <div className="popover-content">
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {options.map((item, i) => (
        <li key={item?.value} title={item?.value}>
          <TextBody14>
            {i + 1}
            .&nbsp;
          </TextBody14>
          <OptionWithColor color={item.color} label={item.label} />
        </li>
      ))}
    </div>
  </div>
);
