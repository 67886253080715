import './index.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { DEVICES, PROCESS_TYPES, PROJECT_TYPES } from '../../../constants';
import { getListProcesses, getProcess } from '../../../store/autosyn/autosyn.actions';
import { setProcess } from '../../../store/experimentCreation/experimentCreation.action';
import { getListProjects } from '../../../store/projects/projects.actions';
import { getProcess as getSynjetProcess, parseProcessDefinition } from '../../../store/synjet/synjet.actions';
import { addLabelsToOptions, parseProcessBuilderData } from '../../../utils';
import { parseProcessBuilderDataFromBackend } from '../../../utils/manualProcessBuilder';
import { Divider, Select } from '../../Common';

const getDevice = (isSynjet, isSynjetPro, isLab) => {
  if (isSynjet) return DEVICES.SYNJET;
  if (isSynjetPro) return DEVICES.SYNJETPRO;
  if (isLab) return DEVICES.LAB;
  return DEVICES.AUTOSYN;
};

const PROCESS_TYPE_OPTIONS = [
  { value: PROCESS_TYPES.AutoSyn.key, label: 'AutoSyn' },
  { value: PROCESS_TYPES.SynJet.key, label: 'SynJet' },
  { value: PROCESS_TYPES.SynJetPro.key, label: 'SynJet Pro' },
  { value: PROCESS_TYPES.Lab.key, label: 'Lab' },
];

export const ChooseProcessForm = ({
  process,
  reactors,
  project,
  processID,
  setProject,
  setProcessID,
  errors,
  setErrors,
  reactions,
  isSynjet,
  isLab,
  isSynjetPro,
  processInstrument,
  setProcessInstrument,
  chooseInstrument,
}) => {
  const [projectOptions, setProjectOptions] = useState([]);

  const dispatch = useDispatch();
  // const getProjects = useCallback(async value => {
  //   const response = await dispatch(getListProjects({ search: value }, true));
  //   if (response?.results) return response.results.map(i => ({ value: i.uuid, label: i.name }));
  //   return [];
  // }, []);
  // const getProcesses = useCallback(
  //   async value => {
  //     const projects = project ? { project_In: project } : {};
  //     const device = { device: getDevice(isSynjet, isSynjetPro, isLab)};
  //     const response = await dispatch(
  //       getListProcesses(
  //         { search: value, searchType: 'name', status_In: 'validated,experiment submitted', ...device, ...projects },
  //         'Processes',
  //         true
  //       )
  //     );
  //     if (response?.results) return response.results.map(i => ({ value: i.uuid, label: i.name }));
  //     return [];
  //   },
  //   [project]
  // );

  useEffect(() => {
    setErrors({ ...errors, project: null });
    if (isSynjet || isSynjetPro ? project === process?.process?.project?.uuid : project === process?.project?.uuid) {
      return;
    }
    setProcessID(null);
    dispatch(setProcess(null, null, null));
  }, [project]);

  useEffect(() => {
    if (!processID) return;
    setErrors({ ...errors, processID: null });
    if (isSynjet || isSynjetPro) {
      if (process && processID === process?.process?.uuid) return;
      dispatch(getSynjetProcess(processID, true)).then(async response => {
        const { process: requestSynjetProcessData } = response;
        const { processSteps, steps, products, quenching } = await dispatch(
          parseProcessDefinition(requestSynjetProcessData.definition, requestSynjetProcessData.variableStep, true)
        );
        dispatch(
          setProcess(
            { process: requestSynjetProcessData },
            {
              experiments: { process_steps: processSteps },
              processSteps: steps,
              products,
              quenching,
            },
            0
          )
        );
        if (!project) {
          setProject(requestSynjetProcessData?.project?.uuid);
        }
      });
    } else {
      if (process && processID === process.uuid) return;
      dispatch(getProcess(processID, true)).then(response => {
        const { process: requestProcessData } = response;
        const processDefinition = JSON.parse(requestProcessData.definition);
        if (!processDefinition) {
          setErrors({ ...errors, processID: 'Process has no definition' });
          return;
        }
        if (isLab) {
          const { parsedData } = parseProcessBuilderDataFromBackend(
            requestProcessData.definition,
            requestProcessData.type
          );
          dispatch(setProcess(requestProcessData, parsedData, null));
        } else {
          const builderData = parseProcessBuilderData(processDefinition, reactors, requestProcessData.type);
          dispatch(setProcess(requestProcessData, builderData, processDefinition?.process_steps));
        }
        if (!project) {
          setProject(requestProcessData?.project?.uuid);
        }
      });
    }
  }, [processID]);

  const actionOptionsProjects = async search => {
    const response = await dispatch(getListProjects({ search, status: 'active' }, true, 20)).then(resp => {
      if (search === '') return [];
      const respCompared = addLabelsToOptions(resp);
      setProjectOptions(respCompared);
      return respCompared;
    });
    return response;
  };

  const actionOptionsProcesses = async search => {
    const projects = project ? { project_In: project } : {};
    const device = { device: getDevice(isSynjet, isSynjetPro, isLab) };

    const response = await dispatch(
      getListProcesses(
        {
          search,
          searchType: 'name',
          status_In: 'validated,experiment submitted',
          ...device,
          ...projects,
        },
        'Processes',
        true,
        20
      )
    ).then(resp => {
      if (search === '') return [];
      const respCompared = addLabelsToOptions(resp);
      setProjectOptions(respCompared);
      return respCompared;
    });
    return response;
  };

  const onSelect = () => {
    setProjectOptions([]);
  };

  const onChangeProcessIntstrument = value => {
    setProcessInstrument(value);
    if (errors.processInstrument && chooseInstrument) {
      setErrors({ ...errors, processInstrument: null });
    }
    setProject(null);
    setProcessID(null);
  };

  return (
    <>
      <div className="createExperimentModal__process-row">
        <h4 className={classNames('createExperimentModal__subtitle', 'title-container')}>
          Process instrument <span>*</span>
        </h4>
        <Select
          className="input"
          placeholder="Select process instrument"
          options={PROCESS_TYPE_OPTIONS}
          value={processInstrument}
          onChange={onChangeProcessIntstrument}
          error={errors.processInstrument}
          errorText={errors.processInstrument}
        />
      </div>
      <div className="createExperimentModal__process-row">
        <h4 className={classNames('createExperimentModal__subtitle', 'title-container')}>
          Project <span>*</span>
        </h4>
        <Select
          disabled={chooseInstrument && !processInstrument}
          className="input"
          typeToSearch
          placeholder="Select project"
          getOptions={actionOptionsProjects}
          onSelect={onSelect}
          options={projectOptions}
          value={isSynjet || isSynjetPro ? process?.process?.project?.name : process?.project?.name || project}
          onChange={setProject}
          error={errors.project}
          errorText={errors.project}
          trigger={project}
          notFoundContent={false}
        />
      </div>
      <div className="createExperimentModal__process-row">
        <h4 className={classNames('createExperimentModal__subtitle', 'title-container')}>
          Process <span>*</span>
        </h4>
        <Select
          disabled={chooseInstrument && !processInstrument}
          className="input"
          typeToSearch
          getOptions={actionOptionsProcesses}
          onSelect={onSelect}
          options={projectOptions}
          placeholder="Select process"
          value={isSynjet || isSynjetPro ? process?.process?.name : process?.name || processID}
          onChange={setProcessID}
          trigger={project}
          error={errors.processID}
          errorText={errors.processID}
          notFoundContent={false}
        />
      </div>
      <div className="createExperimentModal__process-row">
        <h4 className={classNames('createExperimentModal__subtitle', 'title-container')}>Process type</h4>
        <span>
          {isSynjet || isSynjetPro
            ? process
              ? `${process?.process?.type} (${process?.process?.numberOfSteps}-step)`
              : 'N/A'
            : process?.type || 'N/A'}
        </span>
      </div>
      <h4 className="createExperimentModal__subtitle">Select reactions</h4>
      <Divider />
      <div className="createExperimentModal__process-body">
        {!process && <h6 className="hint">Reactions from the selected route will be displayed here</h6>}
        {((reactions?.length < 2 && process) || (process && process.type === PROJECT_TYPES.PRODUCTION)) &&
          !isSynjet &&
          !isSynjetPro && <h6 className="hint">This process has only one reaction, you can proceed</h6>}
      </div>
    </>
  );
};
