import React, { useCallback, useEffect, useState } from 'react';
import { Spinner, openNotification, closeNotification, Popup } from 'components/Common';
import { ProcessDetails } from 'components/ProcessBuilder/ProcessDetails/ProcessDetails';
import { useSelector, useDispatch } from 'react-redux';
import { user, editModeSelector } from 'store/common/common.selector';
import cn from 'classnames';
import { getProcess, updateProcess, setProcess } from 'store/autosyn/autosyn.actions';
import { getDetailsUser, setEditMode } from 'store/common/common.actions';
import { useLocation, useParams } from 'react-router-dom';
import { process } from 'store/autosyn/autosyn.selector';
import history from 'utils/history';
import { ManualWorkArea } from 'components/ProcessBuilder/WorkSpace/ManualWorkArea';
import { INITIAL_MANUAL_STATE } from 'constants/manualProcess';
import { ViewInitialRoute } from 'components/ProcessBuilder/ViewInitialRoute';
import { SidebarGlassware } from 'components/ProcessBuilder/Manual/Sidebars/SidebarGlassware';
import { SidebarInput } from 'components/ProcessBuilder/Manual/Sidebars/SidebarInput';
import { SidebarEquipment } from 'components/ProcessBuilder/Manual/Sidebars/SidebarEquipment';
import { SidebarReactorCompound } from 'components/ProcessBuilder/SidebarReactorCompound/SidebarReactorCompound';
import { OnBeforeLeave } from '../../components/OnBeforeLeave';
import { Footer } from 'components/SynJet/Footer';
import { CreateExperimentModal } from 'components';
import { parseProcessBuilderDataToBackend, parseProcessBuilderDataFromBackend } from 'utils/manualProcessBuilder';
import {
  saveManualProcess,
  lockManualProcess,
  updateProcessDefinition,
  setValidationErrors,
  setValidationWarnings,
} from 'store/processBuilder/processbuilder.actions';
import { COMPOUND_VARIATY, PROCESS_BUILDER_ERROR, PROCESS_TYPES, PROJECT_TYPES, typesProcess } from '../../constants';
import { errors, warnings } from '../../store/processBuilder/processbuilder.selector';
import { checkIfMyLock } from '../../utils/checkIfMyLock';
import { setModalOpen } from '../../store/experimentCreation/experimentCreation.action';
import { CreatorProcessFromRoute } from '../../components/AutoSin/CreatorProcessFromRoute';
import { decodeURIComponentSafe } from 'utils';

export const LabProcessBuilder = () => {
  const [loading, setLoading] = useState(false);
  const [headerData, setHeaderData] = useState(null);
  const [showInitialRoute, setShowInitialRoute] = useState(false);
  const [data, setData] = useState([{ ...INITIAL_MANUAL_STATE }]);
  const [stateIndexes, setStateIndexes] = useState({
    dataIndex: null,
    inputIndex: null,
    editIndex: null,
  });

  const editMode = useSelector(editModeSelector);

  //sidebars
  const [openSidebarGlassware, setSidebarGlassware] = useState(false);
  const [openSidebarInput, setSidebarInput] = useState(false);
  const [openSidebarEquipment, setSidebarEquipment] = useState(false);
  const [openSidebarCompoundEquipment, setOpenSidebarCompoundEquipment] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [description, setDescription] = useState('');
  const [limitingInput, setLimitingInput] = useState(null);
  const [inputWithName, setInputWithName] = useState(null);

  const [editingData, setEditingData] = useState(null);

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [confirmationDeleteCompound, setConfirmationDeleteCompound] = useState(false);
  const [validatedConfirmationOpen, setValidatedConfirmationOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openValidatePopup, setOpenValidatePopup] = useState(false);
  const [indexOfInput, setIndexOfInput] = useState(0);
  const [createExperimentErrorPopup, setCreateExperimentErrorPopup] = useState(false);

  const [allErrorsCount, _setAllErrorsCount] = useState(0);
  const [allWarningsCount, _setAllWarningsCount] = useState(0);
  const [count, _setCount] = useState(0);
  const [showResultingRoute, setShowResultingRoute] = useState(false);
  const [becameVariable, setBecameVariable] = useState(null);
  const [processPermissionsError, setProcessPermissionsError] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector(user);
  const currentProcess = useSelector(process);
  const validationErrors = useSelector(errors);
  const validationWarnings = useSelector(warnings);
  const { id } = useParams();
  const { location } = useLocation();

  useEffect(() => {
    setLoading(true);
    dispatch(getDetailsUser());
    dispatch(getProcess(id)).finally(data => {
      setLoading(false);
      if (location?.state?.showInitial) {
        setShowInitialRoute(true);
        history.replace(location.pathname);
      }
    });

    return () => {
      dispatch(setProcess([]));
      if (allErrorsCountRef.current > 0 || allWarningsCountRef.current > 0) {
        for (let i = 0; i < allErrorsCountRef.current; i++) {
          closeNotification('queue-notification-' + i);
        }
        for (let i = 0; i < allWarningsCountRef.current; i++) {
          closeNotification('warning-notification-' + i);
        }
        dispatch(setValidationErrors([]));
        dispatch(setValidationWarnings([]));
      }
    };
  }, []);

  useEffect(() => {
    if (validationErrors?.length) {
      setCount(validationErrors.length);
      setAllErrorsCount(validationErrors.length);
      validationErrors.forEach((err, idx) => {
        openNotification('', err, null, 'queue-notification-' + idx, updateCounter);
      });
    }
  }, [validationErrors]);

  useEffect(() => {
    if (validationWarnings?.length) {
      setAllWarningsCount(validationWarnings.length);
      validationWarnings.forEach((warning, idx) => {
        openNotification('', '', null, 'warning-notification-' + idx, null, warning);
      });
    }
  }, [validationWarnings]);

  const updateCounter = () => {
    setCount(counterRef.current - 1);
  };

  const allErrorsCountRef = React.useRef(allErrorsCount);
  const setAllErrorsCount = data => {
    allErrorsCountRef.current = data;
    _setAllErrorsCount(data);
  };

  const allWarningsCountRef = React.useRef(allWarningsCount);
  const setAllWarningsCount = data => {
    allWarningsCountRef.current = data;
    _setAllWarningsCount(data);
  };

  const counterRef = React.useRef(count);
  const setCount = data => {
    counterRef.current = data;
    _setCount(data);
  };

  const closePopupResultingRoute = () => {
    setShowResultingRoute(false);
  };
  const [isInitial, setIsInitial] = useState(true);
  useEffect(() => {
    if (currentProcess?.error) {
      openNotification(null, PROCESS_BUILDER_ERROR);
      setProcessPermissionsError(true);
    } else {
      if (currentProcess) setHeaderData(currentProcess);
      const process = currentProcess;
      if (process?.process && isInitial) {
        setIsInitial(false);
        if (process?.process?.definition) {
          if (process?.process?.processLock?.isMyLock) {
            dispatch(setEditMode(true));
          } else {
            dispatch(setEditMode(false));
          }
        } else if (process?.process && !process.process?.processLock?.createdBy) {
          dispatch(lockManualProcess(process.process?.processLock?.uuid));
          dispatch(setEditMode(true));
        } else if (
          process?.process &&
          !!process.process?.processLock?.createdBy &&
          process?.process?.processLock?.isMyLock
        ) {
          dispatch(setEditMode(true));
        }
      }
    }
  }, [currentProcess]);

  useEffect(() => {
    setProcessDataByDefinition();
  }, [currentProcess?.process?.definition]);

  const setProcessDataByDefinition = () => {
    if (currentProcess?.process?.definition) {
      let { parsedData, description } = parseProcessBuilderDataFromBackend(
        currentProcess.process.definition,
        currentProcess.process.type
      );
      setDescription(description);
      parsedData = addEmptyState(parsedData);
      parsedData = addVariations(parsedData);
      setData(parsedData);
      setLimitingInput(findLimitingInput(null, true, parsedData));
      if (currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION) {
        findLimitingInputLibGen(null, true, parsedData);
      }
    } else {
      setData([{ ...INITIAL_MANUAL_STATE }]);
      setDescription('');
    }
  };

  const setInputIdx = position => {
    let inputIdx = 0;
    data.map((item, index) => {
      if (index <= position) {
        inputIdx += item.inputs.length;
      }
    });
    setIndexOfInput(inputIdx + 1);
  };

  const checkIfEmpty = dataItem => {
    return !!dataItem && !dataItem.glassware && !dataItem.equipment && !dataItem?.inputs.length;
  };

  const getVariableInput = (dataToSearch = data) =>
    dataToSearch.find(i => i.inputs.find(input => input.variable))?.inputs.find(input => input.variable);

  const addGlassware = (index, editMode = false) => {
    setSidebarGlassware(true);
    setStateIndexes({
      ...stateIndexes,
      editIndex: !!editMode,
      dataIndex: index,
    });
    setEditingData(editMode ? data[index]?.glassware : null);
  };

  const addInput = (index, editIndex = null, propertyIndex = 0) => {
    setSidebarInput(true);
    setStateIndexes({
      ...stateIndexes,
      editIndex,
      dataIndex: index,
      propertyIndex,
    });
    setInputIdx(index);
    setEditingData(editIndex !== null ? { ...data[index]?.inputs[editIndex].properties[propertyIndex] } : null);
    setInputWithName(editIndex !== null ? data[index].inputs[editIndex] : null);
  };

  const findLimitingInput = (inputData, firstLoading = false, _data) => {
    let lim = null,
      newData = _data;
    if (!_data) {
      newData = [...data];
    }
    newData.forEach((item, index) => {
      item.inputs.forEach((input, i) => {
        if (!!input.properties) {
          input.properties.forEach((property, propertyIndex) => {
            let temp = {};
            if (
              (property.limiting &&
                (!_data ? property.name_input === inputData?.name_input : input.name === inputData?.name_input) &&
                inputData?.limiting) ||
              (!inputData?.limiting && property.limiting)
            ) {
              if (!_data) {
                setLimitingInput(property);
                lim = property;
              } else {
                temp = { ...property, name: input.name };
                setLimitingInput(temp);
                lim = temp;
              }
            }
            if (firstLoading && property.limiting) {
              if (!_data) {
                setLimitingInput(property);
                lim = property;
              } else {
                temp = { ...property, name: input.name };
                setLimitingInput(temp);
                lim = temp;
              }
            }
          });
        }
      });
    });
    if (!lim && inputData?.limiting && !!inputData) {
      setLimitingInput(inputData);
      lim = inputData;
    } else if (!lim) {
      setLimitingInput(null);
    }
    return lim ? { ...lim } : null;
  };

  const findLimitingInputLibGen = (inputData, firstLoading = false, _data) => {
    let lim = null,
      newData = _data;
    if (!_data) {
      newData = [...data];
    }
    newData.forEach((item, index) => {
      item.inputs.forEach((input, i) => {
        if (!!input.properties) {
          input.properties.forEach((property, propertyIndex) => {
            if (
              (property.limiting && property.name_input === inputData?.name_input && inputData?.limiting) ||
              (!inputData?.limiting && property.limiting)
            ) {
              let temp;
              let props =
                currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION
                  ? { upperInputName: input.name }
                  : {};
              temp = {
                ...property,
                name:
                  currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION
                    ? `${input.name}${COMPOUND_VARIATY[propertyIndex]}`
                    : input.name,
                ...props,
              };
              if (input.variable) {
                lim = getVariableInput(newData);
                let t = lim.properties.map((prop, idx) => {
                  return {
                    ...prop,
                    name_input: `${input.name}${COMPOUND_VARIATY[idx]}`,
                    name: `${input.name}${COMPOUND_VARIATY[idx]}`,
                    upperInputName: input.name,
                  };
                });
                lim = { ...lim, properties: t };
              } else {
                lim = temp;
              }
              setLimitingInput(lim);
            }
            if (firstLoading && property.limiting) {
              let temp;
              let props =
                currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION
                  ? { upperInputName: input.name }
                  : {};
              temp = {
                ...property,
                name:
                  currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION
                    ? `${input.name}${COMPOUND_VARIATY[propertyIndex]}`
                    : input.name,
                ...props,
              };
              if (input.variable) {
                lim = getVariableInput(newData);
                let t = lim.properties.map((prop, idx) => {
                  return {
                    ...prop,
                    name_input: `${input.name}${COMPOUND_VARIATY[idx]}`,
                    name: `${input.name}${COMPOUND_VARIATY[idx]}`,
                    upperInputName: input.name,
                  };
                });
                lim = { ...lim, properties: t };
              } else {
                lim = temp;
              }
              setLimitingInput(lim);
            }
          });
        }
      });
    });

    if (!lim && inputData?.limiting && !!inputData) {
      setLimitingInput(inputData);
      lim = inputData;
    } else if (!lim) {
      setLimitingInput(null);
    }
    return lim ? { ...lim } : null;
  };

  const openAddInputProperty = (dataIndex, inputIndex, propertyIndex, flag) => {
    setStateIndexes({
      ...stateIndexes,
      dataIndex,
      propertyIndex,
      inputIndex,
      editIndex: inputIndex,
    });
    if (data[dataIndex].inputs[inputIndex]) {
      setInputWithName(data[dataIndex].inputs[inputIndex]);
    } else {
      setInputWithName(null);
    }

    if (flag) {
      setBecameVariable(data[dataIndex].inputs[inputIndex]);
    } else {
      setBecameVariable(null);
    }

    if (data[dataIndex].inputs[inputIndex]?.properties[propertyIndex]) {
      setEditingData(data[dataIndex].inputs[inputIndex].properties[propertyIndex]);
    } else {
      setEditingData(null);
    }

    setSidebarInput(true);
  };

  const addEquipment = (index, editMode = false) => {
    setSidebarEquipment(true);
    setStateIndexes({
      ...stateIndexes,
      editIndex: !!editMode,
      dataIndex: index,
    });
    setEditingData(editMode ? data[index]?.equipment : null);
  };

  const onSaveGlassware = glasswareData => {
    let { dataIndex, editIndex } = stateIndexes;

    let notEmptyData = data.filter(i => !!i.glassware);
    if (!editIndex && notEmptyData > 9) {
      openNotification(null, 'You cannot add more than 10 glasswares'); // change this message
      return;
    }
    let newData = [...data].map((dataItem, _dataIndex) => {
      if (_dataIndex === dataIndex) {
        dataItem.glassware = editIndex ? { ...dataItem.glassware, ...glasswareData } : { ...glasswareData };
      }
      dataItem.visibleInput = true;
      return dataItem;
    });
    newData = addEmptyState(newData);
    setData(newData);
    if (!editIndex) {
      openNotification('Glassware has been added to the process');
    } else {
      openNotification('Glassware is successfully updated');
    }
    setSidebarGlassware(false);
  };

  const onSaveEquipment = equipmentData => {
    let { dataIndex, editIndex } = stateIndexes;

    let notEmptyData = data.filter(i => !!i.equipment);
    if (!editIndex && notEmptyData > 9) {
      openNotification(null, 'You cannot add more than 10 equipments'); // change this message
      return;
    }
    let newData = [...data].map((dataItem, _dataIndex) => {
      if (_dataIndex === dataIndex) {
        dataItem.equipment = editIndex ? { ...dataItem.equipment, ...equipmentData } : { ...equipmentData };
      }
      return dataItem;
    });
    newData = addVariations(newData);
    newData = addEmptyState(newData);
    setData(newData);

    if (!editIndex) {
      openNotification('Equipment has been added to the process');
    } else {
      openNotification('Equipment is successfully updated');
    }
    setSidebarEquipment(false);
  };

  const onSaveInput = inputData => {
    let { dataIndex, editIndex, inputIndex, propertyIndex } = stateIndexes;
    if (editIndex === null && data[dataIndex].inputs.length > 4) {
      openNotification(null, 'Process step should have 5 inputs max');
      return;
    }
    let newData = [...data].map((dataItem, _dataIndex) => {
      if (_dataIndex === dataIndex) {
        if (editIndex !== null) {
          if (dataItem.inputs[inputIndex]?.properties.length >= propertyIndex + 1) {
            dataItem.inputs = dataItem.inputs.map((input, _inputIndex) => {
              if (_inputIndex === editIndex) {
                return {
                  ...input,
                  variable: input.variable,
                  properties: input.properties.map((i, inputPropIdx) => {
                    if (inputPropIdx === propertyIndex) return inputData;
                    return i;
                  }),
                };
              }
              return input;
            });
          } else {
            dataItem.inputs = dataItem.inputs.map((input, _inputIndex) => {
              if (_inputIndex === editIndex) {
                return {
                  ...input,
                  variable: !!getVariableInput() ? !!input.variable : true,
                  properties: [...input.properties, inputData],
                };
              }
              return input;
            });
          }
        } else {
          dataItem.inputs = [...dataItem.inputs, { properties: [inputData] }];
        }
      }

      return dataItem;
    });
    newData = setNamesForComponents(newData);
    newData = setNamesToProperties(newData);
    newData = newData.map((dataItem, _dataIndex) => {
      let lim;
      if (currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION) {
        lim = findLimitingInputLibGen(inputData, false, newData);
      } else {
        lim = findLimitingInput(inputData, false, newData) ? findLimitingInput(inputData, false, newData) : null;
      }
      if (!!lim) {
        dataItem.inputs = dataItem.inputs.map(input => {
          input.properties = input.properties.map(property => {
            if (property.chemical_type !== 'solvent') {
              if (currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION) {
                let limInVar = lim.properties ? lim.properties.find(i => i.limiting) : lim;
                let lim_name = lim.properties ? limInVar.name_input : lim?.name_input;
                let lim_upper = lim.properties ? limInVar.upperInputName : lim?.upperInputName;
                if (property.name_input !== lim_name) {
                  property = recalculateEquivalentsDependingOnLimitingLibGen(property, lim, input.variable);
                } else if (
                  property.limiting &&
                  property.name_input === lim_name &&
                  ((becameVariable && property.upperInputName !== becameVariable.name) ||
                    (variationInput && property.upperInputName !== variationInput.name))
                ) {
                  let var1 = becameVariable ? becameVariable.properties.length + 1 : variationInput.properties.length;
                  let arr1 = [];
                  for (let i = 0; i < var1; i++) {
                    arr1[i] = 1;
                  }
                  property.equivalents = arr1;
                }
                if (property.name_input !== lim_name && property.upperInputName !== lim_upper) {
                  if (!lim) {
                    let eq = property.equivalents;
                    eq.fill(null, 0, eq.length);
                    property.equivalents = eq;
                  }
                  property.limiting = false;
                }
              } else {
                if (property.name_input !== lim?.name_input || input.name !== lim.name_input)
                  property = recalculateEquivalentsDependingOnLimiting(property, lim);
                if (
                  property.limiting &&
                  input.name !== inputData.name_input &&
                  input.name !== lim.name_input &&
                  input.name !== lim.name
                ) {
                  if (!lim) {
                    let eq = property.equivalents;
                    eq.fill(null, 0, eq.length);
                    property.equivalents = eq;
                  }
                  property.limiting = false;
                }
              }
            }
            return property;
          });
          return input;
        });
      } else {
        dataItem.inputs = dataItem.inputs.map(input => {
          input.properties = input.properties.map(property => {
            if (property.chemical_type !== 'solvent') {
              property.limiting = false;
              let eq = property.equivalents;
              eq.fill(null, 0, eq.length);
              property.equivalents = eq;
            }
            if (
              (becameVariable && property.upperInputName !== becameVariable.name && !input.variable) ||
              (variationInput && property.upperInputName !== variationInput.name && !input.variable)
            ) {
              let var1 = becameVariable ? becameVariable.properties.length + 1 : variationInput.properties.length;
              let arr1 = [];
              for (let i = 0; i < var1; i++) {
                arr1[i] = null;
              }
              property.equivalents = arr1;
            }

            return property;
          });
          return input;
        });
      }
      setLimitingInput(lim ? { ...lim } : null);
      return dataItem;
    });

    newData = addVariations(newData);
    newData = addEmptyState(newData);
    setData(newData);
    setBecameVariable(null);
    if (editIndex === null) {
      openNotification('Input has been added to the process');
      setStateIndexes({ editIndex: null, inputIndex: null, propertyIndex: null, dataIndex: null });
    } else {
      openNotification('Input is successfully updated');
    }
    setSidebarInput(false);
  };

  const recalculateEquivalentsDependingOnLimiting = (property, limitingInput) => {
    property.equivalents = property?.equivalents.map((item, index) => {
      item = limitingInput?.moles[0]
        ? (Number(property?.moles[index]) / Number(limitingInput?.moles[0])).toFixed(3)
        : null;
      return item;
    });
    return property;
  };

  const recalculateEquivalentsDependingOnLimitingLibGen = (property, limitingInput, isVariable = false) => {
    if (limitingInput.properties) {
      let arr = [];
      limitingInput.properties.forEach((propertyLim, propertyIndex) => {
        if (isVariable) {
          arr = property.limiting ? [1] : [null];
        } else {
          arr[propertyIndex] = propertyLim.limiting
            ? (Number(property?.moles[0]) / Number(propertyLim?.moles[0])).toFixed(3)
            : null;
        }
      });
      property.equivalents = arr;
      return property;
    } else {
      if (
        (becameVariable && property.upperInputName !== becameVariable.name) ||
        (variationInput && property.upperInputName !== variationInput.name)
      ) {
        let arr = [];
        let var1 = becameVariable ? becameVariable.properties.length + 1 : variationInput.properties.length;
        for (let i = 0; i < var1; i++) {
          arr[i] = limitingInput?.moles[0]
            ? (Number(property?.moles[0]) / Number(limitingInput?.moles[0])).toFixed(3)
            : null;
        }
        property.equivalents = arr;
      } else {
        property.equivalents = property?.equivalents.map((item, index) => {
          item = limitingInput?.moles[0]
            ? (Number(property?.moles[0]) / Number(limitingInput?.moles[0])).toFixed(3)
            : null;
          return item;
        });
      }
      return property;
    }
  };

  const setNamesToProperties = _data => {
    let newData = _data;
    if (!_data) {
      newData = [...data];
    }
    newData = newData.map((item, index) => {
      item.inputs = item.inputs.map((input, i) => {
        if (!!input.properties) {
          input.properties = input.properties.map((property, propertyIndex) => {
            if (!!input.name) {
              property.name =
                currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION
                  ? `${input.name}${COMPOUND_VARIATY[propertyIndex]}`
                  : input.name;
              property.upperInputName =
                currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION ? input.name : '';
              property.name_input =
                currentProcess?.process?.type === PROJECT_TYPES.LIBRARY_GENERATION
                  ? `${input.name}${COMPOUND_VARIATY[propertyIndex]}`
                  : input.name;
            }
            return property;
          });
        }
        return input;
      });
      return item;
    });
    return newData;
  };

  const addVariations = (_data, deletePropertyIndex = false) => {
    let newData = [..._data];
    if (getVariableInput(newData)) {
      let variableInputLength = getVariableInput(newData)?.properties.length;
      let variableInputIndex = newData.findIndex(i => i.inputs.find(input => !!input.variable));

      newData.map((dataItem, dataIndex) => {
        if (variableInputIndex <= dataIndex && dataItem.equipment?.properties.length < variableInputLength) {
          for (let i = 0; i <= variableInputLength - 1; i++) {
            if (!dataItem.equipment.properties[i]) {
              dataItem.equipment.properties[i] = {};
            }
          }
        } else if (variableInputIndex <= dataIndex && dataItem.equipment?.properties.length > variableInputLength) {
          if (deletePropertyIndex !== false) {
            dataItem.equipment.properties.splice(deletePropertyIndex, 1);
          } else
            for (let i = 0; i <= dataItem.equipment?.properties.length - 1; i++) {
              if (i > variableInputLength - 1) {
                dataItem.equipment.properties.splice(i, 1);
              }
            }
        } else if (variableInputIndex > dataIndex && dataItem.equipment?.properties.length > 1) {
          dataItem.equipment.properties.splice(0, 1);
        }
        return dataItem;
      });
    } else {
      newData.map((dataItem, dataIndex) => {
        if (dataItem?.equipment) {
          if (deletePropertyIndex !== false && dataItem.equipment.properties.length > 1)
            dataItem.equipment.properties.splice(deletePropertyIndex, 1);
        }
        return dataItem;
      });
    }
    return newData;
  };

  const addEmptyState = newData => {
    newData = newData.filter(i => !checkIfEmpty(i));
    if (!checkIfEmpty(newData[0])) {
      newData = [{ ...INITIAL_MANUAL_STATE }, ...newData];
    }
    if (newData.length > 1 && !checkIfEmpty(newData[newData.length - 1])) {
      if (newData[newData.length - 1].equipment) {
        newData = [...newData, { ...INITIAL_MANUAL_STATE, visibleEquipment: true }];
      } else {
        newData = [...newData, { ...INITIAL_MANUAL_STATE }];
      }
    }
    let dataWithEmptyStates = [...newData];
    let indexToAdd = 1;
    newData.forEach((item, index) => {
      if (newData[index + 1] && !checkIfEmpty(newData[index + 1]) && !checkIfEmpty(item) && item.equipment) {
        dataWithEmptyStates.splice(index + indexToAdd, 0, { ...INITIAL_MANUAL_STATE });
        indexToAdd += 1;
      } else if (newData[index + 1] && !checkIfEmpty(newData[index + 1]) && !checkIfEmpty(item) && !item.equipment) {
        dataWithEmptyStates.splice(index + indexToAdd, 0, { ...INITIAL_MANUAL_STATE });
        indexToAdd += 1;
      } else if (newData[index + 1] && checkIfEmpty(newData[index + 1]) && checkIfEmpty(item)) {
        dataWithEmptyStates.splice(index + indexToAdd, 1);
        indexToAdd += 1;
      }
    });
    for (let i = 0; i < dataWithEmptyStates.length - 1; i++) {
      if (
        checkIfEmpty(dataWithEmptyStates[i]) &&
        !dataWithEmptyStates[i].visibleEquipment &&
        !dataWithEmptyStates[i + 1].glassware
      ) {
        dataWithEmptyStates.splice(i, 1);
      }
    }
    return dataWithEmptyStates;
  };

  const onDeleteGlassware = () => {
    let { dataIndex, editIndex } = stateIndexes;
    let newData = [...data];
    newData[dataIndex].glassware = null;
    if (!newData[dataIndex].equipment) newData[dataIndex].inputs = [];
    newData = addEmptyState(newData);
    setData(newData);
  };

  const onDeleteInput = () => {
    let { dataIndex, editIndex } = stateIndexes;
    if (data[dataIndex].inputs[editIndex].properties.length > 1) setConfirmationDeleteCompound(true);
    else deleteInputCompound();
  };

  const deleteInputCompound = () => {
    let { dataIndex, editIndex, propertyIndex } = stateIndexes;
    let newData = [...data];
    if (PROJECT_TYPES.LIBRARY_GENERATION !== currentProcess?.process?.type) {
      newData = newData.map((i, index) => {
        if (dataIndex === index) {
          if (i.inputs[editIndex].properties.length > 1) {
            i.inputs[editIndex].properties.splice(propertyIndex, 1);
            if (i.inputs[editIndex].properties.length === 1) i.inputs[editIndex].variable = false;
          } else i.inputs.splice(editIndex, 1);
        }
        return i;
      });
    } else {
      newData = newData.map((i, index) => {
        if (dataIndex === index) {
          if (i.inputs[editIndex].properties.length > 1) {
            i.inputs[editIndex].properties.splice(propertyIndex, 1);
            if (i.inputs[editIndex].variable) {
              i.inputs = i.inputs.map((input, inputIndex) => {
                if (!input.variable) {
                  input.properties = input.properties.map(property => {
                    property.equivalents.splice(propertyIndex, 1);
                    return property;
                  });
                }
                return input;
              });
            }
            if (i.inputs[editIndex].properties.length === 1) {
              i.inputs[editIndex].variable = false;
            }
          } else {
            i.inputs.splice(editIndex, 1);
            i.inputs = i.inputs.map((input, inputIndex) => {
              input.properties = input.properties.map(property => {
                if (property.equivalents) {
                  let eq = property.equivalents;
                  eq.fill(null, 0, eq.length);
                }
                return property;
              });
              return input;
            });
          }
        }
        return i;
      });
    }
    findLimitingInputLibGen(newData);
    newData = addEmptyState(newData);
    newData = addVariations(newData, propertyIndex);
    setData(newData);
    setSidebarInput(false);
    setConfirmationDeleteCompound(false);
  };

  const onDeleteEquipment = () => {
    let { dataIndex, editIndex } = stateIndexes;
    let newData = [...data];
    newData[dataIndex].equipment = null;
    if (!newData[dataIndex].glassware) newData[dataIndex].inputs = [];
    newData[dataIndex + 1].visibleEquipment = false;
    newData = addEmptyState(newData);
    setData(newData);
  };

  const onSaveInputEquivalents = (equivalents, _inputIndex, dataIndex, propertyIndex = 0) => {
    let newData = [...data];
    let newInput = {
      ...newData[dataIndex].inputs[_inputIndex],
    };
    newInput.properties[propertyIndex] = {
      ...newInput.properties[propertyIndex],
      equivalents: [],
      moles: [],
      mass: [],
      volume: [],
    };
    equivalents.forEach((e, index) => {
      newInput.properties[propertyIndex].equivalents.push(equivalents[index].equivalents);
      newInput.properties[propertyIndex].moles.push(equivalents[index].moles);
      newInput.properties[propertyIndex].volume.push(equivalents[index].volume);
      newInput.properties[propertyIndex].mass.push(equivalents[index].mass);
    });
    newData[dataIndex].inputs[_inputIndex] = newInput;
    setData(newData);
  };

  const onSaveEquipmentOptions = (_newData, idx, deleteIndex, dataIndex) => {
    let newData = [...data];
    newData[dataIndex].equipment.temperatures = _newData.temperatures;
    newData[dataIndex].equipment.times = _newData.times;
    newData[dataIndex].equipment.variable = _newData.variable;
    setData(newData);
  };

  const setEquipmentCompoundData = compounds => {
    let newData = [...data];
    let { dataIndex, equipmentPropertyIndex } = stateIndexes;
    if (compounds.intermediate) {
      newData[dataIndex].equipment.properties[equipmentPropertyIndex].products = [compounds.intermediate];
    }
    if (compounds.sideproducts) {
      if (editingData)
        newData[dataIndex].equipment.properties[equipmentPropertyIndex].sideproducts = [...compounds.sideproducts];
      else if (!newData[dataIndex].equipment.properties[equipmentPropertyIndex].sideproducts)
        newData[dataIndex].equipment.properties[equipmentPropertyIndex].sideproducts = [];
      compounds.sideproducts.forEach(sideproduct => {
        if (
          !newData[dataIndex].equipment.properties[equipmentPropertyIndex].sideproducts.find(
            sp => sp.compound_id === sideproduct.compound_id
          )
        )
          newData[dataIndex].equipment.properties[equipmentPropertyIndex].sideproducts = [
            ...newData[dataIndex].equipment.properties[equipmentPropertyIndex].sideproducts,
            sideproduct,
          ];
      });
    }
    setData(newData);
    setOpenSidebarCompoundEquipment(false);
  };

  const openCompoundAddToEquipment = (index, dataIndex) => {
    setEditingData(null);
    setStateIndexes({
      ...stateIndexes,
      equipmentPropertyIndex: index,
      dataIndex,
    });
    setOpenSidebarCompoundEquipment(true);
  };

  const changeEquipmentCompound = (editData, dataIndex, index, pumpIdx, compoundPosition, variationAvailable) => {
    setOpenSidebarCompoundEquipment(true);
    setEditingData(editData);
    setStateIndexes({
      ...stateIndexes,
      equipmentPropertyIndex: compoundPosition,
      dataIndex,
    });
  };

  const deleteEquipmentCompound = () => {
    let newData = [...data];
    let { dataIndex, equipmentPropertyIndex } = stateIndexes;
    if (editingData.length) newData[dataIndex].equipment.properties[equipmentPropertyIndex].sideproducts = [];
    else newData[dataIndex].equipment.properties[equipmentPropertyIndex].products = [];
    setData(newData);
    setOpenSidebarCompoundEquipment(false);
    setConfirmationDelete(false);
  };

  const getVariableEquipmentData = newData => {
    let index = -1;
    let variableData = newData ? newData : [...data];
    variableData.forEach((i, indexVariable) => {
      if (i.variable || i?.times?.length > 1 || i?.temperature?.length > 1) {
        index = indexVariable;
      }
    });
    return variableData[index];
  };

  const onSaveProcess = async (validate = false) => {
    if (currentProcess.process?.status === 'validated' && !validatedConfirmationOpen && !validate) {
      setValidatedConfirmationOpen(true);
      return;
    }
    setLoadingButton(true);
    setLoading(true);

    const dataWithoutEmptyStates = setNamesForComponents(data).filter(dataItem => !checkIfEmpty(dataItem));
    const parsedData = parseProcessBuilderDataToBackend(
      dataWithoutEmptyStates,
      description,
      currentProcess?.process?.type
    );
    try {
      if (!editMode && validate) {
        await dispatch(lockManualProcess(currentProcess.process.processLock.uuid)).then(() => {});
      }
      const data = await dispatch(
        updateProcessDefinition(
          {
            definition: parsedData,
            uuid: id,
          },
          validate
        )
      );
      if (!data.updateProcessDefinition.errors?.length) {
        openNotification(
          validate
            ? 'Process is valid for experiment'
            : currentProcess.process?.definition
            ? 'Process changes are saved'
            : 'Process has been saved.'
        );
        dispatch(getProcess(id));
        dispatch(setEditMode(false));
      } else if (editMode) {
        await dispatch(lockManualProcess(currentProcess.process.processLock.uuid));
      }
    } catch (e) {
      if (editMode) {
        await dispatch(lockManualProcess(currentProcess.process.processLock.uuid));
      }
      openNotification('Process has been saved.', 'Please check your data.');
    } finally {
      setLoading(false);
      setLoadingButton(false);
      setValidatedConfirmationOpen(false);
      setOpenValidatePopup(false);
    }
  };

  const updateProcessLab = useCallback((...args) => {
    return dispatch(updateProcess(...args));
  }, []);

  const setNamesForComponents = _data => {
    let newData = [..._data];
    let inputIndex = 1,
      glasswareIndex = 1,
      equipmentIndex = 1;
    newData.map((dataItem, index) => {
      if (dataItem?.equipment) {
        dataItem.equipment.name = `Equipment ${equipmentIndex}`;
        equipmentIndex += 1;
      }
      if (dataItem?.glassware) {
        dataItem.glassware.name = `Glassware ${glasswareIndex}`;
        glasswareIndex += 1;
      }
      if (dataItem?.inputs.length) {
        dataItem.inputs = dataItem.inputs.map(input => {
          input.name = `Input ${inputIndex}`;
          inputIndex += 1;
          return input;
        });
      }
      return dataItem;
    });
    return newData;
  };

  const disableCreateExperiment =
    (currentProcess?.process?.status !== 'validated' && currentProcess?.process?.status !== 'experiment submitted') ||
    editMode ||
    checkIfMyLock(currentProcess.process.processLock.uuid);
  const showCreateExperimentBtn = currentUser?.permissions?.execution?.create_experiment;

  const handleCreateExperiment = async () => {
    setLoading(true);
    try {
      const { updateProcessDefinition: labUpdateProcessDefinition } = await dispatch(
        updateProcessDefinition({ uuid: id }, true)
      );
      if (labUpdateProcessDefinition.errors) {
        setCreateExperimentErrorPopup(true);
        dispatch(getProcess(id)).finally(() => {
          setLoading(false);
        });
      } else dispatch(setModalOpen(currentProcess.process, null, data));
    } catch (e) {
      setCreateExperimentErrorPopup(true);
    } finally {
      setLoading(false);
    }
  };

  const variationInput = getVariableInput();
  return (
    <>
      <Spinner loading={loading}>
        <div className="builder">
          {headerData && !processPermissionsError && (
            <div className="builder-content manual-builder-content">
              <ProcessDetails
                data={headerData}
                setShowResultingRoute={setShowResultingRoute}
                showResultingRoute={showResultingRoute}
                currentUser={currentUser}
                setShowInitialRoute={setShowInitialRoute}
                showInitialRoute={showInitialRoute}
              />
              <CreatorProcessFromRoute
                isPreviewResult
                dataProcess={currentProcess}
                open={showResultingRoute}
                handleCancel={closePopupResultingRoute}
                deviceType={PROCESS_TYPES.Lab.device}
              />
              {showInitialRoute && headerData?.process && (
                <ViewInitialRoute
                  type={typesProcess.lab}
                  dataProcess={currentProcess}
                  updateProcess={updateProcessLab}
                  setShowInitialRoute={setShowInitialRoute}
                  showInitialRoute={showInitialRoute}
                  editMode={editMode}
                  setGenerationData={newData => {
                    setData(newData);
                  }}
                />
              )}
              <div className={cn({ 'work-space-view-mode': !editMode && currentProcess?.process?.definition })}>
                <div className="view-mode-wrapper">
                  <ManualWorkArea
                    data={data}
                    addInput={addInput}
                    process={currentProcess}
                    processType={currentProcess?.process?.type}
                    addEquipment={addEquipment}
                    addGlassware={addGlassware}
                    onSaveEquipment={onSaveEquipment}
                    onSaveInputEquivalents={onSaveInputEquivalents}
                    onSaveEquipmentOptions={onSaveEquipmentOptions}
                    openCompoundAddToEquipment={openCompoundAddToEquipment}
                    changeEquipmentCompound={changeEquipmentCompound}
                    description={description}
                    setDescription={setDescription}
                    editMode={editMode}
                    openAddProperty={openAddInputProperty}
                    variationInput={variationInput}
                    limitingInput={limitingInput}
                  />
                </div>
              </div>
            </div>
          )}
          <SidebarGlassware
            open={openSidebarGlassware}
            onClose={() => setSidebarGlassware(false)}
            onSaveGlassware={onSaveGlassware}
            onDelete={onDeleteGlassware}
            editingData={editingData}
          />
          <SidebarInput
            open={openSidebarInput}
            onClose={() => {
              setSidebarInput(false);
              setBecameVariable(null);
            }}
            onSaveInput={onSaveInput}
            onDelete={onDeleteInput}
            editingData={editingData}
            processType={currentProcess?.process?.type}
            limitingInput={limitingInput}
            stateIndexes={stateIndexes}
            variationInput={variationInput}
            indexOfInput={indexOfInput}
            inputWithName={inputWithName}
            selectedInput={data[stateIndexes.dataIndex]?.inputs[stateIndexes.inputIndex]}
            dataProcess={data}
            becameVariable={becameVariable}
          />
          <SidebarEquipment
            open={openSidebarEquipment}
            onClose={() => setSidebarEquipment(false)}
            onSaveEquipment={onSaveEquipment}
            onDelete={onDeleteEquipment}
            editingData={editingData}
            processType={currentProcess?.process?.type}
          />
          {openSidebarCompoundEquipment && (
            <SidebarReactorCompound
              open={openSidebarCompoundEquipment}
              title="Add compound"
              setReactorCompoundData={setEquipmentCompoundData}
              disableIntermediate={
                data[stateIndexes.dataIndex]?.equipment?.properties[stateIndexes.equipmentPropertyIndex]?.products
              }
              onClose={() => {
                setOpenSidebarCompoundEquipment(false);
                setStateIndexes({
                  ...stateIndexes,
                  editIndex: null,
                });
                setEditingData(null);
              }}
              editingData={openSidebarCompoundEquipment && editingData}
              onDelete={() => setConfirmationDelete(true)}
              sidebarCompoundPumpIndex={0}
            />
          )}
        </div>
        {currentUser?.permissions?.process?.create_process && (
          <Footer
            onSave={onSaveProcess}
            onCancel={() => setOpenCancelPopup(true)}
            onValidate={() => {
              setOpenValidatePopup(true);
            }}
            onCreateExperiment={handleCreateExperiment}
            editMode={editMode}
            setEditMode={() => {
              dispatch(setEditMode(true));
              dispatch(lockManualProcess(currentProcess.process.processLock.uuid));
            }}
            showValidationErrorsCounter={validationErrors?.length > 0}
            count={count}
            process={currentProcess?.process || {}}
            loadingButton={loadingButton}
            showEditValidate={currentProcess?.process?.status !== 'experiment submitted'}
            disableCreateExperiment={disableCreateExperiment}
            showCreateExperimentBtn={showCreateExperimentBtn}
            disableSaveProcess={data.length === 1 && checkIfEmpty(data[0])}
            fixedFooter
          />
        )}
        <Popup
          open={openCancelPopup}
          title="Leave the page?"
          handleSubmit={async () => {
            setLoading(true);
            setOpenCancelPopup(false);
            dispatch(lockManualProcess(currentProcess.process?.processLock?.uuid, 'unlocked'));
            await dispatch(getProcess(id));
            setLoading(false);
            if (editMode && currentProcess.process?.definition) {
              dispatch(setEditMode(false));
              setProcessDataByDefinition();
            } else history.push('/lab');
            setLoading(false);
          }}
          handleCancel={() => {
            setOpenCancelPopup(false);
          }}
          textSubmit="Ok"
          loading={false}
        >
          Changes you made may not be saved
        </Popup>
        <Popup
          open={confirmationDelete}
          title="Warning"
          handleSubmit={deleteEquipmentCompound}
          handleCancel={() => {
            setConfirmationDelete(false);
          }}
          textSubmit="Delete"
          loading={loading}
        >
          <span>Do you want to Delete a compound?</span>
        </Popup>
        <Popup
          open={confirmationDeleteCompound}
          title="Delete a compound"
          handleSubmit={deleteInputCompound}
          handleCancel={() => {
            setConfirmationDeleteCompound(false);
          }}
          textSubmit="Delete"
          loading={false}
        >
          <span>Deletion of this compound will affect all following equimpent</span>
        </Popup>

        <Popup
          open={validatedConfirmationOpen}
          title="Warning"
          handleSubmit={() => {
            onSaveProcess();
            setValidatedConfirmationOpen(false);
          }}
          handleThirdButton={() => {
            onSaveProcess(true);
            setValidatedConfirmationOpen(false);
          }}
          handleCancel={() => {
            setValidatedConfirmationOpen(false);
          }}
          textSubmit="Save in draft"
          textThirdButton="Validate and save"
          loading={loading}
        >
          <div>Please note that you’ve changed the Validated process.</div>
          <div>Please validate it or it will be saved in Draft status until validated.</div>
        </Popup>
        <Popup
          open={openValidatePopup}
          title="Validate process"
          handleSubmit={() => onSaveProcess(true)}
          handleCancel={() => {
            setOpenValidatePopup(false);
          }}
          textSubmit="Validate"
          loading={loading}
        >
          <span>Please note that validate action will also save all process changes made</span>
        </Popup>
        <Popup
          open={createExperimentErrorPopup}
          title="Warning"
          textCancle="OK"
          handleCancel={() => setCreateExperimentErrorPopup(false)}
        >
          <div>The hardware setup has changed. The designed process is not valid anymore.</div>
          <div>Please update the process to create an experiment.</div>
        </Popup>
      </Spinner>
      <CreateExperimentModal isProcessBuilder isSynJet={false} isSynjetPro={false} isLab />
      <OnBeforeLeave />
    </>
  );
};
