import React, { useCallback } from 'react';
import './style.scss';
import { useDropzone } from 'react-dropzone';
import { openNotification } from 'components/Common';
import cn from 'classnames';

export const Dropzone = ({
  onLoadFile = () => {},
  onAppendFile = () => {},
  tooLargeErrorText = 'The file is too large',
  disabled = false,
  ...rest
}) => {
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      onAppendFile(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        onLoadFile(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const onDropRejected = e => {
    if (!!e[0].errors.find(err => err.code === 'file-too-large')) openNotification('', tooLargeErrorText);
    if (!!e[0].errors.find(err => err.code === 'file-invalid-type'))
      openNotification('', 'The file format is not supported');
    if (!!e[0].errors.find(err => err.code === 'too-many-files'))
      openNotification('', 'Multiple attachments should be uploaded one by one');
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, onDropRejected, disabled, ...rest });
  return (
    <div {...getRootProps()} className={cn('dropzone', { active: isDragActive, disabled })}>
      <input {...getInputProps()} />
      Drag and drop file or <span className="browse">browse</span> file
      <br />
      to attach
    </div>
  );
};
