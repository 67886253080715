import React, { useState } from 'react';
import './style.scss';
import { WarningFilled } from '@ant-design/icons';
import { Button, Carousel, PreviewSmiles } from '../../../Common';
import { formatSecondsToHours } from '../../../../utils';
import { Notes } from '../../../ListExperiments/Notes/Notes';
import { StatusPriority } from '../../../Experiment/StatusPriority';

export const ViewBatch = ({ data, unschedule, reschedule, premissions, goToBatchDetails }) => {
  const [activeExprt, setActiveExprt] = useState(0);
  const duration = formatSecondsToHours(data?.duration * 60);

  const Title = () => {
    const exprt = data.experiments[activeExprt];
    return (
      <div className="view-batch_slider_title">
        <div className="view-batch_slider_title_name">{exprt?.name}</div>
        <span className="view-batch_slider_title_type">
          {exprt?.process?.type}-{exprt?.process?.numberOfSteps}
        </span>
      </div>
    );
  };

  const onChangeSlider = value => {
    setActiveExprt(value);
  };

  return (
    <div className="view-batch">
      <div className="view-batch_header">
        <span className="view-batch_header_name">
          {data.hasWarnings && <WarningFilled className="view-batch_header_name_icon" />} Batch {data.batchId}
        </span>
        <span className="view-batch_header_duration">
          {duration.hour}h {duration.min}min
        </span>
      </div>
      <div className="view-batch_status">Status: {data.status}</div>
      <div className="view-batch_experiments">{data.experiments.map(i => i.name).join(', ')}</div>
      <div className="view-batch_buttons">
        {premissions.canChangeBatch && (
          <Button secondary onClick={reschedule}>
            Reschedule
          </Button>
        )}
        {premissions.canDeleteBatch && (
          <Button secondary onClick={unschedule}>
            Unbatch
          </Button>
        )}
        <Button secondary onClick={goToBatchDetails}>
          Details
        </Button>
      </div>

      <div className="view-batch_slider">
        <Carousel
          countSlide={data.experiments.length}
          initialSlide={1}
          noTitle
          Title={Title}
          customTitle
          onChange={onChangeSlider}
        >
          {data.experiments.map((i, index) => {
            const durationExpr = formatSecondsToHours(i?.totalTime);
            return (
              <div className="view-batch_slider_item">
                <div className="view-batch_slider_item_row">
                  <span className="view-batch_slider_item_row_title">Project: {i.process.project.name}</span>
                  <StatusPriority priority={i.priority} />
                </div>
                <div className="view-batch_slider_item_row">
                  <span className="view-batch_slider_item_row_title">Process: {i.process.name}</span>
                  <span className="view-batch_slider_item_row_time">
                    {durationExpr.hour}h {durationExpr.min}min
                  </span>
                </div>
                <div className="view-batch_slider_item_row">
                  <span className="view-batch_slider_item_row_title">
                    Compatible with: {i.compatibleWith.map(j => j.name).join(', ')}
                  </span>
                  <Notes count={i.notes.length} />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
