import { useEffect, useState } from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [value]);

  return debouncedValue;
}

export function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      const root = document.querySelector('#root');
      if (ref.current && !ref.current.contains(event.target) && root.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
