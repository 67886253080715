import { objectToGraphqlString, objectWithArrayToString } from '../../utils/common';

export const mutationCreateProject = (mode, data) => {
  const typeAction = `project${data.uuid ? 'Update' : 'Create'}`;
  if (mode === 'send') {
    return `mutation {
  ${typeAction}(input: {${objectToGraphqlString({
      ...data,
    })}}) {
    name
    uuid
    customer
    program
    type
    goals
    errors{
  field
  messages}
  }
}`;
  }
  if (data[typeAction].errors) return { errors: data[typeAction].errors };
  const obj = { ...data[typeAction] };
  return obj;
};

export const mutationUpdateTeamMembers = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
  projectUpdate(input: {${objectToGraphqlString({
    ...data,
  })}}) {
    errors{
  field
  messages}
  }
}`;
  }
  if (data.projectUpdate.errors) return { errors: data.projectUpdate.errors };
  return {};
};

export const mutationUpdateManager = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
  projectUpdate(input: {${objectToGraphqlString({
    ...data,
  })}}) {
    errors{
  field
  messages}
  }
}`;
  }
  if (data.projectUpdate.errors) return { errors: data.projectUpdate.errors };
  return {};
};

export const mutationUpdateStatusProject = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
  projectUpdate(input: {${objectToGraphqlString({
    ...data,
  })}}) {
    status
    errors{
  field
  messages}
  }
}`;
  }
  if (data.projectUpdate.errors) return { errors: data.projectUpdate.errors };
  return {
    status: data.projectUpdate.status,
  };
};

export const mutationUpdateUserRoles = data => `mutation {
    updateUserRoles(input: {${objectToGraphqlString(data)}}) {
    uuid
    roles
    errors {
      field
      messages
    }
  }
}`;

export const mutationUpdateProcessName = (mode, data) => {
  if (mode === 'get') {
    return {
      ...data.updateProcessName,
    };
  }
  return `mutation {
      updateProcessName(input: {${objectWithArrayToString(data)}}) {
      uuid
      name
      errors {
      field
      messages
    }}
  }`;
};

export const mutationUpdateProcess = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
  updateProcess(input: {${objectToGraphqlString({
    ...data,
  })}}) {
  errors{
    messages
    field
   }
   uuid
  }
}`;
  }

  return data.updateProcess;
};

export const mutationPrepareMaterials = (mode, data) => {
  if (mode === 'send') {
    const solutionStructure = data.solutionStructure
      ? `solutionStructure:${JSON.stringify(JSON.stringify(data.solutionStructure))}`
      : '';
    return `mutation { 
  prepareMaterials(input: { ${objectWithArrayToString({ ...data })} }) {
    warnings
    batch
    solutionStructure
    errors
  }
}`;
  }

  return data.UpdateSolutionVolumesMutationPayload;
};

export const mutationUpdateProcessDefinition = (data, validate) => {
  const definition = data.definition ? `definition:${JSON.stringify(JSON.stringify(data.definition))}` : '';
  return `mutation { 
  updateProcessDefinition(input: { uuid:"${data.uuid}",verify: ${validate}, ${definition} }){
    uuid,
    definition,
    errors
    warnings {
      field
      messages
    }
  }
}`;
};

export const mutationCreateExperiment = data => {
  const note = data.note ? `note: { body: "${data.note}"},` : '';
  const purification = data?.purification ? `purification: "${data.purification}"` : 'purification: "%20"';
  const status = data.isSubmitted ? 'status: "Submitted",' : '';
  const timePerReaction = data.timePerReaction ? `timePerReaction: ${data.timePerReaction},` : '';
  const totalTime = data.totalTime ? `totalTime: "${data.totalTime}",` : '';
  const uuid = data.uuid ? `uuid: "${data.uuid}",` : '';
  const analyticalSetting = data.analyticalSetting
    ? `analyticalSetting: ${JSON.stringify(data.analyticalSetting)},`
    : uuid
    ? 'analyticalSetting: "[]",'
    : '';
  return `mutation { 
  createExperiment (input: {
      ${uuid}
      name: "${data.name}",
      process: "${data.process}",
      analyticalType: ${data.analyticalType},
      priority: "${data.priority}",
      reactionsInfo: ${JSON.stringify(data.reactionsInfo)},
      enableVialCopying: ${data.enableVialCoping},
      ${analyticalSetting}
      ${note}
      ${purification}
      ${status}
      ${timePerReaction}
      ${totalTime}
  }){
  uuid
  key
    name
    process
    errors 
  }
}`;
};

export const mutationUpdateTheLockState = data => `mutation { 
  updateProcessLock(input:{uuid:"${data.uuid}",state:"${data.state}"}){
  uuid
  errors {
    field
    messages
  }
  }
}`;

export const mutationCalculateEstimatedTime = data => {
  const timePerReaction = data.timePerReaction ? `timePerReaction: ${data.timePerReaction},` : '';
  return `mutation {
  calculateExperimentTotalTime(
    input: {
      process: "${data.process}"
      reactionsInfo: ${JSON.stringify(data.reactionsInfo)}
      ${timePerReaction}
    }) {
    totalTime
    errors
  }
}`;
};

export const mutationCreateProcess = (mode, data) => {
  if (mode === 'get') {
    const { createProcess } = data;
    const { cloneProcess } = data;
    if (createProcess) {
      if (createProcess.errors) {
        return { errors: createProcess.errors };
      }

      return {
        ...createProcess,
      };
    }
    if (cloneProcess) {
      if (cloneProcess.errors) {
        return { errors: cloneProcess.errors };
      }

      return {
        ...cloneProcess,
      };
    }
  } else if (mode === 'send') {
    return `mutation {
      createProcess(input: {${objectToGraphqlString(data)}}) {
        uuid
        name
        type
        numberOfSteps
        variableStep
        enableQuenching
        errors{
          field
          messages
        } 
      }
    }
    `;
  } else if (mode === 'clone') {
    return `mutation {
      cloneProcess(input:{${objectToGraphqlString({
        process: data.uuid,
        name: data.name,
        project: data.project,
      })}}) {
            uuid
        errors{
        field
        messages
        }
      }
    }
    `;
  }
};

export const mutationManageExperimentOnSchedule = (mode, data, type) => {
  if (mode === 'send') {
    let errors = '';
    if (type === 'unscheduleExperiment') {
      errors = `{messages 
    field}`;
    }

    return `mutation {
      ${type}(input: {${objectToGraphqlString(data)}}) {
        uuid
        errors ${errors}
        }
    }
    `;
  }
  return data[type];
};

export const mutationManageMaintenanceOnSchedule = (mode, data, type) => {
  if (mode === 'send') {
    let errors = '';
    let uuid = 'uuid';
    if (type === 'deleteMaintenance') {
      delete data.description;
      errors = `{messages 
    field}`;
    }
    if (type === 'createMaintenance') uuid = '';
    return `mutation {
      ${type}(input: {${objectToGraphqlString(data)}}) {
        ${uuid}
        errors ${errors}
        }
    }
    `;
  }
  return data[type];
};

export const mutationUpdateNote = (mode, data) => `mutation {
  updateNote(input: {
  isDeleted: ${data.isDeleted},uuid: "${data.uuid}"}) {
    isDeleted
    uuid
    errors {
      field
      messages
    }
  }
}`;

export const mutationCreateNote = (mode, data) => `mutation {
  createNote(input: {${objectToGraphqlString({
    ...data,
  })}}) {
    uuid
    reason
    body
    experiment
    isDeleted
    errors {
      field
      messages
    }
  }
}`;

export const mutationPrepareExperiment = experimentID => `mutation {
    prepareExperiment(input: {
      uuid: "${experimentID}"
    }) {
      uuid
      key
      errors {
        field
        messages
      }
    }
  }`;

export const mutationExperimentPumpKeys = (experimentID, pumpList) => `mutation{
  updatePumpKey(input:{uuid: "${experimentID}", pumpKeys: [${pumpList
  .map(i => `{${objectToGraphqlString(i)}}`)
  .join(',')}]}) {
    uuid
  errors
    clientMutationId
  }
  
}`;

export const mutationRejectExperiment = (experimentID, data) => `mutation {
  rejectExperiment(
    input: {
      uuid: "${experimentID}"
      note: {
        body: "${data.note}",
        reason: "${data.reason}"
      }
    }
  ) {
    uuid
    errors
  }
}`;

export const mutationUpdateReactorMaterial = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  updateReactorMaterial(
    input: {${objectToGraphqlString(data)}}
  ) {
    uuid
    errors {
      field
      messages
    }
  }
}`;
  }
  return data.updateReactorMaterial;
};

export const mutationUpdatePumpBottle = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  updatePumpBottle(
    input: {${objectToGraphqlString(data)}}
  ) {
    uuid
    errors {
      field
      messages
    }
  }
}`;
  }
  return data.updatePumpBottle;
};

export const mutationUpdatePumpMaterial = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  updatePumpMaterial(
    input: {${objectToGraphqlString(data)}}
  ) {
    uuid
    errors {
      field
      messages
    }
  }
}`;
  }
  return data.updatePumpMaterial;
};

export const mutationFinishMaterialsPreparation = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  finishMaterialsPreparation(
    input: {${objectToGraphqlString(data)}}
  ) {
    uuid
    errors
  }
}`;
  }
  return data.finishMaterialsPreparation;
};

export const mutationCheckList = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
  performExperiment(input: {uuid: "${data}"}){
    uuid
    errors{
      field
      messages
    }
  }
}`;
  }
  return data.performExperiment;
};

export const mutationLaunchExpLab = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
  launchExperiment(input: {uuid: "${data}"}){
    uuid
    errors{
      field
      messages
    }
  }
}`;
  }
  return data.launchExperiment;
};

export const mutationSubmitExperiment = experimentID => `mutation {
    submitExperiment(input: {
      uuid: "${experimentID}"
    }) {
      uuid
      errors
    }
  }`;

export const mutationSaveSynjetProcess = (id, data, validate) => {
  const definition = `definition:${JSON.stringify(JSON.stringify(data))}`;
  return `mutation { 
  updateProcessDefinition(input: { uuid:"${id}",verify: ${validate}, ${definition} }){
  uuid,
  definition,
  errors
  warnings {
    field
    messages
  }
  }
}`;
};

export const mutationCreateBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  createBatch(
    input: {${objectToGraphqlString(data)}}
  ) {
    errors
  }
}`;
  }
  return data.createBatch;
};
export const mutationAddExperimentToBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  addExperimentIntoBatch(
    input: {${objectToGraphqlString(data)}}
  ) {
    uuid
    errors
  }
}`;
  }
  return data.addExperimentIntoBatch;
};
export const mutationDeleteExperimentFromBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  removeExperimentFromBatch(
    input: {${objectToGraphqlString(data)}}
  ) {
    uuid
    errors
  }
}`;
  }
  return data.removeExperimentFromBatch;
};

export const mutationRecalculateDispensers = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
      recalculateDispensers(
        input: { batch: "${data.batchId}" }
      ) {
        batch
        errors
      }
    }`;
  }
  return data.recalculateDispensers;
};

export const mutationUpdateBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
  rescheduleBatch(
    input: {${objectToGraphqlString(data)}}
  ) {
    uuid
    errors
  }
}`;
  }
  return data.rescheduleBatch;
};

export const mutationUnBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
    unscheduleBatch(input: {${objectToGraphqlString(data)}}) 
      {
        uuid
      }
    }`;
  }
  return data.unscheduleBatch;
};

export const mutationCompleteExperiment = experimentID => `mutation {
    completeExperiment(input: {
      uuid: "${experimentID}"
    }) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;

export const mutationCreationRoute = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
    experimentRouteCreate(input: {
      experiment: "${data.uuid}",
      data: ${data.data}
    }) {
      uuid
      errors
    }
  }`;
  }
  return data.experimentRouteCreate;
};

export const mutationEndExperiment = experimentID => `mutation {
    endExperiment(input: {
      uuid: "${experimentID}"
    }) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;

export const mutationAbortExperiment = experimentID => `mutation {
    abortExperiment (input: {
      uuid: "${experimentID}"
    }) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;

export const mutationInProgressExperiment = experimentID => `mutation {
    inProgressExperiment(input: {
      uuid: "${experimentID}"
    }) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;

export const mutationPausedExperiment = experimentID => `mutation {
    pausedExperiment(input: {
      uuid: "${experimentID}"
    }) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;

export const mutationExperimentResult = (mode, data, isSynGet) => {
  if (mode === 'send') {
    if (isSynGet) {
      return `mutation {
      saveExperimentResult(input: {
      experiment: "${data.experiment}",
          notes: "${data.notes}",
          attachments: ${JSON.stringify(data.attachments)}
      }) {
        uuid
        errors
      }
    }`;
    }
    const purificationType = data.purificationType ? `purificationType: "${data.purificationType}",` : '';
    return `mutation {
      saveExperimentResult(input: {
          experiment: "${data.experiment}",
          ${data.workup ? `workup: "${data.workup}",` : ''}
          ${purificationType}
          ${data.purificationDescription ? `purificationDescription: "${data.purificationDescription}",` : ''}
          productsInfo: ${JSON.stringify(JSON.stringify(data.productsInfo))},
          attachments: ${JSON.stringify(data.attachments)}
      }) {
        uuid
        errors
      }
    }`;
  }
  return data.saveExperimentResult;
};

export const mutationFinalizeExperiment = experimentID => `mutation {
  finalizeExperiment(input: {
      uuid: "${experimentID}",
    }) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;

export const mutationStartPreparation = batchID => `mutation {
  batchStartPreparation(
    input: {
      uuid: "${batchID}"
    }
  ) {
    uuid
    errors
    warnings {
      field,
      messages
    }
  }
}`;

export const mutationCalculateVialsSchedule = (mode = 'send', data) => {
  if (mode === 'send') {
    return `mutation {
      calculateBatchConfiguration(
        input: {${objectToGraphqlString(data)}}) {
        uuid
        batchConfiguration
        errors {
          field
          messages
        }
        warnings {
          field
          messages
        }
      }
    }`;
  }
  return data.calculateBatchConfiguration;
};

export const mutationSaveVialsSchedule = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
    saveBatchConfiguration(
      input: {
        uuid: "${data}"
      }) {
      uuid
      warnings {
        field
        messages
      }
    }
  }`;
  }
  return data.saveBatchConfiguration;
};

export const mutationLaunchBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
    launchBatch(
      input: {
        uuid: "${data}"
      }) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;
  }
  return data.launchBatch;
};

export const mutationRejectExperimentFromBatch = (batchId, experimentId) => `mutation {
  batchExperimentReject(
    input: {
      uuid: "${batchId}"
      experiment: "${experimentId}"
    }
  ) {
    uuid
    errors {
      field
      messages
    }
  }
}`;

export const mutationRejectBatch = batchId => `mutation {
  batchReject(
    input: {
      uuid: "${batchId}"
    }
  ) {
    uuid
  }
}`;

export const mutationMoveToBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
    moveExperimentBetweenBatch(
    input: {
    uuid: "${data.uuid}"
    experiment: "${data.experiment}"
    toBatch: "${data.toBatchUUID}"
    }
    ) {
    uuid
    errors {
    field
    messages
    }
    }
    }`;
  }
  return data.moveExperimentBetweenBatch;
};

export const mutationUpdateExperimentConditions = data => {
  const experiments = data.experiments
    ? `experiments: [${data.experiments.map(
        experiment =>
          `{
          experiment: "${experiment.experiment}",
          reactionsInfo: ${JSON.stringify(JSON.stringify(experiment.reactionsInfo))}
        }`
      )}
  ]`
    : '';
  return `mutation {
  updateExperimentConditions(
    input: {
      batch: "${data.batchID}",
      ${experiments}
    }
  ) {
    batch
    errors
    warnings {
      field
      messages
    }
  }
}`;
};

export const mutationSJPUpdateExperimentConditions = data => {
  const experiments = data.experiments
    ? `experiments: [${data.experiments.map(
        experiment =>
          `{
          experiment: "${experiment.experiment}",
          reactionsInfo: ${JSON.stringify(JSON.stringify(experiment.reactionsInfo))}
        }`
      )}
  ]`
    : '';

  const heatingBlocks = data.heatingBlocks
    ? `heatingBlocks: [${data.heatingBlocks.map(
        block => `{label: ${block.label}, ${block.temperature ? `temperature: ${block.temperature}` : ''}}`
      )}]`
    : '';

  return `mutation {
  updateSjpExperimentConditions(
    input: {
      batch: "${data.batchID}",
      ${experiments}
      ${heatingBlocks}
    }
  ) {
    batch
    errors
    warnings {
      field
      messages
    }
  }
}`;
};

export const mutationUpdateBatchDispenser = data => {
  const mutation = data.isPro ? 'updateSjpBatchDispenser' : 'updateBatchDispenser';
  const materials = data.materials ? `materials: {${objectToGraphqlString(data.materials)}},` : '';
  const calibration = data.calibration ? `calibration: ${data.calibration},` : '';

  const experiments = data.experiments
    ? `experiments: [
       ${data.experiments.map(experiment =>
         experiment?.isNormalized
           ? `{
                  experiment: "${experiment?.experiment}",
                  ${
                    experiment.reactionsInfo
                      ? `reactionsInfo: ${JSON.stringify(JSON.stringify(experiment.reactionsInfo))},`
                      : ''
                  }
                  isNormalized: ${experiment?.isNormalized},
                  ${
                    data.isPro
                      ? `calculatedVolume: ${experiment.calculatedVolume}, isManually: ${experiment.isManually}`
                      : ''
                  }
                  solvents: [
                      ${experiment?.solvents.map(
                        solvent =>
                          `{
                            compoundId: "${solvent.compoundId}",
                            fraction: ${solvent.fraction},
                          }`
                      )}
              ],
                }`
           : `{
                experiment: "${experiment?.experiment}",
                 ${
                   experiment.reactionsInfo
                     ? `reactionsInfo: ${JSON.stringify(JSON.stringify(experiment.reactionsInfo))},`
                     : ''
                 }
                compoundId: "${experiment.compoundId}",
                compoundName: "${experiment?.compoundName}",
                isNormalized: ${experiment?.isNormalized},
                ${
                  data.isPro
                    ? `calculatedVolume: ${experiment.calculatedVolume}, isManually: ${experiment.isManually}`
                    : ''
                }
                ${
                  experiment?.solvents
                    ? `solvents: [
                  ${experiment?.solvents.map(
                    solvent =>
                      `{
                      compoundId: "${solvent.compoundId}",
                      fraction: ${solvent.fraction},
                  
                    }`
                  )}
              ]`
                    : ''
                },
        },`
       )}
    ],`
    : '';

  const reservoirVolume = data.isPro && data.reservoirVolume ? `reservoirVolume: "${data.reservoirVolume}"` : '';

  return `mutation {
    ${mutation}(
      input: {
        batch: "${data.batch}",
        dispenserUuid: "${data.dispenser}",
        ${calibration},
        ${materials}
        ${experiments}
        ${reservoirVolume}
      }) {
      batch
      warnings{
        field
        messages
      }
      errors
    }
  }`;
};

export const mutationCompleteBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
    completeBatch(
      input: {
        uuid: "${data}"
      }
    ) {
      uuid
      errors {
        field
        messages
      }
    }
  }`;
  }

  return data.completeBatch;
};

export const mutationPauseBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation{
      pausedBatch(input:{uuid: "${data}"}){
        uuid
        errors{
          field
          messages
        }
      }
    }`;
  }

  return data.pausedBatch;
};
export const mutationResumeBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation{
      resumeBatch(input:{uuid: "${data}"}){
        uuid
        errors{
          field
          messages
        }
      }
    }`;
  }

  return data.resumeBatch;
};
export const mutationEndBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation{
      endBatch(input:{uuid: "${data}"}){
        uuid
        errors{
          field
          messages
        }
      }
    }`;
  }

  return data.endBatch;
};

export const mutationFinalizeBatch = (mode, data) => {
  if (mode === 'send') {
    return `mutation{
      finalizeBatch(input:{uuid: "${data}"}){
        uuid
        errors{
          field
          messages
        }
      }
    }`;
  }
  return data.finalizeBatch;
};

export const mutationFinalizeBatchExperiment = (mode, data) => {
  if (mode === 'send') {
    return `mutation{
      completeBatchExperiment(input:{uuid: "${data}"}){
        uuid
        errors{
          field
          messages
        }
      }
    }`;
  }

  return data.completeBatchExperiment;
};

export const mutationUpdateDispenserMaterials = (mode, data) => {
  if (mode === 'sent') {
    return `mutation { 
  updateDispenserVolume(input: {${objectToGraphqlString({
    ...data,
  })}}){
  errors {
      field
      messages
    }
  }
}`;
  }

  return data.updateDispenserVolume;
};

export const mutationUpdateSjpSolidVials = (mode, data) => {
  if (mode === 'sent') {
    const solidVials = data
      ? `
     batch: "${data.batch}",
     solidUuid: "${data.solidUuid}",
     vials: [${data.vials.map(
       i => `{
       name: "${i.name}",
       actualMass: ${i.actualMass},
       error: ${i.error}
       }`
     )}]
  `
      : '';

    return `mutation { 
  updateSjpSolidVials(input: {${solidVials}}){
  errors
  }
}`;
  }

  return data.updateSjpSolidVials;
};

export const mutationUpdateInputMaterials = (mode, data) => {
  if (mode === 'sent') {
    return `mutation { 
  updateInputMaterial(input: {${objectToGraphqlString({
    ...data,
  })}}){
  uuid
  errors
  }
}`;
  }

  return data.updateInputMaterial;
};

export const mutationDestroyResultAttachment = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
      destroyResultAttachment(input: {
        uuid: "${data.uuid}"
      }) {
        errors {
          field
          messages
        }
      }
    }`;
  }
  return data.destroyResultAttachment;
};

export const mutationAddProjectTargetCompound = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
      addProjectTargetCompound(input: {
        uuid: "${data.uuid}",
        targetCompound: ${JSON.stringify(JSON.stringify(data.targetCompound))},
      }) {
        uuid
        targetCompound
        errors {
          field
            messages
        }
      }
    }`;
  }
  return data.addProjectTargetCompound;
};

export const mutationDeleteEmptyRoute = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
      destroyProjectRoute(input: {
        uuid: "${data.uuid}"
      }) {
        errors {
          field
          messages
        }
      }
    }`;
  }
  return data;
};

export const mutationGetAvailableAutoSyns = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
      checkAvailableDevices (input: {
          process: "${data.processId}",
          analyticalType: ${data.analyticalType},
      }){
      availableDevices
      errors
      }
    }`;
  }
  return data.checkAvailableDevices;
};

export const mutationUpdateSJPExperimentsReactionsInfo = (mode, data) => {
  if (mode === 'send') {
    return `mutation {
      updateSjpExperimentReactionsInfo(input: {
        batch: "${data.batch}",
        experiments: [
          ${data.experiments.map(
            exp => ` {
              experiment: "${exp.experiment}",
              reactionsInfo: ${JSON.stringify(JSON.stringify(exp.reactionsInfo))}
            }
            `
          )}
        ],
      }) {
        batch,
        errors
      }
    }`;
  }

  return data.updateSjpExperimentReactionsInfo;
};

export const mutationSJProLaunchBatch = (mode, data) => {
  if (mode === 'sent') {
    return `mutation {
      sjpLaunchBatch(input:{
        uuid: "${data.uuid}",
        finishPlates: [
            { plateLabel: TRAY_1, plateValue: "${data.finishPlates[0].plateValue}" },
            { plateLabel: TRAY_2, plateValue: "${data.finishPlates[1].plateValue}" },
  ]
      }){
        errors {
          field
          messages
        }
      }
    }`;
  }

  return data.sjpLaunchBatch;
};

export const mutationExtensibleTableField = (data, action, mode) => {
  const uuid = data.uuid ? `uuid: "${data.uuid}",` : '';
  const dataType = action == 'create' ? `dataType: "${data.dataType}"` : '';
  const clientMutationId = action == 'create' ? `clientMutationId: "${data?.clientMutationId}"` : '';
  const defaultValue = !data?.systemic ? `defaultValue: ${JSON.stringify(JSON.stringify(data.defaultValue))}` : '';
  const availableOptions = !data?.systemic
    ? `availableOptions: ${JSON.stringify(JSON.stringify(data.availableOptions))}`
    : '';
  const hint = !data?.systemic ? `hint: "${data.hint}"` : '';
  if (mode === 'send') {
    return `mutation { 
    ${action == 'create' ? 'createExtensibleTableField' : 'updateExtensibleTableField'} (input: {
      ${uuid}
      name: "${data.name}"
      ${dataType}
      unique: ${data.unique}
      editable: ${data.editable}
      visibleInDetails: ${data.visibleInDetails}
      addUpdatesToHistory:${data.addUpdatesToHistory}
      ${availableOptions}
      ${defaultValue}
      ${hint}
      createdBy:""
      tableName:""
      ${clientMutationId}
  }){
  errors{
    field
    messages
  }
  }
}`;
  }
  return action == 'create' ? data.createExtensibleTableField : data.updateExtensibleTableField;
};

export const mutationArchiveExtensibleTableField = (data, mode) => {
  if (mode == 'send') {
    return `mutation { 
      archiveExtensibleTableField (input: {
        uuid: "${data.uuid}"
      archived:${data.archived}
    }){
    uuid
    archived
    }
  }`;
  }
  return data.archiveExtensibleTableField;
};

export const mutationSaveExtensibleTableGrid = (mode, data) => {
  if (mode === 'send') {
    return `mutation { 
  saveExtensibleTableGrid(input: { grid: ${JSON.stringify(JSON.stringify(data))} }){
      grid
      errors {
        messages
        field
      }
    }
  }`;
  }
  return data.saveExtensibleTableGrid;
};

export const mutationUpdateExtensibleExperiment = (mode, data, uuid) => {
  if (mode === 'send') {
    return `mutation { 
  updateExtensibleExperiment(input: { uuid: "${uuid}", data: ${JSON.stringify(JSON.stringify(data))} }){
      uuid
      errors {
        messages
        field
      }
    }
  }`;
  }
  return data.updateExtensibleExperiment;
};
