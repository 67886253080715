import './styles.scss';

import React, { useMemo, useState } from 'react';

import { useDebounce } from 'hooks/useDebounce';

import { Popover } from '@atoms';

import { ListWithSearchBase } from './ListWithSearchBase';

export const ListWithSearchApi = ({ defaultItems, selectedItems, itemActions, getQuery, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState(defaultItems || []);
  useDebounce({
    action: async () => {
      setIsLoading(true);
      try {
        const resp = await getQuery({ search: search?.trim() });
        setItems(resp);
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    },
    delay: 600,
    deps: [search],
  });

  const itemsWithSelectedField = useMemo(
    () =>
      items.map(item => ({
        ...item,
        selected: !!selectedItems.find(selectedItem => selectedItem.uuid === item.value.uuid),
      })),
    [selectedItems, items]
  );

  return (
    <ListWithSearchBase
      isLoading={isLoading}
      itemActions={itemActions}
      options={itemsWithSelectedField}
      search={search}
      onSearch={setSearch}
      {...props}
    />
  );
};

export const PopoverListWithSearchApi = ({ popoverProps, children, listProps }) => (
  <Popover
    destroyTooltipOnHide
    overlayClassName="popover-list-with-search-overlay"
    trigger="click"
    content={<ListWithSearchApi {...listProps} />}
    {...popoverProps}
  >
    <div>{children}</div>
  </Popover>
);
