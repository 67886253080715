import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Popup, Spinner } from '../../../Common';
import { ProcessPreviewData } from './ProcessPreviewData';
import { getProcess, resetSynJetStore } from '../../../../store/synjet/synjet.actions';

export const SynjetViewProcessPopup = ({ data, open, handleCancel, isPro }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState(null);

  useEffect(() => {
    setLoading(true);
    dispatch(getProcess(data.uuid)).then(_process => {
      setProcess(_process.process);
      setLoading(false);
    });
  }, [data.uuid]);

  useEffect(() => () => dispatch(resetSynJetStore()), []);

  const isEmpty = !process?.definition;

  return (
    <Popup
      title={data.name}
      open={open}
      closable
      className="popup-process-preview synjet-popup"
      textCancle="Close"
      handleCancel={handleCancel}
      loading={false}
    >
      <div className="popup-process-preview_content">
        <span className="popup-process-preview_content_project">
          Project:
          {data.project}
        </span>
        <br />
        {!!data.type && (
          <span className="popup-process-preview_content_project">
            Type: {data.type} ({process?.numberOfSteps}
            -Step)
          </span>
        )}
        <div className={cn('popup-process-preview_workspace-container', { empty: isEmpty })}>
          <Spinner loading={loading}>
            {!isEmpty ? (
              loading ? null : (
                <ProcessPreviewData processType={data.type} isPro={isPro} />
              )
            ) : loading ? null : (
              <span className="empty-state">No process yet</span>
            )}
          </Spinner>
        </div>
      </div>
    </Popup>
  );
};
