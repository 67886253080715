import React from 'react';
import cn from 'classnames';
import { GLASSWARE_TYPES } from 'constants/manualProcess';

export const Glassware = ({ noArrow, data, openEditMode }) => {
  const typeConfig = GLASSWARE_TYPES.glassware;
  const Icon = typeConfig.icon;

  return (
    <div
      className={cn('reactor-container-wrapper glassware', {
        'no-arrow': noArrow,
      })}
      onClick={openEditMode}
    >
      <div className={cn('reactor-container')}>
        <div className="reactor-left-block">
          <div className={cn('reactor-left-block-name-block')}>
            <Icon />
            <div>
              <span className={cn('reactor-left-block-name-block_name')}>
                {data.type === 'other' ? data.otherType : data.type}
              </span>
              <br />
              <span className={cn('reactor-left-block-name-block_data')}>{data.size} ml</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
