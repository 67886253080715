import React, { useEffect, useState } from 'react';

import { Switch } from 'antd';

import { DISPLAY_OPTIONS, PROJECT_TYPES } from '../../../constants';
import { ReactionsTable } from '../../ProcessBuilder/ReactionsTable/ReactionsTable';
import { SynJetExperimentTable } from '../../SynJet/SynJetProccess/SynJetExperimentTable/SynJetExperimentTable';
import { ChooseExperimentAnalytical } from './ChooseExperimentAnalytical';
import { ChooseProcessForm } from './ChooseProcessForm';
import { CreateExperimentForm } from './CreateExperimentForm';

export const CreateExperimentModalBody = ({
  process,
  experimentStep,
  showProjectForm,
  isProduction,
  steps,
  experimentData,
  availableAnalytics,
  priorities,
  errors,
  skipReactionStep,
  experimentCreationLoading,
  submitModalOpen,
  processID,
  project,
  setProcessID,
  setProject,
  reactors,
  setErrors,
  reactions,
  columns,
  setReactions,
  setExperimentData,
  processData,
  setDefaultAnalyticalData,
  defaultAnalyticalData,
  isSynjet,
  handleExperimentTimeCalculation,
  moveBack,
  experiment,
  isLab,
  isSynjetPro,
  vialsCopies,
  setVialsCopies,
  errorCopiedVials,
  setErrorCopiedVials,
  initialExpRender,
  processInstrument,
  setProcessInstrument,
  chooseInstrument,
}) => {
  const [reactionsIndex, setReactionsIndex] = useState([0]);

  useEffect(() => {
    const updatedReactionsIndex = skipReactionStep
      ? [0]
      : experimentData.reactionsInfo
      ? Object.keys(experimentData.reactionsInfo)
      : [];
    setReactionsIndex(updatedReactionsIndex);
  }, [skipReactionStep, experimentData.reactionsInfo]);

  const handleAllowVialsCopingChange = value => {
    const vialsKeys = Object.keys(vialsCopies);
    let newVialsCopies = vialsCopies;
    if (value && !vialsKeys.length) {
      newVialsCopies = reactions.reduce((copies, _, index) => ({ ...copies, [index]: 1 }), {});
    }
    setVialsCopies(newVialsCopies);
    setExperimentData({
      ...experimentData,
      allowVialsCopy: value,
    });
  };

  const handleVialCopyChange = (index, value) => {
    setErrorCopiedVials(errorCopiedVials.filter(errorIndex => index !== errorIndex));
    setVialsCopies({
      ...vialsCopies,
      [index]: value,
    });
  };

  const handleFormChange = (value, field, errorField, reactionIndex, stepIndex) => {
    if (errorField) {
      if (errors[field]) {
        const newErrors = { ...errors };
        const reactionIndexErrors = errors[field] && errors[field][reactionIndex] ? errors[field][reactionIndex] : {};
        const stepIndexErrors = reactionIndexErrors[errorField] ? reactionIndexErrors[errorField] : [];
        newErrors[field] = {
          ...newErrors[field],
          [reactionIndex]: {
            ...reactionIndexErrors,
            [errorField]:
              stepIndex || stepIndex === 0
                ? stepIndexErrors.map((error, index) => (index === stepIndex ? null : error))
                : null,
          },
        };
        setErrors(newErrors);
      }
    } else if (field === 'useAnalytic') {
      setErrors({
        ...errors,
        analytics: null,
      });
    } else if (field === 'timePerReactionHour' || field === 'timePerReactionMin' || field === 'timePerReactionSec') {
      if (value) {
        setErrors({
          ...errors,
          timePerReactionHour: null,
          timePerReactionMin: null,
          timePerReactionSec: null,
        });
      }
    } else {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
    setExperimentData({
      ...experimentData,
      [field]: value,
    });
  };

  return (
    <>
      {experimentStep === 0 && (
        <>
          {!isSynjet &&
          !isSynjetPro &&
          !showProjectForm &&
          (isProduction || (!isProduction && reactions?.length < 2)) ? (
            <CreateExperimentForm
              process={process}
              steps={steps}
              handleFormChange={handleFormChange}
              handleExperimentTimeCalculation={handleExperimentTimeCalculation}
              experimentData={experimentData}
              analytics={availableAnalytics}
              priorities={priorities}
              errors={errors}
              reactions={reactionsIndex}
              loading={experimentCreationLoading || submitModalOpen}
              setErrors={setErrors}
              isEdit={!!experiment}
              isLab={isLab}
              experimentStep={experimentStep}
            />
          ) : (
            <>
              {showProjectForm && (
                <ChooseProcessForm
                  processInstrument={processInstrument}
                  setProcessInstrument={setProcessInstrument}
                  chooseInstrument={chooseInstrument}
                  process={process}
                  processID={processID}
                  project={project}
                  setProcessID={setProcessID}
                  setProject={setProject}
                  errors={errors}
                  setErrors={setErrors}
                  reactors={reactors}
                  reactions={reactions}
                  isSynjet={isSynjet}
                  isLab={isLab}
                  isSynjetPro={isSynjetPro}
                />
              )}
              {isSynjetPro && (
                <div className="vials-copying-container">
                  <Switch checked={experimentData.allowVialsCopy} onChange={handleAllowVialsCopingChange} />
                  <span className="vials-label">Allow vials copying</span>
                </div>
              )}
              {isSynjet || isSynjetPro ? (
                processData?.experiments?.process_steps?.[0]?.step?.vials?.length ? (
                  <SynJetExperimentTable
                    experimentReactionInfo={experiment ? JSON.parse(experiment.reactionsInfo) : null}
                    experiments={processData.experiments}
                    processType={process?.process?.type}
                    processSteps={processData.processSteps}
                    previewMode
                    displayOption={DISPLAY_OPTIONS.equivalent.label}
                    showCheckboxes
                    setReactions={setReactions}
                    selectedReactions={reactions}
                    moveBack={moveBack}
                    isQuenchingEnabled={process?.process?.enableQuenching}
                    allowVialsCopy={
                      experiment && initialExpRender ? experiment.enableVialCopying : experimentData.allowVialsCopy
                    }
                    vialsCopies={vialsCopies}
                    handleVialCopyChange={handleVialCopyChange}
                    setVialsCopies={setVialsCopies}
                    errorCopiedVials={errorCopiedVials}
                  />
                ) : (
                  <div />
                )
              ) : (
                <>
                  {process && process?.type !== PROJECT_TYPES.PRODUCTION && reactions?.length >= 2 && (
                    <ReactionsTable
                      reactions={reactions}
                      showCheckboxes
                      columns={columns}
                      setReactions={setReactions}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {experimentStep === 1 && (
        <>
          {(process?.type !== PROJECT_TYPES.PRODUCTION && reactions?.length >= 2) ||
          showProjectForm ||
          isSynjet ||
          isSynjetPro ? (
            <CreateExperimentForm
              process={process}
              steps={steps}
              handleFormChange={handleFormChange}
              handleExperimentTimeCalculation={handleExperimentTimeCalculation}
              experimentData={experimentData}
              analytics={availableAnalytics}
              priorities={priorities}
              errors={errors}
              reactions={reactionsIndex}
              loading={experimentCreationLoading || submitModalOpen}
              isSynjet={isSynjet}
              isSynjetPro={isSynjetPro}
              isEdit={!!experiment}
              isLab={isLab}
              experimentStep={experimentStep}
            />
          ) : (
            <>
              <ChooseExperimentAnalytical
                analytical={experimentData.analytics}
                processData={processData}
                reactions={reactionsIndex}
                experimentData={experimentData}
                handleFormChange={handleFormChange}
                processType={isSynjet || isSynjetPro ? process?.process?.type : process?.type}
                setDefaultAnalyticalData={setDefaultAnalyticalData}
                defaultAnalyticalData={defaultAnalyticalData}
                experiment={experiment}
                errors={errors}
                isSynjet={isSynjet || isSynjetPro}
                isSynjetPro={isSynjetPro}
                setErrors={setErrors}
                loading={experimentCreationLoading || submitModalOpen}
              />
            </>
          )}
        </>
      )}
      {experimentStep === 2 && (
        <ChooseExperimentAnalytical
          analytical={experimentData.analytics}
          processData={processData}
          reactions={reactionsIndex}
          experimentData={experimentData}
          handleFormChange={handleFormChange}
          processType={isSynjet || isSynjetPro ? process?.process?.type : process?.type}
          setDefaultAnalyticalData={setDefaultAnalyticalData}
          defaultAnalyticalData={defaultAnalyticalData}
          analyticalData={experimentData.analyticalData}
          editMode={!!experimentData.experiment}
          errors={errors}
          experiment={experiment}
          setErrors={setErrors}
          isSynjet={isSynjet || isSynjetPro}
          isSynjetPro={isSynjetPro}
          loading={experimentCreationLoading || submitModalOpen}
        />
      )}
    </>
  );
};
