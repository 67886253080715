import React from 'react';
import { Popup } from 'components/Common';

export const Confirmations = ({
  cancelConfirmation,
  openCancelConfirmation,
  deleteConfirmation,
  openDeleteConfirmation,
  onDelete,
  onClose,
}) => (
  <>
    <Popup
      open={cancelConfirmation}
      title="Warning"
      handleSubmit={() => {
        onClose();
        openCancelConfirmation(false);
      }}
      handleCancel={() => {
        openCancelConfirmation(false);
      }}
      textSubmit="Proceed"
      loading={false}
    >
      <span>All changes will be lost, proceed?</span>
    </Popup>
    <Popup
      open={deleteConfirmation}
      title="Warning"
      handleSubmit={() => {
        onDelete();
        openDeleteConfirmation(false);
        onClose();
      }}
      handleCancel={() => {
        openDeleteConfirmation(false);
      }}
      textSubmit="Delete"
      loading={false}
    >
      <span>Do you want to Delete this option?</span>
    </Popup>
  </>
);
