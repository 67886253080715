import React, { useEffect, useState } from 'react';
import './style.scss';

// SVG for types
import cn from 'classnames';
import { ReactComponent as NotUsedSVG } from '../../../../../dist/images/NotUsedType.svg';
import { ReactComponent as NotReadySVG } from '../../../../../dist/images/NeedToFillType.svg';
import { ReactComponent as ReadySVG } from '../../../../../dist/images/FilledType.svg';

import VialPlate from './VialPlate';
import { getExperimentContainerStyle } from '../../../../../utils/execution';
import { WebsocketService } from '../../../../../utils/service/WebSocketService';

const FinishPlate = ({ batch, experimentNames = [], plateVialsData, getProduct }) => {
  const [experimentInfoSubject, setExperimentInfoSubject] = useState(null);
  const [updatedVial, setUpdatedVial] = useState({});

  const updateVial = msg => {
    setUpdatedVial(msg.data);
  };

  useEffect(() => {
    if (experimentInfoSubject) experimentInfoSubject.unsubscribe();
    const newSubject = new WebsocketService(`/batch-execution/${batch?.uuid}/final-plate/`);
    setExperimentInfoSubject(newSubject);
  }, []);

  useEffect(() => {
    if (experimentInfoSubject && experimentInfoSubject?.reconnect) {
      experimentInfoSubject.subscribe(updateVial);
    }

    return () => {
      if (experimentInfoSubject) {
        experimentInfoSubject.unsubscribe();
      }
    };
  }, [experimentInfoSubject]);

  const getExpRow = (exp, index) => (
    <div className="finish-plate_aside-row">
      <div className={cn('finish-plate_aside__experiments-circle', getExperimentContainerStyle(index))} />
      <div className="sjp-finish-plate_text">{exp}</div>
    </div>
  );

  return (
    <div className="sjp-finish-plate">
      <div className="finish-plate_aside">
        <div className="finish-plate_aside__vials">
          <span className="sjp-finish-plate_extra-text">Vials</span>
          <div className="finish-plate_aside-row">
            <NotUsedSVG />
            <span className="sjp-finish-plate_text">Not used</span>
          </div>
          <div className="finish-plate_aside-row">
            <NotReadySVG />
            <span className="sjp-finish-plate_text">Not ready</span>
          </div>
          <div className="finish-plate_aside-row">
            <ReadySVG />
            <span className="sjp-finish-plate_text">Ready</span>
          </div>
        </div>
        <div className="finish-plate_aside__experiments">
          <span className="sjp-finish-plate_extra-text">Experiments</span>
          {experimentNames.map((exp, idx) => getExpRow(exp.name, idx))}
        </div>
      </div>
      <div className="sjp-finish-plate__main-area">
        <VialPlate
          experimentNames={experimentNames}
          plateData={plateVialsData.vials}
          getProduct={getProduct}
          updatedVial={updatedVial}
          plateNames={JSON.parse(batch?.metaData).finish_plates}
        />
        <VialPlate
          experimentNames={experimentNames}
          plateData={plateVialsData.vials}
          getProduct={getProduct}
          updatedVial={updatedVial}
          plateNames={JSON.parse(batch?.metaData).finish_plates}
          isReverse
        />
      </div>
    </div>
  );
};

export default FinishPlate;
