import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';

import { Collapse } from '../../Common';
import { ReactionsTable } from '../ReactionsTable/ReactionsTable';
import { getReactionTableColumns } from './columns';
import { getProcessReactions } from './reactions';

const ReactionAccordion = props => {
  const { processData, wrapperClass, processType, showCheckboxes } = props;
  const [reactions, setReactions] = useState([]);
  const [columns, setColumns] = useState([]);

  const getReactions = useCallback(() => getProcessReactions(processData, processType), [processData, processType]);

  const getColumns = useCallback(() => getReactionTableColumns(processData, processType), [processData, processType]);

  useEffect(() => {
    setReactions(getReactions());
    setColumns(getColumns());
  }, [processData]);
  const ReactionPanel = {
    title: (
      <span className="reaction-accordion-section__title">
        <span className="reaction-accordion-section__title--bold">Reactions</span>{' '}
        <span className="reaction-accordion-section__title--faded">{reactions?.length || ''}</span>
      </span>
    ),
    key: 'reactions',
    body: (
      <ReactionsTable
        reactions={reactions}
        showCheckboxes={showCheckboxes}
        columns={columns}
        setReactions={setReactions}
      />
    ),
  };

  return (
    <div data-protected className={cn('reactions-accordion', { [wrapperClass]: wrapperClass })}>
      <Collapse items={[ReactionPanel]} defaultActiveKey="reactions" />
    </div>
  );
};

export default ReactionAccordion;
