import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox } from 'components/Common';
import { DragAndDropContainer } from '@templates';
import { ExperimentFieldOption, INITIAL_OPTION, INITIAL_DEFAULT_VALUE, OPTION_FIELD, ERROR_TYPE } from '@organisms';
import './styles.scss';

export const DropdownSection = ({
  dropdownOptions,
  setDropdownOptions,
  type,
  defaultFields,
  setData,
  colorCoding,
  uneditabled,
  setColorCoding,
}) => {
  const defaultFieldKeys = defaultFields?.length > 0 ? defaultFields.map(option => String(option?.key)) : [];

  const onChangeColor = value => {
    setData({ defaultField: { ...INITIAL_DEFAULT_VALUE } });
    setColorCoding(value);
    setDropdownOptions(prevOptions => prevOptions.map(option => ({ ...option, color: '', errorMessageForColor: '' })));
  };

  const onAdd = () => {
    setDropdownOptions(prevOptions => [...prevOptions, { ...INITIAL_OPTION, key: uuidv4() }]);
  };

  const onChange = (index, value, field) => {
    setDropdownOptions(prevOptions => {
      return prevOptions.map((option, i) => {
        if (index == i) {
          const newOptionData =
            field == OPTION_FIELD.value
              ? { ...option, [field]: value, label: value, [ERROR_TYPE.errorMessage]: '' }
              : { ...option, [field]: value, [ERROR_TYPE.errorMessageForColor]: '' };
          return newOptionData;
        } else return option;
      });
    });
  };

  const onRemove = index => {
    setDropdownOptions(prevOptions => prevOptions.filter((option, i) => i != index));
  };

  const OptionElements =
    dropdownOptions.length <= 1
      ? [
          <ExperimentFieldOption
            single
            optionData={dropdownOptions[0]}
            onChange={(value, field) => {
              onChange(0, value, field);
            }}
            colorCoding={colorCoding}
            disabled={defaultFieldKeys?.includes(String(dropdownOptions[0].key)) || uneditabled}
          />,
        ]
      : dropdownOptions.map((option, index) => (
          <ExperimentFieldOption
            optionData={option}
            key={option.key}
            onChange={(value, field) => {
              onChange(index, value, field);
            }}
            onRemove={() => {
              onRemove(index);
            }}
            colorCoding={colorCoding}
            disabled={defaultFieldKeys?.includes(String(option.key)) || uneditabled}
          />
        ));

  return (
    <div className="dropdown">
      <div className="dropdown-title">Dropdown options</div>
      <Checkbox value={colorCoding} field={type} onChange={onChangeColor} disabled={uneditabled}>
        Add color coding
      </Checkbox>
      <DragAndDropContainer
        className="dropdown-options"
        useDragHandle
        setOptions={setDropdownOptions}
        options={dropdownOptions}
        optionElements={OptionElements}
      />
      {dropdownOptions.length < 25 && (
        <div className="dropdown-button">
          <div className="dropdown-button-add" onClick={!uneditabled && onAdd}>
            <span className="dropdown-button-add-icon">+</span> ADD OPTION
          </div>
        </div>
      )}
    </div>
  );
};
