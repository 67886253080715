import { INITIAL_ERRORS } from '../../../../constants';

export const inputValidations = inputValues => {
  let isValid = true;
  let currentObject = inputValues[inputValues.chemical_type];
  let temp = !!inputValues[inputValues.chemical_type].volume ? inputValues[inputValues.chemical_type].volume.length : 1;
  let obj = { ...INITIAL_ERRORS };
  for (let i = 0; i < temp; i++) {
    let mass_err, moles_err, volume_err;
    if (
      (inputValues.reagent_type === 'liquid' ||
        inputValues.reagent_type === 'solution' ||
        inputValues.reagent_type === 'solid') &&
      inputValues.chemical_type !== 'solvent'
    ) {
      mass_err = [...obj[inputValues.chemical_type].mass];
      if (inputValues[inputValues.chemical_type].mass.length > i)
        mass_err[i] = !inputValues[inputValues.chemical_type].mass[i] && !!currentObject.compound;

      moles_err = [...obj[inputValues.chemical_type].moles];
      if (inputValues[inputValues.chemical_type].moles.length > i)
        moles_err[i] = !inputValues[inputValues.chemical_type].moles[i] && !!currentObject.compound;

      volume_err = [...obj[inputValues.chemical_type].volume];
      if (inputValues[inputValues.chemical_type].volume.length > i)
        volume_err[i] = !inputValues[inputValues.chemical_type].volume[i] && !!currentObject.compound;
      if (inputValues.reagent_type === 'liquid') {
        if (mass_err[i] || moles_err[i] || volume_err[i] || !currentObject.compound || !currentObject.density)
          isValid = false;
      } else if (inputValues.reagent_type === 'solution') {
        if (mass_err[i] || moles_err[i] || volume_err[i] || !currentObject.compound || !currentObject.concentration) {
          isValid = false;
        }
      } else if (inputValues.reagent_type === 'solid') {
        if (mass_err[i] || moles_err[i]) isValid = false;
      }
    } else if (inputValues.chemical_type === 'solvent') {
      mass_err = [false];
      moles_err = [false];
      volume_err = [false];
      if (!currentObject.solvent || !currentObject.solvent_volume) isValid = false;
    }
    obj = {
      reagent: {
        //type: currentObject.type !== inputValues.chemical_type,
        compound: !currentObject.compound,
        solvent: !currentObject.solvent,
        solvent_volume: !currentObject.solvent_volume,
        mass: mass_err,
        moles: moles_err,
        volume: volume_err,
        density: !currentObject.density && !!currentObject.compound,
        concentration: !currentObject.concentration,
        //limiting: !!currentObject.limiting,
        // equivalents: !!currentObject.equivalents,
      },
      reactant: {
        //type: currentObject.type !== inputValues.chemical_type,
        compound: !currentObject.compound,
        solvent: !currentObject.solvent,
        solvent_volume: !currentObject.solvent_volume,
        //mw: !!currentObject.mw,
        mass: mass_err,
        moles: moles_err,
        volume: volume_err,
        density: !currentObject.density && !!currentObject.compound,
        concentration: !currentObject.concentration,
        //limiting: !!currentObject.limiting,
        // equivalents: !!currentObject.equivalents,
      },
      solvent: {
        solvent: !currentObject.solvent,
        solvent_volume: !currentObject.solvent_volume,
        //mw: !!currentObject.mw,
      },
    };
  }
  return { obj, isValid };
};
