import React, { useState, useEffect } from 'react';
import { Sidebar, Tabs, Tooltip } from '../../Common';
import Notes from '../../Experiment/Notes';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { notes } from '../../../store/experiment/experiment.selector';
import { getNotes } from '../../../store/experiment/experiment.actions';
import cn from 'classnames';

export const BatchNotesSidebar = ({ open, onClose, updateNotesList, experiments }) => {
  const [experimentId, setExperimentId] = useState(null);
  const notesList = useSelector(notes);
  const dispatch = useDispatch();
  const getExperimentNotes = experimentId => {
    setExperimentId(experimentId);
    dispatch(
      getNotes({
        experimentId,
      })
    );
  };
  useEffect(() => {
    if (!experimentId && !!experiments.length) {
      getExperimentNotes(experiments[0].uuid);
    }
  }, [experiments]);

  useEffect(() => {
    if (!!experiments.length) {
      getExperimentNotes(experiments[0].uuid);
    }
  }, [open]);
  return (
    <Sidebar width={660} title="Notes" open={open} showFooter={false} className="notes-batch-sidebar" onClose={onClose}>
      <Tabs
        options={experiments.map((v, k, arr) => {
          return {
            label: (
              <Tooltip title={v.name}>
                <div className="experiment-name-wrapper">
                  <div
                    className={cn('notes-name', {
                      five: arr.length === 5,
                      four: arr.length === 4,
                      three: arr.length === 3,
                      two: arr.length === 2,
                      one: arr.length === 1,
                    })}
                  >
                    {v.name}
                  </div>
                  <span className="notes-counter">({v.notes.length})</span>
                </div>
              </Tooltip>
            ),
            value: v.uuid,
          };
        })}
        activeKey={experimentId}
        onChange={getExperimentNotes}
      >
        <Notes
          showDeleteNote
          notes={notesList}
          updateNotesList={() => {
            dispatch(
              getNotes({
                experimentId,
              })
            );
            updateNotesList();
          }}
          dataExperiment={experimentId}
        />
      </Tabs>
    </Sidebar>
  );
};
