import './style.scss';

import React from 'react';

import no_projects from 'dist/images/no-projects.svg';
import no_results from 'dist/images/no-results.svg';

import { Button, DropdownButton } from '..';

export const EmptyState = ({
  emptyState,
  action,
  essence,
  canCreate,
  message,
  menu,
  filteringText = 'Please try using a different search request',
  mainText = '',
}) => (
  <>
    {!emptyState ? (
      <div className="no-results">
        <div className="caption">
          <p className="main-caption">Nothing found</p>
          <p>{filteringText}</p>
        </div>
        <img src={no_results} />
      </div>
    ) : (
      <div className="no-results">
        <div className="caption">
          <p className="main-caption">{mainText || `No ${essence} yet`}</p>
          <p>{message}</p>
          {canCreate &&
            (menu ? (
              <DropdownButton secondaryTypeButton menu={menu} text={`Create a ${essence}`} />
            ) : (
              <Button secondary onClick={() => action()}>
                Create a {essence}
              </Button>
            ))}
        </div>
        <img src={no_projects} />
      </div>
    )}
  </>
);
