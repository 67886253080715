import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import ReactDOM from 'react-dom';
import { RangeSlider } from '../../Common/RangeSlider';
import { Chart } from '../Chart';
import { getDiffChart, getHHmmFromSec, getHHmmSSFromSec } from '../../../utils/date';
import { Slider } from 'antd';
import moment from 'moment';
import {
  getConfigurationUnivariateCharts,
  getDataUnivariateCharts,
  updateUnivariateData,
} from '../../../store/experiment/experiment.actions';
import { WebsocketService } from '../../../utils/service/WebSocketService';
import { configHARD, dataHARD } from '../ExperimentProgress/ExperimentSummary';
import { Spinner } from '../../Common/Spinner';
import { colorsCharts } from '../../../constants';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { callHelperUnivariateData } from '../../../utils/execution';
const getDiff = (start, end) => moment.utc(end).diff(moment.utc(start), 'seconds');

//colorLine for color
const setDataLine = (data, idx, arr) => ({
  name: data.label,
  typeLine: data.line_type,
  x: 'time',
  y: 'value',
  colorLine: data.colorLine,
  sensor_id: data.sensor_id,
});

const associateDataWithChart = (config, newData, startPoint) => {
  const data = {};
  if (!newData?.length) {
    config.forEach(i => (data[i.label] = []));
    return data;
  }
  newData.forEach(i => {
    let targetChart = null;
    let targetLine = null;

    const setUpPoint = () => {
      let timeSec = getDiffChart(startPoint.datetime, i.datetime);
      let timeTrunc = Math.trunc(timeSec / 60);
      let timeExtraSec = timeSec - timeTrunc * 60;
      let proportionExtraSecFromMin = Math.trunc((timeExtraSec * 100) / 60);
      return {
        timeInSec: timeSec,
        time: +`${timeTrunc}.${
          proportionExtraSecFromMin < 10 ? '0' + proportionExtraSecFromMin : proportionExtraSecFromMin
        }`,
        name: i.sensor_id,
        extraOptions: { ...targetLine },
        value: Math.floor(i.value * 1000) / 1000,
      };
    };
    config.forEach(j => {
      const line = j.lines.find(k => k.sensor_id === i.sensor_id);
      if (line) {
        targetChart = j.label;
        targetLine = line;
      }
    });
    const point = setUpPoint();
    if (point.timeInSec >= 0) {
      if (data[targetChart]) data[targetChart] = [...data[targetChart], point];
      else data[targetChart] = [point];
    }
  });

  return data;
};

const mergeAllDataAndNewData = (all, last) => {
  const data = { ...all };
  Object.keys(last).forEach(i => {
    if (data[i]) data[i] = [...data[i], ...last[i]];
    else data[i] = last[i];
  });

  return data;
};
let interval = 0;

let loadingData = false;
let countRequests = 0;

class UnivariateComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: null,
    };

    this.abortController = new AbortController();
    this.timer = null;
  }

  updateStore = payload => {
    return this.props.updateStore(payload);
  };

  getSeosorsId = data =>
    data
      .map(i => i.lines.map(i => i.sensor_id).join(','))
      .filter(i => i)
      .join(',');

  getLastTime = data => {
    if (!data) return 0;
    const values =
      data[
        Object.keys(data).find(i => {
          if (Array.isArray(data[i]) && data[i].length) return i;
        })
      ];
    if (!values.length) return 0;
    return values[values.length - 1].timeInSec;
  };

  setDataChartsRecursion = (dataChart, processedConfig, setConnectWS) => {
    const startPoint = { datetime: moment.utc(this.props.experiment.execution.startedAt).format() };
    const data = associateDataWithChart(processedConfig, dataChart, startPoint);
    if (data.null) delete data.null;
    const newData = {
      valueRange: [0, this.getLastTime(data)],
      configCharts: processedConfig,
      startPoint: startPoint,
      dataCharts: data,
      filteredData: data,
      loading: false,
      loadingUpdateUnivariateData: false,
    };
    if (setConnectWS && !this.props.viewMode) {
      const subject = new WebsocketService(`/experiment-execution/${this.props.experiment?.uuid}/`);
      subject.subscribe(this.addNewPoint);

      this.setState({
        subject: subject,
      });
    }
    this.updateStore(newData);
  };

  resursionGetUnivariateCharts = (body, processedConfig) => {
    interval = setInterval(() => {
      if (!loadingData) {
        loadingData = true;
        callHelperUnivariateData({
          controller: this.abortController,
          sensorsIds: body.sensorIds,
          viewMode: this.props.viewMode,
          body,
          callBack502: () => this.updateStore({ loading: false }),
          callBackNotData: () => this.updateStore({ loading: false }),
          callBackLimit: () => this.setDataChartsRecursion([], processedConfig, false),
        })
          .then(resp => {
            const isAllData = resp.length === resp.filter(i => Array.isArray(i)).length;
            const data = resp.filter(i => i).reduce((acc, cur) => acc?.concat(Array.isArray(cur) ? cur : []), []);
            if (Array.isArray(data) && data.length && (countRequests >= 1 || data.length > 100) && isAllData) {
              clearInterval(interval);
              this.setDataChartsRecursion(data, processedConfig, true);
            } else {
              countRequests = countRequests + 1;
            }
            loadingData = false;
          })
          .catch(error => {
            if (error === 'error') clearInterval(interval);
            loadingData = false;
          });
      }
    }, 1000);
  };

  getDataChats = processedConfig =>
    this.resursionGetUnivariateCharts(
      {
        uuid: this.props.experiment?.uuid,
        sensorIds: this.getSeosorsId(processedConfig),
      },
      processedConfig
    );

  componentWillUnmount() {
    if (this.state.subject) {
      this.state.subject.unsubscribe();
    }
    loadingData = false;
    clearTimeout(this.timer);
    this.updateStore({
      valueRange: [0, 0],
      configCharts: [],
      dataCharts: null,
      updateDataChart: false,
      startPoint: null,
      filteredData: null,
      loading: true,
    });
    this.abortController.abort();
  }

  setTimerUpdateData = () => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.updateStore({ updateDataChart: true });
    }, 10000);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (!!nextProps.dataCharts && !nextProps.updateDataChart) {
      this.setTimerUpdateData();
    }
  }

  componentDidMount() {
    getConfigurationUnivariateCharts({ uuid: this.props.experiment?.uuid }).then(config => {
      let colors = [...colorsCharts];
      const itemsWithItems = [];
      let processedConfig = config.map(i => ({ ...i, lines: i.lines.map(setDataLine) }));
      processedConfig.forEach(j =>
        j.lines.forEach((i, idx, arr) => {
          let color = colors[0];
          const copyElem = itemsWithItems.find(k => {
            let el = i.name.split(' ')[0];
            let el1 = k.name.split(' ')[0];
            return el1 === el && k.name !== i.name && el[0] === 'R' && el1[0] === 'R';
          });
          if (!!copyElem && copyElem.colorLine) {
            color = copyElem.colorLine;
          } else colors = colors.splice(1);
          itemsWithItems.push({ ...i, colorLine: color });
        })
      );
      processedConfig = processedConfig.map(j => ({
        ...j,
        lines: j.lines.map(i => ({
          ...i,
          colorLine: itemsWithItems.find(k => k.sensor_id === i.sensor_id)?.colorLine,
        })),
      }));
      this.getDataChats(processedConfig);
    });
  }

  addNewPoint = point => {
    if (point.type !== 'univariate_data' || !this.props.updateDataChart || this.props.loadingUpdateUnivariateData)
      return;

    const suitableData = this.props.configCharts.find(i => !!this.props.dataCharts[i.label]);
    const timeInSecNewPoint = getDiff(this.props.startPoint.datetime, point.datetime);
    const lastPointFromData = this.props.dataCharts[suitableData?.label];
    if (lastPointFromData[lastPointFromData?.length - 1]?.timeInSec + 100 > timeInSecNewPoint) {
      let newData = associateDataWithChart(
        this.props.configCharts,
        point.data.map(i => ({ ...i, datetime: point.datetime })),
        this.props.startPoint
      );
      delete newData.null;
      const mergingData = mergeAllDataAndNewData(this.props.dataCharts, newData);
      ReactDOM.unstable_batchedUpdates(() => {
        const newDataStore = {};
        const timeLastPoint = this.getLastTime(this.props.dataCharts);
        if (timeLastPoint === this.props.valueRange[1]) {
          const newFiltersData = mergeAllDataAndNewData(this.props.filteredData, newData);
          newDataStore.filteredData = newFiltersData;
          newDataStore.valueRange = [this.props.valueRange[0], this.getLastTime(newData)];
        }
        newDataStore.dataCharts = mergingData;
        newDataStore.updateDataChart = false;
        this.updateStore(newDataStore);
      });
    } else {
      this.updateStore({ loading: true, loadingUpdateUnivariateData: true });
      this.getDataChats(this.props.configCharts);
    }
  };

  handleRangeSlider = value => {
    let newValue = [...value];
    const maxSec = this.getLastTime(this.props.dataCharts);
    if (maxSec < newValue[1]) newValue = [newValue[0], maxSec];
    const obj = {};
    Object.keys(this.props.dataCharts).forEach(
      i => (obj[i] = this.props.dataCharts[i].filter(i => i.timeInSec >= newValue[0] && i.timeInSec <= newValue[1]))
    );
    const newDataStore = {
      filteredData: obj,
      valueRange: newValue,
    };
    this.updateStore(newDataStore);
  };
  render() {
    const { valueRange, configCharts, dataCharts, filteredData, loading } = this.props;
    const lastPoint = this.getLastTime(dataCharts);
    return (
      <>
        <span className="charts-scale">Charts scale</span>
        <div className="univariate-tab" id="univariate">
          <Spinner loading={loading}>
            {dataCharts && (
              <RangeSlider
                value={valueRange}
                onChange={this.handleRangeSlider}
                date
                customId={this.props.idContainerTab || 'univariate'}
                noMarks
                max={lastPoint + 60}
                tipFormatter={value => {
                  return <div>{getHHmmFromSec(value)}</div>;
                }}
                tooltipPlacement="bottom"
                tooltipVisible
              />
            )}
            {configCharts.map((i, idx) => {
              if (dataCharts && !dataCharts[i.label]) return;
              return (
                <Chart
                  axisYName={i.unit}
                  useMin={valueRange[0] !== 0}
                  endPoint={
                    dataCharts && dataCharts[i.label] ? dataCharts[i.label][dataCharts[i.label].length - 1] : {}
                  }
                  dataConfig={i.lines}
                  data={filteredData ? filteredData[i.label] || [] : []}
                  label={i.label}
                />
              );
            })}
          </Spinner>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => {
  return {
    valueRange: store.experimentReducer.valueRange,
    configCharts: store.experimentReducer.configCharts,
    dataCharts: store.experimentReducer.dataCharts,
    updateDataChart: store.experimentReducer.updateDataChart,
    startPoint: store.experimentReducer.startPoint,
    filteredData: store.experimentReducer.filteredData,
    loading: store.experimentReducer.loading,
    loadingUpdateUnivariateData: store.experimentReducer.loadingUpdateUnivariateData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateStore: (...args) => dispatch(updateUnivariateData(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnivariateComponent);
