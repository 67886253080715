import React from 'react';
import { Checkbox } from 'components/Common';
import './styles.scss';

export const ComplexCheckbox = ({ title, value, field, onChange, children, uneditabled }) => {
  return (
    <>
      <div className="checkbox">
        <div className="checkbox-name">{title}</div>
        <Checkbox value={value} field={field} onChange={onChange} disabled={uneditabled}>
          Yes
        </Checkbox>
      </div>
      {value && <div className="checkbox-additional">{children}</div>}
    </>
  );
};
