import cn from 'classnames';
import React from 'react';
import './styles.scss';

export const LabelValue = ({ label, value, className }) => (
  <div className={cn('experiment-info-label-value container-info-experiment_block', className)}>
    <span>{label}</span>
    <span>{value || 'N/A'}</span>
  </div>
);
