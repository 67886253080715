import moment from 'moment';
import { getCompoundVolume } from '../../../../utils/synjetHelpers';

export const getExperimentsData = (
  data,
  previewMode,
  analyticsData,
  calibrationTemperatures,
  analytical,
  processStepsWithTime,
  isProExecution = false
) => {
  const result = [];
  if (data?.process_steps) {
    data.process_steps.forEach((step, stepIndex) => {
      if (step.step.vials) {
        step.step.vials.forEach((vial, vialIndex) => {
          let payload;
          if (result[vialIndex]) {
            payload = {
              ...result[vialIndex],
              [`step${stepIndex}`]: {
                ...vial,
                time: analytical ? moment().startOf('day').seconds(Math.ceil(vial.actual_reaction_time)) : vial.time,
                calibrationTemperature: isProExecution
                  ? vial.calibrationTemperature
                  : getCalibrationTemperature(calibrationTemperatures?.[stepIndex], vialIndex),
                actual_reaction_time: processStepsWithTime
                  ? processStepsWithTime[stepIndex].step.vials[vialIndex]?.actual_reaction_time
                  : null,
              },
              error: result[vialIndex].error || vial.error,
              is_valid: result[vialIndex].is_valid && vial.is_valid,
              name: result[vialIndex].name || vial.name,
              ancestor: result[vialIndex].ancestor || vial.ancestor,
              total: result[vialIndex].total || vial.total,
              normalization_solvent_volume:
                result[vialIndex].normalization_solvent_volume || vial.normalization_solvent_volume,
            };
          } else {
            payload = {
              name: vial.name,
              ancestor: vial.ancestor,
              error: vial.error,
              is_valid: vial.is_valid,
              [`step${stepIndex}`]: {
                ...vial,
                time: analytical ? moment().startOf('day').seconds(Math.ceil(vial.actual_reaction_time)) : vial.time,
                calibrationTemperature: isProExecution
                  ? vial.calibrationTemperature
                  : getCalibrationTemperature(calibrationTemperatures?.[stepIndex], vialIndex),
                actual_reaction_time: processStepsWithTime
                  ? processStepsWithTime[stepIndex].step.vials[vialIndex]?.actual_reaction_time
                  : null,
              },
              total: vial.total,
              normalization_solvent_volume: vial.normalization_solvent_volume,
              analytic: analyticsData ? getAnalysisData(analyticsData, vialIndex) : {},
            };
          }
          result[vialIndex] = payload;
        });
      }
    });
  }
  return result.map(vial => ({
    ...vial,
    include: previewMode ? vial.is_valid : false,
  }));
};

const getAnalysisData = (analyticsData, vialIndex) => {
  const data = analyticsData.find(analytics => analytics?.vialId === vialIndex + 1);
  if (!data?.data) {
    return {};
  }

  return data.data.reduce(
    (resultProperties, analysisComponent) => ({
      ...resultProperties,
      [analysisComponent.label]: analysisComponent.value,
    }),
    {}
  );
};

const getCalibrationTemperature = (temperatures, vialIndex) => (temperatures ? temperatures[vialIndex + 2] : null);

const calculateCompoundTotal = (compounds, compoundName, isValid, stepIndex, total) => {
  const totalRow = { ...total };

  if (!compounds) return totalRow;

  compounds.forEach((compound, compoundIndex) => {
    const volume = isValid ? Number(getCompoundVolume(compound)) : 0;
    const equivalent = isValid ? Number(compound.equivalent || 0) : 0;

    if (!totalRow[`step${stepIndex}`][compoundName]) {
      totalRow[`step${stepIndex}`][compoundName] = [
        {
          ...compound,
          volume,
          equivalent,
        },
      ];
    } else {
      const totalReactant = totalRow[`step${stepIndex}`][compoundName][compoundIndex];
      if (!totalReactant) {
        totalRow[`step${stepIndex}`][compoundName][compoundIndex] = {
          volume: 0,
          equivalent: 0,
        };
      }
      totalRow[`step${stepIndex}`][compoundName][compoundIndex].volume += volume;
      totalRow[`step${stepIndex}`][compoundName][compoundIndex].equivalent += equivalent;
    }
  });

  return totalRow;
};

export const calculatePageTotal = (calculatedVials, processSteps) => {
  let totalRow = {
    normalization_solvent_volume: 0,
    total: 0,
    is_valid: true,
    error: 'Includes only reactions that can be executed',
    step0: {},
    step1: {},
  };

  calculatedVials.forEach(vial => {
    const isValid = vial.is_valid;

    totalRow.normalization_solvent_volume += isValid ? vial.normalization_solvent_volume : 0;
    totalRow.total += isValid ? vial.total : 0;

    if (!isValid) {
      totalRow.is_valid = false;
    }
    processSteps.forEach((step, stepIndex) => {
      const currentStep = vial[`step${stepIndex}`];
      if (currentStep) {
        totalRow = calculateCompoundTotal(currentStep.reactants, 'reactants', isValid, stepIndex, totalRow);
        totalRow = calculateCompoundTotal(currentStep.reagents, 'reagents', isValid, stepIndex, totalRow);
      }
    });
  });

  return totalRow;
};
