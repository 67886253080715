import './index.scss';

import React, { useState } from 'react';

import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { PROJECT_TYPES } from '../../../constants';
import { INPUT_EXCLUDE_SYMBOLS } from '../../../constants/regexp';
import { checkNumericField } from '../../../utils';
import { Button, Checkbox, Divider, Input, Select, Spinner } from '../../Common';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const convertEstimatedTime = seconds => {
  const hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  const sec = Math.floor(seconds % 60);
  if (sec > 0) minutes += 1;
  return `${hours < 10 ? `0${hours}` : hours} h ${minutes < 10 ? `0${minutes}` : minutes} m`;
};

const getEstimatedYield = (process, steps, reactionInfo, handleYieldChange, errors, loading, reactions) => {
  switch (process?.type) {
    case PROJECT_TYPES.PRODUCTION:
      return (
        <div className="createExperimentModal__yield-container">
          {steps.map((step, stepIndex) => (
            <>
              <h6 className="createExperimentModal__subtitle">
                {`Step ${stepIndex + 1} estimated yield`} <span>*</span>
              </h6>
              <div className="createExperimentModal__yield-container__row">
                <Input
                  className="yield_input"
                  placeholder="100"
                  regex={INPUT_EXCLUDE_SYMBOLS}
                  value={
                    reactionInfo && reactionInfo[0]?.estimated_yields && reactionInfo[0]?.estimated_yields[stepIndex]
                  }
                  require
                  disabled={loading}
                  onChange={value => handleYieldChange(value, 0, stepIndex)}
                  error={errors && errors[0] && errors[0]?.estimated_yields && errors[0]?.estimated_yields[stepIndex]}
                  errorText={
                    errors && errors[0] && errors[0]?.estimated_yields && errors[0]?.estimated_yields[stepIndex]
                  }
                />
                <span>%</span>
              </div>
            </>
          ))}
        </div>
      );
    case PROJECT_TYPES.LIBRARY_GENERATION:
      return (
        <div className="createExperimentModal__libgen-yield-container">
          <div
            className="createExperimentModal__libgen-yield-container__reactions"
            style={{ gridTemplateColumns: `2.5fr repeat(${steps.length}, 1fr)` }}
          >
            <h6 className="createExperimentModal__subtitle" />
            {steps.map((step, stepIndex) => (
              <h6 className={classNames('createExperimentModal__subtitle', 'libgen__subtitle')}>
                {`Step ${stepIndex + 1} estimated yield`} <span>*</span>
              </h6>
            ))}
            {reactions.map(reaction => (
              <>
                <h6 className="createExperimentModal__subtitle">
                  Reaction
                  {+reaction + 1}:
                </h6>
                {steps.map((step, stepIndex) => (
                  <div className="createExperimentModal__yield-container__row">
                    <Input
                      className="yield_input"
                      placeholder="100"
                      regex={INPUT_EXCLUDE_SYMBOLS}
                      value={
                        reactionInfo &&
                        reactionInfo[reaction]?.estimated_yields &&
                        reactionInfo[reaction]?.estimated_yields[stepIndex]
                      }
                      require
                      disabled={loading}
                      onChange={value => handleYieldChange(value, reaction, stepIndex)}
                      error={
                        errors && errors[reaction]?.estimated_yields && errors[reaction]?.estimated_yields[stepIndex]
                      }
                      errorText={
                        errors && errors[reaction]?.estimated_yields && errors[reaction]?.estimated_yields[stepIndex]
                      }
                    />
                    <span>%</span>
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>
      );
    default:
      return <div />;
  }
};

const getCalculation = (
  process,
  data,
  handleCalculationChange,
  recalculate,
  handleCalculation,
  requestedOutputError,
  calculationLoading,
  loading,
  reactions
) => {
  if (process?.type !== PROJECT_TYPES.PRODUCTION) {
    return (
      <div className="createExperimentModal__opt-calculation">
        <div
          className={classNames('createExperimentModal__opt-calculation__reactions', {
            'createExperimentModal__libgen-calculation__reactions': process?.type === PROJECT_TYPES.LIBRARY_GENERATION,
          })}
        >
          <h6 className="createExperimentModal__subtitle" />
          <h6 className={classNames('createExperimentModal__subtitle', 'libgen__subtitle')}>
            Theoretical production rate
          </h6>
          {process?.type === PROJECT_TYPES.LIBRARY_GENERATION && (
            <h6 className="createExperimentModal__subtitle">
              Requested output <span>*</span>
            </h6>
          )}
          {reactions.map((reaction, reactionIndex) => (
            <>
              <h6 className="createExperimentModal__subtitle">
                Reaction
                {+reaction + 1}:
              </h6>
              <h6 className="createExperimentModal__text">
                {data?.theoreticalProdRate[reactionIndex]?.value?.toFixed(3)} mg/hr
              </h6>
              {process?.type === PROJECT_TYPES.LIBRARY_GENERATION && (
                <div className="calculation__row">
                  <Input
                    className="output_input"
                    placeholder="100"
                    regex={INPUT_EXCLUDE_SYMBOLS}
                    value={data?.reactionsInfo && data?.reactionsInfo[reaction]?.requested_output}
                    onChange={value => handleCalculationChange(value, reaction)}
                    require
                    error={requestedOutputError && requestedOutputError[reaction]?.requested_output}
                    errorText={requestedOutputError && requestedOutputError[reaction]?.requested_output}
                    disabled={loading}
                  />{' '}
                  <span>mg</span>
                </div>
              )}
            </>
          ))}
        </div>
        <div className="createExperimentModal__opt-calculation__column">
          <h6 className="createExperimentModal__subtitle">Total estimated time</h6>
          <div className="createExperimentModal__row">
            <h6 className={classNames('createExperimentModal__text', 'estimated-time')}>
              {data.estimatedTime ? convertEstimatedTime(data.estimatedTime) : 'N/A'}
            </h6>
            <Spinner loading={calculationLoading} tip="" size="small" indicator={antIcon} />
          </div>
          {(recalculate || !data.estimatedTime) && (
            <Button type="link" className="calculation__btn" onClick={handleCalculation}>
              <InfoCircleOutlined />
              {!data.estimatedTime ? 'Calculate' : 'Recalculate'}
            </Button>
          )}
        </div>
      </div>
    );
  }
  return data?.theoreticalProdRate.map((theoreticalRate, i) => (
    <div className="createExperimentModal__calculation">
      <h6 className="createExperimentModal__subtitle">Theoretical production rate</h6>
      <h6 className="createExperimentModal__subtitle">
        Requested output <span>*</span>
      </h6>
      <h6 className="createExperimentModal__subtitle">Total estimated time</h6>
      <h6 className="createExperimentModal__text">{theoreticalRate.value?.toFixed(3)} mg/hr</h6>
      <div className="calculation__row">
        <Input
          className="output_input"
          placeholder="100"
          regex={INPUT_EXCLUDE_SYMBOLS}
          value={data?.reactionsInfo && data?.reactionsInfo[0]?.requested_output}
          onChange={value => handleCalculationChange(value, 0)}
          require
          error={requestedOutputError && requestedOutputError[0]?.requested_output}
          errorText={requestedOutputError && requestedOutputError[0]?.requested_output}
          disabled={loading}
        />{' '}
        <span>mg</span>
      </div>
      <div className="calculation__column">
        <div className="createExperimentModal__row">
          <h6 className="createExperimentModal__text">
            {data.estimatedTime ? convertEstimatedTime(data.estimatedTime) : 'N/A'}
          </h6>
          <Spinner loading={calculationLoading} tip="" size="small" indicator={antIcon} />
        </div>
        {(recalculate || !data.estimatedTime) && (
          <Button type="link" className="calculation__btn" onClick={handleCalculation}>
            <InfoCircleOutlined />
            {!data.estimatedTime ? 'Calculate' : 'Recalculate'}
          </Button>
        )}
      </div>
    </div>
  ));
};

export const CreateExperimentForm = ({
  process,
  steps,
  experimentData,
  handleFormChange,
  analytics,
  priorities,
  errors,
  handleExperimentTimeCalculation = () => {},
  loading,
  reactions,
  isSynjet,
  isEdit,
  isLab,
  isSynjetPro,
  experimentStep,
}) => {
  const [recalculate, setRecalculate] = useState(false);
  const calculationLoading = useSelector(state => state.createExperimentReducer.calculationLoading);

  const getAutoSynCalculation = () => (
    <>
      {process?.type !== PROJECT_TYPES.OPTIMIZATION ? (
        getEstimatedYield(
          process,
          steps,
          experimentData?.reactionsInfo,
          handleYieldChange,
          errors?.reactionsInfo,
          loading,
          reactions
        )
      ) : (
        <div className="createExperimentModal__timePerReaction">
          <div>
            <h6 className="createExperimentModal__subtitle">
              Time per Reaction <span>*</span>
            </h6>
            <p className="createExperimentModal__hint">
              {reactions.length} {reactions?.length > 1 ? 'Reactions' : 'Reaction'}
            </p>
          </div>
          <div className="createExperimentModal__timePerReaction__row">
            <Input
              className="time_input"
              placeholder="0"
              regex={/[^\d.]+/g}
              value={experimentData?.timePerReaction}
              onChange={value => handleTimePerReactionChange(value, 'timePerReaction')}
              field="timePerReaction"
              require
              error={errors.timePerReaction}
              errorText={errors.timePerReaction}
              disabled={loading}
            />{' '}
            <span>min</span>
          </div>
        </div>
      )}
      <Divider />
      {getCalculation(
        process,
        experimentData,
        handleCalculationChange,
        recalculate,
        handleCalculation,
        errors.reactionsInfo,
        calculationLoading,
        loading,
        reactions
      )}
      <Divider />
    </>
  );
  const handleAnalyticChange = (selected, analitic) => {
    const analytics = selected
      ? [...experimentData?.analytics, analitic.value]
      : experimentData?.analytics.filter(analiticData => analiticData !== analitic.value);
    handleFormChange(analytics, 'analytics');
  };
  const handleYieldChange = (value, reactionIndex, index) => {
    setRecalculate(true);
    const updatedReactionInfo = { ...experimentData.reactionsInfo };
    updatedReactionInfo[reactionIndex] = {
      ...updatedReactionInfo[reactionIndex],
      estimated_yields: updatedReactionInfo[reactionIndex]?.estimated_yields.map((yieldValue, yieldIndex) =>
        index === yieldIndex ? checkNumericField(value, 100, 1) : yieldValue
      ),
    };
    handleFormChange(updatedReactionInfo, 'reactionsInfo', 'estimated_yields', reactionIndex, index);
  };

  const handleCalculationChange = (value, reactionIndex) => {
    const output = checkNumericField(value, 10000, 1);
    setRecalculate(true);
    const updatedReactionInfo = { ...experimentData.reactionsInfo };
    updatedReactionInfo[reactionIndex] = {
      ...updatedReactionInfo[reactionIndex],
      requested_output: output,
    };
    handleFormChange(updatedReactionInfo, 'reactionsInfo', 'requested_output', reactionIndex);
  };

  const handleTimePerReactionChange = (value, field) => {
    setRecalculate(true);
    const maxValue = field === 'timePerReactionSec' || field === 'timePerReactionMin' ? 59 : 1000;
    handleFormChange(checkNumericField(value, maxValue, 3), field);
  };

  const handleCalculation = () => {
    setRecalculate(false);
    handleExperimentTimeCalculation();
  };
  return (
    <div className={experimentStep == 1 && isSynjetPro ? 'createExperimentModal__secondStepSJP' : ''}>
      <div>
        <div className="createExperimentModal__row-start">
          <h4 className={classNames('createExperimentModal__subtitle', 'title-container')}>
            Experiment name
            <span>*</span>
          </h4>
          <Input
            className="createExperimentModal__input"
            placeholder="Enter name"
            maxLength={256}
            autoFocus
            require
            onChange={handleFormChange}
            value={experimentData?.name}
            error={errors.name}
            errorText={errors.name}
            field="name"
            disabled={loading}
            allowSpecials
          />
        </div>
        <Divider />
        {(isSynjet || isSynjetPro) && (
          <>
            <div className="createExperimentModal__row-start">
              <h4 className={classNames('createExperimentModal__subtitle', 'title-container')}>Total estimated time</h4>
              <span>{experimentData.estimatedTime ? convertEstimatedTime(experimentData.estimatedTime) : 'N/A'}</span>
            </div>
            <Divider />
          </>
        )}
        {!isSynjet && !isSynjetPro && !isLab && getAutoSynCalculation()}
        {isLab && (
          <>
            <div className={classNames('createExperimentModal__row-start', 'lab')}>
              <h4 className={classNames('createExperimentModal__subtitle', 'title-container')}>
                Total estimated time
                <span>*</span>
              </h4>
              <div className="createExperimentModal__timePerReaction__row">
                <Input
                  className="lab time_input"
                  placeholder="0"
                  regex={/[^\d.]+/g}
                  value={experimentData?.timePerReactionHour}
                  onChange={value => handleTimePerReactionChange(value, 'timePerReactionHour')}
                  field="timePerReactionHour"
                  require
                  error={errors.timePerReactionHour}
                  errorText={errors.timePerReactionHour}
                  disabled={loading}
                />{' '}
                <span>h</span>
                <Input
                  className={classNames('lab', 'time_input', 'sec')}
                  placeholder="0"
                  regex={/[^\d.]+/g}
                  value={experimentData?.timePerReactionMin}
                  onChange={value => handleTimePerReactionChange(value, 'timePerReactionMin')}
                  field="timePerReactionMin"
                  require
                  error={errors.timePerReactionMin}
                  errorText={errors.timePerReactionMin}
                  disabled={loading}
                />{' '}
                <span>min</span>
                <Input
                  className={classNames('lab', 'time_input', 'sec')}
                  placeholder="0"
                  regex={/[^\d.]+/g}
                  value={experimentData?.timePerReactionSec}
                  onChange={value => handleTimePerReactionChange(value, 'timePerReactionSec')}
                  field="timePerReactionSec"
                  require
                  error={errors.timePerReactionSec}
                  errorText={errors.timePerReactionSec}
                  disabled={loading}
                />{' '}
                <span>sec</span>
              </div>
            </div>
            <Divider />
          </>
        )}
        {!isLab && !isSynjetPro && (
          <div className="createExperimentModal__analytical-radio">
            <h6 className="createExperimentModal__subtitle">Analytical</h6>
            {isSynjet ? (
              <Radio.Group
                disabled={loading}
                value={experimentData?.useAnalytic}
                onChange={e => handleFormChange(e.target.value, 'useAnalytic')}
              >
                {analytics.map(analytic => (
                  <Radio value={analytic.value} disabled={!analytic.available}>
                    {analytic.value}
                  </Radio>
                ))}
                <Radio value="other">Other</Radio>
              </Radio.Group>
            ) : (
              <>
                <Radio.Group
                  disabled={loading}
                  value={experimentData?.useAnalytic}
                  onChange={e => handleFormChange(e.target.value, 'useAnalytic')}
                >
                  <Radio value="none">None</Radio>
                  <Radio value="use">
                    Use analytical
                    <div className="analylics_list">
                      {analytics.map(analitic => (
                        <Checkbox
                          value={experimentData?.analytics.find(analiticData => analiticData === analitic.value)}
                          disabled={experimentData?.useAnalytic === 'none' || !analitic.available}
                          onChange={selected => handleAnalyticChange(selected, analitic)}
                        >
                          {analitic.value}
                        </Checkbox>
                      ))}
                      {errors.analytics && <p className="text-error">{errors.analytics}</p>}
                    </div>
                  </Radio>
                </Radio.Group>
              </>
            )}
          </div>
        )}
        <div className="createExperimentModal__priority">
          <div className="priority__row">
            <h6 className={classNames('createExperimentModal__subtitle', 'title-container')}>Priority</h6>
            <Select
              className="priority_input"
              disabled={loading}
              value={experimentData?.priority}
              onChange={value => handleFormChange(value, 'priority')}
              options={priorities}
            />
          </div>
          {!isEdit && (
            <div className="priority__row">
              <h6 className={classNames('createExperimentModal__subtitle', 'title-container')}>Notes</h6>
              <Input
                maxLength={1000}
                placeholder="Type your note here"
                type="textarea"
                rows={4}
                field="notes"
                require
                className="priority_input"
                value={experimentData?.notes}
                onChange={handleFormChange}
                disabled={loading}
                allowSpecials
                withoutComma={false}
              />
            </div>
          )}
        </div>
      </div>
      {experimentStep == 1 && isSynjetPro && (
        <div className="createExperimentModal__purification">
          <h6 className="createExperimentModal__subtitle">Purification details</h6>
          <Input
            placeholder="Type purification details here"
            type="textarea"
            rows={19}
            field="purification"
            className="purification_input"
            value={experimentData?.purification}
            onChange={handleFormChange}
            disabled={loading}
            allowSpecials
            withoutComma={false}
          />
        </div>
      )}
    </div>
  );
};
