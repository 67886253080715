import React, { useState, useEffect } from 'react';
import { Sidebar, Select, Input } from '../../../Common';
import './style.scss';
import { GLASSWARE_OPTIONS, INITIAL_GLASSWARE } from '../../../../constants';
import { checkIfTrue } from '../../../../utils';
import { Confirmations } from './Confirmations';

const INITIAL_ERRORS = {
  type: false,
  size: false,
  otherType: false,
};

const errorsText = {
  size: 'Should be within 0.5 - 22000',
  otherType: 'This field is required',
};

export const SidebarGlassware = ({ open, onClose, editingData, onSaveGlassware, onDelete }) => {
  const [edited, setEdited] = useState(false);
  const [errors, setErrors] = useState(INITIAL_ERRORS);
  const [cancelConfirmation, openCancelConfirmation] = useState(false);
  const [deleteConfirmation, openDeleteConfirmation] = useState(false);

  const [glasswareValues, setGlasswearValues] = useState(INITIAL_GLASSWARE);
  const [showOther, setShowOther] = useState(false);

  useEffect(() => {
    if (open) {
      if (!editingData) {
        reset();
      } else {
        setGlasswearValues(editingData);
        if (editingData.type === 'Other') setShowOther(true);
      }
    } else {
      setEdited(false);
    }
  }, [open]);

  const reset = () => {
    setGlasswearValues({ ...INITIAL_GLASSWARE });
    setErrors({ ...INITIAL_ERRORS });
    setShowOther(false);
    setEdited(false);
  };

  const onChangeField = (field, value) => {
    setGlasswearValues({ ...glasswareValues, [field]: value });
    setErrors({ ...errors, [field]: false });
  };

  const onSave = () => {
    if (validation()) {
      onSaveGlassware(glasswareValues);
    }
  };

  const validation = () => {
    let isValid = false;
    let obj = {
      type: !glasswareValues.type,
      size: !glasswareValues.size || glasswareValues.size > 22000 || glasswareValues.size < 0.5,
      otherType: glasswareValues.type === 'Other' ? !glasswareValues.otherType : false,
    };
    setErrors(obj);
    isValid = !checkIfTrue(obj);
    return isValid;
  };

  const onCancel = () => {
    if (edited) openCancelConfirmation(true);
    else onClose();
  };

  return (
    <>
      <Sidebar
        width={528}
        open={open}
        onClose={onCancel}
        onSave={onSave}
        onDelete={() => openDeleteConfirmation(true)}
        disableSave={false}
        editingData={editingData}
        title={`Set glassware`}
        saveButtonText={`${editingData ? 'Save' : 'Set'}`}
        cancelText={edited ? 'Cancel' : 'Close'}
      >
        <div className="glassware">
          <div className="glassware-block">
            <div className="column-title">Glassware type</div>
            <Select
              options={GLASSWARE_OPTIONS}
              value={glasswareValues.type}
              onChange={e => {
                setEdited(true);
                onChangeField('type', e);
                setShowOther(e === 'Other');
              }}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              error={errors.type}
              errorText={errorsText.otherType}
            />
          </div>
          {showOther && (
            <div className="glassware-block">
              <div className="column-title"></div>
              <Input
                value={glasswareValues.otherType}
                maxLength={100}
                placeholder={`Type here`}
                onChange={e => {
                  setEdited(true);
                  onChangeField('otherType', e);
                }}
                errorText={errorsText.otherType}
                error={errors.otherType}
              />
            </div>
          )}
          <div className="glassware-block">
            <div className="column-title">Glassware size</div>
            <Input
              className="unit-input"
              value={glasswareValues.size}
              placeholder={`0.5 - 22000`}
              onChange={e => {
                let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                if ((str || !e) && e < 100000000) {
                  e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 2) : e;
                  onChangeField('size', e);
                  setEdited(true);
                }
              }}
              error={errors.size}
              errorText={errorsText.size}
            />
            <span className="unit"> mL</span>
          </div>
        </div>
      </Sidebar>
      <Confirmations
        openDeleteConfirmation={openDeleteConfirmation}
        openCancelConfirmation={openCancelConfirmation}
        onDelete={onDelete}
        onClose={() => {
          onClose();
          reset();
        }}
        deleteConfirmation={deleteConfirmation}
        cancelConfirmation={cancelConfirmation}
      />
    </>
  );
};
