import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProcessBuilderSynJetPro } from './ProcessBuilderSynJetPro/ProcessBuilderSynJetPro';
import { ExperimentDetailsSynJetPro } from './ExperimentDetailsSynJetPro/ExperimentDetailsSynJetPro';

export const SynJetPro = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/process-builder/details/:id`} component={ProcessBuilderSynJetPro} />
      <Route path={`${path}/details/:key`} component={ExperimentDetailsSynJetPro} />
    </Switch>
  );
};
