import React, { useEffect } from 'react';

import SynJetProExperimentProgress from '../SynJetProExperimentProgress';
import { CheckList } from '../../BatchFinalization/CheckList/CheckList';

export const SjpBatchFinalization = ({
  batch,
  finalizationOptions,
  setFinalizationOptions,
  plateVialsData,
  getProduct,
  connectivitySubject,
  setConnectivitySubject,
  setDisableButtons,
  disableButtons,
}) => {
  useEffect(() => {
    setFinalizationOptions(finalizationOptions.map(i => ({ ...i, checked: false })));
  }, []);

  return (
    <div>
      <CheckList finalizationOptions={finalizationOptions} setFinalizationOptions={setFinalizationOptions} />
      <SynJetProExperimentProgress
        batch={batch}
        plateVialsData={plateVialsData}
        getProduct={getProduct}
        connectivitySubject={connectivitySubject}
        setConnectivitySubject={setConnectivitySubject}
        setDisableButtons={setDisableButtons}
        disableButtons={disableButtons}
      />
    </div>
  );
};
