import React, { useState } from 'react';
import './style.scss';
import { Tree } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Popup, Search, Tooltip } from 'components/Common';
import { getParentKey } from './helpers';
import { deleteEmptyRoute } from '../../../store/projects/projects.actions';
import { useDispatch } from 'react-redux';
import { USER_ROLES } from '../../../constants/common';

export const ExpandableTree = ({
  treeData,
  searchTree = false,
  defaultExpandAll = true,
  onSelect = () => {},
  selectedKeys = [],
  getProjectDetails,
  project,
  userRoles,
  ...rest
}) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [infoForDelete, setInfoForDelete] = useState(null);

  const dataList = [];

  const isSysAdmin = userRoles && userRoles.includes(USER_ROLES.sysAdmin);
  const isPM = userRoles && userRoles.includes(USER_ROLES.pm);

  const dispatch = useDispatch();
  const generateList = data => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, type, device } = node;
      dataList.push({ key, title: node.title, type, device });

      if (node.children) {
        generateList(node.children);
      }
    }
  };
  generateList(treeData);

  const onSearch = value => {
    const keys = dataList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(value ? keys : []);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const onExpand = keys => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const loop = data =>
    data.map(item => {
      const index = item.title.toLowerCase().indexOf(searchValue.toLowerCase());
      const beforeStr = item.title.substr(0, index);
      const searchedVal = item.title.substr(index, searchValue?.length);
      const afterStr = item.title.substr(index + searchValue?.length);
      const { icon, key, type, device } = item;
      let { deletable } = item;

      if (!isSysAdmin && !isPM && type === 'route') {
        deletable = false;
      }

      const title = (
        <>
          <div className="hidden-name">
            {item.title}
            <Tooltip title={item.title}>
              {index > -1 ? (
                <div className="title">
                  {beforeStr}
                  <span className="tree-search-value">{searchedVal}</span>
                  {afterStr}
                </div>
              ) : (
                <div className="title">{item.title}</div>
              )}
            </Tooltip>
          </div>
          {deletable && <DeleteOutlined />}
        </>
      );
      if (item.children) {
        return { title, key, children: loop(item.children), icon, type, device, deletable };
      }

      return {
        title,
        key,
        icon,
        type,
        device,
        deletable,
      };
    });

  const filterTreeNode = node => {
    if (!searchValue) return true;

    let hasSearchedVal = false;
    hasSearchedVal = checkChildrenBySearchVal(node, hasSearchedVal);
    return hasSearchedVal;
  };

  const checkChildrenBySearchVal = (node, hasSearchedVal) => {
    let v = hasSearchedVal;
    if (!!v) return v;
    v = node.title.props.children[0]?.props?.children[0].toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    if (!v && node.children && node.children?.length) {
      node.children.forEach(childNode => {
        v = checkChildrenBySearchVal(childNode, v);
      });
    }
    return v;
  };

  const cancelDeleteRouteConfirmation = () => {
    setOpenDeleteConfirmation(false);
    setInfoForDelete(null);
  };

  const submitRouteConfirmation = () => {
    dispatch(deleteEmptyRoute({ uuid: infoForDelete.event.node.key, projectId: project.uuid })).then(res => {
      getProjectDetails();
      cancelDeleteRouteConfirmation();
    });

    if (!infoForDelete.event.selected) {
      onSelect(
        infoForDelete.itemIds,
        infoForDelete.event.selectedNodes.map(node => {
          return { ...node, title: node.title?.props?.children?.[0] };
        })
      );
    }
  };

  return (
    <>
      <div className="expandable-tree">
        {searchTree && (
          <Search
            placeholder="Search"
            searchAction={() => {}}
            onChange={onSearch}
            button={false}
            value={searchValue}
            className="expandable-tree__search"
            allowSpecials
          />
        )}
        <Tree
          {...rest}
          className="expandable-tree__tree"
          showIcon
          defaultExpandAll={defaultExpandAll}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          switcherIcon={<DownOutlined />}
          treeData={searchTree ? loop(treeData) : treeData}
          onSelect={(itemIds, event) => {
            let isDeletable = event.node
              ? event.node.hasOwnProperty('deletable')
                ? event.node.deletable
                : false
              : false;
            let clickOnDelete =
              event.nativeEvent.target.tagName.toLowerCase() === 'path' ||
              event.nativeEvent.target.tagName.toLowerCase() === 'svg';
            if (isDeletable && !!clickOnDelete) {
              setOpenDeleteConfirmation(true);
              setInfoForDelete({ event: event, itemIds: itemIds });
            } else {
              onSelect(
                itemIds,
                event.selectedNodes.map(node => {
                  return { ...node, title: node.title?.props?.children?.[0] };
                })
              );
            }
          }}
          selectedKeys={selectedKeys}
          filterTreeNode={filterTreeNode}
        />
      </div>
      <Popup
        title="Remove route"
        open={openDeleteConfirmation}
        handleCancel={cancelDeleteRouteConfirmation}
        handleSubmit={submitRouteConfirmation}
        textSubmit="Confirm"
      >
        {`Please confirm that you want to remove ${infoForDelete?.event?.node?.title?.props?.children?.[0]?.props?.children?.[0]} from the current project`}
      </Popup>
    </>
  );
};
