import React, { useState } from 'react';
import './style.scss';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Select, Button, Search, DatePicker, DropdownButton, CreateExperimentModal } from '../../index';
import { dateMMDDYY, dateMMDDYYmmHH } from '../../../constants';
import { getStrTagPlaceholder } from '../../../utils';
import { SmilesSearcherAutoSin } from './SmilesSearcher';
import { setModalOpen } from '../../../store/experimentCreation/experimentCreation.action';

export const FiltersListProcess = ({
  createProcess,
  searchAction,
  valueSearch,
  onChangeSearch,
  filtersOptions,
  onChangeDropDowns,
  filtersValue,
  resetFilters,
  hadnlerDates,
  openPopupSaveFromRoute,
  dates,
  menu,
  searchType,
  onChangeSearchType,
  permissions,
  activeTab,
  isSynJet,
  isLab,
  actionOptionsProjects,
  foundProjects,
  actionOptionsCreators,
  selectedOptions,
  setSelectedOptions,
  isSynJetPro,
}) => {
  const [notCloseDropDown, setNotCloseDropDown] = useState(false);
  const dispatch = useDispatch();
  const onSelect = (field, value) => {
    let values;
    if (value === 'all') values = filtersOptions[field].map(i => i.value);
    else {
      values = [...filtersValue[field], value];
      if (values.length === filtersOptions[field].length - 1) values.push('all');
    }
    setNotCloseDropDown(true);
    onChangeDropDowns(field, values);
  };
  const onDeselect = (field, value) => {
    setNotCloseDropDown(true);
    if (value === 'all') onChangeDropDowns(field, []);
    else {
      onChangeDropDowns(
        field,
        filtersValue[field].filter(i => i !== value && i !== 'all')
      );
    }
  };

  const callAction = (search, creatorsInValue) => {
    if (search !== '') setNotCloseDropDown(false);
    else if (search === '' && !!creatorsInValue?.length) {
      setNotCloseDropDown(false);
    }
    return actionOptionsCreators(search, creatorsInValue);
  };

  const callActionProjects = (search, projectsInValue) => {
    if (search !== '') setNotCloseDropDown(false);
    else if (search === '' && !!projectsInValue?.length) {
      setNotCloseDropDown(false);
    }
    return actionOptionsProjects(search, projectsInValue);
  };

  return (
    <div className="filters-process">
      <div className="search-row">
        <SmilesSearcherAutoSin
          value={valueSearch}
          searchType={searchType}
          searchAction={searchAction}
          onChange={onChangeSearch}
          onChangeSearchType={onChangeSearchType}
        />
        {permissions?.process?.create_process && <DropdownButton menu={menu} text="Create a process" />}
      </div>
      <div className="group-selects">
        <Select
          classNameContainer="statuses-select"
          placeholder="All statuses"
          showSearch={false}
          showArrow
          maxTagPlaceholder={opts => getStrTagPlaceholder(opts, filtersOptions.statuses, 'statuses')}
          maxTagCount={0}
          mode="multiple"
          onSelect={(...args) => onSelect('statuses', ...args)}
          onDeselect={(...args) => onDeselect('statuses', ...args)}
          options={filtersOptions.statuses}
          value={filtersValue.statuses}
        />
        <DatePicker
          disabledDate={current => current && moment() < current}
          allowEmpty={[true, true]}
          value={dates}
          format={dateMMDDYY}
          onCalendarChange={hadnlerDates}
          range
        />
        <Select
          maxTagPlaceholder={opts => getStrTagPlaceholder(opts, filtersOptions.projects, 'projects')}
          classNameContainer="projects-select"
          placeholder="All projects"
          showArrow
          typeToSearch
          getOptions={search => callActionProjects(search, filtersValue.projects)}
          notFoundContent={false}
          hideValueOnFocus
          onSelect={(...args) => onSelect('projects', ...args)}
          onDeselect={(...args) => onDeselect('projects', ...args)}
          value={filtersValue.projects}
          options={filtersOptions.projects}
          maxTagCount={0}
          mode="multiple"
          notCloseDropDown={notCloseDropDown}
          onBlur={e => {
            {
              // callActionProjects('', [], true);
              setSelectedOptions([]);
              setNotCloseDropDown(true);
            }
          }}
          noClearing
          onFocus={e => {
            if (filtersValue.projects.length) {
              callActionProjects('', filtersValue.projects);
            }
          }}
          selectedOptions={selectedOptions}
        />
        <Select
          classNameContainer="creators-select"
          placeholder="All creators"
          showArrow
          hideValueOnFocus
          maxTagPlaceholder={opts => getStrTagPlaceholder(opts, filtersOptions.createdBy, 'creators')}
          maxTagCount={0}
          mode="multiple"
          onSelect={(...args) => onSelect('createdBy', ...args)}
          onDeselect={(...args) => onDeselect('createdBy', ...args)}
          value={filtersValue.createdBy}
          options={foundProjects}
          notFoundContent={false}
          typeToSearch
          getOptions={search => callAction(search, filtersValue.createdBy)}
          notCloseDropDown={notCloseDropDown}
          onBlur={e => {
            {
              setSelectedOptions([]);
              setNotCloseDropDown(false);
            }
          }}
          noClearing
          onFocus={e => {
            if (filtersValue.createdBy.length) {
              callAction('', filtersValue.createdBy);
            }
          }}
          selectedOptions={selectedOptions}
        />
        <Button secondary onClick={resetFilters}>
          Reset filters
        </Button>
      </div>
      <CreateExperimentModal isSynJet={isSynJet} isLab={isLab} showFirstStepIsLab isSynjetPro={isSynJetPro} />
    </div>
  );
};
