import React from 'react';
import { Popup } from '../..';

export const PopupEditExperiment = ({ editModalOpen, handleEditConfirmation, setEditModalOpen }) => (
  <Popup
    open={editModalOpen}
    title="Edit the experiment"
    handleSubmit={handleEditConfirmation}
    textSubmit="Proceed"
    textCancle="Discard"
    handleCancel={() => setEditModalOpen(null)}
  >
    <span>Are you sure you want to proceed? Experiment data will be changed.</span>
  </Popup>
);
