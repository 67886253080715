import React from 'react';
import { Spinner } from '../Common/Spinner';
import './style.scss';
import { useSelector } from 'react-redux';
import { globalLoading } from '../../store/common/common.selector';

const GlobalSpinner = ({ children }) => {
  const loading = useSelector(globalLoading);
  return (
    <Spinner wrapperClassName="global-spinner" loading={loading}>
      {children}
    </Spinner>
  );
};

export default GlobalSpinner;
