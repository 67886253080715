import React, { useCallback, useEffect, useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Select } from '../../../Common';
import {
  getHeatingBlocksTemperatureOptions,
  getHeatingBlockStyle,
  isValidHeatingBlocks,
} from '../../../../utils/execution';

export const HeatingBlock = ({
  heatingBlocks,
  temperatureOptions,
  handleHeatingBlocksChange,
  setDefaultHeatingBlocks,
  setConditionsErrors,
}) => {
  const isValid = useMemo(() => isValidHeatingBlocks(temperatureOptions, heatingBlocks), [
    heatingBlocks,
    temperatureOptions.length,
  ]);

  useEffect(() => setConditionsErrors(isValid ? null : 'All temperatures should be used in Heating block'), [isValid]);

  const getOptions = useCallback(
    currentValue => getHeatingBlocksTemperatureOptions(temperatureOptions, heatingBlocks, false, currentValue),
    [heatingBlocks, temperatureOptions.length]
  );

  const getStyles = useCallback(color => getHeatingBlockStyle(color, temperatureOptions), [
    heatingBlocks,
    temperatureOptions.length,
  ]);

  return (
    <div className="heating-block">
      <div className="heating-block__header">
        <p className="title">Heating block</p>
        <Button type="primary" onClick={setDefaultHeatingBlocks}>
          Default assignment
        </Button>
      </div>
      <div className="block-list">
        {!isValid && (
          <div className="error">
            <InfoCircleOutlined className="icon" />
            All temperatures should be used in Heating block
          </div>
        )}
        {heatingBlocks.map(block => (
          <div className="block-item">
            <span className="block-item__name">{block.label}</span>
            <div className="block" style={getStyles(block.temperature)} />
            <span className="block-item__name">&mdash;</span>
            <Select
              defaultValue="N/A"
              options={getOptions(block.temperature)}
              value={block.temperature}
              onChange={value => handleHeatingBlocksChange(block.label, value)}
            />
          </div>
        ))}
      </div>
      <div className="finish-plate">Finish Plate</div>
    </div>
  );
};
