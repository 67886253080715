import { parseProcessBuilderData } from './parseProcessBuilderData';
import { getReactorsTypes } from '../store/processBuilder/processbuilder.actions';

const getAllPropertiesEntity = ({ data, field, entity }) => {
  return data[field].find(j => j.name === entity.name).properties;
};

const setTimeToHour = sec => {
  if (typeof sec !== 'number') return;
  return Math.floor((sec / 3600) * 100) / 100;
};

export const parseExpToRouteAutoSyn = ({ experiment }) => dispatch => {
  const route = {
    routes: [],
    cpds: {},
    rxns: {},
    strategyRouteIndex: {},
  };

  const data = JSON.parse(experiment.reactionsInfo);
  data.compounds.forEach(i => (route.cpds[i.compound_id] = i));
  return dispatch(getReactorsTypes(true)).then(reactorsResp => {
    let liquidReactorPumps = [];
    let reactionInfo = parseProcessBuilderData(data, reactorsResp, experiment.process.type)
      .filter(i => i.name)
      .map(i => {
        if (i.type === 'liquid') {
          liquidReactorPumps = [...liquidReactorPumps, ...i.pumps.slice(1)];
          return null;
        } else {
          const reactor = { ...i, pumps: [...i.pumps, ...liquidReactorPumps] };
          liquidReactorPumps = [];
          return reactor;
        }
      })
      .filter(i => i);
    reactionInfo = reactionInfo.map(i => ({
      ...i,
      properties: getAllPropertiesEntity({ data, field: 'reactors', entity: i }),
      pumps: i.pumps.map(j => ({ ...j, properties: getAllPropertiesEntity({ data, field: 'pumps', entity: j }) })),
    }));
    data.reactions.forEach((reaction, idxReaction) => {
      const routeReaction = {
        name: `${experiment.key}-RR-${idxReaction + 1}`,
        route_segments: [],
      };
      const reactorsCurrentReaction = reactionInfo.map(i => ({
        ...i,
        properties: i.properties[idxReaction],
        pumps: i.pumps.map(j => ({ ...j, properties: j.properties[idxReaction] })),
      }));
      const reactorsGroups = [];
      let group = [];
      reactorsCurrentReaction.forEach(i => {
        if (i.properties.product) {
          group.push(i);
          reactorsGroups.push(group);
          group = [];
        } else group.push(i);
      });
      routeReaction.route_segments = [
        [
          ...reactorsGroups.map((group, idxGroup, arr) => {
            const rxn_id = `reactor_${idxReaction}_${idxGroup}`;
            let reactants = [];
            const currentProduct = group.find(reactor => !!reactor?.properties?.product)?.properties?.product;
            group.forEach(reactor =>
              reactor.pumps.forEach(pump => {
                const reactantsPump = pump?.properties?.reactants;
                if (reactantsPump.length) reactants = [...reactants, ...reactantsPump];
              })
            );
            const reactantsIds = reactants.map(reactant => reactant.reactant);
            const prevProduct = arr[idxGroup - 1]?.find(reactor => !!reactor?.properties?.product)?.properties?.product;
            if (prevProduct) reactants.unshift(prevProduct);
            route.rxns[rxn_id] = {
              reactants: reactantsIds,
              products: [currentProduct],
              variations: [
                {
                  yields: [[reaction.compound_id, '']],
                  conditions: group.map(reactor => {
                    return {
                      solvents: reactor.pumps.reduce(
                        (accum, current) => [...accum, ...current.properties.solvents.map(reagent => reagent.solvent)],
                        []
                      ),
                      reagents: reactor.pumps.reduce(
                        (accum, current) => [...accum, ...current.properties.reagents.map(reagent => reagent.reagent)],
                        []
                      ),
                      catalysts: reactor.catalysts.map(i => i.compound_id),
                      temps: [reactor.properties.temperature],
                      times: [setTimeToHour(+reactor.properties.time)],
                    };
                  }),
                },
              ],
            };
            return {
              feedstockIds: [],
              prod_id: currentProduct,
              rxn_id: rxn_id,
              branchIds: reactantsIds,
            };
          }),
        ],
      ];
      route.strategyRouteIndex[`S${0}R${idxReaction}`] = routeReaction;
      route.routes.push(routeReaction);
    });
    return route;
  });
};
