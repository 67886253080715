import { getExtensibleTableFieldsGrid } from '../../experiment/experiment.actions';
import { error, finish, loading, set } from './slice';

export const fetchData = () => async dispatch => {
  try {
    dispatch(loading());
    const data = await getExtensibleTableFieldsGrid();
    if (data) {
      dispatch(set({ data }));
    }
  } catch (err) {
    dispatch(error({ error: err }));
  } finally {
    dispatch(finish());
  }
};
