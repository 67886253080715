import { http } from '../../utils/http';
import * as actionsTypes from './autosyn.actionTypes';
import {
  queryListProcess,
  queryFiltersProcess,
  mutationUpdateProcessName,
  queryRoutesOfProject,
  queryRouteDetailsFromSave,
  queryProcessDetails,
  queryGetTypesProcesses,
  mutationUpdateProcess,
  queryProcessStructure,
  queryFiltersCreators,
} from '../graphql';
import { PROCESS_BUILDER_ERROR } from '../../constants';

const setProcessesList = payload => ({ type: actionsTypes.SET_PROCESSES_LIST, payload });
const setFiltersData = payload => ({ type: actionsTypes.SET_FILTERS_DATA_PROCESS, payload });
const updateNameInList = payload => ({ type: actionsTypes.SET_NAME_IN_LIST, payload });
export const setProcess = payload => ({ type: actionsTypes.SET_PROCESS, payload });
const setTypesProcess = payload => ({ type: actionsTypes.SET_TYPES_PROCESS, payload });
const setFiltersCreators = payload => ({ type: actionsTypes.SET_FILTERS_CREATORS, payload });

export const getListProcesses = (filters, tab, noSetToStore = false) => dispatch => {
  const page = filters?.page;
  delete filters.page;
  return http(queryListProcess('send', null, page, filters, tab)).then(resp => {
    if (noSetToStore) return queryListProcess('get', resp);
    dispatch(setProcessesList(queryListProcess('get', resp, page)));
  });
};

export const getFiltersProcesses = type => dispatch => {
  return http(queryFiltersProcess('send', null, null, type)).then(resp => {
    const filters = queryFiltersProcess('get', resp);
    dispatch(setFiltersData(filters));
    return filters;
  });
};

export const getFiltersCreators = (filters, search) => dispatch => {
  return http(queryFiltersCreators('send', null, filters, search)).then(resp => {
    const creators = queryFiltersCreators('get', resp);
    dispatch(setFiltersCreators(creators));
    return creators;
  });
};

export const updateProcessName = data => dispatch => {
  return http(mutationUpdateProcessName('send', data)).then(resp => {
    const processedName = mutationUpdateProcessName('get', resp);
    if (!processedName.errors) dispatch(updateNameInList(mutationUpdateProcessName('get', resp)));
    else throw processedName.errors[0].messages[0];
  });
};

export const getRoutesOfProject = data => {
  return http(queryRoutesOfProject('send', data)).then(resp => {
    return queryRoutesOfProject('get', resp);
  });
};

export const getRouteDetailsFromSave = data => {
  return http(queryRouteDetailsFromSave('send', data)).then(resp => {
    return queryRouteDetailsFromSave('get', resp);
  });
};

export const getProcess = (data, noUpdateStore) => dispatch => {
  return http(queryProcessDetails('send', null, data)).then(resp => {
    let new_resp = resp;
    if (typeof resp === 'string' && resp.includes(PROCESS_BUILDER_ERROR)) {
      new_resp = { error: true };
    }
    if (noUpdateStore) {
      return queryProcessDetails('get', new_resp, data);
    }
    dispatch(setProcess(queryProcessDetails('get', new_resp, data)));
  });
};

export const getProcessStructure = (data, noUpdateStore) => dispatch => {
  return http(queryProcessStructure('send', null, data)).then(resp => {
    return queryProcessStructure('get', resp, data);
  });
};

export const getTypesProcesses = data => dispatch => {
  return http(queryGetTypesProcesses('send', data)).then(resp => {
    dispatch(setTypesProcess(queryGetTypesProcesses('get', resp)));
  });
};

export const updateProcess = data => dispatch => {
  return http(mutationUpdateProcess('send', data)).then(resp => {
    const processedData = mutationUpdateProcess('get', resp);
    if (!processedData.errors) dispatch(getProcess(processedData.uuid));
    else throw 'error update steps';
  });
};
