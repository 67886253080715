import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkSpace } from '../../ProcessBuilder/WorkSpace/WorkSpace';
import './style.scss';
import { StatusLabel } from '../../Common/StatusLabel';
import { Button, openNotification, Popup } from '../../Common';
import { Item } from './Item';
import { getPumpKeys } from '../../../store/processBuilder/processbuilder.actions';
import { deviceOnline, pumpsOnline, reactorsOnline } from '../../../store/experiment/experiment.selector';
import classNames from 'classnames';
import { getProcessDeviceStatus } from '../../../store/experiment/experiment.actions';

export const ReviseTheDetails = ({
  experiment,
  definition,
  deviceSetUp,
  setDeviceSetUp,
  stepErrors,
  setErrors,
  processStructure,
  setEdited,
  remoteConnectivity,
  viewMode = false,
}) => {
  const dispatch = useDispatch();
  const isDeviceOnline = useSelector(deviceOnline);
  const onlinePumps = useSelector(pumpsOnline);
  const onlineReactors = useSelector(reactorsOnline);
  const [resetModal, setResetModal] = useState(false);

  useEffect(() => {
    if (experiment?.timeSlot?.device?.uuid) {
      checkDeviseStatus();
    }
  }, [experiment?.timeSlot?.device?.uuid]);

  const checkDeviseStatus = async () => {
    try {
      const data = await dispatch(
        getProcessDeviceStatus(experiment.timeSlot.device.uuid, experiment.timeSlot.device.name)
      );
      if (!data.device) {
        openNotification(null, 'Experiment could not be launched due to execution service connection issues');
      }
      if (!data.connection) {
        openNotification(null, 'Hardware connectivity issue occurred, please wait until connection is restored');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetSetup = () => {
    setResetModal(false);
    setDeviceSetUp(JSON.parse(experiment.defaultPumpKeys));
  };

  const handleSetupChange = (item, value) => {
    setEdited(true);
    const prevSetUp = deviceSetUp.find(device => device.name === item);
    setErrors({ ...stepErrors, [prevSetUp?.key]: null });
    setDeviceSetUp({
      ...deviceSetUp,
      [item]: value,
    });
    const newDeviceSetUp = prevSetUp
      ? deviceSetUp.map(device => (device.name === item ? { ...device, key: value } : device))
      : [...deviceSetUp, { name: item, key: value }];
    setDeviceSetUp(newDeviceSetUp);
  };

  const getPumps = (search, maxFlowRate) => {
    return dispatch(getPumpKeys(search, experiment?.timeSlot?.device.uuid, maxFlowRate)).then(data => {
      return data;
    });
  };

  return (
    <div className="revise-details-wrapper">
      <div className="left-part">
        {!viewMode && (
          <div className="name-wrapper">
            <div className="name">{experiment?.name}</div>
            <StatusLabel type="active">{experiment?.status}</StatusLabel>
          </div>
        )}
        <div className={'device-name-wrapper'}>
          <span className={classNames('device-name', { 'device-name--error': !isDeviceOnline || !remoteConnectivity })}>
            {experiment?.timeSlot?.device?.name}
          </span>
        </div>
        {processStructure.map(processItem => (
          <Item
            processItem={processItem}
            getOptions={getPumps}
            deviceSetUp={deviceSetUp}
            handleSetupChange={handleSetupChange}
            errors={stepErrors}
            isAutosynOnline={isDeviceOnline}
            onlinePumps={onlinePumps}
            onlineReactors={onlineReactors}
            remoteConnectivity={remoteConnectivity}
            viewMode={viewMode}
          />
        ))}
        {!viewMode && (
          <Button
            type="secondary"
            onClick={() => {
              setEdited(true);
              setResetModal(true);
            }}
            className="reset-button"
          >
            Reset to default
          </Button>
        )}
      </div>
      {!viewMode && <div className="right-part">{definition && <WorkSpace previewMode data={definition} />}</div>}
      <Popup
        open={resetModal}
        title="Warning"
        textSubmit="Reset"
        handleSubmit={handleResetSetup}
        handleCancel={() => setResetModal(false)}
      >
        Do you want to reset to system suggested controllable setup?
      </Popup>
    </div>
  );
};
