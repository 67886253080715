import React, { useEffect, useState } from 'react';
import { ManualWorkArea } from 'components/ProcessBuilder/WorkSpace/ManualWorkArea';
import { parseProcessBuilderDataFromBackend } from 'utils/manualProcessBuilder';
import './styles.scss';

export const LabExperimentProgress = ({ process, description }) => {
  const [processData, setProcessData] = useState([]);

  useEffect(() => {
    if (process) {
      let { parsedData } = parseProcessBuilderDataFromBackend(process.definition, process.type);
      setProcessData(parsedData);
    }
  }, [process]);
  return (
    <div className="work-space-view-mode lab-experiment-progress-workspace">
      <div className="lab-experiment-progress-title">Process</div>
      <div className="view-mode-wrapper">
        <ManualWorkArea data={processData} description={description} processType={process?.type} editMode={false} />
      </div>
    </div>
  );
};
