import React, { useEffect, useState } from 'react';
import { Button, Spinner, NoResultsEmptyState } from '../../Common';
import './style.scss';
import no_experiment_results from 'dist/images/no-experiment-results.svg';
import { useDispatch, useSelector } from 'react-redux';
import { user } from 'store/common/common.selector';
import { PopupAppendResults } from './PopupAppendResults';
import { ResultsInfo } from './ResultsInfo';
import { getAppendedResult } from '../../../store/experiment/experiment.actions';
import { appended_result } from '../../../store/experiment/experiment.selector';
import { PageContainer } from '../../PdfGenerator/PageContainer';

export const ResultsTab = ({ pdfMode, data, isSynJet, isLabDevice, getExperiment, isSynJetPro }) => {
  const [openAppendResults, setOpenAppendResults] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(user);
  const canAppendResults = true; /*! !currentUser?.permissions?.experiment?.create_experiment_result */
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    if (data) {
      updateResult();
    }
  }, [data]);
  const updateResult = async () => {
    if (data.uuid) {
      setLoading(true);
      await dispatch(getAppendedResult({ experimentId: data.uuid })).then(resp => {
        setResultData(resp);
      });
      setLoading(false);
    }
  };

  const openAppendResultPoppup = emptyData => {
    if (emptyData) setResultData(null);
    setOpenAppendResults(true);
  };
  if (!resultData && pdfMode) {
    return <></>;
  }
  const Child = (
    <div className="results-tab">
      <div data-pdf-node className="results-tab__title">
        <span>Outcome</span>
        {resultData && !pdfMode ? (
          <Button onClick={() => openAppendResultPoppup()} secondary>
            Update results
          </Button>
        ) : null}
      </div>
      {!resultData ? (
        <NoResultsEmptyState canAppendResults={canAppendResults} appendResults={() => openAppendResultPoppup(true)} />
      ) : (
        <ResultsInfo
          resultData={resultData}
          experimentData={data}
          isSynJet={isSynJet}
          isSynJetPro={isSynJetPro}
          isLabDevice={isLabDevice}
        />
      )}
      <PopupAppendResults
        resultData={resultData}
        isSynJet={isSynJet}
        isSynJetPro={isSynJetPro}
        open={openAppendResults}
        handleCancel={() => setOpenAppendResults(false)}
        experimantData={data}
        updateResult={updateResult}
        isLabDevice={isLabDevice}
        getExperiment={getExperiment}
      />
    </div>
  );

  if (pdfMode) {
    return <PageContainer>{Child}</PageContainer>;
  }
  return <Spinner loading={loading}>{Child}</Spinner>;
};
