import React from 'react';
import cn from 'classnames';

export const Tubular = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6739 15.9184C22.6739 20.1595 21.614 23.9513 19.9531 26.6503C18.2822 29.3654 16.1062 30.8367 13.8779 30.8367C11.6497 30.8367 9.47371 29.3654 7.80285 26.6503C6.14194 23.9513 5.08203 20.1595 5.08203 15.9184C5.08203 11.6772 6.14194 7.88546 7.80285 5.18648C9.47371 2.47132 11.6497 1 13.8779 1C16.1062 1 18.2822 2.47132 19.9531 5.18648C21.614 7.88546 22.6739 11.6772 22.6739 15.9184Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M29.0293 15.9184C29.0293 20.1595 27.9694 23.9513 26.3085 26.6503C24.6377 29.3654 22.4617 30.8367 20.2334 30.8367C18.0051 30.8367 15.8292 29.3654 14.1583 26.6503C12.4974 23.9513 11.4375 20.1595 11.4375 15.9184C11.4375 11.6772 12.4974 7.88546 14.1583 5.18648C15.8292 2.47132 18.0051 1 20.2334 1C22.4617 1 24.6377 2.47132 26.3085 5.18648C27.9694 7.88546 29.0293 11.6772 29.0293 15.9184Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M34.918 15.9184C34.918 20.1595 33.8581 23.9513 32.1972 26.6503C30.5263 29.3654 28.3504 30.8367 26.1221 30.8367C23.8938 30.8367 21.7179 29.3654 20.047 26.6503C18.3861 23.9513 17.3262 20.1595 17.3262 15.9184C17.3262 11.6772 18.3861 7.88546 20.047 5.18648C21.7179 2.47132 23.8938 1 26.1221 1C28.3504 1 30.5263 2.47132 32.1972 5.18648C33.8581 7.88546 34.918 11.6772 34.918 15.9184Z"
        stroke="white"
        strokeWidth="2"
      />
      <line x1="35.918" y1="15.918" x2="39.9996" y2="15.918" stroke="white" strokeWidth="2" />
      <line y1="15.918" x2="4.08163" y2="15.918" stroke="white" strokeWidth="2" />
    </svg>
  </div>
);
