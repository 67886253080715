import './style.scss';

import React, { useMemo } from 'react';

import { getDefaultValue } from '@templates/ExperimentsTable/utils';
import { Select as AntdSelect } from 'antd';
import cn from 'classnames';
import { Checkbox, DatePicker, Input, Select } from 'components';

import { OptionWithColor, TextBody14 } from '@atoms';
import { FIELD_DATA_TYPES } from '@organisms';

import { SmilesSearcher } from '../../../../components/SmilesSearcher';
import { dateMMDDYY, dateMMDDYYmmHH } from '../../../../constants';
import { useExperimentDetailsContext } from '../hooks/useExperimentDetailsController';
import { ExperimentSelect } from './ExperimentSelect';
import { UserSelect } from './UserSelect';

const { Option } = AntdSelect;

export const ExperimentField = ({ style, data: experimentFieldsData, index }) => {
  const { dataType, name, editable, availableOptions, fieldName, uuid, archived } = experimentFieldsData[index];
  const { editExperimentFields, editableExperimentFields } = useExperimentDetailsContext();
  const value = editableExperimentFields[fieldName];
  const onChange = val => {
    editExperimentFields({
      value: val,
      fieldName,
      dataType,
      uuid,
    });
  };
  const renderDataTypesEditMode = () => {
    const baseProps = {
      value,
      disabled: archived,
      onChange,
    };
    switch (dataType) {
      case FIELD_DATA_TYPES.select:
        const optionsSelect = [...availableOptions];
        if (!!value && !optionsSelect.find(option => option.value === value.value)) {
          optionsSelect.push(value);
        }
        return (
          <Select
            allowClear={!!value}
            {...baseProps}
            options={optionsSelect}
            value={value ? <OptionWithColor color={value.color} label={value.label} /> : value}
            onChange={newValue => onChange(newValue ? JSON.parse(newValue) : null)}
          >
            {optionsSelect.map(option => (
              <Option value={JSON.stringify(option)}>
                <OptionWithColor color={option.color} label={option.label} />
              </Option>
            ))}
          </Select>
        );
      case FIELD_DATA_TYPES.multiselect:
        const optionsMultiSelect = [...availableOptions];
        if (value) {
          value.forEach(valueItem => {
            const parsedValueItem = JSON.parse(valueItem);
            if (!optionsMultiSelect.find(option => option.value === parsedValueItem.value)) {
              optionsMultiSelect.push(parsedValueItem);
            }
          });
        }
        return (
          <Select
            {...baseProps}
            mode={FIELD_DATA_TYPES.multiselect === dataType ? 'multiple' : ''}
            options={optionsMultiSelect}
            value={value || []}
            onChange={newValue => onChange(newValue)}
          >
            {optionsMultiSelect.map(option => (
              <Option value={JSON.stringify(option)}>
                <OptionWithColor color={option.color} label={option.label} />
              </Option>
            ))}
          </Select>
        );
      case FIELD_DATA_TYPES.user:
        return <UserSelect {...baseProps} />;
      case FIELD_DATA_TYPES.short_text:
        return <Input {...baseProps} maxLength={256} placeholder="Type your text here" type="text" />;
      case FIELD_DATA_TYPES.long_text:
        return <Input {...baseProps} maxLength={1024} placeholder="Type your text here" type="text" />;
      case FIELD_DATA_TYPES.numeric:
        return (
          <Input
            {...baseProps}
            max={100000000000000}
            min={-100000000000000}
            placeholder="Type your number here"
            type="number"
            onChange={newValue => {
              if (newValue) {
                onChange(parseInt(newValue));
              } else {
                onChange(newValue);
              }
            }}
          />
        );
      case FIELD_DATA_TYPES.decimal:
        return (
          <Input
            {...baseProps}
            max={100000000000000}
            min={-100000000000000}
            placeholder="Type your number here"
            type="number"
            onChange={newValue => {
              if (newValue) {
                onChange(parseFloat(newValue));
              } else {
                onChange(newValue);
              }
            }}
          />
        );
      case FIELD_DATA_TYPES.boolean:
        return <Checkbox {...baseProps} />;
      case FIELD_DATA_TYPES.datetime:
        return <DatePicker {...baseProps} format={dateMMDDYYmmHH} placeholder="Select date and time" time date />;
      case FIELD_DATA_TYPES.date:
        return <DatePicker {...baseProps} format={dateMMDDYY} placeholder="Select date" />;
      case FIELD_DATA_TYPES.experiment:
        return <ExperimentSelect {...baseProps} />;
      case FIELD_DATA_TYPES.smile:
        if (archived && !value) {
          return '-';
        }
        return (
          <SmilesSearcher
            onDeleteAction={() => onChange(null)}
            enableToDelete={!archived}
            changeSelect={onChange}
            smilesPlaceholder="Search"
            data={value}
          />
        );
      default:
        return 'N/A';
    }
  };

  const child = useMemo(() => (editable ? renderDataTypesEditMode() : getDefaultValue(dataType, value)), [
    editable,
    value,
  ]);

  return (
    <>
      <div className={cn('experiment-field', { archived })} style={style}>
        <TextBody14 className="experiment-field_label" weight="bold">
          {name}
        </TextBody14>
        <div className="experiment-field_value">{child}</div>
      </div>
    </>
  );
};
