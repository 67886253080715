import React, { useEffect, useState } from 'react';
import { DescriptionSidebar, Footer, NotesSidebar, Steps } from '../../../components/Execution';
import { useDispatch, useSelector } from 'react-redux';
import '../style.scss';
import { useParams } from 'react-router-dom';
import { openNotification, Popup, Spinner } from 'components/Common';
import { EXPERIMENT_STATUSES, PROCESS_TYPES, PROJECT_TYPES } from 'constants/common';
import history from 'utils/history';
import { notes } from 'store/experiment/experiment.selector';
import { user } from 'store/common/common.selector';
import { parseProcessBuilderDataFromBackend } from 'utils/manualProcessBuilder';
import { LabExperimentProgress } from 'components/Execution/LabExperimentProgress/LabExperimentProgress';
import { ExperimentTimer } from 'components/Execution/ExperimentProgress/ExperimentTimer';
import { ExperimentFinalization } from 'components/Execution/ExperimentFinalization/ExperimentFinalization';
import { DeclineExperimentPopup } from 'components/Experiment/DeclineExperimentPopup';
import { parseExpLabToRoute } from '../../../utils/parseRoute/parseExpLabToRoute';
import { initialErrors } from '../../../components/Experiment/ResultsTab/PopupAppendResults';
import { parseJSONErrors, lengthObj } from '../../../utils';
import { LabExperimentFinalization } from '../../../components/Execution/LabExperimentFinalization/LabExperimentFinalization';
import { LabPrepareMaterials } from '../../../components/Execution/LabPrepareMaterials';
import { SynJetPrepareMaterials } from '../../../components/Execution/SynJetExecution/SynJetPrepareMaterials';
import {
  completeExperiment,
  finalizeExperiment,
  abortExperiment,
  getExperiment,
  getNotes,
  launchExpLab,
  saveExperimentResult,
  setRouteStructureFromExp,
} from '../../../store/experiment/experiment.actions';

const finalizationCheckList = {
  removeReagents: { label: 'Remove reagent and solvent bottles', checked: false },
  emptyWaste: { label: 'Empty waste', checked: false },
  collectProduct: { label: 'Collect product', checked: false },
};

const defaultLettersLG = ['A', 'B', 'C', 'D', 'G', 'F'];
const defaultCheckList = [
  { item: 'Confirm equipment temperature', is_checked: false },
  { item: 'Confirm all reactants/reagents/solvents added', is_checked: false },
  { item: 'Confirm atmosphere', is_checked: false },
];

export const LabDetails = () => {
  const dispatch = useDispatch();
  const { key: experimenKey } = useParams();
  const [experimentID, setExperimentId] = useState(null);
  const notesList = useSelector(notes);
  const currentUser = useSelector(user);
  const [activeStep, setActiveStep] = useState(1);
  const [data, setData] = useState(null);
  const [experimentLoading, setExperimentLoading] = useState(false);
  const [showNotesSidebar, setShowNotesSidebar] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showDescriptionSidebar, setShowDescriptionSidebar] = useState(false);
  const [description, setDescription] = useState('');

  const [openEndConfirmation, setOpenEndConfirmation] = useState(false);
  const [openCompleteConfirmation, setOpenCompleteConfirmation] = useState(false);
  const [openFinalizeConfirmation, setOpenFinalizeConfirmation] = useState(false);
  const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);
  const [finalizationOptions, setFinalizationOptions] = useState(finalizationCheckList);

  // timer on 2nd step
  const [actualTime, setActualTime] = useState(new Date());
  const [estimatedTime, setEstimatedTime] = useState(0);
  // step - 1
  const [edited, setEdited] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [listCheckboxes, setListCheckboxes] = useState(defaultCheckList);

  const [finalizationData, setFinalizationData] = useState({
    reactions: [],
    comments: {},
    appendedFiles: {},
  });
  const [finalizationErrors, setFinalizationErrors] = useState({ ...initialErrors });

  useEffect(() => {
    getDetails();
  }, [experimenKey]);

  useEffect(() => {
    if (data) {
      setEstimatedTime(data?.totalTime);
      if (data?.status === 'Completed') {
        setActualTime(data?.actualTime);
      } else if (data?.execution?.startedAt) setActualTime(data?.execution?.startedAt);
    }
  }, [data]);

  const groupOpt = properties => {
    const groups = {};
    properties.forEach((j, idx) => {
      const unicId = `${j.density}-${j.equivalents}-${j.mass}-${j.moles}-${j.compound || j.solvent}`;
      if (groups[unicId]) groups[unicId].keys.push(idx);
      else groups[unicId] = { data: j, keys: [idx] };
    });
    return groups;
  };

  const processTableData = exp => {
    const arr = [];
    const reactionInfo = JSON.parse(exp.reactionsInfo);
    reactionInfo.inputs.forEach((i, idx) => {
      let groups;

      const isLG = exp.process.type === PROJECT_TYPES.LIBRARY_GENERATION;
      const isOpt = exp.process.type === PROJECT_TYPES.OPTIMIZATION;
      groups = groupOpt(i.properties);
      const isVariations = lengthObj(groups) > 1;
      Object.keys(groups).forEach((c, cdx, cArr) => {
        const dataProperty = groups[c].data;
        const cpds = reactionInfo.compounds;
        const compound = cpds.find(
          k => k.compound_id === dataProperty.compound || k.compound_id === dataProperty.solvent
        );
        const isSolution = dataProperty.reagent_type === 'Solution';
        const isSolvent = dataProperty.type === 'Solvent' && !isSolution;
        let rowSpan = 1;
        let order = idx + 1;
        if (isOpt) rowSpan = cdx === 0 ? cArr.length : 0;
        if (isLG && isVariations) order = `${idx + 1} ${defaultLettersLG[cdx]}`;
        arr.push({
          allData: dataProperty,
          order,
          compound: {
            ...compound,
          },
          useBlueBlocks: isVariations && isOpt,
          uuidExp: exp.uuid,
          nameInput: i.name,
          solvent_volume: { value: dataProperty?.solvent_volume },
          volume_calc: { value: dataProperty?.volume },
          mass_calc: { value: dataProperty.mass },
          has_sent: {
            value: !!dataProperty.actual_volume || !!dataProperty.actual_mass || !!dataProperty.actual_solvent_volume,
          },
          solvent_volume_act: { value: dataProperty.actual_solvent_volume || '' },
          volume_act: { value: dataProperty.actual_volume || '' },
          mass_act: { value: dataProperty.actual_mass || '' },
          solvent: { value: isSolution ? cpds.find(k => k.compound_id === dataProperty.solvent) : null },
          keys: groups[c].keys,
          variation_index: isVariations ? cdx + 1 : null,
          rowSpan,
          amount: { value: dataProperty.moles },
          isSolvent,
          density: { value: dataProperty.density },
          mw: { value: compound.molwt },
          concentration: { value: dataProperty.concentration },
        });
      });
    });
    return arr;
  };

  const getDetails = (skipStepNavigation = false) => {
    setExperimentLoading(true);
    dispatch(getExperiment(experimenKey)).then(resp => {
      setExperimentId(resp.uuid);
      setData(resp);
      setDescription(parseProcessBuilderDataFromBackend(resp.process?.definition, resp.process?.type)?.description);
      if (resp.step) setActiveStep(resp.step + 1);
      else setActiveStep(1);
      updateNotesList({ experimentId: resp.uuid });
      setExperimentLoading(false);
      setTableData(processTableData(resp));
    });
  };

  const onContinue = () => {
    if (activeStep === 1) {
      launchExpLab(experimentID).then(() => {
        getDetails();
        setActiveStep(2);
      });
    } else if (activeStep === 2) {
      setActiveStep(3);
    } else if (activeStep === 3) {
    }
  };

  const endExperimentAction = () => {
    setExperimentLoading(true);
    dispatch(abortExperiment(experimentID)).then(resp => {
      if (resp.errors) {
        handleError(resp.errors);
      } else {
        setRouteStructureFromExp({
          uuid: data.uuid,
          data: JSON.stringify(JSON.stringify(parseExpLabToRoute({ experiment: data }))),
        });
        getDetails();
      }
      setOpenEndConfirmation(false);
    });
  };

  const finalizeExperimentAction = () => {
    setExperimentLoading(true);
    dispatch(finalizeExperiment(experimentID)).then(resp => {
      if (resp.errors) {
        handleError(resp.errors);
      } else getDetails();
      setOpenFinalizeConfirmation(false);
    });
  };

  const handleError = errors => {
    setExperimentLoading(false);
    errors.forEach(error => {
      error.messages.forEach(msg => openNotification(null, msg));
    });
  };

  const updateNotesList = body => {
    dispatch(getNotes(body));
  };

  let disableContinue = false;

  if (activeStep === 1) {
    disableContinue = !!listCheckboxes.find(i => !i.is_checked) || !!tableData.find(i => !i.has_sent.value);
  }

  const completeExperimentAction = async goToAppendResults => {
    const { comments, appendedFiles, reactions } = finalizationData;
    const { workup, purification, purificationComment } = comments;
    const productsInfo = [...reactions];

    if (purification !== 'None' && !purificationComment) {
      setFinalizationErrors({ ...finalizationErrors, purificationComment: true });
      return;
    }

    if (finalizationErrors.time.find(timeItem => !!timeItem)) {
      return;
    }

    const res = {
      experiment: data.uuid,
      workup: workup ? encodeURIComponent(workup) : null,
      purificationType: purification,
      purificationDescription: purificationComment ? encodeURIComponent(purificationComment) : null,
      productsInfo: productsInfo.map(reaction => {
        const { name, ...restReactionData } = reaction;
        return {
          ...restReactionData,
          mass: reaction.mass ? reaction.mass.toString().substr(0, 10) : reaction.mass,
          product_yield: reaction.product_yield
            ? reaction.product_yield.toString().substr(0, 10)
            : reaction.product_yield,
          start_collection: reaction.start_collection.format('HH:mm'),
          end_collection: reaction.end_collection.format('HH:mm'),
        };
      }),
      attachments: [
        ...appendedFiles.purification,
        ...appendedFiles.lcms,
        ...appendedFiles._1hnmr,
        ...appendedFiles._13cnmr,
      ].map(file => file.uuid),
    };

    try {
      const resp = await saveExperimentResult(res);
      if (resp?.errors) {
        parseJSONErrors(resp?.errors).forEach(err => {
          openNotification(null, err);
        });
        return;
      }
      await dispatch(completeExperiment(experimentID));

      if (goToAppendResults) {
        history.push(`/execution?key=${data.key}&tab=history&isBatch=false&deviceId=${data?.timeSlot?.device?.uuid}`);
      } else {
        history.push('/execution');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Spinner loading={experimentLoading}>
      <div className="reset-padding-container lab-execution-container">
        <Steps active={activeStep} isLab />
        <ExperimentFinalization
          experiment={data}
          showTimer={activeStep === 2 || activeStep === 3}
          showContent={false}
          stopCountdown={data?.status === EXPERIMENT_STATUSES.Finalization.value}
        />
        <div className="sj-execution-body">
          {activeStep === 1 && (
            <LabPrepareMaterials
              errors={errors}
              listCheckboxes={listCheckboxes}
              setListCheckboxes={setListCheckboxes}
              setErrors={setErrors}
              setEdited={setEdited}
              tableData={tableData}
              setTableData={setTableData}
            />
          )}
          {activeStep === 2 && <LabExperimentProgress description={description} process={data?.process} />}
          {activeStep === 3 && (
            <LabExperimentFinalization
              experiment={data}
              options={finalizationOptions}
              setOptions={setFinalizationOptions}
              setFinalizationData={setFinalizationData}
              setErrors={setFinalizationErrors}
              errors={finalizationErrors}
            />
          )}
        </div>
      </div>
      <Footer
        isLab
        experiment={data}
        activeStep={activeStep}
        permissions={currentUser?.permissions}
        canSeeNotes={!!currentUser.permissions?.execution.view_note}
        canSeeDescription
        hideNotesNumber={false}
        showNotes={() => {
          setShowNotesSidebar(true);
        }}
        showDescription={() => setShowDescriptionSidebar(true)}
        notesLength={notesList.length}
        handleBack={() => setActiveStep(step => step - 1)}
        handleContinue={onContinue}
        disableContinue={disableContinue}
        remoteConnectivity
        showPause={false}
        showResume={false}
        showEndExperiment={data?.status === EXPERIMENT_STATUSES.InProgress.value}
        showFinalize={data?.status === EXPERIMENT_STATUSES.InProgress.value}
        disableCompleteExperiment={Object.keys(finalizationOptions).some(
          optionKey => !finalizationOptions[optionKey].checked
        )}
        pauseExperiment={() => {}}
        resumeExperiment={() => {}}
        completeExperiment={() => {
          setOpenCompleteConfirmation(true);
        }}
        endExperiment={() => setOpenEndConfirmation(true)}
        finalizeExperiment={() => setOpenFinalizeConfirmation(true)}
        handleDecline={() => {
          setOpenRejectConfirmation(true);
        }}
      />
      {showNotesSidebar && (
        <NotesSidebar
          notes={notesList}
          updateNotesList={updateNotesList}
          experimentId={experimentID}
          open={showNotesSidebar}
          onClose={() => setShowNotesSidebar(false)}
        />
      )}
      <DescriptionSidebar
        description={description}
        open={showDescriptionSidebar}
        onClose={() => setShowDescriptionSidebar(false)}
      />
      <Popup
        title=" "
        open={openEndConfirmation}
        textSubmit="Abort experiment"
        handleCancel={() => setOpenEndConfirmation(false)}
        handleSubmit={endExperimentAction}
        loading={experimentLoading}
      >
        Do you really want to Abort the Experiment?
      </Popup>
      <Popup
        title=" "
        open={openFinalizeConfirmation}
        textSubmit="Finalize experiment"
        handleCancel={() => setOpenFinalizeConfirmation(false)}
        handleSubmit={finalizeExperimentAction}
        loading={experimentLoading}
      >
        Do you really want to Finalize the Experiment?
      </Popup>
      <Popup
        title="Complete experiment"
        open={openCompleteConfirmation}
        textSubmit="Complete"
        textCancle="Cancel"
        handleCancel={() => setOpenCompleteConfirmation(false)}
        handleSubmit={() => completeExperimentAction(true)}
      >
        Do you want to complete the experiment. Note: You’ll be able to change the experiment results later
      </Popup>
      <DeclineExperimentPopup
        open={openRejectConfirmation}
        closePopup={() => setOpenRejectConfirmation(false)}
        isSchedule={false}
        experiment={data?.uuid}
        device={data?.timeSlot?.device?.type}
        handleNavigation={() => {
          history.push(
            `/execution?isBatch=false&key=${data.key}&tab=scheduled&deviceId=${data?.timeSlot?.device?.uuid}`
          );
        }}
      />
    </Spinner>
  );
};
