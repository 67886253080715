import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { Avatar, StatusLabel, Tooltip } from '../../Common';
import { transferIsoToMMDDYY, transferIsoToMMDDYYmmHH } from '../../../utils/date';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LockLabel } from '../LockLabel/LockLabel';

export const ProcessDetails = ({
  data,
  currentUser,
  showInitialRoute,
  setShowInitialRoute,
  setShowResultingRoute,
  showResultingRoute,
}) => {
  const isResultingRoute = data?.process?.experiments?.find(i => i?.route);
  let process = data.process?.process || data.process;
  return (
    <div className="process-details">
      <div className="first-row">
        <div className="main-info">
          <Tooltip title="Navigate to project">
            {!!currentUser?.permissions?.project.view_project ? (
              <Link to={`/projects/details/${process?.project?.uuid}`} className="project-name" target="_blank">
                {process?.project?.name}
              </Link>
            ) : (
              <a className="project-name" target="_blank">
                {process?.project?.name}
              </a>
            )}
          </Tooltip>
          <div className="process-name-wrapper">
            <div className="process-name">{process?.name}</div>
            <LockLabel processLock={process?.processLock} />
          </div>
        </div>
        <StatusLabel type="passive">{process?.status}</StatusLabel>
      </div>
      <div className="second-row">
        <div className="second-row-block">
          <div className="subheader">Initial route</div>
          <div className="second-row-block-content">
            <Tooltip title={'Open initial route'}>
              <span>
                {process?.route?.name || 'N/A'}
                {process?.route?.name &&
                  (showInitialRoute ? (
                    <EyeInvisibleOutlined
                      className="second-row-block-content_eye"
                      onClick={() => setShowInitialRoute(!showInitialRoute)}
                    />
                  ) : (
                    <EyeOutlined
                      className="second-row-block-content_eye"
                      onClick={() => setShowInitialRoute(!showInitialRoute)}
                    />
                  ))}
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="second-row-block">
          <div className="subheader">Resulting route</div>
          <div className="second-row-block-content">
            {isResultingRoute ? (
              <Tooltip title={'Open resulting route'}>
                <span>
                  Resulting route
                  {isResultingRoute &&
                    (showInitialRoute ? (
                      <EyeInvisibleOutlined
                        className="second-row-block-content_eye"
                        onClick={() => setShowResultingRoute(!showResultingRoute)}
                      />
                    ) : (
                      <EyeOutlined
                        className="second-row-block-content_eye"
                        onClick={() => setShowResultingRoute(!showResultingRoute)}
                      />
                    ))}
                </span>
              </Tooltip>
            ) : (
              'N/A'
            )}
          </div>
        </div>
        <div className="second-row-block">
          <div className="subheader">Process type</div>
          <div className="second-row-block-content">
            {process?.type} ({process?.numberOfSteps}-Step)
          </div>
        </div>
        <div className="second-row-block">
          <div className="subheader">Created by</div>
          <div className="second-row-block-content">
            {!!process?.createdBy.firstName && !!process?.createdBy.lastName ? (
              <Avatar size={24}>
                {`${process?.createdBy.firstName} ${process?.createdBy.lastName}`.match(/\b(\w)/g).join('')}
              </Avatar>
            ) : (
              <Avatar size={24}> </Avatar>
            )}
            <div className="name-creator" title={`${process?.createdBy.firstName} ${process?.createdBy.lastName}`}>
              {process?.createdBy.firstName} {process?.createdBy.lastName}
            </div>
          </div>
        </div>
        <div className="second-row-block">
          <div className="subheader">Created</div>
          <Tooltip title={transferIsoToMMDDYYmmHH(process?.createdAt)}>
            <div className="second-row-block-content">{transferIsoToMMDDYY(process?.createdAt)}</div>
          </Tooltip>
        </div>
        <div className="second-row-block">
          <div className="subheader">Last modified</div>
          <Tooltip title={transferIsoToMMDDYYmmHH(process?.updatedAt)}>
            <div className="second-row-block-content">{transferIsoToMMDDYY(process?.updatedAt)}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
