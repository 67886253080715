import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { InfoOutlined } from '@ant-design/icons';
import { NullCompoundCard, Tooltip } from '../../../Common';
import { CompoundSmilesPreview } from '../../../index';
import { ReactComponent as CompoundImage } from '../../../../dist/icons/process-builder/synjet_compound.svg';

const COMBINATION_DEFAULT_COUNT = 4;

const CombinationItem = ({ compound, type }) => {
  const compoundSmile = type ? compound?.smiles : compound.product;
  return (
    <div className="compound-item" key={compound.name}>
      <div className={cn('compound-item__image', { 'solvent-image ': !compoundSmile })}>
        {compoundSmile ? (
          <CompoundSmilesPreview compound={compoundSmile} showTooltip hideCompoudInfo />
        ) : (
          <CompoundImage />
        )}
      </div>
      <div className="compound-item__info">
        <span className="name">
          {type} {compound.name}
        </span>
        <Tooltip
          title={
            compoundSmile ? (
              <CompoundSmilesPreview
                compound={type ? compound?.smiles : compound.product}
                className="smiles-preview"
                options={{ width: 130, height: 80 }}
                hideMW
                showTooltip
                key={compound.name}
              />
            ) : (
              <NullCompoundCard className="synjet-compound__row" />
            )
          }
          color="white"
        >
          <InfoOutlined className="icon" />
        </Tooltip>
      </div>
    </div>
  );
};

const getCombinationArray = (reactants, reagents, expectedIntermediate) => {
  const mappedReactants = reactants?.map(reactant => ({ component: reactant, type: 'Reactant' })) || [];
  const mappedReagents = reagents?.map(reagent => ({ component: reagent, type: 'Reagent' })) || [];
  const combinations = [...mappedReactants, ...mappedReagents];
  return expectedIntermediate ? [expectedIntermediate, ...combinations] : combinations;
};

export const SynjetProCombinationView = ({ combination }) => {
  const [isHidden, setHidden] = useState(false);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [combinationsList, setCombinationsList] = useState([]);

  const showHidden = () => {
    const combinations = getCombinationArray(
      combination?.reactants,
      combination?.reagents,
      combination?.expectedIntermediate
    );
    setHiddenCount(0);
    setCombinationsList(combinations);
  };

  useEffect(() => {
    const combinations = getCombinationArray(
      combination?.reactants,
      combination?.reagents,
      combination?.expectedIntermediate
    );
    if (combinations.length > COMBINATION_DEFAULT_COUNT) {
      const slicedCombination = combinations.slice(0, COMBINATION_DEFAULT_COUNT);
      setHidden(true);
      setHiddenCount(combinations.length - COMBINATION_DEFAULT_COUNT);
      setCombinationsList(slicedCombination);
    } else {
      setCombinationsList(combinations);
    }
  }, [combination]);

  return (
    <div className="combination-list">
      {combinationsList?.map(combination => (
        <CombinationItem type={combination.type} compound={combination.type ? combination.component : combination} />
      ))}
      {!!hiddenCount && <div className="show-hidden" onClick={showHidden}>{`+ ${hiddenCount}`}</div>}
    </div>
  );
};
