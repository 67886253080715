import React from 'react';
import ContainerExprSched from '../../../components/ContainerExprSched';
import './style.scss';
import { modeDetails } from '../../../constants';
import { useParams } from 'react-router-dom';

export const ExperimentDetails = () => {
  let { key } = useParams();
  return <ContainerExprSched mode={modeDetails} experimentKey={key} className="details-container" />;
};
