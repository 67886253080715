import React, { useState, useEffect } from 'react';
import './style.scss';
import { CoefficientsTable } from './CoefficientsTable/CoefficientsTable';
import { Button, Collapse } from '../Common';
import _ from 'lodash';
import { transferIsoToMMDDYYutc } from '../../utils/date';
import { generateCSVFile } from '../../utils/generateCSVFile';
import { convertTime } from '../../utils/formatTime';

export const RegressionTables = ({ experimentData, isProjectPage, experiment, isInterpolated = false }) => {
  const [currentData, setCurrentData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [originalWithIndexes, setOriginalWithIndexes] = useState(null);

  const [renderedData, setRenderedData] = useState({});
  const [count, setCount] = useState(20);
  const [isBottom, setIsBottom] = useState(false);
  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    if (!!experimentData) {
      let arrayWithIndexes = JSON.parse(experimentData?.execution?.regressionAnalysis.data).df_interpolated.map(
        (item, index) => {
          item.unshift(index);
          return item;
        }
      );
      let t = JSON.stringify({
        ...JSON.parse(experimentData?.execution?.regressionAnalysis.data),
        df_interpolated: arrayWithIndexes,
      });
      setOriginalWithIndexes(arrayWithIndexes);

      setCurrentData({ data: t });
      setOriginalData({ data: t });
      setData({ data: t });
      let analytics =
        experimentData?.analyticalSetting.length && experimentData?.analyticalType?.length
          ? experimentData?.analyticalSetting
          : [];
      setAnalytics(analytics);
    }
  }, [experimentData]);

  useEffect(() => {
    if (isBottom) {
      getDataForRender();
    }
  }, [isBottom]);

  const getDataForRender = () => {
    setCount(count + 20);
    setData(currentData);
    setIsBottom(false);
  };

  const setData = expData => {
    let exp = JSON.parse(expData.data);
    let data = exp.df_interpolated;
    setRenderedData({ ...exp, df_interpolated: data.slice(0, count + 20) });
  };

  const changeTableSort = (sorter, indexColumn) => {
    let exp = JSON.parse(currentData.data);
    let data = exp.df_interpolated;
    let u = !!sorter.order ? sortFunc(data, sorter, indexColumn) : JSON.parse(originalData.data).df_interpolated;
    let result = { ...exp, df_interpolated: u };
    setCurrentData({ data: JSON.stringify(result) });
    setRenderedData({ ...exp, df_interpolated: u.slice(0, count) });
  };

  const sortFunc = (data, sorter, indexColumn) => {
    let u = [];
    if (sorter.order === 'ascend') {
      u = _.orderBy(data, [indexColumn], ['asc']);
    } else if (sorter.order === 'descend') {
      u = _.orderBy(data, [indexColumn], ['desc']);
    } else {
      u = data;
    }
    return u;
  };

  const downloadCSVFile = () => {
    let numberColumns = 0;
    renderedData.df_interpolated_columns.some((item, index) => {
      if (item === 'Time') {
        renderedData.df_interpolated_columns.splice(index, 1, 'Time, min');
      } else if (item === 'Temperature') {
        renderedData.df_interpolated_columns.splice(index, 1, 'Temperature, C');
      }
      analytics.forEach(elem => {
        if (elem.label == item) {
          let nameColumn = `${elem.label}, m/z ${elem.observ} (${convertTime(elem.interval_start)} - ${convertTime(
            elem.interval_end
          )})`;
          renderedData.df_interpolated_columns.splice(index, 1, nameColumn);
        }
      });
      let arr = item.split('_');
      if (arr.length > 0 && arr[1] === 'color') {
        //if this column contain color
        numberColumns = index + 1; //+1 because first column is index
      }
      return !!numberColumns;
    });
    let columnsWithoutColors = [['#', ...renderedData.df_interpolated_columns], ...originalWithIndexes];
    columnsWithoutColors = columnsWithoutColors.map((row, rowIdx) => {
      if (rowIdx !== 0) {
        //first row is columns name
        row[0] = rowIdx; //index+1
      }
      return row.slice(0, numberColumns); // slice colors from row
    });
    columnsWithoutColors = columnsWithoutColors.map((row, idx) => {
      if (idx === 0) {
        //special characters only in first row
        return row.map(cell => {
          if (cell.replace(/ /g, '').match(/[\s,"]/)) {
            //Fields containing line breaks (CRLF), double quotes, and commas should be enclosed in double-quotes. Ex: foo,"bar,baz"
            return '"' + cell.replace(/"/g, '""') + '"';
          }
          return cell;
        });
      } else {
        return row;
      }
    });
    const today = transferIsoToMMDDYYutc(new Date());
    generateCSVFile(columnsWithoutColors, `${experimentData.uuid}_${today}_DOE_table`);
  };

  return (
    <div id="regression">
      {!isInterpolated && (
        <div>
          <h4 className="experiment-info__header__title  small-header">Coefficients for all masses</h4>
          <CoefficientsTable
            showEmptyTooltip={true}
            experimentData={experimentData?.execution?.regressionAnalysis}
            analytics={
              experimentData?.analyticalSetting.length && experimentData?.analyticalType?.length
                ? experimentData?.analyticalSetting
                : []
            }
          />
        </div>
      )}
      {isInterpolated && (
        <Collapse
          items={[
            {
              title: 'Interpolated DOE Table',
              key: 0,
              body: (
                <div>
                  <div class="export-csv-block">
                    <Button secondary onClick={downloadCSVFile}>
                      Export to .CSV
                    </Button>
                  </div>
                  <CoefficientsTable
                    showEmptyTooltip={true}
                    experimentData={{ data: JSON.stringify(renderedData) }}
                    interpolated
                    setIsBottom={setIsBottom}
                    changeTableSort={changeTableSort}
                    isProjectPage={isProjectPage}
                    analytics={analytics}
                  />
                </div>
              ),
            },
          ]}
        />
      )}
    </div>
  );
};
