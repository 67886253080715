import React, { useEffect, useState } from 'react';
import { WebsocketService } from 'utils/service/WebSocketService';
import { useDispatch } from 'react-redux';
import './style.scss';

const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const HeatingBlockItem = ({ data }) => (
  <div className="heating-block-section__item__row">
    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
    <div className="heating-block-section__item__title">Heating Block {LETTERS[data.index - 1]}</div>
    <div className="heating-block-section__item__temperature">{data.temperature} °C</div>
  </div>
);

export const HeatingBlockSection = ({ receiveData, batch, isSynjetPro = false }) => {
  const [subscribtion, setSubscribtion] = useState(null);
  const [heatingBlocks, setHeatingBlocks] = useState(
    [...Array(isSynjetPro ? 8 : 6)].map((_, idx) => ({ index: idx + 1, temperature: 0 }))
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (subscribtion) subscribtion.unsubscribe();
    if (receiveData && batch?.uuid) {
      const newSubscribtion = new WebsocketService(`/batch-execution/${batch?.uuid}/`);
      setSubscribtion(newSubscribtion);
    }
    if (batch?.uuid && batch?.univariateData?.data) setHeatingBlocksData(JSON.parse(batch?.univariateData?.data));
    return () => {
      if (subscribtion) subscribtion.unsubscribe();
      setSubscribtion(null);
    };
  }, [batch?.uuid]);

  useEffect(() => {
    if (batch?.univariateData?.data) setHeatingBlocksData(JSON.parse(batch?.univariateData?.data));
  }, [batch?.univariateData]);

  useEffect(() => {
    if (!receiveData && subscribtion) {
      subscribtion.unsubscribe();
      setSubscribtion(null);
    }
    if (receiveData && !subscribtion) {
      const newSubscribtion = new WebsocketService(`/batch-execution/${batch?.uuid}/`);
      setSubscribtion(newSubscribtion);
    }
  }, [receiveData]);

  useEffect(() => {
    if (subscribtion) {
      subscribtion.subscribe(getDeviceParams);
    }
  }, [subscribtion]);

  const setHeatingBlocksData = hbData => {
    let newHeatingBlocks = [...heatingBlocks];
    Object.keys(hbData).forEach(key => {
      newHeatingBlocks = newHeatingBlocks.map(hb => {
        if (hb.index === +key) hb.temperature = hbData[+key];
        return hb;
      });
    });
    setHeatingBlocks(newHeatingBlocks);
  };

  const getDeviceParams = msg => {
    if (msg.type === 'batch_univariate_data') {
      setHeatingBlocksData(msg.data);
    }
  };

  return (
    <div className="heating-block-section">
      <div className="heating-block-section__title">Vial temperatures</div>
      <div className="heating-block-section__items">
        <div className="heating-block-section__item">
          {heatingBlocks
            .filter(i => i.index % 2 !== 0)
            .reverse()
            .map((i, idx) => (
              <HeatingBlockItem data={i} key={idx} />
            ))}
        </div>
        <div className="heating-block-section__item">
          {heatingBlocks
            .filter(i => i.index % 2 === 0)
            .reverse()
            .map((i, idx) => (
              <HeatingBlockItem data={i} key={idx} />
            ))}
        </div>
      </div>
    </div>
  );
};
