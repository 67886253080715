import './style.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Skeleton } from 'antd';
import { Button, openNotification, Tabs } from 'components';
import { Popup } from 'components/Common';
import { Switch } from 'components/Common/Switch';
import moment from 'moment';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { getData } from 'store/slices/extensibleTableFieldsGrid/getters';

import { TextBody14 } from '@atoms';
import { FIELD_DATA_TYPES } from '@organisms';

import { dateYYYYMMDD } from '../../../constants';
import { updateExtensibleExperiment } from '../../../store/experiment/experiment.actions';
import history from '../../../utils/history';
import { ExperimentField } from './components/ExperimentField';
import { OPTIONS_TABS } from './constants';
import { ExperimentDetailsHOC, useExperimentDetailsContext } from './hooks/useExperimentDetailsController';

const Component = ({ visible, handleCancel, experimentUuid, modifyDataAfterUpdate }) => {
  const [activeTab, setActiveTab] = useState(OPTIONS_TABS[0].value);
  const [isShowArchivedItems, setIsShowArchivedItems] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const ref = useRef();
  const tableFieldsGrid = useSelector(getData);

  const experimentDataFields = useMemo(
    () =>
      isShowArchivedItems
        ? tableFieldsGrid.filter(col => col.visibleInDetails)
        : tableFieldsGrid.filter(col => !col.archived && col.visibleInDetails),
    [tableFieldsGrid, isShowArchivedItems]
  );

  const { editableExperimentFields, editedSmileFieldUuid } = useExperimentDetailsContext();

  useEffect(() => {
    const methods = ref.current;
    if (methods) {
      methods.resetAfterIndex(0);
    }
  }, [editedSmileFieldUuid]);

  const onSave = async () => {
    try {
      setUpdateLoading(true);
      const payload = {};
      experimentDataFields.forEach(({ editable, fieldName, dataType }) => {
        const value = editableExperimentFields[fieldName];
        if (editable) {
          if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
            payload[fieldName] = JSON.stringify(null);
            return;
          }
          if (dataType === FIELD_DATA_TYPES.date) {
            payload[fieldName] = JSON.stringify(value.format(dateYYYYMMDD));
          } else if (dataType === FIELD_DATA_TYPES.datetime) {
            payload[fieldName] = JSON.stringify(moment.utc(value).format());
          } else if (dataType === FIELD_DATA_TYPES.user || dataType === FIELD_DATA_TYPES.experiment) {
            payload[fieldName] = JSON.stringify(value.value);
          } else if (dataType === FIELD_DATA_TYPES.multiselect) {
            payload[fieldName] = JSON.stringify(value.map(i => JSON.parse(i)));
          } else if (dataType === FIELD_DATA_TYPES.smile) {
            const copyValue = { ...value };
            if (!copyValue.compatibility) {
              delete copyValue.compatibility;
            }
            payload[fieldName] = JSON.stringify(copyValue);
          } else {
            payload[fieldName] = JSON.stringify(value);
          }
        }
      });
      const newData = await updateExtensibleExperiment(payload, experimentUuid);
      modifyDataAfterUpdate(newData, experimentUuid);
      setUpdateLoading(false);
      handleCancel();
    } catch (error) {
      if (Array.isArray(error)) {
        error.map(errorData => {
          openNotification(null, errorData?.messages[0]);
        });
      }
      setUpdateLoading(false);
      console.error(error);
    }
  };

  const showArchivedFields = () => {
    setIsShowArchivedItems(prev => !prev);
  };
  const onChangeTab = activeKey => {
    setActiveTab(activeKey);
  };

  const getItemSize = index => {
    const data = experimentDataFields[index];
    const value = editableExperimentFields?.[data.fieldName];
    if (data.dataType === FIELD_DATA_TYPES.smile && value) {
      return 200;
    }
    return 82;
  };

  return (
    <Popup
      className="popup-experiment-details"
      title={
        <PopupExperimentDetailsHeader
          showArchivedFields={showArchivedFields}
          isShowArchivedItems={isShowArchivedItems}
          experimentDevice={editableExperimentFields?.device?.value}
          experimentKey={editableExperimentFields?.experiment_key}
        />
      }
      open={visible}
      handleCancel={handleCancel}
      handleSubmit={onSave}
      loading={updateLoading}
    >
      {/* <Tabs */}
      {/*  className="popup-experiment-details_tabs" */}
      {/*  activeKey={activeTab} */}
      {/*  options={OPTIONS_TABS} */}
      {/*  onChange={onChangeTab} */}
      {/* /> */}
      {activeTab === OPTIONS_TABS[0].value &&
        (editableExperimentFields ? (
          <div className="popup-experiment-details_information-tab">
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  ref={ref}
                  itemKey={idx => experimentDataFields[idx].uuid}
                  itemSize={getItemSize}
                  itemCount={experimentDataFields.length}
                  height={height}
                  width={width}
                  itemData={experimentDataFields}
                >
                  {ExperimentField}
                </VariableSizeList>
              )}
            </AutoSizer>
          </div>
        ) : (
          <Loading />
        ))}
      {activeTab === OPTIONS_TABS[1].value && <div />}
    </Popup>
  );
};

const Loading = () => (
  <div className="popup-experiment-details_loading">
    {Array.from(Array(10).keys()).map(() => (
      <Skeleton.Input size="large" active block />
    ))}
  </div>
);

const PopupExperimentDetailsHeader = ({ showArchivedFields, isShowArchivedItems, experimentKey, experimentDevice }) => {
  const getDeviceNavFormat = () => {
    switch (experimentDevice) {
      case 'Lab':
        return 'lab';
      case 'SynJet':
        return 'synjet';
      case 'SynJet Pro':
        return 'synjet_pro';
      default:
        return 'autosyn';
    }
  };
  const onClicked = () => {
    const device = getDeviceNavFormat();

    window.open(`${device}/details/${experimentKey}`);
  };
  return (
    <div className="popup-experiment-details_header">
      Details
      <div className="popup-experiment-details_header_switch">
        <TextBody14 className="popup-experiment-details_header_switch_label">Show archived fields</TextBody14>
        <Switch onChange={showArchivedFields} checked={isShowArchivedItems} />
        {experimentKey && experimentDevice && <Button onClick={onClicked}>See Full Details</Button>}
      </div>
    </div>
  );
};

export const PopupExperimentDetails = ({ visible, handleCancel, uuid, modifyDataAfterUpdate }) =>
  !!visible && (
    <ExperimentDetailsHOC uuid={uuid}>
      <Component
        experimentUuid={uuid}
        visible={!!visible}
        handleCancel={handleCancel}
        modifyDataAfterUpdate={modifyDataAfterUpdate}
      />
    </ExperimentDetailsHOC>
  );
