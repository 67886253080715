import './styles.scss';

import React from 'react';

import { Popover as AntdPopover } from 'antd';
import cn from 'classnames';

export const Popover = ({ className, children, overlayClassName, ...props }) => (
  <AntdPopover
    overlayClassName={cn('popover-overlay', overlayClassName)}
    className={cn('popover', className)}
    {...props}
  >
    {children}
  </AntdPopover>
);
