import './styles.scss';

import React, { useContext, useState } from 'react';

import cn from 'classnames';

const FooterContext = React.createContext(false);

export const FooterContainer = ({ children, className }) => {
  const hasScrolled = useContext(FooterContext);
  return (
    <div
      className={cn('footer-container', className, {
        'has-scrolled': hasScrolled,
      })}
    >
      {children}
    </div>
  );
};

export const FooterScrollObserver = ({ children }) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const onScroll = event => {
    setHasScrolled(event.target.scrollTop !== 0);
  };
  return (
    <FooterContext.Provider value={hasScrolled}>
      <div className="footer-scroll-observer" onScroll={onScroll}>
        {children}
      </div>
    </FooterContext.Provider>
  );
};
