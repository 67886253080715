import './styles.scss';

import React from 'react';

import cn from 'classnames';

export const BaseSideBar = ({ visible, children, className }) => {
  if (!visible) return <></>;
  return (
    <div className={cn('base-side-bar', className)}>
      <div className="base-side-bar_sider">{children}</div>
    </div>
  );
};
