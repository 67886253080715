import React, { useState } from 'react';
import './style.scss';
import { SearchOutlined } from '@ant-design/icons';
import { PopupWithStructureView } from './PopupWithStructureView';

export const LoupeButton = ({ data, label, clickOnLoupe }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const onLoupeClick = e => {
    e.stopPropagation();
    setOpenPopup(true);
    clickOnLoupe && clickOnLoupe(true);
  };

  const closePopup = e => {
    e.stopPropagation();
    setOpenPopup(false);
    clickOnLoupe && clickOnLoupe(false);
  };

  return (
    <>
      <div className="loupe-button" onClick={onLoupeClick}>
        <SearchOutlined width={20} />
      </div>
      <PopupWithStructureView openPopup={openPopup} data={data} closePopup={closePopup} label={label} />
    </>
  );
};
