import React from 'react';
import classNames from 'classnames';
import { InfoCircleOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SYNJET_PROCESS_TYPES, DISPLAY_OPTIONS, SYNJET_PRO_PROCESS_TYPES, SOLVENT_TYPES } from '../../../../constants';
import { ExperimentStatus, NullCompoundCard, Tooltip } from '../../../Common';
import { convertTime, formatSecondsToHours } from '../../../../utils';
import CompoundSmilesPreview from '../../../CompoundSmilesPreview/CompoundSmilesPreview';

export const getExperimentTableColumns = (
  steps,
  displayOption,
  processType,
  previewMode,
  stepStatuses,
  analytical,
  showActualTemperatures,
  isQuenchingEnabled,
  copiedVialsData
) => {
  const columns = [
    {
      title: <span className="column-name">Vial #</span>,
      sorter: previewMode ? false : (a, b) => a.name - b.name,
      render: (data, rowData, index) =>
        !data.is_valid ? (
          <span>
            {data.name}
            <Tooltip title={data.error} overlayClassName="synjet-process-error-tooltip">
              <InfoCircleOutlined className="total-icon" />
            </Tooltip>
          </span>
        ) : (
          <span>
            {data.name}
            {copiedVialsData[data.name] && (
              <Tooltip
                title={`Copy of the reaction #${copiedVialsData[data.name]}`}
                overlayClassName="synjet-copied-vial-tooltip"
              >
                <CopyOutlined className="copy-icon" />
              </Tooltip>
            )}
          </span>
        ),
    },
  ];

  let stepsColumns;

  const isTwoStepProcess = steps.length > 1;

  if (isTwoStepProcess) {
    stepsColumns = steps.map((step, stepIndex) => ({
      title: (
        <span className="column-name">
          STEP {stepIndex + 1} ({step.isFixed ? 'Fixed' : 'Variable'}){' '}
          {stepStatuses?.length && <ExperimentStatus status={stepStatuses[stepIndex]} />}
        </span>
      ),
      children: getStepColumns(
        step,
        stepIndex,
        displayOption,
        processType,
        previewMode,
        analytical,
        showActualTemperatures,
        false,
        isTwoStepProcess
      ),
    }));
  } else {
    stepsColumns = getStepColumns(
      steps[0],
      0,
      displayOption,
      processType,
      previewMode,
      analytical,
      showActualTemperatures,
      isQuenchingEnabled
    );
  }

  const cols = [...columns, ...stepsColumns];

  if (
    !!steps[steps.length - 1].conditions[0].dispensing.normalize &&
    !!steps[steps.length - 1].conditions[0].dispensing.solvent
  ) {
    const acnColumn = {
      title: (
        <span className="column-name">
          Normalization solvent (µL){' '}
          {steps[steps.length - 1].conditions[0].dispensing.solvent && (
            <Tooltip
              overlayClassName="synjet-experiment-table_header_tooltip"
              title={
                <CompoundSmilesPreview
                  compound={steps[steps.length - 1].conditions[0].dispensing.solvent}
                  options={{ width: 130, height: 80 }}
                  hideMW
                  showDivider
                  showEmptyCompound
                />
              }
            >
              <InfoCircleOutlined className="info-icon_header" />
            </Tooltip>
          )}
        </span>
      ),
      sorter:
        !previewMode &&
        ((a, b) => {
          if (a.normalization_solvent_volume < 0 && b.normalization_solvent_volume < 0) return false;
          return a.normalization_solvent_volume - b.normalization_solvent_volume;
        }),
      dataIndex: 'normalization_solvent_volume',
      render: text => (text < 0 ? '-' : text !== null && text !== undefined ? text.toFixed(3) : ''),
    };
    cols.push(acnColumn);
  }

  return [
    ...cols,

    {
      title: <span className="column-name">Total (µL)</span>,
      dataIndex: 'total',
      className: 'synjet-experiment-table_total_column',
      align: 'right',
      sorter: previewMode ? false : (a, b) => a.total - b.total,
      render: text => (text ? text.toFixed(3) : ''),
    },
  ];
};

const getStepColumns = (
  step,
  stepIndex,
  displayOption,
  processType,
  previewMode,
  analytical,
  showActualTemperatures,
  isQuenchingEnabled,
  isTwoStepProcess
) => {
  switch (processType) {
    case SYNJET_PROCESS_TYPES.OPTIMIZATION:
      return getOptimizationColumns(step, stepIndex, displayOption, previewMode, analytical, showActualTemperatures);
    case SYNJET_PROCESS_TYPES.SCREENING:
      return getScreeningColumns(step, stepIndex, displayOption, previewMode, analytical, showActualTemperatures);
    case SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION:
      return getScreeningColumns(
        step,
        stepIndex,
        displayOption,
        previewMode,
        analytical,
        showActualTemperatures,
        true,
        isQuenchingEnabled,
        isTwoStepProcess
      );
    default:
      return [];
  }
};

const getOptimizationColumns = (step, stepIndex, displayOption, previewMode, analytical) => {
  const conditionsColumns = [
    {
      title: <span className="column-name">Temperature (°C)</span>,
      dataIndex: [`step${stepIndex}`, 'temperature'],
      sorter: previewMode
        ? false
        : (a, b) =>
            a[`step${stepIndex}`] && b[`step${stepIndex}`]
              ? a[`step${stepIndex}`].temperature - b[`step${stepIndex}`].temperature
              : false,
      render: (text, data) => {
        const temperatureSecondValue = data[`step${stepIndex}`]?.calibrationTemperature
          ? ` / ${data[`step${stepIndex}`]?.calibrationTemperature}`
          : ' / --';

        return (
          <div>
            {text ? (
              <>
                <span>{`${data[`step${stepIndex}`]?.temperature_label}`}</span>
                {` (${text}${analytical ? temperatureSecondValue : ''})`}
              </>
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
    {
      title: <span className="column-name">Time (hh:mm)</span>,
      dataIndex: [`step${stepIndex}`, 'time'],
      sorter: previewMode
        ? false
        : (a, b) =>
            a[`step${stepIndex}`] && b[`step${stepIndex}`]
              ? a[`step${stepIndex}`].time - b[`step${stepIndex}`].time
              : false,
      render: (text, data) => {
        const time = formatSecondsToHours(moment(text).diff(moment().startOf('day'), 'seconds'));
        return (
          <div>
            {text ? (
              <>
                <span>{`${data[`step${stepIndex}`]?.time_label}`}</span>
                {` (${time.hour}:${time.min < 10 ? `0${time.min}` : time.min})`}
              </>
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
  ];

  const reactants = step.compounds.reactants.map((reactant, reactantIndex) =>
    getCompoundOptimizationColumn({
      compound: reactant,
      compoundIndex: reactantIndex,
      stepIndex,
      displayOption,
      property: 'reactants',
      previewMode,
    })
  );

  const reagents = step.compounds.reagents.map((reagent, reagentIndex) =>
    getCompoundOptimizationColumn({
      compound: reagent,
      compoundIndex: reagentIndex,
      stepIndex,
      displayOption,
      property: 'reagents',
      previewMode,
    })
  );

  return [...conditionsColumns, ...reactants, ...reagents];
};

const getCompoundOptimizationColumn = ({
  compound,
  compoundIndex,
  stepIndex,
  displayOption,
  property,
  previewMode,
}) => {
  const volumeTitle =
    compound.solventType === SOLVENT_TYPES.SOLID ? DISPLAY_OPTIONS.mass.measure : DISPLAY_OPTIONS.volume.measure;
  return {
    title: (
      <span className="column-name">
        {compound.name} (
        {displayOption === DISPLAY_OPTIONS.volume.label ? volumeTitle : DISPLAY_OPTIONS.equivalent.measure}){' '}
        {compound?.smiles && (
          <Tooltip
            title={
              <CompoundSmilesPreview
                compound={compound?.smiles}
                options={{ width: 130, height: 80 }}
                hideMW
                showDivider
                showEmptyCompound
              />
            }
            overlayClassName="synjet-experiment-table_header_tooltip"
          >
            <InfoCircleOutlined className="info-icon_header" />
          </Tooltip>
        )}
      </span>
    ),
    sorter: previewMode
      ? false
      : (a, b) =>
          a[`step${stepIndex}`] &&
          b[`step${stepIndex}`] &&
          a[`step${stepIndex}`][property].length &&
          b[`step${stepIndex}`][property].length
            ? a[`step${stepIndex}`][property][compoundIndex][displayOption] -
              b[`step${stepIndex}`][property][compoundIndex][displayOption]
            : false,
    dataIndex: [`step${stepIndex}`, property, compoundIndex],
    key: [`step${stepIndex}`, property, compoundIndex, DISPLAY_OPTIONS.volume.label],
    render: property => (
      <div>
        {property ? (
          <>
            <span>{property?.label}</span>
            {` (${getFormattedNumber(property, displayOption)})`}
          </>
        ) : (
          '-'
        )}
      </div>
    ),
  };
};

const getScreeningColumns = (
  step,
  stepIndex,
  displayOption,
  previewMode,
  analytical,
  showActualTemperatures,
  isLibGen,
  isQuenchingEnabled,
  isTwoStepProcess
) => {
  const conditionColumn = {
    title: <span className="column-name">Temperature, °C / Time, hh:mm</span>,
    dataIndex: [`step${stepIndex}`, 'temperature'],
    sorter: previewMode
      ? false
      : (a, b) =>
          a[`step${stepIndex}`] && b[`step${stepIndex}`]
            ? a[`step${stepIndex}`].time - b[`step${stepIndex}`].time
            : false,
    render: (text, data) => {
      const time = formatSecondsToHours(
        moment(data[`step${stepIndex}`]?.time).diff(moment().startOf('day'), 'seconds')
      );
      return (
        <div>
          {data[`step${stepIndex}`] ? (
            <>
              <span>{`${data[`step${stepIndex}`]?.temperature_label}`}</span>
              {` (${text} / ${time.hour}:${time.min < 10 ? `0${time.min}` : time.min})`}
            </>
          ) : (
            '-'
          )}
        </div>
      );
    },
  };

  const actualTemperatureColumn = {
    title: <span className="column-name">Temperature (°C), actual</span>,
    dataIndex: [`step${stepIndex}`, 'calibrationTemperature'],
    render: text => text ?? '-',
  };

  const reactantColumns = !step.isFixed
    ? step.compounds.reactantGroups.map((group, groupIndex) =>
        getScreeningCompoundColumn({
          group,
          groupIndex,
          stepIndex,
          displayOption,
          property: 'reactants',
          previewMode,
        })
      )
    : step.compounds.reactants.map((reactant, reactantIndex) =>
        getCompoundOptimizationColumn({
          compound: reactant,
          compoundIndex: reactantIndex,
          stepIndex,
          displayOption,
          property: 'reactants',
          previewMode,
        })
      );

  const reagentColumns = !step.isFixed
    ? step.compounds.reagentGroups.map((group, groupIndex) =>
        getScreeningCompoundColumn({
          group,
          groupIndex,
          stepIndex,
          displayOption,
          property: 'reagents',
          previewMode,
        })
      )
    : step.compounds.reagents.map((reagent, reagentIndex) =>
        getCompoundOptimizationColumn({
          compound: reagent,
          compoundIndex: reagentIndex,
          stepIndex,
          displayOption,
          property: 'reagents',
          previewMode,
        })
      );

  const productColumn = {
    title: <span className="column-name">Product (mg)</span>,
    dataIndex: [`step${stepIndex}`],
    sorter: previewMode
      ? false
      : (a, b) => {
          const rowA = a[`step${stepIndex}`];
          const rowB = b[`step${stepIndex}`];
          const numberA = rowA.productName.match(/\d/)?.[0];
          const numberB = rowB.productName.match(/\d/)?.[0];
          return numberA && numberB ? numberA - numberB : false;
        },
    render: data => (
      <div>
        <span>{data?.productName}</span>
        {` (${data?.calculatedYield || 0})`}
        <Tooltip
          title={
            <CompoundSmilesPreview
              compound={data?.product}
              options={{ width: 130, height: 80 }}
              hideMW
              showDivider
              showEmptyCompound
            />
          }
          overlayClassName="synjet-experiment-table_header_tooltip"
        >
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      </div>
    ),
  };

  const quenchingColumn = isQuenchingEnabled
    ? [
        {
          title: (
            <span className="flex-container">
              <span className="capitalize column-name">Quenching</span> (
              <span className="column-name">
                {displayOption === DISPLAY_OPTIONS.volume.label
                  ? DISPLAY_OPTIONS.volume.measure
                  : DISPLAY_OPTIONS.equivalent.measure}
              </span>
              )
            </span>
          ),
          sorter: previewMode ? false : (a, b) => {},
          dataIndex: [`step${stepIndex}`],
          render: data => (
            <div>
              {data?.quenchingCombination?.map((quenchingItem, index) => (
                <>
                  <span className="synjet-experiment-table_header_title">{quenchingItem?.name}</span>(
                  {getFormattedNumber(quenchingItem, displayOption)})
                  {index !== data?.quenchingCombination.length - 1 && <span>{', '}</span>}
                </>
              ))}
              <Tooltip
                title={
                  <div>
                    {data?.quenching?.map((quenchingItem, index) => (
                      <div
                        className={classNames('quenching-list-preview', {
                          last: index === data?.quenching.length - 1,
                        })}
                      >
                        <span className="quenching-label">{quenchingItem.name}</span>
                        <CompoundSmilesPreview
                          compound={quenchingItem?.smiles}
                          options={{ width: 130, height: 80 }}
                          hideMW
                          showDivider
                          showEmptyCompound
                        />
                      </div>
                    ))}
                  </div>
                }
                overlayClassName="synjet-experiment-table_header_tooltip"
              >
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            </div>
          ),
        },
      ]
    : [];

  const columns = showActualTemperatures
    ? [conditionColumn, actualTemperatureColumn, ...reactantColumns, ...reagentColumns]
    : [conditionColumn, ...reactantColumns, ...reagentColumns];

  const showProducts = isLibGen && (!isTwoStepProcess || !!stepIndex);
  return showProducts ? [...columns, ...quenchingColumn, productColumn] : columns;
};

const getScreeningCompoundColumn = ({ group, groupIndex, stepIndex, displayOption, property, previewMode }) => {
  let volumeTitle = DISPLAY_OPTIONS.volume.measure;
  const compoundsLength = group?.compoundsList?.length;
  const solidCompounds = group?.compoundsList?.filter(compound => compound.solventType === SOLVENT_TYPES.SOLID);
  const solidCompoundsLength = solidCompounds?.length;
  if (solidCompoundsLength) {
    volumeTitle = DISPLAY_OPTIONS.mass_volume.measure;
    if (solidCompoundsLength === compoundsLength) {
      volumeTitle = DISPLAY_OPTIONS.mass.measure;
    }
  }
  return {
    title: (
      <span className="flex-container">
        <span className="capitalize column-name">{group.name}</span>
        <span className="column-name">
          ({displayOption === DISPLAY_OPTIONS.volume.label ? volumeTitle : DISPLAY_OPTIONS.equivalent.measure})
        </span>
      </span>
    ),
    sorter: previewMode
      ? false
      : (a, b) =>
          a[`step${stepIndex}`] && b[`step${stepIndex}`]
            ? a[`step${stepIndex}`][property][groupIndex][displayOption] -
              b[`step${stepIndex}`][property][groupIndex][displayOption]
            : false,
    dataIndex: [`step${stepIndex}`, property, groupIndex],
    key: [`step${stepIndex}`, property, groupIndex, DISPLAY_OPTIONS.volume.label],
    render: data => (
      <div>
        {data?.name ? (
          <>
            <span className="synjet-experiment-table_header_title">{data?.name}</span> (
            {getFormattedNumber(data, displayOption)})
            {(data?.smiles || data?.isNullCompound) && (
              <Tooltip
                title={
                  data?.isNullCompound ? (
                    <NullCompoundCard className="synjet-experiment-table_null_compound_tooltip" showDivider />
                  ) : (
                    <CompoundSmilesPreview
                      compound={data?.smiles}
                      options={{ width: 130, height: 80 }}
                      hideMW
                      showDivider
                      showEmptyCompound
                    />
                  )
                }
                overlayClassName="synjet-experiment-table_header_tooltip"
              >
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            )}
          </>
        ) : (
          '-'
        )}
      </div>
    ),
  };
};

const getFormattedNumber = (data, displayOption) => {
  if (!data?.volume || !data?.equivalent) {
    return '-';
  }

  if (displayOption === DISPLAY_OPTIONS.volume.label) {
    return Number(data.volume).toFixed(3);
  }

  if (displayOption === DISPLAY_OPTIONS.equivalent.label) {
    return Number(data?.equivalent).toFixed(3);
  }
};

export const getAnalyticsColumns = (analytics, selectedExperiment, previewMode) =>
  analytics
    ? analytics.map(analytic => {
        const observed = analytic.observed_mass || analytic.observ;
        return {
          title: (
            <div className="analytical-columns">
              <span className="capitalize column-name" title={analytic.label}>
                {analytic.label}
              </span>
              {(analytic.compound || analytic.compound === null) && (
                <Tooltip
                  title={
                    <CompoundSmilesPreview
                      compound={analytic?.compound}
                      options={{ width: 130, height: 80 }}
                      hideMW
                      showDivider
                      showEmptyCompound
                    />
                  }
                  overlayClassName="synjet-experiment-table_header_tooltip"
                >
                  <InfoCircleOutlined className="info-icon" />{' '}
                </Tooltip>
              )}
              <span className="column-name">
                m/z {observed && Number(observed).toFixed(3)}{' '}
                {analytic?.interval_end &&
                  `(${convertTime(analytic?.interval_start)} - ${convertTime(analytic?.interval_end)})`}
              </span>
            </div>
          ),
          dataIndex: ['analytic', analytic.label],
          sorter: previewMode
            ? false
            : (a, b) => (a.analytic && b.analytic ? a.analytic[analytic.label] - b.analytic[analytic.label] : false),
          render: data =>
            data !== null && data !== undefined
              ? Number(data).toExponential(2)
              : selectedExperiment.status === 'Completed'
              ? '--'
              : '--- Waiting ---',
        };
      })
    : [];
