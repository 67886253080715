import createReducer from '../createReducer.js';
import * as actions from './users.actionsType';
import * as commonActions from '../common/common.actionTypes';

const initialState = {
  users: [],
};

function setUsers(state, data) {
  return { ...state, users: data.payload };
}
function clean(state) {
  return { ...state, users: [] };
}

const usersReducer = createReducer(initialState, {
  [actions.SET_USERS_LIST]: setUsers,
  [commonActions.CLEAN_USERS]: clean,
});

export default usersReducer;
