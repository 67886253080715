export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USERS_BY_ROLES = 'SET_USERS_BY_ROLES';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_LOADING = 'SET_LOADING';

export const CLEAN_USERS = 'CLEAN_USERS';
export const CLEAN_PROJECTS = 'CLEAN_PROJECTS';
export const CLEAN_PROCESSES = 'CLEAN_PROCESSES';
export const CLEAN_PROCESS_BUILDER_SYNJET = 'CLEAN_PROCESS_BUILDER_SYNJET';
