import moment from 'moment';
import { SOLVENT_TYPES } from './common';

export const SYNJET_COMPOUND_TYPES = {
  PRODUCT: 'product',
  REAGENT: 'reagent',
  REACTANT: 'reactant',
  QUENCHING: 'quenching',
};
export const COMPOUND_ROLE = [
  { label: 'Solvent', value: 'solvent' },
  { label: 'reactant/reagent', value: 'reactant/reagent' },
];

export const COMPONENT = [
  { label: 'temperature', value: 'temperature' },
  { label: 'time', value: 'time' },
  { label: 'solvent', value: 'solvent' },
  { label: 'reactant/reagent', value: 'reactant/reagent' },
];

export const OPERATOR = [
  { label: 'more than', value: 'more than' },
  { label: 'less than', value: 'less than' },
  { label: 'equals to', value: 'equals to' },
];

export const PREDEFINED_RULES = [
  {
    compound_id: 'SFC00000971',
    compoundRole: 'solvent',
    compoundData: 'N-methyl-2-pyrrolidone',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 170,
  },
  {
    compound_id: 'SFC00001015',
    compoundRole: 'solvent',
    compoundData: 'Dimethylformamide',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 153,
  },
  {
    compound_id: 'SFC00000797',
    compoundRole: 'solvent',
    compoundData: 'Toluene',
    component: 'temperature',
    component_id: '',
    operator: 'more than',
    componentValue: 111,
  },
  {
    compound_id: 'SFC00001000',
    compoundRole: 'solvent',
    compoundData: 'Acetonitrile',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 82,
  },
  {
    compound_id: 'SFC00001031',
    compoundRole: 'solvent',
    compoundData: 'Tetrahydrofuran',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 61,
  },
  {
    compound_id: 'SFC00001008',
    compoundRole: 'solvent',
    compoundData: 'Chloroform',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 55,
  },
  {
    compound_id: 'SFC00001029',
    compoundRole: 'solvent',
    compoundData: '2-propanol',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 82,
  },
  {
    compound_id: 'SFC00001016',
    compoundRole: 'solvent',
    compoundData: 'Dimethylsulfoxide',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 180,
  },
  {
    compound_id: 'SFC00001023',
    compoundRole: 'solvent',
    compoundData: 'Methyl t-butyl ether',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 45,
  },
  {
    compound_id: 'SFC00001030',
    compoundRole: 'solvent',
    compoundData: 'Pyridine',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 115,
  },
  {
    compound_id: 'SFC00001032',
    compoundRole: 'solvent',
    compoundData: 'Triethylamine',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 89,
  },
  {
    compound_id: 'SFC00000999',
    compoundRole: 'solvent',
    compoundData: 'Acetic Acid',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 117,
  },
  {
    compound_id: 'SFC00000972',
    compoundRole: 'solvent',
    compoundData: 'Hydrochloric Acid',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 100,
  },
  {
    compound_id: 'SFC00001017',
    compoundRole: 'solvent',
    compoundData: '1,4-Dioxane',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 100,
  },
  {
    compound_id: 'SFC00001024',
    compoundRole: 'solvent',
    compoundData: 'Dichloromethane',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 25,
  },
  {
    compound_id: 'SFC00000002',
    compoundRole: 'solvent',
    compoundData: 'Water',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 100,
  },
  {
    compound_id: 'SFC00000006',
    compoundRole: 'solvent',
    compoundData: 'Ethanol',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 78,
  },
  {
    compound_id: 'SFC00000005',
    compoundRole: 'solvent',
    compoundData: 'Methanol',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 65,
  },
  {
    compound_id: 'SFC00001013',
    compoundRole: 'solvent',
    component_id: '',
    compoundData: 'Diglyme',
    component: 'temperature',
    operator: 'more than',
    componentValue: 80,
  },
  {
    compound_id: 'SFC00000205',
    compoundRole: 'solvent',
    compoundData: 'Acetone',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 46,
  },
  {
    compound_id: 'SFC00001010',
    compoundRole: 'solvent',
    component_id: '',
    compoundData: '1,2-dichloroethane',
    component: 'temperature',
    operator: 'more than',
    componentValue: 83,
  },
];

export const PREDEFINED_RULES_PRO = [
  {
    compound_id: 'SFC00000971',
    compoundRole: 'solvent',
    compoundData: 'N-methyl-2-pyrrolidone',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 165,
  },
  {
    compound_id: 'SFC00001015',
    compoundRole: 'solvent',
    compoundData: 'Dimethylformamide',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 138,
  },
  {
    compound_id: 'SFC00000797',
    compoundRole: 'solvent',
    compoundData: 'Toluene',
    component: 'temperature',
    component_id: '',
    operator: 'more than',
    componentValue: 96,
  },
  {
    compound_id: 'SFC00001000',
    compoundRole: 'solvent',
    compoundData: 'Acetonitrile',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 67,
  },
  {
    compound_id: 'SFC00001031',
    compoundRole: 'solvent',
    compoundData: 'Tetrahydrofuran',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 46,
  },
  {
    compound_id: 'SFC00001008',
    compoundRole: 'solvent',
    compoundData: 'Chloroform',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 40,
  },
  {
    compound_id: 'SFC00001029',
    compoundRole: 'solvent',
    compoundData: '2-propanol',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 67,
  },
  {
    compound_id: 'SFC00001016',
    compoundRole: 'solvent',
    compoundData: 'Dimethylsulfoxide',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 165,
  },
  {
    compound_id: 'SFC00001023',
    compoundRole: 'solvent',
    compoundData: 'Methyl t-butyl ether',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 30,
  },
  {
    compound_id: 'SFC00001030',
    compoundRole: 'solvent',
    compoundData: 'Pyridine',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 100,
  },
  {
    compound_id: 'SFC00001032',
    compoundRole: 'solvent',
    compoundData: 'Triethylamine',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 74,
  },
  {
    compound_id: 'SFC00000999',
    compoundRole: 'solvent',
    compoundData: 'Acetic Acid',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 102,
  },
  {
    compound_id: 'SFC00000972',
    compoundRole: 'solvent',
    compoundData: 'Hydrochloric Acid',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 85,
  },
  {
    compound_id: 'SFC00001017',
    compoundRole: 'solvent',
    compoundData: '1,4-Dioxane',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 85,
  },
  {
    compound_id: 'SFC00001024',
    compoundRole: 'solvent',
    compoundData: 'Dichloromethane',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 30,
  },
  {
    compound_id: 'SFC00000002',
    compoundRole: 'solvent',
    compoundData: 'Water',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 85,
  },
  {
    compound_id: 'SFC00000006',
    compoundRole: 'solvent',
    compoundData: 'Ethanol',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 63,
  },
  {
    compound_id: 'SFC00000005',
    compoundRole: 'solvent',
    compoundData: 'Methanol',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 50,
  },
  {
    compound_id: 'SFC00001013',
    compoundRole: 'solvent',
    component_id: '',
    compoundData: 'Diglyme',
    component: 'temperature',
    operator: 'more than',
    componentValue: 65,
  },
  {
    compound_id: 'SFC00000205',
    compoundRole: 'solvent',
    compoundData: 'acetone',
    component_id: '',
    component: 'temperature',
    operator: 'more than',
    componentValue: 35,
  },
  {
    compound_id: 'SFC00001010',
    compoundRole: 'solvent',
    component_id: '',
    compoundData: '1,2-dichloroethane',
    component: 'temperature',
    operator: 'more than',
    componentValue: 68,
  },
];

export const SYNJET_INITIAL_CONDITIONS = {
  temperature: {
    low: 20,
    med: 20,
    high: 20,
    fixed: 20,
  },
  time: {
    low: moment().startOf('day').minutes(15),
    med: moment().startOf('day').minutes(15),
    high: moment().startOf('day').minutes(15),
    fixed: moment().startOf('day').minutes(15),
  },
  dispensing: {
    volume: 34,
    normalize: false,
    solvent: null,
  },
};

export const SYNJET_PRO_INITIAL_CONDITIONS = {
  temperature: {
    low: 20,
    med: 20,
    high: 20,
    fixed: 20,
  },
  time: {
    low: moment().startOf('day').minutes(15),
    med: moment().startOf('day').minutes(15),
    high: moment().startOf('day').minutes(15),
    fixed: moment().startOf('day').minutes(15),
  },
  dispensing: {
    volume: 500,
    normalize: false,
    solvent: null,
  },
};

export const SYNJET_INITIAL_QUENCHING = {
  smiles: null,
  solvents: null,
  concentration: null,
  equivalents: [null],
  volumes: [null],
  name: '',
};

export const SYNJET_INITIAL_COMPOUND = {
  smiles: null,
  solvents: null,
  concentration: null,
  isLimiting: false,
  equivalents: [null, null, null],
  volumes: [null, null, null],
  name: '',
  solventType: SOLVENT_TYPES.LIQUID,
};

export const SYNJET_PRO_INITIAL_COMPOUND = {
  ...SYNJET_INITIAL_COMPOUND,
  solventType: SOLVENT_TYPES.LIQUID,
};

export const SYNJET_INITIAL_COMPOUND_SCRINNING = {
  smiles: null,
  solvents: null,
  concentration: null,
  equivalents: [null],
  volumes: [null],
  name: '',
  solventType: SOLVENT_TYPES.LIQUID,
};

export const SYNJET_INITIAL_REAGENT_GROUP = {
  name: 'reagent 1',
  isLimiting: false,
  compoundsList: [{ ...SYNJET_INITIAL_COMPOUND_SCRINNING, name: '' }],
};

export const SYNJET_INITIAL_REACTANT_GROUP = {
  name: 'reactant 1',
  isLimiting: false,
  compoundsList: [{ ...SYNJET_INITIAL_COMPOUND_SCRINNING, name: '' }],
};

export const SYNJET_INITIAL_PRODUCT = {
  smiles: null,
};

export const SYNJET_INITIAL_STEP = {
  isFixed: false,
  product: SYNJET_INITIAL_PRODUCT,
  compounds: {
    reactants: [{ ...SYNJET_INITIAL_COMPOUND, name: '' }],
    reagents: [{ ...SYNJET_INITIAL_COMPOUND, name: '' }],
    reactantGroups: [SYNJET_INITIAL_REACTANT_GROUP],
    reagentGroups: [SYNJET_INITIAL_REAGENT_GROUP],
  },
  conditions: [SYNJET_INITIAL_CONDITIONS],
};

export const SYNJET_PRO_INITIAL_STEP = {
  isFixed: false,
  product: SYNJET_INITIAL_PRODUCT,
  compounds: {
    reactants: [{ ...SYNJET_PRO_INITIAL_COMPOUND, name: '' }],
    reagents: [{ ...SYNJET_PRO_INITIAL_COMPOUND, name: '' }],
    reactantGroups: [SYNJET_INITIAL_REACTANT_GROUP],
    reagentGroups: [SYNJET_INITIAL_REAGENT_GROUP],
  },
  conditions: [SYNJET_PRO_INITIAL_CONDITIONS],
};

export const SYNJET_INITIAL_FIXED_STEP = {
  ...SYNJET_INITIAL_STEP,
  isFixed: true,
  product: SYNJET_INITIAL_PRODUCT,
  compounds: {
    ...SYNJET_INITIAL_STEP.compounds,
    reactants: [SYNJET_INITIAL_COMPOUND_SCRINNING],
    reagents: [SYNJET_INITIAL_COMPOUND_SCRINNING],
  },
};

export const SYNJET_INITIAL_RULE = {
  compoundRole: '',
  compoundData: '',
  component: '',
  operator: '',
  componentValue: 0,
  compoundSmiles: '',
  componentSmiles: '',
};

export const DISPLAY_OPTIONS = {
  equivalent: { label: 'equivalent', measure: 'equiv' },
  volume: { label: 'volume', measure: 'µL' },
  mass_volume: { label: 'volume', measure: 'µL/mg' },
  mass: { label: 'volume', measure: 'mg' },
};

export const BUTTONS_SYNJET_TABLE = [
  { label: 'Equivalent', value: DISPLAY_OPTIONS.equivalent.label },
  { label: 'Volume', value: DISPLAY_OPTIONS.volume.label },
];

export const SYNJET_SOCKET_TYPES = {
  ANALYSIS_DATA: 'synjet_analysis_data',
  BATCH_EXECUTION_UPDATE: 'batch_execution_update',
};

export const BATCH_STATUSES = {
  FINALIZATION: {
    label: 'Finalization',
    value: 'Finalization',
  },
  PENDING_FINALIZATION: {
    label: 'Pending Finalization',
    value: 'Pending Finalization',
  },
};

export const SYNJET_EXPERIMENT_STATUSES = {
  PENDING: {
    label: 'Pending',
    value: 'Pending',
  },
  RUNNING: {
    label: 'Running',
    value: 'Running',
  },
  PAUSED: {
    label: 'Paused',
    value: 'Paused',
  },
  ABORTED: {
    label: 'Aborted',
    value: 'Aborted',
  },
  COMPLETED: {
    label: 'Completed',
    value: 'Completed',
  },
};

export const SYNJET_EXPERIMENT_TABLE_SCHEMA = {
  2: {
    temperature: [-1, 0, 1, -1, 0, 1, -1, 0, 1],
    time: [-1, -1, -1, 0, 0, 0, 1, 1, 1],
  },
  3: {
    temperature: [-1, -1, 0, 1, 1, -1, 0, 0, 0, 0, 1, -1, -1, 0, 1, 1],
    time: [-1, -1, -1, -1, -1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1],
    variableCompound1: [1, -1, 0, -1, 1, 0, 1, 0, -1, 0, 0, 1, -1, 0, -1, 1],
  },
  4: {
    temperature: [
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      0,
      1,
      1,
      1,
      -1,
      -1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      1,
      1,
      1,
      1,
    ],
    time: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    variableCompound1: [
      -1,
      0,
      -1,
      1,
      1,
      0,
      0,
      -1,
      1,
      -1,
      0,
      1,
      0,
      -1,
      1,
      0,
      -1,
      0,
      0,
      0,
      0,
      1,
      -1,
      0,
      -1,
      1,
      1,
      0,
      1,
      -1,
      0,
      -1,
    ],
    variableCompound2: [
      -1,
      1,
      0,
      -1,
      1,
      0,
      0,
      1,
      0,
      -1,
      0,
      0,
      0,
      0,
      -1,
      0,
      1,
      0,
      -1,
      -1,
      0,
      1,
      -1,
      -1,
      1,
      1,
      0,
      1,
      -1,
      0,
      1,
      -1,
    ],
  },
  5: {
    temperature: [
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      1,
      1,
      1,
      1,
      1,
      -1,
      -1,
      0,
      0,
      0,
      1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
    time: [
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
    variableCompound1: [
      1,
      0,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      -1,
      1,
      1,
      1,
      -1,
      0,
      1,
      0,
      0,
      1,
      -1,
      -1,
      1,
      1,
      1,
      0,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      0,
      1,
    ],
    variableCompound2: [
      1,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      0,
      -1,
      0,
      0,
      -1,
      -1,
      1,
      0,
      1,
      -1,
      1,
      0,
      -1,
      0,
      1,
      1,
      -1,
      -1,
      1,
    ],
    variableCompound3: [
      1,
      0,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      -1,
      1,
      0,
      1,
      0,
      -1,
      0,
      1,
      1,
      -1,
      1,
      1,
      -1,
      -1,
      1,
      0,
      1,
      -1,
      1,
      -1,
      0,
      -1,
      1,
    ],
  },
  6: {
    temperature: [
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      1,
      1,
      1,
      1,
      1,
      -1,
      -1,
      0,
      0,
      0,
      1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
    time: [
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
    variableCompound1: [
      1,
      0,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      -1,
      1,
      1,
      1,
      -1,
      0,
      1,
      0,
      0,
      1,
      -1,
      -1,
      1,
      1,
      1,
      0,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      0,
      1,
    ],
    variableCompound2: [
      1,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      0,
      -1,
      0,
      0,
      -1,
      -1,
      1,
      0,
      1,
      -1,
      1,
      0,
      -1,
      0,
      1,
      1,
      -1,
      -1,
      1,
    ],
    variableCompound3: [
      1,
      0,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      -1,
      1,
      0,
      1,
      0,
      -1,
      0,
      1,
      1,
      -1,
      1,
      1,
      -1,
      -1,
      1,
      0,
      1,
      -1,
      1,
      -1,
      0,
      -1,
      1,
    ],
    variableCompound4: [
      -1,
      -1,
      1,
      1,
      1,
      -1,
      -1,
      0,
      1,
      1,
      -1,
      -1,
      -1,
      0,
      -1,
      0,
      -1,
      0,
      1,
      0,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      1,
      0,
      -1,
      1,
      -1,
      0,
      1,
    ],
  },
  7: {
    temperature: [
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      -1,
      -1,
      0,
      0,
      0,
      1,
      1,
      1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
    time: [
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ],
    variableCompound1: [
      1,
      1,
      1,
      -1,
      -1,
      0,
      1,
      -1,
      -1,
      -1,
      -1,
      -1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      -1,
      1,
      1,
      -1,
      1,
      1,
      -1,
      -1,
      -1,
      1,
      1,
      -1,
      0,
      0,
      -1,
      1,
      0,
      -1,
      -1,
      1,
      1,
      1,
      1,
      -1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      1,
      0,
      -1,
      1,
      1,
      1,
      1,
      1,
      -1,
      1,
      1,
      -1,
      -1,
      0,
      -1,
      -1,
    ],
    variableCompound2: [
      -1,
      -1,
      1,
      1,
      0,
      1,
      -1,
      0,
      1,
      -1,
      1,
      -1,
      -1,
      1,
      0,
      -1,
      -1,
      -1,
      1,
      0,
      1,
      -1,
      1,
      -1,
      0,
      1,
      1,
      -1,
      -1,
      -1,
      1,
      1,
      0,
      0,
      0,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      -1,
      0,
      1,
      1,
      -1,
      -1,
      -1,
      -1,
      1,
      1,
      -1,
      -1,
      1,
      1,
      1,
      1,
      -1,
      1,
      -1,
      -1,
      -1,
      1,
    ],
    variableCompound3: [
      1,
      -1,
      1,
      -1,
      1,
      0,
      -1,
      0,
      1,
      -1,
      1,
      0,
      -1,
      -1,
      -1,
      1,
      1,
      -1,
      -1,
      -1,
      1,
      -1,
      0,
      1,
      1,
      1,
      1,
      -1,
      1,
      -1,
      -1,
      -1,
      0,
      0,
      0,
      -1,
      1,
      0,
      -1,
      1,
      1,
      1,
      -1,
      1,
      -1,
      1,
      1,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      0,
      0,
      1,
      1,
      1,
      -1,
      -1,
      -1,
      -1,
      -1,
      1,
      1,
      -1,
    ],
    variableCompound4: [
      1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      0,
      -1,
      0,
      1,
      1,
      -1,
      0,
      1,
      1,
      -1,
      0,
      1,
      -1,
      1,
      1,
      -1,
      -1,
      0,
      1,
      -1,
      1,
      1,
      1,
      0,
      -1,
      0,
      0,
      1,
      1,
      -1,
      -1,
      -1,
      1,
      -1,
      0,
      -1,
      1,
      1,
      1,
      1,
      -1,
      1,
      -1,
      -1,
      1,
      1,
      1,
      1,
      1,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      -1,
      -1,
    ],
    variableCompound5: [
      1,
      1,
      0,
      1,
      1,
      0,
      -1,
      -1,
      -1,
      0,
      1,
      -1,
      1,
      1,
      0,
      -1,
      1,
      1,
      -1,
      -1,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      1,
      -1,
      1,
      -1,
      1,
      -1,
      0,
      0,
      1,
      1,
      -1,
      -1,
      1,
      -1,
      -1,
      0,
      -1,
      1,
      1,
      -1,
      1,
      -1,
      1,
      0,
      1,
      -1,
      1,
      0,
      -1,
      1,
      1,
      -1,
      1,
      1,
      0,
      -1,
      1,
      1,
      1,
      -1,
    ],
  },
};

export const LABEL_STEPS = {
  1: '1-Step',
  2: '2-Steps',
};

export const MESSAGES = {
  83: 'Route has been successfully converted into a Process',
  237: 'Route step contains >1 Reaction stage and could not be converted into SynJet process',
  238: 'Route step contains >5 reactants/reagents for and could not be converted into SynJet process',
};
