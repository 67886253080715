import './styles.scss';

import React from 'react';

import cn from 'classnames';

import { FooterContainer, FooterScrollObserver } from '@atoms';

import { SCROLL_BOX_SIZES } from './constants';

export const ScrollBox = ({
  size = SCROLL_BOX_SIZES.medium,
  subHeader,
  className,
  body,
  bodyRender,
  header,
  actions,
  isFooter = true,
}) => {
  const render = () => (
    <div
      className={cn('scroll-box', className, size, {
        'is-footer': isFooter,
      })}
    >
      <div className="scroll-box_header">{header}</div>
      {subHeader && <div className="scroll-box_sub-header">{subHeader}</div>}
      <div className="scroll-box_body">{body || bodyRender()}</div>
      {isFooter && <FooterContainer>{actions}</FooterContainer>}
    </div>
  );

  if (isFooter) {
    return <FooterScrollObserver>{render()}</FooterScrollObserver>;
  }
  return render();
};
