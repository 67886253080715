import { useEffect, useState } from 'react';

export const useDebounce = ({ action, value, delay = 1000, deps = [] }) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if (action) {
        action();
      }
    }, delay);

    return () => clearTimeout(handler);
  }, [...deps, delay, value]);

  return debouncedValue;
};
