import React, { useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { SynJetExperimentTable } from '../../../SynJet/SynJetProccess/SynJetExperimentTable/SynJetExperimentTable';
import { DISPLAY_OPTIONS } from '../../../../constants';
import { ButtonGroup } from '../../../Common/ButtonGroup';
import { Button, Divider } from '../../../Common';
import { MassOfInterestPreviewModal } from './MassOfInterestPreviewModal';

const buttons = [
  { label: 'Equivalent', value: DISPLAY_OPTIONS.equivalent.label },
  { label: 'Volume', value: DISPLAY_OPTIONS.volume.label },
];

export const ExperimentInfo = ({ experiment, experimentInfo, isPro }) => {
  const [selectedDisplayOption, setSelectedDisplayOption] = useState(DISPLAY_OPTIONS.equivalent.label);
  const [massOfInterestModalOpen, setMassOfInterestModalOpen] = useState(false);

  return (
    <div className="experiment-info">
      <div className="experiment-info__online-analysis">
        {!isPro && (
          <div className="experiment-info__row">
            <h5 className="experiment-info__subtitle">Online analysis</h5>
            <div className="experiment-info__online-analysis__list">
              {experiment?.analyticalType?.length ? (
                experiment.analyticalType.map(type => (
                  <>
                    <span>{type}</span>
                  </>
                ))
              ) : (
                <span>Other</span>
              )}
              {experiment?.analyticalSetting && (
                <Button type="text" onClick={() => setMassOfInterestModalOpen(true)}>
                  <EyeOutlined />
                </Button>
              )}
            </div>
          </div>
        )}
        <Divider />
      </div>
      <div className="experiment-info__table-container">
        <div className="experiment-info__row">
          <div className="experiment-info__title">Experiment table</div>
          <ButtonGroup
            className="experiment-info__table-container__button-group"
            value={selectedDisplayOption}
            onChange={value => {
              setSelectedDisplayOption(value);
            }}
            options={buttons}
          />
        </div>
      </div>
      {experimentInfo && (
        <div className="experiment-info__table-container__table">
          <SynJetExperimentTable
            previewMode
            experiments={{ process_steps: experimentInfo?.processSteps }}
            displayOption={selectedDisplayOption}
            processType={experiment?.process?.type}
            processSteps={experimentInfo?.steps}
            isQuenchingEnabled={experiment?.process?.enableQuenching}
            showCopiedVials
            enableVialCopying={experiment?.enableVialCopying}
          />
        </div>
      )}
      {massOfInterestModalOpen && (
        <MassOfInterestPreviewModal
          open={massOfInterestModalOpen}
          analyticalType={experiment?.analyticalType}
          handleClose={() => setMassOfInterestModalOpen(false)}
          experimentName={experiment?.name}
          data={experiment?.analyticalSetting ? JSON.parse(experiment?.analyticalSetting) : null}
        />
      )}
    </div>
  );
};
