import React from 'react';
import { Button } from '../Common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { checkIfMyLock } from '../../utils/checkIfMyLock';
import { LockLabel } from '../ProcessBuilder/LockLabel/LockLabel';

export const Footer = ({
  onSave,
  onCancel,
  onValidate,
  onCreateExperiment,
  editMode,
  setEditMode,
  process,
  loadingButton,
  showEditValidate = true,
  disableCreateExperiment,
  showCreateExperimentBtn,
  showValidationErrorsCounter = false,
  disableValidate = false,
  count,
  disableSaveProcess = false,
  fixedFooter,
}) => {
  const disableButton = checkIfMyLock(process?.processLock) || loadingButton || disableSaveProcess;
  return (
    <div className={fixedFooter ? 'builder-footer fixed-footer' : 'builder-footer'}>
      <div>
        {editMode ? (
          <>
            <Button
              style={{ marginRight: 8 }}
              onClick={() => onSave()}
              disabled={disableButton}
              loading={loadingButton}
            >
              Save
            </Button>
            <Button secondary onClick={onCancel}>
              Cancel
            </Button>
          </>
        ) : (
          showEditValidate && (
            <Button onClick={setEditMode} disabled={disableButton}>
              Edit
            </Button>
          )
        )}
        <LockLabel processLock={process?.processLock} />
      </div>
      <div>
        {showValidationErrorsCounter && (
          <Badge count={count} showZero>
            <div className="badge-wrapper">
              <ExclamationCircleOutlined />
            </div>
          </Badge>
        )}
        {showEditValidate && (
          <Button
            secondary
            style={{ marginRight: 8 }}
            onClick={onValidate}
            disabled={process?.status === 'experiment submitted' || disableButton || disableValidate}
            disabledTooltip={
              editMode
                ? checkIfMyLock(process?.processLock)
                  ? `The process is being edited by ${process?.processLock.createdBy.firstName} ${process?.processLock.createdBy.lastName}`
                  : null
                : 'The process can only be validated in edit mode'
            }
          >
            Validate
          </Button>
        )}
        {showCreateExperimentBtn && (
          <Button
            disabled={disableCreateExperiment}
            onClick={onCreateExperiment}
            disabledTooltip={
              checkIfMyLock(process?.processLock) &&
              `The process is being edited by ${process?.processLock.createdBy.firstName} ${process?.processLock.createdBy.lastName}`
            }
          >
            Create an experiment
          </Button>
        )}
      </div>
    </div>
  );
};
