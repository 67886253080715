import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { Select, Button, Search } from '..';
import { getStrTagPlaceholder } from '../../utils';
export const FiltersList = ({
  createProject,
  searchAction,
  valueSearch,
  onChangeSearch,
  filtersOptions,
  onChangeDropDowns,
  filtersValue,
  resetFilters,
  permissions,
}) => {
  const onChangeTypes = (value, item) => {
    onChangeDropDowns('type', value);
  };

  const onChangeStatuses = (value, item) => {
    onChangeDropDowns('status', value);
  };

  const onChangePrograms = (values, item, ...e) => {
    onChangeDropDowns(
      'programs',
      values.slice(-1)[0] === 'all' || values.length === filtersOptions.programs.length - 1
        ? filtersOptions.programs.map(i => i.value)
        : values
    );
  };

  const deselectItem = (value, item) => {
    if (value === 'all') onChangeDropDowns('programs', []);
    else
      onChangeDropDowns(
        'programs',
        filtersValue.programs.filter(i => i !== value && i !== 'all')
      );
  };

  return (
    <div className="filters">
      <Search
        placeholder="Search by name or ID"
        value={valueSearch}
        allowSpecials={true}
        searchAction={searchAction}
        onChange={onChangeSearch}
        onKeyPress={e => {
          if (e.charCode === 13) searchAction();
        }}
      />
      <div className="group-selects">
        <Select showSearch={false} value={filtersValue.type} options={filtersOptions.types} onChange={onChangeTypes} />
        <Select
          onChange={onChangeStatuses}
          showSearch={false}
          value={filtersValue.status}
          options={filtersOptions.statuses}
        />
        <Select
          showArrow={true}
          hideValueOnFocus
          onDeselect={deselectItem}
          onChange={onChangePrograms}
          options={filtersOptions.programs}
          value={filtersValue.programs}
          maxTagPlaceholder={opts => getStrTagPlaceholder(opts, filtersOptions.programs, 'programs')}
          maxTagCount={0}
          mode="multiple"
        />
        <Button secondary onClick={resetFilters}>
          Reset filters
        </Button>
      </div>
      {permissions.create_project && <Button onClick={createProject}>Create a project</Button>}
    </div>
  );
};
