import React from 'react';
import { PROJECT_TYPES } from '../../../constants';
import { Tooltip } from '../../Common';
import { InfoCircleOutlined } from '@ant-design/icons';
import CompoundSmilesPreview from '../../CompoundSmilesPreview/CompoundSmilesPreview';

const getTableCell = data => {
  if (!data || !data.value) {
    return <div />;
  }

  if (!data.label.startsWith('T') && data.value < 0.001) {
    return (
      <Tooltip title={data.value}>
        <span className="cell-data cell-data-error">
          {data.label} <span className="cell-data-faded">(0.000)</span>
        </span>
      </Tooltip>
    );
  }

  return (
    <span className="cell-data">
      {data.label} <span className="cell-data-faded">({Number(data.value).toFixed(3)})</span>
    </span>
  );
};

const getTableTimeCell = data => {
  return data ? (
    <span className="cell-data">
      {data.label} <span className="cell-data-faded">({data.value || ''})</span>
    </span>
  ) : (
    <div />
  );
};

export const getOptimizationsTableColumns = processData => {
  const variableReactorIndex = processData.findIndex(reactor => reactor.variable);
  const columns = [
    {
      title: 'Reaction #',
      dataIndex: 'reactionNumber',
      key: 'reactionNumber',
      align: 'right',
      render: (text, record, index) => index + 1,
    },
  ];

  let pumpNumber = 1;

  processData.forEach((reactor, reactorIndex) => {
    reactor.pumps.forEach((pump, pumpIndex) => {
      columns.push({
        title: `P${pumpNumber} flow rate, mL/min`,
        dataIndex: `P${reactorIndex + 1}${pumpIndex + 1}`,
        key: `P${reactorIndex + 1}${pumpIndex + 1}`,
        render: getTableCell,
      });
      pumpNumber++;
    });
    if (reactor.temperatures && reactor.type !== 'liquid') {
      columns.push({
        title: `${reactor.key} temp, ° C`,
        dataIndex: `RT${reactorIndex + 1}`,
        key: `RT${reactorIndex + 1}`,
        render: getTableCell,
        className: variableReactorIndex === reactorIndex && 'reaction-table_header_temperature_column-variable',
      });
    }
    if (reactor.times && reactor.type !== 'liquid') {
      columns.push({
        title: `${reactor.key} time, min:sec`,
        dataIndex: `Rt${reactorIndex + 1}`,
        key: `Rt${reactorIndex + 1}`,
        render: getTableTimeCell,
        className: variableReactorIndex === reactorIndex && 'reaction-table_header_time_column-variable',
      });
    }
  });

  return columns;
};

const getLibraryGenerationColumns = processData => {
  const columns = [
    {
      title: 'Reaction #',
      dataIndex: 'reactionNumber',
      key: 'reactionNumber',
      align: 'right',
      width: '100px',
      render: (text, record, index) => index + 1,
    },
  ];

  let pumpNumber = 1;

  processData.forEach(reactor => {
    reactor.pumps.forEach(() => {
      columns.push({
        title: `Pump ${pumpNumber} compound`,
        dataIndex: `P${pumpNumber}`,
        key: `P${pumpNumber}`,
        render: text => {
          return (
            <div className="cell-label">
              <div>{text.label}</div>
              {!!text.compounds.length && (
                <Tooltip
                  title={
                    <CompoundSmilesPreview
                      compounds={text.compounds}
                      options={{ width: 120, height: 80 }}
                      hideMW
                      multipleCompounds
                      showDivider
                    />
                  }
                  overlayClassName="autosyn-color-card"
                >
                  <InfoCircleOutlined className="icon-compound" />
                </Tooltip>
              )}
            </div>
          );
        },
      });
      pumpNumber++;
    });
  });

  return columns;
};

export const getReactionTableColumns = (processData, processType) => {
  if (!processData || !processData.length) {
    return [];
  }

  if (processType === PROJECT_TYPES.OPTIMIZATION) {
    return getOptimizationsTableColumns(processData);
  } else if (processType === PROJECT_TYPES.LIBRARY_GENERATION) {
    return getLibraryGenerationColumns(processData);
  }
};
