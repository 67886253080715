import React, { useEffect, useState } from 'react';
import { SmilesSearcher } from 'components/SmilesSearcher';
import { updateTargetProduct } from 'store/projects/projects.actions';
import { useDispatch } from 'react-redux';
import './style.scss';
import { openNotification, Popup } from 'components/Common';
import { USER_ROLES } from '../../../../constants/common';

export const TargetProductTab = ({ project, getProjectDetails, userRoles }) => {
  const dispatch = useDispatch();
  const [compound, setCompound] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const isSysAdmin = userRoles && userRoles.includes(USER_ROLES.sysAdmin);
  const isPM = userRoles && userRoles.includes(USER_ROLES.pm);

  useEffect(() => {
    if (project?.uuid) {
      setCompound(project.targetCompound ? JSON.parse(project.targetCompound) : null);
    }
  }, [project?.uuid]);

  const onChange = (targetCompound, action = 'set') => {
    setLoading(true);
    dispatch(
      updateTargetProduct({
        uuid: project?.uuid,
        targetCompound,
      })
    ).then(resp => {
      setLoading(false);
      if (resp.errors || action === 'delete') {
        setCompound(null);
        setDeleteConfirmationOpen(false);
        if (resp.errors)
          resp.errors.forEach(err => {
            openNotification(err.message[0]);
          });
        if (action === 'delete') {
          getProjectDetails();
        }
      } else {
        setCompound(targetCompound);
        getProjectDetails();
      }
    });
  };

  return (
    <>
      <div className="target-product">
        <div className="target-product__title">Target product</div>
        {isSysAdmin || isPM || compound ? (
          <div className="target-product__content">
            <div className="target-product__content__title">Add compound</div>
            <SmilesSearcher
              changeSelect={onChange}
              data={compound}
              onDeleteAction={() => setDeleteConfirmationOpen(true)}
              showDeleteConfirmation={true}
              userRoles={userRoles}
            />
          </div>
        ) : (
          <div className="target-product__content"></div>
        )}
      </div>
      <Popup
        open={deleteConfirmationOpen}
        title="Remove Target product"
        handleSubmit={() => {
          onChange({}, 'delete');
        }}
        handleCancel={() => {
          setDeleteConfirmationOpen(false);
        }}
        textSubmit="Confirm"
        loading={loading}
      >
        <div>Please confirm that you want to remove Target product from the current project</div>
      </Popup>
    </>
  );
};
