import React, { useMemo } from 'react';
import SynJetProductInfoView from './SynJetProductInfoView';
import SynJetProductInfoEdit from './SynJetProductInfoEdit';
import SynJetProProductListItemView from './SynjetProProductListItemView';
import SynJetProProductListItemEdit from './SynjetProProductListItemEdit';
import { getLimitedCompoundFromCombination, getProductCalculatedYield } from '../../../../utils/synjetHelpers';

const SynjetProduct = ({ isViewMode, compoundInfo, validationErrors, setValidationErrors, compound, setCompound }) => {
  if (isViewMode) {
    return <SynJetProductInfoView compoundInfo={compoundInfo} />;
  }
  return (
    <SynJetProductInfoEdit
      validationErrors={validationErrors}
      clearValidationErrors={field =>
        setValidationErrors(errors => ({
          ...errors,
          [field]: null,
        }))
      }
      compound={compound}
      setCompound={setCompound}
    />
  );
};

const SynjetProProductListItem = ({
  isViewMode,
  compoundInfo,
  validationErrors,
  setValidationErrors,
  compound,
  setCompound,
  conditions,
  isExpectedIntermediate,
  disableConditionDelete,
  fixedConditions,
  limitingCompound,
}) => {
  const combinationLimitingComponent = useMemo(
    () => getLimitedCompoundFromCombination(compoundInfo.combination) || limitingCompound,
    [compoundInfo.combination, limitingCompound]
  );

  if (isViewMode) {
    return <SynJetProProductListItemView compoundInfo={compoundInfo} isExpectedIntermediate={isExpectedIntermediate} />;
  }
  return (
    <SynJetProProductListItemEdit
      validationErrors={validationErrors}
      clearValidationErrors={field =>
        setValidationErrors(errors => ({
          ...errors,
          [field]: null,
        }))
      }
      compound={compound}
      setCompound={setCompound}
      conditions={conditions}
      isExpectedIntermediate={isExpectedIntermediate}
      disableConditionDelete={disableConditionDelete}
      fixedConditions={fixedConditions}
      limitingCompound={combinationLimitingComponent}
    />
  );
};

export const SynjetProductWrapper = ({ isProListItem, ...props }) => {
  if (isProListItem) {
    return <SynjetProProductListItem {...props} />;
  }

  return <SynjetProduct {...props} />;
};
