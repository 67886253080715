import './TypeToSearchSelectSmile.styles.scss';
import '../../../../components/Common/Select/style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Select as AntdSelect, Spin } from 'antd';
import cn from 'classnames';
import { Jsme, Popover } from 'components';

import SmileSvg from '../../../../dist/images/smile.svg';
import { useDebounce } from '../../../../utils/customHooks';

export const TypeToSearchSelectSmile = ({ getData, value, onChange }) => (
  <Select
    className="type-to-search-select"
    getOptions={search => getData(search)}
    value={value || undefined}
    onChange={onChange}
  />
);
const Select = ({ onChange, getOptions, value, className }) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSelectOpened, setIsSelectOpened] = useState(false);
  const ref = useRef();

  const debounceSearchValue = useDebounce(search, 600)?.trim();
  const invokeRequest = async () => {
    setLoading(true);
    setOptions([]);
    try {
      const data = await getOptions(debounceSearchValue);
      setOptions(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSelectOpened) {
      invokeRequest();
    }
  }, [debounceSearchValue, isSelectOpened]);

  const onClickWindow = useCallback(event => {
    if (ref.current) {
      setIsSelectOpened(ref.current.contains(event.target));
    }
  }, []);
  useEffect(() => {
    window.addEventListener('click', onClickWindow);

    return () => {
      window.removeEventListener('click', onClickWindow);
    };
  }, [!!ref.current]);
  useEffect(() => {
    if (!isSelectOpened) {
      setSearch('');
      setLoading(false);
    }
  }, [isSelectOpened]);
  const onChangedSmile = smile => setSearch(smile);

  const onSearch = searchTerm => setSearch(searchTerm);

  return (
    <button ref={ref} className="container-select container-select_smiles">
      <AntdSelect
        getPopupContainer={() => ref.current}
        className={cn(
          'select',
          {
            'select-type-tags_is-value': !!value?.length,
            // 'select-type-tags_hide-value': focusFilters,
          },
          className
        )}
        filterOption={false}
        labelInValue
        open={isSelectOpened}
        placeholder="Please type to select"
        mode="multiple"
        onChange={newValue => {
          setSearch('');
          onChange(newValue.map(option => ({ label: option.label, value: option.value })));
        }}
        showSearch
        showArrow
        options={options}
        value={value}
        onSearch={onSearch}
        searchValue={search}
        notFoundContent={loading ? <Spin size="small" /> : undefined}
        suffixIcon={
          <Popover
            getPopupContainer={() => ref.current}
            destroyTooltipOnHide
            trigger="click"
            content={<Jsme id="search-jsme" handleChangeSmiles={onChangedSmile} />}
          >
            <img className="type-to-search-select_smile-drawer" src={SmileSvg} />
          </Popover>
        }
      />
    </button>
  );
};
