import React, { useState } from 'react';
import './style.scss';
import { Switch } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Button, CompoundSmilesPreview, Input, Select, Tooltip } from '../../../index';
import { SmilesSearcher } from '../../../SmilesSearcher';
import { SynjetProCombinationView } from '../SynjetPro/SynjetProCombinationView';
import { getConditionalLabel, getProductCalculatedYield } from '../../../../utils/synjetHelpers';
import { SynjetProQuenchingView } from '../SynjetPro/SynjetProQuenchingView';
import { setForceProductConditionGeneration } from '../../../../store/synjet/synjet.actions';
import { INPUT_EXCLUDE_SYMBOLS } from '../../../../constants/regexp';

const getConditionOptions = (conditions, productConditions, currentCondition) => {
  const mappedConditions = conditions.map((condition, index) => ({
    label: getConditionalLabel(condition),
    value: index,
  }));
  const unusedConditions = mappedConditions.filter(
    ({ value }) =>
      value === currentCondition.initialIndex ||
      !productConditions.find(productCondition => productCondition.initialIndex === value && !productCondition.isFixed)
  );
  return unusedConditions;
};

const getFixedConditionOptions = fixedConditions =>
  fixedConditions.map(condition => ({
    label: getConditionalLabel(condition),
    value: 0,
  }));

const ProductConditionsList = ({
  productConditions,
  conditions,
  handleConditionDelete,
  handleConditionAdd,
  handleConditionChange,
  disableConditionDelete,
  fixedConditions,
  isEI,
}) => {
  const fixedConditionsCount = isEI ? 0 : fixedConditions?.length || 0;
  const allConditions = conditions?.length + fixedConditionsCount;
  const showAddCondition = !disableConditionDelete && allConditions > productConditions.length;

  const getOptions = productCond =>
    productCond.isFixed
      ? getFixedConditionOptions(fixedConditions)
      : getConditionOptions(conditions, productConditions, productCond);
  return (
    <>
      {productConditions.map((productCondition, index) => (
        <div className="product-condition-edit">
          {disableConditionDelete ? (
            <Tooltip title="This condition is fixed on the first step">
              <Select
                options={getOptions(productCondition)}
                placeholder="None"
                className="condition-select"
                value={productCondition.isFixed ? 0 : productCondition.initialIndex}
                disabled
              />
            </Tooltip>
          ) : (
            <Select
              options={getOptions(productCondition)}
              placeholder="None"
              className="condition-select"
              value={productCondition?.initialIndex}
              onChange={value => handleConditionChange(value, index)}
            />
          )}
          {!!index && !productCondition.isFixed && !disableConditionDelete && (
            <Button
              type="text"
              className="delete-condition"
              onClick={() => handleConditionDelete(productCondition?.initialIndex)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      ))}
      {showAddCondition && (
        <Button type="text" className="add-condition" onClick={handleConditionAdd}>
          <PlusOutlined /> CONDITION
        </Button>
      )}
    </>
  );
};
const SynJetProProductListItemEdit = ({
  compound,
  setCompound,
  validationErrors,
  clearValidationErrors,
  conditions,
  fixedConditions,
  isExpectedIntermediate,
  disableConditionDelete,
  limitingCompound,
}) => {
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState('name');

  const handleSmilesChange = product => {
    clearValidationErrors('product');
    clearValidationErrors('name');
    const calculatedYield = getProductCalculatedYield(product, limitingCompound);
    if (product?.compound_id) setCompound({ ...compound, product, calculatedYield });
  };

  const handleIncludeChange = includeProduct => {
    setCompound({ ...compound, includeProduct });
  };

  const handleConditionDelete = deleteKey => {
    setCompound({
      ...compound,
      conditions: compound.conditions.filter(cond => cond.isFixed || cond.initialIndex !== deleteKey),
    });
  };

  const handleConditionAdd = () => {
    setCompound({ ...compound, conditions: [...compound.conditions, {}] });
  };

  const handleConditionChange = (value, index) => {
    if (compound.name.startsWith('EI')) {
      dispatch(setForceProductConditionGeneration(true));
    }
    clearValidationErrors('conditions');
    setCompound({
      ...compound,
      conditions: compound.conditions.map((cond, condIndex) =>
        condIndex === index ? { ...conditions[value], initialIndex: cond?.isFixed ? 0 : value } : cond
      ),
    });
  };

  return (
    <>
      <div>
        <SynjetProCombinationView combination={compound.combination} />
        {compound.quenching && <SynjetProQuenchingView quenching={compound.quenching} />}
      </div>
      <div className="synjet-compound__info__condition-list">
        <ProductConditionsList
          conditions={conditions}
          fixedConditions={fixedConditions}
          productConditions={compound.conditions}
          handleConditionDelete={handleConditionDelete}
          handleConditionAdd={handleConditionAdd}
          handleConditionChange={handleConditionChange}
          disableConditionDelete={disableConditionDelete}
          isEI={compound.name.startsWith('EI')}
        />
        {validationErrors?.conditions && <p className="error">{validationErrors?.conditions}</p>}
      </div>
      {isExpectedIntermediate ? (
        <div />
      ) : (
        <div className="synjet-compound__info__include">
          <Switch checked={compound.includeProduct} onChange={handleIncludeChange} />
        </div>
      )}
      <div className="synjet-compound__info__smiles">
        <SmilesSearcher
          synjet
          changeSelect={handleSmilesChange}
          smilesPlaceholder="Search"
          data={compound?.product}
          handleCompoundChange={() => setCompound({ ...compound, smiles: null })}
          initialSearchValue={compound?.product?.compound_name}
          setSelectValue={setSelectValue}
          error={selectValue === 'name' ? validationErrors?.name : validationErrors?.product}
          errorText={selectValue === 'name' ? validationErrors?.name : validationErrors?.product}
        />
        <CompoundSmilesPreview
          compound={compound?.product}
          className="smiles-preview"
          options={{ width: 130, height: 80 }}
          hideMW
          showTooltip
        />
      </div>
      <div className="synjet-compound__info-product__mass">
        <Input
          className="calculated-yeild-input"
          placeholder="0"
          maxLength={10}
          regex={INPUT_EXCLUDE_SYMBOLS}
          disabled
          value={compound?.calculatedYield}
          error={validationErrors?.calculatedYield && validationErrors?.calculatedYield}
          errorText={validationErrors?.calculatedYield && validationErrors?.calculatedYield}
        />
      </div>
    </>
  );
};

export default SynJetProProductListItemEdit;
