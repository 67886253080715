import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { getExtensibleExperiment } from 'store/experiment/experiment.actions';
import { getData } from 'store/slices/extensibleTableFieldsGrid/getters';

import { FIELD_DATA_TYPES } from '@organisms';
import { getExperimentFieldValue } from '@templates';

const ExperimentDetailsContext = createContext(null);
export const useExperimentDetailsContext = () => useContext(ExperimentDetailsContext);

export const useExperimentDetailsController = ({ experimentRowData }) => {
  const [editableExperimentFields, setEditableExperimentFields] = useState(null);
  const [editedSmileFieldUuid, setEditedSmileFieldUuid] = useState();
  useEffect(() => {
    setEditableExperimentFields(experimentRowData);
  }, [experimentRowData]);
  const editExperimentFields = ({ value, fieldName, uuid, dataType }) => {
    if (dataType === FIELD_DATA_TYPES.smile) {
      setEditedSmileFieldUuid(`${fieldName}:${uuid}:${value}`);
    }
    setEditableExperimentFields(prev => ({ ...prev, [fieldName]: value }));
  };
  return {
    editableExperimentFields,
    editExperimentFields,
    editedSmileFieldUuid,
  };
};

export const ExperimentDetailsHOC = ({ children, uuid }) => {
  const [experimentRowData, setExperimentRowData] = useState(null);
  const tableFieldsGrid = useSelector(getData);

  useEffect(() => {
    getExtensibleExperiment({ uuid }).then(resp => {
      const data = {};
      tableFieldsGrid.forEach(({ fieldName, dataType }) => {
        data[fieldName] = getExperimentFieldValue({ storage: resp, dataType, fieldName });
      });
      setExperimentRowData(data);
    });
  }, []);
  const { editableExperimentFields, editExperimentFields, editedSmileFieldUuid } = useExperimentDetailsController({
    experimentRowData,
  });

  const value = useMemo(
    () => ({
      editableExperimentFields,
      editExperimentFields,
      editedSmileFieldUuid,
    }),
    [editableExperimentFields, editedSmileFieldUuid]
  );

  return <ExperimentDetailsContext.Provider value={value}>{children}</ExperimentDetailsContext.Provider>;
};
