import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { Button } from 'components/Common';
import { AttachedFiles } from 'components/Experiment/ResultsTab/resultsComponents';

import { BUTTONS_SYNJET_TABLE, DEVICES, DISPLAY_OPTIONS, EXPERIMENT_STATUSES, FILE_TYPES } from '../../../constants';
import { transferIsoToMMDDYYutc } from '../../../utils/date';
import { getExperimentTableData } from '../../../utils/execution';
import { generateCSVFile } from '../../../utils/generateCSVFile';
import { parseProcessDataFromBackend } from '../../../utils/synjetHelpers';
import { getExecutionIdToVialNameDeps, withCalibrationTemperature } from '../../../utils/synjetProExecutionHelpers';
import { MassOfInterestTable } from '../../AnalyticalSettings/MassOfInterestTable';
import { RegressionTables } from '../../AnalyticalSettings/RegressionTables';
import { ButtonGroup, NoResultsEmptyState, openNotification } from '../../Common';
import { ExperimentSummary } from '../../Execution/ExperimentProgress/ExperimentSummary';
import { SynJetExperimentTable } from '../../SynJet/SynJetProccess/SynJetExperimentTable/SynJetExperimentTable';

export const AnalyticalTab = ({
  data = [],
  experiment,
  allReactors,
  blueBackground,
  isSynJet,
  isSynJetPro,
  isProjectPage,
  batch,
}) => {
  const [selectedDisplayOption, setSelectedDisplayOption] = useState(DISPLAY_OPTIONS.equivalent.label);
  const [experimentData, setExperimentData] = useState({});
  const [experimentSteps, setExperimentSteps] = useState([]);
  const [originalProcessSteps, setOriginalProcessSteps] = useState(null);

  const executionTemperatures =
    experiment?.execution?.executionSteps
      ?.sort((a, b) => Number(a.step) - Number(b.step))
      .map(step =>
        typeof step.calibrationTemperatures === 'string'
          ? JSON.parse(step.calibrationTemperatures)
          : step.calibrationTemperatures
      ) || [];
  const directoryPath = useRef();

  useEffect(() => {
    if (experiment?.uuid && (isSynJet || isSynJetPro)) {
      const experimentTableData = getExperimentTableData(experiment);
      let exp;
      let executionIdToVialNameDeps;
      if (batch?.batchId) {
        executionIdToVialNameDeps = getExecutionIdToVialNameDeps(batch, experiment);
        exp = withCalibrationTemperature(experimentTableData.processSteps, experiment, executionIdToVialNameDeps);
      }
      setOriginalProcessSteps(experimentTableData.processSteps);
      setExperimentData({ process_steps: batch?.batchId ? exp : experimentTableData.processSteps });
      setExperimentSteps(experimentTableData.steps);
    }
  }, [experiment, batch]);

  const onCopyPath = () => {
    if (directoryPath.current) {
      const text = directoryPath.current.textContent;
      const input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      openNotification('Copied');
    }
  };

  const parseExperimentData = useCallback(
    () => parseProcessDataFromBackend(JSON.parse(experiment?.reactionsInfo), experiment?.process?.variableStep),
    [experiment?.reactionsInfo]
  );

  const logLinks = experiment?.execution?.executionSteps.filter(i => i.logLink) || [];
  const idContainerTab = 'analytical-tab';

  const downloadCSVFile = () => {
    let rows = [];
    const table = document.querySelector('.synjet-experiment-table');
    const tableHeaderRows = table.querySelector('thead').querySelectorAll('tr'); // number of rows depend on number of steps
    const tableBody = table.querySelector('tbody');
    const tableFooter = table.querySelector('tfoot');
    const tableFooterCells = tableFooter.querySelectorAll('.ant-table-cell'); // in footer only one row
    const tableBodyRows = tableBody.querySelectorAll('.ant-table-row');

    tableHeaderRows.forEach((row, rowId) => {
      const cellsInRow = row.querySelectorAll('.ant-table-cell'); // th
      const cellsArray = [];
      const pushEmptyCell = () => {
        cellsArray.push('');
      };
      cellsInRow.forEach(cell => {
        const colspanNumber = cell.getAttribute('colspan'); // define the number of taken useColumns
        let textColumn = '';
        cell.querySelectorAll('.column-name').forEach(item => {
          textColumn += `${item.innerText} `;
        });
        cellsArray.push(textColumn);
        if (colspanNumber) {
          for (let i = 1; i < Number(colspanNumber); i++) {
            pushEmptyCell();
          }
        }
      });

      if (rowId === 1) {
        cellsArray.unshift('');
        cellsArray.unshift('');
        if (rows[0].length > cellsArray.length) {
          const emptyColumns = rows[0].length - cellsArray.length;
          for (let i = 0; i < emptyColumns; i++) {
            pushEmptyCell();
          }
        }
      }
      rows.push(cellsArray);
    });

    tableBodyRows.forEach(row => {
      // tr
      const cellsInRow = row.querySelectorAll('.ant-table-cell'); // td
      const cellsArray = [];
      cellsInRow.forEach(cell => {
        cellsArray.push(cell.innerText);
      });
      rows.push(cellsArray);
    });
    const cellsArray = [];
    tableFooterCells.forEach(cell => {
      cellsArray.push(cell.innerText);
    });
    rows.push(cellsArray);
    rows = rows.map((row, idx) => {
      // special characters only in first row
      row = row.map(cell => {
        if (cell.replace(/ /g, '')?.match(/[\s,"]/)) {
          // Fields containing line breaks (CRLF), double quotes, and commas should be enclosed in double-quotes. Ex: foo,"bar,baz"
          return `"${cell.replace(/"/g, '""')}"`;
        }
        return cell;
      });
      row.splice(0, 1);
      return row;
    });
    const today = transferIsoToMMDDYYutc(new Date());
    generateCSVFile(rows, `${experiment?.uuid}_${today}_Experiment_table`);
  };

  return (
    <div className="analytical-tab" id={idContainerTab}>
      {data?.length && (
        <>
          <div
            className={classNames('analytical-tab_title', { 'analytical-tab_title--blueBackground': blueBackground })}
          >
            Online analysis
          </div>
          <MassOfInterestTable previewMode tableData={data} newCompounds={[]} />
        </>
      )}
      {!isSynJet && !isSynJetPro && (
        <>
          <div
            className={classNames('analytical-tab_title', { 'analytical-tab_title--blueBackground': blueBackground })}
          >
            Experiment data
          </div>
          {experiment?.status === EXPERIMENT_STATUSES.Completed.value ? (
            <div className="experiment-data_content">
              {experiment?.analyticalType?.some(analysis => analysis === FILE_TYPES.lcms) &&
                experiment?.execution?.analysisRootFolderPath && (
                  <div className="analytical-tab_lcms_data">
                    <div>
                      <span className="analytical-tab_lcms_data_label">LC-MS analysis root folder</span>
                      <span ref={directoryPath} className="analytical-tab_lcms_data_link">
                        {experiment?.execution?.analysisRootFolderPath}
                      </span>
                    </div>
                    <Button secondary onClick={onCopyPath}>
                      Copy
                    </Button>
                  </div>
                )}
              <ExperimentSummary
                viewMode
                idContainerTab={idContainerTab}
                experiment={experiment}
                allReactors={allReactors}
                estimatedTime={experiment?.estimatedTime}
                showProgress={false}
              />
            </div>
          ) : (
            <NoResultsEmptyState canAppendResults={false} />
          )}
        </>
      )}
      {!!logLinks.length && (
        <div className="results-info-row">
          <div className="results-info-row_column results-info-row_title">Experiment log file</div>
          <div className="results-info-row_column results-info-row_text">
            <AttachedFiles
              appendedFiles={logLinks.map(i => ({ ...i, file: i.logLink.substr(1), loaded: true }))}
              notEditable
            />
          </div>
        </div>
      )}
      {!!experiment?.execution?.regressionAnalysis && (
        <RegressionTables
          experimentData={experiment}
          isProjectPage={isProjectPage}
          experiment={experiment}
          isInterpolated
        />
      )}
      {experiment?.status === EXPERIMENT_STATUSES.Completed.value &&
        (experiment?.process?.device === DEVICES.SYNJET || experiment?.process?.device === DEVICES.SYNJETPRO) && (
          <>
            <div className="experiment-info__header">
              <h4 className="experiment-info__header__title  small-header">Experiment Table</h4>
              <div className="export-files-buttons">
                <div className="export-csv-block">
                  <Button secondary onClick={downloadCSVFile}>
                    Export to .CSV
                  </Button>
                </div>
                <ButtonGroup
                  className="process-builder-container-display-option-button-group"
                  value={selectedDisplayOption}
                  onChange={value => {
                    setSelectedDisplayOption(value);
                  }}
                  options={BUTTONS_SYNJET_TABLE}
                />
              </div>
            </div>
            {experimentData && experimentSteps.length && (
              <SynJetExperimentTable
                experiments={experimentData}
                displayOption={selectedDisplayOption}
                processType={experiment?.process?.type}
                processSteps={experimentSteps}
                analytics={
                  experiment?.analyticalSetting.length && experiment?.analyticalType?.length
                    ? experiment?.analyticalSetting
                    : []
                }
                analyticsData={
                  experiment?.execution?.sjAnalysisData
                    ? experiment.execution.sjAnalysisData.map(analysis => ({
                        ...analysis,
                        data: JSON.parse(analysis.data),
                      }))
                    : []
                }
                stepStatuses={
                  experiment?.execution?.executionSteps
                    .sort((step1, step2) => Number(step1.step) - Number(step2.step))
                    .map(step => step.status) || []
                }
                isProExecution={isSynJetPro}
                calibrationTemperatures={executionTemperatures}
                analytical
                showActualTemperatures
                selectedExperiment={experiment}
                processStepsWithTime={originalProcessSteps}
                downloadCSVFile={downloadCSVFile}
              />
            )}
            {!!experiment?.execution?.regressionAnalysis && (
              <RegressionTables experimentData={experiment} isProjectPage={isProjectPage} experiment={experiment} />
            )}
          </>
        )}
    </div>
  );
};
