import React, { useEffect, useState } from 'react';
import './style.scss';
import { StatusLabel } from '../../Common/StatusLabel';
import { Switch } from '../../Common/Switch';
import { WorkSpace } from '../../ProcessBuilder/WorkSpace/WorkSpace';
import { EXPERIMENT_STATUSES } from 'constants/common';
import classNames from 'classnames';
import { Item } from '../ReviseTheDetails/Item';
import { useDispatch, useSelector } from 'react-redux';
import { deviceOnline, pumpsOnline, reactorsOnline } from '../../../store/experiment/experiment.selector';
import { getProcessDeviceStatus } from '../../../store/experiment/experiment.actions';
import { openNotification } from '../../Common';
import { getPumpKeys } from '../../../store/processBuilder/processbuilder.actions';

export const StartUpChecklist = ({
  definition,
  checkList,
  setCheckList,
  experiment,
  deviceSetUp,
  setDeviceSetUp,
  stepErrors,
  setErrors,
  processStructure,
  setEdited,
  remoteConnectivity,
  viewMode = false,
}) => {
  const dispatch = useDispatch();
  const isDeviceOnline = useSelector(deviceOnline);
  const onlinePumps = useSelector(pumpsOnline);
  const onlineReactors = useSelector(reactorsOnline);
  const [resetModal, setResetModal] = useState(false);

  useEffect(() => {
    if (experiment?.timeSlot?.device?.uuid) {
      checkDeviseStatus();
    }
  }, [experiment?.timeSlot?.device?.uuid]);

  const checkDeviseStatus = async () => {
    try {
      const data = await dispatch(
        getProcessDeviceStatus(experiment.timeSlot.device.uuid, experiment.timeSlot.device.name)
      );
      if (!data.device) {
        openNotification(null, 'Experiment could not be launched due to execution service connection issues');
      }
      if (!data.connection) {
        openNotification(null, 'Hardware connectivity issue occurred, please wait until connection is restored');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetSetup = () => {
    setResetModal(false);
    setDeviceSetUp(JSON.parse(experiment.defaultPumpKeys));
  };

  const handleSetupChange = (item, value) => {
    setEdited(true);
    const prevSetUp = deviceSetUp.find(device => device.name === item);
    setErrors({ ...stepErrors, [prevSetUp?.key]: null });
    setDeviceSetUp({
      ...deviceSetUp,
      [item]: value,
    });
    const newDeviceSetUp = prevSetUp
      ? deviceSetUp.map(device => (device.name === item ? { ...device, key: value } : device))
      : [...deviceSetUp, { name: item, key: value }];
    setDeviceSetUp(newDeviceSetUp);
  };

  const getPumps = (search, maxFlowRate) => {
    return dispatch(getPumpKeys(search, experiment?.timeSlot?.device.uuid, maxFlowRate)).then(data => {
      return data;
    });
  };
  const handleSwitch = (val, idx) => {
    setCheckList(checkList.map((i, index) => (idx === index ? { ...i, checked: val } : i)));
  };

  const disableSwitch = experiment?.step == 3;
  return (
    <>
      <div className="start-up-check-list">
        <div className="left-parts">
          <div className="start-up-check-list_left">
            <div className="start-up-check-list_left_header">
              <span className="start-up-check-list_left_header_name">Experiment {experiment?.name}</span>
              <StatusLabel type={experiment?.status === EXPERIMENT_STATUSES.Inpreparation.value ? 'active' : 'success'}>
                {experiment?.status}
              </StatusLabel>
            </div>
            {checkList.map((i, idx) => (
              <div className="start-up-check-list_left_item" key={i.name}>
                <span>{i.name}</span>
                <Switch
                  disabled={disableSwitch}
                  checked={disableSwitch || i.checked}
                  onChange={val => handleSwitch(val, idx)}
                />
              </div>
            ))}
          </div>
          <div className={'revise-details-wrapper'}>
            <div className={'left-part'}>
              <div className={'device-name-wrapper'}>
                <span
                  className={classNames('device-name', {
                    'device-name--error': !isDeviceOnline || !remoteConnectivity,
                  })}
                >
                  {experiment?.timeSlot?.device?.name}
                </span>
              </div>
              {processStructure.map(processItem => (
                <Item
                  processItem={processItem}
                  getOptions={getPumps}
                  deviceSetUp={deviceSetUp}
                  handleSetupChange={handleSetupChange}
                  errors={stepErrors}
                  isAutosynOnline={isDeviceOnline}
                  onlinePumps={onlinePumps}
                  onlineReactors={onlineReactors}
                  remoteConnectivity={remoteConnectivity}
                  viewMode={true}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="start-up-check-list_right">{definition && <WorkSpace previewMode data={definition} />}</div>
      </div>
    </>
  );
};
