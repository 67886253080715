import React from 'react';
import { Menu, Dropdown } from 'antd';
import './style.scss';
import { MoreOutlined } from '@ant-design/icons';
import cn from 'classnames';

export const ContextMenu = ({ options, ...rest }) => {
  return (
    <Dropdown
      className="context-menu"
      overlayClassName="context-menu-overlay"
      overlay={
        <Menu>
          {options.map((v, k) => (
            <Menu.Item
              className={cn({ disabled: v.disabled })}
              key={k}
              onClick={e => {
                if (v.disabled) {
                  return false;
                }
                v.onClick(e);
              }}
            >
              {v.title}
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="bottomRight"
      {...rest}
    >
      <MoreOutlined />
    </Dropdown>
  );
};
