import './style.scss';

import React from 'react';

import no_projects from 'dist/images/no-projects.svg';

import { Heading3, TextBody16 } from '@atoms';

export const EmptyState = ({ message = 'Experiments will be available here', action }) => (
  <>
    <div className="experiments-empty-state">
      <div className="experiments-empty-state_caption">
        <Heading3 className="experiments-empty-state_caption_main-caption">No experiments yet</Heading3>
        <TextBody16 className="experiments-empty-state_caption_message">{message}</TextBody16>
        {action}
      </div>
      <img src={no_projects} />
    </div>
  </>
);
