import React, { useEffect, useState } from 'react';
import { AnalyticalTab } from '../../../Experiment/AnalyticalTab';
import { useDispatch } from 'react-redux';
import { getExperimentInfo } from '../../../../store/projects/projects.actions';
import { Spinner } from '../../../Common';
import { DEVICES } from '../../../../constants';
import { mapCompoundToAnalyticDataToFront } from '../../../../utils/experimentHelpers';
import { ResultsTab } from '../../../Experiment/ResultsTab';
import { getAppendedResult } from '../../../../store/experiment/experiment.actions';
import { Info } from '../../../Experiment/Info';
import { parseProcessDataFromBackend } from '../../../../utils/synjetHelpers';
import { formatSecondsToHours } from '../../../../utils';
import { manageBatch } from '../../../../store/scheduling/scheduling.actions';

const mapExperimentData = experiment => {
  if (!experiment) {
    return {};
  }

  return {
    ...experiment,
    analyticalSetting: experiment?.analyticalSetting
      ? mapCompoundToAnalyticDataToFront(JSON.parse(experiment?.analyticalSetting))
      : [],
  };
};

export const ExperimentInfo = ({ itemId, isProjectPage }) => {
  const [experimentData, setExperimentData] = useState(null);
  const [allReactors, setAllReactors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [dataSynJet, setDataSynJet] = useState('');
  const [numberDefinitionSteps, setNumberDefinitionSteps] = useState(0);
  const [timeExpTab, setTimeExpTab] = useState(null);
  const [batch, setBatch] = useState(null);

  const dispatch = useDispatch();

  const getData = async itemId => {
    setLoading(true);
    try {
      const data = await dispatch(getExperimentInfo(itemId));
      const expData = mapExperimentData(data.experiment);
      setExperimentData(expData);

      if (!!expData?.timeSlot && !!expData.timeSlot?.batch) {
        const key = expData.timeSlot.batch?.batchId;
        await dispatch(manageBatch({ data: { key }, type: 'getBatchDetails' })).then(resp => {
          setBatch(resp);
        });
      }

      setDataForSynJet(data.experiment);
      setAllReactors(data.allReactors);
      dispatch(getAppendedResult({ experimentId: data.experiment.uuid })).then(resp => {
        setResultData(resp);
      });
      calculateTime(data.experiment, DEVICES.SYNJET === data.experiment?.process?.device ? 'actualTime' : 'totalTime');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(itemId);
  }, [itemId]);

  const calculateTime = (data, field) => {
    setTimeExpTab(
      data?.actualTime
        ? `${formatSecondsToHours(Math.floor(+data[field])).hour}h ${Math.ceil((+data[field] % 3600) / 60)}min`
        : ''
    );
  };

  const setDataForSynJet = data => {
    if (data?.process?.definition) {
      setNumberDefinitionSteps(JSON.parse(data?.process?.definition).process_steps.length);
      if (DEVICES.SYNJET === data?.process?.device) {
        const { processSteps, steps } = parseProcessDataFromBackend(
          JSON.parse(data?.reactionsInfo),
          data?.process?.variableStep
        );
        let dataReactionsInfo = {
          processSteps,
          steps,
          process: { process: data?.process },
          experimentID: data.uuid,
        };
        setDataSynJet(dataReactionsInfo);
      }
    }
  };

  return (
    experimentData &&
    allReactors && (
      <Spinner loading={loading}>
        {resultData && (
          <ResultsTab
            data={experimentData}
            isSynJet={DEVICES.SYNJET === experimentData?.process?.device}
            sLabDevice={DEVICES.LAB === experimentData?.process?.device}
          />
        )}
        {experimentData?.analyticalSetting?.length && !resultData ? (
          <AnalyticalTab
            blueBackground
            data={experimentData?.analyticalSetting}
            experiment={experimentData}
            allReactors={allReactors}
            isSynJet={experimentData?.process?.device === DEVICES.SYNJET}
            isSynJetPro={experimentData?.process?.device === DEVICES.SYNJETPRO}
            isProjectPage={isProjectPage}
            batch={batch}
          />
        ) : null}
        {!resultData && !experimentData?.analyticalSetting?.length && experimentData && (
          <Info
            dataExperiment={experimentData}
            getExperimentInfo={getExperimentInfo}
            numberDefinitionSteps={numberDefinitionSteps}
            isSynJet={DEVICES.SYNJET === experimentData?.process?.device}
            dataSynJet={dataSynJet}
            estimatedTime={timeExpTab}
            isLabDevice={DEVICES.LAB === experimentData?.process?.device}
          />
        )}
      </Spinner>
    )
  );
};
