import { FIELD_DATA_TYPES } from '@organisms/ExperimentTemplateFieldPopup/constants';
import moment from 'moment';
import { decodeURIComponentSafe } from 'utils';

import { dependenciesRermissions } from '../../constants';
import { PROCESS_TYPES } from '../../constants/common';
import { objectToGraphqlString, objectWithArrayToString, toCapitalizeChart1, toLowerCaseChart1 } from '../../utils';
import { isALlDay, transferIsoToMMDDYYmmHH } from '../../utils/date';
import { processDetailsResp } from './entities';
import { uniqArrayExperiments } from '../../utils/synjetHelpers';

export const queryConstructor = (...args) => {
  let query = '';

  if (args) {
    args.forEach(argument => {
      if (Array.isArray(argument)) {
        argument.forEach(queries => {
          query += `${queries} `;
        });
      } else if (typeof argument === 'string') {
        query += `${argument} `;
      }
    });
  }

  return `query {
    ${query}
  }`;
};
// , search: "${search}"
export const queryListProjects = (mode = 'send', data, page, filters, pageSize = 12) => {
  if (mode === 'send') {
    return `query {
  allProjects ${filters && Object.keys(filters).length > 0 ? `(${objectToGraphqlString(filters)})` : ''} {
  totalCount
  results (page: ${page || 1}, pageSize: ${pageSize}) {
    uuid
    name
    key
    customer
    program
    type
    manager{
    firstName
    lastName
    }
    goals
    status
    createdAt
   }
  }
}`;
  }
  return {
    ...data.allProjects,
    results: data.allProjects.results.map(i => ({
      ...i,
      manager: `${i.manager.firstName} ${i.manager.lastName}`,
      createdAt: transferIsoToMMDDYYmmHH(i.createdAt),
    })),
  };
};

export const queryFiltersProject = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
    projectTypes
  projectStatuses
  allProjects(uniquePrograms: true) {
  results {
    program
    }
  }
}`;
  }
  return {
    types: [
      { label: 'All project types', value: 'all' },
      ...data.projectTypes.map(i => ({ value: i, label: toCapitalizeChart1(i) })),
    ],
    statuses: [
      { label: 'All statuses', value: 'all' },
      ...data.projectStatuses.map(i => ({ value: i, label: toCapitalizeChart1(i) })),
    ],
    programs: [
      { label: 'All programs', value: 'all' },
      ...data.allProjects?.results.map(i => ({ label: i.program, value: i.program })),
    ],
  };
};

export const queryDetailsProject = (mode = 'send', uuid, data) => {
  if (mode === 'send') {
    return `query {
  project(uuid: "${uuid}") {
    uuid
    name
    key
    customer
    program
    type
    goals
    status
    createdAt
    targetCompound
    processes {
      uuid
      updatedAt
      name
      device
      route {
        uuid
        name
      }
      experiments {
        uuid
        key
        updatedAt
        name
      }
    }
    routes {
      uuid 
      name
      createdAt
      processes {
        uuid
        name
      }
    }
    teamMembers{
      uuid
      firstName
      lastName
      email
      roles
    }
    manager{
    firstName
    lastName
    uuid
    }
  }
}`;
  }
  return {
    ...data.project,

    teamMembers: data.project.teamMembers.map(i => ({ ...i, initials: `${i.firstName} ${i.lastName}` })),
    manager: {
      initials: `${data.project.manager.firstName} ${data.project.manager.lastName}`,
      uuid: data.project.manager.uuid,
    },
    createdAt: transferIsoToMMDDYYmmHH(data.project.createdAt),
  };
};

export const queryDetailsCurrentUser = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  currentUser{
  firstName
  lastName
  uuid
  email
  roles
  permissions
  integrations{
type
name
link
}
  projects {
    uuid
    name
  }
}
}`;
  }
  const permissionsEntries = {};
  data.currentUser[0].permissions
    .map(i => i.split('.'))
    .forEach(i => {
      if (!permissionsEntries[i[0]]) {
        permissionsEntries[i[0]] = {};
      }
      permissionsEntries[i[0]][i[1]] = true;
    });

  const permissions = {};
  for (const key in dependenciesRermissions) {
    let permissionsDepencies = {};
    dependenciesRermissions[key].dependencies.forEach(i => {
      if (permissionsEntries?.[i]) permissionsDepencies = { ...permissionsDepencies, ...permissionsEntries[i] };
    });

    permissions[key] = { ...permissionsEntries[key], ...permissionsDepencies };
  }
  return { ...data.currentUser[0], permissions };
};

export const queryListProcess = (mode = 'send', data, page = 1, filters = {}, tab) => {
  const filtersData = objectToGraphqlString(filters);
  if (mode === 'send') {
    let additional;
    const isProcesses = tab === 'Processes';
    if (isProcesses) {
      additional = `
      type
          processLock{
          uuid,
          createdBy{
            uuid,
            firstName,
            lastName
          }
          isMyLock,
        }
      route{
      key
      name
      }
      project {
        uuid
        name
      }
    `;
    } else {
      additional = `
      process{
        uuid
        name
        type
      project{
          name
        }
      }
      `;
    }

    return `query {
  ${isProcesses ? 'allProcesses' : 'allExperiments'}  ${filtersData ? `(${filtersData})` : ''} {
    totalCount
    results(page: ${page}) {
      uuid
      key
      name
      status
      updatedAt
      createdAt
      createdBy {
        firstName
        lastName
        roles
      }
      ${additional}
    }
  }
}`;
  }
  const result = data.allProcesses || data.allExperiments;
  return {
    ...result,
    results: result.results.map(i => ({
      ...i,
    })),
  };
};

export const queryCheckExperimentName = name => `query {
              allExperiments(
                name: "${name}"
              ){
                 totalCount
                }}`;

export const queryFiltersProcess = (mode = 'send', data, pageSize, type) => {
  if (mode === 'send') {
    return `query {
  allProjects {
  results (page: 1, pageSize: ${pageSize || 1000}) {
    name
    uuid
    }
  }
  processStatuses
  experimentStatuses  ${type == PROCESS_TYPES.SynJetPro.key ? '(deviceType: "SynJet Pro")' : ''} 
  processesCreatedBy {
    results (page: 1, pageSize: ${pageSize || 1000}) {
    createdBy {
      uuid
      firstName
      lastName
    }
    }
  }
}`;
  }
  const result = data.experimentStatuses.slice(2, -2).split("', '");
  return {
    projects: [
      { label: 'All projects', value: 'all' },
      ...data.allProjects?.results.map(i => ({ label: i.name, value: i.uuid })),
    ],
    statuses: [
      { label: 'All statuses', value: 'all' },
      ...data.processStatuses.map(i => ({ value: i, label: toCapitalizeChart1(i) })),
    ],
    experimentStatuses: [
      { label: 'All statuses', value: 'all' },
      ...result.map(i => ({ value: i, label: toCapitalizeChart1(i) })),
    ],
    createdBy: [
      { label: 'All creators', value: 'all' },
      ...data.processesCreatedBy.results.map(i => ({
        value: i.createdBy.uuid,
        label: `${i.createdBy.firstName} ${i.createdBy.lastName}`.trim(),
      })),
    ],
  };
};

export const queryFiltersCreators = (mode = 'send', data, filters, search, pageSize = 1000) => {
  if (mode === 'send') {
    return `query {
  processesCreatedBy  ${search ? `(search: "${search}")` : ''} {
    results (page: 1, pageSize: ${pageSize}) {
    createdBy {
      uuid
      firstName
      lastName
    }
    }
  }
}`;
  }
  return {
    createdBy: [
      ...data.processesCreatedBy.results.map(i => ({
        value: i.createdBy.uuid,
        label: `${i.createdBy.firstName} ${i.createdBy.lastName}`.trim(),
      })),
    ],
  };
};

export const queryRoutesOfProject = (mode = 'send', data) => {
  if (mode === 'send') {
    const filters = objectToGraphqlString(data);
    return `query {
  allProjectRoutes${filters ? `(${filters})` : ''} {
  results(page: 1, pageSize: 10) {
    uuid
    key
    name
    }
  }
}`;
  }
  return data.allProjectRoutes.results.map(i => ({ value: i.uuid, label: i.name }));
};

export const queryRouteDetailsFromSave = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  projectRoute(uuid: "${data.uuid}") {
  data
  }
}`;
  }
  const newData = JSON.parse(data.projectRoute.data);
  newData.strategyRouteIndex = { S0R0: newData.routes[0] };
  return newData;
};

export const queryGetUserByRoles = (mode = 'send', data, search = '') => {
  if (mode === 'send') {
    return `query {
    ${data.map(
      i => `${toLowerCaseChart1(i.replace(/\s/g, ''))}: allUsers(roles:["${i}"], search: "${search}"){
    results(page: 1, pageSize: 20){
      firstName
      uuid
      lastName
    }
  }`
    )}
  }`;
  }
  const obj = {};
  Object.keys(data).forEach(i => {
    obj[i] = data[i].results.map(k => ({ label: `${k.firstName} ${k.lastName}`, value: k.uuid }));
  });
  return obj;
};

export const queryListUsers = (mode = 'send', data, page, pageSize, search, filters = {}) => {
  if (mode === 'send') {
    return `query {
                allUsers ${search ? `(search: "${search}")` : ''} {
                  totalCount
                  results(page: ${page || 1}, pageSize: ${pageSize || 10}) {
                    uuid
                    firstName
                    lastName
                    roles
                    familiarWith
                    permissions
                    managedProjects {
                      uuid
                      name
                    }
                    projects {
                      uuid
                      name
                    }
                  }
                }
            }`;
  }
  return {
    ...data.allUsers,
    results: data.allUsers.results.map(i => ({
      ...i,
      projects: Array.from(new Set(i.managedProjects.concat(i.projects))),
    })),
  };
};

export const queryUsersByUuids = (mode = 'send', data, uuids) => {
  if (mode === 'send') {
    return `query {
                allUsers (pk_In: ${JSON.stringify(uuids)}) {
                  totalCount
                  results{
                    uuid
                    firstName
                    lastName
                  }
                }
            }`;
  }
  return data.allUsers.results;
};

export const queryExperimentsByUuids = (mode = 'send', data, uuids) => {
  if (mode === 'send') {
    return `query {
                allExperiments (pk_In: "${uuids.join(',')}") {
                  totalCount
                  results{
                    uuid
                    name
                  }
                }
            }`;
  }
  return data.allExperiments.results;
};

export const queryUserById = (mode = 'send', data, uuid) => {
  if (mode === 'send') {
    return `query {
                user (uuid: "${uuid}") {
                    firstName
                    lastName
                }
            }`;
  }
  return { ...data.user };
};

export const queryFiltersProjectName = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  allProjects {
  results {
    name
    }
  }
}`;
  }
  return { projects: data.allProjects?.results.map(i => ({ label: i.name, value: i.name })) };
};

export const queryProcessDetails = (mode = 'send', data, uuid) => {
  if (mode === 'send') {
    const u = `query {
  process(uuid: "${uuid}") {
    ${processDetailsResp}
    }
}`;
    return u;
  }
  let routeData = null;
  let steps = null;
  let variableStepPreviewInitialRoute = null;
  let fixedStep = null;
  const err = data.error;
  if (!err) {
    if (data.process.route) {
      routeData = JSON.parse(data.process.route.data);
      routeData.strategyRouteIndex = { S0R0: routeData.routes[0] };
    }
    if (data.process.stepTo || data.process.stepTo === 0) {
      steps = [data.process.stepFrom, data.process.stepTo];
      variableStepPreviewInitialRoute = data.process.variableStep && steps[data.process.variableStep[0] - 1];
      fixedStep = data.process.variableStep && steps.find(i => i !== variableStepPreviewInitialRoute);
    }
  }
  return !err
    ? {
        process: {
          ...data.process,
          steps,
          route: routeData ? { ...data.process.route, data: routeData } : null,
          fixedStep,
          variableStepPreviewInitialRoute,
        },
        allReactors: data.allReactors,
      }
    : { error: true };
};

export const queryReactorsTypes = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
    allReactors (typeUnique: true) {
    key
    type
    volume
    maxTemperature
    material
  }
}`;
  }
  return { allReactors: data.allReactors };
};

export const queryProcessStructure = (mode = 'send', data, uuid) => {
  if (mode === 'send') {
    return `query {
    process(uuid: "${uuid}") {
        definition
        type
        }
    allReactors (typeUnique: true) {
    key
    type
    volume
    maxTemperature
    material
  }
    }`;
  }
  return {
    process: JSON.parse(data.process.definition),
    type: data.process.type,
    allReactors: data.allReactors,
  };
};

export const queryGetTypesProcesses = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  processTypes
  }`;
  }
  return data.processTypes.map(i => ({ value: i, label: i }));
};

export const queryGetPumpTypes = (mode = 'send', data, search = '') => {
  if (mode === 'send') {
    return `query {
  allPumps(search: "${search}", typeUnique: true) {
    results(page: 1, pageSize: 100) {
      type
      material
    }
  }
            }`;
  }
  const obj = {};
  Object.keys(data).forEach(i => {
    obj[i] = data[i].results.map(k => ({ label: k.type, value: k.type, material: k.material }));
  });
  return obj;
};

export const queryGetPumpKeys = (search = '', system, maxFlowRate) => `query {
              allPumps(search: "${search}", system: "${system}", maxFlow_Gte: ${maxFlowRate}) {
                results(page: 1, pageSize: 100) {
                    uuid
                    key
                    type
                    material
                    maxFlow
                    system{
                      uuid
                      type
                    }
                }
              }
            }`;

export const queryListMaintenence = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  allMaintainaces(${objectToGraphqlString(data)}) {
     uuid
      description
      timeSlot {
        uuid
        device {
          uuid
        }
        startAt
        endAt
      }
      
    }
  }`;
  }

  return data.allMaintainaces
    ?.map(i => ({
      start: i.timeSlot.startAt,
      end: i.timeSlot.endAt,
      allDay: isALlDay(i.timeSlot.startAt, i.timeSlot.endAt),
      description: i.description,
      duration: moment(i.timeSlot.endAt).diff(i.timeSlot.startAt, 'seconds'),
      uuid: i.uuid,
    }))
    .reverse();
};

export const queryGetExperiments = (mode = 'send', data, pagination) => {
  if (mode === 'send') {
    return `query {
  allExperiments(${objectToGraphqlString(data)}) {
     results(page: ${pagination?.page || 1}, pageSize: ${pagination?.pageSize || 10000}) {
     compatibleWith{
      name
      type
     }
      uuid
      key
      name
      actualTime
      submittedAt
      updatedAt
      createdAt
      totalTime
      status
      completedAt
      priority
      route {
      data
      uuid
      }
      timeSlot{
      startAt
      device {
        type
      }
      }
      compatibleWith{
      uuid
      name
      type
      }
      createdBy{
      firstName
      lastName
      }
      process{
        name
        type
        device
        numberOfSteps
        variableStep
        project{
        name
        }
      }
      notes {
        uuid
      }
    }
  }
  }`;
  }
  return data.allExperiments.results;
};

export const queryGetBatchExperiments = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query{
      allBatches(${objectToGraphqlString(data)})  {
        uuid
        batchId
        status
        warnings
        hasWarnings
        timeSlot {
        device {
          uuid
        }
        startAt
          experiments {          
            uuid
            key
            name
            actualTime
             route {
      data
      uuid
      }
            submittedAt
            updatedAt
            createdAt
            totalTime
            status
            completedAt
            priority
            compatibleWith {
              uuid
              name
              type
            }
            createdBy {
              firstName
              lastName
            }
            process {
              name
              type
              project {
                name
              }
            }
            notes {
              uuid
            }
          }
        }
      }
    }`;
  }
  return data.allBatches;
};

export const queryGetExperiment = (mode = 'send', data, notScheduled = true) => {
  if (mode === 'send') {
    return `
  experiment(key: "${data}") {
      uuid
      key
      name
      enableVialCopying
      createdAt
      updatedAt
      reactionsInfo
      analyticalType
      analyticalSetting
      solventsUsed
      completedAt
      actualTime
      route {
      uuid
      data
    }
      timeSlot{
      createdAt
      uuid
      startAt
      batch {
        uuid
        batchId
        status
        experiments {
          uuid
          key
        }
      }
      endAt
      device {
      uuid
      name
      type
      }
      }
      targetProducts
      submittedAt
      totalTime
      status
      priority
      purification
      outputVolume
      reactionsInfo
      notes{
      uuid,
      body
      }
      compatibleWith{
      uuid
      name
      type
      }
      createdBy{
      firstName
      lastName
      }
      process{
        ${processDetailsResp}
      }
      execution {
      uuid
      abortedAt 
      finalizedAt 
      executionSteps{
          uuid
          step
          status
          logLink
          calibrationTemperatures
        }
      ilStructure
      analysisRootFolderPath
      startedAt
      regressionAnalysis { 
        data
      }
       sjAnalysisData{
          data
          vialId
       }
      createdBy {
          uuid
          email
        }
      }
      step
      ${
        notScheduled
          ? `pumpKeys
        defaultPumpKeys`
          : ''
      }
  }`;
  }
  return {
    ...data.experiment,
    analyticalSetting:
      data.experiment?.analyticalSetting &&
      JSON.parse(data.experiment.analyticalSetting).map(i => ({
        mass: i.exact_mass,
        compound: i.compound,
        mol: i.modification_mass,
        label: i.label,
        observ: i.observed_mass,
        type: i.chemical_type,
        interval_end: (+i.interval_end / 60).toFixed(2),
        interval_start: (+i.interval_start / 60).toFixed(2),
      })),
  };
};

export const queryExperimentInfo = data => {
  const experimentTheoreticalRates = data.isSynJet
    ? ''
    : `experimentTheoreticalRates(
    process: "${data.processID}",
    reactions: ${data.reactions}
  ) {
    index
    value
  }`;
  return `query {
  ${experimentTheoreticalRates}
  experimentAnalyticalTypes(process: "${data.processID}"){
    value
    available
  }
  experimentPriorities {
    key
    label
  }
}`;
};

export const queryGetNotes = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  allNotes(experiment: "${data.experimentId}") {
    totalCount
    results(page: 1, pageSize: 100) {
      uuid
      body
      reason
      isDeleted
      createdAt
      createdBy {
        uuid
        firstName
        lastName
      }
      isDeleted
      status
      experimentTimestamp
      updatedAt
    }
  }
}`;
  }
  return data.allNotes.results.map(i => ({ ...i, body: decodeURIComponentSafe(i.body) }));
};

export const queryListEventsScheduling = (mode = 'send', data) => {
  if (mode === 'send') {
    const filtersData = objectWithArrayToString(data);
    return `query {
  allTimeSlots(${filtersData}){
  
  uuid
  startAt
  endAt
  maintenance{
    uuid
    description
  }
  experiments{
  uuid
  key
  notes{
    uuid
  }
      name
      submittedAt
      totalTime
      status
      priority
      compatibleWith{
      name
      type
     }
      createdBy{
      firstName
      lastName
      }
      process{
        name
        numberOfSteps
        type
        project{
        name
        }
      }
    }
  
  batch{
    uuid
    batchId
    batchId
    status
    warnings
    hasWarnings
  }
  
}
}`;
  }
  const dataProceed = data.allTimeSlots.map(i => {
    const duration = moment(i.endAt).diff(i.startAt, 'minutes');
    const experiment = i.experiments[0];
    let dataObj = { start: i.startAt, end: i.endAt };
    if (i.maintenance) {
      dataObj = {
        ...i.maintenance,
        type: 'maintenance',
        allDay: isALlDay(i.startAt, i.endAt),
      };
    } else if (i.batch) {
      dataObj = {
        ...dataObj,
        ...i.batch,
        experiments: i.experiments,
      };
    } else {
      dataObj = {
        ...experiment,
        ...dataObj,
      };
    }
    return { start: i.startAt, end: i.endAt, extendedProps: { data: { ...dataObj, duration } } };
  });

  return dataProceed;

  // delete
  // return [
  //   {
  //     end: '2020-11-13T14:00:00+00:00',
  //     extendedProps: {
  //       data: {
  //         start: '2020-11-13T07:00:00+00:00',
  //         end: '2020-11-13T14:00:00+00:00',
  //         duration: 240,
  //       },
  //     },
  //     start: '2020-11-13T07:00:00+00:00',
  //   },
  // ];
};

export const queryGetDevices = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
    devices${data?.forExecution ? '(forExecution: true)' : ''}{
      uuid
      name
      type
      online
    }
  }`;
  }

  return data.devices.map(i => ({ label: i.name, value: i.uuid, ...i }));
};

export const queryCheckList = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
    experimentChecklist
  }`;
  }

  return data.experimentChecklist;
};

export const queryExperimentResultPurifications = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      experimentResultPurifications
    }`;
  }

  return data.experimentResultPurifications;
};

export const queryAllBatchesForAssignExpt = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      allBatches(${objectToGraphqlString(data)}){
      uuid
      batchId
      status
      timeSlot {
        startAt
      }
      experiments {
        uuid
        key
      }
    }
    }`;
  }

  return data.allBatches;
};

export const queryExperimentResult = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  experimentResult(experiment: "${data.experimentId}") {
    uuid
    createdAt
    updatedAt
    workup
    purificationType
    purificationDescription
    productsInfo
    notes
    createdBy {
      uuid
      firstName
    }
    attachments {
      uuid
      file
      type
    }
  }
}`;
  }
  return data.experimentResult;
};

export const queryBatchDetails = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      batch(${objectToGraphqlString(data)}) {
        uuid
        metaData
        batchId
        status
        warnings
        hasWarnings
        dispenserStructure
        step
        configuration
        univariateData{
          datetime
          data
        }
        timeSlot {
          startAt
          endAt
          device {
            uuid
          }
        }
         experiments {
          uuid
          key
          name
            route {
      data
      uuid
      }
      enableVialCopying
          totalTime
          vialsCount
          dispensersCount
        completedAt
          analyticalType
          analyticalSetting
          reactionsInfo
          priority
          purification
          status
          actualTime
                execution{
        startedAt
      estimatedTime
        uuid
        executionSteps{
          uuid
          step
          status
          logLink
          calibrationTemperatures
          vialsOffset
        }
        sjAnalysisData{
          data
          vialId
        }
      }
          timeSlot {
            device {
              uuid
              name
            }
          }
         notes {
            uuid
          }
          process {
            name
            type
            device
            numberOfSteps
            variableStep
            enableQuenching
          }
        }
      }
    }`;
  }
  const { batch } = data;
  if (batch?.configuration && batch?.experiments) {
    const configurationDelays = JSON.parse(batch.configuration || '{}')?.delays?.sort((a, b) => a.delay - b.delay);
    const configurationDelaysUniq = uniqArrayExperiments(configurationDelays);
    const expeimentsOrder = configurationDelaysUniq
      .map(i => batch.experiments.find(j => j.uuid === i.experiment_uuid))
      .filter(i => i);
    batch.experiments = expeimentsOrder;
  }
  return batch;
};

export const queryAvailableDispenserMaterials = data => `query {
    availableDispenserMaterials(${objectToGraphqlString(data)})
    }`;

export const queryAvailableReservoirVolumes = () => `query {
    availableReservoirVolumes {
      label
      value
    }
    }`;

export const queryAvailableFluidBodies = data => `query {
  fluidBodies(${objectToGraphqlString(data)}){
            uuid
            material
            key
          }
  }`;

export const queryAvailableBackPacks = data => `query {
  backpacks(${objectToGraphqlString(data)}){
            uuid
            material
            key
          }
  }`;

export const queryAvailableFluidBody = data => `query {
  backpacks(${objectToGraphqlString(data)}){
            uuid
            material
            key
          }
  }`;

export const queryUnivariateConfig = (mode, data) => {
  if (mode === 'send') {
    return `query {
  experimentCharts(experimentUuid: "${data}")
  }`;
  }

  return JSON.parse(data.experimentCharts);
};

export const queryUnivariateData = (mode, data) => {
  if (mode === 'send') {
    return `query {
  univariateData(experimentUuid: "${data.uuid}", sensorIds: "${data.sensorIds}"){
  results
  }
  }`;
  }
  if (data?.univariateData?.results) return JSON.parse(data.univariateData.results);
  return data;
};

export const queryAnalitycsData = (mode, data) => {
  if (mode === 'send') {
    return `query {
  analysisData(experimentUuid: "${data.uuid}")
  }`;
  }

  return data.analysisData.map(i => JSON.parse(i));
};

export const queryProcessDeviceStatus = (deviceId, deviceName) => `query {
    allReactors(${objectToGraphqlString({ system: deviceId })}) {
      online
      key
    }
    allPumps(${objectToGraphqlString({ system: deviceId })}) {
      results(page: 1, pageSize: 1000){
        online
        uuid
        key
      }
     }
     device(uuid: "${deviceId}"){
      uuid
      online
     }
     localRemoteConnection(systemName:"${deviceName}")
  }`;

export const queryLogs = (mode, data) => {
  if (mode === 'send') {
    return `query {
      ilExecutionProgress(experiment:"${data}"){
        executedAt
        step
      }
    }`;
  }
  return data.ilExecutionProgress;
};

/* export const queryBatchUnivariateData = (mode, data) => {
  if (mode === 'send')
    return `query{
    batchUnivariateData(batchUuid: "${data.uuid}"){
      data
    }
  }`;
  return data.batchUnivariateData;
}; */

export const queryListCheckboxesThirdStepWizard = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
  prepareMaterialsChecklist
}`;
  }
  return JSON.parse(data.prepareMaterialsChecklist).map(i => ({
    ...i,
    is_checked: false,
    sub_items: i.sub_items.map(j => ({ ...j, is_checked: false, sub_items: [] })),
  }));
};

export const queryListCheckboxesLoadMaterials = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      prepareMaterialsChecklist
    }`;
  }
  return JSON.parse(data.prepareMaterialsChecklist).map(i => ({
    ...i,
    is_checked: false,
    sub_items: i.sub_items.map(j => ({ ...j, is_checked: false, sub_items: [] })),
  }));
};

export const allReactorsQuery = () => `
    allReactors (typeUnique: true) {
    key
    type
    volume
    maxTemperature
    material
  }
`;

export const batchExperiments = uuid => `
  batch(uuid: "${uuid}") {
    experiments {
      uuid
      name
      key
      totalTime
      completedAt
      analyticalType
      analyticalSetting
      reactionsInfo
      priority
      purification
      status
      actualTime
      execution{
        startedAt
        estimatedTime
        uuid
        executionSteps{
          uuid
          step
          status
          logLink
          calibrationTemperatures
          vialsOffset
        }
        sjAnalysisData{
          data
          vialId
        }
      }
      timeSlot {
        device {
          uuid
          name
        }
      }
      notes {
        uuid
      }
      process {
        name
        type
        device
        numberOfSteps
        variableStep
        enableQuenching
      }
    }
  }
`;

export const queryBatchDetailsMetaData = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      batch(${objectToGraphqlString(data)}) {
        metaData
      }
    }`;
  }

  return data.batch;
};

export const queryExperimentField = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      extensibleTableField(uuid: "${data}") {
          uuid
          name
          dataType
          archived
          unique
          editable
          systemic
          hint
          defaultValue
          availableOptions
          visibleInDetails
          addUpdatesToHistory      
      }
    }`;
  }
  return data.extensibleTableField;
};

export const queryGetExtensibleTableFields = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      extensibleTableFields(orderBy: "${data.activeSort || ''}", search:"${data.search || ''}") 
      {
        results(page: ${data.page || 1}, pageSize: ${data.pageSize || 30}){
          uuid
          createdAt
          updatedAt
          name
          dataType
          archived
          unique
          editable
          systemic
          defaultValue
          defaultValueLabel
          visibleInDetails
          fieldName
          addUpdatesToHistory
          availableOptions
        }
        totalCount
      }
    }`;
  }

  return {
    ...data.extensibleTableFields,
    results: data.extensibleTableFields.results,
  };
};

export const queryGetExtensibleExperiments = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
      extensibleExperiments(orderBy: "${data.activeSort}", dynamicFilters: ${JSON.stringify(
      JSON.stringify(data.filters || [])
    )}, search: "${data.search ? encodeURIComponent(data.search) : ''}") {
        results(page: ${data.page}, pageSize: ${data.pageSize})
        totalCount
      }
    }`;
  }
  return {
    ...data.extensibleExperiments,
    results: data.extensibleExperiments.results.map(i => {
      const dataItem = JSON.parse(i);
      dataItem.uuid = dataItem.pk;
      delete dataItem.pk;
      return dataItem;
    }),
  };
};

export const queryGetExtensibleTableFieldsFull = (mode = 'send', data) => {
  if (mode === 'send') {
    // page: 0, pageSize: 0 - means that we request all customization fields
    return `query {
      extensibleTableFields{
        results(page: 0, pageSize: 0){
          name
          archived
          fieldName
          dataType
        }
      }
    }`;
  }

  return data.extensibleTableFields.results.filter(i => !i.archived);
};

export const queryGetExtensibleTableFieldsGrid = (
  mode = 'send',
  { hidden, page = 0, pageSize = 0, extensibleTableFieldsGrid }
) => {
  if (mode === 'send') {
    return `query {
    extensibleTableFieldsGrid${typeof hidden === 'boolean' ? `(hidden: ${hidden})` : ''} {
      totalCount
      results(page: ${page}, pageSize: ${pageSize}){
        uuid
        width
        name
        fieldName
        dataType
        hidden
        visibleInDetails
        editable
        archived
        availableOptions
      }
    }
  }`;
  }
  return extensibleTableFieldsGrid.results.map(i => {
    try {
      return {
        ...i,
        availableOptions: JSON.parse(i.availableOptions),
      };
    } catch (e) {
      return i;
    }
  });
};

export const queryGetExtensibleExperiment = (mode = 'send', data) => {
  if (mode === 'send') {
    return `query {
    extensibleExperiment(uuid: "${data.uuid}")}`;
  }
  try {
    const extensibleExperiment = JSON.parse(data.extensibleExperiment);
    extensibleExperiment.uuid = extensibleExperiment.pk;
    delete extensibleExperiment.pk;
    return extensibleExperiment;
  } catch (e) {
    console.error(e);
  }
};
