import './DndDropEdgeAreas.styles.scss';

import React from 'react';

import { useDndContext, useSelectableContext } from '@molecules';

import {
  DND_DROP_AREA_EDGE_WIDTH,
  DND_DROP_AREA_END_EDGE,
  DND_DROP_AREA_START_EDGE,
  HEADER_HEIGHT,
  SELECTABLE_HEADER_WIDTH,
} from '../constants';

export const DndDropEdgeAreas = ({ updateOrder, children, totalWidth }) => {
  const { isSelectableContext } = useSelectableContext();
  const {
    setDragColumnIndex,
    setPotentialDropColumnIndex,
    dragColumnIndex,
    potentialDropColumnIndex,
    isDndContext,
  } = useDndContext();

  const onDragEnterStartEdge = () => {
    if (potentialDropColumnIndex !== DND_DROP_AREA_START_EDGE) {
      setPotentialDropColumnIndex(DND_DROP_AREA_START_EDGE);
    }
  };

  const onDragEnterEndEdge = () => {
    if (potentialDropColumnIndex !== DND_DROP_AREA_END_EDGE) {
      setPotentialDropColumnIndex(DND_DROP_AREA_END_EDGE);
    }
  };

  const onDragEnd = () => {
    setDragColumnIndex(null);
    setPotentialDropColumnIndex(null);
  };

  const onDropStartEdge = () => {
    if (dragColumnIndex && potentialDropColumnIndex) {
      updateOrder({
        dataIndex: dragColumnIndex,
        insertStart: true,
      });
      onDragEnd();
    }
  };

  const onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDropEndEdge = () => {
    if (dragColumnIndex && potentialDropColumnIndex) {
      updateOrder({
        dataIndex: dragColumnIndex,
        insertEnd: true,
      });
      onDragEnd();
    }
  };

  if (!isDndContext) {
    return children;
  }
  return (
    <>
      <div
        onDragOver={onDragOver}
        onDrop={onDropStartEdge}
        onDragEnter={onDragEnterStartEdge}
        onDragEnd={onDragEnd}
        className="dnd-drop-edge-start-area"
        style={{
          width: DND_DROP_AREA_EDGE_WIDTH,
          left: isSelectableContext ? SELECTABLE_HEADER_WIDTH - 10 : 0,
          height: HEADER_HEIGHT,
        }}
      />
      {children}
      <div
        onDragOver={onDragOver}
        onDrop={onDropEndEdge}
        onDragEnter={onDragEnterEndEdge}
        onDragEnd={onDragEnd}
        className="dnd-drop-edge-end-area"
        style={{
          width: DND_DROP_AREA_EDGE_WIDTH,
          left: totalWidth - DND_DROP_AREA_EDGE_WIDTH,
          height: HEADER_HEIGHT,
        }}
      />
    </>
  );
};
