import React from 'react';
import cn from 'classnames';

export const Microwave = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#20A89A" />
      <path
        d="M37.1875 11.8125H10.8125C9.26169 11.8125 8 13.0742 8 14.625V31.375C8 32.9258 9.26169 34.1875 10.8125 34.1875H12.0625V35.25C12.0625 35.7677 12.4823 36.1875 13 36.1875C13.5177 36.1875 13.9375 35.7677 13.9375 35.25V34.1875H34.0625V35.25C34.0625 35.7677 34.4823 36.1875 35 36.1875C35.5177 36.1875 35.9375 35.7677 35.9375 35.25V34.1875H37.1875C38.7383 34.1875 40 32.9258 40 31.375V14.625C40 13.0742 38.7383 11.8125 37.1875 11.8125ZM30.0625 32.3125H10.8125C10.2956 32.3125 9.875 31.8919 9.875 31.375V14.625C9.875 14.1081 10.2956 13.6875 10.8125 13.6875H30.0625V32.3125ZM38.125 31.375C38.125 31.8919 37.7044 32.3125 37.1875 32.3125H31.9375V18.1875H38.125V31.375ZM38.125 16.3125H31.9375V13.6875H37.1875C37.7044 13.6875 38.125 14.1081 38.125 14.625V16.3125Z"
        fill="white"
      />
      <path
        d="M34.0807 29.4331C34.1861 29.9411 34.6645 30.2666 35.1832 30.1694C35.6925 30.0632 36.0161 29.5832 35.9194 29.0669C35.8015 28.5043 35.2416 28.1969 34.7281 28.3531C34.246 28.507 33.9933 28.9666 34.0807 29.4331Z"
        fill="white"
      />
      <path
        d="M34.0807 25.4331C34.1861 25.9411 34.6645 26.2666 35.1832 26.1694C35.6925 26.0633 36.0161 25.5832 35.9194 25.0669C35.8015 24.5042 35.2415 24.197 34.7281 24.3531C34.246 24.507 33.9933 24.9666 34.0807 25.4331Z"
        fill="white"
      />
      <path
        d="M34.0807 21.4331C34.1861 21.9411 34.6645 22.2666 35.1832 22.1694C35.6925 22.0632 36.0161 21.5832 35.9194 21.0669C35.8015 20.5042 35.2415 20.197 34.7281 20.3531C34.246 20.507 33.9933 20.9666 34.0807 21.4331Z"
        fill="white"
      />
      <path
        d="M14.875 30.1875H25.125C26.6758 30.1875 27.9375 28.9258 27.9375 27.375V18.625C27.9375 17.0742 26.6758 15.8125 25.125 15.8125H14.875C13.3242 15.8125 12.0625 17.0742 12.0625 18.625V27.375C12.0625 28.9258 13.3242 30.1875 14.875 30.1875ZM13.9375 18.625C13.9375 18.1081 14.3581 17.6875 14.875 17.6875H25.125C25.6419 17.6875 26.0625 18.1081 26.0625 18.625V27.375C26.0625 27.8919 25.6419 28.3125 25.125 28.3125H14.875C14.3581 28.3125 13.9375 27.8919 13.9375 27.375V18.625Z"
        fill="white"
      />
    </svg>
  </div>
);
