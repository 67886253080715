import React from 'react';

import { DEFAULT_COLUMN_WIDTH, SCROLLBAR_WIDTH } from '@molecules/VirtualTable/constants';

export const addAcceptedDropColumns = ({ column, columns }) =>
  columns.map(i => i.dataIndex).filter((i, idx, arr) => !(column.dataIndex === i || column.dataIndex === arr[idx - 1]));

export const getHeaderStyles = ({ width, height, left }) => {
  const styles = {
    display: 'inline-flex',
    height,
    width,
    position: 'sticky',
    top: 0,
    zIndex: 3,
  };
  if (left) {
    styles.marginLeft = left;
  }
  return styles;
};

export const calculateColumnWidth = ({ column, columns, tableSize }) => {
  const { width } = tableSize;
  if (column.width) {
    return column.width;
  }
  const fixedColumnsWidth = columns.reduce((prev, cur) => prev + (cur.width || 0), 0);
  const flexibleColumnsLength = columns.filter(i => !i.width).length;
  const spareWidth = width - fixedColumnsWidth - SCROLLBAR_WIDTH;
  const columnWidth = spareWidth / flexibleColumnsLength;
  return columnWidth < DEFAULT_COLUMN_WIDTH ? DEFAULT_COLUMN_WIDTH : columnWidth;
};

export const getMinMaxColumnsData = children => {
  let minColumn = Infinity;
  let maxColumn = -Infinity;
  let leftOffset = 0;
  React.Children.forEach(children, child => {
    const { columnIndex } = child.props;
    minColumn = Math.min(minColumn, columnIndex);
    if (minColumn === columnIndex) {
      leftOffset = child.props.style.left;
    }
    maxColumn = Math.max(maxColumn, columnIndex);
  });

  if (minColumn === Infinity || maxColumn === -Infinity) {
    maxColumn = 0;
    minColumn = 0;
  }

  return {
    childrenWidths: React.Children.map(children, child => child.props.style.width),
    minIndex: minColumn,
    maxIndex: maxColumn,
    leftOffset,
  };
};
