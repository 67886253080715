import React from 'react';
import './style.scss';
import { CompoundSmilesPreview } from '../../../index';

const SynJetProductInfoView = ({ compoundInfo }) => (
  <>
    <div className="synjet-compound__info__smiles">
      {compoundInfo?.smiles ? (
        <CompoundSmilesPreview compound={compoundInfo?.smiles} hideMW showTooltip />
      ) : (
        <span className="synjet-compound__text">N/A</span>
      )}
    </div>
    <div className="synjet-compound__info__solvent">
      <span className="synjet-compound__text">{compoundInfo?.smiles?.molwt || 'N/A'}</span>
    </div>
  </>
);
export default SynJetProductInfoView;
