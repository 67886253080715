import React from 'react';
import './style.scss';

const BalloonPopover = ({ name, formula, volume, material, backpack, fluidBody, calibration }) => (
  <div className="balloon-popover">
    <div>
      <div className="balloon-popover_name">{name}</div>
    </div>
    <div className="balloon-popover_formula">{formula}</div>
    <div>
      <div className="balloon-popover_definition">Dispenser volume</div>
      <div className="balloon-popover_value">{volume} ml</div>
    </div>
    <div>
      <div className="balloon-popover_definition">Dispenser material</div>
      <div className="balloon-popover_value">{material}</div>
    </div>
    <div>
      <div className="balloon-popover_definition">Backpack</div>
      <div className="balloon-popover_value">{backpack}</div>
    </div>
    <div>
      <div className="balloon-popover_definition">Fluid body</div>
      <div className="balloon-popover_value">{fluidBody}</div>
    </div>
    <div>
      <div className="balloon-popover_definition">Calibration</div>
      <div className="balloon-popover_value">{calibration} µL/ms</div>
    </div>
  </div>
);

export default BalloonPopover;
