import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { updateExtensibleTableGrid } from 'store/experiment/experiment.actions';
import { fetchData } from 'store/slices/extensibleTableFieldsGrid/actions';
import { getData, getIsLoading } from 'store/slices/extensibleTableFieldsGrid/getters';

import { mapExperimentTableFieldsGridToColumns } from '../utils';

const useColumnsManager = () => {
  const [columnsData, setColumnsData] = useState([]);

  const tableFieldsGrid = useSelector(getData);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tableFieldsGrid) {
      setColumnsData(mapExperimentTableFieldsGridToColumns(tableFieldsGrid));
    }
  }, [tableFieldsGrid]);

  useEffect(() => {
    dispatch(fetchData());
  }, []);

  const updateExperimentFieldsGrid = data => {
    updateExtensibleTableGrid(
      data.map(({ uuid, width, hidden }) => ({
        uuid,
        width,
        hidden,
      }))
    );
  };

  const updateOrder = ({ dataIndex, dropAreaDataIndex, insertStart, insertEnd }) => {
    let newColumnData = [...columnsData];
    const dataIndexPosition = newColumnData.findIndex(i => i.dataIndex === dataIndex);
    const dropAreaDataIndexPosition = newColumnData.findIndex(i => i.dataIndex === dropAreaDataIndex);
    const dataIndexData = newColumnData[dataIndexPosition];
    if (insertStart) {
      newColumnData.splice(dataIndexPosition, 1);
      newColumnData.unshift(dataIndexData);
    } else if (insertEnd) {
      newColumnData.splice(dataIndexPosition, 1);
      newColumnData.push(dataIndexData);
    } else {
      newColumnData.splice(dropAreaDataIndexPosition + 1, 0, dataIndexData);
      if (dataIndexPosition > dropAreaDataIndexPosition) {
        newColumnData.splice(dataIndexPosition + 1, 1);
      } else {
        newColumnData.splice(dataIndexPosition, 1);
      }
    }
    newColumnData = newColumnData.map((column, columnIndex) => ({ ...column, actualIndex: columnIndex }));
    setColumnsData(newColumnData);

    updateExperimentFieldsGrid(newColumnData);
  };

  const updateColumnWidth = ({ columnIndex, width }) => {
    const newColumnData = columnsData.map((i, idx) => (idx === columnIndex ? { ...i, width } : i));
    setColumnsData(newColumnData);
    updateExperimentFieldsGrid(newColumnData);
  };

  const columns = useMemo(() => columnsData.filter(i => !i.hidden && !i.archived), [columnsData]);

  return {
    updateColumnWidth,
    columns,
    updateOrder,
    isLoading,
  };
};

export default useColumnsManager;
