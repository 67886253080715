import { httpSynMolDB } from '../http';

const getDataCompoundSmile = ({ smile }) =>
  httpSynMolDB(`get_compound?smiles=${encodeURIComponent(smile)}&return_compatibility=true`);

const registrCompound = ({ smile }) =>
  httpSynMolDB(`register_compound?smiles=${encodeURIComponent(smile)}&return_compatibility=true`);

const getDataCompoundName = ({ name }) =>
  httpSynMolDB(`get_compound?compound_name=${encodeURIComponent(name)}&return_compatibility=true`);

export const prepareDataRoute = async ({ route, segment, part }) => {
  let compoundsDataBase = route.cpds;
  const partForTransform = route.routes[0].route_segments[segment - 1].slice(part[0], part[1] + 1);
  const reactorsDetails = partForTransform.map(i => {
    const reactorId = i.rxn_id;
    return route.rxns[reactorId];
  });
  const reactorsDetailsSTRING = JSON.stringify(reactorsDetails);
  const partForTransformSTRING = JSON.stringify(partForTransform);
  const compoundsDataBaseKeys = Object.keys(compoundsDataBase).filter(i => {
    return reactorsDetailsSTRING.indexOf(i) > 0 || partForTransformSTRING.indexOf(i) > 0;
  });
  const compoundsDataBaseKeysPromises = compoundsDataBaseKeys.map(i => {
    const item = compoundsDataBase[i];
    if (item?.smiles) return getDataCompoundSmile({ smile: item.smiles });
    return getDataCompoundName({ name: item.name });
  });
  let resultData = await Promise.all(compoundsDataBaseKeysPromises);
  if (resultData.find(i => i.status === -1)) {
    const compoundsDataBaseRegistrCompound = resultData.map((i, idx) => {
      if (i.status === -1) return registrCompound({ smile: compoundsDataBase[compoundsDataBaseKeys[idx]].smiles });
      return i;
    });
    resultData = await Promise.all(compoundsDataBaseRegistrCompound);
  }
  compoundsDataBase = {};
  compoundsDataBaseKeys.forEach((i, idx) => {
    if (resultData[idx]?.status === 1) compoundsDataBase[i] = resultData[idx]?.compound;
  });
  return { compoundsDataBase, partForTransform, reactorsDetails };
};
