import React, { useEffect, useState } from 'react';
import { Collapse } from '../../Common';
import Canvas from '../../DrawerRoute/Canvas';
import './style.scss';
import { PageContainer } from '../../PdfGenerator/PageContainer';
import { ResultingRouteTitle } from '../../PdfGenerator/ResultingRouteTitle';

const mapExperimentRoutes = experimentRoutes =>
  experimentRoutes.routes.map((route, routeIndex) => ({
    title: `Reaction ${routeIndex + 1}`,
    body: (
      <div className="routes-tab__collapse__content">
        <div className="routes-tab__experiment">
          <Canvas
            indexUniq={0}
            results={experimentRoutes}
            routeId={routeIndex}
            onCanvasClick={() => {}}
            type="routeCanvas"
          />
        </div>
      </div>
    ),
    key: routeIndex,
  }));

export const RoutesTab = props => {
  const { routes, pdfMode } = props;

  const [experimentRoutes, setExperimentRoutes] = useState([]);

  useEffect(() => {
    if (routes) {
      setExperimentRoutes(mapExperimentRoutes(JSON.parse(routes)));
    }
  }, [routes]);
  if (pdfMode) {
    return experimentRoutes.map(i => (
      <PageContainer>
        <ResultingRouteTitle title={i.title} />
        {i.body}
      </PageContainer>
    ));
  }
  return (
    <section className="routes-tab">
      <header>
        <h3 className="routes-tab__title">Resulting route(s)</h3>
      </header>
      <Collapse items={experimentRoutes} />
    </section>
  );
};
