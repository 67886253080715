import moment from 'moment';
import {
  COMPONENT,
  COMPOUND_ROLE,
  OPERATOR,
  SOLVENT_TYPES,
  SYNJET_COMPOUND_TYPES,
  SYNJET_EXPERIMENT_TABLE_SCHEMA,
  SYNJET_PRO_PROCESS_TYPES,
  SYNJET_PROCESS_STEPS,
  SYNJET_PROCESS_TYPES,
} from '../constants';

export const getCompoundVolume = compound => (compound.solventType === SOLVENT_TYPES.SOLID ? 0 : compound.volume || 0);

export const getSynJetCompoundLetter = (compoundIndex, compoundOffset) => {
  const offset =
    compoundIndex + compoundOffset > 25 ? compoundIndex + compoundOffset - 26 : compoundIndex + compoundOffset;
  const letter = String.fromCharCode(65 + offset);
  return compoundIndex + compoundOffset > 25 ? `A${letter}` : letter;
};

export const getPrevCompoundGroupCount = (group, sliceIndex) =>
  group.slice(0, sliceIndex).reduce((sum, currentGroup) => sum + currentGroup.compoundsList.length, 0);

export const getPrevStepCount = (step, isOptimization) => {
  if (!step) return 0;
  return isOptimization || step.isFixed
    ? step.compounds.reactants.length + step.compounds.reagents.length
    : step.compounds.reagentGroups.reduce((sum, currentGroup) => sum + currentGroup.compoundsList.length, 0) +
        step.compounds.reactantGroups.reduce((sum, currentGroup) => sum + currentGroup.compoundsList.length, 0);
};

export const recalculateCompoundsName = (
  compoundsList,
  limitingCompoundGroup,
  compoundGroupOffSet = 0,
  isGroup,
  isFixed
) =>
  compoundsList.map((compound, index) => ({
    ...compound,
    name: getSynJetCompoundLetter(index, compoundGroupOffSet),
    volumes: limitingCompoundGroup ? compound.volumes : isGroup || isFixed ? [null] : [null, null, null],
  }));

export const recalculateGroupCompoundsName = (
  compoundGroup,
  limitingCompoundGroup,
  compoundGroupOffSet = 0,
  type,
  isGroup
) =>
  compoundGroup.map((group, groupIndex) => {
    const prevOffset = getPrevCompoundGroupCount(compoundGroup, groupIndex);
    return {
      ...group,
      name: `${type} ${groupIndex + 1}`,
      compoundsList: recalculateCompoundsName(
        group.compoundsList,
        limitingCompoundGroup,
        prevOffset + compoundGroupOffSet,
        isGroup
      ),
    };
  });

export const recalculateQuenchingName = quenchingList =>
  quenchingList.map((quenching, quenchingIndex) => ({
    ...quenching,
    name: `Q${quenchingIndex + 1}`,
  }));

const calculateVolumes = (index, limitingCompound, concentration, equivalents, compound, limitedCompoundMole) => {
  if (!limitingCompound) return null;
  const isSolid = compound.solventType === SOLVENT_TYPES.SOLID;
  const equivalent = equivalents?.[index] || compound.equivalents[index];
  let resultedVolume = 0;
  if (isSolid) {
    // Number of Moles of the Limiting reagent * number of equivalents of reactant/reagent *
    // MW of reactant/reagen (g/M) / 1000
    const molarWeight = compound?.smiles?.molwt || 0;
    resultedVolume = (limitedCompoundMole * equivalent * molarWeight) / 1000;
  } else {
    // Number of Equivalents * Number of Moles of the Limiting reagent (uMole) /
    // Concentration (M/L) = Volume (uL)
    if (!concentration) return null;
    resultedVolume = (equivalent * limitedCompoundMole) / concentration;
  }
  return +Number(resultedVolume).toFixed(4);
};

const getLimitedCompoundMole = limitingCompound => {
  if (!limitingCompound) return 0;
  const isSolid = limitingCompound.solventType === SOLVENT_TYPES.SOLID;
  if (isSolid) {
    //  X (uMole) = Mass of limiting reagent (mg) / MW of limiting reagent (g/M) / 1000
    const mass = limitingCompound.volumes?.[0] || limitingCompound.volume;
    const molarWeight = limitingCompound.smiles.molwt;
    return (mass / molarWeight) * 1000;
  }
  // X (uMole) = Concentration (M/L) * Volume (uL)
  const volume = limitingCompound.volumes?.[0] || limitingCompound.volume;
  return limitingCompound.concentration * volume;
};

export const calculateVolumesOnLimited = (limitingCompound, concentration, compound, equivalents = []) => {
  const limitedCompoundMole = getLimitedCompoundMole(limitingCompound);
  if (compound.isLimiting) return compound.volumes;
  return compound.volumes.map((_, index) =>
    calculateVolumes(index, limitingCompound, concentration, equivalents, compound, limitedCompoundMole)
  );
};

export const calculateVolumesOnLimitedGroup = (limitingCompoundGroup, concentration, compound, equivalents) => {
  if (limitingCompoundGroup) {
    if (limitingCompoundGroup.compoundsList) {
      return limitingCompoundGroup.compoundsList.map((limitingCompound, index) => {
        const limitedCompoundMole = getLimitedCompoundMole(limitingCompound);
        return calculateVolumes(index, limitingCompound, concentration, equivalents, compound, limitedCompoundMole);
      });
    }
    return calculateVolumesOnLimited(limitingCompoundGroup, concentration, compound, equivalents);
  }
  return [null];
};

export const calculateCompoundListOnLimited = (limitingCompound, compoundList) =>
  compoundList.map(compound => ({
    ...compound,
    volumes: calculateVolumesOnLimited(limitingCompound, compound.concentration, compound),
    equivalents: limitingCompound ? compound.equivalents : compound.equivalents.map(() => null),
  }));

export const calculateCompoundGroupListOnLimited = (limitingCompoundGroup, compoundGroups, disableLimiting) =>
  compoundGroups.map(compoundGroup => ({
    ...compoundGroup,
    compoundsList:
      limitingCompoundGroup?.name === compoundGroup.name
        ? compoundGroup.compoundsList
        : compoundGroup.compoundsList.map((compound, compoundIndex) => ({
            ...compound,
            equivalents: compound.isNullCompound
              ? undefined
              : limitingCompoundGroup
              ? disableLimiting
                ? compound.equivalents
                : limitingCompoundGroup.compoundsList.map(
                    (limitedCompound, index) => compound.equivalents[index] || null
                  )
              : [null],
            volumes: compound.isNullCompound
              ? undefined
              : compoundGroup.isLimiting
              ? compound.volumes
              : disableLimiting
              ? calculateVolumesOnLimited(limitingCompoundGroup, compound.concentration, compound)
              : calculateVolumesOnLimitedGroup(limitingCompoundGroup, compound.concentration, compound),
          })),
  }));

export const calculateQuenchingListOnLimited = (limitingCompoundGroup, quenchingList) =>
  quenchingList.map(quenchingItem => ({
    ...quenchingItem,
    equivalents: limitingCompoundGroup.compoundsList.map(
      (limitedCompound, index) => quenchingItem.equivalents[index] || null
    ),
    volumes: calculateVolumesOnLimitedGroup(limitingCompoundGroup, quenchingItem.concentration, quenchingItem),
  }));

export const synjetCompoundValidation = (
  type,
  compound,
  limitingCompound,
  compoundInfo,
  setValidationErrors,
  limitingCompoundGroup,
  isLimitingCompoundGroup,
  isProListItem
) => {
  let isValid = true;
  const newErrors = {};
  const isSolid = compound?.solventType === SOLVENT_TYPES.SOLID;
  if (type === SYNJET_COMPOUND_TYPES.PRODUCT) {
    if (isProListItem) {
      if (compound?.includeProduct) {
        if (!compound?.product) {
          newErrors.name = 'Name is a required field';
          newErrors.product = 'Product is a required field';
          isValid = false;
        }
        if (compound.conditions.some(cond => !cond.time && !cond.temperature)) {
          newErrors.conditions = 'Specify all conditions';
          isValid = false;
        }
      }
    } else if (!compound?.smiles) {
      newErrors.name = 'Name is a required field';
      newErrors.smiles = 'Smile is a required field';
      isValid = false;
    }
  } else {
    if (!compound?.smiles) {
      newErrors.name = 'Name is a required field';
      newErrors.smiles = 'Smile is a required field';
      isValid = false;
    }
    if (!isSolid) {
      if (!compound?.concentration) {
        newErrors.concentration = 'Concentration is a required field';
        isValid = false;
      }
      if (compound?.solvents) {
        const fragmentSum = compound?.solvents.reduce((sum, currentSolvent) => +currentSolvent.fraction + sum, 0);
        if (fragmentSum !== 1.0) {
          newErrors.fraction = new Array(compound?.solvents?.length || 0)
            .fill(null)
            .map(() => 'The sum of solvent Fractions should be equal to 1.0');
          isValid = false;
        }
        newErrors.solvents = compound?.solvents.map(solvent =>
          solvent?.solvent ? null : 'Solvent is a required field'
        );
        isValid = isValid && newErrors.solvents.every(solvent => !solvent);
      } else {
        newErrors.solvent = 'Solvent is a required field';
        isValid = false;
      }
    }
    if (compound?.isLimiting || isLimitingCompoundGroup) {
      if (!compound?.volumes || !compound?.volumes[0]) {
        newErrors.volumes = ['Volume is a required field'];
        isValid = false;
      }
    } else if ((limitingCompound && !!compoundInfo.isLimiting === !!compound.isLimiting) || limitingCompoundGroup) {
      newErrors.equivalents = compound?.equivalents.map(equivalent =>
        equivalent ? null : 'Equivalent is a required field'
      );
      isValid = isValid && newErrors.equivalents.every(equivalent => !equivalent);
    }
  }
  setValidationErrors(newErrors);
  return isValid;
};

export const getFixedVials = (
  step,
  rules,
  dispensingOption,
  variableVialsLength,
  isFirst,
  stepsProcess,
  isLibGen,
  vialProducts
) => {
  const timeData = getConditionOption(0, step.conditions[0].time, true);
  const temperatureData = getConditionOption(0, step.conditions[0].temperature, true);

  const data = {
    time: timeData.value,
    temperature: temperatureData.value,
    time_label: timeData.label,
    temperature_label: temperatureData.label,
  };

  const reactants = step.compounds.reactants.map(reactant => ({
    name: reactant.name,
    solvents: reactant.solvents,
    smiles: reactant.smiles,
    ...getCompoundOption(0, reactant, true),
  }));

  const reagents = step.compounds.reagents.map(reagent => ({
    name: reagent.name,
    solvents: reagent.solvents,
    smiles: reagent.smiles,
    ...getCompoundOption(0, reagent, true),
  }));
  const result = {
    ...data,
    reactants,
    reagents,
  };

  let errorText = null;

  rules &&
    rules.forEach((rule, index) => {
      errorText = errorText || synjetRulesParser(rule, index, result, dispensingOption);
    });

  const vials = [];

  for (let i = 0; i < variableVialsLength; i++) {
    const productData = isLibGen
      ? {
          ...(vialProducts[i] || {}),
        }
      : {};
    vials.push({
      ...result,
      name: i + 1,
      actual_reaction_time: stepsProcess ? stepsProcess[0].step.vials[i]?.actual_reaction_time : null,
      error: errorText,
      is_valid: !errorText,
      ...productData,
    });
  }

  if (isFirst && !isLibGen) {
    return [
      ...vials,
      {
        ...result,
        name: variableVialsLength + 1,
        error: errorText,
        is_valid: !errorText,
      },
      {
        ...result,
        name: variableVialsLength + 2,
        error: errorText,
        is_valid: !errorText,
      },
    ];
  }

  return vials;
};

export const getOptimizationVials = (step, rules, dispensingOption, twoStep, processSteps) => {
  let limitingCompound = step.compounds.reactants.find(group => group.isLimiting);
  if (!limitingCompound) {
    limitingCompound = step.compounds.reagents.find(group => group.isLimiting);
  }

  if ((!limitingCompound || !validateConditionOptimization(step.conditions[0])) && !twoStep) {
    return [];
  }

  const filteredReagents = step.compounds.reagents.filter(item => !item.isLimiting);
  const filteredReactants = step.compounds.reactants.filter(item => !item.isLimiting);
  const numberOfVariables = filteredReagents.length + filteredReactants.length + 2;
  const variations = SYNJET_EXPERIMENT_TABLE_SCHEMA[numberOfVariables];
  if (!variations) {
    return [];
  }

  return variations.temperature.map((temperature, index) => {
    const timeData = getConditionOption(variations.time[index], step.conditions[0].time, step.isFixed);
    const temperatureData = getConditionOption(
      variations.temperature[index],
      step.conditions[0].temperature,
      step.isFixed
    );
    const data = {
      name: index + 1,
      time: timeData.value,
      temperature: temperatureData.value,
      time_label: timeData.label,
      temperature_label: temperatureData.label,
    };

    let compoundsCount = 0;

    const reactants = step.compounds.reactants.map(reactant => {
      if (!reactant.isLimiting) {
        compoundsCount++;
      }
      return {
        name: reactant.name,
        solvents: reactant.solvents,
        smiles: reactant.smiles,
        ...getCompoundOption(variations[`variableCompound${compoundsCount}`]?.[index], reactant, step.isFixed),
      };
    });

    const reagents = step.compounds.reagents.map(reagent => {
      if (!reagent.isLimiting) {
        compoundsCount++;
      }
      return {
        name: reagent.name,
        solvents: reagent.solvents,
        smiles: reagent.smiles,
        ...getCompoundOption(variations[`variableCompound${compoundsCount}`]?.[index], reagent, step.isFixed),
      };
    });
    const result = {
      ...data,
      actual_reaction_time: processSteps
        ? processSteps[processSteps.length === 2 ? 1 : 0].step.vials[index]?.actual_reaction_time
        : null,
      reactants,
      reagents,
    };
    let errorText = null;
    rules &&
      rules.forEach((rule, index) => {
        errorText = errorText || synjetRulesParser(rule, index, result, dispensingOption);
      });
    return {
      ...result,
      error: errorText,
      is_valid: !errorText,
    };
  });
};

const getConditionOption = (variation, condition, isFixed) => {
  if (isFixed) {
    return { value: condition?.fixed, label: 'FIXED' };
  }

  switch (variation) {
    case -1:
      return { value: condition.low, label: 'LOW' };
    case 0:
      return { value: condition.med, label: 'MED' };
    case 1:
      return { value: condition.high, label: 'HIGH' };
    default:
      return {};
  }
};

const getCompoundOption = (variation, compound, isFixed) => {
  if (isFixed || compound.isLimiting) {
    return {
      volume: compound.volumes[0],
      equivalent: compound.equivalents[0],
      label: 'FIXED',
    };
  }

  switch (variation) {
    case -1:
      return {
        volume: compound.volumes[0],
        equivalent: compound.equivalents[0],
        label: 'LOW',
      };
    case 0:
      return {
        volume: compound.volumes[1],
        equivalent: compound.equivalents[1],
        label: 'MED',
      };
    case 1:
      return {
        volume: compound.volumes[2],
        equivalent: compound.equivalents[2],
        label: 'HIGH',
      };
    default:
      return {};
  }
};

const getLimitingGroupData = compounds => {
  let limitingCompoundName = '';
  let limitingGroup = compounds.reactantGroups.find(group => group.isLimiting);
  let limitingGroupIndex = compounds.reactantGroups.findIndex(group => group.isLimiting);
  limitingCompoundName = 'reactants';
  if (!limitingGroup) {
    limitingGroup = compounds.reagentGroups.find(group => group.isLimiting);
    limitingGroupIndex = compounds.reagentGroups.findIndex(group => group.isLimiting);
    limitingCompoundName = 'reagents';
  }

  return { limitingCompoundName, limitingGroup, limitingGroupIndex };
};

const getCompoundGroupVariation = step => {
  const { limitingCompoundName, limitingGroup, limitingGroupIndex } = getLimitingGroupData(step.compounds);

  if (!limitingGroup) {
    return [];
  }
  const { reactantGroups, reagentGroups } = step.compounds;

  const compoundGroupVariations = [];
  limitingGroup.compoundsList.forEach((limitingCompound, limitingCompoundIndex) => {
    let compoundVariations = [];

    compoundVariations = getCompoundGroupVariations(
      compoundVariations,
      reactantGroups,
      'reactants',
      limitingCompoundIndex
    );

    compoundVariations = getCompoundGroupVariations(
      compoundVariations,
      reagentGroups,
      'reagents',
      limitingCompoundIndex
    );

    if (compoundVariations.length) {
      compoundVariations.forEach(variation => {
        const data = { ...variation };
        const limitingCompoundData = {
          volume: limitingCompound.volumes[0],
          equivalent: limitingCompound.equivalents[0],
          solvents: limitingCompound.solvents,
          name: limitingCompound.name,
          smiles: limitingCompound.smiles,
          isNullCompound: limitingCompound.isNullCompound,
          solventType: limitingCompound.solventType,
          isLimiting: true,
          concentration: limitingCompound.concentration,
        };

        if (variation[limitingCompoundName]) {
          data[limitingCompoundName] = [...variation[limitingCompoundName]];
          data[limitingCompoundName][limitingGroupIndex] = limitingCompoundData;
        } else {
          data[limitingCompoundName] = [];
          data[limitingCompoundName][limitingGroupIndex] = limitingCompoundData;
        }

        compoundGroupVariations.push(data);
      });
    } else {
      const compounds = [];
      compounds[limitingGroupIndex] = {
        volume: limitingCompound.volumes[0],
        equivalent: limitingCompound.equivalents[0],
        solvents: limitingCompound.solvents,
        name: limitingCompound.name,
        smiles: limitingCompound.smiles,
        isNullCompound: limitingCompound.isNullCompound,
        solventType: limitingCompound.solventType,
        isLimiting: true,
        concentration: limitingCompound.concentration,
      };
      compoundGroupVariations.push({
        [limitingCompoundName]: compounds,
      });
    }
  });

  return compoundGroupVariations;
};

export const getQuenchingVariation = (step, quenching, reagentNames) => {
  const { limitingGroup } = getLimitingGroupData(step.compounds);

  if (!limitingGroup) {
    return [];
  }

  const variationIndex = limitingGroup.compoundsList.findIndex(limitingCompound =>
    reagentNames.includes(limitingCompound.name)
  );

  return quenching.map(quenchingItem => ({
    name: quenchingItem.name,
    equivalent: quenchingItem.equivalents[variationIndex],
    volume: quenchingItem.volumes[variationIndex],
  }));
};
export const getScreeningVials = (step, rules, dispensingOption, stepsProcess) => {
  let limitingCompoundName = '';
  let limitingGroup = step.compounds.reactantGroups.find(group => group.isLimiting);
  let limitingGroupIndex = step.compounds.reactantGroups.findIndex(group => group.isLimiting);
  limitingCompoundName = 'reactants';
  if (!limitingGroup) {
    limitingGroup = step.compounds.reagentGroups.find(group => group.isLimiting);
    limitingGroupIndex = step.compounds.reagentGroups.findIndex(group => group.isLimiting);
    limitingCompoundName = 'reagents';
  }

  if (!limitingGroup) {
    return [];
  }

  const { reactantGroups, reagentGroups } = step.compounds;
  const compoundGroupVariations = [];
  const resultVariations = [];

  limitingGroup.compoundsList.forEach((limitingCompound, limitingCompoundIndex) => {
    let compoundVariations = [];

    compoundVariations = getCompoundGroupVariations(
      compoundVariations,
      reactantGroups,
      'reactants',
      limitingCompoundIndex
    );

    compoundVariations = getCompoundGroupVariations(
      compoundVariations,
      reagentGroups,
      'reagents',
      limitingCompoundIndex
    );

    if (compoundVariations.length) {
      compoundVariations.forEach(variation => {
        const data = { ...variation };
        const limitingCompoundData = {
          volume: limitingCompound.volumes[0],
          equivalent: limitingCompound.equivalents[0],
          solvents: limitingCompound.solvents,
          name: limitingCompound.name,
          smiles: limitingCompound.smiles,
          isNullCompound: limitingCompound.isNullCompound,
        };

        if (variation[limitingCompoundName]) {
          data[limitingCompoundName] = [...variation[limitingCompoundName]];
          data[limitingCompoundName][limitingGroupIndex] = limitingCompoundData;
        } else {
          data[limitingCompoundName] = [];
          data[limitingCompoundName][limitingGroupIndex] = limitingCompoundData;
        }

        compoundGroupVariations.push(data);
      });
    } else {
      const compounds = [];
      compounds[limitingGroupIndex] = {
        volume: limitingCompound.volumes[0],
        equivalent: limitingCompound.equivalents[0],
        solvents: limitingCompound.solvents,
        name: limitingCompound.name,
        smiles: limitingCompound.smiles,
        isNullCompound: limitingCompound.isNullCompound,
      };
      compoundGroupVariations.push({
        [limitingCompoundName]: compounds,
      });
    }
  });

  let index = 0;
  compoundGroupVariations.forEach(groupVariation => {
    step.conditions.forEach((condition, conditionIndex) => {
      if (!validateConditionScreening(condition)) {
        return;
      }
      index++;
      let errorText = null;
      const data = {
        name: index,
        time: condition.time?.fixed,
        temperature: condition.temperature?.fixed,
        time_label: `COND ${conditionIndex + 1}`,
        temperature_label: `COND ${conditionIndex + 1}`,
        reactants: [],
        reagents: [],
        ...groupVariation,
      };
      if (rules) {
        rules.forEach((rule, index) => {
          errorText = errorText || synjetRulesParser(rule, index, data, dispensingOption);
        });
      }

      resultVariations.push({
        ...data,
        error: errorText,
        is_valid: !errorText,
      });
    });
  });
  return resultVariations
    .sort((a, b) => {
      if (moment(a.time).isSame(b.time)) return a.temperature - b.temperature;
      return a.time - b.time;
    })
    .map((variation, variationIndex) => ({
      ...variation,
      name: variationIndex + 1,
      actual_reaction_time: stepsProcess ? stepsProcess[0].step.vials[variationIndex]?.actual_reaction_time : null,
    }));
};

export const getLibGenVials = (
  products,
  rules,
  dispensingOption,
  stepsProcess,
  filterFirstStepProducts,
  setExpectedIntermediateAsProduct,
  fixedStepIndex
) => {
  const resultVariations = [];
  let index = 0;
  products
    .filter(product => product?.includeProduct)
    .forEach(product => {
      product.conditions
        .filter(cond => fixedStepIndex === -1 || cond.step !== fixedStepIndex)
        .forEach((condition, condIndex) => {
          if (!validateConditionScreening(condition)) {
            return;
          }
          index++;
          let errorText = null;
          const expectedIntermediate = product?.combination?.expectedIntermediate;
          const reactants = filterFirstStepProducts
            ? expectedIntermediate?.combination?.reactants?.filter(compound => !compound.isFixed) || []
            : product?.combination?.reactants?.filter(compound => !compound.isFixed);
          const reagents = filterFirstStepProducts
            ? expectedIntermediate?.combination?.reagents?.filter(compound => !compound.isFixed) || []
            : product?.combination?.reagents?.filter(compound => !compound.isFixed);
          const productName = setExpectedIntermediateAsProduct ? expectedIntermediate.name : product?.name;
          const productSmile = setExpectedIntermediateAsProduct ? expectedIntermediate.product : product?.product;
          const data = {
            name: index,
            time: condition.time?.fixed,
            temperature: condition.temperature?.fixed,
            time_label: `COND ${condition.initialIndex + 1}`,
            temperature_label: `COND ${condition.initialIndex + 1}`,
            reactants,
            reagents,
            quenchingCombination: product?.quenchingCombination,
            ...product,
            productName,
            product: productSmile,
          };

          // TODO: add rules check
          if (rules) {
            rules.forEach((rule, index) => {
              errorText = errorText || synjetRulesParser(rule, index, data, dispensingOption);
            });
          }

          resultVariations.push({
            ...data,
            error: errorText,
            is_valid: !errorText,
          });
        });
    });

  return resultVariations
    .sort((a, b) => {
      if (moment(a.time).isSame(b.time)) return a.temperature - b.temperature;
      return a.time - b.time;
    })
    .map((variation, variationIndex) => ({
      ...variation,
      name: variationIndex + 1,
      actual_reaction_time: stepsProcess ? stepsProcess[0].step.vials[variationIndex]?.actual_reaction_time : null,
    }));
};

export const getTwoStepScreeningVials = (step, rules, dispensingOption, stepsProcess) => {
  const { reactantGroups, reagentGroups } = step.compounds;
  let compoundGroupVariations = [];
  const resultVariations = [];
  compoundGroupVariations = getCompoundGroupVariations(compoundGroupVariations, reactantGroups, 'reactants', 0);

  compoundGroupVariations = getCompoundGroupVariations(compoundGroupVariations, reagentGroups, 'reagents', 0);

  let index = 0;
  compoundGroupVariations.forEach(groupVariation => {
    step.conditions.forEach((condition, conditionIndex) => {
      if (!validateConditionScreening(condition)) {
        return;
      }
      index++;
      let errorText = null;
      const data = {
        name: index,
        time: condition.time?.fixed,
        temperature: condition.temperature?.fixed,
        time_label: `COND ${conditionIndex + 1}`,
        temperature_label: `COND ${conditionIndex + 1}`,
        reactants: [],
        reagents: [],
        ...groupVariation,
      };
      if (rules) {
        rules.forEach((rule, index) => {
          errorText = errorText || synjetRulesParser(rule, index, data, dispensingOption);
        });
      }

      resultVariations.push({
        ...data,
        error: errorText,
        is_valid: !errorText,
      });
    });
  });

  return resultVariations
    .sort((a, b) => {
      if (moment(a.time).isSame(b.time)) return a.temperature - b.temperature;
      return a.time - b.time;
    })
    .map((variation, variationIndex) => ({
      ...variation,
      name: variationIndex + 1,
      actual_reaction_time: stepsProcess ? stepsProcess[0].step.vials[variationIndex]?.actual_reaction_time : null,
    }));
};

const getReagentNames = combination => {
  const names = [];
  if (!combination) return names;
  if (combination.reactants) combination.reactants.forEach(reactant => names.push(reactant.name));
  if (combination.reagents) combination.reagents.forEach(reagent => names.push(reagent.name));
  if (combination.expectedIntermediate) names.push(combination?.expectedIntermediate?.name);
  return names;
};

const generateProductConditions = (currentConditions, newConditions, regenerateCondition) => {
  if (!currentConditions.length) return newConditions;
  const conditionalIndexes = [];
  const conditions = currentConditions.reduce((result, current) => {
    const conditionIndex = newConditions.findIndex((cond, index) => {
      let compare;
      if (regenerateCondition) {
        compare = cond.initialIndex === current.initialIndex && cond.step === current.step;
      } else {
        compare =
          moment(cond.time?.fixed).isSame(current.time?.fixed) &&
          cond.temperature?.fixed === current.temperature?.fixed &&
          !conditionalIndexes.includes(index);
      }
      if (compare) conditionalIndexes.push(index);
      return compare;
    });

    if (conditionIndex !== -1) {
      const condition = newConditions[conditionIndex];
      result.push(
        regenerateCondition
          ? condition
          : {
              ...current,
              initialIndex: condition.initialIndex,
            }
      );
    }

    return result;
  }, []);

  return conditions;
};

export const sortConditionalByTemperature = conditions =>
  conditions.sort((cond1, cond2) => {
    const temp1 = cond1?.temperature?.fixed || 0;
    const temp2 = cond2?.temperature?.fixed || 0;
    return temp1 - temp2;
  });

const sortByName = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

const getProductName = (combination, index, fixedStep, productsCount) => {
  if (!fixedStep || combination.step) return `Pr${index + 1}`;
  return `EI${index + 1 - productsCount}`;
};

const getProductConditions = (step, fixedStep, isFirstFixed, name, expectedIntermediate) => {
  const fixedIndex = isFirstFixed ? 0 : 1;
  const variableIndex = isFirstFixed ? 1 : 0;
  const variableConditions = step.conditions
    .filter(cond => !cond.isFixed)
    .map((condition, index) => ({
      ...condition,
      initialIndex: index,
      step: variableIndex,
    }));
  if (!fixedStep || name.startsWith('EI')) return variableConditions;
  const fixedCondition = fixedStep?.conditions[0];
  const varConditions = expectedIntermediate && !isFirstFixed ? expectedIntermediate.conditions : variableConditions;
  return [
    ...varConditions,
    {
      ...fixedCondition,
      initialIndex: 0,
      isFixed: true,
      step: fixedIndex,
    },
  ];
};

export const getLimitedCompoundFromCombination = combination => {
  if (!combination) return null;
  const { reagents, reactants } = combination;
  const reagentLimited = reagents?.find(reagent => reagent.isLimiting);
  const reactantLimited = reactants?.find(reagent => reagent.isLimiting);
  return reagentLimited || reactantLimited;
};

export const getProductCalculatedYield = (product, limitingCompound) => {
  if (!product) return 0;
  const limitedCompoundMole = getLimitedCompoundMole(limitingCompound);
  const mole = limitedCompoundMole * product.molwt;
  const calculatedYield = mole / 1000;
  return calculatedYield.toFixed(3);
};

const recalculateProductYield = (product, combination, limitingCompound) => {
  const parsedLimitingCompound = getLimitedCompoundFromCombination(combination) || limitingCompound;
  return getProductCalculatedYield(product.product, parsedLimitingCompound);
};

export const getLibGenProducts = (
  step,
  products,
  quenching,
  forceProductConditionGeneration,
  fixedStep,
  isFirstFixed,
  newEI,
  isEI,
  regenerateCondition,
  limitingCompound
) => {
  let combinations = [];
  if (!fixedStep) {
    combinations = getCompoundGroupVariation(step);
  } else {
    const { reactantGroups, reagentGroups } = step.compounds;
    combinations = getCompoundGroupVariations(combinations, reactantGroups, 'reactants', 0);
    combinations = getCompoundGroupVariations(combinations, reagentGroups, 'reagents', 0);
  }

  let mappedCombinationWithStep = [];
  let productsCount = 0;

  if (!fixedStep || isEI) {
    mappedCombinationWithStep = combinations.map(combination => ({ ...combination, step: 0 }));
  } else if (isFirstFixed) {
    mappedCombinationWithStep = combinations.map(combination => {
      const { product } = fixedStep;
      const combinationReagents = combination.reagents || [];
      const combinationReactants = combination.reactants || [];
      productsCount += 1;
      return {
        reagents: combinationReagents,
        reactants: combinationReactants,
        expectedIntermediate: {
          product: product.smiles,
          name: 'EI1',
        },
        step: 1,
      };
    });
  } else {
    const { compounds } = fixedStep;
    const fixedReagents = compounds.reagents.map(reagent => ({ ...reagent, isFixed: true }));
    const fixedReactants = compounds.reactants.map(reagent => ({ ...reagent, isFixed: true }));
    mappedCombinationWithStep = newEI.map(intermediate => ({
      reagents: fixedReagents,
      reactants: fixedReactants,
      step: 1,
      expectedIntermediate: intermediate,
    }));
  }

  const generatedProducts = mappedCombinationWithStep.map((combination, index) => {
    const reagentNames = getReagentNames(combination);
    const name = getProductName(combination, index, fixedStep, productsCount);
    const { expectedIntermediate } = combination;
    return {
      name,
      reagentNames,
      combination,
      includeProduct: true,
      product: null,
      conditions: getProductConditions(step, fixedStep, isFirstFixed, name, expectedIntermediate),
      calculatedYield: null,
      quenching: quenching || [],
      quenchingCombination: quenching ? getQuenchingVariation(step, quenching, reagentNames) : [],
      step: combination.step,
    };
  });

  return generatedProducts.map(newProduct => {
    const oldProduct = products.find(product => newProduct.reagentNames.join() === product.reagentNames.join());
    if (oldProduct) {
      return {
        ...newProduct,
        name: regenerateCondition ? oldProduct.name : newProduct.name,
        includeProduct: oldProduct.includeProduct,
        product: oldProduct.product,
        calculatedYield: regenerateCondition
          ? oldProduct.calculatedYield
          : recalculateProductYield(oldProduct, newProduct.combination, limitingCompound),
        conditions: forceProductConditionGeneration
          ? newProduct.conditions
          : generateProductConditions(oldProduct.conditions, newProduct.conditions, regenerateCondition),
      };
    }
    return newProduct;
  });
};

export const getConditionalLabel = condition => {
  if (!condition?.temperature && !condition?.time) return '';
  return `${condition?.temperature?.fixed} \u{B0}C / ${moment(condition?.time?.fixed).format('HH')}h ${moment(
    condition?.time?.fixed
  ).format('mm')}m`;
};

const getCompoundGroupVariations = (oldCompoundVariations, groups, compoundName, limitingCompoundIndex) => {
  let compoundVariations = [...oldCompoundVariations];
  groups.forEach((compoundGroup, compoundGroupIndex) => {
    if (!compoundGroup.isLimiting) {
      const reagentVariations = compoundGroup.compoundsList.map(compound => ({
        volume: compound.volumes?.[limitingCompoundIndex],
        solvents: compound.solvents,
        equivalent: compound.equivalents?.[limitingCompoundIndex],
        name: compound.name,
        smiles: compound.smiles,
        isNullCompound: compound.isNullCompound,
        solventType: compound.solventType,
      }));

      if (!compoundVariations.length) {
        reagentVariations.forEach(variation => {
          const newArray = [];
          newArray[compoundGroupIndex] = variation;
          compoundVariations.push({ [compoundName]: newArray });
        });
      } else {
        const newVariations = [];
        reagentVariations.forEach(variation => {
          compoundVariations.forEach(compoundVariation => {
            const compoundVar = compoundVariation[compoundName] || [];
            const compounds = [...compoundVar];
            compounds[compoundGroupIndex] = variation;
            newVariations.push({ ...compoundVariation, [compoundName]: compounds });
          });
        });
        compoundVariations = newVariations;
      }
    }
  });

  return compoundVariations;
};

const synjetRulesParser = (rule, index, data, dispensingOption) => {
  let isValid = true;

  if (!validateRule(rule)) {
    return null;
  }

  let fitsTheFirstRule = false;

  if (rule.compoundRole && rule.compoundData) {
    const hasInReactants = checkCompoundRule(data.reactants, rule);
    const hasInReagents = checkCompoundRule(data.reagents, rule);
    const hasInQuenching = data.quenching ? checkCompoundRule(data.quenching, rule) : false;
    const hasInDispensing = checkDispensingRule(dispensingOption, rule);
    if (hasInReactants || hasInReagents || hasInDispensing || hasInQuenching) {
      fitsTheFirstRule = true;
    }
  }

  if (!fitsTheFirstRule) {
    return null;
  }

  if (rule.component && rule.componentValue && rule.operator) {
    if (rule.component === COMPONENT[3].value || rule.component === COMPONENT[2].value) {
      const hasInReactants = checkCompoundRule(data.reactants, rule, true);
      const hasInReagents = checkCompoundRule(data.reagents, rule, true);
      const hasInQuenching = data.quenching ? checkCompoundRule(data.quenching, rule, true) : false;
      const hasInDispensing = checkDispensingRule(dispensingOption, rule, true);
      if (hasInReactants || hasInReagents || hasInDispensing || hasInQuenching) {
        isValid = false;
      }
    } else if (
      rule.component === COMPONENT[0].value &&
      compareByCondition(data.temperature, rule.operator, rule.componentValue)
    ) {
      isValid = false;
    } else if (
      rule.component === COMPONENT[1].value &&
      compareTimeByCondition(data.time, rule.operator, rule.componentValue)
    ) {
      isValid = false;
    }
  }

  return !isValid ? `The reaction cannot be executed according to exclusion rule ${index + 1}` : null;
};

const checkCompoundRule = (compounds, rule, isSecondRule) => {
  if (isSecondRule) {
    return compounds?.some(compound => {
      if (rule.component === COMPONENT[2].value) {
        return compound.solvents?.some(({ solvent }) => solvent?.compound_id === rule.component_id);
      }
      if (rule.component === COMPONENT[3].value) {
        return compound.smiles?.compound_id === rule.component_id;
      }
    });
  }

  return (
    compounds?.some(compound => {
      if (rule.compoundRole === COMPOUND_ROLE[0].value && !!compound.solvents) {
        return compound.solvents?.some(({ solvent }) => solvent?.compound_id === rule.compound_id);
      }
      if (rule.compoundRole === COMPOUND_ROLE[1].value) {
        return compound.smiles?.compound_id === rule.compound_id;
      }
    }) || []
  );
};

const checkDispensingRule = (dispensing, rule, isSecondRule) => {
  if (isSecondRule && rule.component === COMPONENT[2].value) {
    return dispensing === rule.component_id;
  }
  if (rule.compoundRole === COMPOUND_ROLE[0].value) {
    return dispensing === rule.compound_id;
  }
};

const compareByCondition = (firstValue, condition, secondValue) => {
  switch (condition) {
    case OPERATOR[0].value:
      return Number(firstValue) > Number(secondValue);
    case OPERATOR[1].value:
      return Number(firstValue) < Number(secondValue);
    case OPERATOR[2].value:
      return Number(firstValue) === Number(secondValue);
    default:
      return false;
  }
};

const compareTimeByCondition = (firstValue, condition, secondValue) => {
  switch (condition) {
    case OPERATOR[0].value:
      return moment(firstValue).isAfter(moment(secondValue));
    case OPERATOR[1].value:
      return moment(firstValue).isBefore(moment(secondValue));
    case OPERATOR[2].value:
      return moment(firstValue).isSame(moment(secondValue));
    default:
      return false;
  }
};

export const validateProcess = (
  rules,
  steps,
  processType,
  validateRules,
  products,
  quenching,
  expectedIntermediates
) => {
  if (validateRules) {
    const containsInvalidRule = rules.some(rule => !validateRule(rule));

    if (containsInvalidRule) {
      return false;
    }
  }

  let hasLimitingCompound = false;

  const containsInvalidStep = steps.some(step => {
    if (processType === SYNJET_PROCESS_TYPES.OPTIMIZATION || step.isFixed) {
      const containsInvalidReactant = step.compounds.reactants.some(compound => {
        if (compound.isLimiting) {
          hasLimitingCompound = true;
        }
        return !validateCompound(compound);
      });

      if (containsInvalidReactant) return true;

      return step.compounds.reagents.some(compound => {
        if (compound.isLimiting) {
          hasLimitingCompound = true;
        }
        return !validateCompound(compound);
      });
    }

    if (
      (processType === SYNJET_PROCESS_TYPES.SCREENING || processType === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION) &&
      !step.isFixed
    ) {
      if (!step.compounds.reactantGroups.length) return true;

      const containsInvalidReactant = step.compounds.reactantGroups.some(group => {
        if (!group.compoundsList.length) {
          return true;
        }
        if (group.isLimiting) {
          hasLimitingCompound = true;
        }
        return group.compoundsList.some(compound => !validateCompound(compound));
      });

      if (containsInvalidReactant) return true;

      return (
        step.compounds.reagentGroups &&
        step.compounds.reagentGroups.some(group => {
          if (!group.compoundsList.length) {
            return true;
          }
          if (group.isLimiting) {
            hasLimitingCompound = true;
          }
          return group.compoundsList.some(compound => !validateCompound(compound));
        })
      );
    }
  });

  let containsInvalidProducts = false;
  if (products) containsInvalidProducts = products.some(product => !validateProduct(product));

  let containsInvalidEI = false;
  if (expectedIntermediates && expectedIntermediates.length) {
    containsInvalidEI = expectedIntermediates.some(product => !validateProduct(product));
  }

  let containsInvalidQuenching = false;
  if (quenching) containsInvalidQuenching = quenching.some(product => !validateCompound(product));

  return (
    !containsInvalidStep &&
    !!hasLimitingCompound &&
    !containsInvalidProducts &&
    !containsInvalidQuenching &&
    !containsInvalidEI
  );
};

const validateCompound = compound => {
  if (compound.isNullCompound) {
    return true;
  }
  const commonValidation = !!compound.smiles && compound.volumes.every(volume => volume);
  if (compound.solventType === SOLVENT_TYPES.SOLID) return commonValidation;
  return commonValidation && compound.concentration;
};

const validateProduct = compound => !compound?.includeProduct || (!!compound?.calculatedYield && !!compound?.product);

const validateRule = rule =>
  rule.compoundRole &&
  rule.compoundData &&
  rule.component &&
  rule.operator &&
  rule.componentValue !== null &&
  rule.componentValue !== undefined;

const validateConditionOptimization = condition =>
  condition.time.low &&
  condition.time.med &&
  condition.time.high &&
  condition.temperature.low &&
  condition.temperature.med &&
  condition.temperature.high;

const validateConditionScreening = condition => condition.time?.fixed && condition.temperature?.fixed;

const mapProducts = (products, compounds, isProduct) =>
  products?.map(product => {
    if (product.product) compounds.push(product.product);
    return {
      name: product.name,
      reagent_names: product.reagentNames,
      include_product: product.includeProduct,
      product: product.product?.compound_id || null,
      product_theoretical_yield: product.calculatedYield || null,
      step: isProduct ? 1 : 0,
      conditions: product.conditions.map(condition => ({
        time_label: null,
        time: moment(condition?.time?.fixed).second(),
        temperature_label: null,
        temperature: condition?.temperature?.fixed,
        initial_index: condition.initialIndex,
        step: condition.step || 0,
      })),
      quenching_names: product.quenching?.map(item => item.name) || [],
    };
  }) || [];

export const getSynjetProcessData = data => {
  const { dispensing } = data.steps[data.steps.length - 1].conditions[0];

  const isQuenchingEnabled = data?.process?.process?.enableQuenching;

  let conditions = [];
  const compounds = [];
  const reagents = [];
  const reactants = [];

  if (dispensing.solvent) {
    compounds.push(dispensing.solvent);
  }

  data.steps.forEach((step, stepIndex) => {
    compounds.push(step.product?.smiles);
    conditions = [
      ...conditions,
      ...step.conditions.map(condition => ({
        ...condition,
        step: stepIndex,
        time: {
          low: getSecondsFromTime(condition.time.low),
          med: getSecondsFromTime(condition.time.med),
          high: getSecondsFromTime(condition.time.high),
          fixed: getSecondsFromTime(condition.time.fixed),
        },
      })),
    ];

    if (data.process.process.type === SYNJET_PROCESS_TYPES.OPTIMIZATION || step?.isFixed) {
      step.compounds.reactants.forEach(compound => {
        const { smilesCompound, compoundData } = getCompoundData(compound, 'reactant', stepIndex);
        if (compoundData.name) {
          compounds.push(...smilesCompound);
          reactants.push(compoundData);
        }
      });
      step.compounds.reagents.forEach(compound => {
        const { smilesCompound, compoundData } = getCompoundData(compound, 'reagent', stepIndex);
        if (compoundData.name) {
          compounds.push(...smilesCompound);
          reagents.push(compoundData);
        }
      });
    } else if (
      data.process.process.type === SYNJET_PROCESS_TYPES.SCREENING ||
      data.process.process.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION
    ) {
      step.compounds.reactantGroups.forEach(group => {
        group.compoundsList.forEach(compound => {
          const { smilesCompound, compoundData } = getCompoundData(compound, 'reactant', stepIndex, group);
          if (compoundData.name) {
            compounds.push(...smilesCompound);
            reactants.push(compoundData);
          }
        });
      });
      step.compounds.reagentGroups.forEach(group => {
        group.compoundsList.forEach(compound => {
          const { smilesCompound, compoundData } = getCompoundData(compound, 'reagent', stepIndex, group);
          if (compoundData.name) {
            compounds.push(...smilesCompound);
            reagents.push(compoundData);
          }
        });
      });
    }
  });

  const processSteps = data.processData.process_steps.length
    ? data.processData.process_steps.map(step => ({
        step: {
          ...step.step,
          product: step.step?.product || null,
          vials: step.step.vials.map(vial => ({
            ...vial,
            total_volume: vial.total,
            time: getSecondsFromTime(vial.time),
            reactants: vial.reactants.map(reactant => ({
              label: reactant.label || null,
              name: reactant.name,
              volume: reactant.volume || null,
              equivalent: reactant.equivalent || null,
              smiles: reactant.smiles?.compound_id || null,
            })),
            reagents:
              vial.reagents?.map(reagent => ({
                label: reagent.label || null,
                name: reagent.name,
                volume: reagent.volume || null,
                equivalent: reagent.equivalent || null,
                smiles: reagent.smiles?.compound_id || null,
              })) || [],
            product: vial.productName || null,
            product_theoretical_yield: vial.calculatedYield || null,
            quenching: vial.quenchingCombination || [],
          })),
        },
      }))
    : data.steps.map(step => ({
        step: {
          isFixed: step.isFixed,
          product: step.product?.smiles?.compound_id || null,
          vials: [],
        },
      }));

  const rules = data.rules.map((rule, idx) => ({
    compound_role: rule.compoundRole || null,
    compound_data: rule.compoundData || rule?.compoundSmiles || null,
    component: rule.component || null,
    operator: rule.operator || null,
    component_value:
      rule.componentValue !== null && rule.componentValue !== undefined && rule.componentValue !== ''
        ? rule.component === COMPONENT[1].value
          ? String(getSecondsFromTime(rule.componentValue))
          : rule.componentValue
        : rule.componentSmiles
        ? rule.componentSmiles
        : null,
    compound_id: rule.compound_id || null,
    component_id: rule.component_id || null,
    predefined: rule.predefined || null,
  }));

  const products = mapProducts(data.products, compounds, true);
  const expectedIntermediate = mapProducts(data.expectedIntermediates, compounds);
  products.push(...expectedIntermediate);

  const quenching = isQuenchingEnabled
    ? data.quenching?.map(quenchingItem => {
        const { smilesCompound } = getCompoundData(quenchingItem);
        compounds.push(...smilesCompound);

        return {
          name: quenchingItem.name,
          compound: quenchingItem?.smiles?.compound_id,
          step: 1,
          solvents:
            quenchingItem?.solvents?.reduce((result, solvent) => {
              if (solvent.solvent.compound_id) {
                compounds.push(solvent.solvent);
                return [
                  ...result,
                  {
                    fraction: solvent.fraction,
                    solvent: solvent.solvent.compound_id,
                  },
                ];
              }
              return result;
            }, []) || [],
          concentration: quenchingItem.concentration,
          equivalent: quenchingItem.equivalents,
          volume: quenchingItem.volumes,
          solvent_type: SOLVENT_TYPES.LIQUID,
        };
      }) || []
    : [];

  return {
    process_steps: processSteps,
    general_info: {
      total_volume: dispensing.volume,
      normalize_with: dispensing.solvent?.compound_id || null,
      is_normalize_with_enabled: dispensing.normalize,
      is_quenching_enabled: false,
    },
    rules,
    conditions,
    compounds: compounds.reduce((unique, compound) => {
      if (compound && !unique.some(existingCompound => existingCompound.compound_id === compound?.compound_id)) {
        const { compatibility, ...restCompound } = compound;
        return compatibility ? [...unique, compound] : [...unique, restCompound];
      }
      return unique;
    }, []),
    reagents,
    reactants,
    products,
    quenching,
  };
};

const getCompoundData = (compoundData, compoundName, stepIndex, group) => {
  if (!compoundData.isNullCompound && !compoundData.smiles?.compound_id) {
    return {
      smilesCompound: [],
      compoundData: {},
    };
  }

  const compounds = compoundData.smiles ? [compoundData.smiles] : [];

  const solvents =
    compoundData.solvents?.reduce((result, solvent) => {
      if (solvent.solvent.compound_id) {
        compounds.push(solvent.solvent);
        return [
          ...result,
          {
            fraction: solvent.fraction,
            solvent: solvent.solvent.compound_id,
          },
        ];
      }
      return result;
    }, []) || [];

  const data = {
    name: compoundData.name,
    group: group?.name || null,
    step: stepIndex,
    concentration: compoundData.concentration || null,
    limiting_reagent: compoundData.isLimiting || group?.isLimiting || false,
    equivalent: compoundData.isNullCompound ? [] : compoundData.equivalents,
    volume: compoundData.isNullCompound ? [] : compoundData.volumes,
    [compoundName]:
      compoundData.isNullCompound || !compoundData.smiles?.compound_id ? null : compoundData.smiles?.compound_id,
    solvents: compoundData.isNullCompound ? [] : solvents,
    solvent_type: compoundData.solventType || null,
  };

  return {
    smilesCompound: compounds,
    compoundData: data,
  };
};

const mapConditions = (data, stepIndex) =>
  data.conditions
    .filter(condition => condition.step === stepIndex)
    .map(condition => ({
      ...condition,
      time: {
        low: getTimeFromSeconds(condition.time.low),
        med: getTimeFromSeconds(condition.time.med),
        high: getTimeFromSeconds(condition.time.high),
        fixed: getTimeFromSeconds(condition.time.fixed),
      },
      dispensing: {
        volume: data.general_info.total_volume,
        normalize: data.general_info.is_normalize_with_enabled,
        solvent: getCompoundById(data.general_info.normalize_with, data.compounds),
      },
    }));

const mapProductConditions = (productConditions, data, stepsNumber, firstStep) =>
  productConditions.map(productCondition => {
    const initialIndex = productCondition.initial_index;
    const conditions = mapConditions(data, productCondition.step);
    const condition = conditions[initialIndex];
    const isFixed = firstStep.isFixed ? !productCondition.step : productCondition.step;
    return {
      ...condition,
      initialIndex,
      step: productCondition.step,
      isFixed: stepsNumber > 1 && isFixed,
    };
  });

const getCompoundCombination = (data, reagentNames, expectedIntermediates, isFirstFixed, fixedStep) =>
  reagentNames.reduce(
    (combination, name) => {
      const reagent = data.reagents.find(reagentItem => reagentItem.name === name);
      if (reagent) {
        const id = getCompoundId(reagent, data.reagents);
        combination.reagents.push({
          ...reagent,
          smiles: getCompoundById(reagent.smiles || id, data.compounds),
        });
      }
      const reactant = data.reactants.find(reagentItem => reagentItem.name === name);
      if (reactant) {
        const id = getCompoundId(reactant, data.reactants);
        combination.reactants.push({
          ...reactant,
          smiles: getCompoundById(reactant.smiles || id, data.compounds),
        });
      }
      const EI =
        isFirstFixed && fixedStep
          ? {
              name: 'EI1',
              product: fixedStep?.smiles,
            }
          : expectedIntermediates?.find(expected => name === expected.name);
      // eslint-disable-next-line no-param-reassign
      if (EI) combination.expectedIntermediate = EI;
      return combination;
    },
    {
      reagents: [],
      reactants: [],
    }
  );

const getProductFromVial = (data, productName) => {
  const product = data.products.find(pr => pr.name === productName);
  return data.compounds.find(component => component.compound_id === product?.product);
};

export const parseProcessDataFromBackend = (data, variableStep) => {
  const rules = data.rules.map(rule => ({
    compoundRole: rule.compound_role,
    compoundData: rule.compound_data,
    component: rule.component,
    operator: rule.operator,
    componentValue:
      rule.component === COMPONENT[1].value ? getTimeFromSeconds(Number(rule.component_value)) : rule.component_value,
    compound_id: rule.compound_id,
    component_id: rule.component_id,
    predefined: rule.predefined,
  }));

  const quenchingCombination = [];

  const quenching =
    data.quenching?.map(quenchingItem => ({
      name: quenchingItem.name,
      smiles: getCompoundById(quenchingItem.compound, data.compounds),
      solvents: quenchingItem?.solvents?.length
        ? quenchingItem?.solvents?.map(solvent => ({
            fraction: solvent.fraction,
            solvent: getCompoundById(solvent.solvent, data.compounds),
          }))
        : [{ fraction: 1, solvent: { compound_name: 'None' } }],
      concentration: quenchingItem.concentration,
      equivalents: quenchingItem.equivalent,
      volumes: quenchingItem.volume,
    })) || [];

  const processSteps = data.process_steps.map((step, stepIndex) => ({
    step: {
      ...step.step,
      isFixed: stepIndex + 1 !== Number(variableStep),
      vials: step.step.vials.map((vial, index) => {
        quenchingCombination.push(vial.quenching);
        const product = data.products ? getProductFromVial(data, vial.product) : [];
        return {
          ...vial,
          total: vial.total_volume,
          time: getTimeFromSeconds(vial.time),
          reactants: vial.reactants.map(reactant => {
            const id = getCompoundId(reactant, data.reactants);
            return { ...reactant, smiles: getCompoundById(reactant.smiles || id, data.compounds) };
          }),
          reagents: vial.reagents?.map(reagent => {
            const id = getCompoundId(reagent, data.reagents, true);
            return { ...reagent, smiles: getCompoundById(reagent.smiles || id, data.compounds) };
          }),
          productName: vial.product,
          product, // product property is empty at SynJet
          calculatedYield: vial.product_theoretical_yield,
          quenchingCombination: vial.quenching,
          quenching,
        };
      }),
    },
  }));

  const steps = data.process_steps.map(({ step }, stepIndex) => {
    const { reactants, reactantGroups } = getCompoundForFrontEnd(data.reactants, stepIndex, 'reactant', data.compounds);
    const { reagents, reagentGroups } = getCompoundForFrontEnd(data.reagents, stepIndex, 'reagent', data.compounds);
    return {
      isFixed: stepIndex + 1 !== Number(variableStep),
      product: { smiles: getCompoundById(step.product, data.compounds) },
      conditions: mapConditions(data, stepIndex),
      compounds: {
        reactants,
        reactantGroups,
        reagents,
        reagentGroups,
      },
    };
  });

  const fixedStep = steps.find(step => step.isFixed);

  const hasExpectedIntermediate = steps?.length > 1 && !steps?.[0]?.isFixed;

  const expectedIntermediates = hasExpectedIntermediate
    ? data?.products
        ?.filter(product => !product.step)
        ?.map(product => ({
          name: product.name,
          reagentNames: product.reagent_names,
          combination: getCompoundCombination(data, product.reagent_names),
          includeProduct: product.include_product,
          product: getCompoundById(product.product, data.compounds),
          conditions: mapProductConditions(product.conditions, data, steps.length, steps[0]),
          calculatedYield: product.product_theoretical_yield,
          quenchingCombination: [],
          quenching: [],
          step: product.step,
        })) || []
    : [];

  const products =
    data.products
      ?.filter(product => !hasExpectedIntermediate || product.step)
      ?.map(product => ({
        name: product.name,
        reagentNames: product.reagent_names,
        combination: getCompoundCombination(
          data,
          product.reagent_names,
          expectedIntermediates,
          steps?.[0]?.isFixed,
          fixedStep
        ),
        includeProduct: product.include_product,
        product: getCompoundById(product.product, data.compounds),
        conditions: mapProductConditions(product.conditions, data, steps.length, steps[0], expectedIntermediates),
        calculatedYield: product.product_theoretical_yield,
        quenchingCombination,
        quenching,
        step: product.step,
      })) || [];

  return {
    processSteps,
    steps,
    rules,
    products,
    quenching,
    expectedIntermediates,
  };
};

export const parseProductDataToArray = productsArray => {
  let resultProductArray = [];
  if (productsArray.length) {
    resultProductArray = productsArray
      .map((item, index) => {
        if (item.product) {
          return {
            smiles: {
              compound_id: item.product.compound_id,
              compound_name: item.product.compound_name,
              exactmolwt: item.product.exactmolwt,
              formula: item.product.formula,
              inchi: item.product.inchi,
              inchikey: item.product.inchikey,
              molregno: item.product.molregno,
              molwt: item.product.molwt,
              single_fragment: item.product.single_fragment,
              smiles: item.product.smiles,
              synonyms: item.product.synonyms,
              tag: item.product.tag,
            },
            conditions: item.conditions,
            step: item.step,
          };
        }
      })
      .filter(item => !!item);
  }
  return resultProductArray;
};

const getCompoundForFrontEnd = (compounds, stepIndex, compoundName, compoundList) => {
  const result = {
    [`${compoundName}s`]: [],
    [`${compoundName}Groups`]: [],
  };

  const getCompound = (compound, isGroup) => {
    const isSolid = compound.solvent_type === SOLVENT_TYPES.SOLID;
    const data = {
      isNullCompound: !compound[compoundName],
      smiles: getCompoundById(compound[compoundName], compoundList),
      solvents: isSolid
        ? null
        : compound.solvents.length
        ? compound.solvents.map(solvent => ({
            fraction: solvent.fraction,
            solvent: getCompoundById(solvent.solvent, compoundList),
          }))
        : [{ fraction: 1, solvent: { compound_name: 'None' } }],
      concentration: compound.concentration,
      equivalents: compound.equivalent,
      volumes: compound.volume,
      name: compound.name,
      solventType: compound.solvent_type,
    };

    if (!isGroup) {
      data.isLimiting = compound.limiting_reagent;
    }

    return data;
  };

  compounds.forEach(compound => {
    if (compound.step === stepIndex) {
      if (compound.group) {
        const groupIndex = result[`${compoundName}Groups`].findIndex(group => group.name === compound.group);

        if (groupIndex !== -1) {
          result[`${compoundName}Groups`][groupIndex].compoundsList.push(getCompound(compound, true));
        } else {
          result[`${compoundName}Groups`].push({
            name: compound.group,
            isLimiting: compound.limiting_reagent,
            compoundsList: [getCompound(compound, true)],
          });
        }
      } else {
        result[`${compoundName}s`].push(getCompound(compound));
      }
    }
  });

  return result;
};

const getCompoundId = (reactantWithoutId, reactants, isReagent) => {
  const fullCompound = reactants?.find(i => i.name === reactantWithoutId.name);
  return isReagent ? fullCompound?.reagent : fullCompound?.reactant;
};

const getCompoundById = (uuid, compounds) => (uuid ? compounds.find(compound => compound.compound_id === uuid) : null);

const getSecondsFromTime = time => moment(time).diff(moment(time).startOf('day'), 'seconds');

export const getTimeFromSeconds = seconds => moment().startOf('day').add(seconds, 'seconds');

export const getVials = (processType, variableStep, rules, dispensingOption, steps, stepsProcess, products) => {
  if (processType === SYNJET_PROCESS_TYPES.OPTIMIZATION) {
    return getOptimizationVials(variableStep, rules, dispensingOption, steps.length === 2, stepsProcess);
  }
  if (processType === SYNJET_PROCESS_TYPES.SCREENING) {
    return steps.length === 2
      ? getTwoStepScreeningVials(variableStep, rules, dispensingOption, stepsProcess)
      : getScreeningVials(variableStep, rules, dispensingOption, stepsProcess);
  }
  if (processType === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION) {
    const fixedStep = steps.find(step => step.isFixed);
    const fixedStepIndex = steps.findIndex(step => step.isFixed);
    const variableStepIndex = steps.findIndex(step => !step.isFixed);
    const isFirstFixed = steps?.[0]?.isFixed;

    const filterFirstStepProducts = fixedStep && !isFirstFixed;
    const setExpectedIntermediateAsProduct = steps?.length > 1 && !variableStepIndex;
    const vials = getLibGenVials(
      products,
      rules,
      dispensingOption,
      stepsProcess,
      filterFirstStepProducts,
      setExpectedIntermediateAsProduct,
      fixedStepIndex
    );
    return vials;
  }
};

export const getProcessStepsData = (steps, rules, processType, stepsProcess, experimentProducts) => {
  const { solvent, normalize } = steps[steps.length - 1].conditions[0].dispensing;
  const dispensingOption = normalize ? solvent?.compound_id : null;
  const variableStep = steps.find(step => !step.isFixed);
  if (!variableStep) {
    return;
  }
  const calculatedVariableStep = {
    step: {
      product: variableStep.product?.smiles?.compound_id,
      isFixed: false,
      vials: getVials(processType, variableStep, rules, dispensingOption, steps, stepsProcess, experimentProducts),
    },
  };

  const isLibGen = processType === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION;
  const vialProducts = isLibGen ? getVialProducts(calculatedVariableStep, experimentProducts) : [];
  // to do implement fixed step
  const processSteps = steps.map((step, stepIndex) => {
    if (step.isFixed) {
      return {
        step: {
          product: step.product?.smiles?.compound_id,
          isFixed: true,
          vials: getFixedVials(
            step,
            rules,
            dispensingOption,
            calculatedVariableStep.step.vials.length,
            stepIndex === 0,
            stepsProcess,
            isLibGen,
            vialProducts
          ),
        },
      };
    }
    return calculatedVariableStep;
  });
  const res = calculateTotalVolume(processSteps, steps);
  return res;
};

export const getVialProducts = (calculatedVariableStep, products) =>
  calculatedVariableStep?.step?.vials.map(({ product, productName }) => {
    if (productName.startsWith('Pr')) {
      return {
        product,
        productName,
      };
    }
    const matchedProduct = products.find(pro => pro.reagentNames.includes(productName));
    if (matchedProduct) {
      return {
        product: matchedProduct.product,
        productName: matchedProduct.name,
        calculatedYield: matchedProduct.calculatedYield,
      };
    }
  });

export const calculateTotalVolume = (result, data) => {
  const dispensingVolume = data[data.length - 1]?.conditions[0].dispensing.volume;
  const normalize =
    data[data.length - 1]?.conditions[0].dispensing.normalize &&
    !!data[data.length - 1]?.conditions[0].dispensing.solvent;
  const compoundSum = [];

  result.forEach(step => {
    step.step.vials.forEach((vial, vialIndex) => {
      vial.reactants.forEach(reactant => {
        compoundSum[vialIndex] = (compoundSum[vialIndex] || 0) + Number(getCompoundVolume(reactant));
      });
      if (vial.reagents) {
        vial.reagents.forEach(reagent => {
          compoundSum[vialIndex] = (compoundSum[vialIndex] || 0) + Number(getCompoundVolume(reagent));
        });
      }
      if (vial.quenchingCombination) {
        const quenchingSum = vial.quenchingCombination.reduce((total, current) => total + Number(current.volume), 0);
        compoundSum[vialIndex] = (compoundSum[vialIndex] || 0) + quenchingSum;
      }
    });
  });
  return result.map(step => ({
    step: {
      ...step.step,
      vials: step.step.vials.map((vial, vialIndex) => {
        const sum = compoundSum[vialIndex];
        const total = dispensingVolume - sum < 0 || !normalize ? sum : sum + (dispensingVolume - sum);
        let message = '';

        if (total > dispensingVolume) {
          message = 'Reaction volume exceeds the total vial volume';
        } else if (total < dispensingVolume) {
          message = 'Reaction volume falls behind the total vial volume';
        }

        return {
          ...vial,
          total,
          normalization_solvent_volume: dispensingVolume - compoundSum[vialIndex],
          is_valid: !message && vial.is_valid,
          error: message || vial.error,
        };
      }),
    },
  }));
};

export const validateTotalVolume = (result, data) => {
  const dispensingVolume = data[data.length - 1]?.conditions[0].dispensing.volume;
  const normalize =
    data[data.length - 1]?.conditions[0].dispensing.normalize &&
    !!data[data.length - 1]?.conditions[0].dispensing.solvent;
  const variableStep = result.find(step => !step.step.isFixed);
  const fixedStep = result.find(step => step.step.isFixed);
  const compoundSum = [];

  variableStep.step.vials.forEach((vial, vialIndex) => {
    vial.reactants.forEach(reactant => {
      compoundSum[vialIndex] = (compoundSum[vialIndex] || 0) + Number(getCompoundVolume(reactant));
    });
    if (vial.reagents) {
      vial.reagents.forEach(reagent => {
        compoundSum[vialIndex] = (compoundSum[vialIndex] || 0) + Number(getCompoundVolume(reagent));
      });
    }
    if (fixedStep) {
      const fixedVial = fixedStep.step.vials[0];
      fixedVial.reactants.forEach(reactant => {
        compoundSum[vialIndex] = (compoundSum[vialIndex] || 0) + Number(getCompoundVolume(reactant));
      });
      fixedVial.reagents.forEach(reagent => {
        compoundSum[vialIndex] = (compoundSum[vialIndex] || 0) + Number(getCompoundVolume(reagent));
      });
    }
  });

  return result.map(step => {
    if (step.step.isFixed) {
      return step;
    }
    return {
      step: {
        ...step.step,
        vials: step.step.vials.map((vial, vialIndex) => {
          const sum = compoundSum[vialIndex];
          const total = dispensingVolume - sum < 0 || !normalize ? sum : sum + (dispensingVolume - sum);
          let message = '';

          if (total > dispensingVolume) {
            message = 'Reaction volume exceeds the total vial volume';
          } else if (total < dispensingVolume) {
            message = 'Reaction volume falls behind the total vial volume';
          }

          return {
            ...vial,
            is_valid: !message && vial.is_valid,
            error: message || vial.error,
          };
        }),
      },
    };
  });
};

export const uniqArrayExperiments = arr => {
  const seen = {};
  return arr.filter(item => (seen.hasOwnProperty(item.experiment_uuid) ? false : (seen[item.experiment_uuid] = true)));
};

const getUniqueVialIdentificator = vial =>
  `${vial.time_label}_${vial.temperature_label}_${vial.productName.replace(/Pr|EI/, '')}`;

export const getInitialVials = processSteps => {
  if (!processSteps) return [];
  const variableStep = processSteps.find(step => !step?.step?.isFixed);
  return variableStep?.step?.vials.map(vial => getUniqueVialIdentificator(vial));
};

export const getCopiedVials = initialVials =>
  initialVials.reduce((accum, current) => {
    const currentCount = accum[current];
    return {
      ...accum,
      [current]: currentCount ? currentCount + 1 : 1,
    };
  }, {});

export const getAncestorMapping = processSteps => {
  if (!processSteps) return [];
  const variableStep = processSteps.find(step => !step?.step?.isFixed);

  return variableStep?.step?.vials.reduce((accum, vial) => {
    const vialIndetificator = getUniqueVialIdentificator(vial);
    return {
      ...accum,
      [vialIndetificator]: vial.ancestor,
    };
  }, {});
};

export const mapWithInitialProcessSteps = (processSteps, initialVials, copiedVials, ancestorMapping) => {
  if (!initialVials) return processSteps;
  const mappedVialsIndex = [];
  const copiedVialsMap = {};
  const variableStep = processSteps.find(({ step }) => !step.isFixed)?.step;
  if (!variableStep) return processSteps;
  const variableStepVials = variableStep.vials
    .reduce((accum, vial, vialIndex) => {
      const vialIndicator = getUniqueVialIdentificator(vial);
      if (initialVials.includes(vialIndicator)) {
        const vialWithCopies = Array(copiedVials[vialIndicator]).fill(vial);
        accum.push(...vialWithCopies);
        if (copiedVials[vialIndicator] > 1) {
          copiedVialsMap[vialIndex] = copiedVials[vialIndicator];
        }
        mappedVialsIndex.push(vialIndex);
      }
      return accum;
    }, [])
    .map((vial, vialIndex) => {
      const updatedVial = { ...vial, name: vialIndex + 1 };
      const ancestorVial = ancestorMapping[getUniqueVialIdentificator(vial)];
      return ancestorVial ? { ...updatedVial, ancestor: ancestorVial } : updatedVial;
    });

  return processSteps.map(({ step }) => {
    if (step.isFixed) {
      return {
        step: {
          ...step,
          vials: step.vials
            .reduce((accum, vial, vialIndex) => {
              if (mappedVialsIndex.includes(vialIndex)) {
                const copiesCount = copiedVialsMap[vialIndex] || 1;
                return [...accum, ...Array(copiesCount).fill(vial)];
              }
              return accum;
            }, [])
            .map((vial, vialIndex) => {
              const updatedVial = { ...vial, name: vialIndex + 1 };
              const variableVialAncestor = variableStepVials[vialIndex]?.ancestor;
              return variableVialAncestor ? { ...updatedVial, ancestor: variableVialAncestor } : updatedVial;
            }),
        },
      };
    }
    return {
      step: {
        ...step,
        vials: variableStepVials,
      },
    };
  });
};

export const getLimitingCompoundFromProcessSteps = (processSteps, isOptimizationProcess) => {
  let tempLimitingCompound = null;
  processSteps.forEach(step => {
    if (tempLimitingCompound) return;
    if (isOptimizationProcess || step.isFixed) {
      tempLimitingCompound =
        step.compounds.reactants.find(reactant => reactant.isLimiting) ||
        step.compounds.reagents.find(reagent => reagent.isLimiting);
    } else {
      tempLimitingCompound =
        step.compounds.reactantGroups.find(reactant => reactant.isLimiting) ||
        step.compounds.reagentGroups.find(reagent => reagent.isLimiting);
    }
  });
  return tempLimitingCompound;
};

export const mapCopiedVials = (vials, mappedData) => {
  const copiedVials = vials.reduce((accum, vial) => {
    const vialIndex = mappedData.findIndex(v => v.name === vial.ancestor);
    const currentCount = accum[vialIndex];
    if (!currentCount) {
      return {
        ...accum,
        [vialIndex]: 1,
      };
    }
    return {
      ...accum,
      [vialIndex]: currentCount + 1,
    };
  }, {});
  return copiedVials;
};

export const getVialCopiesMapping = data => {
  if (!data) return {};
  const parentVials = {};
  const copiedVialsMapping = data.reduce((copyMapping, vial) => {
    if (!parentVials[vial.ancestor]) {
      parentVials[vial.ancestor] = vial.name;
      return copyMapping;
    }
    return {
      ...copyMapping,
      [vial.name]: parentVials[vial.ancestor],
    };
  }, {});
  return copiedVialsMapping;
};
