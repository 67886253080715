import React from 'react';
import './style.scss';
import { Checkbox as AntdCheckbox } from 'antd';
import cn from 'classnames';

export const Checkbox = ({ children, onChange = () => {}, value, className, field, ...rest }) => {
  return (
    <AntdCheckbox
      onChange={e => {
        onChange(e.target.checked, field);
      }}
      checked={value}
      {...rest}
      className={cn('checkbox-wrapper', className)}
    >
      {children}
    </AntdCheckbox>
  );
};
