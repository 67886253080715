import React from 'react';
import { Popup } from '../../Common';
import { useDispatch } from 'react-redux';
import { navigateTo } from '../../../store/common/common.actions';

export const ExperimentFinalizationPopup = ({ open, experimentKey, close, completeExperiment }) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    completeExperiment().then(() => {
      dispatch(navigateTo(`/execution?key=${experimentKey}&tab=history`));
      close();
    });
  };

  const onCancel = () => {
    completeExperiment().then(() => {
      dispatch(navigateTo('/execution'));
      close();
    });
  };

  return (
    <Popup
      open={open}
      title="Experiment results"
      handleSubmit={onSubmit}
      textSubmit="Yes"
      textCancle="No"
      handleCancel={onCancel}
    >
      <p>Do you want to navigate to experiment results screen to append the results?</p>
    </Popup>
  );
};
