import './style.scss';

import React, { useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { decodeURIComponentSafe, formatSecondsToMinsHours } from 'utils';

import { dateMMDDYYTime, DEVICES, EXPERIMENT_STATUSES } from '../../constants';
import { getDetailsUser } from '../../store/common/common.actions';
import { createNote, updateNote } from '../../store/experiment/experiment.actions';
import { Avatar, Button, Input, openNotification, Popup, StatusLabel } from '../Common';
import { PageContainer } from '../PdfGenerator/PageContainer';
import { TitleContainer } from '../PdfGenerator/TitleContainer';

const ConvertComment = ({ data }) => <span className="comment">{decodeURIComponentSafe(data)}</span>;

const Note = ({ data, deleteNote, showDelete = true }) => {
  const timeStamp = formatSecondsToMinsHours(data.experimentTimestamp);
  return (
    <div
      data-pdf-node
      className={cn('experiment-notes-list_note', { 'experiment-notes-list_note_deleted': data.isDeleted })}
    >
      <div className="experiment-notes-list_note_name">
        <Avatar size={24}>
          {`${data?.createdBy.firstName} ${data?.createdBy.lastName}`.match(/\b(\w)/g)?.join('')}
        </Avatar>
        <div className="name-user">{`${data?.createdBy.firstName} ${data?.createdBy.lastName}`}</div>
      </div>
      <div className="experiment-notes-list_note_date">{moment(data.createdAt).format(dateMMDDYYTime)}</div>
      {!!data.experimentTimestamp &&
        (data.status === EXPERIMENT_STATUSES.InProgress ||
          data.status === EXPERIMENT_STATUSES.Paused.value ||
          data.status === EXPERIMENT_STATUSES.InProgress.value ||
          data.status === EXPERIMENT_STATUSES.PendingFinalization.value ||
          (data.status === EXPERIMENT_STATUSES.Finalization.value && data?.process?.device === DEVICES.AUTOSYN)) && (
          <div className="experiment-notes-list_note_date">
            {`Timestamp: ${timeStamp.hours > 9 ? '' : '0'}${timeStamp.hours}:${timeStamp.min > 9 ? '' : '0'}${
              timeStamp.min
            }:${timeStamp.sec > 9 ? '' : '0'}${timeStamp.sec}`}
          </div>
        )}
      {!!data.reason && (
        <div className="experiment-notes-list_note_reason">
          Rejection reason:
          <ConvertComment data={data.reason} />
        </div>
      )}
      <div className="experiment-notes-list_note_comment">
        <ConvertComment data={data.body} />
      </div>
      <div className="experiment-notes-list_note_status">
        {data?.status && (
          <StatusLabel type={data?.status === EXPERIMENT_STATUSES.Draft.value ? 'passive' : 'active'}>
            {data?.status}
          </StatusLabel>
        )}
        {showDelete && (
          <div className="experiment-notes-list_note_delete-block">
            {data.isDeleted ? 'This note was deleted' : <DeleteOutlined className="delete-btn" onClick={deleteNote} />}
          </div>
        )}
      </div>
    </div>
  );
};

const maxLengthInput = 256;
const Notes = ({ notes, createNote, dataExperiment, updateNote, updateNotesList, user, getDetailsUser, pdfMode }) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [errorNote, setErrorNote] = useState('');
  const [selectedNote, setSelectedNote] = useState(null);
  const [confirmationDeleteOpen, setConfirmationDeleteOpen] = useState(false);

  const sendNote = () => {
    if (!comment.trim().length || comment.trim().length < 1) {
      setErrorNote('Please provide your notes first');
    } else if (comment.trim().length > 1000) {
      setErrorNote('Ensure this field has no more than 1000 characters.');
    } else {
      setLoading(true);
      createNote({ body: encodeURIComponent(comment), experiment: dataExperiment }).then(() => {
        setComment('');
        openNotification('New note was successfully added');
        setLoading(false);
        updateNotesList({ experimentId: dataExperiment });
      });
    }
  };

  const changeComment = value => {
    setComment(value);
    setErrorNote(false);
  };

  const deleteNote = data => {
    setSelectedNote(data);
    setConfirmationDeleteOpen(true);
  };

  const confirmDeleteNote = () => {
    setLoading(true);
    updateNote({ isDeleted: true, uuid: selectedNote.uuid }).then(() => {
      setConfirmationDeleteOpen(false);
      setLoading(false);
      openNotification('The note has been deleted');
      updateNotesList({ experimentId: dataExperiment });
    });
  };

  useEffect(() => {
    getDetailsUser();
  }, []);

  const renderNodes = () =>
    !!notes &&
    !!user.permissions?.execution.view_note &&
    notes
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map(data => (
        <Note
          data={data}
          deleteNote={() => deleteNote(data)}
          showDelete={
            data.createdBy.uuid === user.uuid ||
            (!!user.roles.find(i => i === 'System Administrator') && !!user.permissions?.execution.update_note)
          }
        />
      ));

  if (pdfMode && notes?.length) {
    return (
      <PageContainer noTable>
        <TitleContainer data-pdf-node>Notes</TitleContainer>
        {renderNodes()}
      </PageContainer>
    );
  }
  return (
    <>
      <div className="experiment-notes-list">
        <span className="experiment-notes-list_title">Notes</span>
        <div className="experiment-notes-list_notes">{renderNodes()}</div>
        {!!user.permissions?.execution.create_note && (
          <div>
            <Input
              allowSpecials
              withoutComma={false}
              maxLength={maxLengthInput}
              label="Leave note"
              placeholder="Type your note here"
              disabled={loading}
              value={comment}
              onChange={changeComment}
              error={errorNote}
              errorText={errorNote}
              type="textarea"
              className="experiment-notes-list_input"
            />
            <Button secondary onClick={sendNote}>
              Add note
            </Button>
          </div>
        )}
      </div>
      <Popup
        open={confirmationDeleteOpen}
        handleSubmit={confirmDeleteNote}
        title=" "
        handleCancel={() => {
          setConfirmationDeleteOpen(false);
        }}
        textSubmit="Delete"
        loading={loading}
      >
        Do you want to mark a Note as ‘deleted’?
      </Popup>
    </>
  );
};
const mapStateToProps = store => ({
  user: store.commonReducer.user,
});

export default connect(mapStateToProps, {
  createNote,
  updateNote,
  getDetailsUser,
})(Notes);
