import React, { useState } from 'react';
import { Radio } from 'antd';
import './style.scss';

export const RadioGroup = ({ options = [], setValue = () => {}, value, field, ...rest }) => {
  const onChange = e => {
    setValue(e.target.value, field);
  };
  return <Radio.Group className="radio-group" options={options} value={value} onChange={onChange} {...rest} />;
};
