import createReducer from '../createReducer';
import * as actionsTypes from './experimentCreation.actionTypes';

const initialState = {
  isModalOpen: false,
  project: null,
  process: null,
  steps: [],
  processData: null,
  experimentInfoLoading: false,
  experimentCreationLoading: false,
  calculationLoading: false,
  showProjectForm: false,
  experiment: null,
};

const setModalOpen = (state, data) => ({
  ...state,
  isModalOpen: true,
  project: data?.payload?.project || null,
  steps: data?.payload?.steps || [],
  process: data?.payload?.process || null,
  processData: data?.payload?.processData || null,
  showProjectForm: !data?.payload?.process,
});

const setEditExperimentModalOpen = (state, data) => ({
  ...state,
  isModalOpen: true,
  project: data?.payload?.experiment?.process?.project || null,
  steps: data?.payload?.steps || [],
  process: data?.payload?.isSynjet
    ? { process: data?.payload?.experiment?.process || null }
    : data?.payload?.experiment?.process || null,
  experiment: data?.payload?.experiment || null,
  processData: data?.payload?.processData || null,
});

const setProcess = (state, data) => ({
  ...state,
  steps: data?.payload?.steps || [],
  process: data?.payload?.process || null,
  processData: data?.payload?.processData || null,
});

const setExperimentInfoLoading = (state, data) => ({
  ...state,
  experimentInfoLoading: data.payload,
});

const setExperimentCreationLoading = (state, data) => ({
  ...state,
  experimentCreationLoading: data.payload,
});

const setCalculationLoading = (state, data) => ({
  ...state,
  calculationLoading: data.payload,
});

const setModalClose = state => ({
  ...state,
  isModalOpen: false,
  project: null,
  process: null,
  errors: [],
  experiment: null,
  steps: [],
  processData: null,
  showProjectForm: false,
});

const createExperimentReducer = createReducer(initialState, {
  [actionsTypes.SET_MODAL_OPEN]: setModalOpen,
  [actionsTypes.SET_EDIT_EXPERIMENT_MODAL_OPEN]: setEditExperimentModalOpen,
  [actionsTypes.SET_MODAL_CLOSE]: setModalClose,
  [actionsTypes.SET_EXPERIMENT_INFO_LOADING]: setExperimentInfoLoading,
  [actionsTypes.SET_EXPERIMENT_CREATION_LOADING]: setExperimentCreationLoading,
  [actionsTypes.SET_CALCULATION_LOADING]: setCalculationLoading,
  [actionsTypes.SET_PROCESS]: setProcess,
});

export default createExperimentReducer;
