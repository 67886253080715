import React from 'react';
import { Tabs as AntdTabs } from 'antd';
import './style.scss';
import cn from 'classnames';

const { TabPane } = AntdTabs;

export const Tabs = ({ activeKey, options = [], onChange, children, className }) => {
  return (
    <>
      <AntdTabs activeKey={activeKey} onChange={onChange} className={cn('tabs-container', className)}>
        {options.map(i => (
          <TabPane tab={i.label} key={i.value} />
        ))}
      </AntdTabs>
      {children}
    </>
  );
};
