import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { MassOfInterestTable } from './MassOfInterestTable';
import moment from 'moment';
import { Popup } from '../Common';

export const AnalyticalSettings = ({ analyticalData, handleAnalyticalDataChange, errors, setErrors, loading }) => {
  const [data, setData] = useState(analyticalData);
  const [dataCreated, setDataCreated] = useState(null);
  const [confirmResetToDefault, setConfirmResetToDefault] = useState(false);
  const [newCompounds, setNewCompounds] = useState([]);

  useEffect(() => {
    setData(analyticalData);
  }, [analyticalData]);

  useEffect(() => {
    handleAnalyticalDataChange([...data, ...newCompounds]);
  }, [data, newCompounds]);

  const addCompound = useCallback(() => {
    setDataCreated({ compound: null });
  }, []);

  const addUnknownStructure = () => {
    setNewCompounds([
      ...newCompounds,
      {
        compound: null,
        type: 'Side Product',
        label: '',
        mass: 0,
        mol: 1,
        observ: 0,
        interval_start: '0',
        interval_end: '4.00',
      },
    ]);
  };

  const updateNewCompound = compound => {
    setDataCreated({ ...dataCreated, compound: compound });
  };

  const clearDataCreated = useCallback(() => setDataCreated(null), []);

  const confirmAdding = () => {
    if (dataCreated?.compound) {
      setNewCompounds([
        ...newCompounds,
        {
          compound: dataCreated.compound,
          type: 'Side Product',
          label: dataCreated.compound.compound_name || dataCreated.compound.smiles,
          mass: dataCreated.compound.exactmolwt,
          mol: 1,
          observ: dataCreated.compound.exactmolwt + 1,
          interval_start: '0',
          interval_end: '4.00',
        },
      ]);
    }
    setDataCreated(null);
  };

  const resetToDefault = () => {
    setConfirmResetToDefault(false);
    setErrors([]);
    setDataCreated(null);
    setData(analyticalData);
    setNewCompounds([]);
  };

  const deleteItem = idx => {
    setErrors([]);
    const isELFromNew = data.length - 1 < idx;
    const offsetIdx = idx - data.length;
    if (isELFromNew) setNewCompounds(newCompounds.filter((i, index) => index !== offsetIdx));
    else setData(data.filter((i, index) => index !== idx));
  };

  const updateFieldOnItem = (value, field, idx) => {
    const changeUnique = field === 'interval_start' || field === 'interval_end' || field === 'mol';
    const changeObserved = field === 'mol';
    const updatedErrors = errors
      ? errors.map((error, errorIndex) =>
          errorIndex === idx
            ? {
                ...error,
                [field]: null,
                uniqueError: changeUnique ? false : error.uniqueError,
                observ: changeObserved ? false : error.observ,
              }
            : error
        )
      : [];

    setErrors(updatedErrors);
    const isELFromNew = data.length - 1 < idx;
    const offsetIdx = idx - data.length;
    const elData = isELFromNew ? { ...newCompounds[offsetIdx], [field]: value } : { ...data[idx], [field]: value };
    if (isELFromNew) setNewCompounds(newCompounds.map((i, index) => (index === offsetIdx ? elData : i)));
    else setData(data.map((i, index) => (index === idx ? elData : i)));
  };
  return (
    <>
      <MassOfInterestTable
        tableData={data}
        deleteItem={deleteItem}
        updateFieldOnItem={updateFieldOnItem}
        addCompound={addCompound}
        addUnknownStructure={addUnknownStructure}
        resetToDefault={() => setConfirmResetToDefault(true)}
        dataCreated={dataCreated}
        updateNewCompound={updateNewCompound}
        confirmAdding={confirmAdding}
        clearDataCreated={clearDataCreated}
        newCompounds={newCompounds}
        errors={errors}
        loading={loading}
      />
      <Popup
        open={confirmResetToDefault}
        title="Warning"
        textSubmit={'Yes'}
        handleSubmit={resetToDefault}
        handleCancel={() => setConfirmResetToDefault(false)}
      >
        <div>Do you want to reset compound masses of interest to default?</div>
      </Popup>
    </>
  );
};
