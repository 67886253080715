import React from 'react';
import cn from 'classnames';

export const Liquid = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.08163 19.918H0V17.918H4.08163V19.918Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.041 0.877352V4.95898H18.041V0.877352H20.041Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.082 32.9184V37H18.082V32.9184H20.082Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6H6L6 32H32V6ZM6 4C4.89543 4 4 4.89543 4 6V32C4 33.1046 4.89543 34 6 34H32C33.1046 34 34 33.1046 34 32V6C34 4.89543 33.1046 4 32 4H6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29304 32.293C4.90252 31.9025 4.90252 31.2694 5.29304 30.8788L6.59306 29.5788C6.98358 29.1883 7.61675 29.1883 8.00727 29.5788C8.3978 29.9693 8.3978 30.6025 8.00727 30.993L6.70726 32.293C6.31673 32.6836 5.68357 32.6836 5.29304 32.293ZM9.19309 28.393C8.80257 28.0025 8.80257 27.3693 9.19309 26.9788L11.7931 24.3788C12.1836 23.9882 12.8168 23.9882 13.2073 24.3788C13.5979 24.7693 13.5979 25.4024 13.2073 25.793L10.6073 28.393C10.2168 28.7835 9.58362 28.7835 9.19309 28.393ZM14.3932 23.1929C14.0026 22.8024 14.0026 22.1692 14.3932 21.7787L16.9932 19.1787C17.3837 18.7882 18.0169 18.7882 18.4074 19.1787C18.7979 19.5692 18.7979 20.2024 18.4074 20.5929L15.8074 23.1929C15.4168 23.5835 14.7837 23.5835 14.3932 23.1929ZM19.5932 17.9929C19.2027 17.6023 19.2027 16.9692 19.5932 16.5787L22.1933 13.9786C22.5838 13.5881 23.2169 13.5881 23.6075 13.9786C23.998 14.3691 23.998 15.0023 23.6075 15.3928L21.0074 17.9929C20.6169 18.3834 19.9837 18.3834 19.5932 17.9929ZM24.7933 12.7928C24.4028 12.4023 24.4028 11.7691 24.7933 11.3786L27.3933 8.77856C27.7838 8.38804 28.417 8.38804 28.8075 8.77856C29.1981 9.16909 29.1981 9.80225 28.8075 10.1928L26.2075 12.7928C25.817 13.1833 25.1838 13.1833 24.7933 12.7928ZM29.9933 7.59274C29.6028 7.20222 29.6028 6.56905 29.9933 6.17853L31.2934 4.87851C31.6839 4.48799 32.317 4.48799 32.7076 4.87851C33.0981 5.26904 33.0981 5.9022 32.7076 6.29273L31.4076 7.59274C31.017 7.98327 30.3839 7.98327 29.9933 7.59274Z"
        fill="white"
      />
    </svg>
  </div>
);
