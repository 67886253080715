import './style.scss';

import React, { useEffect, useState } from 'react';

import { MoveToBatchPopup } from 'components/BatchDetails/MoveToBatchPopup';
import { RemoveFromBatchPopup } from 'components/BatchDetails/RemoveFromBatchPopup';
import { HeaderInfo } from 'components/ExperimentDetailsComponents/HeaderInfo';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setExperimentEditModalOpen } from 'store/experimentCreation/experimentCreation.action';
import { getProcess as getSynjetProcess, resetSynJetStore } from 'store/synjet/synjet.actions';

import { DEVICES, EXPERIMENT_STATUSES, modeDetails, modeSched, statuses_execution } from '../../constants';
import {
  getAvailableAutoSyns,
  getExperiment,
  getNotes,
  prepareExperiment,
  setRouteStructureFromExp,
  submitExperiment,
} from '../../store/experiment/experiment.actions';
import { getReactorsTypes } from '../../store/processBuilder/processbuilder.actions';
import { reactors } from '../../store/processBuilder/processbuilder.selector';
import { manageBatch, manageBatchStartPreparation } from '../../store/scheduling/scheduling.actions';
import { parseProcessBuilderData, showExpTime } from '../../utils';
import history from '../../utils/history';
import { useQuery } from '../../utils/hooks/hooks';
import { parseProcessBuilderDataFromBackend } from '../../utils/manualProcessBuilder';
import { parseExpToRouteAutoSyn } from '../../utils/parseExpToRoute';
import { parseExpLabToRoute } from '../../utils/parseRoute/parseExpLabToRoute';
import { parseExpToRouteSynJetPro } from '../../utils/parseRoute/parseSynjetProRoute';
import { parseExpToRouteSynJet } from '../../utils/parseRoute/parseSynjetRoute';
import { parseProcessDataFromBackend } from '../../utils/synjetHelpers';
import { RejectExperimentFromBatchPopup } from '../BatchDetails/RejectExperimentFromBatchPopup';
import { Button, openNotification, Popup, Spinner } from '../Common';
import { Tabs } from '../Common/Tabs';
import { PopupSubmitExperiment } from '../Execution/PopupSubmitExperiment/PopupSubmitExperiment';
import { CreateExperimentModal } from '../index';
import { PdfGenerator } from '../PdfGenerator';
import { AnalyticalTab } from './AnalyticalTab';
import { DeclineExperimentPopup } from './DeclineExperimentPopup';
import { Info } from './Info';
import Notes from './Notes';
import { ProcessTab } from './ProcessTab';
import { getReactionsForManual } from './ReactionTab/getReactionsForManual';
import { ReactionTab } from './ReactionTab/ReactionTab';
import { ResultsTab } from './ResultsTab';
import { RoutesTab } from './Routes/Routes';

export { default as CreateExperimentModal } from './CreateExperiment/CreateExperimentModal';

const OPTIONS_TABS = [
  { value: 'results', label: 'Results' },
  { value: 'reactions', label: 'Reactions' },
  { value: 'process', label: 'Process' },
  { value: 'info', label: 'Experiment info' },
  { value: 'analytical', label: 'Online analysis' },
  { value: 'notes', label: 'Notes (0)' },
];

const SYNJET_OPTIONS_TABS = [
  { value: 'results', label: 'Results' },
  { value: 'process', label: 'Process' },
  { value: 'info', label: 'Experiment info' },
  { value: 'analytical', label: 'Online analysis' },
  { value: 'routes', label: 'Route(s)' },
  { value: 'notes', label: 'Notes (0)' },
];

export const Experiment = ({
  experimentKey,
  getNotes,
  notes,
  setModeCalendar,
  mode,
  handleExperimentReject,
  user,
  isSynJet,
  isSynJetPro,
  navigateToFirstExperiment,
  getListExperiments = () => {},
  setViewBatch,
  setSideLoader,
  navigateToFirstBatch,
}) => {
  const [tabs, setTabs] = useState(isSynJet ? SYNJET_OPTIONS_TABS : OPTIONS_TABS);
  const [tab, setTab] = useState(isSynJet ? SYNJET_OPTIONS_TABS[0].value : OPTIONS_TABS[0].value);
  const [definition, setDefinition] = useState(null);
  const [commonInfo, setCommonInfo] = useState({});
  const [declineExperimentPopupOpen, setDeclineExperimentPopupOpen] = useState(false);
  const [confirmExperimentPopupOpen, setConfirmExperimentPopupOpen] = useState(false);
  const [openRemoveFromBatchPopup, setOpenRemoveFromBatchPopup] = useState(false);
  const [loaderSeparateExperiment, setLoaderSeparateExperiment] = useState(false);
  const [moveToBatchConfOpen, setMoveToBatchConfOpen] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [dataSynJet, setDataSynJet] = useState('');
  const [rejectBatchOpen, setRejectBatchOpen] = useState(false);
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [manualReactions, setManualReactions] = useState(null);
  const [experiment, setExperiment] = useState(null);
  const [availableEquipment, setAvailableEquipment] = useState([]);

  const dispatch = useDispatch();
  const allReactors = useSelector(reactors);
  const [numberDefinitionSteps, setNumberDefinitionSteps] = useState(0);
  const estimatedTime = showExpTime(commonInfo.totalTime);
  const actualTimeExp = showExpTime(commonInfo.actualTime);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const parentProcess = query.get('parent');
  const isSchedule = mode === modeSched;
  const showPrepareExperimentBtn =
    user?.permissions?.execution?.create_prepare_experiment && !isSchedule && commonInfo?.status !== 'Completed';
  const showRejectExperimentBtn =
    user?.permissions?.execution?.create_reject_experiment &&
    ((DEVICES.LAB !== commonInfo?.process?.device &&
      (isSchedule
        ? commonInfo?.status === 'Submitted'
        : commonInfo?.status === EXPERIMENT_STATUSES.Inpreparation.value ||
          commonInfo?.status === EXPERIMENT_STATUSES.InQueue.value)) ||
      (DEVICES.LAB === commonInfo?.process?.device &&
        (commonInfo?.status === EXPERIMENT_STATUSES.Inpreparation.value ||
          commonInfo?.status === EXPERIMENT_STATUSES.InQueue.value)));
  const showSubmitExperiment =
    user?.permissions?.execution?.submit_experiment &&
    mode === modeDetails &&
    commonInfo?.status === EXPERIMENT_STATUSES.Draft.value;
  const showEditExperiment =
    user?.permissions?.execution?.submit_experiment &&
    mode === modeDetails &&
    commonInfo?.status === EXPERIMENT_STATUSES.Draft.value;
  const showMoveRemoveFromBatch =
    (DEVICES.SYNJET === commonInfo?.process?.device || DEVICES.SYNJETPRO === commonInfo?.process?.device) &&
    commonInfo?.timeSlot?.batch?.uuid &&
    user?.permissions?.execution?.update_batch &&
    commonInfo?.status === 'In Queue';
  const queryTab = useQuery().get('tab');
  const queryExp = useQuery().get('key');

  const handleSubmitPreparation = async () => {
    const { errors, key } = await dispatch(prepareExperiment(experiment));
    if (errors) {
      openNotification(null, errors.map(error => error.messages).join(' '));
      setConfirmExperimentPopupOpen(false);
    } else {
      const isLabProperty = commonInfo?.timeSlot?.device?.type === DEVICES.LAB ? 'lb/' : '';
      history.push(`execution/${isLabProperty}details/${key}`);
    }
  };

  const handleStartBatchPreparation = () => {
    dispatch(manageBatchStartPreparation(commonInfo?.timeSlot?.batch?.uuid))
      .then(resp => {
        if (resp.warnings) {
          resp.warnings.forEach((warn, warnIdx) => {
            warn.messages.forEach((msg, msgIdx) => {
              openNotification('', '', null, `warning-notification-${warnIdx}-${msgIdx}`, null, msg);
            });
          });
        }
        if (DEVICES.SYNJET === commonInfo?.process?.device) {
          history.push(`execution/sj/details/${commonInfo?.timeSlot?.batch?.uuid}`);
        } else {
          history.push(`execution/sjp/details/${commonInfo?.timeSlot?.batch?.uuid}`);
        }
      })
      .catch(errors => {
        errors.forEach(error => {
          openNotification(null, error?.messages?.[0]);
        });
      })
      .finally(() => setConfirmExperimentPopupOpen(false));
  };

  useEffect(() => {
    dispatch(getReactorsTypes());
    return () => {
      dispatch(resetSynJetStore());
    };
  }, []);

  useEffect(() => {
    if (experimentKey && allReactors?.allReactors?.length) {
      const key = experimentKey || queryExp;
      getExperimentInfo(key);
    }
  }, [experimentKey, allReactors]);

  const getBatchDetails = (key, navigate, experimentToOpen) => {
    setLoading(true);
    if (navigate) navigateToFirstExperiment(key, experimentToOpen);
    dispatch(manageBatch({ data: { key }, type: 'getBatchDetails' }))
      .then(resp => {
        setBatch(resp);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleWizardTriggering = isPending => {
    let status = commonInfo?.status;
    let key = experimentKey;
    if (commonInfo?.timeSlot?.device?.type === 'SynJet') {
      status = commonInfo?.timeSlot?.batch?.status;
      key = commonInfo?.timeSlot?.batch?.batchId;
    }
    if (statuses_execution.indexOf(status) === -1 && !isPending) {
      setConfirmExperimentPopupOpen(true);
    } else {
      const executionURLParameter = commonInfo?.timeSlot?.batch
        ? 'sj/'
        : commonInfo?.timeSlot?.device?.type === DEVICES.LAB
        ? 'lb/'
        : '';
      history.push(`execution/${executionURLParameter}details/${key}`);
    }
  };

  const getExperimentInfo = experiment => {
    setLoaderSeparateExperiment(true);
    dispatch(getExperiment(experiment, false))
      .then(data => {
        if (!data?.uuid) {
          setViewBatch(true);
          return;
        }
        const experimentUUID = data.uuid;
        setExperiment(experimentUUID);
        if (data.status === 'Completed' && !data.route) {
          if (DEVICES.AUTOSYN === data?.process?.device) {
            dispatch(parseExpToRouteAutoSyn({ experiment: data })).then(resp =>
              setRouteStructureFromExp({
                uuid: experimentUUID,
                data: JSON.stringify(JSON.stringify(resp)),
              })
            );
          } else if (DEVICES.LAB === data?.process?.device) {
            setRouteStructureFromExp({
              uuid: experimentUUID,
              data: JSON.stringify(JSON.stringify(parseExpLabToRoute({ experiment: data }))),
            });
          }
        }

        if (
          data.status === 'Completed' &&
          !data.route &&
          (DEVICES.SYNJET === data?.process?.device || DEVICES.SYNJETPRO === data?.process?.device)
        ) {
          const sendData = JSON.parse(data.reactionsInfo);
          const parsedRoute =
            DEVICES.SYNJET === data?.process?.device
              ? parseExpToRouteSynJet(sendData, data.key)
              : parseExpToRouteSynJetPro(sendData, data.key);
          setRouteStructureFromExp({
            uuid: experimentUUID,
            data: JSON.stringify(JSON.stringify(parsedRoute)),
          }).then(() => {
            getExperimentInfo(experiment);
          });
        }

        if (DEVICES.SYNJET === data?.process?.device || DEVICES.SYNJETPRO === data?.process?.device) {
          dispatch(getSynjetProcess(data?.process?.uuid, true, { process: data.process }));
        }
        setCommonInfo(data);
        updateNotesList({ experimentId: data?.uuid }, data);
        if (data?.process?.definition) {
          setNumberDefinitionSteps(JSON.parse(data?.process?.definition).process_steps.length);
          if (DEVICES.AUTOSYN === data?.process?.device) {
            const o = parseProcessBuilderData(JSON.parse(data?.process?.definition), allReactors, data.process?.type);
            setDefinition(
              parseProcessBuilderData(JSON.parse(data?.process?.definition), allReactors, data.process?.type)
            );
          } else if (DEVICES.LAB === data?.process?.device) {
            const { parsedData, description } = parseProcessBuilderDataFromBackend(
              data?.process?.definition,
              data.process?.type
            );
            setDefinition(parsedData);
            setDescription(description);
            const u = getReactionsForManual(parsedData, data?.process?.type, data?.reactionsInfo, data?.targetProducts);
            setManualReactions(u);
          } else {
            const { processSteps, steps } = parseProcessDataFromBackend(
              JSON.parse(data?.reactionsInfo),
              data?.process?.variableStep
            );
            const dataReactionsInfo = {
              processSteps,
              steps,
              process: { process: data?.process },
              experimentID: data.uuid,
            };
            setDataSynJet(dataReactionsInfo);
          }
          if (!!data.timeSlot && !!data.timeSlot.batch) getBatchDetails(data?.timeSlot?.batch?.batchId);
        } else {
          setDefinition(null);
        }
      })
      .finally(() => {
        setLoaderSeparateExperiment(false);
      });
  };

  useEffect(() => {
    if (queryTab && tabs.find(tab => tab.value === queryTab)) {
      setTab(queryTab);
    } else {
      setTab(tabs[0].value);
    }
  }, [tabs.length]);

  const updateNotesList = (body, allData = commonInfo) => {
    getNotes(body).then(resp => {
      let t = [
        ...(DEVICES.SYNJET === allData?.process?.device || DEVICES.SYNJETPRO === allData?.process?.device
          ? SYNJET_OPTIONS_TABS
          : OPTIONS_TABS),
      ];
      let i = 0;
      t.forEach((el, idx) => {
        if (el.value === 'notes') {
          i = idx;
        }
      });
      t[i].label = `${t[i].label.replace(/ *\([^)]*\) */g, '')} (${resp.length})`;
      if (
        (!allData?.analyticalSetting && allData?.status !== EXPERIMENT_STATUSES.Completed.value) ||
        allData?.timeSlot?.device?.type === 'Lab'
      ) {
        t = t.filter(i => i.value !== 'analytical');
      }
      if (allData?.status !== EXPERIMENT_STATUSES.Completed.value) t = t.filter(i => i.value !== 'results');
      setTabs(t);
    });
  };

  const handleSubmitConfirmation = () => {
    handleSaveExperiment(true).finally(() => {
      setSubmitModalOpen(false);
    });
  };

  const handleSaveExperiment = async () => {
    const { errors, uuid } = await dispatch(submitExperiment(experiment));
    if (errors) {
      openNotification(
        null,
        JSON.parse(errors)
          .map(error => error.messages)
          .join(' ')
      );
      setConfirmExperimentPopupOpen(false);
    } else {
      setConfirmExperimentPopupOpen(false);
      getExperimentInfo(experimentKey);
    }
  };

  const handleExperimentEdit = () => {
    dispatch(
      setExperimentEditModalOpen(
        commonInfo,
        DEVICES.SYNJET === commonInfo?.process?.device || DEVICES.SYNJETPRO === commonInfo?.process?.device,
        DEVICES.LAB === commonInfo?.process?.device
      )
    );
  };

  return (
    <div className="experiment-details">
      <Spinner loading={loaderSeparateExperiment}>
        <div className="experiment-details_header">
          <HeaderInfo commonInfo={commonInfo} estimatedTime={estimatedTime} />
          <Tabs className="experiment-details_header_tabs" options={tabs} onChange={setTab} activeKey={tab} />
        </div>
        <div className="experiment-details_body">
          {tab === 'reactions' &&
            (DEVICES.SYNJET === commonInfo?.process?.device ? (
              <div />
            ) : DEVICES.AUTOSYN === commonInfo?.process?.device ? (
              <ReactionTab
                key={commonInfo.uuid}
                experiment={commonInfo}
                reactionsInfo={commonInfo.reactionsInfo ? JSON.parse(commonInfo.reactionsInfo) : {}}
                processType={commonInfo.process?.type}
              />
            ) : (
              <ReactionTab
                key={commonInfo.uuid}
                experiment={commonInfo}
                reactionsInfo={manualReactions || null}
                processType={commonInfo.process?.type}
                manual
                reactionsIdx={JSON.parse(commonInfo?.reactionsInfo)}
              />
            ))}
          {tab === 'results' && (
            <ResultsTab
              data={commonInfo}
              isSynJet={DEVICES.SYNJET === commonInfo?.process?.device}
              isSynJetPro={DEVICES.SYNJETPRO === commonInfo?.process?.device}
              isLabDevice={DEVICES.LAB === commonInfo?.process?.device}
              getExperiment={key => getExperimentInfo(key)}
            />
          )}
          {tab === 'process' && (
            <ProcessTab commonInfo={commonInfo} definition={definition} description={description} />
          )}
          {tab === 'info' && (
            <Info
              dataExperiment={commonInfo}
              getExperimentInfo={getExperimentInfo}
              numberDefinitionSteps={numberDefinitionSteps}
              dataSynJet={dataSynJet}
              actualTimeExp={actualTimeExp}
            />
          )}
          {tab === 'analytical' && (
            <AnalyticalTab
              data={commonInfo?.analyticalSetting}
              experiment={commonInfo}
              allReactors={allReactors}
              batch={batch}
              isSynJet={DEVICES.SYNJET === commonInfo?.process?.device}
              isSynJetPro={DEVICES.SYNJETPRO === commonInfo?.process?.device}
            />
          )}
          {tab === 'routes' &&
            (DEVICES.SYNJET === commonInfo?.process?.device || DEVICES.SYNJETPRO === commonInfo?.process?.device) && (
              <RoutesTab routes={commonInfo?.route?.data} />
            )}
          {tab === 'notes' && <Notes notes={notes} dataExperiment={experiment} updateNotesList={updateNotesList} />}
        </div>
      </Spinner>
      <div className="experiment-details_footer">
        {isSchedule && (
          <div>
            <Button secondary onClick={() => setModeCalendar(true)}>
              Cancel
            </Button>
            {showMoveRemoveFromBatch && (
              <>
                <Button secondary onClick={() => setOpenRemoveFromBatchPopup(true)} style={{ marginLeft: 8 }}>
                  Remove from batch
                </Button>
                <Button secondary onClick={() => setMoveToBatchConfOpen(true)} style={{ marginLeft: 8 }}>
                  Move to another batch
                </Button>
              </>
            )}
          </div>
        )}
        {showRejectExperimentBtn && mode !== modeDetails ? (
          <Button
            secondary
            className="reject-btn"
            onClick={() => {
              if ((isSynJet || isSynJetPro) && mode !== modeSched) setRejectBatchOpen(true);
              else setDeclineExperimentPopupOpen(true);
            }}
          >
            Reject {isSchedule && 'experiment'}
          </Button>
        ) : null}
        {mode === modeDetails && (
          <Button
            secondary
            onClick={() => {
              history.push(
                parentProcess
                  ? `/${
                      DEVICES.SYNJET === commonInfo?.process?.device
                        ? 'synjet'
                        : DEVICES.SYNJETPRO === commonInfo?.process?.device
                        ? 'synjet_pro'
                        : DEVICES.LAB === commonInfo?.process?.device
                        ? 'lab'
                        : 'autosyn'
                    }/process-builder/details/${parentProcess}`
                  : '/experiments'
              );
            }}
          >
            Close
          </Button>
        )}
        {DEVICES.SYNJET !== commonInfo?.process?.device &&
          DEVICES.SYNJETPRO !== commonInfo?.process?.device &&
          showPrepareExperimentBtn &&
          mode !== modeDetails && (
            <Button
              className="continue-btn"
              onClick={() => handleWizardTriggering(commonInfo?.status === 'Pending Finalization')}
            >
              {statuses_execution.indexOf(commonInfo?.status) === -1 && commonInfo?.status !== 'Pending Finalization'
                ? 'Start preparation'
                : 'Continue'}
            </Button>
          )}
        {(showEditExperiment || showSubmitExperiment) && (
          <div className="btn-container">
            {showEditExperiment && (
              <Button
                className="edit-btn"
                type="secondary"
                onClick={handleExperimentEdit}
                disabled={loaderSeparateExperiment}
              >
                Edit
              </Button>
            )}
            {showSubmitExperiment && (
              <Button
                onClick={() => {
                  const isAutoSyn = DEVICES.AUTOSYN === commonInfo?.process?.device;
                  if (isAutoSyn) {
                    setLoaderSeparateExperiment(true);
                    const validateExperimentData = {
                      processId: commonInfo?.process?.uuid,
                      analyticalType: JSON.stringify(commonInfo.analyticalType),
                    };
                    dispatch(getAvailableAutoSyns(validateExperimentData)).then(resp => {
                      setLoaderSeparateExperiment(false);
                      setAvailableEquipment(resp.availableDevices);
                      setSubmitModalOpen(true);
                    });
                  } else {
                    setSubmitModalOpen(true);
                  }
                }}
              >
                Submit
              </Button>
            )}
          </div>
        )}

        <PdfGenerator
          definition={definition}
          description={description}
          notes={notes}
          manualReactions={manualReactions}
          commonInfo={commonInfo}
          estimatedTime={estimatedTime}
          numberDefinitionSteps={numberDefinitionSteps}
          dataSynJet={dataSynJet}
          actualTimeExp={actualTimeExp}
        />
      </div>
      <DeclineExperimentPopup
        device={commonInfo?.process?.device}
        handleNavigation={handleExperimentReject}
        isSchedule={isSchedule}
        open={declineExperimentPopupOpen}
        experiment={experiment}
        closePopup={() => setDeclineExperimentPopupOpen(false)}
        isScheduled={isSchedule}
        showWarning={commonInfo?.status === EXPERIMENT_STATUSES.Submitted.value}
      />
      <Popup
        title="Start preparation"
        textSubmit="Proceed"
        open={confirmExperimentPopupOpen}
        handleSubmit={commonInfo?.timeSlot?.batch ? handleStartBatchPreparation : handleSubmitPreparation}
        handleCancel={() => setConfirmExperimentPopupOpen(false)}
      >
        Do you want to proceed with preparation of the selected {commonInfo?.timeSlot?.batch ? 'Batch' : 'Experiment'}?
      </Popup>
      <PopupSubmitExperiment
        submitModalOpen={submitModalOpen}
        handleSubmitConfirmation={handleSubmitConfirmation}
        setSubmitModalOpen={setSubmitModalOpen}
        availableEquipment={availableEquipment}
      />
      <RemoveFromBatchPopup
        experimentsLength={commonInfo?.timeSlot?.batch?.experiments.length}
        open={openRemoveFromBatchPopup}
        batch={commonInfo?.timeSlot?.batch}
        experiment={commonInfo}
        handleCancel={() => {
          setOpenRemoveFromBatchPopup(false);
        }}
        onSuccess={() => {
          setModeCalendar(true);
          getListExperiments();
        }}
      />
      <MoveToBatchPopup
        handleCancel={() => {
          setMoveToBatchConfOpen(false);
        }}
        onSuccess={() => setModeCalendar(true)}
        experiment={commonInfo}
        open={moveToBatchConfOpen}
        batch={commonInfo?.timeSlot?.batch}
      />
      <RejectExperimentFromBatchPopup
        closePopup={() => setRejectBatchOpen(false)}
        open={rejectBatchOpen}
        type="experiment"
        batch={batch}
        experiment={{ uuid: experiment }}
        navigateToFirstBatch={navigateToFirstBatch}
        getBatchDetails={getBatchDetails}
        mode={mode}
        setSideLoader={setSideLoader}
        s
      />
      <CreateExperimentModal
        isProcessBuilder
        isSynJet={DEVICES.SYNJET === commonInfo?.process?.device}
        isSynjetPro={DEVICES.SYNJETPRO === commonInfo?.process?.device}
        isLab={DEVICES.LAB === commonInfo?.process?.device}
        getExperiment={getExperimentInfo}
      />
    </div>
  );
};
const mapStateToProps = store => ({
  notes: store.experimentReducer.notes,
  user: store.commonReducer.user,
});
export default connect(mapStateToProps, {
  getNotes,
})(Experiment);
