import createReducer from '../createReducer.js';
import * as actionsTypes from './processbuilder.actionTypes';

const initialState = {
  reactors: [],
  pumpsTypes: [],
  errors: [],
  errorsCount: 0,
  warnings: [],
};

function setReactorsTypes(state, data) {
  return { ...state, reactors: data.payload };
}

function setPumpTypes(state, { data }) {
  return { ...state, pumpsTypes: data };
}

function setValidationErrors(state, { data }) {
  return { ...state, errors: data };
}

function setValidationWarnings(state, { data }) {
  return { ...state, warnings: data };
}

const processbuilderReducer = createReducer(initialState, {
  [actionsTypes.SET_REACTORS_TYPES]: setReactorsTypes,
  [actionsTypes.SET_PUMP_TYPES]: setPumpTypes,
  [actionsTypes.SET_VALIDATION_ERRORS]: setValidationErrors,
  [actionsTypes.SET_VALIDATION_WARNINGS]: setValidationWarnings,
});

export default processbuilderReducer;
