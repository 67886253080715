import React, { useState, useEffect, useCallback, useRef } from 'react';
import './style.scss';
import { Input, Popup, RadioGroup, openNotification, Button } from '..';
import { filterObj, lengthObj } from '../../utils/common';
import { transformString, validationIsEmpty, validationLength } from '../../utils/validation';
import _ from 'lodash';
import history from '../../utils/history';

const options = [
  { label: 'Drug discovery', value: 'Drug Discovery' },
  { label: 'Process development', value: 'Process Development' },
];
const initialState = {
  projectName: '',
  customer: '',
  program: '',
  type: options[0].value,
  goals: '',
};

const maxLengthInput = 256;

export const PopupCreateProject = ({
  open,
  openPopup,
  closePopup,
  setPopupDetails,
  createProject,
  defaultData = {},
}) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [nameInput, setNameInput] = useState(null);

  useEffect(() => {
    if (!defaultData.uuid) return;
    setData({
      projectName: defaultData.name,
      customer: defaultData.customer,
      program: defaultData.program,
      type: options.find(i => i.value === defaultData.type).value,
      goals: defaultData.goals,
      uuid: defaultData.uuid,
    });
  }, [defaultData.uuid]);

  const setRefFirstInput = elem => {
    if (!nameInput && elem && open && !loading) {
      elem.focus();
      setNameInput(elem);
    }
  };

  const handleCancel = useCallback(() => {
    setErrors({});
    setNameInput(null);
    if (!data.uuid) setData(initialState);
    closePopup();
  }, [data.uuid]);

  const validation = () => {
    const errorsChecking = validationIsEmpty({ projectName: data.projectName, program: data.program });

    errorsChecking.projectName = errorsChecking.projectName || validationLength(data.projectName, 'Project name');

    return filterObj(errorsChecking);
  };

  //delete for release
  const generateProjects = () => {
    //Do not change number 1
    // IF you want to create more then 2 projects, press on button
    for (var i = 0; i < 1; i++) {
      let dataGenerated = { ...data };
      dataGenerated.program = 'Generated program: ' + Math.floor(Math.random() * 1000);
      dataGenerated.name = 'Generated project: ' + Math.floor(Math.random() * 1000);
      delete dataGenerated.projectName;
      createProject(dataGenerated);
    }
  };

  const saveChanges = () => {
    const errorsChecking = validation();
    if (lengthObj(errorsChecking)) setErrors(errorsChecking);
    else {
      setLoading(true);
      const requestData = {
        ...data,
        name: encodeURIComponent(data.projectName),
        customer: encodeURIComponent(data.customer),
        program: encodeURIComponent(data.program),
        goals: encodeURIComponent(data.goals),
      };
      delete requestData.projectName;
      createProject(requestData)
        .then(resp => {
          if (resp?.errors) {
            let errorsResponse = {};
            resp.errors.map(i => (errorsResponse[i.field === 'name' ? 'projectName' : i.field] = i.messages[0]));
            setErrors(errorsResponse);
          } else {
            !data?.uuid && history.push(`/projects/details/${resp.uuid}`);
            handleCancel();
            openNotification(`The project has been successfully ${data.uuid ? 'updated' : 'created'}`);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handlerChanges = (value, field) => {
    setData({ ...data, [field]: value });
    const errorsNew = { ...errors };
    delete errorsNew[field];
    setErrors(errorsNew);
  };
  return (
    <Popup
      title={data.uuid ? 'Edit project' : 'Create project'}
      open={open}
      className="popup-create-project"
      textSubmit={data.uuid ? 'Save' : 'Create'}
      handleCancel={handleCancel}
      handleSubmit={saveChanges}
      loading={loading}
    >
      {/*delete for release*/}
      {window.location.hostname === 'localhost' && <Button onClick={generateProjects}>generateProject</Button>}
      <div className="popup-project">
        <Input
          setRef={setRefFirstInput}
          autoFocus
          maxLength={maxLengthInput}
          label="Project name"
          placeholder="Project name"
          require
          className="first-item"
          disabled={loading}
          value={data.projectName}
          onChange={handlerChanges}
          field="projectName"
          error={errors.projectName}
          errorText={errors.projectName}
          allowSpecials
        />
        <Input
          maxLength={maxLengthInput}
          label="Customer"
          disabled={loading}
          placeholder="Customer"
          value={data.customer}
          onChange={handlerChanges}
          field="customer"
          error={errors.customer}
          allowSpecials
          withoutComma={false}
        />
        <Input
          maxLength={maxLengthInput}
          label="Program"
          placeholder="Program"
          require
          disabled={loading}
          value={data.program}
          onChange={handlerChanges}
          field="program"
          error={errors.program}
          errorText={errors.program}
          allowSpecials
          withoutComma={false}
        />
        <div className="radio-group">
          <p className="title-details">
            Project type<span>*</span>
          </p>
          <RadioGroup disabled={loading} options={options} value={data.type} setValue={handlerChanges} field="type" />
        </div>
        <Input
          maxLength={1000}
          label="Project goals"
          placeholder="Describe project goals"
          type="textarea"
          rows={4}
          disabled={loading}
          field="goals"
          value={data.goals}
          onChange={handlerChanges}
          allowSpecials
          withoutComma={false}
        />
      </div>
    </Popup>
  );
};
