import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EXPERIMENT_STATUSES } from 'constants/common';
import { Spinner, StatusLabel, Popup, openNotification } from 'components/Common';
import history from 'utils/history';
import { isEqual } from 'lodash';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { parseJSONErrors } from 'utils/parseJSONErrors';
import { saveAs } from 'file-saver';
import { DEVICES } from '../../../constants/common';
import {
  Steps,
  Footer,
  SJReviseTheDetails,
  SJBatchConfiguration,
  BatchConfigurationWithExperimentsTable,
  BatchFinalization,
} from '../../../components/Execution';
import { user } from '../../../store/common/common.selector';
import '../style.scss';
import { SynJetPrepareMaterials } from '../../../components/Execution/SynJetExecution/SynJetPrepareMaterials';
import {
  getListCheckboxesStepThirdWizard,
  launchBatch,
  manageBatch,
  saveVialsSchedule,
  updateExperimentConditions,
} from '../../../store/scheduling/scheduling.actions';
import { getDevices } from '../../../store/common/common.actions';
import {
  getNotes,
  pauseBatch,
  resumeBatch,
  endBatch,
  completeBatch,
  finalizeBatch,
  finalizeExperimentOnBatch,
  setRouteStructureFromExp,
} from '../../../store/experiment/experiment.actions';
import { BatchNotesSidebar } from '../../../components/Execution/NotesSidebar/BatchNotesSidebar';
import { RejectExperimentFromBatchPopup } from '../../../components/BatchDetails/RejectExperimentFromBatchPopup';
import { getCompoundInfo, getCompoundNormalizeInfo, getExperimentTableData } from '../../../utils/execution';
import { getSynjetProcessData } from '../../../utils/synjetHelpers';
import { setBatchErrorShown } from '../../../store/synjet/synjet.actions';
import { parseExpToRouteSynJet } from '../../../utils/parseRoute/parseSynjetRoute';

const finalizationCheckList = [
  { checked: false, label: 'Place heater blocks in standby (room Temp or off)' },
  { checked: false, label: 'Clean the workspace' },
  { checked: false, label: 'Replace fluid bodies' },
  { checked: false, label: 'Dispose of waste' },
  { checked: false, label: 'Leave dispense heads in standby with appropriate solvent.' },
  { checked: false, label: 'At completion of analytical result delivery, turn off LCMS and Spinnaker.' },
  { checked: false, label: 'Set FANUC speed to 1%' },
  { checked: false, label: 'Power down SynJet hardware' },
  { checked: false, label: 'Verify/clear heater block/ conveyor belt/ Well plate of vials' },
];

const setRowTable = (dispenser, idx, batchUuid) => ({
  allData: dispenser,
  batchUuid,
  dispenserUuid: dispenser.uuid,
  order: { value: idx + 1 },
  name: {
    value: dispenser?.compound,
  },
  solvent: { value: dispenser?.solvents },
  mw: { value: dispenser?.compound?.molwt },
  concentration: { value: dispenser.concentration },
  dispenser: { backpack: dispenser.backpack, fluid_body: dispenser.fluid_body, calibration: dispenser.calibration },
  calc_volume: { value: dispenser.calculated_volume },
  calc_mass: { value: dispenser.calculated_mass },
  act_volume: { value: dispenser.actual_volume },
  act_mass: { value: dispenser.actual_mass || 0 },
  has_sent: { value: !!dispenser.actual_volume },
});

export const SynJetDetails = () => {
  const dispatch = useDispatch();
  const { key: batchKey } = useParams();
  const [batchID, setBatchId] = useState(null);
  const currentUser = useSelector(user);
  const [activeStep, setActiveStep] = useState(1);
  const [batch, setBatch] = useState(null);
  const [deviceId, setDeviceId] = useState('');
  const [batchLoading, setBatchLoading] = useState(false);
  const [experiments, setExperements] = useState([]);
  const [showNotesSidebar, setShowNotesSidebar] = useState(false);
  const [showWarning, setShowWarning] = useState(null);
  const [checkboxesOnBatchConfigStep, setcheckboxesOnBatchConfigStep] = useState({ allChecked: 0, generated: false });
  const [confirmOnBatchConfigStep, setconfirmOnBatchConfigStep] = useState(false);
  const [rejectBatchOpen, setRejectBatchOpen] = useState(false);
  const [rejectWarningOpen, setRejectWarningOpen] = useState(false);
  const [type, setType] = useState('batch');
  const [openPauseConfirmation, setOpenPauseConfirmation] = useState(false);
  const [openEndConfirmation, setOpenEndConfirmation] = useState(false);
  const [openCompleteConfirmation, setOpenCompleteConfirmation] = useState(false);
  const [openFinalizeConfirmation, setOpenFinalizeConfirmation] = useState(false);
  const [notReceivedAnalytics, setNotReceivedAnalytics] = useState([]);
  const [finalizeExperimentActionOnClick, setFinalizeExperimentAction] = useState(false);

  const [finalizationOptions, setFinalizationOptions] = useState([...finalizationCheckList]);
  const [finalizationPopupOpen, setFinalizationPopupOpen] = useState(false);

  const [rejectItem, setRejectItem] = useState(null);
  const [experimentsInfo, setExperimentsInfo] = useState([]);
  const [initialExperimentsInfo, setInitialExperimentsInfo] = useState([]);
  const [dispensingOptions, setDispensingOptions] = useState([]);

  const [dispenserErrors, setDispenserErrors] = useState([]);

  // COMMON STATE
  const [loading, setLoading] = useState(false);
  const [connectivitySubject, setConnectivitySubject] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);

  const [errors, setErrors] = useState([]);
  const [edited, setEdited] = useState(false);
  // COMMON STATE
  // STEP 3 STATE
  const [tableData, setTableData] = useState([]);
  const [listCheckboxes, setListCheckboxes] = useState([]);
  // STEP 3 STATE
  const isPermittedDownloadDatFile = currentUser?.permissions?.execution?.download_dat_files;

  const setTableDataThirdStep = () => {
    setTableData(
      dispensingOptions.map((i, idx) => {
        let compound = null;
        const func = (j, id) => {
          if (j.reactionsInfo) {
            const compoundData = JSON.parse(j.reactionsInfo).compounds.find(k => k.compound_id === id);
            if (compoundData) {
              compound = compoundData;
              return true;
            }
          }
        };
        batch.experiments.find(a => func(a, i.compound_id));

        const data = {
          ...i,
          compound: { ...compound },
        };
        data.solvents = i.solvents.map(l => {
          batch.experiments.find(a => func(a, l.compound_id));
          return { ...l, compound: { ...compound } };
        });
        return setRowTable(data, idx, batch.uuid);
      })
    );
  };

  useEffect(() => {
    if (batch && batch?.experiments) {
      const experiments = batch?.experiments.map(exp => getExperimentTableData(exp));
      setExperimentsInfo(experiments);
      setInitialExperimentsInfo(experiments);
      if (batch?.dispenserStructure) {
        setDispensingOptions(JSON.parse(batch?.dispenserStructure));
      }
    }
  }, [batch]);

  useEffect(() => {
    if (!!dispensingOptions.length && batch?.step >= 2) {
      setTableDataThirdStep();
    }
  }, [dispensingOptions]);

  const handleRejectConfirm = () => {
    setRejectWarningOpen(false);
    setRejectBatchOpen(true);
  };

  const dropdownMenu = [
    ...(batch?.experiments
      ? batch?.experiments.map(experiment => ({
          name: `Reject Experiment ${experiment.name}`,
          onClick: () => {
            setRejectWarningOpen(true);
            setType('experiment');
            setRejectItem(experiment);
          },
        }))
      : []),
    {
      name: 'Reject batch',
      onClick: () => {
        setRejectWarningOpen(true);
        setType('batch');
      },
    },
  ];

  const getDetails = (skipStepNavigation = false) => {
    setBatchLoading(true);
    dispatch(manageBatch({ data: { key: batchKey }, type: 'getBatchDetails' }))
      .then(response => {
        dispatch(getNotes({ experimentId: response.uuid }));
        setBatch(response);
        setBatchId(response.uuid);
        setExperements(response.experiments);
        setDeviceId(response.timeSlot.device.uuid);
        if (response.step && !skipStepNavigation) setActiveStep(response.step + 1);
        else setActiveStep(1);
        manageExperimentRoutes(response?.experiments);
      })
      .finally(() => setBatchLoading(false));

    getListCheckboxesStepThirdWizard().then(resp => {
      setListCheckboxes(resp);
    });
  };

  const manageExperimentRoutes = experiments => {
    if (!experiments) {
      return;
    }
    experiments.forEach(experiment => {
      if (experiment.status === 'Completed' && !experiment.route) {
        const parsedRoute = parseExpToRouteSynJet(JSON.parse(experiment.reactionsInfo), experiment.key);
        setRouteStructureFromExp({
          uuid: experiment,
          data: JSON.stringify(JSON.stringify(parsedRoute)),
        });
      }
    });
  };

  useEffect(() => {
    getDetails();
  }, [batchKey]);

  const handleUpdateExperimentConditions = updatedExperiments => {
    setDispenserErrors([]);
    dispatch(
      updateExperimentConditions({
        batchID,
        experiments: updatedExperiments.length ? updatedExperiments : null,
      })
    )
      .then(resp => {
        if (resp?.warnings) {
          resp.warnings.forEach((warn, warnIdx) => {
            warn.messages.forEach((msg, msgIdx) => {
              openNotification('', '', null, `warning-notification-${warnIdx}${msgIdx}`, null, msg);
            });
          });
        }
        getDetails();
        setActiveStep(2);
      })
      .catch(errors => {
        if (typeof errors === 'string') {
          openNotification(null, errors);
        } else {
          const msgs = [];
          parseJSONErrors(JSON.stringify(errors)).forEach(msg => {
            if (msg.match(/\[\S+\]/gm)?.length) msgs.push(msg);
            else openNotification(null, msg);
          });
          setDispenserErrors(msgs);
        }
      })
      .finally(() => setShowWarning(null));
  };

  const onContinue = () => {
    if (activeStep === 1) {
      const updatedConditions = experimentsInfo.filter(
        (experiment, experimentIndex) => !isEqual(experiment, initialExperimentsInfo?.[experimentIndex])
      );
      const updatedExperiments = updatedConditions.map(condition => ({
        experiment: condition.experimentID,
        reactionsInfo: getSynjetProcessData({
          ...condition,
          processData: { process_steps: condition?.processSteps },
        }),
      }));
      if (batch.step && updatedExperiments.length) {
        setShowWarning(updatedExperiments);
      } else {
        handleUpdateExperimentConditions(updatedExperiments);
      }
    } else if (activeStep === 2) {
      if (!checkboxesOnBatchConfigStep.allChecked) {
        setconfirmOnBatchConfigStep(true);
      } else goFrom2ndTo3rdStep();
    } else if (activeStep === 3) {
      goFrom3ndTo4rdStep();
    }
  };

  const goFrom2ndTo3rdStep = () => {
    setBatchLoading(true);
    setconfirmOnBatchConfigStep(false);
    dispatch(saveVialsSchedule(batchID))
      .then(resp => {
        if (resp.warnings) {
          resp.warnings.forEach(err => {
            err.messages.forEach(msg => {
              openNotification(null, null, null, null, null, msg);
            });
          });
        }
        getDetails();
        setActiveStep(3);
      })
      .finally(() => {
        setBatchLoading(false);
      });
  };

  const goFrom3ndTo4rdStep = () => {
    setBatchLoading(true);
    setconfirmOnBatchConfigStep(false);
    dispatch(launchBatch(batchID))
      .then(() => {
        getDetails();
        setActiveStep(4);
      })
      .finally(() => {
        setBatchLoading(false);
      });
  };

  const pauseResumeBatchAction = () => {
    setBatchLoading(true);
    const func = batch.status === EXPERIMENT_STATUSES.InProgress.value ? pauseBatch : resumeBatch;
    dispatch(func(batchID)).then(resp => {
      if (resp.errors) handleError(resp.errors);
      else getDetails();
      setOpenPauseConfirmation(false);
    });
  };

  const endBatchAction = () => {
    setBatchLoading(true);
    dispatch(setBatchErrorShown(true));
    dispatch(endBatch(batchID)).then(resp => {
      if (resp.errors) {
        handleError(resp.errors);
        dispatch(setBatchErrorShown(false));
      } else getDetails();
      setOpenEndConfirmation(false);
    });
  };

  const completeBatchAction = (goToAppendResults = false) => {
    setBatchLoading(true);
    dispatch(completeBatch(batchID)).then(resp => {
      setOpenCompleteConfirmation(false);
      if (resp.errors) handleError(resp.errors);
      else if (goToAppendResults) {
        history.push(
          `/execution?key=${batch.experiments[0]?.key}&tab=history&isBatch=false&deviceId=${batch?.timeSlot?.device?.uuid}`
        );
      } else history.push('/execution');
    });
  };

  const finalizeBatchConfirmation = (experiment = null) => {
    // check if some analytics does not receive yet
    const experimentsInProgress = batch.experiments.filter(exp => exp.status === EXPERIMENT_STATUSES.InProgress.value);
    setFinalizeExperimentAction(experiment);
    if (!!experimentsInProgress.length || experiment?.name) {
      setNotReceivedAnalytics(experiment?.name ? [experiment] : experimentsInProgress);
      setOpenFinalizeConfirmation(true);
    } else {
      setNotReceivedAnalytics([]);
      if (experiment?.name) finalizeExperimentAction(experiment);
      else finalizeBatchAction();
    }
  };

  const finalizeBatchAction = () => {
    setBatchLoading(true);
    dispatch(finalizeBatch(batchID)).then(resp => {
      if (resp.errors) handleError(resp.errors);
      else getDetails();
      setOpenFinalizeConfirmation(false);
    });
  };

  const finalizeExperimentAction = experiment => {
    setBatchLoading(true);
    dispatch(finalizeExperimentOnBatch(experiment?.uuid)).then(resp => {
      if (resp.errors) handleError(resp.errors);
      else getDetails();
      setOpenFinalizeConfirmation(false);
    });
  };

  const navigateToFirstBatch = () => {
    setBatchLoading(true);
    dispatch(getDevices(true)).then(d => {
      const device = d.find(i => i.type === DEVICES.SYNJET).uuid;
      dispatch(manageBatch({ data: { device }, type: 'getBatchForAssign' }))
        .then(resp => {
          history.push(`/execution?key=${resp[0].batchId}&tab=scheduled&isBatch=true&deviceId=${deviceId}`);
        })
        .finally(() => {
          setBatchLoading(false);
        });
    });
  };

  const handleError = errors => {
    setBatchLoading(false);
    errors.forEach(error => {
      error.messages.forEach(msg => openNotification(null, msg));
    });
  };
  let disableContinue = (activeStep === 2 && !checkboxesOnBatchConfigStep.generated) || activeStep > 3;

  const notReceivedAnalyticsNames =
    notReceivedAnalytics.length > 0 ? notReceivedAnalytics.map(i => i.name).join(', ') : '';
  const notReceivedAnalyticsOneExperiment = notReceivedAnalytics.length === 1;

  const pauseOrResume = batch?.status === EXPERIMENT_STATUSES.InProgress.value ? 'Pause' : 'Resume';

  const setBatchStatus = status => {
    setBatch({
      ...batch,
      status,
    });
  };

  if (activeStep === 3) {
    disableContinue =
      !!listCheckboxes.find(i => !i.is_checked || i.sub_items.find(j => !j.is_checked)) ||
      !!tableData.find(i => !i?.has_sent?.value);
  }

  const goToFinalization = () => {
    setActiveStep(5);
    getDetails();
  };

  const downloadDatFile = () => {
    const auth = localStorage.getItem('token');
    fetch(`/api/execution/dat_files_archive/?key=${batchKey}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${auth}`,
      },
    })
      .then(res => res.blob())
      .then(res => saveAs(res, 'DAT.zip'));
  };

  return (
    <Spinner loading={batchLoading}>
      <div className="reset-padding-container">
        <Steps active={activeStep} isSynJet />
        <div className="sj-execution-header">
          <div className="name">
            Batch
            {batch?.batchId}
          </div>
          <StatusLabel type="active">{batch?.status}</StatusLabel>
        </div>
        <div className={cn('sj-execution-body', { 'sj-revise-details': activeStep === 1 })}>
          {activeStep === 1 && (
            <SJReviseTheDetails
              batch={batch}
              getDetails={getDetails}
              experimentsInfo={experimentsInfo}
              dispensingOptions={dispensingOptions}
              setExperimentsInfo={setExperimentsInfo}
              errors={dispenserErrors}
            />
          )}
          {activeStep === 2 && (
            <SJBatchConfiguration
              batch={batch}
              checkboxesOnBatchConfigStep={checkboxesOnBatchConfigStep}
              onContinue={goFrom2ndTo3rdStep}
              confirmOnBatchConfigStep={confirmOnBatchConfigStep}
              setconfirmOnBatchConfigStep={setconfirmOnBatchConfigStep}
              setcheckboxesOnBatchConfigStep={setcheckboxesOnBatchConfigStep}
            />
          )}

          {activeStep === 3 && (
            <SynJetPrepareMaterials
              errors={errors}
              listCheckboxes={listCheckboxes}
              setListCheckboxes={setListCheckboxes}
              setErrors={setErrors}
              setEdited={setEdited}
              tableData={tableData}
              setTableData={setTableData}
            />
          )}
          {activeStep === 4 && (
            <BatchConfigurationWithExperimentsTable
              batch={batch}
              setBatchStatus={setBatchStatus}
              finalizeAndComplete={finalizeBatchConfirmation}
              goToFinalization={goToFinalization}
              manageExperimentRoutes={manageExperimentRoutes}
              connectivitySubject={connectivitySubject}
              setConnectivitySubject={setConnectivitySubject}
              setDisableButtons={setDisableButtons}
              getDetails={getDetails}
              dispensingOptions={dispensingOptions}
              errors={dispenserErrors}
              showDispensing
              disableButtons={disableButtons}
            />
          )}
          {activeStep === 5 && (
            <BatchFinalization
              batch={batch}
              finalizationOptions={finalizationOptions}
              setFinalizationOptions={setFinalizationOptions}
              connectivitySubject={connectivitySubject}
              setConnectivitySubject={setConnectivitySubject}
              setDisableButtons={setDisableButtons}
              disableButtons={disableButtons}
            />
          )}
        </div>
      </div>
      <Footer
        isSynjet
        experiment={batch}
        dropdownMenu={dropdownMenu}
        activeStep={activeStep}
        permissions={currentUser?.permissions}
        canSeeNotes={!!currentUser.permissions?.execution.view_note}
        notesLength={experiments.reduce((sum, exp) => sum + exp.notes.length, 0)}
        showNotes={() => {
          setShowNotesSidebar(true);
        }}
        handleBack={() => setActiveStep(step => step - 1)}
        handleContinue={onContinue}
        disableContinue={disableContinue}
        remoteConnectivity
        showPause={batch?.status === EXPERIMENT_STATUSES.InProgress.value}
        showResume={batch?.status === EXPERIMENT_STATUSES.Paused.value}
        showEndExperiment={batch?.status === EXPERIMENT_STATUSES.Paused.value}
        showFinalize={batch?.status === EXPERIMENT_STATUSES.PendingFinalization.value}
        disableCompleteExperiment={finalizationOptions.find(i => !i.checked)}
        pauseExperiment={() => setOpenPauseConfirmation(true)}
        resumeExperiment={() => setOpenPauseConfirmation(true)}
        completeExperiment={() => {
          setOpenCompleteConfirmation(true);
        }}
        endExperiment={() => setOpenEndConfirmation(true)}
        finalizeExperiment={finalizeBatchConfirmation}
        downloadDatFile={downloadDatFile}
        isPermittedDownloadDatFile={isPermittedDownloadDatFile}
        disableButtons={disableButtons}
      />
      {showNotesSidebar && (
        <BatchNotesSidebar
          experiments={experiments}
          updateNotesList={() => {
            dispatch(manageBatch({ data: { key: batchKey }, type: 'getBatchDetails' })).then(response => {
              setExperements(response.experiments);
            });
          }}
          open={showNotesSidebar}
          onClose={() => setShowNotesSidebar(false)}
        />
      )}
      <Popup
        open={rejectWarningOpen}
        title="Warning"
        textSubmit="Proceed"
        handleSubmit={handleRejectConfirm}
        handleCancel={() => setRejectWarningOpen(false)}
      >
        Reject will reset the preparation progress for the Batch, proceed?
      </Popup>
      <Popup
        open={!!showWarning}
        title="Warning"
        textSubmit="Proceed"
        handleSubmit={() => handleUpdateExperimentConditions(showWarning)}
        handleCancel={() => setShowWarning(null)}
      >
        Changes made for current wizard step will reset to default following wizard steps, proceed?
      </Popup>
      <Popup
        title=" "
        open={openPauseConfirmation}
        textSubmit={pauseOrResume}
        handleCancel={() => setOpenPauseConfirmation(false)}
        handleSubmit={pauseResumeBatchAction}
        loading={batchLoading}
      >
        {`Do you really want to ${pauseOrResume} the batch execution?`}
      </Popup>
      <Popup
        title=" "
        open={openEndConfirmation}
        textSubmit="End batch"
        handleCancel={() => setOpenEndConfirmation(false)}
        handleSubmit={endBatchAction}
        loading={batchLoading}
      >
        Do you really want to End the Batch execution?
      </Popup>
      <Popup
        title=" "
        open={openCompleteConfirmation}
        textSubmit="Yes"
        textCancle="No"
        handleCancel={completeBatchAction}
        handleSubmit={() => completeBatchAction(true)}
        loading={batchLoading}
      >
        Do you want to navigate to experiment results screen to append the results?
      </Popup>
      <Popup
        title="Finalize"
        open={openFinalizeConfirmation}
        textSubmit="Finalize"
        handleCancel={() => setOpenFinalizeConfirmation(false)}
        handleSubmit={() => {
          if (finalizeExperimentActionOnClick) finalizeExperimentAction(finalizeExperimentActionOnClick);
          else finalizeBatchAction();
        }}
        loading={batchLoading}
      >
        Analytics for experiment
        {notReceivedAnalyticsOneExperiment ? '' : 's'}:<b>{notReceivedAnalyticsNames}</b> not recieved yet. Do you want
        to proceed?
      </Popup>
      <RejectExperimentFromBatchPopup
        closePopup={() => setRejectBatchOpen(false)}
        open={rejectBatchOpen}
        type={type}
        batch={batch}
        experiment={rejectItem}
        navigateToFirstBatch={navigateToFirstBatch}
        getBatchDetails={getDetails}
      />
    </Spinner>
  );
};
