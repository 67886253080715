import React, { useEffect, useRef, useState, useContext } from 'react';
import './styles.scss';
import moment from 'moment';
import { Button, Popup, Spinner } from '../Common';
import { HeaderInfo } from '../ExperimentDetailsComponents/HeaderInfo';
import { DEVICES, EXPERIMENT_STATUSES } from '../../constants';
import { Info } from '../Experiment/Info';
import { ReactionTab } from '../Experiment/ReactionTab/ReactionTab';
import { RoutesTab } from '../Experiment/Routes/Routes';
import { generatePdf } from './utils';
import Notes from '../Experiment/Notes';
import { ProcessTab } from '../Experiment/ProcessTab';
import { ResultsTab } from '../Experiment/ResultsTab';

const PdfContext = React.createContext();

export const PdfGenerator = ({
  commonInfo,
  estimatedTime,
  numberDefinitionSteps,
  dataSynJet,
  actualTimeExp,
  manualReactions,
  notes,
  definition,
  description,
}) => {
  const [generate, setGenerate] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (generate) {
      const delay = setInterval(() => {
        const isActiveRequest = !!+localStorage.getItem('pdfRequestCount');
        if (!isActiveRequest) {
          generatePdf({
            ref,
            name: `${commonInfo.key}_${moment().format('MM-DD-YYYY')}_Experiment_info`,
            callback: () => {
              setGenerate(false);
            },
          });
          clearInterval(delay);
        }
      }, [2000]);
    }
  }, [generate]);
  const handleGenerate = () => {
    localStorage.setItem('pdfRequestCount', 0);
    setGenerate(true);
  };

  const isSynJet = DEVICES.SYNJET === commonInfo?.process?.device;
  const isLabDevice = DEVICES.LAB === commonInfo?.process?.device;
  const isSynJetPro = DEVICES.SYNJETPRO === commonInfo?.process?.device;
  if (
    (commonInfo.status !== EXPERIMENT_STATUSES.Submitted.value &&
      commonInfo.status !== EXPERIMENT_STATUSES.Completed.value) ||
    isSynJetPro
  ) {
    return <></>;
  }
  const values = {
    isPdf: generate,
  };
  return (
    <>
      <PdfContext.Provider value={values}>
        <Button disabled={generate} className="export-pdf-btn" secondary onClick={handleGenerate}>
          Export to PDF
        </Button>
        <Popup className="pdf-export-popup" open={generate} title="">
          <Spinner
            loading
            tip={
              <span>
                We are generating PDF
                <br /> This can take a few minutes
              </span>
            }
          />
        </Popup>
        {generate && (
          <div ref={ref} className="pdf-generator">
            <Info
              pdfMode
              ExtraComponents={() => <HeaderInfo commonInfo={commonInfo} estimatedTime={estimatedTime} />}
              dataExperiment={commonInfo}
              numberDefinitionSteps={numberDefinitionSteps}
              dataSynJet={dataSynJet}
              actualTimeExp={actualTimeExp}
            />
            {isSynJet ? (
              <RoutesTab routes={commonInfo?.route?.data} pdfMode />
            ) : isLabDevice ? (
              <ReactionTab
                pdfMode
                experiment={commonInfo}
                reactionsInfo={manualReactions || null}
                processType={commonInfo.process?.type}
                manual
                reactionsIdx={JSON.parse(commonInfo?.reactionsInfo)}
              />
            ) : (
              <ReactionTab
                pdfMode
                experiment={commonInfo}
                reactionsInfo={commonInfo.reactionsInfo ? JSON.parse(commonInfo.reactionsInfo) : {}}
                processType={commonInfo.process?.type}
              />
            )}
            <ResultsTab pdfMode data={commonInfo} isSynJet={isSynJet} isLabDevice={isLabDevice} />
            <ProcessTab pdfMode commonInfo={commonInfo} definition={definition} description={description} />
            <Notes pdfMode notes={notes} dataExperiment={commonInfo} />
          </div>
        )}
      </PdfContext.Provider>
    </>
  );
};

export const usePdfContext = () => {
  const state = useContext(PdfContext);
  return state || {};
};
