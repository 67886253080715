import createReducer from '../createReducer.js';
import * as actionTypes from './common.actionTypes';

const common = {
  user: {},
  globalLoading: false,
  usersBuRoles: {},
  editMode: false,
  devices: [],
};

function setCurrentUser(state, { data }) {
  return { ...state, user: data };
}
function setDevices(state, { data }) {
  return { ...state, devices: data };
}
function setUsersByRoles(state, { data }) {
  return { ...state, usersBuRoles: data };
}

function setEditMode(state, { data }) {
  return { ...state, editMode: data };
}
function setLoading(state, { data }) {
  return { ...state, globalLoading: data };
}

const commonReducer = createReducer(common, {
  [actionTypes.SET_CURRENT_USER]: setCurrentUser,
  [actionTypes.SET_USERS_BY_ROLES]: setUsersByRoles,
  [actionTypes.SET_EDIT_MODE]: setEditMode,
  [actionTypes.SET_DEVICES]: setDevices,
  [actionTypes.SET_LOADING]: setLoading,
});

export default commonReducer;
