import './App.scss';

import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { Experiments, Processes } from '@screens';

import GlobalSpinner from '../components/GlobalSpin';
import { Header } from '../components/Header/Header';
import { getDetailsUser } from '../store/common/common.actions';
import history from '../utils/history';
import { AutoSyn } from './AutoSyn/AutoSyn';
import { Execution } from './Execution/Execution';
import { ManualLab } from './ManualLab/Lab';
import { Projects } from './Projects/Projects';
import { Scheduling } from './Sheduling/Scheduling';
import { SynJet } from './SynJet/SynJet';
import { SynJetPro } from './SynJetPro/SynJetPro';
import { SynRoute } from './SynRoute/SynRoute';
import { Users } from './Users/Users';

function App(props) {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    props.getDetailsUser().then(resp => {
      if (resp?.uuid && pathname === '/') {
        if (resp.roles.includes('System Administrator')) history.push(`${path}users`);
        else if (resp.roles.includes('Project Manager')) history.push(`${path}projects`);
        else if (resp.roles.includes('Chemistry Designer')) history.push(`${path}processes`);
        else if (resp.roles.includes('Maintenance Technician')) history.push(`${path}scheduling`);
        else if (resp.roles.includes('Operations Manager')) history.push(`${path}scheduling`);
        else if (resp.roles.includes('Trained Operator')) history.push(`${path}execution`);
      }
    });
  }, []);
  return (
    <>
      <Header path={props.location.pathname} user={props.user} />
      <GlobalSpinner>
        <main className="main">
          <Switch>
            <Route path={`${path}users`} component={Users} />
            <Route path={`${path}projects`} component={Projects} />
            <Route path={`${path}execution`} component={Execution} />
            <Route path={`${path}scheduling`} component={Scheduling} />
            <Route path={`${path}synroute`} component={SynRoute} />
            <Route path={`${path}synjet_pro`} component={SynJetPro} />
            <Route path={`${path}synjet`} component={SynJet} />
            <Route path={`${path}lab`} component={ManualLab} />
            <Route path={`${path}autosyn`} component={AutoSyn} />
            <Route path={`${path}experiments`} component={Experiments} />
            <Route path={`${path}processes`} component={Processes} />
          </Switch>
        </main>
      </GlobalSpinner>
    </>
  );
}

const mapStateToProps = store => ({
  user: store.commonReducer.user,
});

export default connect(mapStateToProps, { getDetailsUser })(App);
