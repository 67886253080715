import React from 'react';

import { Select as AntdSelect } from 'antd';
import { Select } from 'components';

import { OptionWithColor } from '@atoms';

const { Option } = AntdSelect;
export const SelectWithTags = ({ options, onChange, value }) => (
  <Select
    showArrow
    value={value}
    onChange={val => onChange(val)}
    placeholder="Please type to select"
    options={options}
    mode="multiple"
  >
    {options.map(option => (
      <Option value={option.value}>
        <OptionWithColor color={option.color} label={option.label} />
      </Option>
    ))}
  </Select>
);
