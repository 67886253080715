import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { COMPOUND_VARIATY, EQUIPMENT_TYPES, PROJECT_TYPES } from '../../../constants';
import { formatSeconds } from '../../../utils';
import CompoundSmilesPreview from '../../CompoundSmilesPreview/CompoundSmilesPreview';
import { Tooltip } from '../../Common';

export const getProcessReactionsAndColumns = (processData, processType) => {
  let generationMethod;

  switch (processType) {
    case PROJECT_TYPES.OPTIMIZATION:
      generationMethod = getOptimizationColumnsAndReactions;
      break;
    case PROJECT_TYPES.LIBRARY_GENERATION:
      generationMethod = getLibraryColumnsAndReactions;
      break;
    default:
      break;
  }

  if (!processData || !processType || !generationMethod) {
    return {
      columns: [],
      reactions: [],
    };
  }

  const { columns, reactions } = generationMethod(processData);

  return {
    columns: [
      {
        title: 'Reaction #',
        render: (text, record, index) => index + 1,
      },
      ...columns,
    ],
    reactions,
  };
};

const getInputColumn = (key, label) => [
  {
    title: `${label}`,
    dataIndex: [key],
    render: data => {
      const text = `${data.label} (${data.value})`;
      return data.compound ? (
        <>
          {text}
          <Tooltip
            overlayClassName="synjet-experiment-table_header_tooltip"
            title={
              <CompoundSmilesPreview compound={data.compound} options={{ width: 130, height: 80 }} hideMW showDivider />
            }
          >
            <InfoCircleOutlined className="info-icon_manual" />
          </Tooltip>
        </>
      ) : (
        text
      );
    },
  },
];

const getEquipmentColumn = (key, label) => [
  {
    title: `${label} temp, °C`,
    dataIndex: [key],
    render: data => `${data.tempLabel} (${Number(data.temp || 0).toFixed(2)})`,
  },
  {
    title: `${label} time, min:sec`,
    dataIndex: [key],
    render: data => {
      const { min, sec } = formatSeconds(data.time || 0);
      return `${data.timeLabel} (${min}:${sec < 10 ? `0${sec}` : sec})`;
    },
  },
];

const getOptimizationCombinations = (reactions, variations, key) => {
  if (!reactions.length) {
    return variations.map(inputVariation => ({
      [key]: inputVariation,
    }));
  }
  const bufferReactions = [];
  variations.forEach(inputVariation => {
    reactions.forEach(reaction => {
      bufferReactions.push({ ...reaction, [key]: inputVariation });
    });
  });
  return bufferReactions;
};

const getEquipmentOptimizationVariations = equipment => {
  const equipmentVariations = [];
  let propIndex = 0;
  equipment.times.forEach((time, timeIndex) => {
    equipment.temperatures.forEach((temperature, temperatureIndex) => {
      equipmentVariations.push({
        tempLabel: `T${temperatureIndex + 1}`,
        temp: temperature,
        timeLabel: `t${timeIndex + 1}`,
        time,
        equipmentPropertyIndex: propIndex,
      });
      propIndex += 1;
    });
  });
  return equipmentVariations;
};

const getEquipmentLibGenVariations = equipment => {
  const equipmentVariations = [];
  equipment.times.forEach((time, timeIndex) => {
    equipmentVariations.push({
      tempLabel: `T${timeIndex + 1}`,
      temp: equipment.temperatures[timeIndex],
      timeLabel: `t${timeIndex + 1}`,
      time,
      name: equipment.properties,
      equipmentPropertyIndex: timeIndex,
    });
  });
  return equipmentVariations;
};

export const getOptimizationColumnsAndReactions = processData => {
  const columns = [];
  let reactions = [];
  let inputIndexGlobal = 1;

  processData.forEach(({ inputs, equipment }, equipmentIndex) => {
    inputs.forEach(input => {
      const inputKey = `I${inputIndexGlobal}`;
      const inputProperties = input.properties[0];
      const isSolvent = inputProperties.chemical_type === 'solvent';
      const inputVariations = isSolvent
        ? [
            {
              label: `${inputKey}1`,
              value: `${Number(inputProperties.solvent_volume || 0).toFixed(2)} mL`,
            },
          ]
        : inputProperties.equivalents.map((equivalent, equivalentIndex) => ({
            label: `${inputKey}${equivalentIndex + 1}`,
            value: `${Number(equivalent || 0).toFixed(2)} equiv, ${Number(
              inputProperties.moles[equivalentIndex] || 0
            ).toFixed(2)} mol`,
            compound: inputProperties.compound,
            inputPropertyIndex: equivalentIndex,
          }));

      columns.push(...getInputColumn(inputKey, inputKey));
      reactions = getOptimizationCombinations(reactions, inputVariations, inputKey);
      inputIndexGlobal++;
    });

    if (equipment) {
      const equipmentKey = `E${equipmentIndex}`;
      const equipmentVariations = getEquipmentOptimizationVariations(equipment);

      columns.push(...getEquipmentColumn(equipmentKey, EQUIPMENT_TYPES[equipment.type].shortLabel));
      reactions = getOptimizationCombinations(reactions, equipmentVariations, equipmentKey);
    }
  });

  return {
    columns,
    reactions: reactions.map(reaction => ({ include: true, ...reaction })),
  };
};

export const getLibraryColumnsAndReactions = processData => {
  const columns = [];
  let reactions = [];

  let variableInput = null;

  processData.forEach(({ inputs }) => {
    inputs.forEach(input => {
      variableInput = input.properties?.length > (variableInput?.properties?.length || 0) ? input : variableInput;
    });
  });

  if (variableInput) {
    variableInput.properties.forEach((variableProperty, variablePropertyIndex) => {
      const resultVariation = {};
      let inputIndexGlobal = 1;

      processData.forEach(({ inputs }) => {
        inputs.forEach(input => {
          const inputKey = `I${inputIndexGlobal}`;
          const inputProperties = input.variable ? variableProperty : input.properties[0];
          const inputPropertyIndex = input.variable ? 0 : variablePropertyIndex;
          const isSolvent = inputProperties.chemical_type === 'solvent';
          resultVariation[inputKey] = isSolvent
            ? {
                label: COMPOUND_VARIATY[0],
                value: `${Number(inputProperties.solvent_volume || 0).toFixed(2)} mL`,
              }
            : {
                value: `${Number(inputProperties.equivalents[inputPropertyIndex] || 0).toFixed(2)} equiv, ${Number(
                  inputProperties.moles[0] || 0
                ).toFixed(2)} mol`,
                label: COMPOUND_VARIATY[variablePropertyIndex],
                compound: inputProperties.compound,
                inputPropertyIndex: input.variable ? variablePropertyIndex : inputPropertyIndex,
              };
          inputIndexGlobal++;
        });
      });

      reactions.push(resultVariation);
    });
  }

  let inputIndexGlobal = 1;
  processData.forEach(({ inputs, equipment }, equipmentIndex) => {
    inputs.forEach(() => {
      const inputKey = `I${inputIndexGlobal}`;
      columns.push(...getInputColumn(inputKey, inputKey));
      inputIndexGlobal++;
    });

    if (equipment) {
      const equipmentKey = `E${equipmentIndex}`;
      const equipmentVariations = getEquipmentLibGenVariations(equipment);

      columns.push(...getEquipmentColumn(equipmentKey, EQUIPMENT_TYPES[equipment.type].shortLabel));
      reactions = getOptimizationCombinations(reactions, equipmentVariations, equipmentKey);
    }
  });
  return {
    columns,
    reactions: reactions.map(reaction => ({ include: true, ...reaction })),
  };
};
