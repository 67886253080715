import { SYNJET_INITIAL_RULE, SYNJET_PRO_INITIAL_STEP } from '../../constants';
import createReducer from '../createReducer';

const initialState = {
  steps: [SYNJET_PRO_INITIAL_STEP],
  process: null,
  rules: [SYNJET_INITIAL_RULE],
  experimentData: [],
  products: [],
  quenching: [],
  hardwareSummary: [],
  processData: {
    process_steps: [],
  },
  errors: [],
  batchAbortedErrorShown: false,
  stateCollapse: false,
};

function resetStore() {
  return initialState;
}

const synjetProReducer = createReducer(initialState, {});

export default synjetProReducer;
