import React from 'react';
import { Button as AntdButton } from 'antd';
import './style.scss';
import cn from 'classnames';
import { Tooltip } from '..';

export const Button = ({
  secondary,
  small,
  large,
  size = 'default',
  className,
  children,
  disabled,
  disabledTooltip,
  onClick,
  type,
  ...rest
}) => {
  const params = {
    type: type || (!secondary && 'primary'),
    size: (!!small && 'small') || (!!large && 'large'),
    ...rest,
  };
  if (!!disabledTooltip && disabled) {
    return (
      <Tooltip title={disabledTooltip}>
        <AntdButton className={cn('sri-button disabled', className)} {...params}>
          {children}
        </AntdButton>
      </Tooltip>
    );
  } else {
    return (
      <AntdButton {...params} disabled={disabled} onClick={onClick} className={cn('sri-button', className)}>
        {children}
      </AntdButton>
    );
  }
};
