import no_experiment_results from 'dist/images/no-experiment-results.svg';
import { Button } from '..';
import React from 'react';
import './style.scss';

export const NoResultsEmptyState = ({ canAppendResults = false, appendResults }) => {
  return (
    <div className="no-results-empty-state">
      <img src={no_experiment_results} className="no-results-empty-state__image" />
      <div className="no-results-empty-state__title">No results yet</div>
      <div className="no-results-empty-state__subtitle">Please fill in experiment results</div>
      {canAppendResults && (
        <Button secondary onClick={appendResults}>
          Append results
        </Button>
      )}
    </div>
  );
};
