import React, { useEffect, useState } from 'react';

import { openNotification } from 'components/Common';
import { useDispatch } from 'react-redux';
import {
  getExperimentFieldData,
  setUpdateExperimentField,
  updateExtensibleTableField,
  updateTableFieldStatus,
} from 'store/experimentField/experimentField.actions';
import { initialState } from 'store/experimentField/experimentField.reducer';
import { getUserById } from 'store/users/users.actions';
import { v4 as uuidv4 } from 'uuid';

import { ExperimentTemplateFieldPopup, FIELD_DATA_TYPES, INITIAL_DEFAULT_VALUE } from '@organisms';

export const EditExperimentFieldPopup = ({ uuid, setExperimentFieldPopup, onUpdateTable, setFieldID }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getUser = value => {
    const defaultUser = dispatch(getUserById(value)).then(resp => ({
      checkbox: true,
      value: [{ value, label: `${resp.firstName} ${resp.lastName}` }],
    }));
    return defaultUser;
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getExperimentFieldData(uuid))
      .then(async resp => {
        const IS_DROPDOWN = FIELD_DATA_TYPES.select == resp.dataType;
        const IS_USERLIST = FIELD_DATA_TYPES.user == resp.dataType;
        const IS_MULTISELECT = FIELD_DATA_TYPES.multiselect == resp.dataType;
        const hint = resp.hint ? { checkbox: true, value: resp.hint } : { checkbox: false, value: '' };
        let dropdownOptions = JSON.parse(resp.availableOptions);
        let defaultField = JSON.parse(resp.defaultValue);
        let colorCoding = false;
        if (IS_DROPDOWN || IS_MULTISELECT) {
          dropdownOptions = dropdownOptions.map(option => ({
            ...option,
            value: option.value,
            label: option.label,
            key: uuidv4(),
            errorMessage: '',
            errorMessageForColor: '',
          }));
          const defaultOptions = IS_DROPDOWN
            ? [dropdownOptions?.find(option => option.value == defaultField?.value)]
            : dropdownOptions.filter(option => defaultField?.some(item => option.value == item.value));
          colorCoding = dropdownOptions.some(option => option.color);
          defaultField = defaultField ? { checkbox: true, value: defaultOptions } : { ...INITIAL_DEFAULT_VALUE };
        } else if (IS_USERLIST && defaultField) {
          defaultField = await getUser(defaultField);
        } else {
          defaultField = defaultField ? { checkbox: true, value: defaultField } : { ...INITIAL_DEFAULT_VALUE };
        }
        const experimentFieldData = {
          uuid: resp.uuid,
          name: resp.name,
          dataType: resp.dataType,
          editable: resp.editable,
          unique: resp.unique,
          updateHistory: resp.addUpdatesToHistory,
          visible: resp.visibleInDetails,
          archived: resp.archived,
          systemic: resp.systemic,
          dropdownOptions,
          defaultField,
          colorCoding,
          hint,
        };
        dispatch(setUpdateExperimentField(experimentFieldData));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uuid]);

  const handleError = errors => {
    errors.forEach(error => {
      error.messages.forEach(errorText => openNotification('', errorText));
    });
  };

  const onSubmit = fieldData => {
    setLoading(true);
    dispatch(updateExtensibleTableField(fieldData))
      .then(resp => {
        if (resp.errors) {
          handleError(resp.errors);
        } else {
          onUpdateTable();
          setExperimentFieldPopup(false);
          setFieldID('');
          openNotification('The experiment field has been successfully updated');
          dispatch(setUpdateExperimentField(initialState));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onArchivate = async (uuid, archived) => {
    setLoading(true);
    dispatch(updateTableFieldStatus({ uuid, archived }))
      .then(data => {
        dispatch(setUpdateExperimentField({ archived: data.archived }));
        onUpdateTable();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ExperimentTemplateFieldPopup
      setExperimentFieldPopup={setExperimentFieldPopup}
      onSubmit={onSubmit}
      editing
      loading={loading}
      onArchivate={onArchivate}
      setFieldID={setFieldID}
    />
  );
};
