import React from 'react';
import cn from 'classnames';

export const Heating = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#20A89A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 38.4C31.9529 38.4 38.4 31.9529 38.4 24C38.4 16.0471 31.9529 9.6 24 9.6C16.0471 9.6 9.6 16.0471 9.6 24C9.6 31.9529 16.0471 38.4 24 38.4ZM24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z"
        fill="white"
      />
      <g clip-path="url(#clip0)">
        <path
          d="M19.2858 18.1075L18.1075 16.9292C17.7825 16.6038 17.2542 16.6038 16.9292 16.9292C16.6038 17.2546 16.6038 17.7821 16.9292 18.1075L18.1075 19.2858C18.27 19.4487 18.4833 19.53 18.6967 19.53C18.91 19.53 19.1233 19.4487 19.2858 19.2858C19.6112 18.9604 19.6112 18.4329 19.2858 18.1075Z"
          fill="white"
        />
        <path
          d="M16.5 23.1667H14.8333C14.3733 23.1667 14 23.54 14 24C14 24.46 14.3733 24.8333 14.8333 24.8333H16.5C16.96 24.8333 17.3333 24.46 17.3333 24C17.3333 23.54 16.96 23.1667 16.5 23.1667Z"
          fill="white"
        />
        <path
          d="M19.2858 28.7142C18.9608 28.3887 18.4325 28.3887 18.1075 28.7142L16.9292 29.8925C16.6038 30.2179 16.6038 30.7454 16.9292 31.0708C17.0917 31.2337 17.305 31.315 17.5183 31.315C17.7317 31.315 17.945 31.2337 18.1075 31.0708L19.2858 29.8925C19.6112 29.5671 19.6112 29.0396 19.2858 28.7142Z"
          fill="white"
        />
        <path
          d="M24 30.6667C23.54 30.6667 23.1667 31.04 23.1667 31.5V33.1667C23.1667 33.6267 23.54 34 24 34C24.46 34 24.8333 33.6267 24.8333 33.1667V31.5C24.8333 31.04 24.46 30.6667 24 30.6667Z"
          fill="white"
        />
        <path
          d="M31.0708 29.8925L29.8925 28.7142C29.5675 28.3887 29.0392 28.3887 28.7142 28.7142C28.3888 29.0396 28.3888 29.5671 28.7142 29.8925L29.8925 31.0708C30.055 31.2337 30.2683 31.315 30.4817 31.315C30.695 31.315 30.9083 31.2337 31.0708 31.0708C31.3963 30.7454 31.3963 30.2179 31.0708 29.8925Z"
          fill="white"
        />
        <path
          d="M33.1667 23.1667H31.5C31.04 23.1667 30.6667 23.54 30.6667 24C30.6667 24.46 31.04 24.8333 31.5 24.8333H33.1667C33.6267 24.8333 34 24.46 34 24C34 23.54 33.6267 23.1667 33.1667 23.1667Z"
          fill="white"
        />
        <path
          d="M31.0708 16.9292C30.7458 16.6038 30.2175 16.6038 29.8925 16.9292L28.7142 18.1075C28.3888 18.4329 28.3888 18.9604 28.7142 19.2858C28.8767 19.4487 29.09 19.53 29.3033 19.53C29.5167 19.53 29.73 19.4487 29.8925 19.2858L31.0708 18.1075C31.3963 17.7821 31.3963 17.2546 31.0708 16.9292Z"
          fill="white"
        />
        <path
          d="M24 14C23.54 14 23.1667 14.3733 23.1667 14.8333V16.5C23.1667 16.96 23.54 17.3333 24 17.3333C24.46 17.3333 24.8333 16.96 24.8333 16.5V14.8333C24.8333 14.3733 24.46 14 24 14Z"
          fill="white"
        />
        <path
          d="M24 18.5833C21.0133 18.5833 18.5833 21.0133 18.5833 24C18.5833 26.9867 21.0133 29.4167 24 29.4167C26.9867 29.4167 29.4167 26.9867 29.4167 24C29.4167 21.0133 26.9867 18.5833 24 18.5833ZM24 27.75C21.9325 27.75 20.25 26.0675 20.25 24C20.25 21.9325 21.9325 20.25 24 20.25C26.0675 20.25 27.75 21.9325 27.75 24C27.75 26.0675 26.0675 27.75 24 27.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
