import React from 'react';

import { DatePicker, Input, Select } from 'components';

import { InputNumber } from './components/Input';
import { InputWithTags } from './components/InputWithTags';
import { SelectWithTags } from './components/Select';
import { TypeToSearchSelect } from './components/TypeToSearchSelect';
import { TypeToSearchSelectSmile } from './components/TypeToSearchSelectSmile';
import {
  BETWEEN_PREDICATE,
  EMPTY_PREDICATE,
  GREATER_OR_EQUAL_TO_PREDICATE,
  GREATER_THAN_PREDICATE,
  LESS_OR_EQUAL_TO_PREDICATE,
  LESS_THAN_PREDICATE,
  NEXT_PREDICATE,
  NOT_EMPTY_PREDICATE,
  PREVIOUS_PREDICATE,
  THIS_PREDICATE,
  TODAY_PREDICATE,
  TOMORROW_PREDICATE,
  YESTERDAY_PREDICATE,
} from './constants';
import { getExperiments, getSmiles, getUsers } from './utils';

export const numericSwitchCase = defaultProperties => {
  switch (defaultProperties.predicate) {
    case LESS_THAN_PREDICATE.key:
      return <InputNumber {...defaultProperties} />;
    case LESS_OR_EQUAL_TO_PREDICATE.key:
      return <InputNumber {...defaultProperties} />;
    case GREATER_THAN_PREDICATE.key:
      return <InputNumber {...defaultProperties} />;
    case GREATER_OR_EQUAL_TO_PREDICATE.key:
      return <InputNumber {...defaultProperties} />;
    default:
      return <InputWithTags {...defaultProperties} />;
  }
};

export const dateSwitchCase = defaultProperties => {
  switch (defaultProperties.predicate) {
    case BETWEEN_PREDICATE.key:
      return <DatePicker range {...defaultProperties} />;
    case TODAY_PREDICATE.key:
      return <Input {...defaultProperties} disabled />;
    case YESTERDAY_PREDICATE.key:
      return <Input {...defaultProperties} disabled />;
    case TOMORROW_PREDICATE.key:
      return <Input {...defaultProperties} disabled />;
    case PREVIOUS_PREDICATE.key:
      return <Select {...defaultProperties} />;
    case THIS_PREDICATE.key:
      return <Select {...defaultProperties} />;
    case NEXT_PREDICATE.key:
      return <Select {...defaultProperties} />;
    default:
      return <DatePicker {...defaultProperties} />;
  }
};

export const filterComponentForEmptyAndIsNotEmptyPredicate = predicate => {
  if (predicate.key === EMPTY_PREDICATE.key || predicate.key === NOT_EMPTY_PREDICATE.key) {
    return () => <Input disabled />;
  }
  return null;
};
export const FILTER_COMPONENTS_BASED_ON_FIELD_TYPES = {
  short_text: InputWithTags,
  long_text: InputWithTags,
  numeric: numericSwitchCase,
  decimal: numericSwitchCase,
  date: dateSwitchCase,
  datetime: dateSwitchCase,
  boolean: defaultProperties => <Select {...defaultProperties} />,
  user: defaultProperties => <TypeToSearchSelect getData={getUsers} {...defaultProperties} />,
  smile: defaultProperties => <TypeToSearchSelectSmile getData={getSmiles} {...defaultProperties} />,
  select: SelectWithTags,
  multiselect: SelectWithTags,
  experiment: defaultProperties => <TypeToSearchSelect getData={getExperiments} {...defaultProperties} />,
};
