import { Spinner } from '../../Common/Spinner';
import cn from 'classnames';
import Canvas from '../../DrawerRoute/Canvas';
import React from 'react';

export const ContainerResultingRoute = ({ routeData, resultingRoute }) => {
  return (
    <div className="creator-processes_content">
      {routeData ? (
        <div className={cn('creator-processes_content_route', 'creator-processes_content_route-result')}>
          <div className="creator-processes_content_route_result">
            <div className="creator-processes_content_route_item_segment">
              <Canvas
                indexUniq={0}
                results={routeData}
                routeId={resultingRoute}
                onCanvasClick={() => {}}
                type="routeCanvas"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="creator-processes_content_empty">The selected route will be displayed here</div>
      )}
    </div>
  );
};
