import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { Input, Popup } from '../..';

export const RenamePopup = ({
  open,
  closePopup,
  value,
  label = 'Process name',
  title = 'Rename process',
  textSubmit = 'Save',
  placeholder = 'Process name',
  save,
  id,
  error,
  setLoadingProcesses,
}) => {
  const [errorText, setErrorText] = useState('Process name is a required field');
  const [name, setName] = useState(value);
  const [errorOn, setErrorOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameInput, setNameInput] = useState(null);

  const saveChanges = () => {
    if (!name) {
      setErrorText('Process name is a required field');
      setErrorOn(true);
    } else if (name.trim().length < 3) {
      setErrorText('Process name must have at least 3 characters');
      setErrorOn(true);
    } else {
      setLoading(true);
      save(name, id).then(() => {
        setLoading(false);
      });
      setErrorOn(false);
    }
  };

  const setRefFirstInput = elem => {
    if (!nameInput && elem && open && !loading) {
      elem.focus();
      setNameInput(elem);
    }
  };

  const handleCancel = useCallback(() => {
    setErrorOn(false);
    setLoading(false);
    closePopup();
    setNameInput(null);
    setLoadingProcesses(false);
  }, []);

  useEffect(() => {
    setName(value);
  }, [value]);

  useEffect(() => {
    if (error.error) {
      setErrorText(error.message);
      setErrorOn(true);
    }
  }, [error]);

  useEffect(() => {
    setErrorOn(false);
  }, []);

  return (
    <Popup
      open={open}
      textSubmit={textSubmit}
      handleCancel={handleCancel}
      handleSubmit={saveChanges}
      title={title}
      loading={loading}
      setErrorOn={false}
    >
      <div>
        <Input
          label={label}
          placeholder={placeholder}
          value={name}
          onChange={e => {
            //let newString = e.replace(/[^A-Z0-9 ]/gi, '');
            setName(e);
            setErrorOn(false);
          }}
          error={errorOn}
          errorText={errorText}
          maxLength={256}
          disabled={loading}
          setRef={setRefFirstInput}
          allowSpecials
        />
      </div>
    </Popup>
  );
};
