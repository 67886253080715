import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Popup, RadioGroup, Select, Sidebar } from '../../Common';
import './style.scss';
import { checkIfTrue, httpSynMolDB } from '../../../utils';
import { SmilesSearcher } from '../../SmilesSearcher';
import { COMPOUND_VARIATY, PROJECT_TYPES } from '../../../constants';

const options = [
  { label: 'Reagent', value: 'Reagent' },
  { label: 'Reactant', value: 'Reactant' },
  { label: 'Solvent', value: 'Solvent' },
];

const initialState = {
  type: options[0].value,
  reagent: {
    limiting_reagent: false,
    MW: '',
    equivalents: '',
    concentration: 0,
    compound: '',
  },
  reactant: {
    limiting_reactant: false,
    MW: '',
    equivalents: '',
    concentration: 0,
    compound: '',
  },
  solvent: {
    name: '',
    MW: '',
    fraction: 0,
  },
};

const initialErrors = {
  reagent: {
    limiting: false,
    equivalents: false,
    concentration: false,
    compound: false,
  },
  reactant: {
    limiting: false,
    equivalents: false,
    concentration: false,
    compound: false,
  },
  solvent: {
    name: false,
    fraction: false,
  },
};

const errorsText = {
  reagent: {
    limiting_reagent: false,
    equivalents: false,
    concentration: 'Concentration is a required field',
    compound: 'Please select the Compound',
  },
  reactant: {
    limiting_reagent: false,
    equivalents: false,
    concentration: 'Concentration is a required field',
    compound: 'Please select the Compound',
  },
  solvent: {
    name: 'Please select the Compound',
    fraction: 'Fraction should be > 0',
  },
};

const errorsInitialOptimization = {
  reagent: {
    limiting: false,
    equivalents: [false],
    concentration: false,
    compound: false,
  },
  reactant: {
    limiting: false,
    equivalents: [false],
    concentration: false,
    compound: false,
  },
};

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

const variationLabel = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
};

export const SidebarPumpCompound = ({
  open,
  onClose,
  setPumpCompoundData,
  editingData,
  onDelete,
  getCurrentPumpByPosition,
  findLimitingReagent,
  findVariativeLimitingReagent,
  processData,
  getVariableReactor,
  idPump,
  sidebarCompoundPumpIndex,
  variationPump,
  getVariablePumpData,
  compoundPosition,
  becameVariable,
}) => {
  const [data, setData] = useState(initialState);
  const [solventsOptions, setSolventsOptions] = useState([]);
  const [errors, setErrors] = useState(initialErrors);
  const [searchOptions, setSearchOptions] = useState([]);
  const [edited, setEdited] = useState(false);

  const [compound, setCompound] = useState(null);
  const [compoundId, setCompoundId] = useState(null);
  const [cancelConfirmation, openCancelConfirmation] = useState(false);
  const [equivalentValue, setEquivalentValue] = useState(['N/A']);

  const [fraction, setFraction] = useState(0);
  const [mw, setMw] = useState(null);
  const [nameSolvent, setNameSolvent] = useState(null);
  const [molarFlowRate, setMolarFlowRate] = useState([0]);
  const [typeOfProcess, setTypeOFProcess] = useState('Production');

  const [dataVariablePump, setDataVariablePump] = useState(false);
  const [isVariablePump, setIsVariablePump] = useState(false);
  const [currentPump, setCurrentPump] = useState(null);
  const [mfcPump, setMfcPump] = useState(false);
  const [gasOptions, setGasOptions] = useState([]);
  const [nameGas, setNameGas] = useState(null);

  const reset = () => {
    setEquivalentValue(['N/A']);
    setData(initialState);
    setCompound(null);
    setCompoundId(null);
    setErrors(initialErrors);
    setSearchOptions([]);
    setFraction(0);
    setMw('');
    setNameGas(null);
    setNameSolvent(null);
    setIsVariablePump(false);
    setCurrentPump(null);
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    setTypeOFProcess(processData?.process?.type);
  }, [processData]);

  useEffect(() => {
    let variablePump = getVariablePumpData();
    setDataVariablePump(variablePump);
    let v = getVariableReactor();
    let currentPumpData = getCurrentPumpByPosition();
    setMfcPump(currentPumpData.type[0] === 'MFC');
    if (!editingData && !!v) {
      let arr = [];
      if (v.times.length > 1) {
        for (let i = 0; i < v.times.length; i++) {
          arr[i] = 'N/A';
        }
        setEquivalentValue(arr);
      }
    }
  }, [open]);

  useEffect(() => {
    if (becameVariable) {
      setDataVariablePump(becameVariable);
    }
  }, [becameVariable]);

  const onSave = () => {
    let isInvalid = validation(data.type);
    let obj = {};
    if (!isInvalid) {
      if (data.type === 'Solvent') {
        obj = {
          solvent: data.solvent.name.compound_id,
          fraction: fraction,
          properties: data.solvent.name,
          molar_flow_rate: null,
          pump_name: `Pump ${idPump}`,
        };
      } else if (data.type === 'Reagent') {
        obj = {
          reagent: mfcPump ? data.reagent.name.compound_id : compoundId,
          concentration: data.reagent.concentration,
          limiting_reagent: data.reagent.limiting_reagent || false,
          equivalents: equivalentValue,
          properties: !mfcPump ? compound : data.reagent.name,
          molar_flow_rate: mfcPump ? null : molarFlowRate,
          pump_name: `Pump ${idPump}`,
        };
      } else {
        obj = {
          reactant: mfcPump ? data.reactant.name.compound_id : compoundId,
          concentration: data.reactant.concentration,
          limiting_reagent: data.reactant.limiting_reactant || false,
          equivalents: equivalentValue,
          properties: !mfcPump ? compound : data.reactant.name,
          molar_flow_rate: mfcPump ? null : molarFlowRate,
          pump_name: `Pump ${idPump}`,
        };
      }
      if (mfcPump) {
        obj.limiting_reagent = false;
        obj.concentration = 0;
        obj.equivalents = 0;
      }
      setPumpCompoundData(obj);
      setEdited(false);
    }
  };

  useEffect(() => {
    if (data.type === 'Solvent') return;

    const calculatedMolarFlowRate = [];
    const equivalentsData = [];
    const limitingReagent = findLimitingReagent();

    const concentration = data.type === 'Reagent' ? data.reagent.concentration : data.reactant.concentration;
    const pumpFlowRates = getCurrentPumpByPosition()?.flow_rate;
    if (variationPump) {
      const calculatedMFR = pumpFlowRates[0] * concentration * 1000 * 0.001 * 1;
      calculatedMolarFlowRate[0] = calculatedMFR;
      const variatyLimitingReagent = variationPump.properties.map((prop, index) => findVariativeLimitingReagent(index));
      const variatyLimitingReagentSelected = variatyLimitingReagent.filter(limitedReagent => !!limitedReagent);
      if (isVariablePump || becameVariable) {
        if (!!data.reagent.limiting_reagent || !!data.reactant.limiting_reactant) {
          equivalentsData[0] = 1;
        } else {
          const variatyIndex = getKeyByValue(COMPOUND_VARIATY, sidebarCompoundPumpIndex);
          const variatyLimitedCompound = variatyLimitingReagent[variatyIndex];
          if (!variatyLimitedCompound) {
            if (variatyLimitingReagentSelected.length) equivalentsData[0] = 'N/A';
            else
              equivalentsData[0] =
                limitingReagent && concentration
                  ? Number(calculatedMFR / limitingReagent.molar_flow_rate[0]).toFixed(3)
                  : 'N/A';
          } else {
            equivalentsData[0] = Number(calculatedMFR / variatyLimitedCompound.molar_flow_rate[0]).toFixed(3);
          }
        }
      } else {
        if (
          variatyLimitingReagentSelected.length ||
          getKeyByValue(COMPOUND_VARIATY, sidebarCompoundPumpIndex) > variationPump.properties.length - 1
        ) {
          variatyLimitingReagent.forEach((reagent, variatyIndex) => {
            if (!!data.reagent.limiting_reagent || !!data.reactant.limiting_reactant) {
              equivalentsData[variatyIndex] = 1;
            } else {
              if (
                !reagent ||
                getKeyByValue(COMPOUND_VARIATY, sidebarCompoundPumpIndex) > variationPump.properties.length - 1
              )
                equivalentsData[variatyIndex] = 'N/A';
              else equivalentsData[variatyIndex] = Number(calculatedMFR / reagent.molar_flow_rate[0]).toFixed(3);
            }
          });
        } else {
          if (
            (!limitingReagent && !(data.reagent.limiting_reagent || data.reactant.limiting_reactant)) ||
            !concentration
          ) {
            variationPump.properties.forEach((prop, index) => (equivalentsData[index] = 'N/A'));
          } else if (!!data.reagent.limiting_reagent || !!data.reactant.limiting_reactant) {
            variationPump.properties.forEach((prop, index) => (equivalentsData[index] = 1));
          } else {
            variationPump.properties.forEach(
              (prop, index) =>
                (equivalentsData[index] = Number(calculatedMFR / limitingReagent.molar_flow_rate[0]).toFixed(3))
            );
          }
        }
      }
    } else if (pumpFlowRates) {
      pumpFlowRates.forEach((flowRate, flowRateIndex) => {
        const calculatedMFR = flowRate * concentration * 1000 * 0.001 * 1;
        calculatedMolarFlowRate[flowRateIndex] = calculatedMFR;
        if (
          (data.type === 'Reagent' && editingData?.limiting_reagent && !data.reagent.limiting_reagent) ||
          (data.type === 'Reactant' && editingData?.limiting_reagent && !data.reactant.limiting_reactant)
        ) {
          equivalentsData[flowRateIndex] = 'N/A';
          return;
        }
        if (
          (!limitingReagent && !(data.reagent.limiting_reagent || data.reactant.limiting_reactant)) ||
          !concentration
        ) {
          equivalentsData[flowRateIndex] = 'N/A';
          return;
        }
        if (!!data.reagent.limiting_reagent || !!data.reactant.limiting_reactant) {
          equivalentsData[flowRateIndex] = 1;
        } else if (limitingReagent?.pump_name === `Pump ${becameVariable?.pumpPosition + 1}`) {
          equivalentsData[flowRateIndex] = 'N/A';
        } else {
          equivalentsData[flowRateIndex] = Number(
            calculatedMFR / limitingReagent.molar_flow_rate[flowRateIndex]
          ).toFixed(3);
        }
      });
    }
    setMolarFlowRate(calculatedMolarFlowRate);
    setEquivalentValue(equivalentsData);
  }, [data.reactant, data.reagent]);

  useEffect(() => {
    if (editingData) {
      let type = editingData.reagent ? 'Reagent' : editingData.reactant ? 'Reactant' : 'Solvent';
      setCompound(editingData.properties);
      let compData = {
        ...data,
        [type.toLowerCase()]: {
          ...editingData,
          ...editingData.properties,
        },
        type,
      };
      if (editingData.solvent) {
        setNameSolvent(editingData.properties);
        setMw(editingData.properties.molwt);
        compData.solvent.name = { ...editingData.properties };
      }
      if (type === 'Reactant') {
        compData.reactant.limiting_reactant = editingData.limiting_reagent;
        delete compData.reactant.limiting_reagent;
      }
      setData(compData);
      let currentPumpData = getCurrentPumpByPosition();
      setIsVariablePump(currentPumpData?.properties.length > 1);
      setCurrentPump(currentPumpData);
      setMfcPump(currentPumpData.type[0] === 'MFC');
      if (currentPumpData.type[0] === 'MFC') {
        setNameGas(editingData.properties);
        setMw(editingData.properties.molwt);
        compData.solvent.name = { ...editingData.properties };
      }
      if (!!editingData.equivalents)
        if (variationPump && variationPump.properties.length !== editingData.equivalents.length) {
          const additionalArray = new Array(variationPump.properties.length - editingData.equivalents.length)
            .fill(null)
            .map(() => 'N/A');
          setEquivalentValue([...editingData.equivalents, ...additionalArray]);
        } else {
          setEquivalentValue(editingData.equivalents);
        }
      setCompoundId(editingData[type.toLowerCase()]);
      setFraction(editingData.fraction);
      if (typeOfProcess === 'Optimization' || editingData.equivalents?.length > 1) {
        setEquivalentValue(editingData.equivalents);
      }
    } else {
      reset();
      countEquivalents();
    }
  }, [editingData]);

  const countEquivalents = () => {
    let v = getVariableReactor();
    if (!editingData && !!v) {
      let arr = [];
      if (v.times.length > 1) {
        for (let i = 0; i < v.times.length; i++) {
          arr[i] = 'N/A';
        }
        setEquivalentValue(arr);
      }
    }
  };

  const validation = type => {
    const obj = {
      reagent: {
        concentration: !mfcPump ? !!compound && data.reagent.concentration <= 0 : false,
        compound: !mfcPump ? !compound : !nameGas,
      },
      reactant: {
        concentration: !mfcPump ? !!compound && data.reactant.concentration <= 0 : false,
        compound: !mfcPump ? !compound : !nameGas,
      },
      solvent: {
        name: !nameSolvent,
        fraction: nameSolvent && fraction <= 0,
      },
    };
    setErrors(obj);
    return checkIfTrue(obj[type.toLowerCase()]);
  };

  const onChangeType = (v, field) => {
    reset();
    countEquivalents();
    setData({ ...initialState, [field]: v });
  };

  useEffect(() => {
    httpSynMolDB(`get_compound_list?tag=solvent&return_compatibility=true`).then(resp => {
      setSolventsOptions(
        compareOptions(resp.compound_list, 'compound_name').sort((a, b) => {
          return a.compound_name > b.compound_name ? 1 : -1;
        })
      );
    });
    httpSynMolDB(`get_compound_list?tag=gas&return_compatibility=true`).then(resp => {
      setGasOptions(compareOptions(resp.compound_list, 'compound_name'));
    });
  }, []);

  const compareOptions = (temp, field) => {
    for (let i in temp) {
      temp[i].value = temp[i][field];
      temp[i].label = temp[i][field];
    }
    return temp;
  };

  const onCloseSidebar = () => {
    if (!edited) {
      reset();
      onClose();
    } else openCancelConfirmation(true);
  };

  const changeCompound = (e, type) => {
    setEdited(true);
    setCompound(e);
    setCompoundId(e?.compound_id);
    setData({ ...data, [type]: { ...data[type], ...e } });
  };

  const canSave = (edited && editingData) || !editingData;

  const getEquivalent = () => {
    if (typeOfProcess === 'Production') {
      return (
        <div className="row">
          <div className="column-title">Equivalents</div>
          <div className="mw">{equivalentValue && equivalentValue[0]}</div>
        </div>
      );
    } else if (typeOfProcess === 'Optimization') {
      return equivalentValue.map((i, index) => (
        <div className="row">
          {typeOfProcess === 'Optimization' && (
            <div className="column-title">{`Equivalent Q${numberOfPump()}${index + 1}`}</div>
          )}
          <div className="mw"> {!data.reagent.limiting_reagent && compound?.type !== null ? i : 1}</div>
        </div>
      ));
    } else if (typeOfProcess === 'Library generation' && equivalentValue) {
      if (variationPump && !sidebarCompoundPumpIndex)
        return variationPump.properties.map((property, i) => (
          <div className="row">
            <div className="column-title">Equivalents {COMPOUND_VARIATY[i]}</div>
            <div className="mw">{equivalentValue[i]}</div>
          </div>
        ));
      else
        return (
          <div className="row">
            <div className="column-title">Equivalents</div>
            <div className="mw">{equivalentValue[0] || 'N/A'}</div>
          </div>
        );
    }
  };
  const numberOfPump = () => getCurrentPumpByPosition()?.name?.split(' ')[1];

  return (
    <>
      <Sidebar
        width={528}
        open={open}
        onClose={onCloseSidebar}
        onSave={onSave}
        onDelete={() => {
          let currentPumpData = currentPump;
          if (!currentPump) currentPumpData = getCurrentPumpByPosition();
          onDelete(currentPumpData, editingData, compoundPosition, isVariablePump);
        }}
        title={`${editingData ? 'Edit' : 'Add'} a compound`}
        editingData={!!editingData}
        disableSave={!canSave}
        cancelText={edited ? 'Cancel' : 'Close'}
      >
        <div className="compound-wrapper">
          <div className="row">
            <div className="column-title">Chemical type</div>
            <RadioGroup
              options={mfcPump ? options.filter(i => i.label !== 'Solvent') : options}
              value={data.type}
              setValue={onChangeType}
              field="type"
              disabled={!!editingData}
            />
          </div>
          <div className="row title-number-pump">Compound for Pump {idPump}</div>
          {data.type === 'Solvent' && (
            <div>
              <div className="row">
                <div className="column-title">
                  Name {sidebarCompoundPumpIndex || ''}
                  <span className="required">*</span>
                </div>
                <Select
                  options={solventsOptions}
                  value={nameSolvent?.compound_name}
                  onChange={e => {
                    let t = solventsOptions.find(i => i.compound_name === e);
                    setEdited(true);
                    setData({
                      ...data,
                      solvent: { ...data.solvent, name: solventsOptions.find(i => i.compound_name === e) },
                    });
                    setNameSolvent(t);
                    setErrors({ ...errors, solvent: { ...errors.solvent, name: false } });
                    setMw(t.molwt);
                  }}
                  error={errors.solvent.name}
                  errorText={errorsText.solvent.name}
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </div>
              <div className="row">
                <div className="column-title">MW</div>
                <div className="mw">{!!mw ? `${mw} g/mol` : '-'} </div>
              </div>
              {!mfcPump && (
                <div className="row">
                  <div className="column-title">
                    Fraction <span className="required">*</span>
                  </div>
                  <Input
                    value={fraction}
                    regex={/[^\d.]+/g}
                    onChange={e => {
                      let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                      if ((str || !e) && e < 10000000) {
                        e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 4) : e;
                        setEdited(true);
                        setData({ ...data, solvent: { ...data.solvent, fraction: e } });
                        setFraction(e);
                        setErrors({ ...errors, solvent: { ...errors.solvent, fraction: false } });
                      }
                    }}
                    error={errors.solvent.fraction}
                    errorText={errorsText.solvent.fraction}
                    disabled={!nameSolvent}
                  />
                </div>
              )}
            </div>
          )}
          {data.type === 'Reagent' && (
            <div className="reagent">
              <div className="column">
                {!mfcPump && (
                  <div className="column-title">
                    Reagent {typeOfProcess === 'Library generation' && <span>{sidebarCompoundPumpIndex || ''}</span>}
                    <span className="required">*</span>
                  </div>
                )}
                {mfcPump ? (
                  <div className="searcher select-searcher">
                    <div className="column-title">
                      Reagent {typeOfProcess === 'Library generation' && <span>{sidebarCompoundPumpIndex || ''}</span>}
                      <span className="required">*</span>
                    </div>
                    <Select
                      options={gasOptions}
                      value={nameGas?.compound_name}
                      onChange={e => {
                        let t = gasOptions.find(i => i.compound_name === e);
                        setEdited(true);
                        setData({
                          ...data,
                          reagent: { ...data.reagent, name: gasOptions.find(i => i.compound_name === e) },
                        });
                        setNameGas(t);
                        setErrors({ ...errors, reagent: { ...errors.reagent, name: false } });
                        setMw(t.molwt);
                      }}
                      error={errors.reagent.name}
                      errorText={errorsText.reagent.name}
                    />
                  </div>
                ) : (
                  <div className="searcher">
                    <SmilesSearcher
                      changeSelect={e => {
                        changeCompound(e, 'reagent');
                      }}
                      onDeleteAction={() => {
                        setCompound(null);
                        setEdited(true);
                        reset();
                        setData({ ...initialState, type: data.type });
                      }}
                      resetError={() => {
                        setErrors({ ...errors, reagent: { ...errors.reagent, compound: false } });
                      }}
                      error={errors.reagent.compound}
                      errorText={errorsText.reagent.compound}
                      data={data.reagent}
                    />
                  </div>
                )}
              </div>
              <div className="row before-searcher">
                <div className="column-title">MW</div>
                <div className="mw">
                  {(!!compound && compound?.type !== null) || !!mw ? `${!!mw ? mw : compound.molwt} g/mol` : 'N/A'}
                </div>
              </div>
              {!mfcPump && (
                <div className="row">
                  <div className="column-title">
                    Concentration <span className="required">*</span>
                  </div>
                  <Input
                    value={data.reagent.concentration}
                    onChange={e => {
                      setEdited(true);
                      let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                      if ((str || !e) && e < 10000000) {
                        e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 4) : e;
                        setData({ ...data, reagent: { ...data.reagent, concentration: e } });
                        setErrors({ ...errors, reagent: { ...errors.reagent, concentration: false } });
                      }
                    }}
                    field="concentration"
                    disabled={!compoundId}
                    error={errors.reagent.concentration}
                    errorText={errorsText.reagent.concentration}
                  />{' '}
                  <div className="unit-concentration">M</div>
                </div>
              )}
              {!mfcPump && getEquivalent()}
              {!mfcPump && (
                <div className="row checkbox-in-row">
                  <div className="column-title">Limiting reagent?</div>
                  <Checkbox
                    className="ckeckbox-center"
                    value={data.reagent.limiting_reagent}
                    onChange={e => {
                      setEdited(true);
                      setData({ ...data, reagent: { ...data.reagent, limiting_reagent: e } });
                      if (!!e) setEquivalentValue(equivalentValue.map(() => 1));
                    }}
                    disabled={!compoundId}
                  />
                </div>
              )}
            </div>
          )}
          {data.type === 'Reactant' && (
            <div className="reagent">
              <div className="column">
                {!mfcPump && (
                  <div className="column-title">
                    {'Reactant '}
                    {typeOfProcess === 'Library generation' && <span>{sidebarCompoundPumpIndex || ''}</span>}
                    <span className="required">*</span>
                  </div>
                )}
                {mfcPump ? (
                  <div className="searcher select-searcher">
                    <div className="column-title">
                      Reactant {typeOfProcess === 'Library generation' && <span>{sidebarCompoundPumpIndex || ''}</span>}
                      <span className="required">*</span>
                    </div>
                    <Select
                      options={gasOptions}
                      value={nameGas?.compound_name}
                      onChange={e => {
                        let t = gasOptions.find(i => i.compound_name === e);
                        setEdited(true);
                        setData({
                          ...data,
                          reactant: { ...data.reactant, name: gasOptions.find(i => i.compound_name === e) },
                        });
                        setNameGas(t);
                        setErrors({ ...errors, reactant: { ...errors.reactant, name: false } });
                        setMw(t.molwt);
                      }}
                      error={errors.reactant.name}
                      errorText={errorsText.reactant.name}
                    />
                  </div>
                ) : (
                  <div className="searcher">
                    <SmilesSearcher
                      changeSelect={e => {
                        changeCompound(e, 'reactant');
                      }}
                      onDeleteAction={() => {
                        setCompound(null);
                        setEdited(true);
                        reset();
                        setData({ ...initialState, type: data.type });
                      }}
                      data={data.reactant}
                      error={errors.reactant.compound}
                      errorText={errorsText.reactant.compound}
                      resetError={() => {
                        setErrors({ ...errors, reactant: { ...errors.reactant, compound: false } });
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="row before-searcher">
                <div className="column-title">MW</div>
                {(!!compound && compound?.type !== null) || !!mw ? `${!!mw ? mw : compound.molwt} g/mol` : 'N/A'}
              </div>
              {!mfcPump && (
                <div className="row">
                  <div className="column-title">
                    Concentration <span className="required">*</span>
                  </div>
                  <Input
                    value={data.reactant.concentration}
                    onChange={e => {
                      setEdited(true);
                      let str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                      if ((str || !e) && e < 10000000) {
                        e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 4) : e;
                        setData({ ...data, reactant: { ...data.reactant, concentration: e } });
                        setErrors({ ...errors, reactant: { ...errors.reactant, concentration: false } });
                      }
                    }}
                    field="concentration"
                    disabled={!compoundId}
                    error={errors.reactant.concentration}
                    errorText={errorsText.reactant.concentration}
                  />{' '}
                  <div className="unit-concentration">M</div>
                </div>
              )}
              {!mfcPump && getEquivalent()}
              {!mfcPump && (
                <div className="row">
                  <div className="column-title">Limiting reagent?</div>
                  <Checkbox
                    value={data.reactant.limiting_reactant}
                    onChange={e => {
                      setEdited(true);
                      setData({ ...data, reactant: { ...data.reactant, limiting_reactant: e } });
                      if (!!e) setEquivalentValue(equivalentValue.map(() => 1));
                    }}
                    disabled={!compoundId}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Sidebar>
      <Popup
        open={cancelConfirmation}
        title="Warning"
        handleSubmit={() => {
          onClose();
          reset();
          openCancelConfirmation(false);
        }}
        handleCancel={() => {
          openCancelConfirmation(false);
        }}
        textSubmit="Proceed"
        loading={false}
      >
        <span>All changes will be lost, proceed?</span>
      </Popup>
    </>
  );
};
