import React from 'react';
import './style.scss';
import { ReactComponent as Check } from '../../../dist/icons/experiment/check.svg';
import { ReactComponent as Pause } from '../../../dist/icons/experiment/pause.svg';
import { ReactComponent as Play } from '../../../dist/icons/experiment/play.svg';
import { ReactComponent as Stop } from '../../../dist/icons/experiment/stop.svg';
import { SYNJET_EXPERIMENT_STATUSES } from '../../../constants';
import classNames from 'classnames';

const getIconAndColorClass = status => {
  switch (status) {
    case SYNJET_EXPERIMENT_STATUSES.PENDING.value:
      return {
        icon: <Stop className="icon" />,
        colorClass: 'experiment-status-icon--pending',
      };
    case SYNJET_EXPERIMENT_STATUSES.RUNNING.value:
      return {
        icon: <Play className="icon" />,
        colorClass: 'experiment-status-icon--in-progress',
      };
    case SYNJET_EXPERIMENT_STATUSES.PAUSED.value:
      return {
        icon: <Pause className="icon" />,
        colorClass: 'experiment-status-icon--paused',
      };
    case SYNJET_EXPERIMENT_STATUSES.ABORTED.value:
      return {
        icon: <Stop className="icon" />,
        colorClass: 'experiment-status-icon--aborted',
      };
    case SYNJET_EXPERIMENT_STATUSES.COMPLETED.value:
      return {
        icon: <Check className="icon" />,
        colorClass: 'experiment-status-icon--pending',
      };
    default:
      return {
        icon: null,
        colorClass: null,
      };
  }
};

export const ExperimentStatus = ({ status }) => {
  const { icon, colorClass } = getIconAndColorClass(status);
  return <span className={classNames('experiment-status-icon', colorClass)}>{icon}</span>;
};
