import { getMappedSolvents } from './getMappedSolvents';

const setRowTable = (dispenser, idx, getCompound) => {
  const compound = getCompound(dispenser.compound_id);

  return {
    dispenserUuid: dispenser.uuid,
    name: {
      value: compound,
    },
    solvent: { value: getMappedSolvents(dispenser?.solvents, getCompound) },
    mw: { value: compound?.molwt },
    concentration: { value: dispenser.concentration },
    dispenser: {
      backpack: dispenser.backpack,
      fluid_body: dispenser.fluid_body,
      calibration: dispenser.calibration,
    },
    calc_volume: { value: dispenser.calculated_volume },
    calc_mass: { value: dispenser.calculated_mass },
    actual_volume: { value: dispenser.actual_volume },
    actual_mass: { value: dispenser.actual_mass },
    has_sent: { value: dispenser.has_sent || false },
    dispenser_number: dispenser.number,
  };
};

export default setRowTable;
