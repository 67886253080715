import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { editModeSelector } from '../store/common/common.selector';

export const OnBeforeLeave = () => {
  const editMode = useSelector(editModeSelector);
  useEffect(() => {
    const onBeforeLeave = event => {
      if (!editMode) {
        return false;
      }

      event.preventDefault();
      event.returnValue = true;
      return true;
    };

    window.addEventListener('beforeunload', onBeforeLeave);

    return () => window.removeEventListener('beforeunload', onBeforeLeave);
  }, [editMode]);

  // without returned element browser would not show leave confirmation
  return <div className="on-before-leave-element" />;
};
