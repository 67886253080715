import createReducer from '../createReducer';
import * as actionsTypes from './synjet.actionTypes';
import {
  SYNJET_INITIAL_CONDITIONS,
  SYNJET_PRO_INITIAL_CONDITIONS,
  SYNJET_INITIAL_COMPOUND,
  SYNJET_INITIAL_COMPOUND_SCRINNING,
  SYNJET_INITIAL_STEP,
  SYNJET_INITIAL_FIXED_STEP,
  SYNJET_INITIAL_RULE,
  SYNJET_INITIAL_QUENCHING,
} from '../../constants/synjet';
import * as commonActions from '../common/common.actionTypes';
import { DEVICES } from '../../constants';

const initialState = {
  steps: [SYNJET_INITIAL_STEP],
  process: null,
  rules: [SYNJET_INITIAL_RULE],
  experimentData: [],
  hardwareSummary: [],
  processData: {
    process_steps: [],
  },
  errors: [],
  batchAbortedErrorShown: false,
  stateCollapse: false,
  products: [],
  expectedIntermediates: [],
  quenching: [{ ...SYNJET_INITIAL_QUENCHING, name: '' }],
  device: '',
  forceProductConditionGeneration: false,
  forceProductConditionVariableGeneration: false,
  forceProductFromEIGeneration: false,
};

function setUpdatedCompoundList(state, data) {
  const { compoundList, compoundType, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              [compoundType]: compoundList,
            },
          }
        : stepItem
    ),
  };
}

function setUpdatedCompoundGroup(state, data) {
  const { compoundGroup, compoundType, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              [compoundType]: stepItem.compounds[compoundType].map(group =>
                group.name === compoundGroup.name ? compoundGroup : group
              ),
            },
          }
        : stepItem
    ),
  };
}

function setUpdatedCompoundGroups(state, data) {
  const { reactantGroups, reagentGroups, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              reactantGroups,
              reagentGroups,
            },
          }
        : stepItem
    ),
  };
}

function setQuenching(state, data) {
  const { quenching } = data;
  return {
    ...state,
    quenching,
  };
}

function updateSynjetProData(state, data) {
  const { quenching, products, expectedIntermediates } = data;
  return {
    ...state,
    quenching,
    products,
    expectedIntermediates,
  };
}

function addQuenchingItem(state, data) {
  const { limitingCompoundGroup } = data;
  const quenchingItem = limitingCompoundGroup
    ? {
        ...SYNJET_INITIAL_QUENCHING,
        equivalents: new Array(limitingCompoundGroup.compoundsList.length).fill(null).map(() => null),
        volumes: new Array(limitingCompoundGroup.compoundsList.length).fill(null).map(() => null),
      }
    : SYNJET_INITIAL_QUENCHING;
  return {
    ...state,
    quenching: [...state.quenching, quenchingItem],
  };
}

function setUpdatedCompound(state, data) {
  const { reagents, reactants, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              reagents,
              reactants,
            },
          }
        : stepItem
    ),
  };
}

function setProduct(state, data) {
  const { product, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            product,
          }
        : stepItem
    ),
  };
}

function setAddedCompoundToList(state, data) {
  const { compoundType, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              [compoundType]: [
                ...stepItem.compounds[compoundType],
                stepItem.isFixed ? SYNJET_INITIAL_COMPOUND_SCRINNING : SYNJET_INITIAL_COMPOUND,
              ],
            },
          }
        : stepItem
    ),
  };
}

function setAddedCompoundGroupToList(state, data) {
  const { compoundType, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              [compoundType]: [
                ...stepItem.compounds[compoundType],
                {
                  name: `${compoundType.replace('Groups', '')} ${stepItem?.compounds[compoundType]?.length + 1}`,
                  isLimiting: false,
                  compoundsList: [SYNJET_INITIAL_COMPOUND_SCRINNING],
                },
              ],
            },
          }
        : stepItem
    ),
  };
}

function setAddedCompoundToGroup(state, data) {
  const { compoundType, step, compoundGroup, limitingCompoundGroup } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              [compoundType]: stepItem.compounds[compoundType].map(group =>
                group.name === compoundGroup.name
                  ? {
                      ...compoundGroup,
                      compoundsList: [
                        ...compoundGroup.compoundsList,
                        limitingCompoundGroup && state.steps.length === 1
                          ? {
                              ...SYNJET_INITIAL_COMPOUND_SCRINNING,
                              equivalents:
                                compoundGroup.name === limitingCompoundGroup.name
                                  ? [1]
                                  : new Array(limitingCompoundGroup.compoundsList.length).fill(null).map(() => null),
                              volumes:
                                compoundGroup.name === limitingCompoundGroup
                                  ? SYNJET_INITIAL_COMPOUND_SCRINNING.volumes
                                  : new Array(limitingCompoundGroup.compoundsList.length).fill(null).map(() => null),
                            }
                          : SYNJET_INITIAL_COMPOUND_SCRINNING,
                      ],
                    }
                  : group
              ),
            },
          }
        : stepItem
    ),
  };
}

function setDeleteCompoundGroup(state, data) {
  const { compoundType, step, compoundGroup } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              [compoundType]: stepItem.compounds[compoundType].filter(group => group.name !== compoundGroup.name),
            },
          }
        : stepItem
    ),
  };
}

function setAddedNullCompoundToGroup(state, data) {
  const { compoundType, step, compoundGroup } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            compounds: {
              ...stepItem.compounds,
              [compoundType]: stepItem.compounds[compoundType].map(group =>
                group.name === compoundGroup.name
                  ? {
                      ...compoundGroup,
                      compoundsList: [
                        ...compoundGroup.compoundsList,
                        {
                          isNullCompound: true,
                          smiles: null,
                          solvents: null,
                          concentration: null,
                          isLimiting: false,
                          equivalents: [0],
                          volumes: [0],
                        },
                      ],
                    }
                  : group
              ),
            },
          }
        : stepItem
    ),
  };
}

function updateConditions(state, data) {
  const { conditionIndex, conditionType, data: conditionData, step } = data;
  const { steps } = state;
  const forceProductConditionGeneration = conditionType !== 'dispensing';
  const forceProductConditionVariableGeneration = conditionType !== 'dispensing' && !steps?.[step]?.isFixed;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            conditions: stepItem.conditions.map((condition, index) =>
              index === conditionIndex && (conditionType == 'temperature' || conditionType == 'time')
                ? {
                    ...condition,
                    [conditionType]: conditionData,
                  }
                : conditionType != 'temperature' && conditionType != 'time'
                ? {
                    ...condition,
                    [conditionType]: conditionData,
                  }
                : condition
            ),
          }
        : stepItem
    ),
    forceProductConditionGeneration,
    forceProductConditionVariableGeneration,
  };
}

function addNewCondition(state, data) {
  const { step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            conditions: [
              ...stepItem.conditions,
              state.device === DEVICES.SYNJET
                ? { ...SYNJET_INITIAL_CONDITIONS, dispensing: stepItem.conditions[0].dispensing }
                : { ...SYNJET_PRO_INITIAL_CONDITIONS, dispensing: stepItem.conditions[0].dispensing },
            ],
          }
        : stepItem
    ),
    forceProductConditionGeneration: true,
    forceProductConditionVariableGeneration: true,
  };
}

function setForceProductCondition(state, data) {
  const { forceProductConditionGeneration } = data;
  return {
    ...state,
    forceProductConditionGeneration,
  };
}

function deleteCondition(state, data) {
  const { conditionIndex, step } = data;
  return {
    ...state,
    steps: state.steps.map((stepItem, stepIndex) =>
      stepIndex === step
        ? {
            ...stepItem,
            conditions: stepItem.conditions.filter((condition, index) => index !== conditionIndex),
          }
        : stepItem
    ),
    forceProductConditionGeneration: true,
    forceProductConditionVariableGeneration: true,
  };
}
function cleanProcess(state) {
  return { ...state, process: null };
}

function setProcess(state, data) {
  const condition = {
    ...(data.payload.process.device === DEVICES.SYNJETPRO ? SYNJET_PRO_INITIAL_CONDITIONS : SYNJET_INITIAL_CONDITIONS),
  };
  const steps = state.steps.map(item => ({ ...item, conditions: [condition] }));
  return {
    ...state,
    steps,
    process: data.payload,
    device: data.payload.process.device,
  };
}

function resetStore() {
  return initialState;
}

function setTwoStepProcess(state, data) {
  const { variableStep } = data;
  const steps = [SYNJET_INITIAL_STEP, SYNJET_INITIAL_FIXED_STEP];
  return {
    ...state,
    steps: variableStep === 0 ? steps : steps.reverse(),
  };
}

const updateRules = (state, data) => ({ ...state, rules: data.rules });

function setExperimentsData(state, action) {
  return {
    ...state,
    processData: action.data,
  };
}

function setProductsData(state, action) {
  const additionalData = action.data?.length
    ? {
        forceProductConditionGeneration: false,
      }
    : {};
  return {
    ...state,
    products: action.data,
    ...additionalData,
  };
}

function setEIData(state, action) {
  const { expectedIntermediates, forceProductFromEIGeneration } = action.data;
  return {
    ...state,
    expectedIntermediates,
    forceProductFromEIGeneration,
  };
}

function setSteps(state, action) {
  return {
    ...state,
    steps: action.data,
  };
}

function setInitialSteps(state) {
  return {
    ...state,
    steps: [SYNJET_INITIAL_STEP],
    process: null,
  };
}

function setValidationErrors(state, { data }) {
  return { ...state, errors: data };
}

function setBatchErrorShown(state, { shown }) {
  return { ...state, batchAbortedErrorShown: shown };
}

function updateStateCollapse(state) {
  return { ...state, stateCollapse: !state.stateCollapse };
}

const synjetReducer = createReducer(initialState, {
  [actionsTypes.SYNJET_SET_STEPS]: setSteps,
  [actionsTypes.SYNJET_RESET_STEPS]: setInitialSteps,
  [actionsTypes.SYNJET_UPDATE_COMPOUND_LIST]: setUpdatedCompoundList,
  [actionsTypes.SYNJET_UPDATE_COMPOUND_GROUP]: setUpdatedCompoundGroup,
  [actionsTypes.SYNJET_UPDATE_COMPOUND_GROUPS]: setUpdatedCompoundGroups,
  [actionsTypes.SYNJET_ADD_COMPOUND_TO_LIST]: setAddedCompoundToList,
  [actionsTypes.SYNJET_ADD_COMPOUND_GROUP_TO_LIST]: setAddedCompoundGroupToList,
  [actionsTypes.SYNJET_ADD_COMPOUND_TO_GROUP]: setAddedCompoundToGroup,
  [actionsTypes.SYNJET_DELETE_COMPOUND_GROUP]: setDeleteCompoundGroup,
  [actionsTypes.SYNJET_ADD_NULL_COMPOUND_TO_GROUP]: setAddedNullCompoundToGroup,
  [actionsTypes.SYNJET_UPDATE_CONDITIONS]: updateConditions,
  [actionsTypes.SYNJET_ADD_CONDITION]: addNewCondition,
  [actionsTypes.SYNJET_DELETE_CONDITION]: deleteCondition,
  [actionsTypes.SYNJET_UPDATE_PRODUCT]: setProduct,
  [actionsTypes.SYNJET_UPDATE_COMPOUND]: setUpdatedCompound,
  [actionsTypes.SYNJET_SET_PROCESS]: setProcess,
  [actionsTypes.SYNJET_SET_TWO_STEP_PROCESS]: setTwoStepProcess,
  [actionsTypes.SYNJET_RESET_STORE]: resetStore,
  [commonActions.CLEAN_PROCESS_BUILDER_SYNJET]: cleanProcess,
  [actionsTypes.SYNJET_SET_RULE]: updateRules,
  [actionsTypes.SYNJET_GENERATE_EXPERIMENTS_DATA]: setExperimentsData,
  [actionsTypes.SET_VALIDATION_ERRORS_SYNJET]: setValidationErrors,
  [actionsTypes.SET_BATCH_ERROR_SHOWN]: setBatchErrorShown,
  [actionsTypes.UPDATE_STATE_COLLAPSE]: updateStateCollapse,
  [actionsTypes.SYNJET_PRO_GENERATE_PRODUCTS_DATA]: setProductsData,
  [actionsTypes.SYNJET_PRO_GENERATE_EI_DATA]: setEIData,
  [actionsTypes.SYNJET_PRO_UPDATE_QUENCHING_LIST]: setQuenching,
  [actionsTypes.SYNJET_PRO_ADD_QUENCHING_ITEM]: addQuenchingItem,
  [actionsTypes.SYNJET_PRO_DATA_UPDATE]: updateSynjetProData,
  [actionsTypes.SYNJET_PRO_SET_FORCE_CONDITION_VARIABLE_GENERATION]: setForceProductCondition,
});

export default synjetReducer;
