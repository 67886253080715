import React, { useEffect, useState } from 'react';
import { Slider } from 'antd';
import './style.scss';

export const RangeSlider = ({
  value,
  defaultValue = [0, 0],
  onChange,
  modeSegment,
  date,
  noMarks = false,
  customId,
  ...props
}) => {
  const [sliderValue, setSliderValue] = useState(value ? value : null);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);
  let marks = {};
  Array.from(Array(defaultValue[1] + 1).keys()).forEach(i => (marks[i] = i + 1));

  if (noMarks) marks = {};

  const idContainer = customId || 'range-slider-container';
  if (idContainer && !document.getElementById(idContainer)) return <></>;
  return (
    <div className="range-slider-container" id={idContainer}>
      <Slider
        value={sliderValue}
        defaultValue={defaultValue}
        tooltipVisible={false}
        marks={marks}
        getTooltipPopupContainer={() => {
          if (document.getElementById(idContainer)) return document.getElementById(idContainer);
          return document.querySelector('body');
        }}
        step={1}
        max={defaultValue[1]}
        range
        onChange={setSliderValue}
        onAfterChange={onChange}
        {...props}
      />
    </div>
  );
};
