import createReducer from '../createReducer.js';
import * as actions from './projects.actionTypes';
import * as commonActions from '../common/common.actionTypes';

const initialState = {
  projects: {},
  details: null,
  allProjects: {},
  filters: {
    types: [],
    statuses: [],
    programs: [],
  },
};

function clean(state) {
  return { ...state, projects: {} };
}

function setProjects(state, data) {
  return { ...state, projects: data.payload };
}

function setDetailsProject(state, data) {
  return { ...state, details: data.payload };
}
function setFiltersData(state, data) {
  return {
    ...state,
    filters: data.payload,
  };
}
function updateDetailsProject(state, data) {
  return { ...state, details: { ...state.details, ...data.payload } };
}

function setAllProjects(state, data) {
  return { ...state, allProjects: data.payload };
}

const projectReducer = createReducer(initialState, {
  [actions.SET_PROJECTS_LIST]: setProjects,
  [actions.SET_DETAILS_PROJECT]: setDetailsProject,
  [actions.UPDATE_DETAILS_PROJECT]: updateDetailsProject,
  [actions.SET_FILTERS_DATA]: setFiltersData,
  [actions.SET_ALL_PROJECTS_LIST]: setAllProjects,
  [commonActions.CLEAN_USERS]: clean,
});

export default projectReducer;
