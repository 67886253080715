import './styles.scss';

import React, { useState } from 'react';

import { COLUMNS_TITLES } from '@templates/CustomizationTable/constants';
import { getExtensibleTableFields } from 'store/experiment/experiment.actions';

import { TableHeaderWithActions, useTableController, VirtualTable } from '@molecules';
import { CreateExperimentFieldPopup, EditExperimentFieldPopup } from '@templates';

import useColumns from './hooks/useColumns';
import { getMappedData } from './utils/getMappedData';

export const CustomizationTable = () => {
  const columns = useColumns();
  const [experimentFieldPopup, setExperimentFieldPopup] = useState(false);
  const [fieldID, setFieldID] = useState('');

  const fetchData = async ({ page, pageSize, activeSort }) =>
    getExtensibleTableFields({ page, pageSize, activeSort }).then(resp => getMappedData(resp));

  const { tableData, totalCount, loadMore, isLoading, onSortClick, getInitialData, error } = useTableController({
    fetchData,
  });

  const openEditFieldPopup = uuid => {
    setFieldID(uuid);
    setExperimentFieldPopup(true);
  };

  return (
    <div className="customization-table">
      <TableHeaderWithActions
        title="Customization of columns"
        count={totalCount}
        actions={
          <CreateExperimentFieldPopup
            onUpdateTable={getInitialData}
            experimentFieldPopup={experimentFieldPopup}
            setExperimentFieldPopup={setExperimentFieldPopup}
            editing={fieldID}
          />
        }
      />
      <VirtualTable
        sortOrder={COLUMNS_TITLES.name.value}
        columns={columns}
        actions={{
          onClickRow: openEditFieldPopup,
          onSortClick,
          loadMore,
        }}
        error={error}
        tableData={tableData}
        isLoading={isLoading}
        totalCount={totalCount}
      />
      {experimentFieldPopup && fieldID && (
        <EditExperimentFieldPopup
          uuid={fieldID}
          setExperimentFieldPopup={setExperimentFieldPopup}
          onUpdateTable={getInitialData}
          setFieldID={setFieldID}
        />
      )}
    </div>
  );
};
