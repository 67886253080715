import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SynJetCompound } from '../../index';
import { Button, openNotification, Popup, Tooltip } from '../../../Common';
import {
  addCompoundToGroup,
  deleteCompoundGroup,
  handleCompoundAddNullControl,
  updateCompoundGroup,
} from '../../../../store/synjet/synjet.actions';
import './index.scss';
import { Switch } from 'antd';
import { toCapitalizeChart1 } from '../../../../utils';
import { getSynJetCompoundLetter } from '../../../../utils/synjetHelpers';

const SynJetCompoundGroup = ({
  type,
  compoundGroup,
  compoundOffset = 0,
  limitingCompoundGroup,
  step,
  isCompoundAddAvailable,
  viewOnly,
  disableLimiting,
  highlightErrors,
  isLibGen,
}) => {
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const addOptionAvailable = compoundGroup.compoundsList.length < 5 && isCompoundAddAvailable;
  const hasSolventOnly = !!compoundGroup.compoundsList.find(compound => compound.isNullCompound);
  const addSolventOnlyAvailable =
    !(compoundGroup.isLimiting || hasSolventOnly) && compoundGroup.compoundsList.length < 5;
  const isLimitingDisabled = (limitingCompoundGroup && !compoundGroup.isLimiting) || hasSolventOnly;

  const getLimitingTooltipText = () =>
    disableLimiting
      ? "You can't make compound limiting on variable step"
      : limitingCompoundGroup
      ? `${toCapitalizeChart1(limitingCompoundGroup?.name)} is limiting. Please unmark it to proceed`
      : 'Please remove the ‘Null control’ option to mark the reagent as limiting.';

  const handleCompoundChange = (updatedCompound, index) => {
    const updatedList = compoundGroup.compoundsList.map((compound, compoundIndex) =>
      compoundIndex === index ? updatedCompound : compound
    );
    dispatch(
      updateCompoundGroup(
        {
          ...compoundGroup,
          compoundsList: updatedList,
        },
        `${type}Groups`,
        step
      )
    );
  };

  const handleCompoundFromGroupDelete = (index, compoundName) => {
    const updatedList = compoundGroup.compoundsList.filter((compound, compoundIndex) => compoundIndex !== index);
    dispatch(
      updateCompoundGroup(
        {
          ...compoundGroup,
          compoundsList: updatedList,
          isLimiting: updatedList.length ? compoundGroup.isLimiting : false,
        },
        `${type}Groups`,
        step
      )
    );
    openNotification(`${compoundName} was removed`);
  };

  const handleLimitingGroupChange = isLimiting => {
    const updatedCompoundsList = compoundGroup.compoundsList.map(compound => ({
      ...compound,
      equivalents: isLimiting ? [1] : [null],
    }));
    dispatch(
      updateCompoundGroup(
        {
          ...compoundGroup,
          compoundsList: updatedCompoundsList,
          isLimiting,
        },
        `${type}Groups`,
        step
      )
    );
  };

  const handleCompoundAdd = () => {
    dispatch(addCompoundToGroup(compoundGroup, `${type}Groups`, step, limitingCompoundGroup));
  };

  const handleNullCompoundAdd = () => {
    dispatch(handleCompoundAddNullControl(compoundGroup, `${type}Groups`, step));
  };

  const handleCompoundGroupDelete = () => {
    dispatch(deleteCompoundGroup(compoundGroup, `${type}Groups`, step));
    openNotification(`${compoundGroup?.name} was removed`);
    setDeleteModalOpen(false);
  };

  return (
    <div className="synjet-compound-group">
      <h5 className="synjet-compound-group__title">{compoundGroup?.name}</h5>
      {!!compoundGroup?.compoundsList?.length && (
        <>
          <div className="row">
            {isLimitingDisabled || disableLimiting ? (
              <Tooltip title={getLimitingTooltipText()}>
                <Switch checked={false} disabled />
              </Tooltip>
            ) : (
              <Switch checked={compoundGroup.isLimiting} onChange={handleLimitingGroupChange} />
            )}
            <div className="synjet-compound-group__header">
              <span className="limiting-title">Limiting reagent</span>
              <span className="title">Solvent</span>
              <span className="title">Concentration (M)</span>
              <div
                className="equivalent"
                style={{
                  gridTemplateColumns:
                    limitingCompoundGroup && !compoundGroup.isLimiting && !disableLimiting
                      ? `repeat(${limitingCompoundGroup.compoundsList.length + 1}, 1fr)`
                      : '1fr 1fr',
                }}
              >
                <span />
                {limitingCompoundGroup ? (
                  compoundGroup.isLimiting ? (
                    <span className="title">Fixed</span>
                  ) : disableLimiting ? (
                    <span className="title">Fixed A</span>
                  ) : (
                    limitingCompoundGroup.compoundsList.map((compound, index) => (
                      <span className="title">{`Fixed ${getSynJetCompoundLetter(index, 0)}`}</span>
                    ))
                  )
                ) : (
                  <span className="title" />
                )}
              </div>
            </div>
          </div>
          {compoundGroup?.compoundsList.map((compound, compoundIndex) => (
            <SynJetCompound
              compoundInfo={compound}
              key={compoundIndex + type}
              type={type}
              limitingCompoundGroup={limitingCompoundGroup}
              isLimitingCompoundGroup={compoundGroup?.isLimiting}
              isScreening
              letter={compound.name}
              handleSubmit={compound => handleCompoundChange(compound, compoundIndex)}
              handleDelete={compoundName => handleCompoundFromGroupDelete(compoundIndex, compoundName)}
              viewOnly={viewOnly}
              disableLimiting={disableLimiting}
              highlightErrors={highlightErrors}
              isLibGen={isLibGen}
              isCompoundAddAvailable={isCompoundAddAvailable}
              disableSolventTypeChange={!!step}
            />
          ))}
        </>
      )}

      <div className="synjet-compound-group__btn-container">
        <div className="row">
          {addOptionAvailable && (
            <Button className="add-option-btn" type="secondary" onClick={handleCompoundAdd}>
              Add option
            </Button>
          )}
          {addSolventOnlyAvailable && (
            <Button type="secondary" onClick={handleNullCompoundAdd}>
              Add “solvent only” option
            </Button>
          )}
        </div>
        <Button type="secondary" onClick={() => setDeleteModalOpen(true)}>
          Remove {compoundGroup?.name}
        </Button>
      </div>
      <Popup
        title={`Remove ${compoundGroup?.name}`}
        open={deleteModalOpen}
        handleSubmit={handleCompoundGroupDelete}
        textSubmit="Remove"
        handleCancel={() => setDeleteModalOpen(false)}
      >
        {`Are you sure you want to remove ${compoundGroup?.name}?`}
        <br />
        Please note: the related compounds will be removed as well
      </Popup>
    </div>
  );
};

export default SynJetCompoundGroup;
