import React from 'react';
import { EyeOutlined, LockOutlined } from '@ant-design/icons';
import { Avatar, ContextMenu, Tooltip } from 'components';
import { transferIsoToMMDDYYmmHHutc, transferIsoToMMDDYYutc } from 'utils/date';
import 'modules/_screens/Processes/style.scss';
import { checkIfMyLock } from 'utils/checkIfMyLock';
import { PROCESS_TYPES, USER_ROLES } from 'constants/common';

export const useColumns = ({
  openRenamePopup,
  openClonePopup,
  openUnlockPopup,
  sortedInfo,
  permissions,
  openPopupSaveFromRoute,
  openProcessPreview,
  activeTab,
}) => {
  const processName = {
    title: 'Process name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    render: (text, data) => (
      <div className="cutted-text-wrapper">
        <Tooltip
          title={
            data.processLock?.createdBy &&
            `The process is being edited by ${data.processLock.createdBy.firstName} ${data.processLock.createdBy.lastName}`
          }
        >
          <LockOutlined />
        </Tooltip>
        <Tooltip title={text}>
          <div className="cutted-text">{text}</div>
        </Tooltip>
        <Tooltip title="Open process preview">
          <EyeOutlined onClick={() => openProcessPreview(data)} />
        </Tooltip>
      </div>
    ),
  };
  const projectName = {
    title: 'Project name',
    dataIndex: 'project',
    key: 'project',
    sorter: true,
    sortOrder: sortedInfo?.columnKey === 'project' && sortedInfo?.order,
    render: text =>
      text ? (
        <div className="cutted-text-wrapper">
          <Tooltip title={text.name}>
            <div className="cutted-text">{text.name}</div>
          </Tooltip>
        </div>
      ) : (
        ''
      ),
  };
  const route = {
    title: 'Route',
    dataIndex: 'route',
    key: 'route',
    width: 160,
    render: (field, data) => (
      <div>
        {data.route ? (
          <div className="cutted-text-wrapper">
            <Tooltip title={data.route.name || data.route.key}>
              <div className="cutted-text">{data.route.name || data.route.key}</div>
            </Tooltip>{' '}
            <Tooltip title="Open route preview">
              <EyeOutlined onClick={() => openPopupSaveFromRoute(data)} />
            </Tooltip>{' '}
          </div>
        ) : (
          <>N / A</>
        )}
      </div>
    ),
  };
  const createdBy = {
    title: 'Created by',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 160,
    render: text => {
      const name = `${text.firstName} ${text.lastName}`;
      return (
        <Tooltip title={name}>
          <div className="cell-avatar">
            {!!text.firstName && !!text.lastName ? (
              <Avatar>{name.match(/\b(\w)/g).join('')}</Avatar>
            ) : (
              <Avatar> </Avatar>
            )}
          </div>
        </Tooltip>
      );
    },
  };
  const createdDate = {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    height: 60,
    sorter: true,
    sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
    render: text => (
      <div className="data">
        <Tooltip title={transferIsoToMMDDYYmmHHutc(text)}>{transferIsoToMMDDYYutc(text)}</Tooltip>
      </div>
    ),
  };
  const typeProcess =
    activeTab === PROCESS_TYPES.SynJet.key || activeTab === PROCESS_TYPES.SynJetPro.key
      ? {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          height: 60,
          render: text => <div className="data">{text}</div>,
        }
      : {};

  const lastModify = {
    title: 'Last modified',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    height: 60,
    sorter: true,
    sortOrder: sortedInfo?.columnKey === 'updatedAt' ? sortedInfo?.order : false,
    render: text => (
      <div className="data">
        <Tooltip title={transferIsoToMMDDYYmmHHutc(text)}>{transferIsoToMMDDYYutc(text)}</Tooltip>
      </div>
    ),
  };
  const status = {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
    render: text => <div>{text.charAt(0).toUpperCase() + text.slice(1)}</div>,
  };
  const context = {
    title: '',
    dataIndex: 'context',
    key: 'context',
    width: 42,
    render: (value, row) => {
      let check = true;
      const sysAdmin = permissions.roles?.find(i => i === USER_ROLES.sysAdmin);
      const designer = permissions.roles?.find(i => i === USER_ROLES.chemistryDesigner);
      if (!sysAdmin || !designer) {
        check = permissions.projects?.find(i => i.uuid === row.project?.uuid);
      }
      if (check) {
        const disabled = checkIfMyLock(row.processLock);
        const options = [
          {
            title: 'Rename',
            disabled,
            onClick: () => {
              openRenamePopup(row);
            },
          },
          {
            title: 'Clone',
            onClick: () => {
              openClonePopup(row);
            },
          },
        ];
        if (disabled && sysAdmin) {
          options.push({
            title: 'Unlock',
            onClick: () => {
              openUnlockPopup(row);
            },
          });
        }
        return <ContextMenu options={options} />;
      }
    },
  };

  return [processName, projectName, route, typeProcess, createdBy, createdDate, lastModify, status, context];
};
