import React from 'react';
import './style.scss';
import { RightOutlined } from '@ant-design/icons';
import cn from 'classnames';

export const ListTabs = ({ options, updateTab, activeTab }) => {
  return (
    <div className="list-tabs">
      {options.map(i => (
        <div
          onClick={() => updateTab(i.route)}
          key={i.route}
          className={cn('list-tabs_item', { 'list-tabs_item_active': activeTab === i.route })}
        >
          <span className="list-tabs_item_name">{i.name}</span>
          <RightOutlined
            className={cn('list-tabs_item_icon', { 'list-tabs_item_icon_primary': activeTab !== i.route })}
          />
        </div>
      ))}
    </div>
  );
};
