import React, { useCallback, useState } from 'react';
import './style.scss';
import { Avatar, Button, openNotification, Popup, Select, Tooltip } from '../../../Common';
import { ContextMenu } from '../../../Common/ContextMenu/ContextMenu';

const options = [
  { name: 'Darrell Steward' },
  { name: 'Robert Fox' },
  { name: 'Robert Fox' },
  { name: 'Ronald Richards' },
];

const optionsAddUsers = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
];

const triggers = ['add', 'change'];

export const TeamTab = props => {
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [popup, setPopup] = useState(false);
  const [fakeOptions, setFakeOptions] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeValuePopup, setActiveValuePopup] = useState(null);
  const [notCloseDropDown, setNotCloseDropDown] = useState(false);

  const openPopup = value => {
    setPopup(true);
    setTrigger(value);
  };

  const openConfirmationDelete = () => {
    setConfirmationDelete(true);
  };
  const closeConfirmationDelete = () => {
    setActiveValuePopup(null);
    setConfirmationDelete(false);
  };

  const updateProject = newTeams => {
    setLoading(true);
    const defaultData = { uuid: props.details.uuid, name: props.details.name, program: props.details.program };
    if (triggers[0] === trigger || newTeams) {
      props
        .updateTeamMembers({
          ...defaultData,
          teamMembers: newTeams || [...activeValuePopup, ...props.details.teamMembers.map(i => i.uuid)],
        })
        .then(resp => {
          if (!resp?.errors) {
            closeConfirmationDelete();
            openNotification(
              newTeams
                ? 'Team member(s) have been removed from the project'
                : 'Team member(s) have been added to the project'
            );
            closePopup();
          }
        })
        .finally(() => setLoading(false));
    } else {
      props
        .updateManager({ ...defaultData, manager: activeValuePopup })
        .then(resp => {
          if (!resp?.errors) {
            closeConfirmationDelete();
            openNotification('Project Manager has been updated');
            closePopup();
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const changeSelectedValue = values => {
    setActiveValuePopup(values);
    setNotCloseDropDown(true);
  };
  const closePopup = useCallback(() => {
    setActiveValuePopup(null);
    setPopup(false);
  }, []);

  const titlePopup = triggers[0] === trigger ? 'Add users' : 'Change project manager';
  const mode = triggers[0] === trigger ? 'multiple' : '';
  const valueSelect = trigger ? (Array.isArray(activeValuePopup) ? activeValuePopup : []) : activeValuePopup;
  const customTags = triggers[0] === trigger;

  const submitText = triggers[0] === trigger ? 'Add' : 'Save';
  const actionOptions = search => {
    return props
      .getUserByRoles(triggers[0] === trigger ? ['Chemistry Designer'] : ['Project Manager'], search)
      .then(resp => {
        if (search === '') return '';
        if (triggers[0] === trigger)
          return resp.chemistryDesigner.filter(i => !props.details.teamMembers.find(j => j.uuid === i.value));
        else return resp.projectManager;
      });
  };

  const isSysAdmin = props.user?.roles?.find(i => i === 'System Administrator');
  const canManageTeams = props.details?.status === 'active' && props.permissions?.change_project_details;

  const getAddButton = () => {
    const button = (
      <Button
        className={!canManageTeams ? 'project-details-team_header_add_member_button_with_tooltip' : ''}
        secondary
        onClick={() => {
          setActiveValuePopup([]);
          openPopup(triggers[0]);
        }}
        disabled={!canManageTeams}
      >
        ADD
      </Button>
    );

    if (!canManageTeams) {
      return (
        <Tooltip title="Team members can be added only for active projects">
          <span className="project-details-team_header_add_member_button_wrapper">{button}</span>
        </Tooltip>
      );
    }

    return button;
  };

  return (
    <div className="project-details-team">
      <div className="project-details-team_header">
        <span className="project-details-team_header_title">Project team</span>
        {getAddButton()}
      </div>
      <div className="project-details-team_body">
        <Item role="Project Manager" name={props.details.manager?.initials}>
          {isSysAdmin && canManageTeams && (
            <ContextMenu
              options={[
                {
                  title: 'Change project manager',
                  onClick: () => {
                    setFakeOptions([
                      {
                        value: props.details.manager?.uuid,
                        label: props.details.manager?.initials,
                      },
                    ]);
                    setActiveValuePopup(props.details.manager?.uuid);
                    openPopup(triggers[1]);
                  },
                },
              ]}
            />
          )}
        </Item>
        {!!props.details?.teamMembers?.length &&
          props.details?.teamMembers.map((i, index, arr) => (
            <Item name={i.initials} role={!index && `Chemistry Designer${arr.length > 1 ? '(s)' : ''}`}>
              {canManageTeams && (
                <ContextMenu
                  options={[
                    {
                      title: 'Remove from project',
                      onClick: () => {
                        setActiveValuePopup(i);
                        openConfirmationDelete();
                      },
                    },
                  ]}
                />
              )}
            </Item>
          ))}
      </div>
      <Popup
        open={confirmationDelete}
        title="Remove from project"
        handleSubmit={() =>
          updateProject(props.details?.teamMembers.filter(j => j.uuid !== activeValuePopup.uuid).map(j => j.uuid))
        }
        handleCancel={closeConfirmationDelete}
        textSubmit="Confirm"
        loading={loading}
      >
        <span>
          Please confirm that you want to remove <strong>{activeValuePopup?.initials}</strong> from the current project
        </span>
      </Popup>
      <Popup
        className="project-details-team_add-user-popup"
        open={popup}
        title={titlePopup}
        handleSubmit={() => updateProject()}
        handleCancel={closePopup}
        textSubmit={submitText}
        loading={loading}
        disabledPrimaryButton={Array.isArray(activeValuePopup) && !activeValuePopup.length}
      >
        <Select
          typeToSearch
          getOptions={actionOptions}
          disabled={loading}
          options={fakeOptions}
          customTags={customTags}
          onChange={changeSelectedValue}
          label="User"
          trigger={trigger}
          value={activeValuePopup}
          mode={mode}
          notFoundContent={false}
          notCloseDropDown={notCloseDropDown}
          onBlur={e => {
            {
              actionOptions('');
              setNotCloseDropDown(false);
            }
          }}
          noClearing={true}
        />
      </Popup>
    </div>
  );
};

const Item = ({ role, name, children }) => {
  const str = name.match(/\b(\w)/g);
  return (
    <div className="item-team-meambers">
      <div className="item-team-meambers_role">{role}</div>
      <div className="item-team-meambers_initials">
        <Avatar>{str ? str.join('') : ''}</Avatar>
        <span className="item-team-meambers_initials_name">{name}</span>
      </div>
      {children}
    </div>
  );
};
