import React from 'react';
import classNames from 'classnames';
import { Select } from '../../Common';

export const Item = ({
  processItem,
  getOptions,
  deviceSetUp,
  handleSetupChange,
  errors,
  isAutosynOnline,
  onlineReactors = [],
  onlinePumps = [],
  remoteConnectivity,
  viewMode,
}) => {
  const pumpKey = processItem.isReactor ? null : deviceSetUp.find(device => device.name === processItem?.name);
  const isOnline = processItem.isReactor
    ? onlineReactors.find(reactor => reactor.key === processItem.key)?.online
    : onlinePumps.find(pump => pump.key === pumpKey?.key)?.online;

  return (
    <div
      className={classNames('details-item', {
        'item-error': !isOnline,
        'item-disabled': !isAutosynOnline || !remoteConnectivity,
      })}
    >
      <div className="details-item-name">
        <span className={classNames({ bold: !processItem.isReactor }, { 'reactor-name': processItem.isReactor })}>
          {processItem?.name}
        </span>
        {!processItem.isReactor && <span className="pump-type">{processItem?.type[0]}</span>}
      </div>
      {processItem.isReactor ? (
        <span className="pump-type">{processItem?.key}</span>
      ) : (
        <div className="details-item-select">
          <Select
            value={pumpKey?.key || ''}
            onChange={value => handleSetupChange(processItem?.name, value)}
            error={errors[pumpKey?.key]}
            errorText={errors[pumpKey?.key]}
            typeToSearch
            getOptions={() => getOptions(processItem?.type, Math.max(...processItem.flow_rate))}
            disabled={!remoteConnectivity || viewMode}
          />
        </div>
      )}
    </div>
  );
};
