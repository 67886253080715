import React, { useEffect, useState } from 'react';
import './style.scss';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Tabs } from '../../../Common/Tabs';
import { timehhmma } from '../../../../constants';
import { Button, DatePicker, Select } from '../../../Common';
import {
  getDiff,
  getDiffBetweenTwoDays,
  setTOffsetUtc,
  toISOfromDateTime,
  toISOfromDateTimeUTC,
} from '../../../../utils/date';
import { filterObj, lengthObj } from '../../../../utils';
import { manageBatch } from '../../../../store/scheduling/scheduling.actions';

const tabsOptions = [
  { value: 'create', label: 'Create batch' },
  { value: 'add', label: 'Add to batch' },
];

export const CreateEditBatch = ({
  onCloseSchedulePopover,
  noTabs,
  title,
  request,
  visible,
  data,
  device,
  dataExperiment,
}) => {
  const [openDate, setOpenDate] = useState(false);
  const [openTime, setOpenTime] = useState(false);
  const [activeTab, setActiveTab] = useState(tabsOptions[0].value);
  const [rangeDate, setRangeDate] = useState(null);
  const [rangeTime, setRangeTime] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      setRangeDate([setTOffsetUtc(data.start), setTOffsetUtc(data.end)]);
      setRangeTime([setTOffsetUtc(data.start), setTOffsetUtc(data.end)]);
    }
    if (!noTabs) {
      dispatch(
        manageBatch({
          type: 'getBatchForAssign',
          data: { device, status_In: 'In Queue' },
        })
      ).then(resp => {
        setOptions(resp.map(i => ({ value: i.uuid, label: i.batchId })));
      });
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setOpenDate(false);
      setOpenTime(false);
      setErrors({});
      setSelectValue(null);
      setRangeTime(null);
      setRangeDate(null);
      setActiveTab(tabsOptions[0].value);
    }
  }, [visible]);

  const handleOpenDate = val => {
    setOpenDate(val);
  };
  const handleOpenTime = val => {
    setOpenTime(val);
  };
  const handleDate = value => {
    setRangeDate(value);
    setErrors({ ...errors, date: false });
  };
  const handleTime = value => {
    setErrors({ ...errors, time: false });
    setRangeTime(value);
  };

  const handleChangeSelect = value => {
    setSelectValue(value);
  };

  const validation = () => {
    const func = i => {
      const start = toISOfromDateTime(rangeDate[0], rangeTime[0]);
      const end = toISOfromDateTime(rangeDate[1], rangeTime[1]);
      const value = getDiffBetweenTwoDays(start, end);
      return value < i?.totalTime;
    };
    const errors = {};
    if (activeTab === tabsOptions[0].value) {
      if (!rangeDate) errors.date = 'Please enter the range date';
      if (!rangeTime) errors.time = 'Please enter the range time';
      // if (!errors.date && rangeTime && rangeDate) {
      //   const startAt = toISOfromDateTimeUTC(rangeDate[0], rangeTime[0]);
      //   const endAt = toISOfromDateTimeUTC(rangeDate[1], rangeTime[1]);
      //   if (86400 < getDiff(startAt, endAt)) {
      //     errors.date = 'Batch time slot must not be longer than 24 hours';
      //   }
      // }
      if (rangeDate && rangeTime && !errors.time && moment() > moment(toISOfromDateTime(rangeDate[0], rangeTime[0])))
        errors.time = 'Batch cannot be scheduled in the past';
      if (rangeDate && rangeTime && !errors.time && (data?.experiments.find(func) || func(dataExperiment))) {
        errors.time = 'Batch cannot be less then the shortest experiments into batch';
      }
    } else if (!selectValue) errors.selectValue = 'Please choose the batch';
    return errors;
  };

  const handleRequest = () => {
    const errors = validation();
    if (lengthObj(errors)) {
      setErrors(errors);
    } else {
      const requestData = {};
      if (activeTab === tabsOptions[0].value) {
        requestData.startAt = toISOfromDateTimeUTC(rangeDate[0], rangeTime[0]);
        requestData.endAt = toISOfromDateTimeUTC(rangeDate[1], rangeTime[1]);
      } else {
        requestData.uuid = selectValue;
        requestData.experiment = dataExperiment?.uuid || data.uuid;
      }
      if (data?.uuid && !requestData.experiment) requestData.uuid = data.uuid;
      if (data?.hasOwnProperty('isNew')) requestData.isNew = data.isNew;
      request(requestData, true).catch(e => {
        if (activeTab === tabsOptions[1].value) {
          setErrors({ selectValue: JSON.parse(e)[0]?.messages[0] });
        } else {
          setErrors({ time: 'Time slot is occupied' });
        }
      });
    }
  };

  const contentTabs = () => (
    <>
      {activeTab === 'create' && (
        <>
          <div className="create-edit-batch_row">
            <div>
              <span className="create-edit-batch_row_title">Date</span>
              <DatePicker
                value={rangeDate}
                range
                open={openDate}
                onOpenChange={handleOpenDate}
                disabledDate={current => moment().startOf('day') >= current}
                onChange={handleDate}
                error={!!errors.date}
              />
            </div>
            {errors.date && <span className="create-edit-batch_row_error">{errors.date}</span>}
          </div>
          <div className="create-edit-batch_row">
            <div>
              <span className="create-edit-batch_row_title">Time</span>
              <DatePicker
                value={rangeTime}
                range
                order={false}
                time
                open={openTime}
                onOpenChange={handleOpenTime}
                onChange={handleTime}
                format={timehhmma}
                error={!!errors.time}
              />
            </div>
            {errors.time && <span className="create-edit-batch_row_error">{errors.time}</span>}
          </div>
        </>
      )}
      {activeTab === 'add' && (
        <>
          <Select
            options={options}
            onChange={handleChangeSelect}
            label="Select batch"
            value={selectValue}
            error={errors.selectValue}
            errorText={errors.selectValue}
            placeholder="Please select"
          />
        </>
      )}
      <div className="create-edit-batch_buttons">
        <Button secondary onClick={onCloseSchedulePopover}>
          Cancel
        </Button>
        <Button onClick={handleRequest}>Confirm</Button>
      </div>
    </>
  );
  return (
    <div className="create-edit-batch">
      {noTabs ? (
        <>
          <div className="create-edit-batch_title">{title}</div>
          {contentTabs()}
        </>
      ) : (
        <Tabs activeKey={activeTab} options={tabsOptions} onChange={setActiveTab}>
          {contentTabs()}
        </Tabs>
      )}
    </div>
  );
};
