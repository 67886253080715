import React, { createContext, useContext, useMemo, useState } from 'react';

const SortContext = createContext(null);
export const useSortContext = () => useContext(SortContext);

export const useSortController = ({ sortOrder, onSortClick }) => {
  const [sortValue, setSortValue] = useState(sortOrder);

  const onSort = ({ sortValueColumn }) => {
    let newSortValue = sortValueColumn;
    if (sortValueColumn === sortValue) {
      newSortValue = `-${sortValueColumn}`;
    } else if (`-${sortValueColumn}` === sortValue) {
      newSortValue = undefined;
    }

    setSortValue(newSortValue);
    onSortClick(newSortValue);
  };

  return {
    sortValue,
    onSort,
  };
};

export const SortTableHOC = ({ children, sortOrder, onSortClick }) => {
  const { sortValue, onSort } = useSortController({ sortOrder, onSortClick });

  const value = useMemo(
    () => ({
      sortValue,
      onSort,
    }),
    [sortValue]
  );

  return <SortContext.Provider value={value}>{children}</SortContext.Provider>;
};
