import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ToolFilled, ToolOutlined, WarningFilled } from '@ant-design/icons';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import cn from 'classnames';
import { findLast } from 'lodash';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';

import { EXPERIMENT_STATUSES, PRIORITIES } from '../../../constants';
import { setGlobalLoading } from '../../../store/common/common.actions';
import { globalLoading } from '../../../store/common/common.selector';
import { getExperiments, getListMaintenance } from '../../../store/experiment/experiment.actions';
import {
  getListEvents,
  manageBatch,
  manageExperimentOnSchedule,
  manageMaintenanceOnSchedule,
  setPotentialBatchForExperiment,
} from '../../../store/scheduling/scheduling.actions';
import { getPotentialEvent } from '../../../store/scheduling/scheduling.selector';
import { checkIntersectionCalendar, elOutSideDisplay, getParamCalendar } from '../../../utils';
import {
  compareCurrentDateWithDate,
  compareDays,
  convertDateTimeZone,
  convertMMtoHHmm,
  dateBetweenTwoDays,
  getHHmmFormat,
  getMmDdHHmmFormat,
  startAndEndTimeToText,
  toISOfromDateTimeUTC,
} from '../../../utils/date';
import { openNotification, Popover, Popup } from '../../Common';
import { Card } from '../../ListExperiments/Card/Card';
import { CreateEditExperimentPopover } from '../../ListExperiments/CreateEditExperimentPopover';
import { SchedulePopover } from '../../ListExperiments/SchedulePopover/SchedulePopover';
import { CreateEditBatch } from '../ComponentsBatch/CreateEditBatch';
import { ViewBatch } from '../ComponentsBatch/ViewBatch';
import { MainTenanceCreation } from '../MainTenanceCreation';
import { MaintenancePreview } from '../MainTenanceCreation/MaintenancePreview';

const formatWeek = 'ddd/DD';

const Calendar = ({
  calendarApi,
  setCalendarApi,
  getListEvents,
  manageExperimentOnSchedule,
  manageBatch,
  events,
  getExperiments,
  device,
  manageMaintenanceOnSchedule,
  setViewExperiment,
  permissions,
  visibleRangeCalendara,
  isSynjetDevice,
  goToBatchDetails,
  isLabDevice,
  confirmationDetails,
  setConfirmationDeatails,
  typeEventsList,
  setSideLoader,
  isSynjetPro,
}) => {
  const [dropingEl, setDropingEL] = useState(null);
  const [showMaintenancePopup, setShowMaintenancePopup] = useState(false);
  const [draggingElDate, setDraggingElDate] = useState(null);
  const [infoViewCard, setInfoViewCard] = useState(null);
  const [resheduleEventUUID, setResheduleEventUUID] = useState(false);
  const [resheduleEventData, setResheduleEventData] = useState(null);

  const loading = useSelector(globalLoading);
  const dispatch = useDispatch();
  const currentDataDropExprtToBatch = useSelector(getPotentialEvent);

  const clearCalendarFromWrongEvents = () => {
    if (calendarApi) {
      const api = calendarApi?.getApi();
      if (api) {
        api.getEvents().forEach(i => {
          if (i.extendedProps.data.status === 'Submitted') i.remove();
        });
      }
    }
  };

  useEffect(() => {
    if (calendarApi) {
      const t = new Draggable(document.querySelector('.list-experiments'), {
        itemSelector: '.experiment-card',
        eventData: eventEl => {
          eventEl.classList.add('experiment-card-drop');
          const data = JSON.parse(eventEl.dataset.card);

          const eventData = {
            ...data,
            title: 'test',
            duration: convertMMtoHHmm(data.duration),
            create: true,
          };
          const api = calendarApi.getApi();
          clearCalendarFromWrongEvents();
          if (api.view.type === 'dayGridMonth') {
            delete eventData.duration;
          }
          return eventData;
        },
      });
    }
  }, [!!calendarApi]);

  useEffect(() => {
    setSideLoader(loading);
  }, [loading]);

  useEffect(() => {
    const handlerClick = e => {
      const validPath = e?.path ? e.path : e.composedPath ? e.composedPath() : null;

      if (
        !validPath?.find(
          i =>
            i.classList?.contains('fc-event') ||
            i.classList?.contains('ant-popover-inner-content') ||
            i.classList?.contains('ant-modal-wrap') ||
            i.classList?.contains('fc-maintenance-button') ||
            i.classList?.contains('ant-picker-panel-container') ||
            i.classList?.contains('preview-maintenance_footer_delete')
        )
      ) {
        clearCalendarFromWrongEvents();
        setDefaultState();
      }
    };

    const handleMouseUp = () => {
      const el = document.querySelector('.experiment-card-drop');
      if (el) el.classList.remove('experiment-card-drop');
    };
    const handleMouseDown = e => {
      const validPath = e?.path ? e.path : e.composedPath ? e.composedPath() : null;

      if (
        validPath.find(
          i =>
            i.classList?.contains('fc-daygrid-event') &&
            i.classList?.contains('fc-event-draggable') &&
            i.tagName.toLowerCase() === 'a'
        )
      ) {
        setDefaultState();
      }
    };

    const handleMouseMove = e => {
      const draggingEL = document.querySelector('.fc-event-dragging');
      if (draggingEL && document.querySelector('.fc-dayGridMonth-view')) {
        if (e.clientX > 400) {
          if (draggingEL) {
            const dayEl = document.querySelector('.fc-daygrid-day');
            draggingEL.classList.add('experiment-card_on-dragging');
            draggingEL.style.left = `${e.clientX - dayEl.offsetWidth / 2}px`;
            draggingEL.style.top = `${e.clientY}px`;
            draggingEL.style.width = `${dayEl.offsetWidth - 6}px`;
          }
        } else if (draggingEL.classList.contains('experiment-card_on-dragging')) {
          draggingEL.classList.remove('experiment-card_on-dragging');
          draggingEL.style.width = '351px';
        }
      }
    };

    window.addEventListener('click', handlerClick);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mousedown', handleMouseDown);
    return () => {
      window.removeEventListener('click', handlerClick);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, [!!calendarApi]);

  useEffect(() => {
    requestGetEvents();
  }, [!!calendarApi, device, typeEventsList]);
  const requestGetEvents = () => {
    if (calendarApi && device) {
      dispatch(setGlobalLoading(true));
      dispatch(setPotentialBatchForExperiment(null));
      getListEvents(getParamCalendar(calendarApi, device, typeEventsList)).finally(() => {
        dispatch(setGlobalLoading(false));
      });
    }
  };

  const updateConfirmationDetails = useCallback(data => {
    setDefaultState();
    setConfirmationDeatails(data);
  }, []);

  const clearConfirmationDetails = useCallback(() => {
    setConfirmationDeatails(null);
  }, []);

  const resheduleEventMode = uuid => {
    setDefaultState();
    setResheduleEventUUID(uuid);
  };

  const setDefaultState = () => {
    clearCalendarFromWrongEvents();
    setResheduleEventData(null);
    setResheduleEventUUID(false);
    setInfoViewCard(null);
    setDraggingElDate(null);
    setShowMaintenancePopup(false);
    setDropingEL(null);
    setConfirmationDeatails(null);
    const el = document.querySelector('.experiment-card-drop');
    if (el) el.classList.remove('experiment-card-drop');
  };

  const openViewCard = (data, ...other) => {
    if (!dropingEl) {
      const uuidEl = data.event.extendedProps.data.uuid;
      if (uuidEl === resheduleEventUUID) {
        setResheduleEventData(null);
        setResheduleEventUUID(null);
      } else if (uuidEl === infoViewCard?.event?.extendedProps?.data?.uuid) {
        setInfoViewCard(null);
      } else {
        setInfoViewCard(data);
      }
    }
  };
  const closeViewCard = useCallback(data => {
    setInfoViewCard(null);
  }, []);

  const unsheduleEvent = () => {
    dispatch(setGlobalLoading(true));
    if (confirmationDetails.data.type === 'maintenance') {
      manageMaintenanceOnSchedule(
        { uuid: confirmationDetails.data.uuid },
        'deleteMaintenance',
        getParamCalendar(calendarApi, device)
      )
        .then(() => {
          openNotification('Maintenance event was deleted');
          setDefaultState();
          return dispatch(getListMaintenance({ device }));
        })
        .finally(() => {
          dispatch(setGlobalLoading(false));
        });
    } else if (confirmationDetails.data.batchId) {
      manageBatch({
        data: { uuid: confirmationDetails.data.uuid },
        type: 'unscheduleBatch',
        dataForUpdateList: getParamCalendar(calendarApi, device),
      })
        .then(() => {
          openNotification('The batch has been unscheduled');
          setDefaultState();
          return getExperiments({ compatibleWith: device });
        })
        .catch(e => {
          openNotification('', 'Batch scheduling cannot be changed. The current batch status is In Preparation.');
        })
        .finally(() => {
          dispatch(setGlobalLoading(false));
        });
    } else {
      manageExperimentOnSchedule(
        { uuid: confirmationDetails.data.uuid },
        'unscheduleExperiment',
        getParamCalendar(calendarApi, device)
      )
        .then(() => {
          openNotification('The experiment has been unscheduled');
          setDefaultState();
          return getExperiments({ compatibleWith: device });
        })
        .finally(() => {
          dispatch(setGlobalLoading(false));
        });
    }
  };

  const createUpdateMaintenance = (data, rescheduleDnD) => {
    dispatch(setGlobalLoading(true));
    return manageMaintenanceOnSchedule(
      {
        uuid: data.uuid,
        description: data.comment,
        timeSlot: {
          device,
          startAt: rescheduleDnD
            ? convertDateTimeZone(data.start)
            : convertDateTimeZone(toISOfromDateTimeUTC(data.date, data.start)),
          endAt: rescheduleDnD
            ? convertDateTimeZone(data.end)
            : convertDateTimeZone(toISOfromDateTimeUTC(data.date, data.end)),
        },
      },
      data.uuid ? 'updateMaintenance' : 'createMaintenance',
      getParamCalendar(calendarApi, device)
    )
      .then(() => {
        openNotification(
          data.uuid ? 'Maintenance event was updated' : 'Maintenance event has been successfully scheduled'
        );
        setDefaultState();
        return dispatch(getListMaintenance({ device }));
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
  };

  const updateBatch = (data, rescheduleDnD) => {
    dispatch(setGlobalLoading(true));
    let dataRequest;
    const isModeAddExp = !!data.experiment;
    if (isModeAddExp) {
      dataRequest = {
        data,
        type: 'addExperiment',
        dataForUpdateList: getParamCalendar(calendarApi, device),
      };
    } else {
      dataRequest = {
        data: {
          uuid: data.uuid,
          timeSlot: {
            device,
            startAt: convertDateTimeZone(data.startAt),
            endAt: convertDateTimeZone(data.endAt),
          },
        },
        type: data.uuid && !data.isNew ? 'updateBatch' : '',
        dataForUpdateList: getParamCalendar(calendarApi, device),
      };
    }
    if (data.isNew && !isModeAddExp) {
      delete dataRequest.data.uuid;
      dataRequest.data.experiment = data.uuid;
    } else if (data.isNew && isModeAddExp) {
      delete dataRequest.data.isNew;
    }
    return manageBatch(dataRequest)
      .then(() => {
        openNotification(data.uuid ? 'Batch event was updated' : 'Batch event has been successfully scheduled');
        setDefaultState();
        return data.uuid && getExperiments({ compatibleWith: device });
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
  };

  const maintenance = {
    text: (
      <Popover
        content={
          <MainTenanceCreation
            open={showMaintenancePopup}
            request={createUpdateMaintenance}
            calendarApi={calendarApi}
            close={() => {
              setShowMaintenancePopup(!showMaintenancePopup);
            }}
          />
        }
        trigger="click"
        visible={showMaintenancePopup}
      >
        <span
          onClick={() => {
            setShowMaintenancePopup(!showMaintenancePopup);
          }}
        >
          <ToolOutlined className="fc-maintenance-button-icon" />
          Plan maintenance
        </span>
      </Popover>
    ),
  };

  const closePoper = useCallback(() => {
    setDefaultState();
    setDropingEL(null);
  }, []);

  const scheduledExperiment = data => {
    dispatch(setGlobalLoading(true));
    const typeAction = resheduleEventUUID || data.reschedule ? 'rescheduleExperiment' : 'scheduleExperiment';
    return manageExperimentOnSchedule(
      {
        uuid: data.uuid,
        timeSlot: { device, startAt: data.start, endAt: data.end },
      },
      typeAction,
      getParamCalendar(calendarApi, device)
    )
      .then(() => {
        setDefaultState();
        openNotification('The experiment has been successfully scheduled');
        if (typeAction === 'scheduleExperiment') {
          getExperiments({ compatibleWith: device });
        }
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
    // scheduling experiment
  };

  const dropExternalExpt = data => {
    console.log(2);
    const api = calendarApi.getApi();
    const isPast = compareCurrentDateWithDate(data.event.startStr, api?.view?.type === 'dayGridMonth');
    if (permissions.scheduling?.create_experiment_scheduling && !isPast) {
      const events = api.getEvents();
      if (dropingEl) {
        const eventOutRules = events.find(i => i.extendedProps?.data?.uuid === dropingEl.uuid);
        eventOutRules && eventOutRules.remove();
      }
      const dataDropEl = {
        startDate: data.event.startStr,
        endDate: data.event.endStr,
        uuid: data.event.extendedProps.data.uuid,
      };
      const pathChecking = api.view.type === 'dayGridMonth' && isSynjetDevice;

      let isIntersection = checkIntersectionCalendar(dataDropEl, calendarApi, [], 'getItems');
      if (
        currentDataDropExprtToBatch &&
        isIntersection.find(i => i?.extendedProps?.data?.uuid === currentDataDropExprtToBatch.uuid)
      ) {
        const lengthExperiments = currentDataDropExprtToBatch.experiments
          .map(i => i.process.numberOfSteps)
          .reduce((acc, cur) => acc + +cur, 0);
        const checkingCountExpr = lengthExperiments + +data.event?.extendedProps?.data?.process?.numberOfSteps <= 5;
        const checkingIntersection = checkIntersectionCalendar(
          {
            uuid: data.event.extendedProps.data.uuid,
            startDate: currentDataDropExprtToBatch.start,
            endDate: moment
              .utc(currentDataDropExprtToBatch.start)
              .add(data.event.extendedProps.data.duration, 'minutes'),
          },
          calendarApi,
          [currentDataDropExprtToBatch.uuid]
        );

        if ((checkingCountExpr && checkingIntersection) || isSynjetPro) {
          dispatch(setGlobalLoading(true));
          manageBatch({
            type: 'addExperiment',
            data: {
              uuid: currentDataDropExprtToBatch.uuid,
              experiment: data.event.extendedProps.data.uuid,
            },
            dataForUpdateList: getParamCalendar(calendarApi, device),
          })
            .then(() => {
              data.event.remove();
              openNotification('Experiment has been added to batch', null, 5, 'expHasBeenAdded');
              setDefaultState();
              return getExperiments({ compatibleWith: device });
            })
            .catch(e => {
              const currentEvent = events.find(i => i.extendedProps?.data?.uuid === data.event.extendedProps.data.uuid);

              if (currentEvent) {
                currentEvent.remove();
              }

              openNotification('', JSON.parse(e)?.[0]?.messages[0]);
            })
            .finally(() => {
              dispatch(setPotentialBatchForExperiment(null));
              dispatch(setGlobalLoading(false));
            });
        } else {
          if (!checkingIntersection) {
            openNotification(
              '',
              `Experiment cannot be added 
to a smaller batch. Reschedule Batch ${currentDataDropExprtToBatch?.batchId} first`
            );
          }
          if (!checkingCountExpr) openNotification('', 'Batch cannot contain more than 5 Steps within it');

          data.event.remove();
        }
      } else {
        let dataBatch = null;
        if (isSynjetPro) {
          isIntersection = [];
        }
        if (isSynjetDevice) {
          if (!!isIntersection.length && !pathChecking) {
            openNotification('', 'This timeslot is occupied');
            data.event.remove();
            return;
          }
          let lastBatchIdx = +findLast(
            events,
            el => !!el?.extendedProps?.data.batchId
          )?.extendedProps?.data?.batchId?.split('-')[1];
          if (Object.is(lastBatchIdx, NaN)) lastBatchIdx = 0;
          dataBatch = {
            batchId: moment.utc(data.startStr).format(`YYYYMMDD-${lastBatchIdx + 1}`),
            experiments: [data?.event?.extendedProps?.data],
            status: data?.event?.extendedProps?.data?.status,
            isNew: true,
            uuid: data?.event?.extendedProps?.data.uuid,
          };
        }
        data.event.setProp('editable', false);
        if (dataBatch) data.event.setExtendedProp('data', dataBatch);
        setDropingEL(dataBatch || data.event?.extendedProps?.data);
      }
    } else data.event.remove();
  };
  const eventDrop = data => {
    const dataEvent = data.event.extendedProps?.data;
    const oldStart = data.oldEvent.startStr;
    const canNotReschedule =
      dataEvent?.type !== 'maintenance' && dataEvent.status !== EXPERIMENT_STATUSES.InQueue.value;
    const isPast = compareCurrentDateWithDate(
      data.event.startStr,
      calendarApi?.getApi()?.view?.type === 'dayGridMonth'
    );
    if (data.event.startStr === oldStart) return;
    if (isPast || compareCurrentDateWithDate(oldStart) || canNotReschedule) {
      data.event.setStart(oldStart, { maintainDuration: true });
      setDraggingElDate(null);
    } else {
      // if (calendarApi.getApi().view.type === 'dayGridMonth') {
      if (true) {
        setResheduleEventData({
          ...data,
          el: document.querySelectorAll(`[id='${data.event.startStr}']`),
        });
        setResheduleEventUUID(dataEvent?.uuid);
      } else {
        const requestData = {
          start: data.event.startStr,
          end: data.event.endStr,
          uuid: dataEvent?.uuid,
          comment: dataEvent?.description,
          reschedule: true,
        };
        if (dataEvent?.type === 'maintenance') createUpdateMaintenance(requestData, true);
        else scheduledExperiment(requestData);
      }
    }
  };

  const renderDayHeaderContent = data => {
    if (data.view.type === 'timeGridDay') return '';
    if (data.view.type === 'timeGridWeek') {
      const date = moment(data.date).format(formatWeek).split('/');
      return {
        html: `<div class="calendar-container_header-week">
                  <span>${date[0]}</span>
                  <span>${date[1]}</span>
                </div>`,
      };
    }
    return {
      html: `<div class="calendar-container_header-month">
                  <span>${data.text}</span>
                </div>`,
    };
  };

  let customButtons = {};
  if (permissions.scheduling?.create_maintenance) customButtons = { maintenance };

  let htmlConfirmation = { __html: '' };
  let titleConfirmation = '';
  if (confirmationDetails) {
    const isMaintenance = confirmationDetails.data.type === 'maintenance';
    const isBatch = !!confirmationDetails.data.batchId;
    htmlConfirmation = {
      __html: isMaintenance
        ? '<div>Are you sure you want to delete a maintenance event?</div>'
        : isBatch
        ? '<div>Are you sure you want to proceed?<br/>All the experiments included in batch will be unscheduled.</div>'
        : '<div>You are about to unschedule the experiment.<br/>Note: After that, the corresponding time slot will be available for other events</div>',
    };

    titleConfirmation = isMaintenance ? 'Delete maintenance event' : isBatch ? 'Unbatch' : 'Unschedule experiment';
  }

  return (
    <div className="calendar-container">
      <FullCalendar
        allDaySlot={false}
        headerToolbar={{
          start: 'prev next title',
          end: 'maintenance today timeGridDay,timeGridWeek,dayGridMonth',
        }}
        timeZone="local"
        ref={calendar => setCalendarApi(calendar)}
        slotDuration="00:15:00"
        duration={60}
        editable
        events={events}
        datesSet={requestGetEvents}
        eventClick={openViewCard}
        eventContent={({ ...args }) => (
          <EventContainer
            permissions={permissions}
            setDefaultState={setDefaultState}
            setViewExperiment={setViewExperiment}
            createUpdateMaintenance={createUpdateMaintenance}
            infoViewCard={infoViewCard}
            updateBatch={updateBatch}
            dropingEl={dropingEl}
            device={device}
            isSynjetDevice={isSynjetDevice}
            resheduleEventData={resheduleEventData}
            setConfirmationDeatails={updateConfirmationDetails}
            setResheduleEventData={setResheduleEventData}
            setResheduleEventUUID={resheduleEventMode}
            calendarApi={calendarApi}
            scheduledExperiment={scheduledExperiment}
            closePopper={closePoper}
            resheduleEventUUID={resheduleEventUUID}
            goToBatchDetails={goToBatchDetails}
            isLabDevice={isLabDevice}
            {...args}
          />
        )}
        droppable
        eventLeave={() => {}}
        initialDate={visibleRangeCalendara.date}
        eventReceive={dropExternalExpt}
        eventDrop={eventDrop}
        dayHeaderContent={renderDayHeaderContent}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        titleFormat={{ year: 'numeric', month: 'long' }}
        initialView={visibleRangeCalendara.type}
        customButtons={customButtons}
        eventOverlap={(stillEvent, movingEvent) =>
          (isSynjetDevice && !movingEvent?.extendedProps?.data?.batchId) ||
          calendarApi.getApi().view.type === 'dayGridMonth' ||
          isLabDevice
        }
        views={{
          timeGridWeek: {
            titleFormat: { year: 'numeric', month: 'long' },
          },
          timeGridDay: {
            titleFormat: {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              weekday: 'long',
            },
          },
          dayGridMonth: {
            titleFormat: { year: 'numeric', month: 'long' },
          },
        }}
      />
      <Popup
        title={titleConfirmation}
        open={confirmationDetails}
        textSubmit="Confirm"
        handleCancel={clearConfirmationDetails}
        handleSubmit={unsheduleEvent}
        loading={loading}
      >
        <div dangerouslySetInnerHTML={htmlConfirmation} />
      </Popup>
    </div>
  );
};

const classesContainers = {
  timeGridDay: '.fc-timegrid-event-harness',
  timeGridWeek: '.fc-timegrid-event-harness',
  dayGridMonth: '.fc-daygrid-event-harness',
};

const EventContainer = ({
  view,
  closePopper,
  event,
  resheduleEventData,
  isDragging,
  scheduledExperiment,
  dropingEl,
  calendarApi,
  infoViewCard,
  setConfirmationDeatails,
  setResheduleEventUUID,
  setResheduleEventData,
  resheduleEventUUID,
  scheduleEvent,
  isSynjetDevice,
  createUpdateMaintenance,
  updateBatch,
  setViewExperiment,
  setDefaultState,
  permissions,
  device,
  goToBatchDetails,
  isLabDevice,
  ...props
}) => {
  const dispatch = useDispatch();
  const currentDataDropExprtToBatch = useSelector(getPotentialEvent);
  const eventRef = useRef(null);
  const dataEvent = event.extendedProps?.data || {};
  const potentialBatchForExpr = currentDataDropExprtToBatch?.uuid === dataEvent.uuid;

  const addDeleteClass = (node, isDelete) => {
    try {
      if (!node) return;
      if (isDelete) {
        node.filter(i => i).forEach(i => i.classList.remove('active-state-event-calendar'));
      } else {
        node.filter(i => i).forEach(i => i.classList.add('active-state-event-calendar'));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getParentsEvent = () => {
    let events = Array.from(document.querySelectorAll(`[data-uuid="${dataEvent.uuid}"]`));
    if (events?.length) events = events.map(i => i.closest(classesContainers[view.type]));
    return events;
  };
  const getPermissionEvent = () => {
    if (permissions.scheduling) {
      const isPast = compareCurrentDateWithDate(event.start);
      const isPastMaintenance = compareCurrentDateWithDate(event.end);
      return {
        canDeleteMaintenance: permissions.scheduling.delete_maintenance && !isPastMaintenance,
        canChangeMaintenance: permissions.scheduling.change_maintenance && !isPastMaintenance,
        canDeleteBatch:
          permissions.scheduling.delete_batch && !isPast && dataEvent?.status === EXPERIMENT_STATUSES.InQueue.value,
        canChangeBatch:
          permissions.scheduling.update_batch && !isPast && dataEvent.status === EXPERIMENT_STATUSES.InQueue.value,
        canScheduleRescheduleExprt:
          permissions.scheduling.change_experiment_scheduling &&
          !isPast &&
          dataEvent.status === EXPERIMENT_STATUSES.InQueue.value,
      };
    }
    return {};
  };

  const updateBatchConfirm = (...args) =>
    updateBatch(...args).then(() => {
      event.remove();
    });
  const confirmScheduling = data => {
    const startDate = toISOfromDateTimeUTC(data.date, data.time);
    let endDate;
    if (isSynjetDevice) {
      endDate = moment
        .utc(startDate)
        .add(+dataEvent.duration, 'minute')
        .format();
    } else {
      endDate = moment
        .utc(startDate)
        .add(+dataEvent.duration, 'minute')
        .format()
        .slice(0, -1); // remove uts Z suffix
    }
    let params = [
      {
        startDate,
        endDate,
        uuid: dataEvent.uuid,
      },
      calendarApi,
    ];
    if (isLabDevice) {
      params = params.concat([[], 'getItems']);
    }
    const result = checkIntersectionCalendar(...params);
    if ((result && !isLabDevice) || isLabDevice) {
      return scheduledExperiment({
        start: convertDateTimeZone(startDate),
        end: convertDateTimeZone(endDate),
        uuid: dataEvent.uuid,
      }).then(() => {
        event.remove();
        closePopper();
      });
    }
    return Promise.reject();
  };
  const onCloseSchedulePopoverExprtBatch = () => {
    const elem = document.querySelector('.experiment-card-drop');
    if (elem) elem.classList.remove('experiment-card-drop');
    if (resheduleEventUUID) event.setStart(dataEvent.start, { maintainDuration: true });
    else event.remove();
    closePopper();
  };
  const unscheduleEvent = () => {
    setConfirmationDeatails({
      data: dataEvent,
      event,
    });
  };
  const rescheduleMaintenanceBatchExpSetMode = e => {
    if (e?.stopPropagation) e.stopPropagation();
    setResheduleEventUUID(dataEvent.uuid);
    setResheduleEventData({ el: eventRef?.current?.parentElement?.parentElement });
  };
  const closeRescheduleMaintenance = () => {
    event.setStart(dataEvent.start, { maintainDuration: true });
    setResheduleEventData(null);
    setResheduleEventUUID(null);
  };
  const setContentPreviewMode = ({ isMaintenance, isBatch }) => {
    let customContent;
    if (isMaintenance) {
      customContent = (
        <MaintenancePreview
          premissions={premissionsActions}
          data={{ ...dataEvent, start: event.startStr, end: event.endStr }}
          unschedule={unscheduleEvent}
          reschedule={rescheduleMaintenanceBatchExpSetMode}
        />
      );
    } else if (isBatch) {
      customContent = (
        <ViewBatch
          premissions={premissionsActions}
          data={{ ...dataEvent, start: event.startStr, end: event.endStr }}
          unschedule={unscheduleEvent}
          reschedule={rescheduleMaintenanceBatchExpSetMode}
          goToBatchDetails={() => goToBatchDetails(dataEvent)}
        />
      );
    } else {
      customContent = (
        <Card
          {...dataEvent}
          permissions={permissions}
          onClick={setViewExperiment}
          reschedule={rescheduleMaintenanceBatchExpSetMode}
          premissionsActions={premissionsActions}
          unschedule={unscheduleEvent}
          className="calendar-container_preview-card"
          data={dataEvent}
          mode="calendar"
        />
      );
    }
    return customContent;
  };

  const setContentEventEditMode = ({ isMaintenance, isBatch, isMonthView }) => {
    let customContent;
    const experimentInfo = { ...dataEvent, date: moment.utc(event.startStr) };
    experimentInfo.start = moment.utc(event.startStr);
    experimentInfo.end = moment.utc(event.endStr);
    const title = resheduleEventUUID ? 'Reschedule experiment' : 'Schedule experiment';
    if (dataEvent.isNew && isMonthView) {
      experimentInfo.start = moment(event.startStr).startOf('day');
      experimentInfo.end = moment(event.startStr).startOf('day').add(dataEvent?.experiments[0]?.duration, 'minutes');
    }
    customContent = (
      <CreateEditExperimentPopover
        title={title}
        visible
        isLabDevice={isLabDevice}
        experimentInfo={experimentInfo}
        permissions={permissions}
        request={confirmScheduling}
        onCloseSchedulePopover={onCloseSchedulePopoverExprtBatch}
      />
    );
    if (isMaintenance) {
      customContent = (
        <MainTenanceCreation
          open
          title="Reschedule maintenance"
          dataEvent={experimentInfo}
          calendarApi={calendarApi}
          request={createUpdateMaintenance}
          close={closeRescheduleMaintenance}
        />
      );
    } else if (isBatch) {
      customContent = (
        <CreateEditBatch
          data={experimentInfo}
          noTabs={!isMonthView || !dataEvent.isNew}
          visible
          device={device}
          request={updateBatchConfirm}
          onCloseSchedulePopover={onCloseSchedulePopoverExprtBatch}
          title={experimentInfo.isNew ? 'Create batch' : `Reschedule Batch ${dataEvent.batchId}`}
        />
      );
    }
    return customContent;
  };

  const compareDateLongEvent = () => {
    let visiblePoper;
    try {
      const allEvents = Array.from(document.querySelectorAll(`[data-uuid="${dataEvent.uuid}"]`));
      visiblePoper = eventRef.current.contains(allEvents[0]);
    } catch (e) {
      console.log(e);
    }
    return visiblePoper;
  };
  const setContentEvent = () => {
    const isMaintenance = dataEvent.type === 'maintenance';
    const isBatch = !!dataEvent.batchId;
    const isMonthView = view.type === 'dayGridMonth';
    const eventMoreThenOneDay = !compareDays(event.startStr, event.endStr);
    const isModeInfoViewCard = infoViewCard && infoViewCard.event?.extendedProps?.data?.uuid === dataEvent.uuid;

    let customContent = null;
    let visiblePoper = false;
    if (isModeInfoViewCard) {
      visiblePoper = true;
      customContent = setContentPreviewMode({ isMaintenance, isBatch });
    }
    if (
      ((dropingEl && dropingEl?.uuid === dataEvent.uuid) ||
        (resheduleEventUUID && resheduleEventUUID === dataEvent.uuid)) &&
      !isDragging
    ) {
      visiblePoper = true;
      customContent = setContentEventEditMode({ isMaintenance, isBatch, isMonthView });
    }
    if (visiblePoper && eventMoreThenOneDay && event.endStr !== '') {
      const isNodeList = !!resheduleEventData?.el?.entries;
      if (isNodeList && !!resheduleEventData?.el) {
        let arr = Array.from(resheduleEventData.el);
        arr = arr.filter(i => !elOutSideDisplay(i));
        visiblePoper = eventRef?.current?.contains(arr[0]);
      } else if (
        (!!infoViewCard?.el && !infoViewCard.el.contains(eventRef.current)) ||
        (!!resheduleEventData?.el && !resheduleEventData.el.contains(eventRef.current))
      ) {
        visiblePoper = false;
      } else if (dropingEl) {
        visiblePoper = compareDateLongEvent();
      }
    }
    return {
      customContent,
      visiblePoper,
      isMaintenance,
      isBatch,
      eventMoreThenOneDay,
      isMonthView,
    };
  };

  const premissionsActions = getPermissionEvent();

  useEffect(() => {
    if (dataEvent.type === 'maintenance') {
      event.setProp('editable', false);
    }
  }, []);

  useEffect(() => {
    if (isSynjetDevice && !dataEvent?.batchId) {
      const allEvents = calendarApi?.getApi().getEvents();
      const eventOverlap = allEvents?.find(i => dateBetweenTwoDays(i.startStr, i.endStr, event.startStr));
      const data = eventOverlap?.extendedProps?.data;
      if (eventOverlap && data?.experiments?.length !== 5 && data.status === 'In Queue') {
        dispatch(setPotentialBatchForExperiment(data));
      } else {
        dispatch(setPotentialBatchForExperiment(null));
      }
    }
  }, [event.startStr]);
  const { isMaintenance, visiblePoper, customContent, isBatch, eventMoreThenOneDay, isMonthView } = setContentEvent();
  const idEvent = event.startStr;
  if (isDragging) {
    setDefaultState();
  }

  useEffect(() => {
    if (visiblePoper) {
      addDeleteClass(getParentsEvent());
    } else addDeleteClass(getParentsEvent(), 'delete');
  }, [visiblePoper]);
  return (
    <SchedulePopover visible={visiblePoper} onVisibleChange={() => {}} content={customContent}>
      <div
        id={idEvent}
        data-uuid={dataEvent.uuid}
        className={cn('calendar-container_event', {
          'calendar-container_event_dragging': isDragging,
          'calendar-container_event_potential-drop': potentialBatchForExpr,
        })}
        ref={eventRef}
      >
        {isBatch ? (
          <ContentEventBatch
            eventMoreThenOneDa={eventMoreThenOneDay}
            dataEvent={dataEvent}
            event={event}
            isMonthView={isMonthView}
          />
        ) : (
          <ContentEventMaintenenceExpr
            dataEvent={dataEvent}
            event={event}
            isMaintenance={isMaintenance}
            isMonthView={isMonthView}
          />
        )}
      </div>
    </SchedulePopover>
  );
};

const ContentEventBatch = ({ isMonthView, dataEvent, event, eventMoreThenOneDay }) => (
  <>
    {isMonthView ? (
      <div className="calendar-container_event-batch-month">
        <span>{getHHmmFormat(event.startStr)}</span>
        {dataEvent.hasWarnings && (
          <>
            &nbsp;
            <WarningFilled className="view-batch_header_name_icon" />
            &nbsp;
          </>
        )}
        Batch {dataEvent.batchId}
      </div>
    ) : (
      <>
        <div className="calendar-container_event-batch_id">
          {dataEvent.hasWarnings && (
            <>
              <WarningFilled className="view-batch_header_name_icon" />
              &nbsp;
            </>
          )}
          Batch {dataEvent.batchId}
        </div>
        <div className="calendar-container_event-batch_timeframe">
          {eventMoreThenOneDay
            ? `${getMmDdHHmmFormat(event.startStr)} — ${getMmDdHHmmFormat(event.endStr)}}}`
            : `${getHHmmFormat(event.startStr)} — ${getHHmmFormat(event.endStr)}`}
        </div>
        <div className="calendar-container_event-batch_exprs">
          {dataEvent.experiments.length}
          experiments
        </div>
        <div className="calendar-container_event-batch_status">{dataEvent.status}</div>
      </>
    )}
  </>
);
const ContentEventMaintenenceExpr = ({ isMaintenance, isMonthView, dataEvent, event }) => (
  <>
    <div className="calendar-container_event_name">
      {isMaintenance ? (
        <>
          <ToolFilled className="fc-maintenance-button-icon" /> Maintenance
        </>
      ) : (
        <>
          {isMonthView && <span>{getHHmmFormat(event.startStr)}</span>} {dataEvent.name}{' '}
        </>
      )}
    </div>
    {isMaintenance && (
      <div className="calendar-container_event_all-day">
        {dataEvent.allDay ? (
          'All day'
        ) : (
          <>
            {getHHmmFormat(event.startStr)} —{getHHmmFormat(event.endStr)}
          </>
        )}
      </div>
    )}
    {!isMonthView && !isMaintenance && (
      <div className="calendar-container_event_container">
        <div className="calendar-container_event_container_first-part">
          <div className="calendar-container_event_container_first-part_data">
            <div>{startAndEndTimeToText({ startAt: event.startStr, endAt: event.endStr })}</div>
            <div>{dataEvent?.process?.project?.name}</div>
          </div>
        </div>
        <div className="calendar-container_event_container_last-part">
          <div
            className={cn('calendar-container_event_container_last-part_type', {
              low: dataEvent.priority === 0,
              medium: dataEvent.priority === 1,
              high: dataEvent.priority === 2,
            })}
          >
            {PRIORITIES[dataEvent.priority]}
          </div>
          <div className="calendar-container_event_container_last-part_status">{dataEvent?.status}</div>
        </div>
      </div>
    )}
  </>
);
const mapStateToProps = store => ({
  events: store.schedulingReducer.events,
});

export default connect(mapStateToProps, {
  getListEvents,
  manageExperimentOnSchedule,
  getExperiments,
  manageBatch,
  manageMaintenanceOnSchedule,
})(Calendar);
