import React, { useEffect, useState } from 'react';
import './style.scss';
import { Input, openNotification, Popup, Select, Tabs } from 'components/Common';
import { FILE_TYPES_SJPRO, PROJECT_TYPES } from 'constants/common';
import { OfflineAnalysisBlock, ReactionData } from '../resultsComponents';
import {
  destroyResultAttachment,
  getExperimentResultPurifications,
  uploadFile,
} from '../../../../store/experiment/experiment.actions';
import { getSynJetCompoundLetter } from '../../../../utils/synjetHelpers';
import { decodeURIComponentSafe } from '../../../../utils';
import { appendedInitialState, initialErrors, initialState } from '../PopupAppendResults';
import { PurificationDetails } from '../../InfoSynJet';
import { getAppendedFilesReactions } from '../../../../utils/synjetProExecutionHelpers';

const options_tab = [
  { label: 'Workup and Purification', value: 'workup_purification' },
  { label: 'Product masses / yields', value: 'masses_yields' },
];

export const AppendSJProResultsForm = ({
  isSynJet,
  data,
  errors,
  setAppendedFiles,
  reactions,
  setReactions,
  setErrors,
  experimantData,
  resultData,
  setData,
  setHasChanges,
  appendedFiles,
}) => {
  const [deleteAttachmentConfirmationOpen, setDeleteAttachmentConfirmationOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [options, setOptions] = useState([]);
  const [tab, setTab] = useState(options_tab[0].value);

  const changeData = (value, type) => {
    setHasChanges(true);
    setErrors({ ...errors, [type]: false });
    if (type === 'purification' && value === 'None') {
      setErrors({ ...errors, purificationComment: false });
    }
    setData({
      ...data,
      [type]: value,
    });
  };

  const getReactionName = reactionIndex => {
    let name = 'Reaction ' + getSynJetCompoundLetter(reactionIndex, 0);
    return name;
  };

  const getAppendedFiles = type =>
    resultData.attachments
      ?.filter(i => i.type === type)
      .map(file => ({
        ...file,
        loaded: true,
        path: file.file,
      })) || [];

  useEffect(() => {
    if (resultData) {
      const { workup, purificationType } = resultData;
      setData({
        ...data,
        workup: workup ? decodeURIComponentSafe(workup) : '',
        purification: purificationType,
      });
      setAppendedFiles({
        'Offline LC-MS': getAppendedFiles('Offline LC-MS'),
        purification: getAppendedFiles('Purification results'),
        'Reaction attachment': getAppendedFilesReactions('Reaction attachment', resultData),
      });
      if (
        resultData.productsInfo &&
        JSON.parse(resultData.productsInfo) &&
        Array.isArray(JSON.parse(resultData.productsInfo))
      ) {
        setReactions(
          JSON.parse(resultData.productsInfo).map((product, productIndex) => ({
            name: getReactionName(productIndex),
            mass: product.mass,
            product_yield: product.product_yield,
            compound: product.compound,
          }))
        );
      }
    } else if (!resultData) {
      setData({ ...initialState, purificationComment: experimantData.purification });
      setErrors({ ...initialErrors });
      setAppendedFiles({ ...appendedInitialState });
    }
  }, [resultData]);

  useEffect(() => {
    getExperimentResultPurifications().then(resp => {
      setOptions([...resp.map(option => ({ value: option, label: option }))]);
    });
  }, []);

  useEffect(() => {
    if (experimantData?.reactionsInfo && !resultData?.uuid) {
      let reactionsforSJP = [];
      let infoReactions = JSON.parse(experimantData?.reactionsInfo);
      if (infoReactions.process_steps) {
        infoReactions.process_steps.forEach(step => {
          let ancestors = [];
          const vialWithoutCopy = step.step?.vials
            .map(vial => {
              if (!ancestors.includes(vial.ancestor)) {
                if (vial.ancestor) {
                  ancestors.push(vial.ancestor);
                }
                return vial;
              }
            })
            .filter(vial => !!vial);
          reactionsforSJP = vialWithoutCopy.map((vial, reactionIndex) => {
            const productInfo = infoReactions.products.find(product => product.name == vial.product);
            return {
              name: getReactionName(reactionIndex),
              mass: null,
              product_yield: null,
              compound: infoReactions.compounds.find(cpd => cpd.compound_id === productInfo.product),
            };
          });
        });
        setReactions([...reactionsforSJP]);
      }
    }
  }, [experimantData]);

  const onAppendFile = (file, type, reactionNumber, allReactionNumber) => {
    setAppendedFiles(prevFiles => {
      let allFiles = [...prevFiles[type]];
      if (type == 'Reaction attachment') {
        for (let r = 0; r < allReactionNumber; r++) {
          if (!allFiles[r]) {
            allFiles[r] = [];
          }
          if (reactionNumber == r) {
            allFiles[r].push({ file: file.path, loaded: false });
          }
        }
      } else allFiles = [...prevFiles[type], { ...file }];
      return {
        ...prevFiles,
        [type]: [...allFiles],
      };
    });
    const data = new FormData();
    data.append('file', file, file.name);
    if (FILE_TYPES_SJPRO[type]) data.append('type', FILE_TYPES_SJPRO[type]);
    uploadFile(data).then(resp => {
      if (resp.uuid) {
        setAppendedFiles(prevFiles => {
          const fileIndex = prevFiles[type].length - 1;
          if (type == 'Reaction attachment') {
            return {
              ...prevFiles,
              [type]: prevFiles[type].map((fileArr, idx) => {
                let filesAttachment = fileArr.filter(f => f.loaded);
                if (reactionNumber === idx) {
                  const fileUrl = new URL(resp.file);
                  return [...filesAttachment, { file: fileUrl.pathname.substring(1), loaded: true, uuid: resp.uuid }];
                }
                return fileArr;
              }),
            };
          } else {
            return {
              ...prevFiles,
              [type]: prevFiles[type].map((file, idx) => {
                if (fileIndex === idx) {
                  const fileUrl = new URL(resp.file);
                  return {
                    ...file,
                    file: fileUrl.pathname.substring(1),
                    loaded: true,
                    uuid: resp.uuid,
                  };
                }
                return file;
              }),
            };
          }
        });
      }
    });
  };

  const onLoadFile = (file, type) => {};

  const onDeleteAttachment = (file, idx, type, reactionNumber) => {
    setFileToDelete({ file, idx, type, reactionNumber });
    setDeleteAttachmentConfirmationOpen(true);
  };

  const handleSubmitDeleteAttachment = () => {
    const { idx, type, reactionNumber } = fileToDelete;
    const files = { ...appendedFiles };
    destroyResultAttachment(
      type == 'Reaction attachment' ? files[type][reactionNumber][idx].uuid : files[type][idx].uuid
    )
      .then(resp => {
        if (resp.errors) {
          openNotification('Error');
        }
        if (type == 'Reaction attachment') {
          files[type][reactionNumber].splice(idx, 1);
        } else {
          files[type].splice(idx, 1);
        }
        setAppendedFiles(files);
        setDeleteAttachmentConfirmationOpen(false);
      })
      .catch(err => {
        openNotification('Error');
      });
  };

  return (
    <>
      <div className="append-results__content append-results__content_sjpro">
        <Tabs activeKey={tab} options={options_tab} onChange={setTab} />
        {tab == 'workup_purification' && (
          <div className="append-results__content__top-block">
            <div className="append-results__horizontal_block">
              <div className="append-results__content__top-block__content">
                <div className="append-results__content__title">Workup</div>
                <Input
                  autoFocus
                  allowSpecials
                  withoutComma={false}
                  type="textarea"
                  maxLength="1000"
                  placeholder="Please provide the workup details"
                  value={data.workup}
                  onChange={value => changeData(value, 'workup')}
                  className="workup-details"
                />
              </div>
              <div className="append-results__content__top-block__content">
                <div className="append-results__content__title">Offline analysis</div>
                <OfflineAnalysisBlock
                  title="Offline LC-MS"
                  onDeleteAttachment={onDeleteAttachment}
                  appendedFiles={appendedFiles['Offline LC-MS']}
                  type={'Offline LC-MS'}
                  onAppendFile={onAppendFile}
                  onLoadFile={onLoadFile}
                />
              </div>
            </div>
            <div className="append-results__horizontal_block purification_block">
              <div className="append-results__content__top-block__content">
                <PurificationDetails purificationData={experimantData.purification} />
              </div>
              <div className="append-results__content__top-block__content">
                <div className="append-results__content__title">Purification</div>
                <Select
                  onChange={value => changeData(value, 'purification')}
                  options={options}
                  value={data.purification || 'None'}
                />
                <OfflineAnalysisBlock
                  title="Purification results"
                  onDeleteAttachment={onDeleteAttachment}
                  appendedFiles={appendedFiles.purification}
                  type="purification"
                  onAppendFile={onAppendFile}
                  onLoadFile={onLoadFile}
                />
              </div>
            </div>
          </div>
        )}
        {tab == 'masses_yields' && (
          <div className="append-results__content__top-block">
            <ReactionData
              reactions={reactions}
              setReactions={setReactions}
              setErrors={setErrors}
              errors={errors}
              showTime={false}
              attachedFiles
              onDeleteAttachment={onDeleteAttachment}
              onAppendFile={onAppendFile}
              onLoadFile={onLoadFile}
              appendedFiles={appendedFiles['Reaction attachment']}
            />
          </div>
        )}
      </div>

      <Popup
        title=" "
        open={deleteAttachmentConfirmationOpen}
        handleSubmit={handleSubmitDeleteAttachment}
        textSubmit="Delete"
        textCancle="Cancel"
        handleCancel={() => setDeleteAttachmentConfirmationOpen(false)}
      >
        Do you want to proceed and delete the attachment?
      </Popup>
    </>
  );
};
