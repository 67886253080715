import React from 'react';
import './styles.scss';

export const HeaderSlide = ({ customTitle, currentSlide, countSlide, Title }) => (
  <span className="header-slide">
    {customTitle ? (
      <Title />
    ) : (
      <>
        <span className="header-slide_current">{`Reaction ${currentSlide + 1}`}</span>
        &nbsp;
        <span className="header-slide_all">{`out of ${countSlide}`}</span>
      </>
    )}
  </span>
);
