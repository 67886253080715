import React, { useEffect, useState } from 'react';
import { Collapse, Spinner } from 'components/Common';
import './style.scss';
import { useDispatch } from 'react-redux';
import { HeatingBlockSection } from '../../BatchConfigurationWithExperimentsTable/HeatingBlockSection/HeatingBlockSection';
import { BatchExperimentsSection } from '../../BatchConfigurationWithExperimentsTable/BatchExperimentsSection/BatchExperimentsSection';
import { SJBatchConfiguration } from '../../SJBatchConfiguration/SJBatchConfiguration';
import { EXPERIMENT_STATUSES } from '../../../../constants';
import FinishPlate from './FinishPlate';
import {
  subscribeToConnectivityChange,
  unsubscribeFromConnectivityChange,
} from '../../../../store/experiment/experiment.actions';

const SynJetProExperimentProgress = ({
  batch,
  setBatchStatus,
  finalizeAndComplete,
  goToFinalization,
  manageExperimentRoutes,
  getDetails,
  dispensingOptions,
  errors,
  showDispensing = false,
  plateVialsData,
  getProduct,
  connectivitySubject,
  setConnectivitySubject,
  setDisableButtons,
  disableButtons,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (batch.experiments[0]?.timeSlot?.device?.name) {
      if (connectivitySubject) {
        setDisableButtons(false);
        dispatch(unsubscribeFromConnectivityChange());
      }
      const conSubject = dispatch(
        subscribeToConnectivityChange(batch.experiments[0]?.timeSlot.device.name, setDisableButtons)
      );
      setConnectivitySubject(conSubject);
    }

    return () => {
      dispatch(unsubscribeFromConnectivityChange());
    };
  }, [batch.experiments[0]?.timeSlot?.device?.name]);

  return (
    <>
      <HeatingBlockSection
        receiveData={batch?.status === EXPERIMENT_STATUSES.InProgress.value}
        batch={batch}
        isSynjetPro
      />
      {showDispensing && (
        <Collapse
          className="batch-collapse configuration_collapse"
          items={[
            {
              title: 'Batch configuration',
              key: 0,
              body: <SJBatchConfiguration batch={batch} isPro showTableOnly />,
            },
          ]}
        />
      )}
      <BatchExperimentsSection
        batch={batch}
        setBatchStatus={setBatchStatus}
        finalizeAndComplete={finalizeAndComplete}
        goToFinalization={goToFinalization}
        manageExperimentRoutes={manageExperimentRoutes}
        isProExecution
        disableButtons={disableButtons}
      />
      <Collapse
        defaultActiveKey={1}
        className="batch-collapse"
        items={[
          {
            title: 'Finish plate',
            key: 1,
            body: (
              <FinishPlate
                batch={batch}
                plateVialsData={plateVialsData}
                experimentNames={plateVialsData.experiments}
                getProduct={getProduct}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default SynJetProExperimentProgress;
