import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEVICES, PROCESS_TYPES } from '../../../../../constants';
import { ProcessPreviewData } from '../../../../SynJet/SynJetProccess/SynjetViewProcessPopup/ProcessPreviewData';
import { WorkSpace } from '../../../../ProcessBuilder/WorkSpace/WorkSpace';
import { getProcess as getSynjetProcess } from '../../../../../store/synjet/synjet.actions';
import { parseProcessBuilderData } from '../../../../../utils';
import { Spinner } from '../../../../Common';
import './style.scss';
import { getProcessStructure } from '../../../../../store/autosyn/autosyn.actions';
import { ManualWorkArea } from '../../../../ProcessBuilder/WorkSpace/ManualWorkArea';
import { parseProcessBuilderDataFromBackend } from '../../../../../utils/manualProcessBuilder';

export const ProcessInfo = ({ itemId, device }) => {
  const [synjetProcessData, setSynjetProcessData] = useState(null);
  const [autosynProcessData, setAutosynProcessData] = useState(null);
  const [labProcessData, setLabProcessData] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getData = async itemId => {
    setLoading(true);
    try {
      if (device === PROCESS_TYPES.SynJet.device || device === PROCESS_TYPES.SynJetPro.device) {
        const data = await dispatch(getSynjetProcess(itemId));
        setSynjetProcessData(data.process);
      } else if (device === PROCESS_TYPES.AutoSyn.device) {
        const data = await dispatch(getProcessStructure(itemId));
        setAutosynProcessData(
          data.process ? parseProcessBuilderData(data.process, { allReactors: data.allReactors }, data.type) : null
        );
      } else {
        const data = await dispatch(getProcessStructure(itemId, true));
        const manualProcess = parseProcessBuilderDataFromBackend(data.process, data.type);

        setLabProcessData({
          process: manualProcess.parsedData,
          description: manualProcess.description,
          type: data.type,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(itemId);
  }, [itemId]);

  return (
    <div className="process_info-tree">
      <Spinner loading={loading}>
        {(device === PROCESS_TYPES.SynJet.key || device === DEVICES.SYNJETPRO) && synjetProcessData && (
          <ProcessPreviewData processType={synjetProcessData.type} isPro={device === DEVICES.SYNJETPRO} />
        )}
        {(device === PROCESS_TYPES.AutoSyn.key || device === PROCESS_TYPES.Lab.key) && autosynProcessData && (
          <WorkSpace previewMode data={autosynProcessData} processType={autosynProcessData.type} />
        )}
        {device === PROCESS_TYPES.Lab.key && labProcessData && (
          <div className="work-space-view-mode">
            <div className="view-mode-wrapper">
              <ManualWorkArea
                data={labProcessData.process}
                description={labProcessData.description}
                processType={labProcessData.type}
                editMode={false}
              />
            </div>
          </div>
        )}
      </Spinner>
    </div>
  );
};
