import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import { SynJetCompound } from '../../index';
import { addQuenchingItem, setQuenchingList } from '../../../../store/synjet/synjet.actions';
import {
  calculateQuenchingListOnLimited,
  getSynJetCompoundLetter,
  recalculateQuenchingName,
} from '../../../../utils/synjetHelpers';
import { selectAbilityAddCompound } from '../../../../store/synjet/synjet.selector';
import { Button } from '../../../Common';

const QUENCHING_LIST_HEADERS = ['Name', 'Solvent', 'Concentration (M)'];

export const SynjetProQuenchingList = ({ quenchingList, viewOnly, limitingCompoundGroup }) => {
  const dispatch = useDispatch();
  const isCompoundAddAvailable = useSelector(selectAbilityAddCompound);
  const canAddSolution =
    isCompoundAddAvailable && (!!quenchingList?.[quenchingList.length - 1]?.smiles || !quenchingList?.length);

  useEffect(() => {
    if (!limitingCompoundGroup) return;
    const updatedList = calculateQuenchingListOnLimited(limitingCompoundGroup, quenchingList);
    dispatch(setQuenchingList(updatedList));
  }, [limitingCompoundGroup?.compoundsList, limitingCompoundGroup?.isLimiting]);

  useEffect(() => {
    const updatedList = recalculateQuenchingName(quenchingList);
    dispatch(setQuenchingList(updatedList));
  }, [quenchingList?.length]);

  const handleQuenchingItemChange = (name, updatedQuenchingItem) => {
    const updatedQuenchingList = quenchingList.map(item => (item.name === name ? updatedQuenchingItem : item));
    dispatch(setQuenchingList(updatedQuenchingList));
  };

  const handleQuenchingItemDelete = name => {
    const updatedQuenchingList = quenchingList.filter(item => item.name !== name);
    dispatch(setQuenchingList(updatedQuenchingList));
  };

  const handleQuenchingAdd = () => {
    dispatch(addQuenchingItem(limitingCompoundGroup));
  };

  return (
    <div className="synjet-quenching">
      <p className="synjet-quenching__header">Solution</p>
      <div className="synjet-quenching__list">
        <div className="synjet-quenching__list__header">
          {QUENCHING_LIST_HEADERS.map(header => (
            <span className="title">{header}</span>
          ))}
          <div
            className="equivalent"
            style={{
              gridTemplateColumns: limitingCompoundGroup
                ? `repeat(${limitingCompoundGroup.compoundsList.length + 1}, 1fr)`
                : '1fr 1fr',
            }}
          >
            <span />
            {limitingCompoundGroup ? (
              limitingCompoundGroup.compoundsList.map((compound, index) => (
                <span className="title">{`Fixed ${getSynJetCompoundLetter(index, 0)}`}</span>
              ))
            ) : (
              <span className="title" />
            )}
          </div>
        </div>
        {quenchingList.map((quenching, index) => (
          <SynJetCompound
            key={quenching.name}
            letter={quenching.name}
            compoundInfo={quenching}
            type="quenching"
            handleSubmit={updatedItem => handleQuenchingItemChange(quenching.name, updatedItem)}
            viewOnly={viewOnly}
            limitingCompoundGroup={limitingCompoundGroup}
            handleDelete={() => handleQuenchingItemDelete(quenching.name)}
            disableDelete={!index}
          />
        ))}
      </div>
      {canAddSolution && (
        <Button className="add-btn" onClick={handleQuenchingAdd}>
          Add Solution
        </Button>
      )}
    </div>
  );
};
