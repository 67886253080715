export const generateCSVFile = (csvArray, fileName) => {
  let uriContent = URL.createObjectURL(
    new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        csvArray.join('\n'),
      ],
      { type: 'data:text/csv;charset=utf-8,' }
    ) //each array is a new line in table
  );

  //download file
  let link = document.createElement('a');
  link.setAttribute('href', uriContent);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};
