import React from 'react';
import cn from 'classnames';
import './style.scss';
import { CheckOutlined } from '@ant-design/icons';

const STEPS = [
  'Revise the details',
  'Prepare the materials',
  'Start up checklist',
  'Experiment progress',
  'Finalization',
];

const SYNJET_STEPS = [
  'Revise the details',
  'Batch configuration',
  'Prepare the materials',
  'Experiment progress',
  'Finalization',
];

const SYNJET_PRO_STEPS = [
  'Prepare the materials',
  'Configure dispensers',
  'Batch configuration',
  'Load materials',
  'Experiment progress',
  'Finalization',
];

const LAB_STEPS = ['Prepare the materials', 'Experiment progress', 'Finalization'];

export const Steps = ({ active, isSynJet, isSynJetPro, isLab }) => {
  const steps = isSynJet ? SYNJET_STEPS : isSynJetPro ? SYNJET_PRO_STEPS : isLab ? LAB_STEPS : STEPS;
  return (
    <div className="execution-step-wrapper">
      {steps.map((v, k) => {
        const index = k + 1;
        const isCompleted = active > index;
        return (
          <div key={k} className={cn('step-item', { active: active === index, previous: isCompleted })}>
            <div className="step-index">{isCompleted ? <CheckOutlined /> : <span>{index}</span>}</div>
            <div className="step-label">{v}</div>
          </div>
        );
      })}
    </div>
  );
};
