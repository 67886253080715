import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import moment from 'moment';
import ListExperiments from '../ListExperiments';
import { BatchDetails } from '../BatchDetails/BatchDetails';
import Calendar from '../Scheduling/Calendar';
import Experiment from '../Experiment';
import { modeSched, modeDetails, DEVICES, TYPES_EVENTS } from '../../constants';
import history, { updateURLParams } from '../../utils/history';
import { getDevices, setDevices } from '../../store/common/common.actions';
import { manageBatch } from '../../store/scheduling/scheduling.actions';

const ContainerExprSched = ({ className, mode, devices, user, experimentKey, isSynJetPro }) => {
  const [modeCalender, setModeCalendar] = useState(mode === modeSched);
  const [calendarApi, setCalendarApi] = useState(null);
  const [visibleRangeCalendara, setVisibleRangeCalendar] = useState({ type: 'timeGridWeek' });
  const [rejectedExperiment, setRejectedExperiment] = useState(null);
  const [device, setDevice] = useState('');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [detailsKey, setDetailsKey] = useState(query.get('key'));
  const [deviceId, setDeviceId] = useState(query.get('deviceId'));
  const [viewBatch, setViewBatch] = useState(query.get('isBatch') == 'true');
  const [rejectBatch, setRejectBatch] = useState(false);
  const [rejectExperimentInQueue, setRejectExperimentInQueue] = useState(false);
  const [batchId, setBatchId] = useState(null);
  const [getListExperiments, setGetListExperiments] = useState(false);
  const [confirmationDetails, setConfirmationDeatails] = useState(null);
  const [devicesIsLoaded, setDevicesIsLoaded] = useState(false);
  const [sideLoader, setSideLoader] = useState(false);
  const [typeEventsList, setTypeEventsList] = useState(TYPES_EVENTS[0].value);
  const dispatch = useDispatch();
  const location = useLocation();
  const batchDetails = query.get('isBatch');
  const currentId = query.get('key');

  useEffect(() => {
    const main = document.querySelector('.main');
    main.style.padding = '0';
    dispatch(getDevices(location?.pathname?.indexOf('execution') >= 0)).then(() => {
      setDevicesIsLoaded(true);
    });
    return () => {
      dispatch(setDevices([]));
      main.style.padding = '24px 24px 0 24px';
      const searchParams = new URLSearchParams(window.location.search);
      query.delete('deviceId');
      query.delete('key');
      query.delete('isBatch');
    };
  }, []);

  useEffect(() => {
    if (experimentKey) {
      setDetailsKey(experimentKey);
    }
  }, [experimentKey]);

  useEffect(() => {
    setViewBatch(query.get('isBatch') == 'true');
  }, [batchDetails, currentId]);

  useEffect(() => {
    if (currentId) {
      setDetailsKey(currentId);
      setCalendarApi(null);
      setModeCalendar(false);
    }
  }, [currentId]);

  useEffect(() => {
    if (!devicesIsLoaded) return;
    const searchParams = new URLSearchParams(window.location.search);
    const _deviceId = searchParams.get('deviceId');

    if (deviceId && devices?.length && !device) {
      setDevice(devices?.find(i => i.value === deviceId)?.value || devices[0].value);
    } else if (!deviceId && devices?.length && !_deviceId) {
      if (viewBatch) setDevice(devices.find(i => i.type === DEVICES.SYNJET)?.value);
      else {
        setDevice(devices[0].value);
      }
    }
  }, [deviceId, devices, devicesIsLoaded]);

  useEffect(() => {
    if (device) {
      const { pathname } = window.location;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('deviceId', device);
      setDeviceId(device);
      history.push({
        pathname,
        search: searchParams.toString(),
      });
    }
  }, [device]);

  const navigateToFirstBatch = () => {
    setSideLoader(true);
    dispatch(manageBatch({ data: { device }, type: 'getBatchForAssign' }))
      .then(resp => {
        history.push(`/execution?key=${resp[0].batchId}&tab=scheduled&isBatch=true&deviceId=${deviceId}`);
      })
      .finally(() => {
        setGetListExperiments(true);
        setSideLoader(false);
      });
  };

  const handleExperimentReject = key => {
    setDetailsKey(null);
    setRejectedExperiment(key);
    if (mode === modeSched) setModeCalendar(true);
  };

  const setUpCalendarApi = calendar => {
    if (calendar && !calendarApi) setCalendarApi(calendar);
  };

  const goToBatchDetails = data => {
    setViewExperiment(data.batchId, 'scheduled', true);
  };

  const setViewExperiment = (key, tab = 'scheduled', isBatch = false, _deviceId) => {
    if (!key) return;
    if (!_deviceId) _deviceId = deviceId;
    setViewBatch(isBatch);

    setDeviceId(_deviceId);

    if (mode === modeSched) {
      updateURLParams([
        { key: 'isBatch', value: isBatch },
        { key: 'key', value: key },
        { key: 'tab', value: tab },
        { key: 'deviceId', value: _deviceId },
      ]);
      if (calendarApi) {
        const api = calendarApi.getApi();
        setVisibleRangeCalendar({
          type: api.view.type,
          date: moment(api.view.currentStart).format('YYYY-MM-DD'),
        });
      }
      setCalendarApi(null);
      setModeCalendar(false);
    } else {
      history.push(`/execution?key=${key}&tab=${tab}&isBatch=${isBatch}&deviceId=${device}`);
    }
    setDetailsKey(key);
  };

  const closeDetails = (value = true) => {
    updateURLParams([{ key: 'deviceId', value: deviceId }]);
    setModeCalendar(value);
    setDetailsKey(null);
  };

  const permissions = user.permissions || {};

  const deviceType = devices.find(i => i.uuid === device)?.type;
  const isSynjetDevice = deviceType === DEVICES.SYNJET;
  const isSynjetProDevice = deviceType === DEVICES.SYNJETPRO;
  const isLabDevice = deviceType === DEVICES.LAB;
  return (
    <div className={cn('expr-sched-container', className)}>
      {mode !== modeDetails && (
        <ListExperiments
          mode={mode}
          typeEventsList={typeEventsList}
          setTypeEventsList={setTypeEventsList}
          confirmationDetails={confirmationDetails}
          setConfirmationDeatails={setConfirmationDeatails}
          isSynjetDevice={isSynjetDevice}
          isSynjetProDevice={isSynjetProDevice}
          isLabDevice={isLabDevice}
          devices={devices}
          setDevice={setDevice}
          device={device}
          permissions={permissions}
          calendarApi={calendarApi}
          setExperiment={setViewExperiment}
          experiment={detailsKey}
          rejectedExperiment={rejectedExperiment}
          rejectBatch={rejectBatch}
          rejectExperimentInQueue={rejectExperimentInQueue}
          batchId={batchId}
          setGetListExperiments={setGetListExperiments}
          getListExperiments={getListExperiments}
          sideLoader={sideLoader}
        />
      )}
      {modeCalender ? (
        <Calendar
          typeEventsList={typeEventsList}
          setTypeEventsList={setTypeEventsList}
          confirmationDetails={confirmationDetails}
          setConfirmationDeatails={setConfirmationDeatails}
          isSynjetDevice={isSynjetDevice || isSynjetProDevice}
          isSynjetPro={isSynjetProDevice}
          visibleRangeCalendara={visibleRangeCalendara}
          setVisibleRangeCalendar={setVisibleRangeCalendar}
          permissions={permissions}
          setViewExperiment={setViewExperiment}
          device={device}
          setCalendarApi={setUpCalendarApi}
          calendarApi={calendarApi}
          goToBatchDetails={goToBatchDetails}
          isLabDevice={isLabDevice}
          setSideLoader={setSideLoader}
        />
      ) : (
        <>
          {detailsKey &&
            (!viewBatch ? (
              <Experiment
                permissions={permissions}
                mode={mode}
                device={device}
                experimentKey={detailsKey}
                calendarApi={calendarApi}
                isSynJet={isSynjetDevice}
                isSynJetPro={isSynjetProDevice}
                setModeCalendar={value => {
                  closeDetails(value);
                }}
                getListExperiments={() => setGetListExperiments(true)}
                handleExperimentReject={handleExperimentReject}
                navigateToFirstExperiment={(id, detailsKey) => {
                  setBatchId(id);
                  setDetailsKey(detailsKey);
                  setRejectExperimentInQueue(true);
                }}
                isLabDevice={isLabDevice}
                setViewBatch={setViewBatch}
                setSideLoader={setSideLoader}
                navigateToFirstBatch={navigateToFirstBatch}
              />
            ) : (
              <BatchDetails
                detailsKey={detailsKey}
                closeBatchDetails={closeDetails}
                mode={mode}
                deviceId={deviceId}
                getListExperiments={() => setGetListExperiments(true)}
                goToExperimentDetails={() => {
                  setViewBatch(false);
                }}
                navigateToFirstBatch={() => {
                  setRejectBatch(true);
                }}
                setDetailsKey={id => setDetailsKey(id)}
                setSideLoader={setSideLoader}
              />
            ))}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  devices: state.commonReducer.devices,
  user: state.commonReducer.user,
});

export default connect(mapStateToProps)(ContainerExprSched);
