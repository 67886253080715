import React, { useState } from 'react';
import '../style.scss';
import '../tableStyles.scss';
import { Spinner } from '../../../Common/Spinner';
import { SolutionsTable } from './SolutionsTable';
import { SolidsSummaryTable } from './SolidsSummaryTable';
import { PageContainer } from '../../../PdfGenerator/PageContainer';
import { StatusLabel } from '../../../Common';

export const SynJetProPrepareMaterials = ({
  batch,
  errors,
  setErrors,
  setEdited,
  experiments,
  setExperiments,
  tableData,
  setTableData,
  pdfMode,
}) => {
  const [loading, setLoading] = useState(false);
  const setSolutionTableData = value => {
    setTableData(prevState => ({
      ...prevState,
      solution_structure: value,
    }));
  };

  const renderSolutionsTable = (
    <div className="syn-jet-materials_solutions-table">
      <Spinner loading={loading}>
        <SolutionsTable
          batch={batch}
          experiments={experiments}
          setExperiments={setExperiments}
          setEdited={setEdited}
          errors={errors}
          setErrors={setErrors}
          tableData={tableData.solution_structure}
          setTableData={setSolutionTableData}
          setLoading={setLoading}
          pdfMode={pdfMode}
        />
      </Spinner>
    </div>
  );

  const renderSummaryTable = (
    <div className="syn-jet-materials_solids-table">
      <Spinner loading={loading}>
        <SolidsSummaryTable
          experiments={experiments}
          setTableData={setTableData}
          tableData={tableData.solid_structure}
          pdfMode={pdfMode}
        />
      </Spinner>
    </div>
  );

  if (pdfMode) {
    return (
      <>
        <PageContainer>
          <div className="sj-execution-header">
            <div className="name">
              Batch
              {batch?.batchId}
            </div>
            <StatusLabel type="active">{batch?.status}</StatusLabel>
          </div>
          <span className="syn-jet-materials_title">Solutions</span>
          <div data-protected>{renderSolutionsTable}</div>
        </PageContainer>
        <PageContainer>
          <span className="syn-jet-materials_title">Solids summary</span>
          <div data-protected>{renderSummaryTable}</div>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <div className="syn-jet-materials">
        <span className="syn-jet-materials_title">Solutions</span>
        {renderSolutionsTable}
      </div>
      <div className="syn-jet-materials">
        <span className="syn-jet-materials_title">Solids summary</span>
        {renderSummaryTable}
      </div>
    </>
  );
};
