import React, { useEffect, useState } from 'react';
import { HeatingBlockSection } from './HeatingBlockSection/HeatingBlockSection';
import { EXPERIMENT_STATUSES } from 'constants/common';
import { Collapse } from 'components/Common';
import { SJBatchConfiguration } from 'components/Execution/SJBatchConfiguration/SJBatchConfiguration';
import './style.scss';
import { useDispatch } from 'react-redux';
import { SJDispensingOptions } from '../SynJetExecution/SynJetReviseTheDeteils/SJDispensingOptions';
import { BatchExperimentsSection } from './BatchExperimentsSection/BatchExperimentsSection';
import {
  subscribeToConnectivityChange,
  unsubscribeFromConnectivityChange,
} from '../../../store/experiment/experiment.actions';

export const BatchConfigurationWithExperimentsTable = ({
  batch,
  setBatchStatus,
  finalizeAndComplete,
  goToFinalization,
  manageExperimentRoutes,
  getDetails,
  dispensingOptions,
  errors,
  showDispensing = false,
  connectivitySubject,
  setConnectivitySubject,
  setDisableButtons,
  disableButtons,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (batch.experiments[0]?.timeSlot?.device?.name) {
      if (connectivitySubject) {
        setDisableButtons(false);
        dispatch(unsubscribeFromConnectivityChange());
      }
      const conSubject = dispatch(
        subscribeToConnectivityChange(batch.experiments[0]?.timeSlot.device.name, setDisableButtons)
      );
      setConnectivitySubject(conSubject);
    }

    return () => {
      dispatch(unsubscribeFromConnectivityChange());
    };
  }, [batch.experiments[0]?.timeSlot?.device?.name]);

  return (
    <>
      <HeatingBlockSection
        receiveData={
          batch?.status === EXPERIMENT_STATUSES.InProgress.value ||
          batch?.status === EXPERIMENT_STATUSES.Inpreparation.value
        }
        batch={batch}
      />
      {showDispensing && (
        <Collapse
          className="batch-collapse configuration_collapse"
          items={[
            {
              title: 'Dispensing options',
              key: 0,
              body: (
                <SJDispensingOptions
                  dispensingOptions={dispensingOptions}
                  experiments={batch?.experiments}
                  batchId={batch?.uuid}
                  getDetails={getDetails}
                  step={batch?.step}
                  errors={errors}
                  previewDispensers
                />
              ),
            },
            {
              title: 'Batch details',
              key: 1,
              body: <SJBatchConfiguration batch={batch} showTableOnly />,
            },
          ]}
        />
      )}
      <BatchExperimentsSection
        batch={batch}
        setBatchStatus={setBatchStatus}
        finalizeAndComplete={finalizeAndComplete}
        goToFinalization={goToFinalization}
        manageExperimentRoutes={manageExperimentRoutes}
        disableButtons={disableButtons}
      />
    </>
  );
};
