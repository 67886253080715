import React, { useCallback, useEffect } from 'react';
import moment from 'moment';

import { Checkbox, Input, Select } from '../../Common';
import { getRoutesOfProject } from '../../../store/autosyn/autosyn.actions';
import {
  STEP_OPTIONS,
  SYNJET_TYPE_OPTIONS,
  AUTOSYN_TYPE_OPTIONS,
  SYNJET_PRO_TYPE_OPTIONS,
} from '../../../constants/createProcessConstatants';
import { PROCESS_TYPES, steps_synjet, steps_synjet_pro } from '../../../constants';

export const Header = props => {
  const getProjects = useCallback(
    value =>
      props
        .getListProjects(
          {
            page: 1,
            action: 'create process',
            search: value,
          },
          true,
          20
        )
        .then(resp => {
          if (value === '') return [];
          if (resp?.results) return resp.results.map(i => ({ value: i.uuid, label: i.name }));
          return [];
        }),
    []
  );

  useEffect(() => {
    if (props.experiments.length) {
      const sortedExperiments = props.experiments.sort((a, b) =>
        !a.completedAt || !b.completedAt ? (a.completedAt ? -1 : 1) : moment(b.completedAt).diff(moment(a.completedAt))
      );
      props.setResultingExperiment(sortedExperiments[0].value);
      props.setResultingRoute(0);
    }
  }, [JSON.stringify(props.experiments), props.open]);

  const getRoutes = search =>
    getRoutesOfProject({
      search,
      project: props.filters.project,
    });

  let title = 'Create new process';
  if (props.previewRouteProcessData) title = `Route ${props.previewRouteProcessData.route?.name}`;
  if (props.isPreviewResult) title = 'Resulting route(s)';

  return (
    <div className="creator-processes_header">
      <span className="creator-processes_header_title">{title}</span>
      {props.previewRouteProcessData ? (
        <div className="creator-processes_header_info">
          <span>
            Project:
            {props.previewRouteProcessData.project.name}
          </span>
          <span>
            Process name:
            {props.previewRouteProcessData.name}
          </span>
        </div>
      ) : (
        <div className="creator-processes_header_filters-container">
          <div className="creator-processes_header_filters">
            {props.isPreviewResult ? (
              <>
                <Select
                  options={props.experiments}
                  onChange={value => {
                    props.setResultingExperiment(value);
                    props.setResultingRoute(0);
                  }}
                  label="Experiment"
                  value={props.resultingExperiment}
                  placeholder="Select experiments"
                />
                <Select
                  disabled={!props.resultingExperiment}
                  options={props.routes}
                  onChange={props.setResultingRoute}
                  label="Route"
                  value={props.resultingRoute}
                  placeholder={props.resultingExperiment ? 'Select route' : 'Please select the experiment'}
                />
              </>
            ) : (
              <>
                <Input
                  disabled={props.disabled}
                  maxLength={256}
                  require
                  autoFocus
                  label="Set process name"
                  placeholder="Process name"
                  value={props.filters.name}
                  onChange={props.updateFilters}
                  error={props.errorName}
                  field="name"
                  errorText={props.errorName}
                  allowSpecials
                />
                {!props.isSynjet && !props.isSynjetPro && (
                  <Select
                    disabled={props.disabled}
                    onChange={value => props.updateFilters(value, 'type')}
                    label="Process type"
                    options={AUTOSYN_TYPE_OPTIONS}
                    value={props.filters.type}
                    placeholder="Select type"
                  />
                )}
                <Select
                  required
                  disabled={props.disabled}
                  typeToSearch
                  getOptions={getProjects}
                  onChange={value => props.updateFilters(value, 'project')}
                  label="Project"
                  value={props.filters.project}
                  placeholder="Select project"
                  notFoundContent={false}
                />
                <Select
                  required
                  disabled={props.disabled || !props.filters.project}
                  trigger={props.filters.project}
                  typeToSearch
                  getOptions={getRoutes}
                  onChange={value => props.updateFilters(value, 'route')}
                  label="Route"
                  value={props.filters.route}
                  placeholder={props.filters.project ? 'Select route' : 'Please select the project'}
                />
                {props.filters.route && (
                  <Select
                    disabled={props.disabled}
                    options={props.segmenetsOptions}
                    onChange={value => props.updateFilters(value, 'segment')}
                    label="Segment"
                    value={props.filters.segment}
                    placeholder="Select segment"
                  />
                )}
              </>
            )}
          </div>
          {(props.isSynjet || props.isSynjetPro) && (
            <div className="creator-processes_header_filters-container_synjet">
              <Select
                disabled={props.disabled}
                onChange={value => props.updateFilters(value, 'type')}
                label="Process type"
                options={props.isSynjetPro ? SYNJET_PRO_TYPE_OPTIONS : SYNJET_TYPE_OPTIONS}
                value={props.filters.type}
                placeholder="Select type"
              />
              <Select
                disabled={props.disabled}
                onChange={value => props.updateFilters(value, 'steps')}
                label="Number of steps"
                options={props.isSynjetPro ? steps_synjet_pro : steps_synjet}
                error={props.segmentError}
                value={props.filters.steps}
                placeholder="Select type"
              />
              {props.isSynjetPro &&
                (props.filters.steps === steps_synjet_pro[0].value ? (
                  <Checkbox
                    field="enableQuenching"
                    disabled={props.disabled}
                    onChange={value => props.updateFilters(value, 'enableQuenching')}
                    value={props.filters.enableQuenching}
                  >
                    Add quenching
                  </Checkbox>
                ) : (
                  <Select
                    label="Variable step"
                    options={props.isSynjetPro ? steps_synjet_pro : steps_synjet}
                    value={props.filters.variableStep}
                    // required
                    onChange={value => props.updateFilters(value, 'variableStep')}
                    disabled={props.disabled}
                  />
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
