import { prepareDataRoute } from './helpers';
import { setTime } from './parseRoute';

const hydrogen = '[H][H]';
const emptyState = { equipment: null, glassware: null, inputs: [] };
const typesEquipments = {
  coolling: {
    name: 'Cooling bath',
    type: 'cooling_bath',
  },
  shaker: {
    name: 'Parr shaker',
    type: 'parr_shaker',
  },
  heating: {
    name: 'Heating bath/mantle',
    type: 'heating_bath',
  },
};
const setGlassware = () => ({ otherType: null, size: '0.5', type: 'Flask' });
const setEqupment = ({ equipment, temp, idxCondition, reactor, isProduct, compoundsDataBase }) => {
  const isParrShaker = equipment.type === typesEquipments.shaker.type;
  const timeinSec = setTime({ reactor: reactor, idx: idxCondition });
  const min = Math.floor(timeinSec[0] / 60);
  const sec = timeinSec[0] - min * 60;
  const product = compoundsDataBase[reactor?.products[0]];
  const properties = product && isProduct ? [{ products: [product] }] : [{}];
  return {
    atmosphere: isParrShaker ? 'hydrogen' : null,
    atmosphereAdditional: '',
    name: equipment.name,
    otherType: '',
    pressure: isParrShaker ? 14 : null,
    properties: properties,
    temperatures: temp,
    timeMin: [min],
    timeSec: [sec || null],
    times: timeinSec,
    type: equipment.type,
  };
};

const average = nums => {
  return nums?.length ? nums.reduce((a, b) => +a + +b, 0) / nums.length : null;
};
const setTemperature = ({ reactor, idx }) => {
  const possibleTemperature = reactor?.variations?.conditions[idx]?.temps;
  const val = average(possibleTemperature);
  return [val];
};

const setTypeEquipment = ({ temp, conditions, compoundsDataBase }) => {
  const isHydrogeb = conditions?.reagents?.find(i => {
    return compoundsDataBase[i]?.smiles === hydrogen;
  });
  if (isHydrogeb) {
    return typesEquipments.shaker;
  } else if (temp[0] > 20) {
    return typesEquipments.heating;
  } else {
    return typesEquipments.coolling;
  }
};

const setDataInput = ({ count, type, compoundData, volume }) => ({
  name: `Input ${count}`,
  properties: [
    {
      chemical_type: type,
      compound: compoundData,
      concentration: '',
      density: '',
      equivalents: ['0.000'],
      limiting: false,
      mass: ['0.000'],
      moles: ['0.000'],
      mw: compoundData?.molwt,
      reagent_type: 'solid',
      solvent: null,
      solvent_volume: null,
      volume: volume,
    },
  ],
});

const setPumps = ({
  route,
  idxCondition,
  compoundsDataBase,
  countPumps,
  useReactants,
  isConditionsMoreOne,
  isFirstReactor,
  dataPrevReactor,
}) => {
  const arrPumps = [];
  let count = countPumps;
  const solvents = route?.variations?.conditions[idxCondition]?.solvents || [];
  const reagents = route?.variations?.conditions[idxCondition]?.reagents || [];
  let reactants = route?.reactants;
  if (!isFirstReactor) reactants = route?.reactants.filter(i => i !== dataPrevReactor?.products[0]);
  if (useReactants) {
    reactants.forEach((i, idx) => {
      const data = compoundsDataBase[i];
      if (data) {
        count += 1;
        arrPumps.push(setDataInput({ count, type: 'reactant', compoundData: data, volume: [''] }));
      }
    });
  }
  reagents.forEach((i, idx) => {
    const data = compoundsDataBase[i];

    if (data) {
      count += 1;
      arrPumps.push(setDataInput({ count, type: 'reagent', compoundData: data, volume: [''] }));
    }
  });
  solvents.forEach((i, idx) => {
    const data = compoundsDataBase[i];
    if (data) {
      count += 1;
      arrPumps.push({
        name: `Input ${count}`,
        properties: [
          {
            chemical_type: 'solvent',
            mw: data?.molwt,
            reagent_type: null,
            solvent: data,
            solvent_volume: '0',
          },
        ],
      });
    }
  });
  return arrPumps;
};

export const parseRouteToLab = async ({ route, segment, part }) => {
  const { reactorsDetails, compoundsDataBase, partForTransform } = await prepareDataRoute({ route, segment, part });
  let countPumps = 0;
  const newProcess = [{ ...emptyState }];
  partForTransform.forEach((item, idx) => {
    const reactorDetailsPrev = reactorsDetails[idx - 1];
    const reactorDetails = reactorsDetails[idx];
    reactorDetails.variations = reactorDetails.variations[0] || { conditions: [{ solvents: [] }] };
    const conditions = [...reactorDetails.variations.conditions];
    if (conditions.length > 1) {
      conditions.forEach((y, ydx, yArr) => {
        const temp = setTemperature({ reactor: reactorDetails, idx: ydx });
        const result = setTypeEquipment({ temp, conditions: conditions[ydx], compoundsDataBase: compoundsDataBase });
        const pumsData = setPumps({
          route: reactorDetails,
          idxCondition: ydx,
          useReactants: ydx === 0,
          isFirstReactor: !reactorDetailsPrev,
          dataPrevReactor: reactorDetailsPrev,
          compoundsDataBase,
          countPumps,
        });
        countPumps += pumsData.length;
        newProcess.push({
          equipment: setEqupment({
            compoundsDataBase,
            equipment: result,
            temp,
            idxCondition: ydx,
            reactor: reactorDetails,
            isProduct: yArr.length - 1 === ydx,
          }),
          glassware: idx === 0 && ydx === 0 ? setGlassware() : null,
          inputs: pumsData,
        });
        newProcess.push({ ...emptyState });
      });
    } else {
      const temp = setTemperature({ reactor: reactorDetails, idx: 0 });
      const result = setTypeEquipment({ temp, conditions: conditions[0], compoundsDataBase: compoundsDataBase });
      const pumsData = setPumps({
        route: reactorDetails,
        idxCondition: 0,
        compoundsDataBase,
        countPumps,
        useReactants: true,
        isFirstReactor: !reactorDetailsPrev,
        dataPrevReactor: reactorDetailsPrev,
      });
      countPumps += pumsData.length;
      newProcess.push({
        equipment: setEqupment({
          compoundsDataBase,
          equipment: result,
          temp,
          idxCondition: 0,
          reactor: reactorDetails,
          isProduct: true,
        }),
        glassware: idx === 0 ? setGlassware() : null,
        inputs: pumsData,
      });
      newProcess.push({ ...emptyState });
    }
  }, []);
  return newProcess;
};
