import React, { useState, useEffect, useCallback } from 'react';
import { Sidebar, Input, Select, Popup } from '../../Common';
import './style.scss';
import { connect } from 'react-redux';
import { getPumpTypes } from '../../../store/processBuilder/processbuilder.actions';
import { checkIfTrue } from '../../../utils/common';
import { httpSynMolDB } from '../../../utils';

const errorsInitial = {
  pump: false,
  flow_required: false,
  flow_min: false,
  flush_solvent: false,
};

const errorsText = {
  pump: 'Pump type is a required field',
  flow_required: 'Flow rate is a required field',
  flow_min: 'Flow rate should be > 0',
  flush_solvent: 'Flush solvent is a required field',
};

const errorsInitialOptimization = {
  pump: false,
  flow_required: [false],
  flow_min: [false],
  flush_solvent: false,
};

const SidebarPump = ({
  open,
  onClose,
  getPumpTypes,
  pumpsTypes,
  setPumpData,
  editingData,
  deletePump,
  id,
  processData,
  getVariableReactor,
  getPumpFlowRate,
  getVariableReactorTimes,
  pumpLevelLength,
  idPump,
}) => {
  const [pumpsOptions, setPumpsOption] = useState('');
  const [option, setOption] = useState(null);
  const [errors, setError] = useState(errorsInitial);
  const [flow, setFlow] = useState(0);
  const [edited, setEdited] = useState(false);

  const [solventsOptions, setSolventsOptions] = useState([]);
  const [selectedSolvent, setSelectedSolvent] = useState(null);
  const [cancelConfirmation, openCancelConfirmation] = useState(false);

  const [typeOfProcess, setTypeOfProcess] = useState('Production');
  const [arrFlowRate, setArrFlowRate] = useState([null]);
  const [optimizationErrors, setOptimizationErrors] = useState(errorsInitialOptimization);
  const [mfcPump, setMfcPump] = useState(false);

  useEffect(() => {
    getPumpTypes(null, '').then(data => {
      setPumpsOption(data);
    });
    httpSynMolDB('get_compound_list?tag=solvent&return_compatibility=true').then(resp => {
      setSolventsOptions(compareOptions(resp.compound_list, 'compound_name'));
    });
  }, []);

  useEffect(() => {
    setEdited(false);
    if (editingData) {
      setMfcPump(editingData.type[0] === 'MFC');
      setFlow(editingData.flow_rate);
      setArrFlowRate(editingData.flow_rate);
      setSelectedSolvent(editingData.flash_solvent[0]?.compound_name);
      setOption(pumpsOptions.data?.allPumps.find(i => i.label == editingData.type));
    } else {
      setFlow(0);
      setSelectedSolvent(null);
      setOption(null);
    }
  }, [editingData]);

  useEffect(() => {
    setTypeOfProcess(processData?.process?.type);
  }, [processData]);

  useEffect(() => {
    if (!open) {
      setOptimizationErrors(errorsInitialOptimization);
      reset();
    }

    const v = getVariableReactor();
    if (!editingData && !!v) {
      const arr = [];
      if (v.times.length > 1) {
        for (let i = 0; i < v.times.length; i++) {
          arr.push(null);
        }
        setArrFlowRate(arr);
      }
    }

    // SET flow rate
    if (open) {
      if (!editingData) {
        setMfcPump(false);
        resetFlowRates();
      }
    }
  }, [open]);

  const resetFlowRates = (sel = option?.value) => {
    const times = getVariableReactorTimes();
    const flowRates = times.map((time, timeIndex) =>
      getPumpFlowRate(
        !!editingData &&
          (!(sel !== 'MFC' && editingData.type[0] === 'MFC') || (sel !== 'MFC' && editingData.type[0] !== 'MFC')),
        null,
        true,
        timeIndex
      )
    );
    setFlow(flowRates[0]);
    setArrFlowRate(flowRates);
  };

  const onSave = () => {
    let obj = {
      pump: !option,
      flow_required: !!(option && flow == '' && flow != 0),
      flow_min: !!(option && flow <= 0),
      flush_solvent: (!selectedSolvent && !mfcPump) || (!!selectedSolvent && mfcPump),
    };
    setError(obj);
    let isValid;
    if (typeOfProcess === 'Production' || typeOfProcess === 'Library generation') {
      isValid = checkIfTrue(obj);
    } else if (typeOfProcess === 'Optimization') {
      isValid = validationOptimization();
    }
    if (!isValid) {
      setEdited(false);
      const additionalParams = editingData
        ? { ...editingData }
        : {
            properties: [
              {
                reactants: [],
                reactants_consentration: [],
                reagents: [],
                reagents_consentration: [],
                solvents: [], // solvents from compound
                solvents_fraction: [],
              },
            ],
          };
      if (typeOfProcess === 'Production' || typeOfProcess === 'Library generation') {
        obj = {
          ...additionalParams,
          type: [option.value || option],
          flow_rate: [`${flow}`],
          flash_solvent: [solventsOptions.find(i => i.compound_name === selectedSolvent)], // solvent from pump
        };
      } else if (typeOfProcess === 'Optimization') {
        obj = {
          ...additionalParams,
          type: [option.value || option],
          flow_rate: arrFlowRate,
          flash_solvent: [solventsOptions.find(i => i.compound_name === selectedSolvent)],
        };
      }
      setPumpData(obj);
      !editingData && reset();
    }
  };

  const validationOptimization = () => {
    let error = false;
    let obj = {};
    const flow_min = [...optimizationErrors.flow_min];
    const flow_required = [...optimizationErrors.flow_required];
    for (let i = 0; i < arrFlowRate.length; i++) {
      if (arrFlowRate[i] <= 0 && !!option && !!selectedSolvent) {
        flow_min[i] = true;
      }
      if (arrFlowRate[i] == '' && !!option && !!selectedSolvent) {
        flow_required[i] = true;
      }

      if (flow_required[i] || flow_min[i] || !option) {
        error = true;
      }

      obj = {
        flow_min,
        flow_required,
        pump: !option,
        flush_solvent: (!selectedSolvent && !mfcPump) || (!!selectedSolvent && mfcPump),
      };
    }
    if (obj.flush_solvent) error = true;
    setOptimizationErrors(obj);
    return error;
  };

  const reset = () => {
    setError(errorsInitial);
    setFlow(0);
    setOption(null);
    // setSolventsOptions([])
    setSelectedSolvent(null);
    setArrFlowRate([null]);
    setOptimizationErrors(errorsInitialOptimization);
  };

  const actionOptions = search => getPumpTypes(null, search).then(data => data.data?.allPumps);

  const changeSelectedValue = e => {
    setOption(pumpsOptions.data?.allPumps.find(i => i.label === e));
    setError({ ...errors });
    return e;
  };

  const compareOptions = (temp, field) => {
    for (const i in temp) {
      temp[i].value = temp[i][field];
      temp[i].label = temp[i][field];
    }
    return temp;
  };

  const canSave = (edited && editingData) || !editingData;

  const onCloseSidebar = () => {
    if (!edited) onClose();
    else openCancelConfirmation(true);
  };

  const customSorting = arr => {
    let result = [];
    const startsFromNumbers = [];
    const onlyLetters = [];
    arr.forEach(item => {
      if (isNaN(item?.compound_name[0])) {
        onlyLetters.push(item);
      } else {
        startsFromNumbers.push(item);
      }
    });
    onlyLetters.sort((a, b) => a.compound_name.localeCompare(b.compound_name, 'en', { numeric: false }));
    startsFromNumbers.sort((a, b) => a.compound_name.localeCompare(b.compound_name, 'en', { numeric: false }));
    result = onlyLetters.concat(startsFromNumbers);
    return result;
  };

  return (
    <>
      <Sidebar
        width={528}
        open={open}
        onClose={onCloseSidebar}
        onSave={onSave}
        title={`${editingData ? 'Edit' : 'Add'} a Pump`}
        onDelete={deletePump}
        disableSave={!canSave}
        editingData={editingData}
        saveButtonText={`${editingData ? 'Save' : 'Add Pump'}`}
        cancelText={edited ? 'Cancel' : 'Close'}
      >
        <div className="sidebar-pomp">
          <div className="first-block">
            <div className="row">
              <div className="column-title">
                Flush solvent
                {!mfcPump && <span className="required">*</span>}
              </div>
              <Select
                options={customSorting(solventsOptions)}
                value={selectedSolvent?.compound_name || selectedSolvent}
                onChange={e => {
                  setSelectedSolvent(e);
                  setEdited(true);
                  setError({ ...errors, flush_solvent: false });
                }}
                error={errors.flush_solvent}
                errorText={errorsText.flush_solvent}
                disabled={mfcPump}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </div>
          </div>
          <div className="second-block">
            <div className="parameters-title">{`Pump ${idPump} parameters`}</div>
            <div className="row">
              <div className="column-title">
                Pump type <span className="required">*</span>
              </div>
              {pumpsTypes && (
                <Select
                  placeholder="Select pump type"
                  typeToSearch
                  getOptions={actionOptions}
                  onChange={e => {
                    if (e === 'MFC') {
                      setFlow(0);
                      setArrFlowRate([null]);
                      setMfcPump(true);
                    }
                    if (
                      (e === 'MFC' && (option === 'MFC' || option?.value === 'MFC')) ||
                      (e !== 'MFC' && (option === 'MFC' || option?.value === 'MFC'))
                    ) {
                      resetFlowRates(e);
                    }
                    changeSelectedValue(e);
                    setMfcPump(e === 'MFC');
                    if (e === 'MFC') {
                      setSelectedSolvent(null);
                    }
                    setError({ ...errors, pump: false, flush_solvent: e === 'MFC' ? false : errors.flush_solvent });
                    setEdited(true);
                    if (editingData) setSelectedSolvent(null);
                  }}
                  value={option?.value || option}
                  error={errors.pump}
                  errorText={errorsText.pump}
                />
              )}
            </div>
            {typeOfProcess === 'Production' && (
              <div className="row">
                <div className="column-title">
                  Flow rate <span className="required">*</span>
                </div>
                <Input
                  disabled={!(option?.value || option)}
                  value={flow}
                  onChange={e => {
                    const str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                    if ((str || !e) && e < 1000000000000) {
                      e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 11) : e;
                      setFlow(e);
                      setError({ ...errors, flow_required: false, flow_min: false });
                      setEdited(true);
                    }
                  }}
                  error={errors.flow_required || errors.flow_min}
                  errorText={(errors.flow_required && errorsText.flow_min) || (errors.flow_min && errorsText.flow_min)}
                />
                <span className="units">{mfcPump ? 'sccm' : 'mL/min'}</span>
              </div>
            )}
            <div className="row">
              <div className="column-title">Material</div>
              <div className="material">{option ? option.material : '-'}</div>
            </div>
            {typeOfProcess === 'Optimization' &&
              arrFlowRate.map((i, index) => (
                <div className="row">
                  <div className="column-title">
                    {`Flow rate Q${id}${index + 1} `}
                    <span className="required">*</span>
                  </div>
                  <Input
                    disabled={!(option?.value || option)}
                    value={i}
                    onChange={e => {
                      const str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                      if ((str || !e) && e < 1000000000000) {
                        e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 11) : e;
                        const arr = [...arrFlowRate];
                        arr[index] = e;
                        setArrFlowRate(arr);

                        const arr_error_min = [...optimizationErrors.flow_min];
                        const arr_error_req = [...optimizationErrors.flow_required];
                        arr_error_min[index] = false;
                        arr_error_req[index] = false;

                        setOptimizationErrors({
                          ...optimizationErrors,
                          flow_min: arr_error_min,
                          flow_required: arr_error_req,
                        });
                        setEdited(true);
                      }
                    }}
                    error={optimizationErrors.flow_required[index] || optimizationErrors.flow_min[index]}
                    errorText={
                      (optimizationErrors.flow_required[index] && errorsText.flow_min) ||
                      (optimizationErrors.flow_min[index] && errorsText.flow_min)
                    }
                  />
                  <span className="units">{mfcPump ? 'sccm' : 'mL/min'}</span>
                </div>
              ))}
            {typeOfProcess === 'Library generation' && (
              <>
                <div className="row">
                  <div className="column-title">
                    Flow rate <span className="required">*</span>
                  </div>
                  <Input
                    disabled={!(option?.value || option)}
                    value={flow}
                    onChange={e => {
                      const str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(e);
                      if ((str || !e) && e < 1000000000000) {
                        e = e.indexOf('.') >= 0 ? e.substr(0, e.indexOf('.')) + e.substr(e.indexOf('.'), 11) : e;
                        setFlow(e);
                        setError({ ...errors, flow_required: false, flow_min: false });
                        setEdited(true);
                      }
                    }}
                    error={errors.flow_required || errors.flow_min}
                    errorText={
                      (errors.flow_required && errorsText.flow_min) || (errors.flow_min && errorsText.flow_min)
                    }
                  />
                  <span className="units">{mfcPump ? 'sccm' : 'mL/min'}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </Sidebar>
      <Popup
        open={cancelConfirmation}
        title="Warning"
        handleSubmit={() => {
          onClose();
          !editingData && reset();
          openCancelConfirmation(false);
        }}
        handleCancel={() => {
          openCancelConfirmation(false);
        }}
        textSubmit="Proceed"
        loading={false}
      >
        <span>All changes will be lost, proceed?</span>
      </Popup>
    </>
  );
};

const mapStateToProps = store => ({
  pumpsTypes: store.processbuilderReducer.pumpsTypes,
});

export default connect(mapStateToProps, {
  getPumpTypes,
})(SidebarPump);
