import React from 'react';
import cn from 'classnames';

export const Other = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#20A89A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 26.2C15.215 26.2 16.2 25.215 16.2 24C16.2 22.785 15.215 21.8 14 21.8C12.785 21.8 11.8 22.785 11.8 24C11.8 25.215 12.785 26.2 14 26.2ZM14 28C16.2091 28 18 26.2091 18 24C18 21.7909 16.2091 20 14 20C11.7909 20 10 21.7909 10 24C10 26.2091 11.7909 28 14 28Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 26.2C25.215 26.2 26.2 25.215 26.2 24C26.2 22.785 25.215 21.8 24 21.8C22.785 21.8 21.8 22.785 21.8 24C21.8 25.215 22.785 26.2 24 26.2ZM24 28C26.2091 28 28 26.2091 28 24C28 21.7909 26.2091 20 24 20C21.7909 20 20 21.7909 20 24C20 26.2091 21.7909 28 24 28Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34 26.2C35.215 26.2 36.2 25.215 36.2 24C36.2 22.785 35.215 21.8 34 21.8C32.785 21.8 31.8 22.785 31.8 24C31.8 25.215 32.785 26.2 34 26.2ZM34 28C36.2091 28 38 26.2091 38 24C38 21.7909 36.2091 20 34 20C31.7909 20 30 21.7909 30 24C30 26.2091 31.7909 28 34 28Z"
        fill="white"
      />
    </svg>
  </div>
);
