import React from 'react';
import './style.scss';
import ContainerExprSched from '../../components/ContainerExprSched';
import { modeExc } from '../../constants';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Details } from './Details/Details';
import { SynJetDetails } from './SynJetDetails/SynJetDetails';
import { SynJetProDetails } from './SynJetProDetails/SynJetProDetails';
import { LabDetails } from './LabDetails/LabDetails';

export const Execution = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/details/:key`} component={Details} />
      <Route path={`${path}/sj/details/:key`} component={SynJetDetails} />
      <Route path={`${path}/sjp/details/:key`} component={SynJetProDetails} />
      <Route path={`${path}/lb/details/:key`} component={LabDetails} />
      <Route path={path} render={() => <ContainerExprSched mode={modeExc} className="execution-container" />} />
    </Switch>
  );
};
