import './styles.scss';

import React from 'react';

export const FormAttribute = ({ title = '', children, require = false }) => (
  <div className="attribute">
    <div className="attribute-name">
      {title}
      {!!require && <span>*</span>}
    </div>
    <div className="attribute-content">{children}</div>
  </div>
);
