import './styles.scss';

import React, { useState } from 'react';

import cn from 'classnames';
import { getExtensibleExperiments } from 'store/experiment/experiment.actions';

import { ButtonGroupBox } from '@atoms';
import {
  ColumnResizerHOC,
  DndTableHOC,
  EmptyState,
  EmptyStateFiltered,
  SelectableHOC,
  TableHeaderWithActions,
  useTableController,
  VirtualTable,
} from '@molecules';
import { getExperimentFieldValue, PopupExperimentDetails } from '@templates';

import { SideBarColumnsManager } from '../SideBarColumnsManager';
import { CreateExperiment } from './components/CreateExperiment';
import useColumnsManager from './hooks/useColumnsManager';

const Component = ({ filters, className, search, isFiltersLoaded }) => {
  const [experimentUUID, setExperimentUUID] = useState(null);

  const fetchData = async requestParams => getExtensibleExperiments(requestParams);

  const makeTestInitialRequest = async () => isFiltersLoaded;

  const {
    tableData,
    totalCount,
    loadMore,
    isLoading,
    onSortClick,
    activeSort,
    invokeInitialRequest,
    updateSpecificRowData,
    error,
    initialEmptyStateCanBePresented,
  } = useTableController({
    fetchData,
    filters,
    search,
    makeTestInitialRequest,
  });

  const { columns, isLoading: isLoadingColumns, updateOrder, updateColumnWidth } = useColumnsManager();

  const mapColumnToRow = (row, { dataType, fieldName }) =>
    getExperimentFieldValue({ storage: row, dataType, fieldName });
  return (
    <div className={cn('experiments-table', className)}>
      <TableHeaderWithActions
        title="Experiments"
        count={totalCount}
        actions={
          <ButtonGroupBox>
            <SideBarColumnsManager invokeInitialTableRequest={invokeInitialRequest} />
            <CreateExperiment />
          </ButtonGroupBox>
        }
      />
      <VirtualTable
        actions={{
          updateColumnWidth,
          updateOrder,
          loadMore,
          onSortClick,
          onClickRow: id => {
            setExperimentUUID(id);
          },
        }}
        error={error}
        sortOrder={activeSort}
        tableData={tableData}
        columns={columns}
        isLoading={isLoading || isLoadingColumns}
        totalCount={totalCount}
        mapColumnToRow={mapColumnToRow}
        emptyState={
          filters?.length || search ? (
            <EmptyStateFiltered />
          ) : (
            initialEmptyStateCanBePresented && <EmptyState action={<CreateExperiment buttonType="secondary" />} />
          )
        }
      />

      <PopupExperimentDetails
        visible={experimentUUID}
        handleCancel={() => setExperimentUUID(null)}
        uuid={experimentUUID}
        modifyDataAfterUpdate={updateSpecificRowData}
      />
    </div>
  );
};

export const ExperimentsTable = React.memo(
  props => (
    <DndTableHOC>
      <SelectableHOC>
        <ColumnResizerHOC>
          <Component {...props} />
        </ColumnResizerHOC>
      </SelectableHOC>
    </DndTableHOC>
  ),
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.filters) === JSON.stringify(nextProps.filters) &&
    prevProps.search === nextProps.search &&
    prevProps.isFiltersLoaded === nextProps.isFiltersLoaded
);
