import React, { useState } from 'react';

import { Select } from 'components';
import { useDispatch } from 'react-redux';
import { getListUsers } from 'store/users/users.actions';

export const UserSelect = ({ value, onChange, disabled }) => {
  const [focused, setFocused] = useState(false);
  const dispatch = useDispatch();
  const getData = search =>
    dispatch(getListUsers(1, 30, search)).then(
      resp =>
        resp?.map(process => ({
          value: process.uuid,
          label: `${process?.firstName} ${process?.lastName}`,
        })) || []
    );

  return (
    <Select
      allowClear
      labelInValue
      disabled={disabled}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      showArrow
      showSearch
      getOptions={search => getData(search)}
      typeToSearch={focused}
      value={value || undefined}
      onChange={(label, val) => {
        setFocused(false);
        onChange(val || null);
      }}
    />
  );
};
