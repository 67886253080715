import { checkIfTrue } from '../../../../utils';

const ERRORS_TEXT = {
  heating_bath: {
    type: 'heating_bath',
    temperatures: 'Temperature should be from 20 to 300°C',
  },
  cooling_bath: {
    type: 'cooling_bath',
    temperatures: 'Temperature should be from -100 to 20°C',
  },
  parr_shaker: {
    type: 'parr_shaker',
    temperatures: 'Temperature should be from 20 to 200°C',
    atmosphere: '‘Atmosphere’ is a required field',
    pressure: 'Pressure should be from 14 psi to 100 psi',
  },
  microwave: {
    type: 'microwave',
    temperatures: 'Temperature should be from 20 to 300°C',
  },
  other: {
    type: 'other',
    temperatures: 'Temperature should be from -100 to 500°C',
  },
  times: 'Residence time is a required field',
  emptyError: 'This field is required',
  pressure: 'Pressure should be from 0 psi to 100 psi',
};
export const equipmentValidation = (equipment, otherType) => {
  let isValid = false;

  const checkPressure = () => {
    let mess = '';
    if (equipment.type !== 'parr_shaker') {
      if (!!equipment.pressure) {
        if (+equipment.pressure < 0 || +equipment.pressure > 100) mess = ERRORS_TEXT.pressure;
      }
    } else {
      if (+equipment.pressure < 14 || +equipment.pressure > 100 || !equipment.pressure)
        mess = ERRORS_TEXT.parr_shaker.pressure;
    }

    return mess;
  };
  let obj = {
    temperatures: [...equipment.temperatures].map(i => {
      let mess = '';
      if (equipment.type === 'heating_bath' || equipment.type === 'microwave') {
        if (+i < 20 || +i > 300) {
          mess = ERRORS_TEXT[equipment.type].temperatures;
        }
      } else if (equipment.type === 'cooling_bath') {
        if (+i < -100 || +i > 20) {
          mess = ERRORS_TEXT[equipment.type].temperatures;
        }
      } else if (equipment.type === 'parr_shaker') {
        if (+i < 20 || +i > 200) {
          mess = ERRORS_TEXT[equipment.type].temperatures;
        }
      } else if (equipment.type === 'other') {
        if (+i < -100 || +i > 500) {
          mess = ERRORS_TEXT[equipment.type].temperatures;
        }
      }
      return mess;
    }),
    times: [...equipment.timeHour].map((i, index) =>
      !i && !equipment.timeMin[index] && !equipment.timeSec[index] ? ERRORS_TEXT.times : false
    ),
    atmosphere:
      equipment.type === 'parr_shaker' && !equipment.atmosphere ? ERRORS_TEXT[equipment.type].atmosphere : false,
    pressure: checkPressure(),
    otherType: equipment.type === 'other' && !otherType ? ERRORS_TEXT.emptyError : false,
  };

  let errorsToCheck = { ...obj };
  delete errorsToCheck.temperatures;
  delete errorsToCheck.times;
  isValid = !checkIfTrue(errorsToCheck) && !obj.temperatures.find(i => !!i) && !obj.times.find(i => !!i);

  return { obj, isValid };
};
