import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import cn from 'classnames';
import SmilesDrawer from 'smiles-drawer';

import { USER_ROLES } from '../../constants/common';
import { httpSynMolDB } from '../../utils';
import { Bird, Input as CustomInput, openNotification, Popup, Search, Select } from '../Common';
import { LoupeButton } from '../PopupWithStructureView/LoupeButton';

const { Option } = Select;
const errorEmptyText = 'Field is empty';
const invalidStractureMessage = 'Invalid SMILE structure provided';

const options = {
  height: 90,
};

export const SmilesSearcher = ({
  data,
  onChange,
  changeSelect,
  synjet,
  error,
  setSelectValue = () => {},
  errorText,
  onDeleteAction = () => {},
  resetError = () => {},
  smilesPlaceholder,
  initialSearchValue = '',
  handleCompoundChange = () => {},
  enableEmptySmiles = false,
  showDeleteConfirmation = false,
  enableToDelete = true,
  userRoles,
  validation,
  componentId,
}) => {
  const [typeSearching, setTypeSearching] = useState('name');
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [viewData, setViewData] = useState(null);
  const [noFoundState, setNoFoundState] = useState(false);
  const [noFoundStateInvalid, setNoFoundStateInvalid] = useState(false);
  const [previewDataSmiles, setPrevieDataSmiles] = useState(null);
  const [nameNewCompound, setNameNewCompound] = useState('');
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [typeToSearch, setTypeToSearch] = useState(false);

  const canvas = useRef(null);
  const canvasContainer = useRef(null);

  const key = Math.random();

  if (userRoles && !userRoles.includes(USER_ROLES.sysAdmin) && !userRoles.includes(USER_ROLES.pm)) {
    enableToDelete = false;
  }

  useEffect(() => {
    if (canvasContainer.current && viewData && viewData.smiles) {
      options.width = canvasContainer.current.offsetWidth;
      canvas.current = new SmilesDrawer.Drawer(options);
      SmilesDrawer.parse(viewData.smiles, tree => {
        canvas.current.draw(tree, `smiles-drawer_${key}`, 'light', false);
      });
      onChange && onChange();
    }
  }, [viewData]);

  useEffect(() => {
    if (data || componentId) {
      setViewData(data);
    } else {
      setViewData(null);
      setSearchValue('');
      setNoFoundState(false);
      setNoFoundStateInvalid(false);
      setPrevieDataSmiles(null);
      // setTypeSearching('name');
      setSelectValue('name');
    }
  }, [data]);

  const handlerChangeSelect = useCallback(value => {
    setTypeSearching(value);
    setSelectValue(value);
    setNoFoundState(false);
    setNoFoundStateInvalid(false);
    setViewData(null);
    handleCompoundChange();
  });

  const closePopup = () => {
    setNameNewCompound('');
    setPopup(false);
    setErrors(false);
  };
  const openPopup = () => {
    setPopup(true);
  };

  const selectName = value => {
    httpSynMolDB(`get_compound?compound_id=${value}&return_compatibility=true`).then(resp => {
      if (resp?.compound) {
        if (
          enableEmptySmiles ||
          (!enableEmptySmiles && !!resp?.compound?.smiles && resp?.compound?.compound_name !== 'None')
        ) {
          setTypeToSearch(false);

          setViewData({ ...resp.compound });
          changeSelect(resp.compound, 'set-compound');
        } else {
          openNotification(null, 'Selected compound does not have SMILE structure and can’t be used');
        }
      }
    });
  };
  const getListCompoundsName = value =>
    httpSynMolDB(`get_compound_name_list?substring=${encodeURIComponent(value)}`).then(resp => {
      if (resp?.compound_name_list) {
        return resp.compound_name_list.slice(0, 20).map(i => ({ label: i.compound_name, value: i.compound_id }));
      }
      return [];
    });

  const handleSearchAction = () => {
    setLoading(true);
    httpSynMolDB(`get_compound?smiles=${encodeURIComponent(searchValue)}&return_compatibility=true`)
      .then(resp => {
        if (resp?.compound) {
          setPrevieDataSmiles({ ...resp.compound });
          changeSelect(resp.compound);
        } else if (resp.status === -1 && resp.message === 'Compound not found') {
          setNoFoundState(true);
          setNoFoundStateInvalid(true);
        } else {
          setNoFoundState(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateViewDataFromPreview = () => {
    setViewData(previewDataSmiles);
    changeSelect(previewDataSmiles, 'set-compound');
    setPrevieDataSmiles(null);
  };
  const createNewCompound = () => {
    setLoading(true);
    let url = '';
    if (nameNewCompound.trim() != '') {
      url = `register_compound?smiles=${encodeURIComponent(
        searchValue
      )}&compound_name=${nameNewCompound.trim()}&return_compatibility=true`;
    } else {
      setErrors('Please select the Compound name');
      setLoading(false);
      return;
    }
    httpSynMolDB(url)
      .then(resp => {
        if (resp?.compound) {
          closePopup();
          setViewData({ ...resp.compound });
          changeSelect(resp.compound, 'set-compound');
          setNoFoundState(false);
        } else if (resp?.message) {
          const mes =
            resp?.message.split(':').length > 1 ? resp?.message.split(':')[1].trim() : invalidStractureMessage;
          setErrors(mes.charAt(0).toUpperCase() + mes.slice(1));
        } else {
          setErrors(invalidStractureMessage);
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="smiles-searcher">
      {viewData?.smiles && !synjet ? (
        <div className="smiles-searcher_view">
          <span className="smiles-searcher_view_name">
            <span>{viewData.compound_id}</span>{' '}
            {enableToDelete && (
              <CloseOutlined
                onClick={() => {
                  if (showDeleteConfirmation) {
                    onDeleteAction();
                    return;
                  }
                  setViewData(null);
                  setSearchValue('');
                  setNoFoundState(false);
                  setNoFoundStateInvalid(false);
                  setPrevieDataSmiles(null);
                  onDeleteAction();
                }}
              />
            )}
          </span>
          <span className="smiles-searcher_view_comp_name">{viewData.compound_name}</span>
          <span className="smiles-searcher_view_smiles">{viewData.smiles}</span>
          <div className="smiles-searcher_view_structure" ref={canvasContainer}>
            <canvas id={`smiles-drawer_${key}`} />
          </div>
          <LoupeButton label={`smiles-drawer_scale${key}`} data={viewData} />
        </div>
      ) : (
        <>
          <Input.Group className="smiles-searcher_searcher" compact>
            <Select
              showSearch={false}
              className="smiles-searcher_searcher_categories"
              onChange={e => {
                handlerChangeSelect(e);
                resetError();
              }}
              value={typeSearching}
              options={[
                { value: 'name', label: 'By name' },
                { value: 'smiles', label: 'By SMILES' },
              ]}
            />
            {typeSearching === 'name' ? (
              <Select
                onFocus={() => {
                  setTypeToSearch(true);
                }}
                onBlur={() => {
                  setTypeToSearch(false);
                }}
                typeToSearch={typeToSearch}
                iconSearch
                getOptions={getListCompoundsName}
                onSelect={selectName}
                placeholder={smilesPlaceholder || 'Search for compound'}
                error={error}
                errorText={errorText}
                {...(searchValue ? { defaultValue: searchValue } : {})}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            ) : (
              <>
                <Search
                  iconButton={<Bird />}
                  smile
                  allowSpecials
                  loading={loading}
                  placeholder={smilesPlaceholder || 'Search for compound'}
                  value={searchValue}
                  searchAction={handleSearchAction}
                  searchBtnClassName={cn({ 'synjet-search-btn': synjet })}
                  onChange={value => {
                    if (!value) {
                      setViewData(null);
                      handleCompoundChange();
                    }
                    setNoFoundState(false);
                    setNoFoundStateInvalid(false);
                    setSearchValue(value);
                    setPrevieDataSmiles(null);
                  }}
                  error={error}
                  errorText={errorText}
                  validation={validation}
                />
              </>
            )}
          </Input.Group>
          {(previewDataSmiles || noFoundState) && (
            <div
              className={cn('smiles-searcher_searcher_option', {
                'smiles-searcher_searcher_option_create': noFoundState,
              })}
              onClick={updateViewDataFromPreview}
            >
              {!noFoundState ? (
                <>
                  <span className="smiles-searcher_searcher_option_id">{previewDataSmiles.compound_id}</span>
                  <span className="smiles-searcher_searcher_option_name">{previewDataSmiles.compound_name}</span>
                  <span className="smiles-searcher_searcher_option_smile">{previewDataSmiles.smiles}</span>
                </>
              ) : (
                <div className="not-found">
                  {noFoundStateInvalid ? (
                    <>
                      <span>Compound not found.</span>
                      <span className="smiles-searcher_searcher_option_create_btn" onClick={openPopup}>
                        Submit new
                      </span>
                    </>
                  ) : (
                    <span>Invalid SMILES structure provided</span>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      <Popup
        open={popup}
        textSubmit="Submit"
        handleCancel={v => {
          closePopup(v);
          setErrorEmpty(false);
        }}
        handleSubmit={() => {
          createNewCompound();
        }}
        title="Submit new compound"
        loading={loading}
        className="creation-new-compound"
      >
        <div className="creation-new-compound_content">
          <span className="creation-new-compound_content_note">
            The compound will be registered with an automatically- generated ID. Enter a systematic name for the
            compound below (optional).
          </span>
          <CustomInput
            autoFocus
            allowSpecials
            withoutComma={false}
            placeholder="New compound name"
            value={nameNewCompound}
            onChange={e => {
              setNameNewCompound(e);
              setErrors(false);
              setErrorEmpty(false);
            }}
            error={errors || errorEmpty}
            errorText={(errors && errors) || (errorEmpty && errorEmptyText)}
            maxLength={256}
            disabled={loading}
          />
        </div>
      </Popup>
    </div>
  );
};
