import React, { createContext, useContext, useMemo, useState } from 'react';

const DndContext = createContext(null);
export const useDndContext = () => useContext(DndContext) || { isDndContext: false };

export let isDraggingNoRerender;
export const useDndController = () => {
  const [dragColumnIndex, setDragColumnIndex] = useState();
  const [potentialDropColumnIndex, setPotentialDropColumnIndex] = useState();

  useMemo(() => {
    isDraggingNoRerender = dragColumnIndex;
  }, [dragColumnIndex]);
  return {
    dragColumnIndex,
    setDragColumnIndex,
    potentialDropColumnIndex,
    setPotentialDropColumnIndex,
  };
};

export const DndTableHOC = ({ children }) => {
  const {
    dragColumnIndex,
    setDragColumnIndex,
    potentialDropColumnIndex,
    setPotentialDropColumnIndex,
  } = useDndController();

  const value = useMemo(
    () => ({
      setDragColumnIndex,
      dragColumnIndex,
      potentialDropColumnIndex,
      setPotentialDropColumnIndex,
      isDndContext: true,
    }),
    [dragColumnIndex, potentialDropColumnIndex]
  );

  return <DndContext.Provider value={value}>{children}</DndContext.Provider>;
};
