/* eslint eqeqeq: 0 */
/* eslint strict: 0 */
/* eslint no-unused-vars: 0 */

'use strict';

import './RouteVisualization.scss';

const alert = console.log;

require('./smiles-drawer-sri.js');

const { SmilesDrawer } = window;

// SynRoute Visualiztion
export var SRV = {
  version: 1.6,
  smilesDrawer: null,
  scaleDevice: 1,
  nullCanvas: null,
  avoidList: {},
  keepList: {},
  avoidKeepMinLength: 110,
  avoidKeepHeight: 30,
  font: 'normal normal 400 12px sans-serif',
  fontBold: 'normal normal 700 13px sans-serif',
  canvasCount: 0,
};

// fix this
const ARROW_RIGHT = 1;
const ARROW_RIGHT_DOWN = 2;
const ARROW_RIGHT_TAIL = 3;
const ARROW_LEFT = 4;
const ARROW_LEFT_DOWN = 5;
const ARROW_LEFT_TAIL = 6;

SRV.Init = function () {
  const options = {
    fontSizeLarge: 10,
    fontSizeSmall: 6,
    bondThickness: 2,
    bondLength: 20,
    shortBondLength: 0.75,
    bondSpacing: 4,
    padding: 0,
    compactDrawing: false,
  };

  SRV.smilesDrawer = new SmilesDrawer.Drawer(options);
  SRV.nullCanvas = document.createElement('canvas');

  // document.body.appendChild(SRV.nullCanvas);
};

// returns search error msg, null if no errors.
SRV.GetSearchErrors = function (results) {
  if (results.status == 'no-error') return null;
  return results.msg;
};

// return [] of route counts in each strategy
SRV.GetStrategyRouteCounts = function (results) {
  const strategy = {};
  const routeIndex = {};
  let sid = 0;

  for (let r = 0; r < results.routes.length; r++) {
    const rxn = results.routes[r].route_tree.rxn_id;
    if (!strategy[rxn]) {
      strategy[rxn] = { sid: sid++, rid: 0 };
    } else {
      strategy[rxn].rid++;
    }

    routeIndex[`S${strategy[rxn].sid}R${strategy[rxn].rid}`] = results.routes[r];
  }
  results.strategyRouteIndex = routeIndex;

  const strategyCount = [];
  for (const s in strategy) {
    strategyCount[strategy[s].sid] = strategy[s].rid + 1;
  }

  return strategyCount;
};

function rxnFixName(rxn) {
  const dashes = rxn.split('-');

  if (dashes.length == 3) return dashes[1];
  return rxn;
}

SRV.GetStrategyDesc = function (results, strategyId) {
  let route = results.strategyRouteIndex[`S${strategyId}R0`];
  const rxn = route.route_tree.rxn_id;
  const name = rxnFixName(rxn);

  const sid = `S${strategyId}R`;
  let mincost;
  let maxcost;
  let minsteps;
  let maxsteps;
  let minsegments;
  let maxsegments;

  mincost = maxcost = route.cost;
  minsteps = maxsteps = route.nbrxns;
  minsegments = maxsegments = route.route_segments.length;
  for (const s in results.strategyRouteIndex) {
    if (s.indexOf(sid) == 0) {
      route = results.strategyRouteIndex[s];
      mincost = Math.min(mincost, route.cost);
      maxcost = Math.max(maxcost, route.cost);
      minsteps = Math.min(minsteps, route.nbrxns);
      maxsteps = Math.max(maxsteps, route.nbrxns);
      minsegments = Math.min(minsegments, route.route_segments.length);
      maxsegments = Math.max(maxsegments, route.route_segments.length);
    }
  }

  let costs = '';

  if (mincost) {
    mincost = mincost.toFixed(2);
    maxcost = maxcost.toFixed(2);
    if (mincost != maxcost) {
      costs = `$${mincost} - $${maxcost}/mol`;
    } else {
      costs = `$${mincost}/mol`;
    }
  }

  let steps;
  if (minsteps != maxsteps) steps = `${minsteps} - ${maxsteps} steps`;
  else steps = minsteps + (minsteps == 1 ? ' step' : ' steps');

  let segments;
  if (minsegments != maxsegments) segments = `${minsegments} - ${maxsegments} segments`;
  else segments = minsegments + (minsegments == 1 ? ' segment' : ' segments');

  const desc = `${name}\xa0\xa0${costs}\xa0\xa0${steps}\xa0\xa0${segments}`;

  return desc;
};

SRV.GetRouteDesc = function (results, strategyId, routeId) {
  const route = results.strategyRouteIndex[`S${strategyId}R${routeId}`];
  const rxn = route.route_tree.rxn_id;
  const name = rxnFixName(rxn);

  const steps = route.nbrxns;
  let cost = '';
  if (route.cost) {
    cost = `$${route.cost.toFixed(2)}/mol`;
  }

  const desc = `${name}\xa0\xa0${cost}\xa0\xa0${steps} steps`;

  return desc;
};

SRV.DrawTarget = function (results, canvas, resize) {
  if (!results.routes[0]) {
    alert("Can't locate target!");
    return;
  }

  const rxc = results.routes[0].route_tree.prod_id;

  SRV.DrawRXC(rxc, results, canvas, resize, 1);
};

SRV.DrawFlaggedInit = function (flagged, canvas) {
  if (!flagged || !flagged.details) {
    console.log('SRV.DrawFlagged invalid flagged', flagged);
    return;
  }

  const { details } = flagged;
  details.canvas = canvas; // save canvas for sending email

  switch (details.type) {
    case 'compoundDetails':
      canvas.draw = function (resize) {
        SRV.DrawRXC(details.id, details.results, canvas, resize);
      };
      break;
    case 'reactionDetails':
      SRV.DrawRXRInit(details.id, details.results, canvas);
      canvas.draw = function (resize) {
        SRV.DrawRoute(canvas, resize);
      };
      break;
    default:
      console.log('invalid flagged', flagged);
  }
};

SRV.DrawRXR = function (canvas, resize) {
  console.log('draw rxr', canvas, resize);
  SRV.DrawStrategy(canvas, resize);
};

SRV.DrawRXRInit = function (rxn_id, results, canvas) {
  const rxr = results.rxns[rxn_id];
  console.log('rxr', rxn_id, rxr);
  if (!rxr) {
    alert(`Can't locate reaction: ${rxn_id}`);
    return;
  }

  let route;

  const ctx = canvas.getContext('2d');
  const nodes = [];

  let needplus = 0;
  for (let r = 0; r < rxr.reactants.length; r++) {
    const rxc = rxr.reactants[r];

    if (needplus) {
      nodes.push({
        type: 'plus',
        name: 'plus',
        length: 50,
        height: 50,
      });
      needplus = 0;
    }

    const cpd = results.cpds[rxc];
    if (!cpd) {
      console.log('Invalid CPDS entry rxc');
      continue;
    }

    if (!cpd.drawTree && !cpd.smilesError) {
      SRV.DrawTreeInit(cpd, canvas);
    }

    // let name = results.cpds[rxn.branchIds[b]].name;
    let { name } = results.cpds[rxc];
    name = SRV.HtmlDecode(name);
    nodes.push({
      type: 'rxc',
      cpd,
      id: rxc,
      name,
      cdpnew: rxc.indexOf('CNEW') == 0,
      length: cpd.length,
      height: cpd.height,
    });
    needplus = 1;
  }

  const name = rxnFixName(rxn_id);
  nodes.push({
    type: 'rxr',
    rxr: results.rxns[rxn_id],
    id: rxn_id,
    rxrnew: rxn_id.indexOf('RNEW') == 0,
    name,
  });

  needplus = 0;
  for (let p = 0; p < rxr.products.length; p++) {
    const rxc = rxr.products[p];

    if (needplus) {
      nodes.push({
        type: 'plus',
        name: 'plus',
        length: 50,
        height: 50,
      });
      needplus = 0;
    }

    const cpd = results.cpds[rxc];
    if (!cpd) {
      console.log('smiles error!', rxc);
      return;
    }

    if (!cpd.drawTree && !cpd.smilesError) {
      SRV.DrawTreeInit(cpd, canvas);
    }

    let { name } = results.cpds[rxc];
    name = SRV.HtmlDecode(name);
    nodes.push({
      type: 'rxc',
      cpd,
      id: rxc,
      name,
      cdpnew: rxc.indexOf('CNEW') == 0,
      length: cpd.length,
      height: cpd.height,
    });
    needplus = 1;
  }

  for (const n in nodes) {
    const node = nodes[n];
    node.results = results;
    SRV.NodeInit(node);
  }

  canvas.segments = [nodes];

  return nodes;
};

SRV.DrawRXC = function (rxcId, results, canvas, resize, hackWidth) {
  const ctx = canvas.getContext('2d');

  const cpd = results.cpds[rxcId];
  if (!cpd) {
    console.log('smiles error!', rxcId);
    return;
  }

  if (!cpd.drawTree && !cpd.smilesError) {
    SRV.DrawTreeInit(cpd, canvas);
  }

  let s = 1;
  if (canvas.type == 'targetCanvas') {
    s = canvas.clientWidth / (cpd.length + 20);
    if (s > 1) s = 1;
  }
  const height = cpd.height * s + 50;

  if (resize) {
    // fix this!
    canvas.style.width = hackWidth ? '300px' : '100%';
    canvas.style.height = `${height}px`;
    canvas.width = canvas.clientWidth * SRV.scaleDevice;
    canvas.height = canvas.clientHeight * SRV.scaleDevice;
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(SRV.scaleDevice, SRV.scaleDevice);
  }

  ctx.save();
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.restore();
  ctx.scale(s, s);
  let xoff = (canvas.clientWidth - cpd.length) / 2;
  let yoff = (canvas.clientHeight - cpd.height) / 2;

  xoff = Math.max(xoff, 10);
  yoff = Math.max(yoff, 10);

  xoff = parseInt(xoff) + 0.5; // anti-aliasing
  yoff = parseInt(yoff) + 0.5;

  ctx.save();
  ctx.translate(xoff, yoff);
  const tree = cpd.drawTree;
  if (tree) {
    tree.canvasWrapper.redraw(canvas);
  } else {
    SRV.DrawNoStructure(canvas, cpd);
  }

  /*
      ctx.strokeStyle = '#ff0000';
      ctx.strokeRect(0,0, cpd.length, cpd.height);
  */
  ctx.restore();
};

SRV.DrawNoStructure = function (canvas, cpd) {
  const ctx = canvas.getContext('2d');
  const l = cpd.length;
  const h = cpd.height;

  ctx.save();

  ctx.beginPath();
  ctx.strokeStyle = '#000';
  ctx.lineWidth = 2;
  ctx.moveTo(15, 0);
  ctx.lineTo(0, 0);
  ctx.lineTo(0, h);
  ctx.lineTo(15, h);
  ctx.moveTo(l - 15, 0);
  ctx.lineTo(l, 0);
  ctx.lineTo(l, h);
  ctx.lineTo(l - 15, h);
  ctx.fillStyle = '#000';
  ctx.textBaseline = 'top';
  ctx.fillText('NO STRUCTURE', 15, h / 2 - 5);
  ctx.stroke();

  ctx.restore();
};

SRV.DrawTreeInit = function (cpd, canvas) {
  if (typeof cpd.smiles != 'string' || cpd.smiles.length <= 0) {
    console.log('Invalid smiles', cpd.name, cpd.smiles);
    cpd.smilesError = true;
    cpd.length = 125;
    cpd.height = 30;
    return;
  }

  SmilesDrawer.parse(
    cpd.smiles,
    tree => {
      const nullcanvas = SRV.nullCanvas;
      SRV.smilesDrawer.draw(tree, nullcanvas, 'light', false);
      cpd.drawTree = tree;
      tree.xoff = 0;
      tree.yoff = 0;
      tree.scale = 1;

      tree.minX = -tree.canvasWrapper.offsetX;
      tree.minY = -tree.canvasWrapper.offsetY;
      tree.maxX = tree.minX + tree.canvasWrapper.drawingWidth;
      tree.maxY = tree.minY + tree.canvasWrapper.drawingHeight;

      cpd.minX = tree.minX;
      cpd.minY = tree.minY;
      cpd.maxX = tree.maxX;
      cpd.maxY = tree.maxY;
      cpd.length = Math.round(cpd.maxX - cpd.minX);
      cpd.height = Math.round(cpd.maxY - cpd.minY);
      if (cpd.minX == cpd.maxX) {
        console.log('fix undrawable smiles', cpd.smiles.length);
        cpd.maxX = cpd.minX + cpd.smiles.length * 6;
        cpd.length = cpd.maxX - cpd.minX;
      }
      cpd.smilesError = false;
    },
    err => {
      console.log('SmilesDrawer error', cpd.smiles, err);
      cpd.smilesError = true;
      cpd.length = 125;
      cpd.height = 30;
    }
  );
};

SRV.LayoutSegments = function (canvas, segments) {
  const xpad = 12;
  const ypad = 20 + SRV.avoidKeepHeight;
  const xmax = 60000;
  const xmin = 0;
  const ymin = 12;
  const rowNodes = [];

  // layout rows
  for (let s = 0; s < segments.length; s++) {
    let xoff = xpad;
    let dir = 1;
    let nodes = [];
    let prevnode = null;

    for (let n = 0; n < segments[s].length; n++) {
      let node = segments[s][n];
      if (node.type == 'segment') {
        node.xoff = xoff;
        node.dir = dir;
        rowNodes.push([node]);
        n++;
        node = segments[s][n];
      }
      if (nodes.length > 0 && dir > 0 && xoff + node.nodeLength > xmax) {
        // xoff -= xpad;
        let dx = xmax - xoff;
        if (nodes.length > 1) dx /= nodes.length;

        for (let n = 0; n < nodes.length; n++) {
          nodes[n].xoff += dx * n * dir;
        }
        rowNodes.push(nodes);

        nodes = [];
        xoff = prevnode.xoff + prevnode.nodeLength;

        if (prevnode.type == 'rxr') prevnode.arrowType = ARROW_RIGHT_DOWN;

        dir *= -1;
      } else if (nodes.length > 0 && dir < 0 && xoff - node.nodeLength < xmin) {
        xoff += xpad;
        let dx = xoff - xmin;
        if (nodes.length > 1) dx /= nodes.length - 1;

        for (let n = 0; n < nodes.length; n++) {
          nodes[n].xoff += dx * n * dir;
        }
        rowNodes.push(nodes);

        nodes = [];
        xoff = prevnode.xoff;

        if (prevnode.type == 'rxr') prevnode.arrowType = ARROW_LEFT_DOWN;

        dir *= -1;
      }
      xoff = Math.round(xoff);
      node.xoff = dir > 0 ? xoff : xoff - node.nodeLength;
      node.dir = dir;

      if (node.type == 'rxr') {
        if (nodes.length == 0) node.arrowType = dir > 0 ? ARROW_RIGHT_TAIL : ARROW_LEFT_TAIL;
        else node.arrowType = dir > 0 ? ARROW_RIGHT : ARROW_LEFT;
      }
      node.dir = dir;
      nodes.push(node);
      xoff += dir > 0 ? node.nodeLength + xpad : -node.nodeLength - xpad;
      prevnode = node;
    }
    if (nodes.length > 0) {
      let dx;
      if (dir > 0) {
        dx = xmax - xoff;
      } else {
        xoff += xpad;
        dx = xoff - xmin;
      }
      dx /= nodes.length;
      dx = Math.min(dx, 30);

      for (let n = 0; n < nodes.length; n++) {
        nodes[n].xoff += dx * n * dir;
        nodes[n].dir = dir;
      }
      rowNodes.push(nodes);
    }
  }
  // compute xgap between nodes
  for (const r in rowNodes) {
    for (const n in rowNodes[r]) {
      const nodes = rowNodes[r];
      if (nodes[n].dir > 0) {
        if (n > 0) nodes[n].xgap = nodes[n].xoff - (nodes[n - 1].xoff + nodes[n - 1].nodeLength);
        else nodes[n].xgap = nodes[n].xoff - xmin;
      } else if (n > 0) nodes[n].xgap = nodes[n - 1].xoff - (nodes[n].xoff + nodes[n].nodeLength);
      else nodes[n].xgap = xmax - (nodes[n].xoff + nodes[n].nodeLength);
    }
  }

  const ctx = SRV.nullCanvas.getContext('2d');
  let yoff = ymin;
  let height = 0;
  for (const r in rowNodes) {
    let rowheight = 0;
    for (const n in rowNodes[r]) {
      const node = rowNodes[r][n];

      switch (node.type) {
        case 'rxc':
          node.textbox = FormatText(node.name, node.nodeLength + node.xgap, 2);
          if (node.textbox.length > 0) {
            const lastline = node.textbox[node.textbox.length - 1];
            node.nodeHeight = node.height + lastline.y + lastline.h;

            if (node.iname) {
              let maxlength = 0;
              for (const t in node.textbox) {
                maxlength = Math.max(maxlength, node.textbox[t].l);
              }
              const ilength = ctx.measureText(node.iname).width;
              const xoff = (maxlength - ilength) / 2;
              node.textbox.push({
                str: node.iname,
                x: xoff,
                y: lastline.y + lastline.h + 5,
                l: ilength,
                h: 15,
              });
              node.nodeHeight += 15;
            }
          } else {
            node.nodeHeight = node.height;
          }
          break;
        default:
      }
      rowheight = Math.max(rowheight, node.nodeHeight);
    }
    if (rowNodes[r][0].type != 'segment') rowheight += SRV.avoidKeepHeight;

    for (const n in rowNodes[r]) {
      const node = rowNodes[r][n];
      node.yoff = yoff + (rowheight - node.nodeHeight) / 2;
      node.rowheight = rowheight;

      const dx = node.nodeLength - node.length;
      node.xoff += dx / 2;
    }

    height = yoff + rowheight;

    if (rowNodes[r][0].type != 'segment') yoff += rowheight + ypad;
    else yoff += rowheight * 2;
  }

  const lastNode = rowNodes[0][rowNodes[0].length - 1];
  // canvas.style.width = lastNode.xoff + lastNode.nodeLength + lastNode.xgap + 'px';
  // canvas.width = lastNode.xoff + lastNode.nodeLength + lastNode.xgap;
  canvas.rowNodes = rowNodes;

  return { height, width: lastNode.xoff + lastNode.nodeLength + lastNode.xgap };
};

SRV.NodeInit = function (node) {
  const ctx = SRV.nullCanvas.getContext('2d');
  ctx.font = SRV.font;
  const bbox = ctx.measureText(node.name);
  node.measureTextWidth = bbox.width;
  const nameLength = Math.round(bbox.width);
  let nameHeight = 11;
  if (bbox.actualBoundingBoxAscent && bbox.actualBoundingBoxDescent) {
    nameHeight = Math.round(bbox.actualBoundingBoxAscent + bbox.actualBoundingBoxDescent + 0.5);
  }

  switch (node.type) {
    case 'segment':
      node.height = nameHeight;
      node.nodeHeight = node.height;
      node.length = nameLength;
      node.nodeLength = node.length;
      break;
    case 'plus':
      node.nodeLength = node.length;
      node.nodeHeight = node.height;
      break;
    case 'rxr':
      node.height = nameHeight;
      node.nodeHeight = node.height;
      node.length = nameLength + 35;

      node.above = [];
      node.below = [];
      if (node.rxr.variations && node.rxr.variations.length > 0) {
        const var0 = node.rxr.variations[0];
        const conditions = var0.conditions[0];
        let maxrows = conditions.reagents.length + conditions.catalysts.length;
        if (maxrows > 5) maxrows = 4;
        for (let r = 0; r < conditions.reagents.length; r++) {
          if (node.above.length >= maxrows) break;
          const rxc = conditions.reagents[r];
          if (!node.results.cpds[rxc]) {
            continue;
          }
          const cpd = node.results.cpds[rxc];
          let name = cpd.name || cpd.compound_name || cpd.formula;
          name = TrimText(name, node.length);
          node.above.push(name);
        }
        for (let c = 0; c < conditions.catalysts.length; c++) {
          if (node.above.length >= maxrows) break;
          const rxc = conditions.catalysts[c];
          if (!node.results.cpds[rxc]) {
            console.log('catalysts missing', rxc);
            continue;
          }
          const cpd = node.results.cpds[rxc];
          let name = cpd.name || cpd.compound_name || cpd.formula;
          name = TrimText(name, node.length);
          node.above.push(name);
        }
        if (conditions.reagents.length + conditions.catalysts.length > 5) node.above.push('[MORE]');

        if (node.rxrnew) {
          node.above.push(node.name);
        }

        maxrows = conditions.solvents.length;
        if (maxrows > 3) maxrows = 2;
        for (let s = 0; s < conditions.solvents.length; s++) {
          if (s >= maxrows) break;
          const rxc = conditions.solvents[s];
          if (!node.results.cpds[rxc]) {
            console.log('solvent missing', rxc);
            continue;
          }

          const cpd = node.results.cpds[rxc];
          let name = cpd.name || cpd.compound_name || cpd.formula;
          name = TrimText(name, node.length);
          node.below.push(name);
        }
        if (conditions.solvents.length > 3) node.below.push('[MORE]');

        let textYield = '';
        const textTimes = [''];
        const textTemps = [''];
        if (var0.yields && var0.yields.length > 0) {
          if (var0.yields[0][1] !== null) textYield = `${(var0.yields[0][1] * 100).toFixed(2)}%`;
        }

        for (const c in var0.conditions) {
          textTimes[c] = '';
          const { times } = var0.conditions[c];
          if (times && times.length > 0) {
            switch (times.length) {
              case 1:
                textTimes[c] = `${FormatTime(times[0])} h`;
                break;
              case 2:
                textTimes[c] = `${FormatTime(times[0])} - ${FormatTime(times[1])} h`;
                break;
              case 3:
                textTimes[c] = `${FormatTime(times[1])} h`;
                break;
              default:
                console.log('Invalid times', times);
            }
          }

          textTemps[c] = '';
          const { temps } = var0.conditions[c];
          if (temps && temps.length > 0) {
            const degC = SRV.HtmlDecode('&nbsp;&#x2103');
            switch (temps.length) {
              case 1:
                textTemps[c] = temps[0] + degC;
                break;
              case 2:
                textTemps[c] = `${temps[0]} - ${temps[1]}${degC}`;
                break;
              case 3:
                textTemps[c] = temps[1] + degC;
                break;
              default:
                console.log('Invalid temps', temps);
            }
          }
        }

        for (let c = 0; c < var0.conditions.length; c++) {
          let text = '';
          let comma = '';
          if (var0.conditions.length > 1) text += `${c + 1}. `;
          if (textTemps[c]) {
            text += textTemps[c];
            comma = ', ';
          }
          if (textTimes[c]) {
            text += comma + textTimes[c];
            comma = ', ';
          }
          if (textYield && c == var0.conditions.length - 1) {
            text += comma + textYield;
          }
          const length = ctx.measureText(text).width;
          node.length = Math.max(node.length, length);
          if (text.length > 0) node.below.push(text);
        }
      }
      // node.conditions = conditions;
      const maxrows = Math.max(node.above.length, node.below.length);
      node.nodeHeight = (maxrows * 15 + 10) * 2;
      node.nodeLength = node.length;
      if (node.nodeLength < SRV.avoidKeepMinLength) node.nodeLength = SRV.avoidKeepMinLength;
      break;

    case 'rxc':
      node.nodeLength = node.length;
      if (node.nodeLength < SRV.avoidKeepMinLength) node.nodeLength = SRV.avoidKeepMinLength;
      break;
    default:
      console.log('NodeInit invalid node', node);
  }
};

function TrimText(text, maxlength) {
  const ctx = SRV.nullCanvas.getContext('2d');
  ctx.font = SRV.font;
  const l = ctx.measureText(text).width;
  if (l < maxlength) return text;

  const nchars = parseInt((text.length * maxlength) / l) - 3;
  const chop = `${text.substring(0, nchars)}...`;
  // console.log(l, maxlength, maxlength/l, nchars, text, chop);
  return chop;
}

function FormatText(text, minlength, maxlines) {
  const ctx = SRV.nullCanvas.getContext('2d');
  ctx.font = SRV.font;

  const words = text.split(/-|\(|\)| /);
  const textbox = [];
  let seglen = 0;
  let p1 = 0;
  let p2 = 0;
  let l;
  let h;
  let yoff = 0;
  for (const w in words) {
    const bbox = ctx.measureText(words[w]);
    l = Math.round(bbox.width);
    h = 11;
    if (bbox.actualBoundingBoxAscent && bbox.actualBoundingBoxDescent) {
      h = Math.round(bbox.actualBoundingBoxAscent + bbox.actualBoundingBoxDescent + 0.5);
    }

    if (seglen + l > minlength && p1 != p2) {
      textbox.push({
        str: text.substring(p1, p2),
        x: 0,
        y: yoff,
        l: seglen,
        h,
      });
      seglen = 0;
      p1 = p2;
      yoff += h + 5;
    }
    if (w < words.length - 1) seglen += l + 5;
    else seglen += l;
    p2 += words[w].length + 1;
  }

  textbox.push({
    str: text.substring(p1, p2),
    x: 0,
    y: yoff,
    l: seglen,
    h,
  });
  yoff += h + 5;
  if (textbox.length > maxlines) {
    textbox.length = maxlines;
    textbox[maxlines - 1].str += '...';
    textbox[maxlines - 1].l = Math.round(ctx.measureText(textbox[maxlines - 1].str).width);
  }

  return textbox;
}

SRV.DrawSegments = function (canvas, segments) {
  const ctx = canvas.getContext('2d');
  // let skyblue = '#46c6e9';

  ctx.font = SRV.font;
  ctx.fillStyle = '#000000';
  ctx.textBaseline = 'top';
  for (const s in segments) {
    for (const n in segments[s]) {
      const node = segments[s][n];
      let { xoff } = node;
      let { yoff } = node;
      let maxlength = 0;
      xoff += 15;

      xoff = parseInt(xoff) + 0.5; // anti-aliasing
      yoff = parseInt(yoff) + 0.5;
      ctx.save();
      ctx.translate(xoff, yoff);

      switch (node.type) {
        case 'segment':
          ctx.font = SRV.fontBold;
          ctx.fillText(node.name, 0, 0);
          ctx.font = SRV.fontBold;
          break;

        case 'rxc':
          if (node.cpd && !node.inode) {
            const tree = node.cpd.drawTree;
            if (tree) {
              tree.canvasWrapper.redraw(canvas);
            } else {
              SRV.DrawNoStructure(canvas, node.cpd);
            }
          }
          let textheight = 0;
          for (const t in node.textbox) {
            maxlength = Math.max(maxlength, node.textbox[t].l);
            textheight += node.textbox[t].h;
          }
          const dx = (node.length - maxlength) / 2;
          let dy = node.height + 5;

          if (node.inode) {
            dy = node.nodeHeight / 2 - textheight / 2;
          }
          ctx.fillStyle = '#000';
          for (const t in node.textbox) {
            const text = node.textbox[t];
            ctx.fillText(text.str, text.x + dx, text.y + dy);
            /*
            ctx.strokeStyle = '#00F';
            ctx.strokeRect(text.x+dx, text.y+dy, text.l, text.h);
            */
          }
          ctx.fillStyle = '#000';
          break;
        case 'rxr':
          let yoff = 0;
          ctx.fillStyle = '#000';
          ctx.strokeStyle = '#000';
          if (node.above) {
            for (let c = 0; c < node.above.length; c++) {
              ctx.fillText(node.above[c], 5, yoff);
              yoff += 15;
            }
          }
          yoff += 5;
          const length = node.nodeLength - 5;
          draw_arrow(ctx, 0, yoff, length, yoff, 10, 10, 0, 1);

          // switch (node.arrowType) {
          //   case ARROW_RIGHT:
          //     draw_arrow(ctx, 0, yoff, length, yoff, 10, 10, 0, 1);
          //     break;
          //   case ARROW_RIGHT_DOWN:
          //     draw_line(ctx, 0, yoff, length, yoff);
          //     draw_arrow(ctx, length, yoff, length, yoff + 30, 10, 10, 0, 1);
          //     break;
          //   case ARROW_RIGHT_TAIL:
          //     draw_line(ctx, 0, yoff, 0, yoff - 30);
          //     draw_arrow(ctx, 0, yoff, length, yoff, 10, 10, 0, 1);
          //     break;
          //   case ARROW_LEFT:
          //     draw_arrow(ctx, length, yoff, 0, yoff, 10, 10, 0, 1);
          //     break;
          //   case ARROW_LEFT_DOWN:
          //     draw_line(ctx, 0, yoff, length, yoff);
          //     draw_arrow(ctx, 0, yoff, 0, yoff + 30, 10, 10, 0, 1);
          //     break;
          //   case ARROW_LEFT_TAIL:
          //     draw_line(ctx, length, yoff, length, yoff - 30);
          //     draw_arrow(ctx, length, yoff, 0, yoff, 10, 10, 0, 1);
          //     break;
          //   default:
          //     console.log('unknow arrow', node.arrowType);
          // }
          yoff += 10;

          if (node.below) {
            for (let c = 0; c < node.below.length; c++) {
              ctx.fillText(node.below[c], 5, yoff);
              yoff += 15;
            }
          }

          // ctx.fillText(node.name, 5, yoff);
          ctx.fillStyle = '#000';
          ctx.strokeStyle = '#000';
          break;
        case 'plus': {
          const x = node.length / 2;
          const y = node.height / 2;
          draw_line(ctx, x - 10, y, x + 10, y);
          draw_line(ctx, x, y - 10, x, y + 10);
          break;
        }
        default:
          console.log('unknown prim', node);
      }

      if (node.frame) {
        // console.log(node.name, node.nodeLength, maxlength);
        const dh = (node.nodeHeight - node.rowheight) / 2;
        let w = Math.max(node.nodeLength, maxlength);
        let h = node.rowheight + SRV.avoidKeepHeight;
        let dx = xoff;
        let dy = yoff + dh;

        if (w > node.length) dx -= (w - node.length) / 2;

        dx -= 8;
        dy -= 8;
        w += 8;
        h += 8;

        node.frame.left = dx;
        node.frame.top = dy;
        node.frame.right = dx + w;
        node.frame.bottom = dy + h;

        node.frame.style.left = `${dx}px`;
        node.frame.style.top = `${dy}px`;
        node.frame.style.width = `${w}px`;
        node.frame.style.height = `${h}px`;
      }
      /*
            ctx.strokeStyle = '#f00';
            ctx.strokeRect(0,0, node.length, node.height);
            ctx.strokeStyle = '#0f0';
            ctx.strokeRect(-5,-5, node.nodeLength+10, node.nodeHeight+10);
      */
      ctx.restore();
    }
  }
};

SRV.DrawStrategyInit = function (results, canvas, strategyId) {
  const route = results.strategyRouteIndex[`S${strategyId}R0`];
  if (!route) {
    alert(`Can't locate strategy: ${strategyId}`);
    return;
  }

  const stub = route.route[route.route.length - 1];

  const ctx = canvas.getContext('2d');
  const nodes = [];

  let needplus = 0;
  for (let p = 0; p < stub.left_primaries.length; p++) {
    if (needplus) {
      nodes.push({
        type: 'plus',
        name: 'plus',
        length: 50,
        height: 50,
      });
      needplus = 0;
    }

    const rxc = stub.left_primaries[p];
    const cpd = results.cpds[rxc];
    if (!cpd) {
      console.log('Invalid CPDS entry rxc');
      continue;
    }

    if (!cpd.drawTree && !cpd.smilesError) {
      SRV.DrawTreeInit(cpd, canvas);
    }

    // let name = results.cpds[rxn.branchIds[b]].name;
    let { name } = cpd;
    if (!name) name = rxc;
    name = SRV.HtmlDecode(name);

    nodes.push({
      type: 'rxc',
      cpd,
      id: rxc,
      name,
      cdpnew: rxc.indexOf('CNEW') == 0,
      length: cpd.length,
      height: cpd.height,
    });
    needplus = 1;
  }

  const rxr = results.rxns[stub.rxn_id];
  if (!rxr) {
    console.log('rxr!', stub.rxn_id);
    return;
  }
  const name = rxnFixName(stub.rxn_id);
  nodes.push({
    type: 'rxr',
    rxr: results.rxns[stub.rxn_id],
    id: stub.rxn_id,
    rxrnew: stub.rxn_id.indexOf('RNEW') == 0,
    name,
  });

  needplus = 0;
  for (let p = 0; p < stub.right_primaries.length; p++) {
    const rxc = stub.right_primaries[p];

    if (needplus) {
      nodes.push({
        type: 'plus',
        name: 'plus',
        length: 50,
        height: 50,
      });
      needplus = 0;
    }

    const cpd = results.cpds[rxc];
    if (!cpd) {
      console.log('smiles error!', rxc);
      return;
    }

    if (!cpd.drawTree && !cpd.smilesError) {
      SRV.DrawTreeInit(cpd, canvas);
    }

    let { name } = cpd;
    if (!name) name = rxc;
    name = SRV.HtmlDecode(name);
    nodes.push({
      type: 'rxc',
      cpd,
      id: rxc,
      name,
      cdpnew: rxc.indexOf('CNEW') == 0,
      length: cpd.length,
      height: cpd.height,
    });
    needplus = 1;
  }

  for (const n in nodes) {
    const node = nodes[n];
    node.results = results;
    SRV.NodeInit(node);
  }

  canvas.results = results;
  canvas.segments = [nodes];

  return nodes;
};

SRV.DrawStrategy = function (canvas, resize) {
  if (!canvas.segments) return;

  const { segments } = canvas;
  const height = SRV.LayoutSegments(canvas, segments);

  const ctx = canvas.getContext('2d');
  SRV.scaleDevice = DevicePixelRatio(ctx);

  if (resize) {
    // canvas.style.width = '100%';
    canvas.style.height = `${height}px`;
    // canvas.width = canvas.clientWidth * SRV.scaleDevice;
    canvas.height = canvas.clientHeight * SRV.scaleDevice;
  }

  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.scale(SRV.scaleDevice, SRV.scaleDevice);

  SRV.DrawSegments(canvas, segments);
};

SRV.DrawRouteInit = function (results, canvas, strategyId, routeId) {
  const sroute = results.strategyRouteIndex[`S${strategyId}R${routeId}`];
  if (!sroute) {
    alert(`Can't locate strategy: ${strategyId}`);
    return;
  }

  const segments = [];

  for (const s in sroute.route_segments) {
    const segment = sroute.route_segments[s];
    const nodes = [];

    segments.push(nodes);
    if (sroute.route_segments.length > 1) {
      const name = `Segment ${segments.length}`;
      nodes.push({
        type: 'segment',
        name,
      });
    }
    let needplus = 0;

    for (let r = 0; r < segment.length; r++) {
      const rxn = segment[r];
      for (let b = 0; b < rxn.branchIds.length; b++) {
        if (needplus) {
          nodes.push({
            type: 'plus',
            name: 'plus',
            length: 50,
            height: 50,
          });
          needplus = 0;
        }

        const rxc = rxn.branchIds[b];
        const cpd = results.cpds[rxc];
        if (!cpd) {
          console.log('smiles error', rxc);
          return;
        }

        if (!cpd.drawTree && !cpd.smilesError) {
          SRV.DrawTreeInit(cpd, canvas);
        }

        let { name } = cpd;
        if (!name) name = rxc;
        name = SRV.HtmlDecode(name);
        // name = rxc;
        nodes.push({
          type: 'rxc',
          cpd,
          id: rxc,
          name,
          cdpnew: rxc.indexOf('CNEW') == 0,
          length: cpd.length,
          height: cpd.height,
        });
        needplus = 1;
      }

      for (let f = 0; f < rxn.feedstockIds.length; f++) {
        if (needplus) {
          nodes.push({
            type: 'plus',
            name: 'plus',
            length: 50,
            height: 50,
          });
          needplus = 0;
        }

        const rxc = rxn.feedstockIds[f];
        const cpd = results.cpds[rxc];
        if (!cpd) {
          console.log('smiles error!', rxn.feedstockIds[f]);
          return;
        }

        if (!cpd.drawTree && !cpd.smilesError) {
          SRV.DrawTreeInit(cpd, canvas);
        }

        let { name } = cpd;
        if (!name) {
          name = rxc;
        }
        name = SRV.HtmlDecode(name);
        // name = rxc;
        nodes.push({
          type: 'rxc',
          cpd,
          id: rxc,
          name,
          cdpnew: rxc.indexOf('CNEW') == 0,
          length: cpd.length,
          height: cpd.height,
        });
        needplus = 1;
      }

      const rxr = results.rxns[rxn.rxn_id];
      if (!rxr) {
        console.log('rxr!', rxn.rxn_id);
        return;
      }

      let name = rxnFixName(rxn.rxn_id);
      nodes.push({
        type: 'rxr',
        rxr: results.rxns[rxn.rxn_id],
        id: rxn.rxn_id,
        rxrnew: rxn.rxn_id.indexOf('RNEW') == 0,
        name,
      });

      const rxc = rxn.prod_id;
      const cpd = results.cpds[rxc];
      if (!cpd) {
        console.log('smiles error!', rxn.prod_id);
        return;
      }

      if (!cpd.drawTree && !cpd.smilesError) {
        SRV.DrawTreeInit(cpd, canvas);
      }

      name = cpd.name || cpd.compound_name || cpd.formula;
      if (!name) name = rxc;
      name = SRV.HtmlDecode(name);
      // name = rxc;
      nodes.push({
        type: 'rxc',
        cpd,
        id: rxc,
        name,
        cdpnew: rxc.indexOf('CNEW') == 0,
        length: cpd.length,
        height: cpd.height,
      });
    }
  }
  for (const s in segments) {
    for (const n in segments[s]) {
      const node = segments[s][n];
      node.results = results;
      SRV.NodeInit(node);
    }
  }

  SRV.InitIntermediates(segments);

  canvas.results = results;
  canvas.segments = segments;
};

SRV.InitIntermediates = function (segments) {
  const Inodes = [];

  for (const s in segments) {
    for (const n in segments[s]) {
      const node = segments[s][n];
      if (node.type != 'rxc') {
        continue;
      }

      if (n == segments[s].length - 1) {
        Inodes[s] = node;
      } else {
        const i = Inodes.findIndex(inode => inode.id == node.id);
        if (i != -1) {
          node.iname = `I-${i + 1}`;
          node.inode = Inodes[i];
          Inodes[i].iname = `I-${i + 1}`;
        }
      }
    }
  }

  // console.log('intermediates', Inodes, segments);
};

function FormatTime(timeInHours) {
  if (parseInt(timeInHours) == timeInHours) return timeInHours;
  return timeInHours.toFixed(2);

  /*
  let hours = parseInt(timeInHours);
  let min = parseInt(60 * (timeInHours - hours)).toString();

  if (min.length == 1)
min = '0' + min;

  return hours + ':' + min;
  */
}

SRV.AddKeepAvoid = function (canvas) {
  const { results } = canvas;
  const targetId = results.routes[0].route_tree.prod_id;

  for (const s in canvas.segments) {
    let prev = null;
    for (let n = 0; n < canvas.segments[s].length; n++) {
      const segment = canvas.segments[s];
      const node = segment[n];
      node.prev = prev;
      node.next = n < segment.length ? segment[n + 1] : null;
      prev = node;

      switch (node.type) {
        case 'rxc':
        case 'rxr':
          const div = document.createElement('DIV');

          let html = "<div class='footer'>";
          const isnew = node.type == 'rxc' ? node.cdpnew : node.rxrnew;
          if (node.id != targetId && isnew == false)
            html += "<button class='btnAvoid'>Avoid</button><button class='btnKeep'>Keep</button>";

          html += '</div>';
          div.innerHTML = html;

          div.classList.add('node');
          div.classList.add('hide');
          div.onclick = function (event) {
            if (canvas.onClickReact) {
              const oldFrame = canvas.detailFrame;
              if (canvas.detailFrame) {
                canvas.detailFrame.classList.remove('detailed');
                canvas.detailFrame = null;
                canvas.onClickReact(canvas.routeDetails);
                if (oldFrame == node.frame) return;
              }

              switch (node.type) {
                case 'rxc': {
                  if (!node.details) node.details = SRV.CompoundDetails(node, results);
                  node.frame.classList.add('detailed');
                  canvas.detailFrame = node.frame;
                  canvas.onClickReact(node.details);
                  break;
                }
                case 'rxr': {
                  if (!node.details) node.details = SRV.ReactionDetails(node, results);
                  node.frame.classList.add('detailed');
                  canvas.detailFrame = node.frame;
                  canvas.onClickReact(node.details);
                  break;
                }
                default:
                  console.log('Invalid keep/avoid type', node);
              }

              if (oldFrame && oldFrame != canvas.detailFrame) {
                if (
                  oldFrame.classList.contains('keep') == false &&
                  oldFrame.classList.contains('avoid') == false &&
                  oldFrame.classList.contains('detailed') == false
                )
                  oldFrame.classList.add('hide');
              }

              return StopEvent(event);
            }
          };

          div.onmouseleave = function () {
            if (
              div.classList.contains('keep') == false &&
              div.classList.contains('avoid') == false &&
              div.classList.contains('detailed') == false
            )
              div.classList.add('hide');
            /*
            let tooltip = document.getElementById('toolTip');
            if (tooltip)
          tooltip.classList.add('hidden');
            */
          };

          const keep = div.getElementsByClassName('btnKeep')[0];
          node.keep = keep;
          if (keep) {
            keep.onclick = function (event) {
              const { frame } = node;
              if (frame.classList.contains('keep')) {
                frame.classList.remove('keep');
                if (SRV.keepList[node.id]) delete SRV.keepList[node.id];
                if (node.type == 'rxr') {
                  for (let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
                    if (neighbor != node && neighbor.type == 'rxr') break;
                    if (neighbor.type == 'rxc') {
                      neighbor.frame.classList.remove('keep');
                      if (neighbor.frame != canvas.detailFrame) neighbor.frame.classList.add('hide');
                      if (SRV.keepList[neighbor.id]) delete SRV.keepList[neighbor.id];
                      if (neighbor.keep) neighbor.keep.classList.remove('hide');
                      if (neighbor.avoid) neighbor.avoid.classList.remove('hide');
                    }
                  }
                }
              } else {
                frame.classList.add('keep');
                SRV.keepList[node.id] = true;
                frame.classList.remove('avoid');
                if (SRV.avoidList[node.id]) delete SRV.avoidList[node.id];
                if (node.type == 'rxr') {
                  for (let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
                    if (neighbor != node && neighbor.type == 'rxr') break;
                    if (neighbor.type == 'rxc') {
                      neighbor.frame.classList.add('keep');
                      neighbor.frame.classList.remove('hide');
                      SRV.keepList[neighbor.id] = true;
                      neighbor.frame.classList.remove('avoid');
                      if (SRV.avoidList[neighbor.id]) delete SRV.avoidList[neighbor.id];
                      if (neighbor.keep) neighbor.keep.classList.add('hide');
                      if (neighbor.avoid) neighbor.avoid.classList.add('hide');
                    }
                  }
                }
              }
              console.log('keep/avoid', SRV.avoidList, SRV.keepList);

              /* disable toggling search again button
              let details = {
                  type: 'searchAgain',
                  enabled: (Object.keys(SRV.avoidList).length > 0 || Object.keys(SRV.keepList).length > 0) ? true :  false
              };
              canvas.onClickReact(details);
              */
              return StopEvent(event);
            };
          }
          const avoid = div.getElementsByClassName('btnAvoid')[0];
          node.avoid = avoid;
          if (avoid) {
            avoid.onclick = function (event) {
              const { frame } = node;
              if (frame.classList.contains('avoid')) {
                frame.classList.remove('avoid');
                if (SRV.avoidList[node.id]) delete SRV.avoidList[node.id];
                /*
                if (node.type == 'rxr') {
              for(let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
                  if (neighbor != node && neighbor.type == 'rxr')
                break;
                  if (neighbor.type == 'rxc') {
                neighbor.frame.classList.add('hide');
                neighbor.frame.classList.remove('keep');
                if (SRV.keepList[neighbor.id])
                    delete SRV.keepList[neighbor.id];
                if (neighbor.keep)
                    neighbor.keep.classList.add('hide');
                if (neighbor.avoid)
                    neighbor.avoid.classList.add('hide');
                  }
              }
                }
                */
              } else {
                frame.classList.add('avoid');
                SRV.avoidList[node.id] = true;
                if (node.type == 'rxr' && frame.classList.contains('keep')) {
                  for (let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
                    if (neighbor != node && neighbor.type == 'rxr') break;
                    if (neighbor.type == 'rxc') {
                      if (neighbor.frame != canvas.detailFrame) neighbor.frame.classList.add('hide');
                      neighbor.frame.classList.remove('keep');
                      if (SRV.keepList[neighbor.id]) delete SRV.keepList[neighbor.id];
                      if (neighbor.keep) neighbor.keep.classList.remove('hide');
                      if (neighbor.avoid) neighbor.avoid.classList.remove('hide');
                    }
                  }
                }
                frame.classList.remove('keep');
                if (SRV.keepList[node.id]) delete SRV.keepList[node.id];
              }
              console.log('keep/avoid', SRV.avoidList, SRV.keepList);

              /* disable toggling search again button
              let details = {
                  type: 'searchAgain',
                  enabled: (Object.keys(SRV.avoidList).length > 0 || Object.keys(SRV.keepList).length > 0) ? true :  false
              };
              canvas.onClickReact(details);
              */
              return StopEvent(event);
            };
          }
          canvas.parentNode.appendChild(div);
          canvas.parentNode.style.position = 'relative';
          node.frame = div;
          break;
        default:
      }
    }
  }
};

SRV.ReactionDetails = function (node, results) {
  const details = {
    type: 'reactionDetails',
    id: node.name,
    idLabel: node.rxrnew ? 'Transformation' : 'Id',
    source: node.rxrnew ? 'Computer Generated' : 'Literature',
    variation: [],
    results,
  };
  const { rxr } = node;

  for (let v = 0; v < rxr.variations.length; v++) {
    const variation = {
      yield: 'n/a',
      yieldLabel: node.rxrnew ? 'Yield (estimated)' : 'Yield',
      stages: [],
    };

    const { yields } = rxr.variations[v];
    if (yields && yields.length > 0) {
      if (yields[0][1] !== null) {
        let _yield = yields[0][1] * 100;
        _yield = _yield.toFixed(0);
        variation.yield = `${_yield}%`;
      }
    }

    for (let c = 0; c < rxr.variations[v].conditions.length; c++) {
      const stage = {
        times: 'n/a',
        temps: 'n/a',
        solvents: 'n/a',
        reagents: 'n/a',
        catalysts: 'n/a',
        ph: 'n/a',
      };

      const { times } = rxr.variations[v].conditions[c];
      if (times && times.length > 0) {
        switch (times.length) {
          case 1:
            stage.times = `${FormatTime(times[0])} h`;
            break;
          case 2:
            stage.times = `${FormatTime(times[0])} - ${FormatTime(times[1])} h`;
            break;
          case 3:
            stage.times = `${FormatTime(times[0])} - ${FormatTime(times[2])} h`;
            break;
          default:
            console.log('Invalid time', times);
        }
      }

      const { temps } = rxr.variations[v].conditions[c];
      if (temps && temps.length > 0) {
        const degC = SRV.HtmlDecode('&nbsp;&#x2103');
        switch (temps.length) {
          case 1:
            stage.temps = temps[0] + degC;
            break;
          case 2:
            stage.temps = `${temps[0]} - ${temps[1]}${degC}`;
            break;
          case 3:
            stage.temps = `${temps[0]} - ${temps[2]}${degC}`;
            break;
          default:
            console.log('Invalid temps', temps);
        }
      }

      const { solvents } = rxr.variations[v].conditions[c];
      if (solvents && solvents.length > 0) {
        let solventList = '';
        for (let x = 0; x < solvents.length; x++) {
          if (x > 0) solventList += '\n';
          const cpd = node.results.cpds[solvents[x]];
          if (!cpd || !cpd.name) {
            console.log('invalid solvent', x, solvents[x]);
            solventList += solvents[x];
          } else {
            solventList += cpd.name;
          }
        }
        stage.solvents = solventList;
      }
      const { reagents } = rxr.variations[v].conditions[c];
      if (reagents && reagents.length > 0) {
        let reagentList = '';
        for (let x = 0; x < reagents.length; x++) {
          if (x > 0) reagentList += '\n';
          const cpd = node.results.cpds[reagents[x]];
          if (!cpd || !cpd.name) {
            console.log('invalid reagent', x, reagents[x]);
            reagentList += reagents[x];
          } else {
            reagentList += cpd.name;
          }
        }
        stage.reagents = reagentList;
      }
      const { catalysts } = rxr.variations[v].conditions[c];
      if (catalysts && catalysts.length > 0) {
        let catalystList = '';
        for (let x = 0; x < catalysts.length; x++) {
          if (x > 0) catalystList += '\n';
          const cpd = node.results.cpds[catalysts[x]];
          if (!cpd || !cpd.name) {
            console.log('invalid catalyst', x, catalysts[x]);
            catalystList += catalysts[x];
          } else {
            catalystList += cpd.name;
          }
        }
        stage.catalysts = catalystList;
      }

      variation.stages.push(stage);
    }
    details.variation.push(variation);
  }

  return details;
};

SRV.CompoundDetails = function (node, results) {
  const details = {
    type: 'compoundDetails',
    mol_cost: 'n/a',
    mol_weight: 'n/a',
    smiles: 'n/a',
    inchi: 'n/a',
    id: node.id,
    name: 'n/a',
    CompoundType: 'Literature',
    MCT: 'n/a',
    results,
  };

  if (node.cpd.name) details.name = SRV.HtmlDecode(node.cpd.name);

  if (node.cpd.mol_cost) details.mol_cost = `$${node.cpd.mol_cost.toFixed(2)}/mol`;

  if (node.cpd.mono_weight) details.mol_weight = `${node.cpd.mono_weight.toFixed(2)} g/mol`;

  if (node.cpd.smiles) details.smiles = node.cpd.smiles;

  if (node.cpd.inchi) {
    let { inchi } = node.cpd;
    const x = inchi.indexOf('InChI=');
    if (x != -1) inchi = inchi.substring(x + 6);
    details.inchi = inchi;
  }

  if (node.cdpnew) {
    details.CompoundType = 'Computer Generated';
  }

  return details;
};

SRV.RouteDetails = function (results, canvas, strategyId, routeId) {
  const route = results.strategyRouteIndex[`S${strategyId}R${routeId}`];
  if (!route) {
    alert(`Can't locate route ${strategyId} route ${routeId}`);
    return;
  }

  const details = {
    type: 'routeDetails',
    steps: route.nbrxns,
    maxsegment: route.depth,
    solventChanges: route.cost_solvent_exchanges.toFixed(0),
    solventCost: route.cost_solvents ? `$${route.cost_solvents.toFixed(2)}/mol` : 'n/a',
    routeCost: route.cost ? `$${route.cost.toFixed(2)}/mol` : 'n/a',
    autosync: 'n/a',
    strategyId,
    routeId,
    results,
    canvas,
  };

  canvas.routeDetails = details;

  if (canvas.onClickReact) canvas.onClickReact(details);
};

SRV.DrawRoute = function (canvas, resize) {
  if (!canvas.segments) return;

  const { segments } = canvas;
  const { height, width } = SRV.LayoutSegments(canvas, segments);

  const ctx = canvas.getContext('2d');
  SRV.scaleDevice = 1;

  if (resize) {
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height * SRV.scaleDevice}px`;
    canvas.width = width;
    canvas.height = canvas.clientHeight * SRV.scaleDevice;
  }

  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.scale(SRV.scaleDevice, SRV.scaleDevice);

  SRV.DrawSegments(canvas, segments);
};

SRV.DrawSavedRoute = function (results, canvas, resize) {
  // console.log("SRV.DrawSavedRoute", canvas.id, canvas.width, canvas.height, resize);

  const ctx = canvas.getContext('2d');
  SRV.scaleDevice = DevicePixelRatio(ctx);

  if (!results.routes[0]) {
    alert("Can't locate target!");
    return;
  }

  const rxc = results.routes[0].route_tree.prod_id;
  const cpd = results.cpds[rxc];

  if (!cpd) {
    console.log('smiles error!', rxc);
    return;
  }

  if (!cpd.drawTree && !cpd.smilesError) {
    SRV.DrawTreeInit(cpd, canvas);
  }

  let xoff = 0;
  let yoff = 0;
  const xpad = 20;
  const ypad = 20;
  let scale = 1;

  ctx.save();
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  //    ctx.fillStyle = "#00F";
  //    ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  if (canvas.type.indexOf('recentCanvas') == 0) {
    // canvas.style.width = '100%';
    // canvas.style.height = '100%';
    // canvas.width = canvas.clientWidth * SRV.scaleDevice;
    // canvas.height = canvas.clientHeight * SRV.scaleDevice;
    const pad = 20;
    const sx = canvas.width / (cpd.length + pad);
    const sy = canvas.height / (cpd.height + pad);
    scale = Math.min(1, Math.min(sx, sy));
    xoff = (canvas.width - (cpd.length + pad) * scale) / 2 + (pad / 2) * scale;
    yoff = (canvas.height - (cpd.height + pad) * scale) / 2 + (pad / 2) * scale;
    /*
      console.log('scale', sx, sy, scale,
            'off', xoff, yoff,
            'clientWidth', canvas.clientWidth,
            'canvasWidth', canvas.width,
            'cpdLength', cpd.length,
            SRV.scaleDevice);
    */
  } else {
    canvas.style.width = '100%';
    canvas.style.height = `${cpd.height + ypad}px`;
    canvas.width = canvas.clientWidth * SRV.scaleDevice;
    canvas.height = canvas.clientHeight * SRV.scaleDevice;
    ctx.scale(SRV.scaleDevice, SRV.scaleDevice);
    xoff = (canvas.clientWidth - cpd.length) / 2;
    yoff = (canvas.clientHeight - cpd.height) / 2;
  }

  xoff = parseInt(xoff) + 0.5; // anti-aliasing
  yoff = parseInt(yoff) + 0.5;

  ctx.translate(xoff, yoff);
  ctx.scale(scale, scale);
  const tree = cpd.drawTree;
  if (tree) {
    tree.canvasWrapper.redraw(canvas);
  } else {
    SRV.DrawNoStructure(canvas, cpd);
  }

  /*
      ctx.strokeStyle = '#ff0000';
      ctx.strokeRect(0,0, cpd.length, cpd.height);
  */
  ctx.restore();

  canvas.onclick = function (event) {
    if (canvas.onClickReact) canvas.onClickReact(results);
  };
};

SRV.CanvasMount = function (canvas, strategyId, routeId, type, results, onClickReact, setRowDataFromCanvas) {
  /*
  let hackId = "RXR-24200362";
  let xx = 0;
  if (results && results.rxns && results.rxns[hackId]) {
let conditions = results.rxns[hackId].variations[0][0].conditions[0];
let rxcs = Object.keys(results.cpds);
for(let r = 0; r < 3; r++)
    conditions.reagents[r] = rxcs[xx++];
results.cpds[rxcs[1]].name += 'very long name';

for(let c = 0; c < 3; c++)
    conditions.catalysts[c] = rxcs[xx++];
results.cpds[rxcs[4]].name += 'very long name';

for(let s = 0; s < 5; s++)
    conditions.solvents[s] = rxcs[xx++];
results.cpds[conditions.solvents[1]].name += 'very long name';

console.log('hack1', conditions);
  }
 hackId = "RXR-2001080";
  if (results && results.rxns && results.rxns[hackId]) {
let conditions = results.rxns[hackId].variations[0][0].conditions[0];
let rxcs = Object.keys(results.cpds);
for(let r = 0; r < 3; r++)
    conditions.reagents[r] = rxcs[xx++];
results.cpds[conditions.reagents[1]].name += 'very long name';

for(let c = 0; c < 2; c++)
    conditions.catalysts[c] = rxcs[xx++];
results.cpds[conditions.catalysts[1]].name += 'very long name';
console.log('hack', conditions);
  }
*/
  /*
      if (results && results.cpds) {
    let testnulls = ["RXC-7539016", "RXC-7671333"];
    for(let id in testnulls) {
        let cpd = results.cpds[testnulls[id]];
        if (cpd) {
      cpd.smiles = '';
      //console.log('nulling smiles', strategyId, routeId, cpd.name);
        }
    }
      }
  */
  if (SRV.smilesDrawer == null) SRV.Init();

  canvas.uid = ++SRV.canvasCount;
  canvas.type = type;
  canvas.results = results;
  canvas.strategyId = strategyId;
  canvas.routeId = routeId;
  canvas.classList.add(type);
  canvas.onClickReact = onClickReact;

  switch (type) {
    case 'strategyCanvas':
      SRV.DrawStrategyInit(results, canvas, strategyId);
      canvas.draw = function (resize) {
        SRV.DrawStrategy(canvas, resize);
      };
      break;
    case 'targetCanvas':
      canvas.draw = function (resize) {
        SRV.DrawTarget(results, canvas, resize);
      };
      break;
    case 'routeCanvas':
      SRV.DrawRouteInit(results, canvas, strategyId, routeId);
      SRV.DrawRoute(canvas, true);
      setRowDataFromCanvas(canvas.rowNodes);
      // canvas.draw = function (resize) {
      //   SRV.DrawRoute(canvas, resize);
      // };
      break;
    case 'refineCanvas':
      SRV.DrawRouteInit(results, canvas, strategyId, routeId);
      SRV.AddKeepAvoid(canvas);
      SRV.RouteDetails(results, canvas, strategyId, routeId);
      canvas.draw = function (resize) {
        SRV.DrawRoute(canvas, resize);
      };
      break;
    case 'recentCanvas':
    case 'savedCanvas':
      SRV.GetStrategyRouteCounts(results);
      canvas.draw = function (resize) {
        SRV.DrawSavedRoute(results, canvas, resize);
      };
      break;
    case 'flaggedCanvas':
      SRV.DrawFlaggedInit(results, canvas);
      break;
    default:
      console.log('Invalid canvas type', type);
      return;
  }

  canvas.draw && canvas.draw(true);
  canvas.drawn = true;

  function ToggleFrame(node, enable) {
    const { frame } = node;
    if (enable) {
      frame.classList.remove('hide');
    } else if (
      frame.classList.contains('hide') == false &&
      frame.classList.contains('keep') == false &&
      frame.classList.contains('avoid') == false &&
      frame.classList.contains('detailed') == false
    ) {
      frame.classList.add('hide');
    }
  }

  function OnMouseMove(evt) {
    const { segments } = canvas;
    if (!segments) return;

    let focusNode = null;

    // turn off all frames, find focus
    for (let s = 0; s < segments.length; s++) {
      const nodes = segments[s];
      for (let n = 0; n < nodes.length; n++) {
        const node = nodes[n];
        if (node.frame) {
          const x = evt.offsetX;
          const y = evt.offsetY;
          const { frame } = node;

          if (x >= frame.left && y >= frame.top && x <= frame.right && y <= frame.bottom) {
            focusNode = node;
          }
          ToggleFrame(node, false);
        }
      }
    }

    if (focusNode) {
      if (focusNode.iname) {
        for (let s = 0; s < segments.length; s++) {
          const nodes = segments[s];
          for (let n = 0; n < nodes.length; n++) {
            const inode = nodes[n];
            if (inode.iname == focusNode.iname) ToggleFrame(inode, true);
          }
        }
      } else {
        ToggleFrame(focusNode, true);
      }
    }
  }
  canvas.addEventListener('mousemove', OnMouseMove, false);

  /*
      let ctx = canvas.getContext('2d');
      let dragStart = null;
      let dragged = false;
      let rxTip = null;
      let j=0;

      function OnMouseDown(evt)
      {
    var px = EventCoords(evt, SRV.scaleDevice);
    dragStart = ctx.transformedPoint(px.x, px.y);
    dragged = false;
    return StopEvent(evt);
      }

      function OnMouseMove(evt)
      {
    let px = EventCoords(evt, SRV.scaleDevice);
    let pt = ctx.transformedPoint(px.x, px.y);
    let tip = null;

    if (evt.buttons == 0) {
        for(let R=0; R < SRV.route.length; R++) {
      let route = SRV.route[R];
      for(let r=0; r < route.length; r++) {
          let rx = route[r];
          let x1 = rx.xoff;
          let y1 = rx.yoff;
          let x2 = x1 + (rx.maxX - rx.minX);
          let y2 = y1 + (rx.maxY - rx.minY);
          if (pt.x > x1 &&
        pt.y > y1 &&
        pt.x < x2 &&
        pt.y < y2) {
        tip = rx;
        break;
          }
      }
        }
    } else {
        dragged = true;
        if (dragStart){
      ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
      Draw(SRV.route);
        }
    }

    let tipHtml = '';
    if (tip && tip.id == 'RXC') {
        tipHtml = '<b>RXC:</b><br>' + tip.smiles + "<br>...<br><br>";
    } else if (tip && tip.id == 'RXR') {
        tipHtml = '<b>RXR:</b><br>' + tip.label + "<br>...<br><br>";
    } else {
        tip = null;
    }

    if (rxTip != tip) {
        let bg = '#ccc';
        let highlight = '#000';
        let s = SRV.scaleCanvas;
        if (rxTip) {
      Draw(SRV.route);//drawBox(ctx, rxTip, bg);
        }
        rxTip = tip;
        let tooltip = document.getElementById('toolTip');
        if (rxTip) {
      drawBox(ctx, rxTip, highlight);
      tooltip.innerHTML = tipHtml;
      tooltip.style.display = 'inline';
        } else {
      let tip = document.getElementById('toolTip');
      tip.style.display = 'none';
        }
    }

    if (rxTip) {
        let tooltip = document.getElementById('toolTip');
        tooltip.style.left = px.x + 20 + 'px';
        tooltip.style.top = px.y - 20 + 'px';
    }
    return StopEvent(evt);
      }

      function OnMouseUp(evt)
      {
    dragStart = null;

    return StopEvent(evt);
      }

      function OnMouseScroll(evt)
      {
    var delta = -evt.deltaY;

    if (delta > 0)
        delta = 1;
    else if (delta < 0)
        delta = -1;
    else
        return;

  //	var delta = evt.wheelDelta ? evt.wheelDelta/40 : evt.detail ? -evt.detail : 0;

    if (delta) {
        let px = EventCoords(evt, SRV.scaleDevice);
        let scaleFactor = 1.02;
        let factor = Math.pow(scaleFactor, delta);
        let scale = SRV.scaleCanvas * factor;
  px.x = 0;
  px.y = 0;
        let pt = ctx.transformedPoint(px.x, px.y);

        SRV.scaleCanvas = scale;

        ctx.setTransform(1,0,0,1,0,0);
        ctx.scale(scale, scale);
        ctx.translate(-pt.x, -pt.y);
        ctx.translate(px.x/scale, px.y/scale);
        Draw(SRV.route);
    }

    return StopEvent(evt);
      }
  */
  /*
  function OnMouseScroll(evt) { console.log('scroll'); }
  function OnMouseDown(evt) { console.log('down', canvas); }
  function OnMouseUp(evt) { console.log('up'); }
  function OnMouseMove(evt) { console.log('move'); }

  canvas.addEventListener('DOMMouseScroll', OnMouseScroll, false);
  canvas.addEventListener('mousewheel', OnMouseScroll,false);
  canvas.addEventListener('mousedown', OnMouseDown, false);
  canvas.addEventListener('mousemove', OnMouseMove, false);
  canvas.addEventListener('mouseup', OnMouseUp, false);
  */
  /*
      function OnMouseDown(evt)
      {
    console.log('OnMouseDown', this, this.onClickReact);
    if (this.onClickReact) {
        if (this.onClickReact) {
      let arg = {
          type: "compound",
          description: "76 reactions produced this compound",
          purchaseOptions: [
        "Apollo Scientific $1.40/g",
        "Chem-Imprex $3.20/g",
        "Combi-Blocks $2.80/g",
        "TCI America $0.77/g"
          ]
      };
      this.onClickReact(arg);
        }
    }

    return StopEvent(evt);
      }
  */
  //    canvas.addEventListener('mousedown', OnMouseDown, false);
};

SRV.CanvasUnmount = function (canvas) {
  // console.log('SRV.CanvasUnmount', canvas.uid);

  canvas.onclick = null;
  canvas.redraw = null;

  for (const s in canvas.segments) {
    for (const n in canvas.segments[s]) {
      const { frame } = canvas.segments[s][n];
      if (frame) {
        frame.remove();
        // console.log(s, n, frame);
      }
    }
  }
};

SRV.DetailsClosed = function () {
  const frames = document.getElementsByClassName('detailed');
  if (frames && frames[0]) {
    const frame = frames[0];
    frame.classList.remove('detailed');
    if (frame.classList.contains('keep') == false && frame.classList.contains('avoid') == false)
      frame.classList.add('hide');
  }
};

function draw_arrow(ctx, xtail, ytail, xhead, yhead, headLength, headWidth, fromHead, toHead) {
  const s = 1;
  const x0 = 0;
  const y0 = 0;
  xhead = (xhead - x0) * s;
  yhead = (yhead - y0) * s;
  xtail = (xtail - x0) * s;
  ytail = (ytail - y0) * s;
  headLength *= s;
  headWidth *= s;

  const dx = xhead - xtail;
  const dy = yhead - ytail;
  const theta = Math.atan2(dy, dx);
  const len = Math.sqrt(dx * dx + dy * dy);
  ctx.save();
  ctx.translate(xhead, yhead);
  ctx.rotate(theta);

  let xbase = -headLength;
  let ybase = 0;
  xhead = 0;
  yhead = 0;
  if (fromHead) xtail = -len + headLength;
  else xtail = -len;
  ytail = 0;
  xbase = -headLength;
  ybase = 0;
  const x1 = xbase;
  const y1 = headWidth / 2;
  const x2 = xhead;
  const y2 = yhead;
  const x3 = xbase;
  const y3 = -headWidth / 2;

  //    ctx.strokeStyle = '#000';
  ctx.lineWidth = 3;
  ctx.beginPath();
  ctx.moveTo(xtail, ytail);
  ctx.lineTo(xbase, ybase);
  ctx.stroke();

  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x3, y3);
  ctx.fill();

  ctx.restore();
}

function draw_line(ctx, x1, y1, x2, y2) {
  //    ctx.strokeStyle = '#000';
  ctx.lineWidth = 3;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
  ctx.lineWidth = 1;
}

function DevicePixelRatio(ctx) {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const backingStoreRatio =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;
  const ratio = devicePixelRatio / backingStoreRatio;

  return ratio;
}

function EventCoords(evt, scaleDevice) {
  const bounds = evt.target.getBoundingClientRect();
  let x = evt.clientX - bounds.left;
  let y = evt.clientY - bounds.top;

  x *= scaleDevice;
  y *= scaleDevice;

  return { x, y };
}

function StopEvent(evt) {
  if (evt.preventDefault != undefined) evt.preventDefault();

  if (evt.stopPropagation != undefined) evt.stopPropagation();

  return false;
}

// Adds ctx.getTransform() - returns an SVGMatrix
// Adds ctx.transformedPoint(x,y) - returns an SVGPoint
function trackTransforms(ctx) {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  let xform = svg.createSVGMatrix();
  ctx.getTransform = function () {
    return xform;
  };

  const savedTransforms = [];
  const { save } = ctx;
  ctx.save = function () {
    savedTransforms.push(xform.translate(0, 0));
    return save.call(ctx);
  };

  const { restore } = ctx;
  ctx.restore = function () {
    xform = savedTransforms.pop();
    return restore.call(ctx);
  };

  const { scale } = ctx;
  ctx.scale = function (sx, sy) {
    xform = xform.scaleNonUniform(sx, sy);
    return scale.call(ctx, sx, sy);
  };

  const { rotate } = ctx;
  ctx.rotate = function (radians) {
    xform = xform.rotate((radians * 180) / Math.PI);
    return rotate.call(ctx, radians);
  };

  const { translate } = ctx;
  ctx.translate = function (dx, dy) {
    xform = xform.translate(dx, dy);
    return translate.call(ctx, dx, dy);
  };

  const { transform } = ctx;
  ctx.transform = function (a, b, c, d, e, f) {
    const m2 = svg.createSVGMatrix();
    m2.a = a;
    m2.b = b;
    m2.c = c;
    m2.d = d;
    m2.e = e;
    m2.f = f;
    xform = xform.multiply(m2);
    return transform.call(ctx, a, b, c, d, e, f);
  };

  const { setTransform } = ctx;
  ctx.setTransform = function (a, b, c, d, e, f) {
    xform.a = a;
    xform.b = b;
    xform.c = c;
    xform.d = d;
    xform.e = e;
    xform.f = f;
    return setTransform.call(ctx, a, b, c, d, e, f);
  };

  const pt = svg.createSVGPoint();
  ctx.transformedPoint = function (x, y) {
    pt.x = x;
    pt.y = y;
    return pt.matrixTransform(xform.inverse());
  };
}

SRV.resizeTimer = 0;

SRV.OnRedraw = function (event) {
  const t0 = performance.now();
  let ndrawn = 0;
  let nskipped = 0;

  const minredraw = !!event;

  const canvases = document.getElementsByTagName('canvas');
  for (let c = 0; c < canvases.length; c++) {
    const canvas = canvases[c];

    const ctx = canvas.getContext('2d');
    SRV.scaleDevice = DevicePixelRatio(ctx);
    const width = canvas.clientWidth * SRV.scaleDevice;
    const height = canvas.clientHeight * SRV.scaleDevice;

    const resize = true; // canvas.width != width || canvas.height != height;
    const rect = canvas.getBoundingClientRect();
    /*
      console.log(c, canvas.type,
            canvas.uid,
            'top', canvas.offsetTop,
            'scroll', window.scrollY,
            'winh', window.innerHeight,
            'height', height,
           rect);
    */
    if ((!minredraw || rect.top < window.innerHeight) && rect.bottom > 0) {
      if (resize || !canvas.drawn) {
        ndrawn++;
        canvas.draw(resize);
        canvas.drawn = true;
      }
    } else {
      // console.log('skipped', c, canvas.type, canvas.uid);
      nskipped++;
      canvas.drawn = false;
    }
  }
  const t1 = performance.now();
  // console.log('scroll', minredraw, ndrawn, nskipped, parseInt(t1-t0));

  if (minredraw) {
    if (SRV.resizeTimer) clearTimeout(SRV.resizeTimer);
    SRV.resizeTimer = setTimeout(() => {
      // console.log('final redraw');
      SRV.OnRedraw(null);
    }, 800);
  }
};

function OnClickReactTmp(evt, canvas, details) {
  let tooltip = document.getElementById('toolTip');
  if (!tooltip) {
    tooltip = document.createElement('DIV');
    tooltip.id = 'toolTip';
    tooltip.classList.add('hidden');
    tooltip.classList.add('arrow_box_left');
    tooltip.classList.add('tooltip');
    document.body.appendChild(tooltip);
  }

  let tipHtml = '<table>';
  const keys = Object.keys(details);
  for (const k in keys) {
    tipHtml += `<tr><td><b>${keys[k]}</b></td><td>${details[keys[k]]}</td></tr>`;
  }
  tipHtml += '</table>';
  tooltip.innerHTML = tipHtml;

  const x = evt.pageX + 20;
  const y = evt.pageY - tooltip.clientHeight / 2;
  tooltip.style.left = `${x}px`;
  tooltip.style.top = `${y}px`;
  tooltip.classList.remove('hidden');
}

const htmlDecodeMap = {};

SRV.HtmlDecode = function (html) {
  if (htmlDecodeMap[html]) {
    return htmlDecodeMap[html];
  }

  let converter = document.getElementById('htmlConverter');
  if (!converter) {
    converter = document.createElement('div');
    converter.id = 'htmlConverter';
    converter.style.display = 'none';
    document.body.appendChild(converter);
  }
  converter.innerHTML = html;
  const text = converter.innerText;
  htmlDecodeMap[html] = text;
  return text;
};

// window.addEventListener('resize', SRV.OnRedraw);
// window.addEventListener('printredraw', SRV.OnRedraw); // custom event for redrawing in print

console.log('SRV version', SRV.version);
