import React, { useEffect, useState } from 'react';
import { ExperimentInfo } from '../ExperimnetInfo/ExperimentInfo';
import './style.scss';
import { WebsocketService } from '../../../../utils/service/WebSocketService';
import { BATCH_STATUSES, SYNJET_EXPERIMENT_STATUSES, SYNJET_SOCKET_TYPES } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { getBatchExperiments, handleBatchAbortedStatus } from '../../../../store/synjet/synjet.actions';
import moment from 'moment';
import { BatchTabs } from './BatchTabs';
import { debounce } from 'lodash';

const parseExperiments = experiments =>
  experiments.map(experiment => ({
    ...experiment,
    execution: {
      ...experiment.execution,
      sjAnalysisData: experiment.execution?.sjAnalysisData
        ? experiment.execution.sjAnalysisData.map(analysis => ({
            ...analysis,
            data: typeof analysis.data === 'string' ? JSON.parse(analysis.data) : analysis.data,
          }))
        : [],
      executionSteps: experiment.execution?.executionSteps
        ? experiment.execution.executionSteps.map(step => ({
            ...step,
            calibrationTemperatures:
              typeof step.calibrationTemperatures === 'string'
                ? JSON.parse(step.calibrationTemperatures)
                : step.calibrationTemperatures,
          }))
        : [
            {
              calibrationTemperatures: null,
              logLink: null,
              status: '',
              step: '1',
            },
          ],
    },
  }));

export const BatchExperimentsSection = ({
  batch,
  setBatchStatus = () => {},
  finalizeAndComplete,
  goToFinalization = () => {},
  manageExperimentRoutes,
  isProExecution,
  disableButtons,
}) => {
  const [experiments, setExperiments] = useState([]);
  const [selectedExperiment, setSelectedExperiment] = useState('');
  const [experimentsOptions, setExperimentsOptions] = useState([]);
  const [experimentInfoSubject, setExperimentInfoSubject] = useState(null);
  const [experimentDelay, setExperimentDelay] = useState(null);
  const [selectedExperimentData, setSelectedExperimentData] = useState(null);
  const [batchStartTime, setBatchStartTime] = useState(null);
  const [executionIdToVialNameDeps, setExecutionIdToVialNameDeps] = useState({});
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      if (experimentInfoSubject) {
        experimentInfoSubject.unsubscribe();
      }
    },
    []
  );

  useEffect(() => {
    const startTime = experiments
      ? experiments.reduce((smallestDate, experiment) => {
          if (!experiment?.execution?.startedAt) {
            return smallestDate;
          }

          if (experiment.execution.startedAt && !smallestDate) {
            return experiment.execution.startedAt;
          }

          if (moment(experiment.execution.startedAt).isBefore(moment(smallestDate))) {
            return experiment.execution.startedAt;
          }

          return smallestDate;
        }, null)
      : null;

    setBatchStartTime(startTime);
  }, [experiments]);

  useEffect(() => {
    if (batch?.uuid && batch?.experiments && batch?.experiments?.length) {
      const subject = new WebsocketService(`/batch-execution/${batch?.uuid}/`);
      const configuration = JSON.parse(batch.configuration);
      const delay = configuration?.delays
        ? configuration.delays.find(delay => delay.experiment_uuid === batch?.experiments[0].uuid && delay.step === 0)
            ?.delay
        : null;
      const newExperiments = parseExperiments(batch.experiments);

      setExperimentDelay(delay);
      setExperiments(newExperiments);
      setExperimentInfoSubject(subject);
      setSelectedExperiment(newExperiments[0]?.uuid);
      if (isProExecution) {
        setExecutionIdToVialNameDeps(getMappedInitialData());
      }
      setExperimentData(newExperiments, newExperiments[0]?.uuid);
    }
  }, [batch?.uuid, batch?.experiments]);

  useEffect(() => {
    if (experimentInfoSubject && experimentInfoSubject?.reconnect) {
      experimentInfoSubject.subscribe(msg => updateExperiments(msg));
    }

    return () => {
      if (experimentInfoSubject) {
        experimentInfoSubject.unsubscribe();
      }
    };
  }, [experimentInfoSubject]);

  useEffect(() => {
    if (batch?.experiments) {
      manageExperimentOptions(batch?.experiments);
    }
  }, [batch?.experiments]);

  useEffect(() => {
    setExperimentData(experiments, selectedExperiment);
  }, [selectedExperiment, experiments]);

  const getExperimentOptions = (experiments, ordering) =>
    experiments
      .sort((exp1, exp2) => ordering.indexOf(exp1.uuid) - ordering.indexOf(exp2.uuid))
      .map(experiment => {
        let status = '';
        if (experiment?.execution?.executionSteps) {
          status = experiment.execution.executionSteps
            .sort((step1, step2) => Number(step1.step) - Number(step2.step))
            .reduce((stepsStatus, step) => {
              if (
                step.status === SYNJET_EXPERIMENT_STATUSES.ABORTED.value ||
                stepsStatus === SYNJET_EXPERIMENT_STATUSES.ABORTED.value
              ) {
                return SYNJET_EXPERIMENT_STATUSES.ABORTED.value;
              }

              if (
                step.status === SYNJET_EXPERIMENT_STATUSES.PAUSED.value ||
                stepsStatus === SYNJET_EXPERIMENT_STATUSES.PAUSED.value
              ) {
                return SYNJET_EXPERIMENT_STATUSES.PAUSED.value;
              }

              if (
                stepsStatus === SYNJET_EXPERIMENT_STATUSES.COMPLETED.value &&
                step.status === SYNJET_EXPERIMENT_STATUSES.PENDING.value
              ) {
                return SYNJET_EXPERIMENT_STATUSES.RUNNING.value;
              }

              return stepsStatus === SYNJET_EXPERIMENT_STATUSES.RUNNING.value ? stepsStatus : step.status;
            }, '');
        }
        return {
          uuid: experiment.uuid,
          name: experiment.name,
          status,
        };
      });

  const manageExperimentOptions = experiments => {
    const configuration = JSON.parse(batch.configuration);
    const ordering = configuration.delays
      .filter(delayConf => delayConf.step === 0)
      .sort((delayConf1, delayConf2) => delayConf1.delay - delayConf2.delay)
      .map(delayConf => delayConf.experiment_uuid);

    const options = getExperimentOptions(experiments, ordering);
    setExperimentsOptions(options);
  };

  const getMappedBatchConfiguration = config =>
    config.schedule.reduce((acc, cur) => {
      const id = `${cur.experiment_uuid},${cur.step + 1}`;
      let vials;

      if (id in acc) {
        vials = [...acc[id], cur];
      } else {
        vials = [cur];
      }

      return {
        ...acc,
        [id]: vials,
      };
    }, {});

  const getMappedExecutionData = experimentsData => {
    let result = {};

    experimentsData.forEach(exp => {
      const data = exp.execution.executionSteps.reduce((acc, cur) => {
        if (cur.step === '1_with_quenching') {
          return acc;
        }

        const id = `${exp.uuid},${cur.step[0]}`;
        const offset = cur.vialsOffset;

        return {
          ...acc,
          [id]: [...(id in acc ? acc[id] : []), `${cur.uuid},${offset}`],
        };
      }, {});

      result = {
        ...result,
        ...data,
      };
    });

    return result;
  };

  const getMappedInitialData = () => {
    const fullBatchConfig = JSON.parse(batch.configuration);
    const batchConfig = getMappedBatchConfiguration(fullBatchConfig);
    const executionData = getMappedExecutionData(batch.experiments);
    const unionData = Object.keys(batchConfig).reduce((acc, curId) => {
      const data = batchConfig[curId].reduce((prev, vial, index) => {
        if (!(curId in executionData)) return prev;

        let prepareData = {};

        executionData[curId].forEach(item => {
          const executionId = item?.split(',')?.[0];
          const offset = Number(item?.split(',')?.[1]);

          const vialName = offset
            ? fullBatchConfig?.schedule[vial?.index + offset - 1].name
            : !vial.is_fixed
            ? fullBatchConfig?.schedule[vial?.index - 1].name
            : vial?.name;

          prepareData = {
            ...prepareData,
            [`${executionId},${vial?.index}`]: `${curId},${vialName}`,
          };
        });

        return {
          ...prev,
          ...prepareData,
        };
      }, {});

      return {
        ...acc,
        ...data,
      };
    }, {});
    return unionData;
  };

  const getExpWithCalibrationTemperature = exp =>
    Object.fromEntries(Object.entries(executionIdToVialNameDeps).filter(item => item[1].split(',')?.[0] === exp?.uuid));

  const setExperimentData = (experiments, uuid) => {
    setSelectedExperimentData(experiments.find(experiment => experiment.uuid === uuid));
  };

  const updateExperiments = msg => {
    if (msg.type === SYNJET_SOCKET_TYPES.ANALYSIS_DATA || msg.type === SYNJET_SOCKET_TYPES.BATCH_EXECUTION_UPDATE) {
      const oldBatchStatus = batch.status;
      const batchStatus =
        msg.type === SYNJET_SOCKET_TYPES.BATCH_EXECUTION_UPDATE ? msg.data.batch_status : msg.batch_status;
      const experimentStatuses =
        msg.type === SYNJET_SOCKET_TYPES.BATCH_EXECUTION_UPDATE
          ? msg.data.experiments_statuses
          : msg.experiments_statuses;
      const hasAbortedStep = experimentStatuses
        ? experimentStatuses.some(data => data.step_status === SYNJET_EXPERIMENT_STATUSES.ABORTED.value)
        : false;

      if (oldBatchStatus !== batchStatus) {
        setBatchStatus(batchStatus);
        if (
          batchStatus === BATCH_STATUSES.FINALIZATION.value ||
          batchStatus === BATCH_STATUSES.PENDING_FINALIZATION.value
        ) {
          goToFinalization();
        }
      }

      debouncedGetExperiments();

      if (hasAbortedStep) {
        debouncedStepStatusAbortedHandler();
      }
    }
  };

  const getExperiments = async () => {
    try {
      const newExperiments = await dispatch(getBatchExperiments(batch?.uuid));
      const parsedExperiments = parseExperiments(newExperiments);
      manageExperimentRoutes(newExperiments);
      setExperiments(parsedExperiments);
      manageExperimentOptions(parsedExperiments);
    } catch (e) {
      console.error(e);
    }
  };

  const stepStatusAbortedHandler = () => {
    dispatch(handleBatchAbortedStatus(selectedExperiment));
  };

  const debouncedStepStatusAbortedHandler = debounce(stepStatusAbortedHandler, 1000);
  const debouncedGetExperiments = debounce(getExperiments, 1000);

  const onExperimentChange = uuid => {
    if (experiments) {
      const foundExperiment = experiments.find(experiment => experiment.uuid === uuid);
      setSelectedExperiment(foundExperiment?.uuid);
      const configuration = JSON.parse(batch.configuration);
      if (configuration?.delays) {
        setExperimentDelay(
          configuration.delays.find(delay => delay.experiment_uuid === uuid && delay.step === 0)?.delay
        );
      }
    }
  };

  const getStepStatuses = selectedExp => {
    const executionSteps = selectedExp?.execution?.executionSteps;
    const stepStatuses =
      executionSteps
        ?.sort((step1, step2) => Number(step1?.step[0]) - Number(step2?.step[0]))
        .map(step => step?.status) || [];

    const withQuenching = executionSteps?.some(i => i.step === '1_with_quenching');

    if (String(selectedExp?.process?.variableStep) === '1') {
      const conditionSteps = executionSteps?.filter(step => step.step === '1_with_condition');
      const secondStepStatus = executionSteps?.find(step => step.step === `${withQuenching ? '1_with_quenching' : '2'}`)
        ?.status;

      const isIdentical = conditionSteps?.every((step, _, arr) => step.status === arr[0]?.status);
      const isSomeRunning = conditionSteps?.some(step => step.status === SYNJET_EXPERIMENT_STATUSES.RUNNING.value);
      const isSomePending = conditionSteps?.some(step => step.status === SYNJET_EXPERIMENT_STATUSES.PENDING.value);
      const isPaused = conditionSteps?.some(step => step.status === SYNJET_EXPERIMENT_STATUSES.PAUSED.value);
      const isAborted = stepStatuses.some(i => i === SYNJET_EXPERIMENT_STATUSES.ABORTED.value);

      if (isAborted) {
        return [SYNJET_EXPERIMENT_STATUSES.ABORTED.value, secondStepStatus];
      }

      if (isPaused) {
        return [SYNJET_EXPERIMENT_STATUSES.PAUSED.value, secondStepStatus];
      }

      if (isSomeRunning) {
        return [SYNJET_EXPERIMENT_STATUSES.RUNNING.value, secondStepStatus];
      }

      if (isSomePending) {
        return [SYNJET_EXPERIMENT_STATUSES.PENDING.value, secondStepStatus];
      }

      if (isIdentical) {
        return [conditionSteps[0]?.status, secondStepStatus];
      }
    }

    return stepStatuses;
  };

  const getExperimentStatus = expUuid => {
    const selectedExp = experiments.find(experiment => experiment.uuid === expUuid);

    if (
      selectedExp &&
      JSON.parse(selectedExp?.reactionsInfo)?.process_steps?.length === 1 &&
      !selectedExp.execution.executionSteps.some(i => i.step === '1_with_quenching')
    ) {
      return experimentsOptions.find(item => item.uuid === expUuid)?.status;
    }

    const stepStatuses = getStepStatuses(selectedExp);

    const isPaused = stepStatuses.some(i => i === SYNJET_EXPERIMENT_STATUSES.PAUSED.value);
    const isAborted = stepStatuses.some(i => i === SYNJET_EXPERIMENT_STATUSES.ABORTED.value);
    const isPending = stepStatuses.some(i => i === SYNJET_EXPERIMENT_STATUSES.PENDING.value);
    const isCompleted = stepStatuses.some(i => i === SYNJET_EXPERIMENT_STATUSES.COMPLETED.value);
    const isRunning = stepStatuses.some(i => i === SYNJET_EXPERIMENT_STATUSES.RUNNING.value);

    if (isPaused) {
      return SYNJET_EXPERIMENT_STATUSES.PAUSED.value;
    }

    if (isAborted) {
      return SYNJET_EXPERIMENT_STATUSES.ABORTED.value;
    }

    if ((isRunning && isCompleted) || (isRunning && isPending) || (isCompleted && isPending)) {
      return SYNJET_EXPERIMENT_STATUSES.RUNNING.value;
    }

    return stepStatuses[0];
  };

  return (
    <section className="batch-experiment-section">
      <BatchTabs
        experiments={experimentsOptions}
        selectedExperiment={selectedExperiment}
        onExperimentChange={onExperimentChange}
        getExperimentStatus={getExperimentStatus}
        isProExecution={isProExecution}
      >
        <ExperimentInfo
          experiment={selectedExperimentData}
          executionIdToVialNameDeps={isProExecution ? getExpWithCalibrationTemperature(selectedExperimentData) : null}
          experimentDelay={experimentDelay}
          batchStartTime={batchStartTime}
          finalizeAndComplete={finalizeAndComplete}
          isProExecution={isProExecution}
          stepStatuses={getStepStatuses(selectedExperimentData)}
          disableButtons={disableButtons}
        />
      </BatchTabs>
    </section>
  );
};
