import React, { useState, useEffect } from 'react';
import './style.scss';
import { Popup, Spinner } from '..';
import { WorkSpace } from '../ProcessBuilder/WorkSpace/WorkSpace';
import { ManualWorkArea } from '../ProcessBuilder/WorkSpace/ManualWorkArea';
import { getProcessStructure } from '../../store/autosyn/autosyn.actions';
import { getReactorsTypes } from '../../store/processBuilder/processbuilder.actions';
import { useDispatch, useSelector } from 'react-redux';
import { parseProcessBuilderData } from '../../utils';
import { parseProcessBuilderDataFromBackend } from 'utils/manualProcessBuilder';
import { reactors } from '../../store/processBuilder/processbuilder.selector';
import cn from 'classnames';

export const PopupProcessPreview = ({ open, closePopup, data }) => {
  const [processData, setProcessData] = useState([]);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const dispatch = useDispatch();
  const allReactors = useSelector(reactors);

  const handleCancel = () => {
    closePopup();
  };

  useEffect(() => {
    dispatch(getReactorsTypes());
  }, []);

  useEffect(() => {
    if (data.uuid && allReactors) {
      setLoading(true);
      dispatch(getProcessStructure(data.uuid, true)).then(resp => {
        setLoading(false);
        if (resp.process) {
          if (resp.process.equipments) {
            let manualProcess = parseProcessBuilderDataFromBackend(resp.process, resp.type);
            setIsManual(true);
            setProcessData(manualProcess.parsedData);
            setDescription(manualProcess.description);
          } else setProcessData(parseProcessBuilderData(resp.process, allReactors, resp.type));
        }
      });
    } else setProcessData([]);
  }, [data.uuid, allReactors]);

  const isEmpty = processData.length === 0;

  return (
    <Popup
      title={data.name}
      open={open}
      closable={true}
      className="popup-process-preview"
      textCancle="Close"
      handleCancel={handleCancel}
      loading={false}
    >
      <div className="popup-process-preview_content">
        <span className="popup-process-preview_content_project">Project: {data.project}</span>
        <div
          className={cn('popup-process-preview_workspace-container', {
            empty: isEmpty,
            'work-space-view-mode': isManual,
          })}
        >
          <Spinner loading={loading}>
            {!isEmpty ? (
              isManual ? (
                <div className="view-mode-wrapper">
                  <ManualWorkArea
                    data={processData}
                    description={description}
                    processType={data.type}
                    editMode={false}
                  />
                </div>
              ) : (
                <WorkSpace previewMode data={processData} processType={data.type} />
              )
            ) : loading ? null : (
              <span className="empty-state">No process yet</span>
            )}
          </Spinner>
        </div>
      </div>
    </Popup>
  );
};
