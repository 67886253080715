import './style.scss';

import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setValidationErrors, setValidationWarnings } from 'store/scheduling/scheduling.actions';
import { errors, warnings } from 'store/scheduling/scheduling.selector';
import history from 'utils/history';

import {
  DEVICES,
  EXPERIMENT_STATUSES,
  high_priority,
  low_priority,
  modeExc,
  modeSched,
  new_sub,
  old,
  OPTIONS_SORTER,
  OPTIONS_SORTER_MAINTENANCE,
  statuses_execution_scheduled,
  TYPES_EVENTS,
} from '../../constants';
import { getBatchExperiments, getExperiments, getListMaintenance } from '../../store/experiment/experiment.actions';
import {
  manageBatch,
  manageExperimentOnSchedule,
  manageMaintenanceOnSchedule,
} from '../../store/scheduling/scheduling.actions';
import { checkIntersectionCalendar, filterObj, formatSecondsToHours, getParamCalendar } from '../../utils';
import {
  compareCurrentDateWithDate,
  convertDateTimeZone,
  isoToExecutionViewTimeZone,
  toISOfromDateTime,
  toISOfromDateTimeUTC,
  toISOString,
} from '../../utils/date';
import { RejectExperimentFromBatchPopup } from '../BatchDetails/RejectExperimentFromBatchPopup';
import { Button, closeNotification, openNotification, Popover, Select, Spinner } from '../Common';
import { Sorter } from '../Common/Sorter';
import { Tabs } from '../Common/Tabs';
import { EmptyState } from '../EmptyState/EmptyState';
import { typeOptions } from '../PopupCreateProcess/PopupCreateProcess';
import { CreateEditBatch } from '../Scheduling/ComponentsBatch';
import { MainTenanceCreation } from '../Scheduling/MainTenanceCreation';
import { BatchCard } from './BatchCard/BatchCard';
import { Card } from './Card/Card';
import { CreateEditExperimentPopover } from './CreateEditExperimentPopover';
import { SchedulePopover } from './SchedulePopover/SchedulePopover';

const OPTIONS_TABS = [
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'History', value: 'history' },
];

const ListExperiments = ({
  mode,
  setExperiment,
  experiment,
  calendarApi,
  getExperiments,
  experiments,
  devices,
  device,
  setDevice,
  permissions,
  manageExperimentOnSchedule,
  rejectedExperiment,
  isSynjetDevice,
  isSynjetProDevice,
  manageBatch,
  getBatchExperiments,
  rejectBatch,
  rejectExperimentInQueue,
  batchId,
  getListExperiments,
  setGetListExperiments,
  isLabDevice,
  confirmationDetails,
  setConfirmationDeatails,
  typeEventsList,
  setTypeEventsList,
  sideLoader,
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const user = useSelector(store => store.commonReducer.user);
  const [tabs, setTabs] = useState(OPTIONS_TABS);
  const [tab, setTab] = useState(query.get('tab') ? query.get('tab') : OPTIONS_TABS[0].value);
  const [experimentsOne, setExperimentsOne] = useState([]);
  const [selectedExperimentInfo, setSelectedExperimentInfo] = useState(null);
  const [valueSorter, setValueSorter] = useState(OPTIONS_SORTER[0].value);
  const [loaderListExperiments, setLoaderListExperiments] = useState(false);
  const [defaultKey, setDefaultKey] = useState(1);

  const [specialLoading, setSpecialLoading] = useState(false);

  const dispatch = useDispatch();
  const validationErrors = useSelector(errors);
  const validationWarnings = useSelector(warnings);
  const [allErrorsCount, _setAllErrorsCount] = useState(0);
  const [allWarningsCount, _setAllWarningsCount] = useState(0);

  const listMaintenance = useSelector(state => state.experimentReducer.listMaintenance);

  const allErrorsCountRef = React.useRef(allErrorsCount);
  const setAllErrorsCount = data => {
    allErrorsCountRef.current = data;
    _setAllErrorsCount(data);
  };

  const allWarningsCountRef = React.useRef(allWarningsCount);
  const setAllWarningsCount = data => {
    allWarningsCountRef.current = data;
    _setAllWarningsCount(data);
  };

  const clearErrorsWarnings = () => {
    if (allErrorsCountRef.current > 0 || allWarningsCountRef.current > 0) {
      for (let i = 0; i < allErrorsCountRef.current; i++) {
        closeNotification(`add-experiment-error-notification-${i}`);
      }
      for (let i = 0; i < allWarningsCountRef.current; i++) {
        closeNotification(`add-experiment-warning-notification-${i}`);
      }
      dispatch(setValidationErrors([]));
      dispatch(setValidationWarnings([]));
    }
  };

  const getMaintennances = () => {
    setLoaderListExperiments(true);
    return dispatch(getListMaintenance({ device })).finally(() => setLoaderListExperiments(false));
  };

  useEffect(() => {
    if (device) {
      if (typeEventsList === TYPES_EVENTS[1].value) getMaintennances();
      else getDataExperiments();
      clearErrorsWarnings();
    }
  }, [device, rejectedExperiment]);

  useEffect(() => {
    setLoaderListExperiments(sideLoader);
  }, [sideLoader]);

  useEffect(() => {
    if (typeEventsList === TYPES_EVENTS[1].value && !listMaintenance) {
      getMaintennances();
    }
  }, [typeEventsList]);

  useEffect(
    () => () => {
      clearErrorsWarnings();
    },
    []
  );

  useEffect(() => {
    setDataExperiments(experiments);
  }, [tab]);

  useEffect(() => {
    if (experiments) setDataExperiments(experiments);
    clearErrorsWarnings();
  }, [JSON.stringify(experiments)]);

  useEffect(() => {
    if (getListExperiments) {
      getDataExperiments();
      setGetListExperiments(false);
    }
  }, [getListExperiments]);

  useEffect(() => {
    if (rejectBatch) {
      getDataExperiments(true);
    }
    if (rejectExperimentInQueue) {
      getDataExperiments(false, true);
    }
  }, [rejectBatch, rejectExperimentInQueue]);

  useEffect(() => {
    if (batchId) {
      experimentsOne.forEach((item, i) => {
        if (item.uuid === batchId) {
          setDefaultKey(i + 1);
        }
      });
    }
  }, [batchId, experiment]);

  const getDataExperiments = (isBatch, changeFirstExperiment) => {
    setLoaderListExperiments(true);
    if (mode === modeExc) {
      if (isSynjetDevice || isSynjetProDevice || isBatch || changeFirstExperiment) {
        let d = device;
        if (isBatch) {
          if (isSynjetDevice) {
            d = devices.find(i => i.type === DEVICES.SYNJET).uuid;
          } else {
            d = devices.find(i => i.type === DEVICES.SYNJETPRO).uuid;
          }
          setDevice(d);
        }
        getBatchExperiments({
          device: d,
        })
          .then()
          .finally(() => {
            setLoaderListExperiments(false);
          });
      } else {
        getExperiments({
          status_In: statuses_execution_scheduled.join(),
          device,
          orderBy: 'execution_status,scheduled_at',
        })
          .then(() => {})
          .finally(() => {
            setLoaderListExperiments(false);
          });
      }
      // } else {
      //   if (isSynjetDevice) {
      //     getBatchExperiments({
      //       device,
      //     }).finally(() => {
      //       setLoaderListExperiments(false);
      //     });
    } else {
      getExperiments({
        status_In: EXPERIMENT_STATUSES.Submitted.value,
        compatibleWith: device,
        orderBy: sendOrderSorting(),
      }).finally(() => {
        setLoaderListExperiments(false);
      });
    }
    // }
  };

  useEffect(() => {
    if (validationErrors?.length) {
      setAllErrorsCount(validationErrors.length);
      validationErrors.forEach((err, idx) => {
        openNotification('', err, null, `add-experiment-error-notification-${idx}`);
      });
    }
  }, [validationErrors]);

  useEffect(() => {
    if (validationWarnings?.length) {
      setAllWarningsCount(validationWarnings.length);
      validationWarnings.forEach((warning, idx) => {
        openNotification('', '', null, `add-experiment-warning-notification-${idx}`, null, warning);
      });
    }
  }, [validationWarnings]);

  const sendOrderSorting = () =>
    valueSorter === new_sub
      ? '-submitted_at'
      : valueSorter === high_priority
      ? '-priority'
      : valueSorter === low_priority
      ? 'priority'
      : 'submitted_at';
  const setDataExperiments = resp => {
    if (resp.length) {
      const completeTimeProperty =
        (isSynjetDevice || isSynjetProDevice) && mode === modeExc
          ? 'endAt'
          : isLabDevice && mode === modeExc
          ? 'updatedAt'
          : 'completedAt';
      const scheduledExperiments = resp.filter(
        i => statuses_execution_scheduled.indexOf(i.status) !== -1 && i.status !== EXPERIMENT_STATUSES.Completed.value
      );
      // .sort((a, b) => moment(b[completeTimeProperty]) - moment(a[completeTimeProperty]));

      const historyExperiments = resp
        .filter(i => i.status === EXPERIMENT_STATUSES.Completed.value)
        .sort((a, b) => moment(b[completeTimeProperty]) - moment(a[completeTimeProperty]));
      const submittedExperiments = resp
        .filter(i => i.status === EXPERIMENT_STATUSES.Submitted.value)
        .sort((a, b) => moment(b[completeTimeProperty]) - moment(a[completeTimeProperty]));

      if (mode === modeExc) {
        if (tab === 'scheduled') {
          if (!experiment) {
            if (scheduledExperiments[0]?.timeSlot?.experiments) {
              const batch = scheduledExperiments[0];
              setExperiment(batch?.batchId, 'scheduled', true, batch?.timeSlot?.device?.uuid);
            } else setExperiment(scheduledExperiments[0]?.key, 'scheduled');
          }
          setExperimentsOne(scheduledExperiments);
          setTab(OPTIONS_TABS[0]?.value);
        } else {
          setExperimentsOne(historyExperiments);
        }
        const t = [...tabs];
        t[0].label = `${t[0].label.replace(/ *\([^)]*\) */g, '')} (${scheduledExperiments.length})`;
        t[1].label = `${t[1].label.replace(/ *\([^)]*\) */g, '')} (${historyExperiments.length})`;
        setTabs(t);
      } else {
        setExperimentsOne(submittedExperiments);
      }
      if (rejectBatch) {
        // if batch was rejected open first batch in the list
        setExperiment(resp[0].batchId, 'scheduled', true, resp[0]?.timeSlot?.device?.uuid);
      }
    } else {
      setExperimentsOne([]);
      if (mode === modeExc) {
        const t = [...tabs];
        t[0].label = `${t[0].label.replace(/ *\([^)]*\) */g, '')} (0)`;
        t[1].label = `${t[1].label.replace(/ *\([^)]*\) */g, '')} (0)`;
        setTabs(t);
      }
    }
  };

  const onScheduleExperimentPress = experimentInfo => {
    setSelectedExperimentInfo(experimentInfo);
  };

  const onVisibleChange = visible => {
    if (!visible) {
      setSelectedExperimentInfo(null);
    }
  };

  const onCloseSchedulePopover = () => {
    setSelectedExperimentInfo(null);
  };

  const changeSorting = e => {
    setValueSorter(e);
    if (e === high_priority) {
      setExperimentsOne(
        experimentsOne.sort((a, b) => b.priority - a.priority || moment(b.submittedAt) - moment(a.submittedAt))
      );
    } else if (e === low_priority) {
      setExperimentsOne(
        experimentsOne.sort((a, b) => a.priority - b.priority || moment(b.submittedAt) - moment(a.submittedAt))
      );
    } else if (e === old) {
      setExperimentsOne(experimentsOne.sort((a, b) => moment(a.submittedAt) - moment(b.submittedAt)));
    } else {
      setExperimentsOne(experimentsOne.sort((a, b) => moment(b.submittedAt) - moment(a.submittedAt)));
    }
  };

  const getListHeight = () => {
    const mainHeaderWidth = document.querySelector('.main-header')?.offsetHeight;
    const listHeaderWidth = document.querySelector('.list-experiments-header')?.offsetHeight;
    return `calc(100vh - ${mainHeaderWidth}px - ${listHeaderWidth}px - 40px)`;
  };

  const sendRequest = (data, v, isBatch, modeAddExpToBatch) => {
    const funcThen = () => {
      openNotification('The experiment has been successfully scheduled');
      onCloseSchedulePopover();
      return getDataExperiments();
    };
    const funcFinally = error => {
      setSpecialLoading(false);
      return error;
    };
    setSpecialLoading(true);
    let result = true;
    const validationOnBeSide = mode === 'scheduling';
    let startDate = toISOfromDateTimeUTC(data.date, data.time);
    let endDate = moment.utc(startDate).add(v.totalTime, 'seconds').format();

    if (isBatch && !modeAddExpToBatch) {
      startDate = data.startAt;
      endDate = data.endAt;
    }
    if (!validationOnBeSide) {
      result = checkIntersectionCalendar(
        {
          startDate: convertDateTimeZone(startDate),
          endDate: convertDateTimeZone(endDate),
          uuid: v.uuid,
        },
        calendarApi
      );
    }
    if (result) {
      let requestData = {};
      if (isBatch && !modeAddExpToBatch) {
        requestData = {
          data: {
            experiment: v.uuid,
            timeSlot: {
              startAt: convertDateTimeZone(startDate),
              endAt: convertDateTimeZone(endDate),
              device,
            },
          },
          type: '',
          dataForUpdateList: calendarApi && getParamCalendar(calendarApi, device),
        };
      } else if (modeAddExpToBatch) {
        requestData = {
          data,
          type: 'addExperiment',
          dataForUpdateList: calendarApi && getParamCalendar(calendarApi, device),
        };
      } else {
        requestData = {
          uuid: v.uuid,
          timeSlot: { device, startAt: convertDateTimeZone(startDate), endAt: convertDateTimeZone(endDate) },
        };
      }

      return isBatch
        ? manageBatch(requestData).then(funcThen).finally(funcFinally)
        : manageExperimentOnSchedule(
            requestData,
            'scheduleExperiment',
            calendarApi && getParamCalendar(calendarApi, device)
          )
            .then(funcThen)
            .finally(funcFinally);
    }
    setSpecialLoading(false);
    return Promise.reject('This timeslot is occupied');
  };

  const updateMaintanenca = data => {
    setLoaderListExperiments(true);
    return dispatch(
      manageMaintenanceOnSchedule(
        {
          uuid: data.uuid,
          description: data.comment,
          timeSlot: {
            device,
            startAt: convertDateTimeZone(toISOfromDateTimeUTC(data.date, data.start)),
            endAt: convertDateTimeZone(toISOfromDateTimeUTC(data.date, data.end)),
          },
        },
        'updateMaintenance',
        getParamCalendar(calendarApi, device)
      )
    )
      .then(() => {
        openNotification('Maintenance event was updated');
        return getMaintennances();
      })
      .finally(() => setLoaderListExperiments(false));
  };

  const changeTypeEvents = value => {
    setTypeEventsList(value);
    if (value === TYPES_EVENTS[1].value) {
      setValueSorter(OPTIONS_SORTER_MAINTENANCE[0].value);
    } else {
      setValueSorter(OPTIONS_SORTER[0].value);
    }
  };

  let permissionAction = {};
  if (permissions.scheduling?.create_experiment_scheduling) {
    permissionAction = {
      canScheduleRescheduleExprt: permissions.scheduling.create_experiment_scheduling,
    };
  }

  const showSelect = mode === modeSched;

  const sorterOptions = typeEventsList === TYPES_EVENTS[0].value ? OPTIONS_SORTER : OPTIONS_SORTER_MAINTENANCE;
  const maintenance = useMemo(() => {
    if (valueSorter === OPTIONS_SORTER_MAINTENANCE[1].value && !!listMaintenance) {
      return [...listMaintenance].reverse();
    }
    return listMaintenance;
  }, [valueSorter, listMaintenance]);

  return (
    <div className="list-experiments">
      <div className="list-experiments-header">
        <Select onChange={setDevice} options={devices} value={device} />
        {showSelect && (
          <Select
            classNameContainer="list-experiments-header_types-events"
            onChange={changeTypeEvents}
            options={TYPES_EVENTS}
            value={typeEventsList}
          />
        )}
        {mode === modeSched && (
          <span className="list-experiments_title">Unscheduled experiments ({experimentsOne.length})</span>
        )}
        {mode === modeSched && <Sorter options={sorterOptions} value={valueSorter} onChange={changeSorting} />}
        {mode === modeExc && <Tabs activeKey={tab} options={OPTIONS_TABS} onChange={setTab} />}
      </div>
      <div className="list-block" style={{ height: getListHeight() }}>
        <Spinner loading={loaderListExperiments || specialLoading}>
          <div className={(isSynjetDevice || isSynjetProDevice) && 'batch-padding'}>
            {!!experimentsOne.length && !loaderListExperiments && typeEventsList === TYPES_EVENTS[0].value ? (
              experimentsOne.map((v, k) => {
                const createRequest = (data, isBatch) => sendRequest(data, v, isBatch, !!data.experiment);
                let contentPopoper = (
                  <CreateEditExperimentPopover
                    visible={selectedExperimentInfo?.uuid === v.uuid}
                    loaderExternal={loaderListExperiments}
                    request={createRequest}
                    isLabDevice={isLabDevice}
                    experimentInfo={{ ...v, duration: Math.ceil(v.totalTime / 60) }}
                    onCloseSchedulePopover={onCloseSchedulePopover}
                    title="Schedule experiment"
                  />
                );
                if (isSynjetDevice || isSynjetProDevice || rejectBatch) {
                  contentPopoper = (
                    <CreateEditBatch
                      visible={selectedExperimentInfo?.uuid === v.uuid}
                      dataExperiment={rejectBatch ? experimentsOne[0] : v}
                      device={device}
                      onCloseSchedulePopover={onCloseSchedulePopover}
                      request={createRequest}
                    />
                  );
                }
                return (
                  <SchedulePopover
                    content={contentPopoper}
                    visible={selectedExperimentInfo?.uuid === v.uuid}
                    onVisibleChange={onVisibleChange}
                  >
                    {(isSynjetDevice || isSynjetProDevice) && mode === modeExc ? (
                      <BatchCard
                        key={v.uuid}
                        data={v}
                        index={k}
                        selectedExperiment={experiment}
                        setExperiment={(key, isBatch, device) => setExperiment(key, tab, isBatch, device)}
                        defaultActiveKey={rejectBatch ? 1 : rejectExperimentInQueue ? defaultKey : false}
                      />
                    ) : (
                      <Card
                        key={v.uuid}
                        {...v}
                        data={v}
                        tab={tab}
                        device={device}
                        mode={mode}
                        premissionsActions={permissionAction}
                        active={v.key === experiment}
                        onScheduleExperimentPress={e => {
                          onScheduleExperimentPress(v);
                          e.stopPropagation();
                        }}
                        onClick={() => {
                          setExperiment(v.key, tab);
                        }}
                      />
                    )}
                  </SchedulePopover>
                );
              })
            ) : typeEventsList === TYPES_EVENTS[1].value && maintenance ? (
              maintenance.map(i => (
                <SchedulePopover
                  content={
                    <MainTenanceCreation
                      title="Reschedule maintenance"
                      dataEvent={i}
                      open
                      calendarApi={calendarApi}
                      request={updateMaintanenca}
                      close={onCloseSchedulePopover}
                    />
                  }
                  visible={selectedExperimentInfo?.uuid === i.uuid}
                  onVisibleChange={onVisibleChange}
                >
                  <PreviewMaintenance
                    {...i}
                    permissions={permissions}
                    deleteMainteneance={() => setConfirmationDeatails({ data: { type: 'maintenance', uuid: i.uuid } })}
                    updateMainteneance={() => {
                      setSelectedExperimentInfo(i);
                    }}
                  />
                </SchedulePopover>
              ))
            ) : (
              <div>
                <EmptyState
                  filteringText="There are no experiments on the list
to be executed"
                />
              </div>
            )}
          </div>
        </Spinner>
      </div>
    </div>
  );
};

const PreviewMaintenance = ({
  deleteMainteneance,
  updateMainteneance,
  allDay,
  start,
  end,
  duration,
  description,
  permissions,
}) => {
  const timeDuration = formatSecondsToHours(duration);
  const isPastMaintenance = compareCurrentDateWithDate(end);
  const canDeleteMaintenance = permissions?.scheduling?.delete_maintenance && !isPastMaintenance;
  const canChangeMaintenance = permissions?.scheduling?.change_maintenance && !isPastMaintenance;
  return (
    <div className="preview-maintenance">
      <div className="preview-maintenance_header">
        <span>Maintenance</span>
        <span>{allDay ? 'All day' : `${timeDuration.hour}h ${timeDuration.min}min`}</span>
      </div>
      <div className="preview-maintenance_date">
        <span>
          Scheduled on:
          {isoToExecutionViewTimeZone(start)}
        </span>
      </div>
      {description && (
        <div className="preview-maintenance_desciption">
          <span>Description:</span>
          <span>{description}</span>
        </div>
      )}
      <div className="preview-maintenance_footer">
        {canChangeMaintenance && (
          <Button secondary onClick={updateMainteneance}>
            Update
          </Button>
        )}
        {canDeleteMaintenance && (
          <Button className="preview-maintenance_footer_delete" secondary onClick={deleteMainteneance}>
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  experiments: store.experimentReducer.experiments,
});

export default connect(mapStateToProps, {
  manageExperimentOnSchedule,
  getExperiments,
  manageBatch,
  getBatchExperiments,
})(ListExperiments);
