import React, { useEffect, useState } from 'react';
import './style.scss';
import { Input, openNotification, Popup, Select } from 'components/Common';
import { FILE_TYPES, MAX_FILES_TO_ATTACH_NUMBER, PROJECT_TYPES } from 'constants/common';
import moment from 'moment';
import { AttachedFiles, AttachmentsDropzone, OfflineAnalysisBlock, ReactionData } from '../resultsComponents';
import {
  destroyResultAttachment,
  getExperimentResultPurifications,
  uploadFile,
} from '../../../../store/experiment/experiment.actions';
import { getSynJetCompoundLetter } from '../../../../utils/synjetHelpers';
import { decodeURIComponentSafe } from '../../../../utils';
import { appendedInitialState, initialErrors, initialState } from '../PopupAppendResults';
import { getReactionsForManual } from '../../ReactionTab/getReactionsForManual';
import { parseProcessBuilderDataFromBackend } from '../../../../utils/manualProcessBuilder';
import { getReactionTabTableDataManual } from '../../../../utils/experimentHelpers';

export const AppendResultsForm = ({
  isSynJet,
  data,
  errors,
  setAppendedFiles,
  reactions,
  setReactions,
  setErrors,
  experimantData,
  resultData,
  setData,
  setHasChanges,
  appendedFiles,
  isLabDevice,
}) => {
  const [deleteAttachmentConfirmationOpen, setDeleteAttachmentConfirmationOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [options, setOptions] = useState([]);
  const [actualTime, setActualTime] = useState(0);

  const changeData = (value, type) => {
    setHasChanges(true);
    setErrors({ ...errors, [type]: false });
    if (type === 'purification' && value === 'None') {
      setErrors({ ...errors, purificationComment: false });
    }
    setData({
      ...data,
      [type]: value,
    });
  };

  const getReactionName = reactionIndex => {
    let name = 'Reaction ';
    if (experimantData.process.type !== PROJECT_TYPES.PRODUCTION) {
      name +=
        experimantData.process.type === PROJECT_TYPES.OPTIMIZATION
          ? reactionIndex + 1
          : getSynJetCompoundLetter(reactionIndex, 0);
    }
    return name;
  };

  const calculateSeconds = time => {
    const hours = Math.floor(experimantData.actualTime / 3600);
    let minutes = Math.floor((experimantData.actualTime % 3600) / 60);
    const sec = Math.floor(experimantData.actualTime % 60);
    if (sec > 0) minutes += 1;
    return hours * 3600 + minutes * 60;
  };

  const getAppendedFiles = type =>
    resultData.attachments
      ?.filter(i => i.type === type)
      .map(file => ({
        ...file,
        loaded: true,
        path: file.file,
      })) || [];

  useEffect(() => {
    if (resultData) {
      const { workup, purificationDescription, purificationType, notes } = resultData;
      setData({
        workup: (isSynJet ? notes : workup) ? decodeURIComponentSafe(isSynJet ? notes : workup) : '',
        purification: purificationType,
        purificationComment: purificationDescription ? decodeURIComponentSafe(purificationDescription) : '',
      });
      setAppendedFiles({
        lcms: getAppendedFiles('LC-MS'),
        _13cnmr: getAppendedFiles('13C NMR'),
        _1hnmr: getAppendedFiles('1H NMR'),
        'Offline LC-MS': getAppendedFiles('Offline LC-MS'),
        'Mass spectrum': getAppendedFiles('Mass spectrum'),
        'UV-vis': getAppendedFiles('UV-vis'),
        purification: getAppendedFiles(null),
      });
      if (
        resultData.productsInfo &&
        JSON.parse(resultData.productsInfo) &&
        Array.isArray(JSON.parse(resultData.productsInfo))
      ) {
        setReactions(
          JSON.parse(resultData.productsInfo).map((product, productIndex) => ({
            name: getReactionName(productIndex),
            mass: product.mass,
            product_yield: product.product_yield,
            start_collection: moment(product.start_collection, 'hh:mm'),
            end_collection: moment(product.end_collection, 'hh:mm'),
            compound: product.compound,
          }))
        );
      }
    } else if (!resultData) {
      setData({ ...initialState });
      setErrors({ ...initialErrors });
      setAppendedFiles({ ...appendedInitialState });
      //setReactions([]);
    }
  }, [resultData]);

  useEffect(() => {
    getExperimentResultPurifications().then(resp => {
      setOptions([...resp.map(option => ({ value: option, label: option }))]);
    });
  }, []);

  useEffect(() => {
    if (experimantData?.actualTime) {
      const _actualTime = calculateSeconds(experimantData.actualTime);
      setActualTime(_actualTime);
    }
  }, [experimantData]);

  useEffect(() => {
    if (experimantData?.reactionsInfo && !resultData?.uuid) {
      const _actualTime = calculateSeconds(experimantData.actualTime);
      let infoReactions = JSON.parse(experimantData?.reactionsInfo);
      if (isLabDevice || experimantData?.timeSlot?.device?.type === 'Lab') {
        let { parsedData } = parseProcessBuilderDataFromBackend(
          experimantData?.process?.definition,
          experimantData.process?.type
        );
        infoReactions = getReactionsForManual(parsedData, experimantData?.process?.type, experimantData?.reactionsInfo);
        infoReactions = {
          reactions: addCompoundsId(getReactionTabTableDataManual(infoReactions)),
          compounds: JSON.parse(experimantData?.reactionsInfo).compounds,
        };
      }
      if (infoReactions.reactions) {
        setReactions(
          [...infoReactions.reactions].map((reaction, reactionIndex) => {
            return {
              name: getReactionName(reactionIndex),
              mass: null,
              product_yield: null,
              start_collection: moment().startOf('day'),
              end_collection: moment().startOf('day').seconds(_actualTime),
              compound: infoReactions.compounds.find(cpd => cpd.compound_id === reaction.compound_id),
            };
          })
        );
      }
    }
  }, [experimantData]);

  const addCompoundsId = reactions => {
    reactions = reactions.map(reaction => {
      let id = null;
      reaction = reaction.map((row, rowIdx) => {
        if (row.chemical_type === 'Product') {
          id = row.compound.compound_id;
        }
      });
      return { compound_id: id };
    });
    return reactions;
  };

  const onAppendFile = (file, type) => {
    setAppendedFiles(prevFiles => {
      const allFiles = [...prevFiles[type], { ...file, file: file.path, loaded: false }];

      return {
        ...prevFiles,
        [type]: [...allFiles],
      };
    });
    const data = new FormData();
    data.append('file', file, file.name);
    if (FILE_TYPES[type]) data.append('type', FILE_TYPES[type]);
    uploadFile(data).then(resp => {
      if (resp.uuid) {
        setAppendedFiles(prevFiles => {
          const fileIndex = prevFiles[type].length - 1;
          return {
            ...prevFiles,
            [type]: prevFiles[type].map((file, idx) => {
              if (fileIndex === idx) {
                const fileUrl = new URL(resp.file);
                return {
                  ...file,
                  file: fileUrl.pathname.substring(1),
                  loaded: true,
                  uuid: resp.uuid,
                };
              }
              return file;
            }),
          };
        });
      }
    });
  };

  const onLoadFile = (file, type) => {};

  const onDeleteAttachment = (file, idx, type) => {
    setFileToDelete({ file, idx, type });
    setDeleteAttachmentConfirmationOpen(true);
  };

  const handleSubmitDeleteAttachment = () => {
    const { idx, type } = fileToDelete;
    const files = { ...appendedFiles };
    destroyResultAttachment(files[type][idx].uuid)
      .then(resp => {
        if (resp.errors) {
          openNotification('Error');
        }
        files[type].splice(idx, 1);
        setAppendedFiles(files);
        setDeleteAttachmentConfirmationOpen(false);
      })
      .catch(err => {
        openNotification('Error');
      });
  };

  return (
    <>
      <div className="append-results__content">
        <div className="append-results__content__top-block">
          <div className="append-results__content__top-block__content">
            <div className="append-results__content__title">{isSynJet ? 'Notes' : 'Workup'}</div>
            <Input
              autoFocus
              allowSpecials
              withoutComma={false}
              type="textarea"
              maxLength="1000"
              placeholder={isSynJet ? 'Please provide experiment notes here' : 'Please provide the workup details'}
              value={data.workup}
              onChange={value => changeData(value, 'workup')}
              className="workup-details"
            />
            {!isSynJet && (
              <>
                <div className="append-results__content__title">Purification</div>
                <Select
                  onChange={value => changeData(value, 'purification')}
                  options={options}
                  value={data.purification || 'None'}
                />
                <Input
                  allowSpecials
                  withoutComma={false}
                  type="textarea"
                  maxLength="1000"
                  placeholder="Please provide the purification details"
                  value={data.purificationComment}
                  onChange={value => changeData(value, 'purificationComment')}
                  error={errors?.purificationComment}
                  errorText="Please provide the Purification details"
                  className="purification-details"
                />
                <AttachedFiles
                  onDelete={onDeleteAttachment}
                  appendedFiles={appendedFiles.purification}
                  type="purification"
                />
                <AttachmentsDropzone
                  onAppendFile={onAppendFile}
                  onLoadFile={onLoadFile}
                  disabled={appendedFiles.purification.length >= MAX_FILES_TO_ATTACH_NUMBER}
                  type="purification"
                />
              </>
            )}
          </div>
          <div className="append-results__content__top-block__content">
            <div className="append-results__content__title">Offline analysis</div>
            <OfflineAnalysisBlock
              title={isSynJet ? 'Offline LC-MS' : '1H NMR'}
              onDeleteAttachment={onDeleteAttachment}
              appendedFiles={isSynJet ? appendedFiles['Offline LC-MS'] : appendedFiles._1hnmr}
              type={isSynJet ? 'Offline LC-MS' : '_1hnmr'}
              onAppendFile={onAppendFile}
              onLoadFile={onLoadFile}
            />
            <OfflineAnalysisBlock
              title={isSynJet ? 'Mass spectrum' : '13C NMR'}
              onDeleteAttachment={onDeleteAttachment}
              appendedFiles={isSynJet ? appendedFiles['Mass spectrum'] : appendedFiles._13cnmr}
              type={isSynJet ? 'Mass spectrum' : '_13cnmr'}
              onAppendFile={onAppendFile}
              onLoadFile={onLoadFile}
            />
            <OfflineAnalysisBlock
              title={isSynJet ? 'UV-vis' : 'LC-MS'}
              onDeleteAttachment={onDeleteAttachment}
              appendedFiles={isSynJet ? appendedFiles['UV-vis'] : appendedFiles.lcms}
              type={isSynJet ? 'UV-vis' : 'lcms'}
              onAppendFile={onAppendFile}
              onLoadFile={onLoadFile}
            />
          </div>
        </div>
        {!isSynJet && (
          <div className="append-results__content__bottom-block">
            <div className="append-results__content__title">Product masses / yields</div>
            <ReactionData
              reactions={reactions}
              setReactions={setReactions}
              setErrors={setErrors}
              errors={errors}
              actualTime={actualTime}
              showTime={!isLabDevice && experimantData.status === 'Completed'}
            />
          </div>
        )}
      </div>

      <Popup
        title=" "
        open={deleteAttachmentConfirmationOpen}
        handleSubmit={handleSubmitDeleteAttachment}
        textSubmit="Delete"
        textCancle="Cancel"
        handleCancel={() => setDeleteAttachmentConfirmationOpen(false)}
      >
        Do you want to proceed and delete the attachment?
      </Popup>
    </>
  );
};
