import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import ReactDOM from 'react-dom';
import { RangeSlider } from '../../Common/RangeSlider';
import { Chart } from '../Chart';
import { getDiff, getDiffChart, getHHmmFromSec } from '../../../utils/date';
import { Slider } from 'antd';
import moment from 'moment';
import {
  getAnalyticsChartsData,
  getConfigurationUnivariateCharts,
  getDataUnivariateCharts,
} from '../../../store/experiment/experiment.actions';
import { WebsocketService } from '../../../utils/service/WebSocketService';
import { configHARD, dataHARD } from '../ExperimentProgress/ExperimentSummary';
import { Spinner } from '../../Common/Spinner';
import { colorsCharts } from '../../../constants';

export const processData = (data, startPoint, key = 'str_datetime') => {
  return data.map(i => {
    const timeSec = getDiffChart(startPoint.datetime, i[key]);
    const timeTrunc = Math.trunc(timeSec / 60);
    const timeExtraSec = timeSec - timeTrunc * 60;
    const proportionExtraSecFromMin = Math.trunc((timeExtraSec * 100) / 60);
    return {
      timeInSec: timeSec,
      time: +`${timeTrunc}.${
        proportionExtraSecFromMin < 10 ? '0' + proportionExtraSecFromMin : proportionExtraSecFromMin
      }`,
      name: i.label,
      extraOptions: {
        colorLine: undefined,
        name: i.label,
        sensor_id: i.label,
        typeLine: 'line',
        x: 'time',
        y: 'value',
      },
      value: i.value,
    };
  });
};

export class AnalyticsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueRange: [0, 0],
      configChart: [],
      dataChart: null,
      filteredData: null,
      updateDataChart: false,
      subject: null,
      startPoint: null,
      loading: true,
    };

    this.timer = 0;
  }

  componentWillUnmount() {
    if (this.state.subject) {
      clearTimeout(this.timer);
      this.state.subject.unsubscribe();
    }
  }

  componentDidMount() {
    getAnalyticsChartsData({
      uuid: this.props.experiment?.uuid,
    }).then(dataChart => {
      const firstPoint = { datetime: moment.utc(this.props.experiment.execution.startedAt).format() };
      const data = processData(dataChart, firstPoint);
      ReactDOM.unstable_batchedUpdates(() => {
        const newData = {
          configChart: this.props.experiment?.analyticalSetting?.map((i, idx) => ({
            colorLine: colorsCharts[idx],
            name: i.label,
            sensor_id: i.label,
            typeLine: 'line',
            x: 'time',
            y: 'value',
          })),
          valueRange: [0, this.getLastTime(data)],
          loading: false,
          dataChart: data,
          filteredData: data,
          startPoint: firstPoint,
        };
        if (!this.props.viewMode) {
          const subject = new WebsocketService(`/experiment-execution/${this.props.experiment?.uuid}/`);
          newData.subject = subject;
          subject.subscribe(this.addNewPoint);
        }

        this.setTimerUpdateData();
        this.updateState(newData);
      });
    });
  }

  setTimerUpdateData = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.updateState({ updateDataChart: true });
    }, 10000);
  };

  handleRangeSlider = value => {
    let newValue = [...value];
    const maxSec = this.getLastTime(this.state.dataChart);
    if (maxSec < newValue[1]) newValue = [newValue[0], maxSec];
    const newData = {
      filteredData: this.state.dataChart?.filter(i => i.timeInSec >= newValue[0] && i.timeInSec <= newValue[1]),
      valueRange: newValue,
    };
    this.updateState(newData);
  };

  addNewPoint = point => {
    if (point.type !== 'analysis_data' || !this.state.updateDataChart) return;
    let newData = processData(
      point.data.map(i => ({ ...i, str_datetime: point.datetime })),
      this.state.startPoint
    );
    ReactDOM.unstable_batchedUpdates(() => {
      const newDataState = {};
      const timeLastPoint = this.getLastTime(this.state.dataChart);
      if (timeLastPoint === this.state.valueRange[1]) {
        newDataState.filteredData = [...this.state.filteredData, ...newData];
        newDataState.valueRange = [this.state.valueRange[0], this.getLastTime(newData)];
      }
      newDataState.dataChart = [...this.state.dataChart, ...newData];
      newDataState.updateDataChart = false;
      this.setTimerUpdateData();
      this.updateState(newDataState);
    });
  };

  getLastTime = data => {
    if (!data) return;
    return data[data.length - 1]?.timeInSec;
  };

  updateState = data => {
    this.setState({
      ...data,
    });
  };

  render() {
    const { valueRange, loading, dataChart, configChart, filteredData } = this.state;

    const lastPoint = this.getLastTime(dataChart);
    return (
      <>
        <span className="charts-scale">Charts scale</span>
        <div className="univariate-tab" id="analytics">
          <Spinner loading={loading}>
            <RangeSlider
              customId="analytics"
              value={valueRange}
              onChange={this.handleRangeSlider}
              date
              noMarks
              max={lastPoint + 60}
              tipFormatter={value => {
                return <div>{getHHmmFromSec(value)}</div>;
              }}
              tooltipPlacement="bottom"
              tooltipVisible
            />
            {!!dataChart && (
              <Chart
                analytics
                useMin={valueRange[0] !== 0}
                axisYName="Intensity"
                endPoint={dataChart[dataChart.length - 1]}
                dataConfig={configChart}
                data={filteredData || []}
                label="LC-MS"
              />
            )}
          </Spinner>
        </div>
      </>
    );
  }
}
