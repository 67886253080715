import React, { useEffect, useState } from 'react';
import { Button } from 'components/Common';
import emptyImage from 'dist/images/no-experiment-results.svg';
import { SJBatchConfigurationTable } from './SJBatchConfigurationTable';

const EmptyState = ({ generate }) => (
  <div className="sj-batch-configuration-block__table__empty-state">
    <img className="sj-batch-configuration-block__table__empty-state__image" src={emptyImage} />
    <div className="sj-batch-configuration-block__table__empty-state__title">Nothing here yet</div>
    <div className="sj-batch-configuration-block__table__empty-state__subtitle">
      Batch execution sequence will be calculated after Generate is triggered
    </div>
    <Button secondary onClick={generate}>
      Generate
    </Button>
  </div>
);

export const SJBatchConfigurationTableBlock = ({ generate, data, loading }) => {
  const isEmpty = !data.length;
  return (
    <div className="sj-batch-configuration-block__table">
      <div className="sj-batch-configuration-block__table__title">Batch configuration</div>
      <div className="sj-batch-configuration-block__table__content">
        {isEmpty ? <EmptyState generate={generate} /> : <SJBatchConfigurationTable data={data} />}
      </div>
    </div>
  );
};
