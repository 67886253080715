import React from 'react';
import logo from '../../../dist/images/PdfHeaderLogo.svg';
import './styles.scss';

export const PageHeader = () => (
  <div data-protected className="page-header">
    <img className="title" src={logo} />
    <span className="page-header_pages">Page 1 of 10</span>
  </div>
);
