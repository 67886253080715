import * as actionTypes from './scheduling.actionTypes';
import { filterObj, http } from '../../utils';
import {
  mutationAddExperimentToBatch,
  mutationCreateBatch,
  mutationDeleteExperimentFromBatch,
  mutationFinishMaterialsPreparation,
  mutationManageExperimentOnSchedule,
  mutationManageMaintenanceOnSchedule,
  mutationUnBatch,
  mutationUpdateBatch,
  mutationStartPreparation,
  mutationUpdatePumpBottle,
  mutationUpdatePumpMaterial,
  mutationUpdateReactorMaterial,
  queryAllBatchesForAssignExpt,
  queryDetailsCurrentUser,
  queryGetUserByRoles,
  queryListEventsScheduling,
  queryBatchDetails,
  mutationCalculateVialsSchedule,
  mutationSaveVialsSchedule,
  mutationUpdateBatchDispenser,
  mutationMoveToBatch,
  mutationUpdateExperimentConditions,
  queryListCheckboxesThirdStepWizard,
  mutationLaunchBatch,
  queryListCheckboxesLoadMaterials,
  mutationRecalculateDispensers,
  mutationSJPUpdateExperimentConditions,
} from '../graphql';
import { openNotification } from '../../components/Common';

const setListEvents = data => ({ type: actionTypes.SET_LIST_EVENTS, data });

export const setValidationErrors = data => ({ type: actionTypes.SET_VALIDATION_ERRORS_ADD_EXPERIMENT_TO_BATCH, data });
export const setValidationWarnings = data => ({
  type: actionTypes.SET_VALIDATION_WARNINGS_ADD_EXPERIMENT_TO_BATCH,
  data,
});

export const setPotentialBatchForExperiment = data => ({
  type: actionTypes.SET_POTENTIAL_BATCH_FOR_EXPERIMETN,
  data,
});

export const getListEvents = data => dispatch =>
  http(queryListEventsScheduling('send', data)).then(resp => {
    const data = queryListEventsScheduling('get', resp);
    dispatch(setListEvents(data));
    return data;
  });

export const getListCheckboxesStepThirdWizard = () =>
  http(queryListCheckboxesThirdStepWizard('send')).then(resp => {
    const proccedData = queryListCheckboxesThirdStepWizard('get', resp);
    return proccedData;
  });

export const getListCheckboxesLoadMaterials = () =>
  http(queryListCheckboxesLoadMaterials('send')).then(resp => {
    const proccedData = queryListCheckboxesLoadMaterials('get', resp);
    return proccedData;
  });

export const manageBatch = ({ data, type, dataForUpdateList }) => dispatch => {
  let mutationQuery = mutationCreateBatch;
  if (type === 'addExperiment') {
    dispatch(setValidationErrors([]));
    dispatch(setValidationWarnings([]));
    mutationQuery = mutationAddExperimentToBatch;
  } else if (type === 'getBatchForAssign') mutationQuery = queryAllBatchesForAssignExpt;
  else if (type === 'deleteExperiment') mutationQuery = mutationDeleteExperimentFromBatch;
  else if (type === 'updateBatch') mutationQuery = mutationUpdateBatch;
  else if (type === 'unscheduleBatch') mutationQuery = mutationUnBatch;
  else if (type === 'getBatchDetails') mutationQuery = queryBatchDetails;
  else if (type === 'moveToBatch') mutationQuery = mutationMoveToBatch;
  return http(mutationQuery('send', data)).then(resp => {
    const proccedData = mutationQuery('get', resp);
    if (proccedData?.warnings) {
      if (type === 'addExperiment') {
        let warn = [];
        proccedData.warnings.map(e => {
          if (e.messages.length) warn = [...warn, ...e.messages];
        });
        dispatch(setValidationWarnings(warn));
      }
    }
    if (proccedData?.errors) {
      if (type === 'addExperiment') {
        let err = [];
        if (proccedData?.errors?.forEach) {
          proccedData.errors.forEach(e => {
            if (e.messages.length) err = [...err, ...e.messages];
          });
        }
        dispatch(setValidationErrors(err));
      }
      throw proccedData.errors;
    } else {
      dataForUpdateList && dispatch(getListEvents(dataForUpdateList));
    }
    return proccedData;
  });
};

export const recalculateDispensers = data => dispatch =>
  http(mutationRecalculateDispensers('send', data)).then(resp => mutationRecalculateDispensers('get', resp));

export const manageExperimentOnSchedule = (data, type, dataForUpdateList) => dispatch =>
  http(mutationManageExperimentOnSchedule('send', data, type)).then(resp => {
    const processedData = mutationManageExperimentOnSchedule('get', resp, type);
    if (processedData.uuid) {
      dataForUpdateList && dispatch(getListEvents(dataForUpdateList));
      return processedData.uuid;
    }
    if (Array.isArray(processedData.errors)) {
      processedData.errors.forEach(i =>
        i?.messages?.forEach(j => {
          openNotification('', j);
        })
      );
      throw 'error';
    }
  });

export const manageMaintenanceOnSchedule = (data, type, dataForUpdateList) => dispatch =>
  http(mutationManageMaintenanceOnSchedule('send', { ...filterObj(data), description: data.description }, type)).then(
    resp => {
      const processedData = mutationManageMaintenanceOnSchedule('get', resp, type);
      if (!processedData.errors) {
        dispatch(getListEvents(dataForUpdateList));
        return processedData.uuid;
      }
      throw 'error';
    }
  );

export const manageBatchStartPreparation = batchID => dispatch =>
  http(mutationStartPreparation(batchID)).then(resp => {
    const { batchStartPreparation } = resp;
    if (batchStartPreparation.errors) {
      return Promise.reject(batchStartPreparation.errors);
    }
    return Promise.resolve(batchStartPreparation);
  });

export const calculateVialsSchedule = data => dispatch =>
  http(mutationCalculateVialsSchedule('send', data)).then(resp => mutationCalculateVialsSchedule('get', resp));

export const saveVialsSchedule = data => dispatch =>
  http(mutationSaveVialsSchedule('send', data)).then(resp => mutationSaveVialsSchedule('get', resp));

export const launchBatch = data => dispatch =>
  http(mutationLaunchBatch('send', data)).then(resp => {
    const processData = mutationLaunchBatch('get', resp);
    if (processData.errors) {
      openNotification('', processData.errors[0]?.messages[0]);
      throw processData.errors.messages;
    } else {
      return processData;
    }
  });

export const updateBatchDispensers = data => dispatch =>
  http(mutationUpdateBatchDispenser(data)).then(
    resp => {
      const response = data.isPro ? resp?.updateSjpBatchDispenser : resp?.updateBatchDispenser;
      if (response) {
        const { errors } = response;
        if (errors) {
          return Promise.reject(JSON.parse(errors));
        }
        return Promise.resolve(resp);
      }
    },
    e => Promise.reject('Error while updating dispenser')
  );

export const updateExperimentConditions = data => dispatch =>
  http(mutationUpdateExperimentConditions(data)).then(
    resp => {
      if (resp?.updateExperimentConditions) {
        const { errors } = resp?.updateExperimentConditions;
        if (errors) {
          return Promise.reject(JSON.parse(errors));
        }
        return Promise.resolve(resp?.updateExperimentConditions);
      }
    },
    e => Promise.reject('Error while updating experiment conditions')
  );

export const updateSJPExperimentConditions = data => dispatch =>
  http(mutationSJPUpdateExperimentConditions(data)).then(
    resp => {
      if (resp?.updateSjpExperimentConditions) {
        const { errors } = resp?.updateSjpExperimentConditions;
        if (errors) {
          return Promise.reject(JSON.parse(errors));
        }
        return Promise.resolve(resp?.updateSjpExperimentConditions);
      }
    },
    e => Promise.reject('Error while updating experiment conditions')
  );
