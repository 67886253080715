import React, { Component } from 'react';
import cn from 'classnames';

export const Molecule = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill={props.color || '#46C5E9'} />
      <circle cx="24" cy="15" r="6" stroke="white" strokeWidth="2" />
      <path d="M27 15C27 13.3431 25.6569 12 24 12" stroke="white" strokeLinecap="round" />
      <path d="M36 32C36 30.3431 34.6569 29 33 29" stroke="white" strokeLinecap="round" />
      <circle cx="15" cy="32" r="6" stroke="white" strokeWidth="2" />
      <circle cx="33" cy="32" r="6" stroke="white" strokeWidth="2" />
      <path d="M24 25L24 20.5" stroke="white" strokeWidth="2" />
      <path d="M20 28L24.0833 25L28.5 28" stroke="white" strokeWidth="2" />
      <path d="M18 32C18 30.3431 16.6569 29 15 29" stroke="white" strokeLinecap="round" />
    </svg>
  </div>
);
