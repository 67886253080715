import React from 'react';
import './style.scss';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from '../..';
import cn from 'classnames';

export const Sidebar = ({
  width,
  open,
  children,
  className = '',
  title,
  onClose,
  onSave,
  saveButtonText = 'Save',
  onDelete,
  disableSave = false,
  editingData,
  showFooter = true,
  cancelText = 'Close',
}) => {
  return (
    <>
      <div className={cn('sidebar-wrapper', className, { 'sidebar-close': !open })} style={{ width: width }}>
        <div className="main-title">
          <div className="title">{title}</div>
          <CloseOutlined onClick={onClose} />
        </div>
        <div className="sidebar-wrapper-content">{children}</div>
        {showFooter && (
          <div className="footer-sidebar">
            {editingData ? (
              <Button secondary onClick={onDelete}>
                <DeleteOutlined /> Delete
              </Button>
            ) : (
              <div></div>
            )}
            <div>
              <Button onClick={onClose} secondary className="cancel">
                {cancelText}
              </Button>
              <Button onClick={onSave} disabled={disableSave}>
                {saveButtonText}
              </Button>
            </div>
          </div>
        )}
      </div>
      {open && <div className="background"></div>}
    </>
  );
};
