import React from 'react';
import { Pagination as PaginationAnt } from 'antd';

export const Pagination = ({ onChange, allPages = 27, defaultPageSize = 12, current = 2, ...rest }) => {
  return (
    <PaginationAnt
      defaultPageSize={defaultPageSize}
      onChange={onChange}
      current={+current}
      total={allPages}
      showSizeChanger={false}
      {...rest}
    />
  );
};
