import React, { useEffect, useState, useRef } from 'react';
import { WorkSpace } from '../../ProcessBuilder/WorkSpace/WorkSpace';
import { parseProcessBuilderData } from '../../../utils/parseProcessBuilderData';
import { WebsocketService } from 'utils/service/WebSocketService';
import {
  getProcessDeviceStatus,
  getConfigurationUnivariateCharts,
  getDataUnivariateCharts,
} from 'store/experiment/experiment.actions';
import { useDispatch } from 'react-redux';
import { Spinner } from 'components/Common';

export const ExperimentProcess = ({ data, allReactors, processType, systemName, experiment }) => {
  const dispatch = useDispatch();
  const [builderData, _setBuilderData] = useState([]);
  const [subject, setSubject] = useState(null);
  const [subscribtion, setSubscribtion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [configCharts, setConfigCharts] = useState(null);

  const builderDataRef = React.useRef(builderData);
  const setBuilderData = data => {
    builderDataRef.current = data;
    _setBuilderData(data);
  };

  const getSensorVal = (initialData, item, key) =>
    Array.isArray(initialData)
      ? initialData.reverse().find(initialDataItem => initialDataItem.sensor_id === item[key])?.value
      : null;

  const getSensorsId = data =>
    data
      .filter(
        i =>
          [
            'Reactors Temperature',
            'Pump Set Flow Rate',
            'Gas Pump Flow Rate',
            'Pump Actual Flow Rate',
            'Pump and System Pressure',
          ].indexOf(i.label) > -1
      )
      .map(i => i.lines.map(i => i.sensor_id).join(','))
      .filter(i => i)
      .join(',');

  useEffect(() => {
    if (experiment?.uuid) {
      let processDefinition = JSON.parse(data);
      let pumpKeys = JSON.parse(experiment?.pumpKeys);
      let proc = parseProcessBuilderData(processDefinition, allReactors, processType);
      if (subscribtion) subscribtion.unsubscribe();
      const newSubscribtion = new WebsocketService(`/experiment-execution/${experiment?.uuid}/`);
      setSubscribtion(newSubscribtion);

      setLoading(true);
      dispatch(getProcessDeviceStatus(experiment?.timeSlot?.device?.uuid, experiment.timeSlot.device.name)).then(
        resp => {
          getConfigurationUnivariateCharts({ uuid: experiment?.uuid }).then(config => {
            setConfigCharts(config);
            getDataUnivariateCharts({
              uuid: experiment?.uuid,
              sensorIds: getSensorsId(config),
            })
              .then(initialData => {
                if (!initialData.errors)
                  proc.map(reactor => {
                    if (resp.reactors.find(i => i.key === reactor.key && i.online)) reactor.working = true;
                    // find sensor key in config
                    config.forEach(configItem => {
                      if (configItem.label === 'Reactors Temperature') {
                        configItem.lines.forEach(line => {
                          let keys = line.label.split(' ');
                          if (keys[0] === reactor.key)
                            keys[1]?.toLowerCase() === 'set'
                              ? (reactor.setTemperatureKey = line.sensor_id)
                              : (reactor.actualTemperatureKey = line.sensor_id);
                        });
                      }
                    });
                    // set initial data
                    if (reactor.setTemperatureKey)
                      reactor.setTemperature = getSensorVal(initialData, reactor, 'setTemperatureKey');
                    if (reactor.actualTemperatureKey)
                      reactor.actualTemperature = getSensorVal(initialData, reactor, 'actualTemperatureKey');

                    reactor.pumps = reactor.pumps.map(pump => {
                      pump.key = pumpKeys.find(i => i.name === pump.name)?.key;
                      // find sensor key in config
                      config.forEach(configItem => {
                        if (configItem.label === 'Pump Set Flow Rate' || 'Gas Pump Flow Rate') {
                          configItem.lines.forEach(line => {
                            if (line.label.split(' ')[0] === pump.key) pump.setFRkey = line.sensor_id;
                          });
                        }
                        if (configItem.label === 'Pump Actual Flow Rate') {
                          configItem.lines.forEach(line => {
                            if (line.label === pump.key) pump.actualFRkey = line.sensor_id;
                          });
                        }
                        if (configItem.label === 'Pump and System Pressure') {
                          configItem.lines.forEach(line => {
                            if (line.label === pump.key) pump.currentPressureKey = line.sensor_id;
                          });
                        }
                      });

                      // set initial data
                      if (pump.setFRkey) pump.sq = getSensorVal(initialData, pump, 'setFRkey');
                      if (pump.actualFRkey) pump.aq = getSensorVal(initialData, pump, 'actualFRkey');
                      if (pump.currentPressureKey) pump.cp = getSensorVal(initialData, pump, 'currentPressureKey');
                      if (resp.pumps.find(i => i.online && pump.key === i.key)) pump.working = true;
                      return pump;
                    });
                    return reactor;
                  });
                setBuilderData(proc);
              })
              .catch(err => {
                setBuilderData(proc);
              })
              .finally(() => {
                setLoading(false);
              });
          });
        }
      );
    }
  }, [experiment?.uuid]);

  useEffect(() => {
    return () => {
      if (subscribtion) subscribtion.unsubscribe();
      setSubscribtion(null);
      if (subject) subject.unsubscribe();
      setSubject(null);
    };
  }, []);

  useEffect(() => {
    if (subject) subject.unsubscribe();
    const newSubject = new WebsocketService(`/system/${systemName}/controllables-states/`);
    setSubject(newSubject);
  }, [systemName]);

  useEffect(() => {
    if (subject) subject.subscribe(changeDeviceState);
  }, [subject]);

  useEffect(() => {
    if (subscribtion) subscribtion.subscribe(getDeviseParams);
  }, [subscribtion]);

  const changeDeviceState = msg => {
    if (msg.type === 'device_controllables_states') {
      setBuilderData(
        [...builderDataRef.current].map(reactor => {
          if (msg.controllable_key === reactor.key) reactor.working = msg.online;
          reactor.pumps = reactor.pumps.map(pump => {
            if (pump.key === msg.controllable_key) pump.working = msg.online;
            return pump;
          });
          return reactor;
        })
      );
    }
  };

  const getDeviseParams = msg => {
    if (msg.type === 'univariate_data') {
      let newBuilderData = [...builderDataRef.current];
      msg.data.forEach(dataItem => {
        newBuilderData = newBuilderData.map(reactor => {
          if (reactor.setTemperatureKey === dataItem.sensor_id) reactor.setTemperature = dataItem.value;
          else if (reactor.actualTemperatureKey === dataItem.sensor_id) reactor.actualTemperature = dataItem.value;
          reactor.pumps = reactor.pumps.map(pump => {
            if (pump.currentPressureKey === dataItem.sensor_id) pump.cp = dataItem.value;
            if (pump.setFRkey === dataItem.sensor_id) pump.sq = dataItem.value;
            else if (pump.actualFRkey === dataItem.sensor_id) pump.aq = dataItem.value;
            return pump;
          });
          return reactor;
        });
      });
      setBuilderData(newBuilderData);
    }
  };

  return (
    <div className="process-wrapper">
      <Spinner loading={loading}>
        <WorkSpace data={builderData} previewMode={true} realTime experiment={experiment} configCharts={configCharts} />
      </Spinner>
    </div>
  );
};
