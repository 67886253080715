import { LockOutlined } from '@ant-design/icons';
import React from 'react';
import { checkIfMyLock } from '../../../utils/checkIfMyLock';
import './style.scss';

export const LockLabel = ({ processLock = {} }) => {
  return (
    checkIfMyLock(processLock) && (
      <span className="lock-label-wrapper">
        <LockOutlined />
        The process is being edited by {processLock.createdBy.firstName} {processLock.createdBy.lastName}
      </span>
    )
  );
};
