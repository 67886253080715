import React from 'react';
import './style.scss';
import { Input } from '../../../../Common';

const FinishPlates = ({ finishPlates, setFinishPlates, finishPlatesErrors, setFinishPlatesErrors }) => {
  const handleChange = (value, field) => {
    setFinishPlates(prev => ({ ...prev, [field]: String(value) }));
    setFinishPlatesErrors(prev => ({ ...prev, [field]: false }));
  };

  return (
    <div className="name-finish-plates">
      <div className="name-finish-plates__row">
        <span>Tray 1</span>
        <Input
          placeholder="Name tray 1"
          value={finishPlates.tray1}
          onChange={value => handleChange(value, 'tray1')}
          error={finishPlatesErrors.tray1}
        />
      </div>
      <div className="name-finish-plates__row">
        <span>Tray 2</span>
        <Input
          placeholder="Name tray 2"
          value={finishPlates.tray2}
          onChange={value => handleChange(value, 'tray2')}
          error={finishPlatesErrors.tray2}
        />
      </div>
    </div>
  );
};

export default FinishPlates;
