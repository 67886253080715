import React from 'react';
import { Switch } from '../../Common/Switch';

export const CheckOptionRow = ({ label, checked, onChangeOptions, optionKey }) => {
  return (
    <div className="experiment-finalization-checklist__row">
      <label className="experiment-finalization-checklist__row__label">{label}</label>
      <Switch onChange={value => onChangeOptions(optionKey, value)} checked={checked} />
    </div>
  );
};

export const ExperimentFinalizationCheckList = ({ options, setOptions }) => {
  const onChangeOption = (key, checked) => {
    const newOptions = {
      ...options,
      [key]: {
        ...options[key],
        checked,
      },
    };
    setOptions(newOptions);
  };

  return (
    <div className="experiment-finalization-checklist">
      <h5 className="experiment-finalization-checklist__title">Stand by check-list</h5>
      {Object.keys(options).map(checkOptionKey => {
        return (
          <CheckOptionRow
            key={checkOptionKey}
            optionKey={checkOptionKey}
            label={options[checkOptionKey].label}
            checked={options[checkOptionKey].checked}
            onChangeOptions={onChangeOption}
          />
        );
      })}
    </div>
  );
};
