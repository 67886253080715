import React, { useEffect, useState } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import './style.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { HeaderSlide } from '../../ExperimentDetailsComponents/HeaderSlide';

export const Carousel = ({ children, countSlide, initialSlide, customTitle, Title, onChange }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    setCurrentSlide(0);
  }, [initialSlide]);
  return (
    <div className="carousel-container">
      <div className="carousel-container_header">
        <LeftOutlined
          onClick={() => {
            let value;
            if (currentSlide === 0) value = countSlide - 1;
            else value = currentSlide - 1;
            setCurrentSlide(value);
            onChange && onChange(value);
          }}
        />
        <HeaderSlide Title={Title} countSlide={countSlide} currentSlide={currentSlide} customTitle={customTitle} />
        <RightOutlined
          onClick={() => {
            let value;
            if (currentSlide + 1 === countSlide) value = 0;
            else value = currentSlide + 1;
            setCurrentSlide(value);
            onChange && onChange(value);
          }}
        />
      </div>
      <ResponsiveCarousel selectedItem={currentSlide}>{children}</ResponsiveCarousel>
    </div>
  );
};
