import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { AttachedFiles } from 'components/Experiment/ResultsTab/resultsComponents';

import {
  BUTTONS_SYNJET_TABLE,
  DISPLAY_OPTIONS,
  EXPERIMENT_STATUSES,
  SYNJET_EXPERIMENT_STATUSES,
} from '../../../../constants';
import { ReactComponent as FileIcon } from '../../../../dist/icons/experiment/file.svg';
import { parseProcessDataFromBackend } from '../../../../utils/synjetHelpers';
import { Button, ButtonGroup, StatusLabel } from '../../../Common';
import { SynJetExperimentTable } from '../../../SynJet/SynJetProccess/SynJetExperimentTable/SynJetExperimentTable';
import { ExperimentTimer } from '../ExperimentTimer/ExperimentTimer';

export const ExperimentInfo = ({
  experiment = {},
  finalizeAndComplete,
  experimentDelay,
  batchStartTime,
  isProExecution,
  executionIdToVialNameDeps,
  stepStatuses,
  disableButtons,
}) => {
  const [selectedDisplayOption, setSelectedDisplayOption] = useState(DISPLAY_OPTIONS.equivalent.label);
  const [experimentData, setExperimentData] = useState({});
  const [experimentSteps, setExperimentSteps] = useState([]);
  const [executionStepsForLogFiles, setExecutionStepsForLogFiles] = useState();
  const executionTemperatures =
    experiment?.execution?.executionSteps
      .sort((a, b) => Number(a.step) - Number(b.step))
      .map(step => step.calibrationTemperatures) || [];
  let stepNumberForLogFile = 0;

  const enableQuenching = experiment?.process?.enableQuenching;

  const updateProcessStepWithTemp = (data, id, temp) => {
    const result = data;

    const splitData = id.split(',');
    const obj = {
      step: splitData[1],
      vialName: splitData[2],
    };

    const vialIdInArr = result[obj.step - 1].step.vials.findIndex(vial => Number(vial.name) === Number(obj.vialName));
    result[obj.step - 1].step.vials[vialIdInArr].calibrationTemperature = temp;

    return result;
  };

  const withCalibrationTemperature = proccesStepsData => {
    const result = proccesStepsData;

    const executionSteps = experiment?.execution?.executionSteps;
    if (!executionSteps) return null;

    executionSteps.forEach(execData => {
      if (!execData.calibrationTemperatures || execData.step === '1_with_quenching') return;
      const vialIdxs = Object.keys(execData.calibrationTemperatures);
      const vialTemprs = Object.values(execData.calibrationTemperatures);

      vialIdxs.forEach((vialIndex, index) => {
        const id = `${execData?.uuid},${vialIndex}`;
        updateProcessStepWithTemp(result, executionIdToVialNameDeps[id], vialTemprs[index]);
      });
    });

    return result;
  };

  const parseExperimentData = useCallback(
    () => parseProcessDataFromBackend(JSON.parse(experiment?.reactionsInfo), experiment?.process?.variableStep),
    [experiment?.reactionsInfo]
  );

  useEffect(() => {
    if (experiment?.uuid) {
      const { processSteps, steps } = parseExperimentData();
      setExperimentData({ process_steps: isProExecution ? withCalibrationTemperature(processSteps) : processSteps });
      setExperimentSteps(steps);
    }
  }, [experiment]);

  useEffect(() => {
    if (enableQuenching && experiment?.execution?.executionSteps) {
      const firstStep = experiment.execution.executionSteps.filter(step => step.step != '1_with_quenching');
      const secondStepWithQuenching = experiment.execution.executionSteps.find(step => step.step == '1_with_quenching');
      setExecutionStepsForLogFiles([...firstStep, secondStepWithQuenching]); // Quenching file after Experiment Log File
    } else {
      stepNumberForLogFile = 0;
      setExecutionStepsForLogFiles(experiment?.execution?.executionSteps);
    }
  }, [experiment?.execution?.executionSteps]);

  return (
    <div className="experiment-info">
      <header className="experiment-info__header">
        <h3 className="experiment-info__header__title">{experiment?.name}</h3>
        <div className="experiment-info__timer">
          <ExperimentTimer
            estimatedTime={experiment?.execution?.estimatedTime}
            experimentDelay={experimentDelay}
            startedAt={experiment?.execution?.startedAt}
            batchStartTime={batchStartTime}
            experimentStatus={experiment?.status}
            experimentActualTime={experiment?.actualTime}
          />
          {experiment?.status === EXPERIMENT_STATUSES.PendingFinalization.value && (
            <Button
              className="experiment-info__finalize__button"
              onClick={() => finalizeAndComplete(experiment)}
              disabled={disableButtons}
            >
              Finalize & Complete
            </Button>
          )}
          <StatusLabel type="success">{experiment?.status}</StatusLabel>
        </div>
      </header>
      <div className="experiment-info__table__container">
        {executionStepsForLogFiles &&
          executionStepsForLogFiles.map((step, stepIndex) => {
            if (!step.logLink) {
              return null;
            }
            stepNumberForLogFile += 1;
            const filePath = step.logLink.split('/');
            const fileName = filePath[filePath.length - 1];

            return (
              <div key={step.uuid} className="experiment-log-files">
                <span className={`experiment-log-files__label ${enableQuenching ? 'quenching_label' : ''}`}>
                  {experiment.execution.executionSteps.length > 1
                    ? enableQuenching
                      ? step.step == '1_with_quenching'
                        ? 'Quenching file: "Experiment log file"'
                        : `Condition ${stepNumberForLogFile}: "Experiment log file"`
                      : `Step ${stepNumberForLogFile}: "Experiment log file"`
                    : '"Experiment log file"'}
                </span>{' '}
                <span className="experiment-log-files__file">
                  <AttachedFiles appendedFiles={[{ file: step.logLink.substr(1), loaded: true }]} notEditable />
                </span>
              </div>
            );
          })}
        <div className="experiment-info__header">
          <h4 className="experiment-info__header__title">Experiment Table</h4>
          <div>
            <ButtonGroup
              className="process-builder-container-display-option-button-group"
              value={selectedDisplayOption}
              onChange={value => {
                setSelectedDisplayOption(value);
              }}
              options={BUTTONS_SYNJET_TABLE}
            />
          </div>
        </div>
        {experimentData && experimentSteps.length && (
          <SynJetExperimentTable
            experiments={experimentData}
            executionIdToVialNameDeps={executionIdToVialNameDeps}
            selectedExperiment={experiment}
            displayOption={selectedDisplayOption}
            processType={experiment?.process?.type}
            processSteps={experimentSteps}
            analytics={
              experiment?.analyticalSetting && experiment?.analyticalType?.length
                ? JSON.parse(experiment?.analyticalSetting)
                : []
            }
            analyticsData={experiment?.execution?.sjAnalysisData}
            stepStatuses={
              isProExecution
                ? stepStatuses
                : experiment?.execution?.executionSteps
                    .sort((step1, step2) => Number(step1.step) - Number(step2.step))
                    .map(step => step.status) || []
            }
            calibrationTemperatures={executionTemperatures}
            analytical={!isProExecution}
            showActualTemperatures
            isProExecution={isProExecution}
            showCopiedVials
            enableVialCopying={experiment?.enableVialCopying}
          />
        )}
      </div>
    </div>
  );
};
