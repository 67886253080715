import React from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import './styles.scss';

const SortableItem = SortableElement(({ value }) => <li>{value}</li>);

const OptionsContainer = SortableContainer(({ children }) => {
  return <ul className="options-container">{children}</ul>;
});

export class DragAndDropContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.setOptions(options => {
      return arrayMove(options, oldIndex, newIndex);
    });
  };

  render() {
    return (
      <OptionsContainer
        onSortEnd={this.onSortEnd}
        useDragHandle={this.props.useDragHandle}
        className={this.props.className}
      >
        {this.props.options?.map((option, index) => (
          <SortableItem key={`item-${option?.key}`} index={index} value={this.props?.optionElements[index]} />
        ))}
      </OptionsContainer>
    );
  }
}
