import React, { useEffect, useState } from 'react';
import { Popup, Select } from 'components/Common';
import { manageBatch } from 'store/scheduling/scheduling.actions';
import { useDispatch } from 'react-redux';
import { EXPERIMENT_STATUSES } from 'constants/common';
import moment from 'moment';

export const MoveToBatchPopup = ({ experiment, open, handleCancel, onSuccess, batch }) => {
  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batches, setBatches] = useState([]);
  const [error, setError] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    // get batches
    if (batch?.uuid && open) {
      let device = experiment?.timeSlot?.device?.uuid;
      dispatch(manageBatch({ data: { device }, type: 'getBatchForAssign' })).then(resp => {
        setBatches(
          resp
            .filter(
              i =>
                i.experiments.length < 5 &&
                i.status === EXPERIMENT_STATUSES.InQueue.value &&
                i.uuid !== batch.uuid &&
                moment().diff(moment(i?.timeSlot?.startAt)) <= 0
            )
            .sort((a, b) => {
              return b.batchId > a.batchId ? -1 : 1;
            })
            .map(i => {
              return { value: i.uuid, label: i.batchId };
            })
        );
      });
    }
  }, [batch?.uuid, open]);

  useEffect(() => {
    if (!open) {
      setSelectedBatch(null);
      setError();
    }
  }, [open]);

  const moveToBatch = () => {
    setLoading(true);
    dispatch(
      manageBatch({
        data: { uuid: batch?.uuid, experiment: experiment?.uuid, toBatchUUID: selectedBatch },
        type: 'moveToBatch',
      })
    )
      .then(resp => {
        setLoading(false);
        /*if (resp.errors) {
        setError(resp.errors[0]?.messages[0]);
        return;
      }*/
        handleCancel();
        onSuccess();
      })
      .catch(err => {
        setError(err[0]?.messages[0]);
        setLoading(false);
      });
  };

  return (
    <Popup
      title="Move experiment"
      open={open}
      handleCancel={handleCancel}
      textSubmit="Confirm"
      disabledPrimaryButton={!selectedBatch}
      handleSubmit={moveToBatch}
      loading={loading}
    >
      <Select
        options={batches}
        value={selectedBatch}
        placeholder="Select batch"
        onChange={v => setSelectedBatch(v)}
        label="Select batch"
        error={error}
        errorText={error}
      />
    </Popup>
  );
};
