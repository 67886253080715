import './SearchhHighlightedCount.style.scss';

import React from 'react';

import { ReactComponent as Down } from '../../../dist/images/arrow-down.svg';
import { ReactComponent as Up } from '../../../dist/images/arrow-up.svg';

export const SearchHighlightedCount = ({ total, current, onUp, onDown }) => (
  <div className="search-highlighted-count">
    <div className="search-highlighted-count_numbers">
      {current}/{total}
    </div>
    <div className="search-highlighted-count_divider" />
    <div className="search-highlighted-count_arrows">
      <Down onClick={onDown} />
      <Up onClick={onUp} />
    </div>
    <div className="search-highlighted-count_divider" />
  </div>
);
