import React, { useEffect, useState } from 'react';
import './style.scss';
import { decodeURIComponentSafe } from 'utils';
import { AttachedFiles } from './resultsComponents';
import { PurificationDetails } from '../../Experiment/InfoSynJet';
import { Compound } from '../../ProcessBuilder/WorkSpace/Compound';
import {
  FILE_TYPES_ARRAY_AUTOSYN,
  FILE_TYPES_ARRAY_SYNJET,
  FILE_TYPES_ARRAY_SYNJET_PRO,
  PROJECT_TYPES,
} from '../../../constants';
import { getSynJetCompoundLetter } from '../../../utils/synjetHelpers';
import { parseProcessBuilderDataFromBackend } from '../../../utils/manualProcessBuilder';
import { getReactionsForManual } from '../ReactionTab/getReactionsForManual';
import { getReactionTabTableDataManual } from '../../../utils/experimentHelpers';
import { usePdfContext } from '../../PdfGenerator';
import { getAppendedFilesReactions } from '../../../utils/synjetProExecutionHelpers';

export const ResultsInfo = ({ resultData, experimentData, isSynJet, isSynJetPro, isLabDevice }) => {
  const [reactions, setReactions] = useState(null);
  const [showReactions, setShowReations] = useState(false);
  useEffect(() => {
    if (experimentData?.reactionsInfo) {
      let infoReactions = JSON.parse(experimentData?.reactionsInfo);
      if (isLabDevice) {
        const { parsedData } = parseProcessBuilderDataFromBackend(
          experimentData?.process?.definition,
          experimentData?.process?.type
        );
        infoReactions = getReactionsForManual(parsedData, experimentData?.process?.type, experimentData?.reactionsInfo);
        infoReactions = {
          reactions: addCompoundsId(getReactionTabTableDataManual(infoReactions)),
          compounds: JSON.parse(experimentData?.reactionsInfo).compounds,
        };
      }
      let anyReaction = false;
      if (infoReactions.reactions) {
        setReactions(
          [...infoReactions.reactions].map((reaction, reactionIndex) => {
            let name = 'Reaction ';
            if (experimentData.process.type !== PROJECT_TYPES.PRODUCTION) {
              name +=
                experimentData.process.type === PROJECT_TYPES.OPTIMIZATION
                  ? reactionIndex + 1
                  : getSynJetCompoundLetter(reactionIndex, 0);
            }
            if (
              JSON.parse(resultData?.productsInfo)[reactionIndex]?.mass ||
              !!JSON.parse(resultData?.productsInfo)[reactionIndex]?.product_yield
            )
              anyReaction = true;
            return {
              name,
              mass: JSON.parse(resultData?.productsInfo)[reactionIndex]?.mass,
              product_yield: JSON.parse(resultData?.productsInfo)[reactionIndex]?.product_yield,
              start_collection: JSON.parse(resultData?.productsInfo)[reactionIndex]?.start_collection,
              end_collection: JSON.parse(resultData?.productsInfo)[reactionIndex]?.end_collection,
              compound: infoReactions.compounds.find(cpd => cpd.compound_id === reaction?.compound_id),
              actual_production_rate: JSON.parse(resultData?.productsInfo)[reactionIndex]?.actual_production_rate,
            };
          })
        );
      } else if (isSynJetPro) {
        setReactions(
          JSON.parse(resultData?.productsInfo).map((reaction, index) => {
            let name = 'Reaction ' + getSynJetCompoundLetter(index, 0);
            if (reaction?.mass || !!reaction?.product_yield || reaction?.attachments?.length) anyReaction = true;
            return {
              name,
              mass: reaction?.mass,
              product_yield: reaction?.product_yield,
              compound: reaction.compound,
              attachments: getAppendedFilesReactions('Reaction attachment', resultData),
            };
          })
        );
      }
      setShowReations(anyReaction);
    }
  }, [experimentData, resultData]);

  const addCompoundsId = reactions => {
    reactions = reactions.map(reaction => {
      let id = null;
      reaction = reaction.map((row, rowIdx) => {
        if (row.chemical_type === 'Product') {
          id = row.compound.compound_id;
        }
      });
      return { compound_id: id };
    });
    return reactions;
  };

  const filterFiles = type => {
    if (resultData?.attachments.length) {
      return resultData.attachments.filter(i => (type ? i.type === type : !i.type)).map(i => ({ ...i, loaded: true }));
    }
  };

  const anyExceptPurificationFiles = () =>
    resultData?.attachments.some(i => (!!i.type && !isSynJetPro) || (isSynJetPro && i.type == 'Offline LC-MS'));

  const files = isSynJet
    ? FILE_TYPES_ARRAY_SYNJET
    : isSynJetPro
    ? FILE_TYPES_ARRAY_SYNJET_PRO
    : FILE_TYPES_ARRAY_AUTOSYN;
  return (
    <div className="results-info">
      {(isSynJet ? !!resultData?.notes : !!resultData?.workup) && (
        <div data-pdf-node className="results-info-row">
          <ContainerTitle title={isSynJet ? 'Notes' : 'Workup'}>
            <div className="results-info-row_column results-info-row_text">
              {decodeURIComponentSafe(isSynJet ? resultData?.notes : resultData?.workup)}
            </div>
          </ContainerTitle>
        </div>
      )}
      {isSynJetPro && decodeURIComponent(experimentData?.purification).trim() && (
        <div data-pdf-node className="results-info-row">
          <ContainerTitle title={'Purification details'}>
            <div className="results-info-row_column results-info-row_text">
              <PurificationDetails purificationData={experimentData.purification} resultsInfo />
            </div>
          </ContainerTitle>
        </div>
      )}
      {!isSynJet &&
        (!!resultData?.purificationType || !!filterFiles()?.length || !!resultData?.purificationDescription) && (
          <div data-pdf-node className="results-info-row">
            <ContainerTitle title="Purification">
              <div className="results-info-row_column results-info-row_text">
                <div>{resultData?.purificationType}</div>
                {!isSynJetPro && (
                  <div className="results-purification_comment">
                    {decodeURIComponentSafe(resultData?.purificationDescription || '')}
                  </div>
                )}
                <AttachedFiles
                  appendedFiles={isSynJetPro ? filterFiles('Purification results') : filterFiles()}
                  notEditable
                />
              </div>
            </ContainerTitle>
          </div>
        )}
      {anyExceptPurificationFiles() && (
        <div className="results-info-row">
          <div className="results-info-row_column">
            {files.map(
              (i, idx) =>
                !!filterFiles(i)?.length && (
                  <ContainerTitle pdfNode title={!idx && 'Offline analysis'}>
                    <div className="results-info-row_column results-info-row_text">
                      <div>
                        <div className="results-info-row_title">{i}</div>
                        <AttachedFiles appendedFiles={filterFiles(i)} notEditable />
                      </div>
                    </div>
                  </ContainerTitle>
                )
            )}
          </div>
        </div>
      )}
      {showReactions && (
        <div className="results-info-row">
          <div className="results-info-row_column">
            {reactions && <ReactionsInfo reactions={reactions} isLabDevice={isLabDevice} isSynJetPro={isSynJetPro} />}
          </div>
        </div>
      )}
    </div>
  );
};

const ContainerTitle = ({ title, children, pdfNode }) => {
  const props = {};
  if (pdfNode) props['data-pdf-node'] = true;

  return (
    <div {...props} className="container-title">
      <div className="container-title_title">{title}</div>
      {children}
    </div>
  );
};

const ReactionsInfo = ({ reactions = [], isLabDevice = false, isSynJetPro = false }) => {
  const { isPdf } = usePdfContext();
  return (
    <>
      {reactions.map(
        (reaction, idx) =>
          (!!reaction.mass || !!reaction.product_yield || (isSynJetPro && reaction?.attachments)) && (
            <ContainerTitle pdfNode title={!idx && 'Product masses / yields'}>
              <div className="results-reaction-data results-reaction-data_column" key={idx}>
                <div className="results-reaction-data__name">{reaction.name}</div>
                {isSynJetPro && reaction?.attachments && (
                  <>
                    {' '}
                    {reaction.attachments[idx]?.length > 0 && reaction.attachments[idx][0]?.loaded && (
                      <AttachedFiles appendedFiles={reaction.attachments[idx]} notEditable />
                    )}
                  </>
                )}
                <div className="results-reaction-data_row">
                  <div className="results-reaction-data__smiles">
                    <div className="results-reaction-data__smiles__compound">
                      <Compound
                        data={reaction.compound}
                        width={130}
                        label={`${reaction.name}-${reaction.compound?.compound_id}-${Math.random()}`}
                      />
                    </div>
                    <div className="results-reaction-data__smiles__data">
                      <div className="results-reaction-data__smiles__data__black-text">
                        {isPdf && !isSynJetPro
                          ? reaction.compound?.inchikey?.split('').map(i => <span>{i}</span>)
                          : reaction.compound?.inchikey}
                      </div>
                      <div className="results-reaction-data__smiles__data__grey-text">
                        {isPdf && !isSynJetPro
                          ? reaction.compound?.compound_name?.split('').map(i => <span>{i}</span>)
                          : reaction.compound?.compound_name}
                      </div>
                      <div className="results-reaction-data__smiles__data__grey-text">{reaction.compound?.smiles}</div>
                    </div>
                  </div>
                  <div className="results-reaction-data__data">
                    <div className="row">
                      <div className="row__title">Mass isolated:</div>
                      {reaction.mass ? (
                        <span>
                          {reaction.mass}
                          mg
                        </span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                    <div className="row">
                      <div className="row__title">Yield:</div>
                      {reaction.product_yield ? <span>{reaction.product_yield}%</span> : <span>N/A</span>}
                    </div>
                    {!isLabDevice && !isSynJetPro && (
                      <div className="row">
                        <div className="row__title">Collection interval:</div>
                        <span>
                          {reaction.start_collection?.split(':')[0]}h{reaction.start_collection?.split(':')[1]}
                          min - {reaction.end_collection?.split(':')[0]}h{reaction.end_collection?.split(':')[1]}
                          min
                        </span>
                      </div>
                    )}
                    {!isLabDevice && !isSynJetPro && (
                      <div className="row">
                        <div className="row__title">Actual production rate:</div>
                        <span>
                          {reaction.actual_production_rate
                            ? `${reaction.actual_production_rate.toFixed(2)} mg/h`
                            : 'N/A'}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ContainerTitle>
          )
      )}
    </>
  );
};
