export const shouldBeHighlight = ({ value, highlightPart }) => {
  const indexOfHighlight = value.toLowerCase().indexOf(highlightPart.toLowerCase());
  if (indexOfHighlight === -1) return false;
  return true;
};

export const getIndexListOfHighlights = (str, substr) => {
  if (!substr) return [];
  const listIdx = [];
  let lastIndex = -1;
  do {
    lastIndex = str.toLowerCase().indexOf(substr.toLowerCase(), lastIndex + 1);
    listIdx.push(lastIndex);
  } while (listIdx[listIdx.length - 1] !== -1);
  listIdx.pop();
  return listIdx;
};
