import { StopOutlined } from '@ant-design/icons';
import React from 'react';
import cn from 'classnames';
import './style.scss';

export const NullCompoundCard = ({ className, showDivider }) => {
  return (
    <div className={cn('null-compound-card', className)}>
      <div className={cn({ 'null-compound-divider': showDivider })}>
        <div className="solvent-only">
          <StopOutlined />
        </div>
      </div>

      <span className="null-compound-card__text">Null control (solvent only)</span>
    </div>
  );
};
