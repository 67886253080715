import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import search from '../../../dist/images/search-outlined.svg';
import smile_svg from '../../../dist/images/smile.svg';
import { Button, Input, Jsme, openNotification, Popover } from '../..';

export const Search = ({
  smile,
  placeholder,
  searchAction = () => {},
  value,
  onChange,
  onReset,
  disabled,
  iconButton,
  button = true,
  loading,
  error,
  errorText,
  allowSpecials,
  searchBtnClassName,
  validation,
  ...props
}) => {
  const container = useRef(null);
  return (
    <div className={cn('search-input', { 'search-error-smiles': smile && error })} ref={container}>
      <Input
        allowClear
        prefix={<img src={search} />}
        suffix={smile && <div className="search-input_spacer" />}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onReset={onReset}
        allowSpecials={allowSpecials}
        error={error}
        errorText={errorText}
        onKeyPress={e => {
          if (e.charCode === 13) searchAction();
        }}
        {...props}
      />
      {smile && (
        <Popover
          overlayClassName="test"
          trigger="click"
          content={<Jsme id="search-jsme" handleChangeSmiles={onChange} />}
        >
          <img className="search-input_smile" src={smile_svg} />
        </Popover>
      )}
      {button && (
        <Button
          loading={loading}
          secondary
          className={searchBtnClassName}
          onClick={() => {
            if (value == '') {
              openNotification(null, 'Selected compound does not have SMILE structure and can’t be used');
            } else {
              searchAction();
              validation && validation();
            }
          }}
        >
          {iconButton || 'Search'}
        </Button>
      )}
    </div>
  );
};
