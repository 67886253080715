import React, { Component } from 'react';
import cn from 'classnames';

export const Bottle = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill={props.color || '#46C5E9'} />
      <mask id="path-2-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.9999 9H18.9999V23.2168L11.6735 33.7915C10.295 35.7811 11.719 38.5 14.1395 38.5H33.8601C36.2806 38.5 37.7046 35.7811 36.3261 33.7915L28.9999 23.217V9Z"
        />
      </mask>
      <path
        d="M18.9999 9V7H16.9999V9H18.9999ZM28.9999 9H30.9999V7H28.9999V9ZM18.9999 23.2168L20.6439 24.3558L20.9999 23.8419V23.2168H18.9999ZM11.6735 33.7915L13.3175 34.9305L11.6735 33.7915ZM36.3261 33.7915L34.6821 34.9305L36.3261 33.7915ZM28.9999 23.217H26.9999V23.8421L27.3559 24.356L28.9999 23.217ZM18.9999 11H28.9999V7H18.9999V11ZM20.9999 23.2168V9H16.9999V23.2168H20.9999ZM13.3175 34.9305L20.6439 24.3558L17.3559 22.0778L10.0295 32.6525L13.3175 34.9305ZM14.1395 36.5C13.3326 36.5 12.858 35.5937 13.3175 34.9305L10.0295 32.6525C7.73209 35.9685 10.1054 40.5 14.1395 40.5V36.5ZM33.8601 36.5H14.1395V40.5H33.8601V36.5ZM34.6821 34.9305C35.1416 35.5937 34.667 36.5 33.8601 36.5V40.5C37.8942 40.5 40.2675 35.9685 37.9701 32.6525L34.6821 34.9305ZM27.3559 24.356L34.6821 34.9305L37.9701 32.6525L30.6439 22.078L27.3559 24.356ZM26.9999 9V23.217H30.9999V9H26.9999Z"
        fill="white"
        mask="url(#path-2-inside-1)"
      />
      <rect x="18" y="9" width="12" height="2" rx="1" fill="white" />
      <line x1="16" y1="30" x2="32" y2="30" stroke="white" strokeWidth="2" />
      <line x1="24.5" y1="34.5" x2="31.5" y2="34.5" stroke="white" strokeLinecap="round" />
    </svg>
  </div>
);
