import './styles.scss';

import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { TextBody14 } from '@atoms';

export const ListWithSearchItemDefault = ({ label, value, onClick, selected, itemActions }) => {
  const onClickItem = () => onClick(value, selected);
  return (
    <div className={cn('list-with-search-item-default')} onClick={onClickItem}>
      <TextBody14>{label}</TextBody14>
      {selected && <CheckOutlined className="list-with-search-item-default_selected-icon" />}
      {itemActions && <div className="list-with-search-item-default_actions">{itemActions}</div>}
    </div>
  );
};
