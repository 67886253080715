import './style.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Select as AntdSelect, Spin } from 'antd';
import cn from 'classnames';

import { useDebounce } from '../../../utils/customHooks';
import { Label } from '..';

const Option = { AntdSelect };

export const Select = ({
  options = [],
  placeholder = 'Please select',
  defaultValue,
  notTags,
  label,
  customTags,
  mode,
  onChange,
  getOptions,
  typeToSearch,
  value,
  showSearch = true,
  showArrow,
  required = false,
  error = false,
  errorText,
  trigger,
  field,
  disabled,
  hideValueOnFocus,
  onFocus,
  onBlur,
  children,
  className,
  errorClassName,
  iconSearch,
  classNameContainer,
  notCloseDropDown,
  noClearing = false,
  selectedOptions,
  optionElement = false,
  ...rest
}) => {
  const [search, setSearch] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [focusFilters, setFocusFilters] = useState(false);
  const [updateDropDown, setUpdateDropDown] = useState(false);
  const handlerFocus = useCallback(() => {
    if (!notCloseDropDown) {
      setUpdateDropDown(true);
    }
    setFocusFilters(true);
  }, []);
  const handlerBlur = useCallback(() => {
    setFocusFilters(false);
  }, []);

  const debounceSearchValue = useDebounce(search, 300);

  useEffect(() => {
    if (typeToSearch && !disabled && (!notCloseDropDown || updateDropDown)) {
      if (!options.length) {
        setLoadingOptions([]);
      }
      setLoading(true);
      getOptions(debounceSearchValue.trim(), selectedOptions)
        .then(resp => {
          setLoadingOptions(resp);
        })
        .finally(() => setLoading(false));
      if (notCloseDropDown) setUpdateDropDown(false);
    }
  }, [debounceSearchValue, trigger, disabled, notCloseDropDown, updateDropDown, typeToSearch]);

  const extraProps = {};
  if (loading) extraProps.notFoundContent = <Spin size="small" />;

  if (!children) {
    extraProps.options = loadingOptions || options;
  }

  return (
    <div className={cn('container-select', { error: !!error }, classNameContainer)}>
      {!!label && <Label requireInput={required}>{label}</Label>}
      <AntdSelect
        className={cn(
          'select',
          {
            'select-type-tags': customTags,
            'select-type-tags_is-value': customTags && !!rest.value?.length,
            'select-type-tags_hide-value': hideValueOnFocus && focusFilters,
          },
          className
        )}
        optionFilterProp="label"
        showSearch={showSearch}
        showArrow={showArrow}
        defaultValue={defaultValue}
        placeholder={placeholder}
        mode={mode}
        onDropdownVisibleChange={stateSelector => {
          if (!stateSelector && typeToSearch) {
            setLoading(false);
            setSearch('');
          }
        }}
        onChange={(...args) => {
          if (typeToSearch && !noClearing) setSearch('');
          onChange && onChange(...args);
        }}
        disabled={disabled}
        value={value}
        filterOption={(inputValue, option) => {
          if (!typeToSearch) {
            if (option?.label && typeof option?.label === 'string') {
              return option.label.toLowerCase().includes(inputValue.toLowerCase().trim());
            }
            if (option?.value && typeof option?.value === 'string') {
              return option.value?.toLowerCase().includes(inputValue.toLowerCase().trim());
            }
          }
          return true;
        }}
        onSearch={e => {
          if (typeToSearch) {
            setLoading(true);
            if (e !== '' && notCloseDropDown) {
              setUpdateDropDown(true);
            }
            setSearch(e);
          }
        }}
        onFocus={(...args) => {
          onFocus && onFocus(args);
          handlerFocus();
        }}
        onBlur={(...args) => {
          onBlur && onBlur(args);
          handlerBlur();
        }}
        getPopupContainer={trigger => trigger.parentElement}
        {...extraProps}
        {...rest}
        suffixIcon={
          iconSearch ? (
            <SearchOutlined width="36px" />
          ) : (
            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L4 4L7 1" stroke="#2C4652" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          )
        }
      >
        {!!children &&
          optionElement &&
          children?.length > 0 &&
          options.map((option, index) => (
            <Option key={option?.key} value={option?.key || option?.value} label={option?.label}>
              {children[index].props?.children}
            </Option>
          ))}
        {!!children && !optionElement && children}
      </AntdSelect>
      {!!error && <p className={`text-error ${errorClassName}`}>{errorText}</p>}
    </div>
  );
};
