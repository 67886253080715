import React, { useEffect, useState } from 'react';
import { getRouteDetailsFromSave } from '../../../../../store/autosyn/autosyn.actions';
import Canvas from '../../../../DrawerRoute/Canvas';
import { Spinner } from '../../../../Common';
import './style.scss';

export const RouteInfo = ({ itemId }) => {
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [processData, setProcessData] = useState(null);

  const getData = async itemId => {
    setLoadingRoute(true);
    try {
      const data = await getRouteDetailsFromSave({ uuid: itemId });

      setProcessData(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingRoute(false);
    }
  };

  useEffect(() => {
    getData(itemId);
  }, [itemId]);

  return processData ? (
    <Spinner loading={loadingRoute}>
      <div className="route-info">
        {processData.strategyRouteIndex?.S0R0?.route_segments.map((i, index) => {
          const data = {
            ...processData,
            strategyRouteIndex: { S0R0: { ...processData.strategyRouteIndex.S0R0, route_segments: [i] } },
          };

          return (
            <div key={index} className="route-info__route">
              <Canvas indexUniq={index + 1} results={data} type="routeCanvas" />
            </div>
          );
        })}
      </div>
    </Spinner>
  ) : null;
};
