import React from 'react';
import './style.scss';
import { SmilesElem } from '../../../../../PrepareMaterials/SmilesElem';

const VialPopover = ({ experiment_name, number, compound }) => (
  <div className="vial-popover">
    <div className="vial-popover__exp-name">{experiment_name}</div>
    <div className="vial-popover-text">Vial #{number}</div>
    <div className="vial-popover-text">{compound?.formula}</div>
    <div className="vial-popover-text">{compound?.compound_name}</div>
    <div className="vial-popover-text">{compound?.smiles}</div>
    <div className="divider" />
    <SmilesElem
      id={`finish-plates-${number}-${compound}`}
      data={compound}
      isNeededTitle={false}
      displayLoupe={false}
      customOptions={{ height: 50, width: 96 }}
    />
  </div>
);

export default VialPopover;
