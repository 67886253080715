import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import './index.scss';
import { SynJetCompound } from '../../index';
import { setExpectedIntermediatesData, setProductsData } from '../../../../store/synjet/synjet.actions';

const PRODUCT_HEADERS = ['Combination', 'Conditions', 'Include product', 'Product name', 'Theoretical yield (mg)'];
const EXPECTED_INTERMEDIATE_HEADERS = ['Combination', 'Conditions', '', 'Product name', 'Theoretical yield (mg)'];

export const SynjetProProductsList = ({
  products,
  viewOnly,
  processSteps,
  quenchingList,
  isExpectedIntermediate,
  disableConditionDelete,
  limitingCompound,
}) => {
  const dispatch = useDispatch();

  const [conditions, setConditions] = useState([]);
  const [fixedConditions, setFixedConditions] = useState([]);

  useEffect(() => {
    const updatedConditions = [];
    const updatedFixedConditions = [];
    processSteps.forEach(step => {
      const newConditions = step.conditions.map(cond => ({ ...cond, isFixed: step.isFixed }));
      if (step.isFixed) {
        updatedFixedConditions.push(...newConditions);
      } else {
        updatedConditions.push(...newConditions);
      }
    });
    setConditions(updatedConditions);
    setFixedConditions(updatedFixedConditions);
  }, [processSteps]);

  const handleProductChange = (reagentNames, updatedProduct) => {
    const updatedProducts = products.map(product => (product.name === reagentNames ? updatedProduct : product));
    if (isExpectedIntermediate) {
      dispatch(
        setExpectedIntermediatesData({
          expectedIntermediates: updatedProducts,
          forceProductFromEIGeneration: true,
        })
      );
    } else {
      dispatch(setProductsData(updatedProducts));
    }
  };

  const headers = isExpectedIntermediate ? EXPECTED_INTERMEDIATE_HEADERS : PRODUCT_HEADERS;
  return (
    <div className="synjet-products-list">
      <div className="synjet-products-list__header">
        {headers.map(header => (
          <span className="title">{header}</span>
        ))}
      </div>
      {products.map(product => (
        <SynJetCompound
          key={product.reagentNames}
          letter={product.name}
          compoundInfo={product}
          type="product"
          isProListItem
          handleSubmit={updatedProduct => handleProductChange(product.name, updatedProduct)}
          viewOnly={viewOnly}
          conditions={conditions}
          fixedConditions={fixedConditions}
          quenchingList={quenchingList}
          isExpectedIntermediate={isExpectedIntermediate}
          disableConditionDelete={disableConditionDelete}
          limitingCompound={limitingCompound}
        />
      ))}
    </div>
  );
};
