import React, { useEffect, useState } from 'react';
import { SynJetCompound, SynJetCompoundGroupList } from '../../../index';
import './index.scss';
import { updateCompoundGroups, updateProduct } from '../../../../store/synjet/synjet.actions';
import { useDispatch } from 'react-redux';
import { calculateCompoundGroupListOnLimited, recalculateGroupCompoundsName } from '../../../../utils/synjetHelpers';

const getCompoundGroupCount = group => group.reduce((sum, currentGroup) => sum + currentGroup.compoundsList.length, 0);

const SynjetCompoundGroupContainer = ({
  compounds,
  step,
  product,
  isExpectedIntermediate,
  viewOnly,
  stepOffset,
  limitingCompoundGroup,
  disableLimiting,
  synjetProcess,
  highlightErrors,
  isLibGen,
}) => {
  const [namesUpdated, setNamesUpdated] = useState(false);
  const dispatch = useDispatch();
  const { reactantGroups, reagentGroups } = compounds;

  useEffect(() => {
    // (disableLimiting && !namesUpdated) to resolve problem with synchronise update of two useEffects
    if (!limitingCompoundGroup || (disableLimiting && !namesUpdated)) return;
    dispatch(
      updateCompoundGroups(
        calculateCompoundGroupListOnLimited(limitingCompoundGroup, reactantGroups, disableLimiting),
        calculateCompoundGroupListOnLimited(limitingCompoundGroup, reagentGroups, disableLimiting),
        step
      )
    );
    setNamesUpdated(false);
  }, [limitingCompoundGroup?.compoundsList, limitingCompoundGroup?.isLimiting, namesUpdated]);

  useEffect(() => {
    setTimeout(() => setNamesUpdated(true), 600);
  }, [limitingCompoundGroup?.compoundsList, limitingCompoundGroup?.volumes]);

  useEffect(() => {
    dispatch(
      updateCompoundGroups(
        recalculateGroupCompoundsName(reactantGroups, limitingCompoundGroup, stepOffset, 'Reactant', true),
        recalculateGroupCompoundsName(
          reagentGroups,
          limitingCompoundGroup,
          getCompoundGroupCount(reactantGroups) + stepOffset,
          'Reagent',
          true
        ),
        step
      )
    );
    setTimeout(() => setNamesUpdated(true), 300);
  }, [
    getCompoundGroupCount(reagentGroups),
    getCompoundGroupCount(reactantGroups),
    stepOffset,
    limitingCompoundGroup?.isLimiting,
    synjetProcess,
  ]);

  const handleProductChange = product => {
    dispatch(updateProduct(product, step));
  };

  const showProduct = !isLibGen || (isLibGen && isExpectedIntermediate && !disableLimiting);
  return (
    <div className="synjet-compound-container">
      <SynJetCompoundGroupList
        type="reactant"
        compoundGroupsList={reactantGroups}
        limitingCompoundGroup={limitingCompoundGroup}
        step={step}
        viewOnly={viewOnly}
        disableLimiting={disableLimiting}
        highlightErrors={highlightErrors}
        isLibGen={isLibGen}
      />
      <SynJetCompoundGroupList
        type="reagent"
        compoundGroupsList={reagentGroups}
        compoundOffset={getCompoundGroupCount(reactantGroups)}
        limitingCompoundGroup={limitingCompoundGroup}
        step={step}
        viewOnly={viewOnly}
        disableLimiting={disableLimiting}
        highlightErrors={highlightErrors}
        isLibGen={isLibGen}
      />
      {showProduct && (
        <>
          <h3 className="synjet-compound-container__title">
            {isExpectedIntermediate ? 'Expected intermediate' : 'Product'}
          </h3>
          <div className="synjet-product__header">
            <span className="title">Name</span>
            <span className="title">Exact mass, g/mol</span>
          </div>
          <SynJetCompound
            letter={isExpectedIntermediate ? 'EI' : 'Pr'}
            compoundInfo={product}
            type="product"
            handleSubmit={handleProductChange}
            viewOnly={viewOnly}
          />
        </>
      )}
    </div>
  );
};

export default SynjetCompoundGroupContainer;
