import moment from 'moment';
import _ from 'lodash';
import { SYNJET_PROCESS_TYPES, SYNJET_PRO_PROCESS_TYPES, TEMPERATURE_LIMITS } from '../constants';
import { getLibGenProducts, parseProcessDataFromBackend } from './synjetHelpers';
import { getDataUnivariateCharts } from '../store/experiment/experiment.actions';
import { openNotification } from '../components/Common';
import { getUniqueValuesFromArray, sortArrayDesc } from './common';
import { SYNJET_PRO_MAX_HEATING_BLOCKS, SYNJET_PRO_VIALS_BY_HEATING_BLOCK } from '../constants/execution';

export const getProcessStructureFromDefinition = definition => {
  const processStructure = [];
  if (definition) {
    definition.forEach(reactor => {
      if (reactor.pumps.length) processStructure.push(...reactor.pumps);
      processStructure.push({
        isReactor: true,
        name: reactor.title,
        key: reactor.key,
        ...reactor,
      });
    });
  }
  return processStructure;
};

const getExperimentReactionInfoFromBatch = (experiments, experimentName) => {
  const experiment = experiments.find(experiment => experiment.uuid === experimentName);
  if (experiment && experiment.reactionsInfo) {
    return JSON.parse(experiment.reactionsInfo);
  }
  return null;
};
export const getCompoundType = (compoundName, experimentName, experiments) => {
  if (!experiments) return '';
  const reactionsInfo = getExperimentReactionInfoFromBatch(experiments, experimentName);
  if (reactionsInfo) {
    if (reactionsInfo.reagents && reactionsInfo.reagents.find(reagent => reagent.name === compoundName)) {
      return 'Reagent';
    }
    if (reactionsInfo.reactants && reactionsInfo.reactants.find(reactant => reactant.name === compoundName)) {
      return 'Reactant';
    }
    if (reactionsInfo.quenching && reactionsInfo.quenching.find(reactant => reactant.name === compoundName)) {
      return 'Quenching';
    }
  }
  return '';
};

export const getCompoundInfo = (compoundName, experimentName, experiments, calculatedVolume) => {
  if (!experiments) return '';
  const experiment = experiments.find(experiment => experiment.uuid === experimentName);
  const reactionsInfo = getExperimentReactionInfoFromBatch(experiments, experimentName);

  if (reactionsInfo) {
    if (reactionsInfo.reagents) {
      const reagent = reactionsInfo.reagents.find(reagent => reagent.name === compoundName);
      if (reagent) {
        return {
          name: experiment.name,
          experimentId: experiment.uuid,
          compound: reactionsInfo.compounds.find(compound => compound.compound_id === reagent.reagent),
          compoundName,
          solvents: reagent.solvents.length
            ? reagent.solvents.map(solvent => ({
                ...solvent,
                solvent: reactionsInfo.compounds.find(compound => compound.compound_id === solvent.solvent),
              }))
            : [],
          calculatedVolume,
        };
      }
    }
    if (reactionsInfo.reactants) {
      const reactant = reactionsInfo.reactants.find(reactant => reactant.name === compoundName);
      if (reactant) {
        return {
          name: experiment.name,
          experimentId: experiment.uuid,
          compound: reactionsInfo.compounds.find(compound => compound.compound_id === reactant.reactant),
          compoundName,
          solvents: reactant.solvents.length
            ? reactant.solvents.map(solvent => ({
                ...solvent,
                solvent: reactionsInfo.compounds.find(compound => compound.compound_id === solvent.solvent),
              }))
            : [],
          calculatedVolume,
        };
      }
    }
    if (reactionsInfo.quenching) {
      const quenchingItem = reactionsInfo.quenching.find(quenching => quenching.name === compoundName);
      if (quenchingItem) {
        return {
          name: experiment.name,
          experimentId: experiment.uuid,
          compound: reactionsInfo.compounds.find(compound => compound.compound_id === quenchingItem.compound),
          compoundName,
          solvents: quenchingItem.solvents.length
            ? quenchingItem.solvents.map(solvent => ({
                ...solvent,
                solvent: reactionsInfo.compounds.find(compound => compound.compound_id === solvent.solvent),
              }))
            : [],
          calculatedVolume,
        };
      }
    }
  }
  return null;
};

export const getCompoundNormalizeInfo = (experimentName, experiments, calculatedVolume) => {
  if (!experiments) return '';
  const experiment = experiments.find(experiment => experiment.uuid === experimentName);
  const reactionsInfo = getExperimentReactionInfoFromBatch(experiments, experimentName);
  if (reactionsInfo) {
    const normalize = reactionsInfo?.general_info?.normalize_with;
    if (normalize) {
      return {
        name: experiment.name,
        experimentId: experiment.uuid,
        solvent: reactionsInfo.compounds.find(compound => compound.compound_id === normalize),
        calculatedVolume,
      };
    }
  }
  return null;
};

export const getExperimentUpdatedReactionInfo = (experimentID, experiments, compoundName, solvents) => {
  if (!experiments) return {};

  const solventCompounds = solvents.map(solvent => (compoundName ? solvent?.solvent : solvent));
  const noneSolvent = !!solvents.find(solvent => solvent?.solvent?.compound_name === 'None');

  const getUpdatedCompoundList = list =>
    list.map(compound => {
      if (compound.name === compoundName) {
        return {
          ...compound,
          solvents: noneSolvent
            ? []
            : solvents.map(solvent => ({ ...solvent, solvent: solvent?.solvent?.compound_id })),
        };
      }
      return compound;
    });
  const reactionsInfo = getExperimentReactionInfoFromBatch(experiments, experimentID);

  if (reactionsInfo) {
    const {
      compounds,
      reagents,
      reactants,
      general_info,
      process_steps,
      quenching,
      products,
      expectedIntermediates,
    } = reactionsInfo;

    // change solvent in the list of reagents/reactants
    const updatedReagents = compoundName ? getUpdatedCompoundList(reagents) : reagents;
    const updatedReactants = compoundName ? getUpdatedCompoundList(reactants) : reactants;
    let updatedQuenching;
    if (quenching) updatedQuenching = compoundName ? getUpdatedCompoundList(quenching) : quenching;
    const updatedCompounds = [];

    const updatedGeneralInfo = compoundName
      ? general_info
      : {
          ...general_info,
          normalize_with: solvents?.[0]?.compound_id,
        };
    // get compound from normalized
    updatedCompounds.push(updatedGeneralInfo.normalize_with);

    // get compounds from reagents
    updatedReagents.forEach(reagent => {
      updatedCompounds.push(reagent.reagent);
      reagent.solvents.forEach(solvent => updatedCompounds.push(solvent.solvent));
    });

    // get compounds from reactants
    updatedReactants.forEach(reactant => {
      updatedCompounds.push(reactant.reactant);
      reactant.solvents.forEach(solvent => updatedCompounds.push(solvent.solvent));
    });

    if (updatedQuenching) {
      updatedQuenching.forEach(reactant => {
        updatedCompounds.push(reactant.compound);
        reactant.solvents.forEach(solvent => updatedCompounds.push(solvent.solvent));
      });
    }

    // get products
    process_steps.forEach(step => updatedCompounds.push(step.step.product));

    if (products) {
      products.forEach(product => {
        updatedCompounds.push(product.product);
        // if (product?.combination?.expectedIntermediate) {
        //   updatedCompounds.push(product?.combination?.expectedIntermediate);
        // }
      });
    }
    const updatedUniqueConpounds = updatedCompounds
      .filter(compound => !!compound)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(
        compoundID =>
          compounds.find(compound => compound.compound_id === compoundID) ||
          solventCompounds.find(compound => compound.compound_id === compoundID)
      );

    const reactionsQuenching = updatedQuenching ? { quenching: updatedQuenching } : {};

    return {
      ...reactionsInfo,
      reactants: updatedReactants,
      reagents: updatedReagents,
      general_info: updatedGeneralInfo,
      compounds: updatedUniqueConpounds,
      ...reactionsQuenching,
    };
  }
};

export const getExperimentTableData = experiment => {
  if (!experiment) return;
  const { processSteps, steps, rules, products, quenching, expectedIntermediates } = parseProcessDataFromBackend(
    JSON.parse(experiment?.reactionsInfo),
    experiment?.process?.variableStep
  );
  return {
    processSteps,
    steps,
    rules,
    products,
    quenching,
    expectedIntermediates,
    process: { process: experiment?.process },
    experimentID: experiment.uuid,
  };
};

export const getConditionVials = (temperature, experiment, processSteps, steps) => {
  if (!experiment || !steps || !processSteps) return 0;

  const isLibGen = experiment?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION;
  if (experiment?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION) {
    if (steps.length === 1) {
      const temperatureVials = processSteps?.[0]?.step?.vials.filter(
        vial => vial?.temperature_label?.toLowerCase() === temperature
      );
      const isPrime = processSteps?.[0]?.step?.vials[0]?.temperature_label?.toLowerCase() === temperature;
      return isPrime ? temperatureVials.length + 1 : temperatureVials.length;
    }
    if (steps.length === 2) {
      const variableVials = processSteps.find(step => !step?.step?.isFixed);
      if (temperature === 'fixed') {
        return variableVials.step.vials.length + 3;
      }
      const temperatureVials = variableVials.step?.vials.filter(
        vial => vial?.temperature_label?.toLowerCase() === temperature
      );
      const isPrime = variableVials.step?.vials[0]?.temperature_label?.toLowerCase() === temperature;
      return isPrime ? temperatureVials.length + 1 : temperatureVials.length;
    }
  }
  if (experiment?.process?.type === SYNJET_PROCESS_TYPES.SCREENING || isLibGen) {
    // add check for LIBRARY_GENERATION to SJP batch details
    if (steps.length === 1) {
      const temperatureVials = processSteps?.[0]?.step?.vials.filter(
        vial => vial?.temperature_label?.replace(' ', '') === temperature
      );
      const isPrime =
        !isLibGen && processSteps?.[0]?.step?.vials[0]?.temperature_label?.replace(' ', '') === temperature;
      return isPrime ? temperatureVials.length + 1 : temperatureVials.length;
    }
    const variableVials = processSteps.find(step => !step?.step?.isFixed);
    if (temperature === 'fixed') {
      return isLibGen ? variableVials.step.vials.length : variableVials.step.vials.length + 3;
    }
    const temperatureVials = variableVials.step?.vials.filter(
      vial => vial?.temperature_label?.replace(' ', '') === temperature
    );
    const isPrime = !isLibGen && variableVials.step?.vials[0]?.temperature_label?.replace(' ', '') === temperature;
    return isPrime ? temperatureVials.length + 1 : temperatureVials.length;
  }
  return 0;
};

export const getExperimentTimeConditions = (experiment, experimentSteps, experimentProcessSteps) => {
  if (!experiment || !experimentSteps) return;
  const condition = experimentSteps?.[0]?.conditions;
  if (experiment?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION) {
    if (experimentSteps?.length === 1) {
      const temperatures = {};
      ['low', 'med', 'high'].forEach((i, idx) => {
        const tempCondition = {};
        tempCondition.temperature = condition?.[0]?.temperature[i];
        tempCondition.time = condition?.[0]?.time[i];
        tempCondition.steps = 1;
        tempCondition.vials = getConditionVials(i, experiment, experimentProcessSteps, experimentSteps);
        temperatures[i] = tempCondition;
      });
      return temperatures;
    }
    const temperatures = {};
    experimentSteps.forEach(step => {
      if (!step.isFixed) {
        ['low', 'med', 'high'].forEach((i, idx) => {
          step.conditions.map(cond => {
            const tempCondition = {};
            tempCondition.temperature = cond?.temperature[i];
            tempCondition.time = cond?.time[i];
            tempCondition.steps = 2;
            tempCondition.vials = getConditionVials(i, experiment, experimentProcessSteps, experimentSteps);
            temperatures[i] = tempCondition;
          });
        });
      } else {
        step.conditions.map(cond => {
          const tempCondition = {};
          tempCondition.temperature = cond?.temperature.fixed;
          tempCondition.steps = 2;
          tempCondition.time = cond?.time.fixed;
          tempCondition.vials = getConditionVials('fixed', experiment, experimentProcessSteps, experimentSteps);
          temperatures.fixed = tempCondition;
        });
      }
    });
    return temperatures;
  }
  if (
    experiment?.process?.type === SYNJET_PROCESS_TYPES.SCREENING ||
    experiment?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION
  ) {
    // add check LIBRARY_GENERATION to avoid error
    if (experimentSteps?.length === 1) {
      const temperatures = {};
      experimentSteps.forEach((step, idx) => {
        step.conditions.forEach((cond, index) => {
          const vials = getConditionVials(
            `COND${idx + 1 + index}`,
            experiment,
            experimentProcessSteps,
            experimentSteps
          );
          const tempCondition = {};
          tempCondition.temperature = cond?.temperature.fixed;
          tempCondition.time = cond?.time.fixed;
          tempCondition.steps = 1;
          tempCondition.vials = vials;
          temperatures[`COND${idx + 1 + index}`] = tempCondition;
        });
      });
      return temperatures;
    }
    const temperatures = {};
    experimentSteps.forEach(step => {
      if (!step.isFixed) {
        step.conditions.forEach((cond, index) => {
          const vials = getConditionVials(`COND${1 + index}`, experiment, experimentProcessSteps, experimentSteps);
          const tempCondition = {};
          tempCondition.temperature = cond?.temperature.fixed;
          tempCondition.time = cond?.time.fixed;
          tempCondition.steps = 2;
          tempCondition.vials = vials;
          temperatures[`COND${1 + index}`] = tempCondition;
        });
      } else {
        step.conditions.forEach((cond, index) => {
          const vials = getConditionVials('fixed', experiment, experimentProcessSteps, experimentSteps);
          const tempCondition = {};
          tempCondition.temperature = cond?.temperature.fixed;
          tempCondition.time = cond?.time.fixed;
          tempCondition.steps = 2;
          tempCondition.vials = vials;
          temperatures.fixed = tempCondition;
        });
      }
    });
    return temperatures;
  }
  return null;
};

export const getExperimentContainerStyle = experimentIndex => {
  switch (experimentIndex) {
    case 0:
      return 'experiment__green';
    case 1:
      return 'experiment__purple';
    case 2:
      return 'experiment__orange';
    case 3:
      return 'experiment__blue';
    case 4:
      return 'experiment__red';
    default:
      return '';
  }
};

export const validateSJDispenser = (dispenser, compoundInfo, isNormalize, setErrors, isManual) => {
  let valid = true;
  const newErrors = {};
  if (!isManual) {
    if (!dispenser.material) {
      valid = false;
      newErrors.material = 'Dispenser material should be specified';
    }
    if (!dispenser.backpack) {
      newErrors.backpack = 'Backpack should be specified';
      valid = false;
    }
    if (!dispenser.fluid_body) {
      newErrors.fluid_body = 'Fluid body should be specified';
      valid = false;
    }
    if (!dispenser.calibration) {
      newErrors.calibration = 'Dispenser calibration should be specified';
      valid = false;
    }
    if (dispenser.calibration < 0.01) {
      newErrors.calibration = 'Dispenser calibration should be >= 0.01';
      valid = false;
    }
  }
  if (compoundInfo) {
    compoundInfo.forEach((compound, index) => {
      newErrors.compoundsList = [];
      const compoundErrors = {};
      if (isNormalize) {
        if (!compound?.solvent) {
          compoundErrors.solvent = 'Solvent is a required field';
          valid = false;
        }
      } else if (compound?.solvents) {
        if (!compound.solvents.length) {
          setErrors(newErrors);
          return valid;
        }
        const fragmentSum = compound?.solvents.reduce((sum, currentSolvent) => +currentSolvent.fraction + sum, 0);
        if (fragmentSum !== 1.0) {
          compoundErrors.fraction = new Array(compound?.solvents?.length || 0)
            .fill(null)
            .map(() => 'The sum of solvent Fractions should be equal to 1.0');
          valid = false;
        }

        compoundErrors.solvents = compound?.solvents.map(solvent =>
          solvent?.solvent ? null : 'Solvent is a required field'
        );
        valid = valid && compoundErrors.solvents.every(solvent => !solvent);
      } else {
        compoundErrors.solvent = 'Solvent is a required field';
        valid = false;
      }
      newErrors.compoundsList.push(compoundErrors);
    });
  }
  setErrors(newErrors);
  return valid;
};

export const getDisabledHours = (type, options) => {
  if (type !== 'low' && type !== 'med' && type !== 'high') return [];
  let disabled = [];
  for (let i = 0; i < 24; i++) disabled.push(i);
  const hours = {
    low: options.low?.time ? +options.low?.time.format('H') : 0,
    med: options.med?.time ? +options.med?.time.format('H') : 0,
    high: options.high?.time ? +options.high?.time.format('H') : 0,
  };
  switch (type) {
    case 'low': {
      return !isNaN(hours.med)
        ? disabled.filter(i => i > hours.med)
        : !isNaN(hours.high)
        ? disabled.filter(i => i > hours.high)
        : [];
    }
    case 'med': {
      if (isNaN(hours.high) && isNaN(hours.low)) return [];
      disabled = disabled.filter(i => {
        const a = !isNaN(hours.low) ? i < hours.low : false;
        const b = !isNaN(hours.high) ? i > hours.high : false;
        return a || b;
      });
      return disabled;
    }
    case 'high': {
      return !isNaN(hours.med)
        ? disabled.filter(i => i < hours.med)
        : !isNaN(hours.low)
        ? disabled.filter(i => i < hours.low)
        : [];
    }
  }
};

export const getDisabledMinutes = (type, selectedHour, options) => {
  if (type !== 'low' && type !== 'med' && type !== 'high') {
    return selectedHour === 0 ? [0] : [];
  }
  let disabled = [];
  for (let i = 0; i < 60; i += 15) disabled.push(i);
  const hours = {
    low: options.low?.time ? +options.low?.time.format('H') : 0,
    med: options.med?.time ? +options.med?.time.format('H') : 0,
    high: options.high?.time ? +options.high?.time.format('H') : 0,
  };
  const mins = {
    low: options.low?.time ? +options.low?.time.format('m') : 0,
    med: options.med?.time ? +options.med?.time.format('m') : 0,
    high: options.high?.time ? +options.high?.time.format('m') : 0,
  };
  switch (type) {
    case 'low': {
      if (!isNaN(hours.med) && hours.med === selectedHour) disabled = disabled.filter(i => i > mins.med);
      else if (!isNaN(hours.high) && hours.high === selectedHour) disabled = disabled.filter(i => i > mins.high);
      else disabled = [];
      break;
    }
    case 'med': {
      if (
        (!isNaN(hours.low) && hours.low !== selectedHour && !isNaN(hours.high) && hours.high !== selectedHour) ||
        (isNaN(hours.high) && isNaN(hours.low))
      ) {
        disabled = [];
      } else {
        disabled = disabled.filter(i => {
          const a = hours.low && hours.low === selectedHour ? i < mins.low : false;
          const b = hours.high && hours.high === selectedHour ? i > mins.high : false;
          return a || b;
        });
      }
      break;
    }
    case 'high': {
      if (!isNaN(hours.med) && hours.med === selectedHour) disabled = disabled.filter(i => i < mins.med);
      else if (!isNaN(hours.low) && hours.low === selectedHour) disabled = disabled.filter(i => i < mins.low);
      else disabled = [];
      break;
    }
  }
  return selectedHour === 0 ? [0, ...disabled] : disabled;
};

export const getRequiredVialQuantity = tempOption => {
  if (!tempOption) return;
  if (Object.keys(tempOption).includes('fixed')) {
    return tempOption?.fixed?.vials;
  }
  return Object.values(tempOption).reduce((sum, option) => option?.vials + sum, 0);
};

export const getTotalRequiredVialQuantity = temperatureOptions => {
  if (!temperatureOptions) return 0;
  return temperatureOptions.reduce((sum, time) => sum + getRequiredVialQuantity(time), 0);
};

export const getTotalRequiredVialPositionQuantity = (temperatureOptions, experimentsInfo, isPro) =>
  experimentsInfo.reduce((sum, experimentInfo, experimentIndex) => {
    const vialQuantity = getRequiredVialQuantity(temperatureOptions[experimentIndex]);
    let vialQuantityPos;
    if (experimentInfo?.steps?.length === 1) vialQuantityPos = vialQuantity;
    else vialQuantityPos = isPro ? vialQuantity * 2 : vialQuantity * 2 - 2;
    return sum + vialQuantityPos;
  }, 0);
export const getTotalHeatingBlockQuantity = (temperatureOptions, isPro) => {
  if (!temperatureOptions || !temperatureOptions.length) return 0;
  const mappedOptions = temperatureOptions.map(option => Object.values(option)).flat();
  const condArray = mappedOptions.reduce((accumulator, current, i) => {
    const vialQuantityPos = current.vials;
    if (accumulator[current.temperature]) {
      accumulator[current.temperature] += vialQuantityPos;
    } else {
      accumulator[current.temperature] = vialQuantityPos;
    }
    return accumulator;
  }, {});
  return Object.values(condArray)
    .map(vials => (isPro ? Math.ceil(vials / SYNJET_PRO_VIALS_BY_HEATING_BLOCK) : Math.ceil(vials / 48)))
    .reduce((sum, current) => sum + current, 0);
};

export const getMinMaxTemperatureValues = (type, variable) => {
  switch (type) {
    case 'low': {
      return {
        min: TEMPERATURE_LIMITS.MIN,
        max: getMaxValue([variable.med, TEMPERATURE_LIMITS.MAX, variable.high]),
      };
    }
    case 'med': {
      return {
        min: getMaxValue([variable.low, TEMPERATURE_LIMITS.MIN]),
        max: getMaxValue([variable.high, TEMPERATURE_LIMITS.MAX]),
      };
    }
    case 'high': {
      return {
        min: getMaxValue([variable.low, TEMPERATURE_LIMITS.MIN, variable.med]),
        max: TEMPERATURE_LIMITS.MAX,
      };
    }
    default: {
      return {
        min: TEMPERATURE_LIMITS.MIN,
        max: TEMPERATURE_LIMITS.MAX,
      };
    }
  }
};

const getMaxValue = array => {
  const max = Math.max.apply(
    null,
    array.filter(i => !!Number.isInteger(i))
  );
  return max > TEMPERATURE_LIMITS.MAX ? TEMPERATURE_LIMITS.MAX : max;
};

export const getReflectedTemperatures = (conditionName, value, condition, type) => {
  const reflectedTemperatures = {};
  if (conditionName === 'low') {
    if (condition?.med?.[type] <= value) reflectedTemperatures.med = value;
    if (condition?.high?.[type] <= value) reflectedTemperatures.high = value;
  } else if (conditionName === 'med' && condition?.high?.[type] <= value) {
    reflectedTemperatures.high = value;
  }
  return reflectedTemperatures;
};

const getInvalidVariableVialsIndexes = variableStep =>
  variableStep?.step?.vials.reduce((invalidIndexes, vial, vialIndex) => {
    if (!vial.is_valid) {
      invalidIndexes.push(vialIndex);
    }
    return invalidIndexes;
  }, []);

export const getValidVialsProcessSteps = (processSteps, isLibGen) => {
  const isTwoStepProcess = processSteps?.length > 1;
  const validatedSteps = processSteps.map(processStep => ({
    step: {
      ...processStep.step,
      vials: processStep.step.vials.filter(vial => vial.is_valid),
    },
  }));
  if (!isTwoStepProcess) {
    return validatedSteps;
  }

  const fixedStep = validatedSteps.find(step => step?.step?.isFixed);
  const validFixedVials = fixedStep?.step?.vials.filter(vial => vial.is_valid);
  if (!validFixedVials.length) {
    return validatedSteps.map(processStep => ({
      step: {
        ...processStep.step,
        vials: [],
      },
    }));
  }
  const variableStep = processSteps.find(step => !step?.step?.isFixed);
  const invalidVialsIndexes = getInvalidVariableVialsIndexes(variableStep);

  if (invalidVialsIndexes.length) {
    return validatedSteps.map(processStep =>
      processStep?.step?.isFixed && isLibGen
        ? {
            step: {
              ...processStep.step,
              vials: processStep.step.vials.filter((vial, vialIndex) => !invalidVialsIndexes.includes(vialIndex)),
            },
          }
        : processStep
    );
  }
  return validatedSteps;
};

export const getUniqueCondition = (conditionOptions, property) => {
  const conditions = Object.entries(conditionOptions)
    .filter(option => option[0] !== 'fixed')
    .map(option => option[1])
    .slice(0, 3)
    .map(option => option[property]);
  return conditions
    .map(value => (property === 'time' ? value.format('HH:mm') : value))
    .filter((value, index, self) => self.indexOf(value) === index)
    .map(value => ({ value, label: value }));
};

export const getUniqueCompounds = (reactionsInfo, compounds, solventCompounds) => {
  const allCompounds = [];

  // get compound from normalized
  allCompounds.push(reactionsInfo.general_info.normalize_with);

  // get compounds from reagents
  reactionsInfo.reagents.forEach(reagent => {
    allCompounds.push(reagent.reagent);
    reagent.solvents.forEach(solvent => allCompounds.push(solvent.solvent));
  });

  // get compounds from reactants
  reactionsInfo.reactants.forEach(reactant => {
    allCompounds.push(reactant.reactant);
    reactant.solvents.forEach(solvent => allCompounds.push(solvent.solvent));
  });

  // get compounds from quenching
  if (reactionsInfo.quenching) {
    reactionsInfo.quenching.forEach(reactant => {
      allCompounds.push(reactant.compound);
      reactant.solvents.forEach(solvent => allCompounds.push(solvent.solvent));
    });
  }

  reactionsInfo.process_steps.forEach(step => allCompounds.push(step.step.product));

  if (reactionsInfo.products) {
    reactionsInfo.products.forEach(product => {
      allCompounds.push(product.product);
    });
  }

  const updatedUniqueCompounds = allCompounds
    .filter(compound => !!compound)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map(
      compoundID =>
        compounds.find(compound => compound.compound_id === compoundID) ||
        solventCompounds.find(compound => compound.compound_id === compoundID)
    );

  return updatedUniqueCompounds;
};

export const getConditionValue = (conditionOptions, property, singleStep, selectorValue) => {
  const index = singleStep ? 3 : 4;
  const selectOption = Object.entries(conditionOptions)[index];
  const options = getUniqueCondition(conditionOptions, property);
  const selectedValue = selectOption?.[1]?.[property];
  const value = property === 'time' ? selectorValue || moment(options[0].value, 'HH:mm') : options[0].value;
  return options.find(option => option.value === selectedValue) ? null : selectorValue || value;
};

export const callHelperUnivariateData = ({
  sensorsIds,
  body,
  controller,
  callBackNotData,
  callBackLimit,
  viewMode,
  chunkNumber,
  callBack502,
}) => {
  const sensors = _.chunk(sensorsIds.split(','), [chunkNumber || 1]);
  const allData = [];
  return sensors.reduce((acc, curr, idx) => {
    const isLast = idx === sensors.length - 1;
    const bodyCall = { ...body, sensorIds: curr, controller };
    const funcThen = data => {
      let isError = false;
      if (data?.indexOf('Univariate data not found') >= 0) {
        allData.push([]);
        callBackNotData && callBackNotData();
        return;
      }
      if (data?.indexOf('The limit of experiment duration is exceeded') >= 0) {
        isError = true;
        callBackLimit && callBackLimit();
      }
      if (isError) throw 'error';

      if (data?.indexOf('Code: 502') >= 0) {
        openNotification(
          '',
          'Univariate data loading has been failed unexpectedly. Try to refresh the page. If the issue is still there - contact your administrator with the issue details'
        );
        callBack502 && callBack502();
        throw 'error';
      }
      allData.push(data);
    };
    if (acc) {
      return acc.then(() => {
        if (isLast) {
          return getDataUnivariateCharts(bodyCall)
            .then(funcThen)
            .then(() => allData);
        }
        return getDataUnivariateCharts(bodyCall).then(funcThen);
      });
    }
    if (isLast) {
      return getDataUnivariateCharts(bodyCall)
        .then(funcThen)
        .then(() => allData);
    }
    return getDataUnivariateCharts(bodyCall).then(funcThen);
  }, undefined);
};

const getTemperatureToVialMapping = temperatureOptions =>
  temperatureOptions.reduce((mapping, option) => {
    const optionValues = Object.values(option);
    optionValues.forEach(value => {
      const currentVials = mapping.get(value.temperature);
      if (currentVials) {
        mapping.set(value.temperature, currentVials + value.vials);
      } else {
        mapping.set(value.temperature, value.vials);
      }
    });
    return mapping;
  }, new Map());

export const getHeatingBlocksTemperatureOptions = (temperatureOptions, heatingBlocks, valueOnly, currentValue) => {
  const options = valueOnly ? [] : [{ label: 'N/A', value: null }];
  const usedTemperatures = heatingBlocks.map(block => block.temperature).filter(temp => !!temp);
  const mapping = getTemperatureToVialMapping(temperatureOptions);
  mapping.forEach((value, key) => {
    const blockNumber = Math.ceil(value / SYNJET_PRO_VIALS_BY_HEATING_BLOCK);
    const usedBlocks = usedTemperatures.filter(temp => temp === key);
    const usedBlocksCount = currentValue === key ? usedBlocks.length - 1 : usedBlocks.length;
    const temperaturesLength = valueOnly ? blockNumber : blockNumber - usedBlocksCount;
    const temperatures = Array(temperaturesLength).fill(key);
    options.push(...temperatures);
  });
  return valueOnly
    ? options
    : options.map(option => (option.label ? option : { label: `${option} C°`, value: option }));
};

const mapBlockWithTemperature = availableTemperatures => {
  const sortedTemperatures = availableTemperatures.sort(sortArrayDesc);
  const uniqueTemps = sortedTemperatures.filter(getUniqueValuesFromArray);
  if (sortedTemperatures.length === 1 || sortedTemperatures.length > SYNJET_PRO_MAX_HEATING_BLOCKS) {
    return availableTemperatures;
  }

  let freeBlocks = sortedTemperatures.length ? SYNJET_PRO_MAX_HEATING_BLOCKS - sortedTemperatures.length : 0;

  while (freeBlocks) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < uniqueTemps.length; i++) {
      if (i !== uniqueTemps.length - 1 || uniqueTemps.length < 2) {
        const index = sortedTemperatures.lastIndexOf(uniqueTemps[i]);
        sortedTemperatures.splice(index + 1, 0, null);
        freeBlocks -= 1;
      }
      if (!freeBlocks) break;
    }
  }

  return sortedTemperatures;
};

export const getHeatingBlocks = temperatures => {
  const availableTemperatures = getHeatingBlocksTemperatureOptions(temperatures, [], true);
  const blockOrder = mapBlockWithTemperature(availableTemperatures);
  const blocks = Array.from({ length: SYNJET_PRO_MAX_HEATING_BLOCKS }, (_, index) => ({
    label: String.fromCharCode(65 + index),
    temperature: blockOrder.length > SYNJET_PRO_MAX_HEATING_BLOCKS ? null : blockOrder[index] || null,
  }));
  return blocks;
};

const BG_HEATING_COLORS = [
  'rgba(228, 0, 43, 0.1)',
  'rgba(228, 82, 0, 0.1)',
  'rgba(228, 137, 0, 0.1)',
  'rgba(248, 211, 59, 0.1)',
  'rgba(248, 229, 59, 0.1)',
  'rgba(135, 199, 0, 0.1)',
  'rgba(49, 182, 27, 0.1)',
  'rgba(32, 168, 154, 0.12)',
];

const BORDER_HEATING_COLORS = [
  'rgba(228, 0, 43, 0.55)',
  'rgba(228, 82, 0, 0.55)',
  'rgba(228, 137, 0, 0.6)',
  '#F8D33B',
  '#F8E53B',
  '#87C700',
  '#31B61B',
  '#20A89A',
];

export const getHeatingBlockStyle = (currentTemperature, temperatures) => {
  const availableTemperatures = getHeatingBlocksTemperatureOptions(temperatures, [], true).sort(sortArrayDesc);
  const maxColor = Math.max(...availableTemperatures);
  const minColor = Math.min(...availableTemperatures);
  if (maxColor === currentTemperature) {
    return { borderColor: BORDER_HEATING_COLORS[0], backgroundColor: BG_HEATING_COLORS[0] };
  }
  if (minColor === currentTemperature) {
    return {
      borderColor: BORDER_HEATING_COLORS[SYNJET_PRO_MAX_HEATING_BLOCKS - 1],
      backgroundColor: BG_HEATING_COLORS[SYNJET_PRO_MAX_HEATING_BLOCKS - 1],
    };
  }

  const currentIndex = availableTemperatures.indexOf(currentTemperature);

  const order = SYNJET_PRO_MAX_HEATING_BLOCKS / availableTemperatures.length;

  const colorIndex = Math.round(order * currentIndex);

  return {
    borderColor: BORDER_HEATING_COLORS[colorIndex],
    backgroundColor: BG_HEATING_COLORS[colorIndex],
  };
};

export const isValidHeatingBlocks = (temperatures, heatingBlocks) => {
  const availableTemperatures = getHeatingBlocksTemperatureOptions(temperatures, [], true);
  const usedHeatingBlocks = heatingBlocks.filter(block => !!block.temperature);
  return usedHeatingBlocks.length === availableTemperatures.length;
};

export const updateSynjetProcessSteps = (
  steps,
  value,
  isSynjet,
  conditionName,
  temperatureOptions,
  experimentIndex,
  type,
  conditionIndex,
  showConditionSelector
) => {
  let updatedSteps = [];
  const propertyName = isSynjet ? 'fixed' : conditionName;
  const reflectedConditions = getReflectedTemperatures(propertyName, value, temperatureOptions[experimentIndex], type);
  const selectorIndex = steps.length > 1 ? 4 : 3;
  const firstIndex = steps.length > 1 ? 1 : 0;
  const selectorValue =
    (conditionIndex === selectorIndex || conditionIndex === firstIndex) && type === 'time' ? value : null;
  const selectorCondition = showConditionSelector(temperatureOptions[experimentIndex], type)
    ? getConditionValue(temperatureOptions[experimentIndex], type, steps.length === 1, selectorValue)
    : null;
  if (steps.length === 1) {
    const updatedConditions = steps[0].conditions.map((cond, i) =>
      i === conditionIndex || !isSynjet || (selectorCondition && i === 3)
        ? {
            ...cond,
            [type]: {
              ...cond[type],
              [propertyName]: selectorCondition && i === 3 ? selectorCondition : value,
              ...reflectedConditions,
            },
          }
        : cond
    );

    updatedSteps = [
      {
        ...steps[0],
        conditions: updatedConditions,
      },
    ];
  } else {
    updatedSteps = steps.map(step => ({
      ...step,
      conditions: step.conditions.map((condition, i) =>
        (step.isFixed && conditionName === 'fixed') ||
        (!step.isFixed && conditionName !== 'fixed' && i === conditionIndex - 1) ||
        (!step.isFixed && selectorCondition && i === 3) ||
        !isSynjet
          ? {
              ...condition,
              [type]: {
                ...condition[type],
                [propertyName]: selectorCondition && i === 3 ? selectorCondition : value,
                ...reflectedConditions,
              },
            }
          : condition
      ),
    }));
  }

  return updatedSteps;
};

export const updateSynjetProProcessSteps = (
  steps,
  value,
  isSynjet,
  conditionName,
  temperatureOptions,
  experimentIndex,
  type,
  conditionIndex
) => {
  let updatedSteps = [];
  const propertyName = 'fixed';
  if (steps.length === 1) {
    const updatedConditions = steps[0].conditions.map((cond, i) =>
      i === conditionIndex
        ? {
            ...cond,
            [type]: {
              ...cond[type],
              [propertyName]: value,
            },
          }
        : cond
    );

    updatedSteps = [
      {
        ...steps[0],
        conditions: updatedConditions,
      },
    ];
  } else {
    const isFirstFixed = steps?.[0]?.isFixed;
    updatedSteps = steps.map(step => ({
      ...step,
      conditions: step.conditions.map((condition, i) => {
        const condIndex = isFirstFixed ? conditionIndex - 1 : conditionIndex;
        if (
          (step.isFixed && conditionName === 'fixed') ||
          (!step.isFixed && conditionName !== 'fixed' && i === condIndex)
        ) {
          return {
            ...condition,
            [type]: {
              ...condition[type],
              [propertyName]: value,
            },
          };
        }
        return condition;
      }),
    }));
  }

  return updatedSteps;
};

export const updateSynjetProProducts = (steps, products, quenching = [], expectedIntermediates) => {
  if (steps.length === 1) {
    const updatedProducts = getLibGenProducts(steps[0], products, quenching, false, null, false, [], false, true);
    return { updatedProducts };
  }
  const isFirstFixed = steps?.[0]?.isFixed;

  const hasExpectedIntermediates = steps.length > 1 && !isFirstFixed;

  const variableStep = steps.find(step => !step.isFixed);
  const fixedStep = steps.find(step => step.isFixed);

  const newEI = hasExpectedIntermediates
    ? getLibGenProducts(variableStep, expectedIntermediates, null, false, fixedStep, false, null, true, true)
    : [];

  let newProducts = [];

  if (hasExpectedIntermediates) {
    newProducts = getLibGenProducts(variableStep, products, null, false, fixedStep, false, newEI, false, true);
  } else {
    newProducts = getLibGenProducts(variableStep, products, null, false, fixedStep, isFirstFixed, [], false, true);
  }
  return { updatedProducts: newProducts, updatedEI: newEI };
};

export const getUniqueTemperatures = tempOptions => {
  const usedTemperatures = tempOptions.reduce((temperatures, experimentConditions) => {
    const expTemperatures = Object.values(experimentConditions).map(cond => cond.temperature);
    return [...temperatures, ...expTemperatures];
  }, []);
  return usedTemperatures.sort(sortArrayDesc).filter(getUniqueValuesFromArray);
};
