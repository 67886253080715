import React from 'react';
import { WarningFilled } from '@ant-design/icons';

export const Warning = ({ data }) => {
  return (
    <div className="warning">
      <WarningFilled />
      {data}
    </div>
  );
};
