import React from 'react';

import { FIELD_DATA_TYPES } from '@organisms/ExperimentTemplateFieldPopup';
import moment from 'moment';

import { ReactComponent as AfterSvg } from '../../../dist/icons/predicates/after.svg';
import { ReactComponent as BeforeSvg } from '../../../dist/icons/predicates/before.svg';
import { ReactComponent as BetweenSvg } from '../../../dist/icons/predicates/between.svg';
import { ReactComponent as ChooseDateSvg } from '../../../dist/icons/predicates/choose-date.svg';
import { ReactComponent as ContainsSvg } from '../../../dist/icons/predicates/contains.svg';
import { ReactComponent as EmptySvg } from '../../../dist/icons/predicates/empty.svg';
import { ReactComponent as EqualsSvg } from '../../../dist/icons/predicates/equals.svg';
import { ReactComponent as GreaterOrEqualsSvg } from '../../../dist/icons/predicates/greater-equals.svg';
import { ReactComponent as GreaterThanSvg } from '../../../dist/icons/predicates/greater-than.svg';
import { ReactComponent as LessOrEqualsSvg } from '../../../dist/icons/predicates/less-equals.svg';
import { ReactComponent as LessThanSvg } from '../../../dist/icons/predicates/less-than.svg';
import { ReactComponent as NextSvg } from '../../../dist/icons/predicates/next.svg';
import { ReactComponent as NotContainsSvg } from '../../../dist/icons/predicates/not-contains.svg';
import { ReactComponent as NotEmptySvg } from '../../../dist/icons/predicates/not-empty.svg';
import { ReactComponent as NotEqualsSvg } from '../../../dist/icons/predicates/not-equals.svg';
import { ReactComponent as PreviousSvg } from '../../../dist/icons/predicates/previous.svg';
import { ReactComponent as ThisSvg } from '../../../dist/icons/predicates/this.svg';
import { ReactComponent as TodaySvg } from '../../../dist/icons/predicates/today.svg';
import { ReactComponent as TomorrowSvg } from '../../../dist/icons/predicates/tomorrow.svg';
import { ReactComponent as YesterdaySvg } from '../../../dist/icons/predicates/yesterday.svg';

export const DATEPICKER_AVAILABLE_OPTIONS = [
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Quarter',
    value: 'quarter',
  },
  {
    label: 'Year',
    value: 'year',
  },
];

export const BOOLEAN_AVAILABLE_OPTIONS = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

export const EQUALS_PREDICATE = {
  label: 'Equals',
  key: 'equals',
  initialValue: value => (Array.isArray(value) ? value : []),
  icon: <EqualsSvg />,
};

export const NOT_EQUALS_PREDICATE = {
  label: 'Does not equal',
  key: 'not_equal',
  initialValue: value => (Array.isArray(value) ? value : []),
  icon: <NotEqualsSvg />,
};

export const CONTAINS_PREDICATE = {
  label: 'Contains',
  key: 'contains',
  initialValue: value => value,
  icon: <ContainsSvg />,
};

export const NOT_CONTAINS_PREDICATE = {
  label: 'Does not contain',
  key: 'not_contain',
  initialValue: value => value,
  icon: <NotContainsSvg />,
};

export const EMPTY_PREDICATE = {
  label: 'Is empty',
  key: 'empty',
  initialValue: () => [],
  icon: <EmptySvg />,
};

export const NOT_EMPTY_PREDICATE = {
  label: 'Is not empty',
  key: 'not_empty',
  initialValue: () => [],
  icon: <NotEmptySvg />,
};

export const LESS_THAN_PREDICATE = {
  label: 'Less than',
  key: 'lt',
  initialValue: value => (Array.isArray(value) ? '' : value),
  icon: <LessThanSvg />,
};

export const LESS_OR_EQUAL_TO_PREDICATE = {
  label: 'Less or equal to',
  key: 'lte',
  initialValue: value => (Array.isArray(value) ? '' : value),
  icon: <LessOrEqualsSvg />,
};

export const GREATER_THAN_PREDICATE = {
  label: 'Greater than',
  key: 'gt',
  initialValue: value => (Array.isArray(value) ? '' : value),
  icon: <GreaterThanSvg />,
};

export const GREATER_OR_EQUAL_TO_PREDICATE = {
  label: 'Greater or equal to',
  key: 'gte',
  initialValue: value => (Array.isArray(value) ? '' : value),
  icon: <GreaterOrEqualsSvg />,
};

export const CHOOSE_DATE_PREDICATE = {
  label: 'Choose date',
  key: 'equals',
  initialValue: value => (moment(value).isValid() ? value : null),
  icon: <ChooseDateSvg />,
};

export const BETWEEN_PREDICATE = {
  label: 'Between',
  key: 'between',
  initialValue: () => null,
  icon: <BetweenSvg />,
};

export const TODAY_PREDICATE = {
  label: 'Today',
  key: 'today',
  initialValue: () => 'Today',
  icon: <TodaySvg />,
};

export const YESTERDAY_PREDICATE = {
  label: 'Yesterday',
  key: 'yesterday',
  initialValue: () => 'Yesterday',
  icon: <YesterdaySvg />,
};

export const TOMORROW_PREDICATE = {
  label: 'Tomorrow',
  key: 'tomorrow',
  initialValue: () => 'Tomorrow',
  icon: <TomorrowSvg />,
};

export const PREVIOUS_PREDICATE = {
  label: 'Previous',
  key: 'previous',
  initialValue: () => null,
  icon: <PreviousSvg />,
  availableOptions: DATEPICKER_AVAILABLE_OPTIONS,
};

export const THIS_PREDICATE = {
  label: 'This',
  key: 'this',
  initialValue: () => null,
  icon: <ThisSvg />,
  availableOptions: DATEPICKER_AVAILABLE_OPTIONS,
};

export const NEXT_PREDICATE = {
  label: 'Next',
  key: 'next',
  initialValue: () => null,
  icon: <NextSvg />,
  availableOptions: DATEPICKER_AVAILABLE_OPTIONS,
};

export const BEFORE_PREDICATE = {
  label: 'Before',
  key: 'before',
  initialValue: value => (moment(value).isValid() ? value : null),
  icon: <BeforeSvg />,
};

export const AFTER_PREDICATE = {
  label: 'After',
  key: 'after',
  initialValue: value => (moment(value).isValid() ? value : null),
  icon: <AfterSvg />,
};
export const EQUALS_BOOLEAN_PREDICATE = {
  label: 'Equals',
  key: 'equals',
  initialValue: () => null,
  icon: <EqualsSvg />,
  availableOptions: BOOLEAN_AVAILABLE_OPTIONS,
};

export const PREDICATES_BY_TYPE = (() => {
  const obj = {};
  Object.keys(FIELD_DATA_TYPES).forEach(key => {
    switch (key) {
      case FIELD_DATA_TYPES.short_text:
        obj[key] = [
          EQUALS_PREDICATE,
          NOT_EQUALS_PREDICATE,
          CONTAINS_PREDICATE,
          NOT_CONTAINS_PREDICATE,
          EMPTY_PREDICATE,
          NOT_EMPTY_PREDICATE,
        ];
        break;
      case FIELD_DATA_TYPES.long_text:
        obj[key] = [
          EQUALS_PREDICATE,
          NOT_EQUALS_PREDICATE,
          CONTAINS_PREDICATE,
          NOT_CONTAINS_PREDICATE,
          EMPTY_PREDICATE,
          NOT_EMPTY_PREDICATE,
        ];
        break;
      case FIELD_DATA_TYPES.numeric:
        obj[key] = [
          EQUALS_PREDICATE,
          LESS_THAN_PREDICATE,
          GREATER_THAN_PREDICATE,
          LESS_OR_EQUAL_TO_PREDICATE,
          GREATER_OR_EQUAL_TO_PREDICATE,
          EMPTY_PREDICATE,
          NOT_EMPTY_PREDICATE,
          NOT_EQUALS_PREDICATE,
        ];
        break;
      case FIELD_DATA_TYPES.decimal:
        obj[key] = [
          EQUALS_PREDICATE,
          LESS_THAN_PREDICATE,
          GREATER_THAN_PREDICATE,
          LESS_OR_EQUAL_TO_PREDICATE,
          GREATER_OR_EQUAL_TO_PREDICATE,
          EMPTY_PREDICATE,
          NOT_EMPTY_PREDICATE,
          NOT_EQUALS_PREDICATE,
        ];
        break;
      case FIELD_DATA_TYPES.date:
        obj[key] = [
          CHOOSE_DATE_PREDICATE,
          BETWEEN_PREDICATE,
          TODAY_PREDICATE,
          YESTERDAY_PREDICATE,
          TOMORROW_PREDICATE,
          PREVIOUS_PREDICATE,
          THIS_PREDICATE,
          NEXT_PREDICATE,
          BEFORE_PREDICATE,
          AFTER_PREDICATE,
        ];
        break;
      case FIELD_DATA_TYPES.datetime:
        obj[key] = [
          CHOOSE_DATE_PREDICATE,
          BETWEEN_PREDICATE,
          TODAY_PREDICATE,
          YESTERDAY_PREDICATE,
          TOMORROW_PREDICATE,
          PREVIOUS_PREDICATE,
          THIS_PREDICATE,
          NEXT_PREDICATE,
          BEFORE_PREDICATE,
          AFTER_PREDICATE,
        ];
        break;
      case FIELD_DATA_TYPES.boolean:
        obj[key] = [EQUALS_BOOLEAN_PREDICATE];
        break;
      case FIELD_DATA_TYPES.user:
        obj[key] = [EQUALS_PREDICATE, NOT_EQUALS_PREDICATE, EMPTY_PREDICATE, NOT_EMPTY_PREDICATE];
        break;
      case FIELD_DATA_TYPES.smile:
        obj[key] = [EQUALS_PREDICATE, NOT_EQUALS_PREDICATE, EMPTY_PREDICATE, NOT_EMPTY_PREDICATE];
        break;
      case FIELD_DATA_TYPES.select:
        obj[key] = [EQUALS_PREDICATE, NOT_EQUALS_PREDICATE, EMPTY_PREDICATE, NOT_EMPTY_PREDICATE];
        break;
      case FIELD_DATA_TYPES.multiselect:
        obj[key] = [EQUALS_PREDICATE, NOT_EQUALS_PREDICATE, EMPTY_PREDICATE, NOT_EMPTY_PREDICATE];
        break;
      case FIELD_DATA_TYPES.experiment:
        obj[key] = [EQUALS_PREDICATE, NOT_EQUALS_PREDICATE, EMPTY_PREDICATE, NOT_EMPTY_PREDICATE];
        break;
      default:
        obj[key] = [];
    }
  });

  return obj;
})();
