import moment from 'moment';

import { FIELD_DATA_TYPES } from '@organisms';
import {
  AFTER_PREDICATE,
  BEFORE_PREDICATE,
  BETWEEN_PREDICATE,
  CHOOSE_DATE_PREDICATE,
  EMPTY_PREDICATE,
  GREATER_OR_EQUAL_TO_PREDICATE,
  GREATER_THAN_PREDICATE,
  LESS_OR_EQUAL_TO_PREDICATE,
  LESS_THAN_PREDICATE,
  NOT_EMPTY_PREDICATE,
  PREDICATES_BY_TYPE,
} from '@templates';

import { getExperimentsByUuids } from '../../../store/experiment/experiment.actions';
import { getUsersByUuids } from '../../../store/users/users.actions';

const formatDateAndDateTimeTypesValueByPredicate = ({ predicateKey, value, format, reverse }) => {
  switch (predicateKey) {
    case CHOOSE_DATE_PREDICATE.key:
    case BEFORE_PREDICATE.key:
    case AFTER_PREDICATE.key:
      if (reverse) {
        return value?.[0] ? format(value[0]) : null;
      }
      return [format(value)];
    case BETWEEN_PREDICATE.key:
      return value?.map(date => format(date)) || [];
    default:
      if (reverse) {
        return value?.[0] || null;
      }
      return [value];
  }
};

const formatNumberTypeValueByPredicate = ({ predicateKey, value, reverse }) => {
  switch (predicateKey) {
    case LESS_THAN_PREDICATE.key:
    case GREATER_THAN_PREDICATE.key:
    case LESS_OR_EQUAL_TO_PREDICATE.key:
    case GREATER_OR_EQUAL_TO_PREDICATE.key:
      if (reverse) {
        return value ? `${parseFloat(value)}` : null;
      }
      return [parseFloat(value)];
    default:
      if (reverse) {
        return value?.map(i => `${i}`) || [];
      }
      return value?.map(i => parseFloat(i));
  }
};

export const formatValueByDataTypeAndPredicate = props => {
  const { dataType, value, reverse, predicateKey } = props;
  if ([EMPTY_PREDICATE.key, NOT_EMPTY_PREDICATE.key].includes(predicateKey)) {
    return value;
  }
  switch (dataType) {
    case FIELD_DATA_TYPES.numeric:
      return formatNumberTypeValueByPredicate(props);
    case FIELD_DATA_TYPES.decimal:
      return formatNumberTypeValueByPredicate(props);
    case FIELD_DATA_TYPES.date:
      return formatDateAndDateTimeTypesValueByPredicate({
        ...props,
        format: date => {
          if (reverse) {
            return moment(date);
          }
          return date.format().replace(/T.*/, '');
        },
      });
    case FIELD_DATA_TYPES.datetime:
      return formatDateAndDateTimeTypesValueByPredicate({
        ...props,
        format: date => {
          if (reverse) {
            return moment(date);
          }
          return date.toISOString().replace(/.\d+Z$/g, 'Z');
        },
      });
    case FIELD_DATA_TYPES.boolean:
      if (reverse) {
        return value?.[0] || [];
      }
      return [value];
    default:
      if (reverse) {
        return value;
      }
      return value?.map(val => val?.value || val);
  }
};

const filterDraftFilters = ({ value, predicate }) =>
  value === 0 ||
  (Array.isArray(value) ? !!value?.length : !!value) ||
  [EMPTY_PREDICATE.key, NOT_EMPTY_PREDICATE.key].includes(predicate.key);

export const mapFiltersToQueryFormat = filters =>
  filters.filter(filterDraftFilters).map(({ uuid, predicate, value, dataType }) => ({
    uuid,
    predicate: predicate.key,
    value: JSON.stringify(formatValueByDataTypeAndPredicate({ dataType, predicateKey: predicate.key, value })),
  }));

const getValueForMapFiltersToUrlQueryFormat = data => {
  const { value, predicateKey } = data;
  if ([EMPTY_PREDICATE.key, NOT_EMPTY_PREDICATE.key].includes(predicateKey)) {
    return value;
  }
  if (!(value === 0 || (Array.isArray(value) ? !!value?.length : !!value))) {
    return undefined;
  }
  if (data.dataType === FIELD_DATA_TYPES.smile) {
    return value;
  }
  return formatValueByDataTypeAndPredicate(data);
};

export const mapFiltersToUrlQueryFormat = filters =>
  JSON.stringify(
    filters.map(({ uuid, predicate, value, dataType }) => ({
      uuid,
      predicate: predicate.key,
      value: getValueForMapFiltersToUrlQueryFormat({ dataType, predicateKey: predicate.key, value }),
    }))
  );

export const mapFilterDataItem = ({ dataType, fieldName, uuid, name, availableOptions, value, predicateKey }) => {
  const predicateData = predicateKey
    ? PREDICATES_BY_TYPE[dataType].find(({ key }) => key === predicateKey)
    : PREDICATES_BY_TYPE[dataType][0];
  return {
    dataType,
    fieldName,
    uuid,
    name,
    value,
    predicate: predicateData,
    availableOptions: availableOptions || predicateData.availableOptions,
  };
};

export const parseFiltersFromUrl = async ({ filtersUrlValue, tableFieldsGrid }) =>
  Promise.all(
    filtersUrlValue.map(async filterUrlData => {
      const fieldGridData = tableFieldsGrid.find(({ uuid }) => uuid === filterUrlData.uuid);
      const predicateKey = filterUrlData.predicate;
      const { dataType } = fieldGridData;
      let value = formatValueByDataTypeAndPredicate({
        dataType,
        predicateKey,
        value: filterUrlData.value,
        reverse: true,
      });
      if (value && !!value?.length && dataType === FIELD_DATA_TYPES.user) {
        const usersData = await getUsersByUuids(value);
        value = usersData.map(({ firstName, lastName, uuid }) => ({
          label: `${firstName} ${lastName}`,
          value: uuid,
        }));
      }
      if (value && !!value?.length && dataType === FIELD_DATA_TYPES.experiment) {
        const experimentsData = await getExperimentsByUuids(value);
        value = experimentsData.map(experiment => ({ label: experiment.name, value: experiment.uuid }));
      }
      return mapFilterDataItem({
        ...fieldGridData,
        predicateKey,
        value,
      });
    })
  );
