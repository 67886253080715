import React, { useState } from 'react';

import { Select } from 'components';

export const TypeToSearchSelect = ({ getData, value, onChange }) => {
  const [focused, setFocused] = useState(false);

  return (
    <Select
      placeholder="Please type to select"
      mode="multiple"
      labelInValue
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      showArrow
      showSearch
      getOptions={search => getData(search)}
      typeToSearch={focused}
      value={value || undefined}
      onChange={(label, val) => {
        onChange(val);
      }}
    />
  );
};
