import './styles.scss';

import React from 'react';

import { Menu as AntdMenu } from 'antd';
import cn from 'classnames';

export const Menu = ({ items, className, onClick, selectedKeys }) => (
  <AntdMenu
    className={cn('menu', className)}
    onClick={menuItem => {
      onClick(menuItem, items);
    }}
    style={{ width: 256 }}
    mode="vertical"
    selectedKeys={selectedKeys}
  >
    {items.map(({ children, icon, label, key }) => {
      if (children) {
        return (
          <AntdMenu.SubMenu
            key={key}
            title={
              <span>
                {icon}
                {label}
              </span>
            }
          >
            {children.map(({ label: labelChild, key: keyChild, icon: iconChild }) => (
              <AntdMenu.Item key={keyChild}>
                {iconChild}
                {labelChild}
              </AntdMenu.Item>
            ))}
          </AntdMenu.SubMenu>
        );
      }
      return (
        <AntdMenu.Item key={key}>
          {icon}
          {label}
        </AntdMenu.Item>
      );
    })}
  </AntdMenu>
);
