import React from 'react';
import { StopOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { getConditionalLabel } from '../../../../utils/synjetHelpers';
import { Compound } from '../../../ProcessBuilder/WorkSpace/Compound';
import reagent from '../../../../dist/icons/process-builder/limited_reagent.svg';
import { usePdfContext } from '../../../PdfGenerator';

export const CompoundData = ({ data }) => {
  const { isPdf } = usePdfContext();
  return (
    <div className="process-preview-compound">
      <div className={cn('process-preview-compound_smiles', { empty: !data.smiles })}>
        {data.smiles ? (
          <Compound data={data.smiles || {}} padding={20} width={138} label={Math.random()} displayLoupe />
        ) : (
          <StopOutlined />
        )}
      </div>
      <div className="process-preview-compound_data">
        {data.smiles ? (
          <>
            <div className="process-preview-compound_data_name">{data.smiles?.inchiKey}</div>
            <div className="process-preview-compound_data_grey-text">
              {isPdf ? data.smiles?.compound_name?.split('').map(i => <span>{i}</span>) : data.smiles?.compound_name}
            </div>
            <div className="process-preview-compound_data_grey-text">
              {isPdf ? data.smiles?.inchi?.split('').map(i => <span>{i}</span>) : data.smiles?.inchi}
            </div>
            {data.solvents?.map(solvent => (
              <div className="process-preview-compound_data_small-text">
                {solvent.solvent?.compound_name}
                {solvent.fraction !== 1 && (
                  <div className="process-preview-compound_data_grey-text">({solvent.fraction})</div>
                )}
              </div>
            ))}
            <div className="process-preview-compound_data_row">
              <div className="process-preview-compound_data_grey-text">Concentration</div>
              <div className="process-preview-compound_data_small-text">
                {data.concentration} <span className="process-preview-compound_data_grey-text">M</span>
              </div>
            </div>
            <div className="process-preview-compound_data_row">
              <div className="process-preview-compound_data_grey-text">MW</div>
              <div className="process-preview-compound_data_small-text">
                {data.smiles?.molwt} <span className="process-preview-compound_data_grey-text">g/mol</span>
              </div>
            </div>
            {data.conditions &&
              data.conditions.map(item => (
                <div className="process-preview-compound_data_row">
                  <div className="process-preview-compound_data_grey-text">{getConditionalLabel(item)}</div>
                </div>
              ))}
          </>
        ) : (
          <div className="process-preview-compound_data_row">
            <div className="process-preview-compound_data_small-text">Null control (solvent only)</div>
          </div>
        )}
      </div>
      <div className="process-preview-compound_label">
        {data.name}
        {data.isLimiting && <img src={reagent} />}
      </div>
    </div>
  );
};
