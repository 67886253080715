import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useSelectableContext } from '@molecules';

import { COLUMN_RESIZER_DRAG_AREA_WIDTH, COLUMN_RESIZER_INDICATOR_ATTRIBUTE } from '../constants';

const ColumnResizerContext = createContext(null);

export const useColumnResizerContext = () => useContext(ColumnResizerContext) || { isColumnResizerContext: false };

export const useColumnResizerController = () => {
  const [leftOffset, setLeftOffset] = useState(-1);
  const [columnIndex, setColumnIndex] = useState(undefined);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const { isSelectableContext } = useSelectableContext();
  const getLeftOffset = event =>
    event.currentTarget.parentElement.offsetLeft + event.currentTarget.offsetLeft + COLUMN_RESIZER_DRAG_AREA_WIDTH / 2;

  const onMouseEnter = ({ event, columnIndex: columnInd }) => {
    if (!(isSelectableContext && columnInd === 0)) {
      setLeftOffset(getLeftOffset(event));
      setColumnIndex(prev => (prev !== undefined ? prev : columnInd));
    }
  };

  const onMouseMoveArea = data => {
    if (!columnIndex) {
      onMouseEnter(data);
    }
  };

  const onMouseLeave = useCallback(
    event => {
      if (!isMouseDown && event.relatedTarget.getAttribute('data-type') !== COLUMN_RESIZER_INDICATOR_ATTRIBUTE) {
        setLeftOffset(-1);
        setColumnIndex(undefined);
      }
    },
    [isMouseDown]
  );

  const reset = (resetData = {}) => {
    const { leftOffset: newLeftOffset } = resetData;
    if (newLeftOffset) {
      setLeftOffset(newLeftOffset);
    } else {
      setColumnIndex(undefined);
      setLeftOffset(-1);
    }
    setIsMouseDown(false);
  };
  return {
    onMouseLeave,
    onMouseEnter,
    leftOffset,
    columnIndex,
    isMouseDown,
    setIsMouseDown,
    reset,
    onMouseMoveArea,
  };
};

export const ColumnResizerHOC = ({ children }) => {
  const {
    onMouseLeave,
    onMouseEnter,
    columnIndex,
    leftOffset,
    isMouseDown,
    setIsMouseDown,
    reset,
    onMouseMoveArea,
  } = useColumnResizerController();

  const value = useMemo(
    () => ({
      isColumnResizerContext: true,
      leftOffset,
      columnIndex,
      onMouseEnter,
      onMouseLeave,
      isMouseDown,
      setIsMouseDown,
      reset,
      onMouseMoveArea,
    }),
    [leftOffset, columnIndex, columnIndex, onMouseLeave, isMouseDown]
  );

  return <ColumnResizerContext.Provider value={value}>{children}</ColumnResizerContext.Provider>;
};
