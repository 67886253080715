import React, { Component } from 'react';
import cn from 'classnames';

export const Bird = props => (
  <div onClick={props.onClick} className={cn(props.className, 'icon-component')} style={props.style}>
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2503 0.96875H12.1581C12.0049 0.96875 11.8596 1.03906 11.7659 1.15937L5.32369 9.32031L2.23463 5.40625C2.1879 5.34692 2.12833 5.29895 2.06041 5.26593C1.99249 5.23292 1.91796 5.21572 1.84244 5.21562H0.750253C0.645565 5.21562 0.587753 5.33594 0.651815 5.41719L4.9315 10.8391C5.1315 11.0922 5.51588 11.0922 5.71744 10.8391L13.3487 1.16875C13.4128 1.08906 13.3549 0.96875 13.2503 0.96875Z"
        fill={props.color || '#E00087'}
      />
    </svg>
  </div>
);
