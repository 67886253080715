import { SYNJET_PROCESS_TYPES, SYNJET_PRO_PROCESS_TYPES } from '../../../../constants';
import cn from 'classnames';
import React from 'react';

export const ConditionsAndDispensingValuesBlock = ({ children, title, type, isFixed }) => {
  const isScreening = type === SYNJET_PROCESS_TYPES.SCREENING || (type !== SYNJET_PROCESS_TYPES.SCREENING && isFixed);
  const isLibraryGenerationPro = type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION;
  return (
    <div
      className={cn('conditions-and-dispensing-options-block_content-block', {
        screening: isScreening || isLibraryGenerationPro,
      })}
    >
      <div className="header">
        {isScreening || isLibraryGenerationPro ? (
          <>
            <div>Conditions</div>
            <div>Temperature, °C</div>
            <div>Time</div>
          </>
        ) : (
          <>
            <div>Low</div>
            <div>Med</div>
            <div>High</div>
          </>
        )}
      </div>
      <div className="children row">
        <div className="children_title">{title}</div>
        {children}
      </div>
    </div>
  );
};
