import React, { useEffect, useRef } from 'react';
import SmilesDrawer from 'smiles-drawer';
import './style.scss';
import { LoupeButton } from '../../PopupWithStructureView/LoupeButton';

const options = {
  height: 80,
  width: 226,
};

export const SmilesElem = ({
  id,
  data,
  children,
  customOptions,
  displayLoupe = true,
  isNeededTitle = true,
  pdfMode,
}) => {
  const canvas = useRef(null);
  useEffect(() => {
    canvas.current = new SmilesDrawer.Drawer(customOptions || options);
    SmilesDrawer.parse(data.smiles, tree => {
      canvas.current.draw(tree, `smiles-structure_${id}${pdfMode ? '-pdf' : ''}`, 'light', false);
    });
  }, [data]);
  return (
    <div className="smiles-structure-wrapper">
      <div className="smiles-structure">
        {isNeededTitle && (
          <>
            <div className="smiles-structure_name">{data.compound_name}</div>
            <div className="smiles-structure_formula">{data.formula}</div>
          </>
        )}
        {children}
        <canvas id={`smiles-structure_${id}${pdfMode ? '-pdf' : ''}`} />
        {displayLoupe && <LoupeButton data={data} label={`smiles-structure_${id}${pdfMode ? '-pdf' : ''}`} />}
      </div>
    </div>
  );
};
