import React from 'react';
import './style.scss';
import { Button } from '../../../Common';
import { PROCESS_TYPES, TREE_ELEMENT_TYPES } from '../../../../constants';
import { ProcessInfo } from '../TreeElementsInfo/ProcessInfo/ProcessInfo';
import { RouteInfo } from '../TreeElementsInfo/RouteInfo/RouteInfo';
import { ExperimentInfo } from '../TreeElementsInfo/ExperimnetInfo';
import history from '../../../../utils/history';

const getPreviewComponent = (type, itemId, device) => {
  switch (type) {
    case TREE_ELEMENT_TYPES.experiment.key:
      return <ExperimentInfo itemId={itemId} isProjectPage={true} />;
    case TREE_ELEMENT_TYPES.route.key:
      return <RouteInfo itemId={itemId} />;
    case TREE_ELEMENT_TYPES.process.key:
      return <ProcessInfo itemId={itemId} device={device} />;
    default:
      return null;
  }
};

export const TreeElementDetails = ({ itemId, type, title, device = PROCESS_TYPES.AutoSyn.key }) => {
  const onOpenPress = () => {
    let devicePath;
    switch (device) {
      case PROCESS_TYPES.AutoSyn.device:
        devicePath = PROCESS_TYPES.AutoSyn.path;
        break;
      case PROCESS_TYPES.SynJet.device:
        devicePath = PROCESS_TYPES.SynJet.path;
        break;
      case PROCESS_TYPES.SynJetPro.device:
        devicePath = PROCESS_TYPES.SynJetPro.path;
        break;
      case PROCESS_TYPES.Lab.device:
        devicePath = PROCESS_TYPES.Lab.path;
        break;
    }
    switch (type) {
      case TREE_ELEMENT_TYPES.experiment.key:
        const expPage = window.open(`${devicePath}/details/${itemId}?tab=analytical`, '_blank');
        expPage.focus();
        return;
      case TREE_ELEMENT_TYPES.process.key:
        const processPage = window.open(`${devicePath}/process-builder/details/${itemId}`, '_blank');
        processPage.focus();
        return;
    }
  };

  return itemId ? (
    <div className="tree-element-details">
      <header className="tree-element-details__header">
        <h4>
          {TREE_ELEMENT_TYPES[type].label} {title}
        </h4>
        {type !== TREE_ELEMENT_TYPES.route.key && (
          <Button secondary onClick={onOpenPress}>
            Open
          </Button>
        )}
      </header>
      <section className="tree-element-details__content">{getPreviewComponent(type, itemId, device)}</section>
    </div>
  ) : null;
};
