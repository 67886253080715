import createReducer from '../createReducer.js';
import * as actionsTypes from './autosyn.actionTypes';
import * as commonActions from '../common/common.actionTypes';

const initialState = {
  processes: [],
  filters: {
    projects: [],
    statuses: [],
    createdBy: [],
    experimentStatuses: [],
  },
  typesProcesses: [],
  process: [],
};

function setProcesses(state, data) {
  return { ...state, processes: data.payload };
}

function setFiltersData(state, data) {
  return {
    ...state,
    filters: data.payload,
  };
}

function setFiltersCreators(state, data) {
  return {
    ...state,
    filters: { ...state.filters, createdBy: data.payload },
  };
}

function setNameInList(state, { payload }) {
  return {
    ...state,
    processes: {
      ...state.processes,
      results: state.processes.results.map(i => (i.uuid === payload.uuid ? { ...i, name: payload.name } : i)),
    },
  };
}

function setProcess(state, data) {
  return { ...state, process: data.payload };
}

function setTypesProcess(state, data) {
  return { ...state, typesProcesses: data.payload };
}

function clean(state) {
  return { ...state, processes: [] };
}

const processReducer = createReducer(initialState, {
  [actionsTypes.SET_PROCESSES_LIST]: setProcesses,
  [actionsTypes.SET_FILTERS_DATA_PROCESS]: setFiltersData,
  [actionsTypes.SET_NAME_IN_LIST]: setNameInList,
  [actionsTypes.SET_PROCESS]: setProcess,
  [actionsTypes.SET_TYPES_PROCESS]: setTypesProcess,
  [commonActions.CLEAN_USERS]: clean,
  [actionsTypes.SET_FILTERS_CREATORS]: setFiltersCreators,
});

export default processReducer;
