import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { Collapse } from '../../Common';
import { getProcessReactionsAndColumns } from './reactionsTableHelpers';
import { ReactionsTable } from '../../ProcessBuilder/ReactionsTable/ReactionsTable';

const ManualReactionsCollapse = props => {
  const { processData, wrapperClass, processType, showCheckboxes, defaultActiveKey = '' } = props;
  const [reactions, setReactions] = useState([]);
  const [columns, setColumns] = useState([]);

  const getReactionsAndColumns = useCallback(() => {
    const data = getProcessReactionsAndColumns(processData, processType);
    return data;
  }, [processData, processType]);

  useEffect(() => {
    const { columns, reactions } = getReactionsAndColumns();
    setColumns(columns);
    setReactions(reactions);
  }, [processData]);

  const ReactionPanel = {
    title: (
      <span className="reaction-accordion-section__title">
        <span className="reaction-accordion-section__title--bold">Reactions</span>{' '}
        <span className="reaction-accordion-section__title--faded">{reactions?.length || ''}</span>
      </span>
    ),
    key: 'reactions',
    body: (
      <ReactionsTable
        reactions={reactions}
        showCheckboxes={showCheckboxes}
        columns={columns}
        setReactions={setReactions}
      />
    ),
  };

  return (
    <div data-protected className={cn('reactions-accordion', { [wrapperClass]: wrapperClass })}>
      <Collapse items={[ReactionPanel]} defaultActiveKey={defaultActiveKey} />
    </div>
  );
};

export default ManualReactionsCollapse;
