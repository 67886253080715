import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, StatusLabel, Tooltip } from '../../Common';
import { isoToExecutionViewTimeZone, transferIsoToMMDDYYmmHH } from '../../../utils/date';
import { DEVICES, LABEL_STEPS } from '../../../constants';
import './styles.scss';

const InfoExp = ({ label, value, icon }) => (
  <div className="header-info_third_col">
    <span className="header-info_third_col_label">{label}</span>
    <span className="header-info_third_col_value">{icon ? <Avatar size={24} name={value} /> : value}</span>
  </div>
);

export const HeaderInfo = ({ commonInfo, estimatedTime }) => {
  const status = commonInfo?.status;
  const showScheduledTime = commonInfo?.timeSlot?.startAt && (status === 'In Preparation' || status === 'In Queue');

  const isSynJet = DEVICES.SYNJET === commonInfo?.process?.device || DEVICES.SYNJETPRO === commonInfo?.process?.device;
  return (
    <div className="header-info">
      <div className="header-info_first">
        <Tooltip title="Navigate to project">
          <Link
            className="header-info_first_project"
            to={`/projects/details/${commonInfo?.process?.project.uuid}`}
            target="_blank"
          >
            {commonInfo?.process?.project.name}
          </Link>
        </Tooltip>
        <StatusLabel type="active">{commonInfo?.status}</StatusLabel>
      </div>
      <div className="header-info_second">
        <span className="header-info_second_name">{commonInfo?.name}</span>
        {showScheduledTime && (
          <span className="header-info_second_date">
            Scheduled:{' '}
            <Tooltip title={transferIsoToMMDDYYmmHH(commonInfo?.timeSlot?.startAt)}>
              {isoToExecutionViewTimeZone(commonInfo?.timeSlot.startAt)}
            </Tooltip>
          </span>
        )}
      </div>
      <div className="header-info_third">
        <InfoExp label="Process name" value={commonInfo?.process?.name} />
        <InfoExp
          label="Process type"
          value={`${commonInfo?.process?.type} ${
            isSynJet ? `(${LABEL_STEPS[commonInfo?.process?.numberOfSteps]})` : ''
          }`}
        />
        <InfoExp
          label="Created by"
          value={`${commonInfo?.createdBy?.firstName || ''} ${commonInfo?.createdBy?.lastName || ''}`}
          icon
        />
        <InfoExp label="Estimated total time" value={estimatedTime} />
      </div>
      <div className="header-info_divider" />
    </div>
  );
};
