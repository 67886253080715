export const COLORS = [
  { value: '#20A89A' },
  { value: '#FF8A00' },
  { value: '#CC00FF' },
  { value: '#FFF733' },
  { value: '#3582E5' },
  { value: '#EF5D80' },
  { value: '#84FFCB' },
  { value: '#FFCC33' },
  { value: '#BF388B' },
  { value: '#FFA77B' },
  { value: '#489ED2' },
  { value: '#F80196' },
  { value: '#94BE1D' },
  { value: '#CC6600' },
  { value: '#781F82' },
  { value: '#FF1A45' },
  { value: '#346503' },
  { value: '#6EE0FF' },
  { value: '#660000' },
  { value: '#DCFFA2' },
  { value: '#717171' },
  { value: '#FCA9AE' },
  { value: '#431070' },
  { value: '#CA0808' },
  { value: '#999900' },
  { value: '#6633FF' },
  { value: '#FF5C00' },
  { value: '#00256C' },
  { value: '#D48484' },
  { value: '#0033FF' },
];
export const OPTION_FIELD = { color: 'color', value: 'value' };
