import React, { useEffect, useRef, useState } from 'react';

export const Jsme = ({ id, smiles, handleChangeSmiles }) => {
  const jsmeApplet = useRef(null);

  let timer = null;

  const initJsme = () => {
    if (window.JSApplet) {
      jsmeApplet.current = new window.JSApplet.JSME(id, '380px', '340px', {
        // optional parameters
        options: 'query,hydrogens',
      });
      jsmeApplet.current.setCallBack('AfterStructureModified', e => {
        const jsme = e.src;
        const newSmiles = jsme.smiles();
        if (smiles !== newSmiles) handleChangeSmiles(newSmiles);
      });
      clearInterval(timer);
      timer = null;
    }
  };
  useEffect(() => {
    timer = setInterval(initJsme, 200);
  }, []);
  return <div id={id} />;
};
