import React, { useState, useEffect } from 'react';
import './style.scss';
import { openNotification, Popup, Spinner } from 'components/Common';
import { parseJSONErrors } from 'utils';
import { saveExperimentResult } from 'store/experiment/experiment.actions';
import { AppendResultsForm } from './AppendResultsForm/AppendResultsForm';
import { AppendSJProResultsForm } from './AppendResultsForm/AppendSJProResultsForm';

export const initialState = {
  workup: '',
  purification: 'None',
  purificationComment: '',
};

export const initialErrors = {
  purificationComment: false,
  time: [],
};

export const appendedInitialState = {
  lcms: [],
  _13cnmr: [],
  _1hnmr: [],
  purification: [],
  'Offline LC-MS': [],
  'Mass spectrum': [],
  'UV-vis': [],
  'Reaction attachment': [],
};

export const PopupAppendResults = ({
  open,
  handleCancel,
  experimantData,
  updateResult,
  getExperiment,
  isSynJet,
  isSynJetPro,
  resultData,
  isLabDevice,
}) => {
  const initialStateForData = isSynJetPro
    ? { ...initialState, purificationComment: experimantData?.purification }
    : { ...initialState };

  const [hasChanges, setHasChanges] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [data, setData] = useState({ ...initialStateForData });
  const [errors, setErrors] = useState({ ...initialErrors });
  const [appendedFiles, setAppendedFiles] = useState({ ...appendedInitialState });
  const [reactions, setReactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!resultData) {
      setData({ ...initialStateForData });
    }
  }, [resultData]);

  const handleSubmit = () => {
    let { workup, purification, purificationComment } = data;
    const funcThen = resp => {
      if (resp?.errors) {
        parseJSONErrors(resp?.errors).forEach(err => {
          openNotification(null, err);
        });
        return;
      }
      if (getExperiment) getExperiment(experimantData?.key);
      updateResult();
      openNotification('The experiment results were updated');
      handleCancel();
    };

    const funcFinally = () => {
      setLoading(false);
    };
    if (isSynJet) {
      setLoading(true);
      saveExperimentResult(
        {
          experiment: experimantData.uuid,
          notes: workup ? encodeURIComponent(workup) : '',
          attachments: [
            ...appendedFiles['Offline LC-MS'],
            ...appendedFiles['Mass spectrum'],
            ...appendedFiles['UV-vis'],
          ].map(file => file.uuid),
        },
        true
      )
        .then(funcThen)
        .finally(funcFinally);
    } else if (isSynJetPro) {
      setLoading(true);
      let productsInfo = [...reactions];
      const res = {
        experiment: experimantData.uuid,
        workup: workup ? encodeURIComponent(workup) : null,
        purificationType: purification,
        purificationDescription: purificationComment ? encodeURIComponent(purificationComment) : null,
        productsInfo: productsInfo.map((reaction, index) => {
          reaction.mass = reaction.mass ? reaction.mass.toString().substr(0, 10) : reaction.mass;
          reaction.product_yield = reaction.product_yield
            ? reaction.product_yield.toString().substr(0, 10)
            : reaction.product_yield;
          delete reaction.name;
          reaction.attachments =
            appendedFiles['Reaction attachment'].length > 0
              ? [...appendedFiles['Reaction attachment'][index].map(file => file.uuid)].filter(item => item)
              : [];
          return reaction;
        }),
        attachments: [...appendedFiles.purification, ...appendedFiles['Offline LC-MS']].map(file => file.uuid),
      };
      // to prevent invalid values inside times inputs
      setReactions(reactions.map((reaction, i) => ({ ...reaction })));
      saveExperimentResult(res).then(funcThen).finally(funcFinally);
    } else {
      if (purification !== 'None' && !!purification && !purificationComment) {
        setErrors({ ...errors, purificationComment: true });
        return;
      } else if (errors.time.find(timeItem => !!timeItem)) {
        return;
      }
      let productsInfo = [...reactions];
      let reactionTimes = [];
      setLoading(true);
      const res = {
        experiment: experimantData.uuid,
        workup: workup ? encodeURIComponent(workup) : null,
        purificationType: purification,
        purificationDescription: purificationComment ? encodeURIComponent(purificationComment) : null,
        productsInfo: productsInfo.map(reaction => {
          reactionTimes.push({
            start_collection: { ...reaction.start_collection },
            end_collection: { ...reaction.end_collection },
          });
          reaction.mass = reaction.mass ? reaction.mass.toString().substr(0, 10) : reaction.mass;
          reaction.product_yield = reaction.product_yield
            ? reaction.product_yield.toString().substr(0, 10)
            : reaction.product_yield;
          delete reaction.name;
          reaction.start_collection = reaction.start_collection.format('HH:mm');
          reaction.end_collection = reaction.end_collection.format('HH:mm');
          return reaction;
        }),
        attachments: [
          ...appendedFiles.purification,
          ...appendedFiles.lcms,
          ...appendedFiles._1hnmr,
          ...appendedFiles._13cnmr,
        ].map(file => file.uuid),
      };
      // to prevent invalid values inside times inputs
      setReactions(reactions.map((reaction, i) => ({ ...reaction, ...reactionTimes[i] })));
      saveExperimentResult(res).then(funcThen).finally(funcFinally);
    }
  };

  const onCancel = () => {
    if (hasChanges) {
      setConfirmationOpen(true);
      return;
    }
    handleCancel();
  };

  const handleSubmitConfirmation = () => {
    if (
      resultData?.workup !== data.workup ||
      resultData?.purificationType !== data.purification ||
      resultData?.purificationDescription !== data.purificationComment
    ) {
      setData({
        ...data,
        workup: resultData?.workup || '',
        purification: resultData?.purificationType || null,
        purificationComment: resultData?.purificationDescription || data.purificationComment || '',
      });
      setHasChanges(false);
    }
    setConfirmationOpen(false);
    handleCancel();
  };

  let disabledSave = false;
  Object.keys(appendedFiles).forEach(type => {
    if (type != 'Reaction attachment') {
      if (appendedFiles[type].find(i => !i.loaded)) {
        disabledSave = true;
        return;
      }
    } else {
      appendedFiles[type].forEach(reaction => {
        if (reaction.find(i => i.file && !i.loaded)) {
          disabledSave = true;
          return;
        }
      });
    }
  });

  return (
    <>
      <Popup
        title="Experiment results"
        open={open}
        closable={true}
        className={isSynJetPro ? 'popup-append-results popup-append-results-sjpro' : 'popup-append-results'}
        textCancle="Cancel"
        textSubmit="Apply"
        handleCancel={onCancel}
        handleSubmit={handleSubmit}
        loading={false}
        disabledPrimaryButton={disabledSave}
      >
        <Spinner loading={loading}>
          {isSynJetPro ? (
            <AppendSJProResultsForm
              setHasChanges={setHasChanges}
              data={data}
              errors={errors}
              appendedFiles={appendedFiles}
              reactions={reactions}
              setReactions={setReactions}
              setErrors={setErrors}
              experimantData={experimantData}
              resultData={resultData}
              setData={setData}
              setAppendedFiles={setAppendedFiles}
            />
          ) : (
            <AppendResultsForm
              isSynJet={isSynJet}
              setHasChanges={setHasChanges}
              data={data}
              errors={errors}
              appendedFiles={appendedFiles}
              reactions={reactions}
              setReactions={setReactions}
              setErrors={setErrors}
              experimantData={experimantData}
              resultData={resultData}
              setData={setData}
              setAppendedFiles={setAppendedFiles}
              isLabDevice={isLabDevice}
            />
          )}
        </Spinner>
      </Popup>
      <Popup
        open={confirmationOpen}
        title="Warning"
        handleSubmit={handleSubmitConfirmation}
        textSubmit="Proceed"
        textCancle="Cancel"
        handleCancel={() => setConfirmationOpen(false)}
      >
        All changes will be lost, proceed?
      </Popup>
    </>
  );
};
