import { createBrowserHistory } from 'history';
import history from '../utils/history';

export default createBrowserHistory({});

export const updateURLParams = params => {
  const keys = new URLSearchParams(window.location.search).keys();
  let currentUrlParams = new URLSearchParams(window.location.search);
  for (const key of keys) currentUrlParams.delete(key);
  params.forEach(param => {
    currentUrlParams.set(param.key, param.value);
  });
  history.replace(window.location.pathname + '?' + currentUrlParams.toString());
  // createBrowserHistory({}).push(window.location.pathname + '?' + currentUrlParams.toString());
};
