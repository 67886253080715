import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as CheckmarkSVG } from 'dist/images/CheckMark.svg';
import { ReactComponent as CancelIconSVG } from 'dist/images/CancelIcon.svg';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, openNotification, Select, Table } from '../../../../Common';
import './style.scss';
import { SmilesElem } from '../../../PrepareMaterials/SmilesElem';
import { checkNumericField, httpSynMolDB } from '../../../../../utils';
import { INPUT_EXCLUDE_SYMBOLS } from '../../../../../constants/regexp';
import {
  getBatchMetaData,
  updateSJPExperimentsReactionsInfo,
} from '../../../../../store/experiment/experiment.actions';
import { getUniqueCompounds } from '../../../../../utils/execution';

const setFixed = value => {
  if (!value) return '';
  const valueSplit = `${value}`.split('.');
  if (valueSplit.length === 2) {
    return value.toFixed(3);
  }
  return `${value}.00`;
};

export const SolutionsTable = ({
  batch,
  tableData,
  setTableData,
  experiments,
  setExperiments,
  errors,
  setErrors,
  setEdited,
  setLoading,
  pdfMode,
}) => {
  const [solventsOptions, setSolventsOptions] = useState([]);
  const { key: batchKey } = useParams();
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState('');
  const [editTableData, setEditTableData] = useState(tableData);
  const [oldSolvents, setOldSolvents] = useState([]);

  const isEditing = record => record.key === editingKey;

  useEffect(() => {
    if (!pdfMode) {
      httpSynMolDB('get_compound_list?tag=solvent&return_compatibility=true').then(resp => {
        setSolventsOptions([{ compound_name: 'None' }, ...resp.compound_list]);
      });
    }
  }, []);

  const getSolventOptions = () =>
    solventsOptions.map(compound => ({
      value: compound.compound_name,
      label: compound.compound_name,
    }));

  const getSolutionId = item => `${item.compound_id}__${item.solvents.map(s => `${s.compound_id}-${s.fraction}`)}`;

  const updateExperimentsReactionsInfo = (expId, reactionsInfo) => {
    setExperiments(prev =>
      prev.map(exp => {
        if (exp.uuid === expId) {
          return {
            ...exp,
            reactionsInfo: JSON.stringify(reactionsInfo),
          };
        }

        return exp;
      })
    );
  };

  const getRowBySolutionId = (data, solutionId) => data.find(i => getSolutionId(i) === solutionId);

  const updateMetaData = () => {
    dispatch(getBatchMetaData({ key: batchKey })).then(response => {
      const updateTableData = JSON.parse(response.metaData).solution_structure;
      const solutionsIds = editTableData.map(i => getSolutionId(i));
      setTableData(
        updateTableData.map(item =>
          solutionsIds.filter(oldIdx => oldIdx === getSolutionId(item)).length === 1
            ? getRowBySolutionId(editTableData, getSolutionId(item))
            : item
        )
      );
    });
  };

  const sendUpdateReactionsInfo = async (solventsData, oldSolventsData, rowIdx) => {
    if (isEqual(solventsData, oldSolventsData)) throw new Error();
    const updatePayload = {
      batch: batch.uuid,
      experiments: [],
    };
    const row = tableData[rowIdx];
    row.solvents = oldSolventsData
      .filter(i => i.compound_id)
      .map(j => ({ fraction: +j.fraction, solvent: j.compound_id }));

    experiments.forEach(exp => {
      const newReactionsInfo = JSON.parse(exp.reactionsInfo);
      const { compounds } = newReactionsInfo;

      const findCompound = (item, field) => {
        if (item[field] !== row.compound_id) return item;
        const isMatch = isEqual(row.solvents, item.solvents);

        if (isMatch) {
          return {
            ...item,
            solvents: solventsData.map(j => ({ fraction: j.fraction, solvent: j.compound_id })),
          };
        }

        return item;
      };

      newReactionsInfo.reactants = newReactionsInfo.reactants.map(reactant => findCompound(reactant, 'reactant'));
      newReactionsInfo.reagents = newReactionsInfo.reagents.map(reagent => findCompound(reagent, 'reagent'));
      newReactionsInfo.quenching = newReactionsInfo.quenching.map(quenching => findCompound(quenching, 'compound'));

      newReactionsInfo.compounds = getUniqueCompounds(newReactionsInfo, compounds, solventsOptions);
      updatePayload.experiments.push({
        experiment: exp.uuid,
        reactionsInfo: newReactionsInfo,
      });
    });

    if (updatePayload.experiments.length) {
      const response = await dispatch(updateSJPExperimentsReactionsInfo(updatePayload));
      if (response.errors) throw Error();
      updatePayload.experiments.forEach(i => updateExperimentsReactionsInfo(i.experiment, i.reactionsInfo));
    }
  };

  const handleSolventChange = (value, idxRow, idxSolvent, isFraction) => {
    const solventData = isFraction
      ? null
      : solventsOptions.find(solventOption => solventOption.compound_name === value);
    if (isFraction && errors?.fraction) {
      setErrors({
        ...errors,
        fraction: errors.fraction.map((fractionError, fractionIndex) => {
          if (fractionIndex === idxRow) {
            return { ...fractionError, solventsIdx: fractionError.solventsIdx.filter(i => i !== idxSolvent) };
          }
          return fractionError;
        }),
      });
    } else if (errors?.solvents) {
      setErrors({
        ...errors,
        solvents: errors.solvents.map((solventError, solventIndex) => {
          if (solventIndex === idxRow) {
            return { ...solventError, solventsIdx: solventError.solventsIdx.filter(i => i !== idxSolvent) };
          }
          return solventError;
        }),
      });
    }
    const updateSolventsData = editTableData[idxRow].solvents.map((solvent, solventIndex) =>
      solventIndex === idxSolvent
        ? isFraction
          ? { ...solvent, fraction: checkNumericField(value, 1, 3) }
          : { ...solvent, compound_id: solventData.compound_id }
        : solvent
    );
    setEditTableData(prevState =>
      prevState.map((i, idx) => {
        if (idxRow === idx) {
          return {
            ...i,
            solvents: updateSolventsData,
          };
        }
        return i;
      })
    );
  };

  const handleSolventAdd = index => {
    setOldSolvents(editTableData[index].solvents);
    setEditTableData(prevState =>
      prevState.map((i, idx) => {
        if (index === idx) {
          return {
            ...i,
            solvents: [...i.solvents, { compound_id: null, fraction: 0 }],
          };
        }
        return i;
      })
    );
  };

  const handleSolventDelete = index => {
    setOldSolvents(editTableData[index].solvents);
    setEditTableData(prevState =>
      prevState.map((i, idx) => {
        if (index === idx) {
          return {
            ...i,
            solvents: [{ ...i.solvents[0], fraction: 1 }],
          };
        }
        return i;
      })
    );
  };

  const getCompound = compoundId => {
    let compound = null;
    const func = (j, id) => {
      if (j.reactionsInfo) {
        const compoundData =
          JSON.parse(j.reactionsInfo).compounds.find(k => k.compound_id === id) ||
          solventsOptions.find(k => k.compound_id === id);
        if (compoundData) {
          compound = compoundData;
          return true;
        }
      }

      return false;
    };

    experiments.find(a => func(a, compoundId));
    return compound;
  };

  const validationInput = value => {
    let error = false;
    const val = value.split('.');

    if (val.length > 2) return true;

    if (val.length === 2) {
      if (val[0].length === 0 || val[0].length === 11) error = true;
      else if (val[1].length === 3) error = true;
    } else if (val[0].length === 11) error = true;

    return error;
  };

  const handleChangeInput = (value, field, index) => {
    if (validationInput(value)) return;
    const updateInput = data =>
      data.map((i, idx) => {
        if (index === idx) {
          if (!i.compound_id) {
            return {
              ...i,
              actual_volume: value === '00' ? '0' : value,
            };
          }

          const { molwt } = getCompound(i.compound_id);

          let volume = null;
          let mass = null;

          if (+value) {
            if (field === 'actual_volume') {
              mass = (i.concentration * +value * molwt).toFixed(2);
              volume = value;
            }

            if (field === 'actual_mass') {
              volume = (+value / (i.concentration * molwt)).toFixed(2);
              mass = value;
            }
          } else if (value === '0' || value.startsWith('0.')) {
            field === 'actual_volume' ? (volume = value) : (mass = value);
          }

          return {
            ...i,
            actual_volume: volume,
            actual_mass: mass,
          };
        }
        return i;
      });
    setTableData(updateInput(tableData));
    setEditTableData(updateInput(editTableData));
    setEdited(true);
    setErrors({
      ...errors,
      [field]: errors[field].filter(i => i.index !== index),
    });
  };

  const getMappedSolvents = solvents =>
    solvents.map(i => ({ fraction: i.fraction, compound: getCompound(i.compound_id) }));

  const setRowSolutionTable = (dispenser, key) => {
    const compound = getCompound(dispenser.compound_id);

    return {
      key,
      allData: dispenser,
      name: {
        value: compound,
      },
      solvent: { value: getMappedSolvents(dispenser?.solvents) },
      mw: { value: compound?.molwt },
      concentration: { value: dispenser.concentration },
      calc_volume: { value: dispenser.calculated_volume },
      calc_mass: { value: dispenser.calculated_mass },
      actual_volume: { value: dispenser.actual_volume },
      actual_mass: { value: dispenser.actual_mass },
    };
  };

  const setSolutionTableData = data => (data ? data.map((i, idx) => setRowSolutionTable(i, idx)) : []);

  const checkRowValidation = (rowData, index) => {
    let isFractionError = false;
    let isSolventError = false;
    const err = {
      fraction: [],
      solvents: [],
    };

    const fragmentSum = rowData?.solvents.reduce((sum, currentSolvent) => +currentSolvent.fraction + sum, 0);
    if (rowData.solvents.length && fragmentSum !== 1) {
      isFractionError = true;
      err.fraction.push({
        index,
        solventsIdx: [...rowData.solvents.map((i, idxSolvent) => idxSolvent)],
      });
    }

    const solventsIdx = [];
    rowData.solvents.forEach((solvent, solventIdx) => {
      if (!solvent.compound_id) {
        isSolventError = true;
        solventsIdx.push(solventIdx);
      }
    });
    if (isSolventError) err.solvents.push({ index, solventsIdx });

    if (isFractionError) openNotification('', 'The sum of solvent Fractions should be equal to 1.0');
    if (isSolventError) openNotification('', 'Solvent is a required field');

    setErrors(prevState => ({ ...prevState, ...err }));
    return !(isFractionError || isSolventError);
  };

  const edit = record => {
    setOldSolvents(tableData[record.key].solvents.map(i => ({ ...i, fraction: +i.fraction })));
    setEditTableData(tableData);
    setEditingKey(record.key);

    if (!tableData[record.key].solvents.length) {
      setEditTableData(
        editTableData.map((i, idx) =>
          idx === record.key ? { ...i, solvents: [{ fraction: 1, compound_id: null }] } : i
        )
      );
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const updateFractionToNum = (data, index) =>
    data.map((item, idx) => {
      if (index === idx) {
        return {
          ...item,
          solvents: item.solvents.map(i => ({ ...i, fraction: +i.fraction })),
        };
      }

      return item;
    });

  const save = async key => {
    if (checkRowValidation(editTableData[key], key)) {
      const newTableData = await updateFractionToNum(editTableData, key);
      setLoading(true);
      sendUpdateReactionsInfo(newTableData[key].solvents, oldSolvents, key)
        .then(() => {
          updateMetaData();
        })
        .catch(e => console.log(e))
        .finally(() => {
          setLoading(false);
          setTableData(newTableData);
          setEditingKey('');
        });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '0',
      editable: false,
      render: (value, row, index) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (!row.name.value) {
          const data = row.solvent?.value[0]?.compound;
          obj.children = (
            <div className="syn-jet-materials_table_solvent-norm">
              <span>{data?.compound_name}</span>
              <span>(Solvent)</span>
            </div>
          );
        } else if (!row?.allData?.is_normalized && row.name.value) {
          obj.children = <SmilesElem id={index} data={row.name.value} displayLoupe pdfMode={pdfMode} />;
        }
        return obj;
      },
    },
    {
      title: 'Solvent',
      dataIndex: 'solvent',
      width: '0',
      editable: true,
      render: (value, row, idxRow) => {
        const obj = {
          children: <div className={row.className}>&nbsp;</div>,
        };
        if (row.solvent.value && row.name.value) {
          if (isEditing(row) && !pdfMode) {
            const errorFraction = errors.fraction.find(i => idxRow === i.index);
            const errorSolvents = errors.solvents.find(i => idxRow === i.index);

            const mappedSolvents = getMappedSolvents(editTableData[idxRow].solvents);
            obj.children = (
              <div className={cn('syn-jet-materials_solutions-table_solvent--edit-mode', row.className)}>
                <div>
                  {mappedSolvents.map((solvent, idxSolvent, solvents) => (
                    <>
                      <div className="select-row">
                        <Select
                          options={getSolventOptions()}
                          placeholder="None"
                          className="solvent-select"
                          value={solvent?.compound?.compound_name}
                          onChange={s => handleSolventChange(s, idxRow, idxSolvent)}
                          error={errorSolvents && errorSolvents.solventsIdx.includes(idxSolvent)}
                        />
                        {idxSolvent === 1 && (
                          <Button type="text" className="delete-solvent" onClick={() => handleSolventDelete(idxRow)}>
                            <DeleteOutlined />
                          </Button>
                        )}
                      </div>
                      <div className="fraction-row">
                        <span className="fraction-row__text">Fraction</span>
                        <Input
                          className="fraction-row__input"
                          placeholder="0"
                          maxLength={5}
                          disabled={solvents.length === 1}
                          onChange={fractionValue => handleSolventChange(fractionValue, idxRow, idxSolvent, true)}
                          value={solvent.fraction}
                          regex={INPUT_EXCLUDE_SYMBOLS}
                          error={errorFraction && errorFraction.solventsIdx.includes(idxSolvent)}
                        />
                      </div>
                      {solvents?.length === 1 && solvents[0]?.compound?.compound_name !== 'None' && (
                        <Button type="text" className="add-solvent" onClick={() => handleSolventAdd(idxRow)}>
                          <PlusOutlined /> ADD SOLVENT
                        </Button>
                      )}
                    </>
                  ))}
                </div>
              </div>
            );
          } else {
            obj.children = (
              <div className={cn('syn-jet-materials_table_solvent', row.className)}>
                {row.solvent.value.map((i, idx, arr) => {
                  const useComma = arr.length - 1 !== idx;
                  return (
                    <>
                      <span>{i.compound?.compound_name || i.compound?.formula}</span>
                      <span className="syn-jet-materials_table_solvent_extra">
                        {i.fraction === 1 ? '' : `(${i.fraction})`}
                      </span>
                      {useComma ? ', ' : ''}
                    </>
                  );
                })}
              </div>
            );
          }
        }
        return obj;
      },
    },
    {
      title: 'MW (g/mol)',
      dataIndex: 'mw',
      width: '0',
      editable: false,
      render: (value, row) => {
        const obj = {
          children: <div className={cn('syn-jet-materials_table_mw', row.className)}>{value?.value}</div>,
        };

        if (!row.name.value) {
          const data = row.solvent?.value[0]?.compound;
          obj.children = <div className={cn('syn-jet-materials_table_mw', row.className)}>{data?.molwt}</div>;
        }

        return obj;
      },
    },
    {
      title: 'Concentration (M)',
      dataIndex: 'concentration',
      width: '0',
      editable: false,
      render: (value, row) => {
        const obj = {
          children: <div className={cn('syn-jet-materials_table_concentration', row.className)}>{value.value}</div>,
        };
        if (row?.allData?.is_normalized) {
          obj.children = <div className={cn('syn-jet-materials_table_concentration', row.className)}>&nbsp;</div>;
        }
        return obj;
      },
    },
    {
      title: 'Calculated',
      children: [
        {
          title: 'Volume (mL)',

          dataIndex: 'calc_volume',
          width: '0',
          editable: false,
          render: (value, row) => {
            const obj = {
              children: (
                <div className={cn('syn-jet-materials_table_volume', row.className)}>
                  {Math.ceil(value.value * 100) / 100}
                </div>
              ),
            };
            return obj;
          },
        },
        {
          title: 'Mass (mg)',
          dataIndex: 'calc_mass',
          width: '0',
          editable: false,
          render: (value, row) => {
            const obj = {
              children: (
                <div className={cn('syn-jet-materials_table_volume', row.className)}>{setFixed(value.value)}</div>
              ),
            };

            if (row?.allData?.is_normalized) {
              obj.children = <div className={cn('syn-jet-materials_table_volume', row.className)}>&nbsp;</div>;
            }
            return obj;
          },
        },
      ],
    },
    {
      title: 'Actual',
      children: [
        {
          title: 'Volume (mL)',
          dataIndex: 'act_volume',
          width: '0',
          editable: false,
          render: (value, row, index) => {
            const obj = {
              children: (
                <div className={cn('syn-jet-materials_table_act_volume', row.className)}>
                  {row.actual_volume?.value}
                </div>
              ),
            };

            if ((row.calc_volume.value || row.calc_volume.value === 0) && !pdfMode) {
              const error = errors.actual_volume.find(i => index === i.index);

              obj.children = (
                <Input
                  regex={/[^\d|.]+/g}
                  value={row.actual_volume.value}
                  className={cn('syn-jet-materials_table_act_volume', row.className)}
                  field="actual_volume"
                  error={error?.field === 'actual_volume'}
                  onChange={(...args) => handleChangeInput(...args, index)}
                />
              );
            }
            return obj;
          },
        },
        {
          title: 'Mass (mg)',
          dataIndex: 'act_mass',
          width: '0',
          editable: false,
          render: (value, row, index) => {
            const obj = {
              children: (
                <div className={cn('syn-jet-materials_table_act_volume', row.className)}>
                  {row.calc_mass.value ? row.actual_mass?.value : ''}
                </div>
              ),
            };

            if (row.calc_mass.value && !pdfMode) {
              const error = errors.actual_mass.find(i => index === i.index);

              obj.children = (
                <Input
                  regex={/[^\d|.]+/g}
                  value={row.actual_mass?.value}
                  className={cn('syn-jet-materials_table_act_volume', row.className)}
                  onChange={(...args) => handleChangeInput(...args, index)}
                  field="actual_mass"
                  error={error?.field === 'actual_mass'}
                />
              );
            }
            return obj;
          },
        },
      ],
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '50px',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="edit-buttons">
            <div className="edit-buttons__item" onClick={() => save(record.key)}>
              <CheckmarkSVG />
            </div>
            <div className="edit-buttons__item" onClick={cancel}>
              <CancelIconSVG />
            </div>
          </div>
        ) : (
          <div className="edit-buttons">&nbsp;</div>
        );
      },
    },
  ];

  if (pdfMode) columns.pop();

  return (
    <Table
      className="solutions-table"
      rowClassName={row =>
        `syn-jet-materials_solutions-table_row${isEditing(row) ? '-active' : ''}${
          !row.name.value ? ' disabled-hover' : ''
        }`
      }
      columns={columns}
      dataSource={setSolutionTableData(tableData)}
      onRow={row => ({
        onClick: event => (!isEditing(row) && !event.target.matches('input') && row.name.value ? edit(row) : null),
      })}
      sidePadding={false}
    />
  );
};
