import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import SmilesDrawer from 'smiles-drawer';
import './index.scss';
import { useSelector } from 'react-redux';
import { StopOutlined } from '@ant-design/icons';
import { Tooltip } from '../Common';
import { LoupeButton } from '../PopupWithStructureView/LoupeButton';

const defaultOptions = {
  height: 50,
  width: 80,
};
const CompoundSmilesPreview = ({
  compound,
  options = defaultOptions,
  className,
  hideMW,
  showDivider,
  compounds,
  classNameCompoundInfo,
  multipleCompounds = false,
  showEmptyCompound = false,
  showTooltip = false,
  isPro,
  calculatedVolume,
  hideCompoudInfo = false,
}) => {
  const canvas = useRef(null);
  const canvasContainer = useRef(null);
  const canvasContainerArr = useRef(null);
  const stateCollapse = useSelector(state => state.synjetReducer.stateCollapse);

  const canvasArr = multipleCompounds ? [...compounds] : [];
  canvasArr.fill(useRef(null), 0, canvasArr.length);
  const key = Math.random();

  useEffect(() => {
    if (canvasContainer.current && compound && compound.smiles) {
      canvas.current = new SmilesDrawer.Drawer(options);
      SmilesDrawer.parse(compound.smiles, tree => {
        canvas.current.draw(tree, `smiles-drawer_${key}_${compound.compound_id}`, 'light', false);
      });
    }
  }, [compound, stateCollapse]);
  useEffect(() => {
    if (multipleCompounds && compounds.length) {
      const optionsCompounds = [];
      compounds.forEach((item, index) => {
        optionsCompounds.push(item.properties);
        canvasArr[index].current = new SmilesDrawer.Drawer(options);
        SmilesDrawer.parse(item.properties.smiles, tree => {
          canvasArr[index].current.draw(tree, `smiles-drawer_${key}_${item.properties.compound_id}`, 'light', false);
        });
      });
    }
  }, [compounds]);
  return multipleCompounds ? (
    <div className="multiple-compounds-preview">
      {compounds.map((compound, index) => (
        <>
          {compound ? (
            <div className={cn('compound-smiles-preview', { 'divider-top-compound': index !== 0 }, className)}>
              <div className={showDivider ? 'divider' : ''}>
                <div className="compound-smiles-preview__structure" ref={canvasContainerArr[index]}>
                  <canvas id={`smiles-drawer_${key}_${compound.properties.compound_id}`} width={80} />
                  <LoupeButton
                    label={`smiles-drawer_scale_${key}_${compound.properties.compound_id}`}
                    data={compound.properties}
                  />
                </div>
              </div>
              {!hideCompoudInfo && (
                <div className="compound-smiles-preview__info">
                  <span className="view_id">{compound.properties.compound_id}</span>
                  <span className="view_name">{compound.properties.compound_name}</span>
                  <span className="view_name">{compound.properties.smiles}</span>
                  {!hideMW && (
                    <span className="view_name">
                      MW <span className="view_molar_weight">{compound.properties.molwt}</span> g/mol
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div />
          )}
        </>
      ))}
    </div>
  ) : (
    <>
      {compound || showEmptyCompound ? (
        <div className={cn('compound-smiles-preview', className)}>
          <div className={cn('compound-smiles-preview__margin', showDivider ? 'divider' : '')}>
            <div className="compound-smiles-preview__structure" ref={canvasContainer}>
              {compound?.smiles ? (
                <div className="canvas-container">
                  <canvas id={`smiles-drawer_${key}_${compound?.compound_id}`} width={80} />
                  <LoupeButton label={`smiles-drawer_scale_${key}_${compound?.compound_id}`} data={compound} />
                </div>
              ) : (
                <div className="canvas-container empty">
                  <StopOutlined />
                </div>
              )}
            </div>
          </div>

          {!hideCompoudInfo && (
            <div className={cn('compound-smiles-preview__info', classNameCompoundInfo)}>
              <span className="view_id">{compound?.compound_id || (showEmptyCompound && 'Unknown structure')}</span>
              {showTooltip ? (
                <Tooltip title={compound?.compound_name}>
                  <span className="view_name">{compound?.compound_name}</span>
                </Tooltip>
              ) : (
                <span className="view_name">{compound?.compound_name}</span>
              )}
              {showTooltip ? (
                <Tooltip title={compound?.smiles}>
                  <span className="view_name">{compound?.smiles}</span>
                </Tooltip>
              ) : (
                <span className="view_name">{compound?.smiles}</span>
              )}
              {!hideMW && (
                <span className="view_name">
                  MW <span className="view_molar_weight">{compound?.molwt}</span> g/mol
                </span>
              )}
              {isPro && <span className="view_volume">{`Calculated Volume: ${calculatedVolume} µL`}</span>}
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
    </>
  );
};
export default CompoundSmilesPreview;
