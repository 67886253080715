import React from 'react';
import cn from 'classnames';
import { Sidebar } from '../../Common';

export const DescriptionSidebar = ({ open, onClose, description }) => {
  return (
    <Sidebar width={528} title="Description" open={open} showFooter={false} className="notes-sidebar" onClose={onClose}>
      <div className="description-sidebar__description">{description}</div>
    </Sidebar>
  );
};
