import './InputWithTags.styles.scss';

import React, { useState } from 'react';

import { Select } from 'components';

import { Popover } from '@atoms';

import { validateNumberDependOnDataType } from '../utils';

export const InputWithTags = ({ onChange, value, dataType }) => {
  const [ref, setRef] = useState();
  const [search, setSearch] = useState('');

  const onChangeValue = valuesArr => {
    onChange(valuesArr.map(val => validateNumberDependOnDataType({ dataType, value: val })).filter(e => e));
    setSearch('');
  };

  const onApply = () => {
    onChange([...value, validateNumberDependOnDataType({ dataType, value: search })]);
    setSearch('');
  };

  const onSearch = val => setSearch(validateNumberDependOnDataType({ dataType, value: val }));
  return (
    <div ref={setRef} className="input-with-tags">
      {(() => {
        if (ref) {
          return (
            <Popover
              getPopupContainer={() => ref}
              visible
              destroyTooltipOnHide
              placement="bottom"
              content={
                search && (
                  <div className="input-with-tags_apply" onClick={onApply}>
                    Apply &#8220;
                    {search}
                    &#8221;
                  </div>
                )
              }
              trigger="click"
            >
              <Select
                open
                optionLabelProp="label"
                onChange={onChangeValue}
                onSearch={onSearch}
                placeholder="Please type to select"
                options={[]}
                value={value}
                mode="tags"
                type={dataType}
              />
            </Popover>
          );
        }
        return <></>;
      })()}
    </div>
  );
};
