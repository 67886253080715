import { COMPOUND_VARIATY, EQUIPMENT_TYPES, PROJECT_TYPES } from '../../../constants';
import React from 'react';

export const getReactionsForManual = (processData, processType, reactionsInfo, targetProducts) => {
  let generationMethod;
  let reactionIdx = JSON.parse(reactionsInfo);
  if (processType === PROJECT_TYPES.OPTIMIZATION) generationMethod = getOptimizationColumnsAndReactions;
  else if (processType === PROJECT_TYPES.LIBRARY_GENERATION || processType === PROJECT_TYPES.PRODUCTION)
    generationMethod = getLibraryColumnsAndReactions;

  if (!processData || !processType || !generationMethod) {
    return {
      reactions: [],
    };
  }
  const { reactions } = generationMethod(
    processData,
    processType === PROJECT_TYPES.PRODUCTION,
    reactionIdx,
    targetProducts
  );

  return {
    reactions,
  };
};

const getOptimizationCombinations = (reactions, variations, key, isLibGen, equipmentIndex, isOpt = true) => {
  if (!reactions.length) {
    return variations.map(inputVariation => ({
      [key]: inputVariation,
    }));
  }
  const bufferReactions = [];
  if (isLibGen) {
    let indexedVariations = [];
    variations.forEach((variation, idxVariation) => {
      let temp_var = [];
      variation.forEach((eachVariation, idx) => {
        if (temp_var[eachVariation.index]) {
          temp_var[eachVariation.index].push(eachVariation);
        } else {
          temp_var[eachVariation.index] = [eachVariation];
        }
      });
      indexedVariations[idxVariation] = temp_var;
    });
    indexedVariations.forEach((inputVariation, varIdx) => {
      reactions.forEach((reaction, reactionIndex) => {
        let next_key = Object.keys(Object.values(reactions)[0]).length + 1;
        bufferReactions.push({ ...reaction, [next_key]: inputVariation[reaction[1].index] });
        next_key++;
      });
    });
  } else {
    variations.forEach(inputVariation => {
      reactions.forEach(reaction => {
        bufferReactions.push({ ...reaction, [key]: inputVariation });
      });
    });
  }

  return bufferReactions;
};

const getEquipmentOptimizationVariations = (equipment, equipmentIndex, processLength) => {
  const equipmentVariations = [];
  equipment.times.forEach((time, timeIndex) => {
    equipment.temperatures.forEach((temperature, temperatureIndex) => {
      let common_properties = {
        tempLabel: `T${temperatureIndex + 1}`,
        temp: temperature,
        timeLabel: `t${timeIndex + 1}`,
        time,
        atmosphere: equipment.atmosphere,
        atmosphereAdditional: equipment.atmosphereAdditional,
        name: equipment.name,
        otherType: equipment.otherType,
        pressure: equipment.pressure,
        temperature: temperature,
        type: equipment.type,
        input_equipment: equipment.type ? EQUIPMENT_TYPES[equipment.type].name : '',
      };
      let rows = [];
      let prod = [];
      equipment.properties.forEach((property, index) => {
        property.products.forEach(product => {
          rows.push({
            ...common_properties,
            compound: { ...product },
            chemical_type: processLength === equipmentIndex + 1 ? 'Product' : 'Expected intermediate',
          });
        });
        property.sideproducts.forEach(sideproduct => {
          rows.push({ ...common_properties, compound: { ...sideproduct }, chemical_type: 'Side product' });
        });
        // rows.push(prod)
      });
      equipmentVariations.push(rows);
    });
  });
  return equipmentVariations;
};

const getEquipmentLibGenVariations = (equipment, equipmentIndex, processLength) => {
  let equipmentVariations = [];
  if (equipment.times) {
    equipment.times.forEach((time, timeIndex) => {
      let rows = [];
      let common_properties = {
        tempLabel: `T${timeIndex + 1}`,
        temp: equipment.temperatures[timeIndex],
        timeLabel: `t${timeIndex + 1}`,
        time,
        atmosphere: equipment.atmosphere,
        atmosphereAdditional: equipment.atmosphereAdditional,
        name: equipment.name,
        otherType: equipment.otherType,
        pressure: equipment.pressure,
        type: equipment.type,
        temperature: equipment.temperatures[timeIndex],
        input_equipment: equipment.type ? EQUIPMENT_TYPES[equipment.type].name : '',
      };

      equipment.properties.forEach((property, index) => {
        property.products.forEach(product => {
          rows.push({
            ...common_properties,
            compound: { ...product },
            chemical_type: processLength === equipmentIndex + 1 ? 'Product' : 'Expected intermediate',
            index,
          });
        });
        property.sideproducts.forEach(sideproduct => {
          rows.push({ ...common_properties, compound: { ...sideproduct }, chemical_type: 'Side product', index });
        });
      });
      equipmentVariations.push(rows);
    });
  }
  return equipmentVariations;
};

export const getOptimizationColumnsAndReactions = (processData, processType, reactionIdx) => {
  let reactions = [];
  let inputIndexGlobal = 1;

  processData.forEach(({ inputs, equipment }, equipmentIndex) => {
    inputs.forEach(input => {
      const inputKey = `I${inputIndexGlobal}`;
      const inputProperties = input.properties[0];
      const inputVariations =
        inputProperties.chemical_type === 'solvent'
          ? [
              {
                label: `${inputKey}1`,
                value: `${Number(inputProperties.solvent_volume || 0).toFixed(2)} mL`,
                solvent_volume: inputProperties.solvent_volume,
                compound: inputProperties.solvent,
                mw: inputProperties.solvent.molwt,
                chemical_type: inputProperties.chemical_type,
                input_equipment: `Input ${inputIndexGlobal}`,
              },
            ]
          : inputProperties.equivalents.map((equivalent, equivalentIndex) => {
              let isSolvent = inputProperties.chemical_type === 'solvent';
              return {
                label: `${inputKey}${equivalentIndex + 1}`,
                equivalent,
                mol: isSolvent ? null : inputProperties.moles[equivalentIndex],
                compound: inputProperties.compound,
                chemical_type: inputProperties.chemical_type,
                concentration: inputProperties.concentration,
                density: inputProperties.density,
                equivalents: inputProperties.equivalents,
                is_limited: inputProperties.limiting,
                mass: isSolvent ? null : inputProperties.mass[equivalentIndex],
                moles: isSolvent ? null : inputProperties.moles[equivalentIndex],
                mw: isSolvent ? inputProperties.solvent.molwt : inputProperties.compound.molwt,
                reagent_type: inputProperties.reagent_type,
                solvent: inputProperties.solvent,
                solvent_volume: inputProperties.solvent_volume,
                volume: isSolvent ? null : inputProperties.volume[equivalentIndex],
                input_equipment: `Input ${inputIndexGlobal}`,
              };
            });

      reactions = getOptimizationCombinations(reactions, inputVariations, inputKey);
      inputIndexGlobal++;
    });

    if (equipment) {
      const equipmentKey = `E${equipmentIndex}`;
      const equipmentVariations = getEquipmentOptimizationVariations(equipment, equipmentIndex, processData.length);

      reactions = getOptimizationCombinations(reactions, equipmentVariations, equipmentKey);
    }
  });
  reactions = getSelectedReactions(reactions, reactionIdx);
  return {
    reactions: reactions.map(reaction => ({ ...reaction })),
  };
};

export const getLibraryColumnsAndReactions = (processData, production = false, reactionIdx, targetProducts) => {
  let reactions = [];

  let variableInput = null;

  processData.forEach(({ inputs }) => {
    inputs.forEach(input => {
      variableInput = input.properties?.length > (variableInput?.properties?.length || 0) ? input : variableInput;
    });
  });

  if (variableInput) {
    variableInput.properties.forEach((variableProperty, variablePropertyIndex) => {
      const resultVariation = {};
      let inputIndexGlobal = 1;

      processData.forEach(({ inputs }) => {
        inputs.forEach(input => {
          const inputProperties = input.variable ? variableProperty : input.properties[0];
          const isSolvent = inputProperties.chemical_type === 'solvent';
          resultVariation[inputIndexGlobal] = isSolvent
            ? {
                label: COMPOUND_VARIATY[0],
                index: 0,
                value: `${Number(inputProperties.solvent_volume || 0).toFixed(2)} mL`,
                solvent_volume: inputProperties.solvent_volume,
                compound: inputProperties.solvent,
                mw: inputProperties.solvent.molwt,
                chemical_type: inputProperties.chemical_type,
                input_equipment: `Input ${inputIndexGlobal}`,
              }
            : {
                label: COMPOUND_VARIATY[variablePropertyIndex],
                index: variablePropertyIndex,
                equivalent: isSolvent ? null : inputProperties.equivalents[0],
                mol: isSolvent ? null : inputProperties.moles[0],
                compound: inputProperties.compound,
                chemical_type: inputProperties.chemical_type,
                concentration: inputProperties.concentration,
                density: inputProperties.density,
                equivalents: inputProperties.equivalents,
                is_limited: inputProperties.limiting,
                mass: isSolvent ? null : inputProperties.mass[0],
                moles: isSolvent ? null : inputProperties.moles[0],
                mw: isSolvent ? inputProperties.solvent.molwt : inputProperties.compound.molwt,
                reagent_type: inputProperties.reagent_type,
                solvent: inputProperties.solvent,
                solvent_volume: inputProperties.solvent_volume,
                volume: isSolvent ? null : inputProperties.volume[0],
                input_equipment: `Input ${inputIndexGlobal}`,
                is_production: production,
              };
          inputIndexGlobal++;
        });
      });

      reactions.push(resultVariation);
    });
  }
  let temp = [];
  processData.forEach(({ equipment }, equipmentIndex) => {
    if (equipment) {
      const equipmentKey = `Equipment ${equipmentIndex}`;
      const equipmentVariations = getEquipmentLibGenVariations(equipment, equipmentIndex, processData.length);
      reactions = getOptimizationCombinations(
        reactions,
        equipmentVariations,
        equipmentKey,
        !production,
        equipmentIndex,
        false
      );
    }
  });
  reactions = getSelectedReactions(reactions, reactionIdx, true, targetProducts);
  return {
    reactions: reactions.map(reaction => ({ ...reaction })),
  };
};

export const getSelectedReactions = (reactions, reactionIdx, isLibGen, targetProducts) => {
  reactions = reactions.filter((item, index) => {
    if (reactionIdx.reaction_indexes) return reactionIdx.reaction_indexes.indexOf(index) === -1 ? false : true;
  });
  return reactions;
};
