import React, { useEffect, useState } from 'react';
import './style.scss';

// SVG for types
import { ReactComponent as NoVialsSVG } from '../../../../../dist/images/NotUsedType.svg';
import { ReactComponent as FilledSVG } from '../../../../../dist/images/FilledType.svg';
import { ReactComponent as OtherVialsSVG } from '../../../../../dist/images/OtherVialsType.svg';
import { ReactComponent as NeedToFillSVG } from '../../../../../dist/images/NeedToFillType.svg';

// SVG for backgroundImage
import NoVialsBG from '../../../../../dist/images/NoVialsBG.svg';
import FilledBG from '../../../../../dist/images/FilledBG.svg';
import NeedToFillBG from '../../../../../dist/images/NeedToFillBG.svg';
import OtherVialsBG from '../../../../../dist/images/OtherVialsBG.svg';

import { Divider, Select } from '../../../../Common';

const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const SVG_BACKGROUNDS = {
  noVials: NoVialsBG,
  filled: FilledBG,
  needToFill: NeedToFillBG,
  otherVials: OtherVialsBG,
};

const SolidsStartingPlate = ({ batch, data = [], getCompound, isManual = false }) => {
  const [selectedOption, setSelectedOption] = useState('all');
  const [plateData, setPlateData] = useState(data);
  const [allData, setAllData] = useState(data);
  const [allVialsLocations, setAllVialsLocations] = useState([]);

  useEffect(() => {
    if (isManual) {
      setAllData(data.map(i => ({ ...i, vials: i.manual_vials })));
      setPlateData(data.map(i => ({ ...i, vials: i.manual_vials })));
    } else {
      setAllData(data);
    }
    const configuration = JSON.parse(batch?.configuration);
    setAllVialsLocations([...new Set(configuration?.schedule.map(i => i.source_position))]);
  }, [data]);

  useEffect(() => {
    if (allData) {
      // eslint-disable-next-line no-unused-expressions
      selectedOption === 'all'
        ? setPlateData(allData)
        : setPlateData(allData.filter(i => i.compound_id === selectedOption));
    }
  }, [selectedOption, allData]);

  const getNumbersRow = () => [...Array(12)].map((_, i) => <div className="plate-item plate-item-extra">{i + 1}</div>);

  const getRow = (letter, startNumber) => {
    const row = [<div className="plate-item-extra">{letter}</div>];
    let currentRowData = [];

    plateData.forEach(i => {
      if (i.vials) {
        i.vials.forEach(j => {
          if (j.location) {
            return j.location[0] === letter
              ? currentRowData.push({ location: j.location, type: i.has_sent ? 'filled' : 'needToFill' })
              : null;
          }
        });
      }
    });

    currentRowData = [...new Map(currentRowData.map(item => [item.location, item])).values()];
    currentRowData = currentRowData
      .sort((a, b) => (a.location > b.location ? 1 : b.location > a.location ? -1 : 0))
      .sort((a, b) => +a.location.slice(1) - +b.location.slice(1));

    for (let i = startNumber; i < startNumber + 12; i++) {
      const currentNum = i - startNumber + 1;
      const isNoVial = !allVialsLocations?.includes(letter + currentNum);
      let bg = isNoVial ? 'noVials' : 'otherVials';
      if (!!currentRowData.length && !isNoVial && +currentRowData[0].location.slice(1) === currentNum) {
        bg = currentRowData[0]?.type;
        currentRowData = currentRowData.slice(1);
      }
      row.push(
        <div className="plate-item" style={{ backgroundImage: `url(${SVG_BACKGROUNDS[bg]})` }}>
          {bg === 'noVials' ? '' : i}
        </div>
      );
    }
    return row;
  };

  const getOptions = () => [
    { label: `All ${isManual ? 'solutions' : 'solids'}`, value: 'all' },
    ...data?.map(i => {
      const compound = getCompound(i.compound_id);
      return {
        label: compound?.compound_name || compound?.smiles,
        value: i.compound_id,
      };
    }),
  ];

  return (
    <div className="solids-starting-plate">
      {plateData && (
        <>
          <div className="solids-starting-plate__header">
            <NoVialsSVG />
            <span>No vials</span>
            <OtherVialsSVG />
            <span>Other vials</span>
            <NeedToFillSVG />
            <span>Need to fill</span>
            <FilledSVG />
            <span>Filled</span>
          </div>
          <div className="solids-starting-plate__main">
            <div className="solids-starting-plate__main_title">
              <span>Starting plate</span>
              <Select
                options={getOptions()}
                placeholder="Select starting plate"
                defaultValue="all"
                onChange={value => setSelectedOption(value)}
              />
            </div>
            <Divider />
            <div className="solids-starting-plate__main_numbers-row">{getNumbersRow()}</div>
            {LETTERS.map((i, idx) => (
              <div className="solids-starting-plate__main_row">{getRow(i, idx * 12 + 1)}</div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(SolidsStartingPlate);
