import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import rootReducer from './store/rootReducer';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import history from './utils/history';
import 'antd/dist/antd.css';
import { Login } from './containers/Login/Login';
import { Security, LoginCallback } from '@okta/okta-react';
import moment from 'moment'
import config_okta from './config_okta';

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};
const PrivateRoute = ({ children, ...rest }) => {

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!!localStorage.getItem('token')) {
          return <App {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk.withExtraArgument({ history }))));

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Security {...config_okta.oidc}>
        <Route component={ScrollToTop} />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/implicit/callback" component={LoginCallback} />
          <PrivateRoute path="/" />
          <Route
            path="/"
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: location.pathname === '/' ? '/users' : '/404',
                  state: { from: location },
                }}
              />
            )}
          />
        </Switch>
      </Security>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
