import React from 'react';
import { Popup } from '../..';
import './style.scss';

export const PopupSubmitExperiment = ({
  submitModalOpen,
  handleSubmitConfirmation,
  setSubmitModalOpen,
  submitLoading,
  availableEquipment,
}) => {
  return (
    <Popup
      open={submitModalOpen}
      title="Submit the experiment"
      handleSubmit={handleSubmitConfirmation}
      loading={submitLoading}
      textSubmit="Confirm"
      handleCancel={() => setSubmitModalOpen(false)}
    >
      <span>
        Please confirm that the experiment should be submitted to the Operations Manager for scheduling.
        <br />
        <br />
        <b>Note:</b> The Operations Manager may return the experiment if additional information is required.
        <br />
        <br />
        {!!availableEquipment?.length && (
          <>
            <b>Note:</b> This experiment will be compatible with the following list of available AutoSyn devices due to
            the specific of its configuration:
            <ul class="available-equipment">
              {availableEquipment.map(equip => (
                <li>{equip}</li>
              ))}
            </ul>
          </>
        )}
      </span>
    </Popup>
  );
};
