import React from 'react';
import './style.scss';
import cn from 'classnames';
import { PRIORITIES } from '../../../constants';

export const StatusPriority = ({ priority }) => {
  return (
    <div className={cn('status-priority', { low: priority === 0, medium: priority === 1, high: priority === 2 })}>
      {PRIORITIES[priority]}
    </div>
  );
};
