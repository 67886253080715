import React, { useEffect, useMemo, useState } from 'react';
import { Button, openNotification, Popup, ZoomIn, ZoomOut } from '../../Common';
import './style.scss';
import { Header } from './Header';
import { getRouteDetailsFromSave } from '../../../store/autosyn/autosyn.actions';
import { PROCESS_TYPES, steps_synjet_pro, testNew } from '../../../constants';
import { filterObj, lengthObj } from '../../../utils';
import history from '../../../utils/history';
import { ContainerRoute } from './ContainerRoute';
import {
  STEP_OPTIONS,
  SYNJET_PRO_TYPE_OPTIONS,
  SYNJET_TYPE_OPTIONS,
} from '../../../constants/createProcessConstatants';
import { ContainerResultingRoute } from './ContainerResultinRoute';

const initialData = {
  project: null,
  type: 'Production',
  name: '',
  route: null,
  segment: null,
  steps: STEP_OPTIONS[0].value,
};
export const CreatorProcessFromRoute = ({
  open,
  handleCancel,
  createProcessFromRoute,
  previewRouteProcessData,
  getProcess,
  isSynjet,
  deviceType,
  isSynjetPro,
  isPreviewResult,
  dataProcess,
  ...props
}) => {
  let initialValueType = isSynjet ? SYNJET_TYPE_OPTIONS[0].value : initialData.type;
  if (isSynjetPro) initialValueType = SYNJET_PRO_TYPE_OPTIONS[0].value;
  const [loading, setLoading] = useState(false);
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [filters, setFilters] = useState({
    ...initialData,
    type: initialValueType,
  });
  const [detailsRoute, setDetailsRoute] = useState(null);
  const [segmenetsOptions, setSegmentsOptions] = useState([]);
  const [errorName, setErrorName] = useState('');
  const [segmentError, setSegmentError] = useState('');

  const [resultingExperiment, setResultingExperiment] = useState(null);
  const [resultingRoute, setResultingRoute] = useState(null);

  useEffect(() => {
    if (!open) {
      setFilters({ ...initialData });
      setLoading(false);
      setLoadingRoute(false);
      setDetailsRoute(null);
      setSegmentsOptions([]);
      setSegmentError('');
      setErrorName('');
      setResultingExperiment(null);
      setResultingRoute(null);
    }
  }, [open]);

  useEffect(() => {
    if (filters.route) {
      setSegmentsOptions([]);
      setFilters({ ...filters, segment: null });
      setDetailsRoute(null);
      setLoadingRoute(true);
      getRouteDetailsFromSave({ uuid: filters.route })
        .then(resp => {
          if (resp?.routes) {
            setFilters({ ...filters, segment: 1 });
            setDetailsRoute(resp);
            setSegmentsOptions(
              resp.routes[0].route_segments.map((i, index) => ({
                countSegments: i.length,
                value: index + 1,
                label: `Segment ${index + 1}`,
              }))
            );
          }
        })
        .catch(() => {
          setFilters({ ...filters, route: null });
          setLoadingRoute(false);
          openNotification('', 'Invalid route structure');
        });
    }
  }, [filters.route]);
  useEffect(() => {
    if (previewRouteProcessData) {
      setLoadingRoute(true);
      getProcess(previewRouteProcessData.uuid, true).then(resp => {
        if (resp?.process?.route) {
          setDetailsRoute(resp?.process?.route.data);
          setFilters({ ...filters, segment: resp.process.segment });
        }
      });
    }
  }, []);
  useEffect(() => {
    if (loadingRoute && detailsRoute) setLoadingRoute(false);
  });

  const saveProcess = () => {
    let validationSegment = true;
    const validationName = filters.name.length >= 3;
    if ((isSynjet || isSynjetPro) && segmenetsOptions[filters?.segment - 1]?.countSegments < +filters.steps) {
      validationSegment = false;
    }
    if (validationName && validationSegment) {
      setLoading(true);
      const requestData = {
        project: filters.project,
        type: filters.type,
        name: encodeURIComponent(filters.name),
        route: filters.route,
        segment: +filters.segment,
        device: deviceType,
      };

      if (filters.enableQuenching) {
        requestData.enableQuenching = true;
      }
      if (isSynjetPro && filters.steps === steps_synjet_pro[1].value) {
        requestData.variableStep = filters.variableStep;
      }
      if (isSynjet || isSynjetPro) {
        requestData.numberOfSteps = filters.steps;
      }

      createProcessFromRoute(requestData)
        .then(resp => {
          if (resp.uuid) {
            openNotification('The process has been successfully created');
            history.push({
              pathname: `${PROCESS_TYPES[deviceType.replace(/\s/g, '')].path}/process-builder/details/${resp.uuid}`,
              state: { showInitial: true },
            });
          } else {
            resp.errors && setErrorName(resp.errors[0].messages[0]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!validationSegment) {
        setSegmentError(true);
        openNotification(
          '',
          'The selected route contains less steps than the process and cannot be used for a process generation.'
        );
      }
      if (!validationName) setErrorName('Process name should have at least 3 characters');
    }
  };

  const updateFilters = (value, field) => {
    if (field === 'name') setErrorName('');
    setFilters({ ...filters, [field]: value });

    if (field === 'project') {
      setFilters(prev => ({
        ...prev,
        route: null,
        segment: null,
      }));
    }
    if (field === 'steps') {
      setFilters(prev => ({ ...prev, variableStep: steps_synjet_pro[0].value }));
    }
    if (field === 'steps' && value === '2') {
      setFilters(prev => ({ ...prev, enableQuenching: false }));
    }
    if (field === 'steps' || field === 'project' || field === 'route' || field === 'segment') setSegmentError(false);
  };

  const getRouteExp = () => {
    const routesExp = experiments.find(i => i.value === resultingExperiment);

    return JSON.parse(routesExp.route.data).routes.map((i, idx) => ({
      value: idx,
      label: i.name,
      data: routesExp.route,
    }));
  };

  const { enableQuenching, variableStep, ...requiredFilters } = filters;

  const disabled = lengthObj(filterObj(requiredFilters)) !== lengthObj(requiredFilters);

  const process = dataProcess?.process;
  const experiments = useMemo(() => {
    if (process) {
      return process.experiments
        ?.filter(i => !!i.route)
        .map(i => ({
          value: i.uuid,
          label: i.name,
          route: i.route,
          completedAt: i.completedAt,
        }));
    }
    return [];
  }, [!!process]);
  const routes = useMemo(() => {
    if (!!process && resultingExperiment) return getRouteExp();
    return [];
  }, [!!process, resultingExperiment]);

  const routeData = useMemo(() => {
    if (typeof resultingRoute === 'number') return JSON.parse(getRouteExp()[resultingRoute]?.data?.data);
    return null;
  }, [resultingRoute, resultingExperiment]);
  return (
    <Popup
      className="creator-processes"
      open={open}
      handleCancel={handleCancel}
      title={
        <Header
          {...props}
          setResultingExperiment={setResultingExperiment}
          setResultingRoute={setResultingRoute}
          resultingExperiment={resultingExperiment}
          resultingRoute={resultingRoute}
          routes={routes}
          experiments={experiments}
          dataProcess={dataProcess}
          isPreviewResult={isPreviewResult}
          isSynjet={isSynjet}
          isSynjetPro={isSynjetPro}
          previewRouteProcessData={previewRouteProcessData}
          disabled={loading || loadingRoute}
          errorName={errorName}
          segmentError={segmentError}
          segmenetsOptions={segmenetsOptions}
          filters={filters}
          updateFilters={updateFilters}
          title={previewRouteProcessData ? `Route ${previewRouteProcessData.route?.key}` : undefined}
          open={open}
        />
      }
      footerComponent={
        previewRouteProcessData || isPreviewResult
          ? [
              <Button type="secondary" onClick={handleCancel} disabled={loading}>
                Close
              </Button>,
            ]
          : [
              <Button type="secondary" onClick={handleCancel} disabled={loading}>
                Cancel
              </Button>,
              <Button onClick={saveProcess} disabled={disabled} loading={loading}>
                Continue
              </Button>,
            ]
      }
    >
      {isPreviewResult ? (
        <ContainerResultingRoute routeData={routeData} resultingRoute={resultingRoute} />
      ) : (
        <ContainerRoute
          filters={filters}
          previewRouteProcessData={previewRouteProcessData}
          detailsRoute={detailsRoute}
          loadingRoute={loadingRoute}
        />
      )}
    </Popup>
  );
};

const ZoomBlock = ({ zoomIn, zoomOut }) => (
  <div className="zoom-block">
    <ZoomIn onClick={zoomIn} />
    <div className="zoom-block_devider" />
    <ZoomOut onClick={zoomOut} />
  </div>
);
