let errorsArray = [];

const getErrors = error => {
  if (!error.messages[0]?.field) {
    let newErrors = [];
    error.messages.forEach(msg => {
      newErrors = [...newErrors, msg];
    });
    errorsArray = [...errorsArray, ...newErrors];
  } else {
    error.messages.forEach(message => {
      getErrors(message);
    });
  }
};

export const parseJSONErrors = string => {
  try {
    errorsArray = [];
    const errors = JSON.parse(string);
    errors.forEach(err => {
      getErrors(err);
    });
    return errorsArray;
  } catch (e) {
    return [];
  }
};
