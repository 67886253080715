import React from 'react';
import './style.scss';
import { Dropzone, Input, Time, Tooltip } from 'components/Common';
import cn from 'classnames';
import { DeleteOutlined, DownloadOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons';
import { MAX_FILES_TO_ATTACH_NUMBER, ORIGIN } from 'constants/common';
import { Compound } from 'components/ProcessBuilder/WorkSpace/Compound';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const OfflineAnalysisBlock = ({ title, appendedFiles, onDeleteAttachment, type, onAppendFile, onLoadFile }) => {
  return (
    <>
      {appendedFiles && (
        <div className="append-results__content__offline-analysis">
          <div className="append-results__content__offline-analysis__title">{title}</div>
          <AttachedFiles appendedFiles={appendedFiles} onDelete={onDeleteAttachment} type={type} />
          <AttachmentsDropzone
            onAppendFile={onAppendFile}
            onLoadFile={onLoadFile}
            disabled={appendedFiles.length >= MAX_FILES_TO_ATTACH_NUMBER}
            type={type}
          />
        </div>
      )}
    </>
  );
};

export const AttachedFiles = ({ appendedFiles = [], onDelete, type, notEditable, reactionNumber }) => {
  const attachedFiles = type == 'Reaction attachment' ? appendedFiles[reactionNumber] : appendedFiles;
  return (
    <div className="append-results__content__appended-file__block">
      {attachedFiles &&
        attachedFiles.length > 0 &&
        attachedFiles.map((file, idx) => (
          <div className={cn('append-results__content__appended-file', { 'not-loaded': !file.loaded })} key={idx}>
            <FileTextOutlined />
            {!notEditable ? (
              <a href={`${ORIGIN}/${file.file}`} className="text" target="_blank" rel="noreferrer">
                {file?.file?.split('/')[file?.file?.split('/').length - 1]}
              </a>
            ) : (
              <Tooltip title={`Open in new tab ${file?.file?.split('/')[file?.file?.split('/').length - 1]}`}>
                <a href={`${ORIGIN}/${file.file}`} className="text-download" target="_blank" rel="noreferrer">
                  {file?.file?.split('/')[file?.file?.split('/').length - 1]}
                </a>
              </Tooltip>
            )}
            {file.loaded ? (
              notEditable ? (
                <a href={`${ORIGIN}/${file.file}`} className="text-download" download>
                  <DownloadOutlined />
                </a>
              ) : (
                <DeleteOutlined onClick={e => onDelete(file, idx, type, reactionNumber)} />
              )
            ) : (
              <LoadingOutlined />
            )}
          </div>
        ))}
    </div>
  );
};

export const AttachmentsDropzone = ({
  onAppendFile,
  onLoadFile,
  type,
  disabled,
  reactionNumber,
  allReactionNumber,
}) => (
  <Dropzone
    onAppendFile={file => onAppendFile(file, type, reactionNumber, allReactionNumber)}
    onLoadFile={file => onLoadFile(file, type)}
    maxSize={5000000}
    disabled={disabled}
    multiple={false}
    tooLargeErrorText="The file size should not exceed 5MB"
    accept={['.pdf', '.txt', '.docx', '.doc', '.xlsx', '.zip', '.xls']}
  />
);

export const ReactionData = ({
  reactions = [],
  setReactions,
  errors = [],
  setErrors,
  actualTime,
  showTime = true,
  attachedFiles,
  onDeleteAttachment,
  onAppendFile,
  onLoadFile,
  appendedFiles,
}) => {
  const changeReaction = (val, type, idx) => {
    if (val === null && type === 'time') val = [moment().startOf('day'), moment().startOf('day')];

    const _reactions = [...reactions];
    _reactions[idx] =
      type === 'time'
        ? {
            ..._reactions[idx],
            start_collection: val[0],
            end_collection: val[1],
          }
        : {
            ..._reactions[idx],
            [type]: val,
          };
    if (type === 'time') {
      const actualExperimentTime = moment().startOf('day').seconds(actualTime);
      const isMoreThanActualTime =
        moment(actualExperimentTime).diff(val[0]) < 0 || moment(actualExperimentTime).diff(val[1]) < 0;
      setErrors({
        ...errors,
        time: [..._reactions].map((i, index) => {
          if (index === idx) return isMoreThanActualTime;
          return errors.time[index] || false;
        }),
      });
    }
    setReactions(_reactions);
  };

  const formatter = val => val.toString().substr(0, 10);

  return (
    <>
      {reactions.map((reaction, idx) => (
        <div className="results-reaction-data" key={idx}>
          <div className="results-reaction-data__name">{reaction.name}</div>
          {reaction.compound && (
            <div className="results-reaction-data__smiles">
              <div className="results-reaction-data__smiles__compound">
                <Compound
                  data={reaction.compound}
                  width={130}
                  label={`${reaction.name}-${reaction.compound?.compound_id}-${Math.random()}`}
                />
              </div>
              <div className="results-reaction-data__smiles__data">
                <div className="results-reaction-data__smiles__data__black-text">{reaction.compound?.inchikey}</div>
                <div className="results-reaction-data__smiles__data__grey-text">{reaction.compound?.compound_name}</div>
                <div className="results-reaction-data__smiles__data__grey-text">{reaction.compound?.smiles}</div>
              </div>
            </div>
          )}
          <div className="results-reaction-data__data">
            <div className="row">
              <div className="row__title">Mass isolated:</div>
              <Input
                type="number"
                placeholder="0"
                min={0}
                value={reaction.mass}
                formatter={formatter}
                onChange={value => changeReaction(value, 'mass', idx)}
              />
              <span className="input-text">mg</span>
            </div>
            <div className="row">
              <div className="row__title">Yield:</div>
              <Input
                type="number"
                placeholder="0"
                value={reaction.product_yield}
                max={100}
                min={0}
                formatter={formatter}
                onChange={value => changeReaction(value, 'product_yield', idx)}
              />
              <span className="input-text">%</span>
            </div>
            {showTime && (
              <div className="row">
                <div className="row__title">Collection interval:</div>
                <Time
                  range
                  error={errors.time[idx]}
                  errorText="The interval value exceeds Actual experiment time"
                  onChange={value => changeReaction(value, 'time', idx)}
                  value={[moment(reaction.start_collection), moment(reaction.end_collection)]}
                />
                <span className="input-text time">HH:MM</span>
              </div>
            )}
          </div>
          {attachedFiles && (
            <div className="appended-file-reaction">
              {appendedFiles[idx]?.length > 0 && appendedFiles[idx][0]?.file && (
                <AttachedFiles
                  appendedFiles={appendedFiles}
                  onDelete={onDeleteAttachment}
                  type="Reaction attachment"
                  reactionNumber={idx}
                />
              )}
              <AttachmentsDropzone
                onAppendFile={onAppendFile}
                onLoadFile={onLoadFile}
                disabled={appendedFiles[idx]?.length >= MAX_FILES_TO_ATTACH_NUMBER}
                type="Reaction attachment"
                reactionNumber={idx}
                allReactionNumber={reactions?.length}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};
