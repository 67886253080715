import React, { createContext, useContext, useMemo, useState } from 'react';

const SelectableContext = createContext(null);

export const useSelectableContext = () => useContext(SelectableContext) || { isSelectableContext: false };

export const useSelectableController = () => {
  const [selected, setSelected] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelected([]);
    }

    setIsAllSelected(prev => !prev);
  };

  const onSelect = (rowId, tableRowsIndexes) => {
    if (isAllSelected) {
      setSelected(tableRowsIndexes.filter(i => i !== rowId));
      setIsAllSelected(false);
      return;
    }
    setSelected(prev => {
      if (prev.includes(rowId)) {
        return prev.filter(id => id !== rowId);
      }

      if (prev.length + 1 === tableRowsIndexes.length) {
        setIsAllSelected(true);
      }

      return [...prev, rowId];
    });
  };

  return {
    selected,
    isAllSelected,
    onSelect,
    onSelectAll,
  };
};

export const SelectableHOC = ({ children }) => {
  const { selected, isAllSelected, onSelectAll, onSelect } = useSelectableController();

  const value = useMemo(
    () => ({
      onSelect,
      onSelectAll,
      isAllSelected,
      selected,
      isSelectableContext: true,
    }),
    [selected, isAllSelected]
  );

  return <SelectableContext.Provider value={value}>{children}</SelectableContext.Provider>;
};
