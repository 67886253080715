import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  ButtonGroup,
  closeNotification,
  CreateExperimentModal,
  openNotification,
  Popup,
  ProcessItemCollapse,
  Spinner,
  SynjetCompoundContainer,
  SynjetCompoundGroupContainer,
} from '../../../components/index';
import { ProcessDetails } from '../../../components/ProcessBuilder/ProcessDetails/ProcessDetails';
import { HardwareSummary } from '../../../components/SynJet/SynJetProccess/HardwareSummary/HardwareSummary';
import { Footer } from '../../../components/SynJet/Footer';
import { ConditionsAndDispensingOptions } from '../../../components/SynJet/SynJetProccess/ConditionsAndDispensingOptions/ConditionsAndDispensingOptions';
import { editModeSelector, user } from '../../../store/common/common.selector';
import {
  generateExpectedIntermediate,
  generateExperimentsData,
  generateExperimentsDataFromProducts,
  generateProducts,
  getProcess,
  lockSynjetProcess,
  resetSynJetStore,
  saveSynjetProcess,
  setSteps,
  updateProcess,
} from '../../../store/synjet/synjet.actions';
import { updateProcessDefinition } from '../../../store/processBuilder/processbuilder.actions';
import { OnBeforeLeave } from '../../../components/OnBeforeLeave';
import {
  errors,
  experimentsData,
  synjectProcess,
  synjectProcessSteps,
  synjetRules,
  synjetProducts,
  synjetQuenching,
  selectEnableQuenching,
  synjetExpectedIntermediates,
} from '../../../store/synjet/synjet.selector';
import history from '../../../utils/history';
import { ViewInitialRoute } from '../../../components/ProcessBuilder/ViewInitialRoute';
import './style.scss';
import {
  PROCESS_BUILDER_ERROR,
  PROCESS_TYPES,
  SOLVENT_TYPES,
  SYNJET_INITIAL_RULE,
  SYNJET_PRO_PROCESS_TYPES,
  SYNJET_PROCESS_STEPS,
  SYNJET_PROCESS_TYPES,
} from '../../../constants';
import { DISPLAY_OPTIONS } from '../../../constants/synjet';
import { SynJetExperimentTable } from '../../../components/SynJet/SynJetProccess/SynJetExperimentTable/SynJetExperimentTable';
import { setEditMode } from '../../../store/common/common.actions';

import { ExclusionRules } from '../../../components/SynJet/SynJetProccess/ExclusionRules/ExclusionRules';
import { getLimitingCompoundFromProcessSteps, getPrevStepCount } from '../../../utils/synjetHelpers';
import { setModalOpen } from '../../../store/experimentCreation/experimentCreation.action';
import { checkIfMyLock } from '../../../utils/checkIfMyLock';
import { CreatorProcessFromRoute } from '../../../components/AutoSin/CreatorProcessFromRoute';
import { SynjetProProductsList } from '../../../components/SynJet/SynJetProccess/SynjetPro/SynjetProProductsList';
import { SynjetProQuenchingList } from '../../../components/SynJet/SynJetProccess/SynjetPro/SynjetProQuenchingList';

const EmptyState = ({ title, subtitle }) => (
  <div className="collapse-empty-state">
    <div className="empty-state-title">{title}</div>
    <div className="empty-state-subtitle">{subtitle}</div>
  </div>
);

const buttons = [
  { label: 'Equivalent', value: DISPLAY_OPTIONS.equivalent.label },
  { label: 'Volume', value: DISPLAY_OPTIONS.volume.label },
];

const getExperimentsCount = steps => {
  let count = 0;

  steps.forEach(step => {
    if (step.step.vials.length > count) {
      count = step.step.vials.length;
    }
  });

  return count;
};

export const ProcessBuilderSynJet = ({ isPro }) => {
  const [showInitialRoute, setShowInitialRoute] = useState(false);
  const [count, _setCount] = useState(0);
  const [allErrorsCount, _setAllErrorsCount] = useState(0);
  const [highlightErrors, setHighlightErrors] = useState(false);
  const [createExperimentErrorPopup, setCreateExperimentErrorPopup] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openValidatePopup, setOpenValidatePopup] = useState(false);
  const [validatedConfirmationOpen, setValidatedConfirmationOpen] = useState(false);
  const [showResultingRoute, setShowResultingRoute] = useState(false);
  const [processPermissionsError, setProcessPermissionsError] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(user);
  const processSteps = useSelector(synjectProcessSteps);
  const synjetProcess = useSelector(synjectProcess);
  const experiments = useSelector(experimentsData);
  const rules = useSelector(synjetRules);
  const validationErrors = useSelector(errors);
  const editMode = useSelector(editModeSelector);
  const synjetProProducts = useSelector(synjetProducts);
  const synjetProExpectedIntermadiate = useSelector(synjetExpectedIntermediates);
  const synjetProQuenching = useSelector(synjetQuenching);

  const isQuenchingEnabled = useSelector(selectEnableQuenching);

  const location = useLocation();
  const { id } = useParams();

  const counterRef = React.useRef(count);
  const setCount = data => {
    counterRef.current = data;
    _setCount(data);
  };

  const allErrorsCountRef = React.useRef(allErrorsCount);
  const setAllErrorsCount = data => {
    allErrorsCountRef.current = data;
    _setAllErrorsCount(data);
  };

  const updateCounter = () => {
    setCount(counterRef.current - 1);
  };

  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [selectedDisplayOption, setSelectedDisplayOption] = useState(DISPLAY_OPTIONS.equivalent.label);
  const [loading, setLoading] = useState(false);

  const experimentsCount = getExperimentsCount(experiments.process_steps);

  useEffect(() => {
    dispatch(getProcess(id)).then(data => {
      if (location?.state?.showInitial) {
        setShowInitialRoute(true);
        history.replace(location.pathname);
      }
    });

    return () => {
      dispatch(resetSynJetStore());
      if (allErrorsCountRef.current > 0) {
        for (let i = 0; i < allErrorsCountRef.current; i++) {
          closeNotification(`error-notification-${i}`);
        }
      }
      if (validationErrors.length) {
        validationErrors.forEach((error, index) => {
          closeNotification(`error-notification-${index}`);
        });
      }
    };
  }, []);

  const [isInitial, setIsInitial] = useState(true);
  useEffect(() => {
    const process = synjetProcess;
    if (process?.error) {
      openNotification(null, PROCESS_BUILDER_ERROR);
      setProcessPermissionsError(true);
    } else if (process?.process && isInitial) {
      setIsInitial(false);
      if (process?.process?.status !== 'validated' && process?.process?.status !== 'draft') return;
      if (process?.process?.definition) {
        if (process.process.processLock.isMyLock) {
          dispatch(setEditMode(true));
          dispatch(lockSynjetProcess(process.process.processLock.uuid));
        } else {
          dispatch(setEditMode(false));
        }
      } else {
        dispatch(setEditMode(true));
        dispatch(lockSynjetProcess(process.process.processLock.uuid));
      }
    }
  }, [synjetProcess]);

  useEffect(() => {
    if (isPro) {
      dispatch(generateExperimentsDataFromProducts());
    }
  }, [synjetProProducts, rules]);

  useEffect(() => {
    if (isPro) {
      setTimeout(() => dispatch(generateProducts(), 100));
    } else {
      dispatch(generateExperimentsData());
    }
  }, [processSteps, rules, synjetProQuenching, synjetProExpectedIntermadiate]);

  useEffect(() => {
    const isFirstFixed = processSteps?.[0]?.isFixed;
    const hasExpectedIntermediates = processSteps.length > 1 && !isFirstFixed;
    if (isPro && hasExpectedIntermediates) {
      dispatch(generateExpectedIntermediate());
    }
  }, [processSteps]);

  const onSave = async (validate = false) => {
    setHighlightErrors(false);
    if (synjetProcess.process?.status === 'validated' && !validatedConfirmationOpen && !validate) {
      setValidatedConfirmationOpen(true);
      return;
    }
    if (allErrorsCountRef.current > 0) {
      for (let i = 0; i < allErrorsCountRef.current; i++) {
        closeNotification(`error-notification-${i}`);
      }
    }
    setLoadingButton(true);
    setLoading(true);
    try {
      if (!editMode && validate) {
        await dispatch(lockSynjetProcess(synjetProcess.process.processLock.uuid)).then(() => {});
      }
      const data = await dispatch(saveSynjetProcess(validate));

      if (!data.updateProcessDefinition.errors?.length) {
        openNotification(
          validate
            ? 'Process is valid for experiment'
            : synjetProcess.process?.definition
            ? 'Process changes are saved'
            : 'Process has been saved.'
        );
        dispatch(getProcess(id));
        dispatch(setEditMode(false));
      } else if (editMode) {
        await dispatch(lockSynjetProcess(synjetProcess.process.processLock.uuid));
      }
    } catch (e) {
      if (editMode) {
        await dispatch(lockSynjetProcess(synjetProcess.process.processLock.uuid));
      }
      openNotification('Process has been saved.', 'Please check your data.');
    } finally {
      setLoading(false);
      setLoadingButton(false);
      setOpenValidatePopup(false);
    }
  };

  const getStepTitle = isFixed =>
    processSteps.length > 1 ? (isFixed ? SYNJET_PROCESS_STEPS.FIXED : SYNJET_PROCESS_STEPS.VARIABLE) : null;

  const getRulesTitle = () => {
    if (!!rules && rules.length === 1 && rules[0] === SYNJET_INITIAL_RULE) return null;
    return rules?.length > 0 ? rules.length : null;
  };

  const getNumberOfCompounds = compounds => {
    let cpds = [];
    cpds = [...cpds, ...compounds.reagents];
    cpds = [...cpds, ...compounds.reactants];
    compounds.reactantGroups.forEach(group => (cpds = [...cpds, ...group.compoundsList]));
    compounds.reagentGroups.forEach(group => (cpds = [...cpds, ...group.compoundsList]));
    return cpds.filter(cpd => {
      const isValid = cpd.solventType === SOLVENT_TYPES.SOLID || !!cpd.concentration;
      return !!cpd.smiles && !!cpd.name && isValid;
    }).length;
  };

  const processType = synjetProcess?.process?.type;

  const isLibGen = useMemo(
    () => isPro && synjetProcess?.process?.type === SYNJET_PRO_PROCESS_TYPES.LIBRARY_GENERATION,
    [isPro, synjetProcess]
  );

  useEffect(() => {
    if (validationErrors?.length) {
      setCount(validationErrors.length);
      setAllErrorsCount(validationErrors.length);
      validationErrors.forEach((err, idx) => {
        openNotification('', err, null, `error-notification-${idx}`, updateCounter);
      });
    }
  }, [validationErrors]);

  const disableCreateExperiment =
    (synjetProcess?.process?.status !== 'validated' && synjetProcess?.process?.status !== 'experiment submitted') ||
    editMode ||
    checkIfMyLock(synjetProcess?.process?.processLock);
  const showCreateExperimentBtn = currentUser?.permissions?.execution?.create_experiment;

  const handleCreateExperiment = async () => {
    try {
      const { updateProcessDefinition: validation } = await dispatch(
        updateProcessDefinition({ uuid: synjetProcess.process.uuid }, true)
      );
      if (validation.errors) {
        setCreateExperimentErrorPopup(true);
        getProcess(synjetProcess.process?.uuid).finally(() => {
          setLoading(false);
        });
      } else {
        dispatch(
          setModalOpen(synjetProcess, 0, {
            experiments,
            processSteps,
            products: synjetProProducts || [],
            quenching: synjetProQuenching || [],
          })
        );
      }
    } catch (e) {
      setCreateExperimentErrorPopup(true);
    }
  };

  const getLibGenStepsItems = useCallback(
    (index, isFixed, isTwoStep, limitingCompound) => {
      const isExpectedIntermediate = isTwoStep && !index;
      if (!isLibGen || (isExpectedIntermediate && isFixed)) {
        return [];
      }
      const productsCount = isExpectedIntermediate
        ? synjetProExpectedIntermadiate?.length || 0
        : synjetProProducts?.length;
      const products = isExpectedIntermediate ? synjetProExpectedIntermadiate : synjetProProducts;

      const disableConditionDelete = isTwoStep && isFixed && index;

      return [
        {
          mainTitle: isExpectedIntermediate ? 'Expected intermediate' : 'Products',
          body: productsCount ? (
            <SynjetProProductsList
              products={products}
              viewOnly={!editMode}
              processSteps={processSteps}
              quenchingList={synjetProQuenching}
              isExpectedIntermediate={isExpectedIntermediate}
              disableConditionDelete={disableConditionDelete}
              limitingCompound={limitingCompound}
            />
          ) : null,
          emptyState: (
            <EmptyState
              title={`${isExpectedIntermediate ? 'Expected Intermediate' : 'Products'} list is empty`}
              subtitle="Insufficient data"
            />
          ),
        },
      ];
    },
    [isLibGen, synjetProProducts, editMode, processSteps, synjetProQuenching, synjetProExpectedIntermadiate]
  );

  const getQuenchingItems = useCallback(
    limitingCompoundGroup => {
      if (!isQuenchingEnabled || processSteps?.length > 1) {
        return [];
      }
      const quenchingCount = synjetProQuenching?.length;
      return [
        {
          mainTitle: 'Quenching',
          body: quenchingCount ? (
            <SynjetProQuenchingList
              quenchingList={synjetProQuenching}
              viewOnly={!editMode}
              limitingCompoundGroup={limitingCompoundGroup}
            />
          ) : null,
          emptyState: <EmptyState title="Quenching list is empty" subtitle="Insufficient data" />,
        },
      ];
    },
    [synjetProQuenching, isQuenchingEnabled, processSteps, editMode]
  );

  const getStepsItems = useCallback(() => {
    const tempLimitingCompound = getLimitingCompoundFromProcessSteps(
      processSteps,
      synjetProcess?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION
    );

    const stepOffset = getPrevStepCount(
      processSteps?.[0],
      synjetProcess?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION
    );
    return processSteps
      .map((step, index) => [
        {
          mainTitle: processSteps.length > 1 ? `Step ${index + 1}. Reactants & Reagents` : 'Reactants & Reagents',
          stepTitle: getStepTitle(step.isFixed),
          numberOfItems: getNumberOfCompounds(step.compounds),
          body:
            synjetProcess?.process?.type === SYNJET_PROCESS_TYPES.OPTIMIZATION || step.isFixed ? (
              <SynjetCompoundContainer
                compounds={step.compounds}
                step={index}
                product={step.product}
                isFixed={step.isFixed}
                viewOnly={!editMode}
                isExpectedIntermediate={processSteps.length > 1 && !index}
                stepOffset={index ? stepOffset : 0}
                limitingCompound={tempLimitingCompound}
                disableLimiting={processSteps.length > 1 && !step.isFixed}
                isTwoStep={processSteps.length === 2}
                synjetProcess={synjetProcess}
                highlightErrors={highlightErrors}
                isLibGen={isLibGen}
              />
            ) : (
              <SynjetCompoundGroupContainer
                compounds={step.compounds}
                step={index}
                product={step.product}
                viewOnly={!editMode}
                isExpectedIntermediate={processSteps.length > 1 && !index}
                stepOffset={index ? stepOffset : 0}
                limitingCompoundGroup={tempLimitingCompound}
                disableLimiting={processSteps.length > 1 && !step.isFixed}
                synjetProcess={synjetProcess}
                highlightErrors={highlightErrors}
                isLibGen={isLibGen}
              />
            ),
        },
        ...getQuenchingItems(tempLimitingCompound),
        {
          mainTitle:
            processSteps.length > 1
              ? index === 0
                ? `Step ${index + 1}. Conditions`
                : `Step ${index + 1}. Conditions & Dispensing Options`
              : 'Conditions & Dispensing Options',
          stepTitle: getStepTitle(step.isFixed),
          body: (
            <ConditionsAndDispensingOptions
              processConditions={step.conditions}
              processType={processType}
              step={index}
              editMode={editMode}
              isFixed={step.isFixed}
              hideDispensing={processSteps.length > 1 && index === 0}
              highlightErrors={highlightErrors}
              isPro={isPro}
              numberOfSteps={synjetProcess?.process?.numberOfSteps}
            />
          ),
        },
        ...getLibGenStepsItems(index, step.isFixed, processSteps.length > 1, tempLimitingCompound),
      ])
      .flat();
  }, [processSteps, synjetProcess, editMode, highlightErrors, isLibGen, getQuenchingItems, getLibGenStepsItems]);

  const handleRouteGeneration = steps => {
    dispatch(setSteps(steps));
    setHighlightErrors(true);
  };

  const closePopupResultingRoute = () => {
    setShowResultingRoute(false);
  };

  return (
    <Spinner loading={loading}>
      <div className="process-builder-container">
        {!!synjetProcess && !processPermissionsError && (
          <>
            <ProcessDetails
              data={synjetProcess}
              currentUser={currentUser}
              setShowInitialRoute={setShowInitialRoute}
              showInitialRoute={showInitialRoute}
              setShowResultingRoute={setShowResultingRoute}
              showResultingRoute={showResultingRoute}
            />
            <CreatorProcessFromRoute
              isPreviewResult
              dataProcess={synjetProcess}
              open={showResultingRoute}
              handleCancel={closePopupResultingRoute}
              deviceType={PROCESS_TYPES.SynJet.device}
            />
            {showInitialRoute && synjetProcess?.process && (
              <ViewInitialRoute
                dataProcess={synjetProcess}
                isSynjet
                isPro={isPro}
                setShowInitialRoute={setShowInitialRoute}
                updateProcess={data => dispatch(updateProcess(data))}
                editMode={editMode}
                setGenerationData={handleRouteGeneration}
              />
            )}
            <ProcessItemCollapse
              showSteps
              useStoreUpdate
              editMode={editMode}
              items={[
                ...getStepsItems(),
                {
                  editModeOnly: false,
                  mainTitle: 'Exclusion rules',
                  numberOfItems: getRulesTitle(),
                  body: (
                    <ExclusionRules
                      synjetProcess={synjetProcess}
                      rulesData={rules}
                      compounds={processSteps}
                      quenching={synjetProQuenching}
                      processType={synjetProcess?.process?.type}
                      editMode={editMode}
                      isPro={isPro}
                    />
                  ),
                },
                {
                  mainTitle: 'Experiment table',
                  body: experimentsCount ? (
                    <SynJetExperimentTable
                      experiments={experiments}
                      displayOption={selectedDisplayOption}
                      processType={synjetProcess?.process?.type}
                      processSteps={processSteps}
                      products={synjetProProducts}
                      quenching={synjetProQuenching}
                      isQuenchingEnabled={isQuenchingEnabled}
                    />
                  ) : null,
                  extra: experimentsCount ? (
                    <ButtonGroup
                      className="process-builder-container-display-option-button-group"
                      value={selectedDisplayOption}
                      onChange={value => {
                        setSelectedDisplayOption(value);
                      }}
                      options={buttons}
                    />
                  ) : null,
                  numberOfItems: experimentsCount,
                  emptyState: <EmptyState title="Experiment table is empty" subtitle="Insufficient data" />,
                },
                {
                  mainTitle: 'Hardware summary',
                  body: (
                    <HardwareSummary
                      isPro={isPro}
                      experiments={experiments}
                      twoStepsWithFirstFixed={processSteps[0].isFixed && processSteps.length > 1}
                    />
                  ),
                },
              ]
                .map((i, idx) => {
                  i.key = idx + 1;
                  return i;
                })
                .filter((i, idx) => (!editMode && !i.editModeOnly) || editMode)}
            />
            {currentUser?.permissions?.process?.create_process && (
              <Footer
                onSave={onSave}
                onCancel={() => setOpenCancelPopup(true)}
                onValidate={() => {
                  setOpenValidatePopup(true);
                }}
                onCreateExperiment={handleCreateExperiment}
                editMode={editMode}
                setEditMode={() => {
                  dispatch(setEditMode(true));
                  dispatch(lockSynjetProcess(synjetProcess.process.processLock.uuid));
                }}
                showValidationErrorsCounter={validationErrors?.length > 0}
                count={count}
                process={synjetProcess?.process || {}}
                loadingButton={loadingButton}
                showEditValidate={synjetProcess?.process?.status !== 'experiment submitted'}
                disableCreateExperiment={disableCreateExperiment}
                showCreateExperimentBtn={showCreateExperimentBtn}
                fixedFooter
              />
            )}
            <OnBeforeLeave />
          </>
        )}
      </div>
      <Popup
        open={openCancelPopup}
        title="Leave the page?"
        handleSubmit={async () => {
          setLoading(true);
          setOpenCancelPopup(false);
          dispatch(lockSynjetProcess(synjetProcess.process.processLock.uuid, 'unlocked'));
          await dispatch(getProcess(id));
          setLoading(false);
          if (editMode) dispatch(setEditMode(false));
          else history.push('/synjet');
        }}
        handleCancel={() => {
          setOpenCancelPopup(false);
        }}
        textSubmit="Ok"
        loading={false}
      >
        Changes you made may not be saved
      </Popup>
      <Popup
        open={openValidatePopup}
        title="Validate process"
        handleSubmit={() => onSave(true)}
        handleCancel={() => {
          setOpenValidatePopup(false);
        }}
        textSubmit="Validate"
        loading={loading}
      >
        <span>Please note that validate action will also save all process changes made</span>
      </Popup>
      <Popup
        open={validatedConfirmationOpen}
        title="Warning"
        handleSubmit={() => {
          onSave();
          setValidatedConfirmationOpen(false);
        }}
        handleThirdButton={() => {
          onSave(true);
          setValidatedConfirmationOpen(false);
        }}
        handleCancel={() => {
          setValidatedConfirmationOpen(false);
        }}
        textSubmit="Save in draft"
        textThirdButton="Validate and save"
        loading={loading}
      >
        <div>Please note that you’ve changed the Validated process.</div>
        <div>Please validate it or it will be saved in Draft status until validated.</div>
      </Popup>
      <Popup
        open={createExperimentErrorPopup}
        title="Warning"
        textCancle="Close"
        handleCancel={() => setCreateExperimentErrorPopup(false)}
      >
        <div>The designed process is not valid anymore.</div>
        <div>Please update the process to create an experiment.</div>
      </Popup>
      <CreateExperimentModal isProcessBuilder isSynJet={!isPro} isSynjetPro={isPro} />
    </Spinner>
  );
};
