export default {
  oidc: {
    clientId: '0oakzwy3plPITCUOP4x6',
    issuer: 'https://dev-689316.okta.com/oauth2/default',
    redirectUri: 'http://localhost:3000/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false,
  },
};
