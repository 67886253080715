import React from 'react';
import { Avatar, Collapse, Tooltip } from '../../Common';
import moment from 'moment';
import cn from 'classnames';
import './style.scss';
import { EXPERIMENT_STATUSES, SYNJET_EXPERIMENT_STATUSES } from '../../../constants';
import { showExpTime } from '../../../utils';
import { Notes } from '../Notes/Notes';

const BatchExperiment = props => {
  const { selected, data, onClick, deviceId } = props;
  const handleClick = () => {
    onClick && onClick(data.key, false, deviceId);
  };

  const calculateTime = () => {};

  return (
    <div className={cn('experiment-card', { 'experiment-card--selected': selected })} onClick={handleClick}>
      <div className="experiment-card__row">
        <span className="experiment-card__text">{data.process?.type}</span>{' '}
        <span className="experiment-card__status">{data.status}</span>
      </div>
      <div className="experiment-card__row">
        <h6 className="experiment-card__title" title={data.name}>
          {data.name}
        </h6>{' '}
        <span className="time">
          {showExpTime(
            data?.status === SYNJET_EXPERIMENT_STATUSES.COMPLETED.label ? +data?.actualTime : +data.totalTime
          )}
        </span>
      </div>
      <div className="experiment-card__row">
        <Tooltip title={'Project: ' + data.process?.project?.name}>
          <span className="experiment-card__text">Project: {data.process?.project?.name}</span>
        </Tooltip>
        {!!data.notes?.length && <Notes count={data?.notes.length} />}
      </div>
      {data.status === 'Completed' && data.completedAt && (
        <div className="experiment-card__row">
          <span className="experiment-card__text">
            Completed: {moment(data.completedAt).format('MM/DD/YYYY - h:mm A')}
          </span>{' '}
        </div>
      )}
      {selected && (
        <>
          <Tooltip title={'Process: ' + data.process?.name}>
            <p className="experiment-card__text">Process: {data.process?.name}</p>
          </Tooltip>
          <p>
            <Avatar size={20} name={`${data.createdBy?.firstName} ${data.createdBy?.lastName}`} />
          </p>
        </>
      )}
    </div>
  );
};

export const BatchCard = props => {
  const { data, selectedExperiment, setExperiment, index, defaultActiveKey } = props;

  const onSelectBatch = () => {
    setExperiment(data.batchId, true, data.timeSlot?.device?.uuid);
  };
  const title = (
    <div className={cn('batch-card__content')} onClick={onSelectBatch}>
      <h5 className="batch-card__title">Batch {data.batchId}</h5>
      <div className="batch-card__info">
        <span className="batch-card__info__experiments">{data.timeSlot?.experiments?.length} experiments</span>
        <span
          className={cn('batch-card__info__status', {
            'batch-card__info__status--progress': data?.status === EXPERIMENT_STATUSES.InProgress,
          })}
        >
          {data?.status}
        </span>
      </div>
      <span className="batch-card__scheduled">
        Scheduled: {data.timeSlot.startAt && moment(data.timeSlot.startAt).format('MM/DD/YYYY - h:mm A')}
      </span>
    </div>
  );

  const body = (
    <div className="batch-card__experiments-container">
      {data.timeSlot.experiments &&
        data.timeSlot.experiments.map(experiment => (
          <BatchExperiment
            selected={selectedExperiment === experiment.key}
            key={experiment.uuid}
            data={experiment}
            onClick={setExperiment}
            deviceId={data.timeSlot?.device?.uuid}
          />
        ))}
    </div>
  );

  return (
    <Collapse
      className={cn('batch-card', { 'batch-card--selected': selectedExperiment === data.batchId })}
      defaultActiveKey={defaultActiveKey}
      items={[
        {
          key: index + 1,
          title,
          body,
        },
      ]}
    />
  );
};
