import React, { useState } from 'react';
import './style.scss';
import pumpIcon from '../../../dist/icons/process-builder/Pump.svg';
import reagent from '../../../dist/icons/process-builder/limited_reagent.svg';
import { Formula } from './Formula';
import { PumpCompoundList } from './PumpCompoundList';
import cn from 'classnames';
import { PopoverControllable } from '../../SynJet/PopoverControllable/PopoverControllable';
import { Reactor } from './Reactor';
import { Popover } from 'components/Common';
import { ManualOptimizationInputPopoverContent } from './ManualOptimizationPopover/ManualOptimizationInputPopoverContent';
import { COMPOUND_VARIATY } from 'constants/common';

export const Pump = ({
  data,
  changePump,
  openCompoundAdd,
  reactorIdx,
  pumpIdx,
  changeCompound,
  liquid = false,
  varationAvailable,
  setCompoundPosition,
  realTime = false,
  configCharts,
  experiment,
  manual = false,
  onChangeInputEquivalents = () => {},
  showOptions = false,
  limitingInput,
  notVariable,
  isGas = false,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const openPumpChange = e => {
    if (manual) return;
    if (
      e.target.classList.contains('workspace-add-button-text') ||
      e.target.classList.contains('ant-modal-wrap') ||
      e.target.classList.contains('workspace-add-button')
    )
      return;
    changePump();
  };

  const MFCPump = !!(data?.type[0] === 'MFC');

  let formulasData = data?.flow_rate;
  if (manual) {
    formulasData = [];
    let props = [];
    if (notVariable) {
      data.properties.forEach(prop => {
        prop.chemical_type === 'solvent'
          ? props.push({
              volume: prop.solvent_volume,
            })
          : prop.equivalents.forEach((mole, index) => {
              props.push({
                moles: prop.moles[0],
                equivalents: prop.equivalents[index],
                mass: prop.mass[0],
                volume: prop.volume[0],
                chemical_type: prop.chemical_type,
                density: prop.density,
                concentration: prop.concentration,
                mw: prop.compound.molwt,
                reagent_type: prop.reagent_type,
                compound: prop.compound,
              });
            });
        formulasData.push(props);
      });
    } else
      data.properties.forEach(prop => {
        let props = [];
        prop.chemical_type === 'solvent'
          ? props.push({
              volume: prop.solvent_volume,
            })
          : prop.moles.forEach((mole, index) => {
              props.push({
                moles: mole,
                equivalents: prop.equivalents[index],
                mass: prop.mass[index],
                volume: prop.volume[index],
                chemical_type: prop.chemical_type,
                density: prop.density,
                concentration: prop.concentration,
                mw: prop.compound.molwt,
                reagent_type: prop.reagent_type,
                compound: prop.compound,
              });
            });
        formulasData.push(props);
      });
  }

  const getPumpQName = formulasDataItemIndex => {
    return manual
      ? formulasData.length > 1
        ? `${formulasDataItemIndex + 1}: `
        : notVariable
        ? `${COMPOUND_VARIATY[formulasDataItemIndex]}: `
        : ''
      : formulasData.length > 1
      ? `Q${formulasDataItemIndex + 1}`
      : 'Q';
  };

  const pumpUnit = manual ? 'mmol' : MFCPump ? 'sccm' : 'mL/min';

  const addOption = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={cn('pump-container', {
        working: realTime && data?.working,
        'not-working': realTime && !data?.working,
        manual: !!manual,
        'gas-pump': isGas,
      })}
      onClick={openPumpChange}
    >
      <div className="pump-name pump-item">
        <div className="pump-header-name">
          <img className="pump-icon" src={pumpIcon} />
          {!realTime && !manual && data?.name}
          <span className="grey-text">{realTime ? data?.key : data?.type.join(', ')}</span>
        </div>
        {realTime && (
          <PopoverControllable data={data} pump configAllCharts={configCharts} experiment={experiment} liquid={liquid}>
            <div className="open-component-details">Details</div>
          </PopoverControllable>
        )}
      </div>
      {!liquid && !isGas && (
        <PumpCompoundList
          pumpProperties={
            manual
              ? data.properties.map(i => {
                  return {
                    reactants: i.chemical_type === 'reactant' ? [{ properties: i.compound }] : [],
                    reagents: i.chemical_type === 'reagent' ? [{ properties: i.compound }] : [],
                    solvents:
                      (!i.compound || i.reagent_type === 'solution') && i.solvent
                        ? [{ properties: i.solvent, solvent: true }]
                        : [],
                    limiting: i.limiting,
                  };
                })
              : data.properties
          }
          varationAvailable={varationAvailable}
          openCompoundAdd={openCompoundAdd}
          reactorIdx={reactorIdx}
          pumpIdx={pumpIdx}
          changeCompound={changeCompound}
          setCompoundPosition={setCompoundPosition}
          isMFCPump={MFCPump}
          manual={manual}
        />
      )}
      {manual && (
        <div className="manual-pump-options">
          {formulasData.map((property, key) => {
            return (
              <div className="pump-data pump-item" key={key} style={{ width: `${100 / formulasData.length}%` }}>
                {property?.map((formulasDataItem, formulasDataItemIndex) => {
                  return (
                    <Formula
                      name={() => getPumpQName(formulasDataItemIndex)}
                      value={formulasDataItem}
                      unit={pumpUnit}
                      error={formulasDataItem < 0.001}
                      errorValueToDisplay={formulasDataItem}
                      manualPump={manual}
                    />
                  );
                })}
                {property?.length < 5 && showOptions && !!property[0]?.chemical_type && !data.properties[0]?.limiting && (
                  <Popover
                    overlayClassName="ant-optimization-popover"
                    visible={popoverVisible === key}
                    onVisibleChange={visible => setPopoverVisible(key)}
                    content={() => (
                      <ManualOptimizationInputPopoverContent
                        equivalents={property.map((prop, index) => {
                          return {
                            equivalents: prop.equivalents,
                            moles: prop.moles,
                            mass: prop.mass,
                            volume: prop.volume,
                            chemical_type: prop.chemical_type,
                            density: prop.density,
                            concentration: prop.concentration,
                            mw: prop.mw,
                            reagent_type: prop.reagent_type,
                            compound: prop.compound,
                          };
                        })}
                        onSave={v => onChangeInputEquivalents(v, key)}
                        onCancel={() => setPopoverVisible(false)}
                        isOpen={popoverVisible === key}
                        data={property}
                        limitingInput={limitingInput}
                        isLimiting={data.properties[0]?.limiting}
                      />
                    )}
                    trigger="click"
                  >
                    <span className="add-option input" onClick={addOption}>
                      options
                    </span>
                  </Popover>
                )}
              </div>
            );
          })}
        </div>
      )}
      {!manual && (
        <div className="pump-data pump-item">
          {!realTime &&
            formulasData?.map((formulasDataItem, formulasDataItemIndex) => {
              return (
                <Formula
                  name={() => getPumpQName(formulasDataItemIndex)}
                  value={formulasDataItem}
                  unit={pumpUnit}
                  error={formulasDataItem < 0.001}
                  errorValueToDisplay={formulasDataItem}
                  manualPump={manual}
                />
              );
            })}
          {realTime && (
            <>
              {data.sq && (
                <Formula
                  name="SQ"
                  value={data.sq}
                  unit={pumpUnit}
                  error={data.sq < 0.001}
                  errorValueToDisplay={data.sq}
                />
              )}
              {data.aq && (
                <Formula
                  name="AQ"
                  value={data.aq}
                  unit={pumpUnit}
                  error={data.aq < 0.001}
                  errorValueToDisplay={data.aq}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
