import './styles.scss';

import React, { useState } from 'react';

import { DeleteOutlined, HolderOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { Input, Select } from 'components/Common';
import { SortableHandle } from 'react-sortable-hoc';

import { COLORS, OPTION_FIELD } from '@organisms';

export const ExperimentFieldOption = ({ optionData, single, colorCoding, disabled, onChange, onRemove = () => {} }) => {
  const [currentColor, setCurrentColor] = useState(optionData.color);
  const DragHandle = SortableHandle(() => (
    <div className="option-context">
      <HolderOutlined className="option-drag-drop" />
    </div>
  ));

  const onChangeColor = value => {
    setCurrentColor(value);
    onChange(value, OPTION_FIELD.color);
  };

  const getOptionElements = (options, currentColor) =>
    options.map(option => (
      <div value={option.value} key={option.value}>
        <div
          className="select-color-dropdown"
          style={{ background: `${currentColor ? currentColor : option?.value}` }}
        ></div>
      </div>
    ));

  return (
    <div className={cn('option', { 'option-error': optionData.errorMessage || optionData.errorMessageForColor })}>
      <DragHandle />
      {colorCoding && (
        <div className="option-color">
          <Select
            optionElement
            options={COLORS}
            value={optionData.color ? getOptionElements([optionData], currentColor) : ''}
            placeholder=""
            error={optionData.errorMessageForColor?.length}
            errorText={optionData.errorMessageForColor}
            errorClassName="option-color-error"
            disabled={disabled}
            onChange={onChangeColor}
          >
            {getOptionElements(COLORS)}
          </Select>
        </div>
      )}
      <div className="option-value">
        <Input
          className="option-input"
          value={optionData.label ? optionData.label : null}
          onChange={value => {
            onChange(value, OPTION_FIELD.value);
          }}
          error={optionData.errorMessage?.length}
          errorText={optionData.errorMessage}
          disabled={disabled}
          allowSpecials
        />
      </div>
      {!single && !disabled && (
        <div className="option-remove" onClick={onRemove}>
          <DeleteOutlined />
        </div>
      )}
    </div>
  );
};
