import moment from 'moment';
import { toISOString, toISOStringUTCMinDay, toISOStringUTCPlusDay } from './date';
import { TYPES_EVENTS } from '../constants';

export const objectToGraphqlString = object => {
  if (!lengthObj(object)) return;
  const keys = Object.keys(object);
  return `${keys
    .map(
      i =>
        `${i}: ${
          Array.isArray(object[i])
            ? JSON.stringify(object[i])
            : typeof object[i] === 'number' || typeof object[i] === 'boolean'
            ? object[i]
            : typeof object[i] === 'object'
            ? `{${objectToGraphqlString(object[i])}}`
            : `"${object[i]}"`
        }`
    )
    .join(',')}`;
};

export const objectWithArrayToString = obj => {
  const keys = Object.keys(obj);
  return `${keys
    .map(
      i =>
        `${i}: ${
          Array.isArray(obj[i])
            ? JSON.stringify(JSON.stringify(obj[i]))
            : typeof obj[i] === 'boolean'
            ? obj[i]
            : `"${obj[i]}"`
        }`
    )
    .join(', ')}`;
};

export const lengthObj = obj => (obj ? Object.keys(obj).length : 0);

export const filterObj = obj => {
  const newObj = {};
  for (const key in obj) {
    if (!!obj[key] && !Array.isArray(obj[key])) newObj[key] = obj[key];
    else if (Array.isArray(obj[key]) && obj[key].length) newObj[key] = obj[key];
  }
  return newObj;
};

export const decodeUrl = url => {
  if (!url) return {};
  const search = url.slice(1);
  const obj = {};
  decodeURIComponent(search)
    .split('&')
    .map(i => i.split('='))
    .map(i => {
      obj[i[0]] = i.length > 2 ? i.splice(1).join('=') : i[1];
    });
  return obj;
};

export const decodeUrlWithSeparatedSpecialSymbols = url => {
  if (!url) return {};
  const search = url.slice(1);
  const obj = {};
  search
    .split('&')
    .map(i => i.split('='))
    .map(i => {
      obj[i[0]] = i.length > 2 ? decodeURIComponent(i.splice(1)).join('=') : decodeURIComponent(i[1]);
    });
  return obj;
};

export const toCapitalizeChart1 = str => {
  const strArr = str.split('');
  strArr[0] = strArr[0].toUpperCase();
  return strArr.join('');
};

export const toLowerCaseChart1 = str => {
  const strArr = str.split('');
  strArr[0] = strArr[0].toLowerCase();
  return strArr.join('');
};

export const getArrayFilter = (options, field) => `${options.length ? `&${field}=${options.join(',')}` : ''}`;

export const urlSearchParams = object => {
  let str = '?';
  const obj = filterObj(object);
  const getStr = data => {
    if (Array.isArray(data)) return data.join(',');
    if (typeof data === 'string' || typeof data === 'number') return data;
  };
  Object.keys(obj).forEach((i, index, arr) => {
    str += `${(index ? '&' : '') + i}=${getStr(object[i])}`;
  });
  return str;
};

export const getStrTagPlaceholder = (selected, allOptions, field, withoutAll) => {
  const lenSelected = selected?.length;
  const lenOpt = field === 'creators' && !allOptions.length ? allOptions.createdBy.length : allOptions?.length;
  return lenOpt === lenSelected && lenSelected > 0
    ? `All ${field}`
    : `Selected ${lenSelected} out of ${withoutAll ? lenOpt : lenOpt - 1}`;
};

export const camelToSnakeCase = str => str.replace(/([A-Z]+)/g, '_$1').toLowerCase();

export const snakeToCamelCase = str =>
  str
    .split('_')
    .map((i, idx) => (idx === 0 ? i : toCapitalizeChart1(i)))
    .join('');

export const checkIfTrue = obj => {
  for (const o in obj) if (obj[o]) return true;
  return false;
};

export const checkIntersectionCalendar = (currentEvent, calendarApi, exludeUuids = [], modeChecking) => {
  const api = calendarApi.getApi();
  let events = api.getEvents();
  const t = moment;
  const currentEventMoment = {
    startDate: moment(currentEvent.startDate),
    endDate: moment(currentEvent.endDate),
    uuid: currentEvent.uuid,
  };
  events = events.filter(i => {
    const uuidEvent = i.extendedProps?.data?.uuid;
    return (
      (t(i.startStr).isSame(currentEventMoment.startDate) ||
        t(i.endStr).isSame(currentEventMoment.endDate) ||
        t(currentEventMoment.startDate).isBetween(i.startStr, i.endStr) ||
        t(currentEventMoment.endDate).isBetween(i.startStr, i.endStr) ||
        t(i.startStr).isBetween(currentEventMoment.startDate, currentEventMoment.endDate) ||
        t(i.end).isBetween(currentEventMoment.startDate, currentEventMoment.endDate)) &&
      uuidEvent !== currentEventMoment.uuid &&
      !exludeUuids.find(uuid => uuid === uuidEvent)
    );
  });
  if (modeChecking === 'getItems') return events;
  return !events.length;
};

export const getParamCalendar = (calendarApi, device, typeEventsList) => {
  const viewObj = calendarApi.getApi().view;
  return {
    maintainancesOnly: typeEventsList === TYPES_EVENTS[1].value,
    device,
    startAt: toISOStringUTCMinDay(viewObj.activeStart),
    endAt: toISOStringUTCPlusDay(viewObj.activeEnd),
  };
};

export const checkNumericField = (value, maxValue, numberAfterDecimal) => {
  const str = /^\-?(\d+\.?\d*|\d*\.?\d+)$/gm.test(value);
  if ((str || !value) && value <= maxValue) {
    return value.indexOf('.') >= 0
      ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), numberAfterDecimal + 1)
      : value;
  }
};

export const decodeURIComponentSafe = s => {
  if (!s) {
    return s;
  }
  return decodeURIComponent(s.replace(/%(?!@#\$%\^&\*()_\+|\\"'\?!\/>\.<,\d+)/g, '%25'));
};

export const elOutSideDisplay = el => {
  const rect = el.getBoundingClientRect();
  return (
    rect.x + rect.width < 0 || rect.y + rect.height < 0 || rect.x > window.innerWidth || rect.y > window.innerHeight
  );
};

export const addLabelsToOptions = resp => {
  const u = [];
  resp.results.forEach(item => {
    u.push({ label: item.name, value: item.uuid });
  });
  return u;
};

export const sortArrayAsc = (value1, value2) => value1 - value2;
export const sortArrayDesc = (value1, value2) => value2 - value1;

export const getUniqueValuesFromArray = (value, index, self) => self.indexOf(value) === index;
