import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Pagination, Spinner, Table } from '../../../components';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { PopupUserDetails } from '../../../components/PopupUserDetails/PopupUserDetails';
import { cleanerStore, getDetailsUser } from '../../../store/common/common.actions';
import { getListUsers, updateUserRoles } from '../../../store/users/users.actions';
import { decodeUrl, urlSearchParams } from '../../../utils';
import history from '../../../utils/history';
import { columns } from './columns';

const List = props => {
  const [popupDetails, setPopupDetails] = useState(false);
  const [infoPopup, setInfoPopup] = useState({});
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { search } = useLocation();

  const getDataFromPage = page => {
    setPage(page);
    history.replace({ search: urlSearchParams({ page }) });
    setLoading(true);
    props.getListUsers(page).finally(() => {
      setLoading(false);
    });
  };
  const [totalCount, setTotalCount] = useState();

  const onRowClick = info => {
    if (props.user.permissions?.user.change_user_role) {
      setPopupDetails(true);
      setInfoPopup(info);
    }
  };

  useEffect(() => {
    setLoading(true);
    const _search = decodeUrl(search);
    props.getListUsers(_search?.page || page).finally(() => {
      setLoading(false);
      setInitialLoading(false);
      if (_search?.page) {
        setPage(_search.page);
      }
    });
    props.getDetailsUser();
    return () => props.cleanerStore('users');
  }, []);

  useEffect(() => {
    if (props.users.results?.length) {
      setTableData(
        props.users.results.map(res => ({
          ...res,
          projects: _.uniqBy([...res.projects, ...res.managedProjects], 'uuid'),
        }))
      );
      setTotalCount(props.users.totalCount);
      setLoading(false);
    }
  }, [props.users.results]);
  return (
    <>
      {props.user.permissions?.user && props.users.results?.length && totalCount ? (
        <Table
          onRowClick={onRowClick}
          columns={columns}
          dataSource={tableData}
          totalCount={totalCount}
          onChange={getDataFromPage}
          current={page}
          defaultPageSize={10}
        />
      ) : initialLoading ? (
        <></>
      ) : (
        <EmptyState message="Users will be available here as soon as you are assigned" emptyState essence="user" />
      )}
      <PopupUserDetails
        infoPopup={infoPopup}
        rolesData={tableData.roles}
        popupDetails={popupDetails}
        setInfoPopup={setInfoPopup}
        setPopupDetails={setPopupDetails}
        updateUserRoles={props.updateUserRoles}
        setLoadingUsers={setLoading}
        currentUser={props.user}
        page={page}
      />
    </>
  );
};

const mapStateToProps = store => ({
  users: store.usersReducer.users,
  user: store.commonReducer.user,
});

export default connect(mapStateToProps, {
  getListUsers,
  updateUserRoles,
  getDetailsUser,
  cleanerStore,
})(List);
