import React, { useMemo } from 'react';

import cn from 'classnames';

import { Input } from '../../../../../Common';
import { Switch } from '../../../../../Common/Switch';
import { SmilesElem } from '../../../../PrepareMaterials/SmilesElem';
import { setFixed } from '../index';

const useColumns = ({ handleChangeSwitch, handleChangeInput, tableData, errors, ...props }) =>
  useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: <div className={row.className}>&nbsp;</div>,
          };

          if (row.name.value) {
            obj.children = (
              <SmilesElem
                id={`solids-${row.name.value}-${index}`}
                data={row.name.value}
                displayLoupe
                isNeededTitle={false}
                customOptions={{ height: 80, width: 120 }}
              />
            );
          }
          return obj;
        },
      },
      {
        title: '',
        dataIndex: 'compound_name',
        width: '0',
        render: (value, row) => {
          const obj = {
            children: <div className={row.className}>&nbsp;</div>,
          };
          obj.children = (
            <div className="lv-solids-table_compound-name">
              <span>{value.name}</span>
              <span>{value.formula}</span>
            </div>
          );

          return obj;
        },
      },
      {
        title: 'Vial #',
        dataIndex: 'vial_number',
        width: '0',
        render: (value, row) => {
          const obj = {
            children: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value.value.map(i => (
                  <div className={cn('lv-solids-table_vial', row.className)}>{i}</div>
                ))}
              </div>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Location',
        dataIndex: 'location',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value.value.map(i => (
                  <div className={cn('lv-solids-table_location', row.className)}>{i}</div>
                ))}
              </div>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Calculated amt (mg)',
        dataIndex: 'calc_amt',
        width: '0',
        render: (value, row, index, ...other) => ({
          children: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {value.value.map(i => (
                <div className={cn('lv-solids-table_calc-amt', row.className)}>{setFixed(i)}</div>
              ))}
            </div>
          ),
        }),
      },
      {
        title: 'Actual amt (mg)',
        dataIndex: 'actual_amt',
        width: '80px',
        render: (value, row, index, ...other) => {
          const obj = {
            children: <div className={row.className}>&nbsp;</div>,
          };

          obj.children = (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {value.value.map((item, indexSubRow) => {
                const error = errors.solids[index]?.find(i => i === indexSubRow);
                return (
                  <Input
                    regex={/[^\d|.]+/g}
                    value={item}
                    className={cn('lv-solids-table_actual-amt', row.className)}
                    onChange={(...args) => handleChangeInput(...args, index, indexSubRow)}
                    field="actual_amt"
                    error={error !== undefined}
                  />
                );
              })}
            </div>
          );

          return obj;
        },
      },
      {
        title: ' % Error',
        dataIndex: 'error_percentage',
        width: '0',
        render: (value, row, index, ...other) => {
          const obj = {
            children: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value.value.map(i => (
                  <div className={cn('lv-solids-table_error', row.className)}>{i}</div>
                ))}
              </div>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Done',
        dataIndex: 'has_sent',
        width: '0',
        render: (val, row, index, ...other) => {
          const obj = {
            children: (
              <div className={row.className}>
                <Switch checked={row.has_sent?.value} onChange={value => handleChangeSwitch(value, index, row)} />
              </div>
            ),
          };
          return obj;
        },
      },
    ],
    [tableData, errors]
  );

export default useColumns;
