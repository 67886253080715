import React, { useCallback, useState } from 'react';
import './style.scss';
import { Button } from '../../../Common';
import { PopupCreateProject } from '../../../PopupCreateProject/PopupCreateProject';
import { typesProjects } from '../../../../constants';
import { createProject } from '../../../../store/projects/projects.actions';

export const GeneralTab = ({ details, createProject, permissions, user }) => {
  const [popupCreateProject, setPopupCreateProject] = useState(false);

  const openPopup = useCallback(() => setPopupCreateProject(true), []);
  const closePopup = useCallback(() => setPopupCreateProject(false), []);

  return (
    <div className="project-details-general">
      <div className="project-details-general_header">
        <span className="project-details-general_header_title">General information</span>
        {permissions.change_project_details && (
          <Button secondary onClick={openPopup}>
            EDIT
          </Button>
        )}
      </div>
      <div className="project-details-general_body">
        <div className="project-details-general_body_column">
          <Item text={details.name} label="Project name" />
          <Item text={details.program} label="Program" />
          <Item text={details.goals || '-'} label="Project goals" />
        </div>
        <div className="project-details-general_body_column">
          <Item text={details.customer || '-'} label="Customer" />
          <Item text={typesProjects[details.type].label} label="Project type" />
          <Item text={details.manager?.initials || '-'} label="Project manager" />
        </div>
      </div>
      {!!popupCreateProject && (
        <PopupCreateProject
          defaultData={details}
          openPopup={openPopup}
          createProject={createProject}
          closePopup={closePopup}
          open={popupCreateProject}
        />
      )}
    </div>
  );
};

const Item = ({ label, text }) => {
  return (
    <div className="item-text-with-label">
      <span className="item-text-with-label_label">{label}</span>
      <span className="item-text-with-label_text">{text}</span>
    </div>
  );
};
