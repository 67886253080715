import React, { useState, useEffect } from 'react';
import './style.scss';
import { Input, Button } from '../../components';
import okta_login from '../../dist/images/okta-login.svg';
import sri_image from '../../dist/images/sri-image.svg';
import { httpRest } from '../../utils/http';
import history from '../../utils/history';
import packagej from '../../../package.json';
import logo from 'dist/images/logo.svg';

export const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    let url = window.location;
    let saml_token = new URLSearchParams(url.search).get('saml_token');
    httpRest({
      url: 'auth/token/exchange/',
      method: 'POST',
      data: { saml_token: saml_token ? saml_token : '' },
    }).then(data => {
      redirectAccordingToRole();
    });
  }, []);

  const redirectAccordingToRole = () => {
    history.push('/');
  };

  return (
    <div className="login-wrapper">
      <div className="left">
        <img className="sri-image" src={sri_image} />
        <div className="login-form">
          <img className="login-title" src={logo} />
          <Input
            error={errors.email || errors.code}
            label="Login"
            placeholder="example@sri.com"
            value={login}
            onChange={e => {
              setLogin(e);
              setErrors(false);
            }}
            className="input-margin"
            errorText={errors.email}
          />
          <Input
            className="password"
            error={errors.password}
            label="Password"
            placeholder="Enter password"
            type="password"
            value={password}
            onChange={e => {
              setPassword(e);
              setErrors(false);
            }}
            errorText={errors.password}
          />
          <div className="login-wrapper-btns">
            <Button
              large
              onClick={() => {
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('refresh');
                httpRest({
                  url: 'auth/password/authentication/',
                  method: 'POST',
                  data: { email: login, password: password },
                })
                  .then(() => {
                    redirectAccordingToRole();
                  })
                  .catch(err => {
                    if (!!err.detail) {
                      setErrors({ password: err.detail, code: err.code });
                    } else {
                      setErrors({ ...err });
                    }
                  });
              }}
            >
              Log in
            </Button>
            <div className="divider">
              <div className="divider-block" />
              <div className="pill-text">OR</div>
            </div>
            <Button
              secondary
              className="btn-okta"
              large
              onClick={() => {
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('refresh');
                httpRest({
                  url: 'auth/sso/okta/login/',
                  method: 'GET',
                }).then(data => {
                  window.open(data.redirect_url, '_self');
                });
              }}
            >
              <img src={okta_login} />
              Login with okta
            </Button>
          </div>
        </div>
        <div className="sri-version">Build version {packagej.version}</div>
      </div>
      <div className="right">
        <p className="login-text">
          Fully automated <br /> chemical synthesis
        </p>
      </div>
    </div>
  );
};
