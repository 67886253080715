export const parseLogs = (action, type, value, compounds) => {
  let actionText = '';
  switch (type) {
    case 'set_channel': {
      if (action.type === 'valve')
        actionText =
          action.device_id === 'VICIV08'
            ? value === 1
              ? 'switch to collection'
              : 'switch to waste'
            : value === 1
            ? 'switch to solvent'
            : 'switch to reagent';
    }
    case 'set_enabled': {
      if (action.type === 'pump' || action.type === 'gas_pump') actionText = value ? 'start pumping' : 'stop pumping';
      if (action.type === 'reactor') actionText = value ? 'turn on' : 'turn off';
      break;
    }
    case 'set_flow_rate': {
      if (action.type === 'pump' || action.type === 'gas_pump') {
        actionText = `set flow rate = ${value} ${action.type === 'gas_pump' ? 'sccm' : 'mL/min'}`;
      }
      break;
    }
    case 'set_allocated_gas': {
      value = compounds.find(cpd => cpd.compound_id === value);
      if (action.type === 'gas_pump') actionText = `set calibration gas = ${value?.compound_name}`;
    }
    case 'set_temperature': {
      if (action.type === 'reactor') actionText = `set temperature = ${value} °C`;
      break;
    }
    case 'end_experiment': {
      actionText = 'End Experiment';
      break;
    }
    case 'start_experiment': {
      actionText = 'Start experiment';
      break;
    }
    default: {
    }
  }
  let _type = action.type;
  if (action.type === 'gas_pump') _type = 'pump';
  if (action.type === 'valve' && action.device_id === 'VICIV08') _type = 'output valve';
  let device = `${_type} ${action.device_id}:`;

  return `${device} ${actionText}`;
};
