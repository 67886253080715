import React, { useEffect } from 'react';
import { SynJetCompound, SynJetCompoundList } from '../../../index';
import './index.scss';
import { updateProduct, updateCompound } from '../../../../store/synjet/synjet.actions';
import { useDispatch } from 'react-redux';
import { calculateCompoundListOnLimited, recalculateCompoundsName } from '../../../../utils/synjetHelpers';

const getLimitingTitle = (reactants, reagents) => {
  const reactantLimiting = reactants.findIndex(reactant => reactant.isLimiting);
  const reagentLimiting = reagents.findIndex(reagent => reagent.isLimiting);
  if (reactantLimiting !== -1) return `Reactant ${String.fromCharCode(65 + reactantLimiting)}`;
  if (reagentLimiting !== -1) {
    return `Reagent ${String.fromCharCode(65 + reagentLimiting + reactants?.length || 0)}`;
  }
  return '';
};
const SynjetCompoundContainer = ({
  compounds,
  step,
  product,
  viewOnly,
  isExpectedIntermediate,
  isFixed,
  stepOffset,
  limitingCompound,
  disableLimiting,
  isTwoStep,
  synjetProcess,
  highlightErrors,
  isLibGen,
}) => {
  const dispatch = useDispatch();
  const { reactants, reagents } = compounds;

  const availableCount = isTwoStep
    ? reactants?.length + reagents?.length < 5
    : reactants?.length + reagents?.length < 6;
  const addCompoundAvailable = isFixed || availableCount;

  const showProduct = !isLibGen || (isLibGen && isTwoStep && isExpectedIntermediate && isFixed);

  useEffect(() => {
    if (viewOnly) return;
    dispatch(
      updateCompound(
        recalculateCompoundsName(reactants, limitingCompound, stepOffset, false, isFixed),
        recalculateCompoundsName(reagents, limitingCompound, reactants.length + stepOffset, false, isFixed),
        step
      )
    );
  }, [reagents.length, reactants.length, stepOffset, limitingCompound?.isLimiting, viewOnly, synjetProcess]);

  useEffect(() => {
    if (!limitingCompound || viewOnly) return;
    dispatch(
      updateCompound(
        calculateCompoundListOnLimited(limitingCompound, reactants),
        calculateCompoundListOnLimited(limitingCompound, reagents),
        step
      )
    );
  }, [limitingCompound?.volumes, limitingCompound?.concentration, viewOnly]);

  const limitingTitle = getLimitingTitle(reactants, reagents);

  const handleProductChange = product => {
    dispatch(updateProduct(product, step));
  };

  return (
    <div className="synjet-compound-container">
      <SynJetCompoundList
        type="reactant"
        compoundList={reactants}
        limitingTitle={limitingTitle}
        limitingCompound={limitingCompound}
        step={step}
        isFixed={isFixed}
        viewOnly={viewOnly}
        addCompoundAvailable={addCompoundAvailable}
        disableLimiting={disableLimiting}
        highlightErrors={highlightErrors}
        isLibGen={isLibGen}
      />
      <SynJetCompoundList
        type="reagent"
        compoundList={reagents}
        compoundOffset={reactants?.length}
        limitingTitle={limitingTitle}
        limitingCompound={limitingCompound}
        step={step}
        isFixed={isFixed}
        viewOnly={viewOnly}
        addCompoundAvailable={addCompoundAvailable}
        disableLimiting={disableLimiting}
        highlightErrors={highlightErrors}
        isLibGen={isLibGen}
      />
      {showProduct && (
        <>
          <h3 className="synjet-compound-container__title">
            {isExpectedIntermediate ? 'Expected intermediate' : 'Product'}
          </h3>
          <div className="synjet-product__header">
            <span className="title">Name</span>
            <span className="title">Exact mass, g/mol</span>
          </div>
          <SynJetCompound
            letter={isExpectedIntermediate ? 'EI' : 'Pr'}
            compoundInfo={product}
            type="product"
            handleSubmit={handleProductChange}
            viewOnly={viewOnly}
          />
        </>
      )}
    </div>
  );
};

export default SynjetCompoundContainer;
