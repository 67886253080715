import React, { useEffect, useState } from 'react';
import { Tooltip } from '../../../Common';
import { InfoOutlined, PlusOutlined } from '@ant-design/icons';
import { CompoundSmilesPreview } from '../../../index';

const COMBINATION_DEFAULT_COUNT = 3;

const CombinationItem = ({ compound }) => (
  <>
    <PlusOutlined className="plus-icon" />
    <div className="compound-item quenching" key={compound.name}>
      <div className="compound-item__info">
        <span className="name">{compound.name}</span>
        <Tooltip
          title={
            <CompoundSmilesPreview
              compound={compound?.smiles}
              className="smiles-preview"
              options={{ width: 130, height: 80 }}
              hideMW
              showTooltip
            />
          }
          color={'white'}
        >
          <InfoOutlined className="icon" />
        </Tooltip>
      </div>
    </div>
  </>
);

export const SynjetProQuenchingView = ({ quenching }) => {
  const [hiddenCount, setHiddenCount] = useState(0);
  const [quenchingList, setQuenchingList] = useState([]);

  const showHidden = () => {
    setHiddenCount(0);
    setQuenchingList(quenching);
  };

  useEffect(() => {
    if (quenching.length > COMBINATION_DEFAULT_COUNT) {
      const slicedList = quenching.slice(0, COMBINATION_DEFAULT_COUNT);
      setHiddenCount(quenching.length - COMBINATION_DEFAULT_COUNT);
      setQuenchingList(slicedList);
    } else {
      setQuenchingList(quenching);
    }
  }, [quenching]);

  return (
    <div className="combination-list">
      {quenchingList.map(quenchingItem => (
        <CombinationItem compound={quenchingItem} />
      ))}
      {!!hiddenCount && <div className="show-hidden" onClick={showHidden}>{`+ ${hiddenCount}`}</div>}
    </div>
  );
};
