import './styles.scss';

import React from 'react';

import cn from 'classnames';

import { Tooltip } from '../../../components';

const TextComponent = ({
  children,
  className,
  classNameDefault,
  weight,
  italic,
  uppercase,
  lowercase,
  success,
  error,
  primary,
  secondary,
  pink,
  ellipsis,
  tooltip,
  text,
}) => {
  const component = (
    <span
      className={cn(
        `typography typography-${classNameDefault}`,
        className,
        weight,
        { italic },
        { uppercase },
        { lowercase },
        { success },
        { error },
        { primary },
        { secondary },
        { pink },
        { ellipsis }
      )}
    >
      {children || text}
    </span>
  );
  if (tooltip && !['', '-'].includes(text)) {
    return (
      <Tooltip destroyTooltipOnHide placement="top" title={children || text}>
        {component}
      </Tooltip>
    );
  }
  return component;
};

const typographyGenerator = (Component, classNameDefault) => props => (
  <Component {...props} classNameDefault={classNameDefault} />
);

export const Heading1 = typographyGenerator(TextComponent, 'title-1');
export const Heading2 = typographyGenerator(TextComponent, 'title-2');
export const Heading3 = typographyGenerator(TextComponent, 'title-3');
export const Heading4 = typographyGenerator(TextComponent, 'title-4');

export const TextBody16 = typographyGenerator(TextComponent, 'body-16');
export const TextBody14 = typographyGenerator(TextComponent, 'body-14');
export const TextBody12 = typographyGenerator(TextComponent, 'body-12');
export const TextBody11 = typographyGenerator(TextComponent, 'body-11');
export const TextBody10 = typographyGenerator(TextComponent, 'body-10');

export const TextMenu16 = typographyGenerator(TextComponent, 'menu-16');
export const TextButton14 = typographyGenerator(TextComponent, 'button-14');
export const TextButton12 = typographyGenerator(TextComponent, 'button-12');
