import React, { useEffect, useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { Divider, Popover } from '../../../../../Common';
import NotUsedBG from '../../../../../../dist/images/NoVialsBG.svg';
import { getExperimentContainerStyle } from '../../../../../../utils/execution';
import VialPopover from './VialPopover/VialPopover';

const LETTERS_FOR_PLATE_1 = ['A', 'B', 'C', 'D', 'E', 'F'];
const LETTERS_FOR_PLATE_2 = ['L', 'K', 'J', 'I', 'H', 'G'];

const VialPlate = ({
  experimentNames = [],
  plateData = [],
  getProduct,
  isReverse = false,
  updatedVial = {},
  plateNames,
}) => {
  const [localPlateData, setLocalPlateData] = useState(plateData);

  useEffect(
    () => setLocalPlateData(localPlateData.map(item => (item.location === updatedVial.location ? updatedVial : item))),
    [updatedVial]
  );

  useEffect(() => setLocalPlateData(plateData), [plateData]);

  const numberRow = () => {
    const arrayWithNumbers = [...Array(9)].map((_, i) => <div className="plate-item plate-item-extra">{i + 1}</div>);

    return (
      <div className={cn('sjp-vial-plate__number_row', { 'sjp-vial-plate__number_row-reverse': isReverse })}>
        {arrayWithNumbers}
      </div>
    );
  };

  const withPopover = (elem, children, number) => {
    const compound = getProduct(elem.product);

    return (
      <Popover
        content={<VialPopover {...elem} number={number} compound={compound} />}
        trigger="hover"
        placement="topLeft"
        overlayClassName="vial-popover-wrapper"
      >
        {children}
      </Popover>
    );
  };

  const getRow = (letter, startNumber) => {
    const itemLetter = (
      <div className={cn('plate-item-extra', 'plate-item-letter', { 'plate-item-reverse': isReverse })}>{letter}</div>
    );
    const row = [];
    let currentRowData = [];

    localPlateData.forEach(i => {
      if (i.location && i.location[0] === letter) {
        currentRowData.push(i);
      }
      return null;
    });

    currentRowData = currentRowData
      .sort((a, b) => (a.location > b.location ? 1 : b.location > a.location ? -1 : 0))
      .sort((a, b) => +a.location.slice(1) - +b.location.slice(1));

    if (isReverse) currentRowData.reverse();

    for (let i = startNumber; i < startNumber + 9; i += 1) {
      const currentNum = i - startNumber + 1;

      if (currentRowData[0] && +currentRowData[0].location.slice(1) === currentNum && 'is_ready' in currentRowData[0]) {
        const elem = currentRowData[0];
        currentRowData = currentRowData.slice(1);

        const children = (
          <div
            className={cn(
              'plate-item',
              getExperimentContainerStyle(experimentNames.map(exp => exp.name).indexOf(elem.experiment_name)),
              {
                'not-ready': !elem.is_ready,
              }
            )}
          >
            {i}
          </div>
        );

        row.push(withPopover(elem, children, i));
      } else {
        row.push(<div className="plate-item" style={{ backgroundImage: `url(${NotUsedBG})` }} />);
      }
    }

    if (isReverse) {
      row.reverse();
      row.push(itemLetter);
    } else {
      row.unshift(itemLetter);
    }

    return row;
  };

  const getPlate = () => {
    const currentLetters = isReverse ? LETTERS_FOR_PLATE_2 : LETTERS_FOR_PLATE_1;
    const plate = currentLetters.map((letter, idx) => (
      <div className="sjp-vial-plate__main_row">{getRow(letter, idx * 9 + 1)}</div>
    ));

    return isReverse ? plate.reverse() : plate;
  };

  return (
    <div className="sjp-vial-plate" style={{ marginLeft: `${isReverse ? '60' : '0'}%` }}>
      <span className="sjp-finish-plate_extra-text">
        {`${isReverse ? plateNames['Tray 2'] : plateNames['Tray 1']}`}
      </span>
      <Divider className="sjp-vial-plate__divider" />
      {!isReverse && numberRow()}
      {getPlate()}
      {isReverse && numberRow()}
    </div>
  );
};

export default VialPlate;
