import './styles.scss';

import React from 'react';

import { Heading3 } from '@atoms';

export const TableHeaderWithActions = ({ title = '', count, actions }) => (
  <div className="table-header-with-actions">
    <Heading3 className="table-header-with-actions__title">
      {title}
      {!!count && (
        <Heading3 secondary className="table-header-with-actions__count">
          ({count})
        </Heading3>
      )}
    </Heading3>
    <div className="table-header-with-actions_actions">{actions}</div>
  </div>
);
