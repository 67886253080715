import './styles.scss';

import React from 'react';

import { ButtonGroupBox, SpaceHorizontal } from '@atoms';
import { ColumnsSortableList, ColumnsSortableSelectAll, SideBar } from '@organisms';

import { Button, Search } from '../../../components';
import { SearchHighlightedCount } from '../../../components/Common/Search/SearchHighlightedCount';
import { ReactComponent as Tool } from '../../../dist/images/tool.svg';
import { useStateController } from './useStateController';

export const SideBarColumnsManager = ({ invokeInitialTableRequest }) => {
  const {
    onOrderChanged,
    columns,
    visible,
    search,
    onSearch,
    highlightedValueItems,
    activeHighlightedItemValue,
    onDown,
    onUp,
    onSelect,
    onReset,
    onSave,
    isAllSelected,
    isLoading,
    onSelectAll,
    onOpen,
    onClose,
  } = useStateController(invokeInitialTableRequest);

  return (
    <>
      <Button secondary onClick={onOpen}>
        <Tool />
      </Button>
      <SideBar
        className="side-bar-columns-manager"
        onClose={onClose}
        title="Column management"
        visible={visible}
        body={
          <ColumnsSortableList
            activeHighlightedItemValue={activeHighlightedItemValue}
            searchValue={search?.trim()}
            columns={columns}
            onSelect={onSelect}
            onOrderChanged={onOrderChanged}
          />
        }
        subHeader={
          <>
            <Search
              suffix={
                search && (
                  <SearchHighlightedCount
                    onDown={onDown}
                    onUp={onUp}
                    total={highlightedValueItems.length}
                    current={highlightedValueItems.indexOf(activeHighlightedItemValue) + 1}
                  />
                )
              }
              activeHighlightedItemValue={activeHighlightedItemValue}
              highlightedItemCount={highlightedValueItems.length}
              button={false}
              allowSpecials
              placeholder="Search through columns"
              value={search}
              onChange={onSearch}
            />
            <SpaceHorizontal />
            <ColumnsSortableSelectAll isAllSelected={isAllSelected} onSelectAll={onSelectAll} />
          </>
        }
        actions={
          <>
            <Button disabled={isLoading} type="secondary" onClick={onReset}>
              Reset
            </Button>
            <ButtonGroupBox>
              <Button disabled={isLoading} type="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button loading={isLoading} onClick={onSave}>
                Save
              </Button>
            </ButtonGroupBox>
          </>
        }
      />
    </>
  );
};
