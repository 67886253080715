import React from 'react';
import './style.scss';
import { typesReactor } from '../../../constants';

export const ReactorType = ({ type, icon, title, status, onClick }) => {
  const typeConfig = typesReactor[type];
  const Icon = typeConfig.icon;
  return (
    <div className={`card-reactor type_${status} ${type}`} onClick={onClick}>
      <div className="icon">{<Icon />}</div>
      <div className="title">{title}</div>
    </div>
  );
};
