import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { WebsocketService } from 'utils/service/WebSocketService';
import { formatSecondsToTimeString, parseLogs } from '../../../utils';
import { timeToText } from 'utils/date';
import moment from 'moment';
import { getLogs } from 'store/experiment/experiment.actions';
import { Spinner } from 'components/Common';

export const LogsTable = ({ experiment }) => {
  const [subject, setSubject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [logs, _setLogs] = useState([]);
  const logsRef = React.useRef(logs);
  const setLogs = data => {
    logsRef.current = data;
    _setLogs(data);
  };

  const startTime = experiment?.execution?.startedAt ? moment(experiment?.execution?.startedAt) : null;

  //set end experiment text if experiment completed
  const completedAtMessage = {
    text: 'End experiment',
    time:
      startTime && experiment?.completedAt
        ? formatSecondsToTimeString(moment(experiment.completedAt).diff(startTime, 'seconds'))
        : null,
    actualTime: startTime && experiment?.completedAt ? timeToText(moment(experiment?.completedAt)) : null,
  };

  const startedAtMessage = {
    text: 'Start experiment',
    time: startTime ? formatSecondsToTimeString(moment(startTime).diff(startTime, 'seconds')) : null,
    actualTime: timeToText(startTime) || null,
  };
  //if name contains Push Material Pump and step is a decimal number (e.g. 6.1) then it is a single pump on production
  const isSinglePumpOnProduction = log =>
    log.step_name.includes('Push Material Pump') && log.step_number.split(' ')[1].includes('.');

  const getEndTimeForSinglePumpOnProduction = (progress, parsedLogs, indexOfCurrentStep = 0) => {
    let indexOfPrevStep = 0;
    let lastStepForSinglePumpOnProduction;
    for (let stepIndex = indexOfCurrentStep; stepIndex < parsedLogs.length; stepIndex++) {
      if (isSinglePumpOnProduction(parsedLogs[stepIndex])) {
        indexOfPrevStep = stepIndex;
      } else {
        lastStepForSinglePumpOnProduction = progress[indexOfPrevStep];
        break;
      }
    }
    return lastStepForSinglePumpOnProduction;
  };

  const getPrevLogs = _logs => {
    setLoading(true);
    getLogs(experiment?.uuid)
      .then(progress => {
        let parsedLogs = [..._logs];
        let steps = [];
        progress.forEach(progressItem => {
          const timeFromStart = moment(progressItem.executedAt).diff(startTime, 'seconds');
          let timeFromStartForSinglePumpOnProduction;
          parsedLogs = parsedLogs
            .map((log, stepIndex) => {
              if (isSinglePumpOnProduction(log)) {
                let lastStepForSinglePumpOnProduction = getEndTimeForSinglePumpOnProduction(
                  progress,
                  parsedLogs,
                  stepIndex
                );
                timeFromStartForSinglePumpOnProduction = lastStepForSinglePumpOnProduction
                  ? moment(lastStepForSinglePumpOnProduction.executedAt).diff(startTime, 'seconds')
                  : null;
              }
              if (stepIndex === progressItem.step) {
                log.actions = log.actions.map(action => {
                  action.time = formatSecondsToTimeString(timeFromStart);
                  action.actualTime = timeToText(moment(progressItem.executedAt));
                  //if it is a single pump on production with 2 or more steps
                  //end time for each substep should be te same as for the last Push Material Pump on this step
                  if (isSinglePumpOnProduction(log)) {
                    action.endTimeForSindlePumpForProduction = formatSecondsToTimeString(
                      timeFromStartForSinglePumpOnProduction
                    );
                    action.isSinglePumpOnProduction = true;
                  }
                  return action;
                });
              }
              if (!log.actions.length) {
                log.actions = [
                  {
                    noTime: true,
                    text: 'No valve switching is supported for the current pump',
                    time: formatSecondsToTimeString(timeFromStart),
                    actualTime: timeToText(moment(progressItem.executedAt)),
                  },
                ];
              }
              return log;
            })
            .map((log, stepIndex) => {
              // set start time from prev log
              log.actions = log.actions.map(action => {
                return {
                  ...action,
                  startTime: stepIndex !== 0 ? parsedLogs[stepIndex - 1]?.actions[0].time : startedAtMessage.time,
                  actualStartTime:
                    stepIndex !== 0 ? parsedLogs[stepIndex - 1]?.actions[0].actualTime : startedAtMessage.actualTime,
                };
              });
              return log;
            });
        });
        if (!progress.length) {
          parsedLogs = parsedLogs.map((log, stepIndex) => {
            if (stepIndex === 0)
              log.actions = log.actions.map(action => {
                return {
                  ...action,
                  startTime: startedAtMessage.time,
                  actualStartTime: startedAtMessage.actualTime,
                };
              });
            return log;
          });
        }
        setLogs(parsedLogs);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (experiment?.uuid) {
      let parsedLogs = [];
      let compounds = JSON.parse(experiment?.reactionsInfo)?.compounds;
      if (experiment?.execution?.ilStructure) {
        JSON.parse(experiment?.execution?.ilStructure).forEach((step, idx) => {
          let step_actions = [];
          step.actions.forEach(action => {
            Object.keys(action.arguments).forEach((key, argument) => {
              step_actions.push({
                time: null,
                text: parseLogs(action, key, action.arguments[key], compounds),
              });
            });
          });
          parsedLogs.push({
            step_number: step.step_number,
            step_name: step.step_label,
            actions: step_actions,
          });
        });
      }
      getPrevLogs(parsedLogs);
      if (subject) subject.unsubscribe();
      const newSubject = new WebsocketService(`/experiment-execution/${experiment?.uuid}/`);
      setSubject(newSubject);
    }
    return () => {
      if (subject) subject.unsubscribe();
      setSubject(null);
    };
  }, [experiment?.uuid]);

  useEffect(() => {
    if (subject) subject.subscribe(addLogs);
  }, [subject]);

  const addLogs = message => {
    if (message.type === 'il_execution_progress') {
      setLogs(
        [...logsRef.current].map((_log, stepIndex) => {
          if (message.step === stepIndex) {
            _log.actions = _log.actions.map(action => {
              let startTime = moment(experiment?.execution?.startedAt);
              const timeFromStart = moment(message.executed_at).diff(startTime, 'seconds');
              action.time = formatSecondsToTimeString(timeFromStart);
              action.actualTime = timeToText(moment(message.executed_at));
              return action;
            });
          } else if (message.step + 1 === stepIndex) {
            _log.actions = _log.actions.map(action => {
              return {
                ...action,
                startTime:
                  stepIndex !== 0 ? [...logsRef.current][stepIndex - 1]?.actions[0].time : startedAtMessage.time,
                actualStartTime:
                  stepIndex !== 0
                    ? [...logsRef.current][stepIndex - 1]?.actions[0].actualTime
                    : startedAtMessage.actualTime,
              };
            });
          }
          return _log;
        })
      );
    }
  };

  const allLogs = [{ actions: [startedAtMessage] }, ...logs, { actions: [completedAtMessage] }];
  return (
    <Spinner loading={loading}>
      <div className="logs-table">
        <div className="logs-table__row logs-table__header">
          <div className="logs-table__column logs-table__actual-time">ACTUAL TIME</div>
          <div className="logs-table__column logs-table__time">TIMESTAMP</div>
          <div className="logs-table__column logs-table__text">EXECUTION STEP</div>
        </div>
        <div className="logs-table__rows">
          {allLogs.map((step, stepIndex) => {
            return (
              <>
                {step.step_number && (
                  <>
                    <div className="logs-table__rows-title">{step.step_number}</div>
                    <div className="logs-table__rows-title">{step.step_name}</div>
                  </>
                )}
                {step.actions.map((log, index) => {
                  return <LogRow log={log} showStartEnd={!!step.step_number} />;
                })}
              </>
            );
          })}
        </div>
      </div>
    </Spinner>
  );
};

const LogRow = ({ log, showStartEnd }) => {
  return (
    <div className="logs-table__row-batch">
      {showStartEnd && !log.noTime && (
        <div className="logs-table__row">
          <div className={cn('logs-table__column logs-table__actual-time', { grey: !log.actualStartTime })}>
            {log.actualStartTime || '[--:--:--]'}
          </div>
          <div className={cn('logs-table__column logs-table__time', { grey: !log.startTime })}>
            [{log.startTime || '--:--:--'}]
          </div>
          <div className="logs-table__column logs-table__text">{log.text} - start</div>
        </div>
      )}
      <div className="logs-table__row">
        {log.noTime ? (
          <div className="logs-table__column logs-table__text no-time-text">{log.text}</div>
        ) : (
          <>
            <div className={cn('logs-table__column logs-table__actual-time', { grey: !log.actualTime })}>
              {log.actualTime || '[--:--:--]'}
            </div>
            <div className={cn('logs-table__column logs-table__time', { grey: !log.time })}>
              [
              {log?.isSinglePumpOnProduction
                ? log.endTimeForSindlePumpForProduction || '--:--:--'
                : log.time || '--:--:--'}
              ]
            </div>
            <div className="logs-table__column logs-table__text">
              {log.text} {showStartEnd && '- end'}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
